import { BoundNumberField, BoundTextField, Css, IconButton } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { SaveScheduleFlagInput, ScheduleFlagReasonDetailFragment } from "src/generated/graphql-types";
import { BoundScheduleFlagReasonSelects } from "src/routes/projects/dynamic-schedules/components/BoundScheduleFlagReasonSelects";

type ScheduleFlagFormProps = {
  formState: ObjectState<Partial<SaveScheduleFlagInput>>;
  reasons: ScheduleFlagReasonDetailFragment[];
  onClose?: () => void;
};
export function ScheduleFlagForm({ formState, reasons, onClose }: ScheduleFlagFormProps) {
  return (
    <div css={Css.df.fdc.gap1.$}>
      <div css={Css.df.jcc.aifs.gap1.my2.$}>
        <div css={Css.fg1.df.gap1.$}>
          <BoundScheduleFlagReasonSelects field={formState.reasonId} scheduleFlagReasons={reasons} />
        </div>
        <div>
          <BoundNumberField
            label="Duration"
            labelStyle="above"
            type="days"
            xss={Css.tal.jcfs.$}
            field={formState.durationInDays}
            readOnly={true}
            positiveOnly
            compact
          />
        </div>
        {onClose && (
          <div css={Css.pt3.$}>
            <IconButton icon="x" onClick={onClose} />
          </div>
        )}
      </div>
      <BoundTextField label="Description (Optional)" field={formState.description} labelStyle="above" />
    </div>
  );
}
