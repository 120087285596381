import { Redirect } from "react-router";
import { Stage, useRedirectToActiveStageQuery } from "src/generated/graphql-types";
import { createProjectDashboardUrl } from "src/RouteUrls";
import { queryResult } from "src/utils/queryResult";

type RedirectToActiveStageProps = {
  projectId: string;
  createUrl(stage: Stage): string;
  showPlanning?: boolean;
};

export function RedirectToActiveStage(props: RedirectToActiveStageProps) {
  const { projectId, createUrl, showPlanning } = props;
  return queryResult(useRedirectToActiveStageQuery({ variables: { projectId } }), {
    data: ({ project }) => {
      const { latestActiveStage } = project;

      // If there are no stages associated with the project (edge/bug case, but exists in in dev), then redirect to Dashboard page
      if (!latestActiveStage) {
        // TODO: Enhance to direct to development overview when it exists?
        return <Redirect to={createProjectDashboardUrl(projectId)} />;
      }

      const latestActiveStageCode = latestActiveStage.stage.code;

      // Many of our pages do not have data to show for the planning stage.
      // In this case, if the active stage is planning, then jump ahead return the PreCon stage instead.
      if (!showPlanning && latestActiveStageCode === Stage.PreConPlanning) {
        return <Redirect to={createUrl(Stage.PreConExecution)} />;
      }

      return <Redirect to={createUrl(latestActiveStageCode)} />;
    },
  });
}
