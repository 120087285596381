import { Css } from "@homebound/beam";
import { Maybe, VersionableFragment } from "src/generated/graphql-types";

type ReadyPlanVersionInfo = {
  aggregateActive?: Maybe<VersionableFragment>;
  aggregateDraft?: Maybe<VersionableFragment>;
};

type ReadyPlanVersionHeaderProps = {
  readyPlan: ReadyPlanVersionInfo;
};

export function ReadyPlanVersionHeader({ readyPlan }: ReadyPlanVersionHeaderProps) {
  const draftVersion = readyPlan.aggregateDraft?.version;
  const activeVersion = readyPlan.aggregateActive?.version;
  return (
    (draftVersion || activeVersion) && (
      <span data-testid="version" css={Css.px2.$}>
        {draftVersion ? `Draft of v${draftVersion}` : `v${activeVersion}`}
      </span>
    )
  );
}
