import { Css, IconButton, useTestIds } from "@homebound/beam";
import { Observer } from "mobx-react";
import { ListFieldState, ObjectState } from "src/utils/formState";

type HasIdAndDeleteFields = {
  id?: string | null;
  delete?: boolean | null;
};

export type DeleteLineItemButtonProps<U extends HasIdAndDeleteFields> = {
  list: ListFieldState<U>;
  row: ObjectState<U>;
  allowEmpty?: boolean;
  onClick?: () => void;
  readOnly?: boolean;
};

/**
 * Provides a smart delete button for reactive/mobx-based lists of line items.
 *
 * This component is smart enough to:
 *
 * - Either hard-delete or soft-delete line items based on their new-ness
 * - Disallow removing a fine line item if at least 1 row is required
 * - Not allow deleting when the list is read only
 */
export function DeleteLineItemButton<U extends HasIdAndDeleteFields>(props: DeleteLineItemButtonProps<U>) {
  const { list, row, allowEmpty = false, onClick = () => {}, readOnly } = props;
  const testIds = useTestIds({}, "deleteLiBtn");

  return (
    <Observer>
      {() => {
        const canDelete = allowEmpty || list.rows.filter((li) => !li.delete.value).length > 1;
        const isHidden = list.readOnly || readOnly || !canDelete;
        return (
          <div css={Css.if(isHidden).vh.$}>
            <IconButton
              {...testIds}
              onClick={() => {
                // If the row is new, just remove it all together, otherwise mark for deletion
                if (!row.id || row.id.value === undefined) {
                  list.remove(row.value);
                } else {
                  row.delete.value = true;
                }
                onClick();
              }}
              disabled={isHidden}
              icon="trash"
            />
          </div>
        );
      }}
    </Observer>
  );
}
