import { Button, ButtonDatePicker, Css, OpenRightPaneOpts, useRightPane } from "@homebound/beam";
import { Maybe } from "graphql/jsutils/Maybe";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { useParams } from "react-router";
import {
  DateFilter2,
  InternalUser,
  JobLog,
  JobLogDetailFragment,
  JobLogNoteDetailFragment,
  ScheduleSettingDetailsFragment,
  TaskDetailPaneFragment,
  useCurrentInternalUserQuery,
  useJobLogsForDatePickerQuery,
  useSaveJobLogMutation,
} from "src/generated/graphql-types";

import { JobLogNoteRightPaneContent } from "src/routes/projects/job-logs/JobLogNoteRightPaneContent";
import { jobLogNoteTypes } from "src/routes/projects/job-logs/JobLogsPage";
import { DateOnly, formatMonthDay } from "src/utils/dates";
import { ProjectSchedulePageRouteParams } from "../ProjectSchedulePageV2";
import { getBusinessDayHelpersForSchedule } from "../table/utils";

type JobLogNoteDetailFragmentWithJobId = Maybe<JobLogNoteDetailFragment> & {
  jobLog: { id: string; logDate: Date };
};

type TaskJobLogsProps = {
  jobLogNotes: JobLogNoteDetailFragmentWithJobId[];
  sourceTask: TaskDetailPaneFragment;
  scheduleSetting: Maybe<ScheduleSettingDetailsFragment>;
  onClose?: VoidFunction;
};

export function TaskJobLogs({ jobLogNotes, sourceTask, scheduleSetting, onClose }: TaskJobLogsProps) {
  const { projectId } = useParams<ProjectSchedulePageRouteParams>();
  const { addBusinessDays } = getBusinessDayHelpersForSchedule(scheduleSetting || undefined);

  const dateRange = {
    fromDate: new DateOnly(addBusinessDays(sourceTask.interval?.startDate, -1)),
    toDate: new DateOnly(addBusinessDays(sourceTask.interval?.endDate, 1)),
  };
  const { openRightPane } = useRightPane();

  const [saveJobLog] = useSaveJobLogMutation();
  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const currentUser = currentInternalUserData?.currentInternalUser as InternalUser;
  // Bring back data only with notes or images
  const jobLogHasContent = (jobLog: any) => jobLog.images.length > 0 || jobLog.notes.length > 0;
  const queryForDatePicker = useJobLogsForDatePickerQuery({
    variables: {
      project: projectId,
      startDate: { op: "BETWEEN", value: dateRange.fromDate, value2: dateRange.toDate } as DateFilter2,
    },
  });
  // disable the days which are outer the business days from start and end dates
  const disabledDaysBefore = [{ before: dateRange.fromDate }, () => false];
  const disabledDaysAfter = [{ after: dateRange.toDate }, () => false];
  const disabledDays = [...disabledDaysBefore, ...disabledDaysAfter];
  const daysWithJobLogs = useMemo(
    () => queryForDatePicker.data?.jobLogs?.filter(jobLogHasContent).map((j) => j.logDate),
    [queryForDatePicker.data],
  );

  async function onDateSelect(date: Date) {
    await addJobLog(date, [], projectId, sourceTask, openRightPane, currentUser, saveJobLog, onClose);
  }
  return (
    <Observer>
      {() => (
        <>
          <div css={Css.smMd.my2.$}>
            <span css={Css.df.aic.jcsb.sm.gray700.$}>
              Job Logs
              <ButtonDatePicker
                trigger={{ label: "Add Job Log Note", variant: "tertiary" }}
                value={dateRange.toDate}
                onSelect={onDateSelect}
                disabledDays={disabledDays}
                data-testid="addJobLog"
                dottedDays={daysWithJobLogs}
              />
            </span>
          </div>

          {jobLogNotes?.nonEmpty ? (
            <div data-testid="allocatedJobLogNotes" css={Css.df.fdc.gap1.mb1.$}>
              {jobLogNotes?.map((jobLogNote) => {
                const { title, type, id, jobLog } = jobLogNote;
                return (
                  <div css={Css.df.fdc.br8.bgGray100.tiny.$} key={id}>
                    <div css={Css.df.fdr.jcsb.aic.fg1.m1.$} key={id}>
                      <div>
                        <span data-testid="title" css={Css.fwb.$}>
                          {title ?? jobLogNoteTypes.find(({ value }) => value === type)?.label}
                        </span>
                        <span>
                          {" - "}
                          {formatMonthDay(jobLog.logDate as DateOnly)}
                        </span>
                      </div>
                      <div css={Css.df.fdr.$}>
                        <div css={Css.df.gap2.$}>
                          <Button
                            variant="tertiary"
                            label="View"
                            size="sm"
                            onClick={() =>
                              openRightPane({
                                content: (
                                  <JobLogNoteRightPaneContent
                                    jobLogId={jobLog.id}
                                    currentUser={currentUser}
                                    sourceTask={sourceTask}
                                    jobLogNote={jobLogNote}
                                    customOnClose={onClose}
                                  />
                                ),
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div data-testid="noJobLogNotes" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
              There are no linked job logs
            </div>
          )}
        </>
      )}
    </Observer>
  );
}

async function addJobLog(
  date: Date,
  sortedJobLogs: JobLog[],
  projectId: string,
  sourceTask: TaskDetailPaneFragment,
  openNote: (opts: OpenRightPaneOpts) => void,
  currentUser: InternalUser,
  saveJobLog: (options?: { variables: { input: { projectId: string; logDate: DateOnly } } }) => any,
  onClose?: VoidFunction,
) {
  const datePicked = date.toString();

  let jobLogForDate: JobLogDetailFragment | undefined = sortedJobLogs.find(
    (log: JobLogDetailFragment) => log.logDate.toString() === datePicked,
  );

  // if log for that day does not exist
  if (!jobLogForDate) {
    const { data } = await saveJobLog({
      variables: {
        input: {
          projectId: projectId,
          logDate: new DateOnly(date),
        },
      },
    });
    jobLogForDate = data?.saveJobLog.jobLog;
  }

  if (jobLogForDate) {
    openNote({
      content: (
        <JobLogNoteRightPaneContent
          jobLogId={jobLogForDate.id}
          currentUser={currentUser}
          sourceTask={sourceTask}
          customOnClose={onClose}
        />
      ),
    });
  }
}
