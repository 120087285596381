import { Filters, usePersistedFilter } from "@homebound/beam";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { SingleStageFilter, useProjectStageSingleFilter } from "src/hooks/useProjectStageSingleFilter";
import { useStripStage } from "src/hooks/useStripStage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { HomeownerContractsTable } from "./components/HomeownerContractsTable";

export function HomeownerContractsPage() {
  useStripStage();
  const { projectId } = useParams<ProjectParams>();
  const { clientContract, latestActiveStage } = useProjectContext();
  const { filterDefs } = useProjectStageSingleFilter(latestActiveStage);
  const { setFilter, filter } = usePersistedFilter<SingleStageFilter>({
    storageKey: "changeEventsFilter",
    filterDefs,
  });

  return (
    <Fragment>
      <PageHeader title={`${clientContract}s`} />
      <TableActions>
        <Filters<SingleStageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
      </TableActions>
      {/* Handles loading/error screens when switching between tabs */}
      <HomeownerContractsTable projectId={projectId} filter={filter} />
    </Fragment>
  );
}
