import { BoundSelectField, Css, SelectField, useComputed } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { useCallback, useMemo, useState } from "react";
import {
  ScheduleDraftMode_ScheduleFlagReasonFragment,
  ScheduleFlagReasonDetailFragment,
  ScheduleFlagReasonType,
} from "src/generated/graphql-types";

type ScheduleFlagReasonDetail = ScheduleFlagReasonDetailFragment | ScheduleDraftMode_ScheduleFlagReasonFragment;

type BoundScheduleFlagReasonSelectsProps = {
  field: FieldState<string | null | undefined>;
  scheduleFlagReasons: ScheduleFlagReasonDetail[];
  isDelayFlagPublishStep?: boolean;
};

export function BoundScheduleFlagReasonSelects({
  field,
  scheduleFlagReasons,
  isDelayFlagPublishStep = false,
}: BoundScheduleFlagReasonSelectsProps) {
  const selectedReason = useComputed(
    () => scheduleFlagReasons.find((r) => r.id === field.value),
    [scheduleFlagReasons],
  );
  const [category, setCategory] = useState<ScheduleFlagReasonType | undefined>(
    selectedReason?.scheduleFlagReasonType.code,
  );

  const scheduleFlagReasonTypes = useMemo(
    () =>
      scheduleFlagReasons
        .flatMap((r) => r.scheduleFlagReasonType)
        .uniqueBy((t) => t.code)
        .sortBy((t) => t.name),
    [scheduleFlagReasons],
  );
  const groupedReasons = useMemo(
    () =>
      scheduleFlagReasons
        .sortBy((sfr) => sfr.title)
        .groupBy(({ scheduleFlagReasonType }) => scheduleFlagReasonType.code),
    [scheduleFlagReasons],
  );

  const onCategorySelect = useCallback(
    (val?: ScheduleFlagReasonType) => {
      if (val) {
        setCategory(val);
        field.set(null);
      }
    },
    [field],
  );
  return (
    <>
      <div css={Css.if(isDelayFlagPublishStep).mr2.$}>
        <SelectField
          value={category}
          label="Delay Category"
          getOptionLabel={({ name }) => name}
          getOptionValue={({ code }) => code}
          options={scheduleFlagReasonTypes}
          compact
          labelStyle={isDelayFlagPublishStep ? "hidden" : undefined}
          onSelect={onCategorySelect}
        />
      </div>
      <BoundSelectField
        // only enable reasons if we have a category selected
        disabled={!category}
        field={field}
        label="Delay Reason"
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
        options={groupedReasons[category as ScheduleFlagReasonType] ?? []}
        compact
        labelStyle={isDelayFlagPublishStep ? "hidden" : undefined}
      />
    </>
  );
}
