import { Css, px, useTestIds } from "@homebound/beam";
import { getStage } from "src/context";
import { isStableQaEnvironment } from "src/utils";

export function ContentPreviewWarning() {
  const contentPreviewWarningId = useTestIds({}, "contentPreviewWarning");
  const stage = getStage();

  if (!(stage === "dev" || stage === "qa")) {
    console.error(
      "The ContentPreviewWarning component was instantiated outside the dev/qa environment. This should not happen! Please debug where this component is rendered.",
    );
    return null;
  }

  // The stable QA environment _can_ auth with Cognito (since it has a stable callback URL we've defined in `auth`),
  // so we don't need to show this warning.
  if (isStableQaEnvironment()) return null;

  return (
    <div css={Css.bgRed100.fs0.w100.sticky.z999.h(px(48)).df.aic.mt2.$} {...contentPreviewWarningId}>
      <div css={Css.mx3.$}>
        Amazon Cognito has a limitation that prevents login from Content Preview branches. Please log in to the &nbsp;
        <a href={`https://blueprint.${stage}-homebound.com/signIn`} {...contentPreviewWarningId.link}>
          {stage} environment
        </a>{" "}
        then reload this page.
      </div>
    </div>
  );
}
