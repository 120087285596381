import {
  BoundNumberField,
  BoundTextField,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  simpleDataRows,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo } from "react";
import {
  LotCheckFormDrawer_ProjectSiteSpecificInfoFragment,
  SaveProjectSiteSpecificInfoInput,
  useLotCheckTableMetadataQuery,
  useSaveProjectSiteSpecificInfoMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type LotCheckTableProps = {
  projectId: string;
  projectSiteSpecificInfos: LotCheckFormDrawer_ProjectSiteSpecificInfoFragment[];
};

export function LotCheckTable({ projectSiteSpecificInfos, projectId }: LotCheckTableProps) {
  const metadataQuery = useLotCheckTableMetadataQuery();

  return queryResult(metadataQuery, {
    data: (data) => (
      <LotCheckTableView
        projectId={projectId}
        siteSpecificInfoMetadata={data.siteSpecificInfos}
        projectSiteSpecificInfos={projectSiteSpecificInfos}
      />
    ),
  });
}

type LotCheckTableViewProps = {
  projectId: string;
  projectSiteSpecificInfos: LotCheckFormDrawer_ProjectSiteSpecificInfoFragment[];
  siteSpecificInfoMetadata: { id: string; description: string }[];
};

function LotCheckTableView(props: LotCheckTableViewProps) {
  const { projectSiteSpecificInfos, siteSpecificInfoMetadata, projectId } = props;
  const [saveSiteSpecificInfo] = useSaveProjectSiteSpecificInfoMutation();
  const rows = useMemo(
    () => createRows(siteSpecificInfoMetadata, projectSiteSpecificInfos),
    [siteSpecificInfoMetadata, projectSiteSpecificInfos],
  );

  const { getFormState } = useFormStates<
    SaveProjectSiteSpecificInfoInput,
    LotCheckFormDrawer_ProjectSiteSpecificInfoFragment
  >({
    config: formConfig,
    map: (ssi) => ({
      projectId,
      siteSpecificInfoId: ssi.siteSpecificInfo?.id,
      notes: ssi.notes,
      perSiteBudgetInCents: ssi.perSiteBudgetInCents,
    }),
    getId: (v) => v.id,
    autoSave: async ({ value }) => {
      await saveSiteSpecificInfo({
        variables: {
          // Using a findOrCreate on the BE to lazy create or update values
          input: value,
        },
      });
    },
  });

  return <GridTable columns={createColumns(getFormState)} rows={rows} />;
}

const formConfig: ObjectConfig<SaveProjectSiteSpecificInfoInput> = {
  projectId: { type: "value" },
  siteSpecificInfoId: { type: "value" },
  perSiteBudgetInCents: { type: "value" },
  notes: { type: "value" },
};

type HeaderRow = { kind: "header" };
type ItemRow = { kind: "data"; id: string; data: any };
type Row = HeaderRow | ItemRow;

function createColumns(
  getFormState: (
    input: LotCheckFormDrawer_ProjectSiteSpecificInfoFragment,
  ) => ObjectState<SaveProjectSiteSpecificInfoInput>,
): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Description",
      data: (row) => row.siteSpecificInfo.description,
    }),
    numericColumn<Row>({
      header: "Per Site Budget",
      data: (row) => {
        const os = getFormState(row);
        return <BoundNumberField field={os.perSiteBudgetInCents} type="cents" />;
      },
      w: "200px",
    }),
    column<Row>({
      header: "Notes",
      data: (row) => {
        const os = getFormState(row);
        return <BoundTextField field={os.notes} />;
      },
    }),
  ];
}

function createRows(
  siteSpecificiInfos: { id: string; description: string }[],
  projectInfos: LotCheckFormDrawer_ProjectSiteSpecificInfoFragment[],
): GridDataRow<Row>[] {
  /**
   * We're currently lazy creating the project SSIs on the BE
   * so we load all instances of the site_specific_infos table to populate the table rows
   * and map the current added values for the project to the matching SSI
   */
  const ssiMappings = siteSpecificiInfos.map((ssi) => {
    const matchingInfo = projectInfos.find((ci) => ssi.id === ci.siteSpecificInfo.id);
    return {
      id: ssi.id,
      siteSpecificInfo: { ...ssi },
      perSiteBudgetInCents: matchingInfo?.perSiteBudgetInCents,
      notes: matchingInfo?.notes,
    };
  });

  return simpleDataRows(ssiMappings);
}
