import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  Button,
  Checkbox,
  Chip,
  Css,
  Palette,
  Tooltip,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { BannerNotice, Icon } from "src/components";
import {
  LotDetailDrawerEnums_AllEnumDetailFragment,
  ProgramDataMatchState,
  ProjectReadyPlanConfigOrientation,
  ReadyPlanOption,
  useSaveProjectReadyPlanConfigMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { extractPoMessages } from "src/utils";
import { REASONS_MAP, evaluateOptionDependencies } from "src/utils/optionDependencies";
import { FormInput, LotDetailDrawerMetadata, ProgramDataDerivedKey, ProgramDataKey, StatusChip } from "../utils";

type PlanInformationTabProps = {
  formState: ObjectState<FormInput>;
  metadata: LotDetailDrawerMetadata;
  readOnly: boolean;
  activeEditMode: () => void;
  programDataMatchState: ProgramDataMatchState;
  isHBL?: boolean;
  disableEdit: boolean;
};

export function PlanInformationTab(props: PlanInformationTabProps) {
  const { formState, metadata, readOnly, activeEditMode, programDataMatchState, isHBL, disableEdit } = props;
  const [savePRPC] = useSaveProjectReadyPlanConfigMutation();
  const { openModal } = useModal();
  const hasPendingDeviations = programDataMatchState === ProgramDataMatchState.DeviationPendingReview;
  const hasAcknowledgedDeviations = programDataMatchState === ProgramDataMatchState.DeviationAcknowledged;

  // get disabled options due to option dependencies
  const { disabledOptions } = useMemo(() => {
    const specOption = metadata.specOptions.find(({ id }) => id === formState.specOptionId.value);
    const elevationOption = metadata.elevationOptions.find(({ id }) => id === formState.elevationOptionId.value);
    const exteriorSchemeOption = metadata.exteriorSchemeOptions.find(
      ({ id }) => id === formState.exteriorPaletteOptionId.value,
    );
    const options = formState.options.value.map(({ id: optionId }) =>
      metadata.otherOptions.find(({ id }) => id === optionId),
    );

    const selectedOptions = [...options, specOption, elevationOption, exteriorSchemeOption];

    return evaluateOptionDependencies(
      selectedOptions as unknown as ReadyPlanOption[],
      options as unknown as ReadyPlanOption[],
    );
  }, [formState, metadata]);

  const onDismissDeviations = () => {
    openModal({
      content: (
        <ConfirmationModal
          title="Are you sure you want to dismiss suggested changes?"
          confirmationMessage="These are based off of the configured plans and options. If you don't accept the changes, the plan might be incorrect."
          label="Dismiss"
          onConfirmAction={() =>
            savePRPC({
              variables: {
                input: {
                  projectReadyPlanConfigs: [
                    {
                      id: props.formState.id.value,
                      programDataMatchState: ProgramDataMatchState.DeviationAcknowledged,
                    },
                  ],
                },
              },
            })
          }
        />
      ),
    });
  };

  const onAcceptAllSuggestions = () => {
    openModal({
      content: (
        <ConfirmationModal
          title="Are you sure you want to accept all suggested changes?"
          confirmationMessage="These are based off of the configured plans and options. If you accept the changes, all previous values will be lost."
          label="Accept Suggestions"
          onConfirmAction={async () => {
            const computedValueEntries = Object.entries(formState.computedProgramData.value);
            for (const [field, value] of computedValueEntries) {
              const isEnumValue = !!value && typeof value === "object" && "code" in value;
              const computed = isEnumValue ? value.code : (value as any);
              formState.programData[field as ProgramDataKey | ProgramDataDerivedKey]?.set(computed);
            }
          }}
        />
      ),
    });
  };

  const specOptions = (metadata?.specOptions ?? []).sortBy(({ id }) =>
    disabledOptions.some(({ option }) => option.id === id) ? 1 : 0,
  );
  const elevationOptions = (metadata?.elevationOptions ?? []).sortBy(({ id }) =>
    disabledOptions.some(({ option }) => option.id === id) ? 1 : 0,
  );
  const exteriorSchemeOptions = (metadata?.exteriorSchemeOptions ?? []).sortBy(({ id }) =>
    disabledOptions.some(({ option }) => option.id === id) ? 1 : 0,
  );

  return (
    <>
      {!readOnly && (
        <BannerNotice
          variant="form"
          message="Changing the plan will require you to choose new options"
          icon="infoCircle"
        />
      )}
      {readOnly && !metadata.canEdit?.allowed && (
        <BannerNotice
          variant="form"
          message={
            <div css={Css.df.fdr.aic.gap2.$}>
              <Icon icon="error" color={Palette.Red600} pxSize={32} />
              {extractPoMessages(metadata.canEdit)}
            </div>
          }
        />
      )}
      {readOnly && !disableEdit && hasPendingDeviations && (
        <BannerNotice
          variant="form"
          message={
            <span>
              There are suggested computed changes to several fields below based on configured plans and options. Accept
              changes in <Button variant="text" label="Edit Configuration" onClick={activeEditMode} />.
            </span>
          }
          right={
            <div css={Css.df.aic.$}>
              <Button variant="textSecondary" label="Dismiss" onClick={onDismissDeviations} />
            </div>
          }
          icon={{ icon: "error", color: Palette.Red600, inc: 8 }}
        />
      )}
      {readOnly && !disableEdit && hasAcknowledgedDeviations && (
        <BannerNotice
          variant="form"
          message="This plan has manually entered fields that differ from the configured plan and option data."
          right={
            <div css={Css.df.aic.$}>
              <Button variant="text" label="View" onClick={() => (formState.readOnly = false)} />
            </div>
          }
          icon={{ icon: "infoCircle", inc: 5 }}
        />
      )}
      <div css={Css.df.aic.jcsb.mb2.$}>
        <div css={Css.gray900.baseBd.$}>Plan Information</div>
        {!disableEdit && (
          <div css={Css.df.h4.$}>
            {readOnly ? (
              <Button variant="tertiary" label="Edit Configuration" onClick={activeEditMode} />
            ) : (
              (hasPendingDeviations || hasAcknowledgedDeviations) && (
                <Button variant="tertiary" label="Accept All Suggestions" onClick={onAcceptAllSuggestions} />
              )
            )}
          </div>
        )}
      </div>
      <div css={Css.dg.gap2.gtc("repeat(1, 45% auto)").aic.if(!readOnly).mt3.$}>
        <RowField
          labelOnTop={!readOnly}
          label={
            <div>
              <span css={Css.df.gap1.$}>
                ReadyPlan{!readOnly && formState.readyPlanId.required && " *"}{" "}
                {readOnly && <StatusChip updatedAt={metadata.updatedFields.readyPlan} />}
              </span>
            </div>
          }
        >
          <BoundSelectField
            required
            compact
            label="ReadyPlan"
            labelStyle="hidden"
            field={formState.readyPlanId}
            options={metadata?.readyPlans ?? []}
            getOptionLabel={(o) => `${o?.displayCode ? `${o.displayCode} - ` : ""}${o?.name}`}
            getOptionValue={(o) => o?.id}
          />
        </RowField>

        <RowField
          labelOnTop={!readOnly}
          label={
            <>
              Spec level{!readOnly && formState.specOptionId.required && " *"}{" "}
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.specOption} />}
            </>
          }
        >
          <BoundSelectField
            required
            compact
            label="Spec level"
            labelStyle="hidden"
            field={formState.specOptionId}
            options={specOptions}
            disabledOptions={disabledOptions.map(({ option, reasons }) => ({
              value: option.id,
              reason: REASONS_MAP[reasons[0].type](reasons[0].option.name),
            }))}
          />
        </RowField>

        <RowField
          labelOnTop={!readOnly}
          label={
            <>
              Elevation{!readOnly && formState.elevationOptionId.required && " *"}{" "}
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.elevationOption} />}
            </>
          }
        >
          {readOnly && !formState.elevationOptionId.value ? (
            <div css={Css.sm.gray400.$}>Not configured</div>
          ) : (
            <>
              <BoundSelectField
                required
                compact
                label="Elevation"
                labelStyle="hidden"
                field={formState.elevationOptionId}
                options={elevationOptions}
                getOptionLabel={(o) =>
                  `${o.code ? `${o.code} - ` : ""}${o?.name}${o.shortName ? ` (${o.shortName})` : ""}`
                }
                getOptionValue={(o) => o.id}
                disabledOptions={disabledOptions.map(({ option, reasons }) => ({
                  value: option.id,
                  reason: REASONS_MAP[reasons[0].type](reasons[0].option.name),
                }))}
                placeholder="Pick an elevation"
                disabled={!formState.readyPlanId.value && "Please pick a ready plan"}
              />
            </>
          )}
        </RowField>

        <RowField
          labelOnTop={!readOnly}
          label={
            <>
              Exterior scheme{!readOnly && formState.exteriorPaletteOptionId.required && " *"}{" "}
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.exteriorPaletteOption} />}
            </>
          }
        >
          {readOnly && !formState.exteriorPaletteOptionId.value ? (
            <div css={Css.sm.gray400.$}>Not configured</div>
          ) : (
            <>
              <BoundSelectField
                required
                compact
                label="Exterior scheme"
                labelStyle="hidden"
                field={formState.exteriorPaletteOptionId}
                options={exteriorSchemeOptions}
                getOptionLabel={(o) => `${o?.code ? `${o.code} - ` : ""}${o?.name}`}
                getOptionValue={(o) => o?.id}
                disabledOptions={disabledOptions.map(({ option, reasons }) => ({
                  value: option.id,
                  reason: REASONS_MAP[reasons[0].type](reasons[0].option.name),
                }))}
                placeholder="Pick an exterior scheme"
                disabled={!formState.elevationOptionId.value && "Please pick an elevation option"}
              />
            </>
          )}
        </RowField>

        <RowField
          labelOnTop={!readOnly}
          label={
            <div>
              <span css={Css.df.gap1.$}>
                Orientation{!readOnly && " *"}{" "}
                {readOnly && <StatusChip updatedAt={metadata.updatedFields.orientation} />}
              </span>
            </div>
          }
        >
          <BoundSelectField
            required
            compact
            label="Orientation"
            labelStyle="hidden"
            field={formState.orientation}
            options={[
              { id: ProjectReadyPlanConfigOrientation.Left, name: "Left" },
              { id: ProjectReadyPlanConfigOrientation.Right, name: "Right" },
            ]}
          />
        </RowField>

        {Object.entries(PD_FIELDS).map(([f, { label, enumName, derived }]) => {
          const field = f as PdKey;
          return (
            <Fragment key={field}>
              <RowField
                label={
                  <>
                    {label}{" "}
                    {derived && !readOnly && (
                      <Tooltip
                        placement="top"
                        title="This field is derived from other fields on the program data, it will update after you save your changes."
                      >
                        <Icon icon="infoCircle" pxSize={18} color={Palette.Gray600} />
                      </Tooltip>
                    )}
                    {readOnly && (
                      <StatusChip updatedAt={metadata.updatedFields[field] ?? metadata.updatedFields[field]} />
                    )}
                  </>
                }
              >
                {enumName ? (
                  <BoundSelectField
                    compact
                    label={label}
                    labelStyle="hidden"
                    field={formState.programData[field] as any}
                    options={getEnumDropdownValue(metadata.enumDetails, enumName)}
                  />
                ) : (
                  <BoundNumberField
                    compact
                    label={label}
                    labelStyle="hidden"
                    readOnly={readOnly || derived}
                    field={formState.programData[field] as any}
                  />
                )}
              </RowField>
              {((hasPendingDeviations && !derived) || (hasAcknowledgedDeviations && !readOnly && !derived)) &&
                !isHBL && (
                  <ComputedValue
                    label={label}
                    fieldName={field}
                    formState={formState}
                    enumDetails={metadata.enumDetails}
                    enumType={enumName}
                  />
                )}
            </Fragment>
          );
        })}

        <RowField
          labelOnTop={!readOnly}
          label={<>Internal notes {readOnly && <StatusChip updatedAt={metadata.updatedFields.internalNote} />}</>}
        >
          {readOnly && !formState.internalNote.value ? (
            <div css={Css.sm.gray400.$}>None</div>
          ) : (
            <BoundTextAreaField
              compact
              label="Internal notes"
              labelStyle="hidden"
              readOnly={readOnly}
              field={formState.internalNote}
            />
          )}
        </RowField>
      </div>
    </>
  );
}

function getEnumDropdownValue(enumDetails: LotDetailDrawerEnums_AllEnumDetailFragment | undefined, enumName: EnumName) {
  if (!enumDetails) return [];

  return (
    enumDetails[enumName].map((enumValue) => ({
      id: enumValue.code,
      name: enumValue.name,
    })) ?? []
  );
}

export function RowField({
  label,
  children,
  labelOnTop,
  separator,
}: {
  label: ReactNode;
  children: ReactNode;
  labelOnTop?: boolean;
  separator?: boolean;
}) {
  return (
    <>
      <div css={Css.smMd.gray900.if(!!labelOnTop).h100.if(!!separator).mt3.$}>{label}</div>
      <div css={Css.if(!!separator).mt3.$}>{children}</div>
    </>
  );
}

type PdKey = ProgramDataKey | ProgramDataDerivedKey;
type EnumName = Exclude<keyof LotDetailDrawerEnums_AllEnumDetailFragment, "__typename">;
type PdFieldsType = Partial<Record<PdKey, { label: string; enumName?: EnumName; derived?: boolean }>>;

/**
 * The list of program data fields: User facing label, their key at the program data object,
 * and optionally the name of their enum detail to populate dropdown options
 */
const PD_FIELDS: PdFieldsType = {
  sellableSqft: { label: "Sellable SF", derived: true },
  netBuildableSqft: { label: "Net Buildable SF" },
  permittableSqft: { label: "Permitable SF" },
  grossBuildableSqft: { label: "Gross Buildable SF" },
  sellableAboveGroundSqft: { label: "Sellable Above Ground SF" },
  grossBelowGroundSqft: { label: "Gross Below Ground SF" },
  sellableBelowGroundSqft: { label: "Sellable Below Ground SF" },
  unfinishedBelowGroundSqft: { label: "Unfinished Below Ground SF", derived: true },
  imperviousSqft: { label: "Impervious SF" },
  stories: { label: "Number of Stories" },
  bedrooms: { label: "Bedrooms" },
  firstFloorBedrooms: { label: "First Floor Bedrooms" },
  primaryBedroom: { label: "Primary Bedroom", enumName: "programDataPrimaryBedroomConfig" },
  closetsInPrimarySuite: { label: "Closets in Primary Suite" },
  fullBaths: { label: "Full Baths" },
  halfBaths: { label: "Half Baths" },
  diningRoom: { label: "Formal Dining" },
  casualDining: { label: "Casual Dining" },
  mediaRooms: { label: "Media Rooms" },
  loftGameFlexRooms: { label: "Lofts/Game Rooms/Flex Rooms" },
  offices: { label: "Offices" },
  workspaces: { label: "Workspaces" },
  basementConfig: { label: "Basement", enumName: "programDataBasementConfig" },
  garageAttached: { label: "Attached Garage" },
  garageDetached: { label: "Detached Garage" },
  garagePort: { label: "Carport" },
  garageConfiguration: { label: "Garage Load", enumName: "programDataGarageConfig" },
  windowColor: { label: "Window Color", enumName: "programDataWindowColor" },
  depthInFeet: { label: "Plan Depth FT" },
  widthInFeet: { label: "Plan Width FT" },
  minLotSizeInSqft: { label: "Lot Min Size SF" },
  minLotDepthInFeet: { label: "Lot Min Depth FT" },
  minLotWidthInFeet: { label: "Lot Min Width FT" },
};

export function ComputedValue(props: {
  label: string;
  fieldName: ProgramDataKey | ProgramDataDerivedKey;
  formState: ObjectState<FormInput>;
  enumDetails: LotDetailDrawerMetadata["enumDetails"];
  enumType?: EnumName;
}) {
  const { label, formState, fieldName, enumDetails, enumType } = props;
  const { value, computedValue, readOnly } = useComputed(
    () => ({
      value: formState.programData[fieldName].value,
      computedValue: formState.computedProgramData[fieldName].value,
      readOnly: formState.readOnly,
    }),
    [fieldName, formState],
  );
  const [originalValue] = useState(value);
  const isEnumValue = !!computedValue && enumType && typeof computedValue === "object" && "code" in computedValue;
  const match = value === (isEnumValue ? computedValue.code : computedValue) || value == computedValue; // == to match "null" and "undefined"

  if ((readOnly && match) || (match && originalValue == (isEnumValue ? computedValue.code : computedValue)))
    return <></>;

  const getEnumName = () => {
    if (!enumType || !enumDetails || !isEnumValue) return;
    return enumDetails[enumType].find((ed) => ed.code === computedValue.code)?.name;
  };

  const onSuggestionCheckChange = (selected: boolean) => {
    const computed = isEnumValue ? computedValue.code : (computedValue as any);
    formState.programData[fieldName].set(selected ? computed : originalValue);
  };

  return (
    <BannerNotice
      variant="field"
      icon={
        match
          ? undefined
          : {
              icon: "error",
              color: Palette.Red600,
              tooltip: "This field contains program data that diverges from our computed suggested values.",
            }
      }
      message={label}
      right={
        <div css={Css.df.aic.jcsb.if(!readOnly).pl1.$}>
          <span>{(isEnumValue ? getEnumName() : (computedValue as number)) ?? ""}</span>
          <span css={Css.df.aic.gap1.$}>
            <Chip text={match ? "Match" : "Suggested"} type={match ? "success" : "warning"} />
            {!readOnly && <Checkbox label="" checkboxOnly selected={match} onChange={onSuggestionCheckChange} />}
          </span>
        </div>
      }
    />
  );
}
