import { Css } from "@homebound/beam";
import { OverviewTab_ProductFragment } from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "../ProductImageViewer";
export type ProductImagesSliderProps = {
  product: OverviewTab_ProductFragment;
  setImageUrl: (imageUrl: string) => void;
};

export function ProductImagesSlider(props: ProductImagesSliderProps) {
  const { product, setImageUrl } = props;

  if (product.images?.isEmpty) return null;

  return (
    <div css={Css.df.fdc.wPx(130).maxhPx(450).oa.$}>
      {product.images.map((image, index) => (
        <div
          data-testid="productImage"
          key={`product-img-container-${index}`}
          css={Css.mb2.w100.cursorPointer.$}
          onClick={() => {
            setImageUrl(image.downloadUrl);
          }}
        >
          <img
            key={`product-img-${index}`}
            css={Css.ma.mb2.maxw100.ha.$}
            src={image.downloadUrl}
            alt={"Product Image"}
            onError={(event) => {
              const target = event.target as HTMLImageElement;
              target.src = PRODUCT_FALLBACK_IMG_URL;
              target.onerror = null;
            }}
          />
        </div>
      ))}
    </div>
  );
}
