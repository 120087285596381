import { Button, ButtonGroup, Css, HbLoadingSpinner, MultiSelectField, TreeSelectField } from "@homebound/beam";
import { useContext } from "react";
import { createDesignCatalogUrl } from "src/RouteUrls";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DesignPackageCompareModeContext, DesignPackageCompareModeProvider } from "./DesignPackageCompareModeContext";
import { CompareColumn } from "./components/CompareColumn";

export function DesignPackageCompareModePage() {
  return (
    <DesignPackageCompareModeProvider>
      <DesignPackageCompareModeView />;
    </DesignPackageCompareModeProvider>
  );
}

export enum ColumnView {
  Single = "1",
  Grid = "3",
}

export function DesignPackageCompareModeView() {
  const { columns, addColumn, updateFilter, filter, designPackage, locations, costCodes, loading } = useContext(
    DesignPackageCompareModeContext,
  );

  const columnView = filter.view ?? ColumnView.Single;

  const breadCrumb = [{ href: createDesignCatalogUrl(), label: "Design Packages" }];
  if (designPackage.code) breadCrumb.push({ href: "", label: designPackage.code });

  return (
    <>
      <PageHeader backButton={createDesignCatalogUrl(designPackage.id)} title="Compare Mode" breadcrumb={breadCrumb} />
      <div>
        <div css={Css.df.jcsb.$}>
          <div>
            Configure your columns by selecting cost codes, rooms, and options to see your products side-by-side.
          </div>
          <div css={Css.df.gap2.$}>
            <TreeSelectField
              label="Locations"
              labelStyle="inline"
              onSelect={(val) => updateFilter("locationInPath", val.root.values)}
              options={locations}
              values={filter.locationInPath}
            />
            <MultiSelectField
              compact
              labelStyle="inline"
              label="Cost Codes"
              options={costCodes}
              getOptionValue={(o) => o.id}
              getOptionLabel={(o) => o.name}
              onSelect={(costCodes) => updateFilter("costCodes", costCodes)}
              values={filter.costCodes ?? []}
            />
            <div>
              <ButtonGroup
                buttons={[
                  {
                    icon: "verticalDots",
                    active: columnView === ColumnView.Single,
                    onClick: () => updateFilter("view", ColumnView.Single),
                  },
                  {
                    icon: "drag",
                    active: columnView === ColumnView.Grid,
                    onClick: () => updateFilter("view", ColumnView.Grid),
                  },
                ]}
              />
            </div>
            <Button variant="secondary" label="Add Column" onClick={addColumn} icon="plus" />
          </div>
        </div>

        {/* Centering the columns prevents horizontal scrolling to the start of the list. 
        As columns are added, the total width increases, cutting off the leftmost column.
        To fix this, conditionally set `justify-content` to `flex-start`, 
        making the entire width scrollable and allowing access to the first column. */}
        {loading ? (
          <HbLoadingSpinner />
        ) : (
          <div css={Css.overflowX("auto").df.fdr.gap2.p4.jcc.if(canResetAlignment(columns.length, columnView)).jcfs.$}>
            {columns.map((column, i) => (
              <CompareColumn key={column.join() || Math.random()} columnIndex={i} numGridColumns={columnView} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function canResetAlignment(numberOfColumns: number, columnView: ColumnView) {
  const { innerWidth } = window;
  // Default width for grid column view
  let columnWidth = 724;
  if (columnView === ColumnView.Single) {
    // Single columns start at a width of 480px, but once they fill the screen,
    // we want to limit their width to 345px to accommodate more columns.
    columnWidth = numberOfColumns * 480 > innerWidth ? 345 : 480;
  }
  return columnWidth * numberOfColumns > innerWidth;
}
