import { LgQuery } from "lightgallery/lgQuery";
import { LightGallery } from "lightgallery/lightgallery";

// This plugin is a hack to get the thumbnail to show up for HTML5 videos.
// Requires the lg-thumbnail plugin to be loaded first.
export default class HTML5VideoThumbnail {
  core: LightGallery;
  private $LG: LgQuery;
  constructor(instance: LightGallery, $LG: LgQuery) {
    this.core = instance;
    this.$LG = $LG;
  }

  init() {
    this.core.LGel.on("lgBeforeOpen", this.onBeforeOpen.bind(this));
  }

  onBeforeOpen() {
    const thumbnails: Element[] = [];
    // Find the container that holds the thumbnails and is visible
    const visibleContainer = this.$LG(".lg-container.lg-show").get();
    this.core.galleryItems.forEach((item, i) => {
      if ("video" in item && item.video) {
        const thumbnail = visibleContainer.querySelector(`.lg-thumb-item[data-lg-item-id="${i}"]`)!;
        thumbnails.push(thumbnail);
      }
    });

    // Replace the <img> tag with the <video> tag
    thumbnails.forEach((thumbnail) => {
      // Check if the thumbnail already has a video tag
      if (thumbnail.querySelector("video")) {
        return;
      }

      const itemIndex = parseInt(thumbnail.getAttribute("data-lg-item-id")!);
      const item = this.core.galleryItems[itemIndex];
      const img = thumbnail.querySelector("img");
      // Remove the <img> tag
      img?.remove();
      // Add the <video> tag inside the thumbnail
      const video = document.createElement("video");
      video.src = item.downloadUrl as string;
      video.controls = false;
      video.muted = true;
      // add the item index to the video tag
      video.setAttribute("data-lg-item-id", itemIndex.toString());

      // Set a custom attribute to the thumbnail so we can find it later
      thumbnail.setAttribute("data-lg-html5-video-thumbnail", "true");

      // Change the video tag width and height to match the thumbnail
      const thumbnailWidth = thumbnail.clientWidth;
      const thumbnailHeight = thumbnail.clientHeight;
      // Add them to the video tag style
      video.style.width = `${thumbnailWidth}px`;
      video.style.height = `${thumbnailHeight}px`;
      thumbnail.appendChild(video);
    });
  }

  destroy() {
    // Unbind all events added by the plugin
    this.core.LGel.off("lgBeforeOpen");
  }
}
