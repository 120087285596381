import {
  Button,
  Css,
  FieldGroup,
  Filters,
  multiFilter,
  singleFilter,
  StaticField,
  useGroupBy,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import omit from "lodash/omit";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { emptyCellDash } from "src/components";
import {
  DcLineItemsTabCommitmentLineItemFragment,
  DevelopmentCommitmentLineItemsQuery,
  Stage,
  useDeleteCommitmentLineItemsMutation,
  useDevelopmentCommitmentLineItemsQuery,
} from "src/generated/graphql-types";
import {
  centsToDollars,
  costTypeToNameMapper,
  formatNumberToString,
  queryResult,
  safeEntries,
  selectionStatusToNameMapper,
} from "src/utils";
import { AddItemsModal } from "./AddItemsModal";
import { LineItemsTable, LineItemsTableFilter } from "./LineItemsTable";
import { LineItemStore } from "./models/LineItemStore";

export function LineItemsTab({ developmentCommitmentId }: { developmentCommitmentId: string }) {
  const { openModal } = useModal();
  const query = useDevelopmentCommitmentLineItemsQuery({ variables: { developmentCommitmentId } });
  const [deleteCommitmentLineItems] = useDeleteCommitmentLineItemsMutation();
  const { data, refetch } = query;

  enum GroupByKeys {
    costCode = "costCode",
    project = "project",
  }

  const filterGroupBy = useGroupBy<GroupByKeys>({
    costCode: "Cost Code",
    project: "Project",
  });

  const filterDefs = useMemo(() => createFilterDefs(data?.developmentCommitment), [data?.developmentCommitment]);
  const { setFilter, filter } = usePersistedFilter<LineItemsTableFilter>({
    storageKey: "devCommitmentLineItems",
    filterDefs,
  });

  const store = useMemo(() => {
    const lineItems =
      data?.developmentCommitment.commitments.flatMap((commitment) =>
        commitment.lineItems.map((li) => ({
          commitmentId: commitment.id,
          ...li,
        })),
      ) || [];
    return new LineItemStore(lineItems, filterGroupBy.value);
  }, [data?.developmentCommitment, filterGroupBy.value]);

  return queryResult(query, {
    data: ({ developmentCommitment: dc }) => {
      const totalCommitted = dc.committedInCents
        ? `$${formatNumberToString(centsToDollars(dc.committedInCents))}`
        : emptyCellDash;

      return (
        <>
          <div css={Css.mb3.$}>
            <FieldGroup widths={["183px", "183px"]}>
              <StaticField label="Total Committed" value={totalCommitted} />
              <StaticField label="Projects" value={dc.projects.length.toString()} />
            </FieldGroup>
          </div>
          <div css={Css.df.gap3.pb1.z5.jcsb.mb2.$}>
            <Filters groupBy={filterGroupBy} filterDefs={filterDefs} filter={filter} onChange={setFilter} />
            {dc.canBeEdited && (
              <div>
                <span css={Css.mr2.$}>
                  <Observer>
                    {() => (
                      <Button
                        disabled={!store.selectedLineItems.length}
                        variant="text"
                        label="Remove"
                        onClick={async () => {
                          if (!!store.selectedLineItems.length) {
                            await deleteCommitmentLineItems({
                              variables: {
                                input: {
                                  lineItemIds: store.selectedLineItems.map(({ id }) => id),
                                },
                              },
                            });
                            await refetch();
                          }
                        }}
                      />
                    )}
                  </Observer>
                </span>
                <Button
                  variant="secondary"
                  label="Add Items"
                  onClick={() =>
                    openModal({
                      content: <AddItemsModal developmentCommitmentId={dc.id} stage={dc.stage} onAddItems={refetch} />,
                      size: "xl",
                    })
                  }
                />
              </div>
            )}
          </div>
          <div>
            <LineItemsTable store={store} filter={filter} readOnly={!dc.canBeEdited} />
          </div>
        </>
      );
    },
  });
}

type Location = NonNullable<DcLineItemsTabCommitmentLineItemFragment["projectItem"]["location"]>;
type CostCode = DcLineItemsTabCommitmentLineItemFragment["costCode"];

function createFilterDefs(dc?: DevelopmentCommitmentLineItemsQuery["developmentCommitment"]) {
  let locations: Location[] = [];
  let costCodes: CostCode[] = [];
  if (dc) {
    const lineItems = dc.commitments.flatMap((commitment) => commitment.lineItems);
    const allLocations = lineItems.map(({ projectItem }) => projectItem.location).filter((l) => !!l);
    const allCostCodes = lineItems.map(({ costCode }) => costCode);

    locations = [...new Map(allLocations.map((location) => [location!.id, location as Location])).values()];
    costCodes = [...new Map(allCostCodes.map((costCode) => [costCode!.id, costCode])).values()];
  }

  return {
    // The precon stage doesn't have selections, so hide `View: Selections`
    ...(dc?.stage === Stage.Construction
      ? {
          view: singleFilter({
            options: [{ id: "selections" as const, name: "Selections" }],
            getOptionValue: (o) => o.id,
            getOptionLabel: (o) => o.name,
          }),
        }
      : {}),
    ...(!!locations.length
      ? {
          locationId: multiFilter({
            options: locations,
            getOptionValue: (location) => location.id,
            getOptionLabel: (location) => location.name,
          }),
        }
      : {}),
    costCode: multiFilter({
      options: costCodes,
      getOptionValue: (costCode) => costCode.id,
      getOptionLabel: (costCode) => costCode.displayName,
    }),
    costType: multiFilter({
      options: safeEntries(costTypeToNameMapper),
      getOptionValue: ([code]) => code,
      getOptionLabel: ([_, name]) => name,
    }),
    selectionStatus: multiFilter({
      options: safeEntries(omit(selectionStatusToNameMapper, "DECLINED")),
      getOptionValue: ([code]) => code,
      getOptionLabel: ([_, name]) => name,
    }),
  };
}
