import { Button, Css, TextField, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createConstraintItemCatalogUrl } from "src/RouteUrls";
import { useSaveGlobalPlanConstraintItemMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize } from "src/utils";
import {
  SaveAdditionalMilestoneCardFormValue,
  SaveGlobalPlanMilestoneFormValue,
} from "../../milestone-catalog/MilestoneCatalogForm";
import { SaveGlobalPlanTaskFormValue } from "./TaskCatalogForm";

type AddConstraintItemModalProps = {
  constraintType: ConstraintModalConfig;
  formState?:
    | ObjectState<SaveGlobalPlanTaskFormValue>
    | ObjectState<SaveGlobalPlanMilestoneFormValue>
    | ObjectState<SaveAdditionalMilestoneCardFormValue>;
};

export enum ConstraintModalConfig {
  Allowance,
  Constraint,
  ConstraintItem,
}
export type ConstraintTypeFormKey = "constraints" | "allowances";
// creating separatate keys as typescript was not recognizing these values as strings
const constraintKey: ConstraintTypeFormKey = "constraints";
const allowanceKey: ConstraintTypeFormKey = "allowances";

type ConstraintItem = {
  header: string;
  title: string;
  body: string;
  formKey: ConstraintTypeFormKey | undefined;
};

export function AddConstraintItemModal({ constraintType, formState }: AddConstraintItemModalProps) {
  const [saveGlobalPlanConstraintItem] = useSaveGlobalPlanConstraintItemMutation({});
  const [name, setName] = useState<string | undefined>("");
  const { closeModal } = useModal();
  const history = useHistory();

  const { header, title, body, formKey }: ConstraintItem = {
    [ConstraintModalConfig.Constraint]: {
      header: "Add a Constraint",
      title: "Constraint",
      body: `What “buffer” must have occurred before this task can start? Prior to starting this task the following must be
    done.`,
      formKey: constraintKey,
    },
    [ConstraintModalConfig.Allowance]: {
      header: "Add an Allowance",
      title: "Allowance",
      body: "What buffer is triggered on completion of this task.",
      formKey: allowanceKey,
    },
    // Creating a "generic" constraint item config - not associated to an allowance or constraint yet
    [ConstraintModalConfig.ConstraintItem]: {
      header: "Add a Constraint",
      title: "Constraint",
      body: "",
      formKey: undefined,
    },
  }[constraintType];

  const currentConstraintItem = useMemo(() => {
    // type-narrowing as the formstate union was throwing an error
    if (formState && isTaskFormState(formState)) {
      return (formKey && formState && formState[formKey].value) ?? [];
    } else if (formState && isMilestoneFormState(formState)) {
      return formState.value.constraints ?? [];
    } else if (formState && isAdditionalMilestoneFormState(formState)) {
      return formState.value.constraints ?? [];
    }
  }, [formState, formKey]);

  const onSubmit = async () => {
    const result = await saveGlobalPlanConstraintItem({
      variables: { input: { name } },
      refetchQueries: [
        "TaskCatalogFormSchedulingDropdown",
        "ConstraintAndAllowanceCatalogPage",
        "MilestoneCatalogFormDropdown",
      ],
    });
    const itemId = result.data?.saveGlobalPlanConstraintItem.globalPlanConstraintItem.id;
    // we only want to auto-select constraints/allowances if we use the dropdown from the form
    // type-narrowing as the formstate union was throwing an error
    if (formState) {
      if (itemId && formKey && isTaskFormState(formState)) {
        formState.set({ [formKey]: [...(currentConstraintItem ?? []), itemId] });
      } else if (itemId && isMilestoneFormState(formState)) {
        formState.set({ constraints: [...(currentConstraintItem ?? []), itemId] });
      } else if (itemId && isAdditionalMilestoneFormState(formState)) {
        formState.set({ constraints: [...(currentConstraintItem ?? []), itemId] });
      }
    }
  };

  return (
    <ConfirmationModal
      title={header}
      onConfirmAction={onSubmit}
      confirmationMessage={
        <>
          {" "}
          <div css={Css.mb3.$}>{body}</div>
          <TextField label={`Add a new ${title}`} onChange={(val) => setName(val)} value={name} />
          <div css={Css.mt2.$}>
            <Button
              label={`Manage ${pluralize(2, title)}`}
              variant="tertiary"
              onClick={() => {
                // only redirect to the constraint/allowance catalog if we're on the dropdown form
                formKey && history.push(createConstraintItemCatalogUrl());
                closeModal();
              }}
            />
          </div>
        </>
      }
      label={`Add ${title}`}
    />
  );
}

function isTaskFormState(
  formState:
    | ObjectState<SaveGlobalPlanTaskFormValue>
    | ObjectState<SaveGlobalPlanMilestoneFormValue>
    | ObjectState<SaveAdditionalMilestoneCardFormValue>,
): formState is ObjectState<SaveGlobalPlanTaskFormValue> {
  return formState !== undefined && formState.value.hasOwnProperty("allowances");
}

function isMilestoneFormState(
  formState:
    | ObjectState<SaveGlobalPlanTaskFormValue>
    | ObjectState<SaveGlobalPlanMilestoneFormValue>
    | ObjectState<SaveAdditionalMilestoneCardFormValue>,
): formState is ObjectState<SaveGlobalPlanMilestoneFormValue> {
  return formState !== undefined && formState.value.hasOwnProperty("constraints");
}

function isAdditionalMilestoneFormState(
  formState:
    | ObjectState<SaveGlobalPlanTaskFormValue>
    | ObjectState<SaveGlobalPlanMilestoneFormValue>
    | ObjectState<SaveAdditionalMilestoneCardFormValue>,
): formState is ObjectState<SaveAdditionalMilestoneCardFormValue> {
  return formState !== undefined && formState.value.hasOwnProperty("constraints");
}
