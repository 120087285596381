import { Button, Css, useModal, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { Observer } from "mobx-react";
import { SaveAttachmentModel } from "src/components/boundAttachments/BoundAttachments";
import {
  ProductAttributeFragment,
  ProductAttributeType,
  ProductDimensionType,
  ProductSuperDrawer_ItemFragment,
  ProductSuperDrawer_ProductFragment,
  SaveProductDimensionInput,
  useSaveProductsMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { ProductForm, productFormConfig } from "../../ProductPage";
import { ProductDuplicationModal } from "../ProductDuplicationModal";
import { ImageAsset, ProductImageEditor } from "../ProductImageEditor";
import { ProductActivitiesContainer } from "../product-activities-container/ProductActivitiesContainer";
import { ProductImageViewer } from "../product-images-viewer/ProductImageViewer";

export type ProductOverviewTabProps = {
  product: ProductSuperDrawer_ProductFragment;
  items: ProductSuperDrawer_ItemFragment[];
  attributes: ProductAttributeFragment[];
  isEdit?: boolean;
  productOptionsRefetch: () => void;
};

export function ProductOverviewTab(props: ProductOverviewTabProps) {
  const { product: selectedProduct, items, attributes, isEdit = false, productOptionsRefetch } = props;
  const [readOnly, toggleReadOnly] = useToggle(isEdit ? false : true);
  const [saveProducts, { loading }] = useSaveProductsMutation();
  const { triggerNotice } = useSnackbar();
  const { openModal } = useModal();

  const formState = useFormState({
    config: productFormConfig,
    init: {
      input: selectedProduct,
      map: (pr) => {
        const { attributes: attrs, dimensions, items, status, images, attachments, ...others } = pr;
        return {
          images: images as ImageAsset[],
          attachments: attachments as SaveAttachmentModel[],
          itemIds: items.map((it) => it.id),
          status: status.code,
          height: dimensions.find((d) => d.dimensionType.code === ProductDimensionType.Height)?.rawValue,
          width: dimensions.find((d) => d.dimensionType.code === ProductDimensionType.Width)?.rawValue,
          length: dimensions.find((d) => d.dimensionType.code === ProductDimensionType.Length)?.rawValue,
          brandId: attrs.find((att) => att.type === ProductAttributeType.Brand)?.id,
          lineIds: attrs.filter((att) => att.type === ProductAttributeType.Line).map((att) => att.id),
          finishIds: attrs.filter((att) => att.type === ProductAttributeType.Finish).map((att) => att.id),
          materialIds: attrs.filter((att) => att.type === ProductAttributeType.Material).map((att) => att.id),
          designPackageIds: attrs.filter((att) => att.type === ProductAttributeType.DesignPackage).map((att) => att.id),
          designPackageLocationIds: attrs
            .filter((att) => att.type === ProductAttributeType.DesignPackageLocation)
            .map((att) => att.id),
          designPackageParentIds: attrs
            .filter((att) => att.type === ProductAttributeType.DesignPackageItem)
            .map((att) => att.id),
          ...others,
        };
      },
    },
  });

  const handleSave = async () => {
    const {
      images,
      length,
      width,
      height,
      brandId,
      lineIds,
      finishIds,
      materialIds,
      designPackageIds,
      designPackageParentIds,
      designPackageLocationIds,
      attachments: selectedAttachments,
      ...others
    } = formState.value;

    const selectedAttributes = [
      ...(brandId ? [brandId] : []),
      ...(lineIds ?? []),
      ...(finishIds ?? []),
      ...(materialIds ?? []),
      ...(designPackageIds ?? []),
      ...(designPackageParentIds ?? []),
      ...(designPackageLocationIds ?? []),
    ];

    // Backend accepts incremental attributes/dimensions
    const addAttributes = selectedAttributes.map((att) => ({ id: att }));

    // So we have to check if an attribute  needs to be removed
    const removeAttributes = selectedProduct.attributes
      .filter((att) => !selectedAttributes.includes(att.id))
      .map((att) => ({ id: att.id, remove: true }));

    const attributes = [...addAttributes, ...removeAttributes];

    // Same for the product dimensions
    // Get the current height, length and width if there is one
    const currLength = selectedProduct?.dimensions.find((d) => d.dimensionType.code === ProductDimensionType.Length);
    const currHeight = selectedProduct?.dimensions.find((d) => d.dimensionType.code === ProductDimensionType.Height);
    const currWidth = selectedProduct?.dimensions.find((d) => d.dimensionType.code === ProductDimensionType.Width);

    // Add/edit/remove dimensions based on the input
    const dimensions: SaveProductDimensionInput[] = [
      ...(currLength
        ? [{ id: currLength.id, rawValue: length, delete: !length }]
        : length
          ? [{ rawValue: length, dimensionType: ProductDimensionType.Length }]
          : []),
      ...(currHeight
        ? [{ id: currHeight.id, rawValue: height, delete: !height }]
        : height
          ? [{ rawValue: height, dimensionType: ProductDimensionType.Height }]
          : []),
      ...(currWidth
        ? [{ id: currWidth.id, rawValue: width, delete: !width }]
        : width
          ? [{ rawValue: width, dimensionType: ProductDimensionType.Width }]
          : []),
    ];

    const addImages = images?.map((img) => omit(img, ["downloadUrl", "attachmentUrl"])) || [];

    const removeImages = selectedProduct.images
      .filter((img) => !images.find((pImg) => pImg.id === img.id))
      .map((img) => ({ id: img.id, delete: true }));

    const attachments = selectedAttachments?.map(({ asset, ...props }) => ({
      ...props,
      asset: pick(asset, [
        // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
        "contentType",
        "fileName",
        "id",
        "s3Key",
        "sizeInBytes",
        "delete",
      ]),
    }));

    const { data } = await saveProducts({
      variables: {
        input: {
          products: [{ ...others, attributes, dimensions, attachments, images: [...addImages, ...removeImages] }],
        },
      },
    });

    triggerNotice({ message: "Product successfully edited", icon: "success" });
    toggleReadOnly();
  };

  return (
    <div>
      <div css={Css.df.$}>
        <div css={Css.w50.$}>
          <div css={Css.xlSb.mb2.$}>{selectedProduct.name}</div>
          <div css={Css.f2.mb2.$}>
            {readOnly ? (
              <ProductImageViewer product={selectedProduct} />
            ) : (
              <ProductImageEditor formState={formState} compact={true} />
            )}
          </div>
          <div css={Css.bgWhite.p2.br8.$}>
            <ProductForm
              isNew={false}
              readOnly={readOnly}
              formState={formState}
              attributes={attributes}
              items={items}
              productOptionsRefetch={productOptionsRefetch}
            />
          </div>
        </div>
        <div css={Css.w50.$}>
          <div css={Css.df.$}>
            <Observer>
              {() => (
                <div css={Css.mla.df.gap1.pr1.$}>
                  {readOnly ? (
                    <>
                      <Button
                        label="Duplicate"
                        onClick={() => openModal({ content: <ProductDuplicationModal product={selectedProduct} /> })}
                      />
                      <Button label="Edit" variant="secondary" onClick={toggleReadOnly} />
                    </>
                  ) : (
                    <>
                      <Button
                        label="Cancel"
                        variant="tertiary"
                        disabled={loading}
                        onClick={() => {
                          formState.revertChanges();
                          toggleReadOnly();
                        }}
                      />
                      <Button
                        label="Save changes"
                        disabled={loading || !formState.dirty}
                        variant="primary"
                        onClick={handleSave}
                      />
                    </>
                  )}
                </div>
              )}
            </Observer>
          </div>
          <div css={Css.mt4.$}>
            <ProductActivitiesContainer product={selectedProduct} />
          </div>
        </div>
      </div>
    </div>
  );
}
