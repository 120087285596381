import {
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  Switch,
  ToggleChip,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import {
  ApplyItemTemplateItemInput,
  ApplyItemTemplateToChangeEventInput,
  Stage,
  useApplyTemplateChangeEventModalQuery,
  useApplyTemplatePlanChangeEventModalQuery,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils/arrays";

type ApplyTemplateChangeEventModalProps = {
  changeEventId: string;
  onApplyTemplate: (input: ApplyItemTemplateToChangeEventInput) => Promise<void>;
  stage: Stage;
};

/** Applies a template to a Change Event. */
export const ApplyTemplateModal = observer((props: ApplyTemplateChangeEventModalProps) => {
  const { changeEventId, onApplyTemplate, stage } = props;

  const query = useApplyTemplateChangeEventModalQuery({ variables: { stage } });
  const tid = useTestIds(props);
  const { closeModal } = useModal();

  const [templateId, setTemplateId] = useState<string | undefined>();
  const [replace, setReplace] = useState<boolean>(false);

  // We don't query for the plan until we know the template
  const planQuery = useApplyTemplatePlanChangeEventModalQuery({
    variables: { templateId: templateId!, changeEventId },
    skip: templateId === undefined,
  });

  // Create a mobx `{ plan: ... }` that we'll use if they opt-in to replacing
  const plan = useMemo<{ items: PlanItem[]; hasMatches: boolean }>(() => {
    const items =
      planQuery.data?.itemTemplates[0].applyTemplatePlanChangeEvent.map((p) => {
        const { projectItem: pi, itemTemplateItem } = p;
        return {
          itemTemplateItemId: itemTemplateItem.id,
          projectItemId: pi?.id,
          originalProjectItemId: pi?.id,
          name: pi
            ? [pi.item.fullCode, pi.name, pi.location.name, pi.costTypeDetail.name].filter(isDefined).join(" - ")
            : undefined,
        } as PlanItem;
      }) || [];
    return observable({
      items,
      hasMatches: items.filter((i) => i.projectItemId).length > 0,
    });
  }, [planQuery]);

  // Keep them from adding while we're loading the replace plan
  const canAdd = templateId && !planQuery.loading;

  // Keep track on number of items that are being replaced
  const numOfItemsReplacing = plan.items.filter((item) => item.projectItemId).length;

  return (
    <>
      <ModalHeader>Add from template</ModalHeader>
      <ModalBody>
        <p css={Css.mb3.$}>Choose a template to add an option or upgrade to this project.</p>
        <SelectField
          label="Template"
          value={templateId}
          onSelect={setTemplateId}
          options={query.data?.itemTemplates || []}
          getOptionLabel={(template) => template.displayName}
          getOptionValue={(template) => template.id}
          helperText={helperText(plan, templateId)}
          {...tid.template}
        />
        {canAdd && plan.hasMatches && (
          <>
            <div css={Css.baseMd.mt3.$}>Matching Line Items</div>
            <p css={Css.mt2.gray700.$}>
              Do you want this template to replace any existing items that match in name, location, and cost type?
            </p>
            <div css={{ ...Css.mt2.mb1.$, "& > label": Css.smMd.$ }}>
              <Switch
                label="Replace items that match template"
                selected={replace}
                onChange={setReplace}
                labelStyle="filter"
                {...tid.replace}
              />
            </div>
            {replace && (
              <>
                <div>
                  <ReplaceChips plan={plan} setReplace={setReplace} />
                </div>
                {/* <div>{replaceChips(tid, plan, setReplace)}</div> */}
                <p css={Css.xs.gray700.$}>
                  {numOfItemsReplacing > 1
                    ? `Replacing ${numOfItemsReplacing} existing items`
                    : "Replacing 1 existing item"}
                </p>
                <div css={Css.xs.gray700.mt3.mb2.$}>
                  <p>
                    If you don't want an item replaced, clear it above. This action will add a duplicate item in
                    addition to the existing one.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Add Template"
          disabled={!canAdd}
          onClick={async () => {
            if (templateId) {
              const items = !replace
                ? undefined
                : plan.items.map((item) => {
                    // Drop the name that we used for display only purposes
                    const { projectItemId, itemTemplateItemId } = item;
                    return { projectItemId, itemTemplateItemId };
                  });
              await onApplyTemplate({ id: templateId, changeEventId, items });
              closeModal();
            }
          }}
        />
      </ModalFooter>
    </>
  );
});

// Add a name to each of our options
type PlanItem = ApplyItemTemplateItemInput & {
  name: string | undefined;
  // We keep a copy to restore projectItemId if they flip replace on/off
  originalProjectItemId: string | undefined;
};

type ReplaceChipsProps = {
  plan: { items: PlanItem[] };
  setReplace: (replace: boolean) => void;
};

function ReplaceChips(props: ReplaceChipsProps) {
  const { plan, setReplace } = props;
  const tid = useTestIds(props);

  // plan is a mobx proxy
  return (
    <>
      {plan.items
        .filter((item) => item.projectItemId)
        .map((item) => (
          <div key={item.itemTemplateItemId} css={Css.my1.$}>
            <ToggleChip
              text={item.name!}
              onClick={() => {
                item.projectItemId = undefined;
                // If that was the last replace-able item, just turn off replace
                if (plan.items.filter((i) => i.projectItemId).length === 0) {
                  setReplace(false);
                  // And flip items back to the original
                  plan.items.forEach((i) => {
                    i.projectItemId = i.originalProjectItemId;
                  });
                }
              }}
              {...tid[`chip_${item.itemTemplateItemId}`]}
            />
          </div>
        ))}
    </>
  );
}

function helperText(plan: { items: PlanItem[] }, templateId: string | undefined): string {
  if (!templateId) return "";
  return plan.items.length > 1
    ? `Adding ${plan.items.length} items`
    : plan.items.length
      ? `Adding ${plan.items.length} item`
      : "Adding 0 items";
}
