import { ButtonDatePicker, ButtonGroup, Css, Loader } from "@homebound/beam";
import { pluralize } from "src/utils";
import { formatMonthDayYear } from "src/utils/dates";
import { RangeFilterReturnTypes } from "./useWeekRangeFilter";

type WeekRangeFilterProps = {
  rangeFilter: RangeFilterReturnTypes;
  loading: boolean;
};

export function WeekRangeFilter({ rangeFilter, loading }: WeekRangeFilterProps) {
  const { dateRange, setStartDate, previousRangeGroup, nextRangeGroup, weekCount } = rangeFilter;
  return (
    <div css={Css.df.gap2.aic.mb2.$} data-testid="weekRangeFilter">
      {/* Hardcoded flex basis (width) to prevent layout shifts when cycling through date strings of varied lengths */}
      <div css={Css.lgBd.fb("250px").$}>
        {formatMonthDayYear(dateRange.start)} - {formatMonthDayYear(dateRange.end)}
      </div>
      <div css={Css.ptPx(4).$}>
        <ButtonDatePicker
          trigger={{ icon: "calendar", compact: true }}
          disabledDays={{ dayOfWeek: [0, 2, 3, 4, 5, 6] }}
          value={dateRange.start}
          disabled={loading}
          onSelect={setStartDate}
        />
      </div>
      <ButtonGroup
        buttons={[
          {
            icon: "chevronLeft",
            tooltip: `Previous ${weekCount} ${pluralize(weekCount, "week")}`,
            onClick: previousRangeGroup,
            disabled: loading,
          },
          {
            icon: "chevronRight",
            tooltip: `Next ${weekCount} ${pluralize(weekCount, "week")}`,
            onClick: nextRangeGroup,
            disabled: loading,
          },
        ]}
      />
      {loading && <Loader size="xs" />}
    </div>
  );
}
