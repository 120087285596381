import { Chip, Css } from "@homebound/beam";
import { OverviewTab_ProductFragment, ProductAttributeType } from "src/generated/graphql-types";

export type ProductDesignPackageDetailsSectionProps = {
  product: OverviewTab_ProductFragment;
};

export function ProductDesignPackageDetailsSection(props: ProductDesignPackageDetailsSectionProps) {
  const { product } = props;
  const designPackageLocation = product.attributes.find(
    (attr) => attr.type === ProductAttributeType.DesignPackageLocation,
  )?.value;

  if (product.items?.isEmpty && designPackageLocation === undefined) {
    return <>Not available</>;
  }

  return (
    <div css={Css.sm.$}>
      {product.items?.nonEmpty && (
        <div data-testid="currentlyUsedInSection">
          <div css={Css.fw7.mb1.$}>Currently used in:</div>
          <div css={Css.df.fdc.$}>
            {product.items.map((item) => (
              <span css={Css.mb1.$} key={`item-${item.id}`}>
                <Chip text={item.name} title={item.displayName} key={`item-chip-${item.id}`} />
              </span>
            ))}
          </div>
        </div>
      )}

      {designPackageLocation && (
        <div css={Css.mt2.$} data-testid="designPackageLocationSection">
          <div>
            <label css={Css.fw7.mr1.$}>Design package location:</label>
            {designPackageLocation}
          </div>
        </div>
      )}
    </div>
  );
}
