import { FilterDefs, singleFilter } from "@homebound/beam";
import { Maybe } from "graphql/jsutils/Maybe";
import { useMemo } from "react";
import { ProjectStagesFilter, Stage } from "src/generated/graphql-types";
import { stageCodeToNameMapper } from "src/utils";

export function useProjectStageSingleFilter(defaultValue?: Stage, includePlanning?: boolean) {
  const filterDefs: FilterDefs<SingleStageFilter> = useMemo(() => {
    const stages = [Stage.PreConExecution, Stage.Construction];

    if (includePlanning) {
      stages.unshift(Stage.PreConPlanning);
    }

    const stage = singleFilter({
      options: stages.map((s) => ({ id: s, name: stageCodeToNameMapper[s] })),
      defaultValue: stages.includes(defaultValue!) ? defaultValue : undefined,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });
    return { stage };
  }, [includePlanning, defaultValue]);

  return { filterDefs };
}

export function mapToProjectStagesFilter(filter: SingleStageFilter): ProjectStagesFilter {
  return {
    ...filter,
    stage: filter.stage ? [filter.stage] : undefined,
  };
}

export type SingleStageFilter = Omit<ProjectStagesFilter, "stage"> & { stage?: Maybe<Stage> };
