import { ScrollableContent } from "@homebound/beam";
import { HistoryFeed } from "src/components";
import { useEstimateHistoryQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type EstimateHistoryTabProps = {
  estimateId: string;
};

export function EstimateHistoryTab({ estimateId }: EstimateHistoryTabProps) {
  const query = useEstimateHistoryQuery({ variables: { estimateId } });
  return queryResult(query, {
    data: ({ projectEstimate: { history } }) => (
      <ScrollableContent>
        <HistoryFeed historyItems={history} />
      </ScrollableContent>
    ),
  });
}
