import { Button, FilterDefs, Filters, multiFilter, usePersistedFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useHistory } from "react-router";
import {
  useGlobalProductOfferingsMetaDataQuery,
  useGlobalProductOfferingsPageQuery,
} from "src/generated/graphql-types";
import { ProductOfferingsPageView } from "src/routes/developments/product-offerings/ProductOfferingsPage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { addEntityParam, createProductOfferingUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

type Filter = {
  market: string[];
};

export function GlobalProductOfferings() {
  const { data } = useGlobalProductOfferingsMetaDataQuery({});
  const history = useHistory();
  // This is for showing at some point we will enable market filtering but we don't need that yet
  const filterDefs: FilterDefs<Filter> = useMemo(
    () => ({
      market: multiFilter({
        options: data?.markets ?? [],
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
        disabled: true,
      }),
    }),
    [data],
  );

  const { filter, setFilter } = usePersistedFilter<Filter>({
    filterDefs,
    storageKey: "product-offering-filter",
  });

  const query = useGlobalProductOfferingsPageQuery({
    variables: {
      filter: {},
    },
  });
  return (
    <>
      <PageHeader
        title="Product Offerings"
        right={
          <>
            <Button label="Create New" onClick={() => history.push(createProductOfferingUrl(addEntityParam))} />
          </>
        }
      />
      <TableActions onlyLeft>
        <Filters<Filter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
      </TableActions>
      {queryResult(query, {
        data: ({ productOfferings }) => {
          return <ProductOfferingsPageView productOfferings={productOfferings.entities} />;
        },
      })}
    </>
  );
}
