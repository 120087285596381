import { Css, TabWithContent, TabsWithContent, useBreakpoint, useTestIds } from "@homebound/beam";
import { useState } from "react";
import { Card } from "src/components/Card";
import { BillPage_BillFragment } from "src/generated/graphql-types";
import { BillSectionHeader } from "src/routes/projects/bills/components/DetailsSection/BillSectionHeader";
import { BillDetailsTab } from "./BillDetailsTab";
import { BillRelationshipsTab } from "./BillRelationshipsTab";

export function BillDetailsSection({ bill }: { bill: BillPage_BillFragment }) {
  const [selectedTab, setTab] = useState("details");
  const { sm } = useBreakpoint();
  const tid = useTestIds({});

  const tabs: TabWithContent[] = [
    {
      name: "Details",
      value: "details",
      render: () => (
        <div {...tid.details}>
          <BillDetailsTab bill={bill} />
        </div>
      ),
    },
    ...(!sm
      ? [
          {
            name: "Relationships",
            value: "relationships",
            render: () => (
              <div {...tid.relationships}>
                <BillRelationshipsTab bill={bill} />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Card xss={Css.mt2.$}>
      <BillSectionHeader bill={bill} />
      <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setTab} />
    </Card>
  );
}
