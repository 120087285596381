import { Tooltip } from "@homebound/beam";
import { DropCodeCellItem_ItiFragment, DropCodeCellItem_ItivFragment } from "src/generated/graphql-types";

export function DropCodeCell({ scope }: { scope: DropCodeCellItem_ItivFragment | DropCodeCellItem_ItiFragment }) {
  return (
    <Tooltip
      placement="top"
      title={
        scope.developmentDrop ? `${scope.item.costCode.name} - ${scope.developmentDrop.name}` : scope.item.costCode.name
      }
    >
      {scope.developmentDrop?.code || scope.item.costCode.number}
    </Tooltip>
  );
}
