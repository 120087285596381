import { Css, useBreakpoint } from "@homebound/beam";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";
import { InitDetail } from "lightgallery/lg-events";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import { useCallback, useEffect, useRef } from "react";
import lgHtml5VideoThumbnail from "src/components/assetGallery/plugins/HTML5VideoThumbnail/lg-html5-video-thumbnail";
import { lightGalleryKey } from "src/context";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { openDownload } from "src/utils/window";

export type OpenGallery = (asset: AssetInfoFragment) => void;

export type AssetGalleryProps<T extends AssetInfoFragment> = {
  assets: T[];
  children: (openGallery: OpenGallery) => JSX.Element | void;
  caption?: (asset: T) => string;
  display?: "vertical" | "horizontal";
  showThumbnails?: boolean;
};

export function AssetGallery<T extends AssetInfoFragment>(props: AssetGalleryProps<T>) {
  const { mdAndDown } = useBreakpoint();
  const { assets, children, caption, display = "vertical", showThumbnails = true } = props;
  const lightGallery = useRef({ refresh: () => {} });
  const plugins: any[] = [lgZoom, lgVideo];
  if (showThumbnails) {
    plugins.push(lgThumbnail, lgHtml5VideoThumbnail);
  }

  const onInit = useCallback((detail: InitDetail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useEffect(() => {
    lightGallery.current?.refresh();
  }, [assets]);

  const showAssetId = (asset: AssetInfoFragment) => `show-${asset.id}`;

  const openGallery = (asset: AssetInfoFragment) => {
    if (mdAndDown && asset.contentType === "application/pdf") {
      // Resolving the problem where iOS devices (iPad and iPhone) display only the first page of PDFs within iframes.
      // This fix enables users to scroll and view all pages of the PDF within the iframe.
      // We've implemented the openDownload function to open the PDF in a new tab, the recommended method for full PDF page access.
      openDownload(asset.attachmentUrl);
    } else {
      // This is the default behavior for all other devices.
      document.getElementById(showAssetId(asset))?.click();
    }
  };

  return (
    <LightGallery plugins={plugins} licenseKey={lightGalleryKey} selector=".asset-preview" onInit={onInit}>
      <div css={Css.df.if(display === "vertical").add("flexWrap", "wrap").else.oya.$}>
        {children(openGallery) || <></>}
        {assets.map((asset) => (
          <div
            id={showAssetId(asset)}
            key={showAssetId(asset)}
            className="asset-preview"
            {...getAssetData(asset)}
            data-download-url={asset.attachmentUrl}
            data-sub-html={
              caption
                ? `<div style="max-height:10vh; overflow:auto; text-align:left;">${caption(asset)}</div>`
                : undefined
            }
            hidden
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <AssetPreview asset={asset} />
          </div>
        ))}
      </div>
    </LightGallery>
  );
}

function getAssetData({ contentType, downloadUrl }: AssetInfoFragment) {
  if (contentType.startsWith("image/")) {
    return {
      "data-src": downloadUrl,
    };
  } else if (contentType.startsWith("video/")) {
    // Setting the type to video/mp4 allows us to play mov files too.
    return {
      "data-video": `{"source": [{"src": "${downloadUrl}", "type":"video/mp4"}], "attributes": {"preload": true, "playsinline": true, "controls": true}}`,
    };
  } else if (contentType === "application/pdf") {
    return {
      "data-src": downloadUrl,
      "data-iframe": "true",
    };
  } else {
    return {
      "data-src": downloadUrl,
    };
  }
}
