import {
  column,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  multiFilter,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
  SuperDrawerWidth,
  usePersistedFilter,
  useSuperDrawer,
} from "@homebound/beam";
import { MutableRefObject, useMemo, useRef, useState } from "react";
import { chipCell, QueryTable, SearchBox } from "src/components";
import { useRoomTypeFilter } from "src/components/autoPopulateFilters/useRoomTypeFilter";
import {
  AdminLocationsPage_LocationFragment,
  AdminLocationsPageQuery,
  LocationsFilter,
  LocationType,
  useAdminLocationsPageQuery,
} from "src/generated/graphql-types";
import { LocationDrawer } from "src/routes/admin/locations/components/LocationDrawer";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { safeEntries } from "src/utils";

export function LocationsPage() {
  const roomTypesFilter = useRoomTypeFilter();
  const filterDefs: FilterDefs<LocationsFilter> = useMemo(
    () => ({
      type: multiFilter({
        label: "Type",
        options: safeEntries(LocationType).map(([name, id]) => ({ id, name })),
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
        defaultValue: [LocationType.Level, LocationType.Group, LocationType.Room],
      }),
      roomTypes: roomTypesFilter,
    }),
    [roomTypesFilter],
  );

  const { setFilter, filter } = usePersistedFilter<LocationsFilter>({
    storageKey: "adminLocationsFilter",
    filterDefs,
  });

  const query = useAdminLocationsPageQuery({ variables: { filter: { ...filter, version: [2] } } });
  const columns = useMemo(createColumns, []);
  const rowLookup = useRef<GridRowLookup<Row>>();
  const { openInDrawer } = useSuperDrawer();
  const rowStyles = useMemo(() => createRowStyles(openInDrawer, rowLookup), [openInDrawer, rowLookup]);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  return (
    <>
      <PageHeader title="Locations V2" />
      <TableActions>
        <Filters<LocationsFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} clearable />
      </TableActions>
      <ScrollableContent virtualized>
        <QueryTable
          query={query}
          columns={columns}
          createRows={createRows}
          as="virtual"
          style={{ allWhite: true, bordered: true }}
          sorting={{ on: "client" }}
          rowStyles={rowStyles}
          rowLookup={rowLookup}
          filter={searchFilter}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<AdminLocationsPage_LocationFragment>;

function createRows(data: AdminLocationsPageQuery | undefined): GridDataRow<Row>[] {
  const locations = data?.locations ?? [];
  return [simpleHeader, ...locations.map((l) => ({ kind: "data" as const, id: l.id, data: l }))];
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Name",
      data: ({ name }) => name,
      mw: "250px",
    }),
    column<Row>({
      header: "Type",
      data: ({ type }) => type.name,
      mw: "150px",
    }),
    column<Row>({
      header: "Room Type",
      data: ({ roomTypes }) => chipCell(roomTypes.map((rt) => rt.name)),
      mw: "250px",
    }),
    column<Row>({
      header: "Path",
      data: ({ path }) => ({
        content: () => <span css={Css.wbba.truncate.$}>{path}</span>,
        tooltip: <span css={Css.wbba.$}>{path}</span>,
        value: path,
      }),
      mw: "250px",
    }),
  ];
}

function createRowStyles(
  openInDrawer: ReturnType<typeof useSuperDrawer>["openInDrawer"],
  lookup: MutableRefObject<GridRowLookup<Row> | undefined>,
): RowStyles<Row> {
  function openRow(row: GridDataRow<Row>): void {
    if (row.kind === "data") {
      const { prev, next } = lookup.current!.lookup(row)["data"];
      openInDrawer({
        onPrevClick: prev && (() => openRow(prev)),
        onNextClick: next && (() => openRow(next)),
        content: <LocationDrawer id={row.data.id} />,
        width: SuperDrawerWidth.Small,
      });
    }
  }
  return { data: { onClick: openRow } };
}
