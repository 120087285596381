import { BoundNumberField, BoundSelectField, Button, Css, SelectField, useComputed } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { ReactElement, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import { HoverDelete } from "src/components/HoverDelete";
import { SaveScheduleTaskInput, ScheduleTask, TaskDependencyType } from "src/generated/graphql-types";
import { TaskDependencyOption } from "src/routes/projects/schedule-v2/components/TaskDependencyOption";
import { ScheduleClearFilters } from "src/routes/projects/schedule-v2/contexts/ScheduleClearFilters";
import { partition, pluralize } from "src/utils";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { ScheduleViewType } from "../scheduleUtils";
import { DependencyValue } from "../table/utils";
import { FormValue } from "./TaskDetailPane";

type TaskDependenciesProps = {
  formState: FormValue;
  tasks: Pick<ScheduleTask, "id" | "name">[];
  taskId: string;
  onSave: (changedValue: SaveScheduleTaskInput) => void;
  onJump: (id: string) => void;
  scheduleIsLocked: boolean;
  isNewTask?: boolean;
  type?: AddingType;
  taskNumberMap?: Record<string, number>;
};

export function TaskDependencies({
  formState,
  tasks,
  taskId,
  onSave,
  onJump,
  scheduleIsLocked,
  isNewTask,
  type,
  taskNumberMap,
}: TaskDependenciesProps) {
  // grab the predecessor and successor ids and concatenate them together to make one single array to filter through
  // filter out tasks by current task and the task's successor and predecessors tasks

  const filteredTasks = useComputed(() => {
    const predecessorIds = formState.predecessorDependencies.rows.map((pd) => pd.id.value);
    const successorIds = formState.successorDependencies.rows.map((pd) => pd.id.value);
    const predecessorAndSuccessorIds = predecessorIds.concat(successorIds);
    return tasks.filter((task) => !predecessorAndSuccessorIds.includes(task.id) && taskId !== task.id);
  }, [tasks, formState, taskId]);

  return isNewTask ? (
    <>
      {(type === "task" || type === "successor") && (
        <NewTaskDependency
          type="predecessor"
          formStateDependencies={formState.predecessorDependencies}
          filteredTasks={filteredTasks}
          onSave={onSave}
          onJump={onJump}
          formState={formState}
          taskId={taskId}
          taskNumberMap={taskNumberMap}
          viewType={type}
        />
      )}
      {(type === "task" || type === "predecessor") && (
        <NewTaskDependency
          type="successor"
          formStateDependencies={formState.successorDependencies}
          filteredTasks={filteredTasks}
          onSave={onSave}
          onJump={onJump}
          formState={formState}
          taskId={taskId}
          taskNumberMap={taskNumberMap}
          viewType={type}
        />
      )}
    </>
  ) : (
    <>
      {(type === "task" || type === "predecessor") && (
        <UpdateTaskDependency
          type="predecessor"
          formStateDependencies={formState.predecessorDependencies}
          filteredTasks={filteredTasks}
          onSave={onSave}
          taskId={taskId}
          onJump={onJump}
          scheduleIsLocked={scheduleIsLocked}
          formState={formState}
          viewType={type}
        />
      )}
      {(type === "task" || type === "successor") && (
        <UpdateTaskDependency
          type="successor"
          formStateDependencies={formState.successorDependencies}
          filteredTasks={filteredTasks}
          onSave={onSave}
          taskId={taskId}
          onJump={onJump}
          scheduleIsLocked={scheduleIsLocked}
          formState={formState}
          viewType={type}
        />
      )}
    </>
  );
}

export type DependencyTypes = "predecessor" | "successor";
export type AddingType = "task" | "predecessor" | "successor";

type TaskDependencyProps = {
  type: DependencyTypes;
  formStateDependencies: FormValue["predecessorDependencies"] | FormValue["successorDependencies"];
  filteredTasks: Pick<ScheduleTask, "id" | "name">[];
  onSave: (changedValue: SaveScheduleTaskInput) => void;
  onJump: (id: string) => void;
  taskId?: string;
  scheduleIsLocked?: boolean;
  formState: FormValue;
  taskNumberMap?: Record<string, number>;
  viewType?: AddingType;
};

type NewDependencyOption = {
  task: Pick<ScheduleTask, "id" | "name">;
  node: ReactElement;
  taskRowNumber: number | null;
};

type AddDependencyProps = {
  title: string;
  setShowSelect: (value: boolean) => void;
  showSelect: boolean;
  scheduleIsLocked?: boolean;
};

type SelectDependencyProps = {
  newDependencyOptions: NewDependencyOption[];
  onSelect: (value: NewDependencyOption) => void;
  setShowSelect: (value: boolean) => void;
  scheduleIsLocked?: boolean;
  type: string;
};

type DependencyProps = {
  td: ObjectState<DependencyValue>;
  onJump?: (id: string) => void;
  scheduleIsLocked?: boolean;
  isFilteredTaskInView?: boolean;
  clearFilters?: any;
  updateDependency?: (taskId: string, isUpdated: boolean, opt?: any, field?: string) => void;
  taskNumber: number | null;
  viewType?: AddingType;
};

function filterDependencyOptions(
  filteredTasks: Pick<ScheduleTask, "id" | "name">[],
  filteredDependencies: ObjectState<DependencyValue>[],
  taskNumberMap: Record<string, number>,
) {
  const dependencyOptions = filteredTasks.map((task) => {
    const taskRowNumber = taskNumberMap[task.id] ?? "";
    return {
      task,
      taskRowNumber,
      node: <TaskDependencyOption task={task} taskNumber={taskRowNumber} />,
    };
  });
  const [numberedTasks, unnumberedTasks] = partition(dependencyOptions, (dependencyOption) =>
    Boolean(dependencyOption.taskRowNumber),
  );
  return numberedTasks
    .sort((t1, t2) => {
      if (t1.taskRowNumber || t2.taskRowNumber) {
        return t1.taskRowNumber - t2.taskRowNumber;
      }
      return 1;
    })
    .concat(unnumberedTasks)
    .filter(({ task }) => {
      // Filter out tasks that are currently dependencies
      const dependencyIds = filteredDependencies.map((fd) => fd.id.value);
      return !dependencyIds.includes(task.id);
    });
}

function UpdateTaskDependency(props: TaskDependencyProps) {
  const {
    type,
    formStateDependencies,
    filteredTasks,
    onSave,
    onJump,
    taskId,
    scheduleIsLocked,
    viewType = "task",
  } = props;

  const filteredDependencies = useComputed(
    () => formStateDependencies.rows.filter((td) => td.delete.value !== true),
    [formStateDependencies],
  );

  const {
    scheduleState: {
      taskNumberMap,
      taskPaneState: { scrollIntoViewType },
    },
  } = useScheduleStore();

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(
    () => {
      setTimeout(() => {
        if (scrollIntoViewType === type && ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth", inline: "nearest", block: "start" });
        }
      }, 500);
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollIntoViewType],
  );

  const clearFilters = useContext(ScheduleClearFilters);
  // use hooks in order to show the dependency type SelectField on click
  const [showSelect, setShowSelect] = useState(false);

  const newDependencyOptions: NewDependencyOption[] = useMemo(
    () => {
      return filterDependencyOptions(filteredTasks, filteredDependencies, taskNumberMap);
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredDependencies, filteredTasks],
  );

  // Use the autosave behavior for any action whether adding a dependency or editing one on an existing task
  function saveDependency(opt: NewDependencyOption) {
    // Instead of modifying formState directly, we directly call the backend b/c it will only
    // succeed if there are no cycles. And so if it fails, the user will get an alerts, and the UI
    // will not have the attempted-to-add dependency in it. But if it succeeds, then the apollo
    // cache/form state will refresh, and then we'll see it.
    onSave({
      id: taskId,
      ...(type === "successor"
        ? {
            successorDependencies: [
              {
                lagInDays: 0,
                successorId: opt.task.id,
                type: TaskDependencyType.FinishStart,
              },
            ],
          }
        : {
            predecessorDependencies: [
              {
                lagInDays: 0,
                predecessorId: opt.task.id,
                type: TaskDependencyType.FinishStart,
              },
            ],
          }),
    });
  }

  return (
    <Observer>
      {() => (
        <>
          <div css={Css.smMd.mb1.$} ref={ref}>
            <AddDependency
              title={titleMap[type]}
              setShowSelect={setShowSelect}
              showSelect={showSelect}
              scheduleIsLocked={scheduleIsLocked}
            />
          </div>
          {showSelect && (
            <SelectDependency
              newDependencyOptions={newDependencyOptions}
              onSelect={saveDependency}
              setShowSelect={setShowSelect}
              type={type}
            />
          )}
          {filteredDependencies.length > 0 ? (
            <div
              css={Css.df.fdc.gap1.if(viewType !== "task" && filteredDependencies.length > 3).oa.hPx(215).$}
              data-testid={`${type}s`}
            >
              {filteredDependencies.map((td) => {
                const taskNumber = taskNumberMap[td.id.value];
                return (
                  <Dependency
                    key={td.id.value}
                    td={td}
                    onJump={onJump}
                    scheduleIsLocked={scheduleIsLocked}
                    taskNumber={taskNumber}
                    clearFilters={clearFilters}
                    viewType={viewType}
                  />
                );
              })}
            </div>
          ) : (
            <NoTypesMessage type={type} />
          )}
        </>
      )}
    </Observer>
  );
}

function NewTaskDependency(props: TaskDependencyProps) {
  const { type, formStateDependencies, filteredTasks, onSave, formState, taskNumberMap = {}, viewType } = props;

  const [showSelect, setShowSelect] = useState(false);

  const filteredDependencies = useComputed(
    () => formStateDependencies.rows.filter((td) => td.delete.value !== true),
    [formStateDependencies],
  );

  const newDependencyOptions: NewDependencyOption[] = useMemo(
    () => {
      return filterDependencyOptions(filteredTasks, filteredDependencies, taskNumberMap);
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredDependencies, filteredTasks],
  );

  // Modify the form state when adding a dependency for a new task
  function updateFormStateHandler(opt: NewDependencyOption): void {
    const dependency = {
      name: opt.task.name,
      id: opt.task.id,
      type: TaskDependencyType.FinishStart,
      lagInDays: 0,
    };

    if (type === "successor") {
      formState.successorDependencies.set([...formState.successorDependencies.value, dependency]);
    } else {
      formState.predecessorDependencies.set([...formState.predecessorDependencies.value, dependency]);
    }
    onSave({});
  }

  // Update the form state when editing a dependency for a new task
  function updateSelectedDependencies(taskId: string, isUpdated: boolean, opt?: any, field?: string): void {
    if (type === "successor") {
      const successorDependencies = [...formState.successorDependencies.value];
      const dependency = successorDependencies.filter((td) => td.id === taskId)[0];

      if (isUpdated && opt && field) field === "code" ? (dependency.type = opt) : (dependency.lagInDays = opt);
      else successorDependencies.splice(successorDependencies.indexOf(dependency), 1);

      formState.successorDependencies.set([...successorDependencies]);
    } else {
      const predecessorDependencies = [...formState.predecessorDependencies.value];
      const dependency = predecessorDependencies.filter((td) => td.id === taskId)[0];

      if (isUpdated && opt && field) field === "code" ? (dependency.type = opt) : (dependency.lagInDays = opt);
      else predecessorDependencies.splice(predecessorDependencies.indexOf(dependency), 1);

      formState.predecessorDependencies.set([...predecessorDependencies]);
    }
    onSave({});
  }

  return (
    <Observer>
      {() => (
        <>
          <div css={Css.smMd.mb1.mt4.$}>
            <AddDependency title={titleMap[type]} setShowSelect={setShowSelect} showSelect={showSelect} />
          </div>
          {showSelect && (
            <SelectDependency
              newDependencyOptions={newDependencyOptions}
              onSelect={updateFormStateHandler}
              setShowSelect={setShowSelect}
              type={type}
            />
          )}
          {filteredDependencies.length > 0 ? (
            <div css={Css.df.fdc.gap1.$} data-testid={`${type}s`}>
              {filteredDependencies.map((td) => {
                const taskNumber = taskNumberMap[td.id.value];
                return (
                  <Dependency
                    key={td.id.value}
                    td={td}
                    updateDependency={updateSelectedDependencies}
                    isFilteredTaskInView={true}
                    taskNumber={taskNumber}
                    viewType={viewType}
                  />
                );
              })}
            </div>
          ) : (
            <NoTypesMessage type={type} />
          )}
        </>
      )}
    </Observer>
  );
}

function AddDependency(props: AddDependencyProps) {
  const { title, setShowSelect, showSelect, scheduleIsLocked } = props;

  return (
    <span css={Css.df.aic.jcsb.sm.gray700.$}>
      {pluralize(2, title)}
      <Button
        label={`Add a ${title}`}
        variant="tertiary"
        onClick={() => setShowSelect(true)}
        disabled={showSelect || scheduleIsLocked}
      />
    </span>
  );
}

function SelectDependency(props: SelectDependencyProps) {
  const { newDependencyOptions, onSelect, setShowSelect, scheduleIsLocked, type } = props;
  return (
    <div css={Css.df.jcsb.aic.p2.bgWhite.br8.mb1.$}>
      <SelectField
        options={newDependencyOptions}
        // Add task rowNumber to label to allow searching by number
        getOptionLabel={({ task, taskRowNumber }) => task.name + taskRowNumber}
        getOptionMenuLabel={({ node }) => node}
        getOptionValue={({ task }) => task.id}
        onSelect={(value, opt) => {
          if (opt) {
            onSelect(opt);
            setShowSelect(false);
          }
        }}
        label="tasks"
        labelStyle="hidden"
        value={"" as string}
        placeholder={`Search for a ${type.toLowerCase()}...`}
        compact
        disabled={scheduleIsLocked}
      />
    </div>
  );
}

function NoTypesMessage({ type }: { type: string }) {
  return (
    <div data-testid={`no-${type}s`} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
      There are no {type}s for this task
    </div>
  );
}

function Dependency(props: DependencyProps) {
  const { td, onJump, scheduleIsLocked, clearFilters, updateDependency, taskNumber, viewType = "task" } = props;
  const [hoveredId, setHoveredId] = useState("");
  const {
    scheduleState: { scheduleViewType },
  } = useScheduleStore();

  const filteredViewWarning = () => {
    if (taskNumber || scheduleViewType !== ScheduleViewType.List) return null;

    return (
      <div css={Css.df.aic.p2.pt0.tiny.$}>
        <div css={Css.pr1.$} data-testid={`${td.id.value}-out-of-view`}>
          This task is filtered out of your current view
        </div>
        <Button data-testid="clear-filters" label="Clear Filters" variant="text" onClick={clearFilters} />
      </div>
    );
  };

  return (
    <div css={Css.df.fdc.br8.bgGray100.$} key={td.id.value}>
      <div
        onPointerEnter={() => setHoveredId(td.id.value)}
        onPointerLeave={() => setHoveredId("")}
        css={Css.df.jcsb.aic.xs.p2.pr1.br8.$}
        key={td.id.value}
      >
        {scheduleViewType === ScheduleViewType.List && (
          <div data-testid={`${td.id.value}-task-number`} css={Css.wPx(24).mr2.$}>
            {taskNumber}
          </div>
        )}
        <div
          data-testid={`${td.id.value}-task-dependency-name`}
          css={Css.if(scheduleViewType !== ScheduleViewType.StandaloneTaskDetailPane).cursorPointer.blue700.$}
          onClick={() =>
            scheduleViewType !== ScheduleViewType.StandaloneTaskDetailPane && onJump && onJump(td.id.value)
          }
        >
          {td.name.value}
        </div>
        <div css={Css.wPx(76).fs0.mx1.if(viewType !== "task").mx3.$}>
          <BoundSelectField
            field={td.type}
            labelStyle="hidden"
            compact
            options={dependencyTypeDetails}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.code}
            data-testid={`type-${td.id.value}`}
            disabled={scheduleIsLocked}
            onSelect={(value) => {
              td.type.set(value);
              updateDependency && updateDependency(td.id.value, true, value, "code");
            }}
            borderless={false}
          />
        </div>
        <div css={Css.wPx(80).fs0.$}>
          <BoundNumberField
            field={td.lagInDays}
            labelStyle="hidden"
            compact
            displayDirection
            type="days"
            data-testid={`lag-${td.id.value}`}
            disabled={scheduleIsLocked}
            onChange={(value) => {
              td.lagInDays.set(value);
              updateDependency && updateDependency(td.id.value, true, value, "lagInDays");
            }}
            borderless={false}
          />
        </div>
        <HoverDelete
          data-testid={`delete-${td.id.value}`}
          visible={hoveredId === td.id.value && !scheduleIsLocked}
          disabled={scheduleIsLocked}
          onClick={() => {
            updateDependency ? updateDependency(td.id.value, false) : td.delete.set(true);
          }}
        />
      </div>
      {filteredViewWarning()}
    </div>
  );
}

const dependencyTypeDetails = [
  { code: TaskDependencyType.FinishFinish, name: "FF" },
  { code: TaskDependencyType.FinishStart, name: "FS" },
  { code: TaskDependencyType.StartFinish, name: "SF" },
  { code: TaskDependencyType.StartStart, name: "SS" },
];

const titleMap: Record<DependencyTypes, string> = {
  predecessor: "Predecessor",
  successor: "Successor",
};
