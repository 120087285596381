import {
  Button,
  column,
  GridColumn,
  GridDataRow,
  numericColumn,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { useParams } from "react-router-dom";
import { QueryTable } from "src/components";
import { ProjectItemsItemFragment, ProjectItemsQuery, useProjectItemsQuery } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/Routes";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { AddProjectItemModal } from "src/routes/projects/items/AddProjectItemModal";

export function ProjectItemsPage() {
  const { projectId } = useParams<ProjectRouteProps>();
  const query = useProjectItemsQuery({ variables: { projectId } });
  const { openModal } = useModal();

  return (
    <>
      <PageHeader title="Project Items" />
      <ScrollableContent>
        <TableActions onlyRight>
          <Button
            variant="secondary"
            label="Add Item"
            onClick={() =>
              openModal({
                content: (
                  <AddProjectItemModal
                    projectStageId={query.data?.project.activeProjectStage?.id || undefined}
                    items={query.data?.items || []}
                  />
                ),
                onClose: () => query.refetch().catch(console.error),
              })
            }
          />
        </TableActions>
        <QueryTable {...{ query, createRows: createItemRows, columns: createItemColumns() }} />
      </ScrollableContent>
    </>
  );
}

type ItemRow = SimpleHeaderAndData<ProjectItemsItemFragment>;

function createItemColumns(): GridColumn<ItemRow>[] {
  return [
    column<ItemRow>({ header: "Item", data: (d) => d.name }),
    numericColumn<ItemRow>({ header: "Quantity", data: (d) => d.quantity }),
    numericColumn<ItemRow>({ header: "Price", data: (d) => d.unitPriceInCents }),
    numericColumn<ItemRow>({ header: "Cost", data: (d) => d.unitCostInCents }),
  ];
}

function createItemRows(data: ProjectItemsQuery | undefined): GridDataRow<ItemRow>[] {
  return simpleDataRows(data?.project.activeProjectStage?.projectItems);
}
