import {
  Accordion,
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { FieldState, ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { pascalCase } from "change-case";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { createProductDetailsUrl } from "src/RouteUrls";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
} from "src/components/boundAttachments/BoundAttachments";
import {
  Maybe,
  ProductAttributeFragment,
  ProductAttributeType,
  ProductDimensionType,
  ProductInput,
  ProductItemFragment,
  ProductStatus,
  SaveProductDimensionInput,
  Scalars,
  useProductPageOptionsQuery,
  useSaveProductsMutation,
} from "src/generated/graphql-types";
import { formatList, queryResult } from "src/utils";
import { ImageAsset, ProductImageEditor } from "./components/ProductImageEditor";
import { ProductAttributeModal } from "./components/product-attribute-modal/ProductAttributeModal";

export function ProductPage() {
  const productOptionsQuery = useProductPageOptionsQuery();

  return queryResult(productOptionsQuery, {
    data: ({ items, attributes }) => (
      <ProductEditor items={items} attributes={attributes} productOptionsRefetch={productOptionsQuery.refetch} />
    ),
  });
}

type ProductEditorProps = {
  items: ProductItemFragment[];
  attributes: ProductAttributeFragment[];
  productOptionsRefetch: () => void;
};

function ProductEditor({ items, attributes, productOptionsRefetch }: ProductEditorProps) {
  const [saveProducts, { loading }] = useSaveProductsMutation();
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const tid = useTestIds({}, "productPage");
  const formState = useFormState({ config: productFormConfig });

  const handleSave = async () => {
    const {
      images: selectedImages,
      attachments: selectedAttachments,
      length,
      width,
      height,
      brandId,
      lineIds,
      finishIds,
      materialIds,
      designPackageIds,
      designPackageParentIds,
      designPackageLocationIds,
      ...others
    } = formState.value;

    const selectedAttributes = [
      ...(brandId ? [brandId] : []),
      ...(lineIds ?? []),
      ...(finishIds ?? []),
      ...(materialIds ?? []),
      ...(designPackageIds ?? []),
      ...(designPackageParentIds ?? []),
      ...(designPackageLocationIds ?? []),
    ];

    const attributes = selectedAttributes.map((att) => ({ id: att }));

    const dimensions: SaveProductDimensionInput[] = [
      ...(length ? [{ rawValue: length, dimensionType: ProductDimensionType.Length }] : []),
      ...(height ? [{ rawValue: height, dimensionType: ProductDimensionType.Height }] : []),
      ...(width ? [{ rawValue: width, dimensionType: ProductDimensionType.Width }] : []),
    ];

    const images = selectedImages?.map((img) => omit(img, ["downloadUrl", "attachmentUrl"])) || [];

    const attachments = selectedAttachments?.map(({ asset, ...props }) => ({
      ...props,
      asset: pick(asset, [
        // Dropping downloadUrl, attachmentUrl and createdAt to get the AssetInput shape
        "contentType",
        "fileName",
        "id",
        "s3Key",
        "sizeInBytes",
        "delete",
      ]),
    }));

    const { data } = await saveProducts({
      variables: { input: { products: [{ ...others, dimensions, attributes, attachments, images }] } },
    });

    const savedProduct = data?.saveProducts.products.first;

    if (savedProduct) {
      triggerNotice({
        message: `Product: ${savedProduct.name} (${savedProduct.id}) created successfully`,
        icon: "success",
      });
      history.push(createProductDetailsUrl(savedProduct.id));
    }
  };

  return (
    <div>
      <header {...tid.title} css={Css.xl2Bd.py3.$}>
        Add a New Product
      </header>
      <div css={Css.df.$}>
        <div css={Css.w50.pb8.$}>
          <ProductForm
            isNew={true}
            readOnly={false}
            attributes={attributes}
            items={items}
            formState={formState}
            productOptionsRefetch={productOptionsRefetch}
          />
        </div>
      </div>
      <footer
        css={Css.df.fixed.z999.bottom0.left0.right0.bgGray100.p4.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$}
      >
        <div css={Css.mla.$}>
          <Observer>
            {() => <Button disabled={loading || !formState.dirty} label="Add Product" onClick={handleSave} />}
          </Observer>
        </div>
      </footer>
    </div>
  );
}

type ProductFormValue = ProductInput & {
  images: ImageAsset[];
  attachments: SaveAttachmentModel[];
  length: string | null | undefined;
  width: string | null | undefined;
  height: string | null | undefined;
  status: string | null | undefined;
  brandId?: Maybe<Scalars["ID"]>;
  lineIds?: Maybe<Array<Scalars["ID"]>>;
  finishIds?: Maybe<Array<Scalars["ID"]>>;
  materialIds?: Maybe<Array<Scalars["ID"]>>;
  designPackageIds?: Maybe<Array<Scalars["ID"]>>;
  designPackageParentIds?: Maybe<Array<Scalars["ID"]>>;
  designPackageLocationIds?: Maybe<Array<Scalars["ID"]>>;
};

export type ProductPageFormState = ObjectState<ProductFormValue>;

export const productFormConfig: ObjectConfig<ProductFormValue> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  itemIds: { type: "value", rules: [required] },
  status: { type: "value" },
  sku: { type: "value" },
  msrpInCents: { type: "value" },
  manufacturerUrl: { type: "value" },
  description: { type: "value" },
  specifications: { type: "value" },
  length: { type: "value" },
  width: { type: "value" },
  height: { type: "value" },
  brandId: { type: "value" },
  lineIds: { type: "value" },
  finishIds: { type: "value" },
  materialIds: { type: "value" },
  designPackageIds: { type: "value" },
  designPackageParentIds: { type: "value" },
  designPackageLocationIds: { type: "value" },
  attachments: { type: "list", config: attachmentConfig },
  images: {
    type: "list",
    config: {
      id: { type: "value" },
      fileName: { type: "value" },
      downloadUrl: { type: "value" },
      attachmentUrl: { type: "value" },
      contentType: { type: "value" },
    },
  },
};

type ProductFormProps = {
  isNew: boolean;
  readOnly: boolean;
  formState: ObjectState<ProductFormValue>;
  attributes: ProductAttributeFragment[];
  items: ProductItemFragment[];
  productOptionsRefetch: () => void;
};

export function ProductForm(props: ProductFormProps) {
  const { isNew, readOnly, formState, attributes, items, productOptionsRefetch } = props;
  const tid = useTestIds({}, "productForm");
  const { openModal } = useModal();

  const addOption = (readOnly: boolean, type: ProductAttributeType, options: ProductAttributeFragment[]) => {
    const _options: ProductAttributeFragment[] = [];
    if (!readOnly) {
      _options.push({ id: "-1", value: "Add New " + pascalCase(type), type });
    }
    _options.push(...options);
    return _options;
  };

  const addSelect =
    (type: ProductAttributeType, field: FieldState<Maybe<string>>) =>
    (value: string | undefined, opt: ProductAttributeFragment | undefined) => {
      // if this is the add option then open product attribute modal
      if (opt?.id === "-1") {
        openModal({
          content: (
            <ProductAttributeModal
              type={type}
              productOptionsRefetch={productOptionsRefetch}
              onSelect={(selectValue: string) => field.set(selectValue)}
            />
          ),
          size: "xl",
        });
      } else {
        field.set(value);
      }
    };

  const addSelectMulti =
    (type: ProductAttributeType, field: FieldState<Maybe<string[]>>) =>
    (value: string[], opt: ProductAttributeFragment[]) => {
      // if this is the add option then open product attribute modal
      if (opt[0]?.id === "-1") {
        // remove the add option since we don't actually want to select it
        opt.findAndRemove((o) => o.id === "-1");

        openModal({
          content: (
            <ProductAttributeModal
              type={type}
              productOptionsRefetch={productOptionsRefetch}
              onSelect={(selectValue: string) => field.set([...(field.value || []), selectValue])}
            />
          ),
          size: "xl",
        });
      } else {
        field.set(value);
      }
    };

  return (
    <>
      <Accordion title="Product Overview" {...tid.overview} defaultExpanded={isNew}>
        <FormLines labelStyle="left" width="full">
          <BoundTextField
            {...tid.name}
            readOnly={readOnly}
            field={formState.name}
            label="Product Name"
            labelStyle="left"
          />
          <div css={Css.df.fdr.$}>
            <BoundSelectField
              {...tid.brand}
              readOnly={readOnly}
              field={formState.brandId}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={addOption(
                readOnly,
                ProductAttributeType.Brand,
                attributes.filter((att) => att.type === ProductAttributeType.Brand),
              )}
              onSelect={addSelect(ProductAttributeType.Brand, formState.brandId)}
              label="Brand"
            />
          </div>
          {readOnly ? (
            <ProductAttributeList
              {...tid.items}
              title="Item code(s)"
              values={items.filter((item) => formState.itemIds.value?.includes(item.id)).map(({ name }) => name)}
            />
          ) : (
            <BoundMultiSelectField readOnly={readOnly} field={formState.itemIds} options={items} label="Items" />
          )}
          {readOnly ? (
            <ProductAttributeList
              {...tid.finish}
              title="Finish"
              values={attributes
                .filter((att) => att.type === ProductAttributeType.Finish)
                .filter((finish) => formState.finishIds.value?.includes(finish.id))
                .map(({ value }) => value)}
            />
          ) : (
            <BoundMultiSelectField
              readOnly={readOnly}
              field={formState.finishIds}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={addOption(
                readOnly,
                ProductAttributeType.Finish,
                attributes.filter((att) => att.type === ProductAttributeType.Finish),
              )}
              onSelect={addSelectMulti(ProductAttributeType.Finish, formState.finishIds)}
              label="Finish"
            />
          )}
          <BoundTextField {...tid.sku} readOnly={readOnly} field={formState.sku} label="SKU / Model Number" />
          {isNew && <ProductImageEditor formState={formState} />}
          {readOnly ? (
            <ProductAttributeList
              {...tid.line}
              title="Line"
              values={attributes
                .filter((att) => att.type === ProductAttributeType.Line)
                .filter((line) => formState.lineIds.value?.includes(line.id))
                .map(({ value }) => value)}
            />
          ) : (
            <BoundMultiSelectField
              readOnly={readOnly}
              field={formState.lineIds}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={attributes.filter((att) => att.type === ProductAttributeType.Line)}
              label="Line"
            />
          )}
          <BoundSelectField
            {...tid.status}
            readOnly={readOnly}
            field={formState.status}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ value }) => value}
            options={Object.values(ProductStatus).map((status) => ({ id: status, value: status }))}
            label="Status"
          />
        </FormLines>
      </Accordion>
      <Accordion title="Design Package Details" {...tid.designDetails} defaultExpanded={isNew}>
        <FormLines labelStyle="left" width="full">
          {readOnly ? (
            <ProductAttributeList
              {...tid.designPackage}
              title="Design Package"
              values={attributes
                .filter((att) => att.type === ProductAttributeType.DesignPackage)
                .filter((dp) => formState.designPackageIds.value?.includes(dp.id))
                .map(({ value }) => value)}
            />
          ) : (
            <BoundMultiSelectField
              readOnly={readOnly}
              field={formState.designPackageIds}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={attributes.filter((att) => att.type === ProductAttributeType.DesignPackage)}
              label="Design Package"
            />
          )}
          {readOnly ? (
            <ProductAttributeList
              {...tid.designParentItem}
              title="Design Package Parent Item"
              values={attributes
                .filter((att) => att.type === ProductAttributeType.DesignPackageItem)
                .filter((dpItem) => formState.designPackageParentIds.value?.includes(dpItem.id))
                .map(({ value }) => value)}
            />
          ) : (
            <BoundMultiSelectField
              {...tid.designParentItem}
              readOnly={readOnly}
              field={formState.designPackageParentIds}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={attributes.filter((att) => att.type === ProductAttributeType.DesignPackageItem)}
              label="Design Package Parent Item"
            />
          )}{" "}
          {readOnly ? (
            <ProductAttributeList
              {...tid.designLocation}
              title="Design Package Location"
              values={attributes
                .filter((att) => att.type === ProductAttributeType.DesignPackageLocation)
                .filter((dpLocation) => formState.designPackageLocationIds.value?.includes(dpLocation.id))
                .map(({ value }) => value)}
            />
          ) : (
            <BoundMultiSelectField
              readOnly={readOnly}
              field={formState.designPackageLocationIds}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={attributes.filter((att) => att.type === ProductAttributeType.DesignPackageLocation)}
              label="Design Package Location"
            />
          )}
        </FormLines>
      </Accordion>
      <Accordion title="Manufacturer Information" {...tid.manufacturerInfo} defaultExpanded={isNew}>
        <FormLines labelStyle="left" width="full">
          <div css={Css.df.$}>
            <div css={Css.mw50.sm.gray700.mya.$}>Dimensions {!readOnly && <span>(Length, Width, Height)</span>}</div>
            <div css={Css.df.cg2.if(readOnly).cg4.$}>
              <>
                <BoundTextField
                  readOnly={readOnly}
                  field={formState.length}
                  label="Length"
                  labelStyle={readOnly ? "above" : "hidden"}
                />
                <BoundTextField
                  readOnly={readOnly}
                  field={formState.width}
                  label="Width"
                  labelStyle={readOnly ? "above" : "hidden"}
                />
                <BoundTextField
                  readOnly={readOnly}
                  field={formState.height}
                  label="Height"
                  labelStyle={readOnly ? "above" : "hidden"}
                />
              </>
            </div>
          </div>
          <BoundNumberField {...tid.msrp} readOnly={readOnly} field={formState.msrpInCents} label="MSRP" />
          <BoundTextField {...tid.url} readOnly={readOnly} field={formState.manufacturerUrl} label="Manufacturer URL" />
          <BoundTextAreaField
            {...tid.description}
            readOnly={readOnly}
            field={formState.description}
            label="Description"
          />
          {readOnly ? (
            <ProductAttributeList
              {...tid.material}
              title="Material"
              values={attributes
                .filter((att) => att.type === ProductAttributeType.Material)
                .filter((material) => formState.materialIds.value?.includes(material.id))
                .map(({ value }) => value)}
            />
          ) : (
            <BoundMultiSelectField
              readOnly={readOnly}
              field={formState.materialIds}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ value }) => value}
              options={addOption(
                readOnly,
                ProductAttributeType.Material,
                attributes.filter((att) => att.type === ProductAttributeType.Material),
              )}
              onSelect={addSelectMulti(ProductAttributeType.Material, formState.materialIds)}
              label="Material"
            />
          )}
          <BoundTextAreaField
            {...tid.specifications}
            readOnly={readOnly}
            field={formState.specifications}
            label="Product Specifications"
          />
        </FormLines>
        <BoundAttachments readonly={readOnly} field={formState.attachments} title="Additional documents" />
      </Accordion>
    </>
  );
}

type FormatListProps = {
  title: string;
  values: string[];
};

function ProductAttributeList(props: FormatListProps) {
  const { title, values, ...other } = props;
  return (
    <div css={Css.df.$} {...other}>
      <div css={Css.gray700.w50.$}>{title}</div>
      <div css={Css.w50.smMd.$}>{values.isEmpty ? "-" : formatList(values)}</div>
    </div>
  );
}
