import { Chips, Css, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { ProjectItemsSection } from "src/routes/projects/dynamic-schedules/utils";

export function CostAllocations({ projectItems, inSidePane = false }: ProjectItemsSection) {
  const tid = useTestIds({}, "costAllocations");

  const uniqueItemCodes = useMemo(() => projectItems.map((pi) => pi.item.fullCode).unique(), [projectItems]);

  return (
    <div css={Css.if(!inSidePane).bcGray300.bb.pb3.$}>
      <div css={Css.my2.$}>
        <span css={Css.df.aic.jcsb.baseSb.gray800.$}>Cost Allocations</span>
      </div>
      {uniqueItemCodes.nonEmpty ? (
        <Chips values={uniqueItemCodes} />
      ) : (
        <div {...tid.noCostAllocations} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no allocated project items
        </div>
      )}
    </div>
  );
}
