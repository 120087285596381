import { Css, Tag, Tooltip } from "@homebound/beam";
import { AddProductOfferingConfig_ReadyPlanOptionsFragment } from "src/generated/graphql-types";
import { pluralize } from "src/utils";

export function PrereqTag(props: {
  preReqs: AddProductOfferingConfig_ReadyPlanOptionsFragment["optionPrerequisites"];
}) {
  const { preReqs } = props;

  return (
    <div css={Css.h2.wPx(100).$}>
      <Tooltip
        title={
          <>
            <p>Preqrequisite(s):&nbsp;{preReqs.map((pr) => pr.displayName).join(", ")}</p>
            <br />
            <p>Prereqs are auto removed if this option is deselected</p>
          </>
        }
      >
        <Tag text={`${preReqs.length} ${pluralize(preReqs.length, "prereq")}`} type="caution" />
      </Tooltip>
    </div>
  );
}
