import { Css, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { useParams } from "react-router";
import { useTaskLookaheadQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { WeekRangeFilter } from "../WeekRangeFilter";
import { useWeekRangeFilter } from "../useWeekRangeFilter";
import { TaskLookaheadTable } from "./TaskLookaheadTable";

const WEEKS_TO_SHOW = 8;

export function TaskLookaheadPage() {
  const { developmentId } = useParams<DevelopmentParams>();

  const tid = useTestIds({}, "taskLookaheadPage");
  const rangeFilter = useWeekRangeFilter({ weekCount: WEEKS_TO_SHOW });
  const { includedWeekIntervals, dateRange } = rangeFilter;

  const query = useTaskLookaheadQuery({
    variables: {
      developmentId,
      searchIntervalEnd: dateRange.end,
      searchIntervalStart: dateRange.start,
    },
  });

  return (
    <div {...tid}>
      <PageHeader title="Task Lookahead" xss={Css.bgWhite.$} />
      <WeekRangeFilter rangeFilter={rangeFilter} loading={query.loading} />
      {queryResult(query, {
        loading: () => (
          <>
            <LoadingSkeleton rows={1} columns={1} size="lg" />
            <LoadingSkeleton rows={10} columns={6} size="lg" />
          </>
        ),
        data: (data) => (
          <TaskLookaheadTable
            queryData={data}
            includedWeekIntervals={includedWeekIntervals}
            developmentId={developmentId}
            loading={query.loading}
          />
        ),
      })}
    </div>
  );
}
