import {
  column,
  dateColumn,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  usePersistedFilter,
} from "@homebound/beam";
import { useParams } from "react-router-dom";
import { dateCell, priceCell } from "src/components";
import { ProjectEstimatesPageEstimateFragment, useProjectEstimatesPageQuery } from "src/generated/graphql-types";
import {
  mapToProjectStagesFilter,
  SingleStageFilter,
  useProjectStageSingleFilter,
} from "src/hooks/useProjectStageSingleFilter";
import { useStripStage } from "src/hooks/useStripStage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { createEstimateUrl } from "src/RouteUrls";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";

export function ProjectEstimatesPage() {
  useStripStage();
  const { projectId } = useParams<ProjectParams>();
  const { clientNoun, latestActiveStage } = useProjectContext();
  const { filterDefs } = useProjectStageSingleFilter(latestActiveStage);
  const { setFilter, filter } = usePersistedFilter<SingleStageFilter>({
    storageKey: "estimatesFilter",
    filterDefs,
  });

  return (
    <>
      <PageHeader title="Estimates" />
      <TableActions>
        <Filters<SingleStageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
      </TableActions>
      <ScrollableContent>
        <EstimatesTable clientNoun={clientNoun} projectId={projectId} filter={filter} />
      </ScrollableContent>
    </>
  );
}

type EstimatesTableProps = {
  clientNoun: string;
  projectId: string;
  filter: SingleStageFilter;
};

function EstimatesTable({ clientNoun, projectId, filter }: EstimatesTableProps) {
  const query = useProjectEstimatesPageQuery({
    variables: { projectId, stagesFilter: mapToProjectStagesFilter(filter) },
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const { project } = query.data;
  const estimates = project.stages.flatMap((s) => s.estimates);

  const columns = createColumns(clientNoun);

  return (
    <GridTable
      rowStyles={createRowStyles(projectId)}
      columns={columns}
      rows={createRows(estimates)}
      fallbackMessage="There are no estimates for this project."
      stickyHeader
    />
  );
}

type Row = SimpleHeaderAndData<ProjectEstimatesPageEstimateFragment>;

const createColumns = (clientNoun: string): GridColumn<Row>[] => [
  column<Row>({ header: "Estimate", data: ({ title }) => title }),
  column<Row>({ header: "Stage", data: ({ projectStage }) => projectStage.stage.name, w: "184px" }),
  column<Row>({ header: "Status", data: ({ status }) => status.name }),
  column<Row>({
    header: "Contract Type",
    data: ({ changeEvent }) => (changeEvent ? "H/O Change Order" : "Prime Contract"),
    w: "172px",
  }),
  column<Row>({ header: "Type", data: ({ type }) => type.name }),
  numericColumn<Row>({
    header: "Estimated Cost",
    data: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
  }),
  numericColumn<Row>({
    header: "Markup",
    data: ({ totalMarkupInCents }) => priceCell({ valueInCents: totalMarkupInCents }),
  }),
  numericColumn<Row>({
    header: `${clientNoun} Price`,
    data: ({ totalPriceInCents }) => priceCell({ valueInCents: totalPriceInCents }),
  }),
  dateColumn<Row>({ header: "Last Updated", data: ({ updatedAt }) => dateCell(updatedAt) }),
];

function createRowStyles(projectId: string): RowStyles<Row> {
  return {
    header: {},
    data: { rowLink: ({ id }) => createEstimateUrl(projectId, id) },
  };
}

function createRows(estimates: ProjectEstimatesPageEstimateFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(estimates);
}
