import FileSaver from "file-saver";
import JsZip from "jszip";

export type DownloadRequest = {
  url: string;
  filename: string;
};

export async function downloadFilesToZip(zipName: string, requests: DownloadRequest[]) {
  const blobs = await Promise.all(
    requests.map(async (r) => {
      const resp = await fetch(r.url, { method: "GET" });
      return { request: r, blob: resp.blob() };
    }),
  );

  const zip = JsZip();
  blobs.forEach(({ request, blob }, i) => {
    zip.file(request.filename, blob);
  });

  const result = await zip.generateAsync({ type: "blob" });
  FileSaver.saveAs(result, zipName);
}
