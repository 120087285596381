import { useCallback, useState } from "react";

type UseStateInitialState<T extends unknown> = T | (() => T);
type UseSetStateTuple<T extends unknown> = [
  T,
  (patch: Partial<T> | ((prevState: T) => Partial<T>), options?: { replace?: boolean }) => void,
];
/**
 * Provides a React Class setState-like interface where you can call
 * setState({ someKey: "value" }) without blowing away other keys that
 * aren't `someKey`.
 */
function useSetState<T extends unknown>(initialState: UseStateInitialState<T> = {} as T): UseSetStateTuple<T> {
  const [state, set] = useState<T>(initialState);

  const setState: UseSetStateTuple<T>[1] = useCallback((patch, config = {}) => {
    set((prevState) =>
      Object.assign(
        {}, // fold into new object
        config.replace ? undefined : prevState, // Only fold in prior state if not replacing
        patch instanceof Function ? patch(prevState) : patch,
      ),
    );
  }, []);

  return [state, setState];
}

export default useSetState;
