import { Button, Css, Icon, IconButton, Palette, Tag, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  AssetInfoFragment,
  DocumentDetailDocumentTypeFragment,
  RequiredTaskDocumentDetailFragment,
  SavePlanTaskInput,
  TaskStatus,
  useSaveRequiredTaskDocumentMutation,
  useSaveRequiredTaskDocumentOnPlanTaskMutation,
} from "src/generated/graphql-types";
import { createAssetLabel } from "src/routes/projects/schedule-v2/detail-pane/TaskDocuments";
import { partition } from "src/utils";
import { FormValue } from "./TaskDetailPane";
import { LinkDocumentRequirementModal } from "./document-requirements/LinkDocumentRequirementModal";

type RequiredTaskDocumentsProps = {
  formState: ObjectState<SavePlanTaskInput> | FormValue;
  parentId: string;
  requiredTaskDocuments: RequiredTaskDocumentDetailFragment[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
  onPlanTask?: boolean;
  taskStatus?: TaskStatus;
};

export function RequiredTaskDocuments({
  documentTypes,
  formState,
  parentId,
  requiredTaskDocuments,
  taskStatus,
  onPlanTask,
}: RequiredTaskDocumentsProps) {
  const [saveRequiredTaskDocument] = useSaveRequiredTaskDocumentMutation();
  // This can be removed once #SC-49625 (canComplete) has been implemented for PlanTask
  const [saveRequiredTaskDocumentOnPlanTask] = useSaveRequiredTaskDocumentOnPlanTaskMutation();
  const { openModal } = useModal();

  const renderAssetGallery = (text: string, asset: AssetInfoFragment) => (
    <AssetGallery assets={[asset]}>
      {(openGallery) => (
        <div css={Css.df.xs.gap1.$}>
          <Button label={text} variant="text" onClick={() => openGallery(asset)} />
        </div>
      )}
    </AssetGallery>
  );

  const handleAddClick = (requiredTaskDocument: RequiredTaskDocumentDetailFragment) => {
    openModal({
      content: (
        <LinkDocumentRequirementModal
          documentTypes={documentTypes}
          parentId={parentId}
          requiredTaskDocument={requiredTaskDocument}
          onPlanTask={onPlanTask}
        />
      ),
    });
  };

  const renderRequiredTaskDoc = (requiredTaskDocument: RequiredTaskDocumentDetailFragment) => {
    const { id, name, sampleAsset, document } = requiredTaskDocument;
    return (
      <div key={id} css={Css.df.jcsb.py1.px0.if(!document).bb.bw1.bcGray200.$}>
        <div css={Css.df.aic.gap1.xsMd.$}>
          {sampleAsset && <Tag text="Sample" type="info" />}
          {sampleAsset ? (
            renderAssetGallery(createAssetLabel(name, sampleAsset), sampleAsset)
          ) : (
            <span css={Css.gray700.xsMd.$}>{name}</span>
          )}
        </div>
        <div css={Css.df.gap1.$}>
          {sampleAsset && (
            <IconButton icon="download" tooltip={"Download sample document"} onClick={sampleAsset.attachmentUrl} />
          )}
          {!document && <Button onClick={() => handleAddClick(requiredTaskDocument)} label={"Add"} />}
        </div>
      </div>
    );
  };

  const renderUploadedRequiredTaskDoc = (requiredTaskDocument: RequiredTaskDocumentDetailFragment) => {
    const { id, document } = requiredTaskDocument;
    const { id: documentId, name, asset } = document!;
    return (
      <div css={Css.df.fdr.aic.jcsb.mb1.pt1.br4.$}>
        {renderAssetGallery(createAssetLabel(name, asset, 35), asset)}
        <div css={Css.df.fdr.jcfe.gap1.w100.$}>
          <IconButton
            data-testid="deleteRequiredTaskDocument"
            icon="x"
            color={Palette.Gray600}
            // disabled if the task is complete
            disabled={taskStatus === TaskStatus.Complete}
            onClick={async () => {
              // The first mutation can be removed once #SC-49625 (canComplete) has been implemented for PlanTask
              const mutation = onPlanTask ? saveRequiredTaskDocumentOnPlanTask : saveRequiredTaskDocument;
              await mutation({
                variables: { input: { id: id, documentId: null } },
              });
              formState.documents.set(formState.documents.value?.filter((docId) => docId !== documentId));
            }}
          />
        </div>
      </div>
    );
  };

  const [withDoc, withOutDoc] = partition(requiredTaskDocuments, ({ document }) => !!document);

  return (
    <div css={Css.mb4.$} data-testid="rtd">
      <div css={Css.df.jcsb.gray900.smMd.$}>
        <div css={Css.df.jcsb.aic.gap1.baseSb.$}>
          <span>Required Documents</span>
          <Icon
            icon="infoCircle"
            inc={2}
            tooltip="These documents must be uploaded to this task in order to mark it as 'Complete'."
          />
        </div>
        <div css={Css.df.aic.gap1.$}>
          <Icon
            icon="checkCircle"
            color={requiredTaskDocuments.length === withDoc.length ? Palette.Green500 : Palette.Transparent}
          />
          <span>
            {withDoc.length}/{requiredTaskDocuments.length} Added
          </span>
        </div>
      </div>
      <div css={Css.mt2.$}>
        {withDoc.map((rtd) => (
          <div key={rtd.id}>
            {rtd.sampleAsset && renderRequiredTaskDoc(rtd)}
            {renderUploadedRequiredTaskDoc(rtd)}
          </div>
        ))}
        {withOutDoc.map((rtd) => renderRequiredTaskDoc(rtd))}
      </div>
    </div>
  );
}
