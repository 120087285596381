import {
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  MaxLines,
  simpleHeader,
  useTestIds,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { dateCell, tagCell } from "src/components";
import { BudgetSuperDrawer_ChangeEventFragment } from "src/generated/graphql-types";
import { createCommitmentChangeOrderUrl, createCommitmentUrl } from "src/RouteUrls";
import {
  changeEventStatusToNameMapper,
  changeEventStatusToTagTypeMapper,
  changeEventTypeToNameMapper,
  formatCentsToPrice,
} from "src/utils";

export type BudgetChangeEventTableProps = {
  changeEvents: BudgetSuperDrawer_ChangeEventFragment[];
  projectId: string;
  projectItemIds: string[];
  title: string;
};

export function BudgetChangeEventsTable(props: BudgetChangeEventTableProps) {
  const { changeEvents, projectId, title, projectItemIds } = props;
  const rows = createRows(changeEvents, projectItemIds);
  const tid = useTestIds({});
  const columns = createColumns(projectId, projectItemIds, tid);

  return (
    <div>
      <div css={Css.smSb.h3.mb2.$} data-testid="title">
        {title}
      </div>
      <GridTable id="changeEvents" columns={columns} rows={rows} />
    </div>
  );
}

function createRows(
  changeEvents: BudgetSuperDrawer_ChangeEventFragment[],
  projectItemIds: string[],
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...changeEvents.map((ce, i) => ({
      kind: "changeEvent" as const,
      id: String(i),
      data: ce,
    })),
    {
      kind: "totals" as const,
      id: "total",
      data: {
        changeEvents: changeEvents.sum((ce) => ce.totalCostInCents),
        perItems: changeEvents.sum((ce) =>
          ce.lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.totalCostInCents : 0)),
        ),
      },
    },
  ];
}

function createColumns(projectId: string, projectItemIds: string[], tid: Record<string, object>): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Change Event",
      changeEvent: ({ name, blueprintUrl }) => <Link to={blueprintUrl.path}>{name}</Link>,
      totals: "Total",
      w: "200px",
    }),
    column<Row>({
      header: "Type",
      changeEvent: ({ type }) => changeEventTypeToNameMapper(type.code),
      totals: emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: "Status",
      changeEvent: ({ status }) =>
        tagCell(changeEventStatusToTagTypeMapper[status], changeEventStatusToNameMapper[status]),
      totals: emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: "Accept Date",
      changeEvent: ({ acceptedAt }) => dateCell(acceptedAt),
      totals: emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: "Description",
      changeEvent: ({ internalNote }) => <MaxLines maxLines={3}>{internalNote}</MaxLines>,
      totals: emptyCell,
      w: "180px",
    }),
    column<Row>({
      header: "Reason for Change",
      changeEvent: ({ reason }) => reason.name,
      totals: emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: "Cost Change",
      changeEvent: ({ totalCostInCents }) => formatCentsToPrice(totalCostInCents),
      totals: (data) => formatCentsToPrice(data.changeEvents),
      w: "100px",
    }),
    column<Row>({
      header: "Per Item Cost",
      changeEvent: ({
        lineItems,
        // If celi is for the current budget super drawer item sum celi in the `per item cost` col
      }) =>
        formatCentsToPrice(
          lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.totalCostInCents : 0)),
        ),
      totals: (data) => formatCentsToPrice(data.perItems),
      w: "100px",
    }),
    column<Row>({
      header: "Related Contracts",
      changeEvent: ({ homeownerContractChangeOrder, commitments }) => ({
        content: (
          <div css={Css.df.fdc.wsn.xs.$}>
            <MaxLines maxLines={3}>
              {homeownerContractChangeOrder?.name && (
                <div>
                  <Link to={homeownerContractChangeOrder.blueprintUrl.path}>{homeownerContractChangeOrder.name}</Link>
                  <br />
                </div>
              )}
              {commitments.map((c) => (
                <div key={c.name}>
                  {c.__typename === "CommitmentChangeOrder" && c.commitment.id ? (
                    <Link
                      {...tid.relatedContract}
                      to={createCommitmentChangeOrderUrl(projectId, c.commitment.id, c.id)}
                    >{`${c.accountingNumber}-${c.displayName}`}</Link>
                  ) : (
                    <Link
                      {...tid.relatedContract}
                      to={createCommitmentUrl(projectId, c.id)}
                    >{`PO #${c.accountingNumber}-${c.displayName}`}</Link>
                  )}
                </div>
              ))}
            </MaxLines>
          </div>
        ),
      }),
      totals: emptyCell,
      w: "200px",
    }),
  ];
}

type Row = { kind: "header" } | ChangeEventRow | TotalRow;

type ChangeEventRow = {
  kind: "changeEvent";
  id: string;
  data: BudgetSuperDrawer_ChangeEventFragment;
};

type TotalRow = {
  kind: "totals";
  data: {
    changeEvents: number;
    perItems: number;
  };
};
