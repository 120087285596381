import { Button, Css, Switch, useComputed, useGridTableApi } from "@homebound/beam";
import { Dispatch, SetStateAction } from "react";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  HomeownerContractDrawItemFragment,
  InvoiceV2Fragment,
  TaskStatus,
  useConfirmDrawProjectStageQuery,
  useSaveInvoiceV2Mutation,
} from "src/generated/graphql-types";
import useSetState from "src/hooks/useSetState";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { PageHeader } from "src/routes/layout/PageHeader";
import { InvoiceDateFilter } from "src/routes/projects/invoices/invoice-v2/components/InvoiceDateFilter";
import { InvoiceStepEnum } from "src/routes/projects/invoices/invoice-v2/enums";
import { queryResult } from "src/utils";
import { DateOnly, ensureDateOnly } from "src/utils/dates";
import { ConfirmDrawAmountTable, CreateDrawAmountRow } from "../components/ConfirmDrawAmountTable";

type ConfirmDrawAmountProp = {
  draftInvoice: InvoiceV2Fragment;
  setDraftInvoice: Dispatch<SetStateAction<InvoiceV2Fragment | undefined>>;
  onExit: () => void;
  startDate: DateOnly;
};

export function ConfirmDrawAmount({ draftInvoice, onExit, setDraftInvoice, startDate }: ConfirmDrawAmountProp) {
  const projectStageId = draftInvoice.projectStage.id;
  const query = useConfirmDrawProjectStageQuery({ variables: { projectStageId } });

  return queryResult(query, {
    data: ({ projectStage }) => {
      const draws = projectStage.allContractDraws;
      return (
        <ConfirmDrawAmountView
          onExit={onExit}
          setDraftInvoice={setDraftInvoice}
          draftInvoice={draftInvoice}
          draws={draws}
          startDate={startDate}
        />
      );
    },
  });
}

type ConfirmDrawAmountProps = {
  draws: HomeownerContractDrawItemFragment[];
  setDraftInvoice: Dispatch<SetStateAction<InvoiceV2Fragment | undefined>>;
  draftInvoice: InvoiceV2Fragment;
  onExit: () => void;
  startDate: DateOnly;
};

export type DrawAmountItemsFilter = {
  startDate: DateOnly;
  endDate: DateOnly;
  showSuggestedDraws: boolean;
};

function ConfirmDrawAmountView({ draws, draftInvoice, onExit, setDraftInvoice, startDate }: ConfirmDrawAmountProps) {
  const invoiceId = draftInvoice.id;
  const { hasCostsToInvoice, hasFixedToInvoice } = draftInvoice.projectStage;
  const [filter, setFilter] = useSetState<DrawAmountItemsFilter>({
    startDate,
    endDate: ensureDateOnly(draftInvoice.lineItemCutoffDate!),
    showSuggestedDraws: true,
  });
  const { setSteps, nextStepEvenIfDisabled } = useStepperContext();
  const [saveInvoice] = useSaveInvoiceV2Mutation();
  const tableApi = useGridTableApi<CreateDrawAmountRow>();
  const filteredDraws = draws.filter((draw) => {
    if (filter.showSuggestedDraws) {
      return (
        draw.task.status.code === TaskStatus.Complete &&
        (!draw.invoiceDrawLineItem?.invoice.id || draw.invoiceDrawLineItem.invoice.id === invoiceId) &&
        draw.task.interval.endDate <= filter.endDate
      );
    }
    return true;
  });
  const selectedDrawIds = useComputed(() => tableApi.getSelectedRowIds("drawAmountRow"), [tableApi]);

  const onSave = async (): Promise<void> => {
    const savedDraws = draws.filter((item) => draftInvoice.drawLineItems.some(({ draw }) => draw.id === item.id));

    const canBeSelectedDraws = draws.filter((draw) => {
      const taskComplete = draw.task.status.code === TaskStatus.Complete;
      const hasInvoice = draw.invoiceDrawLineItem?.invoice.id && draw.invoiceDrawLineItem.invoice.id !== invoiceId;
      return taskComplete && !hasInvoice;
    });

    const drawsToSave = canBeSelectedDraws
      .filter((draw) => {
        const isSelected = selectedDrawIds.some((id) => id === draw.id);
        const isSaved = savedDraws.some((item) => draw.id === item.id);
        return isSelected && !isSaved;
      })
      .map((item) => {
        return {
          drawId: item.id,
        };
      });

    const unselectedDraws = savedDraws
      .filter((item) => !selectedDrawIds.some((id) => id === item.id))
      .map((item) => {
        return {
          drawId: item.id,
          delete: true,
        };
      });
    const invoiceResult = await saveInvoice({
      variables: {
        input: {
          id: invoiceId,
          drawLineItems: [...drawsToSave, ...unselectedDraws],
          lineItemCutoffDate: filter.endDate,
        },
      },
    });

    const invoice = invoiceResult.data?.saveInvoice?.invoice;
    setDraftInvoice(invoice);
  };

  return (
    <>
      <PageHeader
        title={"Confirm Draw Amount"}
        breadcrumb={{
          label: "NEW INVOICE",
          href: "#",
        }}
        right={
          <div css={Css.df.jcsb.gap4.$}>
            <ExitIconButton showModal={false} onCloseConfirmed={onExit} />
          </div>
        }
      />

      <div css={Css.pr3.w("55%").$}>
        <h2 css={Css.lgSb.mb2.$}>Invoice “{`${draftInvoice.invoiceNumber} - ${draftInvoice.title}`}”</h2>
        <span css={Css.sm.$}>
          All existing draws for this period have been prepopulated and selected for you. <b>Deselect draws</b> you do
          not want to invoice at this time. When finished, click Continue.
        </span>
      </div>

      <TableFilter filter={filter} setFilter={setFilter} />
      <div css={Css.mt2.$}>
        <ConfirmDrawAmountTable api={tableApi} draws={filteredDraws} filter={filter} invoice={draftInvoice} />
      </div>

      <StepActions>
        <div css={Css.df.gap2.$}>
          <Button
            label="Save & Exit"
            variant="secondary"
            onClick={async () => {
              await onSave();
              onExit();
            }}
          />
          <Button
            label="Continue"
            disabled={!hasCostsToInvoice && !hasFixedToInvoice && selectedDrawIds.length === 0}
            onClick={async () => {
              await onSave();

              setSteps((prevState) => {
                const currentIndex = prevState.findIndex((s) => s.value === InvoiceStepEnum.SELECT_DRAW_AMOUNT);
                return [
                  ...prevState.slice(0, currentIndex),
                  { ...prevState[currentIndex], state: "complete" },
                  { ...prevState[currentIndex + 1], disabled: false },
                  ...(currentIndex + 2 === prevState.length ? [] : prevState.slice(currentIndex + 2)),
                ];
              });

              nextStepEvenIfDisabled();
            }}
          />
        </div>
      </StepActions>
    </>
  );
}

type TableFilterProps = {
  filter: DrawAmountItemsFilter;
  setFilter: Dispatch<Partial<DrawAmountItemsFilter>>;
};

function TableFilter({ filter, setFilter }: TableFilterProps) {
  return (
    <div css={Css.mt3.df.pr3.jcsb.$}>
      <div css={Css.df.f(0.3).$}>
        <InvoiceDateFilter
          endDate={new Date(filter.endDate)}
          startDate={new Date(filter.startDate)}
          onChange={(endDate: Date) => setFilter({ endDate: new DateOnly(endDate) })}
        />
      </div>
      <Switch
        label="Show only outstanding draws within the date range"
        data-testid="showOnlyOutstanding"
        selected={filter.showSuggestedDraws}
        onChange={() => setFilter({ showSuggestedDraws: !filter.showSuggestedDraws })}
        labelStyle="inline"
      />
    </div>
  );
}
