import { TakeoffsItemsTableActions } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsItemsTableActions";
import { PlanPackageTakeoffTable } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/PlanPackageTakeoffTable";

type TakeoffsManagerItemsTabProps = {
  id: string;
};

export function TakeoffsManagerItemsTab(props: TakeoffsManagerItemsTabProps) {
  const { id } = props;

  return (
    <>
      <TakeoffsItemsTableActions />
      <PlanPackageTakeoffTable itemTemplateId={id} />
    </>
  );
}
