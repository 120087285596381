import {
  BoundSelectField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  Cutoff,
  Maybe,
  SaveProjectCutoffInput,
  SetupModalCutoffsQuery,
  SetupModalProjectCutoffFragment,
  TaskStatus,
  useSetupModalCutoffsQuery,
  useSetupModalSaveProjectCutoffMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export function SetupModal({ projectId }: { projectId: string }) {
  const query = useSetupModalCutoffsQuery({ variables: { projectId } });
  return queryResult(query, (result) => <SetupModalFormDataView data={result} />);
}

type SetupModalFormDataViewProps = {
  data: SetupModalCutoffsQuery;
};

function SetupModalFormDataView({ data }: SetupModalFormDataViewProps) {
  const { project, tasks } = data;

  const { closeModal } = useModal();
  const [saveProjectCutoff] = useSetupModalSaveProjectCutoffMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: project,
      map: (project) => ({
        projectCutoffs: project.cutoffs.map((pc: SetupModalProjectCutoffFragment) => ({
          id: pc.id,
          cutoff: pc.cutoff,
          name: pc.name,
          taskId: pc.task?.id,
        })),
      }),
    },
  });

  // disable option if task status is complete
  const completedTaskIds = tasks.filter((t) => t.status.code === TaskStatus.Complete).map((t) => t.id);

  return (
    <>
      <ModalHeader>Cutoffs Setup</ModalHeader>
      <ModalBody>
        <p css={Css.mb3.$}>Tie each cutoff group to its corresponding schedule task.</p>
        <p css={Css.mb3.$}>Cutoff tasks should be separate from milestone tasks in the schedule.</p>
        <FormLines width="full">
          {formState.projectCutoffs.rows.map((cutoff) => (
            <BoundSelectField
              disabledOptions={completedTaskIds}
              field={cutoff.taskId} // set task to projectCutoff
              key={cutoff.value.id}
              label={cutoff.value.name || ""}
              options={tasks}
            />
          ))}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Cancel" onClick={closeModal} />
              <Button
                disabled={!formState.dirty}
                label="Save Cutoffs"
                onClick={async () => {
                  const pcs = formState.value.projectCutoffs;
                  await Promise.all(
                    pcs.map(async (pc) => {
                      if (pc.taskId) {
                        await saveProjectCutoff({
                          variables: { input: { id: pc.id, taskId: pc.taskId } },
                        });
                      }
                    }),
                  );
                  closeModal();
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type SetupModalProjectCutoff = Omit<SaveProjectCutoffInput, "cutoffId" | "description" | "projectId"> & {
  cutoff: Maybe<Pick<Cutoff, "id" | "name">>;
};

const formConfig: ObjectConfig<{ projectCutoffs: SetupModalProjectCutoff[] }> = {
  projectCutoffs: {
    type: "list",
    config: {
      id: { type: "value" },
      cutoff: { type: "value" },
      name: { type: "value" },
      taskId: { type: "value" },
    },
  },
};
