import { FieldPolicy } from "@apollo/client";
import { addDays, format, parseISO } from "date-fns";
import { Scalars } from "src/generated/graphql-types";

export function formatWithYear(date: Scalars["Date"]) {
  return format(date, "MM/dd/yyyy");
}

export function formatWithShortYear(date: Scalars["Date"]) {
  return format(date, "MM/dd/yy");
}

export function formatShort(date: Scalars["Date"]) {
  return format(date, "M/d/yy");
}

// Ex: Mar 1, 2020
const monthDayYearFormatFns = "MMM d, yyyy";
export function formatMonthDayYear(date: Scalars["Date"]) {
  return format(date, monthDayYearFormatFns);
}

export function formatMonthDay(date: Scalars["Date"]) {
  return format(date, "MMM d");
}
// Ex: March 1
export function formatLongMonthDay(date: Scalars["Date"]) {
  return format(date, "MMMM d");
}

// Used to break structural typing between Date and DateOnly, because we don't
// want non-DateOnly Date's being passed to GraphQL queries/mutations as-is.
//
// (Because when they're put on the wire, the Date.toJSON method will include
// timestamp information and the GraphQL server will reject it as an invalid type).
const NotDate = Symbol();

/**
 * A calendar day, i.e. a date without any time or time zone.
 *
 * Constructed by calling `new DateOnly(anExistingDate)`.
 */
export class DateOnly extends Date {
  constructor(public date: Date) {
    super(date.getTime());
  }

  toString() {
    return this.toISOString().split("T")[0];
  }

  toJSON() {
    return this.toString();
  }

  [NotDate]: undefined;
}

export function addDaysDateOnly(date: DateOnly, days: number) {
  return new DateOnly(addDays(date.date, days));
}

/** An alias for Date that specifically has time (unlike DateOnly). */
export type DateTime = Date;

/**
 * If the backend sends in `2018-01-01`, we convert to it a DateOnly.
 *
 * Technically in Jest tests, the "wire calls" are mocked out with already-DateOnlys,
 * so this can be a noop for unit tests.
 */
export const dateTypePolicy: FieldPolicy<DateOnly, string | DateOnly, string> = {
  merge: (_, incoming) => formatDateOnly(incoming),
};

/** Used for custom query resolvers that return a list of dates */
export const dateArrayTypePolicy: FieldPolicy<DateOnly[], string[] | DateOnly[], string> = {
  merge: (_, incoming) => {
    if (isNullOrUndefined(incoming)) return incoming;

    return incoming.map((date) => formatDateOnly(date));
  },
};

function formatDateOnly(incoming: string | Readonly<DateOnly>) {
  if (isNullOrUndefined(incoming)) {
    // It's important for these methods to return null if passed null
    return incoming;
  } else if (incoming instanceof DateOnly) {
    // In tests our mocks already have DateOnly
    return incoming;
  } else if ((incoming as any) instanceof Date) {
    // Somehow a story is getting a `Date` even though it's calling `newDate` through a factory...
    return new DateOnly(incoming as any as Date);
  } else {
    return new DateOnly(parseISO(incoming as string));
  }
}

/**
 * Ensures the date is really a DateOnly.
 *
 * Unfortunately mutation results will still have string dates, see:
 * https://github.com/apollographql/apollo-client/issues/7665
 *
 * Note that this is only required when you are directly accessing the
 * result of a mutation call. Any data that goes through the cache and
 * then updates other queries on the page will be fine.
 */
export function ensureDateOnly(date: DateOnly | string): DateOnly {
  if (typeof date === "string") {
    return new DateOnly(parseISO(date));
  } else {
    return date;
  }
}

export const dateTimeTypePolicy: FieldPolicy<DateTime, string | Date, string> = {
  merge: (_, incoming) => {
    if (isNullOrUndefined(incoming)) {
      return incoming;
    } else if (incoming instanceof Date) {
      return incoming;
    } else {
      return parseISO(incoming as string);
    }
  },
};

function isNullOrUndefined(param: any | null | undefined): param is null | undefined {
  return param === null || param === undefined;
}

export function formatWithDateAndTime(date: Scalars["Date"] | Date) {
  return format("date" in date ? date.date : date, "MM/dd/yy 'at' h:mmaaa");
}
