import { Css, IconButton, useRightPane } from "@homebound/beam";
import { FC } from "react";
import { useJobLogQuery } from "src/generated/graphql-types";
import { jobLogNoteFormWidth } from "src/routes/projects/job-logs/JobLogNoteRightPaneContent";
import { formatDate } from "src/routes/projects/job-logs/JobLogsPage";
import { queryResult } from "src/utils";
import { JobLogImages } from "./JobLogImages";

type JobLogImageSuperDrawerContentProps = {
  jobLogId: string;
};

export const JobLogImageRightPaneContent: FC<JobLogImageSuperDrawerContentProps> = ({ jobLogId }) => {
  const { closeRightPane } = useRightPane();
  const query = useJobLogQuery({ variables: { id: jobLogId }, nextFetchPolicy: "cache-first" });

  return queryResult(query, {
    data: ({ jobLog }) => (
      <div css={Css.df.fdc.p2.$}>
        <div css={Css.df.aic.jcsb.mb2.wPx(jobLogNoteFormWidth).$}>
          <h1 css={Css.lgSb.$}>{formatDate(jobLog.logDate)}</h1>
          <IconButton data-testid="closePane" icon="x" onClick={closeRightPane} />
        </div>
        <div css={Css.df.fdc.pbPx(100).$} data-testid="jobLogImageSuperDrawerContent">
          <h2 css={Css.gray900.baseMd.mb1.$}>Photos</h2>
          <div css={Css.mb2.$}>
            <JobLogImages editingEnabled display={"vertical"} parentId={jobLogId} images={jobLog.images} />
          </div>
        </div>
      </div>
    ),
  });
};
