import { Button, Css, useModal } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  ScheduleDetailsFragment,
  Stage,
  TaskTradePartnerFragment,
  useDevelopmentScheduleQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ScheduleExportModal } from "src/routes/pdfs";
import { StartOrCompleteStageButton } from "src/routes/projects/schedule-v2/components/StartOrCompleteStageButton";
import { ScheduleStoreProvider, useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { Schedule } from "src/routes/projects/schedule-v2/Schedule";
import { prepareFilterOptions } from "src/routes/projects/schedule-v2/scheduleUtils";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { developmentsPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export function DevelopmentSchedulePage() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const pageQuery = useDevelopmentScheduleQuery({ variables: { developmentId } });

  return queryResult(pageQuery, {
    data: ({
      development: {
        name,
        schedules: { 0: schedule },
      },
      scheduleTasks,
      taskStatuses,
      internalUsers,
    }) => {
      const { tradePartners, assignees } = prepareFilterOptions(scheduleTasks);

      return (
        <ScheduleStoreProvider scheduleType={ScheduleType.Development} stage={Stage.Development}>
          <div data-testid="developmentSchedulePage">
            <PageHeader
              title={name}
              breadcrumb={{ label: "All Developments", href: developmentsPath }}
              xss={Css.bgWhite.$}
              left={
                <div css={Css.df.aic.$}>
                  <StatusIndicator status={schedule.status} />
                  <div css={Css.ml1.smMd.$}>{schedule.statusDetail.name}</div>
                </div>
              }
              right={<ExportAndStartStageButtons schedule={schedule} tradePartners={tradePartners} />}
            />
            <Schedule
              schedule={schedule}
              tasks={scheduleTasks}
              filterOptions={{ taskStatuses, tradePartners, assignees }}
            />
          </div>
        </ScheduleStoreProvider>
      );
    },
  });
}

function ExportAndStartStageButtons({
  schedule,
  tradePartners,
}: {
  schedule: ScheduleDetailsFragment;
  tradePartners: TaskTradePartnerFragment[];
}) {
  const { openModal } = useModal();
  const {
    scheduleState: { emptyScheduleState },
  } = useScheduleStore();

  // Hide when loading or the schedule template is not applied yet
  if (emptyScheduleState) return null;

  return (
    <>
      <Button
        onClick={() =>
          openModal({
            content: <ScheduleExportModal schedule={schedule} tradePartners={tradePartners} />,
          })
        }
        variant="tertiary"
        label="Export"
        icon="linkExternal"
        size="sm"
      />
      <StartOrCompleteStageButton schedule={schedule} refetchQueries={["DevelopmentSchedule"]} />
    </>
  );
}
