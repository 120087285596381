import { Css, Palette } from "@homebound/beam";
import { capitalCase } from "change-case";
import { Icon } from "src/components";
import { pluralize } from "src/utils";
import { combineFlagsByReasonTitle } from "src/utils/jobLog";
import { BryntumDependencyNumbers, OriginalTaskDataById, bryntumNumberToTaskDependencyType } from "./ganttUtils";

type DependencyTooltipEvent = {
  activeTarget: {
    elementData: {
      dependency: {
        originalData: {
          fromTask: string;
          toTask: string;
          lag?: number;
          type: BryntumDependencyNumbers;
          fromEvent: {
            id: string;
          };
          toEvent: {
            id: string;
          };
        };
      };
    };
  };
};

type TaskTooltip = {
  endText: string;
  startText: string;
  taskRecord: {
    id: string;
    name: string;
    duration: number;
    percentDone: number;
  };
};

/**
 * Bryntum tooltips require HTML strings, so we are currently hardcoding template strings
 * For fancier JSX -> HTML we could use `renderToString` from "react-dom/server", but that seems overkill to include here
 */
export function dependencyTooltipMarkup(event: unknown, originalTaskDataById: OriginalTaskDataById) {
  const { originalData } = (event as DependencyTooltipEvent).activeTarget.elementData.dependency;
  const { fromTask, toTask, lag, type, fromEvent, toEvent } = originalData;

  // On newly created dependency lines, the event callback returns fromEvent/toEvent objects rather than fromTask/toTask ID strings
  const fromTaskId = fromTask ?? fromEvent.id;
  const toTaskId = toTask ?? toEvent.id;
  const lagInDays = lag ?? 0;

  function rowMarkup(title: string, content: string) {
    return `<p><strong>${title}:</strong> ${content}</p>`;
  }

  const rows = [
    {
      title: "From",
      content: originalTaskDataById[fromTaskId].name,
    },
    {
      title: "To",
      content: originalTaskDataById[toTaskId].name,
    },
    {
      title: "Lag",
      content: `${lagInDays} Days`,
    },
    {
      title: "Type",
      content: capitalCase(bryntumNumberToTaskDependencyType[type]),
    },
  ];

  return `
    <div>
      ${rows.map(({ title, content }) => rowMarkup(title, content)).join("")}
    </div>
  `;
}

export function taskTooltipMarkup(
  data: unknown,
  originalTaskDataById: OriginalTaskDataById,
  delayFeatureFlagIsOn: boolean = false,
) {
  const { startText, endText, taskRecord } = data as TaskTooltip;
  const { id, name, duration, percentDone } = taskRecord;
  return (
    <>
      <div className="b-gantt-task-title">{name}</div>
      <table css={Css.w100.$}>
        <tr>
          <td>Start:</td>
          <td>{startText}</td>
        </tr>
        <tr>
          <td>End:</td>
          <td>{endText}</td>
        </tr>
        <tr>
          <td>Duration:</td>
          <td className="b-right">
            {duration} {pluralize(duration, "day")}
          </td>
        </tr>
        <tr>
          <td>Complete:</td>
          <td className="b-right">{percentDone}%</td>
        </tr>
        {originalTaskDataById[id]?.tradePartners.nonEmpty && (
          <tr>
            <td>
              <div css={Css.df.aic.$}>
                <div>{[originalTaskDataById[id]?.tradePartnerStatus.name]}</div>
              </div>
            </td>
          </tr>
        )}
        {delayFeatureFlagIsOn && originalTaskDataById[id]?.scheduleFlags?.nonEmpty && (
          <tr>
            <td colSpan={2}>
              <div css={Css.df.gap1.$}>
                <div>
                  <Icon icon="flag" color={Palette.Red700} inc={2} />
                </div>
                <div css={Css.tal.$}>
                  {combineFlagsByReasonTitle(originalTaskDataById[id].scheduleFlags).join(", ")}
                </div>
              </div>
            </td>
          </tr>
        )}
      </table>
    </>
  );
}
