import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { FormattedDate } from "src/components";
import { Card } from "src/components/Card";
import { PlanPackageVersionHistory_RpavFragment, usePlanPackageVersionHistoryQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { AggregateMemberVersionChangeType } from "../../design-catalog/design-package-configurator/components/DesignUpdatesAvailableButton";
import { renderAffectedListItem, renderListItem } from "./components/VersionHistoryUtils";

export function PlanPackageVersionHistoryPage() {
  const { planPackageId } = useParams<{ planPackageId: string }>();
  const query = usePlanPackageVersionHistoryQuery({ variables: { filter: { readyPlanId: planPackageId } } });

  return queryResult(query, ({ readyPlanAggregateVersions }) => (
    <PlanPackageVersionHistoryView readyPlanAggregateVersions={readyPlanAggregateVersions} />
  ));
}

type PlanPackageVersionHistoryViewProps = {
  readyPlanAggregateVersions: PlanPackageVersionHistory_RpavFragment[];
};

function PlanPackageVersionHistoryView({ readyPlanAggregateVersions }: PlanPackageVersionHistoryViewProps) {
  return (
    <>
      <PageHeader title="Version History" />
      <div css={Css.df.fdc.gap2.$}>
        {[...readyPlanAggregateVersions].reverse().map((rpav) => {
          const dpUpdatesCount = rpav.designPackagePlanUpdates.count((dppu) => dppu.accepted);
          const poUpdatesCount = rpav.productOfferingIncomingUpdates.count((poiu) => poiu.accepted);
          const { newTlivCount, removedTlivCount, updatedTlivCount } = getTlivChangeCounts(rpav);
          return (
            <Card key={rpav.id} xss={Css.wPx(730).mhPx(550).ma.p6.$}>
              <div css={Css.df.fdc.gap5.$}>
                <div data-testid="planInfo" css={Css.df.gap1.$}>
                  <span css={Css.xl2Bd.$}>v{rpav.version} </span>
                  <span css={Css.gray400.xl2.pl1.lhPx(32).$}>
                    <FormattedDate date={rpav.createdAt} dateFormatStyle="long" />
                  </span>
                </div>
                <div data-testid="versionNotes" css={Css.lg.$}>
                  "{rpav.versionNotes}"
                </div>
                <div>
                  <div css={Css.lgBd.$}>Changes made</div>
                  <ul css={Css.lg.m0.mt1.$}>
                    <>
                      {renderListItem(newTlivCount, "Added", "takeoff item")}
                      {renderListItem(removedTlivCount, "Removed", "takeoff item")}
                      {renderListItem(updatedTlivCount, "Modified", "takeoff item")}
                    </>
                  </ul>
                </div>
                <div>
                  <div css={Css.lgBd.$}>This version is being used by:</div>
                  <ul css={Css.lg.m0.mt1.$}>
                    {renderAffectedListItem(dpUpdatesCount, "Design Package")}
                    {renderAffectedListItem(poUpdatesCount, "Product Offering")}
                  </ul>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}

function getTlivChangeCounts(rpav: PlanPackageVersionHistory_RpavFragment) {
  const tlivs = rpav.tliVersions;
  const newTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.REMOVED);
  const updatedTlivCount = tlivs.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.UPDATED);

  return { newTlivCount, removedTlivCount, updatedTlivCount };
}
