import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  BusinessFunctionRoles_BusinessFunctionFragment,
  ProjectRoleDetail,
  SaveBusinessFunctionInput,
  useProjectRolesForBusinessFunctionQuery,
  useSaveBusinessFunctionMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { getRoleOptions } from "../utils";

export function BusinessFunctionRolesModal(props: BusinessFunctionRoles_BusinessFunctionFragment) {
  const { businessFunctionType } = props;
  const { closeModal } = useModal();
  const formState = useFormState({
    config,
    init: {
      input: props,
      map: (data) => ({
        id: data.id,
        businessFunctionType: data.businessFunctionType.code,
        impactReviewer: data.impactReviewer?.code,
        initialReviewer: data.initialReviewer?.code,
        priority: data.priority,
        autoApprovalAmountInCents: data.autoApprovalAmountInCents,
        approverProjectRole: data.approverProjectRole?.code,
      }),
    },
  });
  const [saveBusinessFunction] = useSaveBusinessFunctionMutation({
    variables: { input: formState.value },
    onCompleted: closeModal,
  });

  const query = useProjectRolesForBusinessFunctionQuery({
    variables: { businessFunctionType: businessFunctionType.code },
  });

  return queryResult(query, (data) => (
    <BusinessFunctionRolesForm
      formState={formState}
      onSave={saveBusinessFunction}
      roles={data.projectRolesForBusinessFunctionType}
    />
  ));
}

const config: ObjectConfig<SaveBusinessFunctionInput> = {
  id: { type: "value", rules: [required] },
  businessFunctionType: { type: "value", rules: [required] },
  impactReviewer: { type: "value" },
  initialReviewer: { type: "value" },
  priority: {
    type: "value",
    rules: [required, ({ value }) => ((value ?? 0) < 1 ? "Priorty must be 1 or higher" : undefined)],
  },
  autoApprovalAmountInCents: { type: "value" },
  approverProjectRole: { type: "value" },
};

type BusinessFunctionRolesFormProps = {
  formState: ObjectState<SaveBusinessFunctionInput>;
  onSave: () => void;
  roles: Omit<ProjectRoleDetail, "sortOrder">[];
};
function BusinessFunctionRolesForm(props: BusinessFunctionRolesFormProps) {
  const { formState, onSave, roles } = props;
  const { closeModal } = useModal();

  return (
    <Observer>
      {() => (
        <div>
          <ModalHeader>Edit Roles</ModalHeader>
          <ModalBody>
            <FormLines>
              <BoundSelectField field={formState.initialReviewer} options={getRoleOptions(roles)} unsetLabel="--" />
              <BoundSelectField field={formState.impactReviewer} options={getRoleOptions(roles)} unsetLabel="--" />
              <BoundNumberField field={formState.autoApprovalAmountInCents} type="cents" />
              <BoundSelectField
                label="Approver"
                field={formState.approverProjectRole}
                options={getRoleOptions(roles)}
                unsetLabel="--"
              />
              <BoundNumberField field={formState.priority} label="Priority of this Function" positiveOnly />
            </FormLines>
          </ModalBody>
          <ModalFooter>
            <div css={Css.df.$}>
              <Button label="Cancel" onClick={closeModal} variant="tertiary" />
              <Button label="Save" onClick={onSave} disabled={!formState.dirty || !formState.valid} />
            </div>
          </ModalFooter>
        </div>
      )}
    </Observer>
  );
}
