import {
  BoundNumberField,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  collapseColumn,
  column,
  emptyCell,
  numericColumn,
} from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import {
  BidPackageItemTemplateItemFragment,
  EditBidContractTradeLineItemFragment,
  InputMaybe,
  NamedFragment,
} from "src/generated/graphql-types";
import { DropCodeCell } from "src/routes/developments/product-offerings/components/DropCodeCell";
import { fail, groupBy, prorate, roundToDigits } from "src/utils";
import { getOptionsKey } from "../BidsTab";
import { FormValues, ProrationFormValues } from "../ProrateBidModal";
import { ProrationCostColumn, ProrationUnitCostColumn } from "./ProrationCostColumn";

export function initializeMaterialFormState(
  scopeLineItems: BidPackageItemTemplateItemFragment[],
  existingTradeLineItems: EditBidContractTradeLineItemFragment[],
): FormValues {
  const materialItemTemplateItems = scopeLineItems.filter((iti) => !!iti.bidItem?.parentMaterialVariant);

  const materialLaborEstimateMap = prorateLaborEstimatesForMaterials(scopeLineItems);

  const groupedITIs = groupBy(
    materialItemTemplateItems,
    (iti) => iti.readyPlan?.id + getOptionsKey(iti.options) + iti.bidItem?.id,
  );

  return {
    bidContractTradeLineItems: Object.entries(groupedITIs).map(([key, materialItis]) => {
      // The existing trade line item would match the product offering, options, and bid item
      const existingTradeLineItem = existingTradeLineItems.find(
        (tli) => tli.productOffering.id + getOptionsKey(tli.options) + tli.bidItem?.id === key,
      );

      const quantity = materialItis.sum((iti) => iti.quantity || 0) || 1;

      return {
        id: existingTradeLineItem?.id,
        quantity,
        unitCostInCents: existingTradeLineItem
          ? roundToDigits(existingTradeLineItem.totalCostInCents / quantity, 1)
          : undefined,
        totalCostInCents: existingTradeLineItem?.totalCostInCents,

        productOfferingId: materialItis[0].readyPlan!.id,
        optionIds: materialItis[0].options.sortByKey("id").map((o) => o.id),
        bidItemId: materialItis[0].bidItem?.id,
        prorations: materialItis.flatMap((materialIti) => {
          const laborLine = scopeLineItems.find((allIti) => allIti.id === materialIti.laborLine?.id);

          const existingMaterialProration = existingTradeLineItem?.prorations.find(
            (p) => p.lineItem.itemTemplateItem?.id === materialIti.id,
          );
          const existingLaborProration =
            laborLine &&
            existingTradeLineItem?.prorations.find((p) => p.lineItem.itemTemplateItem?.id === laborLine.id);

          return [
            {
              id: existingMaterialProration?.id,
              quantity: materialIti.quantity,
              unitCostInCents: existingMaterialProration
                ? roundToDigits(existingMaterialProration.costInCents / (materialIti.quantity || 1), 1)
                : undefined,
              costInCents: existingMaterialProration?.costInCents,
              estimatedCostInCents: materialIti.costEstimate.costInCents,

              itemTemplateItemId: materialIti.id,
              userEntered: !!existingMaterialProration?.userEntered,
            },
            ...(laborLine
              ? [
                  {
                    id: existingLaborProration?.id,
                    quantity: materialIti.quantity,
                    unitCostInCents: existingLaborProration
                      ? roundToDigits(existingLaborProration.costInCents / (materialIti.quantity || 1), 1)
                      : undefined,
                    costInCents: existingLaborProration?.costInCents,
                    estimatedCostInCents: materialLaborEstimateMap.get(`${laborLine.id}${materialIti.id}`)!,

                    itemTemplateItemId: laborLine.id,
                    userEntered: !!existingLaborProration?.userEntered,
                  },
                ]
              : []),
          ];
        }),
      };
    }),
  };
}

/**
 * Because a labor iti maybe be used by multiple material itis, we need to split out the labor iti estimated cost.
 * To do this we look at the cost estimate of each of the materials and ratio of the cost estimates to split out the labor cost.
 */
function prorateLaborEstimatesForMaterials(scopeLineItems: BidPackageItemTemplateItemFragment[]): Map<string, number> {
  const materialItemTemplateItems = scopeLineItems.filter((iti) => !!iti.bidItem?.parentMaterialVariant);
  // Group the material ITIs by the labor ITI
  const groupByLaborITI = materialItemTemplateItems
    .filter((iti) => iti.laborLine)
    .groupBy((iti) => iti.laborLine?.id || "");
  // the key will be a combination of the labor ITI and the material ITI id
  const materialLaborEstimateMap = new Map<string, number>();
  Object.entries(groupByLaborITI).forEach(([key, materialItis]) => {
    const laborLine = scopeLineItems.find((allIti) => allIti.id === key);
    if (!laborLine) {
      return;
    }

    // Calculate the total cost of all materials
    const materialTotal = materialItis.sum((iti) => iti.costEstimate.costInCents);
    // Calculate the ratios by dividing the materials by the material total
    const ratios = materialItis.map((iti) => iti.costEstimate.costInCents / materialTotal);
    const proratedCosts = prorate(laborLine.costEstimate.costInCents, ratios);
    // Write the cost estimates back to the map
    for (let index = 0; index < materialItis.length; ++index) {
      materialLaborEstimateMap.set(`${key}${materialItis[index].id}`, proratedCosts[index]);
    }
  });

  return materialLaborEstimateMap;
}

type MaterialTablesProps = {
  formState: ObjectState<FormValues>;
  scopeLineItems: BidPackageItemTemplateItemFragment[];
  tradePartnerName: string;
};

const threeDigitCurrencyFormat = { style: "currency" as const, currency: "USD", minimumFractionDigits: 3 };

export function MaterialTables({ formState, scopeLineItems, tradePartnerName }: MaterialTablesProps) {
  const productOfferings = scopeLineItems.map((iti) => iti.readyPlan!).unique();
  const columns = createColumns();
  return (
    <div css={Css.df.fdc.gap3.w100.$}>
      {productOfferings.map((po) => {
        const rows = createRows(po, formState, scopeLineItems, tradePartnerName);
        return <GridTable key={po.id} columns={columns} rows={rows} rowStyles={rowStyles} />;
      })}
    </div>
  );
}

const rowStyles: RowStyles<Row> = {
  productOffering: {
    cellCss: Css.py3.$,
  },
  head: {
    cellCss: Css.smMd.$,
  },
};

function createRows(
  productOffering: NamedFragment,
  formState: ObjectState<FormValues>,
  scopeLineItems: BidPackageItemTemplateItemFragment[],
  tradePartnerName: string,
): GridDataRow<Row>[] {
  const materialItemTemplateItems = scopeLineItems.filter(
    (iti) => iti.readyPlan!.id === productOffering.id && !!iti.bidItem?.parentMaterialVariant,
  );

  const optionGroupBy = groupBy(materialItemTemplateItems, (iti) => getOptionsKey(iti.options));
  const optionRows = Object.keys(optionGroupBy).map((optionKey) => {
    const children = optionGroupBy[optionKey];
    const groupedChildren = groupBy(children, (iti) => iti.bidItem!.id);

    return {
      kind: "option" as const,
      id: optionKey || "base",
      data: {
        options: children[0].options,
      },
      children: Object.entries(groupedChildren).flatMap(([key, materialItis]) => {
        const bidContractTradeLineItem = formState.bidContractTradeLineItems.rows.find(
          (bcli) =>
            bcli.productOfferingId.value === productOffering.id &&
            (bcli.optionIds.value || []).join(",") === optionKey &&
            bcli.bidItemId.value === materialItis[0].bidItem?.id,
        );
        if (!bidContractTradeLineItem) {
          fail("BidContractTradeLineItem not found for product offering, options, and bid item");
        }

        return {
          kind: "material" as const,
          id: `material-${materialItis[0].id}`,
          data: {
            itemTemplateItem: materialItis[0],
            totalCostField: bidContractTradeLineItem.totalCostInCents,
            unitCostField: bidContractTradeLineItem.unitCostInCents,
          },
          children: materialItis.flatMap((materialIti) => {
            const laborLine = scopeLineItems.find((allIti) => allIti.id === materialIti.laborLine?.id);

            const materialFormState = bidContractTradeLineItem.prorations.rows.find(
              (p) => p.itemTemplateItemId.value === materialIti.id,
            );
            const laborFormState = laborLine
              ? bidContractTradeLineItem.prorations.rows.find((p) => p.itemTemplateItemId.value === laborLine.id)
              : undefined;

            if (!materialFormState) {
              fail("materialFormState not found for item template item");
            }

            return [
              {
                kind: "materialProration" as const,
                id: materialIti.id,
                data: {
                  itemTemplateItem: materialIti,
                  prorationState: materialFormState,
                },
              },
              ...(laborLine && laborFormState
                ? [
                    {
                      kind: "laborProration" as const,
                      id: `${materialIti.id}-${laborLine.id}`,
                      data: {
                        materialItemTemplateItem: materialIti,
                        itemTemplateItem: laborLine,
                        prorationState: laborFormState,
                      },
                    },
                  ]
                : []),
            ];
          }),
        };
      }),
    };
  });

  return [
    {
      kind: "productOffering" as const,
      id: "productOffering",
      data: {
        productOfferingName: productOffering.name,
        tradePartnerName,
      },
      pin: "first",
    },
    { kind: "head" as const, id: "head", data: undefined, pin: "last" },
    ...optionRows,
  ];
}

type ProductOfferingRow = {
  kind: "productOffering";
  data: {
    productOfferingName: string;
    tradePartnerName: string;
  };
};
type HeaderRow = { kind: "head"; data: undefined };
type OptionRow = { kind: "option"; data: { options: NamedFragment[] } };
type MaterialRow = {
  kind: "material";
  data: {
    itemTemplateItem: BidPackageItemTemplateItemFragment;
    totalCostField: FieldState<InputMaybe<number>>;
    unitCostField: FieldState<InputMaybe<number>>;
  };
};
type MaterialProrationRow = {
  kind: "materialProration";
  data: {
    itemTemplateItem: BidPackageItemTemplateItemFragment;
    prorationState: ObjectState<ProrationFormValues>;
  };
};
type LaborProrationRow = {
  kind: "laborProration";
  data: {
    materialItemTemplateItem: BidPackageItemTemplateItemFragment;
    itemTemplateItem: BidPackageItemTemplateItemFragment;
    prorationState: ObjectState<ProrationFormValues>;
  };
};

type Row = HeaderRow | ProductOfferingRow | OptionRow | MaterialRow | MaterialProrationRow | LaborProrationRow;

function createColumns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({
      productOffering: ({ productOfferingName, tradePartnerName }) => ({
        content: (
          <div css={Css.df.w100.aic.$}>
            <div css={Css.xlSb.$}>
              {productOfferingName} <span css={Css.gray600.$}>{`— ${tradePartnerName} Bid`}</span>
            </div>
          </div>
        ),
        colspan: 9,
      }),
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Code",
      option: emptyCell,
      material: emptyCell,
      materialProration: ({ itemTemplateItem }) => <DropCodeCell scope={itemTemplateItem} />,
      laborProration: ({ itemTemplateItem }) => <DropCodeCell scope={itemTemplateItem} />,
      w: "100px",
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Name",
      option: ({ options }) => ({ content: options.map((o) => o.name).join(", ") || "Base", css: Css.smBd.$ }),
      material: ({ itemTemplateItem }) => ({ content: itemTemplateItem.name, css: Css.xsBd.$ }),
      materialProration: ({ itemTemplateItem }) => itemTemplateItem.name,
      laborProration: ({ itemTemplateItem }) => itemTemplateItem.name,
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Material Code",
      option: emptyCell,
      material: emptyCell,
      materialProration: ({ itemTemplateItem }) => itemTemplateItem.bidItem?.parentMaterialVariant?.code,
      laborProration: ({ itemTemplateItem }) => itemTemplateItem.bidItem?.parentMaterialVariant?.code,
      // w: "120px", are the material codes going to be this long?
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "Qty",
      option: emptyCell,
      material: emptyCell,
      materialProration: ({ itemTemplateItem }) => itemTemplateItem.quantity,
      laborProration: ({ materialItemTemplateItem }) => materialItemTemplateItem.quantity,
      w: "53px",
    }),
    column<Row>({
      productOffering: emptyCell,
      head: "UoM",
      option: emptyCell,
      material: emptyCell,
      materialProration: ({ itemTemplateItem }) => itemTemplateItem.unitOfMeasure.shortName,
      laborProration: ({ materialItemTemplateItem }) => materialItemTemplateItem.unitOfMeasure.shortName,
      w: "67px",
    }),
    numericColumn<Row>({
      productOffering: emptyCell,
      head: "Cost",
      option: emptyCell,
      material: ({ unitCostField }) => (
        <BoundNumberField numberFormatOptions={threeDigitCurrencyFormat} numFractionDigits={3} field={unitCostField} />
      ),
      materialProration: ProrationUnitCostColumn,
      laborProration: ProrationUnitCostColumn,
      w: "160px",
    }),
    numericColumn<Row>({
      productOffering: emptyCell,
      head: "Total Cost",
      option: emptyCell,
      material: ({ totalCostField }) => <BoundNumberField field={totalCostField} />,
      materialProration: ProrationCostColumn,
      laborProration: ProrationCostColumn,
      w: "160px",
    }),
  ];
}
