import {
  actionColumn,
  Button,
  Checkbox,
  Chips,
  column,
  Css,
  Filter,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  simpleDataRows,
  SimpleHeaderAndData,
  toggleFilter,
  useFilter,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { Card } from "src/components/Card";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  ContactsTabTradePartnerContactFragment,
  ContactsTabTradePartnerFragment,
  FeatureFlagType,
  TradePartnerContactsDocument,
  TradePartnerContactsQuery,
  useSaveTradePartnerContactUserMutation,
  useTradePartnerContactsQuery,
} from "src/generated/graphql-types";
import { TradePartnerContactModal } from "src/routes/trade-partners/TradePartnerContactModal";
import { fail, isDefined } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { StringParam, useQueryParams } from "use-query-params";
import { TradePartnerContactsRolesPanel } from "./components/TradePartnerContactsRolesPanel";
import { TradePartnerContactCommunicationPreferences } from "./TradePartnerContactCommunicationPreferences";

export type TradePartnerContactsTabProps = { tradePartnerId: string };

export function TradePartnerContactsTab({ tradePartnerId }: TradePartnerContactsTabProps) {
  const filterDefs = { useInactive: toggleFilter({ defaultValue: false, label: "View Deactivated" }) };
  const { filter, setFilter } = useFilter({ filterDefs });
  const query = useTradePartnerContactsQuery({
    variables: { tradePartnerId, includeDeactivated: filter.useInactive },
  });

  return queryResult(query, (data) => <DataView data={data} filterBundle={{ filterDefs, filter, setFilter }} />);
}

type DataViewProps = {
  data: TradePartnerContactsQuery;
  filterBundle: {
    filterDefs: { useInactive: (key: string) => Filter<boolean> };
    filter: { useInactive: boolean };
    setFilter: (filter: { useInactive: boolean }) => void;
  };
};

function DataView({ data, filterBundle }: DataViewProps) {
  const tradePartner = data.tradePartners.first ?? fail("no trade partner loaded to TradePartnerContactsTab");
  const modal = useModal();
  const tid = useTestIds({});

  const showCommsPreferences = useFeatureFlag(FeatureFlagType.TradePartnerCommunicationsSms);
  const [{ tpcId }] = useQueryParams({ tpcId: StringParam });
  const selectedTpc = useMemo(
    () => tradePartner.contacts.find(({ id }) => id === tpcId),
    [tpcId, tradePartner.contacts],
  );

  const columns = useCreateColumns(tradePartner, data);
  const rows: GridDataRow<Row>[] = useMemo(() => simpleDataRows(tradePartner.contacts), [tradePartner.contacts]);

  if (selectedTpc && showCommsPreferences) {
    return <TradePartnerContactCommunicationPreferences tpContact={selectedTpc} />;
  }

  return (
    <div css={Css.df.$}>
      <div css={Css.w100.mw0.$}>
        <div css={Css.mb1.$}>
          <h2 css={Css.fwn.base.mb2.$}>All Contacts</h2>
          <div css={Css.df.jcsb.$}>
            <Filters
              filter={filterBundle.filter}
              filterDefs={filterBundle.filterDefs}
              onChange={filterBundle.setFilter}
            />
            <Button
              label="Add Contact"
              {...tid.addContact}
              icon="plus"
              variant="tertiary"
              onClick={() => {
                modal.openModal({
                  size: "xl",
                  content: (
                    <TradePartnerContactModal
                      tradePartnerId={tradePartner.id!}
                      contact={undefined}
                      markets={data.markets}
                    />
                  ),
                });
              }}
            />
          </div>
        </div>
        <GridTable
          stickyHeader
          columns={columns}
          rows={rows}
          fallbackMessage="No contacts found."
          sorting={{ on: "client" }}
        />
      </div>
      <div css={Css.ml3.$}>
        <Card>
          <TradePartnerContactsRolesPanel
            tradePartnerId={tradePartner.id}
            roles={tradePartner.marketContacts}
            contacts={tradePartner.contacts}
            markets={tradePartner.markets}
          />
        </Card>
      </div>
    </div>
  );
}

type Column = GridColumn<Row>;
type Row = SimpleHeaderAndData<ContactsTabTradePartnerContactFragment>;

function useCreateColumns(tradePartner: ContactsTabTradePartnerFragment, data: TradePartnerContactsQuery): Column[] {
  const tid = useTestIds({});
  const modal = useModal();
  const showCommsPreferencesFlag = useFeatureFlag(FeatureFlagType.TradePartnerCommunicationsSms);
  const [_, setQueryParams] = useQueryParams({ tpcId: StringParam });

  const [saveTPContactUser] = useSaveTradePartnerContactUserMutation({
    refetchQueries: [TradePartnerContactsDocument],
  });
  const toggleTPUPortalAccess = useCallback(
    (tpc: ContactsTabTradePartnerContactFragment, canLoginToPortal: boolean) =>
      saveTPContactUser({ variables: { input: { id: tpc.id, canLoginToPortal } } }),
    [saveTPContactUser],
  );

  return useMemo(
    () => [
      column<Row>({
        header: "Name",
        data: (tpc) => ({
          content: (
            <div>
              <div css={Css.xsMd.gray900.$}>{tpc.name}</div>
              {tpc.title && <div css={Css.xs.gray700.$}>{tpc.title}</div>}
              {!tpc.isActive && <div css={Css.xsMd.red400.$}>(Deactivated)</div>}
            </div>
          ),
          value: `${tpc.name} ${tpc.title}`,
        }),
      }),
      column<Row>({
        header: "Markets",
        data: (tpc) => <Chips values={tpc.markets.map((m) => m.name)} xss={Css.mt1.mb0.$} />,
        clientSideSort: false,
      }),
      column<Row>({
        header: "Phone",
        data: (tpc) => (
          <div>
            {tpc.officePhone && <div>O: {tpc.officePhone}</div>}
            {tpc.mobilePhone && <div>C: {tpc.mobilePhone}</div>}
          </div>
        ),
        clientSideSort: false,
      }),
      column<Row>({
        header: "Email",
        data: (tpc) => ({
          content: (
            <div css={Css.xsMd.wbba.$}>
              <a href={"mailto:" + tpc.email}>{tpc.email}</a>
            </div>
          ),
          value: tpc.email,
        }),
        w: 1.2,
      }),
      ...(tradePartner.canLoginToPortal
        ? [
            column<Row>({
              header: "Trade Portal Access",
              data: (tpc) => (
                <Checkbox
                  {...tid.enablePortal}
                  selected={isDefined(tpc.canLoginToPortal) ? tpc.canLoginToPortal : false}
                  label=""
                  onChange={(val) => toggleTPUPortalAccess(tpc, val)}
                  disabled={!tpc.tradePartner.canLoginToPortal || !tpc.email}
                />
              ),
              clientSideSort: false,
              align: "center",
              w: 0.5,
            }),
          ]
        : []),
      ...(showCommsPreferencesFlag
        ? [
            column<Row>({
              header: "Communication Preferences",
              data: (tpc) => (
                <Button
                  label="View"
                  disabled={!tpc.email ? "TP Contact Email set up is required" : undefined}
                  onClick={() => setQueryParams({ tpcId: tpc.id })}
                />
              ),
              clientSideSort: false,
              align: "center",
            }),
          ]
        : []),
      actionColumn<Row>({
        header: () => undefined,
        data: (tpc) => (
          <IconButton
            label="Edit"
            icon="pencil"
            onClick={() => {
              modal.openModal({
                size: "lg",
                content: (
                  <TradePartnerContactModal tradePartnerId={tradePartner.id} contact={tpc} markets={data.markets} />
                ),
              });
            }}
            {...tid.edit}
          />
        ),
        w: 0.5,
      }),
    ],
    [
      data.markets,
      modal,
      setQueryParams,
      showCommsPreferencesFlag,
      tid.edit,
      tid.enablePortal,
      toggleTPUPortalAccess,
      tradePartner.canLoginToPortal,
      tradePartner.id,
    ],
  );
}
