import { Css, useTestIds } from "@homebound/beam";

type EmptyStateProps = {
  message: string;
  svg: JSX.Element;
  button?: JSX.Element;
};

export function EmptyState({ message, button, svg }: EmptyStateProps) {
  const tid = useTestIds({}, "emptyState");
  return (
    <div css={Css.df.fdc.aic.bgGray100.p8.mt3.br8.$}>
      <div {...tid.image} css={Css.py4.$}>
        {svg}
      </div>
      <div {...tid.message} css={Css.xlSb.pb3.$}>
        {message}
      </div>
      {button && <div css={Css.mb2.$}>{button}</div>}
    </div>
  );
}
