import { BoundSelectField, SelectField } from "@homebound/beam";
import { PresentationFieldProps } from "@homebound/beam/dist/components/PresentationContext";
import { FieldState } from "@homebound/form-state";
import { ReactNode } from "react";
import {
  Maybe,
  NamedFragment,
  TradePartner,
  TradePartnerFilter,
  useTradePartnersSelectLazyQuery,
  useTradePartnersSelectQuery,
} from "src/generated/graphql-types";

export type TradePartnerSelectFieldProps = PresentationFieldProps & {
  label?: string;
  value: Maybe<Pick<TradePartner, "id" | "name">>;
  filter?: TradePartnerFilter;
  readOnly?: boolean | ReactNode;
  onSelect: (id: string | undefined, selectedTradePartner: NamedFragment | undefined) => void;
};

export function TradePartnerSelectField(props: TradePartnerSelectFieldProps) {
  const { filter, value, onSelect, readOnly, label, ...others } = props;
  const [load, { data }] = useTradePartnersSelectLazyQuery({
    variables: { filter: filter ?? {} },
    fetchPolicy: "cache-first",
  });
  return (
    <SelectField
      {...others}
      label={label ?? "Trade Partner"}
      readOnly={readOnly}
      onSelect={onSelect}
      options={{ current: value ?? undefined, load, options: data?.tradePartners }}
      value={value?.id}
    />
  );
}

type BoundTradePartnerSelectFieldProps = Omit<TradePartnerSelectFieldProps, "onSelect" | "value" | "readOnly"> & {
  field: FieldState<string | null | undefined>;
  onSelect?: (id: string | undefined, selectedTradePartner: NamedFragment | undefined) => void;
};

export function BoundTradePartnerSelectField({ field, ...props }: BoundTradePartnerSelectFieldProps) {
  const { filter, label, ...others } = props;
  const { data } = useTradePartnersSelectQuery({
    variables: { filter: filter ?? {} },
    fetchPolicy: "cache-first",
  });
  return (
    <BoundSelectField {...others} field={field} label={label ?? "Trade Partner"} options={data?.tradePartners ?? []} />
  );
}
