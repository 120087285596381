import {
  Button,
  column,
  Css,
  dateColumn,
  dateRangeFilter,
  DateRangeFilterValue,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  numericColumn,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  singleFilter,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { chipCell, dateCell, priceCell, SearchBox, tagCell } from "src/components";
import {
  CommitmentsFilter,
  CommitmentsPage_ChangeOrderFragment,
  CommitmentsPage_CommitmentFragment,
  DateOperation,
  Maybe,
  Stage,
  useCommitmentsPageMetadataQuery,
  useCommitmentsPageQuery,
} from "src/generated/graphql-types";
import { useStripStage } from "src/hooks/useStripStage";
import useZodQueryString from "src/hooks/useZodQueryString";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { NewCommitmentModal } from "src/routes/projects/commitments/components/NewCommitmentModal";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { createCommitmentChangeOrderUrl, createCommitmentUrl } from "src/RouteUrls";
import {
  commitmentStatusToTagTypeMapper,
  foldEnum,
  hasData,
  renderLoadingOrError,
  searchSchema,
  stageCodeToNameMapper,
} from "src/utils";
import { DateOnly } from "src/utils/dates";

export function CommitmentsPage() {
  useStripStage();
  const { projectId } = useParams<ProjectParams>();
  const {
    latestActiveStage,
    market: { id: marketId },
  } = useProjectContext();

  const { data: metadata } = useCommitmentsPageMetadataQuery({ variables: { projectId }, fetchPolicy: "cache-first" });
  const filterDefs: FilterDefs<FilterOptions> = useMemo(() => {
    const stages = [Stage.PreConExecution, Stage.Construction];

    return {
      stage: singleFilter({
        options: [...stages.map((s) => ({ id: s, name: stageCodeToNameMapper[s] }))],
        defaultValue: stages.includes(latestActiveStage) ? latestActiveStage : undefined,
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      }),
      status: multiFilter({
        options: metadata?.commitmentStatuses ?? [],
        getOptionValue: ({ code }) => code,
        getOptionLabel: ({ name }) => name,
      }),
      costCodes: multiFilter({
        options:
          metadata?.commitments
            .flatMap(({ costCodes }) => costCodes)
            .uniqueByKey("id")
            .sortByKey("displayName") ?? [],
        getOptionLabel: (cc) => cc.displayName,
        getOptionValue: (cc) => cc.id,
        label: "Cost codes",
      }),
      releasedAt: dateRangeFilter<string>({
        label: "Released",
        testFieldLabel: "Released",
      }),
    };
  }, [latestActiveStage, metadata]);

  const [{ search }, setSearch] = useZodQueryString(searchSchema);
  const { setFilter, filter } = usePersistedFilter<FilterOptions>({
    storageKey: `commitmentsPageFilter_${projectId}`,
    filterDefs,
  });
  const { openModal } = useModal();

  const handleSearch = useCallback((text: string) => setSearch({ search: text }), [setSearch]);

  return (
    <>
      <PageHeader
        title="Commitments"
        right={
          <Button
            label="Add New"
            onClick={() => {
              openModal({ content: <NewCommitmentModal projectId={projectId} marketId={marketId} /> });
            }}
            variant="tertiary"
          />
        }
      />
      <TableActions>
        <Filters<FilterOptions> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <SearchBox debounceDelayInMs={500} onSearch={handleSearch} />
      </TableActions>
      <ScrollableContent>
        <CommitmentsTable projectId={projectId} filter={filter} searchFilter={search} />
      </ScrollableContent>
    </>
  );
}

type FilterOptions = Omit<CommitmentsFilter, "stage"> & {
  releasedAt: DateRangeFilterValue<string>;
  stage?: Maybe<Stage>;
};

type CommitmentsTableProps = {
  projectId: string;
  filter: FilterOptions;
  searchFilter?: string;
};

function CommitmentsTable({ projectId, filter, searchFilter }: CommitmentsTableProps) {
  const query = useCommitmentsPageQuery({ variables: { filter: mapToCommitmentsFilter(projectId, filter) } });
  const commitmentsTableId = "project-commitments-table";
  const rowStyles = useMemo(() => createRowStyles(projectId), [projectId]);

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const commitments = query.data.commitments;
  return (
    <GridTable<Row>
      columns={createColumns()}
      rows={mapRows(commitments)}
      fallbackMessage={
        searchFilter
          ? "There are no commitments matching your search."
          : "There are no commitments for the selected stage(s)."
      }
      stickyHeader
      sorting={{ on: "client", initial: ["po#", "DESC"] }}
      filter={searchFilter}
      persistCollapse={commitmentsTableId}
      style={{ rowHeight: "fixed", bordered: true, allWhite: true }}
      rowStyles={rowStyles}
    />
  );
}

type HeaderRow = { kind: "header" };
type CommitmentRow = { kind: "commitment"; data: CommitmentsPage_CommitmentFragment };
type ChangeOrderRow = { kind: "changeOrder"; data: CommitmentsPage_ChangeOrderFragment };
type TotalsRow = { kind: "totals"; id: string; data: { name: string } & CommitmentTotals };
type Row = HeaderRow | CommitmentRow | ChangeOrderRow | TotalsRow;
type CommitmentTotals = {
  committedInCents: number;
  billedInCents: number;
  paidInCents: number;
  balanceInCents: number;
  remainingInCents: number;
};

function createRowStyles(projectId: string): RowStyles<Row> {
  return {
    commitment: { rowLink: (row) => createCommitmentUrl(projectId, row.data.id) },
    changeOrder: { rowLink: (row) => createCommitmentChangeOrderUrl(projectId, row.data.commitment.id, row.data.id) },
  };
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      id: "po#",
      totals: "Totals",
      header: "PO#",
      commitment: ({ accountingNumber }) => ({
        content: () => `#${accountingNumber}`,
        sortValue: accountingNumber,
      }),
      changeOrder: ({ accountingNumber }) => ({
        content: () => `#${accountingNumber}`,
        sortValue: accountingNumber,
      }),
      w: "120px",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Trade Partner",
      commitment: (commitment) => commitment.name,
      changeOrder: (changeOrder) => ({
        content: <div css={Css.pl2.$}>{changeOrder.name}</div>,
        value: changeOrder.name,
      }),
      w: "160px",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Item Code",
      commitment: ({ lineItems }) => {
        const chips = lineItems.map((li) => li.projectItem.item.displayName).sort();
        return chipCell(chips);
      },
      changeOrder: ({ lineItems }) => {
        const chips = lineItems.map((li) => li.projectItem.item.displayName).sort();
        return chipCell(chips);
      },
      mw: "200px",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Type",
      commitment: ({ commitmentType }) =>
        foldEnum(commitmentType.code, {
          PURCHASE_ORDER: "PO",
          SUBCONTRACT: "SUB",
        }),
      changeOrder: () => "CO",
      w: "80px",
    }),
    dateColumn<Row>({
      totals: emptyCell,
      header: "Last Edited",
      commitment: ({ updatedAt }) => dateCell(updatedAt),
      changeOrder: ({ updatedAt }) => dateCell(updatedAt),
      w: "110px",
    }),
    dateColumn<Row>({
      totals: emptyCell,
      header: "Signed On",
      commitment: ({ executionDate }) => dateCell(executionDate),
      changeOrder: ({ executionDate }) => dateCell(executionDate),
      w: "110px",
    }),
    numericColumn<Row>({
      totals: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
      header: "Committed",
      commitment: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
      changeOrder: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
      w: "120px",
    }),
    numericColumn<Row>({
      totals: ({ billedInCents }) => priceCell({ valueInCents: billedInCents }),
      header: "Billed",
      commitment: ({ pendingBilledInCents }) => priceCell({ valueInCents: pendingBilledInCents }),
      changeOrder: ({ pendingBilledInCents }) => priceCell({ valueInCents: pendingBilledInCents }),
      w: "110px",
    }),
    numericColumn<Row>({
      totals: ({ remainingInCents }) => priceCell({ valueInCents: remainingInCents }),
      header: "Remaining",
      commitment: (commitment) => priceCell({ valueInCents: commitment.pendingRemainingInCents }),
      changeOrder: (changeOrder) => priceCell({ valueInCents: changeOrder.pendingRemainingInCents }),
      w: "120px",
    }),
    numericColumn<Row>({
      totals: ({ paidInCents }) => priceCell({ valueInCents: paidInCents }),
      header: "Paid to Date",
      commitment: ({ paidInCents }) => priceCell({ valueInCents: paidInCents }),
      changeOrder: ({ paidInCents }) => priceCell({ valueInCents: paidInCents }),
      w: "120px",
    }),
    numericColumn<Row>({
      totals: ({ balanceInCents }) => priceCell({ valueInCents: balanceInCents }),
      header: "Balance Due",
      commitment: ({ balanceInCents }) => priceCell({ valueInCents: balanceInCents }),
      changeOrder: ({ balanceInCents }) => priceCell({ valueInCents: balanceInCents }),
      w: "120px",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Status",
      commitment: ({ status, statusText }) => tagCell(commitmentStatusToTagTypeMapper[status], statusText),
      changeOrder: ({ status, statusText }) => tagCell(commitmentStatusToTagTypeMapper[status], statusText),
      w: "88px",
    }),
  ];
}

function mapRows(commitments: CommitmentsPage_CommitmentFragment[]): GridDataRow<Row>[] {
  return [
    { kind: "totals", id: "totals", data: { name: "Totals", ...sumCommitments(commitments) } },
    simpleHeader,
    ...commitments.map((c) => ({
      kind: "commitment" as const,
      id: c.id,
      data: c,
      children: c.changeOrders.map((co) => ({
        kind: "changeOrder" as const,
        id: co.id,
        data: co,
      })),
    })),
  ];
}

export function sumCommitments(commitments: CommitmentsPage_CommitmentFragment[]): CommitmentTotals {
  const allCommitments = commitments.flatMap(({ changeOrders, ...contract }) => [contract, ...changeOrders]);

  return allCommitments.reduce(
    (acc, contract) => {
      return {
        remainingInCents: acc.remainingInCents + (contract.pendingRemainingInCents || 0),
        committedInCents: acc.committedInCents + (contract.committedInCents || 0),
        billedInCents: acc.billedInCents + (contract.pendingBilledInCents || 0),
        paidInCents: acc.paidInCents + (contract.paidInCents || 0),
        balanceInCents: acc.balanceInCents + (contract.balanceInCents || 0),
      };
    },
    {
      remainingInCents: 0,
      committedInCents: 0,
      billedInCents: 0,
      paidInCents: 0,
      balanceInCents: 0,
    },
  );
}

function mapToCommitmentsFilter(projectId: string, filter: FilterOptions): CommitmentsFilter {
  return {
    projectId,
    status: filter.status,
    costCodes: filter.costCodes,
    stage: filter.stage ? [filter.stage] : undefined,
    executionDate: filter.releasedAt?.value
      ? {
          op: DateOperation.Between,
          value: new DateOnly(new Date(filter.releasedAt.value.from!)),
          value2: new DateOnly(new Date(filter.releasedAt.value.to!)),
        }
      : undefined,
  };
}
