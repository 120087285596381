import { BoundSelectField, BoundTextField, Button, Css, SuperDrawerHeader, useSuperDrawer } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useState } from "react";
import {
  ChangeEventStatus,
  ChangeEventType,
  OverviewTabProjectCutoffFragment,
  SaveChangeEventInput,
  useDraftChangeEventDrawerQuery,
  useSaveChangeEventMutation,
} from "src/generated/graphql-types";
import { createChangeEventUrl } from "src/RouteUrls";
import { filterArchivedUnlessSelected } from "src/utils/changeEventReasons";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";

type DraftChangeEventDrawerProps = {
  projectCutoff: OverviewTabProjectCutoffFragment;
  projectId: string;
  projectStageId: string;
};

const INITIAL_CHANGE_REASON_CODE = "FINALIZE_FOR_CUTOFF";

export function DraftChangeEventDrawer({ projectCutoff, projectId, projectStageId }: DraftChangeEventDrawerProps) {
  const { closeDrawerDetail } = useSuperDrawer();
  const { data } = useDraftChangeEventDrawerQuery();
  const [saveChangeEvent] = useSaveChangeEventMutation();
  const [changeEventId, setChangeEventId] = useState<string>();
  const changeEventReasons = filterArchivedUnlessSelected(data?.changeEventReasons);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: data,
      map: () => ({
        title: projectCutoff.name,
        reasonId: changeEventReasons.find((reason) => reason.code === INITIAL_CHANGE_REASON_CODE)?.id,
      }),
    },
  });

  if (!!changeEventId) {
    return (
      <>
        <SuperDrawerHeader title="Manage Cutoffs" />
        <div css={Css.df.jcc.aic.mtPx(300).$}>
          <div css={Css.df.fdc.jcc.aic.$}>
            <p css={Css.lgSb.gray900.mb1.$}>Change Event Created</p>
            <p css={Css.smMd.gray700.mb2.maxwPx(360).ta("center").$}>
              To finish finalizing selections in this cutoff, create a change order from this change event.
            </p>
            <div css={Css.mb1.$}>
              <Button
                label="View Change Event"
                onClick={() => openNewTab(createChangeEventUrl(projectId, changeEventId))}
              />
            </div>
            <div>
              <Button variant="tertiary" label="Return to Cutoffs" onClick={closeDrawerDetail} />
            </div>
          </div>
        </div>
      </>
    );
  }

  const numberOfSelections = projectCutoff.homeownerSelections.length;

  return (
    <>
      <SuperDrawerHeader title="Manage Cutoffs" />
      <div css={Css.df.jcc.aic.mtPx(245).$}>
        <div css={Css.df.fdc.jcc.aic.$}>
          <p css={Css.lgSb.gray900.mb1.$} data-testid="drawerTitle">
            Draft Change Event
          </p>
          <p css={Css.smMd.gray700.mb2.$}>
            This change event will include {numberOfSelections} selection{numberOfSelections !== 1 ? "s" : ""}.
          </p>
          <div css={Css.wPx(360).mb3.$}>
            <div css={Css.mt1.df.fdc.gap2.$}>
              <BoundTextField label="Name" field={formState.title} />
              <BoundSelectField
                label="Reason for Change"
                field={formState.reasonId}
                options={changeEventReasons}
                getOptionLabel={(reason) => reason.name}
                getOptionValue={(reason) => reason.id}
              />
            </div>
          </div>
          <div css={Css.mb1.$}>
            <Observer>
              {() => (
                <Button
                  disabled={!formState.valid}
                  label="Create Change Event"
                  onClick={async () => {
                    if (formState.canSave()) {
                      try {
                        const result = await saveChangeEvent({
                          variables: {
                            input: {
                              ...formState.value,
                              type: ChangeEventType.External,
                              projectCutoffId: projectCutoff.id,
                              projectStageId,
                              status: ChangeEventStatus.Draft,
                            },
                          },
                        });
                        const id = result.data?.saveChangeEvent.changeEvent.id;
                        formState.commitChanges();
                        setChangeEventId(id);
                      } catch (e) {
                        console.error(e);
                      }
                    }
                  }}
                />
              )}
            </Observer>
          </div>
          <div>
            <Button variant="tertiary" label="Cancel" onClick={closeDrawerDetail} />
          </div>
        </div>
      </div>
    </>
  );
}

type FormValue = Pick<SaveChangeEventInput, "title" | "reasonId">;

const formConfig: ObjectConfig<FormValue> = {
  reasonId: { type: "value", rules: [required] },
  title: { type: "value", rules: [required] },
};
