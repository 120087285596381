import { Button, ButtonModal, Css } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useCallback } from "react";
import {
  SaveScheduleTaskInput,
  TaskFilterOptionsFragment,
  TaskScheduleDetailsFragment,
} from "src/generated/graphql-types";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { setTaskPaneState } from "../contexts/scheduleStoreReducer";
import { DependencyTypes, TaskDependencies } from "../detail-pane/TaskDependencies";
import { TaskDetailsFormInput } from "./utils";

export type ScheduleTaskWithDependencies = Pick<
  TaskScheduleDetailsFragment,
  "id" | "successorDependencies" | "predecessorDependencies"
>;

type DependencyCellProps = {
  task: ScheduleTaskWithDependencies;
  dependencyType: DependencyTypes;
  formState: ObjectState<Partial<TaskDetailsFormInput>>;
  tasks: TaskFilterOptionsFragment[];
  onSave: (changedValue: SaveScheduleTaskInput) => void;
  scheduleIsLocked: boolean;
  defaultOpen?: boolean; // Only used for stories
  setPaneId?: (taskId: string) => void;
};

export function DependencyCell({
  task,
  dependencyType,
  formState,
  tasks,
  onSave,
  scheduleIsLocked,
  defaultOpen,
  setPaneId,
}: DependencyCellProps) {
  const {
    dispatch,
    scheduleState: { taskNumberMap },
  } = useScheduleStore();

  // when you click on the +more button, we update taskPaneState to open and scroll to the dependencies column section
  const onDependencyTaskRowNumberClick = useCallback(
    (taskId: string, scrollIntoViewType: DependencyTypes | undefined = undefined) => {
      dispatch(
        setTaskPaneState({
          taskPaneId: taskId,
          scrollIntoViewType,
          tab: "details",
        }),
      );
    },
    [dispatch],
  );

  const onJump = useCallback(
    async (taskId: string) => {
      dispatch(
        setTaskPaneState({
          taskPaneId: taskId,
          scrollIntoViewType: dependencyType,
          tab: "details",
        }),
      );
      setPaneId && setPaneId(taskId);
    },
    [dependencyType, dispatch, setPaneId],
  );

  const dependencyTasks =
    dependencyType === "predecessor"
      ? task.predecessorDependencies.map((pd) => ({
          dependency: pd.predecessor,
          type: pd.type,
          lagInDays: pd.lagInDays,
        }))
      : task.successorDependencies.map((pd) => ({ dependency: pd.successor, type: pd.type, lagInDays: pd.lagInDays }));

  const dependencyTaskRowNumbers = dependencyTasks
    .filter((pd) => taskNumberMap[pd.dependency.id] !== undefined)
    .map(
      (pd) =>
        `${taskNumberMap[pd.dependency.id]}${
          pd.lagInDays > 0 ? `+${pd.lagInDays}` : pd.lagInDays < 0 ? `${pd.lagInDays}` : ""
        }`,
    )
    .slice(0, 3)
    .sort((a, b) => a.localeCompare(b));

  const dependencyTaskRowNumbersText = dependencyTaskRowNumbers.join(", ");
  const numberOfAdditionalTasks = dependencyTasks.length - dependencyTaskRowNumbers.length;
  const label = `${dependencyTasks.length ? `${dependencyTaskRowNumbersText} ` : "--"}`;

  return (
    <div key={`${dependencyType}-${task.id}`} data-testid={dependencyType} css={Css.df.jcfs.gap1.wPx(200).$}>
      <ButtonModal
        content={
          <div css={Css.wPx(420).$}>
            <TaskDependencies
              formState={formState}
              tasks={tasks}
              taskId={task.id}
              onSave={onSave}
              onJump={onJump}
              scheduleIsLocked={scheduleIsLocked}
              type={dependencyType}
            />
            {numberOfAdditionalTasks > 0 && (
              <div css={Css.df.jcc.mt1.$}>
                <Button
                  label={`+${numberOfAdditionalTasks} more`}
                  variant="text"
                  onClick={() => onDependencyTaskRowNumberClick(task.id, dependencyType)}
                />
              </div>
            )}
          </div>
        }
        trigger={{ label: label }}
        hideEndAdornment
        showActiveBorder
        storybookDefaultOpen={defaultOpen}
      />
      {numberOfAdditionalTasks ? (
        <Button
          label={`+${numberOfAdditionalTasks} more`}
          variant="text"
          onClick={() => onDependencyTaskRowNumberClick(task.id, dependencyType)}
        />
      ) : (
        ""
      )}
    </div>
  );
}
