import {
  BoundTextAreaField,
  Button,
  Checkbox,
  column,
  Css,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  Palette,
  useSnackbar,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useParams } from "react-router";
import { dateCell } from "src/components";
import {
  AssetInfoFragment,
  EditHomeownerNoteFragment,
  HomeownerNoteScheduleTaskFragment,
  Maybe,
  Scalars,
  TaskStatus,
  useDeleteHomeownerNoteMutation,
  useEditHomeownerNoteQuery,
  useSaveHomeownerNoteMutation,
  useScheduleTasksForHomeownerNoteQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

type CreateHomeownerNoteRightPaneProps = {
  onClose: () => void;
  onSuccess: () => void;
  homeownerNoteId?: string;
  selectedImages?: AssetInfoFragment[];
};

export function HomeownerNoteRightPane({
  homeownerNoteId,
  onClose,
  onSuccess,
  selectedImages = [],
}: CreateHomeownerNoteRightPaneProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const editing = !!homeownerNoteId;

  const query = useScheduleTasksForHomeownerNoteQuery({
    variables: {
      project: [projectId],
    },
    skip: editing,
  });

  const editQuery = useEditHomeownerNoteQuery({
    variables: {
      id: homeownerNoteId!,
    },
    skip: !editing,
  });

  if (editing) {
    return queryResult(editQuery, ({ homeownerNote }) => (
      <div css={Css.df.fdc.py3.h100.$} data-testid="editHomeownerNoteRightPane">
        <div css={Css.df.fdc.pb3.maxw100.px3.$}>
          <div css={Css.df.jcsb.w100.$}>
            <h1 css={Css.lgSb.$}>Edit Homeowner Note</h1>
            <IconButton data-testid="closePane" icon="x" onClick={onClose} />
          </div>
        </div>
        <CreateHomeownerNoteRightPaneView onClose={onClose} onSuccess={onSuccess} homeownerNote={homeownerNote} />
      </div>
    ));
  }

  return queryResult(query, ({ scheduleTasks }) => (
    <div css={Css.df.fdc.py3.h100.$} data-testid="createHomeownerNoteRightPane">
      <div css={Css.df.fdc.pb3.maxw100.px3.$}>
        <div css={Css.df.jcsb.w100.$}>
          <h1 css={Css.lgSb.$}>New Homeowner Note</h1>
          <IconButton data-testid="closePane" icon="x" onClick={onClose} />
        </div>
      </div>
      <CreateHomeownerNoteRightPaneView
        onClose={onClose}
        onSuccess={onSuccess}
        scheduleTasks={scheduleTasks || []}
        selectedImages={selectedImages}
      />
    </div>
  ));
}

type Row = GridDataRow<{ kind: "scheduleTask"; id: string; data: HomeownerNoteScheduleTaskFragment }>;
function CreateHomeownerNoteRightPaneView({
  homeownerNote,
  scheduleTasks = [],
  selectedImages = [],
  onClose,
  onSuccess,
}: {
  homeownerNote?: EditHomeownerNoteFragment;
  scheduleTasks?: HomeownerNoteScheduleTaskFragment[];
  selectedImages?: AssetInfoFragment[];
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { projectId } = useParams<{ projectId: string }>();
  const [saveNote] = useSaveHomeownerNoteMutation();
  const [deleteNote] = useDeleteHomeownerNoteMutation();
  const { triggerNotice } = useSnackbar();
  const form = useFormState({
    config: hmwrNoteConfig,
    init: {
      input: { projectId, selectedImages, homeownerNote },
      map: ({ projectId, selectedImages, homeownerNote }) => ({
        projectId,
        jobLogImageIds: homeownerNote ? null : selectedImages?.map(({ id }) => id) || [],
        title: homeownerNote?.title,
        description: homeownerNote?.description,
        scheduleTaskIds: null,
      }),
    },
  });

  const images = homeownerNote?.jobLogImages.map((jli) => jli.asset) || selectedImages || [];

  const today = new Date();
  // Completed tasks
  const completedTasks = scheduleTasks
    .filter((st) => st.status === TaskStatus.Complete)
    .sort((st1, st2) => {
      if (!st1.interval?.endDate || !st2.interval?.endDate) return 0;
      return st1.interval?.endDate > st2.interval?.endDate ? -1 : 1;
    });
  // In Progress tasks
  const inProgressTasks = scheduleTasks.filter((st) => st.status === TaskStatus.InProgress);
  // Upcoming tasks; the not started tasks status includes many more than upcoming
  const upcomingTasks = scheduleTasks.filter((st) => st.interval?.startDate > today);

  async function onSubmit() {
    await saveNote({
      variables: {
        input: {
          id: homeownerNote?.id,
          projectId: form.projectId.value,
          title: form.title.value,
          description: form.description.value,
          jobLogImageIds: form.jobLogImageIds.value,
          scheduleTaskIds: form.scheduleTaskIds.value,
        },
      },
    });

    triggerNotice({ icon: "success", message: "Homeowner Note successfully created" });
    onSuccess();
  }

  const handleHomeownerNoteDelete = async () => {
    if (homeownerNote?.id) {
      await deleteNote({
        refetchQueries: ["HomeownerNotesForProject"],
        variables: {
          id: homeownerNote.id,
        },
      });

      onSuccess();
    }
  };

  return (
    <Observer>
      {() => (
        <>
          <div css={Css.df.fdc.h100.w100.gap4.px3.oya.pb3.$}>
            <div css={Css.df.fdc.gap1.$}>
              <h2 css={Css.gray900.smBd.$}>Title</h2>
              <BoundTextAreaField
                label="Title"
                placeholder="Homeowner Note Title"
                preventNewLines
                labelStyle="hidden"
                borderless
                xss={Css.xl2Sb.$}
                field={form.title}
              />
            </div>
            {/* description */}
            <div css={Css.df.fdc.gap1.$}>
              <h2 css={Css.gray900.smBd.$}>Description</h2>
              <BoundTextAreaField
                label="Description"
                labelStyle="hidden"
                placeholder="Heres a progress update on recently completed tasks"
                field={form.description}
              />
            </div>
            {/* photos */}
            <div css={Css.df.fdc.gap1.$}>
              <h2 css={Css.gray900.smBd.$}>Photos</h2>
              <div css={Css.df.fdr.w100.gap1.add("flexWrap", "wrap").mhPx(86).$}>
                {images.map(({ id, previewUrl }) => (
                  <img key={id} data-testid={id} src={previewUrl || undefined} css={Css.wPx(86).hPx(86).$} alt={id} />
                ))}
              </div>
            </div>
            {scheduleTasks.length > 0 && (
              <>
                <div css={Css.df.fdc.gap1.$}>
                  <h2 css={Css.gray900.smBd.$}>Attached Schedule Tasks</h2>
                  <div css={Css.sm.lh("22px").$}>
                    Select from homeowner visible tasks below to attach. They will automatically attach client facing
                    descriptions added at a schedule template level.
                  </div>
                </div>

                <h2 css={Css.gray700.baseBd.lh("18px").$}>Completed Tasks</h2>
                <div data-testid="completedTaskTable" css={Css.mhPx(125).oya.$}>
                  <GridTable<Row>
                    xss={Css.bt.bcGray200.br8.$}
                    columns={createColumns(form)}
                    style={{
                      rowHeight: "fixed",
                      bordered: true,
                    }}
                    rows={createRows(completedTasks)}
                  />
                </div>

                <h2 css={Css.gray700.baseBd.lh("18px").$}>In Progress Tasks</h2>
                <div data-testid="inProgressTaskTable" css={Css.mhPx(125).oya.$}>
                  <GridTable<Row>
                    xss={Css.bt.bcGray200.br8.$}
                    columns={createColumns(form)}
                    style={{
                      rowHeight: "fixed",
                      bordered: true,
                    }}
                    rows={createRows(inProgressTasks)}
                  />
                </div>

                <h2 css={Css.gray700.baseBd.lh("18px").$}>Upcoming Tasks</h2>
                <div data-testid="upcomingTaskTable" css={Css.mhPx(125).oya.$}>
                  <GridTable<Row>
                    xss={Css.bt.bcGray200.br8.$}
                    columns={createColumns(form, true)}
                    style={{
                      rowHeight: "fixed",
                      bordered: true,
                    }}
                    rows={createRows(upcomingTasks)}
                  />
                </div>
              </>
            )}
          </div>
          <div css={Css.df.jcfe.gap1.px3.pt2.mt2.add("borderTop", `1px solid ${Palette.Gray200}`).$}>
            <Button label="Cancel" variant="text" onClick={onClose} />
            {homeownerNote && (
              <Button label="Delete Note" variant="tertiaryDanger" icon="trash" onClick={handleHomeownerNoteDelete} />
            )}
            <Button
              label={homeownerNote ? "Edit note" : "Add note"}
              type="submit"
              disabled={!form.valid || !form.dirty}
              onClick={onSubmit}
            />
          </div>
        </>
      )}
    </Observer>
  );
}
function createColumns(form: ObjectState<HmwrFormValue>, showStartDate: boolean = false): GridColumn<Row>[] {
  return [
    column<Row>({
      clientSideSort: false,
      align: "center",
      w: "40px",
      wrapAction: false,
      isAction: true,
      scheduleTask: ({ id }) => {
        return (
          <Checkbox
            label=""
            checkboxOnly
            selected={form?.scheduleTaskIds?.value?.includes(id) ?? false}
            onChange={(selected) => {
              const cur = form.scheduleTaskIds.value || [];
              if (!selected) {
                form.scheduleTaskIds.set(cur.filter((x) => x !== id));
              } else {
                form.scheduleTaskIds.set([...cur, id]);
              }
            }}
          />
        );
      },
    }),
    column<Row>({
      w: 3,
      scheduleTask: ({ name }) => ({ content: name }),
    }),
    dateColumn<Row>({
      scheduleTask: ({ interval }) =>
        dateCell(showStartDate ? interval?.startDate : interval?.endDate, { dateFormatStyle: "monthShort" }),
    }),
  ];
}

function createRows(scheduleTasks: HomeownerNoteScheduleTaskFragment[]): GridDataRow<Row>[] {
  return scheduleTasks.map((st) => ({
    kind: "scheduleTask",
    id: st.id,
    data: st,
  }));
}

type HmwrFormValue = {
  projectId: Maybe<Scalars["ID"]>;
  title: Maybe<string>;
  description: Maybe<string>;
  jobLogImageIds: Maybe<Array<Scalars["ID"]>>;
  scheduleTaskIds: Maybe<Array<Scalars["ID"]>>;
};

const hmwrNoteConfig: ObjectConfig<HmwrFormValue> = {
  projectId: { type: "value", rules: [required] },
  title: { type: "value", rules: [required] },
  description: { type: "value", rules: [required] },
  jobLogImageIds: { type: "value", rules: [] },
  scheduleTaskIds: {
    type: "value",
    rules: [],
  },
};
