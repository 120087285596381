import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import {
  AddOptionsMetadataQuery,
  AddReadyPlanOptionFragment,
  GlobalOption,
  IncrementalCollectionOp,
  InputMaybe,
  Location,
  Maybe,
  PlanDetailsStep_ReadyPlanFragment,
  ProgramDataForLotSequenceFragment,
  ReadyPlanDetailsFragment,
  ReadyPlanOptionGroupFragment,
  SaveReadyPlanInput,
  SaveReadyPlanOptionGroupInput,
  SaveReadyPlanOptionInput,
  SaveReadyPlanOptionProgramDataModificationInput,
  SaveReadyPlanProgramDataInput,
  useSaveReadyPlanMutation,
  useSaveReadyPlanOptionGroupsMutation,
  useSaveReadyPlanOptionsMutation,
} from "src/generated/graphql-types";
import { HasIdAndName } from "src/utils";
import { ProgramDataKey } from "../../lot-summary/sequence-sheet/components/utils";

type ChildOptions = Maybe<SaveReadyPlanOptionInput> & { globalOptionName?: string };

export type ProgramDataModifications = Maybe<
  Omit<SaveReadyPlanOptionProgramDataModificationInput, "modifiedOptionId" | "matchesOptionId">
> & {
  globalOptionCode?: string;
  modifiedOption: { id: string };
  matchesOption?: {
    id: string;
    globalOption: {
      id: string;
      code: string;
    };
  };
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
};

// Extending the `SaveReadyPlanOptionInput` to help with rendering the UI
export type AddReadyPlanOption = Omit<SaveReadyPlanOptionInput, "childOptions"> & {
  fragment?: AddReadyPlanOptionFragment;
  optionTypeId: Maybe<string>;
  globalTypeName?: Maybe<string>;
  globalOptionCode?: Maybe<string>;
  globalOptionDescription?: Maybe<string>;
  isElevation?: Maybe<boolean>;
  globalOptionLocation?: Maybe<Pick<Location, "id" | "name">>;
  childOptions?: ChildOptions[];
  programDataModifiedBy?: ProgramDataModifications[];
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
  optionGroup?: ReadyPlanOptionGroupFragment;
};

export type AddReadyPlanOptionsForm = {
  readyPlanId: string;
  readyPlanOptions: AddReadyPlanOption[];
};

export const addRpoConfig: ObjectConfig<AddReadyPlanOptionsForm> = {
  readyPlanId: { type: "value" },
  readyPlanOptions: {
    type: "list",
    config: {
      fragment: { type: "value" },
      id: { type: "value" },
      name: { type: "value" },
      active: { type: "value", rules: [required] },
      globalOptionId: { type: "value", rules: [required] },
      readyPlanId: { type: "value", rules: [required] },
      locationId: { type: "value" },
      optionTypeId: { type: "value" },
      globalTypeName: { type: "value" },
      globalOptionCode: { type: "value" },
      globalOptionDescription: { type: "value" },
      globalOptionLocation: { type: "value" },
      isElevation: { type: "value" },
      programDatas: {
        type: "list",
        config: {
          id: { type: "value" },
          op: { type: "value" },
          readyPlanOptionIds: { type: "value" },
          programData: { type: "object", config: completePDConfig() },
        },
      },
      programData: {
        type: "object",
        config: completePDConfig(),
      },
      programDataModifiedBy: {
        type: "list",
        config: {
          id: { type: "value" },
          globalOptionCode: { type: "value" },
          modifiedOption: { type: "value" },
          matchesOption: { type: "value" },
          programData: {
            type: "object",
            config: completePDConfig(),
          },
        },
      },
      childOptions: {
        type: "list",
        config: {
          id: { type: "value" },
          active: { type: "value", rules: [required] },
          globalOptionId: { type: "value", rules: [required] },
          globalOptionName: { type: "value" },
        },
      },
      optionGroup: { type: "value" },
    },
  },
};

// Extending the `SaveReadyPlanOptionInput` to help with rendering the UI
export type ConfigureReadyPlanOption = Omit<SaveReadyPlanOptionInput, "childOptions"> & {
  fragment?: AddReadyPlanOptionFragment;
  optionTypeId: Maybe<string>;
  globalTypeName?: Maybe<string>;
  globalOptionCode?: Maybe<string>;
  globalOptionDescription?: Maybe<string>;
  isElevation?: Maybe<boolean>;
  globalOptionLocation?: Maybe<Pick<Location, "id" | "name">>;
  childOptions?: ChildOptions[];
  programDataModifiedBy?: ProgramDataModifications[];
  // Add derived fields that are not in the inputs, so the UI can render them
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
  optionGroup?: Omit<ReadyPlanOptionGroupFragment, "required"> & { required: InputMaybe<boolean> };
  optionDefaultsIfIds: InputMaybe<string[]>;
  optionPrerequisiteIds: InputMaybe<string[]>;
  optionConflictIds: InputMaybe<string[]>;
  optionConflictChildIds: InputMaybe<string[]>;
  order: InputMaybe<number>;
};

export type ConfigureReadyPlanOptionsForm = {
  readyPlanOptions: ConfigureReadyPlanOption[];
};

export const configureRpoConfig: ObjectConfig<ConfigureReadyPlanOptionsForm> = {
  readyPlanOptions: {
    type: "list",
    config: {
      fragment: { type: "value" },
      id: { type: "value" },
      name: { type: "value" },
      active: { type: "value", rules: [required] },
      globalOptionId: { type: "value", rules: [required] },
      readyPlanId: { type: "value", rules: [required] },
      locationId: { type: "value" },
      optionTypeId: { type: "value" },
      globalTypeName: { type: "value" },
      globalOptionCode: { type: "value" },
      globalOptionDescription: { type: "value" },
      globalOptionLocation: { type: "value" },
      isElevation: { type: "value" },
      optionGroup: {
        type: "object",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          isMultiOptionGroup: { type: "value" },
          isSingleOptionGroup: { type: "value" },
          order: { type: "value" },
          required: { type: "value" },
        },
      },
      optionDefaultsIfIds: { type: "value" },
      optionPrerequisiteIds: { type: "value" },
      optionConflictIds: { type: "value" },
      optionConflictChildIds: { type: "value" },
      order: { type: "value" },
    },
  },
};

export type AddProgramDetailsForm = {
  id?: string;
  description: Maybe<string>;
  programData: Maybe<
    SaveReadyPlanInput["programData"] & {
      readyPlanProgramData?: PlanDetailsStep_ReadyPlanFragment["programData"]["readyPlanProgramData"];
    }
  >;
  minProgramData: Maybe<SaveReadyPlanInput["minProgramData"]>;
  maxProgramData: Maybe<SaveReadyPlanInput["maxProgramData"]>;
};

export const readyPlanProgramDataConfig: ObjectConfig<AddProgramDetailsForm> = {
  id: { type: "value" },
  description: { type: "value" },
  programData: {
    type: "object",
    config: readyPlanProgramDataConfigConfig({ readyPlanProgramData: { type: "value" } }),
  },
  minProgramData: {
    type: "object",
    config: readyPlanProgramDataConfigConfig(),
  },
  maxProgramData: {
    type: "object",
    config: readyPlanProgramDataConfigConfig(),
  },
};

export function getExistingAndActiveOptions(
  globalSchemes: Pick<GlobalOption, "id" | "code" | "name">[],
  currentOptions: { globalOption: Pick<GlobalOption, "id" | "name"> }[],
): HasIdAndName[] {
  // Modify activeGlobalSchemes to include the code of the global option in the name
  const globalSchemesWithCode = globalSchemes.map(({ id, code, name }) => ({ id, name: `${code} - ${name}` }));
  return currentOptions
    .map((rpo) => rpo.globalOption as HasIdAndName)
    .concat(globalSchemesWithCode)
    .uniqueByKey("id")
    .sortByKey("name");
}

export function completePDConfig() {
  return {
    ...readyPlanProgramDataConfigConfig(),
    sellableSqft: { type: "value" },
    permittableSqft: { type: "value" },
    grossBuildableSqft: { type: "value" },
    netBuildableSqft: { type: "value" },
    sellableAboveGroundSqft: { type: "value" },
    sellableBelowGroundSqft: { type: "value" },
    grossBelowGroundSqft: { type: "value" },
    unfinishedBelowGroundSqft: { type: "value" },
    imperviousSqft: { type: "value" },
  };
}

export type AddOptionsMetadata = Pick<AddOptionsMetadataQuery, "enumDetails"> | undefined;
export type OptionsEnumNames = Exclude<keyof AddOptionsMetadataQuery["enumDetails"], "__typename">;

function readyPlanProgramDataConfigConfig(extra: Record<string, unknown> = {}) {
  return {
    stories: { type: "value" },
    widthInFeet: { type: "value" },
    minLotSizeInSqft: { type: "value" },
    minLotDepthInFeet: { type: "value" },
    minLotWidthInFeet: { type: "value" },
    buildingHeightInFeet: { type: "value" },
    depthInFeet: { type: "value" },
    bedrooms: { type: "value" },
    closetsInPrimarySuite: { type: "value" },
    fullBaths: { type: "value" },
    halfBaths: { type: "value" },
    garageAttached: { type: "value" },
    garageDetached: { type: "value" },
    garagePort: { type: "value" },
    garageConfiguration: { type: "value" },
    basementConfig: { type: "value" },
    firstFloorBedrooms: { type: "value" },
    diningRoom: { type: "value" },
    casualDining: { type: "value" },
    mediaRooms: { type: "value" },
    loftGameFlexRooms: { type: "value" },
    offices: { type: "value" },
    workspaces: { type: "value" },
    primaryBedroom: { type: "value" },
    ...extra,
  };
}

export async function saveReadyPlanOptionsProgramData(
  formState: ObjectState<AddReadyPlanOptionsForm>,
  readyPlan: { id: string },
  saveMutation: ReturnType<typeof useSaveReadyPlanMutation>[0],
) {
  // for every rpo, we need to gather the program data changes
  const saveReadyPlanPayload: SaveReadyPlanInput = {
    id: readyPlan.id,
    programDatas: formState.readyPlanOptions.rows
      .flatMap((rpo) => {
        const programDataChanges = rpo.programData.changedValue;
        // identify its id
        const readyPlanProgramDataId = rpo.programDatas?.value.find(
          (rppd) => rppd.readyPlanOptionIds?.length === 1,
        )?.id;

        const modifiersChanges = rpo.programDataModifiedBy.changedValue
          .filter((rppd) => !!rppd.programData || rppd.id?.startsWith("temp_"))
          .map((modifierChange) => {
            let id = modifierChange?.id?.split("_")?.[1];
            let readyPlanOptionIds: string[] | undefined;

            if (modifierChange?.id?.startsWith("temp_")) {
              id = undefined;
              const fullModifyRecord = rpo.programDataModifiedBy.value.find((pdm) => pdm.id === modifierChange.id);
              readyPlanOptionIds = [rpo.id.value, fullModifyRecord?.matchesOption?.id].compact();
            }

            return {
              id,
              op: IncrementalCollectionOp.Include,
              ...(readyPlanOptionIds && { readyPlanOptionIds }),
              ...(Object.keys(modifierChange.programData ?? {}).nonEmpty && {
                programData: modifierChange.programData,
              }),
            };
          });

        // turn it into a ready plan program data record
        return [
          {
            id: readyPlanProgramDataId,
            op: IncrementalCollectionOp.Include,
            ...(Object.keys(programDataChanges ?? {}).nonEmpty && { programData: programDataChanges }),
          },
          ...modifiersChanges,
        ] as SaveReadyPlanProgramDataInput[];
      })
      .filter(
        (saveRppdInput) => Object.keys(saveRppdInput.programData ?? {}).nonEmpty || saveRppdInput.readyPlanOptionIds,
      ),
  };

  // save it
  if (saveReadyPlanPayload.programDatas?.nonEmpty) await saveMutation({ variables: { input: saveReadyPlanPayload } });
}

export async function toSaveOptionsConfiguration(
  formState: ObjectState<ConfigureReadyPlanOptionsForm>,
  saveReadyPlanOptionsConfiguration: ReturnType<typeof useSaveReadyPlanOptionsMutation>[0],
  saveReadyPlanOptionGroups: ReturnType<typeof useSaveReadyPlanOptionGroupsMutation>[0],
) {
  const changes = [];

  // these are dirty when the group order gets changed, not sure why
  const changedOptions: SaveReadyPlanOptionInput[] = formState.readyPlanOptions.rows
    .filter((rpo) => rpo.dirty && Object.getOwnPropertyNames(rpo.changedValue).length > 1)
    .map((rpo) => ({ ...rpo.changedValue, optionGroup: undefined }))
    // since we're ignoring the optionGroup here, rpos may be dirty but technically not changed
    // so let's make sure there is something other than the id and group here
    .filter(({ id, optionGroup, ...rest }) => Object.keys(rest).length);

  if (changedOptions.length > 0) {
    changes.push(
      await saveReadyPlanOptionsConfiguration({ variables: { input: { readyPlanOptions: changedOptions } } }),
    );
  }

  const changedGroups: SaveReadyPlanOptionGroupInput[] = formState.readyPlanOptions.rows
    .filter((rpo) => Object.keys(rpo.optionGroup.changedValue ?? {}).length > 1)
    .flatMap((rpo) => {
      return rpo.optionGroup.changedValue ?? [];
    });

  if (changedGroups.length > 0) {
    changes.push(await saveReadyPlanOptionGroups({ variables: { inputs: changedGroups } }));
  }

  return changes;
}

export function mapReadyPlanToFormState(readyPlan: Pick<ReadyPlanDetailsFragment, "id" | "options">) {
  return {
    readyPlanId: readyPlan.id,
    readyPlanOptions: readyPlan.options.map((o) => {
      const [programDatas, modifiedBy] = o.programDatas.partition((rppd) => rppd.readyPlanOptions.length === 1);
      const programData = programDatas[0]?.programData;
      const programDataModifiedBy = o.type.isElevation
        ? []
        : modifiedBy
            .filter((rppd) => rppd.readyPlanOptions.last?.id !== o.id)
            .map((rppd) => ({
              id: `${o.id}_${rppd.id}`,
              modifiedOption: rppd.readyPlanOptions[0],
              matchesOption: rppd.readyPlanOptions[1],
              programData: rppd.programData,
            }));

      return {
        fragment: o,
        id: o.id,
        name: o.name,
        active: o.active,
        globalOptionId: o.globalOption.id,
        readyPlanId: readyPlan.id,
        locationId: o.location?.id,
        optionTypeId: o.type.id,
        programDatas: o.programDatas.map((rppd) => ({
          ...rppd,
          op: IncrementalCollectionOp.Include,
          readyPlanOptionIds: rppd.readyPlanOptions.map((rpo) => rpo.id),
          programData: {
            ...rppd.programData,
            basementConfig: rppd.programData?.basementConfig?.code,
            primaryBedroom: rppd.programData?.primaryBedroom?.code,
            garageConfiguration: rppd.programData?.garageConfiguration?.code,
          },
        })),
        programData: {
          ...programData,
          basementConfig: programData?.basementConfig?.code,
          primaryBedroom: programData?.primaryBedroom?.code,
          garageConfiguration: programData?.garageConfiguration?.code,
        },
        programDataModifiedBy: programDataModifiedBy.map((pdm) => ({
          ...pdm,
          globalOptionCode: pdm.matchesOption.globalOption.code,
          programData: {
            ...pdm?.programData,
            basementConfig: pdm?.programData?.basementConfig?.code,
            garageConfiguration: pdm?.programData?.garageConfiguration?.code,
            primaryBedroom: pdm?.programData?.primaryBedroom?.code,
          },
        })),
        isElevation: o.type.isElevation,
        globalTypeName: o.type.name,
        globalOptionCode: o.globalOption.code,
        globalOptionDescription: o.description,
        globalOptionLocation: o.location,
        childOptions: o.childOptions.map((c) => ({
          id: c.id,
          active: c.active,
          globalOptionId: c.globalOption.id,
          globalOptionName: `${c.globalOption.code} - ${c.globalOption.name}`,
        })),
      };
    }),
  };
}
