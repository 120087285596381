import { Css } from "@homebound/beam";
import { CsvUploader } from "src/components/CsvUploader";
import { useItemTemplateItemsUploader } from "src/routes/settings/itemTemplates/useItemTemplateItemsUploader";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

type ItemTemplateLineItemsUploaderProps = { itApi: ItemTemplateApi };

/** An "inline" uploader for v2 templates, only used for the v2 template empty state/first time. */
export function ItemTemplateLineItemsUploader({ itApi }: ItemTemplateLineItemsUploaderProps) {
  const { errors, addError, handleOnDrop } = useItemTemplateItemsUploader(itApi, { autoImport: true });
  return (
    <div data-testid="itemTemplateItemsUploader" css={Css.w(50).mxa.$}>
      <CsvUploader errors={errors} onError={addError} onDrop={handleOnDrop} />
    </div>
  );
}
