import {
  BoundNumberField,
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  selectColumn,
  simpleHeader,
  useComputed,
  useGridTableApi,
} from "@homebound/beam";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Price, priceCell, priceTotal } from "src/components";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  ContractType,
  InvoiceV2Fragment,
  SaveInvoiceLineItemInput,
  SelectFixedItemsHomeownerContractLineItemFragment,
  SelectFixedItemsHomeownerContractsFragment,
  useSaveInvoiceV2Mutation,
  useSelectFixedItemsDataQuery,
} from "src/generated/graphql-types";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { PageHeader } from "src/routes/layout/PageHeader";
import { calcPercent, groupBy, isDefined, queryResult, sum, trimNumber } from "src/utils";
import { ObjectConfig, ObjectState, useFormState } from "src/utils/formState";
import {
  ContractLineItem,
  initLineItemData,
  isCostPlusWithoutCost,
  ownerContractType,
  PricePercentageType,
} from "../../utils";
import { InvoiceStepEnum } from "../enums/index";
type SelectFixedItemsProps = {
  onExit: () => void;
  setDraftInvoice: Dispatch<SetStateAction<InvoiceV2Fragment | undefined>>;
  invoice: InvoiceV2Fragment;
};

export function SelectFixedItems({ onExit, invoice, setDraftInvoice }: SelectFixedItemsProps) {
  const query = useSelectFixedItemsDataQuery({
    variables: { projectStageId: invoice.projectStage.id },
  });

  return queryResult(query, ({ projectStage }) => (
    <SelectFixedItemsView
      onExit={onExit}
      invoice={invoice}
      setDraftInvoice={setDraftInvoice}
      homeownerContracts={projectStage.homeownerContracts}
    />
  ));
}

type SelectFixedItemsViewProps = SelectFixedItemsProps & {
  homeownerContracts: SelectFixedItemsHomeownerContractsFragment[];
};

function SelectFixedItemsView({ homeownerContracts, invoice, onExit, setDraftInvoice }: SelectFixedItemsViewProps) {
  const { nextStepEvenIfDisabled, setSteps } = useStepperContext();
  const [saveInvoice] = useSaveInvoiceV2Mutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: mapToForm(invoice, homeownerContracts),
    },
  });
  const tableApi = useGridTableApi<Row>();

  const selectedRows = useComputed(() => tableApi.getSelectedRows("lineItem"), [tableApi]);

  const invoiceHasOtherLineItems = useMemo(
    () =>
      invoice.drawLineItems.nonEmpty ||
      invoice.lineItems.some(
        (li) =>
          ownerContractType(li.homeownerContractLineItem) === ContractType.CostPlus &&
          !isCostPlusWithoutCost(li.homeownerContractLineItem),
      ),
    [invoice],
  );

  const hasLineItems = useComputed(
    () => selectedRows.some((r) => isDefined(r.data.amountInCents.value) && r.data.amountInCents.value !== 0),
    [selectedRows, formState],
  );

  // Initialize row selection
  useEffect(() => {
    formState.lineItems.value
      .filter((li) => li.amountInCents)
      .forEach((li) => tableApi.selectRow(li.homeownerContractLineItemId!, true));
  }, [invoice.lineItems, homeownerContracts, tableApi, formState.lineItems.value]);

  // Clear up fields when de-select the item
  useEffect(() => {
    const selectedRowsIds = tableApi.getSelectedRowIds();
    formState.lineItems.rows.forEach((row) => {
      const isSelected = selectedRowsIds.some((rowId) => rowId === row.homeownerContractLineItemId.value);
      if (!isSelected) {
        row.uninvoiced.set(row.uninvoiced.originalValue);
        row.percentComplete.set(row.percentComplete.originalValue);
        row.amountInCents.set(undefined);
      }
    });
  }, [formState.lineItems.rows, invoice.lineItems, selectedRows, tableApi]);

  const save = async () => {
    const lineItemsInput = mapToInput(selectedRows);

    // unselected input must be deleted
    const unselectInput: SaveInvoiceLineItemInput[] = invoice.lineItems
      .filter(
        (ili) =>
          !lineItemsInput.some((input) => input.homeownerContractLineItemId === ili.homeownerContractLineItem.id) &&
          (ownerContractType(ili.homeownerContractLineItem) === ContractType.Fixed ||
            isCostPlusWithoutCost(ili.homeownerContractLineItem)),
      )
      .map(({ id }) => ({ id, delete: true }));

    const costPlusInput = invoice.lineItems
      .filter(
        (ili) =>
          ownerContractType(ili.homeownerContractLineItem) === ContractType.CostPlus &&
          !isCostPlusWithoutCost(ili.homeownerContractLineItem),
      )
      .map(({ id, amountInCents, costLineItemAllocations }) => ({
        id,
        amountInCents,
        costLineItemAllocations: costLineItemAllocations.map(
          ({ id, costAmountInCents, invoicedAmountInCents, costLineItemId }) => ({
            id,
            costAmountInCents,
            invoicedAmountInCents,
            costLineItemId,
          }),
        ),
      }));

    const invoiceResult = await saveInvoice({
      variables: {
        input: {
          id: invoice.id,
          lineItems: [...lineItemsInput, ...unselectInput, ...costPlusInput],
        },
      },
    });
    const newInvoice = invoiceResult.data?.saveInvoice?.invoice;
    setDraftInvoice(newInvoice);
  };

  const columns = useMemo(() => createColumns(selectedRows), [selectedRows]);
  const rows = useMemo(
    () => createRows(homeownerContracts, formState, selectedRows),
    [homeownerContracts, formState, selectedRows],
  );

  return (
    <>
      <PageHeader
        title={"Select Fixed Price Items"}
        breadcrumb={{
          label: "NEW INVOICE",
          href: "#",
        }}
        right={
          <div css={Css.df.jcsb.gap4.$}>
            <ExitIconButton showModal={false} onCloseConfirmed={onExit} />
          </div>
        }
      />

      <div css={Css.pr3.w("55%").$}>
        <h2 css={Css.lgSb.mb2.$}>Invoice “{`${invoice.invoiceNumber} - ${invoice.title}`}”</h2>
        <span css={Css.sm.$}>
          We found the following Fixed Items for this period.
          <br />
          <b>Select items</b> you want to invoice at this time.
        </span>
      </div>
      <GridTable
        api={tableApi}
        stickyHeader
        columns={columns}
        rows={rows}
        style={{ grouped: true, inlineEditing: true }}
      />

      <StepActions>
        <div css={Css.df.gap2.$}>
          <Button
            label="Save & Exit"
            variant="secondary"
            onClick={async () => {
              await save();
              onExit();
            }}
          />
          <Button
            label="Continue"
            disabled={!(hasLineItems || invoiceHasOtherLineItems)}
            onClick={async () => {
              await save();

              setSteps((prevState) => {
                const currentIndex = prevState.findIndex((s) => s.value === InvoiceStepEnum.SELECT_FIXED_ITEM);
                return [
                  ...prevState.slice(0, currentIndex),
                  { ...prevState[currentIndex], state: "complete" },
                  { ...prevState[currentIndex + 1], disabled: false },
                  ...(currentIndex + 2 === prevState.length ? [] : prevState.slice(currentIndex + 2)),
                ];
              });

              nextStepEvenIfDisabled();
            }}
          />
        </div>
      </StepActions>
    </>
  );
}

type CommonColumns = {
  homeownerContractAmount: number;
  invoicedToDate: number;
  remaining: number;
};
type HeaderRow = { kind: "header" };
type CostCodeRow = { kind: "costCode"; data: CommonColumns & { name: string } };
type LineItemRow = { kind: "lineItem"; data: FormState["lineItems"]["rows"][0] };
type TotalRow = { kind: "totals"; data: CommonColumns & { thisInvoiceInCents: number } };
type Row = HeaderRow | CostCodeRow | LineItemRow | TotalRow;

function createRows(
  homeownerContracts: SelectFixedItemsHomeownerContractsFragment[],
  formState: FormState,
  selectedRows: GridDataRow<LineItemRow>[],
): GridDataRow<Row>[] {
  const hclis = filterPotentialFixedLineItems(homeownerContracts);
  const hclisGroupedByCostCode = groupBy(hclis, (hcli) => hcli.projectItem.item.costCode.id);
  const selectedHcliIds = selectedRows.map((sr) => sr.data.homeownerContractLineItemId.value);

  const costCodeRows = Object.keys(hclisGroupedByCostCode).map((key) => {
    const hcliRows = hclisGroupedByCostCode[key];
    const children = hcliRows.map(
      (hcli) => formState.lineItems.rows.find((r) => r.homeownerContractLineItemId.value === hcli.id)!,
    );
    const selectedChildren = children.filter((c) => selectedHcliIds.includes(c.homeownerContractLineItemId.value));
    const firstHcli = hcliRows[0];
    return {
      kind: "costCode" as const,
      id: key,
      data: {
        name: firstHcli.projectItem.item.costCode.displayName,
        homeownerContractAmount: selectedChildren.sum((c) => c.priceChangeInCents.value || 0),
        invoicedToDate: selectedChildren.sum((c) => c.otherInvoices.value?.price || 0),
        remaining: selectedChildren.sum((c) => c.uninvoiced.value?.price || 0),
      },
      children: children.map((data) => ({
        kind: "lineItem" as const,
        id: String(data.homeownerContractLineItemId.value),
        data,
      })),
    };
  });

  return [
    simpleHeader,
    ...costCodeRows,
    {
      kind: "totals" as const,
      id: "total",
      data: {
        homeownerContractAmount: costCodeRows.sum((cc) => cc.data.homeownerContractAmount),
        invoicedToDate: costCodeRows.sum((cc) => cc.data.invoicedToDate),
        remaining: costCodeRows.sum((cc) => cc.data.remaining),
        thisInvoiceInCents: [
          ...formState.lineItems.rows
            .filter((r) => selectedHcliIds.includes(r.homeownerContractLineItemId.value))
            .map((r) => r.amountInCents.value || 0),
        ].reduce(sum, 0),
      },
    },
  ];
}

function createColumns(selectedRows: GridDataRow<LineItemRow>[]): GridColumn<Row>[] {
  const selectedHcliIds = selectedRows.map((sr) => sr.data.homeownerContractLineItemId.value);
  return [
    collapseColumn<Row>({ w: "32px", header: () => emptyCell }),
    selectColumn<Row>({
      w: "32px",
      totals: () => emptyCell,
      header: () => emptyCell,
    }),
    column<Row>({
      header: "Cost Code",
      costCode: (row) => <span css={Css.absolute.$}>{row.name}</span>,
      lineItem: (row) => row.name.value,
      totals: () => <span css={Css.baseMd.absolute.left5.$}>Total</span>,
    }),
    numericColumn<Row>({
      header: "Homeowner Contract",
      costCode: (row) => priceCell({ valueInCents: row.homeownerContractAmount }),
      lineItem: (row) => priceCell({ valueInCents: row.priceChangeInCents.value }),
      totals: (row) => <Price valueInCents={row.homeownerContractAmount} />,
    }),
    numericColumn<Row>({
      header: "Invoiced To Date",
      costCode: (row) => priceCell({ valueInCents: row.invoicedToDate }),
      lineItem: (row) => priceCell({ valueInCents: row.otherInvoices.value?.price }),
      totals: (row) => <Price valueInCents={row.invoicedToDate} />,
    }),
    numericColumn<Row>({
      header: "Remaining",
      costCode: (row) => priceCell({ valueInCents: row.remaining }),
      lineItem: (row) => priceCell({ valueInCents: row.uninvoiced.value?.price }),
      totals: (row) => <Price valueInCents={row.remaining} />,
    }),
    numericColumn<Row>({
      header: () => "Completion",
      lineItem: (row) => {
        if (!row.homeownerContractLineItemId.value) {
          return <></>;
        }
        return (
          <BoundNumberField
            field={row.percentComplete}
            type="percent"
            disabled={!selectedHcliIds.includes(row.homeownerContractLineItemId.value)}
            numFractionDigits={2}
            onChange={(percentComplete = 0) => {
              const amountInCents = calculateAmountByPercentage(row, percentComplete);
              row.set({
                amountInCents,
                percentComplete,
                uninvoiced: calculateUninvoiced(row, amountInCents, percentComplete),
              });
            }}
          />
        );
      },
      costCode: () => emptyCell,
      totals: () => emptyCell,
      w: "110px",
    }),
    numericColumn<Row>({
      header: () => "This Invoice",
      lineItem: (row) => {
        if (!row.homeownerContractLineItemId.value) {
          return <></>;
        }
        return (
          <BoundNumberField
            type="cents"
            field={row.amountInCents}
            disabled={!selectedHcliIds.includes(row.homeownerContractLineItemId.value)}
            onChange={(amountInCents = 0) => {
              const percentComplete = calculatePercentageCompleteByAmount(row, amountInCents);
              row.set({
                amountInCents,
                percentComplete,
                uninvoiced: calculateUninvoiced(row, amountInCents, percentComplete),
              });
            }}
          />
        );
      },
      costCode: () => emptyCell,
      totals: (row) => priceTotal({ id: "invoicedTotal", valueInCents: row.thisInvoiceInCents }),
      w: "140px",
    }),
  ];
}

type FormState = ObjectState<FormInput>;
type FormInput = {
  id: string;
  lineItems: ContractLineItem[];
};

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      isChangeOrder: { type: "value" },
      billIds: { type: "list", config: { id: { type: "value" } } },
      projectItemId: { type: "value" },
      homeownerContractLineItemId: { type: "value" },
      name: { type: "value" },
      otherInvoices: { type: "value" },
      percentComplete: { type: "value" },
      minPercentage: { type: "value" },
      amountInCents: { type: "value" },
      priceChangeInCents: { type: "value" },
      uninvoiced: { type: "value" },
      delete: { type: "value" },
    },
  },
};

function mapToForm(
  invoice: InvoiceV2Fragment,
  homeownerContracts: SelectFixedItemsHomeownerContractsFragment[],
): FormInput {
  return {
    id: invoice.id,
    // Get the unique list of projectItems for the invoice line items, then add each
    // original contract line item + any C/O line items right after it.
    lineItems: filterPotentialFixedLineItems(homeownerContracts).map((hcli) => {
      const ili = invoice.lineItems.find((li) => li.homeownerContractLineItem.id === hcli.id);
      return initLineItemData(hcli.projectItem, hcli, ili, false, true);
    }),
  };
}

function filterPotentialFixedLineItems(
  homeownerContracts: SelectFixedItemsHomeownerContractsFragment[],
): SelectFixedItemsHomeownerContractLineItemFragment[] {
  const lineItems = (homeownerContracts.first?.lineItemsByProjectItem ?? []).flatMap((li) => li.lineItems);
  const fixedLineItems = lineItems.filter((li) => ownerContractType(li) === ContractType.Fixed);
  // Other cost+ line items without cost
  // These are usually representing project management fees or other prices a customer has agreed to pay but will not actually be a cost to Homebound.
  // These will never show on the cost + line page because they will never have bills in the system.
  const otherCostPlusWithoutCost = lineItems.filter(isCostPlusWithoutCost);
  return [...fixedLineItems, ...otherCostPlusWithoutCost];
}

function mapToInput(selectedRows: GridDataRow<LineItemRow>[]): SaveInvoiceLineItemInput[] {
  return selectedRows
    .map(({ data: { value } }) => ({
      id: value.id,
      homeownerContractLineItemId: value.homeownerContractLineItemId,
      amountInCents: value.amountInCents,
      delete: value.delete,
    }))
    .filter((li) => li.homeownerContractLineItemId && typeof li.amountInCents === "number");
}

function calculateUninvoiced(
  row: ObjectState<ContractLineItem>,
  amountInCents: number,
  percentage: number,
): PricePercentageType {
  const priceChangeInCents = row.priceChangeInCents.value || 0;
  const totalAmountInvoiced = amountInCents + (row.otherInvoices.value?.price || 0);
  const uninvoicedPrice = priceChangeInCents - totalAmountInvoiced;
  const uninvoicedPercentage = 100 - percentage;
  return { price: uninvoicedPrice, percentage: uninvoicedPercentage };
}

function calculatePercentageCompleteByAmount(row: ObjectState<ContractLineItem>, amountInCents: number): number {
  const priceChangeInCents = row.priceChangeInCents.value || 0;
  let otherPrice = 0;
  let otherPercentage = 0;
  if (row.otherInvoices.value) {
    otherPrice = row.otherInvoices.value.price;
    otherPercentage = row.otherInvoices.value.percentage;
  }
  const totalAmountInvoiced = amountInCents + otherPrice;

  return !amountInCents
    ? // reset % Complete to be equal to previously invoiced percentage
      otherPercentage
    : trimNumber(calcPercent(totalAmountInvoiced, priceChangeInCents));
}

function calculateAmountByPercentage(row: ObjectState<ContractLineItem>, percentage: number): number {
  // Our `percentage` field is the sum of all invoice %'s. To properly calculate this invoice's amount we first need to subtract the the other invoice's percentage.
  const thisPercentage = percentage - (row.otherInvoices.value?.percentage || 0);
  // If either the percentage or `priceChangeInCents` are 0 or not defined, then return 0 for the amount.
  return !thisPercentage || !row.priceChangeInCents.value
    ? 0
    : Math.round(row.priceChangeInCents.value * (thisPercentage / 100));
}
