import {
  Css,
  FilterDefs,
  Filters,
  multiFilter,
  Palette,
  ScrollableContent,
  SelectField,
  toggleFilter,
  usePersistedFilter,
  useSessionStorage,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  AddOptionsGlobalOptionTypeFragment,
  PlanPackagePageMetadataQuery,
  ReadyPlanOptionsFilter,
  useAddOptionsMetadataQuery,
  useSaveReadyPlanMutation,
} from "src/generated/graphql-types";
import {
  OptionDetailsTable,
  PlanOptionGroupBy,
} from "../../../developments/plan-and-options/components/OptionDetailsTable";
import {
  addRpoConfig,
  mapReadyPlanToFormState,
  saveReadyPlanOptionsProgramData,
} from "../../../developments/plan-and-options/components/utils";

export function OptionDataTab({ metadata }: { metadata: PlanPackagePageMetadataQuery }) {
  const { data: metadataProgramData } = useAddOptionsMetadataQuery();
  const planPackage = metadata.planPackage;
  const [save] = useSaveReadyPlanMutation();

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      input: planPackage,
      map: (planPackage) => mapReadyPlanToFormState(planPackage),
    },
    autoSave: async (formState) => {
      await saveReadyPlanOptionsProgramData(formState, planPackage ?? { id: "" }, save);
    },
  });
  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    // NOTE: We are relying on the fact that the server will sort the GlobalOption types by order
    return metadata.globalOptionTypes;
  }, [metadata.globalOptionTypes]);

  const { globalOptionTypes = [], locations = [] } = metadata;
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const filterDefs: FilterDefs<ReadyPlanOptionsFilter> = useMemo(() => {
    const type = multiFilter({
      label: "Option Type",
      options: globalOptionTypes.filter((got) => !got.isElevation),
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const location = multiFilter({
      label: "Location",
      options: [{ id: null, name: "None" } as any, ...locations],
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const active = toggleFilter({ label: "Show Archived" });

    return { type, location, active };
  }, [globalOptionTypes, locations]);

  const { filter, setFilter } = usePersistedFilter<ReadyPlanOptionsFilter>({
    storageKey: "tableFilter",
    filterDefs,
  });
  const [planOptionGroupBy, setPlanOptionGroupBy] = useSessionStorage("planOptionGroupBy", "optionType");
  return (
    <>
      <div css={Css.df.aic.jcfe.gap1.$}>
        <div css={Css.h100.$}>
          <SelectField
            compact
            sizeToContent
            label="Group by"
            labelStyle={"inline"}
            options={[
              { id: "optionType", name: "Option Type" },
              { id: "location", name: "Location" },
              { id: "none", name: "None" },
            ]}
            value={planOptionGroupBy}
            onSelect={(gb) => gb && setPlanOptionGroupBy(gb)}
          />
        </div>
        <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <SearchBox onSearch={setSearchFilter} />
      </div>
      <ScrollableContent virtualized bgColor={Palette.Gray100}>
        <div css={Css.h100.w("calc(100% - 24px)").$}>
          <OptionDetailsTable
            formState={formState}
            searchFilter={searchFilter}
            filter={filter}
            optionsTypes={optionsTypes}
            metadata={metadataProgramData}
            groupBy={planOptionGroupBy as PlanOptionGroupBy}
            showActions={true}
          />
        </div>
      </ScrollableContent>
    </>
  );
}
