import { Css, GridDataRow, Palette, Tag, Tooltip } from "@homebound/beam";
import { ChooseOptionsRow } from "src/routes/components/PlanConfiguration/utils";

export function SelectedOptionsTag({ selectedRows }: { selectedRows: GridDataRow<ChooseOptionsRow>[] }) {
  const explodedRowsByLocation = selectedRows.flatMap((r) => r.data?.locations.map((loc) => ({ ...r, location: loc })));
  return (
    selectedRows.length > 0 && (
      <div css={Css.asfe.$}>
        <Tooltip
          bgColor={Palette.White}
          placement="bottom"
          title={
            <div css={Css.dg.gtc(`auto auto auto`).gap1.flexWrap("wrap").$}>
              {selectedRows.map(({ data: option }, idx) => {
                const numLocations = option?.locations.length ?? 0;
                return (
                  <Tag
                    key={option?.id ?? idx}
                    type="info"
                    text={
                      <span css={Css.tinyMd.$}>
                        {option?.code}
                        {numLocations > 1 ? <span css={Css.tinySb.$}> x{numLocations}</span> : ""}
                      </span>
                    }
                  />
                );
              })}
            </div>
          }
        >
          <Tag text={`${explodedRowsByLocation.length} options selected`} type="info" preventTooltip />
        </Tooltip>
      </div>
    )
  );
}
