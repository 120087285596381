import { Css, IconButton } from "@homebound/beam";
import { useState } from "react";
import {
  DevelopmentBulkSchedulePhasesDetailFragment,
  PhaseTaskListPaneScheduleTaskDetailFragment,
  usePhaseTaskListPaneQuery,
} from "src/generated/graphql-types";
import { nonEmpty, partition } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { TaskSidebarListItem } from "./TaskSidebarListItem";

type PhaseTaskListPaneProps = {
  onClose: () => void;
  phase: DevelopmentBulkSchedulePhasesDetailFragment;
  openTaskPane: (taskId: string) => void;
  stale: boolean;
};

export function PhaseTaskListPane(props: PhaseTaskListPaneProps) {
  const { onClose, phase, openTaskPane, stale } = props;
  const query = usePhaseTaskListPaneQuery({
    variables: {
      phaseId: phase.id,
      stale,
    },
  });

  return (
    <div data-testid="phaseTaskListPane" css={Css.p3.h100.oa.$}>
      <div css={Css.df.jcsb.mb1.$}>
        <div css={Css.lgSb.$}>{phase.name || "Loading Tasks..."}</div>
        <div>
          <IconButton data-testid="closePane" icon="x" onClick={onClose} />
        </div>
      </div>
      {queryResult(query, {
        data: ({ scheduleTasks }) => {
          const [withSubPhase, withoutSubPhase] = partition(scheduleTasks, (st) => !!st.scheduleSubPhase?.id);
          const scheduleSubPhases = phase.scheduleSubPhases.map(({ id, name }) => ({
            id,
            name,
            scheduleTasks: withSubPhase.filter((st) => st.scheduleSubPhase?.id === id),
          }));
          return (
            <>
              {scheduleSubPhases.map((subPhase) => (
                <ScheduleSubPhaseInfo
                  title={subPhase.name}
                  key={subPhase.id}
                  scheduleTasks={subPhase.scheduleTasks}
                  openTaskPane={openTaskPane}
                />
              ))}
              {nonEmpty(withoutSubPhase) && (
                <ScheduleSubPhaseInfo
                  title="Global Tasks"
                  key="GlobalTasks"
                  scheduleTasks={withoutSubPhase}
                  openTaskPane={openTaskPane}
                />
              )}
            </>
          );
        },
      })}
    </div>
  );
}

type ScheduleSubPhaseInfoProps = {
  title: string;
  scheduleTasks: PhaseTaskListPaneScheduleTaskDetailFragment[];
  openTaskPane: (taskId: string) => void;
};

function ScheduleSubPhaseInfo({ title, scheduleTasks, openTaskPane }: ScheduleSubPhaseInfoProps) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div data-testid="scheduleSubPhaseInfo" css={Css.mb1.$}>
      <div css={Css.df.fdr.jcsb.aic.$}>
        {title}
        <IconButton icon={collapsed ? "chevronUp" : "chevronDown"} onClick={() => setCollapsed(!collapsed)} />
      </div>
      <div css={Css.hPx(1).mbPx(12).bgGray200.$} />
      <div data-testid="scheduleTaskList" css={Css.if(collapsed).dn.$}>
        {scheduleTasks.map((task) => (
          <TaskSidebarListItem key={task.id} scheduleTask={task} onClick={openTaskPane} />
        ))}
      </div>
    </div>
  );
}
