import {
  actionColumn,
  Chip,
  collapseColumn,
  CollapseToggle,
  column,
  Css,
  dateColumn,
  emptyCell,
  FullBleed,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  Icon,
  IconButton,
  numericColumn,
  Palette,
  ScrollableContent,
  simpleHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { closestTo } from "date-fns";
import { useEffect, useMemo } from "react";
import { chipCell, dateCell } from "src/components";
import {
  LotSequenceSheetTableMetadataQuery,
  LotSequenceSheetTableQuery,
  LotSequentSheetTable_CohortFragment,
  Order,
  ProgramDataMatchState,
  ProjectCollaboratorRole,
  ProjectReadyPlanConfigFilter,
  ProjectReadyPlanConfigForLotSequenceFragment,
  ProjectReadyPlanConfigOrientation,
  PrpcOptionFragment,
  ScopeMode,
  Stage,
  SystemTask,
  useLotSequenceSheetTableMetadataQuery,
  useLotSequenceSheetTableQuery,
} from "src/generated/graphql-types";
import { CreateOrUpdateCohortModal } from "src/routes/developments/components/cohorts/CreateOrUpdateCohortModal";
import { useDevelopmentContext } from "src/routes/developments/context/DevelopmentContext";
import { LotConfigurationStatusChip } from "src/routes/projects/components/LotConfigurationStatusChip";
import { createDevelopmentLotDetailsUrl, createProjectUrl } from "src/RouteUrls";
import { assertNever, pluralize, projectStatusDetails, queryResult } from "src/utils";
import { LotCheckFormDrawer } from "../lot-check-form/LotCheckFormDrawer";
import { MoveProjectButtonMenu } from "../move-project-button-menu/MoveProjectButtonMenu";
import { dateCanShowStatusTags, LotSummaryGroupBy, OptionTypes } from "../utils";

type LotSequenceSheetTableProps = {
  developmentId: string;
  api?: GridTableApi<Row>; // Optional for tests
  queryFilter: ProjectReadyPlanConfigFilter;
  groupBy: LotSummaryGroupBy;
  searchFilter?: string;
  setSummaryProjectIds?: React.Dispatch<React.SetStateAction<string[]>>;
};

export function LotSequenceSheetTable(props: LotSequenceSheetTableProps) {
  const { developmentId, api, queryFilter, groupBy, searchFilter, setSummaryProjectIds = () => {} } = props;
  const query = useLotSequenceSheetTableMetadataQuery({ variables: { developmentId } });
  return queryResult(query, {
    data: (data) => (
      <DataView
        developmentId={developmentId}
        metadata={data}
        api={api}
        queryFilter={queryFilter}
        groupBy={groupBy}
        searchFilter={searchFilter}
        setSummaryProjectIds={setSummaryProjectIds}
      />
    ),
  });
}

type DataViewProps = {
  developmentId: string;
  metadata: LotSequenceSheetTableMetadataQuery;
  api: GridTableApi<Row> | undefined;
  queryFilter: ProjectReadyPlanConfigFilter;
  groupBy: LotSummaryGroupBy;
  searchFilter?: string;
  setSummaryProjectIds: React.Dispatch<React.SetStateAction<string[]>>;
};

function DataView(props: DataViewProps) {
  const { developmentId, metadata, api, queryFilter, groupBy, searchFilter, setSummaryProjectIds } = props;
  const { data, refetch } = useLotSequenceSheetTableQuery({
    variables: {
      prpcFilter: { development: [developmentId], ...queryFilter },
      cohortFilter: { development: [developmentId], id: queryFilter.cohort },
    },
  });

  const development = useDevelopmentContext();
  const { openInDrawer } = useSuperDrawer();

  const [constructionRtsSystemTaskName, verticalCompleteSystemTaskName] = useMemo(() => {
    let constructionRtsSystemTaskName = "";
    let verticalCompleteSystemTaskName = "";
    data?.globalTasks.forEach((gt: any) => {
      if (gt.systemTask === SystemTask.ConstructionRts) {
        constructionRtsSystemTaskName = gt.name;
      } else if (gt.systemTask === SystemTask.VerticalComplete) {
        verticalCompleteSystemTaskName = gt.name;
      }
    });
    return [constructionRtsSystemTaskName, verticalCompleteSystemTaskName];
  }, [data]);

  const columns = useMemo(
    () =>
      createColumns(
        developmentId,
        constructionRtsSystemTaskName,
        verticalCompleteSystemTaskName,
        data,
        metadata,
        openInDrawer,
        groupBy,
        refetch,
      ),
    [
      developmentId,
      constructionRtsSystemTaskName,
      verticalCompleteSystemTaskName,
      data,
      metadata,
      openInDrawer,
      groupBy,
      refetch,
    ],
  );

  const rows = useMemo(() => {
    switch (groupBy) {
      case LotSummaryGroupBy.cohort:
        return createRowsGroupByCohort(data?.projectReadyPlanConfigs, data?.cohorts);
      case LotSummaryGroupBy.none:
        return createRowsGroupByNone(data?.projectReadyPlanConfigs);
      default:
        assertNever(groupBy);
    }
  }, [data, groupBy]);

  useEffect(() => {
    const lineItemRows = api?.getVisibleRows("project");
    const projectIds = lineItemRows?.map((row) => row.data.project.id);
    setSummaryProjectIds(projectIds ?? []);
  }, [api, development, rows, setSummaryProjectIds]);

  return (
    <div css={Css.mt("-24px").pt3.$}>
      <ScrollableContent virtualized>
        <FullBleed>
          <div css={Css.bgGray100.h100.w("calc(100% + 24px)").$}>
            <GridTable
              as="virtual"
              api={api}
              style={{ grouped: groupBy !== LotSummaryGroupBy.none, allWhite: true, bordered: true }}
              columns={columns}
              filter={searchFilter}
              rows={rows}
              stickyHeader
              sorting={{ on: "client", initial: [`${ExpandedColumns.ConstructionStart}`, Order.Asc] }}
              fallbackMessage={
                Object.keys(queryFilter).length > 0 || searchFilter
                  ? "There are no configured projects that matches the applied filters."
                  : "There are no configured projects"
              }
            />
          </div>
        </FullBleed>
      </ScrollableContent>
    </div>
  );
}

function createRowsGroupByCohort(
  configs?: ProjectReadyPlanConfigForLotSequenceFragment[],
  cohorts?: LotSequentSheetTable_CohortFragment[],
): GridDataRow<Row>[] {
  const allCohortsById = cohorts?.groupBy(({ id }) => id) ?? {};
  const groupedByCohorts = configs?.groupByObject(({ project }) => project.cohort!) ?? [];
  const result = groupedByCohorts.map(([cohort, configs]) => {
    delete allCohortsById[cohort.id];
    return {
      kind: "cohort" as const,
      data: cohort,
      id: cohort.id,
      pin: { at: "first" as const, filter: true },
      children: configs.map((config) => {
        return {
          kind: "project" as const,
          data: config,
          id: config.id,
        };
      }),
    };
  });
  result.push(
    ...Object.values(allCohortsById).flatMap(([cohort]) => ({
      kind: "cohort" as const,
      data: cohort,
      id: cohort.id,
      pin: { at: "first" as const, filter: true },
      children: [],
    })),
  );
  return [
    { kind: "expandableHeader" as const, id: "expandableHeader", data: {} },
    simpleHeader,
    ...result.sortBy(({ data }) => data.createdAt),
  ];
}

function createRowsGroupByNone(configs?: ProjectReadyPlanConfigForLotSequenceFragment[]): GridDataRow<Row>[] {
  const result = configs
    ? configs.map((config) => {
        return {
          kind: "project" as const,
          data: config,
          id: config.id,
        };
      })
    : [];
  return [{ kind: "expandableHeader" as const, id: "expandableHeader", data: {} }, simpleHeader, ...result];
}

function createColumns(
  developmentId: string,
  constructionRtsSystemTaskName: string,
  verticalCompleteSystemTaskName: string,
  data: LotSequenceSheetTableQuery | undefined,
  metadata: LotSequenceSheetTableMetadataQuery,
  openInDrawer: ReturnType<typeof useSuperDrawer>["openInDrawer"],
  groupBy: LotSummaryGroupBy,
  refetch: () => Promise<any>,
): GridColumn<Row>[] {
  const otherOptionsData: PrpcOptionFragment[] = [];
  data?.projectReadyPlanConfigs.forEach((project) => {
    const options = project?.options.filter(
      ({ readyPlanOption }) =>
        !readyPlanOption.type.isElevation &&
        !readyPlanOption.type.isExteriorPalette &&
        !readyPlanOption.type.isSpecLevel,
    );
    options.forEach((option) => otherOptionsData.push(option));
  });
  const otherOptionTypes = metadata.globalOptionTypes
    .filter((type) => !type.isElevation && !type.isExteriorPalette && !type.isSpecLevel)
    .sortBy(({ order }) => order);

  const collapseColum = collapseColumn<Row>({
    project: (projectReadyPlanConfigs) => ({
      content: getStatusTag(projectReadyPlanConfigs),
      alignment: "right",
    }),
    cohort: (cohort, { row, level }) => {
      return !(cohort?.scopeMode?.code === ScopeMode.Full || cohort?.scopeMode?.code === ScopeMode.BudgetSafe) ? (
        <span css={Css.df.aic.jcsb.gap1.w100.$}>
          <CollapseToggle row={row} compact={level > 0} />
          <Icon
            icon="error"
            color={Palette.Red600}
            tooltip={
              <div css={Css.tac.$}>
                This cohort isn't using scope templates. Configuration changes will need to be manually updated on those
                projects.
              </div>
            }
            inc={2}
          />
        </span>
      ) : (
        <CollapseToggle row={row} compact={level > 0} />
      );
    },
    w: "100px",
    align: "left",
    sticky: "left",
  });

  const statusColumn = actionColumn<Row>({
    expandableHeader: emptyCell,
    header: emptyCell,
    cohort: emptyCell,
    project: (projectReadyPlanConfigs) => getStatusTag(projectReadyPlanConfigs),
    w: "100px",
    align: "left",
    sticky: "left",
  });

  const idColumn = column<Row>({
    expandableHeader: emptyCell,
    header: "Id",
    cohort: (cohort) => ({ content: cohort?.name, colspan: 2 }),
    project: ({ id, project }) => ({
      content: project.id,
      onClick: createProjectUrl(project.id),
    }),
    w: "100px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    expandableHeader: emptyCell,
    header: "Address",
    cohort: emptyCell,
    project: ({ id, project }) => ({
      content: project.buildAddress.street1,
      onClick: createDevelopmentLotDetailsUrl(developmentId, id),
    }),
    w: "192px",
    sticky: "left",
  });

  const scheduleColumn = column<Row>({
    expandableHeader: "Schedule",
    header: "Latest Stage",
    cohort: emptyCell,
    project: ({ project }) => project.latestActiveStage?.stage.name ?? "N/A",
    w: "130px",
    expandColumns: [
      column<Row>({
        expandableHeader: emptyCell,
        header: "Status",
        cohort: emptyCell,
        project: ({ project }) => projectStatusDetails[project?.status.code],
        w: "175px",
      }),
      dateColumn<Row>({
        expandableHeader: emptyCell,
        header: "Pre-Con start",
        cohort: emptyCell,
        project: ({ project }) =>
          dateCell(project.schedules.find((schedule) => schedule.stage === Stage.PreConExecution)?.interval?.startDate),
        w: "130px",
      }),
      dateColumn<Row>({
        expandableHeader: emptyCell,
        header: "Pre-Con end",
        cohort: emptyCell,
        project: ({ project }) =>
          dateCell(project.schedules.find((schedule) => schedule.stage === Stage.PreConExecution)?.interval?.endDate),
        w: "130px",
      }),
      dateColumn<Row>({
        expandableHeader: emptyCell,
        id: `${ExpandedColumns.ConstructionStart}`,
        header: () => ({
          content: () => "Construction RTS",
          tooltip: constructionRtsSystemTaskName
            ? `Schedule milestone task: ${constructionRtsSystemTaskName}`
            : undefined,
        }),
        cohort: emptyCell,
        project: ({ constructionStartDate }) => dateCell(constructionStartDate),
        w: "175px",
      }),
      dateColumn<Row>({
        expandableHeader: emptyCell,
        id: `${ExpandedColumns.VerticalComplete}`,
        header: () => ({
          content: () => "Vertical Complete",
          tooltip: verticalCompleteSystemTaskName
            ? `Schedule milestone task: ${verticalCompleteSystemTaskName}`
            : undefined,
        }),
        cohort: emptyCell,
        project: ({ deliveryDate }) => dateCell(deliveryDate),
        w: "175px",
      }),
    ],
  });

  const lotInfoColumn = column<Row>({
    expandableHeader: "Lot information",
    header: "Lot Type",
    cohort: emptyCell,
    project: ({ project }) => project && `${project?.lotType?.name || ""}`,
    w: "160px",
    expandColumns: [
      column<Row>({
        expandableHeader: emptyCell,
        header: "Client Name",
        cohort: emptyCell,
        project: ({ project }) =>
          project &&
          `${
            project?.collaborators?.find((collaborator) => collaborator.role === ProjectCollaboratorRole.Homeowner)
              ?.collaborator.fullName || ""
          }`,
        w: "160px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Lot Number",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.lotNumber,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Block",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.block,
        w: "110px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Section",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.section,
        w: "150px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Lot Square Footage",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.lotSquareFootage,
        w: "150px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Flood Plain",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.floodPlain?.name,
        w: "110px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Flood Zone",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.floodZone?.name,
        w: "110px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Setback Front",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.setBackFrontInFeet,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Setback Right",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.setBackRightInFeet,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Setback Left",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.setBackLeftInFeet,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Setback Rear",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.setBackRearInFeet,
        w: "100px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "HOA",
        cohort: emptyCell,
        project: ({ project }) => (project?.lotDetail?.hoa ? "Yes" : "No"),
        w: "100px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "HOA Specific Modifications",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.hoaSpecificModifications,
        w: "100px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Site Specific Modifications",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.siteSpecificModifications,
        w: "100px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Construction Type",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.constructionType?.name,
        w: "100px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Foundation Type",
        cohort: emptyCell,
        project: ({ project }) => project?.lotDetail?.foundationType?.name,
        w: "100px",
      }),
      column<Row>({
        expandableHeader: emptyCell,
        header: "Fire Sprinklers Required",
        cohort: emptyCell,
        project: ({ project }) => (project?.lotDetail?.fireSprinklersRequired ? "Yes" : "No"),
        w: "100px",
      }),
    ],
  });

  const planColumn = column<Row>({
    expandableHeader: "Plan information",
    header: "Plan",
    cohort: emptyCell,
    project: ({ readyPlan, orientationDetail }) =>
      readyPlan &&
      `${readyPlan.name} ${orientationDetail.code === ProjectReadyPlanConfigOrientation.Left ? "(LH)" : "(RH)"}`,
    w: "160px",
    expandColumns: [
      column<Row>({
        expandableHeader: emptyCell,
        header: "Spec Option",
        cohort: emptyCell,
        project: (prpco) => prpco.specOption?.readyPlanOption?.name,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Sellable SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.sellableSqft,
        w: "110px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Net Buildable SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.netBuildableSqft,
        w: "120px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Gross Buildable SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.grossBuildableSqft,
        w: "120px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Permitable SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.permittableSqft,
        w: "110px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Sellable Above Ground SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.sellableAboveGroundSqft,
        w: "135px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Gross Below Ground SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.grossBelowGroundSqft,
        w: "135px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Sellable Below Ground SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.sellableBelowGroundSqft,
        w: "135px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Unfinished Below Ground SF",
        cohort: emptyCell,
        project: ({ programData }) => programData?.unfinishedBelowGroundSqft,
        w: "147px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Orientation",
        cohort: emptyCell,
        project: ({ orientationDetail }) => orientationDetail.name,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Stories",
        cohort: emptyCell,
        project: ({ programData }) => programData?.stories,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Bedrooms",
        cohort: emptyCell,
        project: ({ programData }) => programData?.bedrooms,
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Full baths",
        cohort: emptyCell,
        project: ({ programData }) => programData?.fullBaths,
        w: "110px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: "Half baths",
        cohort: emptyCell,
        project: ({ programData }) => programData?.halfBaths,
        w: "110px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: { content: "Basement", alignment: "left" },
        cohort: emptyCell,
        project: ({ programData }) => ({ content: programData?.basementConfig?.name, alignment: "left" }),
        w: "150px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: { content: "Attached Garage", alignment: "left" },
        cohort: emptyCell,
        project: ({ programData }) => ({ content: programData?.garageAttached, alignment: "left" }),
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: { content: "Detached Garage", alignment: "left" },
        cohort: emptyCell,
        project: ({ programData }) => ({ content: programData?.garageDetached, alignment: "left" }),
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: { content: "Carport", alignment: "left" },
        cohort: emptyCell,
        project: ({ programData }) => ({ content: programData?.garagePort, alignment: "left" }),
        w: "100px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: { content: "Garage Load", alignment: "left" },
        cohort: emptyCell,
        project: ({ programData }) => ({ content: programData?.garageConfiguration?.name, alignment: "left" }),
        w: "130px",
      }),
      numericColumn<Row>({
        expandableHeader: emptyCell,
        header: { content: "Window Color", alignment: "left" },
        cohort: emptyCell,
        project: ({ programData }) => ({ content: programData?.windowColor?.name, alignment: "left" }),
        w: "100px",
      }),
    ],
  });

  const elevationColumns = column<Row>({
    expandableHeader: "Exterior options",
    header: "Elevation",
    cohort: emptyCell,
    project: ({ elevationOption }) =>
      elevationOption
        ? `${elevationOption.readyPlanOption?.name}${
            elevationOption.readyPlanOption.shortName ? ` (${elevationOption.readyPlanOption.shortName})` : ""
          }`
        : "Not configured",
    w: "200px",
    expandColumns: [
      column<Row>({
        expandableHeader: emptyCell,
        header: "Exterior scheme",
        cohort: emptyCell,
        project: ({ exteriorPaletteOption }) => exteriorPaletteOption?.readyPlanOption?.name ?? "Not configured",
        w: "200px",
      }),
    ],
  });

  const siteConditionsColumns = column<Row>({
    expandableHeader: "",
    expandColumns: [],
    header: "Site Conditions",
    cohort: emptyCell,
    project: ({ project }) => (
      <IconButton
        icon={project.projectSiteCondition ? "checklistComplete" : "checklistNotComplete"}
        onClick={() =>
          openInDrawer({ content: <LotCheckFormDrawer projectId={project.id} developmentId={developmentId} /> })
        }
      />
    ),
    w: "200px",
    clientSideSort: false,
  });

  const optionColumns: GridColumn<Row>[] = [];
  otherOptionTypes.forEach((option) => {
    const optionColumn = column<Row>({
      expandableHeader: option.name === OptionTypes.AddOn ? "Add-ons" : `${option.name} options`,
      header: emptyCell,
      cohort: emptyCell,
      project: ({ id, options }, { expanded }) => {
        const chips = options?.filter(({ readyPlanOption }) => readyPlanOption.globalOption.type.name === option.name);
        return chips.isEmpty
          ? "None"
          : expanded
            ? chipCell(
                chips.map(({ readyPlanOption }) => readyPlanOption.name) ?? [],
                3,
                `${createDevelopmentLotDetailsUrl(developmentId, id)}?tab=options`,
              )
            : `${chips.length} ${pluralize(chips.length, "option")}`;
      },
      w: "160px",
      expandedWidth: "340px",
    });

    const hasData = !otherOptionsData.filter(({ readyPlanOption }) => readyPlanOption.type.name === option.name)
      .isEmpty;
    hasData && optionColumns.push(optionColumn);
  });

  const moveColumn = actionColumn<Row>({
    expandableHeader: emptyCell,
    header: emptyCell,
    cohort: (cohort) => (
      <CreateOrUpdateCohortModal
        developmentId={developmentId}
        marketId={cohort?.market.id}
        cohortId={cohort?.id}
        newOrUpdateCohortFromDevelopment={refetch}
      />
    ),
    project: ({ project }) => <MoveProjectButtonMenu project={project} />,
    mw: "80px",
    sticky: "right",
  });

  return [
    ...(groupBy !== LotSummaryGroupBy.none ? [collapseColum] : [statusColumn]),
    idColumn,
    addressColumn,
    scheduleColumn,
    lotInfoColumn,
    planColumn,
    elevationColumns,
    ...optionColumns,
    siteConditionsColumns,
    moveColumn,
  ];
}

function getStatusTag(projectReadyPlanConfigs: ProjectReadyPlanConfigForLotSequenceFragment) {
  const { createdAt, configurationUpdatedAt, applied, project, programData } = projectReadyPlanConfigs;
  const isNew = dateCanShowStatusTags(createdAt);
  const pldUpdatedAt = closestTo(new Date(), [project?.lotDetail?.updatedAt, programData?.updatedAt].compact());
  const pldCreatedAt = project?.lotDetail?.createdAt;
  const isUpdated =
    (configurationUpdatedAt > createdAt && dateCanShowStatusTags(configurationUpdatedAt)) ||
    (pldUpdatedAt && pldUpdatedAt > pldCreatedAt && dateCanShowStatusTags(pldUpdatedAt));
  const isPending =
    !applied && (project.scopeMode.code === ScopeMode.Full || project.scopeMode.code === ScopeMode.BudgetSafe);
  const hasDeviations =
    projectReadyPlanConfigs.programDataMatchState.code === ProgramDataMatchState.DeviationPendingReview;

  // In cases where one or more tags are applicable, Pending trumps Updated, Updated trumps New
  return hasDeviations ? (
    <Icon
      data-testid="deviations_warning"
      icon="error"
      color={Palette.Red600}
      tooltip={
        <div css={Css.tac.$}>This lot contains configurations that diverge from our computed suggested values</div>
      }
      inc={2}
    />
  ) : isPending ? (
    <LotConfigurationStatusChip readyPlanConfig={projectReadyPlanConfigs} />
  ) : isUpdated ? (
    <Chip type="caution" text="Updated" />
  ) : isNew ? (
    <Chip type="dark" text="New" />
  ) : (
    <></>
  );
}

type HeaderRow = { kind: "header" };
type ExpandableHeader = { kind: "expandableHeader" };

type CohortRow = {
  kind: "cohort";
  data: LotSequentSheetTable_CohortFragment;
};
type ProjectRow = {
  kind: "project";
  data: ProjectReadyPlanConfigForLotSequenceFragment;
};
export type Row = ExpandableHeader | HeaderRow | CohortRow | ProjectRow;

export enum ExpandedColumns {
  Collapse,
  Id,
  Address,
  LatestStage,
  Status,
  PreConStart,
  PreConEnd,
  ConstructionStart,
  VerticalComplete,
  LotType,
  ClientName,
  LotNumber,
  Block,
  Section,
  LotSquareFootage,
  FloodPlain,
  FloodZone,
  setBackFrontInFeet,
  setBackRightInFeet,
  setBackLeftInFeet,
  setBackRearInFeet,
  Hoa,
  HoaSpecificModifications,
  SiteSpecificModifications,
  ConstructionType,
  FoundationType,
  FireSprinklersRequired,
  Plan,
  SpecOption,
  SellableSF,
  NetBuildableSF,
  GrossBuildableSF,
  PermitableSF,
  SellableAboveGroundSF,
  GrossBelowGroundSF,
  SellableBelowGroundSF,
  UnfinishedBelowGroundSF,
  Orientation,
  Stories,
  Bedrooms,
  FullBaths,
  HalfBaths,
  Basement,
  AttachedGarage,
  DetachedGarage,
  Carport,
  GarageLoad,
  WindowColor,
  Elevation,
  ExteriorScheme,
  FloorPlanOptions,
  AddOns,
  InteriorOptions,
  CustomOptions,
  siteConditions,
  Actions,
}

export enum CollapsedColumns {
  Collapse,
  Id,
  Address,
  LatestStage,
  LotType,
  Plan,
  Elevation,
  FloorPlanOptions,
  AddOns,
  InteriorOptions,
  CustomOptions,
}
