import { Accordion, Css, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { createBillPageUrl } from "src/RouteUrls";
import { ActionItemsBillsPendingReviewQuery, useActionItemsBillsPendingReviewQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { ItemCard } from "../../components/ItemCard";
import { ActionItemTypes } from "../../enums";
import { ActionItemsContainer, EmptyActionItems } from "./ActionItemsPage";

export function ActionItemsBillsPendingReview() {
  const { filter } = useDashboardFilterContext();
  const query = useActionItemsBillsPendingReviewQuery({
    variables: { reviewer: filter.internalUser ?? [], projectId: filter.project },
    skip: !filter.internalUser,
  });

  return queryResult(query, {
    data: (data) => <ActionItemsBillsPendingReviewLayout data={data} />,
    loading: () => <LoadingSkeleton rows={3} columns={3} size="lg" />,
    showLoading: "always",
  });
}

function ActionItemsBillsPendingReviewLayout({ data }: { data: ActionItemsBillsPendingReviewQuery }) {
  const { bills } = data;
  const tid = useTestIds({}, "actionItemBillsPendingReview");

  const pendingReviewBills = useMemo(() => {
    return bills.map((bill) => (
      <ItemCard
        key={`bill_pending_review${bill.id}`}
        actionType={ActionItemTypes.BILLS_PENDING_REVIEW}
        onClick={() => window.open(createBillPageUrl({ idOrAdd: bill.id }), "_blank")}
        title="Bill Pending Review"
        description={
          <p css={Css.df.fdr.gap1.tal.$}>
            Bill
            <Link target="_blank" to={bill.blueprintUrl.path}>
              #{bill.tradePartnerNumber}
            </Link>
            from
            <span>{bill.tradePartner.name}</span>
            {bill.createdBy && (
              <>
                Created by
                <span>{bill.createdBy.name}</span>
              </>
            )}{" "}
            is pending review
          </p>
        }
        duration={bill.dueDate ? formatDistanceToNow(bill.dueDate, { addSuffix: true }) : undefined}
        project={bill.project}
      />
    ));
  }, [bills]);

  return (
    <Accordion {...tid} title={`Bills Pending Review (${pendingReviewBills.length})`} defaultExpanded topBorder={false}>
      {pendingReviewBills.isEmpty && <EmptyActionItems title="No bills pending review" />}
      <ActionItemsContainer count={pendingReviewBills.length}>{pendingReviewBills}</ActionItemsContainer>
    </Accordion>
  );
}
