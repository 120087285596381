import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { ReactNode, useState } from "react";
import {
  ChangeEvent,
  EstimateStatus,
  EstimateType,
  ProjectStage,
  SaveEstimateInput,
  useCreateEstimateMutation,
} from "src/generated/graphql-types";
import { createEstimateUrl } from "src/RouteUrls";
import { safeEntries } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";

type CreateEstimateModalProps = {
  initialFormState?: {
    title?: string;
    type?: EstimateType;
  };
  projectId: string;
  projectStage: Pick<ProjectStage, "id" | "canCreateFinalEstimate">;
  changeEvent?: Pick<ChangeEvent, "id" | "canCreateFinalEstimate">;
  successConfirmation?: ReactNode;
};

export function CreateEstimateModal(props: CreateEstimateModalProps) {
  const { initialFormState, projectId, projectStage, changeEvent, successConfirmation } = props;
  const { canCreateFinalEstimate } = changeEvent ?? projectStage;
  const [createdEstimateId, setCreatedEstimateId] = useState<string>();
  const [createEstimate] = useCreateEstimateMutation();
  const { closeModal } = useModal();
  const init = initialFormState || {};
  if (!canCreateFinalEstimate) {
    init.type = EstimateType.Preliminary;
  }
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: init,
    },
  });

  if (!!createdEstimateId && successConfirmation) {
    return <>{successConfirmation}</>;
  }

  if (!!createdEstimateId) {
    return (
      <>
        <ModalHeader>Estimate Created</ModalHeader>
        <ModalBody>
          An estimate has been successfully created.
          <br />
          <br />
          Once the homeowner approves the estimate, be sure to mark the status as <b>Accepted</b>.
        </ModalBody>
        <ModalFooter>
          <Observer>
            {() => (
              <>
                <Button variant="tertiary" label="Close" onClick={closeModal} />
                <Button
                  endAdornment={<Icon icon="linkExternal" inc={2} />}
                  label="View Estimate"
                  onClick={() => {
                    openNewTab(createEstimateUrl(projectId, createdEstimateId));
                    closeModal();
                  }}
                />
              </>
            )}
          </Observer>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader>Create Estimate</ModalHeader>
      <ModalBody>
        <Observer>
          {() => (
            <div css={Css.mt1.df.fdc.gap2.$}>
              <BoundSelectField
                field={formState.type}
                options={safeEntries(EstimateType)}
                getOptionLabel={([name]) => name}
                getOptionValue={([_, code]) => code}
                helperText={canCreateFinalEstimate ? undefined : "Can be changed once all selections are finalized"}
                readOnly={!canCreateFinalEstimate}
              />
              <BoundTextField field={formState.title} />
            </div>
          )}
        </Observer>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Cancel" onClick={closeModal} />
              <Button
                disabled={!formState.valid}
                label="Create Estimate"
                onClick={async () => {
                  if (formState.canSave()) {
                    try {
                      const result = await createEstimate({
                        variables: {
                          input: {
                            ...formState.value,
                            projectStageId: projectStage.id,
                            // changeEvent?.id will be undefined when it's not for a change event, which is correct
                            changeEventId: changeEvent?.id,
                            status: EstimateStatus.Draft,
                          },
                        },
                      });
                      const id = result.data?.saveEstimate.estimate.id;
                      formState.commitChanges();
                      setCreatedEstimateId(id);
                    } catch (e) {
                      console.error(e);
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = SaveEstimateInput;

const formConfig: ObjectConfig<FormValue> = {
  type: { type: "value", rules: [required] },
  title: { type: "value", rules: [required] },
};
