import {
  BoundSelectField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  DuplicateScheduleTemplateInput,
  ScheduleTemplatesDetailFragment,
  Stage,
  useDuplicateScheduleTemplateMutation,
  useScheduleTemplateDetailQuery,
  useScheduleTemplatesPageMetadataQuery,
} from "src/generated/graphql-types";
import { createScheduleTemplateUrl } from "src/RouteUrls";
import { stageCodeToNameMapper } from "src/utils";

type DuplicateScheduleTemplateModalFormProps = {
  scheduleTemplateId: string;
  stage: Stage;
};

export function DuplicateScheduleTemplateModalForm({
  scheduleTemplateId,
  stage,
}: DuplicateScheduleTemplateModalFormProps) {
  const { closeModal } = useModal();
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { data: marketOptions, loading: marketOptionsLoading } = useScheduleTemplatesPageMetadataQuery({
    fetchPolicy: "cache-first",
  });

  const { data: templateData, loading: templateDataLoading } = useScheduleTemplateDetailQuery({
    variables: { scheduleTemplateId },
  });

  const [duplicateScheduleTemplate, { loading: duplicateScheduleTemplatesLoading }] =
    useDuplicateScheduleTemplateMutation();

  const loading = marketOptionsLoading || templateDataLoading || duplicateScheduleTemplatesLoading;
  const testIds = useTestIds({});
  const formState = useFormState({
    config: formConfig,
    init: {
      input: templateData?.scheduleTemplate,
      map: mapToFormState,
    },
  });
  const isProjectTemplate = stage !== Stage.Development;
  const onSubmit = async () => {
    const result = await duplicateScheduleTemplate({
      variables: { input: { ...mapToInput(formState.value), scheduleTemplateId } },
    });
    const userErrors = result.data?.duplicateScheduleTemplate?.userErrors;
    if (userErrors && userErrors.length > 0) {
      setIsError(true);
      setErrorText(userErrors.map((e) => e.message).join(" "));
    } else {
      // close modal then redirect to schedule template details page
      closeModal();
      history.push({
        pathname: createScheduleTemplateUrl(result.data?.duplicateScheduleTemplate?.scheduleTemplate?.id),
      });
    }
  };

  return (
    <>
      <ModalHeader>{"Duplicate a template"}</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField
            field={formState.name}
            label="Name"
            compact={true}
            disabled={loading}
            errorMsg={isError ? errorText : ""}
          />
          {isProjectTemplate && (
            <>
              <TextField value={stageCodeToNameMapper[stage]} label="Stage" compact readOnly onChange={() => {}} />
              <BoundSelectField
                options={marketOptions?.markets ?? []}
                field={formState.market}
                label="Market"
                disabled={loading}
                required
                compact
              />
            </>
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              {...testIds.duplicateTemplateButton}
              label="Create"
              onClick={onSubmit}
              disabled={!formState.valid || loading}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

function mapToFormState(scheduleTemplate: ScheduleTemplatesDetailFragment): FormValue {
  if (!scheduleTemplate) return {} as FormValue;

  const { id, name, market } = scheduleTemplate;
  return {
    scheduleTemplateId: id,
    name: `Copy of ${name}`,
    market: market?.id,
  };
}

type FormValue = Pick<DuplicateScheduleTemplateInput, "scheduleTemplateId" | "market"> & {
  name: string | null | undefined;
};

const formConfig: ObjectConfig<FormValue> = {
  scheduleTemplateId: { type: "value" },
  market: { type: "value" },
  name: { type: "value", rules: [required] },
};

function mapToInput(form: FormValue): DuplicateScheduleTemplateInput {
  const { name, ...others } = form;
  return {
    ...others,
    // ensures that we are sending back a string
    name: String(form.name),
  };
}
