import {
  column,
  dateColumn,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleHeader,
} from "@homebound/beam";
import { dateCell, markupCell, priceCell } from "src/components";
import {
  HomeownerContractChangeOrderSummaryFragment,
  HomeownerContractSummaryFragment,
  HomeownerContractSummaryWithChangeOrdersFragment,
  useHomeownerContractSummariesQuery,
} from "src/generated/graphql-types";
import { mapToProjectStagesFilter, SingleStageFilter } from "src/hooks/useProjectStageSingleFilter";
import { createHomeownerContractChangeOrderUrl, createHomeownerContractsUrl } from "src/RouteUrls";
import { hasData, renderLoadingOrError } from "src/utils";

type HomeownerContractsTableProps = {
  projectId: string;
  filter: SingleStageFilter;
};

export function HomeownerContractsTable({ projectId, filter }: HomeownerContractsTableProps) {
  const query = useHomeownerContractSummariesQuery({
    variables: { projectId, stagesFilter: mapToProjectStagesFilter(filter) },
    // We're using cache-and-network to re-fetch contracts after adding one via the Specs & Selections workflow
    nextFetchPolicy: "cache-and-network",
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const contracts = query.data.project.stages.flatMap((s) => s.homeownerContracts);

  return (
    <GridTable
      columns={createColumns()}
      rows={buildRows(contracts, sumContracts(contracts))}
      sorting={{ on: "client" }}
      rowStyles={createRowStyles(projectId)}
      style={{ rowHeight: "fixed", bordered: true, allWhite: true }}
      stickyHeader
      fallbackMessage="There are no signed contracts for the selected stage(s)."
    />
  );
}

type TotalsRow = {
  kind: "totals";
  data: {
    invoicedInCents: number;
    uninvoicedInCents: number;
    amountPaidInCents: number;
    balanceInCents: number;
    budgetChangeInCents: number;
    priceChangeInCents: number;
  };
};
type HeaderRow = { kind: "header" };
type ContractRow = { kind: "contract"; id: string; data: HomeownerContractSummaryFragment };
type ChangeOrderRow = {
  kind: "changeOrder";
  id: string;
  data: {
    changeOrder: HomeownerContractChangeOrderSummaryFragment;
    stageName: string;
  };
};
type Row = TotalsRow | HeaderRow | ContractRow | ChangeOrderRow;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      totals: "Totals",
      header: "Contract",
      contract: ({ name }) => name,
      changeOrder: ({ changeOrder }) => ({
        content: `CO #${changeOrder.identifier}`,
        // Coerce string-sort back to numeric sort (so "10" comes after "9" instead of after "1")
        sortValue: Number(changeOrder.identifier),
      }),
      mw: "150px",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Stage",
      contract: ({ projectStage }) => projectStage.stage.name,
      changeOrder: ({ stageName }) => stageName,
      w: "84px",
    }),
    dateColumn<Row>({
      totals: emptyCell,
      header: "Execution Date",
      contract: ({ executionDate }) => dateCell(executionDate),
      changeOrder: ({ changeOrder }) => dateCell(changeOrder.executionDate),
      w: "140px",
    }),
    numericColumn<Row>({
      totals: ({ budgetChangeInCents }) => priceCell({ valueInCents: budgetChangeInCents }),
      header: () => "Budgeted Cost",
      contract: ({ budgetChangeInCents }) => priceCell({ valueInCents: budgetChangeInCents }),
      changeOrder: ({ changeOrder }) => priceCell({ valueInCents: changeOrder.budgetChangeInCents }),
      w: "130px",
    }),
    numericColumn<Row>({
      totals: ({ budgetChangeInCents, priceChangeInCents }) => markupCell(budgetChangeInCents, priceChangeInCents),
      header: () => "Markup",
      contract: ({ budgetChangeInCents, priceChangeInCents }) => markupCell(budgetChangeInCents, priceChangeInCents),
      changeOrder: ({ changeOrder: { budgetChangeInCents, priceChangeInCents } }) =>
        markupCell(budgetChangeInCents, priceChangeInCents),
      w: "200px",
    }),
    numericColumn<Row>({
      totals: ({ priceChangeInCents }) => priceCell({ valueInCents: priceChangeInCents }),
      header: () => "Contract Price",
      contract: ({ priceChangeInCents }) => priceCell({ valueInCents: priceChangeInCents }),
      changeOrder: ({ changeOrder }) => priceCell({ valueInCents: changeOrder.priceChangeInCents }),
      w: "140px",
    }),
    numericColumn<Row>({
      totals: ({ invoicedInCents }) => priceCell({ valueInCents: invoicedInCents }),
      header: () => "Invoiced",
      contract: ({ invoicedInCents }) => priceCell({ valueInCents: invoicedInCents }),
      changeOrder: ({ changeOrder }) => priceCell({ valueInCents: changeOrder.invoicedInCents }),
      w: "120px",
    }),
    numericColumn<Row>({
      totals: ({ uninvoicedInCents }) => priceCell({ valueInCents: uninvoicedInCents }),
      header: () => "Remaining on Contract",
      contract: ({ uninvoicedInCents }) => priceCell({ valueInCents: uninvoicedInCents }),
      changeOrder: ({ changeOrder }) => priceCell({ valueInCents: changeOrder.uninvoicedInCents }),
      w: "180px",
    }),
    numericColumn<Row>({
      totals: ({ amountPaidInCents }) => priceCell({ valueInCents: amountPaidInCents }),
      header: () => "Received to Date",
      contract: ({ amountPaidInCents }) => priceCell({ valueInCents: amountPaidInCents }),
      changeOrder: ({ changeOrder }) => priceCell({ valueInCents: changeOrder.amountPaidInCents }),
      w: "150px",
    }),
    numericColumn<Row>({
      totals: ({ balanceInCents }) => priceCell({ valueInCents: balanceInCents }),
      header: () => "Balance Outstanding",
      contract: ({ balanceInCents }) => priceCell({ valueInCents: balanceInCents }),
      changeOrder: ({ changeOrder }) => priceCell({ valueInCents: changeOrder.balanceInCents }),
      w: "170px",
    }),
  ];
}

function buildRows(
  contracts: HomeownerContractSummaryWithChangeOrdersFragment[],
  totals: HomeownerContractTotals["data"],
): GridDataRow<Row>[] {
  return [
    { kind: "totals", id: "totals", data: totals },
    simpleHeader,
    ...contracts.map((contract) => {
      return {
        kind: "contract" as const,
        id: contract.id,
        data: contract,
        children: contract.changeOrders.map((co) => ({
          kind: "changeOrder" as const,
          id: co.id,
          data: {
            changeOrder: co,
            stageName: contract.projectStage.stage.name,
          },
        })),
      };
    }),
  ];
}

function createRowStyles(projectId: string): RowStyles<Row> {
  return {
    header: {},
    contract: {
      rowLink: ({ id }) => createHomeownerContractsUrl(projectId, id),
    },
    changeOrder: {
      rowLink: ({ data }) => createHomeownerContractChangeOrderUrl(projectId, data.changeOrder.id),
    },
  };
}

type HomeownerContractTotals = Omit<TotalsRow, "kind">;

export function sumContracts(
  contracts: HomeownerContractSummaryWithChangeOrdersFragment[],
): HomeownerContractTotals["data"] {
  const flattenedContracts = contracts.flatMap(({ changeOrders, ...contract }) => [contract, ...changeOrders]);

  return flattenedContracts.reduce(
    (acc, contract) => {
      return {
        budgetChangeInCents: acc.budgetChangeInCents + (contract.budgetChangeInCents || 0),
        priceChangeInCents: acc.priceChangeInCents + (contract.priceChangeInCents || 0),
        invoicedInCents: acc.invoicedInCents + (contract.invoicedInCents || 0),
        uninvoicedInCents: acc.uninvoicedInCents + (contract.uninvoicedInCents || 0),
        amountPaidInCents: acc.amountPaidInCents + (contract.amountPaidInCents || 0),
        balanceInCents: acc.balanceInCents + (contract.balanceInCents || 0),
      };
    },
    {
      budgetChangeInCents: 0,
      priceChangeInCents: 0,
      invoicedInCents: 0,
      amountPaidInCents: 0,
      uninvoicedInCents: 0,
      balanceInCents: 0,
    },
  );
}
