import {
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  RowStyles,
  simpleHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { chipCell, tagCell } from "src/components";
import { ProductAttributeType, ProductCatalogDetailsFragment } from "src/generated/graphql-types";
import { ProductParams } from "src/routes/routesDef";
import { createProductCatalogUrl, createProductDetailsUrl } from "src/RouteUrls";
import { productStatusToTagTypeMapper } from "src/utils";
import { ProductSuperDrawer } from "./product-super-drawer/ProductSuperDrawer";

export type ProductCatalogListViewsProps = {
  filteredProducts: ProductCatalogDetailsFragment[];
};

export function ProductCatalogListView({ filteredProducts }: ProductCatalogListViewsProps) {
  const { openInDrawer } = useSuperDrawer();
  const { push } = useHistory();
  const rows = useMemo(() => createRows(filteredProducts), [filteredProducts]);
  const columns = useMemo(() => createColumns(), []);
  const rowLookup = useRef<GridRowLookup<Row>>();
  const { productId } = useParams<ProductParams>();
  const rowStyles = useMemo(() => createRowStyles(createProductDetailsUrl, push), [push]);

  const openRow = useCallback(
    (productId: string) => {
      if (!rowLookup.current) return;

      const list = rowLookup.current.currentList();
      const productRows = list.filter((l) => l.kind === "item");
      const productRow = productRows.find((row) => row.id === productId);
      if (!productRow) return;

      /**
       * Update URL with the prev/next product id. We do not need to
       * explicity call openRow since the useEffect below watches for URL
       * changes and triggers a SuperDrawer update.
       */
      const { prev, next } = rowLookup.current!.lookup(productRow)["item"];
      openInDrawer({
        content: <ProductSuperDrawer productId={productRow.id} />,
        onClose: () => {
          // When closing the SuperDrawer, remove /:productId and retain query parameters
          push(createProductCatalogUrl());
        },
        onPrevClick: prev && (() => push(createProductDetailsUrl(prev.id))),
        onNextClick: next && (() => push(createProductDetailsUrl(next.id))),
      });
    },
    [openInDrawer, push],
  );

  // Attempt to open the SuperDrawer based on the queryParam productId - i.e. libraries/product-catalog/pr:1
  useEffect(() => {
    if (productId) {
      openRow(productId);
    }
  }, [productId, openRow]);

  return (
    <div css={Css.maxw100.add("flexWrap", "wrap").oa.$}>
      <GridTable
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        rowLookup={rowLookup}
        stickyHeader
        sorting={{ on: "client" }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header"; id: string };
type DetailRow = { kind: "item"; data: ProductCatalogDetailsFragment };
type Row = HeaderRow | DetailRow;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Item Code / SKU",
      item: (p) => (p.sku ? chipCell([p.sku]) : ""),
    }),
    column<Row>({
      header: "Name",
      item: (p) => p.name,
    }),
    column<Row>({
      header: "Brand",
      item: (p) => {
        const brand = p.attributes.find((atr) => atr.type === ProductAttributeType.Brand)?.value;
        return brand ? chipCell([brand!]) : undefined;
      },
    }),
    column<Row>({
      header: "UPC",
      item: (p) => p.upc ?? "",
    }),
    column<Row>({
      header: "Finish",
      item: (p) =>
        chipCell(p.attributes.filter((atr) => atr.type === ProductAttributeType.Finish).map((atr) => atr.value)),
    }),
    column<Row>({
      header: "Status",
      item: (p) => tagCell(productStatusToTagTypeMapper[p.status.code], p.status.code),
    }),
  ];
}

function createRows(filteredProducts: ProductCatalogDetailsFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...filteredProducts.map((p) => ({
      id: p.id,
      kind: "item" as const,
      data: p,
    })),
  ];
}

function createRowStyles(createProductDetailsUrl: (id: string) => string, push: (url: string) => void): RowStyles<Row> {
  function openProductDrawer(row: GridDataRow<Row>): void {
    if (row.kind === "item") push(createProductDetailsUrl(row.id));
  }
  return {
    item: { onClick: openProductDrawer },
  };
}
