import { Css, Properties } from "@homebound/beam";
import { HasChildren } from "src/utils/types";

// TODO: These are all ancient, up to 3 years, and were clearly poised to be reused across the app but never caughtt on.
// Suggest either just localizing them to where they're used and getting rid of this, or making a tangible push to
// incorporate updated versions of these across the paltform.

// TODO: Used in 1 place, 22mo old
export function PageHeading(props: HasChildren & { xss?: Properties }) {
  const { xss = {} } = props;
  return (
    <h1
      css={{
        ...Css.my2.xl2Sb.gray800.df.aic.gap1.$,
        ...xss,
      }}
      data-testid={props["data-testid"] || "PageHeading"}
    >
      {props.children}
    </h1>
  );
}

// TODO: Used in 1 place, 9mo old
export function PageHeadingDetails(props: HasChildren) {
  return <span css={Css.gray700.sm.$}>{props.children}</span>;
}

// TODO: Used in 2 places, 3y/o
export function SubHeading(props: HasChildren) {
  // Using padding here instead of margin in order to not allow content that might be scrolling beneath it to bleed through.
  return (
    <div css={Css.sm.gray700.py1.$} data-testid={props["data-testid"] || "SubHeading"}>
      {props.children}
    </div>
  );
}

// TODO: Used in 1 place, 3 y/o
export function CaptionText(props: HasChildren) {
  return (
    <div css={Css.tinySb.gray700.$} data-testid={props["data-testid"] || "CaptionText"}>
      {props.children}
    </div>
  );
}
