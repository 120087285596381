import { Button, Checkbox, Css, FieldGroup, FormLines, MultiSelectField } from "@homebound/beam";
import { useState } from "react";
import { TaskAssigneeFragment, TaskStatusesFragment, TaskTradePartnerFragment } from "src/generated/graphql-types";
import { TaskAssigneeMultiSelectField } from "../components/TaskAssigneeMultiSelectField";
import { CustomTaskFilter } from "../table/filterUtils";

type ScheduleCalendarFilterModalProps = {
  filterOptions: {
    assignees: TaskAssigneeFragment[];
    tradePartners: TaskTradePartnerFragment[];
    taskStatuses: TaskStatusesFragment[];
  };
  filter: CustomTaskFilter;
  setFilter: (filter: CustomTaskFilter) => void;
  onClose: () => void;
};

export function ScheduleCalendarFilterModal({
  filterOptions,
  filter,
  setFilter,
  onClose,
}: ScheduleCalendarFilterModalProps) {
  const [modalFilter, setModalFilter] = useState<CustomTaskFilter>({ ...filter });
  const { tradePartners, assignees } = filterOptions;

  const onSubmit = () => {
    setFilter({ ...(modalFilter as CustomTaskFilter) });
    onClose();
  };

  return (
    <div>
      <FormLines width="full">
        <MultiSelectField
          onSelect={(val) =>
            setModalFilter((prevState) => ({ ...prevState, tradePartner: val.isEmpty ? undefined : val }))
          }
          label="Trade Partner"
          options={tradePartners}
          values={modalFilter.tradePartner ?? []}
          disabled={
            filterOptions.tradePartners.length === 0 && "There are no trade partners assigned to tasks on this project"
          }
        />
        <TaskAssigneeMultiSelectField
          options={assignees}
          values={modalFilter.internalUser ?? []}
          onSelect={(val) =>
            setModalFilter((prevState) => ({
              ...prevState,
              internalUser: val?.isEmpty ? undefined : val,
            }))
          }
        />
        <h3 css={Css.sm.gray700.$}>Other</h3>
        <FieldGroup>
          <Checkbox
            label="Milestones"
            selected={!!modalFilter.isGlobalMilestoneTask}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, isGlobalMilestoneTask: val }));
            }}
          />
          <Checkbox
            label="Critical Paths"
            selected={!!modalFilter.isCriticalPath}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, isCriticalPath: val }));
            }}
          />
        </FieldGroup>
        <FieldGroup>
          <Checkbox
            selected={!!modalFilter.homeownerVisible}
            label="Homeowner Visible"
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, homeownerVisible: val }));
            }}
          />
          <Checkbox
            selected={!!modalFilter.stale}
            label="Stale Tasks"
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, stale: val }));
            }}
          />
        </FieldGroup>
      </FormLines>
      <div css={Css.df.gap1.jcfe.$}>
        <Button variant="tertiary" label="Cancel" onClick={onClose} />
        <Button label="Apply" onClick={onSubmit} />
      </div>
    </div>
  );
}
