import { BoundChipSelectField } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useContext } from "react";
import { GlobalPhaseDetailsFragment } from "src/generated/graphql-types";
import { SchedulePhaseContext } from "src/routes/projects/schedule-v2/contexts/SchedulePhaseContext";
import { TaskDetailsFormInput } from "src/routes/projects/schedule-v2/table/utils";

type PhaseCellProps = {
  objectState: ObjectState<Partial<TaskDetailsFormInput>>;
  scheduleIsLocked: boolean;
  hasGlobalTask: boolean;
  globalPhaseName?: string;
};

export function PhaseCell({ objectState, scheduleIsLocked, hasGlobalTask, globalPhaseName }: PhaseCellProps) {
  const { globalPhases } = useContext(SchedulePhaseContext);
  const globalPhasesForStage = Object.values(globalPhases)
    .filter((meta) => meta.schedulePhaseId !== undefined || meta.globalPhase.active)
    .map((meta) => meta.globalPhase);

  const globalTaskDisabledText = hasGlobalTask ? `This task cannot be moved from ${globalPhaseName}` : undefined;

  return (
    <BoundChipSelectField
      getOptionValue={phaseOptionValue}
      getOptionLabel={phaseOptionLabel}
      options={globalPhasesForStage}
      field={objectState.schedulePhase}
      placeholder="+ Phase"
      disabled={scheduleIsLocked || globalTaskDisabledText}
    />
  );
}

function phaseOptionValue(p: GlobalPhaseDetailsFragment) {
  return p.id;
}

function phaseOptionLabel(p: GlobalPhaseDetailsFragment) {
  return p.name;
}
