export function EmptyPlansDetailImage() {
  return (
    <svg width="400" height="200" viewBox="0 0 250 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11597_83224)">
        <path d="M153.262 175.765L158.012 175.765L160.272 157.442L153.261 157.443L153.262 175.765Z" fill="#A0616A" />
        <path
          d="M169.537 175.657C169.685 175.906 169.763 176.71 169.763 177C169.763 177.89 169.041 178.612 168.15 178.612H153.44C152.832 178.612 152.34 178.12 152.34 177.512V176.9C152.34 176.9 151.612 175.059 153.11 172.79C153.11 172.79 154.972 174.567 157.755 171.784L158.576 170.298L164.515 174.642L167.807 175.047C168.528 175.136 169.166 175.033 169.537 175.657H169.537V175.657V175.657Z"
          fill="#2F2E41"
        />
        <path d="M167.75 170.932L172.235 172.495L180.401 155.938L173.781 153.63L167.75 170.932Z" fill="#A0616A" />
        <path
          d="M183.152 176.188C183.21 176.472 183.019 177.257 182.924 177.53C182.63 178.371 181.711 178.815 180.87 178.522L166.98 173.678C166.406 173.479 166.103 172.851 166.303 172.278L166.505 171.7C166.505 171.7 166.424 169.722 168.586 168.073C168.586 168.073 169.759 170.363 173.302 168.652L174.567 167.519L178.745 173.576L181.72 175.042C182.371 175.363 183.008 175.477 183.152 176.188L183.152 176.188L183.152 176.188V176.188Z"
          fill="#2F2E41"
        />
        <path
          d="M19.8963 62.7955H1.44792C0.66543 62.7955 0.0288086 62.1589 0.0288086 61.3764C0.0288086 60.5939 0.66543 59.9573 1.44792 59.9573H19.8963C20.6788 59.9573 21.3154 60.5939 21.3154 61.3764C21.3154 62.1589 20.6788 62.7955 19.8963 62.7955Z"
          fill="#E6E6E6"
        />
        <path d="M69.2102 49.1616H0V49.5164H69.2102V49.1616Z" fill="#E6E6E6" />
        <path
          d="M47.9236 87.6297H29.4753C28.6928 87.6297 28.0562 86.9931 28.0562 86.2106C28.0562 85.4281 28.6928 84.7915 29.4753 84.7915H47.9236C48.7061 84.7915 49.3428 85.4281 49.3428 86.2106C49.3428 86.9931 48.7061 87.6297 47.9236 87.6297Z"
          fill="#E6E6E6"
        />
        <path d="M97.2375 73.9961H28.0273V74.3509H97.2375V73.9961Z" fill="#E6E6E6" />
        <path
          d="M22.0247 120.269H3.57633C2.79385 120.269 2.15723 119.633 2.15723 118.85C2.15723 118.068 2.79385 117.431 3.57633 117.431H22.0247C22.8072 117.431 23.4438 118.068 23.4438 118.85C23.4438 119.633 22.8072 120.269 22.0247 120.269Z"
          fill="#E6E6E6"
        />
        <path d="M71.3386 106.635H2.12842V106.99H71.3386V106.635Z" fill="#E6E6E6" />
        <path
          d="M63.4471 108.224L60.5273 106.841C61.7249 108.512 62.7568 111.122 63.3093 113.199C64.2447 111.264 65.7525 108.897 67.2451 107.483L64.1592 108.277C66.0607 98.9575 73.2128 92.2672 81.4029 92.2672V91.5576C72.8481 91.5576 65.3886 98.5281 63.4471 108.224V108.224Z"
          fill="#3F3D56"
        />
        <path
          d="M76.8856 122.423H58.4372C57.6547 122.423 57.0181 121.787 57.0181 121.004C57.0181 120.222 57.6547 119.585 58.4372 119.585H76.8856C77.668 119.585 78.3047 120.222 78.3047 121.004C78.3047 121.787 77.668 122.423 76.8856 122.423Z"
          fill="#0369A1"
        />
        <path
          d="M196.554 95.4861L193.634 94.103C194.831 95.7743 195.863 98.3844 196.416 100.461C197.351 98.5267 198.859 96.1594 200.352 94.7453L197.266 95.5392C199.167 86.2199 206.319 79.5296 214.509 79.5296V78.8201C205.955 78.8201 198.495 85.7906 196.554 95.4861Z"
          fill="#3F3D56"
        />
        <path
          d="M209.992 109.686H191.544C190.761 109.686 190.125 109.049 190.125 108.267C190.125 107.484 190.761 106.847 191.544 106.847H209.992C210.774 106.847 211.411 107.484 211.411 108.267C211.411 109.049 210.774 109.686 209.992 109.686Z"
          fill="#0369A1"
        />
        <path
          d="M176.288 68.4719L144.712 73.084L152.517 167.809L161.387 167.455L164.225 102.885L175.933 131.622L168.837 167.1L177.707 166.753L188.35 131.267L181.254 90.8228L176.288 68.4719Z"
          fill="#2F2E41"
        />
        <path
          d="M167.063 21.9961H155.571L146.246 73.7775C146.246 73.7775 151.798 81.3919 162.801 74.2223C173.804 67.0527 178.886 73.0679 178.886 73.0679L167.063 21.9961V21.9961Z"
          fill="#0369A1"
        />
        <path
          d="M149.085 111.739L159.968 91.8871L160.678 33.7037L155.571 21.9961L152.518 26.963L143.293 32.6757L145.081 57.8329L144.447 74.1734L144.118 82.6469C142.968 87.725 145.012 108.494 149.085 111.739Z"
          fill="#2F2E41"
        />
        <path
          d="M167.376 23.292L178.594 32.8168L185.157 89.0489L185.272 89.0329C185.874 91.3944 185.917 105.8 184.561 107.826L168.305 91.3549L163.516 33.3489L167.376 23.292V23.292Z"
          fill="#2F2E41"
        />
        <path
          d="M204.836 80.6809C205.905 82.7495 205.704 84.9777 204.388 85.6577C203.071 86.3377 201.138 85.2121 200.069 83.1435C199.601 82.238 199.377 81.3019 199.383 80.4785L194.965 71.6565L199.149 69.6714L203.06 78.5789C203.735 79.0507 204.368 79.7754 204.836 80.6809V80.6809V80.6809Z"
          fill="#A0616A"
        />
        <path
          d="M169.354 24.7852C169.354 24.7852 177.67 31.499 180.236 33.8398C181.599 35.0837 190.743 54.2776 190.743 54.2776L203.443 76.395L196.841 78.9025L183.337 58.5093L171.676 45.0566L169.354 24.7852V24.7852V24.7852Z"
          fill="#2F2E41"
        />
        <path
          d="M117.583 82.8096C116.514 84.8782 116.715 87.1064 118.032 87.7864C119.348 88.4664 121.281 87.3407 122.35 85.2721C122.818 84.3666 123.042 83.4306 123.036 82.6071L127.454 73.7851L123.27 71.8L119.359 80.7075C118.684 81.1793 118.051 81.9041 117.583 82.8096V82.8096V82.8096Z"
          fill="#A0616A"
        />
        <path
          d="M152.835 26.4319C152.835 26.4319 144.749 30.7894 142.183 33.1302C140.82 34.3742 131.676 56.4063 131.676 56.4063L118.977 78.5236L125.578 81.0311L139.082 60.6379L148.26 49.3139L152.835 26.4319L152.835 26.4319V26.4319Z"
          fill="#2F2E41"
        />
        <path
          d="M161.572 20.0827C166.689 20.0827 170.837 15.9346 170.837 10.8176C170.837 5.70062 166.689 1.55249 161.572 1.55249C156.455 1.55249 152.307 5.70062 152.307 10.8176C152.307 15.9346 156.455 20.0827 161.572 20.0827Z"
          fill="#A0616A"
        />
        <path
          d="M165.373 3.54777e-06C165.373 3.54777e-06 166.036 2.65091 163.054 0.331368C161.966 -0.51444 160.575 0.847221 159.409 0.994095C157.376 1.25001 155.764 1.05634 155.764 2.31955C155.764 3.1271 154.691 1.8982 154.107 1.98818C153.253 2.11973 152.781 3.31364 152.781 3.31364C151.487 4.13711 150.462 5.96454 152.45 6.62727C150.462 8.94681 152.305 10.1288 152.305 10.1288L154.107 7.95272C155.04 8.46183 156.308 9.77736 156.772 9.03946C158.083 6.95863 161.94 9.12807 163.539 8.25415C165.148 7.37413 166.036 10.935 166.036 10.935L166.773 11.3185C166.773 11.3185 166.367 10.6036 168.355 9.9409C169.35 9.60954 169.598 10.6036 169.598 11.6806C169.598 12.8987 169.251 14.0918 168.653 15.1527L166.773 18.4845L167.598 17.9583C169.335 16.8509 170.47 15.089 171.083 13.1225C171.113 13.0276 171.152 12.9419 171.203 12.8686L171.006 11.2664L171.655 12.0278C171.755 11.7427 171.781 11.4763 171.669 11.2664C170.475 9.01865 172.332 7.62136 170.344 4.63909C167.397 0.219103 165.373 0 165.373 0H165.373V3.54777e-06H165.373Z"
          fill="#2F2E41"
        />
        <path
          d="M102.729 178.578C102.729 178.812 102.918 179 103.152 179H232.038C232.273 179 232.461 178.812 232.461 178.578C232.461 178.344 232.273 178.156 232.038 178.156H103.152C102.918 178.156 102.729 178.344 102.729 178.578V178.578Z"
          fill="#CCCCCC"
        />
        <path
          d="M87.6577 88.5167H76.5311C75.0994 88.5167 73.9346 87.3519 73.9346 85.9201V73.3744C73.9346 71.9426 75.0994 70.7778 76.5311 70.7778H87.6577C89.0895 70.7778 90.2543 71.9426 90.2543 73.3744V85.9201C90.2543 87.3519 89.0895 88.5167 87.6577 88.5167Z"
          fill="white"
        />
        <path
          d="M87.6577 88.5167H76.5311C75.0994 88.5167 73.9346 87.3519 73.9346 85.9201V73.3744C73.9346 71.9426 75.0994 70.7778 76.5311 70.7778H87.6577C89.0895 70.7778 90.2543 71.9426 90.2543 73.3744C91.5012 77.3217 91.3746 81.5274 90.2543 85.9201C90.2543 87.3519 89.0895 88.5167 87.6577 88.5167ZM76.5311 71.1326C75.2949 71.1326 74.2893 72.1382 74.2893 73.3744V85.9201C74.2893 87.1563 75.2949 88.1619 76.5311 88.1619H87.6577C88.8939 88.1619 89.8995 87.1563 89.8995 85.9201V73.3744C89.8995 72.1382 88.8939 71.1326 87.6577 71.1326H76.5311Z"
          fill="#3F3D56"
        />
        <path
          d="M86.5337 78.4056H77.3659C76.977 78.4056 76.6606 78.0892 76.6606 77.7003C76.6606 77.3115 76.977 76.9951 77.3659 76.9951H86.5337C86.9226 76.9951 87.2389 77.3115 87.2389 77.7003C87.2389 78.0892 86.9226 78.4056 86.5337 78.4056Z"
          fill="#0369A1"
        />
        <path
          d="M86.5337 82.3081H77.3659C76.977 82.3081 76.6606 81.9918 76.6606 81.6029C76.6606 81.2141 76.977 80.8977 77.3659 80.8977H86.5337C86.9226 80.8977 87.2389 81.2141 87.2389 81.6029C87.2389 81.9918 86.9226 82.3081 86.5337 82.3081Z"
          fill="#0369A1"
        />
        <path
          d="M17.4888 54.8814H6.36218C4.93043 54.8814 3.76562 53.7166 3.76562 52.2849V39.7391C3.76562 38.3074 4.93043 37.1426 6.36218 37.1426H17.4888C18.9205 37.1426 20.0853 38.3074 20.0853 39.7391V52.2849C20.0853 53.7166 18.9205 54.8814 17.4888 54.8814Z"
          fill="white"
        />
        <path
          d="M17.4889 54.8814H6.36234C4.93059 54.8814 3.76578 53.7166 3.76578 52.2848C3.20628 47.6652 3.05215 43.3627 3.76578 39.7391C3.76578 38.3074 4.93059 37.1426 6.36234 37.1426H17.4889C18.9207 37.1426 20.0855 38.3074 20.0855 39.7391V52.2849C20.0855 53.7166 18.9207 54.8814 17.4889 54.8814V54.8814ZM6.36234 37.4974C5.12616 37.4974 4.12056 38.503 4.12056 39.7391V52.2848C4.12056 53.521 5.12616 54.5266 6.36234 54.5266H17.4889C18.7251 54.5266 19.7307 53.521 19.7307 52.2848V39.7391C19.7307 38.503 18.7251 37.4974 17.4889 37.4974H6.36234V37.4974Z"
          fill="#3F3D56"
        />
        <path
          d="M16.3647 44.7703H7.19692C6.80806 44.7703 6.4917 44.4539 6.4917 44.0651C6.4917 43.6762 6.80807 43.3599 7.19692 43.3599H16.3648C16.7536 43.3599 17.07 43.6762 17.07 44.0651C17.07 44.4539 16.7536 44.7703 16.3647 44.7703Z"
          fill="#0369A1"
        />
        <path
          d="M16.3647 48.6729H7.19692C6.80806 48.6729 6.4917 48.3565 6.4917 47.9677C6.4917 47.5788 6.80807 47.2625 7.19692 47.2625H16.3648C16.7536 47.2625 17.07 47.5788 17.07 47.9677C17.07 48.3565 16.7536 48.6729 16.3647 48.6729Z"
          fill="#0369A1"
        />
        <path
          d="M16.3647 40.8677H7.19692C6.80806 40.8677 6.4917 40.5513 6.4917 40.1625C6.4917 39.7736 6.80807 39.4573 7.19692 39.4573H16.3648C16.7536 39.4573 17.07 39.7736 17.07 40.1625C17.07 40.5513 16.7536 40.8677 16.3647 40.8677Z"
          fill="#0369A1"
        />
        <path
          d="M136.215 80.5185L127.9 87.9121C126.83 88.8635 125.186 88.7671 124.234 87.6971C120.778 84.9522 117.943 81.8586 115.898 78.3218C114.946 77.2519 115.043 75.6074 116.113 74.656L124.427 67.2624C125.497 66.311 127.142 66.4075 128.093 67.4774L136.43 76.8527C137.381 77.9226 137.285 79.5671 136.215 80.5185V80.5185Z"
          fill="white"
        />
        <path
          d="M136.215 80.5185L127.9 87.9121C126.83 88.8635 125.186 88.7671 124.234 87.6971C120.291 85.2831 117.337 82.2651 115.898 78.3218C114.946 77.2519 115.043 75.6074 116.113 74.656L124.427 67.2624C125.497 66.311 127.142 66.4075 128.093 67.4774L136.43 76.8527C137.381 77.9226 137.285 79.5671 136.215 80.5185V80.5185ZM116.348 74.9212C115.425 75.7426 115.341 77.1623 116.163 78.0861C118.007 81.8268 120.85 84.9095 124.499 87.4614C125.321 88.3852 126.741 88.4684 127.664 87.647L135.979 80.2534C136.903 79.4319 136.986 78.0122 136.165 77.0885L127.828 67.7132C127.007 66.7894 125.587 66.7061 124.663 67.5276L116.348 74.9212V74.9212Z"
          fill="#3F3D56"
        />
        <path
          d="M128.656 73.7097L121.805 79.8017C121.514 80.0601 121.068 80.0339 120.809 79.7433C120.551 79.4527 120.577 79.0061 120.868 78.7477L127.719 72.6557C128.009 72.3973 128.456 72.4235 128.714 72.7141C128.973 73.0046 128.946 73.4513 128.656 73.7097V73.7097Z"
          fill="#0369A1"
        />
        <path
          d="M131.249 76.6259L124.398 82.7179C124.108 82.9763 123.661 82.9502 123.403 82.6596C123.144 82.369 123.17 81.9223 123.461 81.6639L130.312 75.5719C130.603 75.3135 131.049 75.3397 131.308 75.6303C131.566 75.9209 131.54 76.3675 131.249 76.6259V76.6259Z"
          fill="#0369A1"
        />
        <path
          d="M126.063 70.7932L119.212 76.8852C118.921 77.1436 118.474 77.1174 118.216 76.8268C117.958 76.5362 117.984 76.0896 118.274 75.8312L125.125 69.7392C125.416 69.4808 125.863 69.507 126.121 69.7976C126.379 70.0881 126.353 70.5348 126.063 70.7932Z"
          fill="#0369A1"
        />
        <path
          d="M40.5493 116.967H29.4227C27.991 116.967 26.8262 115.803 26.8262 114.371V101.825C26.8262 100.393 27.991 99.2285 29.4227 99.2285H40.5493C41.9811 99.2285 43.1459 100.393 43.1459 101.825V114.371C43.1459 115.803 41.9811 116.967 40.5493 116.967Z"
          fill="white"
        />
        <path
          d="M40.5494 116.967H29.4228C27.991 116.967 26.8262 115.803 26.8262 114.371C25.8397 110.237 25.9215 106.051 26.8262 101.825C26.8262 100.393 27.991 99.2285 29.4228 99.2285H40.5494C41.9811 99.2285 43.1459 100.393 43.1459 101.825V114.371C43.1459 115.803 41.9811 116.967 40.5494 116.967ZM29.4228 99.5833C28.1866 99.5833 27.181 100.589 27.181 101.825V114.371C27.181 115.607 28.1866 116.613 29.4228 116.613H40.5494C41.7855 116.613 42.7911 115.607 42.7911 114.371V101.825C42.7911 100.589 41.7855 99.5833 40.5494 99.5833H29.4228Z"
          fill="#3F3D56"
        />
        <path
          d="M39.4253 106.856H30.2575C29.8686 106.856 29.5522 106.54 29.5522 106.151C29.5522 105.762 29.8686 105.446 30.2575 105.446H39.4253C39.8142 105.446 40.1305 105.762 40.1305 106.151C40.1305 106.54 39.8142 106.856 39.4253 106.856H39.4253Z"
          fill="#0369A1"
        />
      </g>
      <defs>
        <clipPath id="clip0_11597_83224">
          <rect width="396.215" height="179" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function EmptyPlanAndOptionImage() {
  return (
    <svg width="397" height="179" viewBox="0 0 397 179" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11597_80926)">
        <path d="M358.213 72.0903H346.237V113.543H358.213V72.0903Z" fill="#F2F2F2" />
        <path
          d="M366.121 105.745L336.995 71.0952L278.531 71.6297L243.065 106.173L243.782 106.634H243.526V178.486H366.042V106.634L366.121 105.745Z"
          fill="#F2F2F2"
        />
        <path d="M337.025 71.1691L300.639 113.828V178.486H366.042V105.713L337.025 71.1691Z" fill="#E6E6E6" />
        <path d="M340.25 142.729H326.893V154.535H340.25V142.729Z" fill="#3F3D56" />
        <path d="M340.25 122.294H326.893V133.913H340.25V122.294Z" fill="#3F3D56" />
        <path d="M340.25 142.729H326.893V154.535H340.25V142.729Z" fill="white" />
        <path d="M340.25 122.294H326.893V133.913H340.25V122.294Z" fill="white" />
        <path d="M280.89 139.597H267.533V151.403H280.89V139.597Z" fill="white" />
        <path d="M280.89 119.162H267.533V130.78H280.89V119.162Z" fill="white" />
        <path d="M263.711 72.0903H251.736V113.543H263.711V72.0903Z" fill="#F2F2F2" />
        <path
          d="M271.619 105.745L242.493 71.0952L184.029 71.6297L148.564 106.173L149.281 106.634H149.025V178.486H271.541V106.634L271.619 105.745Z"
          fill="#F2F2F2"
        />
        <path d="M242.524 71.1691L206.138 113.828V178.486H271.541V105.713L242.524 71.1691Z" fill="#E6E6E6" />
        <path d="M245.748 142.729H232.391V154.535H245.748V142.729Z" fill="#3F3D56" />
        <path d="M245.748 122.294H232.391V133.913H245.748V122.294Z" fill="#3F3D56" />
        <path d="M245.748 142.729H232.391V154.535H245.748V142.729Z" fill="white" />
        <path d="M245.748 122.294H232.391V133.913H245.748V122.294Z" fill="white" />
        <path d="M186.389 139.597H173.032V151.403H186.389V139.597Z" fill="white" />
        <path d="M186.389 119.162H173.032V130.78H186.389V119.162Z" fill="white" />
        <path d="M166.948 36.3157H150.946V91.7066H166.948V36.3157Z" fill="#F2F2F2" />
        <path
          d="M177.515 81.2863L138.596 34.9862L60.4736 35.7001L13.0835 81.8593L14.0408 82.4747H13.6989V178.486H177.41V82.4747L177.515 81.2863Z"
          fill="#F2F2F2"
        />
        <path d="M138.636 35.0846L90.0151 92.0876V178.486H177.41V81.2437L138.636 35.0846Z" fill="#E6E6E6" />
        <path d="M142.945 130.706H125.097V146.482H142.945V130.706Z" fill="#3F3D56" />
        <path d="M142.945 103.4H125.097V118.926H142.945V103.4Z" fill="#3F3D56" />
        <path d="M142.945 130.706H125.097V146.482H142.945V130.706Z" fill="white" />
        <path d="M142.945 103.4H125.097V118.926H142.945V103.4Z" fill="white" />
        <path d="M63.626 126.52H45.7778V142.296H63.626V126.52Z" fill="white" />
        <path d="M63.626 99.2142H45.7778V114.74H63.626V99.2142Z" fill="white" />
        <path d="M382.751 178.131H0V178.925H382.751V178.131Z" fill="#3F3D56" />
        <path
          d="M60.3033 163.53C66.6846 163.53 71.8577 152.975 71.8577 139.954C71.8577 126.934 66.6846 116.379 60.3033 116.379C53.9221 116.379 48.749 126.934 48.749 139.954C48.749 152.975 53.9221 163.53 60.3033 163.53Z"
          fill="#3F3D56"
        />
        <path
          d="M61.3703 178.544C56.7427 150.779 61.3237 123.114 61.3706 122.838L62.2707 122.991C62.2241 123.265 57.6694 150.784 62.2709 178.394L61.3703 178.544Z"
          fill="#0369A1"
        />
        <path d="M70.4756 134.885L60.0273 140.473L60.458 141.279L70.9062 135.69L70.4756 134.885Z" fill="#0369A1" />
        <path d="M49.4309 137.684L49 138.489L59.447 144.081L59.8779 143.276L49.4309 137.684Z" fill="#0369A1" />
        <path
          d="M32.5417 149.706C45.0228 149.706 55.1406 129.062 55.1406 103.596C55.1406 78.1293 45.0228 57.4847 32.5417 57.4847C20.0607 57.4847 9.94287 78.1293 9.94287 103.596C9.94287 129.062 20.0607 149.706 32.5417 149.706Z"
          fill="#0369A1"
        />
        <path
          d="M35.0587 179C26.0196 124.767 34.9675 70.7311 35.0589 70.1917L35.959 70.3441C35.8679 70.8822 26.9463 124.773 35.9593 178.85L35.0587 179Z"
          fill="#3F3D56"
        />
        <path d="M52.644 94.0648L32.208 104.996L32.6387 105.801L53.0747 94.87L52.644 94.0648Z" fill="#3F3D56" />
        <path d="M11.071 99.5406L10.6401 100.346L31.0731 111.282L31.504 110.477L11.071 99.5406Z" fill="#3F3D56" />
        <path
          d="M86.0654 138.942C103.145 138.942 116.99 110.691 116.99 75.8426C116.99 40.9939 103.145 12.7435 86.0654 12.7435C68.9861 12.7435 55.1406 40.9939 55.1406 75.8426C55.1406 110.691 68.9861 138.942 86.0654 138.942Z"
          fill="#0369A1"
        />
        <path
          d="M89.6755 179C77.311 104.814 89.5507 30.8979 89.6758 30.16L90.5759 30.3125C90.4511 31.049 78.2377 104.819 90.5761 178.85L89.6755 179Z"
          fill="#3F3D56"
        />
        <path d="M113.653 62.9488L85.688 77.9069L86.1187 78.712L114.084 63.754L113.653 62.9488Z" fill="#3F3D56" />
        <path d="M56.6052 70.442L56.1743 71.2471L84.1354 86.2125L84.5662 85.4074L56.6052 70.442Z" fill="#3F3D56" />
        <path
          d="M345.855 163.53C352.236 163.53 357.409 152.975 357.409 139.954C357.409 126.934 352.236 116.379 345.855 116.379C339.474 116.379 334.301 126.934 334.301 139.954C334.301 152.975 339.474 163.53 345.855 163.53Z"
          fill="#3F3D56"
        />
        <path
          d="M344.788 178.544C349.416 150.779 344.835 123.114 344.788 122.838L343.888 122.991C343.935 123.265 348.489 150.784 343.888 178.394L344.788 178.544Z"
          fill="#0369A1"
        />
        <path d="M335.683 134.885L335.252 135.69L345.7 141.279L346.131 140.473L335.683 134.885Z" fill="#0369A1" />
        <path d="M356.727 137.684L346.28 143.276L346.711 144.081L357.158 138.489L356.727 137.684Z" fill="#0369A1" />
        <path
          d="M373.616 149.706C386.097 149.706 396.215 129.062 396.215 103.596C396.215 78.1293 386.097 57.4847 373.616 57.4847C361.135 57.4847 351.018 78.1293 351.018 103.596C351.018 129.062 361.135 149.706 373.616 149.706Z"
          fill="#0369A1"
        />
        <path
          d="M371.099 179C380.138 124.767 371.191 70.7311 371.099 70.1917L370.199 70.3441C370.29 70.8822 379.212 124.773 370.199 178.85L371.099 179Z"
          fill="#3F3D56"
        />
        <path d="M353.514 94.0648L353.083 94.87L373.519 105.801L373.95 104.996L353.514 94.0648Z" fill="#3F3D56" />
        <path d="M395.087 99.5406L374.654 110.477L375.085 111.282L395.518 100.346L395.087 99.5406Z" fill="#3F3D56" />
        <path
          d="M320.093 138.942C337.172 138.942 351.018 110.691 351.018 75.8426C351.018 40.9939 337.172 12.7435 320.093 12.7435C303.013 12.7435 289.168 40.9939 289.168 75.8426C289.168 110.691 303.013 138.942 320.093 138.942Z"
          fill="#0369A1"
        />
        <path
          d="M316.482 179C328.847 104.814 316.607 30.8979 316.482 30.16L315.582 30.3125C315.707 31.049 327.92 104.819 315.582 178.85L316.482 179Z"
          fill="#3F3D56"
        />
        <path d="M292.505 62.9488L292.075 63.754L320.04 78.7121L320.47 77.9069L292.505 62.9488Z" fill="#3F3D56" />
        <path d="M349.553 70.442L321.592 85.4073L322.023 86.2124L349.984 71.2471L349.553 70.442Z" fill="#3F3D56" />
        <path
          d="M234.111 40.2885L237.766 37.365C234.927 37.0518 233.76 38.6004 233.282 39.8262C231.064 38.905 228.649 40.1123 228.649 40.1123L235.962 42.7673C235.593 41.7818 234.951 40.922 234.111 40.2885Z"
          fill="#3F3D56"
        />
        <path
          d="M144.772 14.4793L148.427 11.5558C145.587 11.2426 144.421 12.7912 143.943 14.017C141.725 13.0958 139.31 14.3031 139.31 14.3031L146.623 16.9581C146.254 15.9726 145.612 15.1128 144.772 14.4793Z"
          fill="#3F3D56"
        />
        <path
          d="M290.494 2.96445L294.15 0.0409421C291.31 -0.272342 290.143 1.27632 289.666 2.50209C287.447 1.5809 285.032 2.78816 285.032 2.78816L292.346 5.44322C291.977 4.45775 291.335 3.59788 290.494 2.96445Z"
          fill="#3F3D56"
        />
        <path
          d="M297.875 173.538C296.903 173.538 295.948 173.794 295.107 174.28C294.253 173.508 293.193 173 292.055 172.818C290.918 172.637 289.753 172.789 288.7 173.257C287.648 173.725 286.754 174.488 286.127 175.454C285.5 176.421 285.167 177.548 285.168 178.7H303.413C303.314 177.298 302.687 175.987 301.66 175.029C300.632 174.071 299.279 173.539 297.875 173.538Z"
          fill="#3F3D56"
        />
        <path
          d="M112.445 173.538C111.474 173.538 110.519 173.794 109.678 174.28C108.824 173.508 107.764 173 106.626 172.818C105.489 172.637 104.323 172.789 103.271 173.257C102.219 173.725 101.325 174.488 100.698 175.454C100.071 176.421 99.7379 177.548 99.7393 178.7H117.984C117.885 177.298 117.258 175.987 116.23 175.029C115.203 174.071 113.85 173.539 112.445 173.538Z"
          fill="#3F3D56"
        />
        <path
          d="M207.344 173.538C206.372 173.538 205.418 173.794 204.576 174.28C203.722 173.508 202.662 173 201.525 172.818C200.387 172.637 199.222 172.789 198.169 173.257C197.117 173.725 196.223 174.488 195.596 175.454C194.969 176.421 194.636 177.548 194.638 178.7H212.883C212.783 177.298 212.157 175.987 211.129 175.029C210.101 174.071 208.749 173.539 207.344 173.538Z"
          fill="#3F3D56"
        />
        <path
          d="M187.319 160.505H134.681V162.202H142.89V178.334H144.588V162.202H176.562V178.334H178.26V162.202H187.319V160.505Z"
          fill="#3F3D56"
        />
        <path d="M187.422 155.425H134.784V157.123H187.422V155.425Z" fill="#3F3D56" />
        <path d="M187.422 151.18H134.784V152.878H187.422V151.18Z" fill="#3F3D56" />
        <path d="M187.422 146.935H134.784V148.633H187.422V146.935Z" fill="#3F3D56" />
        <path
          d="M269.511 160.505H216.873V162.202H225.082V178.334H226.78V162.202H258.755V178.334H260.453V162.202H269.511V160.505Z"
          fill="#3F3D56"
        />
        <path d="M269.615 155.425H216.977V157.123H269.615V155.425Z" fill="#3F3D56" />
        <path d="M269.615 151.18H216.977V152.878H269.615V151.18Z" fill="#3F3D56" />
        <path d="M269.615 146.935H216.977V148.633H269.615V146.935Z" fill="#3F3D56" />
      </g>
      <defs>
        <clipPath id="clip0_11597_80926">
          <rect width="396.215" height="179" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
