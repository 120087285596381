export function maybeAddIdColumn<T>(column: T): T[] {
  return window.sessionStorage.getItem("showIdColumn") === "true" ? [column] : [];
}

export function showIdColumn() {
  window.sessionStorage.setItem("showIdColumn", "true");
}

export function hideIdColumn() {
  window.sessionStorage.setItem("showIdColumn", "false");
}
