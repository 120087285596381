import {
  actionColumn,
  Checkbox,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  numericColumn,
  RowStyles,
  ScrollableContent,
  singleFilter,
  useComputed,
  useTestIds,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useState } from "react";
import { priceCell, SearchBox } from "src/components";
import { Stage } from "src/generated/graphql-types";
import { DevelopmentCommitmentPotentialLineItem } from "src/routes/development-commitments/models/DevelopmentCommitmentPotentialLineItem";
import {
  DevelopmentCommitmentPotentialLineItemStore,
  ObservableCostCodeGroup,
} from "src/routes/development-commitments/models/DevelopmentCommitmentPotentialLineItemStore";
import { TableActions } from "src/routes/layout/TableActions";
import { costTypeToNameMapper } from "src/utils";

export type DevelopmentCommitmentPotentialLineItemTableProps = {
  stage: Stage;
  store: DevelopmentCommitmentPotentialLineItemStore;
};

export function DevelopmentCommitmentPotentialLineItemTable(props: DevelopmentCommitmentPotentialLineItemTableProps) {
  const { stage, store } = props;
  const testIds = useTestIds({}, "developmentCommitmentPotentialLineItemTable");

  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const columns = createColumns();

  const filterDefs = useComputed(() => createFilterDefs(stage, store), [stage, store]);
  // Wasn't sure if there was a non persisting filter
  const [filter, setFilter] = useState<Filter>({});

  const rows = useComputed(() => createRows(store, filter), [store, filter]);

  return (
    <div {...testIds}>
      <div css={Css.df.mb2.$}>
        <h2 css={Css.baseMd.$}>Line Items</h2>
        <Observer>
          {() => {
            const selectedItems = store.selectedLineItems;
            if (!selectedItems.length) {
              return null;
            }

            const projectItemCount = store.selectedLineItems.flatMap((li) => li.projectIds).length;
            return (
              <div css={Css.mla.xs.$}>
                {projectItemCount} project item{projectItemCount === 1 ? "" : "s"} selected
              </div>
            );
          }}
        </Observer>
      </div>
      <TableActions>
        <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={rows}
          rowStyles={rowStyles}
          style={{ grouped: true }}
          filter={searchFilter}
          stickyHeader
          fallbackMessage="No Line Items found for the selected projects."
          sorting={{ on: "client", initial: ["costCode", "DESC"] }}
        />
      </ScrollableContent>
    </div>
  );
}

const rowStyles: RowStyles<Row> = {
  header: { cellCss: { ...Css.bgGray200.$ } },
  group: { cellCss: { ...Css.bgGray100.$ } },
};

type Row =
  | { kind: "header"; data: DevelopmentCommitmentPotentialLineItemStore; id: string }
  | { kind: "group"; data: ObservableCostCodeGroup; id: string }
  | { kind: "lineItem"; data: DevelopmentCommitmentPotentialLineItem; id: string };

type Filter = {
  view?: "selections";
  locationId?: string[];
};

function createRows(store: DevelopmentCommitmentPotentialLineItemStore, filter: Filter): GridDataRow<Row>[] {
  const omittedLineItemIds: string[] = [];
  if (filter.locationId) {
    store.items.forEach((li) => {
      if (!li.locationObj?.id || !filter.locationId!.includes(li.locationObj?.id)) {
        omittedLineItemIds.push(li.id);
      }
    });
  }
  if (filter.view === "selections") {
    store.items.forEach((li) => {
      if (!li.isSelection) {
        omittedLineItemIds.push(li.id);
      }
    });
  }

  return [
    { kind: "header", id: "header", data: store },
    ...store.children
      .filter((group) => !group.children.every(({ id }) => omittedLineItemIds.includes(id)))
      .map((group) => ({
        kind: "group" as const,
        id: group.id,
        data: group,
        children: group.children
          .filter(({ id }) => !omittedLineItemIds.includes(id))
          .map((li) => ({ kind: "lineItem" as const, id: li.id, data: li })),
      })),
  ];
}

function createColumns(): GridColumn<Row>[] {
  return [
    actionColumn<Row>({
      clientSideSort: false,
      header: (data, { row }) => <CollapseToggle row={row} />,
      group: (data, { row }) => <CollapseToggle row={row} />,
      lineItem: emptyCell,
      w: "32px",
    }),
    actionColumn<Row>({
      clientSideSort: false,
      header: (row) => (
        <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
      ),
      group: (row) => (
        <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
      ),
      lineItem: (row) => (
        <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
      ),
      w: "32px",
    }),
    column<Row>({
      id: "costCode",
      header: "Cost Code",
      group: (row) => row.name,
      lineItem: (row) => row.name,
    }),
    column<Row>({
      header: "Location",
      group: " ",
      lineItem: (row) => row.location,
    }),
    column<Row>({
      header: "Cost Type",
      group: " ",
      lineItem: (row) => row.costType && costTypeToNameMapper[row.costType],
    }),
    column<Row>({
      header: "# of Projects",
      group: (row) => {
        const projectIds = row.children.reduce((arr: string[], c) => arr.concat(c.projectIds), []);
        return `${[...new Set(projectIds)].length} Projects`;
      },
      lineItem: (row) => `${row.projectIds.length} Projects`,
    }),
    numericColumn<Row>({
      header: () => "Cost",
      group: (row) => priceCell({ valueInCents: row.totalCostInCents }),
      lineItem: (row) => priceCell({ valueInCents: row.totalCostInCents }),
    }),
  ];
}

function createFilterDefs(stage: Stage, store: DevelopmentCommitmentPotentialLineItemStore): FilterDefs<Filter> {
  const allLocations = store.items.map(({ locationObj }) => locationObj).filter((l) => !!l);
  const locations = [...new Map(allLocations.map((location) => [location.id, location])).values()];

  return {
    // The precon stage doesn't have selections, so hide `View: Selections`
    ...(stage === Stage.Construction
      ? {
          view: singleFilter({
            options: [{ id: "selections" as const, name: "Selections" }],
            getOptionValue: (o) => o.id,
            getOptionLabel: (o) => o.name,
          }),
        }
      : {}),
    locationId: multiFilter({
      options: locations,
      getOptionValue: (location) => location!.id,
      getOptionLabel: (location) => location!.name,
    }),
  };
}
