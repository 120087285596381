import { useComputed, useModal } from "@homebound/beam";
import { AddLineItemButton } from "src/components";
import { InvoiceEditorInvoiceFragment, Maybe, useDrawLineItemsQuery } from "src/generated/graphql-types";
import { AddInvoiceDrawModal } from "src/routes/projects/invoices/AddInvoiceDrawModal";
import { InvoiceFormState } from "src/routes/projects/invoices/InvoiceEditor";
import { hasData } from "src/utils";

type AddDrawLineItemButtonProps = {
  lineItems: InvoiceFormState["drawLineItems"];
  projectId: string;
  projectStageId: Maybe<string>;
  invoice: InvoiceEditorInvoiceFragment | undefined;
  invoiceV2: boolean;
};

export function AddDrawLineItemButton(props: AddDrawLineItemButtonProps) {
  const { lineItems, projectId, projectStageId, invoice, invoiceV2 } = props;
  const disabled = !projectStageId;
  const query = useDrawLineItemsQuery({
    variables: { projectId, projectStageId: projectStageId! },
    skip: disabled,
  });
  const { openModal } = useModal();

  const allContractDraws = query.data?.project.stages[0]?.allContractDraws;

  // And what draws have not yet been added to an invoice
  const availableDraws = useComputed(() => {
    return allContractDraws?.filter((d) => {
      // We can't use just HomeownerContractDraw.isDrawn b/c it might become false
      // if the user is editing the invoice it was drawn on. So we distinguish between
      // "draw on a different invoice" (won't change) or "drawn on this invoice" (might change).
      const drawInvoiceId = d.invoiceDrawLineItem?.invoice.id;
      const drawOnOtherInvoice = drawInvoiceId !== undefined && drawInvoiceId !== invoice?.id;
      const drawOnMe = lineItems.rows.filter((r) => !r.delete.value).some((li) => li.drawId.value === d.id);
      return !drawOnOtherInvoice && !drawOnMe;
    });
  }, [allContractDraws, lineItems]);

  return (
    <AddLineItemButton
      id="addDrawLineItem"
      label="Draw Line Item"
      list={lineItems}
      onClick={() =>
        openModal({
          content: (
            <AddInvoiceDrawModal
              // `availableDraws` should be populated due to the `disabled` logic for the button.
              draws={availableDraws ?? []}
              onAdd={(draw) => {
                const existing = lineItems.rows.find((li) => li.drawId.value === draw.id);
                if (existing) {
                  existing.delete.value = false;
                } else {
                  lineItems.add({
                    id: undefined, // this is the new draw line item id
                    drawId: draw.id,
                    description: draw.description,
                    amountInCents: draw.amountInCents,
                    taskId: draw.task.id,
                    contractId: draw.parent.id,
                  });
                }
              }}
            />
          ),
        })
      }
      disabled={invoiceV2 || disabled || !hasData(query) || (availableDraws && availableDraws.length === 0)}
    />
  );
}
