import { Maybe } from "src/generated/graphql-types";
import { trimNumber } from ".";

export type BudgetLineItemMarkup = {
  markupPercentage: number;
  markupBasisPoints: number;
  markupAmount: number;
};

export function calculateMarkup(budgetInCents: Maybe<number>, priceInCents: Maybe<number>): BudgetLineItemMarkup {
  priceInCents = priceInCents || 0;
  budgetInCents = budgetInCents || 0;

  const markupAmount = priceInCents - budgetInCents;

  const markupPercentage = budgetInCents === 0 ? 0 : trimNumber((markupAmount / budgetInCents) * 100);
  return {
    markupPercentage,
    markupBasisPoints: Math.round(markupPercentage * 100),
    markupAmount,
  };
}
