import { BoundTextAreaField, Css, FormHeading, FormLines, ScrollableContent } from "@homebound/beam";
import { Observer } from "mobx-react";
import { CommentFeed, ContractTypeField, HeaderBar } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { FormFileUploaderSection } from "src/components/contracts/FormFileUploaderSection";
import { FormActions, FormMode } from "src/components/formFields";
import {
  DocumentEditorDetailFragment,
  DocumentType,
  HomeownerContractChangeOrderWithProjectItemsFragment,
  HomeownerContractChangeOrder_ChangeEventFragment,
  HomeownerContractWithProjectItemsFragment,
  SaveHomeownerContractChangeOrderInput,
  SaveHomeownerContractInput,
  Stage,
} from "src/generated/graphql-types";
import { costPlusMarkupRule } from "src/routes/projects/homeowner-contracts/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { ChangeEventLink } from "../commitments/CommitmentChangeOrderEditor";
import { LineItemsTable } from "./LineItemsTable";

export type HomeownerContractEditorProps = {
  projectId: string;
  contract: HomeownerContractWithProjectItemsFragment | HomeownerContractChangeOrderWithProjectItemsFragment;
  changeEvent?: HomeownerContractChangeOrder_ChangeEventFragment | null;
  mode: FormMode;
  stage: Stage;
  onCancel(): void;
  onEdit(): void;
  onSave(formState: SaveHomeownerContractInput | SaveHomeownerContractChangeOrderInput): Promise<unknown>;
};

export function DetailsEditor(props: HomeownerContractEditorProps) {
  const { projectId, contract, changeEvent, mode, onCancel, onEdit, onSave, stage } = props;
  const readOnly = mode === "read";
  const isChangeOrder = contract.__typename === "HomeownerContractChangeOrder";
  const documentType =
    isChangeOrder && stage === Stage.PreConExecution
      ? DocumentType.PreconAgreementChangeOrder
      : isChangeOrder && stage === Stage.Construction
        ? DocumentType.ConstructionAgreementChangeOrder
        : stage === Stage.PreConExecution
          ? DocumentType.PreconAgreement
          : DocumentType.ConstructionAgreement;

  const formState = useFormState({
    config: formConfig,
    init: { input: contract, map: mapToForm },
    readOnly,
    addRules(state) {
      state.costPlusMarkupBasisPoints.rules.push(costPlusMarkupRule(state));
    },
  });

  return (
    <Observer>
      {() => (
        <>
          <HeaderBar
            right={
              <FormActions
                formState={formState}
                onEdit={onEdit}
                onCancel={onCancel}
                mode={mode}
                onSave={async () => await onSave(mapToInput(formState))}
              />
            }
          />
          <ScrollableContent>
            <FormLines width="sm">
              <FormHeading title="Details" />
              {changeEvent && (
                <ChangeEventLink
                  displayName={changeEvent.displayName}
                  projectId={projectId}
                  changeEventId={changeEvent.id}
                />
              )}
              <ContractTypeField
                contractTypeField={formState.contractType}
                costPlusMarkupBasisPointsField={formState.costPlusMarkupBasisPoints}
              />
              <BoundBeamDateField field={formState.executionDate} helperText="The date the contract was executed" />
              <BoundTextAreaField field={formState.internalNote} />
            </FormLines>

            <FormFileUploaderSection
              projectId={projectId}
              documentFormState={formState.document}
              documentType={documentType}
              message={`Upload Executed ${stage === Stage.Construction ? "Construction" : "Pre-Con"} Agreement`}
            />

            <FormHeading title="Line Items" xss={Css.mb2.$} />
            <LineItemsTable contract={contract} />
            <CommentFeed commentable={contract} />
          </ScrollableContent>
        </>
      )}
    </Observer>
  );
}

type HomeownerContractFormState = Omit<
  SaveHomeownerContractInput,
  "projectStageId" | "documentId" | "lineItems" | "executionDate"
> & {
  executionDate: DateOnly | undefined | null;
  document: DocumentEditorDetailFragment | undefined | null;
};

type HomeownerContractChangeOrderFormState = Omit<
  SaveHomeownerContractChangeOrderInput,
  "projectStageId" | "documentId" | "lineItems" | "executionDate"
> & {
  executionDate: DateOnly | undefined | null;
  document: DocumentEditorDetailFragment | undefined | null;
};

const formConfig: ObjectConfig<HomeownerContractFormState | HomeownerContractChangeOrderFormState> = {
  id: { type: "value" },
  executionDate: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  contractType: { type: "value", rules: [required] },
  costPlusMarkupBasisPoints: { type: "value" },
  document: { type: "value", rules: [required] },
};

function mapToForm(
  contract: HomeownerContractWithProjectItemsFragment | HomeownerContractChangeOrderWithProjectItemsFragment,
): HomeownerContractFormState {
  return {
    id: contract.id,
    executionDate: contract.executionDate,
    internalNote: contract.internalNote || undefined,
    contractType: contract.contractType,
    costPlusMarkupBasisPoints: contract.costPlusMarkupBasisPoints,
    document: contract.document,
  };
}

function mapToInput(
  formState: ObjectState<HomeownerContractFormState | HomeownerContractChangeOrderFormState>,
): SaveHomeownerContractInput {
  const { document, ...others } = formState.value;
  return { ...others, documentId: document?.id };
}
