import {
  column,
  dateColumn,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleHeader,
  SimpleHeaderAndData,
  Tag,
  TagType,
} from "@homebound/beam";
import { Fragment } from "react";
import { FormattedDate, priceCell } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import { CommitmentBillFragment, CommitmentEditor_ExpenseAllocationFragment } from "src/generated/graphql-types";
import { createBillUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";

export type CommitmentBillsTableV3Props = {
  bills: CommitmentBillFragment[];
  expenseAllocations: CommitmentEditor_ExpenseAllocationFragment[];
  commitmentId: string;
  projectId: string;
};

export function CommitmentBillsTableV3(props: CommitmentBillsTableV3Props) {
  const { bills, expenseAllocations, commitmentId, projectId } = props;

  return (
    <Fragment>
      <GridTable
        rowStyles={createRowStyles(projectId, commitmentId)}
        columns={createColumns()}
        rows={createRows(bills, expenseAllocations)}
        fallbackMessage="Add bills after this commitment has been signed."
      />
    </Fragment>
  );
}

type RowData = {
  isExpense: boolean;
  entityNumber: string;
  approvalStatus: [TagType, string];
  entityDate: DateOnly;
  dueDate: DateOnly | undefined;
  billedInCents: number;
  paidInCents: number;
  balanceInCents?: number;
  paidDate?: DateOnly;
};
type Row = SimpleHeaderAndData<RowData>;

function createRowStyles(projectId: string, commitmentId: string): RowStyles<Row> {
  return {
    header: {},
    data: {
      rowLink: (row) => (!row.data.isExpense ? createBillUrl(row.id) : "#"),
    },
  };
}

function createRows(
  bills: CommitmentBillFragment[],
  expenseAllocations: CommitmentEditor_ExpenseAllocationFragment[],
): GridDataRow<Row>[] {
  const groupedByExpense = expenseAllocations.groupBy((ea) => ea.expense.id);
  const expenseRows = Object.values(groupedByExpense).map((group) => {
    const expense = group.first!.expense;
    return {
      id: expense.id,
      kind: "data" as const,
      data: {
        isExpense: true,
        entityNumber: `${expense.id} - ${expense.intacctApBillId}`,
        dueDate: expense.dueDate,
        entityDate: expense.expenseDate,
        approvalStatus: expense.tradeNumber ? `${expense.tradeNumber} - ${expense.description}` : expense.description,
        billedInCents: group.sum((ea) => ea.amountInCents),
        paidInCents: group.sum((ea) => ea.paidAmountInCents),
      },
    };
  }) as GridDataRow<Row>[];

  const billRows = bills.map((bill) => ({
    id: bill.id,
    kind: "data" as const,
    data: {
      isExpense: false,
      entityNumber: bill.isTradePartnerCredit ? `Credit ${bill.tradePartnerNumber}` : `${bill.tradePartnerNumber}`,
      dueDate: bill.dueDate,
      entityDate: bill.billDate,
      approvalStatus: billStatusTagMapper(bill),
      billedInCents: bill.isTradePartnerCredit ? -bill.billedInCents : bill.billedInCents,
      paidInCents: bill.isTradePartnerCredit ? -bill.paidInCents : bill.paidInCents,
      balanceInCents: bill.isTradePartnerCredit ? -bill.balanceInCents : bill.balanceInCents,
      paidDate: bill.paidDate,
    },
  })) as GridDataRow<Row>[];

  return [simpleHeader, ...billRows, ...expenseRows];
}

function createColumns(): GridColumn<Row>[] {
  const billNumberColumn = column<Row>({
    header: () => "Bill #",
    data: (row) => row.entityNumber,
  });

  const approvalsColumn = column<Row>({
    header: () => "Approval",
    data: ({ approvalStatus }) =>
      // This could be either a string for expenses or a tag mapper for bills
      Array.isArray(approvalStatus) ? <Tag type={approvalStatus[0]} text={approvalStatus[1]} /> : approvalStatus,
    w: 3,
  });

  const billDateColumn = dateColumn<Row>({
    header: () => "Bill Date",
    data: (row) => <FormattedDate date={row.entityDate} />,
  });

  const dueDateColumn = dateColumn<Row>({
    header: () => "Due Date",
    data: (row) => row.dueDate && <FormattedDate date={row.dueDate} />,
  });

  const billedColumn = numericColumn<Row>({
    header: () => "Billed",
    data: (row) => priceCell({ valueInCents: row.billedInCents }),
  });

  const paidToDateColumn = numericColumn<Row>({
    header: () => "Paid to Date",
    data: (row) => priceCell({ valueInCents: row.paidInCents }),
  });

  const balanceDueColumn = numericColumn<Row>({
    header: () => "Balance Due",
    data: (row) => (row.isExpense ? emptyCell : priceCell({ valueInCents: row.balanceInCents })),
  });

  const paidDateColumn = dateColumn<Row>({
    header: () => "Paid Date",
    data: (row) => row.paidDate && <FormattedDate date={row.paidDate} />,
  });

  return [
    billNumberColumn,
    approvalsColumn,
    billDateColumn,
    dueDateColumn,
    billedColumn,
    paidToDateColumn,
    balanceDueColumn,
    paidDateColumn,
  ];
}
