import {
  BoundDateField,
  BoundRadioGroupField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  FormHeading,
  FormLines,
  ScrollableContent,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { useParams } from "react-router";
import {
  LotType,
  LotUnitSalesStatus,
  Maybe,
  ProjectInput,
  PublicationStatus,
  SaveLotUnitInput,
  SetUpTabProjectFragment,
  SetUpTabQuery,
  useSaveProjectSetUpTabMutation,
  useSetUpTabQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";

export function SetUpTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useSetUpTabQuery({ variables: { projectId } });

  return queryResult(query, {
    data: (data) => <SetUpTabDataView data={data} />,
  });
}

type SetUpTabDataViewProps = {
  data: SetUpTabQuery;
};

export function SetUpTabDataView({ data }: SetUpTabDataViewProps) {
  const { project, lotUnitSalesStatuses, productTypes, preconContractTypes, lotRatings } = data;
  const { lotUnit } = project;

  const undecidedOption = { id: undefined, name: "Undecided" };
  const undecidedLotRating = { id: undefined, grade: "Incomplete", description: "" };

  const [saveProjectSetUpTab] = useSaveProjectSetUpTabMutation();

  const formState = useFormState({
    config: formConfig,
    init: { input: project, map: mapToFormState },
    autoSave: async ({ changedValue }) => {
      const projectInput = mapFormToInput(changedValue, project.lotUnit?.id);
      await saveProjectSetUpTab({ variables: { input: projectInput } });
      formState.commitChanges();
    },
  });

  return (
    <ScrollableContent>
      <FormLines width="sm">
        <FormHeading title="Project Types" />
        {/* BoundRadioGroupField doesn't accept boolean values */}
        <BoundRadioGroupField
          label="Disaster Recovery"
          field={formState.isDisaster}
          options={[
            { value: "disaster", label: "Disaster" },
            { value: "nonDisaster", label: "Non-disaster" },
          ]}
        />
        <BoundRadioGroupField
          label="Lot Type"
          field={formState.lotType}
          options={data.enumDetails.lotType.map((lotType) => ({
            value: lotType.code,
            label: lotType.description ? `${lotType.description} (${lotType.code})` : lotType.code,
          }))}
        />
        <BoundSelectField
          label="Lot Unit Published Status"
          field={formState.publicationStatus}
          options={[
            { value: PublicationStatus.Unpublished, label: "Unpublished" },
            { value: PublicationStatus.Published, label: "Published" },
          ]}
          disabled={!lotUnit || formState.lotType.value !== LotType.Bool}
          helperText={!lotUnit ? "Publication status cannot be set without a lot unit" : ""}
          getOptionLabel={({ label }) => label}
          getOptionValue={({ value }) => value}
        />
        <BoundSelectField
          label="Sales Status for BOOL"
          field={formState.lotUnitSalesStatus}
          disabled={!lotUnit || formState.lotType.value !== LotType.Bool}
          helperText={!lotUnit ? "Sales status cannot be set without a lot unit" : ""}
          options={lotUnitSalesStatuses}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ code }) => code}
        />
        <BoundTextField
          field={formState.mlsLink}
          disabled={!lotUnit || formState.lotType.value !== LotType.Bool}
          label="MLS Link"
          helperText={!lotUnit ? "MLS link cannot be set without a lot unit" : ""}
        />
        <BoundTextField
          field={formState.apnNumber}
          disabled={!lotUnit || formState.lotType.value !== LotType.Bool}
          label="APN Number"
          helperText={!lotUnit ? "APN Number cannot be set without a lot unit" : ""}
        />
        <BoundTextField
          field={formState.cityGisSite}
          disabled={!lotUnit || formState.lotType.value !== LotType.Bool}
          label="City GIS Site"
          helperText={!lotUnit ? "City GIS site cannot be set without a lot unit" : ""}
        />
        <BoundDateField
          field={formState.lotUnitMoveInDate}
          disabled={!lotUnit || formState.lotType.value !== LotType.Bool}
          label="Move In Date"
          helperText={!lotUnit ? "Move in date is construction end date without a lot unit" : ""}
        />
        <BoundSelectField
          label="Product Type"
          field={formState.productTypeId}
          options={[undecidedOption, ...productTypes]}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id!}
        />
        <BoundSelectField
          label="Pre-Con Contract Type"
          field={formState.preconContractTypeId}
          options={[undecidedOption, ...preconContractTypes]}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id!}
        />
        <BoundSelectField
          label="Lot Rating"
          field={formState.lotRatingId}
          options={[undecidedLotRating, ...lotRatings]}
          getOptionLabel={({ grade, description }) => `${grade}${description && " - "}${description}`}
          getOptionValue={({ id }) => id!}
        />
        <BoundTextField label="Checkout Customer Configuration ID" field={formState.checkoutCustomerConfigId} />
        <FormHeading title="Design Options" />
        <BoundTextAreaField label="Initial intake style notes" field={formState.intakeStyleNotes} />
      </FormLines>
    </ScrollableContent>
  );
}
type FormValue = Pick<
  ProjectInput,
  | "id"
  | "lotType"
  | "productTypeId"
  | "preconContractTypeId"
  | "lotRatingId"
  | "intakeStyleNotes"
  | "checkoutCustomerConfigId"
> &
  Pick<SaveLotUnitInput, "mlsLink" | "apnNumber" | "cityGisSite" | "publicationStatus"> & {
    lotUnitSalesStatus?: Maybe<LotUnitSalesStatus>;
    isDisaster?: Maybe<string>;
    lotUnitMoveInDate?: Date | null | undefined;
  };

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  isDisaster: { type: "value" },
  lotType: { type: "value" },
  productTypeId: { type: "value" },
  preconContractTypeId: { type: "value" },
  lotRatingId: { type: "value" },
  intakeStyleNotes: { type: "value" },
  lotUnitSalesStatus: { type: "value" },
  mlsLink: { type: "value" },
  apnNumber: { type: "value" },
  cityGisSite: { type: "value" },
  lotUnitMoveInDate: { type: "value" },
  publicationStatus: { type: "value" },
  checkoutCustomerConfigId: { type: "value" },
};

function mapToFormState(project: SetUpTabProjectFragment): FormValue {
  if (!project) return {} as FormValue;

  const {
    id,
    isDisaster,
    lotType,
    productType,
    preconContractType,
    lotRating,
    intakeStyleNotes,
    lotUnit,
    checkoutCustomerConfigId,
  } = project;

  return {
    id,
    isDisaster: isDisaster ? "disaster" : "nonDisaster",
    lotType: lotType?.code,
    productTypeId: productType?.id,
    preconContractTypeId: preconContractType?.id,
    lotRatingId: lotRating?.id,
    intakeStyleNotes,
    lotUnitSalesStatus: lotUnit?.lotUnitSalesStatus?.code,
    mlsLink: lotUnit?.mlsLink,
    apnNumber: lotUnit?.apnNumber,
    cityGisSite: lotUnit?.cityGisSite,
    lotUnitMoveInDate: lotUnit?.moveInDate,
    publicationStatus: lotUnit?.publicationStatus?.code,
    checkoutCustomerConfigId,
  };
}

export function mapFormToInput(project: FormValue, lotUnitId?: string): ProjectInput {
  const {
    mlsLink,
    apnNumber,
    cityGisSite,
    publicationStatus,
    lotUnitMoveInDate,
    lotUnitSalesStatus,
    ...projectFields
  } = project;
  const {
    id,
    isDisaster,
    lotType,
    productTypeId,
    preconContractTypeId,
    lotRatingId,
    intakeStyleNotes,
    checkoutCustomerConfigId,
  } = projectFields;

  const lotUnit = {
    id: lotUnitId,
    mlsLink,
    apnNumber,
    cityGisSite,
    publicationStatus,
    moveInDate: lotUnitMoveInDate && new DateOnly(lotUnitMoveInDate),
    lotUnitSalesStatus,
  };

  let projectInput: ProjectInput = {
    id,
    isDisaster: isDisaster === "disaster" ? true : false,
    lotType,
    productTypeId,
    preconContractTypeId,
    lotRatingId,
    intakeStyleNotes,
    checkoutCustomerConfigId,
  };

  if (lotUnitId) {
    projectInput = { ...projectInput, lotUnit };
  }

  return projectInput;
}
