import { Chip, Css, LoadingSkeleton, Tag, useTestIds } from "@homebound/beam";
import { Card } from "src/components/Card";
import { usePlanPackageOptionsListQuery } from "src/generated/graphql-types";
import { SectionTitle } from "src/routes/libraries/plan-package/details/plan-overview/components/SectionTitle";
import { OptionsDataStore } from "src/routes/libraries/plan-package/details/plan-overview/utils";
import { pluralize, queryResult } from "src/utils";
import { StoreApi, UseBoundStore } from "zustand";

type OptionsListProps = {
  planPackageId: string;
  useOptions: UseBoundStore<StoreApi<OptionsDataStore>>;
};

export function OptionsList({ planPackageId, useOptions }: OptionsListProps) {
  const getNumberOfItemsByOption = useOptions((state) => state.getNumberOfItemsByOption);
  const query = usePlanPackageOptionsListQuery({
    variables: { filter: { active: true, readyPlan: [planPackageId], isElevation: false } },
  });
  const tid = useTestIds({}, "optionsList");
  return (
    <>
      <SectionTitle
        title={`Options${query.data?.readyPlanOptions.length ? ` (${query.data?.readyPlanOptions.length})` : ""}`}
      />
      <Card xss={Css.m0.p0.$}>
        {queryResult(query, {
          loading: () => <LoadingSkeleton rows={5} size="lg" />,
          data: ({ readyPlanOptions }) => (
            <ul
              css={
                Css.dg
                  .gtc("auto 1fr max(200px) auto")
                  .cg2.listReset.addIn("> li:not(:last-of-type)", Css.bb.bcGray200.$).$
              }
            >
              {readyPlanOptions.map((option) => {
                const numItems = getNumberOfItemsByOption(option.id);
                return (
                  <li key={option.id} css={Css.dg.gc("span 4").gtc("subgrid").px3.py2.base.$}>
                    <div css={Css.fs0.$} {...tid.code}>
                      {option.code}
                    </div>
                    <div css={Css.fg1.baseSb.lineClamp1.$} {...tid.name} title={option.name}>
                      {option.name}
                    </div>
                    <div css={Css.df.aic.gap1.$}>
                      <Chip {...tid.type} text={option.type.name} compact title={option.type.name} />
                      {option.location && (
                        <Chip
                          {...tid.location}
                          icon="map"
                          text={option.location.name}
                          compact
                          title={option.location.name}
                        />
                      )}
                    </div>
                    <div css={Css.df.aic.gap1.fs0.jcfe.$}>
                      <Tag {...tid.itemCount} text={`${numItems} ${pluralize(numItems, "item")}`} type="warning" />
                    </div>
                  </li>
                );
              })}
            </ul>
          ),
        })}
      </Card>
    </>
  );
}
