import {
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Css,
  FormHeading,
  FormLines,
  ScrollableContent,
  StaticField,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { CommentFeed, Divider, FormActions, HeaderBar } from "src/components";
import {
  ChangeEventStatus,
  ChangeEventType,
  HomeownerSelectionStatus,
  OverviewTabChangeEventFragment,
  SaveChangeEventInput,
  useChangeEventOverviewTabQuery,
  useSaveChangeEventOverviewTabMutation,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks/useModeParam";
import { NotFound } from "src/routes/NotFound";
import { ChangeEventAssociationsTable } from "src/routes/projects/change-events/ChangeEventAssociationsTable";
import { EraseChangeEventPricesModal } from "src/routes/projects/change-events/components/EraseChangeEventPricesModal";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ChangeEventParams, ProjectParams } from "src/routes/routesDef";
import { createChangeEventsUrl, createChangeEventUrl } from "src/RouteUrls";
import { changeEventTypeToNameMapper, hasData, renderLoadingOrError, safeEntries } from "src/utils";
import { filterArchivedUnlessSelected } from "src/utils/changeEventReasons";
import { entityNotFoundError } from "src/utils/error";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { isContractualStage } from "src/utils/projects";

export function ChangeEventOverviewTab() {
  const { projectId, changeEventId } = useParams<ProjectParams & ChangeEventParams>();
  const { mode, onEdit, onCancel, onSave } = useModeParam({
    listPageUrl: createChangeEventsUrl(projectId),
  });
  const { clientNoun } = useProjectContext();

  const query = useChangeEventOverviewTabQuery({ variables: { id: changeEventId } });
  const [saveChangeEvent] = useSaveChangeEventOverviewTabMutation({ onCompleted: () => onSave() });

  const changeEvent = query.data?.changeEvent;
  const changeEventReasons = filterArchivedUnlessSelected(query.data?.changeEventReasons, changeEvent?.reason.id);
  const budgetPhases = query.data?.changeEventBudgetPhases || [];
  const readOnly = mode === "read";

  const formState = useFormState({
    config: formConfig,
    init: { input: changeEvent, map: mapToForm },
    readOnly,
  });
  const { openModal } = useModal();

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  if (!changeEvent) {
    return <NotFound error={entityNotFoundError(changeEventId)} />;
  }

  const type = changeEvent?.type.code;
  const selectionsLineItemsCount = getSelectionsLineItemsCount(changeEvent);
  const publishedLineItemsCount = getPublishedLineItemsCount(changeEvent);
  const finalizedLineItemsCount = getFinalizedLineItemsCount(changeEvent);
  const hasLineItems = changeEvent ? changeEvent.lineItems.length > 0 : false;

  const isAccepted = changeEvent?.status === ChangeEventStatus.Accepted;
  const isVoided = changeEvent?.status === ChangeEventStatus.Void;
  const statusOptions = isAccepted
    ? safeEntries(ChangeEventStatus)
    : safeEntries(ChangeEventStatus).filter(([_, code]) => code !== ChangeEventStatus.Accepted);
  const projectStages = changeEvent!.project.stages;

  return (
    <ScrollableContent>
      <HeaderBar
        right={
          <FormActions
            mode={mode}
            formState={formState}
            onCancel={onCancel}
            onEdit={onEdit}
            editDisableReason={isVoided ? "Cannot edit a voided Change Event" : undefined}
            onSave={async () => {
              // exclude readonly displayTitle from input
              const { displayTitle, ...others } = formState.value;
              const response = await saveChangeEvent({
                variables: {
                  input: {
                    ...others,
                    status: formState.value.status || ChangeEventStatus.Draft,
                    type: formState.value.type,
                  },
                },
              });
              const newId = response.data?.saveChangeEvent.changeEvent.id;
              if (newId) {
                onSave({ url: createChangeEventUrl(projectId, newId) });
              }
            }}
          />
        }
      />

      {/** * details section ***/}
      <FormLines width="sm">
        <FormHeading title="Details" isFirst />
        <BoundTextField field={readOnly ? formState.displayTitle : formState.title} label="Name" />
        <BoundSelectField
          label="Related Contract"
          field={formState.projectStageId}
          options={projectStages.filter((s) => isContractualStage(s.stage.code))}
          getOptionLabel={({ stage }) => stage.name}
          getOptionValue={({ id }) => id}
          readOnly={
            hasLineItems
              ? "Cannot change Related Contract of a Change Event with existing Line Items"
              : isAccepted || readOnly
          }
          disabledOptions={projectStages.filter((ps) => !ps.hasSignedContract).map((ps) => ps.id)}
          helperText="Only signed contracts can be selected."
        />

        {mode === "read" || isAccepted ? (
          <Observer>
            {() => <StaticField label="type" value={`${clientNoun} ${changeEventTypeToNameMapper(type)}`} />}
          </Observer>
        ) : (
          <BoundSelectField
            label="type"
            labelStyle="inline"
            field={formState.type}
            options={Object.entries(ChangeEventType)}
            getOptionValue={([, type]) => type}
            getOptionLabel={([_, type]) => `${clientNoun} ${changeEventTypeToNameMapper(type)}`} // e.g. "Homeowner Chargeable"
            onSelect={(value) => {
              if (value && value === ChangeEventType.Internal && changeEvent && changeEvent.totalPriceInCents > 0) {
                openModal({
                  content: (
                    <EraseChangeEventPricesModal
                      clientNoun={clientNoun}
                      lineItemIds={changeEvent?.lineItems.map(({ id }) => id) || []}
                      onErase={() => formState.type.set(value)}
                    />
                  ),
                });
                return;
              }

              formState.type.set(value);
            }}
          />
        )}

        <BoundSelectField
          field={formState.status}
          options={statusOptions}
          getOptionLabel={([name]) => name}
          getOptionValue={([_, code]) => code}
          readOnly={isAccepted || readOnly}
        />

        <BoundTextAreaField field={formState.internalNote} label="Description" />
        <BoundSelectField label="Reason for Change" field={formState.reasonId} options={changeEventReasons} />
        <BoundTextAreaField field={formState.reasonDetails} label="Reason Details" />
        <BoundSelectField
          field={formState.budgetPhase}
          label="Impacted Budget"
          options={budgetPhases}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.code}
        />

        <FormHeading title={"Homeowner Selections"} />
        <StaticField
          label="Published?"
          value={selectionsLineItemsCount ? `${publishedLineItemsCount}/${selectionsLineItemsCount}` : "N/A"}
        />
        <StaticField
          label="Finalized?"
          value={selectionsLineItemsCount ? `${finalizedLineItemsCount}/${selectionsLineItemsCount}` : "N/A"}
        />

        <FormHeading title="Schedule Impact" />
        <BoundNumberField label="Internal Days" field={formState.internalImpactInDays} type="days" />
        <BoundNumberField label="Homeowner Days" field={formState.homeownerImpactInDays} type="days" />
      </FormLines>
      {changeEvent && mode === "read" && (
        <>
          {/* Only show associations table for Homeowner facing change events */}
          {changeEvent.type.code !== ChangeEventType.Internal && (
            <div css={Css.mt2.$} data-testid="changeEventAssociationsTable">
              <ChangeEventAssociationsTable
                changeEventId={changeEvent.id}
                projectId={projectId}
                stage={changeEvent.projectStage.stage.code}
              />
            </div>
          )}

          <Divider />
          <CommentFeed commentable={changeEvent} />
        </>
      )}
    </ScrollableContent>
  );
}

type FormValue = SaveChangeEventInput & {
  displayTitle?: string | null;
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  displayTitle: { type: "value" },
  title: { type: "value", rules: [required] },
  status: { type: "value" },
  internalNote: { type: "value" },
  internalImpactInDays: { type: "value" },
  homeownerImpactInDays: { type: "value" },
  budgetPhase: { type: "value" },
  type: { type: "value" },
  reasonDetails: { type: "value" },
  reasonId: { type: "value", rules: [required] },
  projectStageId: { type: "value", rules: [required] },
};

function mapToForm(changeEvent: OverviewTabChangeEventFragment): FormValue {
  // Omit line items from input
  const { lineItems, ...others } = changeEvent;
  return {
    ...others,
    projectStageId: changeEvent.projectStage.id,
    displayTitle: `CE #${changeEvent.identifier} - ${changeEvent.title}`,
    reasonId: changeEvent.reason.id,
    // Defaulting to show `0` for impactInDays if undefined. Otherwise the UI looks odd
    internalImpactInDays: changeEvent.internalImpactInDays ?? 0,
    homeownerImpactInDays: changeEvent.homeownerImpactInDays ?? 0,
    budgetPhase: changeEvent.budgetPhase.code,
    type: changeEvent.type.code,
  };
}

function getFinalizedLineItemsCount(changeEvent: OverviewTabChangeEventFragment | undefined) {
  return (
    changeEvent?.lineItems.filter(
      (li) => li.item.isSelection && li.homeownerSelection?.status.code === HomeownerSelectionStatus.Finalized,
    ).length || 0
  );
}
function getPublishedLineItemsCount(changeEvent: OverviewTabChangeEventFragment | undefined) {
  return changeEvent?.lineItems.filter((li) => li.item.isSelection && li.homeownerSelection?.isPublished).length || 0;
}
function getSelectionsLineItemsCount(changeEvent: OverviewTabChangeEventFragment | undefined) {
  return changeEvent?.lineItems.filter((li) => li.item.isSelection).length || 0;
}
