import {
  column,
  dateColumn,
  dateFilter,
  DateFilterValue,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  numericColumn,
  Pagination,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  TagType,
  usePersistedFilter,
} from "@homebound/beam";
import { sentenceCase } from "change-case";
import { useState } from "react";
import { dateCell, priceCell, SearchBox, tagCell } from "src/components";
import {
  DateFilter2,
  FinancesPageSharedQuery,
  InputMaybe,
  InvoiceFilter,
  InvoiceOrder,
  InvoicesPageInvoiceFragment,
  InvoiceStatus,
  Order,
  useInvoicesPageQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { createInvoiceUrl } from "src/RouteUrls";
import { dateFilterOperations, pageSchema, queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { parseOrder, toOrder } from "src/utils/ordering";
import { financesDefaultFilter } from "../billing/FinancesPage";
import { TableActions } from "../layout/TableActions";

type InvoicesPageProps = {
  shared: FinancesPageSharedQuery;
};

type InvoicesPageFilter = Omit<InvoiceFilter, "dueDate"> & { dueDate: DateFilterValue<string> };

export function InvoicesPage({ shared }: InvoicesPageProps) {
  const { markets, projects, internalUsers, currentInternalUser } = shared;
  const currentUserId = currentInternalUser?.id ?? "";
  const [order, setOrder] = useState<InvoiceOrder>({ id: Order.Asc });
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [searchFilter, setSearchFilter] = useState("");
  const userOptions = [{ id: currentUserId, name: "Me" }, ...internalUsers.filter(({ id }) => id !== currentUserId)];

  const filterDefs: FilterDefs<InvoicesPageFilter> = {
    marketId: multiFilter({
      label: "Market",
      options: markets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    assigneeId: multiFilter({
      label: "Approver",
      options: userOptions,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    status: multiFilter({
      label: "Status",
      options: Object.values(InvoiceStatus).map((status) => ({ value: status, name: sentenceCase(status) })),
      getOptionValue: (o) => o.value,
      getOptionLabel: (o) => o.name,
    }),

    projectId: multiFilter({
      label: "Project",
      options: projects,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),

    dueDate: dateFilter({
      label: "Due",
      operations: dateFilterOperations,
      getOperationLabel: (o) => o.label,
      getOperationValue: (o) => o.value,
      defaultValue: financesDefaultFilter.dueDate,
    }),
  };

  const { setFilter, filter } = usePersistedFilter<InvoicesPageFilter>({
    storageKey: "financesInvoiceFilter",
    filterDefs,
  });

  const query = useInvoicesPageQuery({ variables: { filter: mapToFilter(filter), order, page: pageSettings } });
  return queryResult(query, ({ invoicesPage: data }) => (
    <>
      <TableActions>
        <Filters<InvoicesPageFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <ScrollableContent>
        <GridTable
          columns={createColumns()}
          rows={createRows(data.entities)}
          stickyHeader
          rowStyles={createRowStyles()}
          sorting={{
            on: "server",
            onSort: (key, direction) => setOrder(toOrder(key, direction)),
            value: parseOrder(order),
          }}
          style={{ rowHeight: "fixed", allWhite: true, bordered: true }}
          filter={searchFilter}
          fallbackMessage="No Invoices found that matched given filters."
        />
        <Pagination page={[pageSettings, setPageSettings]} totalCount={data.pageInfo.totalCount} />
      </ScrollableContent>
    </>
  ));
}

type Row = SimpleHeaderAndData<InvoicesPageInvoiceFragment>;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Project",
      data: (row) => row.project.name,
    }),
    column<Row>({ header: "Title", data: (row) => row.title }),
    numericColumn<Row>({
      header: "Number",
      data: (row) => row.invoiceNumber,
    }),
    column<Row>({
      header: "Status",
      data: (row) => tagCell(invoiceStatusToTagType[row.status.code], row.status.name),
    }),
    dateColumn<Row>({
      header: "Invoice Date",
      data: (row) => dateCell(row.invoiceDate),
      serverSideSortKey: "invoiceDate",
    }),
    dateColumn<Row>({
      header: "Due Date",
      data: (row) => dateCell(row.dueDate),
      serverSideSortKey: "dueDate",
    }),
    numericColumn<Row>({
      header: "Invoiced",
      data: (row) => priceCell({ valueInCents: row.amountInCents }),
    }),
    numericColumn<Row>({
      header: "Received To Date",
      data: (row) => priceCell({ valueInCents: row.amountPaidInCents }),
    }),
    numericColumn<Row>({
      header: "Balance Owed",
      data: (row) => priceCell({ valueInCents: row.balanceInCents }),
    }),
    dateColumn<Row>({
      header: "Paid Date",
      data: (row) => dateCell(row.paidDate),
      serverSideSortKey: "paidDate",
    }),
  ];
}

function createRowStyles(): RowStyles<Row> {
  return {
    header: {},
    data: {
      rowLink: ({ data, id }) => createInvoiceUrl(data.project.id, id),
    },
  };
}

function createRows(invoices: InvoicesPageInvoiceFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(invoices);
}

export const invoiceStatusToTagType: Record<InvoiceStatus, TagType> = {
  [InvoiceStatus.Draft]: "info",
  [InvoiceStatus.Requested]: "caution",
  [InvoiceStatus.ChangesRequested]: "warning",
  [InvoiceStatus.Approved]: "success",
  [InvoiceStatus.Paid]: "success",
  [InvoiceStatus.Void]: "neutral",
  [InvoiceStatus.Rejected]: "warning",
};

function mapToFilter(filter: InvoicesPageFilter) {
  const { dueDate, ...others } = filter;
  return {
    ...others,
    ...(dueDate
      ? { dueDate: { op: dueDate.op, value: new DateOnly(new Date(dueDate.value)) } as InputMaybe<DateFilter2> }
      : {}),
  };
}
