import { Css } from "@homebound/beam";
import { MarkupSummary } from "src/components/MarkupSummary";

type MarkupHeaderProps = {
  label: string;
  budget: number;
  price: number | undefined;
};

export function MarkupHeader(props: MarkupHeaderProps) {
  const { label, budget, price } = props;
  return (
    <div>
      <div css={Css.tiny.wsnw.tar.$}>{label}</div>
      <div css={Css.sm.mt1.tar.$}>
        <MarkupSummary dropZero {...{ budget, price }} />
      </div>
    </div>
  );
}
