import { Css, Step, useModal, useSnackbar } from "@homebound/beam";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { StepperContextStepper } from "src/components/stepper/index";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

export type StepperContextProps = {
  currentStep: Step;
  goToStep: (value: Step["value"]) => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  /** Go to the next enabled step. */
  nextStep: VoidFunction;
  /** Go to the next step immediately, even if it is disabled. */
  nextStepEvenIfDisabled: VoidFunction;
  prevStep: VoidFunction;
  /** `useState` setter method for `steps` */
  setSteps: Dispatch<SetStateAction<Step[]>>;
  stepActionsEl: HTMLDivElement | null;
  steps: Step[];
};

export const StepperContext = createContext<StepperContextProps>({
  currentStep: {} as Step,
  goToStep: () => {},
  isFirstStep: false,
  isLastStep: false,
  nextStep: () => {},
  nextStepEvenIfDisabled: () => {},
  prevStep: () => {},
  setSteps: () => {},
  stepActionsEl: null,
  steps: [],
});

export function useStepperContext() {
  return useContext(StepperContext);
}

export type StepperProviderProps = {
  currentStep?: Step;
  /** The initial steps state */
  steps: Step[];
  valueAsUrl?: true;
  shouldConfirmStepChange?: boolean;
  /** If true, the stepper will be rendered in a flexbox layout to push the stepper to the bottom, rather than being `position: fix` */
  newLayout?: boolean;
};

export function StepperProvider(props: PropsWithChildren<StepperProviderProps>) {
  const {
    children,
    steps: initSteps,
    currentStep: initCurrentStep,
    valueAsUrl,
    shouldConfirmStepChange,
    newLayout = false,
  } = props;
  const [steps, setSteps] = useState(initSteps);
  const [currentStep, setCurrentStep] = useState(initCurrentStep ?? steps[0]);
  const history = useHistory();
  const { openModal } = useModal();

  const stepActionsEl = useMemo(() => document.createElement("div"), []);
  const stepperActionsRef = useRef<HTMLDivElement>(null);

  // Ensure any Beam Snackbar notices sit above the Stepper.
  const { useSnackbarOffset } = useSnackbar();
  useSnackbarOffset({ bottom: stepperBarHeight + 32 });

  useLayoutEffect(() => {
    stepperActionsRef.current!.appendChild(stepActionsEl as HTMLDivElement);
  }, [stepActionsEl]);

  // Update `steps` in the event the provider is given a new array.
  // It is expected that steps will be typically updated via the provided setter in the context.
  useEffect(() => {
    setSteps(initSteps);
  }, [initSteps]);

  const contextValue: StepperContextProps = useMemo(
    () => {
      const currentStepIdx = steps.findIndex((s) => s.value === currentStep.value);

      function goToStep(v: Step["value"], overrideShouldConfirm: boolean = false) {
        const confirmModalNotBeingOverriden = !overrideShouldConfirm;
        const openConfirmModal = shouldConfirmStepChange && confirmModalNotBeingOverriden;

        if (openConfirmModal) {
          // open confirmation modal
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage="You have unsaved changes that will be lost. Do you want to discard these changes?"
                onConfirmAction={() => {
                  if (valueAsUrl) {
                    history.push(v);
                  }
                  const step = steps.find((s) => s.value === v);
                  step && setCurrentStep(step);
                }}
                title="Discard changes?"
                label="Discard changes"
                danger
              />
            ),
          });
        } else {
          if (valueAsUrl) {
            history.push(v);
          }
          const step = steps.find((s) => s.value === v);
          step && setCurrentStep(step);
        }
      }

      function nextStep(checkDisabled: boolean = false) {
        const next = steps.find((s, ix) => ix > currentStepIdx && (checkDisabled ? !s.disabled : true));
        next && goToStep(next.value, true);
      }

      return {
        goToStep,
        nextStep: () => nextStep(true),
        nextStepEvenIfDisabled: () => nextStep(false),
        prevStep: () => {
          const prev = currentStepIdx >= 0 && steps[currentStepIdx - 1];
          prev && goToStep(prev.value);
        },
        currentStep: steps[currentStepIdx],
        setSteps,
        steps,
        stepActionsEl,
        isLastStep: currentStepIdx === steps.length - 1,
        isFirstStep: currentStepIdx === 0,
      };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCurrentStep, currentStep, steps, valueAsUrl, history, shouldConfirmStepChange],
  );

  return (
    <StepperContext.Provider value={contextValue}>
      {newLayout ? (
        <div css={Css.df.fdc.h100.oh.$}>
          <div css={Css.fg1.h100.oa.df.fdc.$}>{children}</div>
          <div
            css={
              Css.df.jcsb.aic.gap3.bgGray100.px4
                .hPx(stepperBarHeight)
                .oxa.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$
            }
          >
            <StepperContextStepper />
            <div ref={stepperActionsRef} css={Css.addIn("> div", Css.df.aic.gap2.$).$} />
          </div>
        </div>
      ) : (
        <>
          {children}
          <div
            css={
              Css.df.jcsb.aic.gap3.fixed.z999.bottom0.left0.right0.bgGray100.px4
                .hPx(stepperBarHeight)
                .oxa.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$
            }
          >
            <StepperContextStepper />
            <div ref={stepperActionsRef} css={Css.addIn("> div", Css.df.aic.gap2.$).$} />
          </div>
        </>
      )}
    </StepperContext.Provider>
  );
}

export const stepperBarHeight = 100;
