import { useModal, useSnackbar, useSuperDrawer } from "@homebound/beam";
import {
  ApprovalsDashboard_ApproverFragment,
  ApproverStatus,
  CommentStreamVisibility,
  useUpdateApproversMutation,
} from "src/generated/graphql-types";
import { foldEnum, pluralize } from "src/utils";
import { useApprovalContext } from "./ApprovalSuperDrawer";

export function useUpdateApprovers(
  /** Defaults to approvers in the scope of Approval Superdrawer Context, but allows overrides for a custom set of Approvers (e.g. from the Dashboard) */
  overrideApprovers?: ApprovalsDashboard_ApproverFragment[],
  /** Determines the post-save Close effect. Defaults to closing a Superdrawer. */
  closeContainerType: "closeDrawer" | "closeModal" = "closeDrawer",
) {
  const { currentUserId, approvals } = useApprovalContext();
  const { closeDrawer } = useSuperDrawer();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const typeOfFirst = approvals?.first?.subject.__typename;
  const relevantApprovers =
    overrideApprovers ||
    approvals
      // If there happens to be ChangeEventApproval, BillApproval, InvoiceApproval, etc... Pick
      // the typeof first and filter down to only those for bulk reviewing
      ?.filter((a) => a.subject.__typename === typeOfFirst)
      // users act on ApprovERs, not ApprovALs
      .flatMap((a) => a.approvers)
      // only "my" approvals
      .filter((approver) => approver.user.id === currentUserId)
      // which are review-able
      .filter((approver) => [ApproverStatus.Pending, ApproverStatus.ActionRequired].includes(approver.status.code))
      // and don't require signature in panda doc
      .filter((approver) => !approver.requireSignature) ||
    [];

  const [saveApproversMutation] = useUpdateApproversMutation();

  // If currentUser is not a valid Approver then return `null` so components can skip rendering Approve/Request/Reject
  if (relevantApprovers?.isEmpty) return null;

  return async (status: ApproverStatus, html?: string, text?: string): Promise<void> => {
    await saveApproversMutation({
      variables: {
        inputs: relevantApprovers.map((approver) => ({
          id: approver.id,
          status,
          comment:
            html || text
              ? { text, html, streamVisibility: CommentStreamVisibility.Internal, commentableId: approver.approval.id }
              : undefined,
        })),
      },
    });

    const message = successSnackbarMessage(status, relevantApprovers);
    message && triggerNotice({ message });

    closeContainerType === "closeDrawer" ? closeDrawer() : closeModal();
  };
}

function successSnackbarMessage(status: ApproverStatus, approvals: unknown[]) {
  return foldEnum(status, {
    APPROVED: () => <>{xApprovalRequests(approvals)} successfully approved</>,
    CHANGES_REQUESTED: () => <>Changes requested for {xApprovalRequests(approvals)} successfully requested</>,
    REJECTED: () => <>{xApprovalRequests(approvals)} successfully rejected</>,
    else: () => "",
  });
}

function xApprovalRequests(approvals: unknown[]) {
  return (
    <strong>
      {approvals.length} approval {pluralize(approvals, "request")}
    </strong>
  );
}
