import { ItivOrderField } from "src/generated/graphql-types";
import { HasIdAndName } from "src/utils";

export function takeoffItemTableGroupByOptions(): HasIdAndName[] {
  return [
    { id: ItivOrderField.Options, name: "Option" },
    { id: ItivOrderField.CostCode, name: "Cost Code" },
    { id: ItivOrderField.CostDivision, name: "Cost Division" },
    { id: ItivOrderField.LocationLevel, name: "Level" },
    { id: ItivOrderField.LocationRoom, name: "Room" },
    { id: ItivOrderField.LocationFeature, name: "Feature" },
    { id: ItivOrderField.GlobalPlanTask, name: "Task" },
  ];
}

export function isItivOrderField(value: string | undefined): value is ItivOrderField {
  return value !== undefined && Object.values(ItivOrderField).includes(value as ItivOrderField);
}

export function takeoffItemTableSortByOptions(): HasIdAndName[] {
  return [
    { id: ItivOrderField.Item, name: "Item code" },
    // TODO: SC-50350 - Add sortBy support for RecentlyEdited
    // { id: TakeoffItemTableSortBy.RecentlyEdited, name: "Recently Edited" },
    { id: ItivOrderField.MaterialCode, name: "Material Code" },
    { id: ItivOrderField.Location, name: "Location" },
    // TODO: SC-50392 - Add sortBy support for Task
    // { id: TakeoffItemTableSortBy.Task, name: "Task" },
  ];
}

export const PLAN_FALLBACK_IMG = "/images/plan-package-fallback-cover.png";
