import { ButtonModal, Css, Icon, LoadingSkeleton, Palette, useBreakpoint, useTestIds } from "@homebound/beam";
import { addWeeks, formatDistanceToNow, isEqual, startOfToday } from "date-fns";
import { useMemo } from "react";
import {
  PersonalDashboard_ScheduleTaskFragment,
  SchedulingModalQuery,
  TradePartnerTaskStatus,
  useSchedulingModalQuery,
} from "src/generated/graphql-types";
import { ItemCard } from "src/routes/personal-dashboard/components/ItemCard";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { ActionItemTypes } from "src/routes/personal-dashboard/enums";
import { ActionItemsContainer } from "src/routes/personal-dashboard/pages/action-items/ActionItemsPage";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { nonClosedProjects } from "src/utils/projects";

export function SchedulingModal() {
  const today = new DateOnly(startOfToday());
  const { sm } = useBreakpoint();
  const fourWeeksFromToday = new DateOnly(addWeeks(today, 4));
  const query = useSchedulingModalQuery({
    variables: { currentDate: today, fourWeeksFromToday, projectStatus: nonClosedProjects },
    fetchPolicy: "cache-first",
  });
  return queryResult(query, {
    data: (data: SchedulingModalQuery) => {
      const schedulingTasks = data?.scheduleTasks?.filter(
        (task) =>
          task.tradePartner !== null && task.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsConfirmation,
      );

      const triggerContent = sm ? (
        <span css={Css.xs.gray900.$}>
          <span css={Css.blue700.smBd.$}>{schedulingTasks?.length}</span> Scheduling
        </span>
      ) : (
        <div css={Css.df.aic.gap2.$}>
          <span css={Css.blue700.xl4.$}>{schedulingTasks?.length}</span>
          <span>Scheduling</span>
        </div>
      );

      return (
        <ButtonModal
          content={(close) => <SchedulingModalLayout schedulingTasks={schedulingTasks} today={today} close={close} />}
          trigger={{ label: triggerContent }}
          variant={sm ? "text" : "textSecondary"}
          placement={"right"}
        />
      );
    },
    loading: () => <LoadingSkeleton rows={5} columns={3} />,
  });
}

type SchedulingModalLayoutProps = {
  schedulingTasks: PersonalDashboard_ScheduleTaskFragment[];
  today: DateOnly;
  close: VoidFunction;
};

function SchedulingModalLayout({ schedulingTasks, today, close }: SchedulingModalLayoutProps) {
  const tids = useTestIds({}, "schedulingModalTasks");
  const { openTaskDetailPane, selectedTaskId } = useDashboardFilterContext();
  const schedulingItems = useMemo(
    () => {
      return schedulingTasks.map((task) => (
        <ItemCard
          key={`scheduling_task_${task.id}`}
          project={task.project}
          assignee={null}
          title={task.name}
          onClick={() => {
            openTaskDetailPane(task);
            close();
          }}
          actionType={ActionItemTypes.SCHEDULING}
          disabledBtn={task.id === selectedTaskId}
          duration={
            isEqual(task.interval.startDate, today)
              ? "Upcoming today"
              : `Upcoming in ${formatDistanceToNow(task.interval.startDate)}`
          }
          parent={task.parent}
        />
      ));
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedulingTasks, selectedTaskId],
  );
  return (
    <>
      <div css={Css.df.jcsb.aic.mb3.$} {...tids}>
        <h1 css={Css.xlBd.gray900.$}>Trades Not Scheduled For:</h1>
        <Icon
          icon={"infoCircle"}
          color={Palette.Gray900}
          inc={2}
          tooltip={"Trade Partners not scheduled on your assigned tasks for the next 4 weeks"}
          xss={Css.if(schedulingItems.isEmpty).ml2.$}
        />
      </div>
      <ActionItemsContainer count={schedulingItems.length}>
        {schedulingItems.isEmpty && <EmptySchedulingTasks />}
        {schedulingItems}
      </ActionItemsContainer>
    </>
  );
}

const EmptySchedulingTasks = () => (
  <div css={Css.w100.bgGray100.df.fdc.aic.p2.$}>
    <span css={Css.mb2.$}>Great work!</span>
    <span>No trades need to be scheduled</span>
  </div>
);
