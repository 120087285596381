import {
  BoundNumberField,
  BoundTextAreaField,
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  ModalProps,
  numericColumn,
  RowStyles,
  selectColumn,
  SelectToggle,
  simpleHeader,
  Tooltip,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { priceCell, priceTotal } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import { SearchBox } from "src/components/SearchBox";
import { StatusIndicator } from "src/components/StatusIndicator";
import {
  BulkAddProjectItem_ProjectItemFragment,
  CommitmentEditLineItemWithProjectItemFragment,
  CommitmentEditorDataFragment,
  CommitmentPage_ItemTemplateItemFragment,
  CommitmentProjectItem_ItemFragment,
  CommitmentProjectItemFragment,
  CostType,
  DocumentEditorDetailFragment,
  Maybe,
  SaveCommitmentInput,
  SaveCommitmentLineItemInput,
  Stage,
} from "src/generated/graphql-types";
import { BulkAddProjectItemsModal, CeliGroupByValue } from "src/routes/components/BulkAddProjectItemsModal";
import { TableActions } from "src/routes/layout/TableActions";
import { TruncatedNameListCell } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsTable/TruncatedNameListCell";
import { CommitmentChangeOrderEditorFormState } from "src/routes/projects/commitments/CommitmentChangeOrderEditor";
import { accountingNumberRule, getUnbilledAmounts } from "src/routes/projects/commitments/utils";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { partition, safeEntries, sum } from "src/utils";
import { ObjectConfig, ObjectState, required } from "src/utils/formState";

type CommitmentEditorLineItemsProps = {
  projectId: string;
  projectStageId: string;
  formState: FormState;
  allowNegative?: boolean;
  canEditLineItems?: boolean;
  stage: Stage;
  enableProductConfigPlan: boolean;
};

export function CommitmentEditorLineItems(props: CommitmentEditorLineItemsProps) {
  const {
    projectId,
    projectStageId,
    formState,
    allowNegative = false,
    canEditLineItems,
    stage,
    enableProductConfigPlan,
  } = props;
  const [searchFilter, setSearchFilter] = useState<string>();
  const { openModal } = useModal();
  const rowStyles = useMemo(() => createRowStyles(), []);
  const columns = useComputed(
    () => createLineItemColumns(projectId, formState, allowNegative, canEditLineItems, stage, enableProductConfigPlan),
    [formState.lineItems, formState.readOnly, allowNegative, canEditLineItems],
  );
  const tableApi = useGridTableApi<Row>();
  const selectedLineItems = useComputed(() => {
    return tableApi.getSelectedRows("lineItem").filter((li) => !li.data.billedInCents.value);
  }, [tableApi]);

  return (
    <div css={Css.sm.$}>
      <TableActions onlyRight>
        <SearchBox onSearch={setSearchFilter} />
        <Observer>
          {() =>
            formState.readOnly ? null : (
              <>
                <RemoveButton formState={formState} tableApi={tableApi} selectedLineItems={selectedLineItems} />
                <AddLineItemButton
                  canEditLineItems={canEditLineItems}
                  formState={formState}
                  projectId={projectId}
                  projectStageId={projectStageId}
                  openModal={openModal}
                  stage={stage}
                />
              </>
            )
          }
        </Observer>
      </TableActions>
      <GridTable
        stickyHeader
        columns={columns}
        filter={searchFilter}
        rows={createLineItemRows(formState, enableProductConfigPlan)}
        rowStyles={rowStyles}
        api={tableApi}
        style={{ bordered: true, allWhite: true, rowHeight: "fixed" }}
      />
    </div>
  );
}

/** Adds other fields to the line item. */
export type CommitmentEditorLineItem = Omit<SaveCommitmentLineItemInput, "projectItemId"> & {
  billedInCents?: Maybe<number>;
  paidInCents?: Maybe<number>;
  balanceInCents?: Maybe<number>;
  costType: CostType;
  displayName: string;
  unitOfMeasureName: Maybe<string>;
  bidItem?: CommitmentEditLineItemWithProjectItemFragment["bidItem"];
  // Make projectItemId a required field.
  projectItemId: string;
  useQuantity?: boolean;
  location?: string;
  itemCode: string;
  templateName?: string;
  task?: CommitmentProjectItemFragment["task"];
  itemTemplateItem?: CommitmentPage_ItemTemplateItemFragment | null;
};

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: ObjectState<CommitmentEditorLineItem>[] };
type LineItemRow = { kind: "lineItem"; data: ObjectState<CommitmentEditorLineItem> };
type BidItemRow = { kind: "bidItem"; data: ObjectState<CommitmentEditorLineItem> };
type TotalRow = {
  kind: "totals";
  data: {
    costChangeInCents: number;
    balanceInCents: number;
    paidInCents: number;
    billedInCents: number;
  };
};
type Row = HeaderRow | TotalRow | GroupRow | LineItemRow | BidItemRow;
type TotalableFields = "costChangeInCents" | "balanceInCents" | "paidInCents" | "billedInCents";

// Tweak documentId -> document for the form UI
type FormInput = Omit<SaveCommitmentInput, "documentId" | "commitmentType" | "lineItems" | "costTypes"> & {
  lineItems: Maybe<CommitmentEditorLineItem[]>;
  document?: Maybe<DocumentEditorDetailFragment>;
};

type CommitmentFormState = ObjectState<FormInput>;

type FormState = CommitmentFormState | CommitmentChangeOrderEditorFormState;

function sumLineItems(formState: FormState, field: TotalableFields) {
  return (formState.lineItems.rows || [])
    .filter((li) => li.delete.value !== true)
    .map((li) => li[field].value || 0)
    .reduce(sum, 0);
}

function createRowStyles(): RowStyles<Row> {
  return {
    header: { cellCss: Css.bt.bcGray200.$ },
    lineItem: { cellCss: Css.aic.$ },
    totals: { cellCss: Css.aic.$ },
  };
}

function createLineItemRows(formState: FormState, enableProductConfigPlan: boolean): GridDataRow<Row>[] {
  const [hasBidItems, withoutBidItems] = partition(
    formState.lineItems.rows.filter((r) => r.delete.value !== true),
    (r) => !!r.bidItem.value,
  );
  /** Group lines by bid items or cost codes depending on the project config */
  const groupBy = Object.entries(
    enableProductConfigPlan
      ? formState.lineItems.rows.groupBy((li) => li.value.itemTemplateItem?.item.costCode.id ?? "")
      : hasBidItems.filter((r) => r.delete.value !== true).groupBy((li) => li.itemCode.value),
  );

  const totalsRow = {
    kind: "totals" as const,
    id: "totals",
    data: {
      costChangeInCents: sumLineItems(formState, "costChangeInCents"),
      balanceInCents: sumLineItems(formState, "balanceInCents"),
      paidInCents: sumLineItems(formState, "paidInCents"),
      billedInCents: sumLineItems(formState, "billedInCents"),
    },
  };

  return enableProductConfigPlan
    ? [
        totalsRow,
        simpleHeader,
        ...groupBy.map(([id, lineItems]) => ({
          kind: "group" as const,
          id,
          data: lineItems,
          children: lineItems.map((li) => ({
            kind: "lineItem" as const,
            id: `lineItem-${li.value.id ?? li.projectItemId.value}`,
            data: li,
          })),
        })),
      ]
    : [
        totalsRow,
        simpleHeader,
        /** Line items with bid items grouped by item code */
        ...groupBy.map(([id, lineItems]) => ({
          kind: "group" as const,
          id,
          data: lineItems,
          // We can't use `li.id.value` because new rows won't have ids, but `li.projectItemId.value` is always set
          children: lineItems.map((li) => ({
            kind: "bidItem" as const,
            id: `bidItem-${li.value.id ?? li.projectItemId.value}`,
            data: li,
          })),
        })),
        ...withoutBidItems.map((li) => ({
          kind: "lineItem" as const,
          id: `lineItem-${li.value.id ?? li.projectItemId.value}`,
          data: li,
        })),
      ];
}

function createLineItemColumns(
  projectId: string,
  formState: FormState,
  allowNegative: boolean,
  canEditLineItems: boolean | undefined,
  stage: Stage,
  enableProductConfigPlan: boolean,
): GridColumn<Row>[] {
  const readOnly = typeof canEditLineItems !== "undefined" ? !canEditLineItems : canEditLineItems;
  const collapsedColumn = collapseColumn<Row>();
  const selectionColumn = selectColumn<Row>({
    totals: emptyCell,
    lineItem: (data, { row }) => ({ content: () => (data.billedInCents.value ? " " : <SelectToggle id={row.id} />) }),
  });
  const planOrOptionColumn = column<Row>({
    header: "Plan/Option",
    group: (row) => row.first?.templateName.value ?? emptyCell,
    bidItem: emptyCell,
    lineItem: (row) => row.templateName.value ?? emptyCell,
    totals: () => ({ content: "Totals", colspan: 4 }),
    w: "180px",
  });

  const optionColumn = column<Row>({
    header: "Option",
    group: () => emptyCell,
    bidItem: emptyCell,
    lineItem: (row) => {
      const optionsCodes =
        row.itemTemplateItem?.value?.options.map((rpo) => (
          <ArchivedTag key={rpo.id} active={rpo.active}>
            {rpo.code}
          </ArchivedTag>
        )) ?? [];

      return <TruncatedNameListCell nameList={optionsCodes} />;
    },
    totals: emptyCell,
    w: "180px",
  });

  const itemColumn = column<Row>({
    header: "Item",
    group: (row) => row.first?.displayName.value,
    lineItem: (row) => row.displayName.value,
    bidItem: emptyCell,
    totals: "",
    w: "240px",
  });

  const itemCodeColumn = column<Row>({
    header: "Item Code",
    group: (row) => ({
      content: <span css={Css.lgSb.w100.$}>{row.first?.itemTemplateItem?.value?.item.costCode.displayName}</span>,
      colspan: 3,
    }),
    lineItem: (row) => row.itemTemplateItem?.value?.item.fullCode,
    bidItem: emptyCell,
    totals: () => ({ content: "Totals", colspan: 4 }),
    w: "240px",
  });

  const nameColumn = column<Row>({
    header: "Name",
    group: () => emptyCell,
    lineItem: (row) => row.itemTemplateItem?.value?.name,
    bidItem: emptyCell,
    totals: emptyCell,
    w: "240px",
  });

  const bidItemColumn = column<Row>({
    header: "Bid Item",
    group: emptyCell,
    lineItem: emptyCell,
    bidItem: (row) => `${row.bidItem.value?.code} ${row.bidItem.value?.name}`,
    totals: "",
    w: "240px",
  });

  const allocationColumn = column<Row>({
    header: "Allocation",
    group: emptyCell,
    lineItem: (row) => {
      return (
        row.task.value && (
          <div css={Css.df.aic.gap1.$}>
            <StatusIndicator status={row.task.value.status.code} />
            <Link to={createProjectScheduleUrl(projectId, stage, row.task.value.id)}>{row.task.value.name}</Link>
          </div>
        )
      );
    },
    bidItem: emptyCell,
    totals: "",
    w: "240px",
  });

  const internalNoteColumn = column<Row>({
    header: () => "Internal Note",
    group: emptyCell,
    lineItem: (row) => {
      if (row.internalNote.value || !row.readOnly) {
        return (
          <BoundTextAreaField
            field={row.internalNote}
            placeholder="Add Internal Note"
            labelStyle="hidden"
            preventNewLines
          />
        );
      }
    },
    bidItem: (row) => {
      if (row.internalNote.value || !row.readOnly) {
        return (
          <BoundTextAreaField
            field={row.internalNote}
            placeholder="Add Internal Note"
            labelStyle="hidden"
            preventNewLines
          />
        );
      }
    },
    totals: () => emptyCell,
    w: "200px",
  });

  const costColumn = numericColumn<Row>({
    header: "Committed",
    group: emptyCell,
    lineItem: (row) => (
      <BoundNumberField
        field={row.costChangeInCents}
        displayDirection={allowNegative}
        // use allowNegative to force value to be positive
        onChange={(value) => row.costChangeInCents.set(value && !allowNegative ? Math.abs(value) : value)}
        readOnly={readOnly}
      />
    ),
    bidItem: (row) => (
      <BoundNumberField
        field={row.costChangeInCents}
        displayDirection={allowNegative}
        // use allowNegative to force value to be positive
        onChange={(value) => row.costChangeInCents.set(value && !allowNegative ? Math.abs(value) : value)}
        readOnly={readOnly}
      />
    ),
    totals: (row) => priceTotal({ valueInCents: row.costChangeInCents, id: "committedTotal" }),
    w: "140px",
  });

  const billedColumn = numericColumn<Row>({
    header: { content: "Billed", tooltip: "Approved bills" },
    group: emptyCell,
    lineItem: (row) => priceCell({ valueInCents: row.billedInCents.value }),
    bidItem: (row) => priceCell({ valueInCents: row.billedInCents.value }),
    totals: (row) => priceTotal({ valueInCents: row.billedInCents, id: "billed_total" }),
    w: "100px",
  });

  const unBilledColumn = numericColumn<Row>({
    header: "Unbilled",
    group: emptyCell,
    lineItem: (row) =>
      priceCell({ valueInCents: getUnbilledAmounts(row.costChangeInCents.value, row.billedInCents.value) }),
    bidItem: (row) =>
      priceCell({ valueInCents: getUnbilledAmounts(row.costChangeInCents.value, row.billedInCents.value) }),
    totals: (row) =>
      priceTotal({ valueInCents: getUnbilledAmounts(row.costChangeInCents, row.billedInCents), id: "unbilled_total" }),
    w: "120px",
  });

  const paidColumn = numericColumn<Row>({
    header: { content: "Paid to Date", tooltip: "Amount of cash paid against billed" },
    group: emptyCell,
    lineItem: (row) => priceCell({ valueInCents: row.paidInCents.value }),
    bidItem: (row) => priceCell({ valueInCents: row.paidInCents.value }),
    totals: (row) => priceTotal({ valueInCents: row.paidInCents, id: "paid_total" }),
    w: "120px",
  });

  const costTypeColumn = column<Row>({
    header: "Cost Type",
    group: emptyCell,
    lineItem: (row) => {
      const costTypes = safeEntries(CostType);
      const costType = costTypes.find(([, code]) => code === row.costType.value);
      if (costType) {
        return costType[0];
      }
    },
    bidItem: (row) => {
      const costTypes = safeEntries(CostType);
      const costType = costTypes.find(([, code]) => code === row.costType.value);
      if (costType) {
        return costType[0];
      }
    },
    totals: "",
    w: "100px",
  });

  const materialCodeColumn = column<Row>({
    header: "Material Code",
    group: emptyCell,
    lineItem: (row) => {
      const materialVariant = row.itemTemplateItem?.value?.materialVariant;
      const attrs = materialVariant?.materialAttributeValues;
      if (!attrs || attrs.isEmpty) {
        return materialVariant?.code;
      }
      return (
        <Tooltip
          placement="top"
          title={attrs.map(({ textValue, dimension }) => (
            <div key={textValue}>
              {dimension.name}: {textValue} {dimension.unitOfMeasure?.abbreviation}
            </div>
          ))}
        >
          <span css={Css.truncate.$}>{materialVariant?.code}</span>
        </Tooltip>
      );
    },
    bidItem: emptyCell,
    totals: "",
    w: "100px",
  });

  const quantityColumn = numericColumn<Row>({
    header: "Quantity",
    group: emptyCell,
    lineItem: (row) => {
      return !row.useQuantity.value ? (
        "N/A"
      ) : (
        <BoundNumberField
          field={row.quantity}
          labelStyle="hidden"
          displayDirection={allowNegative}
          readOnly={readOnly}
        />
      );
    },
    bidItem: (row) => {
      return !row.useQuantity.value ? (
        "N/A"
      ) : (
        <BoundNumberField
          field={row.quantity}
          labelStyle="hidden"
          displayDirection={allowNegative}
          readOnly={readOnly}
        />
      );
    },
    totals: "",
    w: "100px",
  });

  const unitOfMeasureColumn = column<Row>({
    header: "Unit",
    group: emptyCell,
    lineItem: (row) => row.unitOfMeasureName.value,
    bidItem: (row) => row.unitOfMeasureName.value,
    totals: "",
    w: "120px",
  });

  const locationColumn = column<Row>({
    header: "Location",
    group: emptyCell,
    lineItem: (row) =>
      enableProductConfigPlan ? row.itemTemplateItem?.value?.location.displayLocationPath : row.location.value,
    bidItem: ({ location }) => location.value,
    totals: "",
    w: "140px",
  });

  const pendingColumn = numericColumn<Row>({
    header: { content: "Pending", tooltip: "Approved bills that are not yet paid" },
    group: emptyCell,
    lineItem: (row) => priceCell({ valueInCents: row.balanceInCents.value }),
    bidItem: (row) => priceCell({ valueInCents: row.balanceInCents.value }),
    totals: (row) => priceTotal({ valueInCents: row.balanceInCents, id: "balance_total" }),
    w: "120px",
    ...(enableProductConfigPlan ? {} : { sticky: "right" }),
  });

  // If no line items can be checked or readonly don't show the checkboxes
  const hasSelectableLines =
    formState.lineItems.value.filter((li) => !li.billedInCents).length && !readOnly && !formState.readOnly;
  return [
    collapsedColumn,
    ...(hasSelectableLines ? [selectionColumn] : []),
    ...(enableProductConfigPlan
      ? [itemCodeColumn, nameColumn, optionColumn]
      : [planOrOptionColumn, itemColumn, bidItemColumn]),
    allocationColumn,
    internalNoteColumn,
    locationColumn,
    ...(enableProductConfigPlan ? [materialCodeColumn] : [costTypeColumn]),
    quantityColumn,
    unitOfMeasureColumn,
    costColumn,
    billedColumn,
    unBilledColumn,
    paidColumn,
    pendingColumn,
  ];
}

type ProjectItemToLineItem = Pick<
  BulkAddProjectItem_ProjectItemFragment,
  | "costType"
  | "displayName"
  | "id"
  | "unitOfMeasure"
  | "protoCommitmentLineItemToBuyout"
  | "location"
  | "bidItem"
  | "itemTemplateItem"
> & { item: CommitmentProjectItem_ItemFragment };

export function projectItemToLineItem(pi: ProjectItemToLineItem): CommitmentEditorLineItem {
  const {
    costType,
    displayName,
    id,
    unitOfMeasure,
    protoCommitmentLineItemToBuyout,
    location,
    bidItem,
    item,
    itemTemplateItem,
  } = pi;
  return {
    bidItem,
    costType,
    displayName,
    itemTemplateItem,
    quantity: protoCommitmentLineItemToBuyout?.quantity || 0,
    projectItemId: id,
    costChangeInCents: protoCommitmentLineItemToBuyout?.costChangeInCents || 0,
    unitOfMeasureName: unitOfMeasure.name,
    useQuantity: unitOfMeasure.useQuantity,
    location: location?.name,
    itemCode: item.fullCode,
    templateName: itemTemplateItem?.parent.displayName,
  };
}

export function mapToSaveLineItemInput(lineItems: Maybe<CommitmentEditorLineItem[]>) {
  return lineItems?.map((li) => ({
    id: li.id,
    internalNote: li.internalNote,
    costChangeInCents: li.costChangeInCents,
    delete: li.delete,
    quantity: li.quantity,
    projectItemId: li.projectItemId,
  }));
}

export function mapCommitmentLineItemToForm(lineItems: Maybe<CommitmentEditLineItemWithProjectItemFragment[]>) {
  return lineItems?.map((li) => {
    const { costType, displayName, id, unitOfMeasure, location, item, itemTemplateItem, task } = li.projectItem;
    return {
      ...li,
      costType,
      displayName,
      itemTemplateItem,
      projectItemId: id,
      unitOfMeasureName: unitOfMeasure?.name,
      delete: undefined,
      useQuantity: unitOfMeasure?.useQuantity,
      location: location?.name,
      itemCode: item.fullCode,
      templateName: itemTemplateItem?.parent.displayName,
      task,
    };
  });
}

export const commitmentLineItemsFormConfig: ObjectConfig<CommitmentEditorLineItem> = {
  id: { type: "value" },
  displayName: { type: "value" },
  costType: { type: "value" },
  quantity: { type: "value" },
  unitOfMeasureName: { type: "value" },
  projectItemId: { type: "value" },
  internalNote: { type: "value" },
  costChangeInCents: { type: "value" },
  billedInCents: { type: "value" },
  paidInCents: { type: "value" },
  balanceInCents: { type: "value" },
  delete: { type: "value" },
  useQuantity: { type: "value" },
  location: { type: "value" },
  bidItem: { type: "value" },
  itemCode: { type: "value" },
  templateName: { type: "value" },
  task: { type: "value" },
  itemTemplateItem: { type: "value" },
};

// export for tests
export const commitmentFormConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  projectStageId: { type: "value", rules: [required] },
  tradePartnerId: { type: "value", rules: [required] },
  executionDate: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  document: { type: "value", rules: [required] },
  accountingNumber: {
    type: "value",
    rules: [accountingNumberRule],
  },
  lineItems: {
    type: "list",
    config: commitmentLineItemsFormConfig,
  },
};

// export for tests
export function mapCommitmentToForm(commitment: CommitmentEditorDataFragment): FormInput {
  return {
    ...commitment,
    projectStageId: commitment.projectStage.id,
    tradePartnerId: commitment.tradePartner?.id,
    lineItems: mapCommitmentLineItemToForm(commitment.lineItems),
  };
}

function handleLineItemDisable(projectId: string, readOnly: boolean | undefined) {
  if (!projectId) {
    return "A Related Contract must be selected in order to add Line Items";
  } else if (!!readOnly) {
    return "Cannot edit POs created from change events or POs from development contracts";
  } else {
    return false;
  }
}

type RemoveButtonProps = {
  formState: FormState;
  tableApi: GridTableApi<Row>;
  selectedLineItems: GridDataRow<LineItemRow>[];
};

function RemoveButton({ formState, tableApi, selectedLineItems }: RemoveButtonProps) {
  return (
    <Button
      disabled={!selectedLineItems.length}
      variant="text"
      label="Remove"
      onClick={async () => {
        if (!!selectedLineItems.length) {
          selectedLineItems.forEach((li) => {
            const formStateLineItem = formState.lineItems.rows.find((fli) => fli.id === li.data.id);
            if (formStateLineItem) {
              formStateLineItem.set({ delete: true });
            }
          });
          tableApi.clearSelections();
        }
      }}
    />
  );
}

type AddLineItemButtonProps = {
  canEditLineItems?: boolean;
  formState: FormState;
  openModal: (props: ModalProps) => void;
  projectId: string;
  projectStageId: string;
  stage: Stage;
};

function AddLineItemButton({
  canEditLineItems,
  formState,
  openModal,
  projectId,
  projectStageId,
  stage,
}: AddLineItemButtonProps) {
  return (
    <Button
      variant="secondary"
      label="Add Line Item"
      data-testid="addLineItemBtn"
      onClick={() =>
        openModal({
          content: (
            <BulkAddProjectItemsModal
              onAdd={(_, pis) => pis.forEach((pi) => formState.lineItems.add(projectItemToLineItem(pi)))}
              stage={stage}
              projectId={projectId}
              groupBy={CeliGroupByValue.CostCode}
              // Exclude project items that are already in the commitment
              // or have been excluded from purchase orders in the cost code settings
              hiddenProjectItemIds={({ id, project, item }) => {
                const excludeCostCode =
                  !project.allowExcludedCostCodesOnPurchaseOrders && item.costCode.excludeFromPurchaseOrders;
                const existingProjectItem = formState.lineItems.value.some((li) => li.projectItemId === id);
                return excludeCostCode || existingProjectItem;
              }}
              submitButtonText={"Add to Commitment"}
            />
          ),
          size: "xl",
        })
      }
      disabled={handleLineItemDisable(
        projectStageId || "",
        typeof canEditLineItems !== "undefined" ? !canEditLineItems : canEditLineItems,
      )}
    />
  );
}

export enum CommitmentLineItemRow {
  Headers,
  Totals,
  Group,
  LineItem,
}

export enum CommitmentLineItemColumn {
  Collapse,
  PlanOrOption,
  ItemCode,
  BidItem,
  Allocation,
  InternalNote,
  Location,
  CostType,
  Quantity,
  Unit,
  Committed,
  Billed,
  Unbilled,
  PaidToDate,
  Pending,
}
