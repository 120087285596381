import {
  Avatar,
  AvatarButton,
  Chip,
  Css,
  navLink,
  Tab,
  Tabs,
  Tooltip,
  useResponsiveGrid,
  useTestIds,
} from "@homebound/beam";
import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useTeamMembersTileInternalUsersQuery,
  useTeamMembersTileTradePartnersQuery,
} from "src/generated/graphql-types";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createProjectSettingsUrl, createTradePartnerUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function TeamMembersTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const [selectedTab, setSelectedTab] = useState<TeamMemberTabs>("internal");
  const tabs: Tab<TeamMemberTabs>[] = [
    { name: "Internal", value: "internal" },
    { name: "Trade Partners", value: "tradePartners" },
  ];

  return (
    <Tile
      href={createProjectSettingsUrl(projectId, "teamMembers")}
      {...props}
      actions={<Tabs tabs={tabs} selected={selectedTab} onChange={(v) => setSelectedTab(v)} />}
    >
      {selectedTab === "internal" ? (
        <InternalUsersTab projectId={projectId} />
      ) : (
        <TradePartnersTab projectId={projectId} />
      )}
    </Tile>
  );
}

function InternalUsersTab({ projectId }: { projectId: string }) {
  const query = useTeamMembersTileInternalUsersQuery({ variables: { projectId }, fetchPolicy: "cache-first" });
  const tid = useTestIds({});
  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 220, gap: 32, columns: 3 });
  return queryResult(query, ({ project }) => (
    <ul css={{ ...gridStyles, ...Css.rgPx(12).listReset.$ }}>
      {project?.teamMembers
        ?.filter(({ user }) => !!user)
        .map(({ user, role }) => {
          return (
            <li key={role.code} css={Css.df.ais.gapPx(12).$} {...tid.teamMember}>
              <Avatar name={user?.name} src={user?.avatar} />
              <div>
                <div css={Css.smMd.gray900.$} {...tid.name}>
                  {user?.name}
                </div>
                <div css={Css.xsMd.gray600.lineClamp1.$} {...tid.role}>
                  {role.name}
                </div>
                <div>
                  <a href={`mailto:${user?.email}`} css={Css.xsMd.$} {...tid.email}>
                    {user?.email}
                  </a>
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  ));
}

function TradePartnersTab({ projectId }: { projectId: string }) {
  const query = useTeamMembersTileTradePartnersQuery({
    variables: { filter: { workingOn: [projectId] }, projectId },
    fetchPolicy: "cache-first",
  });
  const tid = useTestIds({});
  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 220, gap: 32, columns: 3 });
  return queryResult(query, ({ tradePartners }) => (
    <ul css={{ ...gridStyles, ...Css.rgPx(20).listReset.$ }}>
      {tradePartners.map((tp) => {
        const category = tp.tradeCategories.first;
        const contact = tp.primaryForProject;
        const phoneNumber = contact?.officePhone ?? contact?.mobilePhone;
        const showPhoneNumber = typeof phoneNumber === "string" && phoneNumber !== "xxx-xxx-xxxx";
        return (
          <li key={tp?.id} css={Css.df.ais.gapPx(12).$} {...tid.tradePartner}>
            <AvatarButton name={tp?.name} src={undefined} onClick={createTradePartnerUrl(tp.id)} />
            <div css={Css.df.fdc.gapPx(4).w100.$}>
              <div css={Css.df.gap1.$}>
                <Link
                  to={createTradePartnerUrl(tp.id)}
                  className={navLink}
                  css={Css.lineClamp1.fg1.smMd.gray900.onHover.tdu.$}
                  {...tid.name}
                >
                  {tp?.name}
                </Link>

                <div css={Css.fs0.jse.$} {...tid.averageScore}>
                  {/* The `Chip.text` prop only allows strings. Using the unicode 'star' rather than updating the Chip.text prop to support ReactNode. */}
                  <Chip compact text={tp.reviewCount === 0 ? "N/A" : `${tp.averageScore} ★`} />
                </div>
              </div>
              {(category || phoneNumber) && (
                <div css={Css.xsMd.gray600.df.gap1.$}>
                  {category && (
                    <span css={Css.mr2.lineClamp1.$} {...tid.category}>
                      {category.name}
                    </span>
                  )}
                  {showPhoneNumber && (
                    <Tooltip title={phoneNumber} placement="right">
                      <a href={`tel:${phoneNumber}`} className="navLink" css={Css.lineClamp1.$} {...tid.phoneNumber}>
                        {phoneNumber}
                      </a>
                    </Tooltip>
                  )}
                </div>
              )}
              {contact && (
                <>
                  <div css={Css.xsMd.gray600.lineClamp1.$}>
                    <span css={Css.mr2.$} {...tid.contactName}>
                      {contact.name}
                    </span>
                    <span css={Css.mr2.$} {...tid.contactTitle}>
                      {contact.title}
                    </span>
                  </div>
                  {contact.email && !contact.email.startsWith("not-a-real-") && (
                    <a href={`mailto:${contact.email}`} css={Css.xsMd.$} {...tid.email}>
                      {contact.email}
                    </a>
                  )}
                </>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  ));
}

type TeamMemberTabs = "internal" | "tradePartners";
