import { ButtonMenu, GridTableApi, MenuItem, useModal } from "@homebound/beam";
import { useMemo } from "react";
import {
  DocumentDetailDocumentTypeFragment,
  DocumentDetailFragment,
  NamedDevelopmentCohortsProjectsFragment,
  useDeleteDocumentsMutation,
  useDocumentsAssetQuery,
  useSaveDocumentsMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { RecategorizeModal } from "src/routes/projects/documents/RecategorizeModal";
import { sortBy } from "src/utils";
import { downloadFilesToZip } from "src/utils/downloader";

type DocumentsActionsMenuProps = {
  selectedDocuments: DocumentDetailFragment[];
  uncontrolledDocumentTypes: DocumentDetailDocumentTypeFragment[];
  tableRefetch: () => void;
  tableApi: GridTableApi<any>;
  development?: NamedDevelopmentCohortsProjectsFragment;
};

export function DocumentsActionsMenu(props: DocumentsActionsMenuProps) {
  const { selectedDocuments, uncontrolledDocumentTypes, tableRefetch, tableApi, development } = props;
  const [deleteDocumentsMutation] = useDeleteDocumentsMutation();
  const [saveDocumentsMutation] = useSaveDocumentsMutation();
  const { openModal, closeModal } = useModal();
  const { name } = useProjectContext();
  const documentIds = selectedDocuments.map(({ id }) => id);
  const query = useDocumentsAssetQuery({
    variables: {
      filter: { id: documentIds },
    },
  });
  const documentsForDownload = query.data?.documents;

  // Determine if the document should be editable; systemControlled document types are not editable
  const editableDocs = sortBy(
    selectedDocuments.filter((doc) => uncontrolledDocumentTypes.some((t) => t.code === doc.documentType.code)),
    ({ displayName }) => displayName,
  );
  const canDeleteAllowed = selectedDocuments.filter((doc) => doc.canDelete?.allowed);
  const archive = selectedDocuments.filter((doc) => doc.canDelete?.allowed && !doc.isDeleted);
  const unarchive = selectedDocuments.filter((doc) => doc.canDelete?.allowed && doc.isDeleted);

  const menuItems: MenuItem[] = useMemo(
    () => {
      const archiveAction = {
        label: "Archive",
        disabled: archive.length === 0,
        onClick: () =>
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage={
                  <>
                    <p>{`Are you sure you want to archive ${archive.length} document(s)`}</p>
                    <ul>
                      {archive.map((doc) => (
                        <li key={doc.id}>{doc.displayName}</li>
                      ))}
                    </ul>
                  </>
                }
                onConfirmAction={async () => {
                  await saveDocumentsMutation({
                    variables: { input: archive.map((doc) => ({ id: doc.id, deleted: true })) },
                  });
                  // Refetch to update our cache.
                  tableRefetch();
                  tableApi.clearSelections();
                  closeModal();
                }}
                title={`Archive Documents`}
                label="Archive"
              />
            ),
          }),
      };

      const unArchiveAction = {
        label: "UnArchive",
        disabled: unarchive.length === 0,
        onClick: () =>
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage={
                  <>
                    <p>{`Are you sure you want to un-archive ${unarchive.length} document(s)`}</p>
                    <ul>
                      {unarchive.map((doc) => (
                        <li key={doc.id}>{doc.displayName}</li>
                      ))}
                    </ul>
                  </>
                }
                onConfirmAction={async () => {
                  await saveDocumentsMutation({
                    variables: { input: unarchive.map((doc) => ({ id: doc.id, deleted: false })) },
                  });
                  // Refetch to update our cache.
                  tableRefetch();
                  closeModal();
                }}
                title={`Unarchive Documents`}
                label="UnArchive"
              />
            ),
          }),
      };

      const deleteAction = {
        label: "Delete",
        disabled: canDeleteAllowed.length === 0,
        onClick: () =>
          openModal({
            content: (
              <DeleteConfirmationModal
                onConfirmDelete={async () => {
                  const idsToDelete = editableDocs.map((doc) => doc.id);
                  await deleteDocumentsMutation({
                    variables: { input: { id: idsToDelete } },
                  });
                  tableApi.deleteRows(idsToDelete);
                  // Refetch to update our cache.
                  tableRefetch();
                }}
                entityType={"Documents"}
                confirmationMessage={
                  <>
                    <p>The following documents will be deleted. This cannot be undone.</p>
                    <ul>
                      {editableDocs.map((doc) => (
                        <li key={doc.id}>{doc.displayName}</li>
                      ))}
                    </ul>
                  </>
                }
              />
            ),
          }),
      };

      return [
        ...(archiveAction.disabled ? [] : [archiveAction]),
        ...(unArchiveAction.disabled ? [] : [unArchiveAction]),
        deleteAction,
        {
          label: "Recategorize",
          disabled: editableDocs.length === 0,
          onClick: () =>
            openModal({
              content: (
                <RecategorizeModal
                  documents={editableDocs}
                  uncontrolledDocumentTypes={uncontrolledDocumentTypes}
                  development={development}
                />
              ),
            }),
        },
        {
          label: "Download",
          disabled: selectedDocuments.length === 0,
          onClick: async () => {
            if (documentsForDownload) {
              await downloadFilesToZip(
                `${name} Documents.zip`,
                documentsForDownload.map(({ name, asset }) => ({
                  filename: asset.fileName ?? name,
                  url: asset.downloadUrl,
                })),
              );
            }
          },
        },
      ];
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editableDocs, archive, unarchive, selectedDocuments, deleteDocumentsMutation],
  );

  return <ButtonMenu trigger={{ label: "Actions" }} items={menuItems} />;
}
