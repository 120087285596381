import { Css, IconButton, Tooltip, useModal, useSnackbar } from "@homebound/beam";
import { useCallback } from "react";
import { InputMaybe } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { truncateString } from "src/utils";
import { UserAddedScheduleFlagsInput } from "./scheduleDraftStore";

export function ScheduleDraftModeDeleteFlagModal({
  userAddedDelayFlags,
  removeUserAddedScheduleFlags,
}: {
  userAddedDelayFlags: UserAddedScheduleFlagsInput[];
  removeUserAddedScheduleFlags: (clientId: string) => void;
}) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  const onScheduleFlagDelete = useCallback(
    (clientId: InputMaybe<string>) => {
      if (clientId) {
        removeUserAddedScheduleFlags(clientId);
        closeModal();
        triggerNotice({ message: "Delay flag removed" });
      }
    },
    [closeModal, removeUserAddedScheduleFlags, triggerNotice],
  );

  return (
    <ConfirmationModal
      title="Delay Flags"
      onConfirmAction={() => {}}
      confirmationMessage={
        <div css={Css.df.fdc.gap2.$}>
          {userAddedDelayFlags.map(({ scheduleFlagReasonType, title, clientId }) => (
            <div key={clientId} css={Css.df.jcsb.$}>
              <Tooltip
                title={`${scheduleFlagReasonType.name} Delay`}
                disabled={scheduleFlagReasonType.name.length <= 20}
              >
                <span css={Css.truncate.w25.$}>{`${truncateString(scheduleFlagReasonType.name, 30)} Delay`}</span>
              </Tooltip>
              <Tooltip title={title} disabled={title.length <= 20}>
                <span css={Css.truncate.w50.$}>{truncateString(title, 30)}</span>
              </Tooltip>
              <IconButton icon="trash" onClick={() => onScheduleFlagDelete(clientId)} />
            </div>
          ))}
        </div>
      }
      label="Ok"
    />
  );
}
