import { Css, Tag, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { createPlanPackageUrl } from "src/RouteUrls";
import { FormattedDate } from "src/components";
import { PlanPackageFragment, ReadyPlanAssetType } from "src/generated/graphql-types";
import { PlanPackageButtonMenu } from "src/routes/libraries/plan-package/details/components/PlanPackageButtonMenu";
import { readyPlanStatusToTagType } from "src/utils";
import { programDataBathRange, programDataRange } from "src/utils/programData";
import { PLAN_FALLBACK_IMG } from "../takeoffs/utils";

type PlanPackageCardProp = {
  planPackage: PlanPackageFragment;
};

export function PlanPackageCard({ planPackage }: PlanPackageCardProp) {
  const tid = useTestIds({}, "planPackageCard");
  const {
    name,
    id,
    status,
    displayCode,
    activeOptionsCount,
    assets,
    minProgramData,
    maxProgramData,
    options,
    updatedAt,
  } = planPackage;
  const coverPhotoUrl =
    assets?.find((asset) => asset?.type.code === ReadyPlanAssetType.CoverPhoto)?.asset.previewUrl ?? PLAN_FALLBACK_IMG;
  const elevationsCount = options?.filter((option) => option?.type.isElevation).length ?? 0;
  return (
    <div {...tid} css={Css.bgWhite.wPx(369).br8.bshBasic.p2.df.fdc.jcsb.gap3.aifs.$}>
      <div css={Css.w100.color("black").$}>
        <Link css={Css.relative.$} to={createPlanPackageUrl(id)}>
          <img src={coverPhotoUrl} alt="Plan package cover photo" {...tid.coverPhoto} css={Css.hPx(184).ma.$} />
          <div css={Css.absolute.top2.left2.$}>
            <Tag text={status.name} type={readyPlanStatusToTagType[status.code]} {...tid.status} />
          </div>
        </Link>
        <div css={Css.mt3.$}>
          <div css={Css.df.jcsb.aic.gap2.$}>
            <h4 css={Css.xlSb.mbPx(4).onHover.tdu.$} {...tid.name}>
              <Link className="navLink" to={createPlanPackageUrl(id)}>
                {name}
              </Link>
            </h4>
            <PlanPackageButtonMenu planPackage={planPackage} iconTrigger />
          </div>
          <span css={Css.smMd.color("black").$} {...tid.displayCode}>
            {displayCode ? displayCode : "N/A"}
          </span>
          <div css={Css.df.fdc.aic.w100.mt1.mb2.$}>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.gray700.w50.$}>{`${
                programDataRange(minProgramData, maxProgramData, "bedrooms") ?? 0
              } bed ${programDataBathRange(minProgramData, maxProgramData) ?? 0} bath`}</span>
              <span css={Css.sm.gray700.w50.$}>{elevationsCount} elevations</span>
            </div>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.gray700.w50.$} {...tid.sellableSqft}>
                {programDataRange(minProgramData, maxProgramData, "sellableSqft") ?? 0} sqft
              </span>
              <span css={Css.sm.gray700.w50.$} {...tid.activeOptionsCount}>
                {activeOptionsCount} options
              </span>
            </div>
          </div>
          <span css={Css.df.gapPx(4).tiny.gray700.$} {...tid.lastUpdated}>
            last updated <FormattedDate date={updatedAt} dateFormatStyle="short" />
          </span>
        </div>
      </div>
    </div>
  );
}
