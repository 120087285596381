import { Css, useTestIds } from "@homebound/beam";
import { emptyCellDash } from "src/components/gridTableCells";
import { Maybe } from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString } from "src/utils";

export type PriceProps = {
  id?: string;
  valueInCents: Maybe<number>;
  dropZero?: boolean;
  /** Prefixes a plus for positive numbers, like +$1,000. Negatives will always display */
  displayDirection?: boolean;
  /** Makes positive numbers red (e.g. price increase) and negative colors green */
  invertColors?: boolean;
  /** trims trailing zeros after decimal point */
  trim?: boolean;
  /** Pairs with displayDirection + invertColors to determine how to display Zero. Neutral overrides either to display without color. */
  zeroIs?: "positive" | "negative" | "neutral";
  maxDecimalPlaces?: number;
  minDecimalPlaces?: number;
};

export function Price(props: PriceProps) {
  const {
    id,
    valueInCents,
    displayDirection = false,
    invertColors = false,
    zeroIs = "positive", // This is less a default than an expected byproduct of !!valueInCents
  } = props;
  const rootId = useTestIds({}, id || "price");
  const isNegativeValue = isNegative(valueInCents, zeroIs);
  const isZero = valueInCents === 0;
  const styles = isZero && zeroIs === "neutral" ? Css.$ : redOrGreen(displayDirection, isNegativeValue, invertColors);
  const formattedPrice = formatToPrice(props);
  return (
    <span {...rootId} css={styles}>
      {formattedPrice}
    </span>
  );
}

function redOrGreen(displayDirection: boolean, isNegative: boolean, invertColors: boolean) {
  let green = displayDirection && !isNegative ? true : isNegative ? false : undefined;
  if (green === undefined) return;
  if (invertColors) green = !green;
  return green ? Css.green800.$ : Css.red600.$;
}

function isNegative(valueInCents: Maybe<number>, zeroIs = "positive") {
  let isNegative = !!valueInCents && valueInCents < 0;
  const isZero = valueInCents === 0;
  if (isZero && zeroIs === "negative") isNegative = !isNegative;
  return isNegative;
}

export function formatToPrice(props: PriceProps) {
  const {
    valueInCents,
    dropZero = false,
    trim = false,
    displayDirection = false,
    zeroIs = "positive",
    maxDecimalPlaces,
    minDecimalPlaces,
  } = props;
  const isNegativeValue = isNegative(valueInCents, zeroIs);
  const prefix = isNegativeValue ? "-$" : displayDirection ? "+$" : "$";
  return typeof valueInCents !== "number" || (dropZero && valueInCents === 0)
    ? emptyCellDash
    : `${prefix}${formatNumberToString(centsToDollars(Math.abs(valueInCents)), trim, maxDecimalPlaces, minDecimalPlaces)}`;
}
