import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { createReadyPlanEditOptionsUrl } from "src/RouteUrls";
import {
  ReadyPlanDetailFragment,
  ReadyPlanStatus,
  SaveReadyPlanInput,
  SaveReadyPlansInput,
  useSaveReadyPlansMutation,
} from "src/generated/graphql-types";
import { updateQuery } from "src/mutations/utils";
import { cdCompletePercentageOptions } from "src/routes/developments/plan-and-options/utils";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { CreateReadyPlanCode } from "./CreateReadyPlanCode";
import { WelcomeReadyPlanModal } from "./WelcomeReadyPlanModal";

type CreateReadyPlanModalButtonProps = {
  readyPlans?: ReadyPlanDetailFragment[];
  developmentId: string;
};

export function CreateReadyPlanModalButton(props: CreateReadyPlanModalButtonProps) {
  const { openModal } = useModal();
  return (
    <Button label="Create ReadyPlan" onClick={() => openModal({ content: <CreateReadyPlanModal {...props} /> })} />
  );
}

export function CreateReadyPlanModal(props: CreateReadyPlanModalButtonProps) {
  const { developmentId, readyPlans } = props;
  const emptyReadyPlanInput: SaveReadyPlanInput = {
    name: "",
    status: ReadyPlanStatus.Draft,
    developmentId,
    constructionDocumentCompletePercentage: 0,
  };
  const { closeModal, openModal } = useModal();
  const [save, { loading }] = useSaveReadyPlansMutation();
  const deliminator = "|||";
  const history = useHistory();
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { readyPlans: [emptyReadyPlanInput] },
    },
    addRules: (state) => {
      state.readyPlans.rules.push(() => {
        const names = getDuplicateNames(formState, readyPlans);
        const codes = getDuplicateCodes(formState, readyPlans);
        const joinNamesAndCodes = [...names, ...codes];

        if (names.length > 0 || codes.length > 0) {
          return joinNamesAndCodes.join(deliminator);
        }

        return undefined;
      });
    },
  });

  return (
    <>
      <ModalHeader>Create Ready Plan</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.$}>
          <Observer>
            {() => (
              <>
                {formState.readyPlans.rows.map((plan, idx) => (
                  <div key={idx} css={Css.bt.bcGray200.pt3.pb1.if(idx === 0).bn.pt0.$}>
                    <div
                      css={Css.df.aifs.addIn("> .removeButton", Css.vh.$).addIn(":hover > .removeButton", Css.vv.$).$}
                    >
                      <FormLines>
                        <BoundTextField
                          field={plan.name}
                          label="Marketing name"
                          placeholder="i.e. The Everett..."
                          compact
                          clearable
                          errorMsg={
                            (plan.name.touched ? plan.name.errors.join(" ") : undefined) ||
                            (plan.name.value &&
                            formState.readyPlans.errors
                              .join()
                              .split(deliminator)
                              .includes(`name-${plan.name.value.toLowerCase()}`)
                              ? "This marketing name already exists"
                              : undefined)
                          }
                        />
                      </FormLines>
                      {formState.readyPlans.rows.length > 1 && (
                        <div css={Css.mlPx(4).mtPx(25).$} className="removeButton">
                          <IconButton
                            icon="x"
                            onClick={() => {
                              formState.readyPlans.remove(plan.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <FormLines>
                      <CreateReadyPlanCode
                        codeField={plan.code}
                        existingCode={getExistingCode(formState, deliminator, plan)}
                        typeField={plan.type}
                      />
                    </FormLines>
                    <FormLines>
                      <div css={Css.wPx(120).$}>
                        <BoundSelectField
                          label="CD Completeness"
                          compact
                          field={plan.constructionDocumentCompletePercentage}
                          options={cdCompletePercentageOptions}
                          getOptionLabel={(cd) => cd.label}
                          getOptionValue={(cd) => cd.value}
                        />
                      </div>
                    </FormLines>
                  </div>
                ))}
              </>
            )}
          </Observer>
          {/* without div, button is a flex item which expands the clickable area all the way across causing accidental presses */}
          <div>
            <Button
              variant="text"
              label="+ Add another ReadyPlan"
              // Spread the `emptyReadyPlanInput` to make a net-new object each time. Otherwise all fields will be updating the same object
              onClick={() => formState.readyPlans.add({ ...emptyReadyPlanInput })}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label="Create ReadyPlan"
              disabled={!formState.valid || loading}
              onClick={async () => {
                // TODO: Handle error case
                const rp = await save({
                  variables: { input: formState.value },
                  update: updateQuery((data) => data.saveReadyPlans.readyPlans, "readyPlans"),
                });

                history.push({
                  pathname: createReadyPlanEditOptionsUrl(developmentId, rp.data?.saveReadyPlans.readyPlans[0].id!),
                });

                openModal({
                  content: <WelcomeReadyPlanModal readyPlans={rp.data?.saveReadyPlans.readyPlans || []} />,
                });
              }}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveReadyPlansInput> = {
  readyPlans: {
    type: "list",
    config: {
      name: { type: "value", rules: [required] },
      status: { type: "value", rules: [required] },
      developmentId: { type: "value", rules: [required] },
      code: { type: "value", rules: [required] },
      type: { type: "value", rules: [required] },
      constructionDocumentCompletePercentage: { type: "value", rules: [required] },
    },
  },
};

function getDuplicateNames(
  formState: ObjectState<{ readyPlans: SaveReadyPlanInput[] }>,
  readyPlans: ReadyPlanDetailFragment[] | undefined,
) {
  const getFormNames = formState.readyPlans.rows.map((rp) => rp.name.value?.toLowerCase().trim());
  const getExistingNames = readyPlans?.map((rp) => rp.name.toLowerCase().trim()) || [];
  return (
    [...getFormNames, ...getExistingNames]
      .map((rp) => rp)
      .filter((rp, i, name) => name.indexOf(rp) !== i)
      // Add a prefix to identify the names on the list of readyReadyPlans.errors
      .map((name) => `name-${name}`)
  );
}

function getDuplicateCodes(
  formState: ObjectState<{ readyPlans: SaveReadyPlanInput[] }>,
  readyPlans: ReadyPlanDetailFragment[] | undefined,
) {
  // since type is now not included with code we need to account for this in order to make
  // sure SFH-CO-240-001 and ADU-CO-240-001 are valid and not seen as duplicates
  const getFormCodes = formState.readyPlans.rows.map((rp) => `${rp.type.value}-${rp.code.value?.trim()}`);
  const getExistingCodes =
    readyPlans?.map((rp) => (rp?.type && rp?.code ? `${rp.type.code}-${rp.code.trim()}` : null)) || [];
  return (
    [...getFormCodes, ...getExistingCodes]
      .map((rp) => rp)
      .filter(Boolean)
      .filter((rp, i, code) => code.indexOf(rp) !== i)
      // Add a prefix to identify the codes on the list of readyReadyPlans.errors
      .map((code) => `code-${code}`)
  );
}

function getExistingCode(
  formState: ObjectState<{ readyPlans: SaveReadyPlanInput[] }>,
  delimiter: string,
  plan: ObjectState<SaveReadyPlanInput>,
) {
  return formState.readyPlans.errors.join().split(delimiter).includes(`code-${plan.type.value}-${plan.code.value}`);
}
