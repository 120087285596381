import { sentenceCase } from "change-case";
import { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import { createDevelopmentUrl, createProjectUrl } from "src/RouteUrls";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";
import { UserEventRowData } from "../../UserEventsTable";

export function ProjectEventText({
  event,
  isProjectPage,
  dateTag,
}: {
  event: UserEventRowData;
  isProjectPage?: boolean;
  dateTag?: ReactNode;
}) {
  const { type, createdBy, project, payload, development } = event;
  const { cohort } = payload as any;
  const cohortName = `${cohort?.name ?? "a cohort"}${development?.name ? ` - ${development?.name}` : ""}`;

  const projectTag = (
    <span css={weightedStyle}>
      {!!project &&
        (!isProjectPage ? (
          <Link to={createProjectUrl(project.id)} target="_blank">
            {project.name}
          </Link>
        ) : (
          project.name
        ))}
    </span>
  );

  const prepProjectTag = (prep: string) =>
    project &&
    !isProjectPage && (
      <>
        {`${prep.trim()} `}
        <span css={weightedStyle}>
          <Link to={createProjectUrl(project.id)} target="_blank">
            {project.name}
          </Link>
        </span>
      </>
    );

  const cohortTag = (
    <span css={weightedStyle}>
      {development?.id ? (
        <Link to={createDevelopmentUrl(development.id)} target="_blank">
          {cohortName}
        </Link>
      ) : (
        cohortName
      )}
    </span>
  );

  switch (type) {
    case "PROJECT_COHORT_CHANGE":
      return (
        <>
          <span>
            {projectTag} has been added to {cohortTag}
          </span>
          {dateTag}
        </>
      );

    case "LOT_RELEASE":
      const { released } = payload;

      return (
        <>
          {released.map(({ firstItem, tradePartner, itemCount }: any) => (
            <Fragment key={tradePartner.name.concat(firstItem.name)}>
              {itemCount > 1 ? (
                <span>
                  <span css={weightedStyle}>{itemCount} cost codes</span> were released by
                  <span css={weightedStyle}> {createdBy}</span> {prepProjectTag("for")}
                </span>
              ) : (
                <span>
                  <span css={weightedStyle}>{firstItem.name}</span> was released to{" "}
                  <span css={weightedStyle}>{tradePartner.name}</span> by <span css={weightedStyle}>{createdBy}</span>{" "}
                  {prepProjectTag("for")}
                </span>
              )}
              {dateTag}
            </Fragment>
          ))}
        </>
      );

    default:
      return (
        <>
          <span>
            {projectTag} was <span css={weightedStyle}>{sentenceCase(type)}</span> by
            <span css={weightedStyle}> {createdBy}</span>
          </span>
          {dateTag}
        </>
      );
  }
}
