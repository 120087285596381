import { AuthViewProps } from "@homebound/auth-components";
import {
  BeamProvider,
  Css,
  PreventBrowserScroll,
  ScrollableParent,
  setGridTableDefaults,
  Toast,
} from "@homebound/beam";
import { PropsWithChildren, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { NavigationContextProvider } from "src/components";
import { ErrorBoundary } from "src/components/ErrorBoundary";
import { ZendeskWidget } from "src/components/ZendeskWidget";
import { getStage } from "src/context";
import { AppNotificationsProvider } from "src/contexts/AppNotificationsContext";
import { BlueprintProvider } from "src/contexts/BlueprintContext";
import { FeatureFlagProvider } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { useCurrentInternalUserQuery } from "src/generated/graphql-types";
import { GlobalNav } from "src/routes/layout/GlobalNav";
import {
  adminPaths,
  changeLogPaths,
  developmentPaths,
  librariesPath,
  personalDashboardPaths,
  projectPaths,
  tradeCategoryLeadTimesPath,
  warrantyPaths,
} from "src/routes/routesDef";
import { setAnalyticsUser } from "src/utils/analytics";
import { LocalCssReset } from "src/utils/LocalCssReset";
import { QaEnvironmentWarning } from "./QaEnvironmentWarning";

export type PageLayoutProps = PropsWithChildren<Pick<AuthViewProps, "user" | "setIsAuthenticated">>;

/** Wraps the application pages (passed as children) with our logged-in-user layout/nav. */
export function PageLayout(props: PageLayoutProps) {
  const { data } = useCurrentInternalUserQuery();
  const { id, name, email } = data?.currentInternalUser || {};

  // Apply grid table defaults for empty cells
  setGridTableDefaults({ stickyHeader: true, style: { allWhite: true } });

  useEffect(() => {
    setAnalyticsUser({ id, name, email });
  }, [id, name, email]);

  // We want two separate top level layouts whether you are in a Project/Development/Libraries specific path or not.
  // This makes styling with the side nav easier if we do not need to nest ScrollableParent containers.
  const hasOwnLayout = useRouteMatch([
    projectPaths.project,
    developmentPaths.development,
    adminPaths.admin,
    librariesPath,
    personalDashboardPaths.dashboard,
    tradeCategoryLeadTimesPath,
    changeLogPaths.base,
    warrantyPaths.details,
  ]);

  const content = hasOwnLayout ? (
    props.children
  ) : (
    <ScrollableParent tagName="main" xss={Css.px3.bgGray100.$}>
      {props.children}
    </ScrollableParent>
  );

  const stage = getStage();

  return (
    <BeamProvider>
      <LocalCssReset />
      <NavigationContextProvider>
        <BlueprintProvider>
          <FeatureFlagProvider>
            <AppNotificationsProvider>
              <ZendeskWidget />
              <PreventBrowserScroll>
                <GlobalNav user={props.user} />
                {stage === "qa" && <QaEnvironmentWarning />}
                <Toast />
                <ErrorBoundary>{content}</ErrorBoundary>
              </PreventBrowserScroll>
            </AppNotificationsProvider>
          </FeatureFlagProvider>
        </BlueprintProvider>
      </NavigationContextProvider>
    </BeamProvider>
  );
}
