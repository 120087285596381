import { Css, useBreakpoint } from "@homebound/beam";
import { ApprovalSubject_ChangeEventFragment } from "src/generated/graphql-types";

// Half of these go away when PresentationProvider or StaticField support
// asymmetric column widths. Right now they're forced to 50/50 whereas wee
// want 100px/362px for left-label form fields. Check commit history to infer
// potential reverts once that's supported, but ensure gap/padding/margins
// are preserved, per: https://www.figma.com/file/r5mxRSbwq0DMCjhmmeDhn7/22-Q3-%F0%9F%9B%A0-Approvals?type=design&node-id=3896-230317&t=I34lrjntsWgZ0mgR-0

type SimpleWrapper = React.PropsWithChildren<unknown>;

export function OverviewWrapper({ children }: SimpleWrapper) {
  const desktop = useApprovalBreakpoint();
  return desktop ? <div css={Css.df.fdr.jcsb.$}>{children}</div> : <div css={Css.fdc.pb1.$}>{children}</div>;
}

export function OverviewLeft({ children }: SimpleWrapper) {
  const desktop = useApprovalBreakpoint();
  return desktop ? (
    <div css={Css.df.fdc.gap4.br.wPx(612).pt3.pr3.bcGray200.$}>{children}</div>
  ) : (
    <div css={Css.df.fdc.gap4.pt3.$}>{children}</div>
  );
}

export function OverviewRight({ children }: SimpleWrapper) {
  const desktop = useApprovalBreakpoint();
  return desktop ? (
    <div css={Css.df.fdc.gap5.mb6.wPx(424).$}>{children}</div>
  ) : (
    <div css={Css.df.fdc.gap5.mb6.$}>{children}</div>
  );
}

/** True: Desktop, False: Mobile/Tablet */
export function useApprovalBreakpoint() {
  const { smOrMd } = useBreakpoint();
  return !smOrMd;
}

export const OverviewForm = ({ children }: SimpleWrapper) => (
  <div css={Css.dg.gtc("100px 362px").wPx(486).maxh("50vh").oya.cg3.rgPx(8).mt1.$}>{children}</div>
);
export const OverviewFormLabel = ({ children }: SimpleWrapper) => <span css={Css.gray700.$}>{children}</span>;
export const OverviewFormGap = () => <div css={Css.mt2.$} /* 32px gap between Note/Trade so gap(8)*2+16=32 */ />;
export const approvalTabXss = (selectedTab: string): any => Css.h("95%").oys.if(selectedTab === "overview").pt0.$;

type ApprovalOverviewTradesListProps = {
  changeEvents: ApprovalSubject_ChangeEventFragment[];
};

export function ApprovalOverviewTradesList({ changeEvents }: ApprovalOverviewTradesListProps) {
  return (
    <>
      <OverviewFormLabel>Trade partners</OverviewFormLabel>
      <div css={Css.df.fdc.oxh.whiteSpace("nowrap").$}>
        {changeEvents
          .flatMap((ce) => ce.impactedTrades)
          .uniqueByKey("id")
          .sortByKey("name")
          .map((tp) => (
            <span key={tp.id}>{tp.name}</span>
          ))}
      </div>
    </>
  );
}

/**
 * Should be reused for all "Create Approval" components so the superdrawer
 * can expect a consistent shape no matter the Subject type.
 */
export type SubjectCreateApprovalProps = { subjectId: string };
