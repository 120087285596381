import { Css, MultiSelectField, SelectField, Switch } from "@homebound/beam";
import { TreeNode } from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { DropdownTreeSelect } from "src/components/DropdownTreeSelect";
import { DevelopmentBulkScheduleCohortDetailFragment, Stage } from "src/generated/graphql-types";
import { DevelopmentBulkScheduleFilter } from "src/routes/developments/schedule/DevelopmentBulkSchedulePage";
import { stageCodeToNameMapper } from "src/utils";
type DevelopmentBulkScheduleFilterModalProps = {
  filter: DevelopmentBulkScheduleFilter;
  setFilter: (filter: DevelopmentBulkScheduleFilter) => void;
  cohorts: DevelopmentBulkScheduleCohortDetailFragment[];
};

export enum DevelopmentBulkSchedulesGroupBy {
  None = "none",
  Cohort = "cohort",
}

export function DevelopmentBulkScheduleFilterModal({
  filter,
  setFilter,
  cohorts,
}: DevelopmentBulkScheduleFilterModalProps) {
  const stageOptions = [Stage.PreConExecution, Stage.Construction].map((s) => ({
    id: s,
    name: stageCodeToNameMapper[s],
  }));

  // mapping our data to fit the dropdown-tree-select component structure
  const dropdownCohortData = cohorts.map((c) => ({
    fragment: c,
    label: c.name,
    value: c.id,
    checked: filter.cohort?.includes(c.id),
    children: c.projects?.map((p) => ({
      fragment: p,
      label: p.name,
      value: p.id,
      checked: filter.project?.includes(p.id) || filter.cohort?.includes(c.id),
    })),
  }));

  const onProjectChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    // grouping cohorts and/or projects to send to filter
    const cohortsOrProjects = selectedNodes.groupBy(
      ({ fragment }) => fragment.__typename,
      ({ fragment }) => fragment.id,
    );
    setFilter({
      ...filter,
      cohort: cohortsOrProjects["Cohort"],
      project: cohortsOrProjects["Project"],
    });
  };

  const groupByOptions: {
    id: DevelopmentBulkSchedulesGroupBy;
    name: string;
  }[] = [
    { id: DevelopmentBulkSchedulesGroupBy.None, name: "None" },
    { id: DevelopmentBulkSchedulesGroupBy.Cohort, name: "Cohort" },
  ];

  return (
    <>
      <div css={Css.df.fdc.gap2.$}>
        <SelectField
          onSelect={(val) => {
            setFilter({ ...filter, groupBy: val! });
          }}
          label="Group By"
          options={groupByOptions}
          value={filter.groupBy}
        />
        <SelectField
          onSelect={(val) => {
            setFilter({ ...filter, stage: val! });
          }}
          label="Stage"
          options={stageOptions}
          value={filter.stage}
        />
        <label css={Css.gray700.$}>Projects</label>
        <DropdownTreeSelect
          data={dropdownCohortData}
          onChange={onProjectChange}
          texts={{ placeholder: "Select items" }}
          showPartiallySelected={true}
        />
        <MultiSelectField
          onSelect={(val) => setFilter({ ...filter, latestActiveStage: val.isEmpty ? undefined : val })}
          label="Current Stage"
          options={stageOptions}
          values={filter.latestActiveStage ?? []}
        />
        <Switch
          label="Milestones"
          selected={!!filter.milestonesOnly}
          onChange={(val) => {
            setFilter({ ...filter, milestonesOnly: val });
          }}
          labelStyle="filter"
          data-testid="milestonesOnly"
        />
        <Switch
          label="Show Task Status instead of Plan"
          selected={!!filter.taskStatusView}
          onChange={(val) => {
            setFilter({ ...filter, taskStatusView: val });
          }}
          labelStyle="filter"
          data-testid="taskStatusView"
        />
        <Switch
          selected={!!filter.stale}
          label="Stale Tasks"
          onChange={(val) => {
            setFilter({ ...filter, stale: val });
          }}
          labelStyle="filter"
          data-testid="stale"
        />
      </div>
    </>
  );
}
