import { useModal } from "@homebound/beam";
import { ReactNode, useCallback } from "react";
import { ConfirmationModal } from "./ConfirmationModal";

type DeleteConfirmationModalProps = {
  confirmationMessage?: ReactNode | string;
  onConfirmDelete: () => void;
  entityType: string;
  disabled?: boolean;
};

export function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
  const { confirmationMessage, onConfirmDelete, entityType, ...rest } = props;
  const { closeModal } = useModal();

  const onConfirmClick = useCallback(async () => {
    await onConfirmDelete();
    closeModal();
  }, [onConfirmDelete, closeModal]);

  return (
    <ConfirmationModal
      confirmationMessage={confirmationMessage ?? "Are you sure?"}
      onConfirmAction={onConfirmClick}
      title={`Delete ${entityType}`}
      label="Delete"
      danger
      {...rest}
    />
  );
}
