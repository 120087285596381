import { useEffect } from "react";

/**
 * Hook to run a cleanup function on component dismount, with an option to check specific conditions
 * before performing the cleanup
 *
 * @param onCleanUp Function to handle effect cleanups when component dismounts
 * @param cleanupCondtion Optional conditions to check in order to perform cleanup
 */
export function useOnDismount(onCleanUp: VoidFunction, cleanupCondtion?: boolean) {
  useEffect(
    () => {
      return function cleanUp() {
        cleanupCondtion ? cleanupCondtion && onCleanUp() : onCleanUp();
      };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
