import { Button, Css, ModalBody, ModalFooter, ModalHeader, NumberField, useModal, useSnackbar } from "@homebound/beam";
import { useState } from "react";

type SelectedItem = { id: string };
type SelectedChangeEventLineItem = { id: string; proposedTotalCostInCents: number; originalTotalPriceInCents: number };

type UpdateMarkupModalItemProps = {
  kind: "projectItem" | "itemTemplateItem";
  selectedItems: SelectedItem[];
  onSave: (...input: { id: string; markupBasisPoints: number }[]) => Promise<boolean>;
};

type UpdateMarkupModalCeliProps = {
  kind: "changeEvent";
  selectedItems: SelectedChangeEventLineItem[];
  onSave: (...input: { id: string; totalPriceInCents: number }[]) => Promise<boolean>;
};

export function UpdateMarkupModal(props: UpdateMarkupModalItemProps | UpdateMarkupModalCeliProps) {
  const { selectedItems, onSave, kind } = props;

  // control state here as being used in `openModal` to receive updated data
  const [markupPercent, setMarkupPercent] = useState<undefined | number>(20);
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  return (
    <>
      <ModalHeader>Update Markup</ModalHeader>
      <ModalBody>
        <p css={Css.mb2.$}>
          {selectedItems.length > 1
            ? `${selectedItems.length} items selected`
            : `${selectedItems.length} item selected`}
        </p>
        <NumberField label="Markup Percentage" onChange={setMarkupPercent} type="percent" value={markupPercent} />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Update"
          onClick={async () => {
            if (kind === "changeEvent") {
              // Update totalPriceInCents for CELIs, since proposedMarkupPercent is a calculated field
              const saveInput = selectedItems.map(({ id, proposedTotalCostInCents, originalTotalPriceInCents }) => ({
                id: id,
                totalPriceInCents:
                  Math.round(
                    (proposedTotalCostInCents || 0) * ((markupPercent || 0) / 100) + (proposedTotalCostInCents || 0),
                  ) - (originalTotalPriceInCents || 0),
              }));

              await onSave(...saveInput);
            } else {
              const saveInput = selectedItems.map(({ id }) => ({
                id: id,
                markupBasisPoints: (markupPercent || 0) * 100,
              }));

              await onSave(...saveInput);
            }

            triggerNotice({
              message: `${
                selectedItems.length > 1
                  ? `Markup has been updated for ${selectedItems.length} items`
                  : "Item markup has been updated"
              }`,
              icon: "success",
            });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
