import { UserEventsTable } from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { useApprovalContext } from "./ApprovalSuperDrawer";

export function ApprovalActivityTab() {
  const { currentUserId, approvals } = useApprovalContext();
  return (
    <UserEventsTable
      currentUser={{ id: currentUserId }}
      queryFilter={{ parentIds: approvals?.map((a) => a.id), allEvents: true }}
      embedded
    />
  );
}
