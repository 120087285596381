import {
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  multiFilter,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  usePersistedFilter,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { QueryTable, SearchBox } from "src/components";
import {
  DevelopmentDetailFragment,
  DevelopmentFilter,
  MarketDetailFragment,
  useDevelopmentsPageMetadataQuery,
  useDevelopmentsQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { createDevelopmentLotSummaryUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function DevelopmentsPage() {
  // Loading markets before rendering the page
  const query = useDevelopmentsPageMetadataQuery();
  useDocumentTitle("Developments");
  return queryResult(query, {
    data: ({ markets }) => <DevelopmentsPageData markets={markets} />,
  });
}

export type DevelopmentsPageDataProps = {
  markets: MarketDetailFragment[];
};

type Row = SimpleHeaderAndData<DevelopmentDetailFragment>;

export function DevelopmentsPageData({ markets }: DevelopmentsPageDataProps) {
  const filterDefs: FilterDefs<DevelopmentFilter> = useMemo(() => {
    const market = multiFilter({
      label: "Market",
      options: markets,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    return { market };
  }, [markets]);

  const { filter, setFilter } = usePersistedFilter<DevelopmentFilter>({
    storageKey: "developmentFilter",
    filterDefs,
  });
  const [searchFilter, setSearchFilter] = useState<string>("");

  const query = useDevelopmentsQuery({ variables: { filter } });

  return (
    <div>
      <div css={Css.df.jcsb.mb2.$}>
        <Filters<DevelopmentFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} />
      </div>
      <ScrollableContent virtualized>
        <QueryTable
          as={"virtual"}
          stickyHeader
          sorting={{ on: "client" }}
          query={query}
          filter={searchFilter}
          columns={columns}
          createRows={(data) => simpleDataRows(data?.developments)}
          fallbackMessage="No developments found."
          rowStyles={rowStyles}
          style={{ allWhite: true, bordered: true }}
        />
      </ScrollableContent>
    </div>
  );
}

const columns: GridColumn<Row>[] = [
  { header: "Name", data: ({ name }) => name },
  { header: "Market", data: ({ market }) => market.name },
  { header: "Cohorts", data: ({ cohorts }) => cohorts?.length },
];

const rowStyles: RowStyles<Row> = {
  header: {},
  data: { rowLink: ({ id }) => createDevelopmentLotSummaryUrl(id) },
};
