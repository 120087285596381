import { RadioGroupField } from "@homebound/beam";

export enum ScheduleCalendarView {
  Completion = "Completion",
  TradePartnerScheduled = "Trade Partner Scheduled",
}

type ScheduleCalendarViewModalProps = {
  viewType: ScheduleCalendarView;
  setViewType: (viewType: ScheduleCalendarView) => void;
};

export function ScheduleCalendarViewModal({ viewType, setViewType }: ScheduleCalendarViewModalProps) {
  const options = [
    { label: "Completion", value: ScheduleCalendarView.Completion },
    { label: "Trade Partner Scheduled", value: ScheduleCalendarView.TradePartnerScheduled },
  ];
  return (
    <div>
      <RadioGroupField labelStyle="hidden" label="" value={viewType} options={options} onChange={setViewType} />
    </div>
  );
}
