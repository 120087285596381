import { useState } from "react";
import { Link } from "react-router-dom";
import { ExpenseAdmin_ProjectFragment, useExpenseAdminTeamMemberProjectsQuery } from "src/generated/graphql-types";
import { EmptyState } from "src/routes/components/EmptyState";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ExpenseProjectView } from "src/routes/projects/expenses/ExpensesPage";
import { EmptyPlansDetailImage } from "src/routes/projects/expenses/components/EmptyStateSvgs";
import { ProjectHeader } from "src/routes/projects/expenses/components/ProjectHeader";
import { queryResult } from "src/utils";

export function ExpensesAdmin() {
  const metadataQuery = useExpenseAdminTeamMemberProjectsQuery();
  return queryResult(metadataQuery, ({ myTeamMemberProjects }) => (
    <ExpensesAdminView
      projects={myTeamMemberProjects.filter((tmp) => tmp.project.expenses.nonEmpty).map((tmp) => tmp.project)}
      refetchHeaderQuery={metadataQuery.refetch}
    />
  ));
}

type ExpenseProjectViewProps = {
  projects: ExpenseAdmin_ProjectFragment[];
  refetchHeaderQuery: VoidFunction;
};

function ExpensesAdminView({ projects, refetchHeaderQuery }: ExpenseProjectViewProps) {
  const [project, setProject] = useState(projects.first);
  return (
    <>
      <PageHeader title="Budget Allocation" />
      {project ? (
        <>
          <ProjectHeader project={project} setProject={setProject} allProjects={projects} />
          <ExpenseProjectView projectId={project.id} refetchHeaderQuery={refetchHeaderQuery} />
        </>
      ) : (
        <EmptyState
          message="Sorry, we couldn't find any projects of yours that require allocation"
          svg={<EmptyPlansDetailImage />}
          button={<Link to="/projects">Go to Projects</Link>}
        />
      )}
    </>
  );
}
