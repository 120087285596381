import { Css, Tabs } from "@homebound/beam";
import { useCallback } from "react";
import { useScheduleStore } from "./contexts/ScheduleStore";
import { setScheduleViewType } from "./contexts/scheduleStoreReducer";
import { ScheduleViewType } from "./scheduleUtils";

export function ScheduleViewTabs() {
  const {
    scheduleState: { scheduleViewType },
    dispatch,
  } = useScheduleStore();

  const handleOnChange = useCallback(
    (newScheduleViewType: ScheduleViewType) => dispatch(setScheduleViewType(newScheduleViewType)),
    [dispatch],
  );

  const tabs = [
    {
      name: "List",
      value: ScheduleViewType.List,
      // Rendering for the selected views's tab is handled within the `Schedule` component
      render: () => <></>,
    },
    { name: "Gantt", value: ScheduleViewType.Gantt, render: () => <></> },
    { name: "Calendar", value: ScheduleViewType.Calendar, render: () => <></> },
  ];

  return (
    <div css={Css.fg1.$}>
      <Tabs tabs={tabs} onChange={handleOnChange} selected={scheduleViewType} />
    </div>
  );
}
