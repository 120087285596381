import { Css } from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { useCallback, useState } from "react";
import { ProgressPill } from "src/components";
import { AssetGallery, OpenGallery } from "src/components/assetGallery/AssetGallery";
import { UploaderProvider, useUploaderContext } from "src/contexts/UploaderContext";
import {
  JobLogImageDetailFragment,
  useDeleteJobLogImageMutation,
  useSaveJobLogImageMutation,
} from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { formatDate } from "src/routes/projects/job-logs/JobLogsPage";
import { sortBy } from "src/utils";
import { JobLogImageEditor } from "./JobLogImageEditor";

export type UppyAsset = {
  id?: string;
  s3Key: string;
  contentType?: string;
  fileName: string;
};

type JobLogImagesProps = {
  parentId?: string;
  images: JobLogImageDetailFragment[];
  editingEnabled?: boolean;
  compact?: boolean;
  display?: "vertical" | "horizontal";
  description?: string;
  onUpload?: (file: UppyFile, asset: UppyAsset) => void;
  onRemove?: (id: string) => void;
};

export function JobLogImages({
  parentId,
  images,
  editingEnabled = false,
  compact = false,
  display = "vertical",
  description = "",
  onUpload,
  onRemove,
}: JobLogImagesProps) {
  const [saveJobLogImage] = useSaveJobLogImageMutation();
  const [deleteJobLogImage] = useDeleteJobLogImageMutation();
  const sortedImages = sortBy(images, (image: JobLogImageDetailFragment) => image.asset.createdAt, "DESC");

  const [hoveredId, setHoveredId] = useState("");

  async function onNewElement(file: UppyFile) {
    /*
      This id will be settled as same as the UppyFile.id for the parent to be able to
      handle it's removal, this id it's not a real blueprint asset id so it only will
      exist for JobLogNoteForm to handle it
    */
    const asset: UppyAsset = {
      id: !parentId ? file.id : undefined,
      s3Key: file.meta.s3Key as string,
      contentType: file.type,
      fileName: file.name,
    };

    /*
      We call this onUpload only if the note doesn't exists yet
      this is due to the fact the images need to be linked to a parent that exists.

      If this parent doesn't exists we're going to pass the file and asset to the
      parent component (JobLogNoteForm) which will handle the creation and linking of
      this assets later on.
    */
    if (!parentId) return onUpload?.(file, asset);

    // Otherwise if the parent does exists we're going to save the image directly
    const image = await saveJobLogImage({
      variables: {
        input: {
          asset,
          parentId,
        },
      },
    });

    // Also passing the file (with the new and proper id) and asset to the parent for it handle it
    if (onUpload) onUpload({ ...file, id: image.data?.saveJobLogImage.jobLogImage.id! }, asset);
  }

  async function onDelete(id: string) {
    if (onRemove) onRemove(id);

    /*
      If we don't have a parent linked to this image (e.g. the note is new and the image wants to be removed)
      we exit out of this function after calling onRemove in where the parent will handle it's removal
      from the temporal state of the form
    */
    if (!parentId) return;

    await deleteJobLogImage({
      variables: { input: { id } },
    });
  }

  const renderImages = useCallback(
    (openGallery: OpenGallery) => {
      return (
        <>
          {sortedImages.map(({ id, asset }) => (
            <div
              key={id}
              data-testid={id}
              css={Css.mr1.mt1.relative.$}
              onPointerEnter={() => setHoveredId(id)}
              onPointerLeave={() => setHoveredId("")}
            >
              <AssetPreview asset={asset} dimensions={{ width: 100, height: 100 }} onClick={() => openGallery(asset)} />
              {editingEnabled && (
                <div
                  data-testid={`delete-${id}`}
                  css={{
                    ...Css.absolute
                      .rightPx(4)
                      .topPx(4)
                      .bgWhite.bshHover.borderRadius("50%")
                      .p1.cursorPointer.add("transition", "visibility 100ms")
                      .vh.if(hoveredId === id).vv.$,
                    ":hover": Css.bgGray100.$,
                  }}
                  onClick={() => onDelete(id)}
                >
                  <XIcon />
                </div>
              )}
            </div>
          ))}
        </>
      );
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedImages, editingEnabled],
  );

  return (
    <div data-testid="jobLogImages" css={Css.if(display !== "vertical").df.$}>
      {editingEnabled && (
        // If the amount of images is greater than 5 (since the with of the job log - 100px from the uploader)
        // we'll add a padding on the left just to separate the images from the uploader
        <div css={Css.df.mr1.if(sortedImages.length > 5 && display === "horizontal").pl1.$}>
          <UploaderProvider>
            <div css={Css.if(images.length > 0).mt1.$}>
              <JobLogImageEditor newElement={onNewElement} compact={compact} />
            </div>
          </UploaderProvider>
        </div>
      )}
      <div css={Css.oa.$}>
        <AssetGallery
          assets={sortedImages.map((i) => i.asset)}
          display={display}
          caption={({ createdAt }) => `Uploaded on ${createdAt && formatDate(createdAt)} ${description}`}
        >
          {renderImages}
        </AssetGallery>
      </div>
    </div>
  );
}

function FileUploadProgress() {
  const { files } = useUploaderContext();
  if (Object.keys(files).length === 0) return null;

  const fileProgress = Object.keys(files).map((id: string) => {
    const { progress } = files[id];
    if (progress < 100) {
      return (
        <div key={id} css={Css.wPx(100).hPx(100).bn.br4.bshBasic.bgGray100.df.aic.jcc.mt1.mr1.$}>
          <ProgressPill fullWidth progress={progress} hideProgress />
        </div>
      );
    }
  });

  return <>{fileProgress}</>;
}

// TODO Surely use something from Beam
function XIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.192 0.343994L5.94903 4.58599L1.70703 0.343994L0.29303 1.75799L4.53503 5.99999L0.29303 10.242L1.70703 11.656L5.94903 7.41399L10.192 11.656L11.606 10.242L7.36403 5.99999L11.606 1.75799L10.192 0.343994Z"
        fill="#646464"
      />
    </svg>
  );
}
