import { observable, reaction } from "mobx";
import {
  ChangeEventLineItemTradePartnerSelect_BidItemBidContractLineItemsFragment,
  ChangeEventLineItemType,
  ChangeEventLineItemsTabChangeEventLineItemFragment,
  ChangeEventLineItemsTabItemFragment,
  ChangeEventLineItemsTabSelectionFragment,
  ChangeEventLineItemsTabSelectionOptionFragment,
  ChangeEventLineItemsTabUnitOfMeasureFragment,
  CostConfidence,
  CostType,
  DisplayNamedFragment,
  HomeownerSelectionStatus,
  Maybe,
  NamedFragment,
  PotentialOperation2Options,
  ProductSelect_ProductFragment,
  SaveChangeEventLineItemInput,
} from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { calculateMarkup, formatNumberToString } from "src/utils";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";
import { CostSourceType } from "../ChangeEventLineItemsTable";

export class ObservableChangeEventLineItem extends Selectable {
  name!: string;
  totalPriceInCents!: number;
  totalCostInCents!: number;
  unitCostInCents!: number;
  quantity: Maybe<number>;
  costType!: CostType;
  unitOfMeasureId?: string;
  unitOfMeasure: ChangeEventLineItemsTabUnitOfMeasureFragment | undefined | null;
  isSelection!: boolean;
  item!: ChangeEventLineItemsTabItemFragment;
  itemId?: string;
  type!: ChangeEventLineItemType;
  costConfidence!: CostConfidence;
  projectItemId!: string;
  bidItemTemplateItemId?: string;
  developmentId?: string;
  isInternal: boolean;
  location: NamedFragment | undefined | null;
  locationId?: string;
  originalTotalCostInCents!: number;
  originalTotalPriceInCents!: number;
  originalQuantity: Maybe<number>;
  originalTradePartner: NamedFragment | undefined | null;

  allCommittedCostInCents!: number;
  tradePartner: NamedFragment | undefined | null;
  tradePartnerId?: string | null;
  originalSelection: ChangeEventLineItemsTabSelectionFragment | undefined | null;
  canPublish?: PotentialOperation2Options;
  isFinalized: boolean | undefined;
  selectedOption: ChangeEventLineItemsTabSelectionOptionFragment | null | undefined;
  proposedSelection: ChangeEventLineItemsTabSelectionFragment | undefined | null;
  proposedCanPublish?: PotentialOperation2Options;
  proposedIsFinalized: boolean | undefined;
  proposedSelectedOption: ChangeEventLineItemsTabSelectionOptionFragment | null | undefined;
  originalProduct?: ProductSelect_ProductFragment | undefined;
  proposedProduct?: ProductSelect_ProductFragment | undefined;
  proposedProductId?: string | null;
  originalBidItem: DisplayNamedFragment | undefined | null;
  proposedBidItem: DisplayNamedFragment | undefined | null;
  proposedBidItemId?: string | null;
  costSource!: CostSourceType;
  originalBidContractLineItem?: ChangeEventLineItemTradePartnerSelect_BidItemBidContractLineItemsFragment;
  proposedBidContractLineItemId?: string | null;
  proposedBidContractLineItem?: ChangeEventLineItemTradePartnerSelect_BidItemBidContractLineItemsFragment;

  fragment!: ChangeEventLineItemsTabChangeEventLineItemFragment;

  constructor(
    changeEventLineItem: ChangeEventLineItemsTabChangeEventLineItemFragment,
    isInternal: boolean,
    onSave?: (input: SaveChangeEventLineItemInput) => void,
  ) {
    super(changeEventLineItem.id, false);
    this.isInternal = isInternal;
    this.update(changeEventLineItem);
    makeSimpleAutoObservable(this, {
      proposedBidContractLineItem: observable.ref,
      originalBidContractLineItem: observable.ref,
      fragment: observable.ref,
    });

    // Listen for changes to properties that we want to persist.
    if (onSave) {
      reaction(
        () =>
          ({
            costConfidence: this.costConfidence,
            quantity: this.quantity,
            totalPriceInCents: this.totalPriceInCents,
            totalCostInCents: this.totalCostInCents,
            tradePartnerId: this.tradePartnerId,
            proposedBidItemId: this.proposedBidItemId,
            proposedBidContractLineItemId: this.proposedBidContractLineItemId,
            proposedProductId: this.proposedProductId,
            unitOfMeasureId: this.unitOfMeasureId,
          }) satisfies SaveChangeEventLineItemInput,
        (newValues, previousValues) => {
          onSave({ id: this.id, ...calcChanges(previousValues, newValues) });
        },
        { delay: 200 },
      );
    }
  }

  public update(celi: ChangeEventLineItemsTabChangeEventLineItemFragment) {
    this.fragment = celi;
    this.totalPriceInCents = celi.totalPriceInCents;
    this.totalCostInCents = celi.totalCostInCents;
    this.quantity = celi.quantity;
    this.unitOfMeasure = celi.unitOfMeasure;
    this.costConfidence = celi.costConfidenceDetail.code;
    this.projectItemId = celi.projectItem.id;
    this.bidItemTemplateItemId =
      celi.itemTemplateItem?.bidItemTemplateItem?.id || celi.projectItem.itemTemplateItem?.bidItemTemplateItem?.id;
    this.developmentId = celi.projectItem.project.cohort?.development?.id;
    // unitCostInCents is a should only ever be calculated on init or when updating totalCostInCents. Otherwise it is updated directly.
    this.unitCostInCents = this.quantity ? Math.round(this.totalCostInCents / this.quantity) : 0;
    this.originalTotalCostInCents = celi.originalTotalCostInCents;
    this.originalTotalPriceInCents = celi.originalTotalPriceInCents;
    this.originalQuantity = celi.originalQuantity;
    this.originalTradePartner = celi.originalTradePartner;
    this.allCommittedCostInCents = celi.projectItem.allCommittedCostInCents;
    this.tradePartner = celi.tradePartner;
    this.tradePartnerId = celi.tradePartner?.id;
    this.originalSelection = celi.originalSelection;
    this.canPublish = celi.originalSelection?.canPublish;
    this.selectedOption = celi.originalSelection?.selectedOption;
    this.proposedSelection = celi.proposedSelection;
    this.proposedCanPublish = celi.proposedSelection?.canPublish;
    this.proposedSelectedOption = celi.proposedSelection?.selectedOption;
    this.proposedIsFinalized = celi.proposedSelection?.status.code === HomeownerSelectionStatus.Finalized;
    this.isSelection = celi.projectItem.isSelection;
    this.unitOfMeasureId = this.unitOfMeasure?.id;
    this.location = celi.location;
    this.costType = celi.costType;
    this.item = celi.item;
    this.locationId = celi.location?.id;
    this.name = celi.name;
    this.type = celi.type;
    this.originalBidItem = celi.originalBidItem;
    this.proposedBidItem = celi.proposedBidItem;
    this.proposedBidItemId = celi.proposedBidItem?.id;
    this.proposedBidContractLineItemId = celi.proposedBidContractLineItem?.id;
    this.originalProduct = celi.originalProduct ?? undefined;
    this.proposedProduct = celi.proposedProduct ?? undefined;
    this.proposedProductId = celi.proposedProduct?.id;
    this.originalBidContractLineItem = celi.originalBidContractLineItem ?? undefined;
    this.proposedBidContractLineItem = celi.proposedBidContractLineItem ?? undefined;
    this.proposedBidContractLineItemId = celi.proposedBidContractLineItem?.id;
    this.deriveCostSource();
  }

  /**
   * Stitch together the PI's current plan info w/the CELI's plan info, keying
   * by the identityId so that "old PI add <-> new PI add" line up,
   * "old PI modify for option A <-> new PI modify for option A" line up, with
   * the caveat that both sides are optional, but at least one side must be present.
   */
  get itiRows() {
    const celiItisByIdentityId = [this.fragment.itemTemplateItem, ...this.fragment.modifyingTemplateItems]
      .compact()
      .keyBy((iti) => iti.identityId);
    const piItisByOriginalId = [
      this.fragment.projectItem.itemTemplateItem,
      ...this.fragment.projectItem.modifyingTemplateItems,
    ]
      .compact()
      .keyBy((iti) => iti.identityId);

    return [...Object.keys(celiItisByIdentityId), ...Object.keys(piItisByOriginalId)]
      .unique()
      .map((id) => ({ originalItiId: id, piIti: piItisByOriginalId[id], celiIti: celiItisByIdentityId[id] }));
  }

  get disableMarkup(): boolean {
    return this.fragment.projectItem.project.canEditPrice.allowed === false;
  }

  get alertMarkup(): string | undefined {
    const { projectMarkupBasisPoints } = this.fragment.projectItem;
    if (!this.disableMarkup || !projectMarkupBasisPoints || this.totalPriceInCents === 0) return undefined;

    const projectMarkup = projectMarkupBasisPoints / 100;
    return projectMarkup !== this.proposedMarkupPercent
      ? `Markup does not match project markup ${formatNumberToString(projectMarkup)}%`
      : undefined;
  }

  get originalUnitCostInCents() {
    return this.originalQuantity ? Math.floor(this.originalTotalCostInCents / this.originalQuantity) : 0;
  }

  get originalMarkupAmount() {
    return calculateMarkup(this.originalTotalCostInCents, this.originalTotalPriceInCents).markupAmount;
  }

  get originalMarkupPercent() {
    return calculateMarkup(this.originalTotalCostInCents, this.originalTotalPriceInCents).markupPercentage;
  }

  get proposedQuantity() {
    return (this.originalQuantity || 0) + (this.quantity || 0);
  }

  get proposedUnitCostInCents() {
    return this.proposedQuantity ? Math.round(this.proposedTotalCostInCents / this.proposedQuantity) : 0;
  }

  get proposedMarkupAmount() {
    return calculateMarkup(this.proposedTotalCostInCents, this.proposedTotalPriceInCents).markupAmount;
  }

  get proposedMarkupPercent() {
    return calculateMarkup(this.proposedTotalCostInCents, this.proposedTotalPriceInCents).markupPercentage;
  }

  get proposedTotalCostInCents() {
    return this.originalTotalCostInCents + this.totalCostInCents;
  }

  get proposedTotalPriceInCents() {
    return this.originalTotalPriceInCents + this.totalPriceInCents;
  }

  get unitCostChangeInCents() {
    return this.proposedUnitCostInCents - this.originalUnitCostInCents;
  }

  updateProposedTradePartner(tradePartner?: NamedFragment) {
    this.tradePartner = tradePartner;
    this.tradePartnerId = tradePartner?.id;
  }

  updateChangeInQuantity(diff: number) {
    const proposedQuantity = (this.originalQuantity || 0) + (diff || 0);
    this.updateProposedQuantity(proposedQuantity);
  }

  updateProposedQuantity(proposedQty: number) {
    // impacts PQ which impacts PUC??, PTC, PTP
    const currentProposedQty = (this.originalQuantity || 0) + (this.quantity || 0);

    // proposedTotalCostInCents / Proposed QTY = proposedUnitCostInCents
    // proposedUnitCostInCents * new value = proposedTotalCostInCents
    // proposedTotalCostInCents - originalTotalCostInCents = totalCostInCents
    const cost = currentProposedQty
      ? Math.round(proposedQty * ((this.originalTotalCostInCents + this.totalCostInCents) / currentProposedQty))
      : Math.round(proposedQty);
    const totalCost = Math.round(cost - this.originalTotalCostInCents);

    // proposedTotalCostInCents * (current proposedMarkupPecent / 100) + proposedTotalCostInCents = proposedTotalPriceInCents
    // proposedTotalPriceInCents - originalTotalPriceInCents = totalPriceInCents
    const price = Math.round(cost * (this.proposedMarkupPercent / 100) + cost);
    const totalPrice = Math.round(price - this.originalTotalPriceInCents);

    if (!this.isInternal) {
      this.totalPriceInCents = totalPrice;
    }
    this.totalCostInCents = totalCost;
    this.quantity = proposedQty - (this.originalQuantity || 0);

    // Currently when we update the quantity to 0, we lose the unit cost. Therefore, we no longer have the proposed BCLI costs associated with the CELI.
    // TODO/Follow-up: make the unit cost persist when the quantity is changed to 0.
    if (proposedQty === 0) {
      this.updateCostSource(CostSourceType.MANUAL);
    }
  }

  updateProposedUnitCostInCents(proposedUnitCost: number, bcliId?: string) {
    if (bcliId) {
      this.proposedBidContractLineItemId = bcliId;
      if (this.quantity === 0) {
        this.quantity = 1;
      }
    }

    // impacts PUC which impacts PTC, PTP
    const newProposedTotalCost = this.proposedQuantity
      ? Math.round(proposedUnitCost * this.proposedQuantity)
      : Math.round(proposedUnitCost);
    const totalCost = Math.round(newProposedTotalCost - this.originalTotalCostInCents);

    const newProposedTotalPrice = Math.round(
      newProposedTotalCost * (this.proposedMarkupPercent / 100) + newProposedTotalCost,
    );
    const totalPrice = Math.round(newProposedTotalPrice - this.originalTotalPriceInCents);

    if (!this.isInternal) {
      this.totalPriceInCents = totalPrice;
    }
    this.totalCostInCents = totalCost;
  }

  updateProposedMarkupPercent(proposedMarkupPercent: number) {
    const newProposedTotalPrice = Math.round(
      this.proposedTotalCostInCents * (proposedMarkupPercent / 100) + this.proposedTotalCostInCents,
    );
    this.totalPriceInCents = newProposedTotalPrice - this.originalTotalPriceInCents;
  }

  updateChangeInTotalCostInCents(diff: number | undefined) {
    const proposed = this.originalTotalCostInCents + (diff ?? 0);
    this.updateProposedTotalCostInCents(proposed);
  }

  updateProposedTotalCostInCents(proposedTotalCost: number) {
    // impacts PTC which impacts PUC, PTP
    if (!this.isInternal) {
      // Calculate the proposed total price based on the proposed total cost and the proposed markup percent when the markup is not disabled meaning the project can edit price
      if (!this.disableMarkup) {
        this.totalPriceInCents =
          Math.round(proposedTotalCost * (this.proposedMarkupPercent / 100) + proposedTotalCost) -
          this.originalTotalPriceInCents;
      }
    }
    this.totalCostInCents = proposedTotalCost - this.originalTotalCostInCents;
  }

  updateChangeInTotalPriceInCents(diff: number | undefined) {
    if (diff) {
      this.totalPriceInCents = diff;
    }
  }

  updateProposedTotalPriceInCents(proposedTotalPrice: number | undefined) {
    if (proposedTotalPrice) {
      // impacts PTP
      this.totalPriceInCents = proposedTotalPrice - this.originalTotalPriceInCents;
    }
  }

  updateUnitOfMeasureId(unitOfMeasureId?: string) {
    this.unitOfMeasureId = unitOfMeasureId;
  }

  updateCostConfidence(costConfidence: CostConfidence) {
    this.costConfidence = costConfidence;
  }

  updateProposedBidItem(bidItem: DisplayNamedFragment | undefined) {
    this.proposedBidItem = bidItem;
    this.proposedBidItemId = bidItem?.id ?? null;
    // Assume the product & BCLI need to be reassigned
    this.updateProposedProduct(undefined);
    this.updateProposedBidContractLineItem(undefined);
    // Only do this here, b/c if we call it in `updateProposedBidContractLineItem`, then cost source
    // can never change/drift from the derived value
    this.deriveCostSource();
  }

  updateProposedProduct(product: ProductSelect_ProductFragment | undefined) {
    this.proposedProduct = product;
    this.proposedProductId = product?.id ?? null;
  }

  updateCostSource(costSource: CostSourceType) {
    this.costSource = costSource;
    // If the cost source is changed to manual, remove the proposed BCLI
    if (costSource === CostSourceType.MANUAL) {
      this.updateProposedBidContractLineItem(undefined);
    }
  }

  updateProposedBidContractLineItem(
    bcli: ChangeEventLineItemTradePartnerSelect_BidItemBidContractLineItemsFragment | undefined,
  ) {
    this.tradePartnerId = bcli?.revision.bidContract.tradePartner?.id ?? null;
    this.proposedBidContractLineItemId = bcli?.id ?? null;
    this.proposedBidContractLineItem = bcli;
    if (bcli) {
      // Bid Item BCLIs `totalCostInCents` is an itemized cost, i.e. total cost === unit cost
      this.updateProposedUnitCostInCents(bcli.totalCostInCents, bcli.id);
    }
  }

  private deriveCostSource() {
    // Unlike other "current values", where we do `original BI || proposed BI`, we always look at
    // proposed BCLI for the cost source, b/c we want to allow the user to turn it on/off like a toggle
    this.costSource = this.proposedBidContractLineItemId ? CostSourceType.DEV_CONTRACT : CostSourceType.MANUAL;
  }
}

// export for testing
export function calcChanges<T extends Record<string, unknown>>(original: T, potentialChanges: Partial<T>): T {
  const changed: Record<string, unknown> = {};
  Object.keys(original).forEach((k) => {
    if (original[k] !== potentialChanges[k]) {
      changed[k] = potentialChanges[k];
    }
  });
  return changed as T;
}
