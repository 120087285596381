import {
  CostCodeFragment,
  CostDivisionFragment,
  HomeownerContractChangeOrderWithProjectItemsFragment,
  HomeownerContractLineItemFragment,
  HomeownerContractWithProjectItemsFragment,
  Maybe,
} from "src/generated/graphql-types";
import { groupBy, isNumber, sortBy } from "src/utils";

export type CostData = {
  budgetChangeInCents?: Maybe<number>;
  priceChangeInCents?: Maybe<number>;
};

export type CostDivisionSummary = {
  division: CostDivisionFragment;
  total: CostData;
  costCodes: CostCodeSummary[];
};

type CostCodeSummary = {
  costCode: CostCodeFragment & { division: CostDivisionFragment };
  total: CostData;
  lineItems: HomeownerContractLineItemFragment[];
};

type SimpleCostCodeSummary = {
  costCode: CostCodeFragment & { division: CostDivisionFragment };
  total: CostData;
  lineItem: HomeownerContractLineItemFragment;
};

export function lineItemTotals(lineItems: HomeownerContractLineItemFragment[]): CostData {
  return lineItems.reduce(sumCostData, {});
}

export function groupLineItemsByDivisionAndCostCode(
  contract: HomeownerContractWithProjectItemsFragment | HomeownerContractChangeOrderWithProjectItemsFragment,
): CostDivisionSummary[] {
  const costData: SimpleCostCodeSummary[] = contract.lineItems.map((li) => {
    return {
      costCode: li.costCode,
      total: {
        budgetChangeInCents: li.budgetChangeInCents,
        priceChangeInCents: li.priceChangeInCents,
      },
      lineItem: li,
    };
  });

  const summaryByDivision: CostDivisionSummary[] = Object.values(
    groupBy(costData, (cd) => cd.costCode.division.number),
  ).map((cds) => {
    // cds is all CostData for an entire division, so groupBy again but by cost code
    const costCodes = Object.values(groupBy(cds, (cd) => cd.costCode.number)).map((cds) => {
      return {
        costCode: cds[0].costCode,
        // Only invoke setDerivedValues after we've combined homeowner & commitment info into a single line
        total: cds.map((cd) => cd.total).reduce(sumCostData, {}),
        lineItems: sortBy(
          cds.map((cd) => cd.lineItem),
          (li) => li.projectItem.displayName,
        ),
      };
    });

    return {
      division: cds[0].costCode.division,
      total: costCodes.map((cc) => cc.total).reduce(sumCostData, {}),
      costCodes,
    };
  });

  // TODO apply sorting

  return Object.values(summaryByDivision);
}

const headerRowDataKeys: ReadonlyArray<keyof CostData> = ["budgetChangeInCents", "priceChangeInCents"];

export function sumCostData(a: CostData, b: CostData): CostData {
  return Object.fromEntries(
    headerRowDataKeys.map((key) => {
      const keepUndefined = !isNumber(a[key]) && !isNumber(b[key]);
      if (keepUndefined) {
        return [key, undefined];
      }
      return [key, (a[key] || 0) + (b[key] || 0)];
    }),
  );
}
