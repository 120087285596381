import { ScrollableContent } from "@homebound/beam";
import { HistoryFeed } from "src/components";
import { useProjectCutoffHistoryQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type ProjectCutoffHistoryTabProps = {
  projectId: string;
};
export function ProjectCutoffHistoryTab({ projectId }: ProjectCutoffHistoryTabProps) {
  const query = useProjectCutoffHistoryQuery({ variables: { projectId } });

  return queryResult(query, {
    data: ({ project: { cutoffs } }) => {
      const cutoffHistory = cutoffs
        .flatMap((cutoff) => cutoff.history)
        .sort((a, b) => b.recordedAt.getTime() - a.recordedAt.getTime());
      return (
        <ScrollableContent>
          <HistoryFeed historyItems={cutoffHistory} />
        </ScrollableContent>
      );
    },
  });
}
