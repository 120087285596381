import { SuperDrawerContent, SuperDrawerHeader } from "@homebound/beam";
import { CommentFeed } from "src/components/comments/CommentFeed";
import { useGetCommentsQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type CommentsSidebarProps = {
  commentableId: string;
};

export function CommentsSidebar(props: CommentsSidebarProps) {
  const { commentableId } = props;
  const query = useGetCommentsQuery({ variables: { commentableId } });

  return (
    <SuperDrawerContent>
      {queryResult(query, ({ commentable }) => (
        <>
          <SuperDrawerHeader title={commentable.name ?? commentable.id} hideControls />
          <CommentFeed commentable={commentable} />
        </>
      ))}
    </SuperDrawerContent>
  );
}
