import { sum } from "src/utils";

/**
 * Given a `total` amount, and some ratios (i.e. percentages), returns
 * the amount prorated into smaller amounts based on the ratios.
 *
 * I.e. the ratios, if percentages, would add up to 100, but don't have to.
 *
 * This function is also careful to only return whole numbers (i.e. it assumes
 * using something like cents) and that the sum of the return values will exactly
 * equal the original total amount (without any rounding errors).
 */
export function prorate(total: number, ratios: number[]): number[] {
  const demoninator = ratios.reduce(sum, 0);

  const result: number[] = [];
  for (let i = 0; i < ratios.length; i++) {
    result[i] = Math.floor((total * ratios[i]) / demoninator);
  }

  const left = total - result.reduce(sum, 0);
  const parts = distributeEvenly(left, result.length);
  for (let i = 0; i < result.length; i++) {
    result[i] += parts[i];
  }

  return result;
}

function distributeEvenly(total: number, parts: number): number[] {
  if (parts === 0) {
    return [];
  } else {
    // Each part gets the evenly-divided amount
    const each = Math.floor(total / parts);
    const result = [...Array(parts)].map(() => each);
    // And the modulo we spread out from the last one backwards
    let rest = total % parts;
    let i = parts - 1;
    while (rest-- > 0) {
      result[i--] += 1;
    }
    return result;
  }
}
