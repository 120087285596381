import { LoadingSkeleton, LoadingSkeletonProps } from "@homebound/beam";

export type LoadingTableProps = Pick<LoadingSkeletonProps, "columns">;

export function LoadingTable(props: LoadingTableProps) {
  const { columns } = props;
  return (
    <>
      {/* Header row */}
      <LoadingSkeleton rows={1} columns={1} />
      {/* Line Item rows - default to 5 rows/columns */}
      <LoadingSkeleton rows={5} columns={columns ?? 5} />
    </>
  );
}
