import { column, Css, emptyCell, GridColumn, GridDataRow, GridTable, simpleHeader } from "@homebound/beam";
import { Link } from "react-router-dom";
import { dateCell, emptyCellDash, tagCell } from "src/components";
import {
  BudgetSuperDrawer_CommitmentChangeOrderFragment,
  BudgetSuperDrawer_CommitmentFragment,
  CommitmentStatus,
} from "src/generated/graphql-types";
import { commitmentStatusToTagTypeMapper, formatCentsToPrice, sum } from "src/utils";

type BudgetCommitmentsTableProps = {
  commitments: BudgetSuperDrawer_CommitmentFragment[];
  commitmentChangeOrders: BudgetSuperDrawer_CommitmentChangeOrderFragment[];
  projectItemIds: string[];
  projectId: string;
};

export function BudgetCommitmentsTable(props: BudgetCommitmentsTableProps) {
  const { commitments, commitmentChangeOrders, projectId, projectItemIds } = props;
  const rows = createRows(commitments, commitmentChangeOrders, projectItemIds);
  const columns = createColumns(projectId, projectItemIds);

  return (
    <div>
      <div css={Css.smSb.h3.mbPx(10).$} data-testid="title">
        Commitments
      </div>
      <GridTable id="commitments" columns={columns} rows={rows} />
    </div>
  );
}

function createRows(
  commitments: BudgetSuperDrawer_CommitmentFragment[],
  commitmentChangeOrders: BudgetSuperDrawer_CommitmentChangeOrderFragment[],
  projectItemIds: string[],
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...commitments.map((c) => ({
      kind: "commitment" as const,
      id: c.id,
      data: c,
    })),
    ...commitmentChangeOrders.map((cco) => ({
      kind: "commitmentChangeOrder" as const,
      id: cco.id,
      data: cco,
    })),
    {
      kind: "totals" as const,
      id: "total",
      data: {
        commitmentLikes: sum(
          commitments.sum(({ committedInCents, status }) =>
            status === CommitmentStatus.Signed ? committedInCents : 0,
          ),
          commitmentChangeOrders.sum(({ committedInCents, status }) =>
            status === CommitmentStatus.Signed ? committedInCents : 0,
          ),
        ),
        perItems: sum(
          commitments.sum(({ lineItems, status }) =>
            status === CommitmentStatus.Signed
              ? lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.costChangeInCents || 0 : 0))
              : 0,
          ),
          commitmentChangeOrders.sum(({ lineItems, status }) =>
            status === CommitmentStatus.Signed
              ? lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.costChangeInCents || 0 : 0))
              : 0,
          ),
        ),
      },
    },
  ];
}

function createColumns(projectId: string, projectItemIds: string[]): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Commitment",
      commitment: ({ accountingNumber, blueprintUrl }) => (
        <Link to={blueprintUrl.path}>{`PO #${accountingNumber}`}</Link>
      ),
      commitmentChangeOrder: ({ accountingNumber, blueprintUrl }) => (
        <Link to={blueprintUrl.path}>{`CO #${accountingNumber}`}</Link>
      ),
      totals: "Total",
      w: "100px",
    }),
    column<Row>({
      header: "Status",
      commitment: ({ status }) => tagCell(commitmentStatusToTagTypeMapper[status], status),
      commitmentChangeOrder: ({ status }) => tagCell(commitmentStatusToTagTypeMapper[status], status),
      totals: emptyCell,
      w: "80px",
    }),
    column<Row>({
      header: "Trade Partner",
      commitment: ({ tradePartner }) => tradePartner?.name,
      commitmentChangeOrder: ({ tradePartner }) => tradePartner?.name,
      totals: emptyCell,
      w: "190px",
    }),
    column<Row>({
      header: "Executed",
      commitment: ({ executionDate }) => dateCell(executionDate),
      commitmentChangeOrder: ({ executionDate }) => dateCell(executionDate),
      totals: emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: "Cost",
      commitment: ({ committedInCents }) => formatCentsToPrice(committedInCents),
      commitmentChangeOrder: ({ committedInCents }) => formatCentsToPrice(committedInCents),
      totals: (data) => formatCentsToPrice(data.commitmentLikes),
      w: "100px",
    }),
    column<Row>({
      header: "Per Item Cost",
      commitment: ({ lineItems }) =>
        // If cli is for the current budget super drawer item sum cli in the `per item cost` col
        formatCentsToPrice(
          lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.costChangeInCents || 0 : 0)),
        ),
      commitmentChangeOrder: ({ lineItems }) =>
        // If cli is for the current budget super drawer item sum cli in the `per item cost` col
        formatCentsToPrice(
          lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.costChangeInCents || 0 : 0)),
        ),
      totals: (data) => formatCentsToPrice(data.perItems),
      w: "100px",
    }),
    column<Row>({
      header: "Per Item Trade Paid",
      // If cli is for the current budget super drawer item sum cli to `trade paid to date col`
      commitment: ({ lineItems }) =>
        formatCentsToPrice(
          lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.paidInCents || 0 : 0)),
        ),
      commitmentChangeOrder: ({ lineItems }) =>
        formatCentsToPrice(
          lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.paidInCents || 0 : 0)),
        ),
      totals: emptyCell,
      w: "120px",
    }),
    column<Row>({
      header: "Per Item Unpaid",
      // If cli is for the current budget super drawer item sum cli to `unpaid col`
      commitment: ({ lineItems }) =>
        formatCentsToPrice(
          lineItems.sum((li) =>
            projectItemIds.includes(li.projectItem.id) ? li.committedInCents - (li.billedInCents || 0) : 0,
          ),
        ),
      commitmentChangeOrder: ({ lineItems }) =>
        formatCentsToPrice(
          lineItems.sum((li) =>
            projectItemIds.includes(li.projectItem.id) ? li.committedInCents - (li.billedInCents || 0) : 0,
          ),
        ),
      totals: emptyCell,
      w: "100px",
    }),

    column<Row>({
      header: "Source Agreement",
      commitment: ({ developmentCommitment, bidContractRevision }) => ({
        content: developmentCommitment?.name ? (
          <div css={Css.xs.$}>
            <Link to={developmentCommitment.blueprintUrl.path}>{developmentCommitment?.name}</Link>
          </div>
        ) : bidContractRevision ? (
          <div css={Css.xs.$}>
            {<Link to={bidContractRevision.blueprintUrl.path}>{bidContractRevision?.bidContract?.name}</Link>}
          </div>
        ) : (
          emptyCellDash
        ),
      }),
      commitmentChangeOrder: emptyCell,
      totals: emptyCell,
      w: "290px",
    }),
  ];
}

type Row = { kind: "header" } | CommitmentRow | CommitmentChangeOrderRow | TotalRow;

type CommitmentRow = {
  kind: "commitment";
  id: string;
  data: BudgetSuperDrawer_CommitmentFragment;
};

type CommitmentChangeOrderRow = {
  kind: "commitmentChangeOrder";
  id: string;
  data: BudgetSuperDrawer_CommitmentChangeOrderFragment;
};

type TotalRow = {
  kind: "totals";
  data: { commitmentLikes: number; perItems: number };
};
