import {
  AutoSaveIndicator,
  BoundMultiSelectField,
  BoundNumberField,
  BoundRichTextField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  StaticField,
  SuperDrawerHeader,
  Switch,
  useComputed,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { BidItemBoundSelectField } from "src/components/autoPopulateSelects/BidItemBoundSelectField";
import { FormActions } from "src/components/formFields/FormActions";
import {
  CostType,
  ItemTemplateItemLineItemFragment,
  ReadyPlanOption,
  useItemTemplateItemDetailQuery,
  useItemTemplateItemProductsQuery,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { useCalculatePriceFields } from "src/routes/projects/selections/recalc";
import { doInNextLoop, safeEntries } from "src/utils";
import { useFormState } from "src/utils/formState";
import { itemTemplateItemFormConfig } from "src/utils/itemTemplateItem";
import { DeleteItemTemplateVersionsModal } from "./DeleteItemTemplateVersionsModal";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

export type ItemTemplateItemDetailProps = {
  itApi: ItemTemplateApi;
  itemTemplateItem: ItemTemplateItemLineItemFragment | undefined;
  elevations?: Pick<ReadyPlanOption, "id" | "shortName" | "name">[];
  specLevels?: Pick<ReadyPlanOption, "id" | "name">[];
  options?: Pick<ReadyPlanOption, "id" | "displayName">[];
};

/** Creates/updates an item template. */
export function ItemTemplateItemDetail(props: ItemTemplateItemDetailProps) {
  const { addCanCloseDrawerCheck, closeDrawer } = useSuperDrawer();
  const { openModal } = useModal();
  const { itemTemplateItem, elevations, itApi, specLevels, options } = props;
  const isNew = !itemTemplateItem;

  const query = useItemTemplateItemDetailQuery({ fetchPolicy: "cache-first" });

  const formState = useFormState({
    config: itemTemplateItemFormConfig,
    init: {
      input: itemTemplateItem,
      map: (item) => ({
        ...item,
        specLevelDependent: item.specOptionIds.nonEmpty,
        locationId: item.location.id,
        unitOfMeasureId: item.unitOfMeasure.id,
        unitCostInCents: !item.unitOfMeasure.useQuantity
          ? undefined
          : Math.round(!item.quantity ? 1 : item.totalCostInCents / item.quantity),
        itemId: item.item.id,
        bidItemId: item.bidItem?.id,
        baseProductId: item.baseProduct?.id,
      }),
    },
    autoSave: async (state) => {
      if (!isNew) {
        const { unitCostInCents, ...others } = state.changedValue;
        await itApi.saveItiv(others);
      }
    },
  });

  const itemId = useComputed(() => formState.itemId.value, [formState]);
  const productsQuery = useItemTemplateItemProductsQuery({
    variables: { itemId: itemId! },
    skip: !itemId,
  });

  const items = query.data?.items || [];
  const isItemsLoading = query.loading;
  const locations = query.data?.locations || [];
  const unitsOfMeasure = query.data?.unitsOfMeasure || [];
  const productsForCurrentItem = productsQuery.data?.products || [];

  // If we're not new, assume auto-save is fast/good enough
  if (isNew) {
    addCanCloseDrawerCheck(() => !formState.dirty);
  }

  const [tradeCategoryName, costCodeName, itemName] = useComputed(() => {
    const itemId = formState.itemId.value;
    const item = items.find((i) => i.id === itemId);
    if (item) {
      return [item.tradeCategory?.name, item.costCode.name, item.name];
    }
    return [];
  }, [formState, items]);

  // Re-calc cost/markup/price as they change
  useCalculatePriceFields(
    () => ({
      quantity: formState.quantity.value,
      unitCostInCents: formState.unitCostInCents.value,
      totalCostInCents: formState.totalCostInCents.value,
    }),
    (update) => {
      const { quantity, unitCostInCents, totalCostInCents } = update;
      formState.set({
        quantity,
        unitCostInCents,
        totalCostInCents,
      });
    },
    { canEditPrice: true },
    [formState],
  );

  const useQuantity = useComputed(
    (prev) => {
      const uomId = formState.unitOfMeasureId.value;
      const uom = unitsOfMeasure.find((uom) => uom.id === uomId);
      const curr = !uom ? true : uom.useQuantity;
      // If going from useQuantity=false --> useQuantity=true, reset quantity
      if (prev === false && curr) {
        formState.quantity.set(1);
      } else if (prev === true && !curr) {
        formState.quantity.set(undefined);
      }
      return curr;
    },
    [formState, unitsOfMeasure],
  );

  const isSelection = useComputed(() => {
    if (isItemsLoading) return false;
    return formState.isSelection.value;
  }, [formState, isItemsLoading]);

  const isSelectionOptionAvailable = useComputed(() => {
    if (isItemsLoading) return false;
    const itemId = formState.itemId.value;
    const item = items.find((i) => i.id === itemId);
    return item?.isSelection && formState.costType.value === CostType.Materials;
  }, [formState, items, isItemsLoading]);

  const onItemSelection = (itemId: any | undefined) => {
    formState.itemId.set(itemId);
    setIsSelectionAfterChange();
  };

  const onCostTypeSelection = (costType: CostType | undefined) => {
    formState.costType.set(costType);
    setIsSelectionAfterChange();
  };

  const setIsSelectionAfterChange = () => {
    formState.isSelection.set(getIsSelectionForItemAndCostType());
  };

  const getIsSelectionForItemAndCostType = (): boolean => {
    const itemId = formState.itemId.value;
    const item = items.find((i) => i.id === itemId);
    if (!item) return false;
    return item.isSelection && formState.costType.value === CostType.Materials;
  };

  const readOnlyFinancials = itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit);
  const readOnlySpecifications = itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit);
  const noteLines = (
    <FormLines width="full">
      <FormHeading title="Notes" />
      <BoundRichTextField
        field={formState.specifications}
        label="Specifications (visible to everyone)"
        readOnly={!!readOnlySpecifications}
      />
      <BoundTextField
        label="Trade Partner Note (only visible to trades)"
        field={formState.tradePartnerNote}
        readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
      />
      <BoundTextField
        label="Internal Note (only visible to Homebound employees)"
        field={formState.internalNote}
        readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
      />
    </FormLines>
  );

  const slotLines = (
    <FormLines>
      <FormHeading title="Slot" />
      {isNew ? (
        <BoundSelectField field={formState.itemId} options={items} readOnly={!isNew} onSelect={onItemSelection} />
      ) : (
        <>
          <StaticField label="Cost Code" value={costCodeName} />
          <StaticField label="Item" value={itemName} />
          <BoundTextField
            label="Display name"
            field={formState.name}
            readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
          />
        </>
      )}
      <BoundSelectField
        label="Location"
        field={formState.locationId}
        options={locations}
        readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
      />
      <BoundSelectField
        label="Unit Of Measure"
        options={unitsOfMeasure}
        field={formState.unitOfMeasureId}
        readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
      />
      <BoundSelectField
        field={formState.costType}
        options={safeEntries(CostType)}
        getOptionLabel={([name]) => name}
        getOptionValue={([, code]) => code}
        readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
        onSelect={onCostTypeSelection}
      />
      <div css={Css.db.if(!isSelectionOptionAvailable).dn.$} data-testid="isSelectionContainer">
        <Switch
          data-testid="isSelection"
          label="Enable Selection"
          labelStyle="left"
          selected={formState.isSelection.value!}
          disabled={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
          onChange={(value) => formState.isSelection.set(value)}
        />
      </div>
    </FormLines>
  );

  const otherOptions = itemTemplateItem?.otherOptionIds.map((id) => options?.find((o) => o.id === id)).compact();
  const costLines = (
    <FormLines width="full" labelStyle="left" compact>
      <FormHeading title={isSelection ? "Product Selection" : "Spec"} data-testid="itemTypeTitle" />
      <div css={Css.py0.$}>
        {!isNew && (
          <>
            <StaticField
              label="Base/Options"
              value={otherOptions?.map((rpo) => rpo.displayName).join(", ") || "Base House"}
            />
            <StaticField label="Trade Category" value={tradeCategoryName} />
          </>
        )}
      </div>

      {isSelection ? (
        <BoundSelectField
          // Ideally we'd use `FormLines.labelSuffix` but quantity and unit cost are not yet conditionally required.
          label="Selection (Optional)"
          field={formState.baseProductId}
          options={itemTemplateItem?.bidItem?.products ?? productsForCurrentItem}
          data-testid="selection"
          disabled={!productsForCurrentItem.length}
          readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
          placeholder={!productsForCurrentItem.length ? "No available selections for Item" : undefined}
        />
      ) : (
        <StaticField label="Selection (Optional)" value="N/A" data-testid="selection" />
      )}
      {itApi.template.isReadyHomeTemplate && !isNew && (
        // Only applicable to ready home templates, and requires a ITI id, thus the !isNew check
        <BidItemBoundSelectField
          value={itemTemplateItem?.bidItem}
          field={formState.bidItemId}
          filter={{ itemTemplateItemId: itemTemplateItem?.scope?.id }}
          readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
          onSelect={(bidItemId, bidItem) => {
            // Unsets the bid item and base product
            if (bidItemId === undefined) {
              formState.set({ bidItemId: null, baseProductId: null });
            } else {
              formState.bidItemId.set(bidItemId);
              // Select product by default if there's just one available, if not, unset the base product
              bidItem?.products.length === 1
                ? formState.baseProductId.set(bidItem.products.first?.id)
                : formState.baseProductId.set(null);
            }
          }}
        />
      )}
      {itApi.template.isReadyHomeTemplate && (
        // Only applicable to ready home templates
        <BoundMultiSelectField
          label="Elevation"
          getOptionLabel={(e) => `${e.shortName ? `${e.shortName} - ` : ""}${e.name}`}
          getOptionValue={(e) => e.id}
          options={[
            { name: "All", id: undefined as any, shortName: undefined },
            ...(elevations?.sortBy((e) => `${e.shortName ? `${e.shortName} - ` : ""}${e.name}`) || []),
          ]}
          field={formState.elevationIds}
          readOnly={itemTemplateItem && disableBasedOnPotentialOperation(itemTemplateItem.canEdit)}
        />
      )}
      {useQuantity ? (
        <BoundNumberField
          label="Unit Cost"
          type="cents"
          field={formState.unitCostInCents}
          readOnly={readOnlyFinancials}
        />
      ) : (
        <StaticField label="Unit Cost" value="N/A" />
      )}
      {useQuantity ? (
        <BoundNumberField label="Quantity" field={formState.quantity} readOnly={readOnlyFinancials} />
      ) : (
        <StaticField label="Quantity" value="N/A" />
      )}

      <BoundNumberField
        label="Total cost"
        type="cents"
        field={formState.totalCostInCents}
        readOnly={readOnlyFinancials}
      />
    </FormLines>
  );

  const specOptions = itemTemplateItem?.specOptionIds.map((id) => specLevels?.find((o) => o.id === id)).compact();

  return (
    <>
      <SuperDrawerHeader
        title={
          itemTemplateItem
            ? `${itemTemplateItem.displayName}${
                specOptions?.nonEmpty ? ` - ${specOptions.map((rpo) => rpo.name).join(", ")}` : ""
              }`
            : "Add Item"
        }
        left={<AutoSaveIndicator />}
        right={
          isNew ? (
            <FormActions
              mode="create"
              formState={formState}
              onEdit={() => {}}
              onSave={async () => {
                const { unitCostInCents, ...others } = formState.changedValue;
                await itApi.saveItiv(others);
                formState.commitChanges();
                doInNextLoop(closeDrawer);
              }}
              onCancel={closeDrawer}
            />
          ) : (
            <Button
              variant="secondary"
              label="Delete"
              data-testid="deleteBtn"
              disabled={disableBasedOnPotentialOperation(itemTemplateItem.canDeleteItem)}
              onClick={async () => {
                openModal({
                  content: <DeleteItemTemplateVersionsModal itApi={itApi} selectedItems={[itemTemplateItem.id]} />,
                });
                closeDrawer();
              }}
            />
          )
        }
      />
      <div css={Css.df.aifs.gap2.jcsb.$}>
        <div>
          {noteLines}
          {slotLines}
        </div>
        <div css={Css.p2.br4.wPx(420).$}>{costLines}</div>
      </div>
    </>
  );
}

function FormHeading(props: { title: string }) {
  const { title, ...others } = props;
  return (
    <div css={Css.baseMd.$} {...others}>
      {title}
    </div>
  );
}
