import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  IconButton,
  useRightPane,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import {
  SaveScheduleSubPhaseInput,
  useSaveScheduleSubPhasesMutation,
  useScheduleTaskPhasesQuery,
} from "src/generated/graphql-types";

type AddSubPhasePaneProps = {
  scheduleId: string;
  phaseId: string;
  scrollToSubPhase?: (subPhaseId: string) => void;
  refetchQueries?: string[];
};

export function AddSubPhasePane({
  scheduleId,
  phaseId,
  scrollToSubPhase,
  refetchQueries = ["SchedulePhases"],
}: AddSubPhasePaneProps) {
  const { closeRightPane } = useRightPane();
  const { triggerNotice } = useSnackbar();
  const phaseQuery = useScheduleTaskPhasesQuery({
    variables: { scheduleId },
  });
  const schedulephases = phaseQuery.data?.schedule.phases;
  const [saveScheduleSubPhase] = useSaveScheduleSubPhasesMutation();

  const formConfig: ObjectConfig<SaveScheduleSubPhaseInput> = useMemo(
    () => ({
      globalSubPhaseId: { type: "value", rules: [required] },
      schedulePhaseId: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { globalSubPhaseId: "", schedulePhaseId: phaseId },
    },
  });

  const onSubmit = async () => {
    const { globalSubPhaseId, schedulePhaseId } = formState.value;
    const result = await saveScheduleSubPhase({
      refetchQueries,
      variables: {
        input: {
          scheduleSubPhases: [
            {
              globalSubPhaseId,
              schedulePhaseId,
            },
          ],
        },
      },
    });

    if (result.data) {
      const subPhaseId = result.data.saveScheduleSubPhases.scheduleSubPhases[0].id;
      triggerNotice({
        icon: "success",
        message: "SubPhase successfully added!",
      });
      // Wait for the subphase to be available in the list
      if (scrollToSubPhase) setTimeout(() => scrollToSubPhase(subPhaseId), 2000);
    }
    closeRightPane();
  };

  return (
    <div css={Css.px4.pt3.pbPx(60).bgWhite.h100.oa.$} data-testid="add_subPhase_pane">
      <div css={Css.df.jcsb.aic.gap1.mb3.$}>
        <div css={Css.lgSb.$} data-testid="add_subPhase_pane_title">
          Add Subphase to Phase
        </div>
        <IconButton data-testid="closePane" icon="x" onClick={closeRightPane} />
      </div>
      <FormLines width="full">
        <BoundTextField field={formState.globalSubPhaseId} label="Subphase Name" />
        <BoundSelectField
          field={formState.schedulePhaseId}
          label="Phase"
          compact
          options={schedulephases || []}
          disabled={schedulephases?.isEmpty}
        />
      </FormLines>
      <div css={Css.px4.df.aic.jcfe.bt.bcGray200.mxPx(-32).wPx(450).hPx(70).fixed.bottom0.bgWhite.$}>
        <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeRightPane} />
        <Observer>{() => <Button label="Add Subphase" onClick={onSubmit} disabled={!formState.valid} />}</Observer>
      </div>
    </div>
  );
}
