import { Css, IconButton, useRightPane, useTestIds } from "@homebound/beam";
import { useCallback } from "react";
import {
  SaveScheduleTaskInput,
  TaskLookaheadRightPaneScheduleTaskFragment,
  useSaveScheduleTasksMutation,
  useTaskLookaheadRightPaneQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { StandaloneTaskDetailPane } from "../../schedule/components/StandaloneTaskDetailPane";
import { TaskSidebarListItem } from "../../schedule/components/TaskSidebarListItem";
import type { WeekInterval } from "../useWeekRangeFilter";
import type { CellSelectionState } from "./TaskLookaheadTable";

type ProjectTaskByWeekRightPaneProps = {
  weekInterval: WeekInterval;
  projectId: string;
  setSelectedCell: React.Dispatch<CellSelectionState>;
};

export function ProjectTaskByWeekRightPane({
  projectId,
  weekInterval,
  setSelectedCell,
}: ProjectTaskByWeekRightPaneProps) {
  const tid = useTestIds({}, "projectTaskByWeekRightPane");
  const { closeRightPane } = useRightPane();

  const query = useTaskLookaheadRightPaneQuery({
    variables: { projectId, searchIntervalStart: weekInterval.start, searchIntervalEnd: weekInterval.end },
  });

  const onClose = useCallback(() => {
    setSelectedCell(undefined);
    closeRightPane();
  }, [setSelectedCell, closeRightPane]);

  return queryResult(query, {
    data: ({ scheduleTasks, project }) => {
      return (
        <div {...tid} css={Css.p4.h100.df.fdc.$}>
          <div css={Css.df.jcsb.mb2.$}>
            <span css={Css.lgSb.$}>{project.name}</span>
            <IconButton icon="x" onClick={onClose} />
          </div>
          <div css={Css.baseSb.pb1.bb.bcGray300.$}>Tasks in this Project this Week</div>
          <div css={Css.os.pt2.$}>
            {scheduleTasks.map((scheduleTask) => (
              <WrappedTaskSidebarListItem
                key={scheduleTask.id}
                scheduleTask={scheduleTask}
                setSelectedCell={setSelectedCell}
              />
            ))}
          </div>
        </div>
      );
    },
  });
}

type WrappedTaskSidebarListItemProps = {
  scheduleTask: TaskLookaheadRightPaneScheduleTaskFragment;
  setSelectedCell: React.Dispatch<CellSelectionState>;
};

function WrappedTaskSidebarListItem({ setSelectedCell, scheduleTask }: WrappedTaskSidebarListItemProps) {
  const { schedule } = scheduleTask;
  const { closeRightPane, openRightPane } = useRightPane();
  const [saveScheduleTasks] = useSaveScheduleTasksMutation();

  const onSave = useCallback(
    async (changedValue: SaveScheduleTaskInput) => {
      await saveScheduleTasks({ variables: { input: { tasks: [{ ...changedValue, id: scheduleTask.id }] } } });
    },
    [scheduleTask.id, saveScheduleTasks],
  );

  const onClose = useCallback(() => {
    setSelectedCell(undefined);
    closeRightPane();
  }, [setSelectedCell, closeRightPane]);

  const onTaskSelect = useCallback(() => {
    openRightPane({
      content: (
        <StandaloneTaskDetailPane
          taskId={scheduleTask.id}
          scheduleId={schedule.id}
          stage={schedule.stage}
          onClose={onClose}
          onSave={onSave}
          disableQueryParams
        />
      ),
    });
  }, [openRightPane, schedule.id, schedule.stage, scheduleTask.id, onSave, onClose]);

  return <TaskSidebarListItem key={scheduleTask.id} scheduleTask={scheduleTask} onClick={onTaskSelect} />;
}
