import React from "react";
import { Maybe } from "src/generated/graphql-types";

export type HasChildren = {
  children: React.ReactNode;
  "data-testid"?: string;
};

export function isNumber(num: Maybe<number>): num is number {
  return num !== undefined && num !== null;
}

export type HasIdAndName = {
  id: string;
  name: string;
};

/** Infers the inner type of an array, so string[] --> string, or Project[] --> Project */
export type ArrayElement<T> = T extends Array<infer U> ? U : never;

/** Filters out any keys of <T> that refer to nested or objects */
export type SimpleKeys<T> = {
  [K in keyof T as T[K] extends object ? never : K]: T[K];
};

/**
 * Converts all keys of some object to a different type. For example remapping `{ a: string; b: string }`
 * to `{ a: (input: string) => void; b: (input: string) => void }` and keeping them tightly linked.
 */
export type Remap<T, U> = { [K in keyof T]: U };
