import { Button, ButtonMenu, Css, TabsWithContent, Tag, useModal } from "@homebound/beam";
import { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createDevelopmentCommitmentsUrl } from "src/RouteUrls";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  CommitmentStatus,
  DocumentType,
  useDeleteDevelopmentCommitmentMutation,
  useDevelopmentCommitmentQuery,
} from "src/generated/graphql-types";
import { useTabParam } from "src/hooks/useTabParam";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { PandaDocUploadButton } from "src/routes/components/PandaDocUploadButton";
import { HistoryTab } from "src/routes/development-commitments/components/HistoryTab";
import { LineItemsTab } from "src/routes/development-commitments/components/LineItemsTab";
import { OverviewTab } from "src/routes/development-commitments/components/OverviewTab";
import { PandaDocConfirmationModal } from "src/routes/development-commitments/components/PandaDocConfirmationModal";
import { ProjectsTab } from "src/routes/development-commitments/components/ProjectsTab";
import { ScheduleAndScopeTab } from "src/routes/development-commitments/components/ScheduleAndScopeTab";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentCommitmentParams } from "src/routes/routesDef";
import { commitmentStatusToTagTypeMapper, hasData, renderLoadingOrError } from "src/utils";
import { UploadPriceAgreementModal } from "../developments/components/UploadPriceAgreementModal";

export function DevelopmentCommitmentPage() {
  const { developmentCommitmentId } = useParams<DevelopmentCommitmentParams>();
  const query = useDevelopmentCommitmentQuery({ variables: { id: developmentCommitmentId } });
  const [deleteDevelopmentCommitment] = useDeleteDevelopmentCommitmentMutation();
  const { openModal } = useModal();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useTabParam("overview");
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const dc = query.data.developmentCommitment;
  const developmentId = dc.development.id;
  const uploadUrl = dc.pandaDoc?.externalPandaDocUrl;
  const asset = dc.pandaDoc?.document?.asset || dc.document?.asset;

  return (
    <div>
      <PageHeader
        breadcrumb={{ label: "All Group Commitments", href: createDevelopmentCommitmentsUrl() }}
        title={dc.name}
        left={
          <span css={Css.ttc.$}>
            <Tag type={commitmentStatusToTagTypeMapper[dc.status]} text={dc.status} />
          </span>
        }
        right={
          <div css={Css.df.$}>
            {/* actions dropdown */}
            <div css={Css.mr1.$}>
              {asset && (
                <AssetGallery assets={[asset]}>
                  {(openGallery) => (
                    <div css={Css.dn.$}>
                      <Button label="" buttonRef={buttonRef} onClick={() => openGallery(asset)} />
                    </div>
                  )}
                </AssetGallery>
              )}
              {/* TODO: forcing re-render via key - remove once ButtonMenu can handle dynamically updated items */}
              <ButtonMenu
                key={uploadUrl ? 2 : 1}
                items={[
                  {
                    label: "Upload Price Agreement",
                    disabled: dc.status !== CommitmentStatus.Draft || !developmentId,
                    onClick: () =>
                      openModal({
                        content: (
                          <UploadPriceAgreementModal
                            parentId={developmentId!}
                            ownerId={dc.id}
                            documentType={DocumentType.PriceAgreementDevelopmentCommitment}
                          />
                        ),
                      }),
                  },
                  {
                    label: "View PDF",
                    disabled: !asset,
                    onClick: () => buttonRef.current?.click(),
                  },
                  {
                    disabled: !dc.canCreatePandaDoc?.allowed || !uploadUrl,
                    label: "Re-Upload to PandaDoc",
                    onClick: () => {
                      // show confirmation modal when re-uploading to PandaDoc
                      openModal({
                        content: (
                          <PandaDocConfirmationModal
                            ownerId={developmentCommitmentId}
                            documentName="commitment"
                            isReUpload
                          />
                        ),
                      });
                    },
                  },
                  {
                    disabled: dc.status === CommitmentStatus.Signed,
                    label: "Delete",
                    onClick: () => {
                      // show confirmation modal when deleting dev comm
                      openModal({
                        content: (
                          <DeleteConfirmationModal
                            confirmationMessage={
                              <div>
                                <p css={Css.baseMd.gray900.mb2.$}>
                                  Are you sure you want to delete this development commitment?
                                </p>
                                <p css={Css.sm.gray700.$}>This action cannot be undone.</p>
                              </div>
                            }
                            entityType="Commitment"
                            onConfirmDelete={async () => {
                              await deleteDevelopmentCommitment({
                                variables: { input: { id: developmentCommitmentId } },
                                update: (cache) => {
                                  // after update completes remove deleted dev comm from our local cache
                                  cache.evict({ id: `DevelopmentCommitment:${developmentCommitmentId}` });
                                },
                              });
                              history.push(createDevelopmentCommitmentsUrl());
                            }}
                          />
                        ),
                      });
                    },
                  },
                ]}
                trigger={{ label: "Actions" }}
              />
            </div>
            <PandaDocUploadButton
              parent={dc}
              onUpload={() => {
                // show confirmation modal when initially uploading to PandaDoc
                openModal({
                  content: <PandaDocConfirmationModal ownerId={developmentCommitmentId} documentName="commitment" />,
                });
              }}
            />
          </div>
        }
      />
      <TabsWithContent
        tabs={[
          {
            name: "Overview",
            value: "overview",
            render: () => <OverviewTab developmentCommitmentId={developmentCommitmentId} />,
          },
          {
            name: "Line Items",
            value: "lineItems",
            render: () => <LineItemsTab developmentCommitmentId={developmentCommitmentId} />,
          },
          {
            name: "Schedule and Scope",
            value: "scheduleAndScope",
            render: () => <ScheduleAndScopeTab developmentCommitmentId={developmentCommitmentId} />,
          },
          {
            name: "Projects",
            value: "projects",
            render: () => <ProjectsTab developmentCommitmentId={developmentCommitmentId} />,
          },
          {
            name: "History",
            value: "history",
            render: () => <HistoryTab developmentCommitmentId={developmentCommitmentId} />,
          },
        ]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </div>
  );
}
