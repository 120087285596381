import { Button, useSnackbar } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";

import { useHistory } from "react-router-dom";
import { createDevelopmentProductOfferingUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  ReadyPlanStatus,
  SaveProductOfferingInput,
  useReviewStep_SaveProductOfferingMutation,
} from "src/generated/graphql-types";
import { StringParam, useQueryParams } from "use-query-params";

type ProductOfferingStepActionsProps = {
  disabled?: boolean;
  formState?: ObjectState<SaveProductOfferingInput>;
};

export function ProductOfferingStepActions({ disabled, formState }: ProductOfferingStepActionsProps) {
  const { currentStep, steps, nextStep, isLastStep } = useStepperContext();
  const [{ devId }] = useQueryParams({
    devId: StringParam,
  });
  const history = useHistory();
  const { triggerNotice } = useSnackbar();

  const currentStepIndex = steps.findIndex((step) => step.value === currentStep.value);
  const nextStepDisabled = steps[currentStepIndex + 1]?.disabled;
  const [saveProductOffering] = useReviewStep_SaveProductOfferingMutation();

  return (
    <StepActions>
      <>
        {!isLastStep ? (
          <Button label="Continue" size="lg" onClick={nextStep} disabled={nextStepDisabled} />
        ) : (
          <Button
            label="Create Draft"
            size="lg"
            onClick={async () => {
              if (!formState) return;
              const res = await saveProductOffering({
                variables: {
                  input: mapToSaveProductOfferingInput(formState),
                },
              });
              if (res.data?.saveProductOffering.productOffering) {
                const productOfferingId = res.data?.saveProductOffering.productOffering.id;
                const developmentId = formState.value.developmentId;
                triggerNotice({ message: "Offering successfully drafted! Procurement will review and start bidding" });
                if (developmentId) {
                  history.push(createDevelopmentProductOfferingUrl(developmentId, productOfferingId));
                }
              }
            }}
            disabled={disabled}
          />
        )}
      </>
    </StepActions>
  );
}

function mapToSaveProductOfferingInput(formState: ObjectState<SaveProductOfferingInput>) {
  return {
    ...formState.value,
    status: ReadyPlanStatus.Draft,
  };
}
