import { ScrollableContent } from "@homebound/beam";
import { HistoryFeed } from "src/components";
import { useProjectItemHistoryQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ProjectItemScopeTable } from "./ProjectItemScopeTable";

type ProjectItemHistoryTabProps = {
  projectItemId: string;
};

export function ProjectItemHistoryTab({ projectItemId }: ProjectItemHistoryTabProps) {
  const query = useProjectItemHistoryQuery({ variables: { projectItemId } });
  return queryResult(query, {
    data: ({ projectItem: { history, scopeHistory, homeownerSelection, unitOfMeasure, projectStage } }) => (
      <ScrollableContent>
        {projectStage.hasSignedContract && (
          <ProjectItemScopeTable
            homeownerSelection={homeownerSelection ?? undefined}
            scopeHistory={scopeHistory}
            unitOfMeasure={unitOfMeasure}
          />
        )}
        <HistoryFeed historyItems={history} />
      </ScrollableContent>
    ),
  });
}
