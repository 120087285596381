import { Button, Css, ModalBody, ModalFooter, ModalHeader, SelectField, useModal, useToast } from "@homebound/beam";
import { useState } from "react";
import { emptyCellDash } from "src/components";
import { Named, ProjectItemInput } from "src/generated/graphql-types";
import { ObservableProjectItem } from "src/routes/projects/models/ObservableProjectItem";

type AllocateItemsModalProps = {
  selectedProjectItems: ObservableProjectItem[];
  scheduleTasks: Named[];
  onSave: (...input: ProjectItemInput[]) => Promise<boolean>;
};

export function AllocateItemsModal(props: AllocateItemsModalProps) {
  const { selectedProjectItems, scheduleTasks, onSave } = props;
  // control state here as being used in `openModal` to receive updated data
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const { closeModal } = useModal();
  const { showToast } = useToast();
  const undefinedOption = { id: "", name: emptyCellDash };
  return (
    <>
      <ModalHeader>Allocate Items</ModalHeader>
      <ModalBody>
        <p css={Css.mb2.$}>
          {selectedProjectItems.length > 1
            ? `${selectedProjectItems.length} items selected`
            : `${selectedProjectItems.length} item selected`}
        </p>
        <SelectField
          label="Schedule Task"
          value={selectedTaskId}
          options={[undefinedOption, ...scheduleTasks]}
          onSelect={(val) => val && setSelectedTaskId(val)}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Update"
          onClick={async () => {
            await onSave(
              ...selectedProjectItems.map((pi) => {
                return {
                  id: pi.id,
                  taskId: selectedTaskId,
                };
              }),
            );
            showToast({ type: "success", message: "Task allocations updated" });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
