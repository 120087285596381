import { Css, FullBleed, useTestIds, Xss } from "@homebound/beam";
import { PropsWithChildren } from "react";

export function SubHeader({ children, preHeader }: PropsWithChildren<{ preHeader: string; xss?: Xss<"marginLeft"> }>) {
  const tid = useTestIds({});
  return (
    <FullBleed>
      <div css={Css.ml0.bgWhite.pb3.$}>
        <div {...tid.preHeader} css={Css.baseMd.fw7.pt4.add("textTransform", "uppercase").$}>
          {preHeader}
        </div>
        <div {...tid.subHeaderTitle} css={Css.xl3Sb.fw6.mt1.$}>
          {children}
        </div>
      </div>
    </FullBleed>
  );
}
