import { Button, Css, MultiSelectField, SelectField } from "@homebound/beam";
import { createDesignPackageCompareModeUrl } from "src/RouteUrls";
import { Icon } from "src/components";
import { useDesignPackageConfiguratorContext } from "../DesignPackageConfiguratorContext";

export function DesignPackageFilters() {
  const { designPackage, selectedOptionIds, setCtxState } = useDesignPackageConfiguratorContext();
  // split base RPOs into groups (Spec Level, Scheme, Elevation, etc.)
  const baseRpogs = designPackage.optionGroups.filter((rpog) => rpog.forDesignPackages);
  // bundle all upgrades into 1 dropdown
  const upgradeRpos = designPackage.optionGroups
    .filter((rpog) => rpog.forDesignUpgrade)
    .flatMap((rpog) => rpog.options);
  return (
    <div css={Css.df.fdc.gap1.mb3.pb3.bb.bcGray400.$}>
      <div css={Css.df.gap2.$}>
        <span>Filter by</span>
        {selectedOptionIds.nonEmpty && (
          <>
            <Button variant="text" label="Clear filters" onClick={() => setCtxState({ optionIds: [] })} />
            <div css={Css.df.aic.gap1.orange500.$}>
              <Icon icon="infoCircle" /> Empty Product Slots hidden
            </div>
          </>
        )}
      </div>
      <div css={Css.df.gap2.jcsb.$}>
        <div css={Css.df.gap2.$}>
          {baseRpogs.map((rpog) => (
            <MultiSelectField
              key={rpog.id}
              label={rpog.name}
              labelStyle="inline"
              placeholder="All"
              options={rpog.options.map((rpo) => ({ name: rpo.name, id: rpo.id }))}
              values={selectedOptionIds}
              onSelect={(rpoIds) => {
                // we don't know which IDs in `optionIds: []` are from which group, so pluck out everything from the current group then spread back in the new selections
                const allGroupIds = rpog.options.map((rpo) => rpo.id);
                const selectedWithoutCurrentGroup = selectedOptionIds.filter((rpoId) => !allGroupIds.includes(rpoId));
                setCtxState({ optionIds: [...selectedWithoutCurrentGroup, ...rpoIds] });
              }}
            />
          ))}
          {upgradeRpos.nonEmpty && (
            <SelectField
              label="Upgrades"
              labelStyle="inline"
              placeholder="None"
              options={upgradeRpos.map((rpo) => ({ name: rpo.name, id: rpo.id }))}
              value={upgradeRpos.find((rpo) => selectedOptionIds.includes(rpo.id))?.id}
              onSelect={(rpoId) => {
                // We don't know which IDs in `optionIds: []` are forDesignUpgrade IDs, so remove all upgrades then add the selected one back in (single select)
                const allUpgradeRpoIds = upgradeRpos.map((rpo) => rpo.id);
                const selectedWithoutUpgrades = selectedOptionIds.filter((rpoId) => !allUpgradeRpoIds.includes(rpoId));
                setCtxState({ optionIds: [...selectedWithoutUpgrades, rpoId].compact() });
              }}
            />
          )}
        </div>
        <Button
          variant="secondary"
          icon="abacus"
          label="Compare"
          onClick={createDesignPackageCompareModeUrl(designPackage.id)}
          size="md"
        />
      </div>
    </div>
  );
}
