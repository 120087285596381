import {
  BoundSelectField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useEffect } from "react";
import {
  CostClassificationType,
  CostSubClassificationType,
  SaveCostCodeInput,
  useCostCodeOverviewTabMetadataQuery,
  useSaveCostCodesMutation,
} from "src/generated/graphql-types";
import { updateQuery } from "src/mutations/utils";

export function CreateNewCostCodeModal() {
  const { openModal } = useModal();

  return <Button label="Create cost code" onClick={() => openModal({ content: <CreateNewCostCodeModalForm /> })} />;
}

function CreateNewCostCodeModalForm() {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { data } = useCostCodeOverviewTabMetadataQuery();
  const [saveCostCodes] = useSaveCostCodesMutation();
  const { costDivisions = [], enumDetails } = data || {};
  const { costClassificationType, costSubClassificationType } = enumDetails ?? {};
  const formState = useFormState({
    config: formConfig,
  });
  const { valid, subClassification, showSubClassification } = useComputed(
    () => ({
      valid: formState.valid,
      showSubClassification: formState.costClassification.value === CostClassificationType.Hard,
      subClassification: formState.costSubClassification.value,
    }),
    [formState],
  );

  useEffect(() => {
    if (!showSubClassification) {
      formState.costSubClassification.set(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSubClassification]);

  async function onSubmit() {
    const { errors } = await saveCostCodes({
      variables: { input: { costCodes: [{ ...formState.value }] } },
      update: updateQuery((data) => data.saveCostCodes.costCodes, "costCodes"),
    });

    if (!errors) {
      closeModal();
      triggerNotice({ message: "Cost code created successfully", icon: "success" });
    }
  }

  return (
    <>
      <ModalHeader>Create cost code</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField label="Name" field={formState.name} />
          <BoundTextField label="Number" field={formState.number} />
          <BoundSelectField
            label="Division"
            field={formState.divisionId}
            options={costDivisions}
            getOptionLabel={({ name, version }) => `${name} V${version}`}
          />
          <BoundSelectField
            label="Classification"
            field={formState.costClassification}
            options={costClassificationType ?? []}
            getOptionLabel={(costClass) => costClass.name}
            getOptionValue={(costClass) => costClass.code as CostClassificationType}
          />
          {showSubClassification && (
            <BoundSelectField
              required
              label="Sub Classification"
              field={formState.costSubClassification}
              options={costSubClassificationType ?? []}
              getOptionLabel={(costClass) => costClass.name}
              getOptionValue={(costClass) => costClass.code as CostSubClassificationType}
            />
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button
          label="Create cost code"
          data-testid="createCostCodeBtn"
          disabled={!valid || (showSubClassification && !subClassification)}
          onClick={onSubmit}
        />
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveCostCodeInput> = {
  name: { type: "value", rules: [required] },
  number: { type: "value", rules: [required] },
  divisionId: { type: "value", rules: [required] },
  costClassification: { type: "value", rules: [required] },
  costSubClassification: { type: "value" },
};
