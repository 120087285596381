import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useState } from "react";
import { CostCodeSelectField } from "src/components/autoPopulateSelects/CostCodeSelectField";
import { CostCodeSelect_CostCodeFragment, Maybe, useSaveExpenseMutation } from "src/generated/graphql-types";
import { ExpensesTableInput } from "src/routes/projects/expenses/components/ExpensesTable";

type ChangeCostCodeModalProps = {
  formState: ObjectState<ExpensesTableInput>;
};

export function ChangeCostCodeModal({ formState }: ChangeCostCodeModalProps) {
  const [saveExpense] = useSaveExpenseMutation();
  const [selectedCostCode, setSelectedCostCode] = useState<Maybe<CostCodeSelect_CostCodeFragment>>(
    formState.expense.costCode.value,
  );
  const { closeModal } = useModal();

  async function handleSave() {
    if (selectedCostCode) {
      const { data } = await saveExpense({
        variables: { input: { id: formState.expense.id.value, costCodeId: selectedCostCode.id } },
      });
      // Update the form state with the new cost code
      formState.expense.costCode.set(data?.saveExpenses.expenses.first!.costCode);
      closeModal();
    }
  }

  return (
    <>
      <ModalHeader>Set Cost Code</ModalHeader>
      <ModalBody>
        <CostCodeSelectField
          onSelect={(_, cc) => setSelectedCostCode(cc)}
          value={selectedCostCode}
          data-testid="costCodeSelect"
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Save" onClick={async () => await handleSave()} variant={"primary"} />
      </ModalFooter>
    </>
  );
}
