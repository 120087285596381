import { Route, Switch } from "react-router-dom";
import {
  designCatalogPath,
  designPackageAddProductPath,
  designPackageNewSlotPath,
  designPackageProductSearchPath,
  designPackageSlotDetailPath,
} from "src/routes/routesDef";
import { DesignPackageConfigurator } from "./DesignPackageConfigurator";
import { DesignPackageConfiguratorProvider } from "./DesignPackageConfiguratorContext";
import { AddProductPage } from "./components/AddProductPage";
import { ItemSlotBidItemPage } from "./routes/detail/ItemSlotBidItemPage";
import { NewProductSlotPage } from "./routes/new/NewProductSlotPage";
import { ProductSearchPage } from "./routes/search/ProductSearchPage";

export function DesignPackageConfiguratorLayout() {
  return (
    <DesignPackageConfiguratorProvider>
      <Switch>
        <Route path={designPackageNewSlotPath} exact component={NewProductSlotPage} />
        <Route path={designPackageSlotDetailPath} exact component={ItemSlotBidItemPage} />
        <Route path={designPackageProductSearchPath} exact component={ProductSearchPage} />
        <Route path={designPackageAddProductPath} exact component={AddProductPage} />
        <Route path={designCatalogPath} exact component={DesignPackageConfigurator} />
      </Switch>
    </DesignPackageConfiguratorProvider>
  );
}
