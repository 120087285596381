import { Button, Css, useModal } from "@homebound/beam";
import { CreateInvoiceTermsModal } from "./components/CreateInvoiceTermsModal";
import { InvoiceTermsTable } from "./components/InvoiceTermsTable";

export function InvoiceTermsPage() {
  const { openModal } = useModal();
  return (
    <div css={Css.w75.mxa.mt5.$}>
      <div css={Css.df.mb2.$}>
        <div css={Css.base.$}>Contract Invoice Terms</div>
        <div css={Css.mla.$}>
          <Button
            variant="tertiary"
            label="Create Invoice Terms"
            icon={"plus"}
            onClick={() => openModal({ content: <CreateInvoiceTermsModal /> })}
          />
        </div>
      </div>
      <div css={Css.bgWhite.p3.br8.bshBasic.$}>
        <InvoiceTermsTable />
      </div>
    </div>
  );
}
