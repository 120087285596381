import { useComputed } from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { createPortal } from "react-dom";
import { FormActions } from "src/components";
import {
  DashboardConfig,
  DashboardTile,
  DashboardTileConfig,
  DashboardTileConfigInput,
  DashboardTileSize,
  ProjectFeature,
  useProjectDashboardTabQuery,
  useSaveProjectDashboardMutation,
} from "src/generated/graphql-types";
import { NotFound } from "src/routes/NotFound";
import { AddNewTile } from "src/routes/projects/dashboard/components/AddNewTile";
import { DashboardGrid } from "src/routes/projects/dashboard/components/DashboardGrid";
import { OtherPagesTile } from "src/routes/projects/dashboard/components/OtherPagesTile";
import { TileComponentConfig, dashboardFormConfig, tileToComponentConfig } from "src/routes/projects/dashboard/config";
import { doInNextLoop, queryResult, safeEntries } from "src/utils";
import { BooleanParam, useQueryParams } from "use-query-params";
import { useProjectContext } from "../../context/ProjectContext";

type DashboardTabProps = {
  actionsPortalDiv: HTMLDivElement;
};

export function DashboardTab(props: DashboardTabProps) {
  const query = useProjectDashboardTabQuery();

  return queryResult(query, ({ currentInternalUser }) =>
    currentInternalUser ? (
      <DashboardTabView
        {...props}
        email={currentInternalUser.email}
        config={currentInternalUser.projectDashboardConfig}
      />
    ) : (
      <NotFound />
    ),
  );
}

type DashboardTabViewProps = DashboardTabProps & {
  config: DashboardConfig | undefined | null;
  email: string;
};

function DashboardTabView(props: DashboardTabViewProps) {
  const [save] = useSaveProjectDashboardMutation();
  const { email, actionsPortalDiv } = props;
  const [{ edit }, setQueryParams] = useQueryParams({ edit: BooleanParam });
  const { features } = useProjectContext();
  const hasProductConfigPlanFeature = features.includes(ProjectFeature.ProductConfigPlan);

  const formState = useFormState({
    config: dashboardFormConfig,
    init: {
      onlyOnce: true,
      input: { tileConfigs: props.config?.tileConfigs ?? defaultDashboardConfig },
    },
    readOnly: !edit,
  });

  const onReorder = useCallback(
    (tileIdsInOrder: string[]) => {
      const sorted = formState.tileConfigs.value.sort(
        (a, b) => tileIdsInOrder.indexOf(a.tile) - tileIdsInOrder.indexOf(b.tile),
      );
      formState.tileConfigs.set(sorted);
    },
    [formState],
  );

  const removeTile = useCallback(
    (config: ObjectState<DashboardTileConfigInput>) => formState.tileConfigs.remove(config.value),
    [formState],
  );

  const addTiles = useCallback(
    ([tile, size]: [DashboardTile, DashboardTileSize]) => formState.tileConfigs.add({ tile, size }),
    [formState],
  );

  const onSave = useCallback(
    async () => {
      await save({
        variables: {
          input: {
            email,
            projectDashboardConfig: {
              tileConfigs: formState.tileConfigs.value.map(({ tile, size }) => ({
                tile,
                size: size ?? tileToComponentConfig[tile].defaultSize,
              })),
            },
          },
        },
      });
      doInNextLoop(() => setQueryParams({ edit: false }, "replaceIn"));
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [save, email, formState],
  );

  const [hiddenTiles, hiddenTilesWithUrl]: [
    [DashboardTile, TileComponentConfig][],
    [DashboardTile, TileComponentConfig][],
  ] = useComputed(() => {
    const visibleTiles: DashboardTile[] = formState.tileConfigs.value.map((config) => config.tile);
    const hiddenTiles: [DashboardTile, TileComponentConfig][] = safeEntries(tileToComponentConfig)
      .filter(([tile]) => !visibleTiles.includes(tile))
      .map(([tile, config]) => [tile, config]);
    return [hiddenTiles, hiddenTiles.filter(([_, config]) => typeof config.createUrl === "function")];
  }, [formState]);

  // filter out the ProjectSchedule and ProjectInfo tile if the project is using dynamic schedules
  const filteredTilesForProductConfigPlan = useComputed(() => {
    const hiddenTilesForProductConfigPlan = [DashboardTile.ProjectSchedule, DashboardTile.ProjectInfo];
    return formState.tileConfigs.rows.filter(({ tile }) => !hiddenTilesForProductConfigPlan.includes(tile.value));
  }, [formState]);

  return (
    <DashboardGrid onReorder={onReorder}>
      <Observer>
        {() => (
          <>
            {!formState.readOnly &&
              createPortal(
                <FormActions
                  mode="update"
                  formState={formState}
                  onSave={onSave}
                  onCancel={() => setQueryParams({ edit: undefined }, "replaceIn")}
                  primaryLabel="Save Widgets"
                  secondaryLabel="Exit Edit Mode"
                  secondaryVariant="secondary"
                />,
                actionsPortalDiv,
              )}
            {(hasProductConfigPlanFeature ? filteredTilesForProductConfigPlan : formState.tileConfigs.rows).map(
              (config) => {
                const componentConfig = tileToComponentConfig[config.tile.value];
                const Component = componentConfig.component;
                return (
                  <Component
                    componentConfig={componentConfig}
                    userDashboardConfig={config}
                    key={config.tile.value}
                    edit={formState.readOnly === false}
                    removeTile={removeTile}
                  />
                );
              },
            )}
            {!formState.readOnly && <AddNewTile onAdd={addTiles} availableTiles={hiddenTiles} />}
            {formState.readOnly && hiddenTilesWithUrl.length > 0 && <OtherPagesTile tiles={hiddenTilesWithUrl} />}
          </>
        )}
      </Observer>
    </DashboardGrid>
  );
}

export const defaultDashboardConfig: DashboardTileConfig[] = [
  { tile: DashboardTile.ProjectSchedule, size: tileToComponentConfig.ProjectSchedule.defaultSize },
  { tile: DashboardTile.ProjectInfo, size: tileToComponentConfig.ProjectInfo.defaultSize },
  { tile: DashboardTile.ProjectFinances, size: tileToComponentConfig.ProjectFinances.defaultSize },
  { tile: DashboardTile.ProjectDocuments, size: tileToComponentConfig.ProjectDocuments.defaultSize },
  { tile: DashboardTile.ProjectToDos, size: tileToComponentConfig.ProjectToDos.defaultSize },
  { tile: DashboardTile.ProjectJobLogs, size: tileToComponentConfig.ProjectJobLogs.defaultSize },
  { tile: DashboardTile.ProjectTeamMembers, size: tileToComponentConfig.ProjectTeamMembers.defaultSize },
  { tile: DashboardTile.ProjectPhotos, size: tileToComponentConfig.ProjectPhotos.defaultSize },
  { tile: DashboardTile.ProjectHomeownerNotes, size: tileToComponentConfig.ProjectHomeownerNotes.defaultSize },
];
