import { Css, Icon, Palette, useTestIds } from "@homebound/beam";
import { useScheduleFlagsOnJobLogNoteQuery } from "src/generated/graphql-types";
import { pluralize, queryResult } from "src/utils";

type JobLogNoteScheduleFlagsProps = {
  scheduleTaskIds: string[];
  isReadOnly?: boolean;
};

export function JobLogNoteScheduleFlags(props: JobLogNoteScheduleFlagsProps) {
  const { scheduleTaskIds } = props;
  // In the case of a pending schedule flag created from a job log note, this will be a listField index
  const tid = useTestIds({}, "jobLogNoteScheduleFlags");

  const query = useScheduleFlagsOnJobLogNoteQuery({
    variables: { taskIds: scheduleTaskIds },
    fetchPolicy: "cache-first",
  });

  return queryResult(query, (flags) => {
    const { scheduleFlags } = flags;
    return (
      <>
        <div css={Css.smBd.mb1.$}>
          <span css={Css.df.aic.jcsb.gray900.$}>Flags</span>
        </div>
        {scheduleFlags.isEmpty ? (
          <div {...tid.noFlags} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
            There are no flags associated to this note
          </div>
        ) : (
          <div {...tid.taskFlags} css={Css.df.fdc.gap1.mb1.br8.$}>
            {scheduleFlags.map((flag) => {
              const { id, durationInDays, reason, description, scheduleTask } = flag;
              return (
                <div {...tid.scheduleFlagCard} key={id} css={Css.df.p1.bgGray100.mhPx(70).$}>
                  <div css={Css.mya.$}>
                    <Icon icon="flag" color={Palette.Red700} {...tid.flag} />
                  </div>
                  <div css={Css.df.fdc.jcc.fg1.m1.tiny.$}>
                    <span {...tid.title} css={Css.fwb.$}>
                      {durationInDays} {pluralize(durationInDays ?? 1, "Day")} {reason?.scheduleFlagReasonType.name} -{" "}
                      {reason?.title}
                    </span>
                    {description && <span {...tid.description}>{description}</span>}
                    {scheduleTask && (
                      <div css={Css.df.aic.$}>
                        <Icon color={Palette.Gray500} icon="link" inc={2} {...tid.link} />
                        <a
                          css={Css.tiny.pl1.truncate.blue700.$}
                          href={scheduleTask?.blueprintUrl.path}
                          target="_blank"
                          rel="noreferrer"
                          {...tid.task}
                        >
                          {scheduleTask?.name}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  });
}
