import { SuperDrawerHeader, Tag } from "@homebound/beam";
import { approvalStatusToTagTypeMapper, foldGqlUnion } from "src/utils";
import { useApprovalContext } from "./ApprovalSuperDrawer";

export function ApprovalSuperDrawerHeader() {
  const { approvals } = useApprovalContext();

  // No data, likely in the create workflow
  if (!approvals?.nonEmpty) return null;

  // Bulk reviewing -- can't pick just 1 so display this for now (maybe do approval.source in the future)
  if (approvals?.length > 1) return <SuperDrawerHeader title="Approval Request" hideControls />;

  // Exactly 1 approval loaded, grab it and render subject+tag
  const approval = approvals?.first!;
  const [type, name] = approvalStatusToTagTypeMapper(approval);
  return (
    <SuperDrawerHeader
      title={
        foldGqlUnion(approval.subject, {
          ChangeEvent: (ce) => ce.displayName,
          Bill: (bill) => bill.name,
          Invoice: (inv) => inv.name,
          ChangeRequest: (cr) => `Change Log Request: ${cr.name}`,
        })!
      }
      left={<Tag type={type} text={name} />}
      hideControls
    />
  );
}
