import { ContractType, Maybe } from "src/generated/graphql-types";
import { isNumber } from "src/utils";
import { ObjectState, Rule } from "src/utils/formState";

type HasContractTypeFields = {
  contractType?: Maybe<ContractType>;
};

export function costPlusMarkupRule<F extends HasContractTypeFields>(formState: ObjectState<F>): Rule<Maybe<number>> {
  return ({ value }) => {
    const { contractType } = formState;
    if (contractType.value && contractType.value === ContractType.CostPlus && !isNumber(value)) {
      return "Must enter Cost Plus Markup when Contract Type is Cost Plus";
    }
    return undefined;
  };
}
