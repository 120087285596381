import { Css } from "@homebound/beam";
import { JobLogDetailFragment, JobLogNoteDetailFragment } from "src/generated/graphql-types";
import { sortBy } from "src/utils";
import { JobLogNote } from "./JobLogNote";

type JobLogNotesProps = {
  notes: JobLogNoteDetailFragment[];
  jobLog: JobLogDetailFragment;
};

export function JobLogNotes(props: JobLogNotesProps) {
  const { notes, jobLog } = props;

  const sortedNotes = sortBy(notes, (note) => note.createdAt, "DESC");

  return (
    <div css={Css.df.fdc.gap2.$}>
      {sortedNotes.map((note: JobLogNoteDetailFragment) => {
        return <JobLogNote key={note.id} note={note} jobLog={jobLog} />;
      })}
    </div>
  );
}
