import { Only } from "@homebound/beam";
import { useUploaderContext } from "src/contexts/UploaderContext";
import {
  CreateDocumentPendingUploadMutationVariables,
  DocumentEditorDetailFragment,
  DocumentType,
  useCreateDocumentPendingUploadMutation,
  useMarkDocumentAsUploadedMutation,
} from "src/generated/graphql-types";
import { FileUploader, FileUploaderProps, FileUploaderXss, FileWithDocumentId } from "./FileUploader";

export type DocumentUploaderProps<X> = Omit<
  FileUploaderProps<X>,
  "getUploadUrl" | "allowedFileTypes" | "onFinish" | "file"
> & {
  documentType: DocumentType;
  file: DocumentEditorDetailFragment | undefined;
  onFinish: (file: DocumentEditorDetailFragment | undefined) => void;
  projectId: string;
};

/**
 * Document-specific file uploader, which handles uploading and marking a document as uploaded.
 *
 * When uploading multiple documents, getUploadUrl and onFinish are called once for each file uploaded.
 */
export function DocumentUploader<X extends Only<FileUploaderXss, X>>(props: DocumentUploaderProps<X>) {
  const { documentType, projectId, onFinish, ...otherProps } = props;
  const { setFiles } = useUploaderContext();

  const [createDocumentUpload] = useCreateDocumentPendingUploadMutation();
  const [markDocumentAsUploaded] = useMarkDocumentAsUploadedMutation();

  return (
    <FileUploader
      {...otherProps}
      allowedFileTypes={["application/pdf"]}
      getUploadUrl={async (file: FileWithDocumentId) => {
        const variables: CreateDocumentPendingUploadMutationVariables = {
          input: {
            parentId: projectId,
            documentType,
            name: file.name,
            sizeInBytes: file.size,
          },
        };
        const { data } = await createDocumentUpload({ variables });
        const { documentId, signedUrl } = data?.createDocumentPendingUpload!;
        file.documentId = documentId;
        setFiles({ [documentId]: { name: file.name, progress: 0 } });
        return signedUrl;
      }}
      onClear={() => onFinish(undefined)}
      onFinish={async (_result, file: FileWithDocumentId) => {
        const { data } = await markDocumentAsUploaded({ variables: { documentId: file.documentId! } });
        setFiles({ [file.documentId!]: { name: file.name, progress: 100 } });
        return onFinish(data?.markDocumentAsUploaded.document);
      }}
      onProgress={(progress: number, file: FileWithDocumentId) => {
        if (file.documentId) {
          setFiles({ [file.documentId]: { name: file.name, progress: progress } });
        }
      }}
    />
  );
}
