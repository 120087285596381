import { Button, Chips, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { HasDisplayName } from "src/generated/graphql-types";

export type AppliedTemplatesModalProps = {
  templates: HasDisplayName[];
};

export function AppliedTemplatesModal({ templates }: AppliedTemplatesModalProps) {
  const { closeModal } = useModal();
  const templateNames = templates.map((template) => template.displayName);
  return (
    <>
      <ModalHeader>Applied Templates</ModalHeader>
      <ModalBody>
        <div css={Css.mb2.$}>
          {templates.length === 1 ? "There is 1 template " : `There are ${templates.length} templates `}
          applied to this project.
        </div>
        <Chips values={templateNames} />
      </ModalBody>
      <ModalFooter>
        <Button label="Close" onClick={() => closeModal()} />
      </ModalFooter>
    </>
  );
}
