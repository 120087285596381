import {
  ButtonModal,
  Css,
  Icon,
  IconButton,
  LoadingSkeleton,
  Palette,
  useBreakpoint,
  useTestIds,
} from "@homebound/beam";
import { PropsWithChildren, useMemo } from "react";
import { useHistory } from "react-router";
import { ApprovalStatus, useApprovalSummaryHeaderQuery } from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { personalDashboardPaths } from "src/routes/routesDef";
import { approvalIsOverdue, foldGqlUnion, formatCentsToPrice } from "src/utils";
import { useDashboardFilterContext } from "../DashboardFilterContext";
import { PersonalDashboardFilter } from "../useHeaderFilterDefs";

export function ApprovalSummaryHeader() {
  const { loading, ...data } = useApprovalSummaryHeaderQueryData();
  const { sm } = useBreakpoint();
  const { waitingOnMe, openAndMine } = data;

  if (loading) return <LoadingSkeleton rows={1} columns={1} />;

  return (
    <ButtonModal
      content={<ApprovalSummaryLayout {...data} />}
      trigger={{
        label: sm ? (
          <span css={Css.xs.gray900.$}>
            <span css={Css.blue700.smBd.$}>{[...waitingOnMe, ...openAndMine].uniqueByKey("id").length}</span> Approvals
          </span>
        ) : (
          <div css={Css.df.aic.gap2.$}>
            <span css={Css.blue700.xl4.$}>{[...waitingOnMe, ...openAndMine].uniqueByKey("id").length}</span>
            <span>Approvals</span>
          </div>
        ),
      }}
      variant={sm ? "text" : "textSecondary"}
      placement="right"
    />
  );
}

function useApprovalSummaryHeaderQueryData() {
  const { id: currentUserId } = useCurrentUser();
  const { data, loading } = useApprovalSummaryHeaderQuery({ variables: { currentUserId } });

  return useMemo(
    () => ({
      loading,
      waitingOnMe: data?.waitinOnMe?.approvals ?? [],
      overDue: data?.waitinOnMe?.approvals.filter(approvalIsOverdue) ?? [],
      needChanges: data?.myApprovals?.approvals.filter((a) => a.status.code === ApprovalStatus.ChangesNeeded) ?? [],
      openAndMine: data?.myApprovals?.approvals.filter((a) => a.status.code === ApprovalStatus.Requested) ?? [],
    }),
    [data, loading],
  );
}

type ApprovalSummaryLayoutProps = Omit<ReturnType<typeof useApprovalSummaryHeaderQueryData>, "loading">;

function ApprovalSummaryLayout({ needChanges, openAndMine, overDue, waitingOnMe }: ApprovalSummaryLayoutProps) {
  const makeFilterOnClick = useSetDashboardFilterForApproval();
  const tids = useTestIds({}, "approvalSummaryLayout");
  const { sm } = useBreakpoint();
  return (
    <div css={Css.mwPx(335).$}>
      <div css={Css.absolute.top3.right3.$}>
        <Icon
          icon="infoCircle"
          inc={2}
          xss={Css.ml2.$}
          color={Palette.Gray900}
          tooltip="Approvals that you are assigned to"
        />
      </div>
      <div css={Css.mt2.mb1.px2.df.fdc.$}>
        <Row breakpoint={sm}>
          <ApprovalSummaryAtom
            {...tids.waitingOnYou}
            label="Waiting on you"
            count={waitingOnMe.length}
            onClick={makeFilterOnClick((id) => ({ internalUser: [id], approvalStatus: ApprovalStatus.Requested }))}
          />
          <ApprovalSummaryAtom
            label="Overdue"
            count={overDue.length}
            onClick={makeFilterOnClick((id) => ({ internalUser: [id], approvalStatus: "overdue" }))}
          />
          <ApprovalSummaryAtom
            label="Needs changes"
            count={needChanges.length}
            onClick={makeFilterOnClick((id) => ({ requested: [id], approvalStatus: ApprovalStatus.ChangesNeeded }))}
          />
        </Row>
        <Separator />
        <Row>
          <ApprovalSummaryAtom
            label="Open and Requested by you"
            count={openAndMine.length}
            onClick={makeFilterOnClick((id) => ({ requested: [id], approvalStatus: ApprovalStatus.Requested }))}
          />
        </Row>
        <div css={Css.w100.bgGray100.borderRadius("12px").df.fdr.jcc.aic.gap2.mt3.py1.$}>
          <div css={Css.smBd.$}>Total pending budget changes</div>
          <div css={Css.xlBd.blue700.$}>
            {formatCentsToPrice(
              openAndMine.sum((approval) =>
                foldGqlUnion(approval.subject, {
                  ChangeEvent: (ce) => ce.budgetImpactInCents || 0,
                  else: () => 0,
                }),
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const atomWidth = 127;

type RowProps = {
  breakpoint?: boolean;
};

const Row = ({ children, breakpoint }: PropsWithChildren<RowProps>) => (
  <div css={Css.df.fdr.jcfs.aifs.w100.gap4.if(!!breakpoint).fdc.$}>{children}</div>
);
const Separator = () => <div css={Css.hPx(1).w100.my3.bgGray300.$} />;
const Label = ({ children }: PropsWithChildren<unknown>) => <div css={Css.smBd.mb1.$}>{children}</div>;

function useSetDashboardFilterForApproval() {
  const { setFilter } = useDashboardFilterContext();
  const history = useHistory();
  const { id: _currentUserId } = useCurrentUser();

  type MakeFilterFn = (id: string) => PersonalDashboardFilter;
  // Notice: () => () => {}
  return (fn: MakeFilterFn) => () => {
    const filter = fn(_currentUserId);
    setFilter(filter);
    history.push(personalDashboardPaths.approvals);
  };
}

type ApprovalSummaryHeaderAtomProps = {
  label: string;
  count: number;
  onClick: VoidFunction;
};

function ApprovalSummaryAtom(props: ApprovalSummaryHeaderAtomProps) {
  const { label, count, onClick } = props;
  const tids = useTestIds(props, label);

  return (
    <div css={Css.wPx(atomWidth).$}>
      <Label>{label}</Label>
      <div css={Css.df.fdr.aic.gap1.jcsb.$}>
        <span css={Css.xl3Bd.red600.$}>{count}</span>
        <span css={Css.smSb.$}>approvals</span>
        <IconButton icon="chevronRight" inc={3} color={Palette.Gray700} onClick={onClick} {...tids} />
      </div>
    </div>
  );
}
