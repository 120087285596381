import { Css } from "@homebound/beam";
import { ReactNode } from "react";
import { UserEventType } from "src/generated/graphql-types";
import { UserEventRowData } from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";
import { fail, sanitizeHtml } from "src/utils";

type ApprovalProps = {
  event: UserEventRowData;
  dateTag?: ReactNode;
};

export function Approval({ event, dateTag }: ApprovalProps) {
  const { comment, type, createdBy } = event;

  const commentTag = comment?.html && (
    <div css={Css.xs.wbbw.$} dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment?.html ?? "") }} />
  );

  return (
    <span>
      <span css={weightedStyle}>{mapUserEventTypeApprovalText(type)}</span> by {createdBy}
      {[UserEventType.Commented, UserEventType.RequestedChanges, UserEventType.Rejected].includes(type) && commentTag}
      {dateTag}
    </span>
  );
}

function mapUserEventTypeApprovalText(userEventType: UserEventType) {
  switch (userEventType) {
    case UserEventType.Created:
      return "Requested Created";
    case UserEventType.Approved:
      return "Approved";
    case UserEventType.Commented:
      return "Comment";
    case UserEventType.RequestedChanges:
      return "Changes Requested";
    case UserEventType.Rejected:
      return "Rejected";
    default:
      fail("Unknown user event type");
  }
}
