import { Accordion, Css, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { formatDistanceToNow, isAfter } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ActionItemsDeferredBillsQuery, useActionItemsDeferredBillsQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { ItemCard } from "../../components/ItemCard";
import { ActionItemTypes } from "../../enums";
import { ActionItemsContainer, EmptyActionItems } from "./ActionItemsPage";

export function ActionItemsDeferredBills() {
  const { filter } = useDashboardFilterContext();
  const query = useActionItemsDeferredBillsQuery({
    variables: {
      requesterId: filter.internalUser,
      projectId: filter.project,
    },
    skip: !filter.internalUser,
  });

  return queryResult(query, {
    data: (data) => <ActionItemsDeferredBillsLayout data={data} />,
    loading: () => <LoadingSkeleton rows={3} columns={3} size="lg" />,
    showLoading: "always",
  });
}

function ActionItemsDeferredBillsLayout({ data }: { data: ActionItemsDeferredBillsQuery }) {
  const { bills } = data;
  const tids = useTestIds({}, "actionItemDeferredBills");

  const deferredBills = useMemo(() => {
    return bills.map((bill) => (
      <ItemCard
        key={`deferred_bill_${bill.id}`}
        actionType={ActionItemTypes.DEFERRED_BILLS}
        onClick={() => window.open(bill.blueprintUrl.path, "_blank")}
        title="Unreleased Click-to-Pay Bill"
        description={
          <p css={Css.df.fdr.gap1.tal.$}>
            A draft bill
            <Link target="_blank" to={bill.blueprintUrl.path}>
              #{bill.tradePartnerNumber}
            </Link>
            for
            <span>{bill.tradePartner.name}</span>
            created by
            <span>{bill.createdBy?.name}</span>
            {!bill.dueDate
              ? undefined
              : isPastDueDate(bill.dueDate)
                ? `is ${formatDistanceToNow(bill.dueDate)} overdue`
                : `is due ${formatDistanceToNow(bill.dueDate, { addSuffix: true })}`}
          </p>
        }
        duration={bill.dueDate ? formatDistanceToNow(bill.dueDate, { addSuffix: true }) : undefined}
        project={bill.project}
      />
    ));
  }, [bills]);

  return (
    <Accordion
      {...tids}
      title={`Unreleased Click to Pay Bills (${deferredBills.length})`}
      defaultExpanded
      topBorder={false}
    >
      {deferredBills.isEmpty && <EmptyActionItems title="No unreleased bills" />}
      <ActionItemsContainer count={deferredBills.length}>{deferredBills}</ActionItemsContainer>
    </Accordion>
  );
}

function isPastDueDate(dueDate: DateOnly): boolean {
  return isAfter(new Date(), dueDate);
}
