import { BoundSelectField, Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  DocumentDetailDocumentTypeFragment,
  DocumentDetailFragment,
  DocumentType,
  Maybe,
  NamedDevelopmentCohortsProjectsFragment,
  ProjectDocumentAssociationInput,
  SaveDocumentInput,
  useSaveDocumentsMutation,
} from "src/generated/graphql-types";
import { BoundAssociatedProjectsTreeSelect } from "./BoundAssociatedProjectsTreeSelect";

type RecategorizeModalProps = {
  documents: DocumentDetailFragment[];
  uncontrolledDocumentTypes: DocumentDetailDocumentTypeFragment[];
  development?: NamedDevelopmentCohortsProjectsFragment;
};

export function RecategorizeModal(props: RecategorizeModalProps) {
  const { documents, uncontrolledDocumentTypes, development } = props;
  const [saveDocumentsMutation, { loading }] = useSaveDocumentsMutation();
  const { closeModal } = useModal();
  // Get the development from the documents if they are all development documents
  const areDevelopmentDocuments = documents.every((d) => d.parent?.__typename === "Development");

  const formState = useFormState({
    config: formConfig,
    init: {
      input: documents,
      map: (documents) => {
        // Also if there are multiple document types, documentType will be null
        const documentTypes = new Set(documents.map((d) => d.documentType));
        const documentType = documentTypes.size === 1 ? documents[0].documentType.code : null;
        // And if there is more than one document with different projectDocumentAssociations, then the projectDocumentAssociations will be empty
        const projectDocumentAssociations = areDevelopmentDocuments
          ? hasSameProjectsAssociations(documents)
            ? documents[0].associatedProjects.map(({ id }) => ({ projectId: id }))
            : []
          : undefined;
        return { documentType, projectDocumentAssociations };
      },
    },
  });

  const title = `Recategorize${documents.length > 1 ? ` ${documents.length}` : ""} Files`;
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <BoundSelectField
          label="Document Type"
          field={formState.documentType}
          options={uncontrolledDocumentTypes}
          getOptionValue={(o) => o.code}
          getOptionLabel={(o) => o.name}
        />
        {areDevelopmentDocuments && development && (
          <BoundAssociatedProjectsTreeSelect development={development} field={formState.projectDocumentAssociations} />
        )}
        <br />
        <span css={Css.gray700.$}>
          {documents.length > 1 ? `This will recategorize ${documents.length} files.` : ""}
        </span>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" variant="tertiary" onClick={closeModal} />
              <Button
                disabled={!formState.valid || loading} // We want to allow null value to remove category
                label="Recategorize"
                onClick={async () => {
                  await saveDocumentsMutation({
                    variables: {
                      input: documents.map(({ id }) => ({ ...formState.value, id })),
                    },
                  });
                  closeModal();
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = Pick<SaveDocumentInput, "id" | "projectDocumentAssociations"> & {
  documentType: Maybe<DocumentType>;
};

const projectDocumentAssociationConfig: ObjectConfig<ProjectDocumentAssociationInput> = {
  projectId: { type: "value", isIdKey: true },
  delete: { type: "value", isDeleteKey: true },
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  documentType: { type: "value", rules: [required] },
  projectDocumentAssociations: { type: "list", config: projectDocumentAssociationConfig },
};

function hasSameProjectsAssociations(documents: DocumentDetailFragment[]): boolean {
  if (documents.length === 0) return false;
  const projectIds = new Set(documents[0].associatedProjects.map(({ id }) => id));
  return documents.every((d) => {
    const ids = new Set(d.associatedProjects.map(({ id }) => id));
    return ids.size === projectIds.size && [...ids].every((id) => projectIds.has(id));
  });
}
