import {
  BoundCheckboxField,
  BoundMultiSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import {
  CalendarInterval,
  Maybe,
  ScheduleDetailsFragment,
  TaskTradePartnerFragment,
  useAvailableGlobalPhasesQuery,
} from "src/generated/graphql-types";
import { createScheduleExportUrl } from "src/RouteUrls";
import { DateOnly, ensureDateOnly } from "src/utils/dates";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openNewTab } from "src/utils/window";

type ScheduleExportModalProps = {
  schedule: ScheduleDetailsFragment;
  tradePartners: TaskTradePartnerFragment[];
};

type StageInterval = Pick<CalendarInterval, "startDate" | "endDate">;

export function ScheduleExportModal({ schedule, tradePartners }: ScheduleExportModalProps) {
  const { closeModal } = useModal();
  const stage = schedule.stage;
  const form = useFormState({ config: formConfig, init: { input: schedule.interval, map: mapToForm() } });
  const { data: availableGlobalPhasesData } = useAvailableGlobalPhasesQuery({
    variables: { scheduleId: schedule.id, stage },
    fetchPolicy: "cache-first",
  });
  // flat mapping over the schedulePhases in order to grab the ScheduleSubPhases array to use as options in Bound Multi-Select Field
  const subPhases = (availableGlobalPhasesData?.schedulePhases ?? []).flatMap((sp) => sp.scheduleSubPhases);

  function handlePrimaryClick() {
    const { startDate, endDate, tradePartners, scheduleSubPhases, withGantt } = form.value;
    openNewTab(
      createScheduleExportUrl(
        schedule.id,
        startDate!,
        endDate!,
        tradePartners ?? undefined,
        scheduleSubPhases ?? undefined,
        withGantt ?? undefined,
      ),
    );
  }

  return (
    <>
      <ModalHeader>Schedule Export</ModalHeader>
      <ModalBody>
        <p>Easily export schedules by date range by trade partners or subphases</p>
        <form css={Css.mt1.df.fdc.gap1.$}>
          <div css={Css.df.gap2.$}>
            <BoundBeamDateField field={form.startDate} />
            <BoundBeamDateField field={form.endDate} />
          </div>
          <BoundMultiSelectField
            field={form.tradePartners}
            options={tradePartners.map((tp) => ({ id: tp.id, name: tp.name }))}
          />
          <BoundMultiSelectField
            field={form.scheduleSubPhases}
            options={subPhases.map((sp) => ({ id: sp.id, name: sp.globalSubPhase.name }))}
            label="Project SubPhases"
          />
          <BoundCheckboxField field={form.withGantt} label="Include Gantt Chart" />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>{() => <Button label="Export" disabled={!form.valid} onClick={handlePrimaryClick} />}</Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = {
  startDate: Maybe<DateOnly>;
  endDate: Maybe<DateOnly>;
  tradePartners: Maybe<string[]>;
  scheduleSubPhases?: Maybe<string[]>;
  withGantt?: Maybe<boolean>;
};

const formConfig: ObjectConfig<FormValue> = {
  startDate: { type: "value", rules: [required] },
  endDate: { type: "value", rules: [required] },
  tradePartners: { type: "value" },
  scheduleSubPhases: { type: "value" },
  withGantt: { type: "value" },
};

const mapToForm = () => (interval: StageInterval) => {
  const { startDate, endDate } = interval;
  return {
    startDate: ensureDateOnly(startDate),
    endDate: ensureDateOnly(endDate),
    tradePartners: [],
    scheduleSubPhases: [],
  };
};
