import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useSaveChangeEventLineItemsMutation } from "src/generated/graphql-types";

type EraseChangeEventPricesModalProps = {
  clientNoun: string;
  lineItemIds: string[];
  onErase: () => void;
};

export function EraseChangeEventPricesModal(props: EraseChangeEventPricesModalProps) {
  const { clientNoun, lineItemIds, onErase } = props;
  const [saveChangeEventLineItems] = useSaveChangeEventLineItemsMutation();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Convert to Internal Change Event?</ModalHeader>
      <ModalBody>
        <p>
          There is a {clientNoun} Price currently associated with one or more line items on this Change Event. All
          Homeowner facing prices must be set to "$0" on the Change Event prior to marking it as internal. Clicking
          "Erase Prices" below will set the price to all associated line items to $0. This cannot be undone.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Erase Prices"
          variant="danger"
          onClick={async () => {
            await saveChangeEventLineItems({
              variables: {
                input: {
                  changeEventLineItems: lineItemIds.map((id) => ({ id, totalPriceInCents: 0 })),
                },
              },
            });
            onErase();
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
