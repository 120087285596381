import {
  BoundTextAreaField,
  Button,
  Chip,
  Css,
  Icon,
  IconButton,
  Palette,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Percentage, Price } from "src/components";
import { TradePartnerSelectField } from "src/components/autoPopulateSelects/TradePartnerSelectField";

import { createDevelopmentCommitmentUrl, createDevelopmentContractPurchaseOrdersUrl } from "src/RouteUrls";
import {
  CommitmentEditorDataFragment,
  CommitmentStatus,
  NamedFragment,
  SaveCommitmentInput,
  TradePartnerOnboardingStatus,
  useSaveNewCommitmentMutation,
} from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString } from "src/utils";
import { AddDepositModal } from "./AddDepositModal";

type OverviewProps = {
  commitment: CommitmentEditorDataFragment;
};

export function CommitmentOverview({ commitment }: OverviewProps) {
  const tid = useTestIds({});
  const [saveCommitment] = useSaveNewCommitmentMutation();
  const [isSubHeaderOpen, toggleSubHeader] = useState(false);

  const isSigned = commitment.status === CommitmentStatus.Signed;

  const formState = useFormState({
    config: formConfig,
    init: { input: commitment, map: mapToForm },
    readOnly: isSigned,
  });

  const handleSave = async () => {
    if (formState.canSave()) {
      if (commitment && !isSigned) {
        await saveCommitment({ variables: { input: formState.value } });
        toggleSubHeader(false);
      }
    }
  };

  const hasInternalNote = !!formState.internalNote.value;

  const internalNote =
    isSubHeaderOpen && !isSigned ? (
      <BoundTextAreaField field={formState.internalNote} label="Internal Note" labelStyle="hidden" />
    ) : (
      <p css={Css.tiny.$} {...tid.internalNoteReading}>
        {formState.internalNote.value}
      </p>
    );

  const internalNoteCta =
    !hasInternalNote && !isSubHeaderOpen ? (
      <Button
        variant="text"
        label={<span css={Css.blue400.tinyMd.$}>Click to add</span>}
        onClick={() => toggleSubHeader(true)}
        {...tid.clickToAddButton}
      />
    ) : (
      isSubHeaderOpen && (
        <Button
          variant="text"
          label={<span css={Css.blue400.tinyMd.$}>Save note</span>}
          onClick={() => handleSave()}
          {...tid.saveNoteButton}
        />
      )
    );

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.df.jcsb.mh("86px").bgWhite.bshBasic.mb3.p2.oh.br8.$}>
        <div css={Css.df.jcsb.gap(7).$}>
          <TradePartnerSection commitment={commitment} formState={formState} handleSave={handleSave} />
          <TradeSignatorySection commitment={commitment} isSubHeaderOpen={isSubHeaderOpen} />
          <ItemCodeSection
            commitment={commitment}
            isSubHeaderOpen={isSubHeaderOpen}
            toggleSubHeader={toggleSubHeader}
          />
          <DepositSection commitment={commitment} />
          <div className="internal-note">
            <div css={Css.tinySb.mb1.$} {...tid.internalNoteTitle}>
              {isSigned ? "Internal Note" : "Internal Note (optional)"}
            </div>
            {internalNote}
            {!isSigned && internalNoteCta}
          </div>
        </div>
        <div css={Css.df.jcfe.aifs.$}>
          <div className="total-committed" css={Css.df.fdc.aife.mr2.$}>
            <span css={Css.xlBd.$} {...tid.totalCommitted}>
              {formatPrice(sumCommittedLineItems(commitment))}
            </span>
            <span css={Css.xs.$}>Total committed</span>
          </div>
          <Button
            label={<Icon icon={isSubHeaderOpen ? "chevronUp" : "chevronDown"} color={Palette.White} />}
            variant="text"
            onClick={() => toggleSubHeader(!isSubHeaderOpen)}
            {...tid.toggleSubHeader}
          />
        </div>
      </div>
    </div>
  );
}

type TradePartnerSectionProps = {
  formState: ObjectState<SaveCommitmentInput>;
  handleSave: () => Promise<void>;
} & OverviewProps;

function TradePartnerSection({ commitment, formState, handleSave }: TradePartnerSectionProps) {
  const dc = commitment.developmentCommitment;
  const tid = useTestIds({});
  const [isChangeTradeParterEnabled, toggleChangeTradeParner] = useState(false);
  const [selectedTradePartner, setSelectedTradePartner] = useState<NamedFragment | undefined>(
    commitment.tradePartner ?? undefined,
  );

  return (
    <div css={Css.wPx(215).$}>
      <div {...tid.tradepartnerName}>
        {!isChangeTradeParterEnabled ? (
          <div css={Css.lgMd.df.fdr.$}>
            {commitment.tradePartner?.name}
            {/* Allow to edit the trade partner only for draft non-bid commitments */}
            {commitment.status === CommitmentStatus.Draft && !commitment.isBidCommitment && (
              <div css={Css.ml1.ptPx(2).$}>
                <IconButton
                  color={Palette.White}
                  contrast
                  compact
                  icon="pencil"
                  onClick={() => toggleChangeTradeParner(true)}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <TradePartnerSelectField
              labelStyle="hidden"
              value={selectedTradePartner}
              filter={{ onboardingStatus: [TradePartnerOnboardingStatus.Complete] }}
              onSelect={(_, tradePartner) => setSelectedTradePartner(tradePartner)}
            />

            <Button
              variant="text"
              label={<span css={Css.blue400.tinyMd.$}>Save</span>}
              onClick={async () => {
                formState.tradePartnerId.set(selectedTradePartner?.id);
                await handleSave();
                toggleChangeTradeParner(false);
              }}
              {...tid.saveTradePartner}
            />
          </>
        )}
      </div>
      {/* Link to the contract version or dev commitment if either is available */}
      {commitment.project.cohort?.development?.id && (
        <Link
          to={
            !!commitment.bidContractRevision
              ? createDevelopmentContractPurchaseOrdersUrl(
                  commitment.project.cohort.development.id,
                  commitment.bidContractRevision.id,
                )
              : !!dc
                ? createDevelopmentCommitmentUrl(dc.id)
                : ""
          }
          target="_blank"
          css={Css.xsSb.blue400.$}
          {...tid.contractLink}
        >
          {!!commitment.bidContractRevision
            ? `Contract ${commitment.bidContractRevision.version}`
            : !!dc
              ? "Group Commitment"
              : ""}
        </Link>
      )}
    </div>
  );
}

function TradeSignatorySection({ commitment, isSubHeaderOpen }: OverviewProps & { isSubHeaderOpen: boolean }) {
  return (
    <div css={Css.df.fdc.wPx(230).$}>
      <div css={Css.df.jcsb.fdc.$}>
        <div css={Css.df.jcsb.w100.add("flexWrap", "wrap").$}>
          <div css={Css.w25.tinySb.mb("4px").$}>Signatory</div>
          <div css={Css.w75.tiny.mb("4px").$} data-testid="tradeSignatoryName">
            {commitment.tradeSignatory?.name ?? "N/A"}
          </div>
        </div>
        {(commitment.tradeSignatory?.officePhone || commitment.tradeSignatory?.mobilePhone) && (
          <div css={Css.df.jcsb.w100.add("flexWrap", "wrap").$}>
            <div css={Css.w25.tinySb.mb("4px").$}>Phone</div>
            <div css={Css.w75.tiny.mb("4px").$} data-testid="tradeSignatoryPhone">
              {commitment.tradeSignatory?.officePhone || commitment.tradeSignatory?.mobilePhone}
            </div>
          </div>
        )}
        {isSubHeaderOpen && (
          <div css={Css.df.jcsb.w100.add("flexWrap", "wrap").$}>
            <div css={Css.w25.tinySb.mb("4px").$}>Email</div>
            <div css={Css.w75.tiny.mb("4px").$} data-testid="tradeSignatoryEmail">
              {commitment.tradeSignatory?.email ?? "N/A"}
            </div>
          </div>
        )}
        {isSubHeaderOpen && (
          <div css={Css.df.jcsb.w100.add("flexWrap", "wrap").$}>
            <div css={Css.w25.tinySb.mb("4px").$}>Billing</div>
            <div css={Css.w75.tiny.mb("4px").$} data-testid="tradeSignatoryBilling">
              {formatAddress(commitment)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

type ItemCodeSectionProps = {
  toggleSubHeader: React.Dispatch<React.SetStateAction<boolean>>;
  isSubHeaderOpen: boolean;
} & OverviewProps;

function ItemCodeSection({ commitment, isSubHeaderOpen, toggleSubHeader }: ItemCodeSectionProps) {
  const costCodeLineItems = commitment.costCodeSummary.lineItems;
  return (
    <div css={Css.w("230px").$} className="item-codes">
      <div css={Css.tinySb.$}>Item Codes</div>
      <>
        {costCodeLineItems.length > 0 ? (
          <div css={Css.dif.aib.gap(1).add("flexWrap", "wrap").$}>
            {!isSubHeaderOpen ? (
              <>
                {costCodeLineItems.slice(0, 2).map((ccli) => (
                  <Chip key={ccli.costCode.id} text={ccli.costCode.number} compact />
                ))}
                {costCodeLineItems.length > 2 && (
                  <Button
                    variant="text"
                    label={<span css={Css.blue400.tinyMd.$}>{`+${costCodeLineItems.length - 2} more...`}</span>}
                    onClick={() => toggleSubHeader(true)}
                    data-testid="buttonMore"
                  />
                )}
              </>
            ) : (
              <>
                {costCodeLineItems.map((ccli) => (
                  <Chip key={ccli.costCode.id} text={ccli.costCode.number} compact />
                ))}
              </>
            )}
          </div>
        ) : (
          <p css={Css.tiny.$}>No item codes exist for this commitment</p>
        )}
      </>
    </div>
  );
}

const formConfig: ObjectConfig<SaveCommitmentInput> = {
  id: { type: "value" },
  internalNote: { type: "value" },
  projectStageId: { type: "value", rules: [required] },
  tradePartnerId: { type: "value", rules: [required] },
};

function mapToForm(commitment: CommitmentEditorDataFragment): SaveCommitmentInput {
  return {
    id: commitment.id,
    internalNote: commitment.internalNote,
    projectStageId: commitment.projectStage.id,
    tradePartnerId: commitment.tradePartner?.id,
  };
}

function sumCommittedLineItems(commitment: CommitmentEditorDataFragment) {
  return (commitment.lineItems || []).sum((li) => li.costChangeInCents || 0);
}

function formatPrice(valueInCents: number) {
  return `$${formatNumberToString(centsToDollars(valueInCents))}`;
}

function formatAddress(commitment: CommitmentEditorDataFragment) {
  const address = commitment.tradeSignatory?.address;
  if (!address) return "N/A";
  const unitAddress = address?.street2 ?? "";
  return `${address.street1} ${unitAddress}, ${address?.city} ${address?.postalCode}`;
}

function DepositSection(props: { commitment: CommitmentEditorDataFragment }) {
  const { depositInBasisPoints, depositScheduleTask, costChangeInCents, canBeEdited } = props.commitment;
  const depositPercent = (depositInBasisPoints ?? 0) / 100;
  const depositFixedValue = (depositPercent / 100) * costChangeInCents;

  const tid = useTestIds({});
  const { openModal } = useModal();

  return (
    <div css={Css.tinySb.$} {...tid.depositSection}>
      Deposit
      <div css={Css.tiny.$}>
        <div css={Css.df.fdr.gap1.tinySb.$}>
          {Math.round(depositPercent) > 0 && <Percentage percent={Math.round(depositPercent)} />}
          <Price valueInCents={depositFixedValue} />
        </div>
        {depositScheduleTask && (
          <div css={Css.df.fdc.my1.$} {...tid.depositTask}>
            <p css={Css.tinySb.$}>Bill Deposit on task complete:</p>
            <p>{depositScheduleTask.name}</p>
          </div>
        )}
      </div>
      {canBeEdited && (
        <Button
          variant="text"
          label={depositInBasisPoints ? "Edit Deposit" : "Add Deposit"}
          onClick={() =>
            openModal({
              content: <AddDepositModal commitment={props.commitment} />,
            })
          }
        />
      )}
    </div>
  );
}
