import { Css, Step, useModal, useTestIds } from "@homebound/beam";
import kebabCase from "lodash/kebabCase";
import React, { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { StepperProvider, useStepperContext } from "src/components/stepper";
import { topNavHeight } from "src/routes/layout/GlobalNav";
import { fail } from "src/utils";
import dedent from "ts-dedent";
import { useUpdateEffect } from "usehooks-ts";

/**
 * A Stepper-Step-like type with a `render` value to link Content + Stepper Steps together. Manages state internally,
 * although any Step (or the given Provider) can reach up to useStepperProvider() if required for edge cases.
 */
export type WizardStep = Omit<Step, "state" | "value"> & {
  render: () => React.ReactNode;
};

type UseStepperWizardParams = {
  steps: WizardStep[];
  /** Wraps each step Step if provided, allowing shared Context across steps */
  Provider?: React.FunctionComponent<PropsWithChildren>;
};

/**
 * This is syntactic sugar around an actual fullscreen modal. Primes a stepper with the given steps
 * and returns an openWizard VoidFunction you can call, like you would openModal()
 *
 * Suggested usage is to wrap this in a custom useMyFlowWizard() hook where you can declare a
 * WizardStep[] and hydrate this hook before reexporting openWizard to your main component.
 */
export function useStepperWizard({ steps, Provider: GivenProvider = React.Fragment }: UseStepperWizardParams) {
  const { openModal, closeModal } = useModal();
  const initialSteps = useMemo<Step[]>(
    () => steps.map((step) => ({ ...step, state: "incomplete", value: kebabCase(step.label) })),
    [steps],
  );
  const opened = useRef(false); // only used for the Warning
  const tid = useTestIds({});
  /**
   * Show an error if initialSteps updates unexpectedly. <StepperProvider /> typically allows it to be
   * updated (or controlled externally), but Modals will not detect it because openModal({ content: ... })
   * is only called one time.
   */
  useUpdateEffect(() => {
    if (!opened.current) return; // If modal isn't open, then nothing to complain about.
    console.error(dedent`
      initialSteps updated unexpectedly. openModal() is only called once, so any subsequent
      updates need to happen within the content it renders, not outside of the modal.
    `);
  }, [initialSteps]);

  const openWizard = useCallback<VoidFunction>(() => {
    closeModal(); // Since we're about to open our own modal, close any others so there aren't issues
    opened.current = true;
    openModal({
      content: (
        <div css={Css.absolute.topPx(topNavHeight).left0.bottom0.right0.bgWhite.$} {...tid.wizard}>
          <StepperProvider steps={initialSteps}>
            <GivenProvider>
              <StepPicker steps={steps} />
            </GivenProvider>
          </StepperProvider>
        </div>
      ),
      onClose: () => (opened.current = false),
    });
  }, [GivenProvider, closeModal, initialSteps, openModal, steps, tid.wizard]);

  return { openWizard };
}

/** Determines which `step` to render. */
function StepPicker({ steps }: { steps: WizardStep[] }) {
  const { currentStep } = useStepperContext();
  const { render: Component } =
    steps.find((s) => s.label === currentStep.label) ?? fail(`No Wizard step found for ${currentStep.label}`);
  return <>{Component()}</>;
}
