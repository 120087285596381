import { Css, useTestIds } from "@homebound/beam";

type SectionTitleProps = {
  testId?: string;
  title: string;
};

export function SectionTitle(props: SectionTitleProps) {
  const { title, testId = "sectionTitle" } = props;
  const { title: titleId } = useTestIds({ title }, testId);
  return (
    <h2 css={Css.gray800.base.m0.$} {...titleId}>
      {title}
    </h2>
  );
}
