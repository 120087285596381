import { BoundSelectField, Button, FormLines, ModalBody, ModalFooter, ModalHeader, useSnackbar } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { LienWaiverType, SaveLienWaiverInput, useSaveLienWaiverMutation } from "src/generated/graphql-types";

type CreateLienWaiverModalProps = {
  tradePartners: { id: string; name: string }[];
  projects: { id: string; name: string }[];
  closeModal: () => void;
};

export function CreateLienWaiverModal({ tradePartners, projects, closeModal }: CreateLienWaiverModalProps) {
  const { triggerNotice } = useSnackbar();
  const [saveFinalLienWaiver] = useSaveLienWaiverMutation();

  const formState = useFormState({ config: formConfig });

  return (
    <>
      <ModalHeader>Create Final Lien Waiver</ModalHeader>
      <ModalBody>
        <form>
          <FormLines width="full">
            <BoundSelectField field={formState.claimantId} label="Trade Partner" options={tradePartners} />
            <BoundSelectField field={formState.parentId} label="Project" options={projects} />
          </FormLines>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Save"
          onClick={async () => {
            try {
              const { data } = await saveFinalLienWaiver({
                variables: { input: { ...formState.value, type: LienWaiverType.Final } },
              });
              if (data?.saveLienWaiver.lienWaiver) {
                triggerNotice({
                  icon: "success",
                  message: `Final Lien Waiver ${data.saveLienWaiver.lienWaiver.id} successfully created`,
                });
              }
            } catch (e) {
              console.error(e);
            } finally {
              closeModal();
            }
          }}
        />
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveLienWaiverInput> = {
  claimantId: { type: "value", rules: [required] },
  parentId: { type: "value", rules: [required] },
};
