import {
  BoundSelectField,
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  column,
  numericColumn,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { priceCell } from "src/components";
import {
  BidContractType,
  BidPackageDetailPageDocument,
  BidPackageItemTemplateItemFragment,
  PageBidPackageDetailFragment,
  SaveBidPackageAwardInput,
  SaveBidPackageAwardsInput,
  useSaveBidPackageAwardsMutation,
} from "src/generated/graphql-types";

type AwardBidModalProps = {
  bidPackage: PageBidPackageDetailFragment;
};

export function AwardBidModal({ bidPackage }: AwardBidModalProps) {
  const { closeModal } = useModal();
  const [saveBidPackageAwards] = useSaveBidPackageAwardsMutation({ refetchQueries: [BidPackageDetailPageDocument] });

  const formState = useFormState({
    config: formConfig,
    init: {
      input: bidPackage,
      map: (bidPackage) => ({ bidPackageAwards: mapOrCreateBidPackageAwards(bidPackage) }),
    },
  });

  async function saveAndExit() {
    await saveBidPackageAwards({
      variables: {
        input: {
          // todo add test for this
          bidPackageAwards: formState.bidPackageAwards.value.filter((award) => award.primaryRevisionId !== undefined),
        },
      },
    });
    closeModal();
  }

  return (
    <div css={Css.df.fdc.p2.bgGray100.h100.$}>
      <div css={Css.asfe.pr1.$}>
        <IconButton icon="x" onClick={closeModal} />
      </div>
      <div css={Css.xl3Sb.asc.$} data-testid="award_title">
        Award Bid
      </div>
      <div css={Css.base.asc.pt2.$}>
        Here you can assign primary and secondary trades for specific scopes of work. After saving, you'll be able to
      </div>
      <div css={Css.base.asc.$}>award and release work to the selected trades when needed.</div>
      <div css={Css.p4.$}>
        <GridTable columns={createColumns(bidPackage, formState)} rows={createRows(bidPackage)} />
      </div>
      <div css={Css.fixed.bottom0.w100.px6.py3.bshHover.df.$}>
        <div css={Css.mla.$}>
          <Observer>
            {() => <Button label="Save & Return" onClick={saveAndExit} size="lg" disabled={!formState.dirty} />}
          </Observer>
        </div>
      </div>
    </div>
  );
}

function createColumns(
  bidPackage: PageBidPackageDetailFragment,
  formState: ObjectState<SaveBidPackageAwardsInput>,
): GridColumn<Row>[] {
  const requests = bidPackage.requests;
  return [
    column<Row>({
      header: "Cost Code",
      costCode: (cc) => cc.displayName,
    }),
    // Generate a column for each BPR which is unqiue to trade partner
    ...requests.map((r) => {
      return numericColumn<Row>({
        header: r.tradePartner.name,
        // For each cost code, sum the total cost for all line items that have the same cost code
        costCode: (cc) => {
          // if bid contract type is unit based then use the cost from the bcli
          if (r.bidContract?.type?.code === BidContractType.UnitBased) {
            // Scope line itis contain the quantity of items
            const scopeLineItis = bidPackage.scopeLines.map((sl) => sl.itemTemplateItem);
            // For plan bids, from all the line items on the bid contract
            const planBasedValue =
              r.bidContract?.latestRevision.lineItems
                // only get the line items that match the cost code
                .filter((bcli) => bcli.bidItem?.items.first?.costCode.id === cc.id)
                // for each BCLI, I need to get all the associated scope line itis for this cost code and sum the quantity
                .map(
                  (bcli) =>
                    (bcli.totalCostInCents ?? 0) *
                    scopeLineItis
                      .filter((slIti) => {
                        return slIti.bidItem?.id === bcli.bidItem?.id && slIti.item.costCode.id === cc.id;
                      })
                      .map((slIti) => slIti.quantity ?? 0)
                      .sum(),
                )
                .sum() ?? 0;
            const valueInCents = bidPackage.isUnitBased
              ? // For unit bids, sum the direct bcli total for the matching costCode bid item
                r.bidContract?.latestRevision.lineItems
                  .filter((bcli) => bcli.bidItem?.items.first?.costCode.id === cc.id)
                  .sum((bcli) => bcli.totalCostInCents ?? 0)
              : planBasedValue;

            return priceCell({ valueInCents });
          }
          // if the bids are prorated use the bcli with a iti that matches the cost code
          const proratedValueInCents =
            r.bidContract?.latestRevision.lineItems // From all the line items on the bid contract
              .filter((bcli) => bcli.itemTemplateItem?.item.costCode.id === cc.id) // only get the line items that match the cost code
              .map((bcli) => bcli.totalCostInCents ?? 0)
              .sum() ?? 0;
          // if the bids are prorated use the price from the iti on the bcli
          return priceCell({ valueInCents: proratedValueInCents });
        },
      });
    }),
    column<Row>({
      header: "Primary",
      costCode: (cc) => (
        <BoundSelectField
          data-testid="primary"
          options={bidPackage.requests}
          field={formState.bidPackageAwards.rows.find((r) => r.costCodeId.value === cc.id)!.primaryRevisionId}
          key={cc.id}
          getOptionLabel={(r) => r.tradePartner.name}
          getOptionValue={(r) => r.bidContract?.latestRevision.id ?? ""}
        />
      ),
    }),
    column<Row>({
      header: "Secondary",
      costCode: (cc) => (
        <BoundSelectField
          data-testid="secondary"
          options={bidPackage.requests}
          field={formState.bidPackageAwards.rows.find((r) => r.costCodeId.value === cc.id)!.secondaryRevisionId}
          key={cc.id}
          getOptionLabel={(r) => r.tradePartner.name}
          getOptionValue={(r) => r.bidContract?.latestRevision.id ?? ""}
        />
      ),
    }),
  ];
}

function createRows(bidPackage: PageBidPackageDetailFragment): GridDataRow<Row>[] {
  const unitBidLineItems = bidPackage.lineItems
    .map((bpli) => bpli.bidItem.items.first?.costCode)
    .compact()
    .uniqueByKey("id")
    .map((cc) => ({
      kind: "costCode" as const,
      id: cc.id,
      data: cc,
    }));

  const planBidLineItems = bidPackage.scopeLines
    .map((sl) => sl.itemTemplateItem.item.costCode)
    .uniqueByKey("id")
    .map((cc) => ({
      kind: "costCode" as const,
      id: cc.id,
      data: cc,
    }));
  return [simpleHeader, ...(bidPackage.isUnitBased ? unitBidLineItems : planBidLineItems)];
}

type HeaderRow = { kind: "header"; data: undefined };
type CostCodeRow = {
  kind: "costCode";
  data: BidPackageItemTemplateItemFragment["item"]["costCode"];
};

type Row = HeaderRow | CostCodeRow;

const formConfig: ObjectConfig<SaveBidPackageAwardsInput> = {
  bidPackageAwards: {
    type: "list",
    config: {
      id: { type: "value" },
      bidPackageId: { type: "value" },
      costCodeId: { type: "value" },
      primaryRevisionId: { type: "value" },
      secondaryRevisionId: { type: "value" },
    },
  },
};

function mapOrCreateBidPackageAwards(bidPackage: PageBidPackageDetailFragment) {
  // get all the cost codes
  const costCodes = bidPackage.isUnitBased
    ? bidPackage.lineItems
        .map((bpli) => bpli.bidItem.items.first?.costCode)
        .compact()
        .uniqueByKey("id")
    : bidPackage.scopeLines.map((sl) => sl.itemTemplateItem.item.costCode).uniqueByKey("id");
  // Need to create a row for each cost code that does not exist
  return costCodes.map<SaveBidPackageAwardInput>((cc) => {
    const award = bidPackage.awards.find((award) => award.costCode.id === cc.id);
    return {
      costCodeId: cc.id,
      bidPackageId: bidPackage.id,
      id: award?.id,
      primaryRevisionId: award?.primaryRevision.id,
      secondaryRevisionId: award?.secondaryRevision?.id,
    };
  });
}
