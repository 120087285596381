import {
  Button,
  column,
  Filters,
  GridColumn,
  GridDataRow,
  multiFilter,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
  singleFilter,
  usePersistedFilter,
  useSuperDrawer,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { emptyCellDash, QueryTable, SearchBox } from "src/components";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  AdminItemFragment,
  FeatureFlagType,
  ItemFilter,
  ItemsPageMetadataQuery,
  ItemsQuery,
  useItemsPageMetadataQuery,
  useItemsQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { queryResult } from "src/utils";
import { AdminItemsDrawer } from "./AdminItemsDrawer";

/** Lists all variables available in the system, allows adding new ones. */
export function ItemsPage() {
  const query = useItemsPageMetadataQuery();
  return queryResult(query, {
    data: ({ costCodes, cutoffs }) => {
      return <ItemsPageDataView costCodes={costCodes} cutoffs={cutoffs} />;
    },
  });
}

function ItemsPageDataView(props: {
  costCodes: ItemsPageMetadataQuery["costCodes"];
  cutoffs: ItemsPageMetadataQuery["cutoffs"];
}) {
  const { costCodes, cutoffs } = props;
  const { openInDrawer } = useSuperDrawer();
  const isMaterialCatalogEnabled = useFeatureFlag(FeatureFlagType.MaterialCatalog);
  const filterDefs = useMemo(() => {
    return {
      costCode: multiFilter({
        options: costCodes,
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => `${o.displayName} V${o.version}`,
      }),
      version: singleFilter({
        options: [
          { id: 1, name: "1" },
          { id: 2, name: "2" },
        ],
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      }),
    };
  }, [costCodes]);

  const { setFilter, filter } = usePersistedFilter<LocalItemFilter>({
    storageKey: "itemsFilter",
    filterDefs,
  });

  const query = useItemsQuery({
    variables: {
      // Map our version number -> backend number[]
      filter: { version: filter.version ? [filter.version] : [1, 2] },
    },
  });
  const [search, setSearch] = useState<string | undefined>();

  const rowStyles = useMemo(
    () =>
      ({
        header: {},
        data: {
          onClick: (d) =>
            openInDrawer({
              content: (
                <AdminItemsDrawer
                  selectedItem={d.data}
                  isMaterialCatalogEnabled={isMaterialCatalogEnabled}
                  costCodes={costCodes || []}
                  unitsOfMeasure={query.data?.unitsOfMeasure || []}
                  cutoffs={cutoffs}
                />
              ),
            }),
        },
      }) as RowStyles<Row>,
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data],
  );

  return (
    <>
      <PageHeader
        title="Items"
        right={
          <>
            <Button
              variant="secondary"
              label="Add Item"
              onClick={() =>
                openInDrawer({
                  content: (
                    <AdminItemsDrawer
                      selectedItem={undefined}
                      isMaterialCatalogEnabled={isMaterialCatalogEnabled}
                      costCodes={costCodes || []}
                      unitsOfMeasure={query.data?.unitsOfMeasure || []}
                      cutoffs={cutoffs}
                    />
                  ),
                })
              }
            />
          </>
        }
      />

      <TableActions>
        <Filters<LocalItemFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearch} />
      </TableActions>
      <ScrollableContent>
        <QueryTable
          stickyHeader
          sorting={{ on: "client" }}
          {...{ query, createRows, rowStyles, columns, filter: search, filterMaxRows: 100 }}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<AdminItemFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({ header: "ID", data: ({ id }) => id, w: "50px" }),
  column<Row>({ header: "Item Code", data: (d) => `${d.costCode.number}.${String(d.itemCode).padStart(3, "0")}` }),
  column<Row>({ header: "Name", data: (d) => d.name }),
  column<Row>({ header: "Cost Code", data: (d) => `${d.costCode.number} ${d.costCode.name}` }),
  column<Row>({ header: "Material Code", data: (d) => d.materialCodePrefix }),
  column<Row>({ header: "Selection?", data: (d) => (d.isSelection ? "Yes" : "No") }),
  column<Row>({
    header: "Dimensions",
    data: (d) => ({
      content: d.materialAttributeDimensions.isEmpty ? emptyCellDash : d.materialAttributeDimensions.length,
      value: d.materialAttributeDimensions.length ?? 0,
    }),
  }),
];

function createRows(data: ItemsQuery | undefined): GridDataRow<Row>[] {
  return [simpleHeader, ...(data?.items.map((v) => ({ kind: "data" as const, id: v.id, data: v })) || [])];
}

// Replace version with just `number` so we can use singleFilter
type LocalItemFilter = Omit<ItemFilter, "version"> & {
  version: number;
};
