import { Css, useBreakpoint } from "@homebound/beam";
import { SchedulingModal } from "src/routes/personal-dashboard/components/SchedulingModal";
import { TasksSummaryHeader } from "src/routes/personal-dashboard/components/TasksSummaryHeader";
import { ApprovalSummaryHeader } from "./ApprovalSummaryHeader";
import { BillsSummaryHeader } from "./BillsSummaryHeader";

export function HeaderSummary() {
  const { sm } = useBreakpoint();
  const separator = <div css={Css.wPx(1).bgGray300.$} />;
  return (
    <div css={Css.df.jcsb.gap4.if(sm).gap1.$}>
      <div>
        <span css={Css.df.aic.gray900.$}>
          <BillsSummaryHeader />
        </span>
      </div>
      {separator}
      <div>
        <span css={Css.df.aic.gray900.$}>
          <ApprovalSummaryHeader />
        </span>
      </div>
      {separator}
      <div>
        <span css={Css.df.aic.gray900.$}>
          <TasksSummaryHeader />
        </span>
      </div>
      {separator}
      <div css={Css.gray900.$}>
        <SchedulingModal />
      </div>
    </div>
  );
}
