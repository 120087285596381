import { Button, ModalBody, ModalFooter, ModalHeader, useModal, useTestIds } from "@homebound/beam";

export type BulkUploadMessageResponse = { row: number; message: string };

type BulkUploadMessagesModalProps = {
  warnings: BulkUploadMessageResponse[];
};

export default function BulkUploadMessagesModal(props: BulkUploadMessagesModalProps) {
  const { warnings } = props;
  const { closeModal } = useModal();
  const testIds = useTestIds({}, "bulkUploadMessagesModal");

  return (
    <>
      <ModalHeader>Bulk upload details</ModalHeader>
      <ModalBody>
        <div>
          <div {...testIds.description}>There were a total of {warnings.length} warnings in the bulk upload</div>
          <ul {...testIds.messages}>
            {warnings.map(({ row, message }) => (
              <li key={row}>
                {message}
                {row ? `, at row ${row}` : ""}
              </li>
            ))}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" label="Close" onClick={closeModal} />
      </ModalFooter>
    </>
  );
}
