import {
  BoundMultiSelectField,
  Css,
  GridColumn,
  GridTable,
  GridTableApi,
  IconButton,
  Palette,
  ScrollableContent,
  Tooltip,
  collapseColumn,
  column,
  dateColumn,
  emptyCell,
  selectColumn,
  simpleHeader,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { createWarrantyTicketDetailsUrl } from "src/RouteUrls";
import { CommentCountBubble, dateCell } from "src/components";
import {
  Address,
  InternalUserDetailFragment,
  SaveWarrantyTicketInput,
  SaveWarrantyTicketItemInput,
  WarrantyTicketDetailsFragment,
  WarrantyTicketItemDetailsFragment,
  WarrantyTicketItemStatusesFragment,
  useSaveWarrantyTicketMutation,
} from "src/generated/graphql-types";
import { groupBy, safeEntries } from "src/utils";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";
import { getWarrantyItemDurationChip, getWarrantyTicketDurationChip } from "./WarrantyPage";
import { WarrantyTicketItemStatusSelect } from "./components/WarrantyTicketItemStatusSelect";

type WarrantyPageTableProps = {
  warrantyTickets: WarrantyTicketDetailsFragment[];
  tableApi: GridTableApi<WarrantyRow>;
  searchFilter: string | undefined;
  warrantyTicketItemStatusesDetail: WarrantyTicketItemStatusesFragment[];
  teamMembers: InternalUserDetailFragment[];
};

export function WarrantyPageTable(props: WarrantyPageTableProps) {
  const { warrantyTickets, tableApi, searchFilter, warrantyTicketItemStatusesDetail, teamMembers } = props;
  const history = useHistory();
  const [saveWarrantyTicket] = useSaveWarrantyTicketMutation();
  const { getFormState } = useFormStates<Partial<TicketFormInput>, WarrantyTicketDetailsFragment>({
    config: formConfig,
    map: (wt) => ({
      id: wt.id,
      assigneeIds: wt.assignees.map((a) => a.id),
      items: wt.items.map((wti) => ({ id: wti.id, status: wti.status.code })),
      urgent: wt.urgent,
    }),
    autoSave: async (os) => {
      await saveWarrantyTicket({ variables: { input: os.changedValue } });
    },
    getId: (t) => t.id,
  });

  const columns = useMemo(
    () => createColumns(warrantyTicketItemStatusesDetail, getFormState, history, teamMembers),
    [getFormState, warrantyTicketItemStatusesDetail, history, teamMembers],
  );

  const rows = useMemo(() => createRows(warrantyTickets), [warrantyTickets]);

  return (
    <ScrollableContent virtualized>
      <GridTable
        as="virtual"
        columns={columns}
        rows={rows}
        sorting={{ on: "client", initial: ["actions", "DESC"] }}
        filter={searchFilter}
        api={tableApi}
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DevelopmentRow = {
  kind: "development";
  data: string;
};
type WarrantyTicketRow = {
  kind: "warrantyTicket";
  data: WarrantyTicketDetailsFragment;
};
type WarrantyTicketItemRow = {
  kind: "warrantyTicketItem";
  data: { wti: WarrantyTicketItemDetailsFragment; ticket: WarrantyTicketDetailsFragment };
};

export type WarrantyRow = HeaderRow | DevelopmentRow | WarrantyTicketRow | WarrantyTicketItemRow;

function createRows(warrantyTickets: WarrantyTicketDetailsFragment[]) {
  const developmentGroups = groupBy(warrantyTickets, (wt) => wt.project.cohort?.development?.name!);
  return [
    simpleHeader,
    ...safeEntries(developmentGroups).map(([development, warrantyTickets]) => ({
      kind: "development" as const,
      data: development,
      id: development,
      children: warrantyTickets.map((wt) => ({
        kind: "warrantyTicket" as const,
        data: wt,
        id: wt.id,
        children:
          wt.items.length === 0
            ? undefined
            : wt.items.map((wti) => ({
                kind: "warrantyTicketItem" as const,
                data: { wti, ticket: wt },
                id: wti.id,
              })),
      })),
    })),
  ];
}

function getFormattedAddress(address: Pick<Address, "street1" | "street2">) {
  return address.street2 ? `${address.street1}, ${address.street2}` : address.street1;
}

function createColumns(
  warrantyTicketItemStatusesDetail: WarrantyTicketItemStatusesFragment[],
  getFormState: (wt: WarrantyTicketDetailsFragment) => ObjectState<Partial<TicketFormInput>>,
  history: ReturnType<typeof useHistory>,
  teamMembers: InternalUserDetailFragment[],
): GridColumn<WarrantyRow>[] {
  return [
    collapseColumn<WarrantyRow>(),
    selectColumn<WarrantyRow>({ w: "24px" }),
    column<WarrantyRow>({
      header: "Project",
      development: (development) => ({
        content: development,
        css: Css.baseSb.$,
      }),
      warrantyTicket: (wt) => ({
        content: wt.project.buildAddress && getFormattedAddress(wt.project.buildAddress),
        css: Css.smMd.$,
      }),
      warrantyTicketItem: (wti) => ({
        content: wti.ticket.project.buildAddress && getFormattedAddress(wti.ticket.project.buildAddress!),
        css: Css.xs.plPx(24).$,
      }),
    }),
    column<WarrantyRow>({
      header: "Name",
      development: emptyCell,
      warrantyTicket: (wt) => ({
        typeScale: "smSb",
        onClick: createWarrantyTicketDetailsUrl(wt.id),
        content: wt.title,
        css: Css.smMd.$,
        value: wt.title,
      }),
      warrantyTicketItem: emptyCell,
    }),
    column<WarrantyRow>({
      header: "Ticket Type",
      development: emptyCell,
      clientSideSort: false,
      warrantyTicket: (wt) => ({ content: wt.type?.name, css: Css.sm.$ }),
      warrantyTicketItem: ({ wti }) => ({ content: wti.type.name, css: Css.sm.$ }),
    }),
    column<WarrantyRow>({
      mw: "200px",
      header: "Status",
      development: emptyCell,
      clientSideSort: false,
      warrantyTicket: (wt) => ({
        content: `${wt.totalCompletedItems} of ${wt.totalAvailableItems} Completed`,
        css: Css.sm.$,
      }),
      warrantyTicketItem: ({ ticket, wti }) => {
        const os = getFormState(ticket);
        const itemState = os.items.rows.find((row) => row.id.value === wti.id)!;
        return (
          <WarrantyTicketItemStatusSelect options={warrantyTicketItemStatusesDetail} statusField={itemState.status} />
        );
      },
    }),
    column<WarrantyRow>({
      header: "Duration",
      development: emptyCell,
      align: "center",
      warrantyTicket: (wt) => {
        const chip = getWarrantyTicketDurationChip(wt);
        return chip ? chip : emptyCell;
      },
      warrantyTicketItem: ({ wti }) => {
        const chip = getWarrantyItemDurationChip(wti);
        return chip ? chip : emptyCell;
      },
    }),
    column<WarrantyRow>({
      header: "Description",
      development: emptyCell,
      warrantyTicket: emptyCell,
      clientSideSort: false,
      warrantyTicketItem: ({ wti }) => ({
        content: wti.description,
        css: Css.sm.truncate.$,
      }),
    }),
    dateColumn<WarrantyRow>({
      header: "Date Submitted",
      development: emptyCell,
      warrantyTicket: (wt) => dateCell(wt.createdAt),
      warrantyTicketItem: ({ wti }) => dateCell(wti.createdAt),
    }),
    dateColumn<WarrantyRow>({
      header: "Last Updated",
      development: emptyCell,
      warrantyTicket: (wt) => dateCell(wt.updatedAt),
      warrantyTicketItem: ({ wti }) => dateCell(wti.updatedAt),
    }),
    column<WarrantyRow>({
      mw: "180px",
      header: "Assignees",
      clientSideSort: false,
      development: emptyCell,
      warrantyTicket: (wt) => {
        const os = getFormState(wt);
        return (
          <BoundMultiSelectField
            label="Assignees"
            labelStyle="hidden"
            field={os.assigneeIds}
            options={teamMembers}
            fieldDecoration={internalUserAvatar}
            getOptionMenuLabel={internalUserMenuLabel}
          />
        );
      },
      warrantyTicketItem: emptyCell,
    }),
    column<WarrantyRow>({
      id: "actions",
      header: "Actions",
      w: "140px",
      sticky: "right",
      development: emptyCell,
      warrantyTicket: (wt) => {
        const { streams } = wt;
        return {
          content: () => {
            const os = getFormState(wt);
            return (
              <div css={Css.df.aic.gap1.jcfe.$}>
                <Tooltip title={os.urgent.value ? "Urgent" : "Not Urgent"}>
                  <div>
                    <IconButton
                      icon={os.urgent.value ? "flag" : "outlineFlag"}
                      color={os.urgent.value ? Palette.Red600 : undefined}
                      onClick={() => os.urgent.set(!os.urgent.value)}
                    />
                  </div>
                </Tooltip>
                <CommentCountBubble
                  streams={streams}
                  onClick={() => history.push(createWarrantyTicketDetailsUrl(wt.id))}
                />
              </div>
            );
          },
          value: wt.urgent,
        };
      },
      warrantyTicketItem: emptyCell,
      clientSideSort: false,
    }),
  ];
}

type ItemFormInput = Pick<SaveWarrantyTicketItemInput, "id" | "status">;
type TicketFormInput = {
  id: SaveWarrantyTicketInput["id"];
  items: ItemFormInput[];
  urgent: SaveWarrantyTicketInput["urgent"];
  assigneeIds: SaveWarrantyTicketInput["assigneeIds"];
};

const formConfig: ObjectConfig<Partial<TicketFormInput>> = {
  id: { type: "value" },
  items: {
    type: "list",
    config: {
      id: { type: "value" },
      status: { type: "value" },
    },
  },
  urgent: { type: "value" },
  assigneeIds: { type: "value" },
};
