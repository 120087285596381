import { Button, Css } from "@homebound/beam";
import { Observer } from "mobx-react";
import { ListFieldState } from "src/utils/formState";

// We don't use U but it fixed some weird type inference errors.
export type AddLineItemButtonProps<U> = {
  id?: string;
  label?: string;
  list: ListFieldState<U>;
  onClick?: () => void;
  disabled?: boolean;
};

export function AddLineItemButton<U>(props: AddLineItemButtonProps<U>) {
  const { id = "add", list, label = "Line Item", onClick, disabled = false } = props;
  return (
    <Observer>
      {() => (
        <span css={Css.if(list.readOnly).vh.$}>
          <Button
            variant="tertiary"
            data-testid={id}
            icon="plus"
            label={label}
            disabled={list.readOnly || disabled}
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                list.add({} as any);
              }
            }}
          />
        </span>
      )}
    </Observer>
  );
}
