import { SelectField, SelectFieldProps } from "@homebound/beam";
import { UnitsOfMeasureDataFragment, useUnitsOfMeasureQuery } from "src/generated/graphql-types";

type unitsOfMeasureFieldProps = Omit<
  SelectFieldProps<UnitsOfMeasureDataFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  getOptionLabel?: (o: UnitsOfMeasureDataFragment) => string;
  getOptionValue?: (o: UnitsOfMeasureDataFragment) => string;
};

export function UnitsOfMeasureSelectField(props: unitsOfMeasureFieldProps) {
  const {
    placeholder = "Select a Units Of Measure",
    getOptionLabel = (o) => o.name,
    getOptionValue = (o) => o.id,
    ...otherProps
  } = props;
  const { data, loading } = useUnitsOfMeasureQuery({ fetchPolicy: "cache-first" });
  return (
    <SelectField
      {...otherProps}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={data?.unitsOfMeasure ?? []}
      placeholder={loading ? "Loading..." : placeholder}
    />
  );
}
