import { DateRange, useSessionStorage } from "@homebound/beam";
import { useEffect } from "react";
import { DateOperation, ScheduleTaskFilter, TaskFilter } from "src/generated/graphql-types";
import { safeKeys } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { JsonParam, useQueryParams } from "use-query-params";
import { unassignedFilterId } from "../components/TaskAssigneeMultiSelectField";
import { ScheduleListGroupBy } from "./utils";

export type CustomTaskFilter = Omit<TaskFilter, "startDateRange" | "endDateRange" | "hasChecklist"> & {
  startDateRange: DateRange | undefined;
  endDateRange: DateRange | undefined;
  requiredDocuments: boolean | undefined;
  hasChecklist: boolean | undefined;
  groupBy: ScheduleListGroupBy | undefined;
};

type UseQueryStorageProps<F> = {
  initialQueryStorage: F;
  storageKey: string;
};

type QueryStorageHook<F> = {
  queryStorage: F;
  setQueryStorage: (filter: F) => void;
};

export function useQueryStorage<F extends object>({
  initialQueryStorage,
  storageKey,
}: UseQueryStorageProps<F>): QueryStorageHook<F> {
  const keys = Object.keys(initialQueryStorage);

  const [params, setQueryParams] = useQueryParams({ [storageKey]: JsonParam });
  // key in session storage and query param
  const queryParams = params[storageKey];
  const [storedFilter, setStoredFilter] = useSessionStorage<F>(storageKey, queryParams);
  const isQueryParamFilterValid = hasValidFilterKeys(queryParams, keys);
  const queryStorage: F = isQueryParamFilterValid ? queryParams : (storedFilter ?? initialQueryStorage);
  // set storage
  const setQueryStorage = (filter: F) => setQueryParams({ [storageKey]: filter });
  useEffect(
    () => {
      if (queryParams === undefined) {
        // if there is no filter in the query params, use stored filter
        // "replaceIn" replaces the url in history instead of creating a new history item
        // back button will go to previous url
        setQueryParams({ [storageKey]: storedFilter }, "replaceIn");
      } else if (!isQueryParamFilterValid) {
        // if there are invalid query params, fallback to the default filters
        setQueryParams({ [storageKey]: storedFilter }, "replaceIn");
      } else if (JSON.stringify(queryParams) !== JSON.stringify(storedFilter)) {
        // if there is a valid filter in query params and its different from the
        // current storedFilter, use query params filter
        setStoredFilter(queryParams);
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedFilter, setStoredFilter, setQueryParams, queryParams],
  );

  return { setQueryStorage, queryStorage };
}

// check for valid filter keys in the query params
function hasValidFilterKeys<F extends object>(queryParamsFilter: F, definedKeys: (keyof F)[]): queryParamsFilter is F {
  return queryParamsFilter && safeKeys(queryParamsFilter).every((key) => definedKeys.includes(key));
}

export function mapToFilter(filter: CustomTaskFilter): ScheduleTaskFilter {
  // TODO: implement interval when it is connected on the backend
  const { startDateRange, endDateRange, requiredDocuments, internalUser, ...otherFilters } = filter;

  return {
    ...otherFilters,
    ...(startDateRange && startDateRange.from && startDateRange.to
      ? {
          startDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(startDateRange.from)),
            value2: new DateOnly(new Date(startDateRange.to)),
          },
        }
      : {}),
    ...(endDateRange && endDateRange.from && endDateRange.to
      ? {
          endDateRange: {
            op: DateOperation.Between,
            value: new DateOnly(new Date(endDateRange.from)),
            value2: new DateOnly(new Date(endDateRange.to)),
          },
        }
      : {}),
    ...{ internalUser: internalUser?.includes(unassignedFilterId) ? null : internalUser },
  } as ScheduleTaskFilter;
}

// helper to filter out undefined filter values, so they don't overwrite default filters
export function definedFilterValues<T>(existingTaskFilter: Record<string, T>): Record<string, T> {
  const filterClone = { ...existingTaskFilter };
  return Object.fromEntries(Object.entries(filterClone).filter(([_key, value]) => value !== undefined));
}

export const customTaskFilterDefault: CustomTaskFilter = {
  tradePartner: undefined,
  status: undefined,
  homeownerVisible: undefined,
  isCriticalPath: undefined,
  isGlobalMilestoneTask: undefined,
  startDateRange: undefined,
  endDateRange: undefined,
  internalUser: undefined,
  requiredDocuments: undefined,
  stale: undefined,
  myTasks: undefined,
  hasChecklist: undefined,
  hasScheduleFlags: undefined,
  groupBy: undefined,
};
