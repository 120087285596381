import {
  BoundSelectField,
  BoundTextAreaField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  Maybe,
  ReviewTabReviewFragment,
  SaveTradePartnerReviewInput,
  useSaveTradePartnerReviewMutation,
} from "src/generated/graphql-types";
import { HasIdAndName } from "src/utils/types";

export type TradePartnerReviewModalContentProps = {
  tradePartnerId: string;
  review: ReviewTabReviewFragment | undefined;
  projects: HasIdAndName[];
  internalUsers: HasIdAndName[];
};

export function TradePartnerReviewModalContent(props: TradePartnerReviewModalContentProps) {
  const { review, tradePartnerId, projects, internalUsers } = props;
  const [saveReview] = useSaveTradePartnerReviewMutation();
  const { closeModal } = useModal();

  const formState = useFormState({
    config: formConfig,
    init: { input: review, map: mapToForm },
  });

  const testIds = useTestIds({}, "tprmc");

  const scoreOptions = [
    { id: "0", name: "0", value: 10 }, // issue using id of 0 or using value of 0 in UI selection
    { id: "1", name: "1", value: 1 },
    { id: "2", name: "2", value: 2 },
    { id: "3", name: "3", value: 3 },
    { id: "4", name: "4", value: 4 },
    { id: "5", name: "5", value: 5 },
  ];

  const recommendedOptions = [
    { id: "undecided", name: "" }, // issue using id of undefined in UI selection
    { id: "true", name: "Yes" },
    { id: "false", name: "No" },
  ];

  return (
    <>
      <ModalHeader>Trade Partner Review</ModalHeader>
      <ModalBody>
        <FormLines width="full">
          <BoundSelectField
            field={formState.score}
            options={scoreOptions}
            getOptionValue={(o) => o.value}
            getOptionLabel={(o) => o.name}
            {...testIds.score}
          />
          <BoundSelectField field={formState.projectId} options={projects || []} {...testIds.project} />
          <BoundSelectField field={formState.internalUserId} options={internalUsers || []} {...testIds.internalUser} />
          <BoundSelectField field={formState.recommended} options={recommendedOptions} {...testIds.recommended} />
          <BoundTextAreaField field={formState.internalNote} label="Note" {...testIds.internalNote} />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <div css={Css.df.gap1.$}>
              <Button label="Cancel" onClick={closeModal} variant="tertiary" />
              <Button
                disabled={!formState.valid}
                label="Save"
                onClick={async () => {
                  const input = mapToInput(formState.value);
                  try {
                    await saveReview({
                      variables: {
                        input: {
                          tradePartnerId,
                          ...input,
                        },
                      },
                    });
                  } catch (e) {
                    console.error(e);
                  }
                  // close modal in all cases - rely on generic error display if present
                  closeModal();
                }}
              />
            </div>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormConfig = Omit<SaveTradePartnerReviewInput, "recommended"> & {
  recommended?: Maybe<string>;
};

const formConfig: ObjectConfig<FormConfig> = {
  id: { type: "value" },
  internalNote: { type: "value" },
  internalUserId: { type: "value", rules: [required] },
  projectId: { type: "value", rules: [required] },
  recommended: { type: "value" },
  score: { type: "value", rules: [required] },
};

function mapToForm(tpr: Omit<ReviewTabReviewFragment, "createdAt">): FormConfig {
  return {
    id: tpr.id,
    internalNote: tpr.internalNote,
    internalUserId: tpr.internalUser.id,
    projectId: tpr.project.id,
    recommended: tpr.recommended === true ? "true" : tpr.recommended === false ? "false" : "undecided",
    score: tpr.score === 0 ? 10 : tpr.score,
  };
}

function mapToInput(form: FormConfig): SaveTradePartnerReviewInput {
  const { recommended, score, ...fields } = form;
  return {
    recommended: recommended === "true" ? true : recommended === "false" ? false : null,
    score: score === 10 ? 0 : score,
    ...fields,
  };
}
