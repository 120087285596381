import { Global } from "@emotion/react";
import { Css, useTestIds } from "@homebound/beam";
import { LienWaiverCondition, LienWaiverType, PandaDocRole } from "src/generated/graphql-types";
import { Signature, SignatureDate } from "../../components/PandaDocField";
import { LienWaiverProps } from "../LienWaiverRouter";
import {
  amountInCents,
  buildAddress,
  claimantName,
  getClaimantName,
  getTitleField,
  invoiceNumber,
  owner,
  payerName,
  throughDate,
} from "../utils";

const FL_OWNER = "MF-HB Florida PropCo, LLC";

export function FLLienWaiver({ data }: LienWaiverProps) {
  const lienWaiver = data.lienWaiver!;
  const testIds = useTestIds({}, "FLLienWaiver");

  const conditional = lienWaiver.condition.code;
  const type = lienWaiver.type.code;
  const isConditional = conditional === LienWaiverCondition.Conditional;
  const isFinal = lienWaiver.type.code === LienWaiverType.Final;
  return (
    <>
      <Global
        styles={{
          "@page": { size: "legal" },
          ul: Css.ml1.my2.$,
          li: Css.my1.$,
        }}
      />

      <div {...testIds.container} css={Css.p2.$}>
        <div css={Css.mb1.lgSb.tac.$}>
          {conditional} WAIVER AND RELEASE OF LIEN UPON {type} PAYMENT
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.tac.fwb.$}>Identifying Information</p>
          <div>
            <p>Name of Claimant: {claimantName(lienWaiver, "claimantName")}</p>
            <hr></hr>
            <p>Customer: {payerName(lienWaiver, "customer")}</p>
            <hr></hr>
            <p>Job Location: {buildAddress(lienWaiver)}</p>
            <hr></hr>
            <p>Owner: {owner(lienWaiver, FL_OWNER)}</p>
            <hr></hr>
            {!isFinal && (
              <>
                <p>Through Date: {throughDate(lienWaiver)}</p>
                <hr></hr>
              </>
            )}
            <p>Invoice Number: {invoiceNumber(lienWaiver)}</p>
            <hr></hr>
          </div>
        </div>

        <div css={Css.mb2.$}>
          <p data-testid="firstParagraph" css={Css.mb2.$}>
            Upon receipt by the undersigned the sum of {amountInCents(lienWaiver)}, payable to the undersigned, and when
            the check has been properly endorsed and has been paid by the bank on which it is drawn, this waiver and
            release document shall become effective and enforceable.
          </p>

          <p data-testid="secondParagraph">
            Subject to the above, the undersigned lienor, in consideration of the sum of {amountInCents(lienWaiver)},
            hereby waives and releases its lien and right to claim a lien for labor, services, or materials furnished
            through the Through Date as identified above, Homebound Technologies on the job of{" "}
            {owner(lienWaiver, FL_OWNER)}, to the following property:
          </p>

          <p>
            This waiver and release does not cover any retention or labor, services, or materials furnished after the
            date specified.
          </p>
        </div>
        <div css={Css.fa.mb2.$}>
          <p css={Css.ml(50).mb2.$}>LIENOR:</p>
          <div css={Css.mb1.$}>
            <span css={Css.mr(27).$}>
              <SignatureDate role={PandaDocRole.Signatory} prefix="DATED on "></SignatureDate>
            </span>
            <span>By:</span>
          </div>
          <div css={Css.mb1.$}>
            <p css={Css.ml(50).$}>Name:</p>
            <p css={Css.ml(50).$}>{getTitleField("Title:")}</p>
          </div>
        </div>
        <div css={Css.mb2.w(100).$}>
          <div css={Css.tdu.$}>
            <Signature role={PandaDocRole.Signatory} prefix="Lienor's Signature:" />
            {isConditional && !isFinal && getClaimantName("Lienor's Name:")}
            <p>{getTitleField("Lienors's Title: ")}</p>
            <SignatureDate role={PandaDocRole.Signatory} prefix="Date of Signature:" />
          </div>
        </div>
      </div>
    </>
  );
}
