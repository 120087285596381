import { BoundNumberField, Css, maybeTooltip } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useMemo } from "react";
import { ScheduleTaskDateEditabilityFragment } from "src/generated/graphql-types";
import { determineScheduleTaskDateEditability } from "src/routes/projects/schedule-v2/table/DateCellField";
import { FormInput, TaskDetailsFormInput } from "src/routes/projects/schedule-v2/table/utils";

type DurationCellProps = {
  objectState: ObjectState<FormInput> | ObjectState<Partial<TaskDetailsFormInput>>;
  editability: ScheduleTaskDateEditabilityFragment;
  scheduleIsLocked: boolean;
  onChange?: (value: number | undefined) => void;
};

export function DurationCellField({ objectState, editability, scheduleIsLocked, onChange }: DurationCellProps) {
  const { disabled, disabledReason } = useMemo(
    () => determineScheduleTaskDateEditability(editability, "durationInDays", scheduleIsLocked),
    [editability, scheduleIsLocked],
  );

  return maybeTooltip({
    title: disabledReason,
    placement: "top",
    children: (
      <BoundNumberField
        field={objectState.durationInDays}
        label="Duration"
        type="days"
        xss={Css.tal.jcfs.$}
        disabled={disabled}
        onChange={onChange}
      />
    ),
  });
}
