import { Css, Icon, IconButton, LoadingSkeleton } from "@homebound/beam";
import { useCallback, useState } from "react";
import { Inbox_CommentStreamFragment, useDashboardCommentsQuery } from "src/generated/graphql-types";
import { Inbox } from "src/routes/inbox/components/Inbox";
import { EmptyStateWrapper } from "src/routes/personal-dashboard/components/EmptyStateWrapper";
import { queryResult } from "src/utils";
import { useDashboardFilterContext } from "../DashboardFilterContext";

type DashboardCommentsWidgetProps = {
  isExpanded: boolean;
  onExpand: (x: boolean) => void;
};

export function DashboardCommentsWidget({ isExpanded, onExpand }: DashboardCommentsWidgetProps) {
  const { filter } = useDashboardFilterContext();
  const [selected, setSelected] = useState<Inbox_CommentStreamFragment | undefined>(undefined);

  const users = filter.internalUser ?? [];

  const query = useDashboardCommentsQuery({
    variables: { internalUser: users, page: { offset: 0, limit: 10 } },
    skip: users.isEmpty,
  });

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.userRelevantCommentStreamsPaged.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data.userRelevantCommentStreamsPaged.entities.length,
            limit: 10,
          },
        },
      });
    }
  }, [query]);

  return (
    <div css={Css.bgWhite.py3.pl3.br8.df.fdc.gap1.h100.$}>
      <div css={Css.df.jcsb.pr3.$}>
        <div css={Css.df.aic.gap1.$}>
          <h1 css={Css.xlBd.$}>Comments</h1>
          <Icon
            inc={2}
            icon="infoCircle"
            tooltip="These are comments that you are tagged in, a watcher of, or that you made."
          />
        </div>
        <IconButton
          onClick={() => onExpand(!isExpanded)}
          icon={!isExpanded ? "expand" : "collapse"}
          tooltip={!isExpanded ? "Expand" : "Collapse"}
          disabled={!!selected}
          inc={2}
        />
      </div>
      <EmptyStateWrapper title="comments">
        {queryResult(query, {
          data: ({ userRelevantCommentStreamsPaged }) => (
            <Inbox
              streams={userRelevantCommentStreamsPaged.entities}
              displayCommentableNames={{ openStream: true, closeStream: false }}
              maybeFetchMore={maybeFetchMore}
              onSelectedStreamChanged={(s) => {
                setSelected(s);
                if (s && !isExpanded) {
                  onExpand(true);
                }
              }}
            />
          ),
          loading: () => <LoadingSkeleton rows={6} columns={2} />,
          showLoading: "always",
        })}
      </EmptyStateWrapper>
    </div>
  );
}
