import { EstimatePageQuery, EstimateType } from "src/generated/graphql-types";

export function createContractDisabledReason(estimate: EstimatePageQuery["projectEstimate"]): string | undefined {
  const isChangeOrderEstimate = estimate.changeEvent?.id !== undefined;
  const projectStage = estimate.projectStage;
  return estimate.type.code === EstimateType.Preliminary
    ? "Only final estimates can create contracts"
    : projectStage.hasSignedContract && !isChangeOrderEstimate
      ? "Stage already has a prime contract"
      : undefined;
}
