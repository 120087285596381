import { CostType, DevelopmentCommitmentPotentialLineItemFragment, Maybe } from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

export class DevelopmentCommitmentPotentialLineItem extends Selectable {
  itemId: string;
  name: string;
  itemCostCode: DevelopmentCommitmentPotentialLineItemFragment["item"]["costCode"];
  costType: CostType;
  locationObj: DevelopmentCommitmentPotentialLineItemFragment["location"];
  location: string | undefined;
  projectIds: DevelopmentCommitmentPotentialLineItemFragment["projects"];
  quantity: Maybe<number>;
  totalCostInCents: Maybe<number>;
  isSelection: boolean;
  fragment: DevelopmentCommitmentPotentialLineItemFragment;

  constructor(iti: DevelopmentCommitmentPotentialLineItemFragment) {
    super(`${iti.item.id}-${iti.location.name}-${iti.costType}`, false);
    this.itemId = iti.item.id;
    this.name = iti.item.displayName;
    this.itemCostCode = iti.item.costCode;
    this.locationObj = iti.location;
    this.location = iti.location.name;
    this.projectIds = iti.projects;
    this.costType = iti.costType;
    this.totalCostInCents = iti.totalCostInCents;
    this.isSelection = iti.item.isSelection;
    this.fragment = iti;
    makeSimpleAutoObservable(this);
  }
}
