import {
  Css,
  DateFilterValue,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  Loader,
  RowStyles,
} from "@homebound/beam";
import { format, isToday, isYesterday, parseISO, subWeeks } from "date-fns";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { formatDate, FormattedDate } from "src/components";
import {
  DateFilter2,
  InputMaybe,
  JobLog,
  Order,
  ProjectFeature,
  ProjectStatus,
  Scalars,
  UserEventDetailFragment,
  UserEventFilter,
  UserEventParent,
  UserEventType,
  useUserEventsQuery,
} from "src/generated/graphql-types";
import { Approval } from "src/routes/my-blueprint/activity-feed/events/Approval/Approval";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { createProjectJobLogsUrl, createProjectUrl } from "src/RouteUrls";
import { DateFilterOperation, groupBy, isDefined, sanitizeHtml } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { nonClosedProjects } from "src/utils/projects";
import {
  ItemTemplateEventText,
  ProjectEventText,
  ProjectReadyPlanConfigEventText,
  ReadyPlanDetailsEventText,
} from "./events";
import { Task } from "./events/Task/Task";
import { UserEventImage } from "./UserEventImage";

export type UserEventFilterWithDateFilterValue = Omit<UserEventFilter, "createdAt"> & {
  createdAt?: InputMaybe<DateFilterValue<string>>;
};
const myProjectsId = "my-projects";

export type UserEventsTableProps = {
  currentUser: { id: string | null | undefined };
  /** This will make the user events table render in a compact way, things that change:
   * * No day rows are going to be rendered
   * * No time column, the date and time stamp will be displayed under the event message
   * * Full width of the event message
   * * No hover background on rows
   */
  embedded?: boolean;
  filter?: UserEventFilterWithDateFilterValue;
  /**
   * * This will defined default filter values for the user events query, or override the filter values passed in
   */
  queryFilter?: {
    projectId?: string;
    parentIds?: string[] | undefined;
    parentType?: InputMaybe<Array<Scalars["ID"]>>;
    allEvents?: boolean;
  };
  filterClosedProjects?: boolean;
};

export function UserEventsTable({
  currentUser,
  embedded = false,
  filter,
  queryFilter,
  filterClosedProjects,
}: UserEventsTableProps) {
  const { projectId, parentIds, parentType, allEvents } = queryFilter ?? {};
  const isProjectPage = !!projectId;
  const isBudgetSuperDrawer = !!parentIds;
  const { features } = useProjectContext();
  // add conditional check bc we dont have project context on dashboard
  const delayFeatureFlagIsOn = features.includes(ProjectFeature.DelayFlags);

  const eventFilters = useMemo(
    () =>
      mapToFilter(
        {
          ...(filter ?? {}),
          parentType: filter?.parentType ?? parentType,
          createdAt: allEvents
            ? undefined
            : (filter?.createdAt ?? {
                op: DateFilterOperation.After,
                value: new DateOnly(subWeeks(new Date(), 1)),
              }),
        },
        currentUser,
        projectId,
        parentIds,
      ),
    [allEvents, currentUser, filter, parentIds, parentType, projectId],
  );

  const { data: queryData, loading } = useUserEventsQuery({
    variables: {
      filter: eventFilters,
      limit: 1000,
      order: { createdAt: Order.Desc },
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    // If we're on the budget super drawer assume we dont need to fetch user events if there are no parents
    skip: isBudgetSuperDrawer && parentIds?.isEmpty,
  });

  const columns = useMemo(() => createColumns(isProjectPage, embedded), [isProjectPage, embedded]);
  const rowStyles = useMemo(() => createRowStyles(embedded), [embedded]);
  const rows = useMemo(
    () =>
      createRows(
        queryData?.userEvents.filter((ue) => {
          if (filter?.parentType?.includes("t")) {
            return !(ue.parentTypeName === "Task" && !(ue.type === UserEventType.Flagged));
          }
          if (ue.parentTypeName === "Project ready plan config" && Object.keys(ue.payload).length === 1) return false;

          if (
            ue.parentTypeName === "Ready plan" ||
            ue.parentTypeName === "Ready plan option" ||
            ue.parentTypeName === "Ready plan option program data modification"
          )
            return true;
          // filter out closed + on hold projects if this prop is true
          return filterClosedProjects
            ? isDefined(ue.project) && nonClosedProjects.includes(ue.project.status?.code)
            : true;
        }) || [],
        currentUser,
        delayFeatureFlagIsOn,
        embedded,
      ),
    [queryData?.userEvents, currentUser, delayFeatureFlagIsOn, embedded, filter?.parentType, filterClosedProjects],
  );

  // handles loading state
  if (loading)
    return (
      <div css={Css.hPx(500).df.fdc.aic.jcc.$}>
        <Loader />
      </div>
    );

  return (
    <div css={Css.if(!embedded).br4.pt3.$}>
      <div css={Css.if(!embedded).bgWhite.br4.bcGray300.$}>
        <GridTable
          rows={rows}
          columns={columns}
          rowStyles={rowStyles}
          style={embedded ? { rowHover: false } : { cellTypography: "sm" }}
        />
      </div>
    </div>
  );
}

function createColumns(isProjectPage: boolean, embedded: boolean): GridColumn<Row>[] {
  const detailsColumn: GridColumn<Row> = {
    header: "detailsColumn",
    userEvent: (row) => renderDetails(row, isProjectPage, embedded),
    day: (data, { row }) => {
      switch (embedded) {
        case true:
          return emptyCell;

        default:
          return {
            content: <p css={Css.baseSb.gray900.$}>{row.id}</p>,
            colspan: 2,
          };
      }
    },
  };
  const timeColumn: GridColumn<Row> = {
    header: "timeColumn",
    w: "85px",
    userEvent: (row) => {
      return (
        <div css={Css.w100.df.fdc.h100.$}>
          <div css={Css.w100.df.jcfe.$}>
            <FormattedDate date={row.createdAt} dateFormatStyle="none" timeFormatStyle="short" />
          </div>
        </div>
      );
    },
    day: emptyCell,
  };
  return [detailsColumn, ...(embedded ? [] : [timeColumn])];
}

function createRows(
  userEvents: UserEventDetailFragment[],
  user: UserEventsTableProps["currentUser"],
  delayFeatureFlagIsOn: boolean,
  embedded: boolean,
): GridDataRow<Row>[] {
  const sections = eventsByDate(userEvents);
  const rows = new Array<GridDataRow<Row>>();

  sections.forEach((section: daySection) => {
    const { date, formattedDate, events } = section;
    const dateIsToday = isToday(date);
    const dateIsYesterday = isYesterday(date);

    if (!embedded) {
      let dateId = formattedDate;

      if (dateIsToday || dateIsYesterday) {
        dateId = dateIsToday ? "Today" : "Yesterday";
      }

      rows.push({ kind: "day" as const, id: dateId, data: {} });
    }

    const [assignedEvents, otherEvents] = events.partition(({ type }) => type === UserEventType.Assigned);

    if (assignedEvents.nonEmpty) {
      // Batch all ToDo assignees changes from the same day into a single row per ToDo
      const assignedEventsByEntity = assignedEvents.groupBy(({ parent }) => (parent as any).id);
      assignedEventsByEntity.toEntries().forEach(([_, events]) => {
        const lastEdit = events.reduce((a, b) => (a.createdAt > b.createdAt ? a : b));
        console.log(user?.id);
        const assignedUsers = events
          .map(({ createdFor }) => (user?.id === createdFor?.id ? "you" : createdFor?.name))
          .join(", ");
        otherEvents.push({ ...lastEdit, createdFor: { id: null!, name: assignedUsers } });
      });
    }

    otherEvents
      .sortBy(({ createdAt }) => -createdAt)
      .forEach((event: UserEventDetailFragment, index: number) => {
        const rowData = generateRowData(user, event, delayFeatureFlagIsOn);
        rowData && rows.push({ kind: "userEvent" as const, id: event.id, data: rowData, index });
      });
  });

  return [{ kind: "header" } as GridDataRow<Row>, ...rows];
}

let allowedEventTypes: Set<UserEventType>;

export function generateRowData(
  user: UserEventsTableProps["currentUser"],
  event: UserEventDetailFragment,
  delayFeatureFlagIsOn: boolean,
): UserEventRowData | undefined {
  allowedEventTypes ??= new Set([
    UserEventType.Created,
    UserEventType.Updated,
    UserEventType.Approved,
    UserEventType.Assigned,
    UserEventType.Completed,
    UserEventType.Finalized,
    UserEventType.Commented,
    UserEventType.Attached,
    UserEventType.ProjectCohortChange,
    UserEventType.LotRelease,
    UserEventType.PrpcApplied,
    UserEventType.PrpcReset,
    UserEventType.RequestedChanges,
    UserEventType.Rejected,
    UserEventType.ItemAdded,
    UserEventType.ItemRemoved,
    UserEventType.Published,
  ]);

  if (delayFeatureFlagIsOn) {
    allowedEventTypes.add(UserEventType.Flagged);
  }

  if (!allowedEventTypes.has(event.type)) {
    return undefined;
  }

  const { parent, parentTypeName, createdAt, createdFor, createdBy, project, payload, development, comment } = event;
  const parentAsAny = parent as any;
  const name = parentAsAny.displayName ?? parentAsAny.name;
  const url = parentAsAny.blueprintUrl?.path;
  const entity = parent.__typename;
  const createdByName = user?.id === createdBy.id ? "you" : createdBy.name;
  const createdForName = user?.id === createdFor?.id ? "you" : createdFor?.name;

  return {
    name,
    parentTypeName,
    url,
    project,
    entity,
    createdAt,
    createdBy: createdByName,
    createdFor: createdForName,
    type: event.type as any,
    payload: payload ?? {},
    parent,
    development,
    comment,
  };
}

function renderDetails(row: UserEventRowData, isProjectPage: boolean, embedded: boolean) {
  const { type, entity } = row;
  const textTag = getEventText(row, isProjectPage, embedded);

  return (
    <>
      <div css={Css.df.fdc.mwPx(24).mr2.h100.$}>
        <UserEventImage entity={entity} eventType={type as UserEventType} />
      </div>
      <div css={Css.df.fdc.jcc.w100.$}>{textTag}</div>
    </>
  );
}

function eventsByDate(events: UserEventDetailFragment[]): daySection[] {
  const grouped = groupBy(events, (e) => format(e.createdAt, "yyyy-MM-dd"));
  const groupedWithDate = Object.entries(grouped).map(([date, dateGroup]) => {
    const newDate = parseISO(date);
    return { date: newDate, formattedDate: format(newDate, "EEE, MMM do"), events: dateGroup };
  });
  return groupedWithDate;
}

function createRowStyles(embedded: boolean): RowStyles<Row> {
  return {
    header: { cellCss: Css.dn.$ },
    day: { cellCss: Css.jcc.pt2.pb2.bgTransparent.bcTransparent.important.$ },
    userEvent: {
      cellCss: ({ index }) => {
        return Css.pl1.pr1.bcGray300.important.if(index === 0).bcTransparent.if(embedded).pr0.important.$;
      },
    },
  };
}

function mapToFilter(
  filter: UserEventFilterWithDateFilterValue,
  currentUser: UserEventsTableProps["currentUser"],
  projectId?: string,
  parentIds?: string[],
) {
  const { createdAt, project, parentType, ...others } = filter;

  return {
    ...others,
    ...(createdAt
      ? { createdAt: { op: createdAt.op, value: new DateOnly(new Date(createdAt.value)) } as DateFilter2 }
      : {}),
    ...(project?.includes(myProjectsId) ? { relevantTo: currentUser?.id ? [currentUser.id] : [] } : {}),
    ...(project?.nonEmpty ? { project: project.filter((p) => p !== myProjectsId) } : {}),
    // project activity feed page
    ...(projectId ? { project: [projectId] } : {}),
    // job log filter should include image and note search
    ...(parentType?.includes("jl") ? { parentType: [...parentType, ...otherJobLogParentTypes] } : { parentType }),
    ...(parentIds?.nonEmpty ? { parent: parentIds } : {}),
  };
}
// creating a specific type for project, as status is an optional field
type UserEventRowProject =
  | {
      id: string;
      name: string;
      status?: {
        code: ProjectStatus;
      };
    }
  | null
  | undefined;

export type UserEventRowData = Pick<
  UserEventDetailFragment,
  "parentTypeName" | "createdAt" | "payload" | "development" | "comment"
> & {
  name: string;
  url: string;
  type: UserEventType;
  entity?: NonNullable<UserEventParent["__typename"]>;
  createdBy: string;
  createdFor: string | undefined;
  payload?: any;
  parent?: any;
  project: UserEventRowProject;
};

type HeaderRow = { kind: "header" };

type UserEventRow = {
  kind: "userEvent";
  id: string;
  data: UserEventRowData;
  index: number;
};

type DayRow = {
  kind: "day";
  id: string;
};

type Row = HeaderRow | UserEventRow | DayRow;

type daySection = {
  date: Date;
  formattedDate: string;
  events: UserEventDetailFragment[];
};

export const otherJobLogParentTypes = ["jli", "jln"];

export function getEventText(event: UserEventRowData, isProjectPage: boolean, embedded: boolean) {
  const { name, parentTypeName, url, type, createdBy, createdFor, project, payload, parent, createdAt, comment } =
    event;
  const [createdByTag, createdForTag] = [createdBy, createdFor].map((s) => (
    <span css={weightedStyle} key={s}>
      {s}
    </span>
  ));
  const parentType = parent.__typename;

  const urlTag = url ? (
    <>
      ,
      <span css={weightedStyle}>
        {" "}
        <Link to={{ pathname: url }} target="_blank">
          {name}
        </Link>
      </span>
      ,
    </>
  ) : (
    <></>
  );

  const projectTag = project && !isProjectPage && (
    <>
      on
      <span css={weightedStyle}>
        {" "}
        <Link to={createProjectUrl(project.id)} target="_blank">
          {project.name}
        </Link>
      </span>
    </>
  );

  const dateTag = embedded && (
    <span css={Css.tiny.gray700.$}>
      <FormattedDate date={createdAt} dateFormatStyle={"medium"} timeFormatStyle={"short"} />
    </span>
  );

  function jobLogTag({ id, logDate }: Pick<JobLog, "id" | "logDate">) {
    return (
      project && (
        <span css={weightedStyle}>
          {" "}
          <Link to={createProjectJobLogsUrl(project.id)} target="_blank">
            {formatDate(logDate, "medium")}
          </Link>
        </span>
      )
    );
  }

  switch (parent.__typename) {
    case "ProjectReadyPlanConfig":
    case "ProjectLotDetail":
      return <ProjectReadyPlanConfigEventText event={event} isProjectPage={isProjectPage} dateTag={dateTag} />;
    case "ReadyPlan":
    case "ReadyPlanOption":
    case "ReadyPlanOptionProgramDataModification":
    case "PlanPackage":
      return <ReadyPlanDetailsEventText event={event} dateTag={dateTag} />;

    case "ItemTemplate":
      return <ItemTemplateEventText event={event} dateTag={dateTag} />;

    case "Project":
      return <ProjectEventText event={event} isProjectPage={isProjectPage} dateTag={dateTag} />;

    case "Task":
    case "ScheduleTask":
    case "PlanTask":
      return <Task event={event} urlTag={urlTag} projectTag={projectTag} createdByTag={createdByTag} />;
    case "Approval":
      return <Approval event={event} dateTag={dateTag} />;
    // TODO: create a case for each type of parent supported for the events
    // TODO: create a new component for each case to render and handle it independently from the rest
    default:
      return (
        <>
          {type === UserEventType.Created ? (
            parentType === "JobLogNote" || parentType === "JobLogImage" ? (
              <span>
                A new {parentType === "JobLogNote" ? "note" : "image"} was created on the job log for{" "}
                {jobLogTag(parent.jobLog)} by {createdByTag} {projectTag}
              </span>
            ) : (
              <span>
                A new {parentTypeName?.toLowerCase()}
                {urlTag} was created by {createdByTag} {projectTag}
              </span>
            )
          ) : type === UserEventType.Updated ? (
            parentType === "JobLog" ? (
              <span>
                {payload?.flags ? "A flag" : "The weather"} was updated on the job log for {jobLogTag(parent)} by{" "}
                {createdByTag} {projectTag}
              </span>
            ) : (
              <span>
                {parentTypeName}
                {urlTag} was {"deletedAt" in payload ? "archived" : "updated"} by {createdByTag} {projectTag}
              </span>
            )
          ) : type === UserEventType.Approved ||
            type === UserEventType.Completed ||
            type === UserEventType.Finalized ? (
            <span>
              {parentTypeName}
              {urlTag} was <span css={weightedStyle}>{type.toLowerCase()}</span> by {createdByTag} {projectTag}
            </span>
          ) : type === UserEventType.Assigned ? (
            <span>
              To-do
              {urlTag} was assigned to {createdForTag} by {createdByTag} {projectTag}
            </span>
          ) : type === UserEventType.Commented ? (
            <span>
              There was a new comment by {createdByTag} on {parentTypeName?.toLowerCase()}
              <div css={Css.df.$}>
                <Icon inc={3} xss={Css.mt3.$} icon="comment" />
                <div css={Css.indent.$}>
                  <div css={Css.mt3.$}>{createdByTag} said</div>
                  <div
                    css={Css.mb2.mtPx(3).wbbw.$}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment?.html || "") }}
                  ></div>
                </div>
              </div>
            </span>
          ) : type === UserEventType.Attached ? (
            <span>
              An attachment was added to a to-do
              {urlTag} by {createdByTag} {projectTag}
            </span>
          ) : (
            ""
          )}
          {embedded && dateTag}
        </>
      );
  }
}
