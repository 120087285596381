import {
  BoundMultiSelectField,
  BoundSelectField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  SaveScheduleTemplateInput,
  ScheduleTemplatesDetailFragment,
  Stage,
  useDevelopmentsByMarketQuery,
  useSaveScheduleTemplatesMutation,
  useScheduleTemplateDetailQuery,
  useScheduleTemplatesPageMetadataQuery,
} from "src/generated/graphql-types";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { createScheduleTemplateUrl } from "src/RouteUrls";

type ScheduleTemplateFormProps = {
  scheduleTemplateId?: string;
  title?: string;
  scheduleType: ScheduleType;
};

export function CreateOrUpdateScheduleTemplateForm({
  scheduleType,
  scheduleTemplateId,
  title,
}: ScheduleTemplateFormProps) {
  const { closeModal } = useModal();
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { data: marketOptions, loading: marketOptionsLoading } = useScheduleTemplatesPageMetadataQuery({
    fetchPolicy: "cache-first",
    skip: scheduleType === ScheduleType.Development,
  });

  const { data: templateData, loading: templateDataLoading } = useScheduleTemplateDetailQuery({
    variables: { scheduleTemplateId: scheduleTemplateId ?? "" },
    skip: !scheduleTemplateId,
  });

  const [saveScheduleTemplates, { loading: saveScheduleTemplatesLoading }] = useSaveScheduleTemplatesMutation();

  const loading = marketOptionsLoading || templateDataLoading || saveScheduleTemplatesLoading;
  const isInitialCreate = !scheduleTemplateId;
  const testIds = useTestIds({});

  const formState = useFormState({
    config: formConfig,
    addRules(state) {
      if (scheduleType === ScheduleType.Project && isInitialCreate) {
        state.stage.rules.push(required);
        state.developmentIds.rules.push(required);
      }
    },
    init: {
      input: templateData?.scheduleTemplate,
      map: mapToFormState,
    },
  });

  const [developmentDisabledReason, selectedMarket] = useComputed(() => {
    let developmentDisabledReason = "";
    if (!formState.marketId.value) {
      developmentDisabledReason = "You must select a market before selecting developments";
    }
    return [developmentDisabledReason, formState.marketId.value!];
  }, [formState, templateData]);

  const { data: developmentData, loading: developmentDataLoading } = useDevelopmentsByMarketQuery({
    variables: { market: [selectedMarket] },
    skip: !!developmentDisabledReason || scheduleType === ScheduleType.Development,
  });

  const onSubmit = async () => {
    const { stage } = formState.value;
    const result = await saveScheduleTemplates({
      variables: {
        scheduleTemplates: {
          scheduleTemplates: [
            { ...formState.value, stage: scheduleType === ScheduleType.Development ? Stage.Development : stage },
          ],
        },
      },
    });
    const userErrors = result.data?.saveScheduleTemplates?.userErrors;
    if (userErrors && userErrors.length > 0) {
      setIsError(true);
      setErrorText(userErrors.map((e) => e.message).join(" "));
    } else {
      // close modal then redirect to schedule template details page
      closeModal();
      history.push({
        pathname: createScheduleTemplateUrl(result.data?.saveScheduleTemplates?.scheduleTemplates[0].id),
      });
    }
  };

  return (
    <>
      <ModalHeader>{title || `Create a new ${scheduleType.toLocaleLowerCase()} template`}</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField
            field={formState.name}
            label="Name"
            compact={true}
            disabled={loading}
            errorMsg={isError ? errorText : ""}
          />
          {scheduleType === ScheduleType.Project && (
            <>
              {isInitialCreate && (
                <BoundSelectField
                  getOptionValue={(s) => s.stage}
                  getOptionLabel={(s) => s.name}
                  options={[
                    { name: "Planning", stage: Stage.PreConPlanning },
                    { name: "Pre-Con", stage: Stage.PreConExecution },
                    { name: "Construction", stage: Stage.Construction },
                  ]}
                  field={formState.stage}
                  label="Stage"
                  disabled={loading}
                  compact
                />
              )}
              <BoundSelectField
                options={marketOptions?.markets ?? []}
                field={formState.marketId}
                label="Market"
                disabled={loading}
                required
                compact
              />
              <BoundMultiSelectField
                options={developmentData?.developments ?? []}
                field={formState.developmentIds}
                label="Development"
                disabled={loading || developmentDataLoading || developmentDisabledReason}
                compact
                required
                onSelect={(val) => formState.developmentIds.set(val.length === 0 ? null : val)}
              />
            </>
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              {...testIds[
                scheduleType ? `create${scheduleType.toLocaleLowerCase()}TemplateButton` : "saveTemplateButton"
              ]}
              label={scheduleTemplateId ? "Save" : "Create"}
              onClick={onSubmit}
              disabled={!formState.valid || loading}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

function mapToFormState(scheduleTemplate: ScheduleTemplatesDetailFragment): SaveScheduleTemplateInput {
  if (!scheduleTemplate) return {} as SaveScheduleTemplateInput;

  const { id, name, market, developments } = scheduleTemplate;
  return {
    id,
    name,
    marketId: market?.id,
    developmentIds: developments.map((d) => d.id),
  };
}

const formConfig: ObjectConfig<SaveScheduleTemplateInput> = {
  id: { type: "value" },
  marketId: { type: "value" },
  name: { type: "value", rules: [required] },
  stage: { type: "value" },
  developmentIds: { type: "value" },
};
