import { useComputed, useGridTableApi } from "@homebound/beam";
import { createPlanDetailsUrl } from "src/RouteUrls";
import { StepLayout } from "src/components/stepper/StepLayout";
import { ChooseOptionsStepBody } from "src/routes/components/PlanConfiguration/ChooseOptionsStepBody";
import { SelectedOptionsTag } from "src/routes/components/PlanConfiguration/SelectedOptionsTag";
import { ChooseOptionsRow } from "src/routes/components/PlanConfiguration/utils";
import { EditOptionsHeader } from "src/routes/developments/plan-and-options/options/components/EditOptionsHeader";

export function ChooseOptionsStep({ developmentId, id }: { developmentId: string; id: string }) {
  const tableApi = useGridTableApi<ChooseOptionsRow>();
  const selectedRows = useComputed(() => tableApi.getSelectedRows("globalOption"), [tableApi]);

  return (
    <StepLayout
      header={
        <EditOptionsHeader
          title="Choose Options"
          tooltip="Choose all Floorplan, Interior, and Add-On Options that you would like to be applicable for this Readyplan"
          subtitle="Choose which options are going to be part of the ready plan, then click Save."
          right={<SelectedOptionsTag selectedRows={selectedRows} />}
        />
      }
      body={
        <ChooseOptionsStepBody
          planId={id}
          withElevations
          returnUrl={createPlanDetailsUrl(developmentId, id)}
          setStepDirty={() => {}}
          tableApi={tableApi}
        />
      }
    />
  );
}
