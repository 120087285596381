import { Button, Css, RichTextField, useTestIds } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useEffect, useMemo } from "react";
import { CommentStreamVisibility } from "src/generated/graphql-types";
import { useInternalUsers } from "src/hooks/useInternalUsers";
import { sanitizeHtml } from "src/utils";
import { BoundAttachments, SaveAttachmentModel, attachmentConfig } from "../boundAttachments/BoundAttachments";

export type CommentEditorProps = {
  commentableId?: string;
  commentStreamId?: string;
  streamVisibility?: CommentStreamVisibility;
  text?: string;
  html?: string;
  tags?: string[];
  forceDisable?: boolean;
  onSave: (
    text: string,
    html: string,
    attachments: SaveAttachmentModel[],
    mentions?: string[],
  ) => Promise<boolean | void>;
  onCancel?: () => void;
  submitText?: "Add" | "Save";
  attachments?: SaveAttachmentModel[];
  fullWidth?: boolean;
};

export function CommentEditor(props: CommentEditorProps) {
  const {
    commentableId,
    commentStreamId,
    streamVisibility,
    text,
    html,
    tags,
    forceDisable,
    onSave,
    onCancel,
    submitText = "Save",
    attachments,
    fullWidth,
  } = props;
  const { internalUsers, loading } = useInternalUsers();
  const testIds = useTestIds({}, "commentEditor");
  const localStorageKey = `${commentStreamId}_${commentableId}_${streamVisibility}_comment`;
  const { text: storeText, html: storeHtml } = useMemo(
    () => JSON.parse(localStorage.getItem(localStorageKey) ?? "{}"),
    [localStorageKey],
  );

  function extractIdsFromMentions(content: string): string[] {
    return internalUsers.filter(({ name }) => content.includes(`@${name}`)).map(({ id }) => id);
  }

  const initText: string = storeText ?? text ?? "";
  const initHtml: string = storeHtml ?? html ?? "";

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: {
        text: initText,
        html: initHtml,
        mentions: tags?.nonEmpty ? tags : undefined,
        attachments: attachments ?? [],
      },
    },
  });

  useEffect(() => {
    const preventDefault = (e: any) => e.preventDefault();
    window.addEventListener("trix-file-accept", preventDefault);
    return () => {
      window.removeEventListener("trix-file-accept", preventDefault);
    };
  }, []);

  function onEditorChange(newHtml: string = "", newText: string = "") {
    formState.html.set(newHtml);
    formState.text.set(newText);
    formState.mentions.set(extractIdsFromMentions(newText));
    if (commentableId && (newText || newHtml)) {
      localStorage.setItem(localStorageKey, JSON.stringify({ text: newText, html: newHtml }));
    }
  }

  const handleSaveComment = useCallback(async () => {
    // In theory trix already sanitizes HTML?
    const sanitized = sanitizeHtml(formState.html.value);
    const isSaveSuccessful = await onSave(
      formState.text.value,
      sanitized,
      formState.attachments.value,
      formState.mentions.changedValue,
    );
    if (isSaveSuccessful || isSaveSuccessful === undefined) {
      localStorage.removeItem(localStorageKey);
      if (!isSaveSuccessful) {
        formState.html.set("");
        formState.text.set("");
        formState.mentions.set([]);
        formState.attachments.set([]);
      }
    }
  }, [formState.attachments, formState.html, formState.mentions, formState.text, localStorageKey, onSave]);

  return (
    <Observer>
      {() => (
        <div css={Css.df.fdc.fg1.jcfe.if(!fullWidth).maxwPx(550).$} {...testIds}>
          <div css={Css.mb1.$}>
            {!loading && (
              <RichTextField
                mergeTags={internalUsers.map(({ name }) => name)}
                value={formState.html.value}
                onChange={onEditorChange}
                placeholder="Add a comment..."
                onBlur={() => {}}
                onFocus={() => {}}
                fullWidth={fullWidth}
              />
            )}
          </div>
          <BoundAttachments
            field={formState.attachments}
            right={
              <div css={Css.df.gap1.jcfe.$}>
                {onCancel && (
                  <Button
                    label="Cancel"
                    variant="tertiary"
                    onClick={() => {
                      localStorage.removeItem(localStorageKey);
                      onCancel();
                    }}
                  />
                )}
                <Button
                  label={submitText}
                  disabled={!formState.valid || forceDisable}
                  onClick={handleSaveComment}
                  {...testIds.submit}
                />
              </div>
            }
          />
        </div>
      )}
    </Observer>
  );
}

type CommentEditorForm = {
  text: string;
  html: string;
  mentions?: string[];
  attachments: SaveAttachmentModel[];
};

const formConfig: ObjectConfig<CommentEditorForm> = {
  text: { type: "value", rules: [required] },
  html: { type: "value" },
  mentions: { type: "value" },
  attachments: { type: "list", config: attachmentConfig },
};
