import {
  SaveHomeownerContractInput,
  useHomeownerContractQuery,
  useSaveHomeownerContractMutation,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks/useModeParam";
import { DetailsEditor } from "src/routes/projects/homeowner-contracts/DetailsEditor";
import { createHomeownerContractsUrl } from "src/RouteUrls";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";

export type DetailsTabProps = {
  projectId: string;
  contractId: string;
};

export function DetailsTab(props: DetailsTabProps) {
  const { projectId, contractId } = props;
  const { mode, onEdit, onSave, onCancel } = useModeParam({
    listPageUrl: createHomeownerContractsUrl(projectId),
  });
  const query = useHomeownerContractQuery({ variables: { contractId } });
  const [saveHomeownerContract] = useSaveHomeownerContractMutation();

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  return (
    <DetailsEditor
      projectId={projectId}
      contract={query.data.homeownerContract}
      mode={mode}
      onCancel={onCancel}
      onEdit={onEdit}
      stage={query.data.homeownerContract.projectStage.stage.code}
      onSave={async (input: SaveHomeownerContractInput) => {
        await saveHomeownerContract({ variables: { input } });
        onSave();
      }}
    />
  );
}
