import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

type AppNotificationsContextProps = {
  notifications: string[];
  pop: () => string | undefined;
  push: (n: string) => void;
  clear: () => void;
};

export const AppNotificationsContext = createContext<AppNotificationsContextProps>({
  notifications: [],
  pop: () => undefined,
  push: () => {},
  clear: () => {},
});

export function AppNotificationsProvider({ children }: PropsWithChildren<unknown>) {
  const [notifications, setNotifications] = useState<string[]>([]);

  const clear = useCallback(() => setNotifications([]), []);

  const push = useCallback((n: string) => setNotifications([...notifications, n]), [notifications]);

  const pop = useCallback(() => {
    const next = notifications.shift();
    setNotifications(notifications);
    return next;
  }, [notifications]);

  return (
    <AppNotificationsContext.Provider
      value={{
        notifications,
        push,
        pop,
        clear,
      }}
    >
      {children}
    </AppNotificationsContext.Provider>
  );
}

export function useAppNotificationsContext() {
  return useContext(AppNotificationsContext);
}
