import {
  actionColumn,
  Chip,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  GridDataRow,
  selectColumn,
  SelectToggle,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import { Price } from "src/components";
import {
  BillSource,
  SaveBillInput,
  SaveBillLineItemInput,
  TaskBillModal_BillFragment,
  TaskBillModal_LineItemsFragment,
  TaskBillModal_ProtoBillFragment,
} from "src/generated/graphql-types";
import { groupBy, isDefined, sortBy } from "src/utils";

/** Form Utils task line items table */
export type FormState = ObjectState<TaskBillForm>;
export type FormLineItem = Pick<SaveBillLineItemInput, "amountInCents" | "commitmentLineItemId" | "id" | "taskId"> &
  Pick<TaskBillModal_LineItemsFragment, "billedInCents" | "pendingUnbilledInCents"> & {
    displayName: string;
  };
export type TaskBillForm = {
  bills: BillForm[];
};

type GroupedBillInputs = Record<string, BillForm[]>;
type GroupedExistingBills = Record<string, TaskBillModal_BillFragment[]>;

export type BillForm = {
  id?: string;
  tradePartner?: { id: string | undefined; name: string | undefined } | undefined;
  ownerCommitmentUrl?: string;
  poAccountNumber?: string;
  projectStageId?: string;
  tradePartnerNumber?: string;
  internalNote?: string | undefined | null;
  lineItems: FormLineItem;
};

export const formConfig: ObjectConfig<TaskBillForm> = {
  bills: {
    type: "list",
    config: {
      id: { type: "value" },
      tradePartner: { type: "object", config: { id: { type: "value" }, name: { type: "value" } } },
      ownerCommitmentUrl: { type: "value" },
      poAccountNumber: { type: "value" },
      projectStageId: { type: "value" },
      tradePartnerNumber: { type: "value" },
      internalNote: { type: "value", rules: [required] },
      lineItems: {
        type: "object",
        config: {
          id: { type: "value" },
          displayName: { type: "value" },
          taskId: { type: "value" },
          commitmentLineItemId: { type: "value" },
          amountInCents: { type: "value" },
          billedInCents: { type: "value" },
          pendingUnbilledInCents: { type: "value" },
        },
      },
    },
  },
};

/** Table Utils task line items */
export type LineItemRow = { kind: "li"; data: FormState["bills"]["rows"][number] };
type GroupedCommitmentRow = {
  kind: "co";
  data: {
    tradePartner: string | undefined;
    accountingNumber: string | undefined;
    totalUnbilled: number;
    totalBilled: number;
  };
};
export type Row = GroupedCommitmentRow | LineItemRow;

export function rows(formState: ObjectState<TaskBillForm>): GridDataRow<Row>[] {
  const billsGroupedByAccountNum = groupBy(
    formState.bills.rows
      .filter((r) => isDefined(r.poAccountNumber))
      .unique()
      .compact(),
    (r) => r.poAccountNumber.value!,
  );
  const groupedCoRows = Object.keys(billsGroupedByAccountNum).map((key) => {
    const coRow = billsGroupedByAccountNum[key];
    const childrenPiRow = sortBy(coRow, (row) => row.lineItems.billedInCents.value, "ASC").map((row) => ({
      kind: "li" as const,
      data: row,
      id: String(row.lineItems.commitmentLineItemId.value),
      // init checkBox select if the cli is billable
      initSelected: row.lineItems.pendingUnbilledInCents.value !== 0,
    }));
    return {
      kind: "co" as const,
      id: key,
      // init collapse for fully billed commitments
      initCollapsed: coRow.sum((r) => r.lineItems.pendingUnbilledInCents.value) === 0,
      data: {
        tradePartner: coRow.first?.tradePartner?.name.value,
        accountingNumber: coRow.first?.poAccountNumber.value,
        totalUnbilled: coRow.sum((r) => r.lineItems.pendingUnbilledInCents.value),
        totalBilled: coRow.sum((r) => r.lineItems.billedInCents.value || 0),
      },
      children: childrenPiRow,
    };
  });

  // sort by `unbilled amount` so that already billed items are at the bottom
  return [...sortBy(groupedCoRows, (row) => row.data.totalUnbilled, "DESC")];
}

export function columns(
  selectedRows: GridDataRow<LineItemRow>[] | undefined,
  tid: Record<string, object>,
  isPayTradeView: boolean,
) {
  return [
    actionColumn<Row>({
      co: (_, { row }) => <CollapseToggle row={row} />,
      li: emptyCell,
      w: "32px",
    }),
    // Prevent deselect line items when directly in pay trade view
    ...(!isPayTradeView
      ? [
          selectColumn<Row>({
            co: (data, { row }) => ({
              content: () => (data.totalUnbilled === 0 ? "" : <SelectToggle id={row.id} />),
            }),
            li: (data, { row }) => ({
              content: () => (data.lineItems.pendingUnbilledInCents.value === 0 ? "" : <SelectToggle id={row.id} />),
            }),
            w: "28px",
          }),
        ]
      : []),
    column<Row>({
      co: (row) => ({
        content: () => {
          return (
            <div>
              <div css={Css.xsSb.df.fdr.if(row.totalUnbilled === 0).gray400.$} {...tid.tradePartner}>
                {row.tradePartner}
              </div>
              <div css={Css.xs.df.fdr.aic.gap1.$} {...tid.accountingNumber}>
                {row.accountingNumber && <Chip text={row.accountingNumber} type="neutral" />}
                <div css={Css.if(row.totalUnbilled === 0).gray400.$}>
                  <Price valueInCents={row.totalUnbilled} />
                  &nbsp;remaining
                </div>
              </div>
            </div>
          );
        },
        value: row.accountingNumber,
      }),
      li: (row) => {
        return {
          content: (
            <div css={Css.fwn.df.fdr.gap1.if(row.lineItems.pendingUnbilledInCents.value === 0).gray400.$}>
              <div css={Css.xs.$}>{row.lineItems.displayName.value}</div>
            </div>
          ),
          value: row.lineItems.displayName.value,
        };
      },
      w: 4,
    }),

    column<Row>({
      // if the unbilled amount is zero, disable the row & show the billed amount.
      // this allows users to see task clis that they've already billed for
      li: (row) => (
        <div css={Css.xs.if(row.lineItems.pendingUnbilledInCents.value === 0).gray400.$}>
          <Price valueInCents={row.lineItems.amountInCents.value || row.lineItems.billedInCents.value} />
        </div>
      ),
      co: (row) => (
        <div css={Css.xsSb.if(row.totalUnbilled === 0).gray400.$}>
          <Price
            valueInCents={
              // if PO items are billable, total the selected PO item values. Otherwise show the already billed PO total
              row.totalUnbilled !== 0
                ? (selectedRows || [])
                    .filter((sel) => sel.data.poAccountNumber.value === row.accountingNumber)
                    .sum((r) => r.data.lineItems.amountInCents.value || 0)
                : row.totalBilled
            }
          />
        </div>
      ),
      w: 1,
    }),
  ];
}

export function mapToForm(protoBills: TaskBillModal_ProtoBillFragment[], taskId?: string): TaskBillForm {
  return {
    bills: protoBills.flatMap((proto) =>
      proto.lineItems.map((lineItem) => {
        const { commitmentLineItem: cli, amountInCents } = lineItem;
        // If there is an existing  draft deferred/immediate bill from the clis' tradePartner, use the
        // bill data from the existing draft bill. Otherwise we hydrate the form with data from the task clis
        const existingDraftBill = proto.existingBills.find(
          (existingBill) => existingBill.tradePartner.id === cli.owner?.tradePartner?.id,
        );
        const isCo = cli.owner.id.startsWith("cco");
        const tradePartner = existingDraftBill?.tradePartner ?? cli.owner.tradePartner;

        return {
          tradePartner: { id: tradePartner?.id, name: tradePartner?.name },
          ownerCommitmentUrl: cli.owner.blueprintUrl.path,
          poAccountNumber: isCo ? `CO# ${cli.owner.accountingNumber}` : `PO# ${cli.owner.accountingNumber}`,
          projectStageId: existingDraftBill?.projectStage.id ?? cli.projectItem.projectStage.id,
          lineItems: {
            amountInCents,
            taskId: cli.projectItem.task?.id ?? taskId,
            itemCode: cli.projectItem.item.fullCode,
            itemDescription: cli.projectItem.name,
            billedInCents: cli.billedInCents,
            commitmentLineItemId: cli.id,
            pendingUnbilledInCents: cli.pendingUnbilledInCents,
            displayName: cli.projectItem.displayName,
          },
        };
      }),
    ),
  };
}

export function mapToInput(
  formState: ObjectState<TaskBillForm>,
  protoBills: TaskBillModal_ProtoBillFragment[],
  deselectedItems: GridDataRow<LineItemRow>[],
  tradePartnerId: string | undefined,
): TaskBillModal_SaveBillInput[] {
  const deselectedClis = deselectedItems.map((r) => r.data.lineItems.commitmentLineItemId.value);
  const groupedBillInputsByTrade: GroupedBillInputs = formState.value.bills
    .filter((b) => b.lineItems.pendingUnbilledInCents !== 0)
    .groupBy((b) => b.tradePartner?.id ?? "");
  const groupedExistingBillsByTrade: GroupedExistingBills = protoBills
    .flatMap((p) => p.existingBills)
    // if in direct pay trade view we filter existing bills by tp id
    .filter((b) => !tradePartnerId || b.tradePartner.id === tradePartnerId)
    .groupBy((b) => b.tradePartner.id);

  // Per figma we only have 1 internalNote field in the `confirm deferred bills` modal.
  // We assume if multiple bills are batched deferred, the internal note (deferrment reason) is shared across all.
  // Get the internalNote from the first formState row & apply it to all bills
  const internalNote = formState.bills.rows.first?.internalNote.value;

  const saveBillsInput = Object.keys(groupedBillInputsByTrade).reduce((input, key) => {
    const groupedInputBills: GroupedBillInputs[string] = groupedBillInputsByTrade[key];
    const groupedExistingBills: GroupedExistingBills[string] = groupedExistingBillsByTrade[key] || [];

    // If a trade has 2 bill types open, make sure we add line items to the correct bill type
    // otherwise if no bill found for that trade with a matching type
    // create a new bill for the trade from our input data

    // extract trade partner and project stage id from an existing draft bill or our inputs
    const { tradePartner } = isDefined(groupedExistingBills.first?.id)
      ? (groupedExistingBills.first as GroupedExistingBills[string][number])
      : (groupedInputBills.first ?? {});
    const projectStageId = groupedExistingBills.first?.projectStage?.id ?? groupedInputBills.first?.projectStageId;

    // split existing trade bills by deferred or immediate type
    const [existingDeferredBill, existingImmediateBill] = groupedExistingBills.partition((b) => b.isDeferred);
    // if there's a matching bill already existing for the trade, format the line items
    const existingDeferredItems = (existingDeferredBill.first?.lineItems || [])
      .map((li) => ({
        taskId: li.task?.id,
        commitmentLineItemId: li.commitmentLineItem?.id,
        amountInCents: li.amountInCents,
      }))
      .uniqueByKey("commitmentLineItemId");
    const existingImmediateItems = (existingImmediateBill.first?.lineItems || [])
      .map((li) => ({
        taskId: li.task?.id,
        commitmentLineItemId: li.commitmentLineItem?.id,
        amountInCents: li.amountInCents,
      }))
      .uniqueByKey("commitmentLineItemId");

    // format the new line item inputs
    const newLineItems = groupedInputBills.flatMap((b) => {
      const { taskId, commitmentLineItemId, amountInCents } = b.lineItems;
      return { taskId, commitmentLineItemId, amountInCents };
    });

    // split the new line item inputs by deferred or immediate type based on items deselected
    // ensure the split occurs only when not in the direct pay trade view
    const [newDeferredItems, newImmediateItems] = newLineItems.partition(
      (bli) => deselectedClis?.includes(bli.commitmentLineItemId) && !isDefined(tradePartnerId),
    );

    const deferredBillInput = [
      ...(existingDeferredBill.first?.tradePartner.id && newDeferredItems.nonEmpty
        ? [
            {
              // If deferred bill already exists update existing bill
              id: existingDeferredBill.first.id,
              lineItems: [...existingDeferredItems, ...newDeferredItems],
              // preserve previous deferred notes & append notes for new items added
              internalNote: [existingDeferredBill.first.internalNote, internalNote].join(",\n"),
            },
          ]
        : // create a new deferred trade bill if deferred bill does not exist
          newDeferredItems.nonEmpty
          ? [
              {
                source: BillSource.ClickToPay,
                isDeferred: true,
                internalNote,
                tradePartnerId: tradePartner?.id,
                projectStageId,
                lineItems: newDeferredItems,
              },
            ]
          : []),
    ];

    const immediateBillInput = [
      ...(existingImmediateBill.first?.tradePartner.id && newImmediateItems.nonEmpty
        ? [
            {
              // If immediate bill already exists update existing bill
              id: existingImmediateBill.first.id,
              lineItems: [...existingImmediateItems, ...newImmediateItems],
            },
          ]
        : // create a new immediate trade bill if immediate bill does not exist
          newImmediateItems.nonEmpty
          ? [
              {
                source: BillSource.ClickToPay,
                isDeferred: false,
                tradePartnerId: tradePartner?.id,
                projectStageId,
                lineItems: newImmediateItems,
              },
            ]
          : []),
    ];

    return input.concat([...deferredBillInput, ...immediateBillInput] as TaskBillModal_SaveBillInput[]);
  }, [] as TaskBillModal_SaveBillInput[]);

  return saveBillsInput;
}

type TaskBillModal_BillLineItemInput = Pick<
  SaveBillLineItemInput,
  "id" | "taskId" | "commitmentLineItemId" | "amountInCents"
>;
type TaskBillModal_SaveBillInput = Pick<SaveBillInput, "id"> & { lineItems: TaskBillModal_BillLineItemInput[] };
