import { useCallback, useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { partition } from "src/utils";
import useSetState from "./useSetState";

export type ValidQueryStringDataTypes = string | string[] | number | boolean | null | undefined;
export type ValidQueryStringObject = Record<string, ValidQueryStringDataTypes>;

type UseQueryStringConfig = {
  /**
   * Replace History as opposed to pushing it. Replaced history
   * cannot be navigated to using the Back button. Default: true
   */
  replace?: boolean;
  /**
   * Will avoid putting nullish (except the value 0) properties
   * into the queryString. Default: true
   */
  cleanNullish?: boolean;
  /**
   * QueryStrings can have the same key multiple times, however
   * that's difficult to parse in non-queryStrings. If that's
   * encountered, this hook will throw an error unless you suppress
   * it. Side-effect: The last duplicate will be the predominant one
   * returned by the hook, so ?filter=true&filter=no, filter will be "no"
   * Default: false
   */
  silenceDuplicateErrors?: boolean;
};

const configDefault: UseQueryStringConfig = {
  replace: true,
  cleanNullish: true,
  silenceDuplicateErrors: false,
};

/**
 * Provised a useSetState()-like hook where the state is constantly reflected in the URL via URL Search/Query Params
 */
export const useQueryString = <FullState extends ValidQueryStringObject>(
  initialState?: Partial<FullState>,
  _config: UseQueryStringConfig = configDefault,
): [FullState, (state: Partial<FullState> | ((prevState: FullState) => Partial<FullState>)) => void] => {
  const config = { ...configDefault, ..._config };

  /** Gather up/initialize from active Query String in the URL. Recalc any time QueryString changes. */
  const location = useLocation();
  const currentQueryParams: Record<string, unknown> = useMemo(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const currentState: Record<string, unknown> = {};
    for (const [key, value] of currentSearchParams.entries()) {
      if (currentState.hasOwnProperty(key) && !config.silenceDuplicateErrors && !key.endsWith("[]")) {
        /**
         * Technically allowed but useSetState will obviously only
         * allow one value for a key unless we do some crazy stuff.
         */
        throw new Error(`Duplicate parameter "${key}" in query string`);
      }
      if (value === "true") currentState[key] = true;
      else if (value === "false") currentState[key] = false;
      else if (key.includes("[]")) {
        const arrKey = key.replace("[]", "");
        currentState[arrKey] ??= [];
        if (value) (currentState[arrKey] as string[]).push(value);
      } else if (Number.isNaN(Number(value)) || value === "") currentState[key] = value;
      else currentState[key] = Number(value);
    }
    return currentState;
  }, [location.search, config.silenceDuplicateErrors]);

  /** Initialize our state */
  const [state, _setState] = useSetState<FullState>({
    ...initialState,
    ...currentQueryParams,
  } as FullState);

  /** If something else changes Query Params, backfill into our state */
  useLayoutEffect(() => {
    _setState(currentQueryParams as FullState);
  }, [currentQueryParams, _setState]);

  /** Clean and push state back into the URL */
  const history = useHistory();
  useLayoutEffect(() => {
    history[config.replace ? "replace" : "push"]({
      search: mapFiltersToUrlString(state, config.cleanNullish!),
    });
  }, [history, state, config.replace, config.cleanNullish]);

  /** Intercept setState so we can apply the patch manually to remove nullish data (if configured) */
  const setState = useCallback<typeof _setState>(
    (patch) => {
      _setState(
        (data) => {
          const current = { ...data }; // re-spread so we can delete keys
          const patched = patch instanceof Function ? patch(current) : patch;
          // filter any keys patch touches out of current (so that we can scrub nulls out even though they were "cleaned" and removed)
          Object.keys(patched).forEach((key) => delete current[key]);
          const cleaned = Object.entries(patched)
            .filter(([k, v]) => !config.cleanNullish || v || v === 0 || v === false || v === "")
            .toObject();
          return { ...current, ...cleaned };
        },
        { replace: config.cleanNullish },
      );
    },
    [config.cleanNullish, _setState],
  );

  return [state, setState];
};

function mapFiltersToUrlString(state: Partial<ValidQueryStringObject>, cleanNullish: boolean): string {
  const cleanedEntries: [key: string, value: ValidQueryStringDataTypes][] = Object.entries(state).filter(
    ([, value]) =>
      // If we're not cleaning nullish, allow
      !cleanNullish ||
      // Otherwise, only allow it in if it's truthy, 0, or False
      value ||
      value === 0 ||
      value === false,
  );
  const [arrayFilters, otherFilters] = partition(cleanedEntries, ([key, value]) => Array.isArray(value));
  const otherUrlStrings = otherFilters.map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`,
  );
  const arrayUrlStrings = (arrayFilters as [key: string, value: ValidQueryStringDataTypes][]).flatMap(
    ([key, value]) =>
      !value || (value as string[]).isEmpty
        ? `${encodeURIComponent(key)}[]=` // allow empty arrays
        : (value as string[]).map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v!)}`), // otherwise format as `key[]=1&key[]=2&key[]=3`
  );

  return [...otherUrlStrings, ...arrayUrlStrings].join("&");
}

export default useQueryString;
