import { QueryResult } from "@apollo/client";
import { Global } from "@emotion/react";
import { Css, GridColumn, GridDataRow, GridTable, RowStyles } from "@homebound/beam";
import { Fragment } from "react";
import {
  ExhibitGExportProjectItemFragment,
  useExhibitGExportQuery,
  useFinishScheduleExportQuery,
} from "src/generated/graphql-types";
import { groupBy, renderLoadingOrError } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";

export function ExhibitGExportPdf() {
  const [{ estimateId, projectStageId }] = useQueryParams({ estimateId: StringParam, projectStageId: StringParam });
  const {
    data: estimateData,
    loading: eLoading,
    error: eError,
  } = useExhibitGExportQuery({ variables: { estimateId: estimateId! }, skip: !estimateId });
  const {
    data: projectStageData,
    loading: psLoading,
    error: psError,
  } = useFinishScheduleExportQuery({ variables: { projectStageId: projectStageId! }, skip: !projectStageId });

  // Handle loading state
  if (eLoading || psLoading || eError || psError)
    return renderLoadingOrError({ loading: eLoading || psLoading, error: eError ?? psError } as QueryResult);

  // Extract projectItems from lineItems
  const projectItems = (
    estimateId
      ? (estimateData?.projectEstimate.lineItems.map(
          ({ projectItem }) => projectItem,
        ) as ExhibitGExportProjectItemFragment[])
      : (projectStageData?.projectStage.projectItems as ExhibitGExportProjectItemFragment[])
  ).filter((projectItem) => projectItem.homeownerSelection);

  // Group projectItems by divisions and costCode
  const divisions = groupBy(projectItems, (projectItem) => {
    const { number, name } = projectItem.item.costCode.division;
    return `${number} ${name}`;
  });
  const divisionsCostCode: any = Object.entries(divisions).reduce(
    (acc, [key, projectItems]) => ({
      ...acc,
      [key]: groupBy(projectItems, (projectItem) => {
        const { number, name } = projectItem.item.costCode;
        return `${number} ${name}`;
      }),
    }),
    {},
  );

  return (
    <Fragment>
      {projectItems.length ? (
        <>
          {/* Custom table styling */}
          <Global
            styles={{
              table: Css.ba.bcGray400.add("fontFamily", "monospace").add("breakInside", "avoid").$,
              tr: Css.add("breakInside", "avoid").$,
            }}
          />
          {/* Creating multiple tables to force each costDivision to be placed on a
          new page if a page break is going to happen */}
          {Object.keys(divisionsCostCode).map((key) => (
            <GridTable
              key={key}
              as="table"
              columns={createColumns()}
              rows={createRows({ [key]: divisionsCostCode[key] })}
              rowStyles={createRowStyles()}
            />
          ))}
        </>
      ) : (
        <p>
          No selection items found. Add at least one selection-based item and a selected option to the change event to
          populate the Exhibit G
        </p>
      )}
    </Fragment>
  );
}

type DivisionRow = { kind: "division"; data: { displayName: string } };
type CostCodeRow = { kind: "costCode"; data: { displayName: string } };
type ProjectItemRow = { kind: "projectItem"; data: ExhibitGExportProjectItemFragment };
type Row = DivisionRow | CostCodeRow | ProjectItemRow;

function createColumns(): GridColumn<Row>[] {
  const mainColumn: GridColumn<Row> = {
    division: (data) => <div css={Css.lgSb.$}>{data.displayName}</div>,
    costCode: (data) => <div css={Css.baseMd.$}>{data.displayName}</div>,
    projectItem: (data) => {
      const quantity = `${data.quantity} ${data.unitOfMeasure?.name}`;
      const selectedOption = data.homeownerSelection?.selectedOption;
      return (
        <div css={Css.smMd.df.fdc.gap1.$}>
          <div>{data.displayName}</div>
          <div>{quantity}</div>
          <div css={Css.dg.gtc("1fr 3fr").gap2.$}>
            {selectedOption ? (
              <>
                <img
                  src={selectedOption.product.images?.[0]?.downloadUrl}
                  alt={selectedOption.name}
                  css={Css.hPx(100).w100.objectContain.$}
                />
                <div css={Css.df.fdc.gap2.$}>
                  <div>{selectedOption?.name}</div>
                  <div>{data.specifications}</div>
                </div>
              </>
            ) : (
              <p>Selection not finalized</p>
            )}
          </div>
        </div>
      );
    },
  };

  return [mainColumn];
}

function createRows(divisionsCostCode?: any): GridDataRow<Row>[] {
  return (
    Object.entries<Record<string, ExhibitGExportProjectItemFragment[]>>(divisionsCostCode)
      // Sorting by costDivision number and name
      .sort(([a], [b]) => a.localeCompare(b))
      .flatMap(([division, costCode]) => {
        return [
          { kind: "division", id: division, data: { displayName: division } },
          // Sorting by costCode number and name
          ...Object.entries(costCode)
            .sort(([a], [b]) => a.localeCompare(b))
            .flatMap(([costCode, projectItems]) => {
              return [
                { kind: "costCode", id: costCode, data: { displayName: costCode } },
                ...projectItems.map((projectItem) => ({ kind: "projectItem", id: projectItem.id, data: projectItem })),
              ];
            }),
        ];
      }) as GridDataRow<Row>[]
  );
}

function createRowStyles(): RowStyles<Row> {
  return {
    division: { cellCss: Css.bgColor("#d9d9d9").p1.sm.$ },
    costCode: { cellCss: Css.bgColor("#f3f3f3").p1.sm.$ },
    projectItem: { cellCss: Css.p1.sm.$ },
  };
}
