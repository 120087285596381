import { Css } from "@homebound/beam";
import { PdfProjectItemFragment } from "src/generated/graphql-types";
import { ProjectItemDetail } from "./ProjectItemDetail";

type ProjectItemDetailsProps = {
  projectItems: PdfProjectItemFragment[];
  title: string;
  id?: string;
};
export const ProjectItemDetails = ({ projectItems, title, id }: ProjectItemDetailsProps) =>
  projectItems.length > 0 ? (
    <div css={Css.mb5.$} data-testid={id}>
      <h1 css={Css.gray800.fwb.xl2Sb.m0.$} data-testid="title">
        {title}
      </h1>
      {projectItems.map((pi) => (
        <ProjectItemDetail key={`projectItem-${pi.id}`} projectItem={pi} />
      ))}
    </div>
  ) : null;
