import { SelectField } from "@homebound/beam";
import { useTakeoffsStore } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { isItivOrderField, takeoffItemTableGroupByOptions } from "src/routes/libraries/plan-package/takeoffs/utils";

export function GroupBySelectField() {
  const itemTableGroupBy = useTakeoffsStore((state) => state.itemTableGroupBy);
  const setItemTableGroupBy = useTakeoffsStore((state) => state.setItemTableGroupBy);

  return (
    <SelectField
      compact
      label="Group By"
      labelStyle="inline"
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      onSelect={(v) => v && isItivOrderField(v) && setItemTableGroupBy(v)}
      options={takeoffItemTableGroupByOptions()}
      value={itemTableGroupBy}
      sizeToContent
    />
  );
}
