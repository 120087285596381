import { Button, Css, ModalBody, ModalFooter, ModalHeader, RadioGroupField, useModal } from "@homebound/beam";
import { useState } from "react";
import { DevelopmentScopeTemplateFragment, useArchiveItemTemplateVersionsMutation } from "src/generated/graphql-types";

type ArchiveItemTemplatesModalProps = {
  itemTemplates: Pick<DevelopmentScopeTemplateFragment, "id" | "statusDetail" | "canCreateNewVersion">[];
  onSuccess(): void;
};

export function ArchiveItemTemplatesModal({ itemTemplates, onSuccess }: ArchiveItemTemplatesModalProps) {
  const modalText =
    "Projects currently using these templates will not be updated, but new projects will no longer be able to use them.";
  const { closeModal } = useModal();
  const [option, setOption] = useState<string>();
  const [archiveItemTemplateVersions] = useArchiveItemTemplateVersionsMutation();

  return (
    <>
      <ModalHeader>
        <h2>Archive Templates</h2>
      </ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap4.$}>
          <p>{modalText}</p>
          <RadioGroupField
            label={modalText}
            labelStyle={"hidden"}
            options={[
              { label: "Archive all versions of selected templates", value: "all" },
              {
                label: "Archive latest versions of selected templates.",
                value: "latest",
              },
            ]}
            value={option}
            onChange={(v) => setOption(v)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={() => closeModal()} />
        <Button
          label="Archive Templates"
          disabled={!option}
          onClick={async () => {
            closeModal();
            await archiveItemTemplateVersions({
              variables: { templateId: itemTemplates.map((it) => it.id), archiveAllVersions: option === "all" },
            });
            onSuccess();
          }}
        />
      </ModalFooter>
    </>
  );
}
