import { makeAutoObservable } from "mobx";
import {
  NamedFragment,
  SaveBidContractRevisionDrawInput,
  SaveBidContractRevisionInput,
} from "src/generated/graphql-types";

export class BidDrawRows {
  constructor(
    private revisionId: string,
    public readonly draws: BidDrawRow[],
    private readonly tasks: NamedFragment[],
  ) {
    makeAutoObservable(this);
  }

  get totalPercent(): number | undefined | null {
    return this.draws.sum((d) => d.amountInPercent || 0);
  }

  addDraw(): void {
    this.draws.push(new BidDrawRow(undefined, 0, undefined, undefined, undefined));
  }

  toInput(): SaveBidContractRevisionInput {
    return {
      id: this.revisionId,
      draws: this.draws.map((d) => d.toInput()),
    };
  }
}

export class BidDrawRow {
  constructor(
    public id: string | null | undefined,
    public amountInPercent: number | null | undefined,
    public description: string | null | undefined,
    public taskId: string | null | undefined,
    public isDeposit: boolean | null | undefined,
  ) {
    makeAutoObservable(this);
  }

  toInput(): SaveBidContractRevisionDrawInput {
    return {
      id: this.id,
      // convert percent to basis points on save
      amountInBasisPoints: parseFloat(((this.amountInPercent || 0) * 100).toFixed(2)),
      description: this.description,
      globalPlanTaskId: this.taskId,
      isDeposit: this.isDeposit,
    };
  }
}
