import { Accordion, Button, Css } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useParams } from "react-router";
import { Card } from "src/components/Card";
import { useFullscreenModal } from "src/components/useFullscreenModal";
import {
  ReviewUnitBidScopeStep_BidPackageGroupQuery,
  useReviewUnitBidScopeStep_BidPackageGroupQuery,
} from "src/generated/graphql-types";
import { BidPackageParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { BidPackageFormState } from "../AddBidPackagePage";
import { UnitBidPackageLineItemModal } from "./unit-bids/UnitBidPackageLineItemModal";

export function ReviewUnitBidScopeStep({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const { bidPackageGroupId } = useParams<BidPackageParams>();
  const unitBasedQuery = useReviewUnitBidScopeStep_BidPackageGroupQuery({
    variables: { id: formState.bidPackageGroupId.value || bidPackageGroupId },
  });
  return queryResult(unitBasedQuery, ({ bidPackageGroup }) => (
    <ReviewScopeUnitBidStepView bidPackageGroup={bidPackageGroup} />
  ));
}

function ReviewScopeUnitBidStepView({
  bidPackageGroup,
}: {
  bidPackageGroup: ReviewUnitBidScopeStep_BidPackageGroupQuery["bidPackageGroup"];
}) {
  const { openFullscreen } = useFullscreenModal();
  return bidPackageGroup.bidPackages.compact().map((bidPackage) => {
    return (
      <div key={bidPackage.id}>
        <div css={Css.df.fdc.aic.mt5.mb4.$}>
          <div css={Css.xl3Sb.$}>Confirm Scope for the Packages</div>
          <div css={Css.base.pt2.$}>
            Based on your previous selections all line items missing contracted costs are pre-filled the packages.
          </div>
          <div css={Css.base.$}>These are automatically attached to the bids as a CSV file</div>
        </div>
        <Card xss={Css.wfc.mxa.$} key={bidPackage.id}>
          <div css={Css.mw("730px").$}>
            <Accordion
              topBorder={false}
              defaultExpanded
              title={
                <div css={Css.lgBd.$}>
                  {bidPackage.name} Package
                  <Button
                    size="sm"
                    label="View Line Items"
                    variant="tertiary"
                    onClick={() => openFullscreen(<UnitBidPackageLineItemModal bidPackage={bidPackage} />, true)}
                  ></Button>
                </div>
              }
            >
              <UnitBidSummarySection bidPackage={bidPackage} />
            </Accordion>
          </div>
        </Card>
      </div>
    );
  });
}

function UnitBidSummarySection({
  bidPackage,
}: {
  bidPackage: ReviewUnitBidScopeStep_BidPackageGroupQuery["bidPackageGroup"]["bidPackages"][0];
}) {
  const bplisGroupedByCostCode = bidPackage.lineItems.compact().groupBy((li) => li.bidItem.items[0].costCode.id);

  return bidPackage.costCodes
    .compact()
    .uniqueByKey("id")
    .map((costCode) => {
      return (
        <div css={Css.my1.bgGray100.br8.mb2.$} key={costCode.id}>
          <div css={Css.br12.hPx(80).pl2.df.aic.$}>
            <div css={Css.m2.df.fdc.gap1.$}>
              <div css={Css.baseBd.$}>{costCode.displayName}</div>
              <div css={Css.smSb.bgGray100.$}>Total Line Items: {bplisGroupedByCostCode[costCode.id]?.length || 0}</div>
            </div>
          </div>
        </div>
      );
    });
}
