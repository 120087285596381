import {
  actionColumn,
  Button,
  Css,
  dateColumn,
  GridColumn,
  GridTable,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
  UseModalHook,
} from "@homebound/beam";
import { formatDate } from "src/components";
import { AssetGallery, OpenGallery } from "src/components/assetGallery/AssetGallery";
import { WithActions } from "src/components/to-dos/WithActions";
import {
  RequirementsTabTradePartnerRequirementFragment,
  TradePartnerRequirementStatusDataFragment,
  TradePartnerRequirementTypeDataFragment,
  useTradePartnerRequirementsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { AssetPreview } from "../projects/assets/AssetPreview";
import { TradePartnerRequirementEditor } from "./TradePartnerRequirementEditor";

type TradePartnerRequirementsProps = {
  tradePartnerId: string;
};

export function TradePartnerRequirements({ tradePartnerId }: TradePartnerRequirementsProps) {
  const query = useTradePartnerRequirementsQuery({ variables: { tradePartnerId } });
  return queryResult(query, (data) => (
    <TradePartnerRequirementsDataView
      requirements={data.tradePartner.requirements}
      tradePartnerId={tradePartnerId}
      tradePartnerRequirementTypes={data.tradePartnerRequirementTypes}
      tradePartnerRequirementStatuses={data.tradePartnerRequirementStatuses}
    />
  ));
}

type TradePartnerRequirementsDataviewProps = {
  requirements: RequirementsTabTradePartnerRequirementFragment[];
  tradePartnerId: string;
  tradePartnerRequirementTypes: TradePartnerRequirementTypeDataFragment[];
  tradePartnerRequirementStatuses: TradePartnerRequirementStatusDataFragment[];
};

export function TradePartnerRequirementsDataView({
  requirements,
  tradePartnerId,
  tradePartnerRequirementTypes,
  tradePartnerRequirementStatuses,
}: TradePartnerRequirementsDataviewProps) {
  const modal = useModal();
  return (
    <>
      <div css={Css.df.jcfe.mb1.$}>
        <Button
          label="Add Requirement"
          onClick={() => {
            modal.openModal({
              size: "md",
              content: (
                <TradePartnerRequirementEditor
                  tradePartnerId={tradePartnerId}
                  tradePartnerRequirementTypes={tradePartnerRequirementTypes || []}
                  tradePartnerRequirementStatuses={tradePartnerRequirementStatuses}
                />
              ),
            });
          }}
        />
      </div>
      <ScrollableContent>
        <GridTable
          stickyHeader
          rows={simpleDataRows(requirements)}
          columns={createColumns()}
          rowStyles={createRowStyles(modal, tradePartnerRequirementTypes, tradePartnerRequirementStatuses)}
          style={{ allWhite: true, bordered: true }}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<RequirementsTabTradePartnerRequirementFragment>;

function createColumns(): GridColumn<Row>[] {
  return [
    { header: "Type", data: ({ type }) => (type.isTsa ? `TSA: ${type.name}` : type.name), w: "300px" },
    { header: "Eligibility", data: (requirement) => requirement.status.name, w: "300px" },
    dateColumn<Row>({
      header: "Expiration Date",
      data: (requirement) => formatDate(requirement.expirationDate || undefined),
      w: "200px",
    }),
    actionColumn<Row>({
      header: "Attachments",
      data: ({ attachments }) => {
        if (!attachments?.length) return;
        const renderAttachments = (openGallery: OpenGallery) => {
          return (
            <>
              {attachments.map((attachment) => {
                return (
                  <div key={attachment.id || attachment.asset.fileName} data-testid={attachment.id}>
                    <WithActions
                      right={{ icon: "download", tooltip: "download", action: attachment.asset.attachmentUrl }}
                      footer={{ text: attachment.asset.fileName! }}
                      onClick={() => openGallery(attachment.asset)}
                    >
                      <AssetPreview asset={attachment.asset} dimensions={{ width: 80, height: 80 }} />
                    </WithActions>
                  </div>
                );
              })}
            </>
          );
        };

        return (
          <div css={Css.oa.$}>
            <AssetGallery assets={attachments.map((i) => i.asset)} display="horizontal">
              {renderAttachments}
            </AssetGallery>
          </div>
        );
      },
    }),
  ];
}

function createRowStyles(
  modal: UseModalHook,
  tradePartnerRequirementTypes: TradePartnerRequirementTypeDataFragment[],
  tradePartnerRequirementStatuses: TradePartnerRequirementStatusDataFragment[],
): RowStyles<Row> {
  return {
    header: {},
    data: {
      onClick: (row) => {
        modal.openModal({
          size: "md",
          content: (
            <TradePartnerRequirementEditor
              requirement={row.data}
              tradePartnerRequirementTypes={tradePartnerRequirementTypes}
              tradePartnerRequirementStatuses={tradePartnerRequirementStatuses}
            />
          ),
        });
      },
    },
  };
}
