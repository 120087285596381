import { Button, Css } from "@homebound/beam";
import { ProgressPill } from "src/components";
import { ScheduleTemplateWithMappingInfo } from "../DevelopmentScheduleCostMappingPage";

type ScheduleTemplateSelectorProps = {
  scheduleTemplates: ScheduleTemplateWithMappingInfo[];
  selectedTemplate?: ScheduleTemplateWithMappingInfo;
  handleSelection(template: ScheduleTemplateWithMappingInfo): void;
  isSummaryStep?: boolean;
};

export default function ScheduleTemplateSelector({
  scheduleTemplates,
  selectedTemplate,
  handleSelection,
  isSummaryStep = false,
}: ScheduleTemplateSelectorProps) {
  const hoverStyle = !isSummaryStep
    ? {
        "&[aria-checked='false']:hover, &[aria-checked='false']:focus": Css.ba.bshHover.$,
        "&[aria-checked='true']": Css.ba.bcBlue700.bw2.$,
      }
    : {};

  return (
    <div css={Css.bgGray100.fdc.if(isSummaryStep).bgWhite.$}>
      <div
        css={Css.df.add("flexWrap", "wrap").gapPx(24).$}
        role="radiogroup"
        aria-labelledby="cost-mapping-schedule-template-options"
      >
        {scheduleTemplates.length === 0 && (
          <span>There aren't any schedule templates associated to this development that can be mapped.</span>
        )}
        {scheduleTemplates.map((scheduleTemplate) => (
          <div
            role="radio"
            tabIndex={0}
            aria-checked={selectedTemplate?.id === scheduleTemplate.id}
            aria-label={`${scheduleTemplate.stage}-template-${scheduleTemplate.name}`}
            onKeyPress={({ key }) => {
              key === "Enter" && !isSummaryStep && handleSelection(scheduleTemplate);
            }}
            onClick={() => !isSummaryStep && handleSelection(scheduleTemplate)}
            key={`schedule-template-card-${scheduleTemplate.id}`}
            css={{
              ...Css.df.fdc.bgWhite.jcsb.br8.px2.pt2
                .wPx(300)
                .mhPx(156)
                .pb("22px")
                .bw1.bcGray200.cursorPointer.if(isSummaryStep).ba.$,
              ...hoverStyle,
            }}
            data-testid="scheduleTemplateCard"
          >
            <div>
              <div css={Css.sm.pb("8px").gray700.if(isSummaryStep).tiny.$}>{scheduleTemplate.stageDetail.name}</div>
              <div css={Css.baseMd.pb("16px").gray900.$}>{scheduleTemplate.name}</div>
            </div>
            <ProgressPill
              stacked
              fullWidth
              bottomLabel
              text={`${scheduleTemplate.mapped}/${scheduleTemplate.total} mapped items ${
                scheduleTemplate.total > 0 && scheduleTemplate.mapped >= scheduleTemplate.total ? "🎉" : ""
              }`}
              changeColorOnCompleted
              progress={(scheduleTemplate.mapped / scheduleTemplate.total) * 100}
              hideProgress
            />
            {isSummaryStep && (
              <div css={Css.mt("32px").addIn("button", Css.db.mw("100%").$).$}>
                <Button
                  label="Continue Mapping"
                  variant="secondary"
                  onClick={() => handleSelection(scheduleTemplate)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
