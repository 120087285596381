import { Only } from "@homebound/beam";
import { Observer } from "mobx-react";
import { DocumentUploader, DocumentUploaderProps, FileUploaderXss } from "src/components/uploader";
import { DocumentEditorDetailFragment } from "src/generated/graphql-types";
import { FieldState } from "src/utils/formState";

type BoundFileUploaderProps<X> = Omit<DocumentUploaderProps<X>, "file" | "onFinish" | "readonly" | "error"> & {
  field: FieldState<DocumentEditorDetailFragment | null | undefined>;
};

/** Wraps `DocumentUploader` and binds it to a form field */
export function BoundFileUploader<X extends Only<FileUploaderXss, X>>(props: BoundFileUploaderProps<X>) {
  const { field, readOnly, ...others } = props;
  return (
    <Observer>
      {() => (
        <DocumentUploader
          readOnly={readOnly ?? field.readOnly}
          file={field.value || undefined}
          onFinish={(file) => {
            field.set(file);
            field.blur();
          }}
          error={field.touched ? field.errors.join(" ") : undefined}
          {...others}
        />
      )}
    </Observer>
  );
}
