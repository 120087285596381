import { useCurrentUserQuery, User } from "src/generated/graphql-types";
import {
  UserEventFilterWithDateFilterValue,
  UserEventsTable,
} from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { queryResult } from "src/utils";
import { PersonalDashboardTitle } from "../../components/PersonalDashboardTitle";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { PersonalDashboardFilter } from "../../useHeaderFilterDefs";

export function ActivityPage() {
  const query = useCurrentUserQuery({ fetchPolicy: "cache-first" });
  return queryResult(query, {
    data: ({ currentUser }) => <ActivityPageView currentUser={currentUser as User} />,
  });
}

type ActivityPageViewProps = {
  currentUser: User;
};

function ActivityPageView({ currentUser }: ActivityPageViewProps) {
  const { filter } = useDashboardFilterContext();

  return (
    <div>
      <PersonalDashboardTitle title="Activity" />
      <UserEventsTable currentUser={currentUser} filter={mapToFilter(filter)} filterClosedProjects />
    </div>
  );
}
// mapping our internalUser to relevantTo for the backend, and omitting the "type" conflict used on todos filtering
function mapToFilter(filter: Omit<PersonalDashboardFilter, "type">): UserEventFilterWithDateFilterValue {
  const { internalUser, ...others } = filter;
  return { ...others, relevantTo: internalUser };
}
