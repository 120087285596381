import { ButtonMenu, Css, IconButton, Palette, useComputed } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { useRef } from "react";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { useUppyUploader } from "src/components/uploader/UppyUploader";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { AddProductFormValue } from "../../design-catalog/design-package-configurator/components/AddProductPage";
import { MaterialVariantFormValue, VariantImageAsset } from "./material-super-drawer/MaterialSuperDrawerContext";

export type MaterialImageUploaderProps = {
  variant: ObjectState<MaterialVariantFormValue> | ObjectState<AddProductFormValue>;
  dimensions?: { height: number; width: number };
};

export function MaterialImageEditor({ variant, dimensions = { height: 128, width: 128 } }: MaterialImageUploaderProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const uppy = useUppyUploader({ onFinish: saveImage, autoProceed: true });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files || [];
    if (file) {
      uppy.addFile({
        name: file.name,
        size: file.size,
        type: file.type,
        data: file,
      });
    }
  };

  function saveImage(file: UppyFile) {
    const downloadUrl = file.meta.downloadUrl as string;
    variant.images.add({
      sortOrder: (variant.images.value?.length ?? 0) + 1,
      downloadUrl,
      attachmentUrl: downloadUrl,
      asset: {
        fileName: file.name,
        s3Key: file.meta.s3Key as string,
        contentType: file.type,
        sizeInBytes: file.size,
      },
    });
  }

  function featureImage(os: ObjectState<VariantImageAsset>) {
    const others = variant.images.rows.filter((i) => i !== os);
    os.sortOrder.set(1);
    others.forEach((im, i) => im.sortOrder.set(i + 2));
  }

  const images = useComputed(
    () =>
      (variant.images.rows ?? [])
        .map((i) => ({
          image: i,
          asset: {
            ...i.value.asset,
            attachmentUrl: i.attachmentUrl.value,
            downloadUrl: i.downloadUrl.value,
          } as AssetInfoFragment,
        }))
        .sortBy((i) => i.image.sortOrder.value ?? 0),
    [variant],
  );

  return (
    <div css={Css.df.fdc.aifs.$}>
      <div css={Css.df.fdc.$}>
        <div css={Css.mb2.df.cg1.$}>
          <AssetGallery assets={images.map((i) => i.asset)} display="horizontal">
            {(openGallery) => (
              <div css={Css.df.fdc.gap1.$}>
                {images.map(({ image, asset }, i) => {
                  return (
                    <div key={asset.id} css={Css.relative.p1.bgWhite.ba.gray300.br4.$}>
                      <AssetPreview asset={asset} dimensions={dimensions} onClick={() => openGallery(asset)} />
                      <div css={Css.absolute.bottom1.right1.$}>
                        <ButtonMenu
                          trigger={{ icon: "verticalDots" }}
                          items={[
                            { label: "Add", onClick: () => inputRef.current!.click() },
                            ...(i !== 0 ? [{ label: "Feature", onClick: () => featureImage(image) }] : []),
                            { label: "Delete", onClick: () => variant.images.remove(image.value) },
                          ]}
                        />
                      </div>
                    </div>
                  );
                })}
                {images.isEmpty && (
                  <div css={Css.wPx(dimensions.width).hPx(dimensions.height).p1.ba.gray300.br4.$}>
                    <div
                      css={
                        Css.df.aic.jcc
                          .wPx(dimensions.width - 16)
                          .hPx(dimensions.height - 16)
                          .bgColor("#F1F6FE").$
                      }
                    >
                      <IconButton
                        icon="cloudUpload"
                        color={Palette.Blue700}
                        onClick={() => inputRef.current!.click()}
                      />
                    </div>
                  </div>
                )}
                <input
                  id="uppyFileInput"
                  css={Css.dn.$}
                  ref={inputRef}
                  type="file"
                  accept={["image/jpeg", "image/png", "image/gif"].join()}
                  onChange={handleFileChange}
                />
              </div>
            )}
          </AssetGallery>
        </div>
      </div>
    </div>
  );
}
