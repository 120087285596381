import {
  Chip,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  collapseColumn,
  column,
  dateColumn,
  emptyCell,
} from "@homebound/beam";
import { dateCell } from "src/components/gridTableCells";
import { BidPackageDetailRequestFragment } from "src/generated/graphql-types";

type BiddersTabProps = {
  requests: BidPackageDetailRequestFragment[];
};

export function BiddersTab({ requests }: BiddersTabProps) {
  const rows: GridDataRow<Row>[] = createRows(requests);
  return <GridTable columns={columns} rows={rows} />;
}
function createRows(requests: BidPackageDetailRequestFragment[]): GridDataRow<Row>[] {
  return [
    { kind: "header" as const, id: "header", data: undefined },
    ...requests.map((request) => {
      return {
        kind: "tradePartnerPrimary" as const,
        data: { request },
        id: request.id,
        children: request.secondaryContact
          ? [
              {
                kind: "tradePartnerSecondary" as const,
                id: request.secondaryContact.id,
                data: { secondary: request.secondaryContact },
              },
            ]
          : undefined,
      };
    }),
  ];
}

type HeaderRow = { kind: "header"; data: undefined };

type TradePartnerPrimaryRow = {
  kind: "tradePartnerPrimary";
  data: {
    request: BidPackageDetailRequestFragment;
  };
};

type TradePartnerSecondaryRow = {
  kind: "tradePartnerSecondary";
  data: {
    secondary: NonNullable<BidPackageDetailRequestFragment["secondaryContact"]>;
  };
};

type Row = HeaderRow | TradePartnerPrimaryRow | TradePartnerSecondaryRow;
const columns: GridColumn<Row>[] = [
  collapseColumn<Row>(),
  column<Row>({
    id: "companyName",
    header: "Company Name",
    tradePartnerPrimary: (r) => r.request.tradePartner.name,
    tradePartnerSecondary: emptyCell,
  }),
  column<Row>({
    id: "contact",
    header: "Contact",
    tradePartnerPrimary: (r) => (
      <>
        {r.request.primaryContact?.name} <Chip text="Primary" type="neutral" xss={Css.ml1.$} />
      </>
    ),
    tradePartnerSecondary: (tp) => (
      <>
        {tp.secondary.name} <Chip text="Secondary" type="neutral" xss={Css.ml1.$} />
      </>
    ),
  }),
  column<Row>({
    id: "phone",
    header: "Phone",
    tradePartnerPrimary: (r) => r.request.primaryContact?.mobilePhone,
    tradePartnerSecondary: (tp) => tp.secondary.mobilePhone,
  }),
  column<Row>({
    id: "email",
    header: "Email",
    tradePartnerPrimary: (r) => r.request.primaryContact?.email,
    tradePartnerSecondary: (tp) => tp.secondary.email,
  }),
  dateColumn<Row>({
    w: "110px",
    id: "sent",
    header: "Invitation Sent",
    tradePartnerPrimary: (r) => dateCell(r.request.sentDate),
    tradePartnerSecondary: emptyCell,
  }),
];
