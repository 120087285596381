import { GridDataRow } from "@homebound/beam/dist/components/Table";
import {
  CostType,
  DevelopmentItemMapping,
  DevelopmentScheduleItemMappingFragment,
  ItemTemplateItem,
  Location,
  Maybe,
  ScheduleTask,
} from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { ObjectConfig, required } from "src/utils/formState";
import { MapTemplateItemsRow } from "../components/MapTemplateItemsTable";

export class DevelopmentPotentialItemMapping extends Selectable {
  costCode: Pick<DevelopmentItemMapping["item"]["costCode"], "id" | "displayName">;
  item: Pick<DevelopmentItemMapping["item"], "id" | "displayName">;
  location: Pick<Location, "id" | "name">;
  costType: CostType;
  scheduleItemMappingId: Maybe<string>;
  task: Maybe<Pick<ScheduleTask, "id" | "name">>;
  taskId: Maybe<string>;

  constructor(im: DevelopmentScheduleItemMappingFragment, scheduleTemplateId: string) {
    super(`${im.item.id}-${im.location.name}-${im.costType}`, false);
    this.costCode = im.item.costCode;
    this.item = im.item;
    this.location = im.location;
    this.costType = im.costType;

    const scheduleTemplateItemMapping = im.scheduleTemplateItemMappings
      .filter((stim) => stim.scheduleTemplate.id === scheduleTemplateId)
      .find(
        (stim) =>
          stim.costType.code === im.costType && stim.location.id === im.location.id && stim.item.id === im.item.id,
      );

    if (scheduleTemplateItemMapping) {
      this.scheduleItemMappingId = scheduleTemplateItemMapping.id;
      this.task = scheduleTemplateItemMapping.task;
      this.taskId = scheduleTemplateItemMapping.task.id;
    }
  }

  toRow(): GridDataRow<MapTemplateItemsRow> {
    return {
      kind: "item" as const,
      id: this.id,
      data: this,
    };
  }

  get isUnmapped() {
    return !this.scheduleItemMappingId;
  }
}

export type MapTemplateItemsFormValue = {
  id: Maybe<string>;
  item: Pick<ItemTemplateItem, "id" | "displayName">;
  location: Pick<Location, "id" | "name">;
  costType: Maybe<CostType>;
  taskId: Maybe<string>;
  scheduleItemMappingId: Maybe<string>;
};

export const mapTemplateItemsFormConfig: ObjectConfig<MapTemplateItemsFormValue> = {
  id: { type: "value" },
  item: { type: "value", rules: [required] },
  costType: { type: "value", rules: [required] },
  location: { type: "value", rules: [required] },
  taskId: { type: "value", rules: [required] },
  scheduleItemMappingId: { type: "value" },
};
