import {
  BoundDateField,
  BoundMultiSelectField,
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  Css,
  FormLines,
  Icon,
  IconButton,
  StaticField,
  TabsWithContent,
  TabWithContent,
  TextField,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  DocumentAttachmentPlace,
  DocumentDetailDocumentTypeFragment,
  DocumentEditorDetailFragment,
  DocumentType,
  InternalUserDetailFragment,
  Maybe,
  NamedDevelopmentCohortsProjectsFragment,
  ProjectDocumentAssociationInput,
  SaveDocumentInput,
  Scalars,
  ToggleDocumentAsFavoritedInput,
  useDocumentEditorQuery,
  useSaveDocumentsMutation,
  useSaveFollowersMutation,
  useScheduleTasksForDocumentParentQuery,
  useToggleDocumentAsFavoritedMutation,
} from "src/generated/graphql-types";
import { DocumentScheduleTasks } from "src/routes/projects/documents/DocumentScheduleTasks";
import { createDevelopmentDocumentUrl, createProjectDocumentUrl } from "src/RouteUrls";
import { bytesToSize, isDefined, pushInNextLoop, safeEntries, sortBy } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, useFormState } from "src/utils/formState";
import { queryResult } from "src/utils/queryResult";
import { StringParam, useQueryParams } from "use-query-params";
import { AssetPreview } from "../assets/AssetPreview";
import { BoundAssociatedProjectsTreeSelect } from "./BoundAssociatedProjectsTreeSelect";
import { DocumentVersionManager } from "./DocumentVersionManager";

export type DocumentEditorProps = {
  documentId: string;
  documentTypes: DocumentDetailDocumentTypeFragment[];
  groupBy?: string;
};

export function DocumentEditor(props: DocumentEditorProps) {
  const { documentId, documentTypes, groupBy } = props;
  const query = useDocumentEditorQuery({ variables: { documentId } });

  return queryResult(query, ({ document, internalUsers }) => (
    <DocumentEditorView
      document={document}
      documentTypes={documentTypes}
      internalUsers={internalUsers}
      groupBy={groupBy}
    />
  ));
}

type DocumentEditorViewProps = Omit<DocumentEditorProps, "documentId"> & {
  document: DocumentEditorDetailFragment;
  documentTypes: DocumentDetailDocumentTypeFragment[];
  internalUsers: InternalUserDetailFragment[];
};

function DocumentEditorView(props: DocumentEditorViewProps) {
  const { document, documentTypes, groupBy, internalUsers } = props;
  // Determine if the document should be editable; systemControlled document types are not editable
  const isEditable = documentTypes.map((t) => t.code).includes(document.documentType.code);
  const displayedDocTypes = sortBy(isEditable ? documentTypes : [document.documentType], ({ name }) => name);
  const scheduleTasksQuery = useScheduleTasksForDocumentParentQuery({
    variables: { parentId: document.parent.id },
  });
  const [saveDocumentsMutation] = useSaveDocumentsMutation();
  const [toggleDocumentAsFavoritedMutation] = useToggleDocumentAsFavoritedMutation();
  const { projectId, developmentId } = useParams<{ projectId: string; developmentId: string }>();
  const history = useHistory();
  const isDevelopmentPage = isDefined(developmentId);
  // If the document is on a development, get its parent's cohorts and projects
  const development = document.parent.__typename === "Development" ? document.parent : undefined;

  const onSave = async (input: SaveDocumentInput) => {
    await saveDocumentsMutation({
      variables: { input: [input] },
      refetchQueries: ["ProjectDocuments", "DocumentsForParent"],
    });
    const baseUrl = isDevelopmentPage
      ? createDevelopmentDocumentUrl(developmentId, document.id)
      : createProjectDocumentUrl(projectId, document.id);
    pushInNextLoop(history, `${baseUrl}${groupBy ? `&groupBy=${groupBy}` : ""}`);
  };
  const onArchive = async (input: SaveDocumentInput) => {
    await saveDocumentsMutation({
      variables: { input: [input] },
      refetchQueries: ["ProjectDocuments", "DocumentsForParent"],
    });
  };
  const onFavorite = async (input: ToggleDocumentAsFavoritedInput) => {
    await toggleDocumentAsFavoritedMutation({
      variables: { input },
    });
  };

  const scheduleTasks = scheduleTasksQuery.data?.scheduleTasks ?? [];
  const [, setQueryParams] = useQueryParams({ documentId: StringParam });

  const tabs: TabWithContent[] = [
    {
      name: "Details",
      value: "details",
      render: () => (
        <Observer>
          {() => (
            <DocumentForm
              document={document}
              internalUsers={internalUsers}
              readOnly={!isEditable}
              displayedDocTypes={displayedDocTypes}
              docTypeCode={document.documentType.code}
              onSave={onSave}
              development={development}
            />
          )}
        </Observer>
      ),
    },
    {
      name: "Relationships",
      value: "relationships",
      render: () => <DocumentScheduleTasks document={document} scheduleTasks={scheduleTasks} onSave={onSave} />,
    },
    {
      name: "History",
      value: "history",
      render: () => <DocumentVersionManager document={document} />,
    },
  ];

  const [tab, setTab] = useState<string>(tabs[0].value);

  return (
    <div css={Css.df.fdc.gap1.pb2.h100.oa.$}>
      <div css={Css.p3.pb0.$}>
        <header css={Css.df.jcsb.aifs.mb1.gap1.$}>
          <div css={Css.df.aic.jcfe.gap1.$}>
            <IconButton
              icon={document.isFavorited ? "starFilled" : "star"}
              onClick={() => onFavorite({ documentId: document.id })}
            />
            <IconButton onClick={document.asset.attachmentUrl} icon="download" />
            <IconButton
              onClick={() => onArchive({ id: document.id, deleted: !document.isDeleted })}
              icon={document.isDeleted ? "unarchive" : "archive"}
              disabled={!document.canDelete?.allowed ? "Cannot archive system generated documents" : false}
            />
          </div>
          <div css={Css.df.jcsb.aic.gap1.$}>
            {/* TODO: add up and down navigation to the header of documents*/}
            <IconButton data-testid="closePane" icon="x" onClick={() => setQueryParams({ documentId: undefined })} />
          </div>
        </header>
        <div css={Css.lgSb.$}>{document.displayName}</div>
      </div>

      <div css={Css.p3.pt0.$}>
        <div css={Css.mb2.$}>
          <AssetGallery assets={[document.asset]}>
            {(openGallery) => <AssetPreview asset={document.asset} onClick={() => openGallery(document.asset)} />}
          </AssetGallery>
        </div>

        <TabsWithContent selected={tab} tabs={tabs} onChange={(value) => setTab(value)} />
      </div>
    </div>
  );
}

type FormValue = Pick<
  SaveDocumentInput,
  "id" | "name" | "homeownerVisible" | "category" | "projectDocumentAssociations" | "attachOn"
> & {
  followers: Maybe<Array<Scalars["ID"]>>;
  issuanceDate: Maybe<Date>;
  documentTypeCode: Maybe<DocumentType>;
};

const projectDocumentAssociationConfig: ObjectConfig<ProjectDocumentAssociationInput> = {
  projectId: { type: "value", isIdKey: true },
  delete: { type: "value", isDeleteKey: true },
};

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value" },
  documentTypeCode: { type: "value" },
  homeownerVisible: { type: "value" },
  followers: { type: "value" },
  attachOn: { type: "value" },
  issuanceDate: { type: "value" },
  projectDocumentAssociations: { type: "list", config: projectDocumentAssociationConfig },
};

function mapToForm(document: DocumentEditorDetailFragment): FormValue {
  const {
    id,
    documentType,
    displayName: name,
    homeownerVisible,
    followers,
    issuanceDate,
    associatedProjects,
    category,
    attachOn,
  } = document;
  return {
    id,
    name,
    category,
    attachOn,
    homeownerVisible,
    followers: followers.map(({ id }) => id),
    documentTypeCode: documentType.code,
    issuanceDate,
    projectDocumentAssociations: associatedProjects.map(({ id }) => ({ projectId: id })),
  };
}

function mapToInput(formInput: FormValue): SaveDocumentInput {
  return {
    id: formInput.id,
    documentType: formInput.documentTypeCode as DocumentType,
    name: formInput.name,
    homeownerVisible: formInput.homeownerVisible,
    issuanceDate: formInput?.issuanceDate && new DateOnly(formInput?.issuanceDate!),
    projectDocumentAssociations: formInput.projectDocumentAssociations,
    attachOn: formInput.attachOn,
  };
}

type DocumentFormProps = {
  document: DocumentEditorDetailFragment;
  internalUsers: InternalUserDetailFragment[];
  readOnly: boolean;
  displayedDocTypes: DocumentDetailDocumentTypeFragment[] | undefined;
  docTypeCode: DocumentType;
  onSave: (input: SaveDocumentInput) => void;
  development?: NamedDevelopmentCohortsProjectsFragment;
};

function DocumentForm({
  document,
  internalUsers,
  readOnly,
  displayedDocTypes,
  docTypeCode,
  development,
  onSave,
}: DocumentFormProps) {
  const [saveFollowersMutation] = useSaveFollowersMutation();
  const saveFollowers: (followerIds: string[]) => Promise<void> = async (followerIds) => {
    await saveFollowersMutation({
      variables: { input: { followableId: document.id, followerIds } },
    });
  };
  const formState = useFormState({
    config: formConfig,
    init: { input: document, map: mapToForm },
    autoSave: async (state) => {
      if (state.changedValue.followers) {
        await saveFollowers(state.changedValue.followers);
      }
      const { projectDocumentAssociations } = state;
      const { id, followers, ...other } = state.changedValue;
      // List fields aren't registered by changedValue, so we have to manually add dirty pda inputs in
      if (projectDocumentAssociations.dirty) {
        other.projectDocumentAssociations = projectDocumentAssociations.value;
      }
      if (Object.keys(other).nonEmpty) {
        await onSave(
          mapToInput({ ...state.changedValue, projectDocumentAssociations: other.projectDocumentAssociations }),
        );
      }
    },
    readOnly,
  });

  const { sizeInBytes, createdBy } = document;
  const readOnlyReason = readOnly ? "Cannot edit system controlled documents" : false;
  const filteredDisplayedDocTypes = displayedDocTypes?.filter(
    ({ code }) => code !== DocumentType.ArchitectureAndEngineering,
  );

  // For some reason, the updated value in the multi-select and issuance date of an updated doc would persist when switching, so this prevents that
  useEffect(() => {
    !readOnly && formState.followers.set(document.followers.map(({ id }) => id));
    !readOnly && formState.issuanceDate.set(document.issuanceDate);
    !readOnly && formState.attachOn.set(document.attachOn);
  }, [document, formState, readOnly]);

  return (
    <FormLines width="full">
      <div css={Css.df.jcsb.gap3.$}>
        <BoundTextField label="Name" field={formState.name} disabled={readOnlyReason} readOnly={false} />
        <div css={Css.w25.$}>
          <TextField
            xss={Css.lh("36px").$}
            label="Version"
            value={document.asset.version}
            onChange={() => {}}
            readOnly
          />
        </div>
      </div>
      <div>
        <BoundSelectField
          label="Type"
          field={formState.documentTypeCode}
          // deprecating A&E document type, so only show the value if it's already been selected, otherwise filter it out
          options={
            (formState.documentTypeCode.value === DocumentType.ArchitectureAndEngineering
              ? displayedDocTypes
              : filteredDisplayedDocTypes) || []
          }
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.code}
          disabledOptions={[DocumentType.General]}
          disabled={readOnlyReason}
          readOnly={false}
        />
        {docTypeCode === DocumentType.General && (
          <div css={Css.df.aic.bcYellow50.sm.gray700.mt1.br8.$}>
            <Icon icon="error" />
            <div css={Css.plPx(4).$}>Select a type to keep this project organized!</div>
          </div>
        )}
      </div>
      <StaticField label="Category" value={document.categoryDetail?.name ?? ""} />
      {development && (
        <BoundAssociatedProjectsTreeSelect
          development={development}
          readOnly={readOnly}
          field={formState.projectDocumentAssociations}
        />
      )}
      {!readOnly && (
        <BoundMultiSelectField
          label="Watchers"
          field={formState.followers}
          options={internalUsers}
          fieldDecoration={({ avatar, name }) => (
            <img src={avatar} alt={name} css={Css.br100.wPx(24).hPx(24).objectCover.oh.$} />
          )}
          getOptionMenuLabel={({ avatar, name }) => (
            <div css={Css.df.aifs.gap1.$}>
              <img src={avatar} alt={name} css={Css.br100.wPx(24).hPx(24).objectCover.oh.$} />
              <span>{name}</span>
            </div>
          )}
        />
      )}
      <BoundDateField field={formState.issuanceDate} readOnly={formState.readOnly} />
      {document.asset.contentType === "application/pdf" && (
        <BoundMultiSelectField
          label="Attach to"
          field={formState.attachOn}
          options={safeEntries(DocumentAttachmentPlace)}
          getOptionLabel={([name]) => name}
          getOptionValue={([, code]) => code}
        />
      )}
      {formState.readOnly ? (
        <StaticField label="Homeowner Viewable" value={formState.homeownerVisible.value ? "Yes" : "No"} />
      ) : (
        <BoundSwitchField label="Homeowner Viewable" labelStyle="inline" field={formState.homeownerVisible} />
      )}
      <div css={Css.df.jcsb.mb2.$}>
        <StaticField label="Created By" value={createdBy.name} />
        <StaticField label="File Status" value={document.isDeleted ? "Archived" : "Active"} />
        <StaticField label="File Size" value={bytesToSize(sizeInBytes)} />
      </div>
    </FormLines>
  );
}
