import { ApolloError } from "@apollo/client";
import Levenshtein from "fast-levenshtein";
import { useLocation } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { Error } from "src/routes/Error";
import { idOrAdd, projectsPath, suggestablePaths } from "src/routes/routesDef";
import { safeKeys } from "src/utils";
import { getErrorMessages } from "src/utils/error";

export function useFuzzyRouteMatch(error?: ApolloError | Error): [string[], Record<string, string>, string[]] {
  // Check for server errors that might give us some clues regarding where the user is trying to go, or what they're trying to do.
  const errorMessages = error ? getErrorMessages(error) : undefined;
  const location = useLocation();
  const currentPathname = location.pathname;
  const match = useRouteMatch();

  // Using `match.params` to help identify valid routes with params we can fuzzy match against
  // This will only ever include the parameters based on the <Route /> components 'path' that matched against it. The further nested within Router calls the more valid routes we have to match against.
  const matchedParams = match.params as Record<string, string>;

  // Test for a tagged id not found error. "foo:1 was not found" is the current graphql-error that is thrown, and mimicked by a local util method `entityNotFoundError` for achieving the same goal
  const taggedIdsInError =
    errorMessages &&
    errorMessages.map((e) => {
      const match = e.match(/(\D*:\d*) was not found/);
      // If matched, the taggedId will be the first capture.
      return match && match[1];
    });

  // Create a list of validParams to help weed out paths to fuzzy match against.
  const validParams = safeKeys(matchedParams).reduce((acc, param) => {
    // Do not consider idOrAdd as a valid parameter, as the parameter is constructed in many different ways and we can't be certain which is being expected.
    // Do not consider any taggedIdsInError valid
    return param === idOrAdd.replace(":", "") || (taggedIdsInError && taggedIdsInError.includes(matchedParams[param]))
      ? acc
      : acc.concat(param);
  }, [] as string[]);

  // Filter through list of suggestablePaths to create a set we can match against.
  const potentialPaths = suggestablePaths.filter((path) => {
    // Get list of parameters in path. - Regex will capture the preceding  `/:` as well as the param name. Remove the `/:` using the map/replace.
    const pathParams = path.match(/\/:[^/|(]*/g)?.map((p) => p.replace("/:", ""));

    // Only include if there are no pathParams, or every pathParam is included in validParams
    return !pathParams || pathParams.every((p) => validParams.includes(p));
  });

  const fuzzyResults: string[] = potentialPaths
    // "Fuzzy" match using `Levenshtein` on all routes that have made the cut.
    // Remove path parameter definitions when getting the levenshtein distance - adding extra `as Type` here to chain on sorting.
    .map((p) => [Levenshtein.get(currentPathname, p.replace(/\/:[^/]*/g, "")), p] as [number, string])
    // Levenshtein returns a score for how well the path matches - sort using that score.
    .sort(([s1], [s2]) => s1 - s2)
    // This set could potentially get really big, and really only want 10 suggestions.
    .slice(0, 10)
    // Only return the paths, we no longer need the score value.
    .map(([, path]) => path);

  // only return 10 possiblePaths - if none, then at least return "/projects"
  return [fuzzyResults.length > 0 ? fuzzyResults : [projectsPath], matchedParams, validParams];
}
