import {
  Accordion,
  BoundTextAreaField,
  Button,
  Checkbox,
  Chips,
  column,
  Css,
  GridTable,
  HbLoadingSpinner,
  Icon,
  numericColumn,
  selectColumn,
  simpleDataRows,
  SimpleHeaderAndData,
  TabsWithContent,
  useComputed,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { addBusinessDays } from "date-fns";
import { useMemo, useState } from "react";
import { chipCell, dateCell, linkHeader, priceCell } from "src/components";
import { ChangeEventReasonsBoundSelectField } from "src/components/autoPopulateSelects/ChangeEventReasonsBoundSelectField";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { StepActions } from "src/components/stepper";
import {
  ApprovalSubject_ChangeEventFragment,
  ApproverUserDetailFragment,
  CommitmentStatus,
  OpenManyChangeEventApprovalsMutationVariables,
  SaveApprovalInput,
  SaveChangeEventInput,
  SubmitChangeEventApprovalStepView_ChangeEventFragment,
  SubmitChangeEventApprovalStepView_CommitmentLikesFragment,
  useApprovalChangeEventQuery,
  useCreateChangeEventApproval_ImpactedPosTableQuery,
  useOpenManyChangeEventApprovalsMutation,
  useSubmitChangesForApprovalStep_ChangeBreakdownTableQuery,
  useToggleCommitmentReleaseMutation,
} from "src/generated/graphql-types";
import {
  ApprovalOverviewTradesList,
  OverviewForm,
  OverviewFormGap,
  OverviewFormLabel,
} from "src/routes/components/Approval/approvalAtoms";
import { ApprovalSummary } from "src/routes/components/Approval/ApprovalSummary";
import { ApproversDetail } from "src/routes/components/Approval/ApproverDetail";
import { count, pluralize, queryResult, transpose } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { WizardHeader } from "../stepper/useStepperWizard/WizardHeader";

type SubmitChangeEventApprovalStepViewProps = {
  changeEventIds: string[];
  /** For the Approva.source (not Approval.subject) to link these various Approvals together */
  sourceId?: string;
};

export function SubmitChangeEventApprovalStepView({
  changeEventIds,
  sourceId,
}: SubmitChangeEventApprovalStepViewProps) {
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const query = useApprovalChangeEventQuery({ variables: { changeEventIds } });
  const [tab, setTab] = useState("change-breakdown");
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { dueOn: new DateOnly(addBusinessDays(new Date(), 5)) },
    },
  });

  const [mutate, { loading: isMutationInFlight }] = useOpenManyChangeEventApprovalsMutation({
    variables: useComputed<OpenManyChangeEventApprovalsMutationVariables>(
      () => ({
        approvalInputs: changeEventIds.map((id) => ({
          subjectId: id,
          dueOn: formState.dueOn.value,
          sourceId,
        })),
        changeEventInputs: changeEventIds.map((id) => ({
          id,
          internalNote: formState.internalNote.value,
          reasonId: formState.reasonId.value,
        })),
      }),
      [formState],
    ),
    onCompleted: ({ saveApprovals }) => {
      triggerNotice({
        message: `${count(saveApprovals.approvals, "budget change request")} submitted for approval`,
      });
      closeModal();
    },
  });

  return queryResult(query, ({ changeEvents }) => (
    <WizardHeader
      pre="Update Project Budgets"
      header="Submit changes for approval"
      post="Once budget updates are approved, the purchase orders you select will automatically be voided and released, respectively."
      uncloseable
    >
      <div css={Css.df.maxh("60vh").mt3.oya.$}>
        <div css={Css.pl2.pb2.$}>
          <ApprovalSummary subjects={changeEvents} />
          <div css={Css.hPx(32).$} />
          <OverviewForm>
            <OverviewFormLabel>Due on</OverviewFormLabel>
            <BoundBeamDateField field={formState.dueOn} labelStyle="hidden" disabledDays={{ before: new Date() }} />
            <OverviewFormLabel>Reason code </OverviewFormLabel>
            <ChangeEventReasonsBoundSelectField field={formState.reasonId} labelStyle="hidden" />
            <OverviewFormLabel>Note</OverviewFormLabel>
            <BoundTextAreaField label="Note" field={formState.internalNote} labelStyle="hidden" />
            <span />
            <OverviewFormGap />
            <ApprovalOverviewTradesList changeEvents={changeEvents} />
            <OverviewFormLabel>Impacted CCs</OverviewFormLabel>
            <Chips
              values={changeEvents
                .flatMap((ce) => ce.lineItems)
                .map((li) => li.projectItem.item.fullCode)
                .unique()}
              compact
            />
            <OverviewFormLabel>Approval order</OverviewFormLabel>
            <ApproversAcrossApprovals changeEvents={changeEvents} />
          </OverviewForm>
        </div>
        <div css={Css.p2.$}>
          <TabsWithContent
            tabs={[
              {
                name: "Change Breakdown",
                value: "change-breakdown",
                render: () => <ChangeBreakdownTable changeEventIds={changeEventIds} />,
              },
              {
                name: "Impacted POs",
                value: "impacted-pos",
                render: () => <ImpactedPosTables changeEventIds={changeEventIds} />,
              },
            ]}
            selected={tab}
            onChange={setTab}
          />
        </div>
      </div>
      <StepActions>
        <Button size="lg" label="Submit for Approval" disabled={isMutationInFlight} onClick={() => mutate()} />
      </StepActions>
    </WizardHeader>
  ));
}

type FormValue = Pick<SaveApprovalInput, "dueOn"> & Pick<SaveChangeEventInput, "reasonId" | "internalNote">;

const formConfig: ObjectConfig<FormValue> = {
  dueOn: { type: "value" },
  internalNote: { type: "value" },
  reasonId: { type: "value" },
};

type ApproversAcrossApprovalsProps = {
  changeEvents: ApprovalSubject_ChangeEventFragment[];
};

function ApproversAcrossApprovals({ changeEvents }: ApproversAcrossApprovalsProps) {
  // The idea here is to take multiple CEs and compare Approvers across each "tier." If it's the
  // same approver then show them. After the FIRST disagreement, show "Remaining approvers vary
  // by project", even if subsequent Approvers match.
  // Notably: These aren't real `Approvers` pre-Approval-creation, but `InternalUser | TradePartnerUser`
  const users = useMemo(() => {
    const maybeUsers =
      // Regroups [[a1, a2], [b1, b2, b3]] as [[a1, b1], [a2, b2], [undefined, b3]]
      transpose(changeEvents.map((ce) => ce.predictedApprovers))
        // Check that each user.id across the tier matches and return them, otherwise undefined
        .map((approverTier) => {
          const samePersonAcrossCEs = approverTier.every((user, _, [firstUser]) => user?.id === firstUser?.id);
          return samePersonAcrossCEs ? approverTier.first : undefined;
        });
    const firstUndefinedIx = maybeUsers.findIndex((i) => i === undefined);
    return firstUndefinedIx >= 0
      ? // Slice the array then map in our "remaining users vary" symbol
        [...maybeUsers.slice(0, firstUndefinedIx), RemainingApproversVary]
      : maybeUsers;
  }, [changeEvents]);

  if (users.isEmpty) {
    return (
      <div css={Css.df.fdr.ais.jcfs.$}>
        <div css={Css.mtPx(4).pr1.$}>
          <Icon icon="infoCircle" inc={2.5} />
        </div>
        <span>
          No Approvers expected. {pluralize(changeEvents, "Change Event")} will immediately Accept, and qualifying
          Commitments will release.
        </span>
      </div>
    );
  }

  return (
    <div css={Css.df.fdc.gap1.$}>
      {users.compact().map((user, ix) => {
        if (user === RemainingApproversVary) {
          return (
            <div key="varies" css={Css.smMd.$}>
              {ix === 0 ? "All approvers vary by project" : "Remaining approvers vary by project"}
            </div>
          );
        }
        // TS thinks `approver` can still be a symbol
        user = user as ApproverUserDetailFragment;
        return <ApproversDetail key={user.id} approver={{ user }} />;
      })}
    </div>
  );
}

const RemainingApproversVary = Symbol();

type ChangeBreakdownTableProps = {
  changeEventIds: string[];
};

function ChangeBreakdownTable({ changeEventIds }: ChangeBreakdownTableProps) {
  const { data, loading } = useSubmitChangesForApprovalStep_ChangeBreakdownTableQuery({
    variables: { changeEventIds },
  });

  type Row = SimpleHeaderAndData<SubmitChangeEventApprovalStepView_ChangeEventFragment>;
  return (
    <div css={Css.w100.mwPx(824).$}>
      <GridTable<Row>
        columns={[
          column<Row>({ header: "Address", data: (ce) => ce.project.name }),
          numericColumn<Row>({
            header: "Underwritten cost",
            data: (ce) => priceCell({ valueInCents: ce.project.budgetFinancials.originalBudgetInCents }),
          }),
          numericColumn<Row>({
            header: "Current cost",
            data: (ce) => priceCell({ valueInCents: ce.originalTotalCostInCents }),
          }),
          numericColumn<Row>({
            header: "Proposed cost",
            data: (ce) => priceCell({ valueInCents: ce.proposedTotalCostInCents }),
          }),
          numericColumn<Row>({
            header: "Cost change",
            data: (ce) =>
              priceCell({
                valueInCents: ce.totalCostInCents,
                displayDirection: true,
                invertColors: true,
                zeroIs: "neutral",
              }),
          }),
        ]}
        rows={simpleDataRows(data?.changeEvents ?? [])}
        fallbackMessage={loading ? "Loading..." : "No Change Events"}
      />
    </div>
  );
}

type ImpactedPosTableProps = {
  changeEventIds: string[];
};

function ImpactedPosTables({ changeEventIds }: ImpactedPosTableProps) {
  const { data, loading } = useCreateChangeEventApproval_ImpactedPosTableQuery({ variables: { changeEventIds } });
  const [saveCommitment] = useToggleCommitmentReleaseMutation();
  const [newPOs, oldPOs] = useMemo(
    () => [
      data?.changeEvents.flatMap((ce) => ce.commitments).compact(),
      data?.changeEvents
        .flatMap((ce) => ce.commitments)
        .flatMap((co) => (co.__typename === "Commitment" && co.voidsCommitments) || [])
        .compact(),
    ],
    [data],
  );

  type Row = SimpleHeaderAndData<SubmitChangeEventApprovalStepView_CommitmentLikesFragment>;
  if (loading) return <HbLoadingSpinner />;
  return (
    <div css={Css.w100.mwPx(824).$}>
      <Accordion title="New POs to release" topBorder={false} defaultExpanded={true}>
        <div css={Css.baseMd.my2.$}></div>
        <div css={Css.xs.my2.$}>
          Deselect POs to keep them as drafts and not be released after budget updates are approved
        </div>
        <GridTable<Row>
          columns={[
            selectColumn({
              header: "",
              data: (cl) => (
                <Checkbox
                  label=""
                  selected={(cl.__typename === "Commitment" && cl.releaseWhenReady) || false}
                  disabled={cl.status !== CommitmentStatus.Draft || cl.__typename !== "Commitment"}
                  onChange={(value) => saveCommitment({ variables: { input: { id: cl.id, releaseWhenReady: value } } })}
                />
              ),
            }),
            column<Row>({
              header: "New PO #",
              data: (cl) => linkHeader(String(cl.accountingNumber), cl.blueprintUrl.path),
            }),
            column<Row>({
              header: "Address",
              data: (cl) => (cl.__typename === "Commitment" ? cl.project.buildAddress.street1 : undefined),
            }),
            column<Row>({ header: "Cost Codes", data: (cl) => chipCell(cl.items.map((cli) => cli.fullCode)) }),
            column<Row>({ header: "Trade Partner", data: (cl) => cl.tradePartner?.name }),
            numericColumn<Row>({
              header: "Proposed Cost",
              data: (cl) => priceCell({ valueInCents: cl.costChangeInCents }),
            }),
            numericColumn<Row>({
              header: "Cost Change",
              data: (cl) =>
                // (new PO) - (sum of old POs) except TS refuses to narrow the types here so we get to do a lot of type-casting
                priceCell({
                  valueInCents:
                    cl.costChangeInCents -
                      (cl.__typename === "Commitment" &&
                        (cl as any).voidsCommitments.sum(
                          (co: SubmitChangeEventApprovalStepView_CommitmentLikesFragment) => co.costChangeInCents,
                        )) || 0,
                  displayDirection: true,
                  invertColors: true,
                  zeroIs: "neutral",
                }),
            }),
          ]}
          rows={simpleDataRows(newPOs)}
          style={{ allWhite: false }}
          fallbackMessage="No impacted POs"
        />
      </Accordion>
      <Accordion title="POs that will be voided" topBorder={false} defaultExpanded={true}>
        <div css={Css.baseMd.my3.$}></div>
        <div css={Css.xs.my2.$}>
          Once budget updates are approved, the below POs will be outdated scope and pricing will be voided.
        </div>
        <GridTable<Row>
          columns={[
            column<Row>({
              header: "Void PO #",
              data: (cl) => linkHeader(String(cl.accountingNumber), cl.blueprintUrl.path),
            }),
            column<Row>({
              header: "Address",
              data: (cl) => (cl.__typename === "Commitment" ? cl.project.buildAddress.street1 : undefined),
            }),
            column<Row>({ header: "Item codes", data: (cl) => chipCell(cl.items.map((cli) => cli.fullCode)) }),
            column<Row>({ header: "Release date", data: (cl) => dateCell(cl.executionDate) }),
            column<Row>({ header: "Trade partner", data: (cl) => cl.tradePartner?.name }),
            column<Row>({ header: "Committed", data: (cl) => cl.committedInCents }),
          ]}
          rows={simpleDataRows(oldPOs ?? [])}
          style={{ allWhite: false }}
          fallbackMessage="No impacted POs"
        />
      </Accordion>
    </div>
  );
}
