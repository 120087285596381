import { GridDataRow } from "@homebound/beam";
import {
  ProgressSummaryStatus,
  SchedulePhaseDetailsFragment,
  TaskScheduleDetailsFragment,
} from "src/generated/graphql-types";
import { ScheduleExportPdfRow } from "src/routes/pdfs";
import { PhaseData, SubPhaseData } from "src/routes/projects/schedule-v2/ProjectScheduleTableV2";
import { ScheduleRow, SubPhaseRow, TaskRow } from "src/routes/projects/schedule-v2/table/utils";
import { keyBy, sortBy } from ".";

export function preProcessQueryResults(
  schedulePhases: SchedulePhaseDetailsFragment[] | undefined,
  tasks: TaskScheduleDetailsFragment[],
): Record<string, PhaseData> {
  const schedulePhasesById = keyBy<SchedulePhaseDetailsFragment, PhaseData>(
    schedulePhases ?? [],
    (sp) => sp.id,
    (sp) => ({
      kind: "phase",
      id: sp.id,
      data: {
        phaseId: sp.globalPhase.id,
        name: sp.name,
        interval: sp.interval ?? null,
        tasks: tasks.filter((task) => task?.schedulePhase?.id === sp.id),
        progress: sp.progress,
        baselineInterval: sp.baselineInterval ?? null,
        status: sp?.progress?.scheduleSubPhaseProgress?.status,
      },
      children: [] as GridDataRow<ScheduleRow>[],
      subPhasesById: keyBy<SubPhaseData>(
        sortBy(sp.scheduleSubPhases, ({ interval }) => interval?.startDate).map((ssp) => ({
          kind: "subPhase",
          id: ssp.id,
          data: {
            subPhaseId: ssp.globalSubPhase?.id,
            name: ssp.name,
            interval: ssp.interval ?? null,
            tasks: tasks.filter((task) => task?.scheduleSubPhase?.id === ssp.id),
            progress: ssp.progress,
            baselineInterval: ssp.baselineInterval ?? null,
            status: ssp?.progress?.taskProgress?.status,
          },
          children: [] as GridDataRow<ScheduleRow>[],
          initCollapsed: ssp?.progress?.taskProgress?.status === ProgressSummaryStatus.Complete,
        })),
        (ssp) => ssp.id,
        (ssp) => ssp,
      ),
      initCollapsed: sp?.progress?.scheduleSubPhaseProgress?.status === ProgressSummaryStatus.Complete,
    }),
  );

  // Insert tasks based on their schedulePhases and subPhases
  tasks
    // for each TaskRow we inject a task row into its subPhase and schedulePhase
    .forEach((task) => {
      const taskRow = { kind: "task", id: task.id, data: task } as TaskRow;
      const { schedulePhase, scheduleSubPhase } = taskRow.data;
      if (schedulePhase && schedulePhasesById[schedulePhase.id]) {
        if (scheduleSubPhase && schedulePhasesById[schedulePhase.id]) {
          schedulePhasesById[schedulePhase.id].subPhasesById[scheduleSubPhase.id]?.children.push(taskRow);
        } else {
          schedulePhasesById[schedulePhase.id].children.push(taskRow);
        }
      }
    });
  return schedulePhasesById;
}

export function postProcessChildren(schedulePhaseRow: PhaseData): GridDataRow<ScheduleRow> {
  schedulePhaseRow.children = [
    // Update the task count on any schedulePhase-level tasks that have been added
    ...schedulePhaseRow.children,
    // Get values of all subPhases rows
    ...(Object.values(schedulePhaseRow.subPhasesById) as GridDataRow<SubPhaseRow | TaskRow>[]),
  ];

  const { subPhasesById, ...others } = schedulePhaseRow;
  return { ...others } as GridDataRow<ScheduleRow>;
}

export function taskNumberMap(rows: GridDataRow<ScheduleExportPdfRow>[] | GridDataRow<ScheduleRow>[]) {
  return rows
    .flatMap((r) => [r, ...(r.children ?? []).flatMap((c1) => [c1, ...(c1.children ?? [])])])
    .filter((r) => r.kind === "task")
    .reduce(
      (acc, r, index) => {
        acc[r.id] = index + 1;
        return acc;
      },
      {} as Record<string, number>,
    );
}
