import { Accordion, Button, ButtonGroup, Css, Icon, Palette, Switch } from "@homebound/beam";
import startCase from "lodash/startCase";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { createDesignPackageNewSlotUrl } from "src/RouteUrls";
import { SearchBox } from "src/components";
import { DesignPackageLeftNavSlotFragment, useDesignPackageSlotsQuery } from "src/generated/graphql-types";
import { fail, foldEnum, queryResult } from "src/utils";
import { useLocalStorage } from "usehooks-ts";
import { useDesignPackageConfiguratorContext } from "../DesignPackageConfiguratorContext";

export function DesignPackageSlotsLeftNav() {
  const { selectedTemplateId, designPackage } = useDesignPackageConfiguratorContext();
  const query = useDesignPackageSlotsQuery({
    variables: { dpId: designPackage.id, templateId: selectedTemplateId },
    skip: !designPackage.id || !selectedTemplateId,
  });

  /** Slots may be Syncing on the backend. Detect if that's happening, and start polling until the sync is done. */
  useEffect(() => {
    query.data?.designPackage.copyInProgress
      ? // 2.5 sec intervals seems like enough
        query.startPolling(2_500)
      : query.stopPolling();
  }, [query]);

  return queryResult(query, ({ designPackage }) => <DesignPackageSlotsLeftNavView dpSlots={designPackage?.slots} />);
}

type DesignPackageSlotsLeftNavViewProps = { dpSlots: DesignPackageLeftNavSlotFragment[] | undefined };
function DesignPackageSlotsLeftNavView({ dpSlots }: DesignPackageSlotsLeftNavViewProps) {
  const [search, setSearch] = useState("");
  const [auditing, setAuditing] = useState(false);
  const filteredSlots = useAdhocFilterSlots(dpSlots ?? [], search, auditing);
  const history = useHistory();

  const [groupBy, setGroupBy] = useLocalStorage("design-package-configurator-left-nav-group-by", GroupBy.Location);
  const groupedDpSlots = useGroupedDesignPackageSlots(filteredSlots, groupBy);

  const { designPackage, setCtxState, selectedSlots } = useDesignPackageConfiguratorContext();

  // if anything is selected, scroll to it so when users hit "back" it's still in view
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    ref.current?.scrollIntoView({ behavior: "instant", block: "center" });
  }, []);

  return (
    <div css={Css.h100.pt2.bgWhite.fg1.df.fdc.gap3.oyh.$}>
      <div css={Css.df.fdc.gap3.px2.$}>
        <SearchBox
          placeholder="Search by type, cost code, location, name..."
          clearable
          onSearch={setSearch}
          fullWidth
          debounceDelayInMs={200}
        />
        <div css={Css.df.jcsb.aic.$}>
          <ButtonGroup
            buttons={[
              { text: "Room", active: groupBy === GroupBy.Location, onClick: () => setGroupBy(GroupBy.Location) },
              { text: "Cost Code", active: groupBy === GroupBy.CostCode, onClick: () => setGroupBy(GroupBy.CostCode) },
            ]}
          />
          <Switch
            label="Only Incomplete"
            selected={auditing}
            onChange={setAuditing}
            labelStyle="hidden"
            hideLabel
            tooltip="Only show slots with missing selections"
          />
        </div>
      </div>
      <div css={Css.fg1.px2.oya.$}>
        {/* No slots UX */}
        {dpSlots?.isEmpty && (
          <div css={Css.df.fdc.aic.tac.jcsb.gap4.$}>
            <Button
              variant="secondary"
              label="Click to add your first slot"
              onClick={createDesignPackageNewSlotUrl(designPackage.id)}
            />
          </div>
        )}
        {/* You filtered out all slots UX */}
        {dpSlots?.nonEmpty && filteredSlots.isEmpty && (
          <div css={Css.df.fdc.aic.tac.jcsb.gap4.$}>
            <div css={Css.lgMd.red400.$}>Filter applied. No slots to show.</div>
            <Button
              label="Click to reset filters"
              variant="secondary"
              onClick={() => {
                setCtxState({ slotIds: undefined });
                setSearch(""); // will reset search but the bar may still show text because it has its own internal state
                setAuditing(false);
              }}
            />
          </div>
        )}
        {/* Regular Slot UX */}
        {Object.entries(groupedDpSlots)
          .sortBy(([primaryGroupName]) => primaryGroupName)
          .map(([primaryGroupName, groupRecord]) => (
            <div key={primaryGroupName} css={Css.mb3.oya.fg1.$}>
              <button
                css={Css.w100.tal.py1.baseBd.onHover.bgGray100.$}
                onClick={() =>
                  setCtxState({
                    slotIds: Object.values(groupRecord)
                      .flat()
                      .map((dps) => dps.slot.id),
                  })
                }
              >
                {startCase(primaryGroupName)}
              </button>
              {Object.entries(groupRecord)
                .sortBy(([subGroupName]) => subGroupName)
                .map(([subGroupName, subgroupedDpSlots]) => (
                  <Accordion
                    key={subGroupName + subgroupedDpSlots.length}
                    title={startCase(subGroupName)}
                    titleOnClick={() => setCtxState({ slotIds: subgroupedDpSlots.map((dps) => dps.slot.id) })}
                    defaultExpanded={
                      // If filters actually filtered anything, expand
                      dpSlots?.length !== filteredSlots.length ||
                      // if we're auding, reveal the audit (specifically the icons)
                      auditing ||
                      // for whatever slot is currently selected, expand its group
                      subgroupedDpSlots.some((dps) => selectedSlots?.map((s) => s.slot.id).includes(dps.slot.id))
                    }
                    omitPadding
                    xss={Css.tal.p1.truncate.$ as any}
                    topBorder={false}
                  >
                    <div css={Css.df.fdc.gap1.$}>
                      {subgroupedDpSlots.map((dpSlot) => (
                        <div
                          key={dpSlot.slot.id}
                          ref={(dpSlot.slot.id === selectedSlots?.[0]?.slot.id && ref) || null}
                          css={{
                            ...Css.df.aic.ml1.px1.pyPx(4).cursorPointer.br4.onHover.bgBlue100.$,
                            // highlight selected slots
                            ...Css.if(selectedSlots?.some((dps) => dps.slot.id === dpSlot.slot.id) ?? false).bgBlue50.$,
                          }}
                          onClick={() => setCtxState({ slotIds: [dpSlot.slot.id] })}
                        >
                          {dpSlot.coverage >= 100 ? (
                            <Icon icon="checkCircleFilled" color={Palette.Green500} xss={{ flexShrink: 0 }} />
                          ) : dpSlot.coverage > 0 ? (
                            <div css={Css.hPx(20).wPx(20).bgBlue400.white.br100.df.jcc.aic.xl2.fs0.$}>-</div>
                          ) : (
                            <div css={Css.hPx(20).wPx(20).bgGray300.br100.fs0.$} />
                          )}
                          <div css={Css.ml3.$}>{startCase(dpSlot.slot.name || dpSlot.slot.item.name)}</div>
                        </div>
                      ))}
                      <div
                        css={Css.df.aic.jcc.pyPx(4).cursorPointer.br4.onHover.bgGray100.smMd.$}
                        onClick={() => history.push(createDesignPackageNewSlotUrl(designPackage.id))}
                      >
                        + New {startCase(primaryGroupName)}
                      </div>
                    </div>
                  </Accordion>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}

enum GroupBy {
  CostCode,
  Location,
}

/**
 * Double-nests Slots into Groups and Subgroups Record -> Record -> Slots, or
 * { appliances: { kitchen: [slot1, slot2], laundry: [slot3] }, ... }
 */
function useGroupedDesignPackageSlots(
  slots: DesignPackageLeftNavSlotFragment[],
  groupBy: GroupBy,
): Record<string, Record<string, DesignPackageLeftNavSlotFragment[]>> {
  return useMemo(() => {
    const [primaryGroup, subGroup] = foldEnum<
      GroupBy,
      [
        primaryGroup: (dpSlot: DesignPackageLeftNavSlotFragment) => PropertyKey,
        subGroup: (dpSlot: DesignPackageLeftNavSlotFragment) => PropertyKey,
      ]
    >(groupBy, {
      [GroupBy.CostCode]: [
        // Group by Cost Code
        (dpSlot) => dpSlot.slot.item.costCode?.name ?? dpSlot.slot.item.name ?? fail("No cost code"),
        // Subgroup by Location (prefer Nook, then Room, then ID--should move to the backend DesignPackageSlot identity)
        (dpSlot) => {
          const { location } = dpSlot.slot;
          return location.feature
            ? `${location.room?.name} > ${location.feature.name}`
            : (location.room?.name ?? location.level?.name ?? location.id);
        },
      ],
      [GroupBy.Location]: [
        // Group by Location (prefer Nook, then Room, then ID--should move to the backend DesignPackageSlot identity)
        (dpSlot) => {
          const { location } = dpSlot.slot;
          return location.feature
            ? `${location.room?.name} > ${location.feature.name}`
            : (location.room?.name ?? location.level?.name ?? location.id);
        },
        // Subgroup by Cost Code
        (dpSlot) => dpSlot.slot.item.costCode?.name ?? dpSlot.slot.item.name ?? fail("No cost code"),
      ],
    });

    return Object.fromEntries(
      Object.entries(slots.groupBy(primaryGroup)).map(([pKey, slots]) => [pKey, slots.groupBy(subGroup)]),
    );
  }, [slots, groupBy]);
}

function useAdhocFilterSlots(slots: DesignPackageLeftNavSlotFragment[], search: string, auditing: boolean) {
  return useMemo(
    () =>
      slots
        .filter((slot) => !auditing || slot.coverage < 100)
        .filter((slot) => {
          if (!search) return true;
          const lower = search.toLowerCase();
          return [
            slot.slot.name,
            slot.slot.item.name,
            slot.slot.item.fullCode,
            slot.slot.location.room?.name,
            slot.slot.location.feature?.name,
            slot.slot.item.costCode?.name,
          ]
            .compact()
            .some((str) => str.toLowerCase().includes(lower));
        }),
    [slots, search, auditing],
  );
}
