import { Tooltip } from "@homebound/beam";
import { Icon, IconKey } from "src/components/Icon";
import { TradePartnerTaskStatusDetail } from "src/generated/graphql-types";
import { tradePartnerStatusToIconConfig } from "../calendar/calendarTaskUtils";

export type TaskTradePartnerStatusIconProps = {
  status: Pick<TradePartnerTaskStatusDetail, "code" | "name">;
};

export function TaskTradePartnerStatusIcon({ status }: TaskTradePartnerStatusIconProps) {
  const iconData = tradePartnerStatusToIconConfig[status.code];
  return (
    <Tooltip title={status.name}>
      <Icon inc={2.5} icon={iconData.icon as IconKey} color={iconData.color} />
    </Tooltip>
  );
}
