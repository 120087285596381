import {
  BoundMultiSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  ToggleChips,
  useModal,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FileUploadProgress, UppyUploader } from "src/components";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  DocumentDetailDocumentTypeFragment,
  DocumentType,
  Maybe,
  ProjectScheduleDocumentDetailFragment,
  SaveDocumentInput,
  SavePlanTaskInput,
  Scalars,
  TaskSaveDocumentDetailFragment,
  useSavePlanTaskMutation,
  useSaveTaskDocumentsMutation,
} from "src/generated/graphql-types";
import { nonEmpty, sortBy } from "src/utils";
import { ObjectConfig, ObjectState, useFormState } from "src/utils/formState";
import { DocumentEdit } from "./DocumentEdit";
import { FormValue } from "./TaskDetailPane";

type TaskDocumentsModalProps = {
  formState: ObjectState<SavePlanTaskInput> | FormValue;
  documents: ProjectScheduleDocumentDetailFragment[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
  taskName: string;
  parentId: string;
  requiredTaskDocuments?: string[];
  onPlanTask?: boolean;
};

export function TaskDocumentsModal(props: TaskDocumentsModalProps) {
  const { formState, documents, documentTypes, taskName, parentId, requiredTaskDocuments, onPlanTask } = props;
  const [saveDocuments] = useSaveTaskDocumentsMutation();
  const [savePlanTask] = useSavePlanTaskMutation();
  const { closeModal } = useModal();
  const [documentOptions, setDocumentOptions] = useState(documents);
  const [completedDocumentUploads, setCompletedDocumentUploads] = useState<
    Array<ProjectScheduleDocumentDetailFragment>
  >([]);

  const modalForm = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { documents: formState.documents.value },
    },
  });

  async function onFinish(file: UppyFile) {
    const saveDocumentsInput: [SaveDocumentInput] = [
      {
        documentType: DocumentType.General,
        name: file.name,
        sizeInBytes: file.size,
        parentId,
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
        },
      },
    ];
    const { data } = await saveDocuments({
      refetchQueries: onPlanTask ? ["PlanTaskDetailsDocuments", "PlanTaskDocuments"] : ["TaskDetailPane"],
      variables: { input: saveDocumentsInput },
    });
    const newDocument = data?.saveDocuments.documents[0];
    if (newDocument) {
      setCompletedDocumentUploads([...completedDocumentUploads, newDocument as ProjectScheduleDocumentDetailFragment]);
      refreshDocumentOptions(newDocument);
      modalForm.documents.set(modalForm.documents.value?.concat([newDocument.id]));
    }
  }

  const onDocumentSave = async (input: SaveDocumentInput) => {
    const { data } = await saveDocuments({
      variables: { input: [input] },
    });
    const updatedDocument = data?.saveDocuments.documents[0];
    if (updatedDocument) {
      refreshDocumentOptions(updatedDocument);
    }
  };

  function refreshDocumentOptions(document: TaskSaveDocumentDetailFragment) {
    const newDocumentOptions = sortBy(document.parent.documents, ({ name }) => name);
    const filteredRequiredTaskDocuments = requiredTaskDocuments
      ? newDocumentOptions.filter((document) => {
          return requiredTaskDocuments.indexOf(document.id) < 0;
        })
      : newDocumentOptions;
    setDocumentOptions(filteredRequiredTaskDocuments);
  }

  const selectPlaceholder = (
    <div css={Css.fb3.$}>
      <SelectField
        label=""
        labelStyle="hidden"
        aria-label="selectPlaceholder"
        disabled
        onSelect={() => {}}
        options={[{ id: "", name: "Select a category" }]}
        value=""
      />
    </div>
  );

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>Link Documents</ModalHeader>
          <ModalBody>
            <p css={Css.mb3.$}>
              Link <span css={Css.fwb.$}>{taskName}</span> with documents
            </p>
            <h1 css={Css.lgSb.mb2.$}>Documents</h1>
            <ToggleChips
              values={documentOptions.filter(({ id }) => modalForm.documents.value?.includes(id))}
              getLabel={({ name }) => name}
              onRemove={(x) => modalForm.documents.set(modalForm.documents.value?.filter((id: string) => id !== x.id))}
            />
            {nonEmpty(modalForm.documents.value ?? []) && <br />}
            <div css={Css.mb2.wPx(320).$}>
              <BoundMultiSelectField
                aria-label="Link documents"
                nothingSelectedText="Select a document"
                labelStyle="hidden"
                options={documentOptions}
                field={modalForm.documents}
              />
            </div>
            <h1 css={Css.lgSb.mb2.$}>Add New Documents</h1>
            <UploaderProvider>
              <div data-testid="fileUploader" css={Css.mb2.$}>
                <UppyUploader onFinish={onFinish} />
              </div>
              {completedDocumentUploads.map((document) => {
                return (
                  <DocumentEdit
                    key={document.id}
                    document={document}
                    documentTypes={documentTypes}
                    onSave={onDocumentSave}
                  />
                );
              })}
              <FileUploadProgress hideOnComplete children={selectPlaceholder} />
            </UploaderProvider>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} />
            <Button
              label="Link Documents"
              disabled={!modalForm.dirty}
              onClick={async () => {
                formState.documents.set(modalForm.documents.value);
                // manually calling savePlanTask here as we don't want to auto save the form right now
                if (onPlanTask) {
                  const formDocuments = formState.documents.value ?? [];
                  const rtdDocuments = requiredTaskDocuments ?? [];
                  await savePlanTask({
                    variables: {
                      input: {
                        id: formState.id.value,
                        // Spread in RTD documents to ensure they are not removed
                        documents: [...formDocuments, ...rtdDocuments],
                      },
                    },
                  });
                }
                closeModal();
              }}
            />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type TaskDocumentsFormValue = {
  documents: Maybe<Array<Scalars["ID"]>>;
};

const formConfig: ObjectConfig<TaskDocumentsFormValue> = {
  documents: { type: "value" },
};
