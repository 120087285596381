import { currentUser, HomeboundUser } from "@homebound/auth-components";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { PdfRoutes } from "src/PdfRoutes";
import { PageLayout } from "src/routes/layout/PageLayout";
import { PdfLayout } from "src/routes/layout/PdfLayout";
import { Routes } from "./Routes";

export function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<HomeboundUser>();

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  async function onLoad() {
    const { data: user } = await currentUser();
    setUser(user);
    setIsAuthenticated(user !== undefined);
    setIsAuthenticating(false);
  }

  const authProps = { isAuthenticated, setIsAuthenticated, user, setUser };
  const routes = <Routes authProps={authProps} />;
  const pdfRoutes = <PdfRoutes />;

  if (isAuthenticating) {
    return <div />;
  } else if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/pdf">
          <PdfLayout>{pdfRoutes}</PdfLayout>
        </Route>
        <Route>
          <PageLayout {...{ user, setIsAuthenticated }}>{routes}</PageLayout>
        </Route>
      </Switch>
    );
  } else {
    // PdfRoutes can be accessed while either authed or unauthed-to-amplify
    return (
      <Switch>
        <Route path="/pdf">
          <PdfLayout>{pdfRoutes}</PdfLayout>
        </Route>
        {routes}
      </Switch>
    );
  }
}
