import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { OverviewTab } from "src/routes/projects/selections/cutoffs/OverviewTab";
import { ProjectCutoffHistoryTab } from "./ProjectCutoffHistoryTab";

export function ManageCutoffsDrawer({ projectId, projectStageId }: { projectId: string; projectStageId: string }) {
  const [selectedTab, setSelectedTab] = useState<TabValue>("overview");

  return (
    <SuperDrawerContent>
      <SuperDrawerHeader hideControls title="Manage Cutoffs" />
      <div>
        <TabsWithContent
          tabs={[
            {
              name: "Overview",
              value: "overview",
              render: () => <OverviewTab projectId={projectId} projectStageId={projectStageId} />,
            },
            {
              name: "History",
              value: "history",
              render: () => <ProjectCutoffHistoryTab projectId={projectId} />,
            },
          ]}
          selected={selectedTab}
          onChange={setSelectedTab}
        />
      </div>
    </SuperDrawerContent>
  );
}

type TabValue = "overview" | "history";
