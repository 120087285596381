import { Button, Css } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  SaveScheduleFlagInput,
  ScheduleFlagDetailFragment,
  ScheduleFlagReasonDetailFragment,
} from "src/generated/graphql-types";
import { ScheduleFlagForm } from "./ScheduleFlagForm";

type ScheduleFlagProps = {
  scheduleFlag: ScheduleFlagDetailFragment | null;
  reasons: ScheduleFlagReasonDetailFragment[];
  onClose: () => void;
  onSave: (os: ObjectState<Partial<SaveScheduleFlagInput>>) => void | Promise<void>;
  hideText?: boolean;
};
export function ScheduleFlag({ scheduleFlag, reasons, onClose, onSave, hideText }: ScheduleFlagProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      input: scheduleFlag,
      map: mapToFormState,
      ifUndefined: { durationInDays: 0 },
    },
  });

  return (
    <Observer>
      {() => (
        <div css={Css.df.fdc.gap1.mb1.mt1.$}>
          {hideText !== true && (
            <>
              <span css={Css.baseSb.$}>{scheduleFlag ? "Edit" : "Add"} a Flag</span>
              <span css={Css.tiny.$}>
                Only zero day delays can be created without schedule changes. For delays longer than 0 days, update the
                task dates above.
              </span>
            </>
          )}
          <ScheduleFlagForm formState={formState} reasons={reasons} onClose={onClose} />
          <div css={Css.df.fdrr.$}>
            <Button
              label={`Confirm ${scheduleFlag ? "Edit" : "Adding"} Flag`}
              disabled={!formState.canSave()}
              variant="tertiary"
              onClick={() => onSave(formState)}
            />
          </div>
        </div>
      )}
    </Observer>
  );
}

export const formConfig: ObjectConfig<Partial<SaveScheduleFlagInput>> = {
  id: { type: "value" },
  reasonId: { type: "value", rules: [required] },
  durationInDays: { type: "value", rules: [required] },
  description: { type: "value" },
  scheduleTaskId: { type: "value" },
};

export function mapToFormState(flag: ScheduleFlagDetailFragment): Partial<SaveScheduleFlagInput> {
  const { id, durationInDays, description, reason } = flag;
  return {
    id,
    reasonId: reason?.id,
    durationInDays,
    description,
  };
}
