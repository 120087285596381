import { Button, Css, GridTableApi, ModalBody, ModalFooter, ModalHeader, useModal, useToast } from "@homebound/beam";
import { useDeleteProjectItemsMutation } from "src/generated/graphql-types";
import { ProjectItemStore } from "src/routes/projects/models/ProjectItemStore";

type DeleteItemsModalProps = {
  selectedProjectItemIds: string[];
  store: ProjectItemStore;
  api: GridTableApi<any>;
};

export function DeleteItemsModal(props: DeleteItemsModalProps) {
  const { selectedProjectItemIds, store, api } = props;
  const [deleteProjectItems] = useDeleteProjectItemsMutation();
  const { closeModal } = useModal();
  const { showToast } = useToast();

  return (
    <>
      <ModalHeader>Delete Items</ModalHeader>
      <ModalBody>
        <p css={Css.smMd.mb2.$}>
          {selectedProjectItemIds.length} {selectedProjectItemIds.length === 1 ? "item" : "items"} selected
        </p>
        <p>Are you sure you want to delete these items? This action cannot be undone.</p>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Delete"
          onClick={async () => {
            await deleteProjectItems({
              variables: { input: { id: selectedProjectItemIds } },
              // cache.evict is removing ProjectItems from cache but is not re-rendering UI when immediately deleting duplicated items so using refetchQueries
              // update: (cache) => selectedProjectItemIds.forEach((id) => cache.evict({ id: `ProjectItem:${id}` })),
              refetchQueries: ["SpecsAndSelectionItems"],
            });
            store.removeProjectItems(selectedProjectItemIds);
            api.deleteRows(selectedProjectItemIds);
            showToast({
              type: "success",
              message: `${selectedProjectItemIds.length > 1 ? "Items have" : "Item has"} been deleted`,
            });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
