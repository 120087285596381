import { Css } from "@homebound/beam";
import { useMemo } from "react";
import TreeSelect, { DropdownTreeSelectProps } from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

export function DropdownTreeSelect(props: DropdownTreeSelectProps) {
  // Memoizing Tree Select component as the 3rd party library handles its own internal state. We intentionally don't want it to re-render
  // See this Github issue for more detail: https://github.com/dowjones/react-dropdown-tree-select/issues/313
  const treeSelect = useMemo(
    () => {
      return <TreeSelect {...props} />;
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <div
      data-testid="dropdownTreeSelect"
      css={
        Css.addIn(".search::placeholder", Css.gray700.$)
          .addIn(".tag-item .search", Css.bn.$)
          .addIn(".tag", Css.tal.bgGray300.gray900.br16.pxPx(10).pyPx(2).$)
          .addIn(".tag-remove", Css.gray900.base.ml1.$)
          .addIn(".react-dropdown-tree-select .dropdown .dropdown-trigger", Css.br4.ba.bcGray300.df.jcsb.w100.$)
          .addIn(".toggle", Css.pl2.important!.$)
          .addIn(".dropdown-trigger.arrow.bottom", Css.w100.$)
          .addIn(".dropdown", Css.w100.$)
          .addIn(
            ".toggle.collapsed:after",
            Css.add({
              backgroundImage:
                "url(\"data:image/svg+xml,%3Csvg width='6' height='12' viewBox='0 0 6 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.43099e-07 -9.53674e-07L6 6L0 12L1.43099e-07 -9.53674e-07Z' fill='%23242424'/%3E%3C/svg%3E \")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              content: '" "',
            }).$,
          )
          .addIn(
            ".toggle.expanded:after",
            Css.add({
              backgroundImage:
                "url(\"data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0L6 6L0 0H12Z' fill='%23242424'/%3E%3C/svg%3E%0A\")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              content: '" "',
              fontSize: "24px",
            }).$,
          )
          .addIn(
            ".react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after",
            Css.add({
              content:
                "url(\"data:image/svg+xml,%3Csvg aria-hidden='true' width='24' height='24' viewBox='0 0 24 24' fill='%23646464' xmlns='http://www.w3.org/2000/svg' data-icon='chevronDown' data-testid='chevronDown' id='chevronDown' class='css-yty67j'%3E%3Cpath d='M16.293 9.29297L12 13.586L7.70697 9.29297L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29297Z'%3E%3C/path%3E%3C/svg%3E\")",
            }).$,
          )
          .addIn(
            ".react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after",
            Css.add({
              content:
                "url(\"data:image/svg+xml,%3Csvg aria-hidden='true' width='24' height='24' viewBox='0 0 24 24' fill='%23646464' xmlns='http://www.w3.org/2000/svg' data-icon='chevronUp' data-testid='chevronUp' id='chevronUp' class='css-yty67j'%3E%3Cpath d='M6.29297 13.2929L7.70697 14.7069L12 10.4139L16.293 14.7069L17.707 13.2929L12 7.58594L6.29297 13.2929Z'%3E%3C/path%3E%3C/svg%3E\")",
            }).$,
          )
          .addIn(".node", Css.tal.$)
          .addIn(".node>label", Css.ml1.$)
          .addIn(".react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after", Css.prPx(4).mr0.asc.$)
          .addIn(".react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after", Css.prPx(4).asc.mr0.$).$
      }
    >
      {treeSelect}
    </div>
  );
}
