import {
  Accordion,
  AccordionList,
  AccordionProps,
  Css,
  LoadingSkeleton,
  ScrollShadows,
  useTestIds,
} from "@homebound/beam";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import {
  ProjectsWidgetFinancials_FinancialsFragment,
  ProjectsWidgetFinancials_ProjectFragment,
  ProjectsWidgetFinancialsCohortFragment,
  ProjectsWidgetFinancialsTableQuery,
  ProjectsWidgetFinancialsTabQuery,
  Stage,
  useProjectsWidgetFinancialsTableQuery,
  useProjectsWidgetFinancialsTabQuery,
} from "src/generated/graphql-types";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { groupBy, queriesResult, queryResult } from "src/utils";

export function ProjectsWidgetFinancials() {
  const { filter } = useDashboardFilterContext();
  const query = useProjectsWidgetFinancialsTabQuery({
    variables: { teamMembers: filter.internalUser, projects: filter.project },
    skip: !filter.internalUser,
  });
  return queryResult(query, {
    data: (data) => {
      return <ProjectsWidgetFinancialTab data={data} />;
    },
    loading: () => <LoadingSkeleton rows={6} columns={2} />,
  });
}

type CohortWithProjects = {
  cohort: ProjectsWidgetFinancialsCohortFragment;
  projects: ProjectsWidgetFinancials_ProjectFragment[];
};

type FinancialsFragmentWithoutTypename = Omit<ProjectsWidgetFinancials_FinancialsFragment, "__typename"> | undefined;

type FinancialsField = FinancialsFragmentWithoutTypename & {
  [key: string]: number | undefined;
};

function ProjectsWidgetFinancialTab({ data }: { data: ProjectsWidgetFinancialsTabQuery }) {
  const { projects } = data;
  const tids = useTestIds({}, "projectsWidgetFinancialTab");
  const projectsWithScheduleTasksAndCohorts = projects.filter((p) => p.cohort);
  // grabbing projects without cohorts to display at the top level
  const projectsWithScheduleTasksWithoutCohorts = projects.filter((p) => !p.cohort);
  // group cohorts by development name
  const cohortsByDevelopment = useMemo(
    () => groupBy(projectsWithScheduleTasksAndCohorts, ({ cohort }) => cohort?.development?.name ?? ""),
    [projectsWithScheduleTasksAndCohorts],
  );

  const projectRows = useMemo(
    () => {
      return projectsWithScheduleTasksWithoutCohorts.map((project) => createProjectFinancialsRow(project));
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projects],
  );

  const financialRowData: Record<string, CohortWithProjects[]> = Object.entries(cohortsByDevelopment).reduce(
    (acc, [development, cohorts]) => {
      // group by cohort name
      const cohortsByCohortName = groupBy(cohorts, ({ cohort }) => cohort?.id ?? "");

      // once we get the cohorts, create an array of all projects associated to the cohort
      const nestedCohorts = Object.entries(cohortsByCohortName).map(([key, value]) => {
        const cohort = value[0].cohort;
        const projects = value.map((project) => project);
        return {
          cohort,
          projects,
        };
      });
      return { ...acc, [development]: nestedCohorts };
    },
    {},
  );

  function createNestedAccordionRows(financialRowData: Record<string, CohortWithProjects[]>) {
    const nestedRows = Object.entries(financialRowData).map(([developmentName, cohorts]) => {
      const accordions: AccordionProps[] = [
        {
          title: <div css={Css.gray800.baseBd.$}>{developmentName}</div>,
          topBorder: false,
          bottomBorder: false,
          children: cohorts.map((cohort) => (
            <Accordion
              key={cohort.cohort.name}
              title={<div css={Css.gray800.baseBd.$}>{cohort.cohort.name}</div>}
              topBorder={false}
              bottomBorder={false}
              children={cohort.projects.map((p) => createProjectFinancialsRow(p))}
              omitPadding
              {...tids.cohort}
            />
          )),
        },
      ];
      return <AccordionList accordions={accordions} key={developmentName} {...tids.development} />;
    });
    return nestedRows;
  }

  function createProjectFinancialsRow(project: ProjectsWidgetFinancials_ProjectFragment) {
    return (
      <Accordion
        key={project.name}
        title={
          <div css={Css.blue700.smSb.$}>
            <Link to={createProjectScheduleUrl(project.id)} target="_blank">
              {project.name}
            </Link>
          </div>
        }
        topBorder={false}
        bottomBorder={false}
        children={<ProjectsWidgetFinancialsTableData project={project} />}
        omitPadding
        {...tids.project}
      />
    );
  }
  // combining both project rows with nested accordion rows
  const allFinancialRows = [...projectRows, ...createNestedAccordionRows(financialRowData)];
  return <ScrollShadows xss={Css.h100.oys.$}>{allFinancialRows}</ScrollShadows>;
}

function ProjectsWidgetFinancialsTableData({ project }: { project: ProjectsWidgetFinancials_ProjectFragment }) {
  // grab both the preCon anc construction project ids to query for table financials
  const preConStageProjectId = project.stages.find((s) => s.stage.code === Stage.PreConExecution)?.id;
  const constructionStageProjectId = project.stages.find((s) => s.stage.code === Stage.Construction)?.id;
  // query for preCon stage financials
  const preConStageQuery = useProjectsWidgetFinancialsTableQuery({
    variables: { projectStageId: preConStageProjectId ?? "" },
    skip: !preConStageProjectId,
  });
  // query for construction stage financials
  const conStageQuery = useProjectsWidgetFinancialsTableQuery({
    variables: { projectStageId: constructionStageProjectId ?? "" },
    skip: !constructionStageProjectId,
  });
  return queriesResult([preConStageQuery, conStageQuery], {
    data: (preConData, conData) => {
      return <ProjectsWidgetFinancialsTable preConFinancials={preConData} constructionFinancials={conData} />;
    },
    loading: () => <LoadingSkeleton rows={10} columns={2} />,
  });
}

type ProjectWidgetFinancialsTableProps = {
  preConFinancials: ProjectsWidgetFinancialsTableQuery;
  constructionFinancials: ProjectsWidgetFinancialsTableQuery;
};

function ProjectsWidgetFinancialsTable({
  preConFinancials,
  constructionFinancials,
}: ProjectWidgetFinancialsTableProps) {
  const { __typename, ...preConFinancialValues } = preConFinancials.projectStage.hocFinancials;
  const { __typename: __typename2, ...constructionValues } = constructionFinancials.projectStage.hocFinancials;
  const financialValues = calculateFinancialValues(preConFinancialValues, constructionValues);

  return (
    <table data-testid={"projectsWidgetFinancialTable"} css={Css.mlPx(-8).$}>
      <tbody>
        <tr>
          <th></th>
          <th css={Css.p1.xsMd.tal.$}>Original</th>
          <th css={Css.p1.xsMd.tal.$}>Revised</th>
          <th css={Css.p1.xsMd.tal.$}>Delta</th>
          <th></th>
        </tr>
        <tr>
          {/* row*/}
          <td css={Css.p1.xs.$}>Contract:</td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.originalContractValue} />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.currentContractValue} />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.currentContractValue - financialValues.originalContractValue} />
          </td>
        </tr>
        <tr>
          <td css={Css.p1.xs.$}>Budget:</td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.originalBudgetValue} dropZero />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.currentBudgetValue} />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.currentBudgetValue - financialValues.originalBudgetValue} />
          </td>
        </tr>
        <tr>
          <td css={Css.p1.xs.$}>Margin:</td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.originalMarginValue} dropZero />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.currentMarginValue} />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.currentMarginValue - financialValues.originalMarginValue} />
          </td>
        </tr>
        <tr>
          <td></td>
          <td css={Css.p1.xsMd.bt.bcGray300.$}>Collected from HO</td>
          <td css={Css.p1.xsMd.bt.bcGray300.$}>Paid to Trades</td>
          <td css={Css.p1.xsMd.bt.bcGray300.$}>Delta</td>
        </tr>
        <tr>
          <td></td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.collectedFromHomeowner} />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.paidToTrades} />
          </td>
          <td css={Css.p1.xsMd.gray600.$}>
            <Price valueInCents={financialValues.netCash} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

// helper function to calculate values combining precon + construction
function getSummedvaluesFn({
  preConFinancials,
  constructionFinancials,
}: {
  preConFinancials: FinancialsField | undefined;
  constructionFinancials: FinancialsField | undefined;
}) {
  return function (valueKey: string) {
    const preConValue = preConFinancials && preConFinancials[valueKey];
    const constructionValue = constructionFinancials && constructionFinancials[valueKey];
    return [preConValue, constructionValue].sum();
  };
}

// helper function to turn all financials tab data into an object for easy access
function calculateFinancialValues(
  preConFinancials: FinancialsField | undefined,
  constructionFinancials: FinancialsField | undefined,
) {
  const getSummedValues = getSummedvaluesFn({ preConFinancials, constructionFinancials });
  return {
    originalContractValue: getSummedValues("originalContractValueInCents"),
    originalBudgetValue: getSummedValues("originalBudgetedCostInCents"),
    originalMarginValue: getSummedValues("originalGrossMarginBasisPoints"),
    currentContractValue: getSummedValues("valueInCents"),
    currentBudgetValue: getSummedValues("costInCents"),
    currentMarginValue: getSummedValues("grossMarginBasisPoints"),
    paidToTrades: getSummedValues("paidToTradesInCents"),
    collectedFromHomeowner: getSummedValues("paidFromHomeownerInCents"),
    netCash: getSummedValues("paidNetInCents"),
  };
}
