export enum PersonalDashboardRoutes {
  ACTIONS = "actions",
  UPCOMING = "upcoming",
  TO_DOS = "to-dos",
  APPROVALS = "approvals",
  ACTIVITY = "activity",
}

export enum ActionItemTypes {
  STALE_TASKS = "staleTasks",
  APPROVALS = "approvals",
  SCHEDULING = "scheduling",
  DEFERRED_BILLS = "deferredBills",
  BILLS_PENDING_REVIEW = "billsPendingReview",
  DELAYS = "delays",
}

export enum UpcomingDueIn {
  STALE = "stale",
  TOMORROW = "tomorrow",
  ONE_WEEK = "1-week",
  THREE_WEEKS = "3-weeks",
  ONE_MONTH = "1-month",
  OVER_ONE_MONTH = "over-1-month",
}

export enum UpcomingView {
  List = "list",
  Calendar = "calendar",
}

export enum SummaryTaskTypes {
  STALE = "stale",
  ASSIGNED = "assigned",
}
