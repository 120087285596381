import {
  Button,
  ButtonMenu,
  Chip,
  Css,
  FilterDefs,
  Filters,
  FullBleed,
  GridTable,
  LoadingSkeleton,
  multiFilter,
  Palette,
  ScrollableContent,
  StaticField,
  useComputed,
  useGridTableApi,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { Location } from "history";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Price, SearchBox } from "src/components";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  ItemTemplateStatus,
  ItivOrderField,
  Order,
  useActivateTemplateVersionsReviewStepMutation,
  useSaveScopeChangeReasonReviewStepMutation,
  useScopeTemplateItemsReviewStepTableQuery,
  useScopeTemplatesReviewStepQuery,
  useScopeTemplatesReviewStepTableQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentScopeTemplatesUrl } from "src/RouteUrls";
import { Header } from "./Header";
import {
  assignItivsToTemplate,
  createReviewStepColumns,
  createReviewStepRows,
  handleBulkScopeChangeReasons,
  ItemTemplateFilterFE,
  mapDataToForm,
  mapToHeaderData,
  mapToMetadata,
  relevantScopeChangeTypes,
  RenderMode,
  reviewStepFormConfig,
  ReviewStepRow,
  ReviewTemplateChangesTableProps,
} from "./utils";

export function ReviewStep() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const tid = useTestIds({}, "ReviewStep");
  const { triggerNotice } = useSnackbar();
  const { nextStep } = useStepperContext();
  const history = useHistory();
  const { state } = useLocation<{ itemTemplatesIds?: string[]; mode: RenderMode; from?: Location<unknown> }>();
  const { id, mode, from } = useMemo(
    () => ({ id: state?.itemTemplatesIds ?? [], mode: state?.mode ?? RenderMode.edit, from: state?.from }),
    [state],
  );
  const [saveScopeChangeReason] = useSaveScopeChangeReasonReviewStepMutation();
  const [activateTemplates] = useActivateTemplateVersionsReviewStepMutation();
  // Base header and metadata query
  const { data } = useScopeTemplatesReviewStepQuery({
    variables: {
      filter: { id, status: [mode === RenderMode.edit ? ItemTemplateStatus.Draft : ItemTemplateStatus.Active] },
      devFilter: { id: [developmentId] },
      itivFilter: { template: id, scopeChangeType: relevantScopeChangeTypes },
    },
  });
  // Table metadata for filters
  const { costCodes, readyPlans, templates, canActivate } = useMemo(() => mapToMetadata(id, data), [id, data]);
  // Header calculations
  const { oldCost, newCost, costChange, costChangePositive } = useMemo(() => mapToHeaderData(data), [data]);
  // gets change reasons options
  const changeReasons = useMemo(() => data?.scopeChangeReasons ?? [], [data]);
  const formState = useFormState({ config: reviewStepFormConfig, init: { onlyOnce: true, input: { items: [] } } });

  const handleSave = async () => {
    if (mode !== RenderMode.edit) return;
    const data = formState.items.changedValue.map(({ scopeChangeReason, ...rest }) => ({
      ...rest,
      ...(scopeChangeReason ? { scopeChangeReasonId: scopeChangeReason } : {}),
    }));
    const [itPayload, itivPayload] = data
      // only get items that have more fields than just the id
      .filter(({ id, ...other }) => Object.keys(other).length > 0)
      // split based on the id tag to get the it and itiv records
      .partition(({ id }) => id.indexOf("it:") === 0);
    const response = await saveScopeChangeReason({
      variables: { itInput: { itemTemplates: itPayload }, itivInput: { itemTemplateItemVersions: itivPayload } },
    });
    const templateIdsToActivate = response.data?.saveItemTemplates.itemTemplates ?? [];
    templateIdsToActivate.nonEmpty && triggerNotice({ message: "Templates changes saved", icon: "success" });
  };

  const saveAndExit = async () => {
    await handleSave();
    exit();
  };

  const exit = () =>
    history.push(from ? `${from.pathname}${from.search}` : createDevelopmentScopeTemplatesUrl(developmentId));

  const publishAndContinue = async () => {
    if (mode === RenderMode.edit) {
      await handleSave();
      const response = await activateTemplates({ variables: { input: { templateId: id } } });
      const { activatedTemplates } = response.data?.activateItemTemplateVersions ?? {
        activatedTemplates: [],
      };
      // all templates activated
      if (id.every((expectedId) => activatedTemplates.some(({ id }) => id === expectedId))) {
        triggerNotice({ message: `Templates successfully published`, icon: "success" });
        return nextStep();
      }

      // not all templates activated, but none got deleted
      triggerNotice({ message: `Some templates can't be activated`, icon: "alert" });

      if (activatedTemplates.nonEmpty) {
        return nextStep();
      }
      return;
    }
    // for read mode only
    nextStep();
  };

  return (
    <div>
      <Header
        preHeader={mode === RenderMode.edit ? "Create new template versions" : "Apply new template versions"}
        header={`Review${mode === RenderMode.edit ? " & Publish" : ""}`}
        postHeader={
          mode === RenderMode.edit
            ? "Listed below are the templates you’re creating new versions for. Make sure everything looks correct before publishing."
            : "Listed below are the templates you want to apply to projects. Make sure everything looks correct before applying them, no changes can be made at this stage"
        }
        right={
          <div css={Css.df.aic.gap6.$}>
            <StaticField label="Old Cost">
              <Price valueInCents={oldCost} />
            </StaticField>
            <StaticField label="New Cost">
              <Price valueInCents={newCost} />
            </StaticField>
            <StaticField label="Change">
              <Chip text={costChange} type={costChangePositive ? "success" : "warning"} />
            </StaticField>
          </div>
        }
      />
      <FullBleed>
        <div css={Css.bgGray100.py3.$}>
          <ReviewTemplateChangesTable
            id={templates}
            mode={mode}
            scopeChangeReasons={changeReasons}
            costCodes={costCodes}
            readyPlans={readyPlans}
            formState={formState}
          />
        </div>
      </FullBleed>
      <StepActions>
        <>
          <Button
            size="lg"
            variant="secondary"
            label={`${mode === RenderMode.edit ? "Save & " : ""}Exit`}
            onClick={saveAndExit}
            {...tid.exit}
          />
          <Button
            size="lg"
            variant="primary"
            label={`${mode === RenderMode.edit ? "Publish & " : ""}Continue`}
            onClick={publishAndContinue}
            disabled={
              mode === RenderMode.edit && !canActivate && "There are no changes in some of the reviewed templates"
            }
          />
        </>
      </StepActions>
    </div>
  );
}

function ReviewTemplateChangesTable(props: ReviewTemplateChangesTableProps) {
  const { id, scopeChangeReasons, costCodes, readyPlans, formState, mode } = props;
  const [wholeHouseTemplateFilters, setWholeHouseTemplateFilters] = useState<ItemTemplateFilterFE>(
    {} as ItemTemplateFilterFE,
  );
  const [search, setSearch] = useState<string | undefined>();
  const tableApi = useGridTableApi<ReviewStepRow>();
  const selectedRows = useComputed(() => tableApi.getSelectedRowIds(), [tableApi]);
  const changeReasonOptions = useMemo(
    () => handleBulkScopeChangeReasons({ scopeChangeReasons, selectedRows, formState, tableApi }),
    [scopeChangeReasons, selectedRows, formState, tableApi],
  );
  const filterDefs = useMemo(
    (): FilterDefs<ItemTemplateFilterFE> => ({
      costCode: multiFilter({
        options: costCodes,
        label: "Cost code",
        getOptionLabel: (c) => c.name,
        getOptionValue: (c) => c.id,
      }),
      readyPlan: multiFilter({
        options: readyPlans,
        label: "Plan",
        getOptionLabel: (c) => c.name,
        getOptionValue: (c) => c.id,
      }),
    }),
    [costCodes, readyPlans],
  );

  const { data, loading } = useScopeTemplatesReviewStepTableQuery({
    variables: {
      filter: {
        id,
        status: [mode === RenderMode.edit ? ItemTemplateStatus.Draft : ItemTemplateStatus.Active],
        readyPlan: wholeHouseTemplateFilters.readyPlan,
      },
    },
    skip: !id.length,
  });

  const relevantTemplates: string[] = useMemo(() => {
    if (!data?.itemTemplates || loading) return id;
    return data.itemTemplates.map(({ id }) => id);
  }, [id, data, loading]);

  const { data: itivData, loading: loadingItivs } = useScopeTemplateItemsReviewStepTableQuery({
    variables: {
      filter: {
        template: relevantTemplates,
        scopeChangeType: relevantScopeChangeTypes,
        costCode: wholeHouseTemplateFilters.costCode,
      },
      order: [
        {
          field: ItivOrderField.Item,
          direction: Order.Asc,
        },
      ],
    },
  });

  const itivs = itivData?.itemTemplateItemVersionsPage.items;

  const mappedData = useMemo(() => {
    if (!data || !itivs) return [];
    return assignItivsToTemplate(data, itivs);
  }, [data, itivs]);

  useEffect(() => {
    if (!loading && mappedData.length) {
      formState.set(mapDataToForm(mappedData), { refreshing: true } as any);
    }
  }, [loading, formState, mappedData]);

  const columns = useMemo(() => createReviewStepColumns(scopeChangeReasons, mode), [scopeChangeReasons, mode]);
  const formRows = useComputed(() => formState.items.rows, [formState]);
  const rows = useMemo(
    () => createReviewStepRows(mappedData, wholeHouseTemplateFilters, formRows),
    [mappedData, wholeHouseTemplateFilters, formRows],
  );

  const showSkeleton = loading || loadingItivs || (!loadingItivs && !itivs);

  return (
    <ScrollableContent bgColor={Palette.Gray100} virtualized>
      <div css={Css.p4.h("calc(100% - 105px)").br8.w100.ml("-24px").bgWhite.$}>
        <TableActions>
          <Filters<ItemTemplateFilterFE>
            filter={wholeHouseTemplateFilters}
            filterDefs={filterDefs}
            onChange={setWholeHouseTemplateFilters}
          />
          <div css={Css.df.gap1.$}>
            <SearchBox onSearch={setSearch} />
            <ButtonMenu
              items={changeReasonOptions}
              disabled={selectedRows.isEmpty}
              trigger={{ label: "Add Reason Code" }}
            />
          </div>
        </TableActions>
        {showSkeleton ? (
          <>
            <LoadingSkeleton rows={1} columns={1} />
            <LoadingSkeleton rows={5} columns={6} />
          </>
        ) : (
          <GridTable
            as="virtual"
            api={tableApi}
            columns={columns}
            rows={rows}
            style={{ grouped: true }}
            stickyHeader
            filter={search}
            sorting={{ on: "client" }}
          />
        )}
      </div>
    </ScrollableContent>
  );
}
