import { TagType } from "@homebound/beam";
import { ItemTemplateStatus, ReadyPlanStatus } from "src/generated/graphql-types";
import { assertNever } from "./utils";

export const readyPlanStatusToTagType: Record<ReadyPlanStatus, TagType> = {
  [ReadyPlanStatus.Draft]: "info",
  [ReadyPlanStatus.Active]: "success",
  [ReadyPlanStatus.Archived]: "warning",
};

export function itemTemplateStatusToTagType(status: ItemTemplateStatus): TagType {
  switch (status) {
    case ItemTemplateStatus.Archived:
      return "warning";
    case ItemTemplateStatus.SystemDraft:
    case ItemTemplateStatus.Active:
      return "success";
    case ItemTemplateStatus.Draft:
      return "info";
    default:
      return assertNever(status);
  }
}
