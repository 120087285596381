import { Button, Css } from "@homebound/beam";
import { useParams } from "react-router";
import { baseDownloadUrl } from "src/context";
import { ReadyPlanDetailFragment, useReadyPlansListQuery } from "src/generated/graphql-types";
import { EmptyState } from "src/routes/components/EmptyState";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { openNewTab } from "src/utils/window";
import { CreateReadyPlanModalButton } from "./components/CreateReadyPlanModalButton";
import { EmptyPlanAndOptionImage } from "./components/EmptyStateSvgs";
import { ReadyPlanCard } from "./components/ReadyPlanCard";

export function PlanAndOptionsPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const query = useReadyPlansListQuery({ variables: { development: developmentId } });

  return queryResult(query, {
    data: ({ readyPlans }) => {
      return <PlanAndOptionsView developmentId={developmentId} readyPlans={readyPlans} />;
    },
  });
}

type PlanAndOptionsViewProps = {
  readyPlans: ReadyPlanDetailFragment[];
  developmentId: string;
};

function PlanAndOptionsView({ readyPlans, developmentId }: PlanAndOptionsViewProps) {
  const { length } = readyPlans;
  // Encode our URL params for creating a CSV for all ReadyPlan and Options within the current developmentId
  const urlParams = encodeURIComponent(`{"development":["${developmentId}"],"active":true,"isParentOption":true}`);
  return (
    <>
      <PageHeader
        title="Plans & Options"
        right={
          length !== 0 && (
            <>
              <Button
                label="Export"
                variant="secondary"
                onClick={async () =>
                  openNewTab(`${baseDownloadUrl()}/csv?type=readyPlansAndOptions&filter=${urlParams}`)
                }
              />
              <CreateReadyPlanModalButton developmentId={developmentId} readyPlans={readyPlans} />
            </>
          )
        }
      />
      {length === 0 ? (
        <EmptyState
          message="There are no ReadyPlans for this development yet"
          svg={<EmptyPlanAndOptionImage />}
          button={<CreateReadyPlanModalButton developmentId={developmentId} readyPlans={readyPlans} />}
        />
      ) : (
        <div data-testid="readyPlanList" css={Css.df.add("flexWrap", "wrap").gap2.pb2.$}>
          {readyPlans.map((readyPlan) => (
            <ReadyPlanCard key={readyPlan.id} readyPlan={readyPlan} />
          ))}
        </div>
      )}
    </>
  );
}
