import { isNumber } from "src/utils";

export type TradeCostData = {
  [tradeId: string]: number | undefined;
};

/** Combines two `TradeCostData` (pre-derived values, i.e. we ignore revised and difference). */
export function sumTradeCostData(tradeIds: string[], a: TradeCostData, b: TradeCostData): TradeCostData {
  return Object.fromEntries(
    tradeIds.map((key) => {
      const keepUndefined = !isNumber(a[key]) && !isNumber(b[key]);
      if (keepUndefined) {
        return [key, undefined];
      }
      return [key, (a[key] || 0) + (b[key] || 0)];
    }),
  );
}
