import {
  column,
  Css,
  dateColumn,
  defaultPage,
  GridDataRow,
  GridTable,
  IconButton,
  numericColumn,
  Pagination,
  Palette,
  simpleHeader,
} from "@homebound/beam";
import { useState } from "react";
import { priceCell, tagCell } from "src/components";
import {
  BidItemCostHistory_BidContractLineItemsFragment,
  BidItemSuperDrawer_BidItemFragment,
  useBidItemCostHistoryQuery,
} from "src/generated/graphql-types";
import { getTagTypeFromStatus } from "src/routes/developments/components/DevelopmentContractHeader";
import { createDevelopmentContractOverviewUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export type BidItemCostHistoryTabProps = {
  bidItem: BidItemSuperDrawer_BidItemFragment;
};

export function BidItemCostHistoryTab({ bidItem }: BidItemCostHistoryTabProps) {
  const [page, setPage] = useState(defaultPage);
  const query = useBidItemCostHistoryQuery({
    variables: { filter: { bidItemIds: [bidItem.id] }, page },
  });

  return (
    <div>
      <div css={Css.df.jcsb.$}>
        <div data-testid="bidItemName" css={Css.xlSb.mb2.$}>
          {bidItem.name}
        </div>
      </div>
      {queryResult(query, {
        data: (data) => {
          const bclis = data.bidContractLineItemsPage.entities;
          const totalCount = data.bidContractLineItemsPage.pageInfo.totalCount;
          return (
            <>
              <GridTable columns={createColumns()} rows={createRows(bclis)} />
              <Pagination page={[page, setPage]} totalCount={totalCount} />
            </>
          );
        },
      })}
    </div>
  );
}

type Row =
  | { kind: "header"; data: undefined }
  | { kind: "lineItem"; id: string; data: BidItemCostHistory_BidContractLineItemsFragment };

function createRows(bclis: BidItemCostHistory_BidContractLineItemsFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...bclis.map((bcli) => ({
      kind: "lineItem" as const,
      id: bcli.id,
      data: bcli,
    })),
  ];
}

function createColumns() {
  return [
    column<Row>({
      header: "Trade Partner Contract",
      lineItem: (bcli) => (
        <div css={Css.df.$}>
          <div css={Css.df.aic.blue800.$}>{bcli.revision.bidContract.displayName}</div>
          <IconButton
            color={Palette.Blue800}
            inc={2}
            onClick={createDevelopmentContractOverviewUrl(bcli.revision.bidContract.parent.id, bcli.revision.id)}
            openInNew
            icon="linkExternal"
          />
        </div>
      ),
      w: 1.5,
    }),
    column<Row>({
      header: "Development",
      lineItem: (bcli) => bcli.revision.bidContract.parent.name,
      w: 1.0,
    }),
    numericColumn<Row>({
      header: "Cost",
      lineItem: (bcli) => priceCell({ valueInCents: bcli.totalCostInCents }),
    }),
    dateColumn<Row>({
      header: "Effective Date",
      lineItem: (bcli) => bcli.revision.startDate.toLocaleDateString(),
    }),
    column<Row>({
      header: "Status",
      lineItem: (bcli) => tagCell(getTagTypeFromStatus(bcli.revision.status.code), bcli.revision.status.name),
    }),
  ];
}
