import createCache, { EmotionCache } from "@emotion/cache";

/** Returns an emotion cache that doesn't spam `console.log` with warnings about `first-child`. */
export function createQuietEmotionCache(): EmotionCache {
  const cache = createCache({ key: "css" });
  // Disable first-child SSR warnings that are not applicable to our non-SSR/SPA app:
  // https://github.com/emotion-js/emotion/issues/1105#issuecomment-1058225197
  cache.compat = true;
  return cache;
}
