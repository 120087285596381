import { Css, FullBleed, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";

type HeaderProps = {
  preHeader: string;
  header: string | ReactNode;
  postHeader: string;
  right?: ReactNode;
};

export function Header({ header, preHeader, postHeader, right }: HeaderProps) {
  const tid = useTestIds({});
  return (
    <FullBleed>
      <div css={Css.df.aic.pb4.bgWhite.$}>
        <div css={Css.ml0.$}>
          <div {...tid.preHeader} css={Css.smMd.gray700.pt4.add("textTransform", "uppercase").$}>
            {preHeader}
          </div>
          <div {...tid.subHeaderTitle} css={Css.xl3Sb.mt1.$}>
            {header}
          </div>
          <div {...tid.postHeader} css={Css.base.mt1.maxwPx(480).$}>
            {postHeader}
          </div>
        </div>
        <div css={Css.df.aic.fs0.mxa.$}>{right && <div css={Css.df.aic.fs0.gap1.$}>{right}</div>}</div>
      </div>
    </FullBleed>
  );
}
