import { Css, Icon, Palette, TextField, useModal, useTestIds } from "@homebound/beam";
import { useEffect, useState } from "react";
import { useDebounce } from "src/hooks/useDebounce";
import useZodQueryString from "src/hooks/useZodQueryString";
import { searchSchema } from "src/utils";

type SearchBoxProps = {
  onSearch(filter: string): void;
  fullWidth?: boolean;
  clearable?: boolean;
  placeholder?: string;
  debounceDelayInMs?: number;
  updateQueryString?: boolean;
};

export function SearchBox(props: SearchBoxProps) {
  const { inModal } = useModal();
  const { onSearch, fullWidth, clearable, debounceDelayInMs, placeholder = "Search", updateQueryString = true } = props;
  const [{ search: initialValue }, setQs] = useZodQueryString(searchSchema);
  const [value, setValue] = useState<string>(initialValue);

  const debouncedValue = useDebounce(value, debounceDelayInMs);
  const tid = useTestIds(props, "searchBox");

  // If we're in a modal, we don't want to update the URL, but we do want to update the search results.
  useEffect(() => {
    if (!inModal && updateQueryString) {
      setQs({ search: debouncedValue });
    }
    onSearch(debouncedValue);
  }, [debouncedValue, inModal, onSearch, setQs, updateQueryString]);

  return (
    // 244px width per design specifications
    <div css={fullWidth ? Css.w100.$ : Css.wPx(244).$}>
      <TextField
        label="Search"
        labelStyle="hidden"
        compact
        value={value}
        onChange={(v) => setValue(v ?? "")}
        placeholder={placeholder}
        clearable={clearable}
        startAdornment={<Icon icon="search" color={Palette.Gray700} />}
        {...tid}
      />
    </div>
  );
}
