import { Css, Icon, IconButton, Tooltip } from "@homebound/beam";
import { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";

type PlanEditorHeaderProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  tooltip?: ReactNode;
  right?: ReactNode;
  breadcrumbs?: { href?: string; text: string }[];
  backUrl?: string;
};

export function PlanEditorHeader(props: PlanEditorHeaderProps) {
  const { title, subtitle, tooltip, right, breadcrumbs, backUrl } = props;

  return (
    <div css={Css.p3.bgWhite.bb.bcGray300.$}>
      <div css={Css.df.gap2.aic.$}>
        {backUrl && <IconButton icon={"arrowBack"} onClick={backUrl} />}
        <div css={Css.df.fdc.gap1.fg1.$}>
          {breadcrumbs && breadcrumbs.length > 0 ? (
            <div css={Css.sm.df.aic.gap1.$}>
              {breadcrumbs.map((b, i) => (
                <Fragment key={i}>
                  {b.href ? <Link to={b.href}>{b.text}</Link> : <span>{b.text}</span>}
                  {i < breadcrumbs.length - 1 && <span>&bull;</span>}
                </Fragment>
              ))}
            </div>
          ) : null}
          <h1 css={Css.xl2Md.df.aic.gap1.$}>
            {title}
            {tooltip && (
              <Tooltip title={tooltip}>
                <Icon icon="infoCircle" inc={2} />
              </Tooltip>
            )}
          </h1>
          {subtitle && <span css={Css.sm.$}>{subtitle}</span>}
        </div>
        {right && right}
      </div>
    </div>
  );
}
