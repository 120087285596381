import {
  actionColumn as beamActionColumn,
  BoundMultiSelectField,
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  ButtonMenu,
  collapseColumn,
  column,
  Css,
  Direction,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridSortConfig,
  GridTable,
  GridTableApi,
  IconButton,
  LoadingSkeleton,
  numericColumn,
  RowStyles,
  ScrollableContent,
  selectColumn,
  useComputed,
} from "@homebound/beam";
import { FieldState, ObjectState, useFormStates } from "@homebound/form-state";
import { camelCase } from "change-case";
import { Observer } from "mobx-react";
import { MutableRefObject, useCallback, useEffect, useMemo } from "react";
import { Icon, priceCell } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import { BidItemBoundSelectField } from "src/components/autoPopulateSelects/BidItemBoundSelectField";
import { CostSourceSelectField } from "src/components/autoPopulateSelects/CostSourceSelectField";
import {
  BidContractRevisionStatus,
  CostType,
  InputMaybe,
  ItemTemplateItemLineItemWithChangesFragment,
  ItemTemplateLineItemsTab_GroupByFragment,
  ItemTemplateLineItemsTab_ItemSlotFragment,
  ItemTemplateLineItemsTab_ReadyPlanOptionFragment,
  ItivOrderField,
  Maybe,
  Named,
  Order,
  PotentialOperation2,
  ScopeChangeType,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { setupPriceFields } from "src/routes/projects/selections/recalc";
import { safeEntries } from "src/utils";
import { maybeAddIdColumn } from "src/utils/idColumn";
import { itemTemplateItemFormConfig, ItemTemplateItemFormValue } from "src/utils/itemTemplateItem";
import { ItemTemplateAnomaliesIconButton } from "./anomalies/ItemTemplateAnomaliesIconButton";
import { ItemTemplateApi } from "./api/ItemTemplateApi";
import { maybeChangedValue } from "./changes/ItemTemplateChangesUtils";
import { GroupByKeys, ItemTemplateItemTableMode, UserRequestedOrder } from "./ItemTemplateLineItemsTab";
import { ItemTemplateLineItemsUploader } from "./ItemTemplateLineItemsUploader";

// An enum of friendly column names to their indexes to make it easier to add/re-arrange columns
export enum Column {
  Id,
  Open,
  Collapse,
  Select,
  // Keep Item + Location + Cost Type together as part of the row's identity
  Item,
  Location,
  CostType,
  BaseOption,
  Elevation,
  SpecOption,
  SelectionName,
  BidItem,
  ProductCode,
  Quantity,
  Unit,
  UnitCost,
  CostSource,
  TotalCost,
  Actions, // keep this column as the last
}

export type ItemTemplateItemAllTableProps = {
  itApi: ItemTemplateApi;
  items: ItemTemplateItemLineItemWithChangesFragment[];
  groupTotals: ItemTemplateLineItemsTab_GroupByFragment[];
  fetchGroupItems: (groupTotal: ItemTemplateLineItemsTab_GroupByFragment) => void;
  groupBy: GroupByKeys;
  elevations?: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[];
  options?: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[];
  specOptions?: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[];
  readyPlanId?: string;
  selectedFilters?: boolean;
  mode?: ItemTemplateItemTableMode;
  createItemTemplateItemUrl?: (itiId: string) => string;
  unitsOfMeasure?: Named[];
  locations?: Named[];
  rowLookup?: MutableRefObject<GridRowLookup<ItemTemplateItemRows> | undefined>;
  hideColumns?: Column[];
  loading?: boolean;
  onSortOrderChange: (order: UserRequestedOrder) => void;
  sortOrder: UserRequestedOrder;
};

export function ItemTemplateItemAllTable(props: ItemTemplateItemAllTableProps) {
  const {
    itApi,
    items,
    groupTotals,
    fetchGroupItems,
    createItemTemplateItemUrl = (id) => id,
    rowLookup,
    onSortOrderChange,
    sortOrder,
    unitsOfMeasure = [],
    locations = [],
    hideColumns = [],
    elevations = [],
    options = [],
    specOptions = [],
    mode = "development",
    selectedFilters,
    loading,
    groupBy,
  } = props;

  const autoSave: (state: ObjectState<ItemTemplateItemFormValue>) => Promise<void> = useCallback(
    async (state) => {
      const { unitCostInCents, ...others } = state.changedValue;
      await itApi.saveItiv(others);
    },
    [itApi],
  );

  const { getFormState } = useFormStates<ItemTemplateItemFormValue, ItemTemplateItemLineItemWithChangesFragment>({
    config: itemTemplateItemFormConfig,
    getId: (v) => v.id,
    autoSave,
    map: mapToForm,
    addRules,
  });

  const canEditLineItems = itApi.template.canEditLineItems;

  const columns = useMemo(
    () =>
      createColumns(
        createItemTemplateItemUrl,
        getFormState,
        unitsOfMeasure,
        locations,
        elevations,
        specOptions,
        options,
        hideColumns,
        itApi,
        canEditLineItems,
        fetchGroupItems,
      ),
    [
      createItemTemplateItemUrl,
      getFormState,
      unitsOfMeasure,
      locations,
      elevations,
      specOptions,
      options,
      hideColumns,
      itApi,
      canEditLineItems,
      fetchGroupItems,
    ],
  );

  const rowStyles = useMemo(() => createRowStyles(itApi.highlightChanges), [itApi.highlightChanges]);
  const rows = useMemo(
    () => createItemTemplateTableRows(items, groupTotals, groupBy, itApi.tableApi, mode),
    [items, groupTotals, groupBy, itApi.tableApi, mode],
  );

  // Clear selection when swithing groupBy to avoid showing a ton of rows as selected but hidden.
  useEffect(
    () => itApi.tableApi.clearSelections(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupBy],
  );

  const onSort = useCallback(
    (key: string | undefined, direction: Direction | undefined) => {
      if (key) onSortOrderChange({ column: key as ItivOrderField, direction: direction as Order });
    },
    [onSortOrderChange],
  );

  const sorting = useComputed<GridSortConfig>(
    () => ({ on: "server", onSort, value: serverOrderToUserOrder(sortOrder) }),
    [sortOrder, onSort],
  );

  function showItiTableOrUploader(items: ItemTemplateItemLineItemWithChangesFragment[]): boolean {
    return items.nonEmpty || (items.isEmpty && !!selectedFilters);
  }

  return (
    <>
      {showItiTableOrUploader(items) ? (
        <ScrollableContent virtualized>
          {loading ? (
            <>
              <LoadingSkeleton rows={1} columns={1} />
              <LoadingSkeleton rows={5} columns={5} />
            </>
          ) : (
            <GridTable
              as="virtual"
              style={{ grouped: true, inlineEditing: true, allWhite: true, bordered: true }}
              columns={columns}
              rows={rows}
              rowStyles={rowStyles}
              stickyHeader
              rowLookup={rowLookup}
              sorting={sorting}
              api={itApi.tableApi}
            />
          )}
        </ScrollableContent>
      ) : (
        <ItemTemplateLineItemsUploader itApi={itApi} />
      )}
    </>
  );
}

function createRowStyles(highlightChanges: boolean): RowStyles<ItemTemplateItemRows> {
  return {
    item: {
      cellCss: (row) => {
        // NOTE: The Removed case does not check highlightChanges, since we always want to make it clear when a removed item is being shown
        if (row.data.scopeChangeType === ScopeChangeType.Removed) {
          return Css.bgRed300.$;
        }
        if (highlightChanges && row.data.scopeChangeType === ScopeChangeType.Add) {
          return Css.bgGreen300.$;
        }
        return {};
      },
    },
  };
}

type HeaderRow = { kind: "header" };
type GroupByRow = { kind: "groupBy"; data: ItemTemplateLineItemsTab_GroupByFragment };
export type ItemRow = { kind: "item"; data: ItemTemplateItemLineItemWithChangesFragment; id: string };
// Add optionId on to handle the case of an ItemSlot appearing in a BaseOption groupBy row. Thus allowing the add actions to put created items into the correct options
export type SlotRow = {
  kind: "slot";
  data: ItemTemplateLineItemsTab_ItemSlotFragment & { otherOptionIds?: Maybe<Array<string>> };
  id: string;
};
type TotalsRow = { kind: "totals"; data: ItemTemplateLineItemsTab_GroupByFragment; id: string };
type LoadingRow = { kind: "loading"; data: ItemTemplateLineItemsTab_GroupByFragment; id: string };
export type ItemTemplateItemRows = HeaderRow | GroupByRow | SlotRow | ItemRow | LoadingRow | TotalsRow;

function createColumns(
  createItemTemplateItemUrl: (itiId: string) => string,
  getFormState: (input: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  unitsOfMeasure: Named[],
  locations: Named[],
  elevations: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
  specOptions: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
  options: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
  hideColumns: Column[],
  itApi: ItemTemplateApi,
  canEditLineItems: PotentialOperation2,
  fetchGroupItems: (groupTotal: ItemTemplateLineItemsTab_GroupByFragment) => void,
) {
  const idColumn = [
    Column.Id,
    numericColumn<ItemTemplateItemRows>({
      header: "ID",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: (row) => row.id,
      item: (row) => row.id,
      loading: emptyCell,
      w: "120px",
    }),
  ] as const;

  const totalCostColumn = [
    Column.TotalCost,
    numericColumn<ItemTemplateItemRows>({
      header: "Total Cost",
      totals: (row) => priceCell({ valueInCents: row.totalCostInCents }),
      groupBy: (row) => priceCell({ valueInCents: row.totalCostInCents }),
      slot: () => emptyCell,
      item: (row) => {
        const os = getFormState(row);
        return {
          content: () => (
            <BoundNumberField
              disabled={!!row.preferredBidContractLineItem}
              type="cents"
              displayDirection
              field={os.totalCostInCents}
              readOnly={disableBasedOnPotentialOperation(row.canEdit)}
            />
          ),
          ...maybeChangedValue(row, "totalCostInCents"),
          value: row.totalCostInCents,
        };
      },
      loading: (row) => ({
        content: () => {
          fetchGroupItems(row);
          return "";
        },
      }),
      serverSideSortKey: "totalCostInCents",
      w: "120px",
      wrapAction: false,
    }),
  ] as const;

  const unitCostColumn = [
    Column.UnitCost,
    numericColumn<ItemTemplateItemRows>({
      header: "Unit Cost",
      totals: "Totals",
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => ({
        content: () => (
          <Observer>
            {() => {
              if (row.unitOfMeasure?.useQuantity === false || row.quantity === 0) {
                return <div data-testid="unitCost">N/A</div>;
              }

              const os = getFormState(row);
              return (
                <BoundNumberField
                  type="cents"
                  field={os.unitCostInCents}
                  readOnly={!!row.preferredBidContractLineItem || disableBasedOnPotentialOperation(row.canEdit)}
                />
              );
            }}
          </Observer>
        ),
        ...maybeChangedValue(row, "unitCostInCents"),
        value: row.unitOfMeasure?.useQuantity === false ? 0 : row.unitCostInCents,
      }),
      loading: emptyCell,
      w: "120px",
      wrapAction: false,
    }),
  ] as const;

  const columnsByType: Array<readonly [Column, GridColumn<ItemTemplateItemRows>]> = [
    ...maybeAddIdColumn(idColumn),
    openColumn(createItemTemplateItemUrl),
    [
      Column.Collapse,
      collapseColumn<ItemTemplateItemRows>({ totals: emptyCell, loading: emptyCell, sticky: "left" }),
    ] as const,
    [
      Column.Select,
      selectColumn<ItemTemplateItemRows>({ totals: emptyCell, loading: emptyCell, sticky: "left" }),
    ] as const,
    actionColumn(itApi, specOptions),
    itemColumn(getFormState),
    specOptionColumn(getFormState, canEditLineItems, specOptions),
    elevationColumn(getFormState, canEditLineItems, elevations),
    optionColumn(getFormState, canEditLineItems, options),
    locationColumn(getFormState, locations),
    selectionNameColumn(),
    costTypeColumn(getFormState),
    bidItemColumn(getFormState, canEditLineItems),
    productCodeColumn(getFormState),
    quantityColumn(getFormState),
    unitColumn(getFormState, unitsOfMeasure),
    costSourceColumn(getFormState),
    unitCostColumn,
    totalCostColumn,
  ];

  return columnsByType
    .sort(([col1], [col2]) => col1 - col2)
    .filter(([columnType]) => !hideColumns?.includes(columnType))
    .map(([_, columnDef]) => columnDef);
}

export const itemColumnGroupByColspan = 5;
function itemColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
) {
  return [
    Column.Item,
    column<ItemTemplateItemRows>({
      id: `${Column.Item}`,
      header: "Item",
      totals: () => emptyCell,
      groupBy: (row) => ({
        colspan: itemColumnGroupByColspan,
        content: () => row.displayName,
        alignment: "left",
        value: row.displayName,
      }),
      slot: (row) => `${row.item.fullCode} - ${row.name} ${row.isSelection ? "(Selection)" : "(Spec)"}`,
      item: (row) => {
        const os = getFormState(row);
        return {
          content: () => {
            if (!row.canEdit.allowed) return row.displayName;
            return <BoundTextField field={os.name} startAdornment={`${row.item.fullCode} -`} />;
          },
          ...maybeChangedValue(row, "name"),
          value: row.displayName,
        };
      },
      loading: () => ({
        content: () => {
          return (
            <>
              Loading...
              <Icon icon="refresh" />
            </>
          );
        },
      }),
      wrapAction: false,
      sticky: "left",
      serverSideSortKey: "item",
      w: "300px",
    }),
  ] as const;
}

function quantityColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
) {
  return [
    Column.Quantity,
    numericColumn<ItemTemplateItemRows>({
      header: () => `${"Qty"}`,
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => {
        const os = getFormState(row);
        return {
          content: () =>
            row.unitOfMeasure?.useQuantity === false ? (
              <span data-testid="quantity" data-readonly="true">
                N/A
              </span>
            ) : (
              <BoundNumberField
                field={os.quantity}
                displayDirection
                readOnly={disableBasedOnPotentialOperation(row.canEdit)}
                onChange={(val) => {
                  // Unset the preferredBcli if the quantity of the ITIV is 0, given that the unit/total costs are also set to 0
                  if (!val || val === 0) {
                    os.preferredBidContractLineItemId.set(null);
                  }
                  os.quantity.set(val);
                }}
              />
            ),
          ...maybeChangedValue(row, "quantity"),
          value: () => (row.unitOfMeasure?.useQuantity ? "N/A" : (row.quantity ?? "")),
        };
      },
      loading: emptyCell,
      serverSideSortKey: "quantity",
      w: "70px",
      align: "right",
      wrapAction: false,
    }),
  ] as const;
}

function unitColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  unitsOfMeasure: Named[] | undefined = undefined,
) {
  return [
    Column.Unit,
    column<ItemTemplateItemRows>({
      header: "Unit",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: (row) => row.unitOfMeasure.name,
      item: (row) => {
        const os = getFormState(row);
        return {
          content: () => (
            // If we do not have `unitsOfMeasure` then default the options to this row's unitOfMeasure so `SelectField` can show the value
            <BoundSelectField
              field={os.unitOfMeasureId}
              readOnly={disableBasedOnPotentialOperation(row.canEdit) || row.isSelection}
              options={unitsOfMeasure || (row.unitOfMeasure ? [row.unitOfMeasure] : [])}
            />
          ),
          ...maybeChangedValue(row, "unitOfMeasure"),
          value: row.unitOfMeasure?.name,
        };
      },
      loading: emptyCell,
      serverSideSortKey: "unitOfMeasure",
      w: "140px",
      wrapAction: false,
    }),
  ] as const;
}

function selectionNameColumn() {
  return [
    Column.SelectionName,
    column<ItemTemplateItemRows>({
      header: "Selection Name",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => ({
        content: () => (row.isSelection && row.baseProduct ? row.baseProduct.name : undefined),
        ...maybeChangedValue(row, "baseProduct"),
      }),
      loading: emptyCell,
      w: "160px",
    }),
  ] as const;
}

function locationColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  locations: Named[] | undefined = undefined,
) {
  return [
    Column.Location,
    column<ItemTemplateItemRows>({
      header: "Location",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: (row) => row.location.name,
      item: (row) => locationCell(row, getFormState(row), locations),
      loading: emptyCell,
      serverSideSortKey: "location",
      w: "160px",
      wrapAction: false,
    }),
  ] as const;
}

function locationCell(
  row: ItemTemplateItemLineItemWithChangesFragment,
  os: ObjectState<ItemTemplateItemFormValue>,
  locations: Named[] | undefined = undefined,
) {
  return {
    content: () => (
      // If we do not have `locations` then default the options to this row's location so `SelectField` can show the value
      <BoundSelectField
        field={os.locationId}
        readOnly={disableBasedOnPotentialOperation(row.canEdit)}
        options={locations || (row.location ? [row.location] : [])}
        getOptionLabel={(loc) => loc.name}
        getOptionValue={(loc) => loc.id}
      />
    ),
    ...maybeChangedValue(row, "location"),
    value: row.location?.name,
  };
}

function optionColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  canEditLineItems: PotentialOperation2,
  options: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[] | undefined = undefined,
) {
  return [
    Column.BaseOption,
    column<ItemTemplateItemRows>({
      header: "Base / Option",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) =>
        optionSelectCell(
          row,
          getFormState(row).otherOptionIds,
          "otherOptionIds",
          canEditLineItems,
          options,
          row.otherOptionIds,
          "Base",
          "displayName",
        ),
      loading: emptyCell,
      serverSideSortKey: "option",
      w: "250px",
      wrapAction: false,
    }),
  ] as const;
}

function elevationColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  canEditLineItems: PotentialOperation2,
  elevations: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[] | undefined = undefined,
) {
  return [
    Column.Elevation,
    column<ItemTemplateItemRows>({
      header: "Elevation",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) =>
        optionSelectCell(
          row,
          getFormState(row).elevationIds,
          "elevationIds",
          canEditLineItems,
          elevations,
          row.elevationIds,
          "All",
          "name",
        ),
      loading: emptyCell,
      serverSideSortKey: "elevation",
      w: "160px",
      wrapAction: false,
    }),
  ] as const;
}

/** Allows controlling a `.optionId`, `.elevationId`, `.specOptionId` field. */
function optionSelectCell(
  row: ItemTemplateItemLineItemWithChangesFragment,
  field: FieldState<InputMaybe<string[]>>,
  fieldName: "otherOptionIds" | "elevationIds" | "specOptionIds",
  canEditLineItems: PotentialOperation2,
  // These say "elevation fragment" but really mean "any elevation-ish option fragment"
  options: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[] | undefined = undefined,
  currentIds: string[] | null = null,
  undefinedOptionName: string = "All",
  labelField: "name" | "displayName" = "name",
) {
  return {
    content: () => (
      // If we do not have `elevations` then default the options to this row's elevation so `SelectField` can show the value
      <BoundMultiSelectField
        field={field}
        options={options ?? []}
        getOptionLabel={(opt) =>
          `${opt.shortName ? `${opt.shortName} - ` : ""}${opt[labelField]} ${!opt.active ? "(Archived)" : ""}` ??
          undefinedOptionName
        }
        nothingSelectedText={undefinedOptionName}
        getOptionValue={(opt) => opt.id}
        getOptionMenuLabel={(opt) =>
          (
            <ArchivedTag active={opt.active}>
              {opt.shortName ? `${opt.shortName} - ` : ""}
              {opt[labelField]}
            </ArchivedTag>
          ) ?? undefinedOptionName
        }
        readOnly={disableBasedOnPotentialOperation(canEditLineItems)}
      />
    ),
    ...maybeChangedValue(row, fieldName, options),
    value: options
      ?.filter((o) => currentIds?.includes(o.id))
      ?.map((o) => o.displayName)
      .sort()
      .join(", "),
  };
}

function specOptionColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  canEditLineItems: PotentialOperation2,
  specOptions?: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
) {
  return [
    Column.SpecOption,
    column<ItemTemplateItemRows>({
      header: "Spec Level",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) =>
        optionSelectCell(
          row,
          getFormState(row).specOptionIds,
          "specOptionIds",
          canEditLineItems,
          specOptions,
          row.specOptionIds,
        ),
      loading: emptyCell,
      w: "160px",
      wrapAction: false,
    }),
  ] as const;
}

function costTypeColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
) {
  return [
    Column.CostType,
    column<ItemTemplateItemRows>({
      header: "Cost Type",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: (row) => row.costTypeDetail.name,
      item: (row) => costTypeCell(row, getFormState(row)),
      loading: emptyCell,
      serverSideSortKey: "costType",
      w: "120px",
      wrapAction: false,
    }),
  ] as const;
}

function costTypeCell(row: ItemTemplateItemLineItemWithChangesFragment, os: ObjectState<ItemTemplateItemFormValue>) {
  return {
    content: () => (
      <BoundSelectField
        field={os.costType}
        options={safeEntries(CostType)}
        getOptionLabel={(option) => option?.[0]}
        getOptionValue={(option) => option?.[1]}
        readOnly={disableBasedOnPotentialOperation(row.canEdit)}
      />
    ),
    ...maybeChangedValue(row, "costType"),
    value: os.costType?.toString(),
  };
}

function bidItemColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
  canEditLineItems: PotentialOperation2,
) {
  return [
    Column.BidItem,
    column<ItemTemplateItemRows>({
      header: "Bid Item",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => ({
        content: () => {
          const os = getFormState(row);
          return (
            <BidItemBoundSelectField
              field={os.bidItemId}
              value={row.bidItem}
              filter={{ itemTemplateItemId: row.scope?.id }}
              readOnly={disableBasedOnPotentialOperation(canEditLineItems)}
              onSelect={(bidItemId, bidItem) => {
                // Unsets the bid item and base product
                if (bidItemId === undefined) {
                  os.set({ bidItemId: null, baseProductId: null });
                } else {
                  os.bidItemId.set(bidItemId);
                  // Select product by default if there's just one available, if not, unset the base product
                  bidItem?.products.length === 1
                    ? os.baseProductId.set(bidItem.products.first?.id)
                    : os.baseProductId.set(null);
                }
              }}
            />
          );
        },
        ...maybeChangedValue(row, "bidItem"),
        value: row.bidItem?.name,
      }),
      loading: emptyCell,
      align: "right",
      w: "280px",
      wrapAction: false,
    }),
  ] as const;
}

function productCodeColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
) {
  return [
    Column.ProductCode,
    column<ItemTemplateItemRows>({
      header: "Product Code",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => ({
        content: () => {
          const os = getFormState(row);
          // Filter products by selected bid item
          const selectedBidItem = row.bidItem;
          return (
            <BoundSelectField
              field={os.baseProductId}
              options={selectedBidItem?.products || []}
              readOnly={!selectedBidItem || selectedBidItem.products.length < 2}
            />
          );
        },
        ...maybeChangedValue(row, "baseProduct"),
        value: () => row.baseProduct?.name,
      }),
      loading: emptyCell,
      w: "130px",
    }),
  ] as const;
}

/** Opens the superdrawer for the given row when clicked... */
function openColumn(createItemTemplateItemUrl: (itiId: string) => string) {
  return [
    Column.Open,
    beamActionColumn<ItemTemplateItemRows>({
      header: () => emptyCell,
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => ({
        content: () => (
          <>
            <IconButton onClick={createItemTemplateItemUrl(row.id)} icon="pencil" inc={2} />
            <ItemTemplateAnomaliesIconButton parentId={row.id} />
          </>
        ),
      }),
      loading: emptyCell,
      w: "60px",
      sticky: "left",
    }),
  ] as const;
}

function ActionColumnMenu(props: {
  itApi: ItemTemplateApi;
  slotId: string;
  itivId?: string;
  otherOptionIds?: Maybe<Array<string>>;
  addBySpec: boolean;
  specOptions: Named[];
}) {
  const { itApi, slotId, itivId, otherOptionIds, addBySpec, specOptions } = props;
  const modifyMenuItems = useMemo(
    () => [
      {
        label: "Add Another",
        onClick: async () => {
          await itApi.saveItiv({ slotId, otherOptionIds, totalCostInCents: 0 });
        },
      },
      ...(addBySpec
        ? [
            {
              label: "Add by Spec Level",
              onClick: async () => {
                // Creates a new item for each spec level
                await itApi.saveItiv(
                  specOptions.map((specOption) => ({
                    slotId,
                    otherOptionIds,
                    totalCostInCents: 0,
                    specOptionIds: [specOption.id],
                  })),
                );
              },
            },
          ]
        : []),
      ...(itivId ? [{ label: "Delete", onClick: () => itApi.deleteItiv({ id: itivId, remove: true }) }] : []),
    ],
    [itApi, slotId, itivId, otherOptionIds, addBySpec, specOptions],
  );

  return <ButtonMenu trigger={{ icon: "verticalDots" }} placement="right" items={modifyMenuItems} />;
}

function actionColumn(itApi: ItemTemplateApi, specOptions: Named[]) {
  return [
    Column.Actions,
    beamActionColumn<ItemTemplateItemRows>({
      header: () => emptyCell,
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: (row) => (
        <ActionColumnMenu
          itApi={itApi}
          slotId={row.id}
          otherOptionIds={row.otherOptionIds}
          addBySpec
          specOptions={specOptions}
        />
      ),
      item: (row) => (
        <ActionColumnMenu
          itApi={itApi}
          slotId={row.slot.id}
          itivId={row.id}
          otherOptionIds={row.otherOptionIds}
          addBySpec={row.specOptionIds.isEmpty}
          specOptions={specOptions}
        />
      ),
      loading: emptyCell,
      w: "52px",
      sticky: "right",
    }),
  ] as const;
}

function costSourceColumn(
  getFormState: (row: ItemTemplateItemLineItemWithChangesFragment) => ObjectState<ItemTemplateItemFormValue>,
) {
  return [
    Column.CostSource,
    column<ItemTemplateItemRows>({
      header: "Cost Source",
      totals: () => emptyCell,
      groupBy: () => emptyCell,
      slot: () => emptyCell,
      item: (row) => {
        const os = getFormState(row);
        return {
          content: () => (
            <CostSourceSelectField
              value={row.preferredBidContractLineItem}
              filter={{
                // Find all matching plan based and item based BCLIs for this development
                developmentId: row.scope.template.development?.id,
                bidItemIds: row.bidItem ? [row.bidItem.id] : undefined,
                itemTemplateItemId: row.scope?.bidItemTemplateItem?.id,
                status: [BidContractRevisionStatus.Signed],
              }}
              readOnly={disableBasedOnPotentialOperation(row.canEdit)}
              onSelect={(bcliId, selectedCostSource) => {
                // Seems like we should do:
                // row.fragment.preferredBidContractLineItem = selectedCostSource;
                // to get `CostSourceSelectField.value` updated with the new selection?
                if (!bcliId) {
                  os.preferredBidContractLineItemId.set(null);
                } else {
                  os.set({
                    preferredBidContractLineItemId: bcliId,
                    // Setting the qty to 1 to not lose the applied bcli costs
                    quantity: row.quantity || 1,
                    totalCostInCents: (selectedCostSource?.unitCostInCents || 0) * (row.quantity || 1),
                  });
                }
              }}
            />
          ),
          ...maybeChangedValue(row, "preferredBidContractLineItem"),
        };
      },
      loading: emptyCell,
      w: "240px",
      wrapAction: false,
    }),
  ] as const;
}

function createItemTemplateTableRows(
  items: ItemTemplateItemLineItemWithChangesFragment[],
  groupTotals: ItemTemplateLineItemsTab_GroupByFragment[],
  groupBy: GroupByKeys,
  tableApi: GridTableApi<ItemTemplateItemRows>,
  mode: ItemTemplateItemTableMode,
): GridDataRow<ItemTemplateItemRows>[] {
  const itemsByGroup = items.groupBy((item) =>
    groupBy === "code" ? item.item.costCode.id : item.otherOptionIds.isEmpty ? "rpo:-1" : item.otherOptionIds.join(","),
  );
  const { totals, groups } = computeTotalIncludingDynamicGroups(itemsByGroup, groupTotals, groupBy);

  return [
    { kind: "totals" as const, id: "totals", data: totals },
    { kind: "header", id: "header", data: undefined },
    ...groups.map((gt) => {
      const itemsInGroup = itemsByGroup[gt.groupId] ?? [];
      let children: Array<GridDataRow<ItemTemplateItemRows>>;
      if (mode === "global") {
        // In global templates, we don't group by slot
        children = itemsInGroup.map((itiv) => ({
          kind: "item" as const,
          id: itiv.id,
          data: itiv,
        }));
      } else {
        // Group items by slot in development mode
        const itemsBySlot = Object.entries(itemsInGroup.groupBy((itiv) => itiv.slot.id));
        children = itemsBySlot.map(([, slotItems]) => {
          const children = slotItems.map((itiv) => ({
            kind: "item" as const,
            id: itiv.id,
            data: itiv,
          }));

          const slot = slotItems.first!.slot;
          return {
            kind: "slot" as const,
            // Include the groupId in the id so that the slot id is unique across groups when in base/option groupBy
            id: `${slot.id}-${gt.groupId}`,
            data: slot,
            children,
          };
        });
      }

      const loadedItems = itemsInGroup.length;
      const initCollapsed = loadedItems === 0;
      const partiallyLoaded = loadedItems !== gt.itivsInGroup;
      if (partiallyLoaded) {
        // Make the loading row unselectable - so that it never appears in the selected but hidden collection
        children.push({ kind: "loading" as const, id: `loading-${gt.groupId}`, data: gt, selectable: false });

        // If we aren't loaded at all, then insert the not-loaded group row with inferSelectedState: false
        if (loadedItems === 0)
          return {
            kind: "groupBy" as const,
            initCollapsed,
            id: gt.groupId + "not-loaded",
            data: gt,
            children,
            // Set inferSelectedState to false so that we can still select the group, even though it has only unselectable loading children
            inferSelectedState: false as const,
          };
      }
      // Remove the group loading row if it exists to prevent a not-loaded row from appearing as a selected but hidden row
      tableApi.deleteRows([gt.groupId + "not-loaded"]);
      return {
        kind: "groupBy" as const,
        id: gt.groupId,
        data: gt,
        children,
      };
    }),
  ];
}

export function computeTotalIncludingDynamicGroups(
  itemsByGroup: Record<string, ItemTemplateItemLineItemWithChangesFragment[]>,
  groupTotals: ItemTemplateLineItemsTab_GroupByFragment[],
  groupBy: GroupByKeys,
): { totals: ItemTemplateLineItemsTab_GroupByFragment; groups: ItemTemplateLineItemsTab_GroupByFragment[] } {
  const serverTotals: ItemTemplateLineItemsTab_GroupByFragment | undefined = groupTotals.find(
    (gt) => gt.groupId === "template",
  );

  const groupsById = new Map(groupTotals.map((gt) => [gt.groupId, gt]));

  // There are some items which are not in any of the expected groups
  // This can occur when the user has a filter on, and adds a new item which doesn't match the filter.
  // In this case, we still want the user to see the item they just added, so that they do not get confused and add it again.
  const dynamicGroups: ItemTemplateLineItemsTab_GroupByFragment[] = Object.entries(itemsByGroup)
    .filter(([groupId]) => !groupsById.has(groupId))
    .map(([groupId, items]) => {
      const group = groupsById.get(groupId);
      const totalCostInCents = items.sum((itiv) => itiv.totalCostInCents);
      const itivIdsInGroup = items.map((itiv) => itiv.id);
      const [firstItem] = items;
      return {
        groupId,
        itivIdsInGroup,
        itivsInGroup: itivIdsInGroup.length,
        totalCostInCents,
        displayName:
          groupBy === GroupByKeys.code
            ? firstItem.item.costCode.displayName
            : firstItem.otherOptionIds.isEmpty
              ? "Base House"
              : (group?.displayName ?? groupId),
      };
    });

  // These items are not included in the serverTotals, so we compute a new total which includes those values
  const computedTotalCostInCents = serverTotals?.totalCostInCents ?? 0 + dynamicGroups.sum((gt) => gt.totalCostInCents);
  const computedTotalItivIdsInGroup = [
    ...(serverTotals?.itivIdsInGroup ?? []),
    ...dynamicGroups.flatMap((gt) => gt.itivIdsInGroup),
  ].unique();
  const computedtotals = {
    groupId: "template",
    displayName: "Totals",
    totalCostInCents: computedTotalCostInCents,
    itivIdsInGroup: computedTotalItivIdsInGroup,
    itivsInGroup: computedTotalItivIdsInGroup.length,
  };

  return {
    totals: computedtotals,
    // Return the groups without the total so that it does not need to be filtered out by the caller
    groups: [...dynamicGroups, ...groupTotals.filter((gt) => gt.groupId !== "template")],
  };
}

function serverOrderToUserOrder(order: UserRequestedOrder): [string, Direction] {
  return [camelCase(order.column), order.direction];
}

function mapToForm(itiv: ItemTemplateItemLineItemWithChangesFragment) {
  return {
    ...itiv,
    itemId: itiv.item.id,
    locationId: itiv.location.id,
    unitOfMeasureId: itiv.unitOfMeasure?.id,
    bidItemId: itiv.bidItem?.id,
    elevationIds: itiv.elevationIds,
    specOptionIds: itiv.specOptionIds,
    otherOptionIds: itiv.otherOptionIds,
    preferredBidContractLineItemId: itiv.preferredBidContractLineItem?.id,
    baseProductId: itiv.baseProduct?.id,
  };
}

function addRules(formState: ObjectState<ItemTemplateItemFormValue>) {
  // Recalc cost/markup/price
  setupPriceFields(
    {
      unitCostInCents: formState.unitCostInCents,
      totalCostInCents: formState.totalCostInCents,
      quantity: formState.quantity,
    },
    // TODO: Update with a potential operation value later? See SC-16307 to determine if this is true
    { canEditPrice: true },
  );
}
