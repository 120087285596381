import { IncrementalCollectionOp, Maybe } from "src/generated/graphql-types";

type HasIncrementalOp = {
  op: IncrementalCollectionOp;
};

export function mapFormInputToIncrementalOp<V>(
  formInput: Maybe<string[]>,
  existingValues: V[] | undefined,
  {
    existingValueToId,
    removedMapper,
    addedMapper,
  }: {
    existingValueToId: (v: V) => string;
    removedMapper: (removed: V) => HasIncrementalOp;
    addedMapper: (added: string) => HasIncrementalOp;
  },
): HasIncrementalOp[] {
  if (!formInput) return [];

  const existingValuesAsIds = existingValues?.map(existingValueToId) ?? [];
  const removedValues =
    existingValues
      ?.filter((existingValue) => !formInput?.find((input) => input === existingValueToId(existingValue)))
      .map(removedMapper) ?? [];

  const addedValues = formInput.filter((input) => !existingValuesAsIds.includes(input)).map(addedMapper);
  return [...removedValues, ...addedValues];
}
