import { Css } from "@homebound/beam";
import { LotSummaryDetailField } from "./LotSummaryDetailField";
import { Rows } from "./LotSummaryDetailsSection";

type LotSummaryDetailTableRowProps = {
  columns: number;
  rows: Rows;
};

export function LotSummaryDetailTableRow({ columns, rows }: LotSummaryDetailTableRowProps) {
  const frValues = Array.from({ length: columns }, () => "1fr").join(" ");

  return (
    <div css={Css.mr3.dg.gtc(frValues).w100.$}>
      {rows.map((props) => (
        <LotSummaryDetailField key={props.label} {...props} />
      ))}
    </div>
  );
}
