import { Button, Css, IconButton, useModal } from "@homebound/beam";
import { useState } from "react";
import { HoverDelete } from "src/components/HoverDelete";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  DocumentDetailDocumentTypeFragment,
  RequiredTaskDocumentDetailFragment,
  useDeleteRequiredTaskDocumentMutation,
} from "src/generated/graphql-types";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { nonEmpty, sortBy } from "src/utils";
import { DocumentRequirementModal } from "./DocumentRequirementModal";

type DocumentRequirementsProps = {
  scheduleTaskId: string;
  scheduleIsLocked: boolean;
  documentRequirements: RequiredTaskDocumentDetailFragment[];
  documentTypes: DocumentDetailDocumentTypeFragment[];
};

export function DocumentRequirements({
  scheduleTaskId,
  documentRequirements,
  documentTypes,
  scheduleIsLocked,
}: DocumentRequirementsProps) {
  const { openModal } = useModal();
  const sortedDocumentRequirements = sortBy(documentRequirements, ({ name }) => name);
  const [hoveredId, setHoveredId] = useState("");
  const [deleteRequiredTaskDocument] = useDeleteRequiredTaskDocumentMutation();

  function openDeleteConfirmationModal(requiredDocumentId: string) {
    openModal({
      content: (
        <DeleteConfirmationModal
          confirmationMessage={
            <div css={Css.gray700.fw4.$}>
              Deleting this document requirement will remove it from this template. This will not affect existing
              schedules made from this template.
            </div>
          }
          entityType="Document Requirement"
          onConfirmDelete={async () => {
            await deleteRequiredTaskDocument({
              variables: { input: { id: requiredDocumentId } },
            });
          }}
        />
      ),
    });
  }

  return (
    <>
      <div css={Css.smMd.mb1.mt4.$}>
        <span css={Css.df.aic.jcsb.sm.gray700.$}>
          Document Requirements
          <Button
            label="Add a Document Requirement"
            variant="tertiary"
            disabled={scheduleIsLocked}
            onClick={() => {
              openModal({
                content: <DocumentRequirementModal scheduleTaskId={scheduleTaskId} documentTypes={documentTypes} />,
              });
            }}
          />
        </span>
      </div>

      {nonEmpty(sortedDocumentRequirements) ? (
        <div data-testid="documentRequirements" css={Css.df.fdc.gap1.$}>
          {sortedDocumentRequirements.map((documentRequirement) => {
            const { id, name, documentType, sampleAsset } = documentRequirement;
            return (
              <div
                data-testid="documentRequirement"
                onPointerEnter={() => setHoveredId(id)}
                onPointerLeave={() => setHoveredId("")}
                css={Css.df.fdc.br8.bgGray100.$}
                key={id}
              >
                <div css={Css.df.fdc.xs.p2.$}>
                  <div css={Css.df.fdr.aic.gap1.hPx(30).$}>
                    <div css={Css.fw5.wPx(100).$}>Document Name</div>
                    <div css={Css.truncate.wPx(120).$}>{name}</div>
                    <div css={Css.wPx(115).$}>
                      <div css={Css.fr.$}>
                        <HoverDelete
                          data-testid="deleteDocumentRequirement"
                          visible={hoveredId === id}
                          onClick={() => openDeleteConfirmationModal(id)}
                        />
                      </div>
                    </div>
                  </div>
                  <div css={Css.df.fdr.aic.gap1.$}>
                    <div css={Css.fw5.wPx(100).$}>Type</div>
                    <div>{documentType.name}</div>
                  </div>
                  {sampleAsset && (
                    <div css={Css.df.fdr.aic.gap1.$}>
                      <div css={Css.fw5.wPx(100).$}>Sample</div>
                      <div css={Css.truncate.wPx(120).$}>
                        <AssetGallery assets={[sampleAsset]}>
                          {(openGallery) => (
                            <Button
                              label={sampleAsset.fileName as string}
                              variant="text"
                              onClick={() => openGallery(sampleAsset)}
                            />
                          )}
                        </AssetGallery>
                      </div>
                      <div css={Css.wPx(115).$}>
                        <div css={Css.fr.$}>
                          <IconButton
                            icon="download"
                            tooltip={"Download sample document"}
                            onClick={sampleAsset.attachmentUrl}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div data-testid="noDocumentRequirements" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no document requirements
        </div>
      )}
    </>
  );
}
