import { currentAuthToken } from "@homebound/auth-components";
import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useCallback, useState } from "react";
import BulkUploadMessagesModal, { BulkUploadMessageResponse } from "src/components/BulkUploadMessagesModal";
import { CsvUploader, unparseCsvRows } from "src/components/CsvUploader";
import { baseDownloadUrl } from "src/context";
import { formatList } from "src/utils";

export type BulkUploadCsvResponse = {
  errors: BulkUploadMessageResponse[];
  warnings: BulkUploadMessageResponse[];
};

type BulkUploadBidItemModalProps = {
  onCompleteUpload: () => Promise<void>;
};

export function BulkUploadBidItemModal({ onCompleteUpload }: BulkUploadBidItemModalProps) {
  const { closeModal } = useModal();
  const { addError, errors, csvContent, loading, upload, clearErrors, setCsvContent } = useUploadBidItemRequestCsv();
  return (
    <>
      <ModalHeader>Bulk Upload Bid Item</ModalHeader>
      <ModalBody>
        <h2 css={Css.lgMd.$}>Upload your CSV file here</h2>
        <CsvUploader
          onError={addError}
          errors={errors}
          onDrop={(content) => {
            clearErrors();
            setCsvContent(unparseCsvRows(content));
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
              <Button
                label="Upload"
                disabled={!csvContent || loading || errors?.toString()}
                onClick={async () => {
                  await upload();
                  await onCompleteUpload();
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

function useUploadBidItemRequestCsv() {
  const { openModal } = useModal();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [csvContent, setCsvContent] = useState("");
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const addError = (err: string | string[]) =>
    setErrors((existing) => existing.concat(Array.isArray(err) ? err : String(err)));
  const clearErrors = () => setErrors([] as string[]);

  const upload = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    if (!csvContent) {
      addError("CSV file must have at least one bid item row.");
      setLoading(false);
      return;
    }

    const response = await fetch(`${baseDownloadUrl()}/csv?type=saveBidItems`, {
      method: "POST",
      headers: { "content-type": "text/csv", Authorization: `Bearer ${await currentAuthToken()}` },
      body: csvContent,
    });

    if (response.status !== 200) {
      // Backend may have identified errors in the CSV (Like unfilled Total Cost columns or poorly-formatted numbers)
      // so pop them into the Errors array here.
      const { message } = await response.json();
      if (message) addError(message);
      setLoading(false);
      return;
    }

    // Catch possible warnings in the process
    const result = (await response.json()) as BulkUploadCsvResponse;
    if (result.errors?.nonEmpty) {
      result.errors.sortBy((e) => e.row).forEach((e) => addError(`Row ${e.row}: ${e.message}`));
      setLoading(false);
      return;
    }

    const bidItemRows = result.warnings?.map((w) => w.row).compact() ?? [];
    if (bidItemRows.nonEmpty) {
      triggerNotice({
        message: `The following rows had warnings while uploading: ${formatList(bidItemRows)}`,
        persistent: true,
        icon: "warning",
        action: {
          label: "See details",
          variant: "tertiary",
          onClick: () =>
            openModal({
              content: <BulkUploadMessagesModal warnings={result.warnings} />,
              size: "xl",
            }),
        },
      });
    }

    triggerNotice({ message: "The file has been uploaded successfully" });
    closeModal();
  }, [closeModal, csvContent, loading, openModal, triggerNotice]);

  return { upload, errors, loading, csvContent, setCsvContent, addError, clearErrors };
}
