// Putting this here instead of CsvUploader.tsx, because CsvUploader gets mocked out during tests

import { isDefined } from "src/utils";
import { CsvRow } from "./CsvUploader";

export function isCsv(file?: { type: string; name: string }): boolean {
  if (!file) {
    return false;
  }
  // Ideally we'd check only file.type, but on Windows (both Chrome and Edge) we're
  // seeing the file.type is empty string, so fallback on file.name for now.
  // Note that papaparse's csv parsing still works, even if file.type is empty string.
  return file.type === "text/csv" || file?.name.endsWith(".csv");
}

/**
 * Validates the content of the CSV file and optionally validates the columns and row values,
 *
 * Base validations:
 * * Has content
 * * Has data rows (more than only the header row)
 * * Has the same number of columns in every row
 *
 * If required columns provided, also validates:
 * * Header has all the required columns
 * * All rows have values for the required columns
 * @param content The rows inside the csv file
 * @param requiredColumns(optional) If there is any required column in the CSV, provide them as array of strings, the strings must be as literals expected to be in the first row of the content
 * @returns whether the csv content passes all validations, and the reasons in case it didn't passes
 */
export function isValidCsvContent({ content, requiredColumns }: { content: CsvRow[]; requiredColumns?: string[] }): {
  valid: boolean;
  reasons?: string[];
} {
  if (!content.length) return { valid: false, reasons: ["No content"] };
  const headerColumns = content[0]?.data || [];

  const hasDataRows = content?.length > 1;
  if (!hasDataRows) {
    return { valid: false, reasons: ["No data to import"] };
  }

  const hasSameNumberOfColumns = content.every((row) => row.data?.length === headerColumns?.length);
  if (!hasSameNumberOfColumns) {
    return { valid: false, reasons: ["The file does not have the same number of columns in all rows"] };
  }

  const missingColumn = requiredColumns?.find((reqColumn) => !headerColumns?.includes(reqColumn));
  if (missingColumn) {
    return { valid: false, reasons: [`Required column missing [${missingColumn}]`] };
  }

  const missingRequiredColumnValues = content
    ?.map((row, index) => {
      const missingColumn = requiredColumns?.find((reqColumn) => !row?.data[headerColumns.indexOf(reqColumn)]);
      return missingColumn ? `Missing value on required column, [${missingColumn}] on row (${index + 1})` : undefined;
    })
    ?.filter(isDefined);
  if (missingRequiredColumnValues?.length) {
    return { valid: false, reasons: missingRequiredColumnValues };
  }

  return { valid: true };
}
