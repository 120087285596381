import { Css, emptyCell, GridColumn, GridTable, RowStyles, simpleHeader } from "@homebound/beam";
import { Price } from "src/components";
import { Number } from "src/components/Number";
import { CommitmentOrChangeOrderPdf_ChangeOrderFragment } from "src/generated/graphql-types";
import { sanitizeHtml } from "src/utils";
import { createPdfLineItemRows, HeaderRow, LineItemRow, OptionGroupRow, SpecificationsRow } from "./CommitmentTable";

type CommitmentChangeOrderTableProps = { commitmentChangeOrder: CommitmentOrChangeOrderPdf_ChangeOrderFragment };

export function CommitmentChangeOrderTable({ commitmentChangeOrder }: CommitmentChangeOrderTableProps) {
  return (
    <>
      <div css={Css.df.fdr.ttu.mt2.$}>
        <div css={Css.df.f1.p1.$}>Line Items</div>
        <div css={Css.df.f1.jcc.p1.fwb.$}>Previous</div>
        <div css={Css.df.f1.jcc.p1.fwb.$}>New</div>
      </div>
      <GridTable
        as="table"
        columns={commitmentChangeOrderColumns}
        rows={createRows(commitmentChangeOrder)}
        rowStyles={rowStyles}
      />
    </>
  );
}

type TotalRow = { kind: "total"; data: { previousTotal?: number; newTotal?: number } };
type CommitmentChangeOrderRow = LineItemRow | OptionGroupRow | HeaderRow | TotalRow | SpecificationsRow;

const commitmentChangeOrderColumns: GridColumn<CommitmentChangeOrderRow>[] = [
  {
    header: "Item Code",
    group: (groupName) => ({
      content: groupName,
      colspan: 11, // Can span across entire table
      css: Css.smMd.px1.$,
    }),
    data: (lineItem) => lineItem.projectItem.item.fullCode,
    specifications: emptyCell,
    total: emptyCell,
  },
  {
    header: "Description",
    group: emptyCell,
    data: (lineItem) => lineItem.projectItem.description,
    specifications: (specification) => ({
      content: (
        <p data-testid="specification">
          Note: <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(specification || "") }} />
        </p>
      ),
      colspan: 10, // Can span across entire table
      css: Css.sm.$,
    }),
    total: emptyCell,
  },
  {
    header: "CostType",
    group: emptyCell,
    data: (lineItem) => lineItem.projectItem.costTypeDetail.name,
    specifications: emptyCell,
    total: emptyCell,
  },
  // previous line item data
  {
    header: "Qty",
    group: emptyCell,
    data: (lineItem) => lineItem.originalQuantity,
    specifications: emptyCell,
    total: emptyCell,
  },
  {
    header: "Unit",
    group: emptyCell,
    data: (lineItem) => lineItem.projectItem.unitOfMeasure.name,
    specifications: emptyCell,
    total: "Previous Total:",
  },
  {
    header: "Unit Cost",
    group: emptyCell,
    data: (lineItem) => <Price valueInCents={lineItem.originalTotalCostInCents / (lineItem.originalQuantity || 1)} />,
    specifications: emptyCell,
    total: emptyCell,
  },
  {
    header: "Subtotal",
    group: emptyCell,
    data: (lineItem) => <Price valueInCents={lineItem.originalTotalCostInCents} />,
    specifications: emptyCell,
    total: ({ previousTotal }) => <Price valueInCents={previousTotal} />,
  },
  // new line item data
  {
    header: "Qty",
    group: emptyCell,
    data: (lineItem) => (
      <div css={Css.df.fdc.$}>
        <Number value={lineItem.newQuantity} />
        <Number displayDirection value={lineItem.newQuantity - lineItem.originalQuantity} />
      </div>
    ),
    specifications: emptyCell,
    total: emptyCell,
  },
  {
    header: "Unit",
    group: emptyCell,
    data: (lineItem) => lineItem.projectItem.unitOfMeasure.name,
    specifications: emptyCell,
    total: emptyCell,
  },
  {
    header: "Unit Cost",
    group: emptyCell,
    data: (lineItem) => {
      const newUnitCost = lineItem.newTotalCostInCents / (lineItem.newQuantity || 1);
      const originalUnitCost = lineItem.originalTotalCostInCents / (lineItem.originalQuantity || 1);
      return (
        <div css={Css.df.fdc.$}>
          <Price valueInCents={newUnitCost} />
          {newUnitCost !== originalUnitCost && <Price displayDirection valueInCents={newUnitCost - originalUnitCost} />}
        </div>
      );
    },
    specifications: emptyCell,
    total: "New Total:",
  },
  {
    header: "Subtotal",
    group: emptyCell,
    data: (lineItem) => {
      return (
        <div css={Css.df.fdc.$}>
          <Price valueInCents={lineItem.newTotalCostInCents} />
          <Price displayDirection valueInCents={lineItem.costChangeInCents} />
        </div>
      );
    },
    specifications: emptyCell,
    total: ({ newTotal }) => <Price valueInCents={newTotal} />,
  },
];

function createRows(commitmentChangeOrder: CommitmentOrChangeOrderPdf_ChangeOrderFragment) {
  const specOptionId = commitmentChangeOrder.commitment.project.readyPlanConfig?.specOption?.readyPlanOption.id;
  const lineItems = commitmentChangeOrder.lineItems;
  const rows = createPdfLineItemRows(lineItems, specOptionId);
  const previousTotal = lineItems?.sum((cli) => cli.originalTotalCostInCents);
  const newTotal = lineItems?.sum((cli) => cli.newTotalCostInCents);
  return [
    simpleHeader,
    ...rows,
    {
      kind: "total" as const,
      id: "total",
      data: { previousTotal, newTotal },
    },
  ];
}

// adding border-left to Quantity columns
const qtyHeaderStyles = Css.addIn("& > th:nth-of-type(4), & > th:nth-of-type(8)", Css.bl.bcGray400.pl2.$).$;
const qtyCellBorderStyles = Css.addIn("& > td:nth-of-type(4), & > td:nth-of-type(8)", Css.bl.bcGray400.pl2.$).$;

const rowStyles: RowStyles<CommitmentChangeOrderRow> = {
  data: { cellCss: Css.p1.vat.$, rowCss: qtyCellBorderStyles },
  header: { rowCss: qtyHeaderStyles },
};
