import { Button, Css, Palette, SelectField, useComputed, useHover, useTestIds } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { BannerNotice, Icon } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import { HoverDelete } from "src/components/HoverDelete";
import { LotDetailDrawerOptionFragment, ReadyPlanOption } from "src/generated/graphql-types";
import { extractPoMessages } from "src/utils";
import { DisabledReason, REASONS_MAP, evaluateOptionDependencies } from "src/utils/optionDependencies";
import { FormInput, LotDetailDrawerMetadata, OptionTypes, StatusChip } from "../utils";

type OptionsTabProps = {
  formState: ObjectState<FormInput>;
  metadata: LotDetailDrawerMetadata;
  readOnly: boolean;
  activeEditMode: () => void;
  disableEdit: boolean;
};

export function OptionsTab({ formState, metadata, readOnly, activeEditMode, disableEdit }: OptionsTabProps) {
  const formOptions = useComputed(() => formState.options.value, [formState]);
  const populatedOptions = formOptions
    .map(({ id: readyPlanOptionId, updatedAt, autoAdded }) => {
      const matchingOption = metadata.otherOptions.find(({ id }) => id === readyPlanOptionId);
      return matchingOption ? { ...matchingOption, updatedAt, autoAdded } : undefined;
    })
    .compact() // filter out options without a match
    .sortBy(({ code }) => code);
  const autoAddedOptions = populatedOptions.filter((o) => !!o.autoAdded);
  const otherOptionTypes = metadata.globalOptionTypes
    .filter((type) => !type.isElevation && !type.isExteriorPalette && !type.isSpecLevel)
    .sortBy(({ order }) => order);
  const assignNewOption = (o?: string) =>
    formState.options.set([...formState.options.value, ...(o ? [{ id: o, updatedAt: new Date() }] : [])]);
  const removeOption = (o?: string) => formState.options.set(formState.options.value.filter((opt) => opt.id !== o));

  const { disabledOptions } = useMemo(() => {
    const specOption = metadata.specOptions.find(({ id }) => id === formState.specOptionId.value);
    const elevationOption = metadata.elevationOptions.find(({ id }) => id === formState.elevationOptionId.value);
    const exteriorSchemeOption = metadata.exteriorSchemeOptions.find(
      ({ id }) => id === formState.exteriorPaletteOptionId.value,
    );

    // populated options do not include exterior scheme, elevation, or spec level options
    // so include those in the selected options
    const selectedOptions = [...populatedOptions, specOption, elevationOption, exteriorSchemeOption];

    return evaluateOptionDependencies(
      selectedOptions as unknown as ReadyPlanOption[],
      metadata.otherOptions as unknown as ReadyPlanOption[],
    );
  }, [populatedOptions, formState, metadata]);

  const maybeS = autoAddedOptions.length > 1 ? "s" : "";

  return (
    <>
      {readOnly && !metadata.canEdit?.allowed && (
        <BannerNotice
          variant="form"
          message={
            <div css={Css.df.fdr.aic.gap2.$}>
              <Icon icon="error" color={Palette.Red600} pxSize={32} />
              {extractPoMessages(metadata.canEdit)}
            </div>
          }
        />
      )}
      {autoAddedOptions.nonEmpty && (
        <BannerNotice
          variant="form"
          message={`${autoAddedOptions.length} new option${maybeS} added as default${maybeS} based on chosen option${maybeS}`}
          icon={{ icon: "checkCircle", color: Palette.Green500 }}
        />
      )}
      {readOnly && !disableEdit ? (
        <div css={Css.df.jcfe.h4.$}>
          <Button variant="tertiary" label="Edit Configuration" onClick={() => activeEditMode()} />
        </div>
      ) : undefined}
      {otherOptionTypes.map((optionType) => (
        <OptionSection
          key={optionType.id}
          label={optionType.name === OptionTypes.AddOn ? "Add-ons" : `${optionType.name} Options`}
          addOptionLabel={
            optionType.name === OptionTypes.AddOn
              ? `Add an ${optionType.name}`
              : optionType.name === OptionTypes.Interior
                ? `Add an ${optionType.name} Option`
                : `Add ${optionType.name} Option`
          }
          options={metadata.otherOptions.filter(({ type, active }) => type.name === optionType.name && active)}
          disabledOptions={disabledOptions.filter(
            ({ option }) => option.type?.name === optionType.name && option.active,
          )}
          readOnly={readOnly}
          onNewOption={assignNewOption}
          onRemoveOption={removeOption}
          selectedOptions={populatedOptions.filter(({ type }) => type.name === optionType.name)}
        />
      ))}
    </>
  );
}

type Option = LotDetailDrawerMetadata["otherOptions"][0];
type SelectedOptions = LotDetailDrawerOptionFragment & { autoAdded?: Date };

function OptionSection(props: {
  label: string;
  addOptionLabel: string;
  selectedOptions: SelectedOptions[];
  options: Option[];
  disabledOptions: { option: Option; reasons: { type: DisabledReason; option: Option }[] }[];
  readOnly?: boolean;
  onNewOption: (id?: string) => void;
  onRemoveOption: (id?: string) => void;
}) {
  const { label, addOptionLabel, selectedOptions, options, disabledOptions, readOnly, onNewOption, onRemoveOption } =
    props;
  const tid = useTestIds(props, `optionsSection_${label}`);
  const [showInput, setShowInput] = useState(false);

  const availableOptions = useMemo(
    () =>
      options
        .filter(({ id }) => !selectedOptions.some((opt) => opt.id === id))
        .sortBy(({ id }) => (disabledOptions.some((o) => o.option.id === id) ? 1 : 0)),
    [options, selectedOptions, disabledOptions],
  );

  useEffect(() => {
    if (readOnly) {
      setShowInput && setShowInput(false);
    }
  }, [readOnly, setShowInput]);

  const onSelect = (option?: string) => {
    onNewOption(option);
    setShowInput(false);
  };

  return (
    <div css={Css.mb8.$} {...tid}>
      <div css={Css.dg.gtc("auto auto").w100.mb1.$}>
        <div css={Css.baseMd.gray900.lh("32px").$}>{label}</div>
        {!readOnly && (
          <div css={Css.ta("right").$}>
            <Button
              size="sm"
              label={addOptionLabel}
              disabled={!availableOptions.length || showInput}
              tooltip={availableOptions.length === 0 && "No more options available"}
              variant="tertiary"
              onClick={() => setShowInput((v) => !v)}
            />
          </div>
        )}
      </div>
      {selectedOptions.length === 0 && !showInput && (
        <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no {label.toLowerCase()} for this project
        </div>
      )}
      {showInput && (
        <div css={Css.bgGray100.br4.mb1.p1.$}>
          <SelectField
            compact
            label={`New Option ${label}`}
            labelStyle="hidden"
            options={availableOptions}
            getOptionMenuLabel={(o) => (
              <div css={Css.w100.df.jcsb.$}>
                <span css={Css.maxw75.$}>
                  {o?.code ? `${o.code} - ` : ""}
                  {o?.name}
                </span>
                <span>{o?.location?.name ?? "No location"}</span>
              </div>
            )}
            getOptionValue={(o) => o?.id}
            getOptionLabel={(o) => `${o.code} - ${o.name}`}
            disabledOptions={disabledOptions.map(({ option, reasons }) => ({
              value: option.id,
              reason: REASONS_MAP[reasons[0].type](reasons[0].option.name),
            }))}
            value={"" as string}
            onSelect={onSelect}
          />
        </div>
      )}
      {selectedOptions.map((option) => (
        <RowField key={option.id} option={option} readOnly={readOnly} onRemove={(opt) => onRemoveOption(opt.id)} />
      ))}
    </div>
  );
}

function RowField(props: { option: SelectedOptions; readOnly?: boolean; onRemove: (option: Option) => void }) {
  const { option, readOnly, onRemove } = props;
  const tid = useTestIds(props, `option_${option.id}`);
  const { isHovered, hoverProps } = useHover({});

  return (
    <div
      {...tid}
      css={
        Css.gap2.dg.gtc("70px 200px auto").aic.bgGray100.br4.p2.mb1.important.if(!!option.autoAdded).bss.bw2.bcGreen300
          .$
      }
      {...hoverProps}
    >
      <div css={Css.tiny.gray900.truncate.$} title={option.code}>
        {option.code}
      </div>
      <div css={Css.tiny.gray900.pPx(2).$}>
        <ArchivedTag active={option.active}>{option.name}</ArchivedTag>
      </div>
      <div css={Css.df.jcsb.aic.$}>
        <div css={Css.tiny.gray900.$}>{option.location?.name ?? "No location"}</div>
        {readOnly && <StatusChip updatedAt={option.updatedAt} />}
        {!readOnly && (
          <HoverDelete
            compact
            data-testid={`deleteOption`}
            icon="x"
            visible={isHovered}
            onClick={() => onRemove(option)}
          />
        )}
      </div>
    </div>
  );
}
