import { Accordion, AccordionProps, Css } from "@homebound/beam";

type AccordionCardProps = AccordionProps;

export function AccordionCard(props: AccordionCardProps) {
  return (
    <div css={Css.bshBasic.bgWhite.br8.ba.bcGray200.$}>
      <Accordion {...props} topBorder={false} bottomBorder={false} />
    </div>
  );
}
