import { Css, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";

// helper for rendering custom static headers
export function StaticHeaderField({ label, value }: { label: string; value: string | ReactNode }) {
  const testIds = useTestIds({}, `${label}Header`);
  return (
    <figure {...testIds}>
      <figcaption css={Css.gray700.xs.$}>{label}</figcaption>
      <div css={Css.smMd.wsnw.$}>{value}</div>
    </figure>
  );
}
