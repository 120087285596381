import { Button, Css, Icon, Palette, TextField, TextFieldApi } from "@homebound/beam";
import { AnimatePresence, motion } from "framer-motion";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Column, ToDoFilterWithDateFilterValue } from "src/components/to-dos/ToDosBoard";
import { useSaveToDosMutation } from "src/generated/graphql-types";
import { useComponentVisible } from "src/hooks/useComponentVisible";
import { PersonalDashboardFilter } from "src/routes/personal-dashboard/useHeaderFilterDefs";

export type ToDoQuickAddCardProps = {
  column?: Column;
  filter?: ToDoFilterWithDateFilterValue | PersonalDashboardFilter;
  projectId: string | undefined;
};

export function ToDoQuickAddCard(props: ToDoQuickAddCardProps) {
  const { column, filter, projectId } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible<HTMLFormElement>(false);
  const [name, setName] = useState<string | undefined>();
  const textFieldApi = useRef<TextFieldApi | undefined>();
  const [saveToDosMutation] = useSaveToDosMutation();

  useEffect(() => {
    !isComponentVisible && setName(undefined);
  }, [isComponentVisible]);

  async function onSave(name: string | undefined) {
    if (!name) return;

    const toDo = {
      name,
      status: column?.code,
      ...getDefaultsFromFilter(filter),
    };

    await saveToDosMutation({
      variables: { toDos: [toDo] },
      refetchQueries: ["ToDos"],
    });
    setName(undefined);
    textFieldApi.current && textFieldApi.current.focus();
  }

  async function onSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    await onSave(name);
  }

  function onClose() {
    setName(undefined);
    setIsComponentVisible(false);
  }

  function getDefaultsFromFilter(filter?: ToDoFilterWithDateFilterValue | PersonalDashboardFilter) {
    const firstProject = filter?.project?.[0] || projectId;
    const firstCohort = filter?.cohort?.[0];
    const assigneeId = filter?.internalUser?.[0];
    const type = filter?.type?.[0];
    return {
      parentId: firstProject || firstCohort,
      ...(assigneeId ? { assigneeIds: [assigneeId] } : {}),
      type,
    };
  }
  return (
    <div css={Css.br4.p2.bgWhite.bshBasic.$}>
      {!isComponentVisible && (
        <button
          css={Css.df.fdr.aic.cursorPointer.w100.$}
          data-testid="quickAddCardButton"
          onClick={() => setIsComponentVisible(true)}
        >
          <Icon color={Palette.Blue700} inc={2} icon="plus" />
          <span css={Css.gray600.pl1.sm.$}>Add a To-Do</span>
        </button>
      )}
      <AnimatePresence initial={false}>
        {isComponentVisible && (
          <motion.form
            ref={ref}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto" },
              collapsed: { height: 0 },
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            onSubmit={onSubmit}
            data-testid="quickAddCardForm"
          >
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{ duration: 0 }}
            >
              <TextField
                autoFocus
                labelStyle="hidden"
                label="Add a To-Do"
                data-testid="nameField"
                value={name}
                onChange={setName}
                onEnter={onSubmit}
                api={textFieldApi}
              />
              <div css={Css.df.fdrr.gap1.mt2.$}>
                {/* `onClick` is required for buttons, but because this button is of `type=submit` within a form then it doesn't need an onclick handler. So make `onClick` a no-op. */}
                <Button data-testid="createButton" label="Create" type="submit" disabled={!name} onClick={() => {}} />
                <Button data-testid="cancelButton" label="Cancel" variant="tertiary" onClick={onClose} />
              </div>
            </motion.div>
          </motion.form>
        )}
      </AnimatePresence>
    </div>
  );
}
