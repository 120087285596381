import {
  Button,
  ButtonMenu,
  Chip,
  Css,
  DateRangeFilterValue,
  FilterDefs,
  Filters,
  dateRangeFilter,
  multiFilter,
  singleFilter,
  useComputed,
  useGridTableApi,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { differenceInCalendarDays } from "date-fns";
import { useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  DateOperation,
  InputMaybe,
  InternalUserDetailFragment,
  LotType,
  NamedFragment,
  Scalars,
  WarrantyTicketDetailsFragment,
  WarrantyTicketFilter,
  WarrantyTicketItemDetailsFragment,
  WarrantyTicketItemStatus,
  WarrantyTicketItemStatusesFragment,
  WarrantyTicketPageDetailsFragment,
  WarrantyTicketStatus,
  WarrantyTicketStatusesFragment,
  useCurrentInternalUserQuery,
  useSaveWarrantyTicketItemsMutation,
  useWarrantyTicketFiltersQuery,
  useWarrantyTicketsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { PageHeader } from "../layout/PageHeader";
import { TableActions } from "../layout/TableActions";
import { WarrantyPageTable, WarrantyRow } from "./WarrantyPageTable";
import { ChangeStatusModal } from "./components/ChangeStatusModal";
import { CreateWarrantyTicketModal } from "./components/CreateWarrantyTicketModal";

export function WarrantyPage() {
  const result = useWarrantyTicketFiltersQuery();
  return queryResult(
    result,
    ({ warrantyTicketItemStatusesDetail, warrantyTicketStatusesDetail, markets, internalUsers }) => (
      <WarrantyDataView
        warrantyTicketItemStatusesDetail={warrantyTicketItemStatusesDetail}
        warrantyTicketStatusesDetail={warrantyTicketStatusesDetail}
        markets={markets}
        teamMembers={internalUsers}
      />
    ),
  );
}

type WarrantyDataViewProps = {
  warrantyTicketItemStatusesDetail: WarrantyTicketItemStatusesFragment[];
  warrantyTicketStatusesDetail: WarrantyTicketStatusesFragment[];
  markets: NamedFragment[];
  teamMembers: InternalUserDetailFragment[];
};

function WarrantyDataView(props: WarrantyDataViewProps) {
  const { warrantyTicketItemStatusesDetail, warrantyTicketStatusesDetail, markets, teamMembers } = props;
  const { openModal } = useModal();
  const [saveWarrantyTicketItems] = useSaveWarrantyTicketItemsMutation();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const tableApi = useGridTableApi<WarrantyRow>();
  const selectedWarrantyTicketItemIds = useComputed(
    () => tableApi.getSelectedRows("warrantyTicketItem").map((r) => r.data.wti.id),
    [tableApi],
  );

  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const currentUser = currentInternalUserData?.currentInternalUser;

  const filterDefs: FilterDefs<WarrantyFilter> = useMemo(() => {
    const itemStatuses = multiFilter({
      label: "Item Status",
      options: warrantyTicketItemStatusesDetail,
      getOptionValue: (o) => o.code,
      getOptionLabel: (o) => o.name,
    });
    const marketIds = multiFilter({
      label: "Market",
      options: markets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const urgent = singleFilter({
      label: "Urgency",
      options: [
        { label: "Urgent", value: "true" },
        { label: "Not Urgent", value: "false" },
      ],
      getOptionValue: (o) => o.value,
      getOptionLabel: (o) => o.label,
    });

    const projectLotType = multiFilter({
      options: Object.values(LotType),
      getOptionValue: (v) => v,
      getOptionLabel: (v) => v,
    });

    const statuses = multiFilter({
      label: "Ticket Status",
      options: warrantyTicketStatusesDetail,
      getOptionValue: (o) => o.code,
      getOptionLabel: (o) => o.name,
      defaultValue: [WarrantyTicketStatus.NotStarted, WarrantyTicketStatus.InProgress],
    });

    const currentTeamMember = teamMembers.find((tm) => tm.id === currentUser?.id);
    const otherTeamMembers = teamMembers.filter((tm) => tm.id !== currentUser?.id);
    const teamMembersOptions = currentTeamMember ? [currentTeamMember, ...otherTeamMembers] : teamMembers;

    const assigneeIds = multiFilter({
      label: "Assigned to",
      options: teamMembersOptions,
      getOptionValue: (o) => o.id,
      getOptionLabel: ({ name, id }) => (id === currentUser?.id ? `${name} (Me)` : name),
      defaultValue: currentTeamMember ? [currentTeamMember.id] : undefined,
    });

    const itemCreatedDateRange = dateRangeFilter({
      label: "Date submitted",
    });

    return { itemStatuses, marketIds, urgent, itemCreatedDateRange, projectLotType, statuses, assigneeIds };
  }, [currentUser, warrantyTicketItemStatusesDetail, markets, warrantyTicketStatusesDetail, teamMembers]);

  const { filter, setFilter } = usePersistedFilter<WarrantyFilter>({
    storageKey: "warranty-filter",
    filterDefs,
  });

  const query = useWarrantyTicketsQuery({
    variables: {
      filter: {
        ...filter,
        itemCreatedDateRange: filter.itemCreatedDateRange?.value
          ? {
              op: DateOperation.Between,
              value: new DateOnly(new Date(filter.itemCreatedDateRange.value.from!)),
              value2: new DateOnly(new Date(filter.itemCreatedDateRange.value.to!)),
            }
          : undefined,
        urgent: filter.urgent ? filter.urgent === "true" : undefined,
      },
      statuses: filter.itemStatuses,
      itemCreatedDateRange: filter.itemCreatedDateRange?.value
        ? {
            op: DateOperation.Between,
            value: new DateOnly(new Date(filter.itemCreatedDateRange.value.from!)),
            value2: new DateOnly(new Date(filter.itemCreatedDateRange.value.to!)),
          }
        : undefined,
    },
  });

  return queryResult(query, {
    data: ({ warrantyTickets }) => {
      return (
        <>
          <PageHeader
            title="Warranty"
            right={
              <Button
                label="Create New"
                onClick={() => openModal({ content: <CreateWarrantyTicketModal />, size: "md" })}
              />
            }
          />
          <TableActions>
            <Filters filter={filter} onChange={setFilter} filterDefs={filterDefs} numberOfInlineFilters={2} />
            <div css={Css.mla.df.gap1.$}>
              <SearchBox onSearch={setSearchFilter} />
              <ButtonMenu
                items={[
                  {
                    label: "Change Status",
                    disabled: selectedWarrantyTicketItemIds.length === 0,
                    onClick: async () =>
                      openModal({
                        content: (
                          <ChangeStatusModal
                            warrantyTicketItemStatusesDetail={warrantyTicketItemStatusesDetail}
                            onConfirmAction={async (status) => {
                              await saveWarrantyTicketItems({
                                variables: {
                                  input: {
                                    warrantyTicketItems: selectedWarrantyTicketItemIds.map((id) => ({ id, status })),
                                  },
                                },
                              });
                              tableApi.clearSelections();
                            }}
                          />
                        ),
                      }),
                  },
                ]}
                trigger={{ label: "Actions All" }}
              />
            </div>
          </TableActions>
          <WarrantyPageTable
            warrantyTickets={warrantyTickets}
            tableApi={tableApi}
            searchFilter={searchFilter}
            warrantyTicketItemStatusesDetail={warrantyTicketItemStatusesDetail}
            teamMembers={teamMembers}
          />
        </>
      );
    },
  });
}

type WarrantyFilter = Omit<
  WarrantyTicketFilter,
  "projectId" | "homeownerVisible" | "urgent" | "itemCreatedDateRange"
> & {
  urgent: InputMaybe<Scalars["String"]>;
  itemCreatedDateRange: DateRangeFilterValue<string>;
};

export function getWarrantyItemDurationChip(wti: WarrantyTicketItemDetailsFragment) {
  // check wti item exist
  if (!wti) {
    return undefined;
  }
  // if completed or reject return undefined
  if (wti.status.code === WarrantyTicketItemStatus.Completed || wti.status.code === WarrantyTicketItemStatus.Rejected) {
    return undefined;
  }
  // find the diff time last updated and current date
  const diffDays = differenceInCalendarDays(new Date(), wti.statusUpdatedAt!);
  // if current status Submitted, and accepted have the same colors
  if (wti.status.code === WarrantyTicketItemStatus.Submitted || wti.status.code === WarrantyTicketItemStatus.Accepted) {
    const chipType = () => {
      if (diffDays <= 1) {
        return "success";
      } else if (diffDays <= 3) {
        return "caution";
      } else {
        return "warning";
      }
    };

    return {
      content: () => <Chip text={`${diffDays}d`} type={chipType()} />,
      sortValue: diffDays,
    };
  } else if (
    wti.status.code === WarrantyTicketItemStatus.OnHold ||
    wti.status.code === WarrantyTicketItemStatus.Scheduled
  ) {
    const chipType = () => {
      if (diffDays <= 7) {
        return "success";
      } else if (diffDays <= 14) {
        return "caution";
      } else {
        return "warning";
      }
    };
    return {
      content: () => <Chip text={`${diffDays}d`} type={chipType()} />,
      sortValue: diffDays,
    };
  }
  return undefined;
}

export function getWarrantyTicketDurationChip(wt: WarrantyTicketDetailsFragment | WarrantyTicketPageDetailsFragment) {
  // if no items return undefined
  if (wt.items.length === 0) {
    return undefined;
  }
  // check if all items are completed or rejected then the duration will be difference between created and last updated
  if (
    wt.items.every(
      (item) =>
        item.status.code === WarrantyTicketItemStatus.Completed ||
        item.status.code === WarrantyTicketItemStatus.Rejected,
    )
  ) {
    // find the last updated item
    const lastUpdatedItem = wt.items.reduce((prev, current) =>
      prev.updatedAt > current.statusUpdatedAt! ? prev : current,
    );
    const diffDays = differenceInCalendarDays(lastUpdatedItem.statusUpdatedAt, wt.createdAt);
    return {
      content: () => <Chip text={`${diffDays}d`} type="success" />,
      sortValue: diffDays,
    };
  }
  // else the duration will be difference between created and current date
  const diffDays = differenceInCalendarDays(new Date(), wt.createdAt);
  return {
    content: () => <Chip text={`${diffDays}d`} type="caution" />,
    sortValue: diffDays,
  };
}
