import { Css, useComputed, useTestIds } from "@homebound/beam";
import { Price, emptyCellDash } from "src/components";
import { ConfigOptionsAndDelta } from "../AddProductOfferingConfigPage";
import { ConflictTag } from "./ConflictTag";
import { OptionSelect } from "./OptionSelect";
import { GroupedOptionsProps } from "./OptionsByTypeSection";
import { PrereqTag } from "./PrereqTag";

export function OptionSection(
  props: Omit<GroupedOptionsProps, "groupedRpos"> & {
    configData: ConfigOptionsAndDelta;
    disabled: boolean;
    conflicts: string[];
    missingPreReqs: string[];
  },
) {
  const { formState, recomputeCosts, configData, disabled, missingPreReqs, conflicts } = props;
  const { rpo, delta } = configData;
  const selectedRpos = useComputed(
    () => formState.value.readyPlanOptions.map((os) => os.readyPlanOptionId).compact(),
    [formState],
  );
  const tid = useTestIds({});

  return (
    <div key={rpo.id} css={Css.base.dg.gtc("1fr .35fr .3fr .1fr").aic.gap2.bb.bcGray200.pyPx(12).$}>
      <div css={Css.smBd.wsn.oh.add("textOverflow", "ellipsis").onHover.ov.h6.$} {...tid.opt}>
        <div css={Css.h2.if(disabled).gray400.$}>{rpo.displayName}</div>
      </div>

      <div css={Css.df.fdc.gap1.aic.jcc.$}>
        {conflicts.nonEmpty ? <ConflictTag conflicts={conflicts} /> : <div />}
        {rpo.optionPrerequisites.nonEmpty ? <PrereqTag preReqs={rpo.optionPrerequisites} /> : <div />}
      </div>

      <div css={Css.sm.gray700.$}>
        {delta?.totalCostInCents &&
        // We only show deltas in this screen for RPOs not yet selected
        !selectedRpos.includes(delta.readyPlanOption.id) ? (
          <Price valueInCents={delta?.totalCostInCents} displayDirection />
        ) : (
          emptyCellDash
        )}
      </div>

      <OptionSelect
        rpo={rpo}
        disabled={conflicts.nonEmpty || missingPreReqs.nonEmpty}
        formState={formState}
        recomputeCosts={recomputeCosts}
      />
    </div>
  );
}
