import {
  Button,
  ButtonMenu,
  collapseColumn,
  column,
  Css,
  dateColumn,
  dateRangeFilter,
  DateRangeFilterValue,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  numericColumn,
  OffsetAndLimit,
  Pagination,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  singleFilter,
  TriggerNoticeProps,
  useComputed,
  useGridTableApi,
  useGroupBy,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { chipCell, dateCell, linkHeader, priceCell, SearchBox, tagCell } from "src/components";
import { LoadingTable } from "src/components/LoadingTable";
import { baseDownloadUrl } from "src/context";
import {
  Commitment,
  CommitmentsFilter,
  CommitmentStatus,
  DateOperation,
  DevelopmentPurchaseOrderQuery,
  PurchaseOrderPage_CommitmentChangeOrderFragment,
  PurchaseOrderPage_CommitmentFragment,
  PurchaseOrderPage_DevelopmentFiltersFragment,
  useDevelopmentPurchaseOrderFiltersQuery,
  useDevelopmentPurchaseOrderQuery,
  useSaveCommitmentsMutation,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentContractOverviewUrl } from "src/RouteUrls";
import {
  commitmentStatusToTagTypeMapper,
  foldEnum,
  formatList,
  groupBy,
  pageSchema,
  pluralize,
  StateHook,
} from "src/utils";
import { DateOnly } from "src/utils/dates";
import { openDownload } from "src/utils/window";
import {
  ReassignOption,
  useReassignPurchaseOrdersWizard,
} from "../reassign-purchase-orders/ReassignPurchaseOrdersWizard";

type PurchaseOrdersPageProps = {
  isGlobal?: boolean;
  isTrades?: boolean;
};

type DevelopmentIdParam = { developmentId: string | undefined };

export function PurchaseOrdersPage({ isGlobal, isTrades }: PurchaseOrdersPageProps) {
  const { developmentId } = useParams<DevelopmentIdParam>();
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<FilterOptions>({});
  const { data: filterOptions } = useDevelopmentPurchaseOrderFiltersQuery({
    variables: { developmentId: developmentId! },
    fetchPolicy: "cache-first",
    skip: isGlobal,
  });

  const { loading, data } = useDevelopmentPurchaseOrderQuery({
    variables: {
      filter: {
        ...applyFilters(filter),
        search,
        developmentId,
      },
      page: pageSettings,
    },
  });

  return (
    <>
      <>
        {(!isGlobal || isTrades) && <PageHeader title="Purchase Orders" />}
        {!loading ? (
          <PurchaseOrdersView
            developmentId={developmentId}
            data={data!.commitmentsPage}
            pageSettings={[pageSettings, setPageSettings]}
            filterOptions={filterOptions?.development}
            filter={filter}
            setFilter={setFilter}
            setSearch={setSearch}
            isGlobal={isGlobal}
          />
        ) : (
          <LoadingTable />
        )}
      </>
    </>
  );
}

type PurchaseOrdersViewProps = DevelopmentIdParam & {
  data: DevelopmentPurchaseOrderQuery["commitmentsPage"];
  filterOptions: PurchaseOrderPage_DevelopmentFiltersFragment | undefined;
  filter: FilterOptions;
  setFilter: Dispatch<SetStateAction<FilterOptions>>;
  setSearch: Dispatch<SetStateAction<string>>;
  pageSettings: StateHook<OffsetAndLimit>;
  isGlobal: boolean | undefined;
};

function PurchaseOrdersView(props: PurchaseOrdersViewProps) {
  const { developmentId, data, filterOptions, filter, setFilter, isGlobal, pageSettings, setSearch } = props;
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const tableApi = useGridTableApi<Row>();
  const filterDefs = useMemo(() => createFilters(filterOptions), [filterOptions]);
  const selectedCommitments = useComputed(() => {
    return tableApi.getSelectedRows().filter((r) => r.kind === "co" || r.kind === "commitment") as GridDataRow<
      CommitmentRow | CommitmentChangeOrderRow
    >[];
  }, [tableApi]);
  const [saveCommitmentsMutation] = useSaveCommitmentsMutation();
  const groupBy = useGroupBy<GroupByValue>({
    project: "Project",
    tradePartner: "Trade partner",
  });

  const onVoid = useCallback(async () => {
    if (!validateSelectedCommitments(selectedCommitments, "voided", triggerNotice)) return;
    return openModal({
      content: (
        <ConfirmationModal
          onConfirmAction={async () => {
            const { data } = await saveCommitmentsMutation({
              variables: { input: selectedCommitments.map((c) => ({ id: c.id, status: CommitmentStatus.Voided })) },
              // after mutation completes, update cache to remove deleted draft commitments
              update: (cache) =>
                selectedCommitments
                  .filter((c) => c.data.status === CommitmentStatus.Draft)
                  .forEach((c) => cache.evict({ id: `Commitment:${c.id}` })),
            });
            if (data) {
              const savedCommitments = data.saveCommitments.commitments;
              const savedChangeOrders = savedCommitments.flatMap((c) => c.changeOrders);
              // 1 Purchase Order and 3 Change Orders have been voided successfully
              // if no mutation errors were thrown, we can assume all selectedCommitments were successfully voided
              triggerNotice({
                message: `${selectedCommitments.length} ${pluralize(savedCommitments, "Purchase Order")}
                ${
                  !savedChangeOrders.isEmpty
                    ? `and ${savedChangeOrders.length} ${pluralize(savedChangeOrders, "Change Order")}`
                    : ""
                } ${pluralize([...selectedCommitments, ...savedChangeOrders], "has", "have")}
                been voided successfully`,
                icon: "success",
              });
            }
          }}
          title="Void Purchase Orders"
          label="Void"
          confirmationMessage={
            <>
              <p css={Css.smMd.mb2.$}>
                {selectedCommitments.length} {pluralize(selectedCommitments, "purchase order")} selected.
              </p>
              <p>
                Are you sure you want to void
                {pluralize(selectedCommitments, " this purchase order", " these purchase orders")}? This action cannot
                be undone.
              </p>
            </>
          }
        />
      ),
    });
  }, [selectedCommitments, saveCommitmentsMutation, openModal, triggerNotice]);

  const onChangeTradePartner = useCallback(() => {
    if (!validateSelectedCommitments(selectedCommitments, "edited", triggerNotice)) return;
    return openModal({
      content: (
        <ReassignPurchaseOrderModal
          mode="trade partner"
          selectedCommitmentIds={selectedCommitments.map((sc) => sc.id)}
        />
      ),
    });
  }, [selectedCommitments, openModal, triggerNotice]);

  const onChangeVersion = useCallback(() => {
    if (!validateSelectedCommitments(selectedCommitments, "edited", triggerNotice)) return;
    return openModal({
      content: (
        <ReassignPurchaseOrderModal mode="version" selectedCommitmentIds={selectedCommitments.map((sc) => sc.id)} />
      ),
    });
  }, [selectedCommitments, openModal, triggerNotice]);

  const onDownload = useCallback(() => {
    openDownload(
      `${baseDownloadUrl()}/zip?type=commitments&ids=${selectedCommitments
        .map((cLike) => cLike.id)
        .sort()
        .join()}`,
    );
  }, [selectedCommitments]);

  const actionMenuItems = useMemo(
    () => [
      { label: "Void", disabled: !selectedCommitments.length, onClick: onVoid },
      { label: "Change Trade Partner", disabled: !selectedCommitments.length, onClick: onChangeTradePartner },
      { label: "Change Version", disabled: !selectedCommitments.length, onClick: onChangeVersion },
      { label: "Download PDFs", disabled: !selectedCommitments.length, onClick: onDownload },
    ],
    [selectedCommitments, onVoid, onChangeTradePartner, onChangeVersion, onDownload],
  );

  const columns = useMemo(() => createColumns(developmentId, groupBy.value), [developmentId, groupBy.value]);
  const rows = useMemo(() => createRows(data.commitments, groupBy.value), [data.commitments, groupBy.value]);
  return (
    <>
      <TableActions>
        <Filters groupBy={groupBy} filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <div css={Css.df.gap1.$}>
          <SearchBox debounceDelayInMs={500} onSearch={setSearch} />
          {!isGlobal && <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />}
        </div>
      </TableActions>
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={rows}
          style={{ grouped: true, rowHeight: "fixed" }}
          stickyHeader
          fallbackMessage="No Commitments or Change Orders for Development"
          api={tableApi}
        />
        <Pagination page={pageSettings} totalCount={data.pageInfo.totalCount} />
      </ScrollableContent>
    </>
  );
}

type CommitmentChangeOrderRowData = PurchaseOrderPage_CommitmentChangeOrderFragment;
type CommitmentRowData = PurchaseOrderPage_CommitmentFragment;
type HeaderRow = { kind: "header" };
type GroupedRow = { kind: "group"; data: CommitmentRowData[] };
type CommitmentChangeOrderRow = { kind: "co"; data: CommitmentChangeOrderRowData };
type CommitmentRow = { kind: "commitment"; data: CommitmentRowData; children: CommitmentChangeOrderRow[] };
type Row = HeaderRow | GroupedRow | CommitmentRow | CommitmentChangeOrderRow;

const createColumns = (
  developmentId: DevelopmentIdParam["developmentId"],
  groupByKey: GroupByValue,
): GridColumn<Row>[] => [
  collapseColumn<Row>(),
  selectColumn<Row>(),
  column<Row>({
    header: "PO #",
    co: ({ accountingNumber, blueprintUrl }) => linkHeader(String(accountingNumber), blueprintUrl.path),
    commitment: ({ accountingNumber, blueprintUrl }) => linkHeader(String(accountingNumber), blueprintUrl.path),
    group: ([row]) => ({
      content: foldEnum(groupByKey, {
        tradePartner: row.tradePartner?.name,
        project: row.project.name,
      }),
      colspan: 5,
    }),
    w: "100px",
  }),
  column<Row>({
    header: "PO Type",
    group: emptyCell,
    co: () => "CO",
    commitment: (row) => (row.isBidCommitment ? "Dev PO" : "Ind PO"),
    w: "80px",
  }),
  column<Row>({
    header: "Address",
    group: emptyCell,
    co: (row) => row.commitment.project.buildAddress.street1,
    commitment: (row) => row.project.buildAddress.street1,
  }),
  column<Row>({
    header: "Trade Partner",
    co: ({ tradePartner }) => tradePartner?.name,
    commitment: ({ tradePartner }) => tradePartner?.name,
    group: emptyCell,
  }),
  column<Row>({
    header: "Item Codes",
    co: ({ items }) => chipCell(items.map((i) => ({ text: i.fullCode, title: i.name }))),
    commitment: ({ items }) => chipCell(items.map((i) => ({ text: i.fullCode, title: i.name }))),
    group: emptyCell,
    w: 2,
  }),
  dateColumn<Row>({
    header: "Released On",
    group: emptyCell,
    co: ({ executionDate }) => dateCell(executionDate),
    commitment: ({ executionDate }) => dateCell(executionDate),
    w: "100px",
  }),
  numericColumn<Row>({
    header: "Version",
    co: (row) => {
      const bidContractRevision = row.commitment.bidContractRevision;
      return bidContractRevision?.id && developmentId
        ? linkHeader(
            bidContractRevision.version,
            createDevelopmentContractOverviewUrl(developmentId, bidContractRevision?.id),
          )
        : undefined;
    },
    commitment: (row) => {
      const bidContractRevision = row.bidContractRevision;
      return bidContractRevision?.id && developmentId
        ? linkHeader(
            bidContractRevision.version,
            createDevelopmentContractOverviewUrl(developmentId, bidContractRevision?.id),
          )
        : undefined;
    },
    group: emptyCell,
    align: "right",
    w: "80px",
  }),
  numericColumn<Row>({
    header: "Committed Cost",
    co: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
    commitment: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
    group: emptyCell,
  }),
  column<Row>({
    header: "Status",
    co: (row) => tagCell(commitmentStatusToTagTypeMapper[row.status], row.statusText),
    commitment: (row) => tagCell(commitmentStatusToTagTypeMapper[row.status], row.statusText),
    group: emptyCell,
    clientSideSort: false,
    w: "100px",
  }),
];

function createRows(commitments: PurchaseOrderPage_CommitmentFragment[], groupByKey: GroupByValue): GridDataRow<Row>[] {
  const groupedCommitments = groupPurchaseOrdersBy(commitments, groupByKey);
  return [
    simpleHeader,
    ...groupedCommitments.map(([id, commitments]) => ({
      kind: "group" as const,
      id: id,
      data: commitments,
      children: commitments.map((cl) => ({
        kind: "commitment" as const,
        id: cl.id,
        data: cl,
        inferSelectedState: false as const,
        children: cl.changeOrders.map((co) => ({
          kind: "co" as const,
          id: co.id,
          data: co,
          initCollapsed: true,
        })),
      })),
    })),
  ];
}

function groupPurchaseOrdersBy(commitments: PurchaseOrderPage_CommitmentFragment[], groupByKey: GroupByValue) {
  const groupedRows = groupBy(commitments, (cl) => {
    return foldEnum(groupByKey, {
      tradePartner: cl.tradePartner?.id || "",
      project: cl.project.id,
    });
  });

  return Object.entries(groupedRows);
}

enum GroupByValue {
  project = "project",
  tradePartner = "tradePartner",
}

const validateSelectedCommitments = (
  commitmentsLike: GridDataRow<CommitmentRow | CommitmentChangeOrderRow>[],
  action: string,
  triggerNotice: (props: TriggerNoticeProps) => { close: () => void },
): boolean => {
  const validationAlert = (accountingNumbers: number[], reason: string) =>
    triggerNotice({
      message: `${pluralize(commitmentsLike, "PO")}: ${formatList(
        accountingNumbers,
      )} can't be ${action} because ${reason}`,
      icon: "warning",
    });

  // Verify if selected rows are already voided
  const voidedCommitments = commitmentsLike
    .filter((sc) => sc.data.status === CommitmentStatus.Voided)
    .map((c) => c.data.accountingNumber);

  if (!voidedCommitments.isEmpty) {
    const reason = pluralize(voidedCommitments, "it's already been voided", "they have already been voided");
    validationAlert(voidedCommitments, reason);
    return false;
  }

  // Verify if all selected rows are commitments (not change orders) and are allowed to be voided
  const notVoidableCommitments = commitmentsLike
    .filter((c) => c.data.__typename === "CommitmentChangeOrder" || !(c.data as Commitment).canBeVoided.allowed)
    .map((c) => c.data.accountingNumber);

  if (!notVoidableCommitments.isEmpty) {
    const reason = pluralize(
      notVoidableCommitments,
      "it has a bill or is a commitment change order",
      "they have a bill or are commitment change orders",
    );
    validationAlert(notVoidableCommitments, reason);
    return false;
  }
  // Purchase orders are valid to be voided/reassigned
  return true;
};

type FilterOptions = Partial<{
  tradePartner: string;
  status: CommitmentStatus;
  costCode: string;
  executionDate: DateRangeFilterValue<string>;
}>;

function createFilters(developmentFilters?: PurchaseOrderPage_DevelopmentFiltersFragment) {
  return {
    status: singleFilter({
      options: Object.values(CommitmentStatus).map((cs) => ({ label: cs, value: cs })),
      getOptionLabel: (cs) => capitalCase(cs.label),
      getOptionValue: (cs) => cs.value,
      label: "Status",
    }),
    ...(developmentFilters
      ? {
          tradePartner: singleFilter({
            options: developmentFilters.tradePartners.sortByKey("name"),
            getOptionLabel: (tp) => tp.name,
            getOptionValue: (tp) => tp.id,
            label: "Trade Partner",
          }),
          costCode: singleFilter({
            options: developmentFilters.costCodes,
            getOptionLabel: (cc) => cc.number,
            getOptionValue: (cc) => cc.id,
            label: "Cost codes",
          }),
        }
      : {}),
    executionDate: dateRangeFilter({ label: "Released on" }),
  };
}

function applyFilters(filter: FilterOptions): CommitmentsFilter {
  return {
    costCodes: filter.costCode ? [filter.costCode] : undefined,
    tradePartners: filter.tradePartner ? [filter.tradePartner] : undefined,
    status: filter.status ? [filter.status] : undefined,
    executionDate: filter.executionDate?.value
      ? {
          op: DateOperation.Between,
          value: new DateOnly(new Date(filter.executionDate.value.from!)),
          value2: new DateOnly(new Date(filter.executionDate.value.to!)),
        }
      : undefined,
  };
}

type ReassignPurchaseOrderModalProps = {
  mode: ReassignOption;
  selectedCommitmentIds: string[];
};

function ReassignPurchaseOrderModal(props: ReassignPurchaseOrderModalProps) {
  const { mode, selectedCommitmentIds } = props;
  const { closeModal } = useModal();
  const openWizard = useReassignPurchaseOrdersWizard(mode, selectedCommitmentIds);

  return (
    <>
      <ModalHeader>Change {capitalCase(mode)}</ModalHeader>
      <ModalBody>
        <div>
          <p css={Css.smMd.mb2.$}>Are you sure you want to change the {mode} for the selected purchase orders (POs)?</p>
          <p>
            The proposed {mode} MUST be able to cover all cost codes in the selected POs. To change the trade for part
            of a PO, void the original PO and choose the new trade in the Lot Release flow.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label={`Change ${capitalCase(mode)}`}
          onClick={() => {
            closeModal();
            openWizard();
          }}
        />
      </ModalFooter>
    </>
  );
}
