import { Css } from "@homebound/beam";
import { OverviewTab_ProductFragment, ProductAttributeType } from "src/generated/graphql-types";
import { formatCentsToPrice } from "src/utils";

export type ProductManufacturerInformationSectionProps = {
  product: OverviewTab_ProductFragment;
};

export function ProductManufacturerInformationSection(props: ProductManufacturerInformationSectionProps) {
  const { product } = props;
  const material = product.attributes.find((attr) => attr.type === ProductAttributeType.Material)?.value;
  return (
    <div css={Css.sm.$}>
      <div css={Css.df.$}>
        {product.dimensions?.nonEmpty && (
          <div css={Css.f1.$}>
            <div css={Css.fw7.mb1.$}>Dimensions</div>
            {product.dimensions.map((dimension) => (
              <div key={`dimension-${dimension.id}`}>
                {dimension.dimensionType.name}: {dimension.rawValue}
              </div>
            ))}
          </div>
        )}
        {product.msrpInCents && (
          <div css={Css.f1.$} data-testid="msrpContainer">
            <div css={Css.fw7.$}>MSRP</div>
            <div css={Css.mt1.$}>{formatCentsToPrice(Math.abs(product.msrpInCents))}</div>
          </div>
        )}
        {material && (
          <div css={Css.f1.$} data-testid="materialContainer">
            <div css={Css.fw7.$}>Material</div>
            <div css={Css.mt1.$}>{material}</div>
          </div>
        )}
      </div>
      {product.manufacturerUrl && (
        <div css={Css.mt2.$} data-testid="manufacturerURLContainer">
          <div css={Css.fw7.$}>Manufacturer URL</div>
          <a
            data-testid="manufacturerURL"
            css={Css.mt2.wbba.whiteSpace("break-spaces").$}
            href={product.manufacturerUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {product.manufacturerUrl}
          </a>
        </div>
      )}
      {product.specifications && (
        <div css={Css.mt2.$} data-testid="productSpecifications">
          <div css={Css.fw7.$}>Product Specifications</div>
          <div css={Css.mt1.wbbw.$}>{product.specifications}</div>
        </div>
      )}
    </div>
  );
}
