import { Css } from "@homebound/beam";
import { ProgressPill } from "src/components/ProgressPill";
import { File, useUploaderContext } from "src/contexts/UploaderContext";

type FileUploadProgressProps = {
  hideOnComplete: boolean;
  children?: React.ReactNode;
};

export function FileUploadProgress(props: FileUploadProgressProps) {
  const { children, hideOnComplete } = props;
  const { files } = useUploaderContext();
  if (Object.keys(files).length === 0) return null;

  const fileProgress = Object.keys(files).map((id: string) => {
    return <FileDisplayRow hideOnComplete={hideOnComplete} file={files[id]} key={id} children={children} />;
  });

  return <>{fileProgress}</>;
}

function FileDisplayRow(props: FileUploadProgressProps & { file: File }) {
  const { children, hideOnComplete, file } = props;
  const { name, progress } = file;

  const isComplete = progress === 100;

  function progressIndicator(progress: number) {
    return isComplete ? "Success" : `${progress}%`;
  }

  return (
    <div css={Css.df.pb1.fdr.aic.gap3.if(hideOnComplete && isComplete).dn.$}>
      <div css={Css.sm.fb2.$}>{name}</div>
      {children}
      <div css={Css.wPx(200).$}>
        <ProgressPill progress={progress} changeColorOnCompleted fullWidth hideProgress />
      </div>
      <div css={Css.sm.gray700.fb0.$}>{progressIndicator(progress)}</div>
    </div>
  );
}
