import { QueryResult } from "@apollo/client";
import {
  BoundCheckboxField,
  BoundNumberField,
  BoundRadioGroupField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  px,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, Rule, useFormState } from "@homebound/form-state";
import differenceBy from "lodash/differenceBy";
import { Observer } from "mobx-react";
import { useEffect } from "react";
import { Price } from "src/components/Price";
import { ImageUploader, PendingAsset } from "src/components/uploader/ImageUploader";
import {
  AssetInput,
  HomeownerSelectionOption,
  HomeownerSelectionOptionDetailsFragment,
  HomeownerSelectionOptionInput,
  HomeownerSelectionOptionPricingType,
  HomeownerSelectionOptionQuery,
  Maybe,
  Product,
  ProductAttributeAssociationInput,
  ProductAttributeType,
  ProductImageFragment,
  PromotionStatus,
  PromotionStatusDetail,
  useHomeownerSelectionOptionLazyQuery,
  useSaveHomeownerSelectionOptionMutation,
} from "src/generated/graphql-types";
import { useUnload } from "src/hooks";
import { isNumber, queryResultDataFirst } from "src/utils";

type HomeownerSelectionOptionFormProps = {
  /** If undefined, we are adding a new custom option */
  homeownerSelectionOptionId?: string;
  homeownerSelectionId: string;
  showPricingType: boolean;
};

export function HomeownerSelectionOptionForm({
  homeownerSelectionOptionId,
  homeownerSelectionId,
  showPricingType,
}: HomeownerSelectionOptionFormProps) {
  const [getProductOption, query] = useHomeownerSelectionOptionLazyQuery();
  const [saveHomeownerSelectionOptionMutation] = useSaveHomeownerSelectionOptionMutation();

  useEffect(() => {
    if (homeownerSelectionOptionId) {
      void getProductOption({ variables: { homeownerSelectionOptionId, homeownerSelectionId } });
    }
  }, [homeownerSelectionOptionId, homeownerSelectionId, getProductOption]);

  return queryResultDataFirst(
    // Passing `{data: {}}` to handle case where the lazy query is not called.
    // Otherwise, this function will return a `no data` error.
    (homeownerSelectionOptionId ? query : { data: {} }) as QueryResult<HomeownerSelectionOptionQuery, any>,
    {
      data: (data) => (
        <HomeownerSelectionOptionFormDataView
          homeownerSelectionId={homeownerSelectionId}
          homeownerSelectionOptionId={homeownerSelectionOptionId}
          homeownerSelectionOptionDetails={data?.homeownerSelectionOption}
          showPricingType={showPricingType}
          onSave={async (productOptions) => {
            await saveHomeownerSelectionOptionMutation({
              variables: {
                input: { ...productOptions, id: homeownerSelectionOptionId },
              },
            });
          }}
        />
      ),
    },
  );
}

export type HomeownerSelectionOptionFormDataViewProps = {
  homeownerSelectionId: string;
  homeownerSelectionOptionId?: string;
  onSave: (productOptions: HomeownerSelectionOptionInput) => void;
  homeownerSelectionOptionDetails?: HomeownerSelectionOptionDetailsFragment;
  showPricingType: boolean;
};

export function HomeownerSelectionOptionFormDataView({
  homeownerSelectionId,
  homeownerSelectionOptionId,
  homeownerSelectionOptionDetails,
  showPricingType,
  onSave,
}: HomeownerSelectionOptionFormDataViewProps) {
  const { closeDrawerDetail, addCanCloseDrawerDetailCheck } = useSuperDrawer();
  const formState = useFormState({
    config: formConfig,
    init: { input: homeownerSelectionOptionDetails, map: mapHomeownerSelectionOptionsToFormState },
    addRules(state) {
      state.priceDeltaInCents.rules.push(priceDeltaRule(state));
    },
  });

  /**
   * Add event listener for native browser close, tab close or page refresh
   * events and confirm close when the form is dirty.
   *
   * TODO: Add <NavigationPrompt /> to handle route change confirmation
   */
  useUnload((e: BeforeUnloadEvent) => {
    e.preventDefault();
    if (formState.dirty) {
      e.returnValue = "";
    }
  });

  useEffect(() => {
    // set priceDeltaInCents.touched to true so errors will show when pricingType is changed
    formState.priceDeltaInCents.touched = true;
    // Add a canClose check to only allow the drawer to close when form is untouched
    function canCloseDrawerDetail() {
      return !formState.dirty;
    }
    addCanCloseDrawerDetailCheck(canCloseDrawerDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCustomProduct = !homeownerSelectionOptionId || !!homeownerSelectionOptionDetails?.product.custom;
  const { homeownerSelection } = homeownerSelectionOptionDetails ?? {};

  useEffect(() => {
    if (isCustomProduct) {
      // sets the form's isHomeownerVisible to true initially
      formState.isHomeownerVisible.value = true;
      // sets pricingType to None if not set
      if (formState.pricingType.value === undefined) {
        formState.pricingType.value = HomeownerSelectionOptionPricingType.None;
      }
    }
  }, [isCustomProduct, formState.isHomeownerVisible, formState.pricingType]);

  return (
    <>
      <SuperDrawerHeader
        title={homeownerSelection ? homeownerSelection.projectItem.displayName : "Add Custom Product"}
      />
      <Observer>
        {() => (
          <SuperDrawerContent
            actions={[
              { label: "Cancel", onClick: closeDrawerDetail, variant: "tertiary" },
              {
                label: !homeownerSelectionOptionId ? "Add Custom Product" : "Save",
                disabled: !formState.dirty || !formState.valid,
                onClick: async () => {
                  await onSave(mapFormStateToInput(formState, homeownerSelectionId, isCustomProduct));
                  // Saving the formState so that the canClose check passes
                  formState.commitChanges();
                  closeDrawerDetail();
                },
              },
            ]}
          >
            <div css={Css.df.mx3.$}>
              <div css={Css.fg1.mr8.$} data-testid="hsoForm">
                {isCustomProduct && homeownerSelectionOptionId && (
                  <div css={Css.xsMd.gray700.$}>Custom Product Option</div>
                )}
                <div css={Css.df.jcsb.aic.pb3.$}>
                  <div css={Css.baseMd.if(!isCustomProduct).maxw("280px").$}>
                    {homeownerSelectionOptionId ? formState.name.value : "Add a Custom Product"}
                  </div>
                  {!isCustomProduct && homeownerSelectionOptionDetails?.product.manufacturerUrl && (
                    <Button
                      variant="tertiary"
                      onClick={homeownerSelectionOptionDetails?.product.manufacturerUrl}
                      label="Manufacturer URL"
                    />
                  )}
                </div>
                <div css={Css.df.gap3.mb3.$}>
                  <Observer>
                    {() => (
                      <div css={Css.wPx(200).hPx(200).$}>
                        <ImageUploader
                          imageUrl={formState.pendingImage.value?.downloadUrl || formState.image.value?.downloadUrl}
                          onChange={(imageData: PendingAsset) => {
                            formState.pendingImage.set(imageData);
                          }}
                          viewOnly={!isCustomProduct}
                          xss={Css.wPx(200).hPx(200).br8.$}
                        />
                      </div>
                    )}
                  </Observer>
                  <BoundTextAreaField field={formState.name} label="Product Name" />
                </div>
                <div css={Css.df.fdc.gap2.$}>
                  {isCustomProduct ? (
                    <div css={Css.df.fdc.gap2.$}>
                      <BoundTextField field={formState.productFinish} label="Finish" />
                      <BoundTextField field={formState.productBrand} label="Brand" />
                      <BoundTextField field={formState.model} />
                      <BoundTextField field={formState.manufacturerUrl} label="Manufacturer URL" />
                    </div>
                  ) : (
                    <div css={Css.df.gap4.mb1.$}>
                      {/* TODO: update these to readOnly Beam text fields when/if they are updated to match styles */}
                      <div data-testid="productFinish" aria-readonly>
                        <label css={Css.gray700.$}>Finish</label>
                        <div>{formState.productFinish.value}</div>
                      </div>
                      <div data-testid="brand" aria-readonly>
                        <label css={Css.gray700.$}>Brand</label>
                        <div>{formState.productBrand.value}</div>
                      </div>
                      <div data-testid="model" aria-readonly>
                        <label css={Css.gray700.$}>Model</label>
                        <div>{formState.model.value}</div>
                      </div>
                    </div>
                  )}
                  <div css={Css.mb2.$}>
                    <BoundTextAreaField field={formState.description} label="Product Description" />
                  </div>
                  <div css={Css.mb2.$}>
                    <BoundTextAreaField field={formState.specifications} label="Product Specifications" />
                  </div>
                  <legend css={Css.baseMd.gray900.$}>Pricing</legend>
                  {isCustomProduct ? (
                    <div css={Css.df.fdc.gap2.$}>
                      <BoundNumberField field={formState.msrp} label="MSRP" type="cents" />
                      <BoundNumberField
                        field={formState.cost}
                        label="HB Cost"
                        type="cents"
                        onChange={(newCost) => {
                          if (isNumber(newCost)) {
                            if (homeownerSelectionOptionDetails?.canEditPrice.allowed === false) {
                              isNumber(formState.price.value) &&
                                formState.markup.set(Math.round((10000 * (formState.price.value - newCost)) / newCost));
                            } else {
                              isNumber(formState.markup.value) &&
                                formState.price.set(Math.round(newCost * (1 + formState.markup.value / 10000)));
                            }
                          }
                          formState.cost.set(newCost);
                        }}
                      />
                    </div>
                  ) : (
                    <div css={Css.df.gap4.mb1.$}>
                      <div data-testid="msrp" aria-readonly>
                        <label css={Css.gray700.db.$}>MSRP</label>
                        <Price valueInCents={formState.msrp.value} dropZero />
                      </div>
                      <BoundNumberField
                        field={formState.cost}
                        label="HB Cost"
                        type="cents"
                        disabled={homeownerSelectionOptionDetails?.canEditPrice.allowed === false}
                        onChange={(newCost) => {
                          if (isNumber(newCost)) {
                            isNumber(formState.markup.value) &&
                              formState.price.set(Math.round(newCost * (1 + formState.markup.value / 10000)));
                          }
                          formState.cost.set(newCost);
                        }}
                      />
                    </div>
                  )}
                  <BoundNumberField
                    field={formState.markup}
                    label="Markup %"
                    type="basisPoints"
                    disabled={homeownerSelectionOptionDetails?.canEditPrice.allowed === false}
                    onChange={(newPercent) => {
                      if (
                        isNumber(formState.cost.value) &&
                        isNumber(newPercent) &&
                        homeownerSelectionOptionDetails?.canEditPrice.allowed !== false
                      ) {
                        formState.price.set(Math.round(formState.cost.value * (1 + newPercent / 10000)));
                      }
                      formState.markup.set(newPercent);
                    }}
                  />
                  <BoundNumberField
                    field={formState.price}
                    label="HO Price"
                    disabled={homeownerSelectionOptionDetails?.canEditPrice.allowed === false}
                    type="cents"
                    onChange={(newPrice) => {
                      if (isNumber(formState.cost.value) && isNumber(newPrice)) {
                        formState.markup.set(
                          Math.round((10000 * (newPrice - formState.cost.value)) / formState.cost.value),
                        );
                      }
                      formState.price.set(newPrice);
                    }}
                  />
                </div>
              </div>
              <div css={Css.fg1.ml8.w(px(300)).$}>
                {showPricingType && (
                  <div data-testid="pricingType" css={Css.df.fdc.gap2.$}>
                    <div css={Css.baseMd.$}>Pricing Type</div>
                    <BoundRadioGroupField
                      field={formState.pricingType}
                      labelStyle="hidden"
                      options={[
                        {
                          label: "None",
                          value: HomeownerSelectionOptionPricingType.None,
                        },
                        {
                          label: "Included",
                          value: HomeownerSelectionOptionPricingType.Included,
                        },
                        {
                          label: "Downgrade",
                          value: HomeownerSelectionOptionPricingType.Downgrade,
                        },
                        {
                          label: "Upgrade",
                          value: HomeownerSelectionOptionPricingType.Upgrade,
                        },
                      ]}
                    />
                    <BoundNumberField
                      compact={true}
                      label="Price Difference (Optional)"
                      field={formState.priceDeltaInCents}
                      data-testid="priceDifference"
                      disabled={
                        formState.pricingType.value === HomeownerSelectionOptionPricingType.None ||
                        formState.pricingType.value === HomeownerSelectionOptionPricingType.Included
                      }
                    />
                  </div>
                )}
                <div css={Css.baseMd.my3.$}>Actions</div>
                <div css={Css.df.fdc.gap2.$}>
                  {isCustomProduct && (
                    <BoundCheckboxField
                      field={formState.promotionRequested}
                      disabled={formState.promotionStatus.value?.code === PromotionStatus.Denied}
                      label="Promote to catalog"
                    />
                  )}
                  <BoundCheckboxField
                    field={formState.isHomeownerVisible}
                    label="Show to homeowner"
                    onChange={(value: boolean) => {
                      // Show to homeowner cannot be deselected if recommended or selected
                      if (formState.isRecommendedOption.value || formState.isSelectedOption.value) {
                        return;
                      }
                      formState.isHomeownerVisible.set(value);
                    }}
                  />
                  <BoundCheckboxField
                    field={formState.isRecommendedOption}
                    label="Designer recommended"
                    onChange={(value: boolean) => {
                      // Automatically select show to homeowner if designer recommended
                      if (value && !formState.isHomeownerVisible.value) {
                        formState.isHomeownerVisible.set(true);
                      }
                      formState.isRecommendedOption.set(value);
                    }}
                  />
                  <BoundCheckboxField
                    field={formState.isSelectedOption}
                    label="Select for homeowner"
                    onChange={(value: boolean) => {
                      // Automatically select show to homeowner if selected for homeowner
                      if (value && !formState.isHomeownerVisible.value) {
                        formState.isHomeownerVisible.set(true);
                      }
                      formState.isSelectedOption.set(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </SuperDrawerContent>
        )}
      </Observer>
    </>
  );
}

type FormValue = {
  name: HomeownerSelectionOptionInput["name"];
  cost: HomeownerSelectionOption["unitCostInCents"];
  price: HomeownerSelectionOption["unitPriceInCents"];
  description: HomeownerSelectionOption["description"];
  markup: HomeownerSelectionOption["markupInCents"];
  isHomeownerVisible: HomeownerSelectionOptionInput["isHomeownerVisible"];
  isRecommendedOption: HomeownerSelectionOptionInput["isRecommendedOption"];
  isSelectedOption: HomeownerSelectionOptionInput["isSelectedOption"];
  promotionStatus: PromotionStatusDetail | null | undefined;
  promotionRequested: boolean | null | undefined;
  msrp: Product["msrpInCents"];
  model: Product["sku"];
  manufacturerUrl: Product["manufacturerUrl"];
  // The brand value to be displayed in the form
  productBrand: string | null | undefined;
  // The existing brand product attribute ID - if any.
  // This is stored so that we can remove the existing brand when the value is changed in the UI
  // This is so that the `Brand` product attribute retains its defacto 1:1 relationship with products.
  // Someday, it would be nice for direct backend support for 1:1 product attribute types
  existingBrandId: string | undefined;
  pendingImage?: PendingAsset;
  image?: ProductImageFragment;
  // The stringified product list to be displayed
  productFinish: string | null | undefined;
  // Product finish list for syncing changes made
  productFinishList: { name: string; id: string }[];
  pricingType?: Maybe<HomeownerSelectionOptionPricingType>;
  priceDeltaInCents?: Maybe<number>;
  specifications: HomeownerSelectionOptionInput["specifications"];
};

const formConfig: ObjectConfig<FormValue> = {
  name: { type: "value", rules: [required] },
  cost: { type: "value" },
  price: { type: "value" },
  description: { type: "value" },
  model: { type: "value" },
  manufacturerUrl: { type: "value" },
  productBrand: { type: "value" },
  existingBrandId: { type: "value" },
  productFinishList: {
    type: "list",
    config: {
      name: { type: "value" },
      id: { type: "value" },
    },
  },
  productFinish: { type: "value" },
  markup: { type: "value" },
  msrp: { type: "value" },
  isHomeownerVisible: { type: "value" },
  isRecommendedOption: { type: "value" },
  isSelectedOption: { type: "value" },
  promotionStatus: { type: "value" },
  promotionRequested: { type: "value" },
  image: { type: "value" },
  pendingImage: { type: "value" },
  pricingType: { type: "value" },
  priceDeltaInCents: { type: "value" },
  specifications: { type: "value" },
};

function mapHomeownerSelectionOptionsToFormState(productOption?: HomeownerSelectionOptionDetailsFragment): FormValue {
  if (!productOption) return {} as FormValue;
  const {
    name,
    unitCostInCents,
    unitPriceInCents,
    description,
    markupInCents,
    isHomeownerVisible,
    isSelectedOption,
    isRecommendedOption,
    product,
    pricingType,
    priceDeltaInCents,
    specifications,
  } = productOption;
  const { msrpInCents, sku, manufacturerUrl, attributes, images, promotionStatus } = product;
  const finishes = attributes
    .filter((attr) => attr.type === ProductAttributeType.Finish)
    .map((finish) => ({ name: finish.value, id: finish.id }));
  const brand = attributes.find((attr) => attr.type === ProductAttributeType.Brand);
  return {
    name,
    cost: unitCostInCents,
    price: unitPriceInCents,
    description,
    markup: markupInCents && unitCostInCents && (markupInCents * 10000) / unitCostInCents,
    isHomeownerVisible,
    isRecommendedOption,
    isSelectedOption,
    msrp: msrpInCents,
    model: sku,
    manufacturerUrl,
    existingBrandId: brand?.id,
    productBrand: brand?.value,
    productFinishList: finishes,
    productFinish: finishes?.map((finish) => finish.name).join(", "),
    image: images[0],
    promotionStatus,
    promotionRequested: promotionStatus
      ? promotionStatus?.code === PromotionStatus.Approved || promotionStatus?.code === PromotionStatus.Requested
      : null,
    pricingType,
    priceDeltaInCents,
    specifications: specifications || product.specifications,
  };
}

function mapFormStateToInput(
  formState: ObjectState<FormValue>,
  homeownerSelectionId: string,
  isCustomProduct: boolean,
): HomeownerSelectionOptionInput {
  const {
    name,
    cost,
    price,
    description,
    isHomeownerVisible,
    isRecommendedOption,
    isSelectedOption,
    model,
    manufacturerUrl,
    msrp,
    pendingImage,
    productFinish,
    productBrand,
    promotionRequested,
    pricingType,
    priceDeltaInCents,
    specifications,
  } = formState.changedValue;

  const productFinishes =
    productFinish?.split(",").map((finish) => ({ type: ProductAttributeType.Finish, name: finish.trim() })) || [];
  const images: AssetInput[] | undefined = formState.pendingImage.value
    ? [
        {
          contentType: pendingImage?.contentType,
          fileName: pendingImage?.fileName,
          s3Key: pendingImage?.s3Key,
        },
      ]
    : undefined;
  const attributes: ProductAttributeAssociationInput[] = [];

  // If Brand, Image or Finish changed, we need to delete the existing value and pass in the new one
  if (pendingImage && formState.image.originalValue) {
    images?.push({ id: formState.image.originalValue.id, delete: true });
  }
  if (productBrand) {
    // The product brand has been changed, we will send a new `Brand` product attribute with the new value
    attributes.push({ type: ProductAttributeType.Brand, value: productBrand });
  }
  if (formState.productBrand.dirty && formState.existingBrandId.value) {
    // If there was an existing brand on the product and we have changed it, then we need to remove it
    attributes.push({ id: formState.existingBrandId.value, remove: true });
  }

  if (productFinish) {
    const productsAttributesToRemove = differenceBy(formState.productFinishList.value, productFinishes, "name").map(
      (finish) => ({ id: finish.id, remove: true }),
    );
    const updatedAttributes: { name?: string; id?: string; type?: ProductAttributeType; remove?: boolean }[] = [];

    const productsAttributesToAdd = differenceBy(productFinishes, formState.productFinishList.value, "name");
    updatedAttributes.push(...productsAttributesToAdd);
    productsAttributesToRemove.length && updatedAttributes.push(...productsAttributesToRemove);

    // Map product attributes back to ProductAttributeAssociationInput
    attributes.push(
      ...updatedAttributes.map((attr) => ({ value: attr.name, type: attr.type, remove: attr.remove, id: attr.id })),
    );
  }

  const homeownerSelectionOption = {
    name,
    description,
    unitCostInCents: cost,
    unitPriceInCents: price,
    homeownerSelectionId,
    isHomeownerVisible,
    isRecommendedOption,
    isSelectedOption,
    pricingType,
    priceDeltaInCents,
    specifications,
  };

  if (isCustomProduct) {
    return {
      ...homeownerSelectionOption,
      product: {
        name,
        description,
        costInCents: cost,
        priceInCents: price,
        sku: model,
        manufacturerUrl,
        msrpInCents: msrp,
        attributes,
        images,
        promotionStatus:
          promotionRequested === false ? null : promotionRequested === true ? PromotionStatus.Requested : undefined,
      },
    };
  } else {
    // for global selection options, we do not want to pass the product to the mutation
    return homeownerSelectionOption;
  }
}

function priceDeltaRule({ pricingType }: ObjectState<FormValue>): Rule<Maybe<number>> {
  return ({ value }) => {
    // if pricing type is Downgrade and price difference > 0
    if (pricingType.value === HomeownerSelectionOptionPricingType.Downgrade && value && value > 0) {
      return "The price difference cannot be positive for a pricing type of Downgrade";
    }
    // if pricing type is Upgrade and price difference < 0
    if (pricingType.value === HomeownerSelectionOptionPricingType.Upgrade && value && value < 0) {
      return "The price difference cannot be negative for a pricing type of Upgrade";
    }
    return undefined;
  };
}
