import { Global } from "@emotion/react";
import { Css, GridColumn, GridDataRow, GridTable, column } from "@homebound/beam";
import { format } from "date-fns";
import startCase from "lodash/startCase";
import { Fragment, useMemo } from "react";
import { emptyCellDash } from "src/components";
import {
  DesignPackageLocation,
  FinishSchedulePdfQuery,
  FinishSchedule_ItiVersionFragment,
  FinishSchedule_MaterialAttributeValueFragment,
  useFinishScheduleDesignPackageItiVersionsQuery,
  useFinishSchedulePdfQuery,
} from "src/generated/graphql-types";
import {
  StackedAttributes,
  fixedDimensionsConfiguration,
  getDynamicAttributes,
  maybeAddUnitSuffix,
} from "src/routes/libraries/design-catalog/finish-schedules/FinishScheduleTable";
import {
  PRODUCT_EMPTY_STATE_IMG_URL,
  PRODUCT_FALLBACK_IMG_URL,
} from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { fail, queryResult, sanitizeHtml } from "src/utils";
import { ArrayParam, DateParam, StringParam, useQueryParams } from "use-query-params";

export function FinishSchedulePdf() {
  const [
    {
      designPackageId,
      designTemplateId,
      planTemplateIds,
      optionIds,
      costCodeIds,
      locationInPath,
      exportDate,
      exportBy,
    },
  ] = useQueryParams({
    designPackageId: StringParam,
    designTemplateId: StringParam,
    planTemplateIds: ArrayParam,
    optionIds: ArrayParam,
    costCodeIds: ArrayParam,
    locationInPath: ArrayParam,
    exportDate: DateParam,
    exportBy: StringParam,
  });

  const query = useFinishSchedulePdfQuery({
    variables: { id: designPackageId ?? fail("Design Package Id is required") },
  });

  const parsedPlanTemplateIds = parseFilterArray(planTemplateIds);
  const parsedOptionIds = parseFilterArray(optionIds);
  const parsedCostCodeIds = parseFilterArray(costCodeIds);
  const parsedLocationInPath = parseFilterArray(locationInPath);

  const { data: itivsData } = useFinishScheduleDesignPackageItiVersionsQuery({
    variables: {
      filter: {
        designTemplateId: designTemplateId ?? fail("Design Package Template Id is required"),
        planTemplateIds: parsedPlanTemplateIds,
        optionIds: parsedOptionIds,
        costCodeIds: parsedCostCodeIds,
        locationInPath: parsedLocationInPath,
      },
    },
  });

  return queryResult(query, (data) => (
    <FinishSchedulePdfView
      data={data}
      exportDate={exportDate}
      exportBy={exportBy}
      itivs={itivsData?.designPackageFinishSchedule.entities ?? []}
      costCodeIds={parsedCostCodeIds ?? []}
      locationIds={parsedLocationInPath ?? []}
      planTemplateIds={parsedPlanTemplateIds ?? []}
      optionIds={parsedOptionIds ?? []}
    />
  ));
}

type FinishSchedulePdfViewProps = {
  data: FinishSchedulePdfQuery;
  itivs: FinishSchedule_ItiVersionFragment[];
  costCodeIds: string[];
  locationIds: string[];
  planTemplateIds: string[];
  optionIds: string[];
  exportDate: Date | null | undefined;
  exportBy: string | null | undefined;
};

export function FinishSchedulePdfView(props: FinishSchedulePdfViewProps) {
  const { data, itivs, costCodeIds, locationIds, planTemplateIds, optionIds, exportDate, exportBy } = props;
  const { designPackage, costCodes, locations } = data;

  const isInterior = designPackage.location.code === DesignPackageLocation.Interior;

  const filteredCostCodes = costCodes.filter((l) => costCodeIds?.includes(l.id) ?? false);

  const filteredLocations = locations.filter((l) => locationIds?.includes(l.id) ?? false);

  const filteredPlans = designPackage.planPackages.filter((p) =>
    p.itemTemplates.some((it) => planTemplateIds?.includes(it.id)),
  );

  // Generate all possible combinations of forDesignPackages GOGs
  // i.e. [[Base, Deluxe, Premium], [Contemporary, Farmhouse]]
  const rposByGOG = itivs
    .flatMap((itiv) =>
      itiv.scope.options
        .filter((rpo) => (isInterior ? rpo.optionGroup.forDesignInterior : rpo.optionGroup.forDesignExterior))
        // Filter rpos by the options selected in the filter
        .filter((rpo) => (optionIds?.nonEmpty ? optionIds.includes(rpo.id) : true)),
    )
    .unique()
    .groupByObject((rop) => rop.optionGroup.group)
    .map(([_, rpos]) => rpos);

  const combinations = generateCombinations(rposByGOG);

  // Each combination represents an IDP
  const idps = combinations
    .map((rpos) => {
      // Filter ITIs looking for options that matches this IDP
      const filteredItivs = itivs.filter((itiv) => rpos.every((rpo) => itiv.scope.options.includes(rpo)));

      const costCodeGroups = filteredItivs.groupBy((itiv) => itiv.scope.item.costCode?.id ?? "none");

      return {
        rpos: rpos.filter((rpo) => rpo.optionGroup.forDesignPackages), // The combination of RPO that defines this IDP name
        order: rpos.sum((rpo) => rpo.order),
        groups: Object.entries(costCodeGroups).map(([costCodeId, costCodeItivs]) => {
          const locationGroups = costCodeItivs.groupBy((itiv) => itiv.location.id);
          return {
            costCode: costCodes.find((c) => c.id === costCodeId),
            rooms: Object.entries(locationGroups).map(([locationId, locationItivs]) => {
              const baseItivs = locationItivs.filter((itiv) =>
                itiv.scope.options.every((rpo) => rpo.optionGroup.forDesignPackages),
              );
              // Find all the ITIs that are associated to a an upgrade option and are not associated to any plan option
              const upgrades = locationItivs
                .flatMap((itiv) => itiv.scope.options)
                .uniqueByKey("id")
                .filter((rpo) => rpo.optionGroup.forDesignUpgrade)
                .map(
                  (upgradeOption) =>
                    [
                      upgradeOption, // We use this to display the option name
                      locationItivs
                        .filter((itiv) => itiv.scope.options.some((rpo) => rpo.id === upgradeOption.id))
                        .filter((itiv) => itiv.scope.options.every((rpo) => !rpo.optionGroup.isPlanPackage)),
                    ] as const,
                )
                .filter(([_, itis]) => itis.nonEmpty);

              // Find all the ITIs that are associated to a plan option
              const plans = locationItivs
                .flatMap((itiv) => itiv.scope.options)
                .uniqueByKey("id")
                .filter((rpo) => rpo.optionGroup.isPlanPackage)
                .map(
                  (planOption) =>
                    [
                      planOption, // We use this to display the option name
                      locationItivs.filter((itiv) => itiv.scope.options.some((rpo) => rpo.id === planOption.id)),
                    ] as const,
                );

              return {
                location: locations.find((l) => l.id === locationId) ?? fail("impossible no location found"),
                baseItivs,
                plans,
                upgrades,
              };
            }),
          };
        }),
      };
    })
    .sortByKey("order");

  return (
    <Fragment>
      <Global styles={{ "@page": { size: "letter landscape" } }} />
      <div css={Css.df.jcsb.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.xlSb.$}>{designPackage.name}</div>
          <div css={Css.df.gap1.sm.aic.$}>
            <span>
              Cost Codes:{" "}
              <span css={Css.smBd.$}>
                {filteredCostCodes.nonEmpty ? filteredCostCodes.map((c) => c.name).join(", ") : "All"}
              </span>
            </span>
            <span>
              Rooms:{" "}
              <span css={Css.smBd.$}>
                {filteredLocations.nonEmpty ? filteredLocations.map((c) => c.name).join(", ") : "All"}
              </span>
            </span>
            <span>
              Plans:{" "}
              <span css={Css.smBd.$}>
                {filteredPlans.nonEmpty ? filteredPlans.map((c) => c.name).join(", ") : "All"}
              </span>
            </span>
            <span>
              Location: <span css={Css.smBd.$}>{designPackage.location.name}</span>
            </span>
          </div>
        </div>
        <div css={Css.df.fdc.gap1.$}>
          <span>Last Updated: {format(designPackage.updatedAt, "MM/dd/yyyy")}</span>
          <span>Exported: {format(exportDate ?? new Date(), "MM/dd/yyyy")}</span>
          <span>Exported by: {exportBy}</span>
        </div>
      </div>
      <div css={Css.df.fdc.gap2.mt1.$}>
        {idps.map(({ rpos, groups }, i) => (
          <div key={rpos.map((rpo) => rpo.id).join()} css={Css.pb3.if(i < idps.length - 1).bb.bcGray400.$}>
            <span css={Css.baseMd.gray700.$}>{rpos.map((rpo) => rpo.name).join(" - ")}</span>
            {groups.map(({ costCode, rooms }) => (
              <div key={`${costCode?.id}-${rpos.map((rpo) => rpo.id).join()}`} css={Css.df.fdc.gap1.$}>
                <span css={Css.lgSb.$}>{costCode?.name ?? "Without Cost Code"}</span>
                {rooms.map(({ location, baseItivs, plans, upgrades }) => (
                  <div key={location.id}>
                    <span css={Css.baseMd.$}>{location.name}</span>
                    <FinishScheduleTable itis={baseItivs} />
                    {upgrades.map(([upgradeOption, upgradeItivs]) => (
                      <div key={upgradeOption.id}>
                        <span css={Css.baseMd.$}>
                          {location.name} - {upgradeOption.name}
                        </span>
                        <FinishScheduleTable itis={upgradeItivs} />
                      </div>
                    ))}
                    {plans.map(([planOption, planItivs]) => (
                      <div key={planOption.id}>
                        <span css={Css.baseMd.$}>
                          {location.name} - {planOption.name}
                        </span>
                        <FinishScheduleTable itis={planItivs} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Fragment>
  );
}

function FinishScheduleTable({ itis }: { itis: FinishSchedule_ItiVersionFragment[] }) {
  const rows = useMemo(() => createRows(itis ?? []), [itis]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <GridTable
      style={{ rowHover: false, bordered: true }}
      rowStyles={{ data: { cellCss: Css.bcGray200.bt.br.$ } }}
      columns={columns}
      rows={rows}
      as="table"
    />
  );
}

type FinishScheduleRow = {
  id: string;
  kind: "data";
  data: FinishSchedule_ItiVersionFragment;
  dynamicAttributes: FinishSchedule_MaterialAttributeValueFragment[][];
};

function createRows(data: FinishSchedule_ItiVersionFragment[]): GridDataRow<FinishScheduleRow>[] {
  // Only show Product ITIs (which all point to their placeholder)
  return data
    .filter((itiv) => !!itiv.scope.placeholder)
    .map((itiv) => ({ kind: "data", id: itiv.id, data: itiv, dynamicAttributes: getDynamicAttributes(itiv) }));
}

function createColumns(): GridColumn<FinishScheduleRow>[] {
  return [
    column<FinishScheduleRow>({
      data: (itiv) => (
        <div css={Css.w100.h100.mwPx(75).df.aic.jcc.$}>
          <img
            css={Css.hPx(75).wPx(75).$}
            src={
              !itiv.scope.isDisabledBidItem && !itiv.scope.bidItem
                ? PRODUCT_EMPTY_STATE_IMG_URL
                : itiv.materialVariant?.featuredImage?.asset?.previewUrl || PRODUCT_FALLBACK_IMG_URL
            }
            alt="product"
          />
        </div>
      ),
      w: "100px",
      mw: "100px",
    }),
    column<FinishScheduleRow>({
      data: (itiv) => (
        <div css={Css.df.fdc.gap2.$}>
          <div>
            <div data-testid="productType" css={Css.smMd.df.fww.$}>
              {startCase(itiv.scope.slot.name || itiv.scope.item.name)}
            </div>
            <div css={Css.gray600.$}>PRODUCT TYPE</div>
          </div>
          <div data-testid="productCode">{itiv.materialVariant?.code}</div>
          {itiv.materialVariant?.listing.manufacturerUrl && (
            <a
              target="_blank"
              href={itiv.materialVariant?.listing.manufacturerUrl}
              css={Css.blue600.tdu.$}
              rel="noreferrer"
            >
              Manufacturer Link
            </a>
          )}
        </div>
      ),
      w: "250px",
    }),
    column<FinishScheduleRow>({
      data: (itiv) => (
        <StackedAttributes
          topAttribute={{
            value: itiv.materialVariant?.listing.name ?? emptyCellDash,
            label: "PRODUCT NAME",
          }}
          bottomAttribute={{
            value: itiv.materialVariant?.listing.brand?.name ?? emptyCellDash,
            label: "BRAND",
          }}
        />
      ),
      w: "250px",
    }),
    column<FinishScheduleRow>({
      data: (itiv) => (
        <div css={Css.h100.gap2.$}>
          <div>
            <div data-testid="skuModel" css={Css.smMd.$}>
              {itiv.materialVariant?.modelNumber ?? emptyCellDash}
            </div>
            <div css={Css.gray600.$}>SKU/MODEL #</div>
          </div>
        </div>
      ),
      w: "150px",
    }),
    // 3 "fixed attribute" columns.
    ...Object.entries(fixedDimensionsConfiguration).map(([_, [topDimName, bottomDimName]]) =>
      column<FinishScheduleRow>({
        data: (itiv) => {
          const topMav = itiv.materialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === topDimName?.toLowerCase(),
          );
          const bottomMav = itiv.materialVariant?.materialAttributeValues.find(
            (mav) => mav.dimension.name.toLowerCase() === bottomDimName?.toLowerCase(),
          );
          return (
            <StackedAttributes
              topAttribute={{
                value: topMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(topMav) ?? topDimName,
              }}
              bottomAttribute={{
                value: bottomMav?.textValue ?? emptyCellDash,
                label: maybeAddUnitSuffix(bottomMav) ?? bottomDimName,
              }}
            />
          );
        },
        w: "120px",
      }),
    ),
    // We try to render up to 4 attribute fields in addition to the 6 fixed attributes
    // 'row.dynamicAttributes' is a 2D array of FinishSchedule_MaterialAttributeValueFragment[][]
    // i.e. [[attrValue1, attrValue2], [attrValue3, attrValue4]]
    column<FinishScheduleRow>({
      data: (_, { row }) => {
        const columnOne = row.dynamicAttributes.first ?? [];
        const [topMav, bottomMav] = columnOne;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
      w: "120px",
    }),
    column<FinishScheduleRow>({
      data: (_, { row }) => {
        const columnTwo = row.dynamicAttributes.last ?? [];
        const [topMav, bottomMav] = columnTwo;
        return (
          <StackedAttributes
            topAttribute={{ value: topMav?.textValue ?? emptyCellDash, label: maybeAddUnitSuffix(topMav) ?? "" }}
            bottomAttribute={{
              value: bottomMav?.textValue ?? emptyCellDash,
              label: maybeAddUnitSuffix(bottomMav) ?? "",
            }}
          />
        );
      },
      w: "120px",
    }),
    column<FinishScheduleRow>({
      data: (itiv) => (
        <div /* Design Notes */ dangerouslySetInnerHTML={{ __html: sanitizeHtml(itiv.scope.specifications ?? "") }} />
      ),
      w: "210px",
    }),
  ];
}

/* Function to generate all combinations from any number of groups
 Turns [[Base, Deluxe, Premium], [Contemporary, Farmhouse]] 
 Into [[Base, Contemporary], [Base, Farmhouse], [Deluxe, Comtemporary], [Deluxe, Farmhouse], [Premium, Contemporary], [Premium, Farmhouse]]
*/
export function generateCombinations<T>(groups: T[][]): T[][] {
  // Reduce the groups to a single array of combinations
  return groups.reduce((acc, group) => {
    if (acc.length === 0) {
      return group.map((item) => [item]);
    }
    const newCombinations: T[][] = [];
    for (const combination of acc) {
      for (const item of group) {
        newCombinations.push([...combination, item]);
      }
    }
    return newCombinations;
  }, [] as T[][]);
}

export function parseFilterArray(filter: (string | null)[] | null | undefined) {
  // If the filter is not empty, split the string by comma and map the values to an array of strings, i.e. ["1,2,3"] => ["1", "2", "3"]
  return filter?.nonEmpty ? filter.first!.split(",").map((id) => id) : undefined;
}
