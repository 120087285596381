import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";

import { StatusIndicator } from "src/components/StatusIndicator";
import {
  AddDepositModalQuery,
  CommitmentEditorDataFragment,
  TaskStatus,
  TaskStatusesFragment,
  useAddDepositModalQuery,
  useSaveCommitmentMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export function AddDepositModal({ commitment }: { commitment: CommitmentEditorDataFragment }) {
  const addDepositQuery = useAddDepositModalQuery({
    variables: {
      commitmentId: commitment.id,
      projectId: commitment.project.id,
      stage: commitment.projectStage.stage.code,
    },
    skip: !commitment,
  });

  return queryResult(addDepositQuery, ({ commitment, tasks }) => (
    <AddDepositModalView commitment={commitment} tasks={tasks} />
  ));
}

export function AddDepositModalView(props: {
  commitment: AddDepositModalQuery["commitment"];
  tasks: AddDepositModalQuery["tasks"];
}) {
  const { commitment, tasks: tasksData } = props;
  const tasks = tasksData
    .unique()
    .compact()
    .filter((t) => t.status.code !== TaskStatus.Complete);

  const [saveCommitment] = useSaveCommitmentMutation();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const tid = useTestIds({});

  const formState = useFormState({
    config: formConfig,
    init: {
      input: commitment,
      map: (c) => mapToForm(c),
    },
    readOnly: !commitment.canBeEdited,
  });

  return (
    <>
      <ModalHeader>Commitment Deposit</ModalHeader>

      <Observer>
        {() => (
          <>
            <ModalBody>
              <div css={Css.smSb.gap1.mt1.mb3.$} {...tid.title}>
                Create a deposit as a fixed value or percent of the commitment
              </div>

              <div css={Css.dg.gtc("1fr 1fr").aib.gap2.xs.$}>
                <BoundNumberField
                  field={formState.depositFixedValue}
                  type="cents"
                  label="Deposit fixed value"
                  disabled={!!formState.depositPercent.changedValue && "Deposit percent already entered"}
                />
                <BoundNumberField
                  field={formState.depositPercent}
                  type="percent"
                  label="Deposit percent"
                  disabled={!!formState.depositFixedValue.changedValue && "Deposit fixed value already entered"}
                />
              </div>
              {tasks.nonEmpty && (
                <>
                  <div css={Css.smSb.gap1.my2.$} {...tid.setDepositTask}>
                    If the deposit should be paid upon task completion, select a schedule task
                  </div>

                  <BoundSelectField
                    field={formState.depositTask.id}
                    label="Deposit schedule task"
                    options={[...tasks, { id: null, name: "None", status: { code: TaskStatus.NotStarted } }]}
                    getOptionLabel={(tsk) => tsk.name}
                    getOptionValue={(tsk) => tsk.id ?? ""}
                    fieldDecoration={(tsk) => <StatusIndicator status={tsk.status.code} />}
                    labelStyle="left"
                    getOptionMenuLabel={(tsk) => (
                      <div css={Css.dg.gtc("auto auto").xs.aic.$}>
                        <StatusIndicator status={tsk.status.code} />
                        <span css={Css.ml3.$}>{tsk.name}</span>
                      </div>
                    )}
                    onSelect={(selectedTaskId) => {
                      const selectedTask = tasks.find((t) => t.id === selectedTaskId);
                      if (selectedTask) {
                        formState.depositTask.set(selectedTask);
                      }
                    }}
                  />
                  <div css={Css.xs.gray700.my2.$}>
                    If no task is selected, the deposit will be paid when commitment is signed
                  </div>
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                label="Clear Deposit Values"
                onClick={() => {
                  formState.set({ depositPercent: null, depositFixedValue: null });
                }}
                disabled={!commitment.canBeEdited}
                variant="secondary"
              />
              <Button label="Cancel" variant="tertiary" onClick={closeModal} />
              <Button
                label="Save"
                variant="primary"
                onClick={async () => {
                  const res = await saveCommitment({
                    variables: { input: mapToInput(formState, commitment) },
                  });
                  if (res.data) {
                    triggerNotice({ message: "Deposit successfully saved" });
                    closeModal();
                  }
                }}
                disabled={
                  (formState.depositFixedValue.value === undefined &&
                    formState.depositPercent.value === undefined &&
                    "No deposit value given") ||
                  !commitment.canBeEdited
                }
              />
            </ModalFooter>
          </>
        )}
      </Observer>
    </>
  );
}

type DepositTask = { id: string | null | undefined; name: string; status: TaskStatusesFragment };
type DepositModalData = {
  depositTask: DepositTask | undefined | null;
  depositFixedValue: number | undefined | null;
  depositPercent: number | undefined | null;
};

export const formConfig: ObjectConfig<DepositModalData> = {
  depositTask: {
    type: "object",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      status: { type: "object", config: { code: { type: "value" } } },
    },
  },
  depositFixedValue: {
    type: "value",
  },
  depositPercent: {
    type: "value",
  },
};

function mapToForm(commitment: AddDepositModalQuery["commitment"]) {
  const { depositInBasisPoints, costChangeInCents, depositScheduleTask } = commitment;
  const depositPercent = (depositInBasisPoints ?? 0) / 100;
  const depositFixedValue = (depositPercent / 100) * costChangeInCents;
  return {
    depositTask: depositScheduleTask,
    depositFixedValue,
    depositPercent,
  };
}

function mapToInput(formState: ObjectState<DepositModalData>, commitment: AddDepositModalQuery["commitment"]) {
  const { depositFixedValue, depositPercent, depositTask } = formState;
  const depositPercentInput = depositFixedValue.value
    ? (depositFixedValue.value / commitment.costChangeInCents) * 100
    : depositPercent.value;
  const depositInBasisPoints = parseFloat(((depositPercentInput || 0) * 100).toFixed(2));
  return {
    id: commitment.id,
    depositInBasisPoints,
    ...(depositTask && { depositScheduleTaskId: depositTask.id.value! }),
  };
}
