import { Css, Tag } from "@homebound/beam";
import { useParams } from "react-router";
import { useUpdateProjectCoverImageMutation } from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { ImageUploader, PendingAsset } from "../uploader/ImageUploader";

type CoverImageProps = {
  modalOpen?: boolean; // using to show modal in story
};

/** Project navigation button to upload, view and delete a project cover image */
export function CoverImage({ modalOpen }: CoverImageProps) {
  const { projectId } = useParams<ProjectParams>();
  const { buildAddress, client, coverImage } = useProjectContext();
  const [updateProjectCoverImage] = useUpdateProjectCoverImageMutation();

  async function handleUpdateImage({ contentType, fileName, s3Key }: PendingAsset) {
    const { errors } = await updateProjectCoverImage({
      variables: { input: { id: projectId, coverImage: { contentType, fileName, s3Key } } },
    });

    if (errors) console.error(errors);
  }
  const { street1, city, state, postalCode } = buildAddress;
  const cityAndState = `${city}, ${state} ${postalCode}`;

  return (
    <>
      <div css={Css.pt3.mb2.add("margin", "auto").df.jcc.$}>
        <ImageUploader
          open={modalOpen}
          imageUrl={coverImage?.downloadUrl}
          thumbnailUrl={coverImage?.previewUrl ?? undefined}
          onChange={handleUpdateImage}
          xss={Css.objectCover.wPx(72).hPx(72).br100.$}
        />
      </div>
      <div css={Css.df.fdc.aic.$}>
        <span css={Css.smMd.tac.$}>{street1}</span>
        <span css={Css.xs.tac.$}>{cityAndState}</span>
        {client?.shortName && (
          <span css={Css.mt1.$}>
            <Tag text={client.shortName}></Tag>
          </span>
        )}
      </div>
    </>
  );
}
