import {
  actionColumn,
  BoundNumberField,
  Checkbox,
  CollapseToggle,
  column,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  ScrollableContent,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo } from "react";
import { Price, priceCell } from "src/components";
import { CostType, Maybe, useSaveCommitmentLineItemsMutation } from "src/generated/graphql-types";
import { isNumber, safeEntries } from "src/utils";
import { LineItemStore, ObservableLineItemGroup } from "./models/LineItemStore";
import { ObservableLineItem } from "./models/ObservableLineItem";

export type LineItemsTableFilter = {
  costCode?: string[];
  costType?: string[];
  locationId?: string[];
  selectionStatus?: string[];
  view?: "selections";
};

type LineItemsTableProps = {
  filter: LineItemsTableFilter;
  readOnly?: boolean;
  store: LineItemStore;
};
export function LineItemsTable({ filter, readOnly, store }: LineItemsTableProps) {
  const [saveCommitment] = useSaveCommitmentLineItemsMutation();
  const { getFormState } = useFormStates<FormInput, ObservableLineItem>({
    config: formConfig,
    map: mapToForm,
    getId: (v: ObservableLineItem) => v.id,
    autoSave: async (os: ObjectState<FormInput>) => {
      await saveCommitment({
        variables: {
          input: {
            id: os.value.commitmentId,
            lineItems: [os.changedValue],
          },
        },
      });
      os.commitChanges();
    },
  });
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rows = useMemo(() => createRows(store, filter, getFormState), [store, filter]);

  return (
    <ScrollableContent>
      <GridTable
        stickyHeader
        style={{ grouped: true, allWhite: true, bordered: true }}
        sorting={{ on: "client" }}
        rows={rows}
        columns={createColumns(readOnly, store.groupBy)}
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header"; data: LineItemStore };
type DataRow = { kind: "data"; objectState: ObjectState<FormInput>; data: ObservableLineItem };
type GroupRow = { kind: "group"; data: ObservableLineItemGroup };
type Row = HeaderRow | DataRow | GroupRow;

function createColumns(readOnly: boolean | undefined, groupBy: "costCode" | "project"): GridColumn<Row>[] {
  return [
    actionColumn<Row>({
      header: (data, { row }) => <CollapseToggle row={row} />,
      group: (data, { row }) => <CollapseToggle row={row} />,
      data: emptyCell,
      w: "32px",
      clientSideSort: false,
    }),
    ...(!readOnly
      ? [
          actionColumn<Row>({
            clientSideSort: false,
            header: (row) => (
              <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
            ),
            group: (row) => (
              <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
            ),
            data: (row) => (
              <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
            ),
            w: "32px",
          }),
        ]
      : []),
    column<Row>({
      header: () => "Cost Code",
      group: (row) =>
        // We always have the project & cost code columns, but pick which one we fill in based on the current group by
        groupBy !== "project"
          ? {
              content: () => row.name,
            }
          : emptyCell,
      data: (row) => (groupBy !== "project" ? row.itemName : `${row.fullCode} ${row.itemName}`),
      w: "250px",
    }),
    column<Row>({
      header: "Project Name",
      group: (row) =>
        // We always have the project & cost code columns, but pick which one we fill in based on the current group by
        groupBy === "project"
          ? {
              content: () => row.name,
            }
          : emptyCell,
      data: (row) => row.projectName,
      clientSideSort: true,
    }),
    column<Row>({
      header: "Location",
      group: emptyCell,
      data: (row) => row.location,
      clientSideSort: false,
    }),
    column<Row>({
      header: "Cost Type",
      group: emptyCell,
      data: (row) => {
        const costTypes = safeEntries(CostType);
        const costType = costTypes.find(([, code]) => code === row.costType);
        if (costType) {
          return costType[0];
        }
      },
      clientSideSort: false,
    }),
    column<Row>({
      header: "Qty",
      group: emptyCell,
      data: (data, { row }) => {
        if (!isNumber(data.quantity)) {
          return;
        } else if (readOnly) {
          return data.quantity;
        }
        return <BoundNumberField field={row.objectState.quantity} />;
      },
      clientSideSort: false,
    }),
    column<Row>({
      header: "Unit",
      group: emptyCell,
      data: (row) => row.unit,
      clientSideSort: false,
    }),
    column<Row>({
      header: "# of Projects",
      group: (row) => `${row.projectIds.length} ${row.projectIds.length > 1 ? "Projects" : `Project`}`,
      data: () => emptyCell,
      clientSideSort: false,
    }),
    numericColumn<Row>({
      header: "Committed",
      group: (row) => <Price valueInCents={row.totalCommittedInCents} />,
      data: (data, { row }) => {
        if (readOnly) return <Price valueInCents={data.totalCommittedInCents} />;
        return <BoundNumberField field={row.objectState.costChangeInCents} disabled={readOnly} />;
      },
      clientSideSort: false,
      w: "150px",
    }),
    column<Row>({
      header: "Billed",
      group: (row) => <Price valueInCents={row.totalBilledInCents} />,
      data: (row) => priceCell({ valueInCents: row.totalBilledInCents }),
      clientSideSort: false,
      align: "right",
    }),
    column<Row>({
      header: "Unbilled",
      group: (row) => <Price valueInCents={row.totalUnbilledinCents} />,
      data: (row) => priceCell({ valueInCents: row.totalUnbilledinCents }),
      clientSideSort: false,
      align: "right",
    }),
    column<Row>({
      header: "Paid to Date",
      group: (row) => <Price valueInCents={row.totalPaidToDate} />,
      data: (row) => priceCell({ valueInCents: row.totalPaidToDate }),
      clientSideSort: false,
      align: "right",
    }),
    column<Row>({
      header: "Balance Due",
      group: (row) => <Price valueInCents={row.totalBalanceDue} />,
      data: (row) => priceCell({ valueInCents: row.totalBalanceDue }),
      clientSideSort: false,
      align: "right",
    }),
  ];
}

function createRows(
  store: LineItemStore,
  filter: LineItemsTableFilter,
  getFormState: (input: ObservableLineItem) => ObjectState<FormInput>,
): GridDataRow<Row>[] {
  const omittedLineItemIds: string[] = [];
  if (filter.locationId) {
    store.lineItems.forEach(({ id, locationId }) => {
      if (!locationId || !filter.locationId!.includes(locationId)) {
        omittedLineItemIds.push(id);
      }
    });
  }
  if (filter.view === "selections") {
    store.lineItems.forEach(({ id, isSelection }) => {
      if (!isSelection) {
        omittedLineItemIds.push(id);
      }
    });
  }
  if (filter.costCode) {
    store.lineItems.forEach(({ id, costCodeId }) => {
      if (!filter.costCode!.includes(costCodeId)) {
        omittedLineItemIds.push(id);
      }
    });
  }
  if (filter.costType) {
    store.lineItems.forEach(({ id, costType }) => {
      if (!filter.costType!.includes(costType)) {
        omittedLineItemIds.push(id);
      }
    });
  }
  if (filter.selectionStatus) {
    store.lineItems.forEach(({ id, selectionStatus }) => {
      if (!selectionStatus || !filter.selectionStatus!.includes(selectionStatus)) {
        omittedLineItemIds.push(id);
      }
    });
  }

  return [
    { kind: "header", id: "header", data: store },
    ...store.groupedBy
      .filter((group) => !group.children.every(({ id }) => omittedLineItemIds.includes(id)))
      .map((g) => ({
        kind: "group" as const,
        id: `group-${g.id}`, // this will be `cli:1` so might conflict with a child
        data: g,
        children: g.children
          .filter(({ id }) => !omittedLineItemIds.includes(id))
          .map((cli) => ({
            kind: "data" as const,
            id: cli.id,
            data: cli,
            objectState: getFormState(cli),
          })),
      })),
  ];
}

type FormInput = {
  id: string;
  commitmentId: string;
  quantity: Maybe<number>;
  costChangeInCents: Maybe<number>;
};

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  commitmentId: { type: "value" },
  quantity: { type: "value" },
  costChangeInCents: { type: "value" },
};

function mapToForm(li: ObservableLineItem): FormInput {
  return {
    id: li.id,
    commitmentId: li.commitmentId,
    quantity: li.quantity,
    costChangeInCents: li.totalCommittedInCents,
  };
}
