import { BoundCheckboxField, BoundSelectField, BoundTextField, Css, FormLines } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import {
  CostClassificationType,
  CostSubClassificationType,
  SaveCostCodeInput,
  useCostCodeOverviewTabMetadataQuery,
  useCostCodeOverviewTabQuery,
  useSaveCostCodesMutation,
} from "src/generated/graphql-types";
import { safeEntries } from "src/utils";

type CostCodeOverviewTabProps = {
  costCodeId: string;
};

export function CostCodeOverviewTab({ costCodeId }: CostCodeOverviewTabProps) {
  const query = useCostCodeOverviewTabQuery({ variables: { filter: { id: [costCodeId], version: [1, 2] } } });
  const { data } = useCostCodeOverviewTabMetadataQuery();
  const { costDivisions = [] } = data || {};
  const [saveCostCodes] = useSaveCostCodesMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: query.data?.costCodes[0],
      map: (cc) => ({
        id: cc.id,
        name: cc.name,
        number: cc.number,
        costClassification: cc.costClassification?.code,
        costSubClassification: cc.costSubClassification?.code,
        divisionId: cc.division?.id,
        quickbooksCategoryId: cc.quickbooksCategoryId,
        quickbooksItemId: cc.quickbooksItemId,
        intacctApAccountId: cc.intacctApAccountId,
        excludeFromPurchaseOrders: cc.excludeFromPurchaseOrders,
        isPriceOnly: cc.isPriceOnly,
      }),
    },
    autoSave: async ({ changedValue, divisionId }) => {
      await saveCostCodes({
        variables: { input: { costCodes: [{ ...changedValue }] } },
        // Manually update the cache to update the list table immediately whenever we change a cost division
        update(cache, result) {
          if (!changedValue.divisionId) return;
          cache.modify({
            id: cache.identify({ __typename: "CostCode", id: changedValue.id }),
            fields: {
              division() {
                return changedValue.divisionId ?? divisionId ?? null;
              },
            },
          });
        },
      });
    },
  });

  return (
    <div>
      <h2 css={Css.mb3.baseMd.$}>Details</h2>
      <FormLines>
        <BoundSelectField
          label="Division"
          field={formState.divisionId}
          options={costDivisions}
          getOptionLabel={({ name, version }) => `${name} V${version}`}
        />
        <BoundSelectField
          label="Cost Classification"
          field={formState.costClassification}
          options={safeEntries(CostClassificationType)}
          getOptionLabel={([name]) => name}
          getOptionValue={([, code]) => code}
        />
        <BoundSelectField
          label="Cost Sub Classification"
          field={formState.costSubClassification}
          options={safeEntries(CostSubClassificationType)}
          getOptionLabel={([name]) => name}
          getOptionValue={([, code]) => code}
        />
        <BoundTextField label="Number" field={formState.number} />
        <BoundTextField label="Quickbooks Category Id" field={formState.quickbooksCategoryId} />
        <BoundTextField label="Quickbooks Item Id" field={formState.quickbooksItemId} />
        <BoundTextField label="Intacct Ap Account Id" field={formState.intacctApAccountId} />
        <div css={Css.addIn("& > label", Css.maxw100.$).$}>
          <BoundCheckboxField
            label="Exclude From Purchase Orders"
            description="Not available in Cost Code list of values when creating Purchase Orders."
            helperText="Except for HBL projects"
            field={formState.excludeFromPurchaseOrders}
          />
        </div>
        <div css={Css.addIn("& > label", Css.maxw100.$).$}>
          <BoundCheckboxField
            label="Price Only?"
            description="Mark this option when the cost code is used for pricing only (e.g. OH&P)."
            field={formState.isPriceOnly}
          />
        </div>
      </FormLines>
    </div>
  );
}

const formConfig: ObjectConfig<SaveCostCodeInput> = {
  id: { type: "value", rules: [required] },
  name: { type: "value" },
  number: { type: "value" },
  costClassification: { type: "value" },
  costSubClassification: { type: "value" },
  divisionId: { type: "value" },
  quickbooksCategoryId: { type: "value" },
  quickbooksItemId: { type: "value" },
  intacctApAccountId: { type: "value" },
  excludeFromPurchaseOrders: { type: "value" },
  isPriceOnly: { type: "value" },
};
