import { ConfigsComparePage_ProgramDataFragment } from "src/generated/graphql-types";

export function labeledProgramData(programData: Omit<ConfigsComparePage_ProgramDataFragment, "id" | "__typename">) {
  const { garageAttached, garageDetached, ...pd } = programData;
  // combine the attached and detached garage counts to one garage key
  (garageAttached || garageDetached) && Object.assign(pd, { garage: (garageAttached || 0) + (garageDetached || 0) });
  return Object.entries(pd).map(([k, value]) => ({
    label: programDataLabel[k],
    value,
  }));
}

const programDataLabel: Record<string, string> = {
  sellableSqft: "Sellable Sq Ft",
  bedrooms: "Bed Count",
  fullBaths: "Full Bath Count",
  halfBaths: "Half Bath Count",
  stories: "Floor Count",
  garage: "Garage Count",
  widthInFeet: "Width",
  depthInFeet: "Depth",
  imperviousSqft: "Impervious Sq Ft",
  minLotSizeInSqft: "Lot Size",
  minLotWidthInFeet: "Lot Width",
  minLotDepthInFeet: "Lot Depth",
};
