import { column, GridColumn, GridDataRow, simpleDataRows, SimpleHeaderAndData } from "@homebound/beam";
import { QueryTable } from "src/components";
import { AdminItemFragment, ItemsDrawerQuery, useItemsDrawerQuery } from "src/generated/graphql-types";

/** Lists all variables available in the system, allows adding new ones. */
export function ItemsDrawer({ id }: { id: string }) {
  const query = useItemsDrawerQuery({ variables: { id } });
  return <QueryTable stickyHeader sorting={{ on: "client" }} {...{ query, createRows, columns }} />;
}

type Row = SimpleHeaderAndData<AdminItemFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({ header: "Item Code", data: (d) => `${d.costCode.number}.${String(d.itemCode).padStart(3, "0")}` }),
  column<Row>({ header: "Name", data: (d) => d.name }),
  column<Row>({ header: "Selection?", data: (d) => (d.isSelection ? "Yes" : "No") }),
];

function createRows(data: ItemsDrawerQuery | undefined): GridDataRow<Row>[] {
  return simpleDataRows(data?.items);
}
