import {
  Avatar,
  Button,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  simpleHeader,
  SimpleHeaderAndData,
  Tooltip,
} from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { formatDate } from "src/components";
import { useToDoModal } from "src/components/to-dos/ToDoModal";
import { ProjectToDosTile_ToDoFragment, useProjectToDosTileQuery } from "src/generated/graphql-types";
import { EmptyTile } from "src/routes/projects/dashboard/components/EmptyTile";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createProjectDashboardUrl, createProjectToDosUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function ProjectToDosTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useProjectToDosTileQuery({ variables: { projectId } });
  const { open } = useToDoModal({
    defaultParentId: projectId,
    returnUrl: createProjectDashboardUrl(projectId),
    refreshQueries: query.refetch,
  });

  const rows = useMemo(() => createRows(query.data?.toDos ?? []), [query.data?.toDos]);

  const columns = useMemo(() => createColumns(open), [open]);

  return (
    <Tile
      href={createProjectToDosUrl(projectId)}
      {...props}
      actions={<Button label="Add" variant="secondary" onClick={() => open("new")} />}
    >
      {queryResult(query, ({ toDos }) => {
        return toDos.nonEmpty ? (
          <GridTable
            rows={rows}
            columns={columns}
            style={{
              rowHeight: "fixed",
              allWhite: true,
              rowHover: false,
              cellTypography: "xsMd",
            }}
          />
        ) : (
          <EmptyToDoTileWrapper />
        );
      })}
    </Tile>
  );
}

type Row = SimpleHeaderAndData<ProjectToDosTile_ToDoFragment>;

function createRows(todos: ProjectToDosTile_ToDoFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...todos.map((todo) => ({ kind: "data" as const, id: todo.id, data: todo }))];
}

function createColumns(openToDoModal: (id: string) => void): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Title",
      data: ({ name, id }) => ({
        content: () => (
          <Tooltip title={name} placement="top">
            <button css={Css.blue700.xsMd.truncate.$} onClick={() => openToDoModal(id)}>
              {name}
            </button>
          </Tooltip>
        ),
      }),
      mw: "140px",
    }),
    column<Row>({
      header: "Added",
      data: ({ createdAt }) => ({
        content: () => formatDate(createdAt),
      }),
      w: "88px",
    }),
    column<Row>({
      header: "By",
      data: ({ createdBy }) => ({
        content: () => <Avatar size="sm" src={createdBy.avatarUrl} name={createdBy.name} />,
      }),
      w: "48px",
      align: "center",
    }),
  ];
}

function EmptyToDoTileWrapper() {
  return (
    <EmptyTile data-testid="projectToDosTileEmptyState">
      No To-Dos have
      <br />
      been created.
    </EmptyTile>
  );
}
