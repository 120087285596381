import { gql, useApolloClient } from "@apollo/client";
import { BoundTextField, Button, Css, SelectField, useComputed } from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSetState } from "react-use";
import { createDesignCatalogUrl, createDesignPackageProductSearchUrl } from "src/RouteUrls";
import { ItemsBoundSelectField } from "src/components/autoPopulateSelects/ItemsBoundSelectField";
import { LocationsBoundSelectField } from "src/components/autoPopulateSelects/LocationsBoundSelectField";
import {
  CreateDesignPackagePlaceholderItivInput,
  DesignPackageItemSlotEditorDocument,
  DesignPackageSlotsDocument,
  useCreateDesignPackagePlaceholderItivMutation,
  useMaterialAttributeDimensionsQuery,
} from "src/generated/graphql-types";
import { useReaction } from "src/hooks";
import { fail } from "src/utils";
import { useDesignPackageConfiguratorContext } from "../../DesignPackageConfiguratorContext";

export function NewProductSlotPage() {
  const { selectedTemplateId, setCtxState, designPackage, selectedSlots } = useDesignPackageConfiguratorContext();
  const history = useHistory();

  const singleSlot = selectedSlots?.length === 1 ? selectedSlots.first! : undefined;
  const withinItemSlot = singleSlot?.slot;

  /** Determines if the Name/Location/Item are editable or restricted */
  const formState = useFormState({
    config: formConfig,
    init: {
      input: withinItemSlot,
      map: (withinItemSlot) => ({
        customSlotName: withinItemSlot.name,
        itemId: withinItemSlot.item.id,
        locationId: withinItemSlot.location.id,
      }),
    },
    readOnly: !!withinItemSlot,
  });
  const [mavs, setMavs] = useSetState<Record<string, string>>({});
  const itemId = useComputed(() => formState.itemId.value, [formState]);
  const madsQuery = useMaterialAttributeDimensionsQuery({ variables: { filter: { itemId } }, skip: !itemId });
  useDefaultSlotNameToItemName(formState);

  const [createDesignPackagePlaceholderItiv] = useCreateDesignPackagePlaceholderItivMutation({
    refetchQueries: [
      // Refetch center content with new cards
      DesignPackageItemSlotEditorDocument,
      // Refetch left nav to get new DPSlot (and new coverage) info
      DesignPackageSlotsDocument,
    ],
  });

  const onClick = async (proceedToPicker = false) => {
    const { data } = await createDesignPackagePlaceholderItiv({
      variables: {
        input: {
          templateId: selectedTemplateId,
          attributeValueIds: Object.values(mavs).compact(),
          ...(withinItemSlot
            ? { itemSlotId: withinItemSlot.id }
            : {
                itemId: formState.itemId.value,
                customSlotName: formState.customSlotName.value,
                locationId: formState.locationId.value,
              }),
        },
      },
    });
    // Navigate to the new slot underneath the modal. When User exits the modal they'll be looking at their newly-created item.
    setCtxState({ slotIds: [data?.createDesignPackagePlaceholderItiv.placeholderItiv.slot.id].compact() });
    if (!proceedToPicker) {
      history.push(createDesignCatalogUrl(designPackage.id));
      return;
    }
    const { productItiv, placeholderItiv } = data?.createDesignPackagePlaceholderItiv ?? {};
    const placeholderItivId = placeholderItiv?.id ?? fail("mutation did not return placeholderItivId");
    const productItivId = productItiv?.id ?? fail("mutation did not return productItivId");
    history.push(createDesignPackageProductSearchUrl(designPackage.id, placeholderItivId, productItivId));
  };

  return (
    <div /* page */ css={Css.h100.w100.bgGray100.oya.$}>
      <div /* top half and form */ css={Css.ma.maxwPx(600).p4.df.fdc.gap2.hmaxc.mbPx(80).$}>
        <div css={Css.xl3Md.$}>Define your Product Slot</div>
        <div css={Css.base.mb2.$}>
          Set the dimensions for your product slot. This will help narrow down your search criteria when you add a
          product and will let other teams at Homebound know you've added a product with those dimensions!
        </div>
        <ItemsBoundSelectField label="Product Type" field={formState.itemId} />
        <BoundTextField
          label="Name"
          field={formState.customSlotName}
          placeholder="e.g. `Primary` or `Bar Sink`"
          disabled={!itemId}
        />
        <LocationsBoundSelectField
          label="Location"
          field={formState.locationId}
          filter={{ designPackageLocation: designPackage.location.code, version: [2] }}
        />
        {madsQuery.loading ? (
          <div>Fetching dimensions...</div>
        ) : (
          madsQuery.data?.materialAttributeDimensions.map((mad) => (
            <SelectField
              key={mad.id}
              label={mad.name}
              unsetLabel="Any"
              // TODO: Make clearable/unsettable/deselectable
              options={mad.values ?? []}
              getOptionValue={(mav) => mav.id}
              // NOTE: This field might need to be updated to handle range values
              //    SC-55230
              getOptionLabel={(mav) => mav?.textValue ?? ""}
              value={mavs[mad.id]}
              onSelect={(mavId) => setMavs({ [mad.id]: mavId })}
            />
          ))
        )}
      </div>
      <div /* bottom buttons */ css={Css.absolute.bottom0.w100.df.fdr.gap2.jcfe.aic.p4.bgWhite.bt.bcGray300.hPx(80).$}>
        <Button label="Cancel" variant="text" onClick={createDesignCatalogUrl(designPackage.id)} />
        <Button label="Add Empty Slot" variant="secondary" disabled={!itemId} onClick={() => onClick(false)} />
        <Button label="Search and Add Product" variant="primary" disabled={!itemId} onClick={() => onClick(true)} />
      </div>
    </div>
  );
}

/**
 * Orchestrates `customSlotName` logic to reflect the currently-selected Item (which we only know
 * asynchronously) and enforce that it's always set. Factors for some unorthodox apollo cache usage
 * to avoid an unnecessary query.
 */
function useDefaultSlotNameToItemName(
  formState: ObjectState<Pick<CreateDesignPackagePlaceholderItivInput, "itemId" | "locationId" | "customSlotName">>,
): void {
  // This data exists in the cache because we populated ItemsBoundSelectField with it. There's no single-item query
  // so we can't make a real query that would hit the cache, and there's no need to make an async query for
  // VERY SIMPLE data we already have. If this was any more complex than id:name we'd defer to a real query.
  const client = useApolloClient();
  const itemId = useComputed(() => formState.itemId.value, [formState]);
  const selectedItemName = client.readFragment({
    id: client.cache.identify({ __typename: "Item", id: itemId }),
    fragment: gql`
      fragment ItemName on Item {
        name
      }
    `,
  })?.name;

  /** if current name is cleared, fill it back in */
  useReaction(
    () => formState.customSlotName.value,
    () => {
      if (formState.readOnly) return;
      if (!formState.customSlotName.value) formState.customSlotName.set(selectedItemName);
    },
    [formState, selectedItemName],
  );

  /** if a selectedItemName triggers a useEffect, set it (i.e. selected item changed) */
  useEffect(() => {
    if (formState.readOnly || !selectedItemName) return;
    formState.customSlotName.set(selectedItemName);
  }, [formState, selectedItemName]);
}

const formConfig: ObjectConfig<
  // Only grab some of these as the rest will be contextually decided upon by the use-case
  Pick<CreateDesignPackagePlaceholderItivInput, "itemId" | "locationId" | "customSlotName">
> = {
  itemId: { type: "value" },
  locationId: { type: "value" },
  customSlotName: { type: "value" },
};
