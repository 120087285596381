import { CsvRow } from "src/components/CsvUploader";
import { DevelopmentContractUploadType } from "./enums";

// Read only them to not be updated
export const planBasedCsvRequiredColumns: readonly string[] = ["HB ID", "Total Cost"];
export const itemizedCsvRequiredColumns: readonly string[] = ["Bid Item ID", "Unit Cost"];

export function getDevContractUploadCsvType(csvContent: CsvRow[]): DevelopmentContractUploadType | undefined {
  if (csvContent.isEmpty) return undefined;
  const { data: headerRow } = csvContent.first!;
  const hasPlanBasedHeader = headerRow.some((h) => planBasedCsvRequiredColumns.includes(h));
  const hasItemizedHeader = headerRow.some((h) => itemizedCsvRequiredColumns.includes(h));

  if (hasPlanBasedHeader) {
    return DevelopmentContractUploadType.PlanBased;
  }

  return hasItemizedHeader ? DevelopmentContractUploadType.Itemized : undefined;
}

export function getDevContractUploadCsvRequiredColumns(
  requestedType: DevelopmentContractUploadType | undefined,
): string[] {
  switch (requestedType) {
    case DevelopmentContractUploadType.PlanBased:
      return [...planBasedCsvRequiredColumns];
    case DevelopmentContractUploadType.Itemized:
      return [...itemizedCsvRequiredColumns];
    default:
      throw new Error(`Invalid CSV content`);
  }
}
