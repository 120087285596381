import { column, Css, emptyCell, GridDataRow, numericColumn, simpleHeader } from "@homebound/beam";
import { percentageCell, priceCell } from "src/components";
import {
  LotType,
  ProjectDashboardFinancials_HocFinancialsFragment,
  ProjectDashboardFinancialsQueryResult,
  Stage,
} from "src/generated/graphql-types";
import { safeEntries } from "src/utils";

export function createFinancialsTableRows(data: ProjectDashboardFinancialsQueryResult["data"]): GridDataRow<Row>[] {
  if (!data) {
    return [simpleHeader];
  }

  const { project } = data;
  const preCon = project.stages.find((s) => s.stage.code === Stage.PreConExecution)?.hocFinancials;
  const construction = project.stages.find((s) => s.stage.code === Stage.Construction)?.hocFinancials;
  const labelToProperty: Record<string, keyof Omit<ProjectDashboardFinancials_HocFinancialsFragment, "__typename">> = {
    "Original Contract Value": "originalContractValueInCents",
    "Original Budgeted Cost": "originalBudgetedCostInCents",
    "Original Gross Margin": "originalGrossMarginBasisPoints",
    "Homeowner Paid": "paidFromHomeownerInCents",
    "Paid To Trades": "paidToTradesInCents",
    "Net Cash": "paidNetInCents",
    "Current Contracted Value": "valueInCents",
    "Current Revised Internal Budget": "costInCents",
    "Current Gross Margin": "grossMarginBasisPoints",
  };

  const isBool = project.lotType?.code === LotType.Bool;
  const hardCostMarkups =
    // If hard costs are the same value, we can override to Hard Cost Markup
    project.indirectHardCostMarkupInBasisPoints === project.directHardCostMarkupInBasisPoints
      ? [
          {
            id: "Hard Cost Markup",
            kind: "percent" as const,
            data: {
              label: "Hard Cost Markup",
              constructionPercent: project.directHardCostMarkupInBasisPoints ?? undefined,
            },
          },
        ]
      : [
          {
            id: "Indirect Hard Cost Markup",
            kind: "percent" as const,
            data: {
              label: "Indirect Hard Cost Markup",
              constructionPercent: project.indirectHardCostMarkupInBasisPoints ?? undefined,
            },
          },
          {
            id: "Direct Hard Cost Markup",
            kind: "percent" as const,
            data: {
              label: "Direct Hard Cost Markup",
              constructionPercent: project.directHardCostMarkupInBasisPoints ?? undefined,
            },
          },
        ];
  const markups = isBool
    ? [
        {
          id: "Soft Cost Markup",
          kind: "percent" as const,
          data: { label: "Soft Cost Markup", constructionPercent: project.softCostMarkupInBasisPoints ?? undefined },
        },
        ...hardCostMarkups,
      ]
    : [];

  return [
    simpleHeader,
    ...safeEntries(labelToProperty).map(([label, prop]) => {
      const isPrice = /incents/i.test(prop);
      return {
        kind: isPrice ? ("price" as const) : ("percent" as const),
        id: label,
        data: {
          label,
          ...(isPrice
            ? { preConPrice: preCon?.[prop], constructionPrice: construction?.[prop] }
            : { preConPercent: preCon?.[prop], constructionPercent: construction?.[prop] }),
        },
      };
    }),
    ...markups,
  ];
}

type HeaderRow = { kind: "header" };
type PriceRow = { kind: "price"; data: { label: string; preConPrice?: number; constructionPrice?: number } };
type PercentRow = { kind: "percent"; data: { label: string; preConPercent?: number; constructionPercent?: number } };
type Row = HeaderRow | PriceRow | PercentRow;

export function createFinancialsTableColumns(inTile: boolean = false) {
  return [
    column<Row>({
      mw: "120px",
      header: emptyCell,
      price: ({ label }) => ({ content: label, css: Css.if(inTile).xsSb.$ }),
      percent: ({ label }) => ({ content: label, css: Css.if(inTile).xsSb.$ }),
    }),
    numericColumn<Row>({
      w: "120px",
      header: "Pre-Con",
      price: ({ preConPrice }) => priceCell({ valueInCents: preConPrice, dropZero: true }),
      percent: ({ preConPercent }) => percentageCell(preConPercent ? preConPercent / 100 : undefined),
    }),
    numericColumn<Row>({
      w: "120px",
      header: "Construction",
      price: ({ constructionPrice }) => priceCell({ valueInCents: constructionPrice, dropZero: true }),
      percent: ({ constructionPercent }) => percentageCell(constructionPercent ? constructionPercent / 100 : undefined),
    }),
  ];
}
