import { Button, Css, GridTableApi, IconButton, useComputed } from "@homebound/beam";

export type CollapseRowToggleProps = {
  rowId: string;
  label: string;
  api: GridTableApi<any>;
  compact?: boolean;
};

/** Wraps `CollapseToggle` and adds a button label. */
export function CollapseRowToggle({ api, rowId, label, compact = false }: CollapseRowToggleProps) {
  const isCollapsed = useComputed(() => api.isCollapsedRow(rowId), [api]);
  const iconKey = isCollapsed ? "chevronRight" : "chevronDown";
  const toggleCollapsedRow = () => api.toggleCollapsedRow(rowId);
  return (
    <div css={Css.df.jcc.$}>
      <Button variant="text" label={label} onClick={toggleCollapsedRow} />
      <IconButton onClick={toggleCollapsedRow} icon={iconKey} compact={compact} />
    </div>
  );
}
