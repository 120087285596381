import { ButtonMenu, MenuItem, useComputed, useModal, useSnackbar } from "@homebound/beam";
import { DeleteItemTemplateVersionsModal } from "./DeleteItemTemplateVersionsModal";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

type ItemTemplateLineItemsBulkActionMenuProps = { itApi: ItemTemplateApi };

export function ItemTemplateLineItemsBulkActionMenu({ itApi }: ItemTemplateLineItemsBulkActionMenuProps) {
  const { openModal } = useModal();
  const selectedItemTemplateIds = useComputed(() => itApi.selectedItivIds, [itApi.selectedItivIds]);

  const hasZeroSelectedItems = selectedItemTemplateIds.isEmpty;
  const { triggerNotice } = useSnackbar();

  const onDuplicate = async () => {
    const { data } = await itApi.copyFromTemplate({
      targetTemplateId: itApi.templateId,
      sourceItemIds: selectedItemTemplateIds,
    });
    triggerNotice({
      message: `${
        data?.copyItemTemplateItems.copiedItemVersions.length === 1
          ? "Item has"
          : `${data?.copyItemTemplateItems.copiedItemVersions.length} items have`
      }  been duplicated`,
      icon: "success",
    });
  };

  const bulkMenuItems = () => {
    const deleteItems: MenuItem = {
      label: "Delete Items",
      disabled: hasZeroSelectedItems,
      onClick: async () =>
        openModal({
          content: <DeleteItemTemplateVersionsModal itApi={itApi} selectedItems={selectedItemTemplateIds} />,
        }),
    };

    const duplicateItems: MenuItem = {
      label: "Duplicate Items",
      disabled: hasZeroSelectedItems,
      onClick: onDuplicate,
    };

    return [deleteItems, duplicateItems];
  };

  return <ButtonMenu items={bulkMenuItems()} trigger={{ label: "Actions" }} />;
}
