import { Css } from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { UppyUploader } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { WithActions } from "src/components/to-dos/WithActions";
import { AssetInfoFragment, AssetInput } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { ProductPageFormState } from "../ProductPage";

export type ImageAsset = AssetInput & {
  downloadUrl?: string;
  attachmentUrl?: string;
};

export type ProductImageUploaderProps = {
  formState: ProductPageFormState;
  compact?: boolean;
};

export function ProductImageEditor({ formState, compact }: ProductImageUploaderProps) {
  function saveImageAttachment(file: UppyFile) {
    const downloadUrl = file.meta.downloadUrl as string;
    formState.images.add({
      downloadUrl,
      attachmentUrl: downloadUrl,
      fileName: file.name,
      s3Key: file.meta.s3Key as string,
      contentType: file.type,
      sizeInBytes: file.size,
    });
  }

  return (
    <div css={Css.df.if(!!compact).fdc.$}>
      <div css={Css.mw50.sm.gray700.mt4.if(!!compact).mb2.$}>Add images</div>
      <Observer>
        {() => (
          <div css={Css.df.fdc.oa.$}>
            {!formState.images.rows.isEmpty && (
              <div css={Css.mb2.df.cg1.oa.$}>
                <AssetGallery
                  assets={formState.images.rows.map((i) => i.value as AssetInfoFragment)}
                  display="horizontal"
                >
                  {(openGallery) => (
                    <>
                      {formState.images.rows.map((attachment) => {
                        const asset = attachment.value as AssetInfoFragment;
                        return (
                          <div key={attachment.downloadUrl.value}>
                            <WithActions
                              left={{ icon: "trash", tooltip: "delete", action: () => formState.images.remove(asset) }}
                              right={{ icon: "download", tooltip: "download", action: asset.attachmentUrl }}
                              footer={{ text: asset.fileName! }}
                              onClick={() => openGallery(asset)}
                            >
                              <AssetPreview asset={asset} dimensions={{ width: 164, height: 180 }} />
                            </WithActions>
                          </div>
                        );
                      })}
                    </>
                  )}
                </AssetGallery>
              </div>
            )}
            <div css={Css.mxa.$}>
              <UppyUploader
                onFinish={saveImageAttachment}
                allowedFileTypes={["image/jpeg", "image/png", "image/gif"]}
                dragDropWidth={345}
                dragDropHeight={91}
              />
            </div>
          </div>
        )}
      </Observer>
    </div>
  );
}
