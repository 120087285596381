import { Button, Css, ModalBody, ModalFooter, ModalHeader, Switch, useModal } from "@homebound/beam";
import { useState } from "react";
import { useCreateChangeEventLineItemsFromCommittedMutation } from "src/generated/graphql-types";

type ImportFromCommittedModalProps = {
  changeEventId: string;
};

/** Applies a template to a Change Event. */
export function ImportFromCommittedModal(props: ImportFromCommittedModalProps) {
  const { changeEventId } = props;
  const { closeModal } = useModal();
  const [createCelisFromCommitted] = useCreateChangeEventLineItemsFromCommittedMutation();
  const [includeDraft, setIncludeDraft] = useState(false);
  const [includeZero, setIncludeZero] = useState(false);

  return (
    <>
      <ModalHeader>Import from Committed</ModalHeader>
      <ModalBody>
        <p css={Css.mb3.$}>Create line items to "true-up" the budget to the current committed amounts.</p>
        <div css={Css.df.fdc.gap2.my2.$}>
          <Switch label="Include Draft Commitments" selected={includeDraft} onChange={setIncludeDraft} />
          <Switch label="Include $0 Committed" selected={includeZero} onChange={setIncludeZero} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Import Lines"
          onClick={async () => {
            await createCelisFromCommitted({ variables: { changeEventId, includeDraft, includeZero } });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
