import { ReactNode } from "react";
import { UserEventType } from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { UserEventRowData } from "../../UserEventsTable";
import { weightedStyle } from "../../utils";

export function ItemTemplateEventText({ event, dateTag }: { event: UserEventRowData; dateTag?: ReactNode }) {
  const { type, createdBy, payload, name } = event;
  const { readyPlan, count } = payload as any;

  switch (type) {
    case UserEventType.Created:
      return (
        <>
          <span>
            {readyPlan ? "Takeoff " : ""}
            <span css={weightedStyle}>{name} </span>
            was added by <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
    case UserEventType.ItemAdded:
      return (
        <>
          <span>
            ({count}) {pluralize(count, "item")} added to takeoff <span css={weightedStyle}>{name} </span>
            by <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
    case UserEventType.ItemRemoved:
      return (
        <>
          <span>
            ({count}) {pluralize(count, "item")} removed from takeoff <span css={weightedStyle}>{name} </span>
            by <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
    case UserEventType.Published:
      return (
        <>
          <span>
            <span css={weightedStyle}>{name} </span>
            was Published by <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
    default:
      return <></>;
  }
}
