import {
  BoundDateField,
  Button,
  Css,
  DateFieldProps,
  LoadingSkeleton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useCallback } from "react";
import {
  Maybe,
  ScheduleSettingDetailsFragment,
  useSaveScheduleTasksMutation,
  useSetDateViaPredecessorModalQuery,
} from "src/generated/graphql-types";
import { useDelayFlagModal } from "src/hooks/useDelayFlagModal";
import { pluralize } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, useFormState } from "src/utils/formState";
import { taskDependencyTypeToAbbreviationMapper } from "src/utils/mappers";
import { calcDependencyLag } from "../calcDependencyLag";
import { DateCellFieldProps } from "./DateCellField";

type SetStartDateViaPredecessorInputProps = Pick<
  DateCellFieldProps,
  "label" | "iconLeft" | "defaultOpen" | "hideCalendarIcon"
> & {
  taskId: string;
  initialValue?: DateOnly;
  disabled: React.ReactNode;
  disabledDays: DateFieldProps["disabledDays"];
  scheduleSetting: Maybe<ScheduleSettingDetailsFragment>;
};

/**  Bypass autosave input and launch modal to calculate predecessor lag instead */
export function SetStartDateViaPredecessorInput({
  taskId,
  initialValue,
  defaultOpen,
  iconLeft,
  hideCalendarIcon,
  label,
  disabled,
  disabledDays,
  scheduleSetting,
}: SetStartDateViaPredecessorInputProps) {
  const { openModal } = useModal();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: initialValue,
      map: (initialValue) => ({ targetStartDate: initialValue }),
    },
  });

  const onDateChange = useCallback(
    (date: Date | undefined) => {
      if (!date) return;

      // Since we're overriding the default `onChange` behavior to open the modal,
      // we still want to keep the form state in sync to be able to revert
      formState.targetStartDate.set(date);
      openModal({
        content: (
          <SetDateViaPredecessorModal
            taskId={taskId}
            targetStartDate={date}
            scheduleSetting={scheduleSetting}
            formState={formState}
          />
        ),
        onClose: () => {
          // Resetting the form on modal close accounts for when a user exits the modal without submitting a change,
          // we can revert back to the initial value. If a user does submit, the form will have been updated with the correct value anyway.
          formState.revertChanges();
        },
      });
    },
    [taskId, openModal, scheduleSetting, formState],
  );

  return (
    <BoundDateField
      field={formState.targetStartDate}
      label={label}
      iconLeft={iconLeft}
      disabled={disabled}
      onChange={onDateChange}
      disabledDays={disabledDays}
      defaultOpen={defaultOpen}
      hideCalendarIcon={hideCalendarIcon}
    />
  );
}

type SetDateViaPredecessorModalProps = {
  taskId: string;
  targetStartDate: Date;
  scheduleSetting: Maybe<ScheduleSettingDetailsFragment>;
  formState: ObjectState<{ targetStartDate?: Date | null | undefined }>;
};

export function SetDateViaPredecessorModal({
  taskId,
  targetStartDate,
  scheduleSetting,
  formState,
}: SetDateViaPredecessorModalProps) {
  const { data } = useSetDateViaPredecessorModalQuery({ variables: { taskId } });
  const { closeModal } = useModal();
  const { openDelayFlagModal } = useDelayFlagModal();
  const [saveScheduleTasks, { loading: saveTaskLoading }] = useSaveScheduleTasksMutation();

  const newDependencyLag = data
    ? calcDependencyLag({
        newStartDate: new DateOnly(targetStartDate),
        originalTaskData: data.scheduleTask,
        scheduleSetting,
      })
    : undefined;

  const onSubmit = useCallback(async () => {
    if (!newDependencyLag) return null;

    const { data } = await saveScheduleTasks({
      variables: { input: { tasks: [{ id: taskId, predecessorDependencies: newDependencyLag.mutationInput }] } },
    });
    formState.commitChanges();

    closeModal();

    const resultingDelay = data?.saveScheduleTasks.resultingDelays[0];
    if (resultingDelay) {
      openDelayFlagModal(resultingDelay);
    }
  }, [newDependencyLag, taskId, saveScheduleTasks, openDelayFlagModal, closeModal, formState]);

  const dayPill = (days: number) => (
    <span css={Css.px1.bgWhite.br24.xsSb.pyPx(2).$}>
      {days} {pluralize(days, "day")}
    </span>
  );

  const predecessorListItems = () => {
    if (!newDependencyLag || saveTaskLoading) return <LoadingSkeleton rows={2} size="lg" />;

    return newDependencyLag.changes.map(({ lagInDays, predecessorId, predecessorName, type, originalLagInDays }) => (
      <div
        key={predecessorId}
        css={Css.dg.add({ gridTemplateColumns: "3fr 35px 2.5fr", alignItems: "center" }).bgGray100.p2.mb2.br8.xs.$}
      >
        <div data-testid="predecessorName">{predecessorName}</div>
        <div data-testid="taskDependencyType" css={Css.tac.$}>
          {taskDependencyTypeToAbbreviationMapper[type]}
        </div>
        <div css={Css.tac.$} data-testid="lagInDaysDelta">
          {dayPill(originalLagInDays)}
          <span css={Css.baseMd.px1.$}>→</span>
          {dayPill(lagInDays)}
        </div>
      </div>
    ));
  };

  return (
    <>
      <ModalHeader>Confirm date change</ModalHeader>
      <ModalBody>
        <p css={Css.gray700.$}>
          Changing the Start date of this task will result in the following changes to dependency lag:
        </p>
        <p css={Css.smBd.py2.$}>Predecessors:</p>
        {predecessorListItems()}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Continue" onClick={onSubmit} disabled={!newDependencyLag || saveTaskLoading} />
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<{
  targetStartDate?: Date | null;
}> = {
  targetStartDate: { type: "value" },
};
