import { History } from "history";

/**
 *  Navigates to `string` in the next loop.
 *
 * I.e. after our formStates have become non-dirty so we don't prompt 'do you want to leave'.
 */
export function pushInNextLoop(history: History, location: string): void {
  doInNextLoop(() => history.push(location));
}

/** Perform `fn` in the next event loop, i.e. after our form state is marked not-dirty. */
export function doInNextLoop(fn: () => void): void {
  setTimeout(fn, 0);
}
