import { ToDoStatus, ToDoStatusDetail, useToDoStatusesQuery } from "../../generated/graphql-types";

export function useToDoStatuses(): ToDoStatusDetail[] {
  const statusesQuery = useToDoStatusesQuery({ fetchPolicy: "cache-first" });

  return (
    statusesQuery.data?.toDoStatuses ??
    Object.values(ToDoStatus).map((code, sortOrder) => ({ code, name: code.replace(/_/g, " "), sortOrder }))
  );
}
