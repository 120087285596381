import { Button, Css, Icon, Palette } from "@homebound/beam";

type LineItemsMergedProps = {
  itemsCount: number;
  editExpenseItems: () => void;
};

export function LineItemsMerged({ itemsCount, editExpenseItems }: LineItemsMergedProps) {
  return (
    <>
      <div css={Css.df.aic.fw7.$} data-testid="lineItemsMergedSection">
        <span data-testid="lineItemsMergedStatement" css={Css.dif.aic.mr1.$}>
          <Icon icon="checkCircle" color={Palette.Green500} xss={Css.mr1.$} /> Merged with {itemsCount} line
          {`${itemsCount > 1 ? " items" : " item"}`}
        </span>
        <Button variant="tertiary" label="Edit" icon="pencil" onClick={editExpenseItems} />
      </div>
    </>
  );
}
