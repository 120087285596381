import { Button, Css, ModalBody, ModalFooter, ModalHeader, RichTextField, useModal } from "@homebound/beam";
import { useState } from "react";
import {
  BillPageV2_BillFragment,
  CommentStreamVisibility,
  PotentialOperation2,
  SaveCommentInput,
} from "src/generated/graphql-types";
import { capitalize, formatList, noop } from "src/utils";

type FinalBillActions = "cancel" | "reverse" | "delete";

export type BillActionConfirmationModalProps = {
  action: FinalBillActions;
  bill: Pick<BillPageV2_BillFragment, "id" | "canCancel" | "canDelete" | "canReverse" | "name">;
  onConfirm: (comment?: SaveCommentInput) => Promise<void>;
};

export function BillActionConfirmationModal({ bill, action, onConfirm }: BillActionConfirmationModalProps) {
  const { closeModal } = useModal();
  const [[html, text], setComment] = useState<[string | undefined, string | undefined]>([undefined, undefined]);

  const operationMapper: Record<FinalBillActions, PotentialOperation2> = {
    cancel: bill.canCancel,
    reverse: bill.canReverse,
    delete: bill.canDelete,
  };

  const disabledReasons = operationMapper[action].disabledReasons.map((r) => r.message);
  const capitalizedAction = capitalize(action);
  return (
    <>
      <ModalHeader>{capitalizedAction} Bill</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.$}>
          <span>Are you sure you want to {action} this bill?</span>
          <span>This is a permanent action and cannot be undone.</span>
          {action === "reverse" && (
            <span css={Css.mt1.$}>
              This action will be synced with the <b>ERP</b> system to ensure data integrity.
            </span>
          )}
          {action !== "delete" && (
            <>
              <div css={Css.mt2.mb1.$}>
                You can optionally add a comment to mention the reason why this action was performed:
              </div>
              <div>
                <RichTextField
                  placeholder="Add a comment..."
                  value={html || ""}
                  onChange={(html, text) => setComment([html, text])}
                  onBlur={noop}
                  onFocus={noop}
                />
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          label={`${capitalizedAction} Bill`}
          data-testid={`confirm${capitalizedAction}`}
          disabled={disabledReasons.nonEmpty ? formatList(disabledReasons) : ""}
          onClick={async () => {
            // Awaiting this so that the modal doesn't close until the action is done and a loading icon is shown.
            await onConfirm(html ? { html, text, streamVisibility: CommentStreamVisibility.Internal } : undefined);
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
