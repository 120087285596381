/**
 * A base class for having mobx-based observable hierarchies in tables.
 *
 * I.e. if you have three division, cost code, and item levels, you can have
 * a page-specific model class for each one, that all extend this base
 * Selectable class.
 *
 * The primary features are: a) providing a consistent container/children
 * interface for `observableAggregate` to use, and b) providing select/unselect
 * that percolates down the tree (i.e. selecting a cost code selects all
 * items in it, and deselecting the cost code unselects all items in it).
 */
export class Selectable<C extends Selectable = Selectable<any>> {
  private _selected: boolean;
  children: C[];

  constructor(
    public id: string,
    selected?: boolean,
    children?: C[],
  ) {
    this._selected = !!selected;
    this.children = children || [];
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(selected: boolean) {
    this._selected = selected;
    this.children?.forEach((c) => (c.selected = selected));
  }

  toggleSelect() {
    this.selected = !this.selected;
  }

  unselect() {
    this.selected = false;
    // Even if `this.selected` didn't change, push down false to all our children
    this.children?.forEach((c) => c.unselect());
  }
}
