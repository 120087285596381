import { Css, px, TabsWithContent, useTestIds } from "@homebound/beam";
import { useState } from "react";
import { CommentFeed2, CommentFeedStyles, HistoryFeed } from "src/components";
import { ProductPageDetailsFragment } from "src/generated/graphql-types";

export type ProductActivitiesContainerProps = {
  product: ProductPageDetailsFragment;
};

export function ProductActivitiesContainer(props: ProductActivitiesContainerProps) {
  const { product } = props;
  const [tab, setTab] = useState<string>("comments");
  const testIds = useTestIds({}, "productActivities");

  return (
    <div css={Css.bgGray100.mlPx(137).mhPx(400).br8.p3.add("height", "max-content").$} {...testIds.container}>
      <TabsWithContent selected={tab} tabs={createTabs(product, testIds)} onChange={setTab} />
    </div>
  );
}

function createTabs(product: ProductPageDetailsFragment, testIds: Record<string, object>) {
  const commentFeedStyles: CommentFeedStyles = {
    mainStyles: Css.w100.$,
    commentsContainerStyles: Css.df.fdc.gap3.mb(px(64)).$,
  };

  return [
    {
      name: "Comments",
      value: "comments" as const,
      render() {
        return (
          <CommentFeed2
            commentable={product}
            hideToggle={true}
            customStyles={commentFeedStyles}
            {...testIds.commentsEditor}
          />
        );
      },
    },
    {
      name: "Activity",
      value: "activity" as const,
      render() {
        return (
          <div css={Css.fn.maxhPx(400).pr3.oa.$}>
            <HistoryFeed historyItems={product.history} />
          </div>
        );
      },
    },
  ];
}
