import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar, useTestIds } from "@homebound/beam";
import identity from "lodash/identity";
import startCase from "lodash/startCase";
import { useSaveOwnerPandaDocMutation } from "src/generated/graphql-types";

type PandaDocModalContentProps = {
  ownerId: string;
  isReUpload: boolean;
};

export function PandaDocModalContent({ ownerId, isReUpload }: PandaDocModalContentProps) {
  const [savePandaDoc] = useSaveOwnerPandaDocMutation();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  const testIds = useTestIds({}, "pdModal");
  const ownerType = pandaDocOwnerName(ownerId);

  return (
    <>
      <ModalHeader>Upload {pandaDocOwnerName(ownerId, true)} to PandaDoc</ModalHeader>
      <ModalBody>
        <p css={Css.sm.gray900.mb2.$}>
          {isReUpload
            ? "Would you like to re-upload to reflect the most current version in Blueprint?"
            : `Are you sure you want to upload this ${ownerType} to PandaDoc?`}
        </p>
        <p css={Css.sm.gray700.$}>
          {isReUpload
            ? `This action will rewrite over the old ${ownerType}. You will no longer be able to access that file.`
            : `If you make changes afterwards, you will need to re-upload this ${ownerType} to PandaDoc.`}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label={isReUpload ? "Re-Upload" : "Upload"}
          onClick={async () => {
            try {
              const { data, errors } = await savePandaDoc({
                variables: { input: { ownerId } },
              });
              const pandaDocId = data?.savePandaDoc.pandaDoc.id;
              if (!pandaDocId) {
                triggerNotice({
                  message: errors?.toString() ?? "Save PandaDoc failed",
                });
                closeModal();
                return;
              }

              triggerNotice({
                message: `${ownerType} is uploading! Check back in a few minutes to view it in PandaDocs`,
                icon: "success",
              });
              closeModal();
            } catch (e) {
              // close modal if error as toast error popup will be displayed
              closeModal();
            }
          }}
          {...testIds.PandaDocUploadTrigger}
        />
      </ModalFooter>
    </>
  );
}

/**
 * Maps a pandaDoc owner ID to something user-readable, like cco:1234 --> "Change Order". Defaults
 * to "commitment" if the key is unrecognized.
 */
export const pandaDocOwnerName = (id: string, capitalize = false): string => {
  const maybeCap: (s: string) => string = capitalize ? startCase : identity;
  if (id.startsWith("cco:")) return maybeCap("change order");
  if (id.startsWith("dc:")) return maybeCap("development commitment");
  if (id.startsWith("bcr:")) return maybeCap("development contract");
  return maybeCap("commitment");
};
