import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  FeatureFlagType,
  ItemTemplateAnomaliesContext_ItAnomalyFragment,
  useItemTemplateAnomaliesContextQuery,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils";

type ItemTemplateAnomaliesContextProps = {
  /** Retrieve the anomalies for a specific parent entity by ID, or all anomalies if undefined */
  anomaliesFor: (parent: string | undefined) => ItemTemplateAnomaliesContext_ItAnomalyFragment[];
  /** Returns true if there are anomalies for the specified parent entity by ID, or any anomalies if undefined */
  hasAnomaliesFor: (parent: string | undefined) => boolean;
  /** Returns true if there are anomalies for an ITIV parent */
  hasLineItemAnomalies: boolean;
};

export const ItemTemplateAnomaliesContext = createContext<ItemTemplateAnomaliesContextProps>({
  anomaliesFor: () => [],
  hasAnomaliesFor: () => false,
  hasLineItemAnomalies: false,
});

export function ItemTemplateAnomaliesProvider({ templateId, children }: { templateId: string } & PropsWithChildren) {
  const { featureIsEnabled } = useFeatureFlags();
  const digitalBuildingExperimentsEnabled = featureIsEnabled(FeatureFlagType.DigitalBuildingExperiments);

  const { data: anomalyData } = useItemTemplateAnomaliesContextQuery({
    variables: { id: templateId },
    skip: !digitalBuildingExperimentsEnabled,
  });

  const context: ItemTemplateAnomaliesContextProps = useMemo(() => {
    const anomaliesByParent = anomalyData?.itemTemplate.anomalies.groupBy((a) => a.parent.id) ?? {};
    const anomaliesFor = (parentId: string | undefined) =>
      (isDefined(parentId) ? anomaliesByParent[parentId] : anomalyData?.itemTemplate.anomalies) ?? [];
    return {
      anomaliesFor,
      hasAnomaliesFor: (parentId) => anomaliesFor(parentId).nonEmpty,
      hasLineItemAnomalies:
        anomalyData?.itemTemplate.anomalies.some((a) => a.parent.__typename === "ItemTemplateItemVersion") ?? false,
    };
  }, [anomalyData]);
  return <ItemTemplateAnomaliesContext.Provider value={context}>{children}</ItemTemplateAnomaliesContext.Provider>;
}

export function useItemTemplateAnomaliesContext() {
  return useContext(ItemTemplateAnomaliesContext);
}
