import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent, useSessionStorage } from "@homebound/beam";
import { useBudgetSuperDrawerQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { BudgetActivityTab } from "./tabs/BudgetActivityTab";
import { BudgetCommentsTab } from "./tabs/BudgetCommentsTab";
import { BudgetDetailsTab } from "./tabs/BudgetDetailsTab";

export type BudgetSuperDrawerProps = {
  projectItemIds: string[];
  title: string;
  projectId: string;
  isPiRow: boolean;
};

export function BudgetSuperDrawer({ projectItemIds, title, projectId, isPiRow }: BudgetSuperDrawerProps) {
  const [selectedTab, setSelectedTab] = useSessionStorage("budgetSuperDrawerTab", "details");
  const query = useBudgetSuperDrawerQuery({ variables: { filter: { id: projectItemIds } } });

  return queryResult(query, {
    data: ({ projectItems }) => {
      const changeEventIds = projectItems
        .flatMap((pi) => pi.changeEventLineItems)
        .map((celi) => celi.changeEvent.id)
        .unique();
      const commitmentLikeIds = projectItems
        .flatMap((pi) => pi.commitmentLineItems)
        .map((li) => li.owner.id)
        .unique();
      const bills = projectItems
        .flatMap((pi) => pi.bills)
        .map((b) => b.id)
        .unique();

      const detailsTab = {
        name: "Details",
        value: "details",
        render: () => <BudgetDetailsTab projectItems={projectItems} projectId={projectId} />,
      };

      const commentTab = {
        name: "Comments",
        value: "comments",
        // The comment tab in the budget super drawer should only display
        // when clicking on a single project item row, so we can safely assume
        // that we are referring to the first and only project item in our drawer query result
        // as the commentable entity
        render: () => <BudgetCommentsTab commentable={projectItems[0]} />,
      };

      const activityTab = {
        name: "Activity",
        value: "activity",
        render: () => (
          <BudgetActivityTab
            projectId={projectId}
            // Restrict activity feed to entities existing on the current selected cost code group or project item row
            parentIds={[...commitmentLikeIds, ...bills, ...changeEventIds]}
          />
        ),
      };

      return (
        <SuperDrawerContent>
          <SuperDrawerHeader title={title} />
          <TabsWithContent
            tabs={[detailsTab, ...(isPiRow ? [commentTab] : []), activityTab]}
            selected={selectedTab}
            onChange={setSelectedTab}
          />
        </SuperDrawerContent>
      );
    },
  });
}
