import {
  BoundSelectField,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
  useComputed,
  useModal,
} from "@homebound/beam";
import { FormActions } from "src/components";
import { CostType, Scalars, useAddItemModalQuery } from "src/generated/graphql-types";
import { safeEntries } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

type AddItemModalProps = {
  /** Passes the form input back to the page b/c we have per-page GQL mutation calls. */
  onAdd: (input: AddSingleItemInput) => Promise<void>;
};

/** Adds a single new project item to pre-sign Specs & Selections. */
export function AddProjectItemModal(props: AddItemModalProps) {
  const { onAdd } = props;
  const { closeModal } = useModal();
  const query = useAddItemModalQuery({ fetchPolicy: "cache-first" });

  const items = query.data?.items || [];
  const isItemsLoading = query.loading;
  const locations = query.data?.locations || [];

  const formState = useFormState({
    config: formConfig,
    addRules(state) {
      state.locationId.rules.push(() => (state.isSelection.value && !state.locationId.value ? "Required" : undefined));
    },
  });

  const isSelectionOptionAvailable = useComputed(() => {
    if (isItemsLoading) {
      return false;
    }
    const itemId = formState.itemId.value;
    const item = items.find((i) => i.id === itemId);
    return item && item.isSelection && formState.costType.value === CostType.Materials;
  }, [formState, items, isItemsLoading]);

  const onCostTypeSelection = (costType: CostType | undefined) => {
    formState.costType.set(costType);
    setIsSelectionAfterChange();
  };

  const setIsSelectionAfterChange = () => {
    formState.isSelection.set(getIsSelectionForItemAndCostType());
  };

  const getIsSelectionForItemAndCostType = (): boolean => {
    const itemId = formState.itemId.value;
    const item = items.find((i) => i.id === itemId);
    if (!item) return false;
    return item.isSelection && formState.costType.value === CostType.Materials;
  };

  const isSelection = useComputed(() => {
    if (isItemsLoading) return false;
    return formState.isSelection.value;
  }, [formState, isItemsLoading]);

  return (
    <>
      <ModalHeader>Add Item</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundSelectField
            field={formState.itemId}
            options={items}
            getOptionLabel={(item) => item.displayName}
            getOptionValue={(item) => item.id}
            onSelect={(itemId) => {
              formState.itemId.set(itemId);
              // location field is displayed based on the whether the selected item is a selection.
              setIsSelectionAfterChange();
              // If we remove the field from the UI, ensure we unset it.
              if (!formState.isSelection.value) {
                formState.locationId.set(undefined);
              }
            }}
          />
          <BoundSelectField
            field={formState.costType}
            options={safeEntries(CostType)}
            getOptionLabel={([name]) => name}
            getOptionValue={([, code]) => code}
            onSelect={onCostTypeSelection}
          />
          {isSelectionOptionAvailable && (
            <Switch
              data-testid="isSelection"
              label="Enable Selection"
              labelStyle="form"
              selected={formState.isSelection.value!}
              onChange={(value) => formState.isSelection.set(value)}
            />
          )}
          {isSelection && <BoundSelectField field={formState.locationId} options={locations} />}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <FormActions
          mode="create"
          formState={formState}
          onSave={async () => {
            const { ...input } = formState.value;
            await onAdd(input);
            closeModal();
          }}
          primaryLabel="Add"
          onCancel={closeModal}
          onEdit={() => {}}
        />
      </ModalFooter>
    </>
  );
}

export type AddSingleItemInput = {
  itemId?: Scalars["ID"] | null;
  costType?: CostType | null;
  isSelection?: boolean | null;
  locationId?: Scalars["ID"] | null;
};

const formConfig: ObjectConfig<AddSingleItemInput> = {
  itemId: { type: "value", rules: [required] },
  costType: { type: "value", rules: [required] },
  locationId: { type: "value" },
  isSelection: { type: "value", rules: [required] },
};
