import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isDefined } from "src/utils";
import { ZodObject, ZodRawShape, z } from "zod";

/**
 * Provised a useQueryString-like hook where the state is constantly reflected in the URL via URL Search/Query Params.
 *
 * Uses Zod to validate the state and the Query String and return a strongly typed sub set state fields.
 * @param schema Zod Object Schema
 */
export const useZodQueryString = <T extends ZodRawShape>(
  schema: ZodObject<T>,
): [z.infer<typeof schema>, (state: z.infer<typeof schema>) => void] => {
  const history = useHistory();
  const location = useLocation();
  /** Gather up/initialize from active Query String in the URL. Recalc any time QueryString changes. */
  const parsedFields = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return schema.parse([...queryParams.entries()].toObject());
  }, [location.search, schema]);

  // Update the Query String when the state changes
  const setState = useCallback(
    (newState: z.infer<typeof schema>) => {
      // Get the current Query Params
      const newQueryParams = new URLSearchParams(history.location.search);
      // Update the Query Params with the new state
      for (const [key, value] of Object.entries(newState)) {
        // Remove any keys that are undefined|null
        if (!isDefined(value)) {
          newQueryParams.delete(key);
        } else {
          newQueryParams.set(key, String(value));
        }
      }
      // Update the URL with the new Query Params
      history.replace({ search: newQueryParams.toString(), state: history.location.state });
    },
    [history],
  );

  return [parsedFields, setState];
};

export default useZodQueryString;
