/** Returns a local path to the file. */
export function readFileAsDataURL(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("File could not be read as a string."));
      }
    };
    reader.onerror = () => reject(reader.error || new Error("An error occurred while reading the file."));
    reader.readAsDataURL(file);
  });
}
