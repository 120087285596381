import { Css, Palette, Tag, Tooltip } from "@homebound/beam";
import { sentenceCase } from "change-case";
import { useHistory } from "react-router-dom";
import { Icon } from "src/components/Icon";
import { JobLogNoteDetailFragment, Stage } from "src/generated/graphql-types";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { combineFlagsByReasonTitle } from "src/utils/jobLog";

type JobLogTagsProps = {
  note: JobLogNoteDetailFragment;
};

export function JobLogNoteTags(props: JobLogTagsProps) {
  const history = useHistory();
  const {
    note: { tradePartner, scheduleTasks },
  } = props;

  const flags = combineFlagsByReasonTitle(scheduleTasks.flatMap((st) => st.scheduleFlags));

  const containerCss = Css.df.gap1.mb1.$;

  const flagsContainerCss = Css.df.add({ gap: "4px", flexWrap: "wrap" }).$;

  if (!tradePartner && flags.isEmpty && scheduleTasks.isEmpty) return null;

  return (
    <>
      <div css={Css.df.fdc.mb2.$}>
        {scheduleTasks.nonEmpty && (
          <div css={containerCss}>
            <Icon color={Palette.Gray800} icon="link" />
            <div css={flagsContainerCss}>
              {scheduleTasks.map((task) => {
                return (
                  <Tooltip title={task.name} key={task.id}>
                    <div
                      css={Css.mrPx(5).cursorPointer.maxwPx(300).$}
                      onClick={() => {
                        task.project &&
                          history.push(createProjectScheduleUrl(task.project?.id, Stage.Construction, task.id));
                      }}
                    >
                      <Tag type="info" text={`LINKED TO [${task.name}]`} />
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        )}
        {flags.nonEmpty && (
          <div css={containerCss}>
            <Icon color={Palette.Red600} icon="flag" />
            <div css={flagsContainerCss}>
              {flags.map((key) => {
                return (
                  <span key={key} css={Css.mrPx(5).$}>
                    <Tag type="warning" text={sentenceCase(key)} />
                  </span>
                );
              })}
            </div>
          </div>
        )}
        {tradePartner?.name && (
          <div css={containerCss}>
            <span css={Css.tac.xsMd.gray700.mlPx(5).$}>TP</span>
            <div css={flagsContainerCss}>
              <span css={Css.mlPx(5).$}>
                <Tag type="neutral" text={tradePartner.name} data-testid="tradePartner" />
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
