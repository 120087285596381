import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { stageSlugToStageCode } from "src/utils";

export function useStripStage() {
  const location = useLocation();
  const history = useHistory();
  const stageSlugs = Object.keys(stageSlugToStageCode);
  const regEx = new RegExp(`/(${stageSlugs.join("|")})`);

  if (regEx.test(location.pathname)) {
    history.replace(location.pathname.replace(regEx, ""));
  }
}
