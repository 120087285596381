import {
  BryntumGantt,
  BryntumProjectModel,
  CalendarModelConfig,
  TaskMenuConfig,
  TaskModel,
  TaskModelConfig,
} from "@homebound/schedules-v2-gantt";
import { RefObject } from "react";
import { GanttScheduleTaskFragment, TaskDependencyType } from "src/generated/graphql-types";
import { DeepPartial } from "src/utils";

export type RowType = "Task" | "SchedulePhase" | "ScheduleSubPhase";
export type GanttTask = Partial<TaskModelConfig> & { type: RowType; id: string };
export type OriginalTaskDataById = Record<string, GanttScheduleTaskFragment>;

// This calendar interval serves two purposes:
// 1. Sets weekends to a gray color in the UI
// 2. Using "Fri at 23:59" ensures Saturdays are not included in our schedule. This will force a 1 day task that starts on Friday to end on Monday (not Saturday).
export const calendarConfig: DeepPartial<CalendarModelConfig> = {
  id: "general",
  name: "general",
  unspecifiedTimeIsWorking: true,
  intervals: [
    {
      recurrentStartDate: "on Fri at 23:59",
      recurrentEndDate: "on Sun at 23:59",
      isWorking: false,
    },
  ],
};

// Controls the right-click dropdown menu options
// Features that are not supported in Blueprint are disabled by selecting `false`
export const taskMenuFeatureConfig: Partial<TaskMenuConfig> = {
  items: {
    deleteTask: false,
    cut: false,
    copy: false,
    add: false,
    indent: false,
    outdent: false,
    convertToMilestone: false,
  },
  processItems({ taskRecord }: { taskRecord: GanttTask }) {
    // Only show menu at lowest "task" level (not for phase or subPhase)
    // Once SC-18696 is done, we could reuse that work to add an "Add Task" option at the Phase and SubPhase levels
    if (taskRecord.type !== "Task") {
      return false;
    }
  },
};

export type BryntumDependencyNumbers = 0 | 1 | 2 | 3;

export const dependencyTypeToBryntumNumber: Record<TaskDependencyType, BryntumDependencyNumbers> = {
  [TaskDependencyType.FinishFinish]: 3,
  [TaskDependencyType.FinishStart]: 2,
  [TaskDependencyType.StartStart]: 0,
  [TaskDependencyType.StartFinish]: 1,
};

export const bryntumNumberToTaskDependencyType: Record<BryntumDependencyNumbers, TaskDependencyType> = {
  3: TaskDependencyType.FinishFinish,
  2: TaskDependencyType.FinishStart,
  0: TaskDependencyType.StartStart,
  1: TaskDependencyType.StartFinish,
};

/** The returned function assumes a task has been rendered within the Gantt chart before attempting to scroll it into view  */
export function getScrollGanttTaskIntoViewById({
  ganttRef,
  projectRef,
}: {
  projectRef: RefObject<BryntumProjectModel>;
  ganttRef: RefObject<BryntumGantt>;
}) {
  return function (taskId: string) {
    if (projectRef.current && ganttRef.current && taskId) {
      const taskReference = projectRef.current.instance.taskStore.getById(taskId) as TaskModel;
      if (!taskReference) {
        console.warn(`Task ${taskId} was not found within the Gantt TaskStore`);
      }

      // Once we have a reference to the task within the TaskStore, we can then tell the chart to scroll that element into view
      return ganttRef.current.instance.scrollTaskIntoView(taskReference, {
        animate: true,
        focus: true,
        highlight: true,
        block: "center",
      });
    }
  };
}
