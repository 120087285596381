import { Selectable } from "src/models/Selectable";
import { calculateMarkup, sum } from "src/utils";

export type RollupChild = {
  id: string;
  totalCostInCents: number;
  totalPriceInCents: number;
  taskId?: string;
} & Selectable;

export class SpecsAndSelectionsRollup<C extends RollupChild> extends Selectable<C> {
  constructor(
    // The id of the roll up row
    id: string,
    // The name of the roll up, i.e cost code or trade category, to use in the rollup.
    public name: string,
    children: C[],
  ) {
    super(id, false, children);
  }

  get totalCostInCents(): number {
    return this.children.map((c) => c.totalCostInCents || 0).reduce(sum, 0);
  }

  get totalPriceInCents(): number {
    return this.children.map((c) => c.totalPriceInCents || 0).reduce(sum, 0);
  }

  get allocatedCount(): number {
    return this.children.filter((c) => c.taskId).length;
  }

  get allocatedCountWithCost(): number {
    return this.children.filter((c) => c.taskId && c.totalCostInCents > 0).length;
  }

  get itemCountWithCost(): number {
    return this.children.filter((c) => c.totalCostInCents > 0).length;
  }

  get allocatedTotal(): number {
    return this.children
      .filter((c) => c.taskId)
      .map((c) => c.totalCostInCents || 0)
      .reduce(sum, 0);
  }

  get totalMarkupAmount(): number {
    return calculateMarkup(this.totalCostInCents, this.totalPriceInCents).markupAmount;
  }

  get totalMarkupPercent(): number {
    return calculateMarkup(this.totalCostInCents, this.totalPriceInCents).markupPercentage;
  }
}
