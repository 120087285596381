import { Button, Css, SelectField, useComputed } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useState } from "react";
import { HoverDelete } from "src/components/HoverDelete";
import { ProjectStageProjectItemDetailFragment } from "src/generated/graphql-types";
import { FormValue } from "./TaskDetailPane";

type CostAllocationProps = {
  formState: FormValue;
  projectItems: ProjectStageProjectItemDetailFragment[];
  allocatedProjectItemIds: string[];
};

export function CostAllocation({ formState, projectItems, allocatedProjectItemIds }: CostAllocationProps) {
  const [showSelect, setShowSelect] = useState(false);
  const [hoveredId, setHoveredId] = useState("");
  const projectItemOptions = projectItems.map(({ id, displayName, costTypeDetail, location }) => {
    const locationName = location?.name;
    const value = id;
    let label = displayName;

    if (locationName) {
      label += ` - ${locationName}`;
    }

    label += ` - ${costTypeDetail.name}`;

    return { value, label };
  });
  const filteredProjectItems = useComputed(
    () => projectItemOptions.filter(({ value }) => formState.projectItems.value?.includes(value)),
    [projectItems],
  );
  const filteredProjectItemIds = filteredProjectItems.map(({ value }) => value);

  return (
    <Observer>
      {() => (
        <>
          <div css={Css.smMd.mb1.$}>
            <span css={Css.df.aic.jcsb.sm.gray700.$}>
              Cost Allocation
              <Button
                label="Add a Project Item"
                variant="tertiary"
                onClick={() => setShowSelect(true)}
                disabled={showSelect}
              />
            </span>
          </div>
          {showSelect && (
            <div css={Css.df.jcsb.aic.p2.bgWhite.br8.mb1.$}>
              <SelectField
                options={projectItemOptions}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                onSelect={(value) => {
                  if (value) {
                    formState.projectItems.set([...filteredProjectItemIds, value]);
                    setShowSelect(false);
                  }
                }}
                disabledOptions={allocatedProjectItemIds}
                label="projectItems"
                labelStyle="hidden"
                value={"" as string}
                placeholder="Search for a project item..."
                compact
              />
            </div>
          )}
          {filteredProjectItems.length > 0 ? (
            <div data-testid="allocatedProjectItems" css={Css.df.fdc.gap1.mb1.$}>
              {filteredProjectItems.map((projectItem) => {
                const { label } = projectItem;
                const projectItemId = projectItem.value;
                return (
                  <div css={Css.df.fdc.br8.bgGray100.$} key={projectItemId}>
                    <div
                      onPointerEnter={() => setHoveredId(projectItemId)}
                      onPointerLeave={() => setHoveredId("")}
                      css={Css.df.jcfs.gap1.aic.xs.p2.pr1.br8.$}
                      key={projectItemId}
                    >
                      <div data-testid={`${projectItemId}-project-item`} css={Css.w100.$}>
                        {label}
                      </div>
                      <HoverDelete
                        data-testid="deleteCostAllocation"
                        visible={hoveredId === projectItemId}
                        onClick={() => {
                          formState.projectItems.set(filteredProjectItemIds.filter((id) => id !== projectItemId));
                          setShowSelect(false);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div data-testid="noProjectItems" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
              There are no allocated project items
            </div>
          )}
        </>
      )}
    </Observer>
  );
}
