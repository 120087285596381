import { useEffect } from "react";
import { getStage } from "src/context";
import { useAppNotificationsContext } from "src/contexts/AppNotificationsContext";
import { useCurrentUserZenDeskJwtQuery } from "src/generated/graphql-types";

export function ZendeskWidget() {
  const { refetch: getUserJWT } = useCurrentUserZenDeskJwtQuery({ skip: true });
  const { push } = useAppNotificationsContext();

  useEffect(
    () => {
      // Even qa doesn't have a zendesk token, so only use this in prod
      if (getStage() !== "prod") return;

      const script = document.createElement("script");
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=52f61412-865a-4480-b160-6d68e0337ea4";
      script.id = "ze-snippet";

      script.onload = () => {
        // Commands from https://developer.zendesk.com/api-reference/widget-messaging/web/core/
        // @ts-ignore
        zE("messenger", "hide");
        // @ts-ignore
        zE("messenger", "loginUser", function (callback) {
          void getUserJWT().then(function ({ data }) {
            callback(data.currentUser?.zendeskJwt);
          });
        });
        // @ts-ignore
        zE("messenger:on", "unreadMessages", function (count) {
          if (count > 0) {
            push(`You have ${count} unread message(s).`);
          }
        });
      };
      document.body.appendChild(script);
      return () => {
        // @ts-ignore
        zE("messenger", "logoutUser");
        document.body.removeChild(script);
      };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <></>;
}

export function openZendesk() {
  // @ts-ignore
  zE("messenger", "show");
  // @ts-ignore
  zE("messenger", "open");
}
