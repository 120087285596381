import {
  actionColumn,
  Button,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  multiFilter,
  numericColumn,
  RowStyles,
  selectColumn,
  singleFilter,
  useComputed,
  useGridTableApi,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { priceCell, SearchBox } from "src/components";
import {
  BulkAddProjectItem_ProjectItemFragment,
  Stage,
  useBulkAddProjectItemsQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { assertNever, costTypeToNameMapper, groupBy, hasData, renderLoadingOrError } from "src/utils";

type HiddenProjectItemsProp = string[] | ((projectItem: BulkAddProjectItem_ProjectItemFragment) => boolean);

export type BulkAddProjectItemsModalProps = {
  stage: Stage;
  projectId: string;
  onAdd: (projectItemId: string[], projectItems: BulkAddProjectItem_ProjectItemFragment[]) => void;
  groupBy: CeliGroupByValue;
  hiddenProjectItemIds: HiddenProjectItemsProp;
  submitButtonText: string;
};

export enum CeliGroupByValue {
  CostCode = "costCode",
  CurrentTradePartner = "currentTradePartner",
}

export function BulkAddProjectItemsModal(props: BulkAddProjectItemsModalProps) {
  const { stage, projectId, groupBy, onAdd, hiddenProjectItemIds, submitButtonText } = props;
  const testIds = useTestIds({}, "bulkAddItemsModal");
  const query = useBulkAddProjectItemsQuery({ variables: { projectId, stage } });
  const projectItems = useMemo(
    () => query.data?.project.stage.projectItems || [],
    [query.data?.project.stage.projectItems],
  );
  const { closeModal } = useModal();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [filter, setFilter] = useState<Filter>({});

  const columns = useMemo(() => createColumns(groupBy), [groupBy]);
  const rows = useMemo(
    () => createRows(projectItems, filter, groupBy, hiddenProjectItemIds),
    [projectItems, groupBy, filter, hiddenProjectItemIds],
  );
  const filterDefs = useMemo(() => createFilterDefs(stage, projectItems), [stage, projectItems]);
  const tableApi = useGridTableApi<Row>();

  const selectedProjectItems = useComputed(() => {
    return tableApi.getSelectedRowIds("lineItem");
  }, [tableApi]);

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  return (
    <>
      <ModalHeader>Select Items</ModalHeader>
      <ModalBody>
        <div {...testIds}>
          <div css={Css.df.mb2.$}>
            <h2 css={Css.baseMd.$}>Line Items</h2>
            {!!selectedProjectItems?.length && (
              <div css={Css.mla.xs.$}>
                {selectedProjectItems?.length} project item{selectedProjectItems?.length === 1 ? "" : "s"} selected
              </div>
            )}
          </div>
          <TableActions>
            <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
            <SearchBox onSearch={setSearchFilter} />
          </TableActions>
          <GridTable
            columns={columns}
            rows={rows}
            rowStyles={rowStyles}
            style={{ grouped: true }}
            filter={searchFilter}
            stickyHeader
            fallbackMessage="No Line Items found for the selected projects."
            sorting={{ on: "client", initial: ["group", "DESC"] }}
            api={tableApi}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label={submitButtonText}
          disabled={!selectedProjectItems?.length}
          onClick={() => {
            const pIds = selectedProjectItems || [];
            closeModal();
            onAdd(
              pIds,
              pIds.map((id) => projectItems.find((pi) => pi.id === id) as BulkAddProjectItem_ProjectItemFragment),
            );
          }}
        />
      </ModalFooter>
    </>
  );
}

const rowStyles: RowStyles<Row> = {
  header: { cellCss: { ...Css.bgGray200.$ } },
  group: { cellCss: { ...Css.bgGray100.$ } },
};

type HeaderRow = Record<string, any>;
type Row =
  | { kind: "header"; data: HeaderRow; id: string }
  | { kind: "group"; data: BulkAddProjectItem_ProjectItemFragment[]; id: string }
  | { kind: "lineItem"; data: BulkAddProjectItem_ProjectItemFragment; id: string };
type Filter = {
  view?: "selections";
  locationId?: string[];
};

function createRows(
  projectItems: BulkAddProjectItem_ProjectItemFragment[],
  filter: Filter,
  groupBy: CeliGroupByValue,
  hiddenProjectItemIds: HiddenProjectItemsProp,
): GridDataRow<Row>[] {
  /** Group project items by costCode or TradeCategory keys */
  const groupedProjects = getProjectsItems(projectItems, groupBy, filter, hiddenProjectItemIds);

  return [
    { kind: "header", id: "header", data: groupedProjects },
    ...groupedProjects.map(([gr, pi]) => ({
      kind: "group" as const,
      id: gr,
      data: pi,
      children: pi.map((i: BulkAddProjectItem_ProjectItemFragment) => ({
        kind: "lineItem" as const,
        id: i.id,
        data: i,
      })),
    })),
  ];
}

function createColumns(groupBy: CeliGroupByValue): GridColumn<Row>[] {
  const isGroupedByCostCode = groupBy === CeliGroupByValue.CostCode;
  return [
    actionColumn<Row>({
      header: (data, { row }) => {
        return <CollapseToggle row={row} />;
      },
      group: (data, { row }) => <CollapseToggle row={row} />,
      lineItem: emptyCell,
      w: "32px",
    }),
    selectColumn<Row>({ w: "32px" }),
    column<Row>({
      id: "group",
      header: isGroupedByCostCode ? "Cost Code" : "Trade Partner",
      group: (data) =>
        isGroupedByCostCode ? data[0]?.item?.costCode?.displayName : data[0]?.currentTradePartner?.name,
      lineItem: (data) => data?.displayName,
      w: 2,
    }),
    column<Row>({
      id: "id",
      header: emptyCell,
      group: emptyCell,
      lineItem: (data) => data.id,
    }),
    column<Row>({
      header: "PO#",
      group: emptyCell,
      lineItem: (data) => data.currentCommitment?.accountingNumber,
      w: 1,
    }),
    column<Row>({
      header: "Location",
      group: emptyCell,
      lineItem: (data) => data.location.name,
      w: 1,
    }),
    column<Row>({
      header: "Cost Type",
      group: emptyCell,
      lineItem: (data) => data.costType && costTypeToNameMapper[data.costType],
      w: 1,
    }),
    column<Row>({ header: "Bid Item", group: emptyCell, lineItem: (data) => data.bidItem?.name }),
    numericColumn<Row>({
      header: "Cost",
      group: (data) => priceCell({ valueInCents: data.sum((pi) => pi.totalCostInCents) }),
      lineItem: (data) => priceCell({ valueInCents: data.totalCostInCents }),
      w: 1,
    }),
    numericColumn<Row>({
      header: "Trade Billed",
      group: (data) => priceCell({ valueInCents: data.sum((pi) => pi.budgetFinancials.tradeBilled) }),
      lineItem: (data) => priceCell({ valueInCents: data.budgetFinancials.tradeBilled }),
      w: 1,
    }),
  ];
}

function createFilterDefs(stage: Stage, projectItems: BulkAddProjectItem_ProjectItemFragment[]): FilterDefs<Filter> {
  const allLocations = projectItems.map(({ location }) => location).filter((l) => !!l);
  const locations = [...new Map(allLocations?.map((location) => [location!.id, location])).values()];
  return {
    // The precon stage doesn't have selections, so hide `View: Selections`
    ...(stage === Stage.Construction
      ? {
          view: singleFilter({
            options: [{ id: "selections" as const, name: "Selections" }],
            getOptionValue: (o) => o.id,
            getOptionLabel: (o) => o.name,
          }),
        }
      : {}),
    locationId: multiFilter({
      options: locations,
      getOptionValue: (location) => location!.id,
      getOptionLabel: (location) => location!.name,
    }),
  };
}

function getProjectsItems(
  projectItems: BulkAddProjectItem_ProjectItemFragment[],
  groupedBy: CeliGroupByValue,
  filter: Filter,
  hiddenProjectItemIds: HiddenProjectItemsProp,
): [string, BulkAddProjectItem_ProjectItemFragment[]][] {
  const isOmittedItem = (pi: BulkAddProjectItem_ProjectItemFragment) =>
    (filter?.locationId && (!pi?.location.id || !filter.locationId?.includes(pi?.location.id))) ||
    (filter.view === "selections" && !pi?.isSelection) ||
    Array.isArray(hiddenProjectItemIds)
      ? (hiddenProjectItemIds as string[]).includes(pi.id)
      : hiddenProjectItemIds(pi);

  const groupedItems = groupBy(
    projectItems.filter((pi) => !isOmittedItem(pi) && getGroupByValue(groupedBy, pi)),
    (pi) => getGroupByValue(groupedBy, pi) || "",
  );
  return Object.entries(groupedItems);
}

function getGroupByValue(groupBy: CeliGroupByValue, pi: BulkAddProjectItem_ProjectItemFragment): string | undefined {
  switch (groupBy) {
    case CeliGroupByValue.CostCode:
      return pi.item[CeliGroupByValue.CostCode].id;
    case CeliGroupByValue.CurrentTradePartner:
      return pi[CeliGroupByValue.CurrentTradePartner]?.id;
    default:
      return assertNever(groupBy);
  }
}
