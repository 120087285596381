import {
  Css,
  IconButton,
  NavLink,
  Palette,
  ScrollableContent,
  ScrollableParent,
  useBreakpoint,
  useTestIds,
} from "@homebound/beam";
import React, { ReactNode, useEffect } from "react";
import { Route, matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { useSideNavContext } from "src/components/layout/SideNavContext";
import { getStage } from "src/context";
import { FeatureFlagHook, useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { FeatureFlagType } from "src/generated/graphql-types";
import { topNavHeight } from "src/routes/layout/GlobalNav";

export type SideNavProps = {
  top?: ReactNode;
  sideNavConfig: SideNavAndRouterConfig[];
  contrast?: boolean;
  navWidth?: number;
};

export function SideNav({ top, sideNavConfig, contrast = false, navWidth = 220 }: SideNavProps) {
  const { sideNavState, setSideNavState } = useSideNavContext();
  const bp = useBreakpoint();
  const tid = useTestIds({}, "sideNav");
  const location = useLocation();
  const { featureIsEnabled } = useFeatureFlags();

  const navToggleSize = 24;
  const navToggleLeft = navWidth - navToggleSize - 20;
  const closedNavLeft = sideNavCollapsedWidth - navWidth;
  const navExpanded = sideNavState === "expanded";
  const navHidden = sideNavState === "hidden";

  // Smartly auto-close the side nav on smaller screens when switching between routes / location changes.
  useEffect(() => {
    if (navExpanded && bp.mdAndDown) {
      setSideNavState("collapse");
    }
    // Explicitly not including `navExpanded` as we don't want to immediately close the nav if someone just opened it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, bp.mdAndDown, setSideNavState]);

  const [bgColor, bColor, iconColor, titleColor] = contrast
    ? [Palette.Gray900, Palette.Gray800, Palette.Gray500, Palette.Gray400]
    : [Palette.White, Palette.Gray200, Palette.Gray800, Palette.Gray900];

  if (navHidden) return null;

  return (
    <div
      css={{
        ...Css.df.relative
          .bgColor(bgColor)
          .bc(bColor)
          .transition.br.fg0.fs0.z999.wPx(navWidth)
          .if(!navExpanded)
          .mlPx(closedNavLeft).$,
        ...(bp.mdAndDown ? Css.absolute.h(`calc(100% - ${topNavHeight}px)`).$ : {}),
      }}
      {...tid}
    >
      <ScrollableParent>
        <div css={Css.relative.$}>
          <div css={Css.absolute.top3.leftPx(navToggleLeft).df.aic.jcc.$}>
            <IconButton
              icon={navExpanded ? "menuClose" : "menuOpen"}
              onClick={() =>
                setSideNavState((prevState) =>
                  prevState === "expanded" ? ("collapse" as const) : ("expanded" as const),
                )
              }
              color={iconColor}
              contrast={contrast}
              {...tid.toggle}
            />
          </div>
          {navExpanded && (
            <>
              {top}
              {sideNavConfig.length > 0 && (
                <ScrollableContent>
                  <nav>
                    <ul css={Css.listReset.df.fdc.gap2.if(!top).pt8.$}>
                      {sideNavConfig.map(({ title, items }, idx) => (
                        <li key={title ?? idx} css={Css.px2.pt2.bt.bc(bColor).addIn(":first-of-type", Css.pt0.bn.$).$}>
                          {title && <h3 css={Css.tinySb.px1.pbPx(2).color(titleColor).ttu.$}>{title}</h3>}
                          <ul css={Css.listReset.$}>
                            {items
                              .filter(({ featureFlag }) => !featureFlag || featureIsEnabled(featureFlag))
                              .filter(({ hideInProd }) => !(getStage() === "prod" && hideInProd))
                              .map(({ label, href, path, isExternal }) => (
                                <li key={label}>
                                  <NavLink
                                    label={label}
                                    variant="side"
                                    contrast={contrast}
                                    href={href}
                                    active={path ? !!matchPath(location.pathname, { path }) : false}
                                    icon={isExternal ? "linkExternal" : undefined}
                                    {...tid.link}
                                  />
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </ScrollableContent>
              )}
            </>
          )}
        </div>
      </ScrollableParent>
    </div>
  );
}

export type SideNavAndRouterConfig = {
  title?: string;
  items: SideNavAndRouterConfigItem[];
};

export type SideNavAndRouterConfigItem = {
  /** Will prevent sidebar links from being generated in production, but you should still be able to URL-hack to it */
  hideInProd?: boolean;
  label: string;
  href: string;
  /** Used to match against the URL to denote this nav link item as "active" and when creating the <Route /> component
   * It is optional for cases where the `href` links outside the application (e.g. `isExternal: true`)
   * If not defined, a <Route /> component will not be created. */
  path?: string | string[];
  isExternal?: boolean;
  /** Notifies <Route /> path is exact. This is only used when creating `<Route />` via the SideNavConfigItems */
  routeIsExact?: true;
  /** Passed into <Route component={component} />.
   * This is only used when creating `<Route />` via the SideNavConfigItems
   * If not defined, a <Route /> component will not be created and will need to be explicitly defined to handle the route. */
  component?: React.ComponentProps<typeof Route>["component"];
  featureFlag?: FeatureFlagType;
};

export function navLinksToRoutes(
  config: SideNavAndRouterConfig[],
  featureIsEnabled?: FeatureFlagHook["featureIsEnabled"],
): React.ReactNode[] {
  return (
    config
      .flatMap(({ items }) => items)
      // filter out items that don't have a component and path defined.
      .filter(({ component, path }) => !!component && !!path)
      .filter(({ featureFlag }) => !featureFlag || (featureIsEnabled && featureIsEnabled(featureFlag)))
      .map(({ routeIsExact, path, component }: SideNavAndRouterConfigItem) => (
        <Route key={Array.isArray(path) ? path.join() : path} path={path} exact={routeIsExact} component={component} />
      ))
  );
}

export const sideNavCollapsedWidth = 56;
