import {
  BoundDateField,
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { createProjectUrl } from "src/RouteUrls";
import {
  CreateProjectFromReadyPlanInput,
  EditProductOfferingConfigQuery,
  InputMaybe,
  LotType,
  useCreateProjectFromConfigMetaQuery,
  useCreateProjectFromProductOfferingConfigMutation,
} from "src/generated/graphql-types";
import { fail, queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { openNewTab } from "src/utils/window";

type CreateProjectFromOfferingConfigModalProps = {
  developmentId: string;
  productOfferingConfig: Pick<EditProductOfferingConfigQuery["productOfferingConfig"], "id" | "name">;
};

export function CreateProjectFromOfferingConfigModal(props: CreateProjectFromOfferingConfigModalProps) {
  const { closeModal } = useModal();
  const { developmentId, productOfferingConfig } = props;
  const { name } = productOfferingConfig;
  const query = useCreateProjectFromConfigMetaQuery({
    variables: { developmentId, productOfferingConfigId: productOfferingConfig.id },
  });
  const { triggerNotice } = useSnackbar();
  const [createProject] = useCreateProjectFromProductOfferingConfigMutation({
    onCompleted: ({ createProjectFromReadyPlan }) => {
      const { project } = createProjectFromReadyPlan;
      openNewTab(createProjectUrl(project.id));
      closeModal();
      triggerNotice({ message: `New project ${project.name} created.` });
    },
  });
  const formState = useFormState({
    config: createProjectFormConfig,
    init: {
      query,
      map: (input) => ({
        rpId: input.productOfferingConfig.productOffering.id ?? "",
        rpoIds: input.productOfferingConfig.readyPlanOptions.map((rpo) => rpo.id),
        street1: `New Project From POC "${name}"`,
        cohortId: input.cohorts.first?.id,
        lotType: LotType.Bool,
        startDate: new Date(),
        buildType: "New Build",
      }),
    },
  });

  const onSave = useCallback(async () => {
    const input = mapFormToInput(formState);
    await createProject({ variables: { input } });
  }, [formState, createProject]);

  const body = queryResult(query, (data) => {
    return (
      <FormLines>
        <BoundTextField field={formState.street1} label="Project Name" />
        <BoundDateField field={formState.startDate} label="Start Date" />
      </FormLines>
    );
  });
  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>Create Project from Offering Configuration</ModalHeader>
          <ModalBody>{body}</ModalBody>
          <ModalFooter>
            <Button variant="secondary" size="md" label="Cancel" onClick={closeModal} />
            <Button variant="primary" size="md" label="Create" disabled={!formState.valid} onClick={onSave} />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type FormConfig = Omit<
  CreateProjectFromReadyPlanInput,
  "buildAddress" | "cohortId" | "lotType" | "siteSpecificCosts" | "lotPartitionId" | "buildType" | "startDate"
> & {
  street1: InputMaybe<string>;
  startDate: InputMaybe<Date>;
};

const createProjectFormConfig: ObjectConfig<FormConfig> = {
  rpId: { type: "value", rules: [required] },
  rpoIds: { type: "value", rules: [required] },
  street1: { type: "value", rules: [required] },
  startDate: { type: "value", rules: [required] },
};

function mapFormToInput(form: ObjectState<FormConfig>): CreateProjectFromReadyPlanInput {
  if (!form.valid) fail("Form is not valid");
  return {
    buildAddress: {
      street1: form.street1.value,
      city: "Test City",
      state: "TX",
      postalCode: "12345",
      country: "US",
    },
    startDate: new DateOnly(form.startDate.value!),
    siteSpecificCosts: [],
    buildType: "New Build",
    rpId: form.rpId.value,
    rpoIds: form.rpoIds.value,
    enableProductConfigPlan: true,
    isTest: true,
  };
}
