import { Css, Properties } from "@homebound/beam";
import { isAfter } from "date-fns";
import {
  GanttSchedulePhaseFragment,
  GanttScheduleSubPhaseFragment,
  GanttScheduleTaskFragment,
  TaskStatus,
} from "src/generated/graphql-types";

const globalMilestoneTaskClass = "global-milestone-task";
const potentialDelayedTaskClass = "potential-delay-task";
const confirmedDelayedTaskClass = "confirmed-delay-task";
const completedTaskClass = "completed-task";
const completedSubPhaseClass = "completed-sub-phase";
const completedPhaseClass = "completed-phase";
const completedTasksPhasesSubPhases = Css.bgGreen300.important.$;
type TaskClasses =
  | typeof globalMilestoneTaskClass
  | typeof potentialDelayedTaskClass
  | typeof confirmedDelayedTaskClass
  | typeof completedTaskClass
  | typeof completedPhaseClass
  | typeof completedSubPhaseClass;

/**
 * This object's sole purpose is to add human readable keys to styles being applied on top of the default Bryntum CSS and avoid
 * long chains of `Css.addIn(".long-class-selector-strings", ..styles)...`, letting that get auto generated at compile time via `ganttStyleOverrides` below.
 * These overrides could certainly be ported over to our `@homebound/schedules-v2-gantt` library instead, though the ergonomics of tweaking styles there is
 * more challenging, especially since some styles are dynamically applied based on task data
 */
const ganttStyleMap: Record<string, { selectors: string; styles: Properties }> = {
  phaseGroupingBar: { selectors: ".b-gantt-task-parent .b-gantt-task", styles: Css.bgBlue600.$ },
  standardTask: { selectors: ".b-gantt-task", styles: Css.bgGray400.$ },
  standardZeroDayTask: {
    selectors: ".b-gantt-task-wrap.b-milestone-wrap .b-gantt-task .b-gantt-task-content",
    styles: Css.bgGray400.$,
  },
  globalMilestoneTask: {
    selectors: generateTaskBarSelectors(globalMilestoneTaskClass),
    styles: Css.bgPurple400.important.$,
  },
  potentialDelayedTask: {
    selectors: generateTaskBarSelectors(potentialDelayedTaskClass),
    styles: Css.bgYellow400.important.$,
  },
  confirmedDelayedTask: {
    selectors: generateTaskBarSelectors(confirmedDelayedTaskClass),
    styles: Css.bgRed400.important.$,
  },
  completedPhase: {
    selectors: generateTaskBarSelectors(completedPhaseClass),
    styles: completedTasksPhasesSubPhases,
  },
  completedSubPhase: {
    selectors: generateTaskBarSelectors(completedSubPhaseClass),
    styles: completedTasksPhasesSubPhases,
  },
  completedTask: {
    selectors: generateTaskBarSelectors(completedTaskClass),
    styles: completedTasksPhasesSubPhases,
  },
  gridHeader: {
    selectors: ".b-grid-header-container .b-sch-timeaxiscolumn",
    styles: Css.bgWhite.$,
  },
  gridHeaderHover: {
    selectors: ".b-grid-header-container:hover .b-sch-timeaxiscolumn.b-depth-0:hover",
    styles: Css.bgBlue100.$,
  },
  columnHeader: {
    selectors: ".b-grid-header-container",
    styles: Css.bgWhite.$,
  },
  selectedRow: {
    selectors: ".b-grid-row.b-selected:not(.b-group-row)",
    styles: Css.bgBlue50.$,
  },
  rowHover: {
    selectors: ".b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row.b-hover",
    styles: Css.bgBlue100.$,
  },
  rowHoverAndSelected: {
    selectors:
      ".b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row.b-hover.b-selected",
    styles: Css.bgBlue100.$,
  },
  phaseAndSubPhaseNames: {
    selectors: ".b-tree-parent-cell > .b-tree-cell-inner",
    styles: Css.smBd.gray900.$,
  },
  taskNames: {
    selectors: ".b-tree-leaf-cell > .b-tree-cell-inner",
    styles: Css.xs.gray900.$,
  },
  todayMarkerContainer: {
    selectors: ".b-grid-header .b-sch-timerange.b-sch-current-time",
    styles: Css.bgBlue700.ttc.br4.px2.left("-36px").$,
  },
  todayMarkerLabel: {
    selectors: ".b-grid-header .b-sch-timerange label",
    styles: Css.smMd.$,
  },
  todayMarkerVerticalLine: {
    selectors: ".b-timeline-subgrid .b-sch-current-time",
    styles: Css.bcBlue700.$,
  },
  zeroDayPhase: {
    selectors: ".b-gantt-task-wrap.b-milestone-wrap .b-gantt-task.b-sch-event-fixed .b-gantt-task-content",
    styles: Css.bgBlue700.$,
  },
  zeroDayPhaseProgress: {
    selectors: ".b-gantt-task-parent.b-milestone-wrap .b-gantt-task.b-sch-event-fixed",
    styles: Css.bgTransparent.$,
  },
  emptySubphase: {
    selectors: `.b-grid-row.${completedSubPhaseClass} .b-tree-leaf-cell>.b-tree-cell-inner`,
    styles: Css.smBd.$,
  },
  /*
 In future, if we want to set a custom color for hover, this code can be used. For now, we are utlilizing bryntum hover.
  globalMilestoneTaskHover: {
    selectors: ".b-gantt-task-wrap:not(.b-milestone-wrap) .global-milestone-task:hover",
    styles: Css.bgPurple400.important.$,
  },
  completedPhaseHover: {
    selectors: generateTaskBarSelectors(completedPhaseClass),
    styles: Css.bgGreen300.important.$,
  },
    completedSubPhaseHover: {
    selectors: generateTaskBarSelectors(completedSubPhaseClass),
    styles: Css.bgGreen300.important.$,
  },
   completedTaskHover: {
    selectors: generateTaskBarSelectors(completedTaskClass),
    styles: Css.bgGreen300.important.$,
  },
  */
};

function generateTaskBarSelectors(taskClass: TaskClasses) {
  return `.b-gantt-task.${taskClass} > .b-gantt-task-content, .b-gantt-task.${taskClass}:not(.b-milestone)`;
}

export const ganttStyleOverrides = Object.values(ganttStyleMap).reduce(
  (acc, { selectors, styles }) => acc.addIn(selectors, styles),
  Css,
);

export function getStyleClassesForGanttTask(scheduleTask: GanttScheduleTaskFragment): TaskClasses | undefined {
  const {
    globalTask,
    baselineInterval2,
    interval: { endDate },
    status,
  } = scheduleTask;
  const baselineEndDate = baselineInterval2?.endDate;

  if (globalTask?.isMilestone) return globalMilestoneTaskClass;
  if (status === TaskStatus.Complete) return completedTaskClass;
  // Highlight tasks that are either expected to be delayed vs baseline, or are already delayed vs baseline by comparing the baseline to today's date
  if (baselineEndDate && isAfter(endDate, baselineEndDate)) {
    const baselineHasPast = isAfter(new Date(), baselineEndDate);
    return baselineHasPast ? confirmedDelayedTaskClass : potentialDelayedTaskClass;
  }

  return undefined;
}

export function getStyleClassesForGanttPhase(schedulePhase: GanttSchedulePhaseFragment): TaskClasses | undefined {
  const {
    progress: {
      scheduleSubPhaseProgress: { completed, total },
    },
  } = schedulePhase;
  if (completed === total) return completedPhaseClass;
  return undefined;
}

export function getStyleClassesForGanttSubPhase(
  scheduleSubPhase: GanttScheduleSubPhaseFragment,
): TaskClasses | undefined {
  const {
    progress: {
      taskProgress: { completed, total },
    },
  } = scheduleSubPhase;
  if (completed === total) return completedSubPhaseClass;
  return undefined;
}
