import {
  Css,
  getTableStyles,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridStyle,
  GridTable,
  GridTableApi,
  PresentationProvider,
  simpleHeader,
} from "@homebound/beam";
import { addWeeks } from "date-fns";
import equal from "fast-deep-equal";
import React, { MutableRefObject, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  ScheduleDetailsFragment,
  SchedulePhaseDetailsFragment,
  ScheduleTaskFilter,
  TaskScheduleDetailsFragment,
  TaskStatus,
  useProjectScheduleTableTasksQuery,
  useSchedulePhasesQuery,
} from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/Routes";
import { setTaskNumberMap } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";
import {
  customTaskFilterDefault,
  definedFilterValues,
  useQueryStorage,
} from "src/routes/projects/schedule-v2/table/filterUtils";
import { PhaseRow, ScheduleRow, SubPhaseRow, TaskRow } from "src/routes/projects/schedule-v2/table/utils";
import { assertNever, isDefined, keyBy, nonEmpty } from "src/utils";
import { postProcessChildren, preProcessQueryResults, taskNumberMap } from "src/utils/schedules";
import { simpleSearch } from "src/utils/simpleSearch";
import { useScheduleStore } from "./contexts/ScheduleStore";
import { ScheduleType } from "./table/ScheduleType";
import { sortTablePhases, sortTableTasks, TableOrder } from "./table/sortUtils";
import { ScheduleListGroupBy } from "./table/utils";

type ProjectScheduleTableV2Props = {
  schedule: ScheduleDetailsFragment;
  taskFilter: ScheduleTaskFilter;
  availableTableWidthRef: MutableRefObject<HTMLDivElement | null>;
  searchTerm?: string | undefined | null;
  listGroupByValue: ScheduleListGroupBy;
  taskPaneId: string | undefined;
  rowLookup: MutableRefObject<GridRowLookup<ScheduleRow> | undefined>;
  gridTableApi: GridTableApi<ScheduleRow> | undefined;
  columns: GridColumn<ScheduleRow>[];
};

export const ProjectScheduleTableV2 = React.memo(function ProjectScheduleTableV2({
  schedule,
  taskFilter,
  availableTableWidthRef,
  searchTerm,
  listGroupByValue,
  taskPaneId,
  rowLookup,
  gridTableApi,
  columns,
}: ProjectScheduleTableV2Props) {
  const { id: scheduleId, stage } = schedule;
  const { dispatch, scheduleState } = useScheduleStore();

  // Expand on the GridStyle to add a min-width, a custom `emptyCell` value, and a custom `rootCss` value.
  const style: GridStyle = useMemo(() => {
    const styles = getTableStyles({ allWhite: true, bordered: true });
    return { ...styles, rootCss: { ...styles.rootCss, ...Css.pb4.$ }, minWidthPx: 1171, emptyCell: "" };
  }, []);

  const { projectId, developmentId, scheduleTemplateId } = useParams<
    ProjectRouteProps & { developmentId: string; scheduleTemplateId: string }
  >();
  const defaultTaskFilter: ScheduleTaskFilter = {
    stage: [stage],
    scheduleParent: [projectId || developmentId || scheduleTemplateId],
  };

  // We will set groupBy to undefined as it is grouped in the frontend
  const filter = { ...defaultTaskFilter, ...definedFilterValues(taskFilter), groupBy: undefined };
  const { data: taskData } = useProjectScheduleTableTasksQuery({
    variables: { filter },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const { data: schedulePhaseData } = useSchedulePhasesQuery({
    variables: { scheduleId },
    fetchPolicy: "cache-and-network",
  });
  const { scheduleTasks: tasks = [] } = taskData ?? {};
  const { schedulePhases = [] } = schedulePhaseData ?? {};
  const { queryStorage } = useQueryStorage({
    storageKey: "scheduleFilter",
    initialQueryStorage: customTaskFilterDefault,
  });
  const { requiredDocuments = false } = queryStorage;
  // Client side `simpleSearch` used vs. `GridTable.filter` to allow for finer grain control over showing parent schedule level
  const searchedTasks = useMemo(() => simpleSearch(tasks, searchTerm, (task) => task.name), [tasks, searchTerm]);
  const filteredTasks = searchTerm ? searchedTasks : tasks;
  const filteredRTDTasks = requiredDocuments
    ? filteredTasks.filter((task) => nonEmpty(task.requiredTaskDocuments))
    : filteredTasks;
  // checking values of our taskFilter to see if they are defined
  const isFilterDefined = Object.values(taskFilter).some(isDefined);
  const isFilterActive = !isFilterDefined ? false : Object.keys(taskFilter).nonEmpty || !!searchTerm;

  // Templates have specialized columns so use a different storage key so switching between project and template schedules
  // doesn't clobber the user's column visibility settings.
  const isTemplate = scheduleState.scheduleType === ScheduleType.Template;
  const visibleColumnsStorageKey = `scheduleVisibleColumns${isTemplate ? "Template" : ""}`;

  // isClientFilter - client side search or required task document search on GridTable.filter
  const isClientFilter = isFilterActive || requiredDocuments;
  const [sortOrder, setSortOrder] = useState<TableOrder>([undefined, undefined]);
  const sortedTasks = sortTableTasks(sortOrder, filteredRTDTasks);
  const sortedPhases = sortTablePhases(sortOrder, schedulePhases);

  // conditional rendering based on the view the user has selected. default view is SchedulePhaseViewScheduleRows
  const rows = useMemo(() => {
    switch (listGroupByValue) {
      case ScheduleListGroupBy.phase:
        return createSchedulePhaseViewScheduleRows(sortedPhases, sortedTasks, isClientFilter);

      case ScheduleListGroupBy.upcoming:
        return createUpcomingViewScheduleRows(sortedTasks);

      case ScheduleListGroupBy.none:
        return createFlatListViewScheduleRows(sortedTasks);

      default:
        assertNever(listGroupByValue);
    }
  }, [listGroupByValue, sortedPhases, sortedTasks, isClientFilter]);

  useEffect(() => {
    const newTaskNumberMap = taskNumberMap(rows);
    // Prevent expensive table re-render if the resulting number map is the same
    if (!equal(newTaskNumberMap, scheduleState.taskNumberMap)) {
      dispatch(setTaskNumberMap(newTaskNumberMap));
    }
  }, [rows, dispatch, scheduleState.taskNumberMap]);

  const fieldProps = useMemo(
    () => ({ borderless: true, typeScale: "xs" as const, visuallyDisabled: false as const }),
    [],
  );

  return (
    <PresentationProvider fieldProps={fieldProps}>
      <GridTable
        rows={rows}
        columns={columns}
        style={style}
        stickyHeader={true}
        as={"virtual"}
        api={gridTableApi}
        sorting={{
          // on "server" (but actually client sort) workaround used to solve for SchedulesV2
          // special use cases including recalculating the taskNumberMap on sort and unique
          // requirements for maintaining phase/subphase position
          on: "server",
          onSort: (orderBy, direction) => setSortOrder([orderBy, direction] as TableOrder),
        }}
        activeRowId={`task_${taskPaneId}`}
        resizeTarget={availableTableWidthRef}
        rowLookup={rowLookup}
        visibleColumnsStorageKey={visibleColumnsStorageKey}
      />
    </PresentationProvider>
  );
});

export type SubPhaseData = SubPhaseRow & {
  children: GridDataRow<ScheduleRow>[];
};

export type PhaseData = PhaseRow & {
  children: GridDataRow<ScheduleRow>[];
  subPhasesById: {
    [key: string]: SubPhaseData;
  };
};

type SchedulePhaseMap = Record<string, PhaseData>;

function createSchedulePhaseViewScheduleRows(
  schedulePhases: SchedulePhaseDetailsFragment[] | undefined,
  tasks: TaskScheduleDetailsFragment[],
  isClientFilter: boolean,
): GridDataRow<ScheduleRow>[] {
  // Get all schedule phases and schedule sub phases in the correct order
  const schedulePhasesById = preProcessQueryResults(schedulePhases, tasks);

  // if a filter has been applied, then filter out empty schedulePhases and subPhases
  const schedulePhase = maybeFilterEmptySchedulePhasesAndSubPhases(schedulePhasesById, isClientFilter).map(
    (schedulePhaseRow) =>
      // sets the children of the schedulePhaseRow & scheduleSubPhasePhases
      postProcessChildren(schedulePhaseRow),
  );
  return [simpleHeader, ...schedulePhase];
}

function createUpcomingViewScheduleRows(tasks: TaskScheduleDetailsFragment[]): GridDataRow<ScheduleRow>[] {
  const today = new Date();
  const twoWeeksFromToday = addWeeks(today, 2);
  const inFlightTasks = tasks
    .filter((task) => task.status !== TaskStatus.Complete && task.interval.startDate <= today)
    .map((t) => ({ kind: "task", id: t.id, data: t })) as TaskRow[];

  const startingNextTasks = tasks
    .filter(
      (task) =>
        task.status !== TaskStatus.Complete &&
        task.interval.startDate > today &&
        task.interval.startDate <= twoWeeksFromToday,
    )
    .map((t) => ({ kind: "task", id: t.id, data: t })) as TaskRow[];

  const startingLaterTasks = tasks
    .filter((task) => task.status !== TaskStatus.Complete && task.interval.startDate > twoWeeksFromToday)
    .map((t) => ({ kind: "task", id: t.id, data: t })) as TaskRow[];

  const completedTasks = tasks
    .filter((task) => task.status === TaskStatus.Complete)
    .map((t) => ({ kind: "task", id: t.id, data: t })) as TaskRow[];

  const upcomingSchedulePhases: GridDataRow<ScheduleRow>[] = [
    { name: "Happening Now", children: inFlightTasks },
    { name: "2 Week Lookahead", children: startingNextTasks },
    { name: "More Than 2 Weeks Ahead", children: startingLaterTasks },
    { name: "Completed", children: completedTasks },
  ].map((ums) => ({
    ...ums,
    id: ums.name,
    kind: "phase",
    data: {
      phaseId: ums.name,
      name: ums.name,
      interval: null,
    },
  }));

  // filter out empty schedule phases
  const filterEmptyUpcomingSchedulePhases = upcomingSchedulePhases.filter(
    (schedulePhaseRow) => schedulePhaseRow.children && schedulePhaseRow.children.length > 0,
  );

  return [simpleHeader, ...filterEmptyUpcomingSchedulePhases];
}

function createFlatListViewScheduleRows(tasks: TaskScheduleDetailsFragment[]): GridDataRow<ScheduleRow>[] {
  const taskRows = tasks.map((t) => ({ kind: "task", id: t.id, data: t })) as TaskRow[];

  return [simpleHeader, ...taskRows];
}

export function maybeFilterEmptySchedulePhasesAndSubPhases(
  schedulePhases: SchedulePhaseMap,
  isClientFilter: boolean,
): PhaseData[] {
  if (!isClientFilter) {
    return Object.values(schedulePhases);
  }

  return Object.values(schedulePhases).reduce((acc, schedulePhaseRow) => {
    const filteredSubPhasesWithChildren = Object.values(schedulePhaseRow.subPhasesById).filter(
      (sp) => sp.children.length > 0,
    );

    const schedulePhaseTasks = schedulePhaseRow.children;

    // if the schedulePhase's children (tasks) or filteredSubPhases has a length, then we want to push the schedulePhases and subPhases back into the phaseRow
    if (schedulePhaseTasks.length > 0 || filteredSubPhasesWithChildren.length > 0) {
      acc.push({
        ...schedulePhaseRow,
        subPhasesById: keyBy(
          filteredSubPhasesWithChildren,
          (sp) => sp.id,
          (sp) => sp,
        ),
      });
    }
    return acc;
  }, [] as PhaseData[]);
}
