import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  FieldGroup,
  FormLines,
  StaticField,
  SuperDrawerHeader,
  useComputed,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { ItemsBoundSelectField } from "src/components/autoPopulateSelects/ItemsBoundSelectField";
import { LocationsBoundSelectField } from "src/components/autoPopulateSelects/LocationsBoundSelectField";

import {
  ChangeEventLineItemType,
  ChangeEventStatus,
  ChangeEventType,
  CostType,
  ExpensesTable_ExpenseFragment,
  ExpensesTable_PotentialProjectItemsFragment,
  Maybe,
  SaveChangeEventInput,
  SaveChangeEventLineItemInput,
  useChangeEventFormContentQuery,
  useSaveChangeEventExpenseLineItemMutation,
} from "src/generated/graphql-types";
import { setupPriceFields } from "src/routes/projects/selections/recalc";
import { changeEventTypeToNameMapper, fail, queryResult, safeEntries } from "src/utils";
import { filterArchivedUnlessSelected } from "src/utils/changeEventReasons";

type ChangeEventFormProps = {
  expense: ExpensesTable_ExpenseFragment;
  closeDrawer: () => void;
  onCreateLineItem: (projectItem: ExpensesTable_PotentialProjectItemsFragment) => void;
};

export function ChangeEventForm({ expense, closeDrawer, onCreateLineItem }: ChangeEventFormProps) {
  const formState = useFormState({
    config: formConfigChangeEvent,
    init: {
      onlyOnce: true,
      input: { homeownerMarkup: -100, homeownerPrice: 0, type: ChangeEventType.External },
    },
    addRules(state) {
      // Recalc cost/markup/price
      setupPriceFields(
        {
          totalCostInCents: state.totalCostInCents,
          totalPriceInCents: state.totalPriceInCents,
          markupPercentage: state.markup,
        },
        { canEditPrice: true },
      );
    },
  });

  const [saveChangeEvent] = useSaveChangeEventExpenseLineItemMutation();

  const query = useChangeEventFormContentQuery({
    variables: {
      projectId: expense.project.id,
      costCodeId: expense.costCode!.id,
      stage: expense.stage.code,
    },
  });

  const ccId: string[] = [expense.costCode!.id];

  const disableButton = useComputed(() => {
    return !formState.valid || !formState.dirty;
  }, [formState]);

  return queryResult(query, {
    data: ({ changeEventReasons: cers, items, project, unitsOfMeasure }) => {
      const reasons = filterArchivedUnlessSelected(cers);
      const expenseProjectStage = project.stage || fail("Stage not found");
      const reason = reasons.find((r) => r.code === "DIRECT_EXPENSE") || fail("Reason not found");
      const unitOfMeasure = unitsOfMeasure.find((r) => !r.useQuantity) || fail("Lump Sum not found");
      const { clientNoun } = project.lotType;

      return (
        <div css={Css.p3.$}>
          <SuperDrawerHeader hideControls title="New Line Item" />
          <p css={Css.pb3.pt1.sm.gray700.$} data-testid="formDescription">
            Enter a line item to allocate cost to. This will create a change event.
          </p>
          <FormLines width="full">
            <ItemsBoundSelectField label="Line Item" filter={{ costCode: ccId }} field={formState.item} />
            <FieldGroup widths={[3, 2]}>
              <BoundSelectField
                label="Cost Type"
                field={formState.costType}
                options={safeEntries(CostType)}
                getOptionLabel={([_, type]) => type}
                getOptionValue={([_, type]) => type}
              />
              <LocationsBoundSelectField placeholder="" field={formState.location} />
            </FieldGroup>

            <div css={Css.mb7.$}>
              <FieldGroup widths={[2, 2]}>
                <BoundNumberField type="cents" label="Proposed Total Cost" field={formState.totalCostInCents} />
                <Observer>
                  {() => {
                    return formState.type.value !== ChangeEventType.Internal ? (
                      <BoundNumberField
                        type="percent"
                        label="Markup"
                        field={formState.markup}
                        onChange={(value) => {
                          formState.set({
                            markup: value,
                          });
                        }}
                      />
                    ) : (
                      <StaticField label="Markup">
                        <p css={Css.mt1.gray800.$}>N/A</p>
                      </StaticField>
                    );
                  }}
                </Observer>
              </FieldGroup>
            </div>

            <FieldGroup widths={[2, 2]}>
              <Observer>
                {() => (
                  <BoundNumberField
                    type="cents"
                    label="Price"
                    field={
                      formState.type.value !== ChangeEventType.Internal
                        ? formState.totalPriceInCents
                        : formState.homeownerPrice
                    }
                    onChange={(value) => {
                      formState.set({
                        totalPriceInCents: value,
                      });
                    }}
                    disabled={
                      formState.type.value === ChangeEventType.Internal && (
                        <div>Price can't be edited for Internal Change Events</div>
                      )
                    }
                  />
                )}
              </Observer>

              <BoundSelectField
                label="Type"
                labelStyle="above"
                field={formState.type}
                options={Object.entries(ChangeEventType).filter(
                  ([_, type]) => type !== ChangeEventType.BudgetReallocation,
                )}
                getOptionValue={([_, type]) => type}
                getOptionLabel={([_, type]) => `${clientNoun} ${changeEventTypeToNameMapper(type)}`}
              />
            </FieldGroup>
            <div css={Css.pt4.df.jcfe.$}>
              <Observer>
                {() => (
                  <Button
                    label="Save Item"
                    disabled={disableButton}
                    onClick={async () => {
                      if (formState.canSave()) {
                        const type = formState.value.type;
                        const item = items.find((item) => item.id === formState.value.item);
                        const title = `Expense ${expense.id} Change Event for ${item?.fullCode}`;

                        const input: SaveChangeEventInput = {
                          createdById: expense.id,
                          title,
                          type,
                          status: ChangeEventStatus.Draft,
                          projectStageId: expenseProjectStage.id,
                          reasonId: reason.id,
                          lineItems: [
                            {
                              costType: formState.value.costType,
                              totalCostInCents: formState.value.totalCostInCents,
                              totalPriceInCents:
                                type !== ChangeEventType.Internal ? formState.value.totalPriceInCents : undefined,
                              locationId: formState.value.location,
                              itemId: formState.value.item,
                              unitOfMeasureId: unitOfMeasure.id,
                              type: ChangeEventLineItemType.Add,
                            },
                          ],
                        };

                        const { data } = await saveChangeEvent({ variables: { input } });

                        const { projectItem } = data?.saveChangeEvent.changeEvent.lineItems.first!;
                        onCreateLineItem(projectItem);

                        closeDrawer();
                      }
                    }}
                  />
                )}
              </Observer>
            </div>
          </FormLines>
        </div>
      );
    },
  });
}

type ChangeEventFormInput = {
  item?: SaveChangeEventLineItemInput["itemId"];
  costType?: SaveChangeEventLineItemInput["costType"];
  location?: SaveChangeEventLineItemInput["locationId"];
  totalCostInCents?: SaveChangeEventLineItemInput["totalCostInCents"];
  markup?: Maybe<number>;
  totalPriceInCents?: SaveChangeEventLineItemInput["totalPriceInCents"];
  type?: SaveChangeEventInput["type"];
  homeownerMarkup?: Maybe<number>;
  homeownerPrice?: Maybe<number>;
};

const formConfigChangeEvent: ObjectConfig<ChangeEventFormInput> = {
  item: { type: "value", rules: [required] },
  costType: { type: "value", rules: [required] },
  location: { type: "value", rules: [required] },
  totalCostInCents: { type: "value", rules: [required] },
  markup: { type: "value" },
  totalPriceInCents: { type: "value" },
  type: { type: "value", rules: [required] },
  homeownerMarkup: { type: "value" },
  homeownerPrice: { type: "value" },
};
