export const zIndexes = {
  // Ensure MUI label stays on top of input
  formLabels: 1,

  okCancelButtons: 10,

  // Put sticky headers over the okCancelButtons
  frozenHeader: 11,

  modal: 20,
};
