import { Avatar, Css, useTestIds } from "@homebound/beam";
import { CommentAttachmentsRow } from "src/components/comments/CommentAttachmentsRow";
import { CommentFragment } from "src/generated/graphql-types";
import { formatWithDateAndTime } from "src/utils/dates";
import { sanitizeHtml } from "src/utils/sanitizeHtml";

export type CommentProps2 = {
  comment: CommentFragment;
};

export function Comment2(props: CommentProps2) {
  const { id, author, createdAt, html, attachments } = props.comment;
  const testIds = useTestIds(props);
  // TODO: designs still missing for editing -- do we want to edit at all?
  return (
    <div css={Css.df.$} {...testIds[id]}>
      <div css={Css.mr2.$}>
        <Avatar name={author.name} src={author.avatarUrl} />
      </div>
      <div css={Css.xs.gray700.$} {...testIds.comment}>
        <div css={Css.mb1.xsMd.$}>{author.name}</div>
        <div css={Css.mb1.wbbw.$} dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}></div>
        <div css={Css.tiny.$}>{formatWithDateAndTime(createdAt)}</div>
        {attachments.nonEmpty && <CommentAttachmentsRow comment={props.comment} />}
      </div>
    </div>
  );
}
