import { Css } from "@homebound/beam";
import { PandaDocRole } from "src/generated/graphql-types";

const extraWidthDateField = 10;

/** Temporary until we code gen enum details into graphql-types.ts */
const PandaDocRoleToShortNameMap: Record<PandaDocRole, string> = {
  [PandaDocRole.User]: "user",
  [PandaDocRole.TradePartner]: "tp",
  [PandaDocRole.HeadOfConstruction]: "hoc",
  [PandaDocRole.ProjectManager]: "pm",
  [PandaDocRole.HomeboundAuthorizedSignatory]: "has",
  [PandaDocRole.AreaManager]: "am",
  [PandaDocRole.LeadEstimator]: "le",
  [PandaDocRole.Homeowner]: "ho",
  [PandaDocRole.Signatory]: "sig",
} satisfies Record<PandaDocRole, string>;

// Documentation: https://support.pandadoc.com/hc/en-us/articles/360051490673--Editor-2-0-Field-tags-recognition
type PandaDocFieldType = "textfield" | "checkbox" | "signature" | "date" | "initials" | "text" | "radio";

type PandaDocProps = { role: PandaDocRole; prefix?: string; extraWidth?: number };

/** Signature boxes are pretty tall, and not something that we can control. */
export function Signature(props: PandaDocProps) {
  return <PandaDocField {...props} type="signature" />;
}

export function TextField(props: PandaDocProps) {
  return <PandaDocField {...props} type="textfield" />;
}

export function Initials(props: PandaDocProps) {
  return <PandaDocField {...props} type="initials" />;
}

export function Radio(props: PandaDocProps & { options: string[] }) {
  return <PandaDocRadioButton {...props} />;
}

export function SignatureDate(props: PandaDocProps) {
  // The date field is a bit special, it requires an underscore to widen it.
  const propsWithUnderscore = { ...props, extraWidth: extraWidthDateField };
  return <PandaDocField {...propsWithUnderscore} type="date" />;
}

function PandaDocRadioButton({ role, prefix, options }: PandaDocProps & { options: string[] }) {
  return (
    <div css={Css.hPx(85).df.fdr.aic.$}>
      {prefix}
      <span>
        {options.map((option) => (
          <div
            css={Css.pbPx(10).plPx(25).$}
            key={option}
          >{`{radio:${PandaDocRoleToShortNameMap[role]}:radiogroup:${option}}`}</div>
        ))}
      </span>
    </div>
  );
}

function PandaDocField({ role, type, prefix, extraWidth }: { type: PandaDocFieldType } & PandaDocProps) {
  return (
    <div css={Css.hPx(85).df.fdr.aic.$}>
      {prefix}
      <span css={Css.plPx(25).$}>{`{${type}:${PandaDocRoleToShortNameMap[role]}${new Array(extraWidth).join(
        "_",
      )}}`}</span>
    </div>
  );
}
