/**
 * Returns a filtered list given a string to search on
 * @param data Array to be filtered
 * @param searchTerm Text entered into search bar
 * @param toSearchString Function to pluck a value or values from data[] to be searched on
 */
export function simpleSearch<T>(data: T[], searchTerm: string | undefined | null, toSearchString: (el: T) => string) {
  if (!searchTerm) return data;

  // Split the search string into tokens: `'Whirl   30'` becomes `['whirl', '30']`;
  const searchTokens = searchTerm?.toLowerCase().split(/\s+/) || [];

  return data.filter((el) => {
    const searchString = toSearchString(el).toLowerCase();
    // Ensure that the `searchString` contains all the desired `searchTokens`
    return searchTokens.every((token) => searchString.includes(token));
  });
}
