import { Css } from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { UppyUploader } from "src/components";
import { DocumentEditorDetailFragment, SaveDocumentInput, useSaveDocumentsMutation } from "src/generated/graphql-types";
import { DocumentVersionTable } from "./DocumentVersionTable";

export type DocumentVersionManagerProps = {
  document: DocumentEditorDetailFragment;
};

export function DocumentVersionManager(props: DocumentVersionManagerProps) {
  const { document } = props;
  const labelCss = Css.db.sm.gray700.mb1.$;
  const [saveDocuments] = useSaveDocumentsMutation();
  const onSave = async (file: UppyFile) => {
    const saveDocumentsInput: [SaveDocumentInput] = [
      {
        id: document.id,
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
        },
      },
    ];
    await saveDocuments({ variables: { input: saveDocumentsInput } });
  };

  return (
    <div data-testid="documentVersionManager" css={Css.bgGray100.br8.p2.$}>
      <div data-testid="documentVersionManager_fileUploader" css={Css.mb2.$}>
        <label css={labelCss}>Add New Version</label>
        <UppyUploader dragDropText="Upload" dragDropWidth={120} maxNumberOfFiles={1} onFinish={onSave} />
      </div>
      <label css={labelCss}>Version History</label>
      <DocumentVersionTable document={document} />
    </div>
  );
}
