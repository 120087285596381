import { Css } from "@homebound/beam";
import { ScheduleTask } from "src/generated/graphql-types";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { ScheduleViewType } from "../scheduleUtils";

type TaskDependencyOptionProps = {
  task: Pick<ScheduleTask, "id" | "name">;
  taskNumber: number | string;
};
export function TaskDependencyOption({ task, taskNumber }: TaskDependencyOptionProps) {
  const {
    scheduleState: { scheduleViewType },
  } = useScheduleStore();
  return (
    <div css={Css.df.jcsb.aifs.$}>
      {scheduleViewType === ScheduleViewType.List && (
        <div data-testid={`${task.id}-task-number-select`} css={Css.mwPx(24).maxwPx(24).mr1.gray700.$}>
          {String(taskNumber).padStart(2, "0")}
        </div>
      )}
      <div>{task.name}</div>
    </div>
  );
}
