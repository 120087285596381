import { BoundSelectField, Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { Observer } from "mobx-react";
import { ProjectItemInput, useSaveProjectItemMutation } from "src/generated/graphql-types";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

export type AddProjectItemModalProps = {
  projectStageId: string | undefined;
  items: { id: string; name: string }[];
};

export function AddProjectItemModal(props: AddProjectItemModalProps) {
  const { items, projectStageId } = props;
  const formState = useFormState({ config: formConfig });
  const [saveProjectItem] = useSaveProjectItemMutation();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Add Project Item</ModalHeader>
      <ModalBody>
        <BoundSelectField field={formState.itemId} options={items} />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label="Save"
              disabled={!formState.valid}
              onClick={async () => {
                if (formState.canSave()) {
                  const result = await saveProjectItem({
                    variables: { input: { projectStageId, ...formState.value } },
                  });
                  const userErrors = result.data?.saveProjectItems?.userErrors;
                  if (userErrors) {
                    console.error(userErrors);
                  }
                  closeModal();
                }
              }}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = ProjectItemInput;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  itemId: { type: "value", rules: [required] },
};
