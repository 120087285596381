import { Css, Palette } from "@homebound/beam";
import { HomeboundLogo, formatDate } from "src/components";
import {
  LotSummaryDetailsPdfQuery,
  ProjectCollaboratorRole,
  PrpcForLotSummaryDetailExportFragment,
  useLotSummaryDetailsPdfQuery,
} from "src/generated/graphql-types";
import { OptionTypes } from "src/routes/developments/lot-summary/sequence-sheet/components/utils";
import { capitalize, queryResult } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { LotSummaryDetailsSection } from "./LotSummaryDetailsSection";

export function LotSummaryDetailsPdf() {
  const [{ projectReadyPlanConfigId }] = useQueryParams({ projectReadyPlanConfigId: StringParam });
  const query = useLotSummaryDetailsPdfQuery({ variables: { id: projectReadyPlanConfigId! } });
  return queryResult(query, {
    data: (data) => <DataView metadata={data} />,
  });
}

type DataViewProps = {
  metadata: LotSummaryDetailsPdfQuery;
};

export function DataView({ metadata }: DataViewProps) {
  const lineValues = mapToFields(metadata.projectReadyPlanConfig);

  const overviewRows = [
    [
      { label: Labels.market, value: lineValues.market },
      { label: Labels.development, value: lineValues.development },
      { label: Labels.cohort, value: lineValues.cohort },
    ],
    [
      { label: Labels.lotType, value: lineValues.lotType },
      { label: Labels.address, value: lineValues.address },
    ],
    [
      { label: Labels.lot, value: lineValues.lot },
      { label: Labels.block, value: lineValues.block },
      { label: Labels.section, value: lineValues.section },
    ],
    [
      { label: Labels.plan, value: lineValues.plan },
      { label: Labels.orientation, value: lineValues.orientation },
    ],
    [
      { label: Labels.elevation, value: lineValues.elevation },
      { label: Labels.exteriorScheme, value: lineValues.exteriorScheme },
    ],
    [
      { label: Labels.foundationType, value: lineValues.foundationType },
      { label: Labels.fireSprinklersRequired, value: lineValues.fireSprinklersRequired },
    ],
    [
      { label: Labels.setBackFrontInFeet, value: lineValues.setback.front },
      { label: Labels.setBackRightInFeet, value: lineValues.setback.right },
      { label: Labels.setBackLeftInFeet, value: lineValues.setback.left },
      { label: Labels.setBackRearInFeet, value: lineValues.setback.rear },
    ],
    [
      { label: Labels.clientName, value: lineValues.clientName },
      { label: Labels.hop, value: lineValues.hoa },
    ],
  ];

  const squareFootageRows = [
    [
      { label: Labels.sellableSF, value: lineValues.sellableSF },
      // { label: Labels.aboveGroundSF, value: lineValues.aboveGroundSF },
    ],
    [
      { label: Labels.netBuildableSF, value: lineValues.netBuildableSF },
      // { label: Labels.belowGroundSF, value: lineValues.belowGroundSF },
    ],
    [{ label: Labels.permittableSF, value: lineValues.permittableSF }],
  ];

  const bedBathRows = [
    [
      { label: Labels.bedrooms, value: lineValues.bedrooms },
      { label: Labels.fullBaths, value: lineValues.fullBaths },
      { label: Labels.halfBaths, value: lineValues.halfBaths },
    ],
  ];

  const optionsRows = [
    [
      { label: Labels.interiorStyle, value: lineValues.interiorStyle },
      { label: Labels.floorPlans, value: lineValues.floorPlans },
      { label: Labels.specOption, value: lineValues.specOption },
      { label: Labels.addOns, value: lineValues.addOns },
    ],
  ];

  return (
    <div>
      {/* Header */}
      <div data-testid="header">
        <div css={Css.df.jcsb.$}>
          <p data-chromatic="ignore" data-testid="subTitle">
            Lot Summary - {formatDate(new Date(), "long")}
          </p>
          <HomeboundLogo color={Palette.Gray900} />
        </div>
        <h1 css={Css.gray700.lgBd.$} data-testid="title">
          {lineValues.address}
        </h1>
      </div>

      {/* Overview Section */}
      <LotSummaryDetailsSection title="Overview" icon="hardHat" columns={3} rows={overviewRows} />

      {/* Square Footage Section */}
      <LotSummaryDetailsSection title="Square Footage" icon="ruler" columns={4} rows={squareFootageRows} />

      {/* Bed / Bath Count Section */}
      <LotSummaryDetailsSection title="Bed / Bath Count" icon="bed" columns={6} rows={bedBathRows} />

      {/* Options Section */}
      <LotSummaryDetailsSection title="Options" icon="palette" columns={1} rows={optionsRows} />
    </div>
  );
}

function mapToFields(prpc: PrpcForLotSummaryDetailExportFragment) {
  const optionTypes = [OptionTypes.Interior, OptionTypes.FloorPlan, OptionTypes.AddOn];
  const [interior, floorPlan, addOn] = optionTypes.map((type) =>
    prpc?.options.filter((option) => option.readyPlanOption.type.name === type),
  );

  return {
    // Overview
    market: prpc?.project.market.name,
    development: prpc?.project.cohort?.development?.name,
    cohort: prpc?.project.cohort?.name,
    lotType: prpc?.project.lotType?.name,
    address: prpc?.project.buildAddress.street1,
    lot: prpc?.project.lotDetail?.lotNumber,
    block: prpc?.project.lotDetail?.block,
    section: prpc?.project.lotDetail?.section,
    plan: prpc?.readyPlan?.displayName,
    orientation: prpc?.orientationDetail.name,
    elevation: prpc?.elevationOption?.readyPlanOption.name,
    exteriorScheme: prpc?.exteriorPaletteOption?.readyPlanOption.name,
    foundationType: capitalize(prpc?.project.lotDetail?.foundationType?.code ?? " "),
    fireSprinklersRequired: prpc?.project.lotDetail?.fireSprinklersRequired ? "Yes" : "No",
    setback: {
      front: prpc?.project.lotDetail?.setBackFrontInFeet,
      right: prpc?.project.lotDetail?.setBackRightInFeet,
      left: prpc?.project.lotDetail?.setBackLeftInFeet,
      rear: prpc?.project.lotDetail?.setBackRearInFeet,
    },
    clientName: prpc?.project.collaborators
      ?.filter(({ role }) => role === ProjectCollaboratorRole.Homeowner)
      .map(({ collaborator }) => collaborator.fullName)
      .join(","),
    hoa: prpc?.project.lotDetail?.hoa ? "Yes" : "No",
    // Square Footage
    sellableSF: prpc?.programData?.sellableSqft,
    netBuildableSF: prpc?.programData?.netBuildableSqft,
    permittableSF: prpc?.programData?.permittableSqft,
    aboveGroundSF: "",
    belowGroundSF: "",
    // Bed / Bath Count
    bedrooms: prpc?.programData?.bedrooms,
    fullBaths: prpc?.programData?.fullBaths,
    halfBaths: prpc?.programData?.halfBaths,
    // Options
    interiorStyle: interior?.map((option) => option.readyPlanOption.name).join(", "),
    floorPlans: floorPlan?.map((option) => option.readyPlanOption.name).join(", "),
    specOption: prpc?.specOption?.readyPlanOption.name,
    addOns: addOn?.map((option) => option.readyPlanOption.name).join(", "),
  };
}

export enum Labels {
  market = "Market",
  development = "Development",
  cohort = "Cohort",
  lotType = "Lot Type",
  address = "Address",
  lot = "Lot",
  block = "Block",
  section = "Section",
  plan = "Plan Number / Name",
  orientation = "Plan Orientation",
  elevation = "Elevation",
  exteriorScheme = "Exterior Scheme",
  foundationType = "Foundation Type",
  fireSprinklersRequired = "Fire Sprinklers Required",
  setBackFrontInFeet = "Setback Front",
  setBackRearInFeet = "Setback Rear",
  setBackLeftInFeet = "Setback Left",
  setBackRightInFeet = "Setback Right",
  clientName = "Client Name (or QMI)",
  hop = "HOA",
  sellableSF = "Sellable SF",
  aboveGroundSF = "Above Ground SF",
  netBuildableSF = "Net Buildable SF",
  belowGroundSF = "Below Ground SF",
  permittableSF = "Permittable SF",
  bedrooms = "Bedrooms",
  fullBaths = "Full Baths",
  halfBaths = "Half Baths",
  interiorStyle = "Interior Style",
  floorPlans = "Floor Plan Options",
  specOption = "Spec Option",
  addOns = "Add-Ons",
}
