import { Button, ModalBody, ModalFooter, ModalHeader, TextField, useModal } from "@homebound/beam";
import { pascalCase } from "change-case";
import { useState } from "react";
import { ProductAttributeType, useSaveProductAttributesMutation } from "src/generated/graphql-types";

type ProductAttributeModalProps = {
  type: ProductAttributeType;
  productOptionsRefetch: () => void;
  onSelect?: (value: string) => void;
};

export function ProductAttributeModal(props: ProductAttributeModalProps) {
  const { type, productOptionsRefetch, onSelect } = props;
  const [name, setName] = useState<string | undefined>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const { closeModal } = useModal();
  const [saveProductAttributes, { loading }] = useSaveProductAttributesMutation();

  return (
    <>
      <ModalHeader>Add new {pascalCase(type)}</ModalHeader>
      <ModalBody>
        {/* Form with name input */}
        <TextField label="Name" value={name} onChange={setName} required errorMsg={error} />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          label="Save"
          onClick={() => {
            setError(undefined);
            // do mutation
            saveProductAttributes({ variables: { input: { attributes: [{ type: type, value: name }] } } })
              .then(async (resp) => {
                // refetch in the calling component so when the modal closes the new attribute is available
                await productOptionsRefetch();
                if (onSelect && resp.data?.saveProductAttributes?.attributes?.first?.id) {
                  onSelect(resp.data.saveProductAttributes.attributes.first.id);
                }
                closeModal();
              })
              .catch((err) => {
                setError(err.networkError?.result?.errors[0]?.message || "Error saving attribute");
              });
          }}
        />
      </ModalFooter>
    </>
  );
}
