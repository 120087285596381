import { Css, useSnackbar } from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { createDraftScheduleUrl } from "src/RouteUrls";
import {
  DynamicSchedulesTaskSnapshot_TaskSnapshotFragment,
  ProjectFeature,
  SavePlanTaskInput,
  TaskDetailsPageWithConstraints_PlanTaskFragment,
  TaskDetailsPage_PlanTaskFragment,
  TaskStatus,
  TaskStatusDetail,
  TradePartnerTaskStatusDetail,
  usePlanTaskDetailsPageQuery,
  useSavePlanTaskMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { SchedulingCard } from "src/routes/projects/dynamic-schedules/task-details/SchedulingCard";
import {
  TaskBillModalProvider,
  useTaskBillContext,
} from "src/routes/projects/schedule-v2/contexts/TaskBillModalContext";
import { projectsPath } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";
import { useScheduleSnapshots } from "../components/useScheduleSnapshots";
import { CalendarCard } from "./CalendarCard";
import { CommentsCard } from "./CommentsCard";
import { HistoryCard } from "./HistoryCard";
import { ReferenceCard } from "./ReferenceCard";

export function TaskDetailsPage() {
  const { planTaskId } = useParams<{ planTaskId: string; projectId: string }>();
  const query = usePlanTaskDetailsPageQuery({ variables: { taskId: planTaskId } });
  return queryResult(query, ({ planTask, enumDetails }) => {
    return (
      <TaskBillModalProvider>
        <TaskDetailPageView enumDetails={enumDetails} planTask={planTask} />{" "}
      </TaskBillModalProvider>
    );
  });
}

type TaskDetailsPageViewProps = {
  planTask: TaskDetailsPageWithConstraints_PlanTaskFragment;
  enumDetails: {
    taskStatus: TaskStatusDetail[];
    tradePartnerTaskStatus: TradePartnerTaskStatusDetail[];
  };
};

function TaskDetailPageView(props: TaskDetailsPageViewProps) {
  const { planTask, enumDetails } = props;
  const [savePlanTask] = useSavePlanTaskMutation();
  const { triggerNotice } = useSnackbar();
  const { openTaskBillModal } = useTaskBillContext();

  const enableClickToPay = planTask.schedule.parent.features?.includes(ProjectFeature.ClickToPay);

  const { getTaskSnapshot } = useScheduleSnapshots(planTask.schedule.parent.id);
  const taskSnapshot = useMemo(() => getTaskSnapshot(planTask.id), [planTask.id, getTaskSnapshot]);

  const formState = useFormState<SavePlanTaskInput, TaskDetailsPage_PlanTaskFragment>({
    config,
    init: {
      input: planTask,
      map: (task) => ({
        id: task.id,
        status: task.status.code,
        documents: task.documents.map((doc) => doc.id),
        tradePartnerId: task.committedTradePartners.first?.id ?? task.tradePartner?.id,
        tradePartnerStatus: task.tradePartnerStatus.code,
      }),
    },
  });

  const saveTaskStatus = useCallback(
    async (status: TaskStatus) => {
      await savePlanTask({ variables: { input: { id: planTask.id, status } } });

      triggerNotice({
        message: `Task status updated to ${enumDetails.taskStatus.find((ts) => ts.code === status)?.name}`,
      });
    },
    [savePlanTask, triggerNotice, planTask.id, enumDetails.taskStatus],
  );

  const onStatusSelect = useCallback(
    async (status?: TaskStatus) => {
      if (!status) return;

      if (enableClickToPay && status === TaskStatus.Complete) {
        openTaskBillModal({
          taskId: planTask.id,
          // `onComplete` is called if the modal is skipped or if the task has no unbilled items or click to pay is not enabled
          // otherwise its called after the modal is submitted
          onComplete: async () => {
            formState.status.set(status);
            await saveTaskStatus(status);
          },
        });
      } else {
        formState.status.set(status);
        await saveTaskStatus(status);
      }
    },
    [saveTaskStatus, openTaskBillModal, formState.status, planTask.id, enableClickToPay],
  );

  return (
    <Observer>
      {() => (
        <div data-testid="taskDetailsPage">
          <PageHeader
            title={planTask.name}
            right={
              <TaskStatusSelect
                dataTestId="taskStatusSelect"
                label="Status"
                hideLabel={true}
                options={enumDetails.taskStatus}
                statusField={formState.status}
                canComplete={planTask.canComplete}
                canStart={planTask.canStart}
                disabled={false}
                onSelect={onStatusSelect}
              />
            }
            breadcrumb={[
              { label: "Projects", href: projectsPath },
              { label: planTask.schedule.parent.name, href: createDraftScheduleUrl(planTask.schedule.parent.id) },
            ]}
          />
          <PlanTaskDetailsPageContent
            task={planTask}
            formState={formState}
            enumDetails={enumDetails}
            taskSnapshot={taskSnapshot}
          />
        </div>
      )}
    </Observer>
  );
}

export const config: ObjectConfig<SavePlanTaskInput> = {
  id: { type: "value" },
  status: { type: "value" },
  documents: { type: "value" },
  tradePartnerStatus: { type: "value" },
  tradePartnerId: { type: "value" },
};

type PlanTaskDetailsPageContentProps = {
  task: TaskDetailsPageWithConstraints_PlanTaskFragment;
  formState: ObjectState<SavePlanTaskInput>;
  enumDetails: TaskDetailsPageViewProps["enumDetails"];
  taskSnapshot: DynamicSchedulesTaskSnapshot_TaskSnapshotFragment | undefined;
};

function PlanTaskDetailsPageContent(props: PlanTaskDetailsPageContentProps) {
  const { task, formState, enumDetails, taskSnapshot } = props;
  return (
    <div css={Css.maxwPx(916).mwPx(706).ma.mt3.dg.gtc("1fr 1fr").gap2.$}>
      <CalendarCard task={task} taskSnapshot={taskSnapshot} />
      <SchedulingCard task={task} formState={formState} tradePartnerTaskStatus={enumDetails.tradePartnerTaskStatus} />
      <CommentsCard task={task} />
      <ReferenceCard task={task} formState={formState} />
      <HistoryCard task={task} />
    </div>
  );
}
