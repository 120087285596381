import {
  actionColumn,
  Avatar,
  Button,
  Checkbox,
  column,
  Css,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { ReactNode, useMemo } from "react";
import { dateCell, emptyCellDash, priceCell } from "src/components";
import {
  DevelopmentCommitmentV2ProjectsTabCommitmentsFragment,
  ProjectRole,
  Stage,
  useDeleteDevelopmentCommitmentProjectsAndCommitmentsMutation,
  useDevelopmentCommitmentV2ProjectsTabQuery,
} from "src/generated/graphql-types";
import { DevelopmentCommitmentProject } from "src/routes/development-commitments/models/DevelopmentCommitmentProject";
import { DevelopmentCommitmentProjectStore } from "src/routes/development-commitments/models/DevelopmentCommitmentProjectStore";
import { createCommitmentUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function ProjectsTab({ developmentCommitmentId }: { developmentCommitmentId: string }) {
  const query = useDevelopmentCommitmentV2ProjectsTabQuery({
    variables: { id: developmentCommitmentId, stage: Stage.Construction },
  });
  const projects = useMemo(
    () => query.data?.developmentCommitment.commitments.map((c) => c.project).uniqueByKey("id") || [],
    [query.data?.developmentCommitment.commitments],
  );
  const store = useMemo(() => new DevelopmentCommitmentProjectStore(projects), [projects]);
  const [deleteDevCommitmentProjectAndCommitment] = useDeleteDevelopmentCommitmentProjectsAndCommitmentsMutation();

  return queryResult(query, {
    data: (data) => {
      const dc = data.developmentCommitment;
      const pmName = dc.conOpsProjectManager?.name || "";
      const pmAvatar = dc.conOpsProjectManager?.avatar || "";
      const signatoryName = dc.homeboundAuthorizedSignatory?.name || "";
      const signatoryAvatar = dc.homeboundAuthorizedSignatory?.avatar || "";

      return (
        <div css={Css.mt4.$}>
          <div css={Css.df.jcsb.$}>
            <div css={Css.df.gap8.$}>
              <BiggerStaticField label="Market" value={dc.market.name} />
              <BiggerStaticField label="Projects" value={projects.length.toString()} />
              <BiggerStaticField label="Development Manager">
                <div css={Css.df.aic.gap1.$}>
                  <Avatar name={pmName} src={pmAvatar} size="sm" />
                  <div css={Css.baseMd.gray900.$}>{pmName}</div>
                </div>
              </BiggerStaticField>
              <BiggerStaticField label="Signatory">
                <div css={Css.df.aic.gap1.$}>
                  <Avatar name={signatoryName} src={signatoryAvatar} size="sm" />
                  <div css={Css.baseMd.gray900.$}>{signatoryName}</div>
                </div>
              </BiggerStaticField>
            </div>
            {dc.canBeEdited && (
              <div css={Css.asfe.$}>
                <Observer>
                  {() => (
                    <Button
                      disabled={store.selectedProjects.length === 0}
                      label="Remove"
                      onClick={async () => {
                        // [projectIds] - selected projects,
                        // [commitmentIds] - selected project's commitments that belong to this development commitment
                        const input = {
                          developmentCommitmentId,
                          commitmentIds: store.selectedProjects.flatMap((p) =>
                            dc.commitments.filter((c) => c.project.id === p.id).map((c) => c.id),
                          ),
                          projectIds: store.selectedProjects.map((p) => p.id),
                        };
                        await deleteDevCommitmentProjectAndCommitment({
                          variables: { input, stage: Stage.Construction },
                        });
                      }}
                      variant="tertiary"
                    />
                  )}
                </Observer>
              </div>
            )}
          </div>
          <div css={Css.mt2.$}>
            {/* remove firstCellCss styling when selectColumn is implemented */}
            <GridTable
              columns={createColumns(dc.canBeEdited)}
              fallbackMessage="No projects found."
              rows={createRows(store, dc.commitments)}
              sorting={{ on: "client" }}
              stickyHeader
              style={{ allWhite: true, bordered: true }}
            />
          </div>
        </div>
      );
    },
  });
}

type HeaderRow = {
  kind: "header";
  data: DevelopmentCommitmentProjectStore;
};

type DataRow = {
  kind: "data";
  data: {
    project: DevelopmentCommitmentProject;
    commitment?: DevelopmentCommitmentV2ProjectsTabCommitmentsFragment;
  };
};
type Rows = HeaderRow | DataRow;

function createRows(
  store: DevelopmentCommitmentProjectStore,
  commitments: DevelopmentCommitmentV2ProjectsTabCommitmentsFragment[],
): GridDataRow<Rows>[] {
  return [
    { kind: "header" as const, id: "header", data: store },
    ...(store.projects.map((project) => {
      const commitment = commitments?.find((c) => c.project.id === project.id);
      if (commitment) {
        return { kind: "data" as const, id: project.id, data: { project, commitment } };
      } else {
        return { kind: "data" as const, id: project.id, data: { project } };
      }
    }) || []),
  ];
}

function createColumns(isDcEditable: boolean): GridColumn<Rows>[] {
  const columns = [
    // TODO: use selectColumn when available and checkboxonly for checkbox
    actionColumn<Rows>({
      header: (row) => (
        <Checkbox label="Select" checkboxOnly selected={row.selected} onChange={() => row.toggleSelect()} />
      ),
      data: (row) => (
        <Checkbox
          label="Select"
          checkboxOnly
          selected={row.project.selected}
          onChange={() => row.project.toggleSelect()}
        />
      ),
      w: "48px",
    }),
    column<Rows>({
      header: "PO #",
      data: (row) => ({
        content: row.commitment && (
          <a href={createCommitmentUrl(row.project.id, row.commitment.id)} target="_blank" rel="noopener noreferrer">
            #{row.commitment.accountingNumber}
          </a>
        ),
        value: row.project.name,
      }),
      w: "80px",
    }),
    column<Rows>({
      header: "Lot Address",
      data: (row) => `${row.project.buildAddress.street1} ${row.project.buildAddress.street2 || ""}`,
    }),
    dateColumn<Rows>({
      header: "Estimated Construction Start",
      data: (row) => dateCell(row.project.stages[0]?.interval.startDate),
      w: "160px",
    }),
    dateColumn<Rows>({
      header: "Target End Date",
      data: (row) => dateCell(row.project.stages[0]?.interval.endDate),
      w: "160px",
    }),
    // TODO: maybe update this to use project lead from project
    column<Rows>({
      header: "Project Manager",
      data: (row) => {
        const projectManagerName = row.project.teamMembers.find(
          (tm) => tm.role.code === ProjectRole.ConOpsProjectManager,
        )?.user?.name;
        return {
          content: projectManagerName ?? emptyCellDash,
          value: projectManagerName ?? "-",
        };
      },
    }),
    numericColumn<Rows>({
      header: "Total Committed",
      data: (row) => priceCell({ valueInCents: row.commitment?.committedInCents, dropZero: true }),
      w: "242px",
    }),
  ];

  // remove select functionality when dev commitment is signed
  if (!isDcEditable) {
    columns.splice(0, 1);
  }

  return columns;
}
type BiggerStaticFieldProps = {
  label: string;
  value?: string;
  children?: ReactNode;
};

/** See what we want to call this in Beam. */
function BiggerStaticField({ label, value, children }: BiggerStaticFieldProps) {
  return (
    <div>
      <label css={Css.db.tiny.gray700.mbPx(2).$}>{label}</label>
      <div css={Css.baseMd.gray900.df.aic.$}>{value || children}</div>
    </div>
  );
}
