import {
  AvatarGroup,
  Button,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
  SuperDrawerWidth,
  useSuperDrawer,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { FeatureFlagDetailsFragment, FeatureFlagStatus, useFeatureFlagsQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { queryResult } from "src/utils";
import { FeatureFlagDrawer } from "./FeatureFlagDrawer";

export function FeatureFlagsPage() {
  const query = useFeatureFlagsQuery({ variables: { filter: {} } });
  return queryResult(query, ({ featureFlags }) => <FeatureFlagView featureFlags={featureFlags} />);
}

function FeatureFlagView({ featureFlags }: { featureFlags: FeatureFlagDetailsFragment[] }) {
  const { openInDrawer } = useSuperDrawer();

  const handleCreateFeatureFlag = useCallback(() => {
    openInDrawer({
      content: <FeatureFlagDrawer />,
      width: SuperDrawerWidth.Small,
    });
  }, [openInDrawer]);

  const handleEditFeatureFlag = useCallback(
    (featureFlag: FeatureFlagDetailsFragment) => {
      openInDrawer({
        content: <FeatureFlagDrawer featureFlag={featureFlag} />,
        width: SuperDrawerWidth.Small,
      });
    },
    [openInDrawer],
  );

  const rows = useMemo(() => createRows(featureFlags ?? []), [featureFlags]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <>
      <PageHeader title="Feature Flags &#x1F6A9;" />
      <TableActions onlyRight>
        <Button label="Create Feature Flag" onClick={handleCreateFeatureFlag} />
      </TableActions>
      <ScrollableContent virtualized>
        <GridTable
          rows={rows}
          columns={columns}
          rowStyles={{ data: { onClick: ({ data: ff }) => handleEditFeatureFlag(ff), rowCss: Css.mhPx(66).$ } }}
          style={{}}
          as="virtual"
          stickyHeader
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<FeatureFlagDetailsFragment>;

function createRows(featureFlags: FeatureFlagDetailsFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...featureFlags.map((ff) => ({ kind: "data" as const, id: ff.id, data: ff }))];
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => "Feature Flag",
      clientSideSort: false,
      data: ({ name }) => ({
        content: () => {
          return (
            <div css={Css.df.aic.gap1.$}>
              <span>{name}</span>
            </div>
          );
        },
      }),
      w: "350px",
    }),
    column<Row>({
      header: () => "Description",
      clientSideSort: false,
      data: ({ description }) => ({
        content: () => {
          return (
            <div css={Css.df.aic.gap1.$}>
              <span>{description}</span>
            </div>
          );
        },
      }),
      mw: "400px",
    }),
    column<Row>({
      header: () => "Enabled for",
      data: ({ targets, status }) => {
        if (status.code === FeatureFlagStatus.Released) {
          return { content: () => <span>All Users</span> };
        } else if (status.code === FeatureFlagStatus.Archived || targets.isEmpty) {
          return { content: () => <span>Nobody</span> };
        }
        const avatars = targets
          .flatMap((t) => {
            switch (t.target?.__typename) {
              case "User":
                return {
                  name: t.target.internalUser?.name,
                  src: t.target.internalUser?.avatar,
                };
              case "UserGroup":
                return t.target.users.map((u) => ({
                  name: u.internalUser?.name,
                  src: u.internalUser?.avatar,
                }));
              case "Project":
                return {
                  name: t.target.name,
                  src: "/user-events/Lot.png",
                };
              case "Development":
                return {
                  name: t.target.name,
                  src: "/user-events/LotRelease.png",
                };
              default:
                return undefined;
            }
          })
          .compact();

        return <AvatarGroup avatars={avatars} />;
      },
      w: "350px",
    }),
  ];
}
