import {
  BoundCheckboxField,
  BoundMultiSelectField,
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Css,
  FieldGroup,
  FormLines,
  ScrollableContent,
  StaticField,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { Card } from "src/components/Card";
import { FormActions, FormMode } from "src/components/formFields";
import {
  DisplayNamedFragment,
  NamedFragment,
  SaveTradePartnerInput,
  TradePartnerDetails_PaymentTermFragment,
  TradePartnerDetailsFragment,
  TradePartnerDetailsTabQuery,
} from "src/generated/graphql-types";
import { createTradePartnersUrl } from "src/RouteUrls";
import { safeEntries } from "src/utils";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { tradePartnerOnboardingStatusToNameMapper } from "src/utils/mappers";

export type TradePartnerEditorProps = {
  mode: FormMode;
  tradePartner: TradePartnerDetailsFragment | undefined;
  markets: NamedFragment[];
  internalUsers: TradePartnerDetailsTabQuery["internalUsers"][0][];
  costCodes: DisplayNamedFragment[];
  tradeCategories: NamedFragment[];
  paymentTerms: TradePartnerDetails_PaymentTermFragment[];
  onEdit: () => void;
  onSave: (formState: SaveTradePartnerInput) => Promise<unknown>;
  onDelete: () => Promise<void>;
};

export function TradePartnerEditor(props: TradePartnerEditorProps) {
  const { tradePartner, mode } = props;
  const readOnly = mode === "read";
  const formState = useFormState({
    config: formConfig,
    init: { input: tradePartner, map: mapToForm },
    readOnly,
  });
  const history = useHistory();
  return <Observer>{() => renderForm(props, formState, readOnly, history)}</Observer>;
}

function renderForm(
  props: TradePartnerEditorProps,
  formState: FormState,
  readOnly: boolean,
  history: ReturnType<typeof useHistory>,
) {
  const { mode, onEdit, onDelete, onSave, paymentTerms } = props;

  const body = (
    <div>
      <h2 css={Css.gray800.base.my2.$}>Details</h2>
      <FormLines width="md">
        <BoundTextField label="Company Name" field={formState.name} />
        <BoundSelectField
          label="Onboarding Status"
          field={formState.onboardingStatus}
          options={safeEntries(tradePartnerOnboardingStatusToNameMapper)}
          getOptionLabel={(d) => d[1]}
          getOptionValue={(d) => d[0]}
        />
        <BoundTextField label="Website" field={formState.website} />
        <BoundMultiSelectField field={formState.marketIds} options={props.markets} />
        <FieldGroup>
          <BoundCheckboxField label="Materials" field={formState.doesMaterials} />
          <BoundCheckboxField label="Labor" field={formState.doesLabor} />
        </FieldGroup>
        <BoundCheckboxField label="Lien Waiver Required" field={formState.isLienWaiverReceiver} />
        <BoundMultiSelectField field={formState.tradeCategoryIds} options={props.tradeCategories} />
        <BoundMultiSelectField
          field={formState.costCodeIds}
          options={props.costCodes.map((cc) => ({ ...cc, name: cc.displayName }))}
        />
        <BoundSelectField label="Source" field={formState.sourceId} options={props.internalUsers} />
        <BoundTextAreaField label={`Other Source ${!readOnly ? "(optional)" : ""}`} field={formState.sourceNote} />
        <BoundTextAreaField label={`Internal Note ${!readOnly ? "(optional)" : ""}`} field={formState.internalNote} />
        <BoundSelectField field={formState.paymentTermId} options={paymentTerms} />
        <BoundTextField
          label={`QBO Vendor Id ${!readOnly ? "(optional, but required for QBO sync)" : ""}`}
          field={formState.quickbooksId}
        />
        <BoundTextField
          label={`Intacct Vendor Id ${!readOnly ? "(optional, but required for Intacct sync)" : ""}`}
          field={formState.intacctId}
        />
        <StaticField label="Requirements" value={formState.meetsRequirements.value ? "Complete" : "Incomplete"} />
      </FormLines>
    </div>
  );

  return (
    <ScrollableContent>
      <Card xss={Css.dib.$}>
        <div css={Css.df.aib.$}>
          {body}
          <FormActions
            {...{ mode, onEdit, onDelete, formState }}
            onCancel={() => {
              formState.revertChanges();
              if (mode === "create") {
                history.push(createTradePartnersUrl());
              }
            }}
            entityType="Trade Partner"
            deleteMessage={`You are deleting ${formState.name.originalValue!}. This cannot be undone.`}
            // deleteDisabledReason={
            //   hasCommitments ? "You cannot delete a trade partner with existing commitments" : undefined
            // }
            onDelete={onDelete}
            onSave={() => onSave({ ...formState.changedValue })}
          />
        </div>
      </Card>
    </ScrollableContent>
  );
}

// We pick so that new fields don't break us...
type FormInput = SaveTradePartnerInput & { meetsRequirements?: boolean };
type FormState = ObjectState<FormInput>;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  website: { type: "value" },
  internalNote: { type: "value" },
  quickbooksId: { type: "value" },
  paymentTermId: { type: "value", rules: [required] },
  intacctId: { type: "value" },
  marketIds: { type: "value" },
  doesMaterials: { type: "value" },
  doesLabor: { type: "value" },
  sourceId: { type: "value" },
  sourceNote: { type: "value" },
  costCodeIds: { type: "value" },
  tradeCategoryIds: { type: "value" },
  meetsRequirements: { type: "value" },
  onboardingStatus: { type: "value" },
  isLienWaiverReceiver: { type: "value" },
};

function mapToForm(tp: TradePartnerDetailsFragment): FormInput {
  return {
    ...tp,
    marketIds: tp.markets.map((m) => m.id),
    sourceId: tp.source?.id,
    paymentTermId: tp.paymentTerm?.id,
    costCodeIds: tp.costCodes.map((cc) => cc.id),
    tradeCategoryIds: tp.tradeCategories.map((c) => c.id),
    onboardingStatus: tp.onboardingStatus.code,
    isLienWaiverReceiver: tp.isLienWaiverReceiver,
  };
}
