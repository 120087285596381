import {
  checkboxFilter,
  column,
  Css,
  dateColumn,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  multiFilter,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  Tag,
  Tooltip,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { chipCell, dateCell, SearchBox } from "src/components";
import {
  BidContract,
  BidContractRevisionStatus,
  DevelopmentContractFragment,
  useDevelopmentContractQuery,
} from "src/generated/graphql-types";
import { getTagTypeFromStatus } from "src/routes/developments/components/DevelopmentContractHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentContractOverviewUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { GenerateNewBidRequestNotice } from "./components/GenerateNewBidRequestNotice";

export function DevelopmentContractsTable() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const query = useDevelopmentContractQuery({ variables: { developmentId } });
  return queryResult(query, {
    data: ({ development }) => <DevelopmentContracts development={development} />,
  });
}
type DevelopmentContractProp = {
  development: DevelopmentContractFragment;
};

function DevelopmentContracts({ development }: DevelopmentContractProp) {
  const [filter, setFilter] = useState<FilterOptions>({});
  const [search, setSearch] = useState("");

  const rows: GridDataRow<Row>[] = useMemo(
    () =>
      simpleDataRows(
        development.bidContracts
          // Remove the internal estimate bid contract from the table results
          ?.filter((bc) => !bc.isInternalEstimate)
          .filter((row) => {
            let result = true;
            if (filter?.primary)
              result =
                (filter?.primary.includes("primary") && row.isPrimary) ||
                (filter?.primary.includes("secondary") && !row.isPrimary);
            if (filter?.status) result = result && filter?.status.includes(row.latestRevision.status.code);
            if (filter?.needsRevision) result = result && row.latestRevision.hasOutdatedScope;
            return result;
          }) || [],
      ),
    [development, filter],
  );

  const rowStyles: RowStyles<Row> = {
    data: {
      rowLink: (row) =>
        createDevelopmentContractOverviewUrl(
          development.id,
          // A bid contract will always be redirected to its latest revision
          row.data.latestRevision.id!,
        ),
    },
  };

  const filterDefs = useMemo(() => {
    return {
      primary: multiFilter({
        options: [
          { value: "primary" as const, label: "Primary" },
          { value: "secondary" as const, label: "Secondary" },
        ],
        getOptionLabel: (tp) => tp.label,
        getOptionValue: (tp) => tp.value,
        label: "Preference",
      }),
      status: multiFilter({
        options: Object.values(BidContractRevisionStatus).map((cs) => ({ label: cs, value: cs })),
        getOptionLabel: (cs) => capitalCase(cs.label),
        getOptionValue: (cs) => cs.value,
        label: "Status",
      }),
      needsRevision: checkboxFilter({
        label: "Needs Revision",
      }),
    };
  }, []);

  return (
    <>
      <GenerateNewBidRequestNotice developmentId={development.id} />
      <TableActions>
        <Filters<FilterOptions> filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <SearchBox onSearch={setSearch} />
      </TableActions>
      <GridTable
        id="contractsTable"
        columns={createColumns()}
        rows={rows}
        filter={search}
        rowStyles={rowStyles}
        fallbackMessage="No Info"
        stickyHeader
        sorting={{ on: "client" }}
      />
    </>
  );
}

type Row = SimpleHeaderAndData<BidContract>;

const createColumns = (): GridColumn<Row>[] => [
  column<Row>({
    header: () => ({ ...emptyCell, w: "32px" }),
    data: (data) => ({
      content: data.latestRevision.hasOutdatedScope && (
        <div css={Css.p0.bcGray900.$}>
          <Tooltip title="This contract contains updated line items">
            <Icon icon="errorCircle" />
          </Tooltip>
        </div>
      ),
      alignment: "center",
      css: Css.pl0.pr0.$,
    }),
    clientSideSort: false,
    w: "32px",
  }),
  column<Row>({
    header: () => ({ content: "Trade Partner", css: Css.pl2.$ }),
    data: (data) => ({
      content: data.tradePartner?.name,
      css: Css.pl2.$,
    }),
    w: "25%",
  }),
  column<Row>({
    header: () => `Preference`,
    data: (data) => (data.isPrimary ? "Primary" : "Secondary"),
  }),
  column<Row>({
    header: () => `Version`,
    data: (data) => ({
      content: data.latestRevision.version,
      value: parseInt(data.latestRevision.version || "0"),
    }),
  }),
  column<Row>({
    header: "Cost Codes",
    data: (data) => chipCell(data.latestRevision.costCodes.map((cc) => cc.number)),
    w: 2,
  }),
  dateColumn<Row>({
    header: () => `Effective On`,
    data: (data) => dateCell(data.latestRevision.startDate),
  }),
  dateColumn<Row>({
    header: () => `Expires On`,
    data: (data) => dateCell(data.latestRevision.endDate),
  }),
  column<Row>({
    header: () => `Status`,
    data: (data) => ({
      content: (
        <>
          <Tag
            text={data.latestRevision.status?.name || "Draft"}
            type={getTagTypeFromStatus(data.latestRevision.status?.code)}
          />
        </>
      ),
      value: data.latestRevision.status?.code,
    }),
  }),
];

type FilterOptions = Partial<{
  primary: ("primary" | "secondary")[];
  status: BidContractRevisionStatus[];
  needsRevision: boolean;
}>;
