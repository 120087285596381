import { Accordion, Css } from "@homebound/beam";
import { ReactNode } from "react";

type TaskCatalogFormCardProps = {
  title: ReactNode;
  children?: ReactNode;
};

export function TaskCatalogFormCard(props: TaskCatalogFormCardProps) {
  const { title, children } = props;
  return (
    <div css={Css.bgWhite.br8.boxShadow("0px 0px 13px 0px rgba(0, 0, 0, 0.05)").mb3.p1.$}>
      {/* @ts-ignore - overriding align items property as we have added an icon to the title */}
      <Accordion title={title} topBorder={false} xss={Css.aifs.$}>
        {children}
      </Accordion>
    </div>
  );
}
