import { ScrollableContent } from "@homebound/beam";
import { ProjectItemDetailsEditor, ProjectItemDetailsEditorProps } from "./ProjectItemDetailsEditor";

export function ProjectItemDetailsView(props: ProjectItemDetailsEditorProps) {
  const { projectItem, unitsOfMeasure, costConfidences, mode, onCancel, onDelete, onEdit, onSave } = props;

  return (
    <ScrollableContent>
      <ProjectItemDetailsEditor
        projectItem={projectItem}
        unitsOfMeasure={unitsOfMeasure}
        costConfidences={costConfidences}
        mode={mode}
        onCancel={onCancel}
        onEdit={onEdit}
        onSave={onSave}
        onDelete={onDelete}
      />
    </ScrollableContent>
  );
}
