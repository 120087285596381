import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useMemo } from "react";
import { useCatalogTaskIdsQuery, useMermaidQuery } from "src/generated/graphql-types";

/** Temporary feature to help debug catalog task dependencies - eventually we want something more polished for an end-user like a gantt chart */
export function MermaidLinkModal() {
  const { closeModal } = useModal();

  const { data: catalogTaskIdData, loading: idQueryLoading } = useCatalogTaskIdsQuery();

  const catalogTaskIds = useMemo(
    () => catalogTaskIdData?.globalPlanTasks.entities.map((task) => task.id) ?? [],
    [catalogTaskIdData],
  );

  const { data: mermaidData, loading: mermaidQueryLoading } = useMermaidQuery({
    variables: {
      input: { id: catalogTaskIds, graph: { constraintItemAllowances: { constrainedGlobalPlanTasks: {} } } },
    },
    skip: catalogTaskIds.isEmpty,
  });

  const loading = idQueryLoading || mermaidQueryLoading;

  return (
    <>
      <ModalHeader>Visualize Task Dependencies</ModalHeader>
      <ModalBody>
        {loading || !mermaidData ? (
          "loading..."
        ) : (
          <Button variant="tertiary" onClick={mermaidData.mermaid.url} label="Link to Chart" />
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Close" onClick={closeModal} />
      </ModalFooter>
    </>
  );
}
