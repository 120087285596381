import { Css, IconButton, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { Price, emptyCellDash } from "src/components";
import { ConfigsComparePage_ConfigCostsFragment } from "src/generated/graphql-types";
import { ProductOfferingCard } from "./ProductOfferingCard";
import { ProgramDataSection } from "./ProgramDataSection";

export function CompareProductConfigsGridLayout({
  configs,
  removeConfig,
}: {
  configs: ConfigsComparePage_ConfigCostsFragment[];
  removeConfig: (configId: string) => void;
}) {
  const tid = useTestIds({});
  const allConfigsData = useMemo(() => configs.flatMap((config) => config.computedOptionCosts), [configs]);
  const rposByConfig = configs
    .flatMap((config) =>
      config.computedOptionCosts
        .sortBy((rpo) => rpo.readyPlanOption.optionGroup.order)
        .map((data) => ({
          config,
          rpo: data.readyPlanOption,
          computedTotal: data.totalCostInCents,
        })),
    )
    .groupBy((rpo) => rpo.config.productOfferingConfig.id);

  return (
    <div
      css={
        Css.dg.gtc("repeat(auto-fill, 364px)").gap3.oxa.add("gridAutoFlow", "column").add("gridAutoColumns", "364px").$
      }
    >
      {Object.entries(rposByConfig).map(([configId, configData], idx) => {
        const rposByExteriorOrDesign = configData
          .filter((data) => data.rpo.type.forDesignInterior || data.rpo.type.forDesignExterior)
          .groupBy((data) => (data.rpo.type.forDesignExterior ? "Exterior" : "Design Packages"));
        const maybeConfig = configData.find((c) => c.config.productOfferingConfig.id === configId)?.config
          .productOfferingConfig;

        return (
          <div key={configId}>
            <div css={Css.df.fdr.jcsb.xlBd.px2.$}>
              <div {...tid.configLabel}>Configuration {idx + 1}</div>
              <IconButton icon="x" onClick={() => removeConfig(configId)} />
            </div>

            {maybeConfig && (
              <>
                <ProductOfferingCard config={maybeConfig} />
                <ProgramDataSection config={maybeConfig} />
              </>
            )}

            {/** Exterior and Design option cards */}
            {Object.entries(rposByExteriorOrDesign)
              .sort(([design, _], [exteriors, __]) => exteriors.localeCompare(design))
              .map(([typeName, designOrExteriorRpos]) => {
                return (
                  <div key={`${configId}-${typeName}`} css={Css.aic.my8.$}>
                    <div css={Css.xlBd.mb1.$} {...tid.typeName}>
                      {typeName}
                    </div>
                    <div css={Css.p3.bgWhite.aic.bshBasic.addIn("& > div:last-child", Css.bsn.$).$}>
                      {allConfigsData
                        .filter((data) =>
                          typeName === "Exterior"
                            ? data.readyPlanOption.type.forDesignExterior
                            : data.readyPlanOption.type.forDesignInterior,
                        )
                        .map((data) => data.readyPlanOption.optionGroup)
                        .uniqueBy((optGroup) => optGroup.id)
                        .sortBy((optGroup) => optGroup.order ?? Number.POSITIVE_INFINITY)
                        .map((optGroup) => {
                          const maybeDesignOrExteriorRpo = designOrExteriorRpos.find(
                            (data) => data.rpo.optionGroup.id === optGroup.id,
                          );
                          return (
                            <div
                              key={`${configId}-${optGroup.id}`}
                              css={Css.df.fdc.bb.bcGray200.py2.$}
                              {...tid[`${designOrExteriorRpos.first?.rpo.displayName.replaceAll(" ", "")}`]}
                            >
                              <div css={Css.sm.gray700.mbPx(12).h2.$}>
                                {maybeDesignOrExteriorRpo?.rpo.optionGroup.name}
                              </div>

                              <div css={Css.dg.gtc("3fr .1fr").aic.gap4.$}>
                                <div
                                  css={Css.smBd.wsnw.oh.add("textOverflow", "ellipsis").h2.onHover.ov.wsn.$}
                                  {...tid[`${designOrExteriorRpos.first?.rpo.displayName.replaceAll(" ", "")}`]}
                                >
                                  {maybeDesignOrExteriorRpo?.rpo.displayName ?? emptyCellDash}
                                </div>

                                <div css={Css.gray700.$}>
                                  {maybeDesignOrExteriorRpo && (
                                    <Price
                                      valueInCents={
                                        configData.find((data) => data.rpo.optionGroup.id === optGroup.id)
                                          ?.computedTotal || 0
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}

            {/** Single option groups cards */}
            <div css={Css.my8.$}>
              <div css={Css.xlBd.mb1.$}>Options</div>
              <div css={Css.p3.bgWhite.aic.bshBasic.br8.addIn("& > div:last-child", Css.bsn.$).$}>
                {allConfigsData
                  .filter(
                    (data) =>
                      // SingleOptionGroups filter is also pulling in design packages and exterior Rpos
                      // so filter that out explicitly here for now
                      !data.readyPlanOption.type.forDesignInterior &&
                      !data.readyPlanOption.type.forDesignExterior &&
                      data.readyPlanOption.optionGroup.isSingleOptionGroup,
                  )
                  .map((data) => data.readyPlanOption.optionGroup)
                  .uniqueBy((optGroup) => optGroup.id)
                  .sortBy((optGroup) => optGroup.order ?? Number.POSITIVE_INFINITY)
                  .map((optGroup) => {
                    const maybeOptGroupConfig = configData.find((data) => data.rpo.optionGroup.id === optGroup.id);
                    return (
                      <div key={`${configId}-${optGroup.id}`} css={Css.df.fdc.bb.bcGray200.py2.$}>
                        <div css={Css.dg.gtc("3fr .1fr").aic.gap4.$}>
                          <div css={Css.smBd.wsnw.oh.add("textOverflow", "ellipsis").$}>
                            {maybeOptGroupConfig?.rpo.optionGroup.name ?? emptyCellDash}
                          </div>
                          <div css={Css.gray700.$}>
                            {maybeOptGroupConfig && <Price valueInCents={maybeOptGroupConfig?.computedTotal || 0} />}
                          </div>
                        </div>
                        <div css={Css.df.fdr.aic.$}>
                          <div
                            css={Css.sm.gray700.mb1.wsnw.oh.add("textOverflow", "ellipsis").h2.onHover.ov.wsn.$}
                            {...tid[`${configData.first?.rpo.displayName.replaceAll(" ", "")}`]}
                          >
                            {maybeOptGroupConfig?.rpo.displayName}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
