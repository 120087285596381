import { Button, Chips, Css } from "@homebound/beam";

type MaxLineChipsProps = {
  values: string[];
  maxInlineChips: number;
  onButtonClick?: () => void;
};
export function MaxInlineChips({ values, maxInlineChips, onButtonClick }: MaxLineChipsProps) {
  return (
    <div css={Css.df.add("alignItems", "baseline").$}>
      <div onClick={onButtonClick} css={Css.cursorPointer.$}>
        <Chips values={values.slice(0, maxInlineChips)} />
      </div>
      {values.length > maxInlineChips && onButtonClick && (
        <Button
          variant="tertiary"
          label={`+${values.length - maxInlineChips}`}
          onClick={onButtonClick}
          data-testid="more"
        />
      )}
    </div>
  );
}
