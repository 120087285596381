import { useApolloClient } from "@apollo/client";
import { currentAuthToken } from "@homebound/auth-components";
import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar } from "@homebound/beam";
import { useCallback, useState } from "react";
import { jsonToCSV } from "react-papaparse";
import { CsvUploader } from "src/components/CsvUploader";
import { isValidCsvContent } from "src/components/CsvUtils";
import { baseDownloadUrl } from "src/context";
import {
  BidContractRevisionLineItemsPageDocument,
  BidContractRevisionUnitPricingDocument,
} from "src/generated/graphql-types";
import { DevelopmentContractUploadType } from "../enums";
import { getDevContractUploadCsvRequiredColumns } from "../utils";

type ImportPricingModalProps = {
  bidContractId: string;
  uploadType: DevelopmentContractUploadType;
  onComplete?: () => Promise<void>;
};

export function ImportPricingModal({ bidContractId, uploadType }: ImportPricingModalProps) {
  const { closeModal } = useModal();
  const { addError, clearErrors, setUploadType, csvContent, errors, loading, setCsvContent, upload } =
    useUploadPricingCsv(bidContractId);

  return (
    <>
      <ModalHeader>Update Development Contract</ModalHeader>
      <ModalBody>
        <h2 css={Css.lgSb.pt2.$}>Import pricing from .csv</h2>
        <CsvUploader
          onError={addError}
          errors={errors}
          onDrop={(content) => {
            clearErrors();
            try {
              const evaluation = isValidCsvContent({
                content,
                requiredColumns: getDevContractUploadCsvRequiredColumns(uploadType),
              });
              if (!evaluation.valid) return addError(evaluation.reasons);
              setUploadType(uploadType);
            } catch (error) {
              return addError((error as Error).message);
            }
            setCsvContent(
              jsonToCSV(
                content.map(({ data }) => data),
                { newline: "\n" }, // default is `\r\n` which breaks the parser on the backend
              ),
            );
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button label="Import" disabled={!csvContent || loading} onClick={upload} />
      </ModalFooter>
    </>
  );
}

function useUploadPricingCsv(bidContractId: string) {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [csvContent, setCsvContent] = useState("");
  const [uploadType, setUploadType] = useState<DevelopmentContractUploadType>();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const client = useApolloClient();

  const addError = (err: unknown) => setErrors((existing) => existing.concat(String(err)));
  const clearErrors = () => setErrors([]);

  const upload = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const response = await fetch(`${baseDownloadUrl()}/csv?type=${uploadType}&bidContractId=${bidContractId}`, {
      method: "POST",
      headers: { "content-type": "text/csv", Authorization: `Bearer ${await currentAuthToken()}` },
      body: csvContent,
    });

    if (response.status !== 200) {
      // Backend may have identified errors in the CSV (Like unfilled Total Cost columns or poorly-formatted numbers)
      // so pop them into the Errors array here.
      const { message } = await response.json();
      if (message) addError(message);
      setLoading(false);
      return;
    }

    // using Apollo Client directly instead of prop drilling refetch to update cache
    await client.refetchQueries({
      include: [BidContractRevisionLineItemsPageDocument, BidContractRevisionUnitPricingDocument],
    });
    triggerNotice({ message: "Bid Contract Successfully Updated" });
    closeModal();
  }, [loading, uploadType, bidContractId, csvContent, client, triggerNotice, closeModal]);

  return { addError, clearErrors, setUploadType, csvContent, errors, loading, setCsvContent, upload };
}
