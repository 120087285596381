import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";
import { Fragment, ReactNode } from "react";
import { PandaDocRole, useTradeSpecificAgreementQuery } from "src/generated/graphql-types";
import { Signature, TextField } from "src/routes/pdfs/components/PandaDocField";
import { HasChildren, queryResult, sanitizeHtml } from "src/utils";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { StringParam, useQueryParam } from "use-query-params";

export function TradeSpecificAgreement() {
  const [costDivisionId] = useQueryParam("costDivisionId", StringParam);
  const [tradePartnerId] = useQueryParam("tradePartnerId", StringParam);

  const query = useTradeSpecificAgreementQuery({
    variables: { costDivisionId: costDivisionId!, tradePartnerId: tradePartnerId! },
  });

  return queryResult(query, (data) => {
    const cd = data.costDivisions[0];
    const tp = data.tradePartner;
    return (
      <Fragment>
        <Global
          styles={{
            "@page": { size: "letter" },
            ul: Css.ml2.add("listStyleType", "disc").$,
          }}
        />

        <div css={Css.xl3Sb.m4.df.jcc.$}>Exhibit A &ndash; {cd.displayName}</div>

        <HeaderText>Section I. Policy, Requirements, and Specifications</HeaderText>
        <div dangerouslySetInnerHTML={{ __html: cd.tsaPolicySection || "" }} />

        <HeaderText>Section II. Scope of Work</HeaderText>
        <b>General Scope</b>
        <div dangerouslySetInnerHTML={{ __html: cd.tsaGeneralScope || "" }} />

        {cd.costCodes
          .filter((cc) => !!cc.tsaSpecifications)
          .map((cc) => {
            return (
              <div key={cc.id}>
                <b>{cc.displayName}</b>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(cc.tsaSpecifications || "") }} />
              </div>
            );
          })}

        <HeaderText>Section III. Payment</HeaderText>
        <div dangerouslySetInnerHTML={{ __html: cd.tsaPaymentSection || "" }} />

        <HeaderText>Section IV. Warranty</HeaderText>
        <div dangerouslySetInnerHTML={{ __html: cd.tsaWarrantySection || "" }} />

        {signatures(tp)}
      </Fragment>
    );
  });
}

function signatures(tp: { name: string }) {
  return (
    <div>
      {/* Use a table to avoid page breaks within fields. */}
      <table width="100%">
        <tr>
          <td>
            <ValueBox name="Company Name" value={tp.name} />
          </td>
          <td>
            <ValueBox name="Company Name" value="Homebound" />
          </td>
        </tr>
        <tr>
          <td>
            <PandaDocBox name="Representative">
              <Signature role={PandaDocRole.TradePartner} />
            </PandaDocBox>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <PandaDocBox name="Title">
              <TextField role={PandaDocRole.TradePartner} />
            </PandaDocBox>
          </td>
          <td>
            <ValueBox name="Title" value="Purchasing Coordinator" />
          </td>
        </tr>
        <tr>
          <td>
            <ValueBox name="Date" value={formatWithYear(new DateOnly(new Date()))} />
          </td>
        </tr>
      </table>
    </div>
  );
}

function ValueBox(props: { name: string; value: string }) {
  return (
    <Box>
      <div css={Css.$}>{props.value}</div>
      <div css={Css.mt1.bt.$}>{props.name}</div>
    </Box>
  );
}

function PandaDocBox({ name, children }: { name: string; children: any }) {
  return (
    <Box>
      <div css={Css.$}>{children}</div>
      <div css={Css.mt1.bt.$}>{name}</div>
    </Box>
  );
}

/**
 * To make the left/right side of form fields line up, we make everything 3"x1.5".
 *
 * 1.25" matches the height of the signature box, and 3" is about half a page width.
 */
function Box(props: HasChildren) {
  return <div css={Css.w("3in").h("1.25in").df.fdc.jcfe.$}>{props.children}</div>;
}

function HeaderText({ children }: { children: ReactNode }) {
  return <div css={Css.xlSb.my2.$}>{children}</div>;
}
