import { IconButton } from "@homebound/beam";
import { useCallback } from "react";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { setTaskPaneState } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";

type TaskCostAllocationIconProps = {
  taskId: string;
};

export const TaskCostAllocationIcon = ({ taskId }: TaskCostAllocationIconProps) => {
  const { dispatch } = useScheduleStore();

  const onClick = useCallback(() => {
    dispatch(
      setTaskPaneState({
        taskPaneId: taskId,
        tab: "relationships",
      }),
    );
  }, [dispatch, taskId]);

  return <IconButton inc={2.5} icon="finances" onClick={onClick} />;
};
