import {
  actionColumn,
  collapseColumn,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  GridDataRow,
  GridTable,
  Icon,
  LoadingSkeleton,
  Palette,
  RowStyles,
  ScrollShadows,
  Tag,
  Tooltip,
  useTestIds,
} from "@homebound/beam";
import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { formatDate, ProgressPill } from "src/components";
import {
  ProjectsWidgetScheduleTabCohortsDetailFragment,
  ProjectsWidgetScheduleTabCutoffsDetailFragment,
  ProjectsWidgetScheduleTabDetailFragment,
  ProjectsWidgetScheduleTabQuery,
  ProjectsWidgetScheduleTabTasksDetailFragment,
  TaskStatus,
  useProjectsWidgetScheduleTabQuery,
} from "src/generated/graphql-types";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { calcPercent, groupBy, percentOfSelectionsFinalized, queryResult } from "src/utils";

type HeaderRow = { kind: "header" };
type DevelopmentRow = { kind: "development"; id: string; data: { name: string }; initCollapsed: boolean };
type CohortRow = {
  kind: "cohort";
  id: string;
  data: {
    cohort: ProjectsWidgetScheduleTabCohortsDetailFragment;
    projects: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp[];
  };
  initCollapsed: boolean;
};
type ProjectRow = {
  kind: "project";
  id: string;
  data: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp;
  children: ProjectDetailsRow[];
  initCollapsed: boolean;
};
type ProjectDetailsRow = {
  kind: "projectDetails";
  id: string;
  data: {
    name: string | ReactNode;
    dataPoint: string | ReactNode | undefined | null;
  };
};

type NestedRow = HeaderRow | DevelopmentRow | CohortRow | ProjectRow | ProjectDetailsRow;

type CohortWithProjects = {
  cohort: ProjectsWidgetScheduleTabCohortsDetailFragment;
  projects: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp[];
};

type ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp = ProjectsWidgetScheduleTabDetailFragment & {
  scheduleTasks: ProjectsWidgetScheduleTabTasksDetailFragment[];
};

export function ProjectsWidgetSchedule() {
  const { filter } = useDashboardFilterContext();

  // if we don't have a filter for projects, then by default, use projects from the assignee/internalUser
  const query = useProjectsWidgetScheduleTabQuery({
    variables: {
      teamMembers: filter.internalUser,
      projects: filter.project,
    },
    skip: !filter.internalUser,
  });

  return queryResult(query, {
    data: (data) => {
      return <ProjectsWidgetScheduleTab data={data} />;
    },
    loading: () => <LoadingSkeleton rows={6} columns={2} />,
    showLoading: "always",
  });
}

function ProjectsWidgetScheduleTab({ data }: { data: ProjectsWidgetScheduleTabQuery }) {
  const tids = useTestIds({}, "projectsWidgetScheduleTab");
  const { projects, scheduleTasks } = data;

  // we need access to schedule tasks from the project level, so manually add tasks as a property to projects as a workaround
  const projectsWithScheduleTasks = projects.map((p) => ({
    ...p,
    scheduleTasks: scheduleTasks.filter((st) => st.project?.id === p.id),
  }));

  const projectsWithScheduleTasksAndCohorts = projectsWithScheduleTasks.filter((p) => p.cohort);
  // grabbing projects without cohorts to display at the top level
  const projectsWithScheduleTasksWithoutCohorts = projectsWithScheduleTasks.filter((p) => !p.cohort);

  // group cohorts by development name
  const cohortsByDevelopment = useMemo(
    () => groupBy(projectsWithScheduleTasksAndCohorts, ({ cohort }) => cohort?.development?.name ?? ""),
    [projectsWithScheduleTasksAndCohorts],
  );

  // create row data
  const rowData: Record<string, CohortWithProjects[]> = Object.entries(cohortsByDevelopment).reduce(
    (acc, [development, cohorts]) => {
      // group by cohort name
      const cohortsByCohortName = groupBy(cohorts, ({ cohort }) => cohort?.id ?? "");

      // once we get the cohorts, create an array of all projects associated to the cohort
      const nestedCohorts = Object.entries(cohortsByCohortName).map(([key, value]) => {
        const cohort = value[0].cohort;
        const projects = value.map((project) => ({
          name: project.name,
          cohort: project.cohort,
          id: project.id,
          schedules: project.schedules,
          scheduleTasks: project.scheduleTasks,
          unassignedTaskCount: project.unassignedTaskCount,
          cutoffs: project.cutoffs,
          lotType: project.lotType,
          latestActiveStage: project.latestActiveStage,
        }));
        return {
          cohort,
          projects,
        };
      });
      return { ...acc, [development]: nestedCohorts };
    },
    {},
  );

  const columns = useMemo(() => createColumns(), []);
  const lastColumn = columns[columns.length - 1];
  const developmentRows = useMemo(() => createNestedProjectsWidgetScheduleRows(rowData), [rowData]);

  // create top level project rows with no cohorts
  const projectRows = useMemo(() => {
    return projectsWithScheduleTasksWithoutCohorts.map((project) => createProjectRow(project));
  }, [projectsWithScheduleTasksWithoutCohorts]);

  const rowsWithHeader: GridDataRow<NestedRow>[] = [...projectRows, ...developmentRows];

  return (
    <ScrollShadows {...tids} xss={Css.h100.oys.$}>
      <GridTable
        columns={columns}
        sorting={{ on: "client", initial: [lastColumn.id!, "ASC"] }}
        {...{ rows: rowsWithHeader }}
        style={{ allWhite: true, bordered: false }}
        rowStyles={rowStyles}
      />
    </ScrollShadows>
  );
}

const createColumns = () => [
  column<NestedRow>({
    clientSideSort: false,
    header: () => emptyCell,
    development: ({ name }) => name,
    cohort: ({ cohort, projects }) => ({
      content: () => {
        return (
          <span css={Css.df.$}>
            {projects?.length ? <Tag type={"info"} text={projects.length.toString()} /> : null}
            <div css={Css.smBd.ml2.$}>{cohort.name}</div>
          </span>
        );
      },
      value: cohort.name,
      colspan: 2,
      css: Css.pl0.$,
    }),
    project: ({ id, name }) => ({
      content: () => {
        return (
          <Link to={createProjectScheduleUrl(id)} target="_blank">
            {name}
          </Link>
        );
      },
      value: name,
    }),
    projectDetails: ({ name }) => name,
  }),
  actionColumn<NestedRow>({
    header: () => emptyCell,
    development: () => emptyCell,
    cohort: () => emptyCell,
    project: (data) => ({
      content: () => <ProgressPill changeColorOnCompleted progress={formatProgressPill(data)} />,
      alignment: "left",
    }),
    projectDetails: (data) => ({
      content: () => <div css={Css.gray600.smMd.truncate.$}>{data.dataPoint}</div>,
      colspan: 2,
      alignment: "right",
    }),
  }),
  collapseColumn<NestedRow>({
    header: () => emptyCell,
    development: (data, { row }) => <CollapseToggle row={row} />,
    cohort: (data, { row }) => ({
      content: () => <CollapseToggle row={row} />,
      css: Css.pl0.$,
    }),
    project: (data, { row }) => <CollapseToggle row={row} />,
    projectDetails: () => emptyCell,
    w: "60px",
  }),
];

const rowStyles: RowStyles<NestedRow> = {
  development: { cellCss: Css.baseBd.gray800.wsnw.pl0.$ },
  project: { cellCss: Css.pl0.smMd.$ },
  projectDetails: { cellCss: Css.pl0.smMd.$ },
};

function formatProgressPill(data: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp) {
  const totalPhases = data.schedules.flatMap(({ phases }) => phases);
  const completedPhases = totalPhases.filter(({ taskStatusRollup }) => taskStatusRollup === TaskStatus.Complete).length;
  return Math.round(calcPercent(completedPhases, totalPhases.length));
}

function formatOriginalStartDate(project: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp) {
  const { schedules, latestActiveStage } = project;

  // filter through all schedules to get the latest active stage's schedule start date
  const activeSchedule = schedules.filter((s) => s.stage === latestActiveStage?.stage.code).first;
  const startDate = activeSchedule?.interval?.startDate;
  return formatDate(startDate, "medium");
}

function formatOriginalStartDateTooltip(project: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp) {
  const { scheduleTasks, latestActiveStage } = project;

  // filter through all schedule tasks to get the tasks that match the latest active stage's schedule and grab the first task
  const taskBySchedule = scheduleTasks.find((st) => st.schedule.stage === latestActiveStage?.stage.code);
  const tasks = taskBySchedule ? taskBySchedule.name : "No Milestone Tasks exist for this stage";
  return (
    <Tooltip title={tasks}>
      <div css={Css.df.aic.truncate.$}>
        Original Start Date: <Icon icon={"infoCircle"} inc={2} color={Palette.Gray900} xss={Css.ml1.$} />
      </div>
    </Tooltip>
  );
}

function formatCutoffDate(pc: ProjectsWidgetScheduleTabCutoffsDetailFragment[], i: number) {
  // if we don't have any project cutoffs, do an early return
  if (!pc[i].date) return "None";
  const date = formatDate(pc[i].date?.date, "medium");
  const percent = percentOfSelectionsFinalized(pc[i]);
  return `${date}, ${percent ? percent : 0} % finalized`;
}

function formatBuyOutShellTaskDate(scheduleTasks: ProjectsWidgetScheduleTabTasksDetailFragment[]) {
  // map over all the tasks to get an array of end dates for Buyout/Shell tasks
  const buyOutShellTaskEndDates = scheduleTasks
    .filter((t) => t.name.includes("Buy out - Shell"))
    .map((t) => t.interval.endDate);
  if (!buyOutShellTaskEndDates.length) return "None";
  // there should be only 1 Buyout/shell task, so grab the end date
  const endDate = buyOutShellTaskEndDates[0];
  return formatDate(endDate, "medium");
}

function formatLatestMilestone(scheduleTasks: ProjectsWidgetScheduleTabTasksDetailFragment[]) {
  // filter by completed milestone tasks
  const completedMilestoneTasks = scheduleTasks.filter((st) => st.status === TaskStatus.Complete);
  // sort the completed milestone tasks by their end date
  const sortedMilestones = [...completedMilestoneTasks].sort(
    (a, b) => a.interval.endDate.getTime() - b.interval.endDate.getTime(),
  );
  // grab the last completed task
  const latestMilestone = sortedMilestones.slice(-1)[0];
  if (!latestMilestone) return "None";
  return (
    <Tooltip title={`${latestMilestone?.name} due ${formatDate(latestMilestone.interval.endDate, "medium")}`}>
      {latestMilestone?.name} due {formatDate(latestMilestone.interval.endDate, "medium")}
    </Tooltip>
  );
}

function formatTaskWaitingOn(scheduleTasks: ProjectsWidgetScheduleTabTasksDetailFragment[]) {
  // filter tasks that are not complete and are on the critical path
  const taskWaitingOn = scheduleTasks.filter((st) => st.status !== TaskStatus.Complete && st.isCriticalPath);
  if (!taskWaitingOn.length) return "None";
  return <Tooltip title={`${taskWaitingOn[0].name}`}>{taskWaitingOn[0].name}</Tooltip>;
}

const createProjectRow = (project: ProjectsWidgetsScheduleProjectsDetailFragmentWithNewProp): ProjectRow => {
  return {
    kind: "project" as const,
    id: project.id,
    data: project,
    initCollapsed: true,
    children: [
      {
        kind: "projectDetails" as const,
        id: `OriginalStartDate_${project.id}`,
        data: {
          name: formatOriginalStartDateTooltip(project),
          dataPoint: formatOriginalStartDate(project),
        },
      },
      {
        kind: "projectDetails" as const,
        id: `type_${project.id}`,
        data: { name: "Type:", dataPoint: project.lotType ? project.lotType.code : "None" },
      },
      {
        kind: "projectDetails" as const,
        id: `Buyout_${project.id}`,
        data: {
          name: "Buyout (Shell+Core):",
          dataPoint: formatBuyOutShellTaskDate(project.scheduleTasks),
        },
      },
      {
        kind: "projectDetails" as const,
        id: `Cutoff1_${project.id}`,
        data: {
          name: "Cutoff 1:",
          dataPoint: formatCutoffDate(project.cutoffs, 0),
        },
      },
      {
        kind: "projectDetails" as const,
        id: `Cutoff2_${project.id}`,
        data: {
          name: "Cutoff 2:",
          dataPoint: formatCutoffDate(project.cutoffs, 1),
        },
      },
      {
        kind: "projectDetails" as const,
        id: `LatestMilestone_${project.id}`,
        data: {
          name: "Latest Milestone:",
          dataPoint: formatLatestMilestone(project.scheduleTasks),
        },
      },
      {
        kind: "projectDetails" as const,
        id: `TaskWaitingOn_${project.id}`,
        data: { name: "Task Waiting on:", dataPoint: formatTaskWaitingOn(project.scheduleTasks) },
      },
      {
        kind: "projectDetails" as const,
        id: `UnassignedTasks_${project.id}`,
        data: { name: "Unassigned Tasks:", dataPoint: project.unassignedTaskCount },
      },
    ],
  };
};

function createNestedProjectsWidgetScheduleRows(
  rowData: Record<string, CohortWithProjects[]>,
): GridDataRow<NestedRow>[] {
  // create rows from nested object
  return Object.entries(rowData).map(([developmentName, cohorts]) => ({
    ...{
      kind: "development" as const,
      id: developmentName,
      data: { name: `${developmentName}` as const },
      initCollapsed: true,
      children: [
        // map over the cohorts to access the project and create project rows from the project
        ...cohorts.map((cohort) => {
          return {
            kind: "cohort" as const,
            id: cohort.cohort?.id,
            data: cohort,
            children: [...cohort.projects.map((project) => createProjectRow(project))],
            initCollapsed: true,
          };
        }),
      ],
    },
  }));
}
