export type Sortable = string | number | Date | null | undefined;
type Direction = "ASC" | "DESC";

export function sortBy<T, K extends Sortable>(array: Array<T>, f: (el: T) => K, direction?: Direction): T[] {
  return [...array].sort(newComparator(f, direction));
}

export function keyBy<T, Y = T>(arr: readonly T[], fn: (x: T) => string, valueFn?: (x: T) => Y): Record<string, Y> {
  const result: Record<string, Y> = {};
  arr.forEach((o) => {
    const group = fn(o);
    if (result[group] !== undefined) {
      throw new Error(`${group} already had a value assigned`);
    }
    result[group] = valueFn === undefined ? (o as any as Y) : valueFn(o);
  });
  return result;
}

export function newComparator<T>(f: (x: T) => any, direction?: Direction): (a: T, b: T) => number {
  return (a, b) => {
    const av = f(a);
    const bv = f(b);
    const maybeNegate = direction === "DESC" ? -1 : 1;

    if (!isDefined(av) || !isDefined(bv)) {
      return compareWithUndefined(av, bv) * maybeNegate;
    } else if (typeof av === "number" && typeof bv === "number") {
      return compareNumber(av, bv) * maybeNegate;
    } else if (typeof av === "string" && typeof bv === "string") {
      return compareString(av, bv) * maybeNegate;
    } else if (av instanceof Date && bv instanceof Date) {
      return compareDate(av, bv) * maybeNegate;
    } else {
      throw new Error(`Unsupported sortBy values ${av}, ${bv}`);
    }
  };
}

function compareWithUndefined(av: any, bv: any) {
  if (!av && !bv) {
    return 0;
  } else {
    return !av ? 1 : -1;
  }
}

function compareNumber(av: number, bv: number) {
  return av - bv;
}

function compareString(av: string, bv: string) {
  return av.localeCompare(bv);
}

function compareDate(av: Date, bv: Date) {
  const avDate = av.getTime();
  const bvDate = bv.getTime();

  return avDate - bvDate;
}

export function isDefined<T extends any>(param: T | undefined | null): param is T {
  return param !== null && param !== undefined;
}

export function findIndexFrom<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
  startIndex: number = 0,
): number {
  if (startIndex < 0 || startIndex >= array.length) {
    return -1;
  }

  for (let i = startIndex; i < array.length; i++) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }

  return -1;
}
