import {
  Button,
  ButtonVariant,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RichTextField,
  useModal,
} from "@homebound/beam";
import { ErrorMessage } from "@homebound/beam/dist/inputs/ErrorMessage";
import { useEffect, useState } from "react";
import { ApprovalsDashboard_ApproverFragment, ApproverStatus } from "src/generated/graphql-types";
import { useUpdateApprovers } from "src/routes/components/Approval/useUpdateApprovers";
import { noop, pluralize } from "src/utils";

type ApproversToUpdateProps = {
  approvers: ApprovalsDashboard_ApproverFragment[];
};

export const BulkApprovalAction: Record<"Approve" | "Reject" | "RequestChanges", React.FC<ApproversToUpdateProps>> = {
  Approve: ({ approvers }) => (
    <BulkApprovalActionModal
      headline="Approve Requests"
      subtext={confirmationBlurb("approve", approvers)}
      buttonLabel="Approve"
      status={ApproverStatus.Approved}
      approvers={approvers}
    />
  ),
  Reject: ({ approvers }) => (
    <BulkApprovalActionModal
      headline="Reject Requests"
      subtext={confirmationBlurb("reject", approvers)}
      requireCommentPrompt="Add a comment explaining your reasoning below:"
      stingerText="Note: A rejected request will NOT allow for any approval changes."
      buttonLabel="Reject requests"
      buttonVariant="danger"
      status={ApproverStatus.Rejected}
      approvers={approvers}
    />
  ),
  RequestChanges: ({ approvers }) => (
    <BulkApprovalActionModal
      headline="Request Changes"
      subtext={confirmationBlurb("request changes to", approvers)}
      requireCommentPrompt="Outline all requested changes below:"
      buttonLabel="Request revisions"
      status={ApproverStatus.ChangesRequested}
      approvers={approvers}
    />
  ),
};

/** Ex: `Are you sure you want to Approve 5 selected approval requests.` */
function confirmationBlurb(action: string, approvers: unknown[]) {
  return (
    <>
      Are you sure you want to {action}{" "}
      <strong>
        {approvers.length} selected approval {pluralize(approvers, "request")}?
      </strong>
    </>
  );
}

type BulkApprovalActionModalProps = ApproversToUpdateProps & {
  headline: React.ReactNode;
  subtext?: React.ReactNode;
  requireCommentPrompt?: React.ReactNode;
  stingerText?: React.ReactNode;
  buttonLabel: React.ReactNode;
  buttonVariant?: ButtonVariant;
  status: ApproverStatus;
};

function BulkApprovalActionModal({
  headline,
  subtext,
  requireCommentPrompt,
  stingerText,
  buttonLabel,
  buttonVariant = "primary",
  approvers,
  status,
}: BulkApprovalActionModalProps) {
  const [[html, text], setComment] = useState<[string | undefined, string | undefined]>([undefined, undefined]);
  const { closeModal } = useModal();
  const updateApprovers = useUpdateApprovers(approvers, "closeModal");

  const [isCommentMissing, setIsCommentMissing] = useState(false); // state (vs calced) so mimick onBlur/onSubmit check
  useEffect(() => void (text && setIsCommentMissing(false)), [text]); // reset error when field updates

  return (
    <>
      <ModalHeader>{headline}</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap2.$}>
          <div css={Css.mb2.$}>{subtext}</div>
          {requireCommentPrompt && (
            <>
              <div>{requireCommentPrompt}</div>
              <RichTextField
                placeholder="Add a comment..."
                value={html || ""}
                onChange={(html, text) => setComment([html, text])}
                onBlur={noop}
                onFocus={noop}
              />
              {isCommentMissing && <ErrorMessage errorMsg="A comment is required." />}
            </>
          )}
          {stingerText && <div css={Css.xsSb.$}>{stingerText}</div>}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label={buttonLabel}
          variant={buttonVariant}
          onClick={() => {
            if (requireCommentPrompt && !text) return setIsCommentMissing(true);
            return updateApprovers?.(status, html, text);
          }}
        />
      </ModalFooter>
    </>
  );
}
