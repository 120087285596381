import { Maybe } from "graphql/jsutils/Maybe";
import { Rule } from "src/utils/formState";

export const emailFormatRule: Rule<Maybe<string>> = ({ value }) => {
  // If trade partner email is undefined or null consider it valid format
  if (value === undefined || value === null) {
    // undefined triggers required field error
    return undefined;
  } else if (
    // If trade partner email format does not pass validation consider it invalid
    // validation check is a simple `string@string.string` rule
    !value.toLowerCase().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  ) {
    return "Invalid format";
  }
};
