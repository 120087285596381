import { useCurrentInternalUserQuery } from "src/generated/graphql-types";

/** A hook for hard-coded/q&d access control to procurement UW features. */
export function useIsProcurementUWUser(): boolean {
  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const email = currentInternalUserData?.currentInternalUser?.email;
  return !!email && procurementUWUsers.includes(email);
}

const procurementUWUsers = ["mjordan@homebound.com", "mashford@homebound.com", "pkwiatkowski@homebound.com"];
