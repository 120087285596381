import { AutoSaveIndicator, Button, Css, useModal } from "@homebound/beam";
import { ReadyPlanVersionHeader } from "src/components/ReadyPlanVersionHeader";
import { PlanPackageTakeoffPageFragment } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { fail } from "src/utils";
import { PublishAggregateVersionModal } from "../../details/components/PublishAggregateVersionModal";

export type TakeoffHeaderProps = {
  planPackage: PlanPackageTakeoffPageFragment;
};

export function TakeoffsHeader({ planPackage }: TakeoffHeaderProps) {
  const { openModal } = useModal();
  const { aggregateDraft, name } = planPackage;

  return (
    <PageHeader
      xss={Css.bgTransparent.bn.mb0.$}
      titleSize="xl3Sb"
      title="Takeoff Manager"
      left={<AutoSaveIndicator />}
      right={
        <>
          <ReadyPlanVersionHeader readyPlan={planPackage} />
          {/* TODO: Work with Product & Design to determine when/how this can be Published */}
          <Button
            size="md"
            disabled={!aggregateDraft}
            label="Publish"
            onClick={() =>
              openModal({
                content: (
                  <PublishAggregateVersionModal
                    draftAggregateVersion={aggregateDraft! ?? fail("No draft aggregate version found")}
                    copyText="This update will be made available to any Design Package and Product Offering that reference it."
                    refetchQueries={["PlanPackageTakeoffPage"]}
                    readyPlanName={name}
                  />
                ),
              })
            }
          />
        </>
      }
    />
  );
}
