import { Css, IconButton, useTestIds } from "@homebound/beam";
import { useUploaderContext } from "src/contexts/UploaderContext";
import { FileUploadProgress } from "./FileUploadProgress";

export function FileUploadProgressAlert() {
  const fileUploadTestId = useTestIds({}, "fileUploadProgressAlert");
  const { files, clearFiles } = useUploaderContext();
  if (Object.keys(files).length === 0) return null;

  return (
    <div css={Css.bgWhite.py3.px4.df.fdc.ba.br8.bcGray400.bw1.bshBasic.$} {...fileUploadTestId}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.base.gray700.$}>Uploading Documents</div>
        <IconButton icon="x" onClick={clearFiles} {...fileUploadTestId.clear} />
      </div>
      <FileUploadProgress hideOnComplete={false} />
    </div>
  );
}
