import { HbLoadingSpinner } from "@homebound/beam";
import React, { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router";
import {
  DesignPackageConfiguratorFragment,
  DesignPackageItemSlotEditorQuery,
  ItemTemplateStatus,
  useDesignPackageConfiguratorQuery,
  useDesignPackageItemSlotEditorQuery,
} from "src/generated/graphql-types";
import useQueryString from "src/hooks/useQueryString";
import { fail } from "src/utils";

type DesignPackageConfiguratorContextState = {
  /** Basic metadata about the loaded DesignPackage */
  designPackage: DesignPackageConfiguratorFragment;
  /** Which version of the DesignPackage to display. Currently auto-detected, as there's no dropdown. Needed to create+load ITIVs. */
  selectedTemplateId: string;
  /** The slots LefNav has selected for "main content" display in the ItemSlotEditor */
  selectedSlots: DesignPackageItemSlotEditorQuery["designPackage"]["slots"] | undefined;
  /** The selected Options via DesignWithFilters */
  selectedOptionIds: string[];
  /** The topic of interest in the New>Product Search>Detail flow */
  bidItemId: DesignPackageConfiguratorQueryString["bidItemId"] | undefined;
  setCtxState: (partial: Partial<DesignPackageConfiguratorQueryString>) => void;
  loading: boolean;
};

export const DesignPackageConfiguratorContext = createContext<DesignPackageConfiguratorContextState>({
  selectedTemplateId: "",
  designPackage: undefined!,
  setCtxState() {},
  selectedOptionIds: [],
  selectedSlots: undefined,
  loading: false,
  bidItemId: undefined,
});

export function DesignPackageConfiguratorProvider({ children }: React.PropsWithChildren<unknown>) {
  const { designPackageId } = useParams<{ designPackageId: string }>();
  const [qs, setQs] = useQueryString<DesignPackageConfiguratorQueryString>({ optionIds: [], slotIds: [] });

  // getting new arrays on each render cycle, so stabilize them
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const slotIds = useMemo(() => qs.slotIds, [qs.slotIds.toString()]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionIds = useMemo(() => qs.optionIds, [qs.optionIds.toString()]);
  const { selectedTemplateId } = qs;

  /** This is the Background data for all pages */
  const designCatalogQuery = useDesignPackageConfiguratorQuery({
    variables: { id: designPackageId },
    /** When DesignCatalogQuery returns for the first time, set TemplateID to latest draft. Do not touch otherwise. */
    onCompleted: (data) => {
      if (selectedTemplateId) return;
      // iirc there can only be 1 draft at a time. Find it, or find the latest active template
      // Eventually this is user-selectable. We need to disable the "Save" on BidItemPicker at that time if the selected
      // Template is not Draft. Perhaps also disable BidItemPicker>BidItemDetails navigation for unselected BidItems as well.
      const template =
        data.designPackage.itemTemplates?.find((it) => it.status === ItemTemplateStatus.Draft) ??
        data.designPackage.itemTemplates?.find((it) => it.isLatest);
      if (template) setQs({ selectedTemplateId: template.id });
    },
  });

  /** This is the "Center Panel" data that "Left Nav" is Setting */
  const selectedSlotQuery = useDesignPackageItemSlotEditorQuery({
    variables: { dpId: designPackageId, slotIds, templateId: selectedTemplateId, optionIds },
    skip: !designPackageId || !selectedTemplateId || (slotIds.isEmpty && optionIds.isEmpty),
  });

  return (
    <DesignPackageConfiguratorContext.Provider
      value={{
        designPackage:
          designCatalogQuery.data?.designPackage! ?? (!designCatalogQuery.loading && fail("No design package")),
        selectedTemplateId,
        setCtxState: setQs,
        selectedOptionIds: optionIds,
        bidItemId: qs.bidItemId,
        selectedSlots:
          // If user had options selected (DWF), then de-selected the last option, return `[]` to return back to "nothing selected; please pick a slot/filters" screen
          (slotIds.isEmpty && optionIds.isEmpty && []) ||
          // prevent flicker when switching between slots as `data` clears out when new variables are set before new data is returned
          (selectedSlotQuery.data?.designPackage.slots ?? selectedSlotQuery.previousData?.designPackage.slots),
        loading: designCatalogQuery.loading || selectedSlotQuery.loading,
      }}
    >
      {designCatalogQuery.loading ? <HbLoadingSpinner /> : children}
    </DesignPackageConfiguratorContext.Provider>
  );
}

/**
 * Several pages within DesignPackage are trying to leverage QueryString including:
 * 1. Context: selectedTemplateId, selected Slots from leftnav
 * 2. SlotEditor: selectedOptionIds for DesignWithFilters
 * 3. ItemSlotBidItemPage: bidItemId (possible "noBidItem" for isDisabledBidItem)
 */
type DesignPackageConfiguratorQueryString = {
  selectedTemplateId: string;
  /** Set by LeftNav to tell ItemSlotEditor which Slots to load */
  slotIds: string[];
  /** Used by DesignWithFilters. Propagates to ItemSlotBidItemPage so the selected filters auto-apply to new ITIs */
  optionIds: string[];
  /**
   * The selected BidItem for the ItemSlotBidItemPage, either from the selected Card or forwarded from the
   * ProductSearchPage and about to be committed to the card. Pass in `"none"` to show the "No Bid Item" UI.
   */
  bidItemId: `bi:${number}` | "noBidItem";
};

export const useDesignPackageConfiguratorContext = () => useContext(DesignPackageConfiguratorContext);
