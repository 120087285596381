import { Css, Icon, Palette } from "@homebound/beam";
import { CompareMode_ItiVersionFragment } from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";

type CompareCellProps = { itivs: CompareMode_ItiVersionFragment[] };

export function CompareCell({ itivs }: CompareCellProps) {
  const [maybeFirstItiv, ...hopefullyEmpty] = itivs;
  // Disabled: There is an ITIV but `itiv.scope.isDisabledBidItem`
  if (!maybeFirstItiv || maybeFirstItiv.isDisabledBidItem)
    return (
      <div data-testid="noProductCell" css={Css.hPx(220).wPx(220).df.fdc.aic.jcc.gap1.tac.ba.bcGray500.bgGray50.br4.$}>
        <Icon icon="remove" color={Palette.Gray700} inc={4} />
        <div css={Css.gray700.baseSb.maxw75.$}>No Product</div>
      </div>
    );

  const slotImage = maybeFirstItiv.bidItem?.parentMaterialVariant?.featuredImage?.asset?.previewUrl;
  return (
    <div
      css={Css.hPx(220).wPx(220).df.fdc.aic.jcc.relative.$}
      data-testid={`${maybeFirstItiv?.id}-${maybeFirstItiv?.scope?.placeholder?.id}`}
    >
      {hopefullyEmpty.nonEmpty && <div css={Css.absolute.top0.right0.m2.br100.bgWhite.$}>+{hopefullyEmpty.length}</div>}
      <img
        // using 'objectContain' to avoid cutting off any images
        css={Css.w100.h100.br4.if(!!slotImage).objectContain.$}
        src={slotImage ?? PRODUCT_FALLBACK_IMG_URL}
        alt={maybeFirstItiv.bidItem?.name ?? "Product Image"}
      />
    </div>
  );
}
