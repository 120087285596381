import { Css, ModalBody, ModalHeader, useTestIds } from "@homebound/beam";
import { PdfViewer } from "src/components";
import { BillPage_DocumentFragment } from "src/generated/graphql-types";
import { getDynamicPdfVierwerWidth } from "../utils";

export const BillDocumentModal = ({
  billDisplayName,
  documents,
}: {
  billDisplayName: string;
  documents: BillPage_DocumentFragment[];
}) => {
  const tids = useTestIds({}, "billDocumentModal");

  return (
    <div {...tids} css>
      <ModalHeader>{billDisplayName}</ModalHeader>
      <ModalBody>
        <div css={Css.bgWhite.bshBasic.oa.br8.mb3.$}>
          <PdfViewer
            hasHeader
            assets={documents.map((d) => d.asset)}
            title={""}
            pdfPageWidth={getDynamicPdfVierwerWidth()}
          />
        </div>
      </ModalBody>
    </div>
  );
};
