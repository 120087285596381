import {
  Avatar,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  Palette,
  simpleHeader,
  Tooltip,
} from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { formatDate } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  AssetInfoFragment,
  DocumentsTile_DocumentFragment,
  useUserProjectDocumentsQuery,
} from "src/generated/graphql-types";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createProjectDocumentsUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { EmptyTile } from "../EmptyTile";

export function DocumentsTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useUserProjectDocumentsQuery({ variables: { projectId, limit: 50 } });

  return (
    <Tile href={createProjectDocumentsUrl(projectId)} {...props}>
      {queryResult(query, {
        data: ({ userProjectDocuments }) => {
          if (userProjectDocuments.isEmpty) {
            return (
              <EmptyTile>
                <div data-testid="userWithOutDocs" css={Css.maxwPx(150).$}>
                  There isn't any related documents for you
                </div>
              </EmptyTile>
            );
          }
          return (
            <AssetGallery
              assets={userProjectDocuments.map(({ asset }) => asset)}
              caption={({ createdAt }) => `Uploaded on ${formatDate(createdAt)}`}
            >
              {(openGallery) => <DocumentsTileTable documents={userProjectDocuments} openGallery={openGallery} />}
            </AssetGallery>
          );
        },
      })}
    </Tile>
  );
}

function DocumentsTileTable({
  documents,
  openGallery,
}: {
  documents: DocumentsTile_DocumentFragment[];
  openGallery: (asset: AssetInfoFragment) => void;
}) {
  const columns = useMemo(() => createColumns(openGallery), [openGallery]);
  const rows = useMemo(() => createRows(documents), [documents]);

  return (
    <GridTable
      columns={columns}
      rows={rows}
      style={{
        rowHeight: "fixed",
        allWhite: true,
        rowHover: false,
        cellTypography: "xsMd",
      }}
    />
  );
}

type Header = { kind: "header" };
type DocumentRow = { kind: "document"; data: DocumentsTile_DocumentFragment };
type Row = Header | DocumentRow;

function createRows(documents: DocumentsTile_DocumentFragment[]): GridDataRow<Row>[] {
  const [favoritedDocs, docs] = documents.partition(({ isFavorited }) => isFavorited);
  return [
    simpleHeader,
    ...[...favoritedDocs, ...docs].map((document) => {
      return {
        kind: "document" as const,
        id: document.id,
        data: document,
      };
    }),
  ];
}

function createColumns(openGallery: (asset: AssetInfoFragment) => void): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Name",
      document: ({ displayName, asset, isFavorited }) => ({
        content: (
          <Tooltip title={displayName} placement="top">
            <div css={Css.df.aic.gap1.xsMd.blue700.w100.$} onClick={() => openGallery(asset)}>
              {isFavorited && (
                <span>
                  <Icon icon="star" inc={2} color={Palette.Yellow500} />
                </span>
              )}
              <span css={Css.tal.truncate.$}>{displayName}</span>
            </div>
          </Tooltip>
        ),
      }),
      mw: "100px",
    }),
    column<Row>({ header: "Added", document: ({ createdAt }) => formatDate(createdAt, "short"), w: "80px" }),
    column<Row>({
      header: "By",
      document: ({ createdBy }) => (
        <Avatar size="sm" name={createdBy.internalUser?.name} src={createdBy.internalUser?.avatar} />
      ),
      align: "center",
      w: "40px",
    }),
  ];
}
