import { Css, Palette } from "@homebound/beam";
import { PieTooltipProps, ResponsivePie } from "@nivo/pie";

type TooltipPosition = "left" | "right";

export function PieChart(props: {
  dataset: { id: string | null; value: number | boolean }[];
  colors?: string[];
  loadingColor: Palette[];
  disabled?: boolean;
  tooltipPosition?: TooltipPosition;
  tooltipValueFormat?: (value: number) => string;
}) {
  const { dataset, colors, loadingColor, disabled, tooltipPosition, tooltipValueFormat } = props;

  return (
    <ResponsivePie
      data={dataset}
      innerRadius={0.5}
      colors={disabled ? loadingColor : colors}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      isInteractive={!disabled}
      tooltip={customTooltip(tooltipPosition, tooltipValueFormat)}
    />
  );
}

function customTooltip(
  tooltipPosition: TooltipPosition = "left",
  tooltipValueFormat: (value: number) => string = (value: number) => value.toString(),
) {
  return ({
    datum,
  }: PieTooltipProps<{
    id: string | null;
    value: number | boolean;
  }>) => {
    const { color, label, value } = datum;

    return (
      <div
        css={
          Css.bgWhite.df.gap1.absolute.fwnw
            .topPx(-5)
            .px1.pyPx(0.5)
            .jcc.aic.borderRadius("2px")
            .boxShadow("0 0 1px 0 rgba(0, 0, 0, 0.5)")
            .if(tooltipPosition === "left").right2.else.left2.$
        }
      >
        <div css={Css.h1.w1.br100.bgColor(color).$} />
        <span css={Css.fg1.$}>
          {label}: <b>{tooltipValueFormat(value)}</b>
        </span>
      </div>
    );
  };
}
