import { Css, ModalBody, ModalHeader } from "@homebound/beam";

import { ItemTemplateAnomaliesContext_ItAnomalyFragment } from "src/generated/graphql-types";

type ItemTemplateAnomaliesModalProps = {
  anomalies: ItemTemplateAnomaliesContext_ItAnomalyFragment[];
};

/** Provides "From Import" for both v1 and v2 templates. */
export function ItemTemplateAnomaliesModal(props: ItemTemplateAnomaliesModalProps) {
  const { anomalies } = props;

  return (
    <>
      <ModalHeader>Anomalies 🧪</ModalHeader>
      <ModalBody>
        <ul>
          {anomalies.map((a) => (
            <li css={Css.p1.$} key={a.id}>
              {a.description}
            </li>
          ))}
        </ul>
      </ModalBody>
    </>
  );
}
