import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useHistory } from "react-router";
import { createChangeEventsUrl } from "src/RouteUrls";
import { CommitmentLikeTypeName } from "src/generated/graphql-types";

type ExceededRevisedBudgetModalProps = {
  projectId: string;
  commitmentLikeType: CommitmentLikeTypeName;
};

export default function ExceededRevisedBudgetModal({ projectId, commitmentLikeType }: ExceededRevisedBudgetModalProps) {
  const { closeModal } = useModal();
  const history = useHistory();

  return (
    <>
      <ModalHeader>Committed Amount Would Exceed Revised Budget</ModalHeader>
      <ModalBody>
        <div css={Css.baseMd.$}>
          In order to release this {commitmentLikeType === "Commitment" ? "commitment" : "commitment change order"}, an
          approved change event is required to update the revised budget.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="danger" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Create Change Event"
          onClick={() => {
            // Redirect to the change events page
            history.push(createChangeEventsUrl(projectId));
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
