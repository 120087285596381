import { setGridTableDefaults } from "@homebound/beam";
import { HasChildren } from "src/utils";
import { PdfCssReset } from "src/utils/PdfCssReset";

export type PdfLayoutProps = HasChildren;

/** Wraps the pdf pages (passed as children) with a basic layout */
export function PdfLayout(props: PdfLayoutProps) {
  setGridTableDefaults({});
  // Add a margin for when the user is just looking at the page, but remove it for actual pdf generation (ie, printing)
  return (
    <>
      <PdfCssReset />
      {props.children}
    </>
  );
}
