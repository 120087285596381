// We have a ton of users with hundreds of stale tasks associated to abandoned/test projects.
// Adding a filter for 30 days ago as a simple fix to this issue.
import { isSameDay, startOfToday } from "date-fns";
import {
  ActionItemBuildAddressFragment,
  PersonalDashboard_ScheduleFlagFragment,
  PersonalDashboard_ScheduleTaskFragment,
  PersonalDashboard_TradePartnerAvailabilityRequestFragment,
  TaskParentPillFragment,
  TradePartnerAvailabilityRequestStatus,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { createDevelopmentScheduleUrl, createProjectScheduleUrl } from "src/RouteUrls";
import { isDefined } from "src/utils";
import { addDaysDateOnly, DateOnly } from "src/utils/dates";
import { nonClosedProjects } from "src/utils/projects";

export function thirtyDaysAgo() {
  return addDaysDateOnly(new DateOnly(startOfToday()), -30);
}

// helper function to get the first name from InternalUser.
export function getFirstNameFromInternalUser(currentInternalUser: string) {
  const firstName = currentInternalUser?.split(" ")[0];
  return { firstName };
}

export function getTaskParentTitleAndUrl(taskParent: TaskParentPillFragment) {
  switch (taskParent.__typename) {
    case "Project":
      return { parentTitle: taskParent.buildAddress.street1, url: createProjectScheduleUrl(taskParent.id) };

    case "Development":
    case "ScheduleTemplate":
      return { parentTitle: taskParent.name, url: createDevelopmentScheduleUrl(taskParent.id) };

    default:
      return { parentTitle: "", url: "" };
  }
}

// filter out projects from schedule tasks on delay flags that are on hold or closed
export function filterScheduleFlagsByNonClosedStatus(scheduleFlags: PersonalDashboard_ScheduleFlagFragment[]) {
  return scheduleFlags?.filter(
    ({ scheduleTask }) =>
      isDefined(scheduleTask?.project) && nonClosedProjects.includes(scheduleTask.project.status.code),
  );
}

export function getFullBuildAddress(buildAddress: ActionItemBuildAddressFragment) {
  const { city, postalCode, state, street1 } = buildAddress;
  return `${street1}, ${city} ${state} ${postalCode}`;
}

function getTradePartnerAvailabilityRequestEmailGreeting(
  scheduleTask: PersonalDashboard_ScheduleTaskFragment,
  prevRequest: PersonalDashboard_TradePartnerAvailabilityRequestFragment | undefined,
) {
  const rescheduleDates = prevRequest?.rescheduleDates ?? [];

  if (
    scheduleTask.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable &&
    rescheduleDates.some((d) => isSameDay(d, scheduleTask.interval.startDate))
  ) {
    return "We received your new availability for the task below and updated our schedule to match the date(s) that you’re available. We would greatly appreciate your availability for the new task date.";
  }

  if (scheduleTask.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsConfirmation) {
    if (prevRequest?.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded) {
      return `We reviewed the new task date(s) that you provided availability on, but are unable to accommodate that date(s). Please review the new date(s) and let us know if you have availability for it.`;
    }
  }

  if (scheduleTask.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsReconfirmation) {
    return `We changed the date of a task that you had already confirmed. We would greatly appreciate your availability for the new task date.`;
  }

  return "We would greatly appreciate your availability for the task below.";
}

function getTradePartnerAvailabilityRequestEmailFooter(
  scheduleTask: PersonalDashboard_ScheduleTaskFragment,
  prevRequest: PersonalDashboard_TradePartnerAvailabilityRequestFragment | undefined,
) {
  const rescheduleDates = prevRequest?.rescheduleDates ?? [];

  if (
    scheduleTask.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable &&
    rescheduleDates.some((d) => isSameDay(d, scheduleTask.interval.startDate))
  ) {
    return "Please review the new task dates and confirm that you are available. Thank you!";
  }

  return "Please confirm if you are available for the tasks in the link below. Thank you!";
}

export function getTradePartnerAvailabilityRequestEmailMessage(scheduleTask: PersonalDashboard_ScheduleTaskFragment) {
  const { tradePartner, name, project, tradePartnerAvailabilityRequests } = scheduleTask;
  const prevRequest = tradePartnerAvailabilityRequests?.sortByKey("createdAt").last;
  const text = getTradePartnerAvailabilityRequestEmailGreeting(scheduleTask, prevRequest);
  const footer = getTradePartnerAvailabilityRequestEmailFooter(scheduleTask, prevRequest);

  return `<div>
  <!--block-->
  Hello ${tradePartner?.name},<br><br>
  We are reaching out regarding an upcoming construction task. ${text}<br>
  <br><br>
  Project Details:&nbsp;
</div>
<ul>
  <li><!--block-->Project Name: ${project?.name}</li>
  <li><!--block-->Location: ${getFullBuildAddress(project?.buildAddress!)}</li>
  <li><!--block-->Construction Task: ${name}</li>
</ul>
<div>
  <!--block-->
  ${footer}
</div>`;
}

export function getTradePartnerAvailabilityRequestEmailSubject(scheduleTask: PersonalDashboard_ScheduleTaskFragment) {
  const { tradePartnerAvailabilityRequests, name } = scheduleTask;
  return tradePartnerAvailabilityRequests?.nonEmpty
    ? `Important: Follow Up Request Availability for Upcoming Work (${name})`
    : `Request Availability for Upcoming Work (${name})`;
}
