import { Button, SuperDrawerContent, SuperDrawerHeader, TabsWithContent, useSessionStorage } from "@homebound/beam";
import { ChangeEventLineItemType } from "src/generated/graphql-types";
import { CeliSelectionOverviewTab } from "src/routes/projects/change-events/CeliSelectionOverviewTab";
import { CeliSpecOverviewTab } from "src/routes/projects/change-events/CeliSpecOverviewTab";
import { ObservableChangeEventLineItem } from "src/routes/projects/change-events/models/ObservableChangeEventLineItem";
import { ProjectItemCommitmentsTable } from "src/routes/projects/selections/ProjectItemCommitmentsTable";
import { ProjectItemHistoryTab } from "src/routes/projects/selections/ProjectItemHistoryTab";
import { SelectionsTab } from "src/routes/projects/selections/SelectionsTab";

export type ChangeEventLineItemSuperDrawerProps = {
  changeEventLineItem: ObservableChangeEventLineItem;
  homeownerSelectionId?: string;
  onDelete: (id: string) => void;
  readOnly: boolean;
  title: string;
};

export function ChangeEventLineItemSuperDrawer(props: ChangeEventLineItemSuperDrawerProps) {
  const { changeEventLineItem, homeownerSelectionId, onDelete, readOnly, title } = props;
  const [selectedTab, setSelectedTab] = useSessionStorage("changeEventLineItemSuperDrawerTab", "overview");
  const { id, isSelection, projectItemId, type } = changeEventLineItem;

  const overviewTab = {
    name: "Overview",
    value: "overview",
    render: () =>
      isSelection ? (
        <CeliSelectionOverviewTab changeEventLineItemId={id} />
      ) : (
        <CeliSpecOverviewTab changeEventLineItem={changeEventLineItem} />
      ),
  };

  const selectionsTab = {
    name: "Selections",
    value: "specOrSelections",
    render: () => (
      <SelectionsTab
        projectItemId={projectItemId}
        changeEventLineItem={changeEventLineItem}
        homeownerSelectionId={homeownerSelectionId!}
      />
    ),
  };

  const commitmentsTab = {
    name: "Commitments",
    value: "commitments",
    render: () => <ProjectItemCommitmentsTable projectItemId={projectItemId} />,
  };

  const historyTab = {
    name: "History",
    value: "history",
    render: () => <ProjectItemHistoryTab projectItemId={projectItemId} />,
  };

  return (
    <SuperDrawerContent>
      <SuperDrawerHeader
        title={title}
        right={
          <Button
            disabled={readOnly || type === ChangeEventLineItemType.Cutoff}
            label={"Delete Item"}
            onClick={() => onDelete(id)}
            variant={"secondary"}
          />
        }
      />
      <TabsWithContent
        tabs={
          isSelection
            ? [overviewTab, selectionsTab, commitmentsTab, historyTab]
            : [overviewTab, commitmentsTab, historyTab]
        }
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </SuperDrawerContent>
  );
}
