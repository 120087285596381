import { ApolloError, ServerError } from "@apollo/client";
import { Error } from "src/routes/Error";

export function getErrorMessages(error: ApolloError | Error): string[] | undefined {
  if (!isApolloError(error)) {
    return [error.message];
  }

  const networkError = error.networkError as ServerError;
  const errorResult = networkError?.result;

  if (!errorResult) {
    return [error.message];
  }

  // Remove duplicate error messages - It is possible the backend sent the same error message multiple times.
  const serverErrors: string[] | undefined = (errorResult as any).errors;

  return serverErrors
    ?.map((e: any) => e.message as string)
    .reduce((acc, m) => (acc.includes(m) ? acc : acc.concat(m)), [] as string[]);
}

function isApolloError(error: ApolloError | Error): error is ApolloError {
  return typeof error === "object" && "networkError" in error;
}

export function entityNotFoundError(id: string) {
  return global.Error(`${id} was not found`);
}
