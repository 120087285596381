import { Css, Icon, IconButton, LoadingSkeleton, Palette, ScrollShadows, Tooltip, useRightPane } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { createTaskDetailsPageUrl } from "src/RouteUrls";
import { CommentFeed, HistoryFeed, formatDate } from "src/components";
import {
  DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment,
  SavePlanTaskInput,
  ScheduleDraftMode_PlanTaskFragment,
  useDynamicScheduleSidePaneQuery,
  usePlanTaskDetailsDocumentsQuery,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { useDraftScheduleStore } from "src/routes/projects/dynamic-schedules/draft-mode/scheduleDraftStore";
import { Bills } from "src/routes/projects/dynamic-schedules/task-details/Bills";
import { CostAllocations } from "src/routes/projects/dynamic-schedules/task-details/CostAllocations";
import { PurchaseOrders } from "src/routes/projects/dynamic-schedules/task-details/PurchaseOrders";
import {
  DelayFlagSection,
  DependenciesSection,
  MaterialsSection,
} from "src/routes/projects/dynamic-schedules/task-details/SchedulingCard";
import { TaskDetailCard, TaskDetailCardType } from "src/routes/projects/dynamic-schedules/task-details/TaskDetailCard";
import { VerificationChecklist } from "src/routes/projects/dynamic-schedules/task-details/VerificationChecklist";
import { TaskDocuments } from "src/routes/projects/schedule-v2/detail-pane/TaskDocuments";
import { pluralize, queryResult } from "src/utils";

export function DynamicScheduleSidePane({
  draftTask,
  onClose,
}: {
  draftTask: ScheduleDraftMode_PlanTaskFragment;
  onClose: () => void;
}) {
  const { closeRightPane } = useRightPane();
  const { id: projectId } = useProjectContext();
  const history = useHistory();
  const draftTaskChanges = useDraftScheduleStore((state) => state.draftTaskChanges);
  const userAddedScheduleFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);

  const query = useDynamicScheduleSidePaneQuery({
    variables: { taskId: draftTask.id },
    // Use no-cache to ensure we're preventing any draft changes from being overwritten by a cache hit
    fetchPolicy: "no-cache",
  });
  const taskHasChanged = useMemo(
    () =>
      draftTaskChanges.some((change) => change.id === draftTask.id) ||
      userAddedScheduleFlags.some((flag) => flag.taskId === draftTask.id),
    [draftTaskChanges, userAddedScheduleFlags, draftTask],
  );

  return (
    <div css={Css.df.fdc.oa.h100.px3.pb5.bgWhite.z0.gap1.$}>
      <div css={Css.df.jcsb.aic.sticky.pt3.top0.bgWhite.z1.$}>
        <Tooltip
          title={
            taskHasChanged &&
            "This task has changes within the table that have yet to be published. Task detail information in this panel cannot be guaranteed until pending changes to the schedule have been published."
          }
          placement="top"
          disabled={!taskHasChanged}
        >
          <div css={Css.df.aic.gap1.xl2Bd.if(taskHasChanged).orange500.$}>
            {draftTask.name}
            {taskHasChanged && (
              <div>
                <Icon icon="error" color={Palette.Orange500} />
              </div>
            )}
          </div>
        </Tooltip>
        <div css={Css.df.gap1.$}>
          <IconButton
            tooltip="View full page details"
            icon="expand"
            color={Palette.Gray900}
            // Note: IconButton should support just a string for onclick navigation since it's built on Button, but it
            // doesn't work
            onClick={() => history.push(createTaskDetailsPageUrl(projectId!, draftTask.id))}
          />
          <IconButton
            icon="x"
            color={Palette.Gray900}
            onClick={() => {
              onClose();
              closeRightPane();
            }}
          />
        </div>
      </div>
      {draftTask.tradePartner?.name && <div css={Css.base.gray700.$}>{draftTask.tradePartner?.name}</div>}
      <div css={Css.base.gray700.$}>
        {formatDate(draftTask.startDate)} - {formatDate(draftTask.endDate)} (
        {draftTask?.knownDurationInDays ?? draftTask.durationInDays}
        {pluralize(draftTask?.knownDurationInDays ?? draftTask.durationInDays, " day")})
      </div>
      {queryResult(query, {
        data: (data) => <DynamicScheduleSidePaneView planTask={data.planTask} />,
        loading: () => (
          <div css={Css.df.fdc.gap7.pt2.$}>
            <LoadingSkeleton columns={1} rows={10} />
          </div>
        ),
      })}
    </div>
  );
}

function DynamicScheduleSidePaneView({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  return (
    <div css={Css.df.fdc.gap7.pt2.$}>
      <SidePaneCommentSection planTask={planTask} />
      <PurchaseOrders projectItems={planTask.projectItems} inSidePane />
      <Bills task={planTask} inSidePane />
      <SidePaneDocumentsSection planTask={planTask} />
      <MaterialsSection task={planTask} inSidePane />
      <DelayFlagSection task={planTask} inSidePane />
      <CostAllocations projectItems={planTask.projectItems} inSidePane />
      <VerificationChecklist planTask={planTask} />
      <DependenciesSection task={planTask} inSidePane />
      <SidePaneHistoryCard planTask={planTask} />
    </div>
  );
}

// We don't really need form state here, but it's easier to just bite that bullet and pray we can refactor it later to have a
// version not weighed down by old code
function SidePaneDocumentsSection({
  planTask,
}: {
  planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment;
}) {
  const { data } = usePlanTaskDetailsDocumentsQuery({ variables: { parentId: planTask.schedule.parent.id } });
  const formState = useFormState<SavePlanTaskInput, DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment>({
    config: {
      id: { type: "value" },
      documents: { type: "value" },
    },
    init: {
      input: planTask,
      map: (task) => ({
        id: task.id,
        documents: task.documents.map((doc) => doc.id),
      }),
    },
  });
  return (
    <TaskDocuments
      parentId={planTask.schedule.parent.id}
      documentTypes={data?.documentTypes ?? []}
      documents={data?.documents ?? []}
      formState={formState}
      task={planTask}
      onPlanTask
      taskStatus={planTask.status.code}
    />
  );
}

function SidePaneHistoryCard({ planTask }: { planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment }) {
  return (
    <TaskDetailCard noPadding cardType={TaskDetailCardType.History} maxHeight={450}>
      <h2 css={Css.lgBd.$}>History</h2>
      <ScrollShadows xss={Css.h100.oys.$}>
        <HistoryFeed historyItems={planTask?.history ?? []} renderAsCard={false} />
      </ScrollShadows>
    </TaskDetailCard>
  );
}

function SidePaneCommentSection({ planTask }: { planTask: DynamicSchedulesSidePaneWithConstraints_PlanTaskFragment }) {
  return (
    <TaskDetailCard cardType={TaskDetailCardType.Comments} noPadding maxHeight={650}>
      <CommentFeed
        inlineCommentTitle={<h2 css={Css.lgBd.$}>Comments</h2>}
        showCommentTitle={false}
        showFollowers={false}
        commentable={planTask}
        maxHeight={490}
      />
    </TaskDetailCard>
  );
}
