import {
  Button,
  column,
  dateColumn,
  defaultPage,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  multiFilter,
  numericColumn,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { chipCell, dateCell, priceCell, QueryTable, SearchBox, tagCell } from "src/components";
import {
  CommitmentStatus,
  DevelopmentCommitmentsPageDevCommFragment,
  DevelopmentCommitmentsPageQuery,
  Maybe,
  Scalars,
  useDevelopmentCommitmentsPageMetaDataQuery,
  useDevelopmentCommitmentsPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentCommitmentUrl } from "src/RouteUrls";
import { commitmentStatusToTagTypeMapper } from "src/utils/mappers";
import { CreateCommitmentModal } from "./components/CreateCommitmentModal";

export function DevelopmentCommitmentsPage() {
  const metaQuery = useDevelopmentCommitmentsPageMetaDataQuery();
  const { openModal } = useModal();
  const [searchFilter, setSearchFilter] = useState<string>("");
  const filterDefs: FilterDefs<DevCommitmentFilter> = useMemo(() => {
    return {
      market: multiFilter({
        options: metaQuery.data?.markets || [],
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
      status: multiFilter({
        options: metaQuery.data?.commitmentStatuses || [],
        getOptionValue: ({ code }) => code,
        getOptionLabel: ({ name }) => name,
      }),
    };
  }, [metaQuery]);

  const { setFilter, filter } = usePersistedFilter<DevCommitmentFilter>({
    storageKey: "developmentCommitmentsFilter",
    filterDefs,
  });

  const query = useDevelopmentCommitmentsPageQuery({
    variables: { filter: { ...filter, search: searchFilter || undefined }, page: defaultPage },
  });

  const maybeFetchNextPage = useCallback(async () => {
    if (query.data?.developmentCommitmentsPage.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: { offset: query.data?.developmentCommitmentsPage.entities?.length, limit: defaultPage.limit },
        },
      });
    }
  }, [query]);

  return (
    <div>
      <PageHeader
        title="Development Commitments"
        right={
          <Button
            variant="secondary"
            label="Create Commitment"
            onClick={() => openModal({ content: <CreateCommitmentModal /> })}
          />
        }
      />
      <TableActions>
        <Filters<DevCommitmentFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <ScrollableContent virtualized>
        <QueryTable
          columns={columns}
          query={query}
          as="virtual"
          createRows={createRows}
          fallbackMessage="No development commitments found that matched given filters."
          rowStyles={createRowStyles()}
          style={{ allWhite: true, bordered: true }}
          sorting={{ on: "client", initial: ["lastEdited", "DESC"] }}
          infiniteScroll={{ onEndReached: maybeFetchNextPage }}
          stickyHeader
        />
      </ScrollableContent>
    </div>
  );
}

type Row = { kind: "header" } | { kind: "data"; id: string; data: DevelopmentCommitmentsPageDevCommFragment };

const columns: GridColumn<Row>[] = [
  column<Row>({
    header: "Commitment Name",
    data: (data) => data.name,
  }),
  column<Row>({
    header: "Market",
    data: (data) => data.market.name,
  }),
  column<Row>({
    header: "Trade Partner",
    data: (data) => data.tradePartner?.name,
  }),
  column<Row>({
    header: "Cost Codes",
    data: (data) => chipCell(data.costCodes.map((cc) => cc.number)),
  }),
  numericColumn<Row>({
    header: "Total Committed",
    data: (data) => priceCell({ valueInCents: data.committedInCents, dropZero: true }),
  }),
  dateColumn<Row>({
    id: "lastEdited",
    header: "Last Edited",
    data: (data) => dateCell(data.updatedAt),
  }),
  dateColumn<Row>({
    header: "Signed On",
    data: (data) => dateCell(data.executionDate),
  }),
  column<Row>({
    header: "Status",
    data: (data) => tagCell(commitmentStatusToTagTypeMapper[data.statusDetail.code], data.statusDetail.name),
  }),
];

function createRowStyles(): RowStyles<Row> {
  return { header: {}, data: { rowLink: ({ id }) => createDevelopmentCommitmentUrl(id) } };
}

function createRows(data: DevelopmentCommitmentsPageQuery | undefined): GridDataRow<Row>[] {
  return simpleDataRows(data?.developmentCommitmentsPage.entities);
}

type DevCommitmentFilter = {
  market?: Maybe<Array<Scalars["ID"]>>;
  status?: Maybe<Array<CommitmentStatus>>;
};
