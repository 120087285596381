import {
  AutoSaveIndicator,
  Chip,
  column,
  Css,
  GridColumn,
  GridDataRow,
  ScrollableContent,
  SelectField,
  simpleHeader,
  SimpleHeaderAndData,
  Tooltip,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { QueryTable } from "src/components";
import {
  AvailableTeamMembersFragment,
  DevelopmentTeamMembersQuery,
  useDevelopmentTeamMembersQuery,
  useUpdateDevelopmentProjectRoleMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams } from "src/routes/routesDef";
import { createDevelopmentsUrl, createDevelopmentTeamMembersUrl, createDevelopmentUrl } from "src/RouteUrls";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";

export function DevelopmentTeamMembers() {
  const { developmentId } = useParams<DevelopmentParams>();

  const query = useDevelopmentTeamMembersQuery({ variables: { developmentId } });
  const availableAssignees = query.data?.internalUsers || [];

  const columns = createColumns(developmentId, availableAssignees);
  const rowStyles = { header: {}, data: { cellCss: Css.py1.aic.$ } };

  return (
    <>
      <PageHeader
        title="Team Members"
        left={<AutoSaveIndicator />}
        breadcrumb={[
          { label: "All developments", href: createDevelopmentsUrl() },
          { label: query?.data?.development?.name ?? "...", href: createDevelopmentUrl(developmentId) },
          { label: "Team Members", href: createDevelopmentTeamMembersUrl(developmentId) },
        ]}
      />
      <ScrollableContent>
        <div css={Css.wPx(560).$}>
          <QueryTable
            query={query}
            createRows={createRows}
            columns={columns}
            rowStyles={rowStyles}
            stickyHeader
            style={{ allWhite: true, bordered: true }}
          />
        </div>
      </ScrollableContent>
    </>
  );
}

type MaybeTeamMember = {
  user: DevelopmentTeamMembersQuery["development"]["teamMembers"][0]["internalUser"] | undefined;
  role: DevelopmentTeamMembersQuery["projectRoleDetails"][0];
};
type Row = SimpleHeaderAndData<MaybeTeamMember>;

function createColumns(developmentId: string, availableAssignees: AvailableTeamMembersFragment[]): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => ({ content: "Role", colspan: 2 }),
      data: (teamMember) => (
        <div css={Css.df.fdr.w100.jcsb.aic.$}>
          <span>{teamMember.role.name}</span>
          {teamMember.user?.user.isActive === false && (
            <Tooltip title={`${teamMember.user?.name} is a deactivated user and should be reassigned`}>
              <Chip text="Deactivated" type="warning" />
            </Tooltip>
          )}
        </div>
      ),
    }),
    column<Row>({
      header: "",
      data: (teamMember) => (
        <TeamMemberSelector
          developmentId={developmentId}
          availableAssignees={availableAssignees}
          teamMember={teamMember}
        />
      ),
    }),
  ];
}

function createRows(data: DevelopmentTeamMembersQuery | undefined): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(data?.projectRoleDetails.map((role) => ({
      kind: "data" as const,
      id: role.code,
      data: {
        role,
        user: data?.development?.teamMembers?.find((tm) => tm?.projectRole.code === role.code)?.internalUser,
      },
    })) || []),
  ];
}

/**
 * Use a mini-component so that we can have a useState hook.
 *
 * I.e. so that the user's new assignee is used immediately. Ideally we wouldn't need this,
 * and could use an optimistic response, but the team member "entity" we're updating doesn't
 * have an `id` b/c to have a cache update to an immediate hit.
 */
function TeamMemberSelector(props: {
  developmentId: string;
  teamMember: MaybeTeamMember;
  availableAssignees: AvailableTeamMembersFragment[];
}) {
  const { developmentId, teamMember, availableAssignees } = props;
  const [updateProjectRole] = useUpdateDevelopmentProjectRoleMutation();
  const [teamMemberId, setTeamMemberId] = useState<string | undefined>(teamMember.user?.id);
  const isDisabled = teamMember.user?.user.isActive === false;
  const options = useMemo(() => {
    // Deactivated users aren't vectored through availableAssignees so the lookup will fail and the field
    // will appear unselected, even though the PTM is in fact assigned to a now-Deactivated user. So backdoor
    // them back into the Options array so it'll display.
    const maybeDisabledUser =
      isDisabled && teamMember.user ? (teamMember.user satisfies AvailableTeamMembersFragment) : undefined;
    return [...availableAssignees, maybeDisabledUser].compact();
  }, [availableAssignees, isDisabled, teamMember.user]);

  return (
    <SelectField
      compact
      labelStyle="hidden"
      data-testid="teamMember"
      label={teamMember.role.name}
      value={teamMemberId}
      // hide the undefined option when there is no assigned user to role, so it only shows user options
      unsetLabel={teamMemberId && " "}
      options={options}
      fieldDecoration={internalUserAvatar}
      getOptionMenuLabel={internalUserMenuLabel}
      onSelect={async (userId) => {
        setTeamMemberId(userId);
        await updateProjectRole({
          variables: { input: { developmentId, projectRole: teamMember.role.code, internalUserId: userId } },
        });
      }}
    />
  );
}
