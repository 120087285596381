import { multiFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useReadyPlanStatusFilterMetadataQuery } from "src/generated/graphql-types";

export function useReadyPlanStatusFilter() {
  const { data } = useReadyPlanStatusFilterMetadataQuery({ fetchPolicy: "cache-first" });
  const rpStatusFilter = useMemo(
    () =>
      multiFilter({
        options: data?.enumDetails.readyPlanStatus ?? [],
        getOptionValue: (o) => o.code,
        getOptionLabel: (o) => o.name,
      }),
    [data],
  );

  return { rpStatusFilter };
}
