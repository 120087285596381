import { Button, Checkbox, Css, ModalBody, ModalFooter, ModalHeader, Tag, Tooltip, useModal } from "@homebound/beam";
import { useState } from "react";
import { FormattedDate } from "src/components";
import { DesignUpdatesAvailable_PlanUpdateFragment, useAcceptDesignUpdatesMutation } from "src/generated/graphql-types";
import { renderListItem } from "src/routes/libraries/plan-package/version-history/components/VersionHistoryUtils";
import { pluralize } from "src/utils";

type DesignUpdatesAvailableButtonProps = {
  designPackageId: string;
  updates: DesignUpdatesAvailable_PlanUpdateFragment[];
  copyInProgress?: boolean;
};

/**
 * We have this enum on the backend, used as the return value for RPAV line item change types.
 * It’s not stored in the database or shared with the frontend, so we created this enum to mirror the backend version.
 */
export enum AggregateMemberVersionChangeType {
  ADDED = "added",
  UPDATED = "updated",
  REMOVED = "removed",
}

export function DesignUpdatesAvailableButton({
  copyInProgress,
  updates,
  designPackageId,
}: DesignUpdatesAvailableButtonProps) {
  const { openModal } = useModal();

  if (copyInProgress || updates.isEmpty) return null;

  return (
    <button
      data-testid="designUpdatesAvailableButton"
      onClick={() =>
        openModal({
          content: <DesignUpdatesAvailableModal updates={updates} designPackageId={designPackageId} />,
        })
      }
    >
      <Tooltip
        title={updates.map((u) => `${u.planVersion.readyPlan.name} - v${u.planVersion.version}`).join(", ")}
        placement="bottom"
      >
        <Tag
          type="caution"
          text={<div css={Css.ttn.smSb.$}>Updates available</div>}
          icon={"arrowFromBottom"}
          preventTooltip
        />
      </Tooltip>
    </button>
  );
}

type DesignUpdatesAvailableModalProps = DesignUpdatesAvailableButtonProps;

export function DesignUpdatesAvailableModal({ updates, designPackageId }: DesignUpdatesAvailableModalProps) {
  const [acceptDesignUpdates] = useAcceptDesignUpdatesMutation();
  const { closeModal } = useModal();
  const [acceptedUpdateIds, setAcceptedUpdateIds] = useState<string[]>(updates.map((u) => u.id));

  async function onAcceptUpdate() {
    await acceptDesignUpdates({
      variables: { input: { designPackageId, designPackagePlanUpdateIds: acceptedUpdateIds } },
    });
    closeModal();
  }

  return (
    <>
      <ModalHeader>Updates Available</ModalHeader>
      <ModalBody>
        <div css={Css.mb3.$}>
          Would you like to pull {pluralize(updates.length, "this update", "these updates")} into a new draft for you to
          review and edit?
        </div>
        <div css={Css.df.fdc.gap2.$}>
          {updates.map((u) => {
            const { newItemSlotCount, removedItemSlotCount, updatedItemSlotCount } = getTlivChangeCounts(u);
            return (
              <div key={u.id}>
                <div data-testid="planInfo" css={Css.sm.df.gap1.$}>
                  <Checkbox
                    label="plan-version"
                    checkboxOnly
                    withLabelElement={false}
                    onChange={() =>
                      acceptedUpdateIds.includes(u.id)
                        ? setAcceptedUpdateIds(acceptedUpdateIds.filter((id) => id !== u.id))
                        : setAcceptedUpdateIds(acceptedUpdateIds.concat(u.id))
                    }
                    selected={acceptedUpdateIds.includes(u.id)}
                  />
                  <span css={Css.smMd.$}>{u.planVersion.readyPlan.name} </span>
                  <span css={Css.gray700.$}>v{u.planVersion.version} - </span>
                  <span css={Css.gray700.$}>
                    <FormattedDate date={u.createdAt} dateFormatStyle="long" />
                  </span>
                </div>
                {u.planVersion.versionNotes && (
                  <div data-testid="versionNotes" css={Css.gray700.pl3.sm.$}>
                    "{u.planVersion.versionNotes}"
                  </div>
                )}
                <ul css={Css.m0.gray700.$}>
                  {renderListItem(newItemSlotCount, "Added", "item-slot")}
                  {renderListItem(removedItemSlotCount, "Removed", "item-slot")}
                  {renderListItem(updatedItemSlotCount, "Modified", "item-slot")}
                </ul>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="secondary" />
        <Button
          disabled={acceptedUpdateIds.isEmpty}
          label={`Accept ${pluralize(acceptedUpdateIds.length, "Update")}`}
          onClick={onAcceptUpdate}
        />
      </ModalFooter>
    </>
  );
}

function getTlivChangeCounts(update: DesignUpdatesAvailable_PlanUpdateFragment) {
  const placeholders = update.planVersion.tliVersions.filter((tliv) => tliv.identity.isPlaceholderMaterial);
  const newItemSlotCount = placeholders.count((tliv) => tliv.changeType === AggregateMemberVersionChangeType.ADDED);
  const removedItemSlotCount = placeholders.count(
    (tliv) => tliv.changeType === AggregateMemberVersionChangeType.REMOVED,
  );
  const updatedItemSlotCount = placeholders.count(
    (tliv) => tliv.changeType === AggregateMemberVersionChangeType.UPDATED,
  );

  return { newItemSlotCount, removedItemSlotCount, updatedItemSlotCount };
}
