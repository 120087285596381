import React, { createContext, Dispatch, SetStateAction, useState } from "react";

export type SideNavState = "hidden" | "collapse" | "expanded";

export type SideNavContextProps = {
  sideNavState: SideNavState;
  setSideNavState: Dispatch<SetStateAction<SideNavState>>;
};

const SideNavContext = createContext<SideNavContextProps>({
  sideNavState: "expanded",
  setSideNavState: () => {},
});

export function SideNavContextProvider(props: { children: React.ReactNode; navStorageKey?: string }) {
  const [sideNavState, setSideNavState] = useState<SideNavState>("expanded");
  return <SideNavContext.Provider value={{ setSideNavState, sideNavState }}>{props.children}</SideNavContext.Provider>;
}

export function useSideNavContext() {
  return React.useContext(SideNavContext);
}
