import {
  Button,
  column,
  dateColumn,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  TabsWithContent,
  TabWithContent,
  useModal,
  usePersistedFilter,
  useTestIds,
} from "@homebound/beam";
import { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dateCell, priceCell, tagCell } from "src/components";
import {
  LotType,
  ProjectFeature,
  ProjectInvoicesPageInvoiceFragment,
  useInvoiceScheduleQuery,
  useProjectInvoicesPageQuery,
} from "src/generated/graphql-types";
import {
  mapToProjectStagesFilter,
  SingleStageFilter,
  useProjectStageSingleFilter,
} from "src/hooks/useProjectStageSingleFilter";
import { useStripStage } from "src/hooks/useStripStage";
import { useTabParam } from "src/hooks/useTabParam";
import { invoiceStatusToTagType } from "src/routes/invoices/InvoicesPage";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { ProjectParams } from "src/routes/routesDef";
import { addEntityParam, createInvoiceUrl } from "src/RouteUrls";
import { hasData, queryResult, renderLoadingOrError } from "src/utils/queryResult";
import { InvoiceScheduleTab } from "../homeowner-contracts/InvoiceScheduleTab";
import { InvoiceProjectStageModal } from "./InvoiceProjectStageModal";

export function ProjectInvoicesPage() {
  useStripStage();
  const { features } = useProjectContext();
  const history = useHistory();
  const { openModal } = useModal();
  const { projectId } = useParams<ProjectParams>();
  const { latestActiveStage } = useProjectContext();
  const { filterDefs } = useProjectStageSingleFilter(latestActiveStage);
  const tid = useTestIds({}, "invoices");
  const { setFilter, filter } = usePersistedFilter<SingleStageFilter>({
    storageKey: "changeEventsFilter",
    filterDefs,
  });

  const [tab, setTab] = useTabParam("invoices");
  const query = useInvoiceScheduleQuery({ variables: { projectId } });

  const invoiceV2 = features.includes(ProjectFeature.InvoiceV2);

  return (
    <Fragment>
      <PageHeader
        title="Invoices"
        right={
          <Button
            label="Create Invoice"
            variant="secondary"
            onClick={
              !invoiceV2
                ? createInvoiceUrl(projectId, addEntityParam)
                : () =>
                    openModal({
                      content: (
                        <InvoiceProjectStageModal
                          projectId={projectId}
                          onSave={(projectStage) => {
                            history.push(createInvoiceUrl(projectId, addEntityParam, projectStage));
                          }}
                        />
                      ),
                    })
            }
            {...tid.addNew}
          />
        }
      />

      {queryResult(query, {
        data(data) {
          if (!!data.project.invoiceSchedule) {
            const tabs: TabWithContent[] = [
              {
                name: "Invoices",
                value: "invoices",
                render: () => (
                  <Fragment>
                    <TableActions>
                      <Filters<SingleStageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
                    </TableActions>
                    <InvoicesTable projectId={projectId} filter={filter} />
                  </Fragment>
                ),
              },
              {
                name: "Invoice Schedule",
                value: "invoice-schedule",
                render: () => <InvoiceScheduleTab invoiceSchedule={data.project.invoiceSchedule!} />,
              },
            ];

            return <TabsWithContent selected={tab} onChange={setTab} tabs={tabs} />;
          }
          return (
            <Fragment>
              <TableActions>
                <Filters<SingleStageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              </TableActions>
              <InvoicesTable projectId={projectId} filter={filter} />
            </Fragment>
          );
        },
      })}
    </Fragment>
  );
}

type InvoicesTableProps = {
  projectId: string;
  filter: SingleStageFilter;
};

function InvoicesTable({ projectId, filter }: InvoicesTableProps) {
  const query = useProjectInvoicesPageQuery({
    variables: { projectId, stagesFilter: mapToProjectStagesFilter(filter) },
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const invoices = query.data.project.stages.flatMap((s) => s.invoices);

  return (
    <ScrollableContent>
      <GridTable
        rowStyles={createRowStyles(projectId)}
        columns={columns}
        rows={createRows(invoices)}
        fallbackMessage="There are no invoices for the selected stage(s)."
        stickyHeader
        style={{ rowHeight: "fixed", bordered: true, allWhite: true }}
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type TotalsRow = {
  kind: "totals";
  data: { invoiced: number; receivedToDate: number; balanceOwed: number };
};
type InvoiceRow = { kind: "invoice"; data: ProjectInvoicesPageInvoiceFragment };
type Row = HeaderRow | TotalsRow | InvoiceRow;

const columns: GridColumn<Row>[] = [
  column<Row>({
    header: "Title",
    invoice: (data) => data.title,
    totals: () => emptyCell,
    mw: "160px",
  }),
  numericColumn<Row>({
    header: "Invoice #",
    invoice: (data) => `${data.invoiceNumber}`,
    totals: () => emptyCell,
  }),
  column<Row>({
    header: "Stage",
    invoice: ({ projectStage }) => projectStage.stage.name,
    totals: () => emptyCell,
    w: "160px",
  }),
  numericColumn<Row>({
    header: "Customer",
    invoice: (data) =>
      data.project.lotType?.code === LotType.Bool
        ? data.project.customer?.name
        : data.project.primaryHomeowner?.fullName,
    totals: () => emptyCell,
  }),
  column<Row>({
    header: "Status",
    totals: () => emptyCell,
    invoice: (data) =>
      data.isHistorical
        ? tagCell("info", "HISTORICAL")
        : tagCell(invoiceStatusToTagType[data.status.code], data.status.name),
    w: "160px",
  }),
  dateColumn<Row>({
    header: "Invoice Date",
    invoice: (data) => dateCell(data.invoiceDate),
    totals: () => emptyCell,
  }),
  dateColumn<Row>({
    header: "Due Date",
    invoice: (data) => dateCell(data.dueDate),
    totals: () => emptyCell,
  }),
  numericColumn<Row>({
    header: "Invoiced",
    invoice: (data) => priceCell({ valueInCents: data.amountInCents }),
    totals: (data) => priceCell({ valueInCents: data.invoiced }),
  }),
  numericColumn<Row>({
    header: "Received To Date",
    invoice: (data) => priceCell({ valueInCents: data.amountPaidInCents }),
    totals: (data) => priceCell({ valueInCents: data.receivedToDate }),
  }),
  numericColumn<Row>({
    header: "Balance Owed",
    invoice: (data) => priceCell({ valueInCents: data.balanceInCents }),
    totals: (data) => priceCell({ valueInCents: data.balanceOwed }),
  }),
  dateColumn<Row>({
    header: "Paid Date",
    invoice: (data) => dateCell(data.paidDate),
    totals: () => emptyCell,
  }),
];

function createRowStyles(projectId: string): RowStyles<Row> {
  return {
    invoice: { rowLink: ({ data: { id } }) => createInvoiceUrl(projectId, id) },
  };
}

function createRows(invoices: ProjectInvoicesPageInvoiceFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    {
      kind: "totals" as const,
      id: "totals",
      data: {
        invoiced: invoices.sum(({ amountInCents }) => amountInCents),
        receivedToDate: invoices.sum(({ amountPaidInCents }) => amountPaidInCents),
        balanceOwed: invoices.sum(({ balanceInCents }) => balanceInCents),
      },
    },
    ...invoices.map((invoice) => ({ kind: "invoice" as const, id: invoice.id, data: invoice })),
  ];
}
