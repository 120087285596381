import { Button, useModal } from "@homebound/beam";
import { useCallback } from "react";
import { ScheduleDetailsFragment, ScheduleStatus, useSaveScheduleStatusMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

type StartOrCompleteStageButtonProps = {
  schedule: ScheduleDetailsFragment;
  refetchQueries: string[];
};

export function StartOrCompleteStageButton({ schedule, refetchQueries }: StartOrCompleteStageButtonProps) {
  const { openModal } = useModal();
  const [saveScheduleStatus] = useSaveScheduleStatusMutation({ refetchQueries });

  const { label, nextStatus, potentialOperation } =
    schedule.status === ScheduleStatus.Draft
      ? { label: "Start Stage", nextStatus: ScheduleStatus.Active, potentialOperation: schedule.canPublishSchedule }
      : {
          label: "Complete Stage",
          nextStatus: ScheduleStatus.Completed,
          potentialOperation: schedule.canCompleteSchedule,
        };

  const onClick = useCallback(async () => {
    schedule.status === ScheduleStatus.Draft
      ? openModal({
          content: (
            <ConfirmationModal
              title="Start Stage"
              onConfirmAction={() =>
                saveScheduleStatus({
                  variables: { id: schedule.id, status: nextStatus },
                })
              }
              label="Start Stage"
              confirmationMessage="Starting this stage will save the plan schedule data. Are you sure you want to start the stage?"
            />
          ),
        })
      : await saveScheduleStatus({
          variables: { id: schedule.id, status: nextStatus },
        });
  }, [saveScheduleStatus, schedule, nextStatus, openModal]);

  // Complete is the terminal state, don't render the button in that case
  if (schedule.status === ScheduleStatus.Completed) {
    return null;
  }

  return (
    <Button
      data-testid="startOrCompleteStage"
      label={label}
      disabled={
        potentialOperation?.allowed === false
          ? potentialOperation.disabledReasons.map(({ message }) => message).join(",")
          : false
      }
      onClick={onClick}
    />
  );
}
