import {
  Accordion,
  CollapseToggle,
  column,
  condensedStyle,
  Css,
  GridColumn,
  GridStyle,
  GridTable,
  OnRowSelect,
  SelectToggle,
  useComputed,
  useGridTableApi,
  useTestIds,
} from "@homebound/beam";
import { useMemo } from "react";
import { Filters_ItemLocationFragment, ItemTemplateItemVersionFilter, LocationType } from "src/generated/graphql-types";
import { maybeSplitRoomNumber } from "src/utils";

type LocationFilterProps = {
  label: string;
  delta?: boolean;
  options?: Filters_ItemLocationFragment[];
  collapsed?: boolean;
  topBorder?: boolean;
  filter: ItemTemplateItemVersionFilter;
  setFilter: React.Dispatch<React.SetStateAction<ItemTemplateItemVersionFilter>>;
};

export function LocationsFilterSelectTree(props: LocationFilterProps) {
  const { label, options = [], collapsed, topBorder = false, filter, setFilter } = props;
  const filterField = "locationInPath";

  const selected = options
    // Map parents so GridTable updates there selected state correctly
    .flatMap(({ id, parents }) => [{ id }, ...parents.map(({ id }) => ({ id }))])
    .filter(({ id }) => {
      return (filter[filterField] as any[])?.includes(id);
    })
    .map(({ id }) => id);

  const api = useGridTableApi<LocationRow>();
  // FIXME: This is not good. Should be fixed alongside other cleanup in sc-55848
  const selectedRowCount = useComputed(() => {
    const sRowCount = api.getSelectedRows().length;
    return sRowCount === 0 ? selected.length : sRowCount;
  }, [api, selected]);

  const tid = useTestIds(props, "LocationsFilterSection");

  const rows = useMemo(() => {
    const rowsWithChildren: LocationRow[] = [];

    options.forEach((option) => {
      const { parents, ...restOption } = option;

      // Build path hierarchy of this location
      const rootToLeaf = [restOption, ...parents]
        .reverse()
        // Filter out any Scope or Assembly Locations.
        // The ITIs will still be picked up in the `locationInPath` filter
        .filter((l) => l.type && l.type.code !== LocationType.Scope);

      // Loop through each level in hierarchy by chaining through the children
      let currentLocationInHierarchy = rowsWithChildren;
      rootToLeaf.forEach(({ id, name }) => {
        currentLocationInHierarchy = getOrCreateLocationRow(
          currentLocationInHierarchy,
          id,
          name,
          selected.includes(id),
        ).children!;
      });
    });

    return rowsWithChildren;
  }, [options, selected]);

  const columns = useMemo(() => nestedLocationColumns(), []);

  const onRowSelect: OnRowSelect<LocationRow> = useMemo(() => {
    return {
      location: ({ id }, isSelected) => {
        setFilter((filter) => {
          const newFilter = {
            ...filter,
            [filterField]: isSelected
              ? [...(filter[filterField] ?? []), id]
              : filter[filterField]?.filter((v) => v !== id),
          };

          if (newFilter[filterField]?.isEmpty) {
            delete newFilter[filterField];
          }

          return newFilter;
        });
      },
    };
  }, [setFilter]);

  return (
    <Accordion
      title={
        <div css={Css.df.jcsb.$} {...tid[`${label.replaceAll(" ", "")}_label`]}>
          <div css={Css.xsSb.$}>{label}</div>
          {selectedRowCount > 0 && <div css={Css.xs.gray700.$}>{selectedRowCount} Selected</div>}
        </div>
      }
      bottomBorder
      topBorder={topBorder}
      defaultExpanded={!collapsed}
      {...tid[label.replaceAll(" ", "")]}
      xss={{ paddingBottom: "8px" }}
    >
      <GridTable<LocationRow>
        api={api}
        onRowSelect={onRowSelect}
        style={gridLocationFilterStyles}
        columns={columns}
        rows={rows}
      />
    </Accordion>
  );
}

type LocationRow = {
  id: string;
  name: string;
  kind: "location";
  initSelected: boolean;
  data: { id: string; name: string };
  children?: LocationRow[];
};

function getOrCreateLocationRow(parent: LocationRow[], id: string, name: string, selected: boolean): LocationRow {
  let node = parent.find((child) => child.id === id);
  if (!node) {
    node = { id, name, kind: "location", data: { id, name }, initSelected: selected, children: [] };
    // Insert the node in sorted order based on ROOM number when present
    const [nodeName, maybeNodeRoomNumber] = maybeSplitRoomNumber(node.name);

    const index = parent.findIndex((item) => {
      const [itemName, maybeItemRoomNumber] = maybeSplitRoomNumber(item.name);
      if (maybeItemRoomNumber && maybeNodeRoomNumber) {
        return maybeItemRoomNumber > maybeNodeRoomNumber;
      } else {
        return itemName.localeCompare(nodeName) > 0;
      }
    });

    if (index === -1) {
      parent.push(node);
    } else {
      parent.splice(index, 0, node);
    }
  }
  return node;
}
const gridLocationFilterStyles: GridStyle = {
  ...condensedStyle,
  cellCss: { ...condensedStyle["cellCss"], ...Css.pl0.xs.hPx(24).bsh0.$ },
  levels: (level) => ({ rowIndent: level > 0 ? 12 * level : undefined }),
  rowHoverColor: "none",
};

export type BasicFilterOptions = {
  id: string;
  name: string;
  filterField?: keyof ItemTemplateItemVersionFilter;
  isBase?: boolean;
};

type LocationFilterOptions = Filters_ItemLocationFragment & {
  filterField?: keyof ItemTemplateItemVersionFilter;
  isBase?: boolean;
};

export type FilterSectionData = {
  label: string;
  delta?: boolean;
  options?: (LocationFilterOptions | BasicFilterOptions)[];
  filterField: keyof ItemTemplateItemVersionFilter;
};

export type FilterLocationSectionData = {
  label: "Location";
  options: Filters_ItemLocationFragment[];
};

export function isLocationFilterSection(
  section: FilterSectionData | FilterLocationSectionData,
): section is FilterLocationSectionData {
  return section.label === "Location";
}

function nestedLocationColumns(): GridColumn<LocationRow>[] {
  return [
    column<LocationRow>({
      location: ({ name }, { row }) => (
        <div css={Css.df.aic.gap1.fg1.$}>
          <SelectToggle id={row.id} />
          <span css={Css.fg1.$}>{name}</span>
          <CollapseToggle row={row} compact />
        </div>
      ),
    }),
  ];
}
