import { IconButton } from "@homebound/beam";
import { useCallback } from "react";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { setTaskPaneState } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";

type TaskChecklistItemsStatusProps = {
  taskId: string;
  checklistComplete: boolean;
};

export const TaskChecklistItemsStatus = ({ checklistComplete, taskId }: TaskChecklistItemsStatusProps) => {
  const { dispatch } = useScheduleStore();

  const onChecklistClick = useCallback(() => {
    dispatch(
      setTaskPaneState({
        taskPaneId: taskId,
        tab: "details",
        scrollIntoViewType: "checklistItems",
      }),
    );
  }, [dispatch, taskId]);

  return (
    <IconButton icon={checklistComplete ? "checklistComplete" : "checklistNotComplete"} onClick={onChecklistClick} />
  );
};
