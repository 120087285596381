import { column, Css, emptyCell, GridColumn, GridDataRow, GridTable, simpleHeader, Tag } from "@homebound/beam";
import { Link } from "react-router-dom";
import { dateCell, Price } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import { BudgetSuperDrawer_BillsFragment, BudgetSuperDrawer_ExpenseFragment } from "src/generated/graphql-types";
import { createProjectExpensesUrl } from "src/RouteUrls";
import { sum } from "src/utils";

export type BudgetBillsTableProps = {
  bills: BudgetSuperDrawer_BillsFragment[];
  expenses: BudgetSuperDrawer_ExpenseFragment[];
  projectId: string;
  projectItemIds: string[];
};

/** Bills table displays all actuals (bills and reallocated direct expenses) */
export function BudgetBillsTable(props: BudgetBillsTableProps) {
  const { bills, expenses, projectId, projectItemIds } = props;
  const rows = createRows(bills, expenses, projectItemIds);
  const columns = createColumns(projectId, projectItemIds);

  return (
    <div>
      <div css={Css.smSb.h3.mb2.$} data-testid="title">
        Trade Paid
      </div>
      <GridTable id="bills" columns={columns} rows={rows} />
    </div>
  );
}

function createRows(
  bills: BudgetSuperDrawer_BillsFragment[],
  expenses: BudgetSuperDrawer_ExpenseFragment[],
  projectItemIds: string[],
): GridDataRow<Row>[] {
  const perItemsBilled = calcPerItem(bills, expenses, projectItemIds, "billed");
  const perItemsPaid = calcPerItem(bills, expenses, projectItemIds, "paid");
  return [
    simpleHeader,
    ...bills.map((b) => ({ kind: "bill" as const, id: b.id, data: b })),
    ...expenses.map((e) => ({ kind: "expense" as const, id: e.id, data: e })),
    {
      kind: "totals" as const,
      id: "total",
      data: {
        billed: sum(
          bills.sum(({ billedInCents, isTradePartnerCredit }) =>
            isTradePartnerCredit ? -billedInCents : billedInCents,
          ),
          expenses.sum(({ amountInCents }) => amountInCents),
        ),
        perItemsBilled,
        perItemsPaid,
        perItemsBalance: perItemsBilled - perItemsPaid,
      },
    },
  ];
}

function createColumns(projectId: string, projectItemIds: string[]): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Bill",
      // Link to Bill by id, or to all expenses in the project
      bill: (data) => <Link to={data.blueprintUrl.path}>{data.tradePartnerNumber}</Link>,
      expense: () => <Link to={createProjectExpensesUrl(projectId)}>Expense</Link>,
      totals: "Total",
      w: "100px",
    }),
    column<Row>({
      header: "Status",
      bill: (data) => {
        const [billType, text] = billStatusTagMapper(data);
        return <Tag type={billType} text={text} />;
      },
      expense: "Paid",
      totals: emptyCell,
      w: "120px",
    }),
    column<Row>({
      header: "Trade",
      bill: (data) => data.tradePartner.name,
      expense: (data) => `${data.intacctVendorName} - ${data.description}`,
      totals: emptyCell,
      w: "190px",
    }),
    column<Row>({
      header: "Due Date",
      bill: (data) => dateCell(data.dueDate),
      expense: (data) => dateCell(data.expenseDate),
      totals: emptyCell,
      w: "100px",
    }),
    column<Row>({
      header: "Billed",
      bill: (b) => <Price valueInCents={b.isTradePartnerCredit ? -b.billedInCents : b.billedInCents} />,
      expense: (e) => <Price valueInCents={e.amountInCents} />,
      totals: (t) => <Price valueInCents={t.billed} />,
      w: "100px",
    }),
    column<Row>({
      header: "Per Item Billed",
      bill: (b) => <Price valueInCents={calcPerItem([b], [], projectItemIds, "billed")} />,
      expense: (e) => <Price valueInCents={calcPerItem([], [e], projectItemIds, "billed")} />,
      totals: (t) => <Price valueInCents={t.perItemsBilled} />,
      w: "100px",
    }),
    column<Row>({
      header: "Per Item Paid",
      bill: (b) => <Price valueInCents={calcPerItem([b], [], projectItemIds, "paid")} />,
      expense: (e) => <Price valueInCents={calcPerItem([], [e], projectItemIds, "paid")} />,
      totals: (t) => <Price valueInCents={t.perItemsPaid} />,
      w: "120px",
    }),
    column<Row>({
      header: "Per Item Balance",
      bill: (b) => {
        const billed = calcPerItem([b], [], projectItemIds, "billed");
        const paid = calcPerItem([b], [], projectItemIds, "paid");
        return <Price valueInCents={billed - paid} />;
      },
      expense: (e) => {
        const billed = calcPerItem([], [e], projectItemIds, "billed");
        const paid = calcPerItem([], [e], projectItemIds, "paid");
        return <Price valueInCents={billed - paid} />;
      },
      totals: (t) => <Price valueInCents={t.perItemsBalance} />,
      w: "390px",
    }),
  ];
}

type Row = { kind: "header" } | BillsRow | ExpenseRow | TotalRow;

type BillsRow = {
  kind: "bill";
  id: string;
  data: BudgetSuperDrawer_BillsFragment;
};

type ExpenseRow = {
  kind: "expense";
  id: string;
  data: BudgetSuperDrawer_ExpenseFragment;
};

type TotalRow = {
  kind: "totals";
  data: {
    billed: number;
    perItemsBilled: number;
    perItemsPaid: number;
    perItemsBalance: number;
  };
};

function calcPerItem(
  bills: BudgetSuperDrawer_BillsFragment[],
  expenses: BudgetSuperDrawer_ExpenseFragment[],
  projectItemIds: string[],
  stat: "billed" | "paid",
): number {
  return sum(
    bills
      .flatMap((b) => b.lineItems)
      .filter((bli) => projectItemIds.includes(bli.projectItem.id))
      .sum((bli) => {
        const amount = stat === "billed" ? bli.amountInCents : bli.paidInCents;
        return bli.bill.isTradePartnerCredit ? -amount : amount;
      }),
    expenses
      .flatMap((e) => e.allocations)
      .filter((ea) => projectItemIds.includes(ea.projectItem.id))
      .sum((ea) => (stat === "billed" ? ea.amountInCents : ea.paidAmountInCents)),
  );
}
