import {
  BoundMultiSelectField,
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  HasIdAndName,
  ModalBody,
  ModalFooter,
  ModalHeader,
  multiFilter,
  ScrollableContent,
  ScrollableParent,
  selectColumn,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Maybe } from "graphql/jsutils/Maybe";
import { useCallback, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  AddItemOptionFragment,
  AddScopeTemplateItemsQuery,
  CostCodeFragment,
  CostType,
  CostTypeDetail,
  SaveItemTemplateItemVersionsInput,
  ScopeTemplateAddItemFragment,
  useAddScopeTemplateItemsQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { NotFound } from "src/routes/NotFound";
import { ItemTemplateApi } from "src/routes/settings/itemTemplates/api/ItemTemplateApi";
import { groupBy, isDefined, pluralize, queryResult, safeEntries } from "src/utils";

type AddScopeTemplateItemsModalProps = { itApi: ItemTemplateApi };

export function AddScopeTemplateItemsModal({ itApi }: AddScopeTemplateItemsModalProps) {
  const query = useAddScopeTemplateItemsQuery({
    variables: { templateId: itApi.templateId },
    fetchPolicy: "cache-first",
  });
  return queryResult(query, {
    data: ({ itemTemplate, ...data }) => {
      if (!itemTemplate) return <NotFound />;
      return <AddScopeTemplateItemsModalContent itemTemplate={itemTemplate} itApi={itApi} {...data} />;
    },
  });
}

type AddScopeTemplateItemsModalContentProps = AddScopeTemplateItemsModalProps & {
  items: ScopeTemplateAddItemFragment[];
  locations: AddScopeTemplateItemsQuery["locations"];
  costTypes: AddScopeTemplateItemsQuery["costTypes"];
  itemTemplate: AddScopeTemplateItemsQuery["itemTemplate"];
};

function AddScopeTemplateItemsModalContent(props: AddScopeTemplateItemsModalContentProps) {
  const { items, locations, costTypes, itemTemplate, itApi } = props;

  const { closeModal } = useModal();
  const tableApi = useGridTableApi<Row>();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [filter, setFilter] = useState<Filter>({});
  const filterDefs = useMemo(() => {
    const allCostCodes = items.map((item) => item.costCode).filter((c) => !!c);
    const costCodes = [...new Map(allCostCodes?.map((costCode) => [costCode!.id, costCode])).values()];
    const costCodeId = multiFilter({
      options: costCodes,
      getOptionValue: (costCode) => costCode.id,
      getOptionLabel: (costCode) => costCode.displayName,
    });

    return { costCodeId };
  }, [items]);

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: {
        templateId: itApi.templateId,
        itemTemplateItemVersions: items.map((i) => ({
          id: i.id,
          itemId: i.id,
          locationIds: [locations.find((l) => l.isWholeHouse)?.id].compact(),
          costTypes: [] as CostType[],
          elevationIds: [] as string[],
          specOptionIds: [] as string[],
          baseOption: [] as string[],
          costCode: i.costCode,
          displayName: i.displayName,
          unitOfMeasure: i.unitOfMeasure.id,
        })),
      },
    },
  });

  const selectedItemTemplateItems = useComputed(() => tableApi.getSelectedRowIds("itemTemplateItem"), [tableApi]);

  const formValid = useComputed(() => {
    const selectedItemForms = formState.value.itemTemplateItemVersions.filter((i) =>
      selectedItemTemplateItems.includes(i.id),
    );

    const allItemsValid = selectedItemForms.every(
      (itemForm) =>
        itemForm.baseOption?.nonEmpty &&
        itemForm.specOptionIds?.nonEmpty &&
        itemForm.locationIds?.nonEmpty &&
        itemForm.costTypes?.nonEmpty &&
        itemForm.elevationIds?.nonEmpty,
    );

    return allItemsValid;
  }, [formState.value.itemTemplateItemVersions, selectedItemTemplateItems]);

  type AddItemOption = AddItemOptionFragment & HasIdAndName;
  const specOptions = useMemo(() => {
    // Using `as any` because AddItemsOption requires a string for the id field, but we're using null.
    const allOption: AddItemsOption = { id: allSpecsOptionId, name: "All Spec Levels" };

    const specOptions =
      itemTemplate.readyPlan?.options
        .filter((o) => o.active && o.type.isSpecLevel)
        .map((o) => ({
          ...o,
          name: o.shortName ? `${o.displayName} (${o.shortName})` : o.displayName,
        })) ?? [];

    return [allOption, ...specOptions.sortByKey("id").reverse()];
  }, [itemTemplate]);

  const [elevationOptions, nonElevationOptions] = useMemo(() => {
    // Using `as any` because AddItemsOption requires a string for the id field, but we're using null.
    const baseOption: AddItemsOption = { id: baseHouseOptionId, name: "Base House" };
    const allOption: AddItemsOption = { id: allElevationsOptionId, name: "All Elevations" };
    const defaultOptions: [AddItemOption[], AddItemOption[]] = [[], []];

    const [elevationOptions, nonElevationOptions] =
      itemTemplate.readyPlan?.options
        .filter((o) => o.active)
        .map((o) => ({
          ...o,
          name: o.shortName ? `${o.displayName} (${o.shortName})` : o.displayName,
        }))
        .partition((o) => o.type.isElevation) ?? defaultOptions;
    const [exteriorSchemeOptions, nonElevationNonExteriorOptions] = nonElevationOptions.partition(
      (rpo) => rpo.type.isExteriorPalette,
    );
    const otherOptions = nonElevationNonExteriorOptions.filter((rpo) => !rpo.type.isSpecLevel);

    // Create a sort of,..
    // { id: globalOption.id , name: rpo.name, rpoIds: [rpo.id,...] }
    const groupedExteriorSchemeOptions = safeEntries(exteriorSchemeOptions.groupBy((rpo) => rpo.globalOption.id));

    return [
      [allOption, ...elevationOptions.sortByKey("name")],
      [
        baseOption,
        ...[
          ...groupedExteriorSchemeOptions.map(([id, options]) => ({
            id,
            name: options.first!.displayName,
            rpoIds: options.map((rpo) => rpo.id),
          })),
          ...otherOptions.map((rpo) => ({
            id: rpo.id,
            name: rpo.displayName,
            rpoIds: [rpo.id],
          })),
        ].sortByKey("name"),
      ],
    ];
  }, [itemTemplate]);

  const columns = useMemo(
    () => createColumns(locations, costTypes, elevationOptions, specOptions, nonElevationOptions, tableApi),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locations, costTypes, elevationOptions, specOptions],
  );

  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rows = useMemo(() => createRows(formState, filter), [filter]);
  // const [saveItemTemplateItems] = useSaveItemTemplateItemVersionsMutation();
  const onSave = useCallback(
    async () => {
      const input = mapToInput(
        formState.value,
        selectedItemTemplateItems,
        itemTemplate.readyPlan?.options ?? [],
        nonElevationOptions,
      );
      await itApi.addItiv(input.itemTemplateItemVersions);
      closeModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itApi, formState, selectedItemTemplateItems],
  );

  return (
    <>
      <ModalHeader>
        <div>Add items</div>
      </ModalHeader>
      <ModalBody virtualized>
        <ScrollableParent xss={Css.h100.pr3.$}>
          <p css={Css.base.gray700.mb3.$}>
            Start by choosing where to add the selected items, then select the items you’d like to add.
          </p>
          <TableActions>
            <div css={Css.df.aic.gap2.$}>
              <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              <span css={Css.smMd.$}>
                {selectedItemTemplateItems.length} selected {pluralize(selectedItemTemplateItems.length, "item")}
              </span>
            </div>
            <SearchBox onSearch={setSearchFilter} />
          </TableActions>
          <ScrollableContent virtualized>
            <GridTable
              as="virtual"
              rows={rows}
              columns={columns}
              filter={searchFilter}
              stickyHeader
              fallbackMessage="No items found"
              style={{ grouped: true, inlineEditing: true, rowHeight: "fixed" }}
              api={tableApi}
              sorting={{ on: "client" }}
            />
          </ScrollableContent>
        </ScrollableParent>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Add items"
          onClick={async () => {
            await onSave();
          }}
          disabled={!selectedItemTemplateItems.length || !formValid || !formState.valid}
        />
      </ModalFooter>
    </>
  );
}

type HeaderRow = { kind: "header"; id: string };
type CostCodeRow = { kind: "costCode"; data: FormState["itemTemplateItemVersions"]["rows"]; id: string };
type ItemTemplateItemRow = {
  kind: "itemTemplateItem";
  data: FormState["itemTemplateItemVersions"]["rows"][0];
  id: string;
};
type Row = HeaderRow | CostCodeRow | ItemTemplateItemRow;

function createRows(formState: FormState, filter?: Filter): GridDataRow<Row>[] {
  const itemTemplateItems = (formState.itemTemplateItemVersions.rows || []).filter((iti) =>
    filter?.costCodeId ? filter.costCodeId?.includes(iti.costCode.value.id) : true,
  );
  const costCodes = groupBy(itemTemplateItems, (iti) => iti.costCode.value.id);

  return [
    { kind: "header", id: "header", data: formState.itemTemplateItemVersions },
    ...Object.entries(costCodes).map(([costCode, children], idx) => {
      return {
        kind: "costCode" as const,
        id: costCode,
        data: children,
        // Expand everything so that it's easy to search + immediately type into the child
        initCollapsed: false,
        children: children.map((iti, i) => {
          return {
            kind: "itemTemplateItem" as const,
            data: iti,
            id: iti.value.id,
          };
        }),
      };
    }),
  ];
}

function createColumns(
  locations: HasIdAndName[],
  costTypeOptions: Omit<CostTypeDetail, "tradePartnerName">[],
  elevationOptions: AddItemsOption[],
  specOptions: AddItemsOption[],
  nonElevationOptions: AddItemsOption[],
  tableApi: GridTableApi<Row>,
): GridColumn<Row>[] {
  // method for selecting a row that was changed by a dropdown
  const selectRow = (value: string[], id: string) => {
    // select row for adding
    if (value.length) {
      tableApi.selectRow(id);
    }
  };

  return [
    collapseColumn({ w: "48px" }),
    selectColumn({ w: "32px" }),
    column<Row>({
      header: "Item",
      costCode: (items) => ({ content: items[0].costCode.value.displayName, colspan: 4 }),
      itemTemplateItem: ({ displayName }) => displayName.value,
      mw: "120px",
    }),
    column<Row>({
      clientSideSort: false,
      header: "Base/Option",
      itemTemplateItem: ({ baseOption, id }) => (
        <BoundMultiSelectField
          options={nonElevationOptions}
          field={baseOption}
          compact
          nothingSelectedText="Base House and Options"
          onSelect={(value) => {
            baseOption.set(value);
            selectRow(value, id.value);
          }}
        />
      ),
      costCode: emptyCell,
      w: 1,
    }),
    column<Row>({
      clientSideSort: false,
      header: "Spec Level",
      itemTemplateItem: ({ specOptionIds, id }) => (
        <BoundMultiSelectField
          field={specOptionIds}
          options={specOptions}
          nothingSelectedText="Add Spec Level"
          onSelect={(value) => {
            specOptionIds.set(value);
            selectRow(value, id.value);
          }}
        />
      ),
      costCode: emptyCell,
      w: 1,
    }),
    column<Row>({
      clientSideSort: false,
      header: "Elevation",
      itemTemplateItem: ({ elevationIds, id }) => (
        <BoundMultiSelectField
          field={elevationIds}
          options={elevationOptions}
          nothingSelectedText="Add Elevation"
          onSelect={(value) => {
            elevationIds.set(value);
            selectRow(value, id.value);
          }}
        />
      ),
      costCode: emptyCell,
      w: 1,
    }),
    column<Row>({
      clientSideSort: false,
      header: "Location",
      itemTemplateItem: ({ locationIds, id }) => {
        return {
          content: () => (
            <BoundMultiSelectField
              field={locationIds}
              options={locations}
              compact
              nothingSelectedText="Add location"
              onSelect={(value) => {
                locationIds.set(value);
                selectRow(value, id.value);
              }}
            />
          ),
        };
      },
      costCode: emptyCell,
      w: 1,
    }),
    column<Row>({
      clientSideSort: false,
      header: "Cost type",
      itemTemplateItem: ({ costTypes, id }) => {
        return {
          content: () => (
            <BoundMultiSelectField
              field={costTypes}
              options={costTypeOptions}
              getOptionValue={(o) => o.code!}
              getOptionLabel={(o) => o.name!}
              compact
              nothingSelectedText="Add cost type"
              onSelect={(value) => {
                costTypes.set(value);
                selectRow(value, id.value);
              }}
            />
          ),
        };
      },
      costCode: emptyCell,
      w: 1,
    }),
  ];
}

type FormInput = {
  templateId: string;
  itemTemplateItemVersions: {
    id: string;
    itemId: string;
    locationIds: Maybe<string[]>;
    costTypes: Maybe<CostType[]>;
    elevationIds: Maybe<string[]>;
    specOptionIds: Maybe<string[]>;
    baseOption: Maybe<string[]>;
    costCode: CostCodeFragment;
    displayName: string;
    unitOfMeasure: string;
  }[];
};
type FormState = ObjectState<FormInput>;
const formConfig: ObjectConfig<FormInput> = {
  templateId: { type: "value" },
  itemTemplateItemVersions: {
    type: "list",
    config: {
      id: { type: "value" },
      itemId: { type: "value" },
      locationIds: { type: "value", rules: [required] },
      elevationIds: { type: "value", rules: [required] },
      costTypes: { type: "value", rules: [required] },
      specOptionIds: { type: "value", rules: [required] },
      baseOption: { type: "value", rules: [required] },
      costCode: { type: "value" },
      displayName: { type: "value" },
      unitOfMeasure: { type: "value" },
    },
    rules: [required],
  },
};

function mapToInput(
  form: FormInput,
  selectedRows: string[],
  readyPlanOptions: AddItemOptionFragment[],
  nonElevationOptions: AddItemsOption[],
): SaveItemTemplateItemVersionsInput {
  // In Add mode, we will have rows of items and one or more locations and one or more cost Types
  //  we want to create template items for every combination of location and cost type
  const templateId = form.templateId;

  const items = form.itemTemplateItemVersions
    .filter((i) => isDefined(i) && selectedRows.includes(i.id))
    .flatMap((item) => {
      // If the user selected the `allElevations` elevation, then consider that as selecting `elevationId: null`.
      const selectedElevationIds = item.elevationIds?.map((o) => (o === allElevationsOptionId ? null : o));

      const selectedSpecOptionIds = item.specOptionIds?.map((o) => (o === allSpecsOptionId ? null : o));

      // for every selected row, we want to create a "save input" for each combination of 'option', location, cost type, and elevation.
      return item.baseOption?.flatMap((rpoId) => {
        const addItemsOption = nonElevationOptions.find((o) => o.rpoIds?.includes(rpoId));
        const isSchemeOption = readyPlanOptions.find((o) => o.globalOption.id === addItemsOption?.id)?.type
          .isExteriorPalette;
        return selectedSpecOptionIds?.flatMap((specOptionId) => {
          return item.locationIds?.flatMap((locationId) => {
            return item.costTypes?.flatMap((costType) => {
              return selectedElevationIds?.map((elevationId) => {
                // Some elevation options may not be applicable to the selected option. For instance, if the selected option is an Exterior scheme that is not supported on this elevation
                const elevationOption = elevationId
                  ? readyPlanOptions.find((o) => o.id === elevationId)
                  : readyPlanOptions.find((o) => o.childOptions.map((o) => o.id).includes(rpoId));
                // Consider the scheme to not be active if the associated elevation is not active
                const isActiveAndSupportedScheme =
                  elevationOption?.active && elevationOption?.childOptions?.some((o) => o.id === rpoId && o.active);

                // Only create a template item if the elevation supports the selected scheme, or if this is not a scheme option
                if (isActiveAndSupportedScheme || !isSchemeOption) {
                  return {
                    locationId,
                    costType,
                    // Treat `baseHouse` as `null` so that the backend will use the base house option
                    otherOptionIds: !rpoId || rpoId === baseHouseOptionId ? null : [rpoId],
                    elevationIds: elevationId ? [elevationId] : null,
                    specOptionIds: specOptionId ? [specOptionId] : null,
                    itemId: item.itemId,
                    totalCostInCents: 0,
                    unitOfMeasureId: item.unitOfMeasure,
                  };
                }
              });
            });
          });
        });
      });
    })
    .filter(isDefined);

  return {
    templateId,
    itemTemplateItemVersions: items,
  };
}

type Filter = {
  costCodeId?: string[];
};

type AddItemsOption = {
  id: string;
  name: string;
  rpoIds?: string[];
};

const baseHouseOptionId = "baseHouse";
const allElevationsOptionId = "allElevations";
const allSpecsOptionId = "allSpecOptions";
