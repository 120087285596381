import { Button, ButtonMenu, Css, MenuItem, useModal, useRightPane } from "@homebound/beam";
import { FC } from "react";
import { useParams } from "react-router";
import { InternalUser, JobLogDetailFragment, PotentialOperation2 } from "src/generated/graphql-types";
import { getDisabledReasons } from "src/utils";
import { PasteJobLogModal } from "./CopyJobLog";
import { JobLogImageRightPaneContent } from "./JobLogImageRightPaneContent";
import { JobLogImages } from "./JobLogImages";
import { JobLogNoteRightPaneContent } from "./JobLogNoteRightPaneContent";
import { JobLogWeatherSummary } from "./JobLogWeatherSummary";
import { formatDate } from "./JobLogsPage";

type JobLogHeaderProps = {
  jobLog: JobLogDetailFragment;
  currentUser: InternalUser;
  refetchJobLogs: () => void;
  canEdit: PotentialOperation2;
};

export const JobLogHeader: FC<JobLogHeaderProps> = ({ jobLog, currentUser, refetchJobLogs, canEdit }) => {
  const { openRightPane } = useRightPane();
  const { openModal } = useModal();
  const { developmentId } = useParams<{ developmentId: string }>();
  const disabledReasons = getDisabledReasons(canEdit);

  const menuItems: MenuItem[] = [
    {
      label: "Copy Job Log to other Projects",
      disabled: disabledReasons,
      onClick: () => {
        openModal({
          content: <PasteJobLogModal jobLog={jobLog} type="DifferentProject" refetchJobLogs={refetchJobLogs} />,
        });
      },
    },
    {
      label: "Copy Job Log to other Days",
      disabled: disabledReasons,
      onClick: () => {
        openModal({
          content: <PasteJobLogModal jobLog={jobLog} type="SameProject" refetchJobLogs={refetchJobLogs} />,
        });
      },
    },
  ];

  const handleAddNote = () => {
    openRightPane({
      content: (
        <JobLogNoteRightPaneContent
          jobLogId={jobLog.id}
          currentUser={currentUser}
          developmentId={developmentId}
          refetchJobLogs={refetchJobLogs}
        />
      ),
    });
  };

  const handleAddImage = () => {
    openRightPane({
      content: <JobLogImageRightPaneContent jobLogId={jobLog.id} />,
    });
  };

  return (
    <div css={Css.df.fdc.$}>
      {
        // show project names on development job-logs
        developmentId && <h4>{jobLog.project.name}</h4>
      }
      <h1 css={Css.gray900.xl2Sb.$}>{formatDate(jobLog.logDate)}</h1>
      <div css={Css.df.aic.jcsb.h7.$}>
        <JobLogWeatherSummary jobLog={jobLog} canEdit />
        <div css={Css.df.aic.gap1.$}>
          {
            <>
              <Button variant="secondary" label="Add media" onClick={handleAddImage} disabled={disabledReasons} />
              <Button label="Add note" onClick={handleAddNote} disabled={disabledReasons} />
            </>
          }
          <ButtonMenu trigger={{ icon: "verticalDots" }} items={menuItems} />
        </div>
      </div>
      <div css={Css.dn.if(jobLog.images.length > 0).mb3.db.$}>
        <JobLogImages editingEnabled compact parentId={jobLog.id} images={jobLog.images} display={"horizontal"} />
      </div>
    </div>
  );
};
