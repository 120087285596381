import { Css } from "@homebound/beam";
import { ReactNode } from "react";

type HeaderBarProps = {
  left?: ReactNode;
  right?: ReactNode;
};

export function HeaderBar(props: HeaderBarProps) {
  const { left, right } = props;
  return (
    <div css={Css.pb1.df.fs0.jcsb.aic.sticky.top0.z5.$}>
      <div>{left}</div>
      <div css={Css.df.aic.gap1.$}>{right}</div>
    </div>
  );
}
