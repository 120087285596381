import { column, GridTable, Icon } from "@homebound/beam";
import { DocumentEditorDetailFragment } from "src/generated/graphql-types";

type ExpenseDocuments = Map<string, DocumentEditorDetailFragment>;

type ExpenseDocumentsTableProps = {
  documents: ExpenseDocuments;
  onDelete: (documentId: string) => void;
};

type DocumentRow = {
  kind: "document";
  id: string;
  data: Pick<DocumentEditorDetailFragment, "id" | "name"> & { downloadUrl: string };
};

export function ExpenseDocumentsTable({ documents, onDelete }: ExpenseDocumentsTableProps) {
  if (documents.size === 0) return null;
  const columns = createColumns(onDelete);
  const rows = createRows(documents);
  return <GridTable style={{ rowHeight: "fixed" }} columns={columns} rows={rows} data-testid="expenseDocumentsTable" />;
}

function createColumns(onDelete: ExpenseDocumentsTableProps["onDelete"]) {
  return [
    column<DocumentRow>({
      align: "center",
      document: ({ name, downloadUrl }) => ({
        content: name,
        onClick: downloadUrl,
      }),
    }),
    column<DocumentRow>({
      align: "center",
      document: ({ id: documentId }) => ({
        content: <Icon icon="trash" data-testid="trash" color="inherit" />,
        onClick: () => onDelete(documentId),
      }),
    }),
  ];
}

function createRows(documents: ExpenseDocumentsTableProps["documents"]) {
  return [...documents].map(([_, { id, name, asset }]) => ({
    id,
    kind: "document" as const,
    data: {
      id,
      name,
      downloadUrl: asset.downloadUrl,
    },
  }));
}
