import { Button, Checkbox, Css, HasIdAndName, MultiSelectField } from "@homebound/beam";
import { useMemo } from "react";
import { BusinessFunctionDetails } from "src/components/change-log/components/BusinessFunctionAssets";
import { CustomTaskCatalogPageFilter } from "../TaskCatalogPage";

type TaskCatalogFilterModalProps = {
  businessFunctionTypes: BusinessFunctionDetails[];
  markets: HasIdAndName[];
  developments: HasIdAndName[];
  globalPlanTags: HasIdAndName[];
  filter: CustomTaskCatalogPageFilter;
  setFilter: (filter: CustomTaskCatalogPageFilter) => void;
};

export function TaskCatalogFilterModal(props: TaskCatalogFilterModalProps) {
  const { filter, setFilter } = props;
  const { teams, markets, developments, tags } = useMemo(
    () => ({
      teams: props.businessFunctionTypes.sortByKey("name"),
      markets: props.markets.sortByKey("name"),
      developments: props.developments.sortByKey("name"),
      tags: props.globalPlanTags.sortByKey("name"),
    }),
    [props.businessFunctionTypes, props.developments, props.globalPlanTags, props.markets],
  );
  return (
    <div css={Css.df.fdc.gap2.$}>
      <MultiSelectField
        onSelect={(val) => {
          setFilter({ ...filter, team: val.isEmpty ? undefined : val });
        }}
        label="Team"
        options={teams}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ code }) => code}
        values={filter.team ?? []}
        compact
      />
      <MultiSelectField
        label="Market"
        onSelect={(val) => {
          setFilter({ ...filter, market: val.isEmpty ? [] : val });
        }}
        options={markets}
        values={filter.market ?? []}
        compact
      />
      <MultiSelectField
        label="Developments"
        onSelect={(val) => {
          setFilter({ ...filter, development: val.isEmpty ? undefined : val });
        }}
        options={developments}
        values={filter.development ?? []}
        compact
      />
      <MultiSelectField
        onSelect={(val) => {
          setFilter({ ...filter, tags: val.isEmpty ? undefined : val });
        }}
        label="Tags"
        options={tags}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        values={filter.tags ?? []}
        compact
      />
      <Checkbox
        label="Specific to Markets and Developments"
        selected={!!filter.applyToAllSchedules}
        onChange={(val) => {
          setFilter({ ...filter, applyToAllSchedules: val });
        }}
      />
      <Checkbox
        label="Show Archived Tasks"
        selected={!!filter.archived}
        onChange={(val) => {
          setFilter({ ...filter, archived: val });
        }}
      />
      {Object.keys(filter).length > 0 && <Button label="Clear" onClick={() => setFilter({})} variant="text" />}
    </div>
  );
}
