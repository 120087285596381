import {
  BoundSelectField,
  BoundTextField,
  Chip,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTableApi,
  selectColumn,
  simpleHeader,
} from "@homebound/beam";
import { FieldState, ObjectConfig, ObjectState } from "@homebound/form-state";
import { emptyCellDash, priceCell } from "src/components";
import {
  ItemTemplateFilter,
  ItemTemplateItemVersionGroupTotal,
  NamedFragment,
  ScopeChangeReason,
  ScopeChangeType,
  ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment,
  ScopeTemplatesReviewStepItemTemplateTableFragment,
  ScopeTemplatesReviewStepQuery,
  ScopeTemplatesReviewStepTableQuery,
} from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString } from "src/utils";

export enum RenderMode {
  edit = "edit",
  read = "read",
}

type ItemTemplateWithItivs = ScopeTemplatesReviewStepTableQuery["itemTemplates"][0] & {
  itemVersions: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment[];
};

type ReadyPlanOptionWithItivs = {
  options: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment["options"];
  itemVersions: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment[];
};

type HeaderRow = { kind: "header"; id: string };
type PlanRow = {
  kind: "plan";
  data: ScopeTemplatesReviewStepItemTemplateTableFragment["readyPlan"] & {
    itemTemplates: ItemTemplateWithItivs[];
    scopeChangeReason?: FieldState<any>;
    scopeChangeReasonNote?: FieldState<any>;
  };
};
type ReadyPlanOptionRow = {
  kind: "rpo";
  data: ReadyPlanOptionWithItivs;
};
type ItemRow = {
  kind: "item";
  data: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment["item"]["costCode"] & {
    options: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment[];
  };
};
type OptionRow = {
  kind: "option";
  data: Omit<ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment, "scopeChangeReason"> & {
    scopeChangeReason?: FieldState<any>;
    scopeChangeReasonNote?: FieldState<any>;
  };
};
export type ReviewStepRow = HeaderRow | PlanRow | ReadyPlanOptionRow | ItemRow | OptionRow;

type ChangeReasons = Omit<ScopeChangeReason, "createdAt" | "updatedAt">[];
export type ReviewTemplateChangesTableProps = {
  id: string[];
  scopeChangeReasons: ChangeReasons;
  costCodes: NamedFragment[];
  readyPlans: NamedFragment[];
  formState: ObjectState<FormConfigType>;
  mode: RenderMode;
};

export type ItemTemplateFilterFE = ItemTemplateFilter & {
  costCode: string[];
};

export function createReviewStepColumns(
  scopeChangeReasons: ChangeReasons,
  mode: RenderMode,
): GridColumn<ReviewStepRow>[] {
  return [
    collapseColumn<ReviewStepRow>({}),
    ...(mode === RenderMode.edit ? [selectColumn<ReviewStepRow>({})] : []),
    column<ReviewStepRow>({
      header: "Template",
      plan: (readyPlan) => ({
        content: () => <span css={Css.smSb.gray900.$}>{readyPlan?.displayName}</span>,
        value: readyPlan.displayName,
      }),
      rpo: ({ options }) => ({
        content: () => <span css={Css.smMd.plPx(20).$}>{options?.map((rpo) => rpo.name).join(", ") ?? "-"}</span>,
        value: options?.map((rpo) => rpo.name).join(", "),
      }),
      item: ({ displayName }) => ({
        content: () => <span css={Css.xsSb.plPx(24).$}>{displayName}</span>,
        value: displayName,
      }),
      option: ({ displayName, specOptionIds, elevationIds, options }) => {
        const value = `${displayName}
        ${elevationIds.nonEmpty ? " - " : ""}
        ${elevationIds
          .map((id) => {
            const elevation = options?.find((rpo) => rpo.id === id);
            return elevation ? `${elevation.name}${elevation.shortName ? ` (${elevation.shortName})` : ""}` : "";
          })
          .join(", ")}
        ${specOptionIds.nonEmpty ? " - " : ""}
        ${specOptionIds
          .map((id) => {
            const specOption = options?.find((rpo) => rpo.id === id);
            return specOption ? specOption.name : "";
          })
          .join(", ")}`;
        return {
          content: () => <span css={Css.xs.plPx(60).$}>{value}</span>,
          value,
        };
      },
      w: 1.4,
    }),
    column<ReviewStepRow>({
      header: "Old Cost",
      plan: ({ itemTemplates }) => {
        const value = itemTemplates.reduce(
          (total, it) => total + it.itemVersions.reduce((t2, itiv) => t2 + getOldCost(itiv), 0),
          0,
        );
        return priceCell({ valueInCents: value });
      },
      rpo: ({ itemVersions }) => {
        const value = itemVersions.reduce((total, itiv) => total + getOldCost(itiv), 0);
        return priceCell({ valueInCents: value });
      },
      item: ({ options }) => {
        const value = options.reduce((total, itiv) => total + getOldCost(itiv), 0);
        return priceCell({ valueInCents: value });
      },
      option: (itiv) => {
        const value = getOldCost(itiv);
        return itiv.scopeChangeType === ScopeChangeType.Add ? emptyCellDash : priceCell({ valueInCents: value });
      },
      align: "right",
      w: "140px",
    }),
    column<ReviewStepRow>({
      header: "New Cost",
      plan: ({ itemTemplates }) => {
        const value = itemTemplates.reduce(
          (total, it) =>
            total +
            it.itemVersions
              .filter(({ scopeChangeType }) => scopeChangeType !== ScopeChangeType.Removed)
              .reduce((t2, { totalCostInCents }) => t2 + totalCostInCents, 0),
          0,
        );
        return priceCell({ valueInCents: value });
      },
      rpo: ({ itemVersions }) => {
        const value = itemVersions
          .filter(({ scopeChangeType }) => scopeChangeType !== ScopeChangeType.Removed)
          .reduce((total, { totalCostInCents }) => total + totalCostInCents, 0);
        return priceCell({ valueInCents: value });
      },
      item: ({ options }) => {
        const value = options
          .filter(({ scopeChangeType }) => scopeChangeType !== ScopeChangeType.Removed)
          .reduce((total, { totalCostInCents }) => total + totalCostInCents, 0);
        return priceCell({ valueInCents: value });
      },
      option: ({ totalCostInCents, scopeChangeType }) => {
        return scopeChangeType === ScopeChangeType.Removed
          ? emptyCellDash
          : priceCell({ valueInCents: totalCostInCents });
      },
      align: "right",
      w: "140px",
    }),
    column<ReviewStepRow>({
      header: "Change",
      plan: ({ itemTemplates }) => {
        const change =
          itemTemplates.reduce(
            (total, it) =>
              total +
              it.itemVersions
                .filter(({ scopeChangeType }) => scopeChangeType !== ScopeChangeType.Removed)
                .reduce((t2, { totalCostInCents }) => t2 + totalCostInCents, 0),
            0,
          ) -
          itemTemplates.reduce(
            (total, it) => total + it.itemVersions.reduce((t2, itiv) => t2 + getOldCost(itiv), 0),
            0,
          );
        const costChangePositive = change >= 0;
        const value = `${costChangePositive ? "+" : "-"}$${formatNumberToString(Math.abs(centsToDollars(change)))}`;
        return { content: () => <Chip text={value} type={costChangePositive ? "success" : "warning"} />, value };
      },
      rpo: ({ itemVersions }) => {
        const [removedCosts, prevalentCosts] = itemVersions.partition(
          ({ scopeChangeType }) => scopeChangeType === ScopeChangeType.Removed,
        );
        const value =
          prevalentCosts.reduce((total, { totalCostInCents }) => total + totalCostInCents, 0) -
          prevalentCosts.reduce((total, itiv) => total + getOldCost(itiv), 0) -
          removedCosts.reduce((total, itiv) => total + getOldCost(itiv), 0);
        return priceCell({ valueInCents: value });
      },
      item: ({ options }) => {
        const [removedCosts, prevalentCosts] = options.partition(
          ({ scopeChangeType }) => scopeChangeType === ScopeChangeType.Removed,
        );
        const value =
          prevalentCosts.reduce((total, { totalCostInCents }) => total + totalCostInCents, 0) -
          prevalentCosts.reduce((total, itiv) => total + getOldCost(itiv), 0) -
          removedCosts.reduce((total, itiv) => total + getOldCost(itiv), 0);

        return priceCell({ valueInCents: value });
      },
      option: (itiv) => {
        const value =
          itiv.scopeChangeType === ScopeChangeType.Removed
            ? scopedCost(itiv.totalCostInCents, itiv.scopeChangeType)
            : itiv.totalCostInCents - getOldCost(itiv);
        return priceCell({ valueInCents: value });
      },
      align: "right",
      w: "140px",
    }),
    column<ReviewStepRow>({
      header: "Reason Code",
      plan: ({ scopeChangeReason }) => ({
        content:
          scopeChangeReason &&
          (() => (
            <BoundSelectField
              disabled={mode === RenderMode.read ? "Template is already active" : undefined}
              field={scopeChangeReason}
              options={scopeChangeReasons}
              label="Scope change reason"
              placeholder="Add a reason code"
            />
          )),
        value: scopeChangeReason?.value,
      }),
      rpo: emptyCell,
      item: emptyCell,
      option: ({ scopeChangeReason }) => ({
        content:
          scopeChangeReason &&
          (() => (
            <BoundSelectField
              disabled={mode === RenderMode.read ? "Template is already active" : undefined}
              field={scopeChangeReason}
              options={scopeChangeReasons}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              label="Scope change reason"
              placeholder="Add a reason code"
            />
          )),
        value: scopeChangeReason?.value,
      }),
    }),
    column<ReviewStepRow>({
      header: "Notes",
      plan: ({ scopeChangeReasonNote }) => ({
        content:
          scopeChangeReasonNote &&
          (() => (
            <BoundTextField
              disabled={mode === RenderMode.read ? "Template is already active" : undefined}
              label=""
              aria-label="Note"
              placeholder="Add a note..."
              field={scopeChangeReasonNote}
            />
          )),
        value: scopeChangeReasonNote?.value,
      }),
      rpo: emptyCell,
      item: emptyCell,
      option: ({ scopeChangeReasonNote }) => ({
        content:
          scopeChangeReasonNote &&
          (() => (
            <BoundTextField
              disabled={mode === RenderMode.read ? "Template is already active" : undefined}
              label=""
              aria-label="Note"
              placeholder="Add a note..."
              field={scopeChangeReasonNote}
            />
          )),
        value: scopeChangeReasonNote?.value,
      }),
      w: 1.4,
    }),
  ];
}

const ignoredScopeChangeTypes = [ScopeChangeType.None, ScopeChangeType.System];
export const relevantScopeChangeTypes = Object.values(ScopeChangeType).filter(
  (type) => ignoredScopeChangeTypes.indexOf(type) < 0,
);

export function createReviewStepRows(
  itemTemplates?: ItemTemplateWithItivs[],
  filter?: ItemTemplateFilterFE,
  formRows?: readonly ObjectState<{
    id: string;
    scopeChangeReason: string;
    scopeChangeReasonNote?: string | undefined;
  }>[],
  groupTotals: ItemTemplateItemVersionGroupTotal[] = [],
): GridDataRow<ReviewStepRow>[] {
  const plans = (
    itemTemplates
      ?.filter(({ itemVersions }) => itemVersions.nonEmpty)
      .groupByObject(({ readyPlan }) => readyPlan ?? { id: "rp:-1", displayName: "Unassigned" }) ?? []
  )
    // cost code filtering for ready plans
    .filter(([, it]) =>
      filter?.costCode
        ? it.some(({ itemVersions }) =>
            itemVersions.some(({ item }) => filter.costCode.some((ccId) => ccId === item.costCode.id)),
          )
        : true,
    );

  const baseHouseOption = { id: "rpo:-1", name: "Base House" };
  const rows: GridDataRow<ReviewStepRow>[] = plans.map(([readyPlan, itemTemplates]) => {
    const formRow = formRows?.find(({ id }) => itemTemplates.some((it) => id.value === it.id));
    return {
      id: readyPlan.id,
      kind: "plan",
      data: {
        ...readyPlan,
        itemTemplates,
        scopeChangeReason: formRow?.scopeChangeReason as any,
        scopeChangeReasonNote: formRow?.scopeChangeReasonNote as any,
      },
      children: itemTemplates
        .flatMap((it) => it.itemVersions)
        .groupByObject((itiv) => (itiv.otherOptionIds.nonEmpty ? itiv.otherOptionIds.join() : baseHouseOption.id))
        .sort(([optionA]) => (optionA === baseHouseOption.id ? 0 : 1))
        .map(([otherOptionIds, itemVersions]) => {
          const options = itemVersions[0].otherOptionIds
            .map((id) => itemVersions[0].options.find((o) => o.id === id))
            .compact();
          return {
            id: `${readyPlan.id}_${otherOptionIds}`,
            kind: "rpo",
            data: { options: options.nonEmpty ? options : [baseHouseOption], itemVersions },
            pin: otherOptionIds === baseHouseOption.id ? { at: "first" as const, filter: true } : undefined,
            children: itemVersions
              .groupByObject((fragment) => fragment.item.costCode.id)
              .map(
                ([costCodeId, itemVersions]: [
                  string,
                  ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment[],
                ]) =>
                  ({
                    id: `${readyPlan.id}_${otherOptionIds}_${costCodeId}`,
                    kind: "item",
                    data: { ...itemVersions[0].item.costCode, options: itemVersions },
                    children: itemVersions.map((itiv: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment) => {
                      const itemFormRow = formRows?.find(({ id }) => id.value === itiv.id);
                      return {
                        id: itiv.id,
                        kind: "option",
                        data: {
                          ...itiv,
                          scopeChangeReason: itemFormRow?.scopeChangeReason as any,
                          scopeChangeReasonNote: itemFormRow?.scopeChangeReasonNote as any,
                        },
                      };
                    }),
                  }) as GridDataRow<ReviewStepRow>,
              ),
          };
        }),
    };
  });

  return [simpleHeader, ...rows];
}

function getOldCost(
  itiv: Pick<
    ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment,
    "scope" | "scopeChangeType" | "totalCostInCents"
  >,
): number {
  const { scope, scopeChangeType, totalCostInCents } = itiv;
  return scope.basedOn?.totalCostInCents ?? (scopeChangeType === ScopeChangeType.Add ? 0 : totalCostInCents);
}

type FormConfigType = {
  items: {
    id: string;
    scopeChangeReason: string;
    scopeChangeReasonNote?: string;
  }[];
};

export const reviewStepFormConfig: ObjectConfig<FormConfigType> = {
  items: {
    type: "list",
    config: {
      id: { type: "value" },
      scopeChangeReason: { type: "value" },
      scopeChangeReasonNote: { type: "value" },
    },
  },
};

export function assignItivsToTemplate(
  { itemTemplates }: ScopeTemplatesReviewStepTableQuery,
  itemTemplateItemVersions: ScopeTemplatesReviewStepItemTemplateItemVersionTableFragment[],
): ItemTemplateWithItivs[] {
  const itivsByTemplate = itemTemplateItemVersions.groupBy(({ template }) => template.id);
  return itemTemplates.map((it) => ({
    ...it,
    itemVersions: itivsByTemplate[it.id] ?? [],
  }));
}

export function mapDataToForm(itemTemplates: ItemTemplateWithItivs[]): FormConfigType {
  return {
    items: itemTemplates.flatMap(({ id, scopeChangeReason, scopeChangeReasonNote, itemVersions }) => {
      return [
        { id, scopeChangeReason: scopeChangeReason?.id ?? "", scopeChangeReasonNote: scopeChangeReasonNote ?? "" },
        ...itemVersions.map(({ id, scopeChangeReason, scopeChangeReasonNote }) => ({
          id,
          scopeChangeReason: scopeChangeReason?.id ?? "",
          scopeChangeReasonNote: scopeChangeReasonNote ?? "",
        })),
      ];
    }),
  };
}

export function mapToMetadata(id: string[], data?: ScopeTemplatesReviewStepQuery) {
  const plans =
    data?.developments
      .flatMap(({ readyPlans }) => readyPlans)
      .filter(({ itemTemplates }) => itemTemplates.some(({ id: itId }) => id.indexOf(itId) >= 0)) ?? [];
  const readyPlans = plans.map(({ id, name }) => ({ id, name }));
  const costCodes =
    data?.itemTemplateItemVersions
      .map(({ item }) => item)
      .groupByObject(({ costCode }) => costCode)
      .map(([cc]) => cc) ?? [];
  const canActivate = !data?.itemTemplates.find((it) => !it.canActivate.allowed);

  return {
    readyPlans,
    costCodes,
    templates: data?.itemTemplates.map(({ id }) => id) ?? [],
    canActivate,
  };
}

export function mapToHeaderData(data?: ScopeTemplatesReviewStepQuery) {
  const oldCost =
    data?.itemTemplates.reduce((total, { basedOn }) => (basedOn ? total + basedOn.totalCostInCents : 0), 0) ?? 0;
  const newCost = data?.itemTemplates.reduce((total, { totalCostInCents }) => total + totalCostInCents, 0) ?? 0;
  const costChangeNumber = centsToDollars(newCost - oldCost);
  const costChangePositive = costChangeNumber >= 0;
  const costChange = `${costChangePositive ? "+" : "-"}$${formatNumberToString(Math.abs(costChangeNumber))}`;
  return { oldCost, newCost, costChange, costChangePositive };
}

export function handleBulkScopeChangeReasons(props: {
  scopeChangeReasons: ChangeReasons;
  selectedRows: string[];
  formState: ObjectState<FormConfigType>;
  tableApi: GridTableApi<ReviewStepRow>;
}) {
  const { scopeChangeReasons, selectedRows, formState, tableApi } = props;
  return (
    scopeChangeReasons.map(({ id, name }) => ({
      label: name,
      onClick: async () => {
        for (const rowId of selectedRows) {
          formState.items.rows.find(({ id }) => id.value === rowId.split("_")[0])?.scopeChangeReason.set(id);
        }
        tableApi.clearSelections();
      },
    })) ?? []
  );
}

function scopedCost(cost: number, scopeChangeType: ScopeChangeType) {
  return cost * (scopeChangeType === ScopeChangeType.Removed ? -1 : 1);
}
