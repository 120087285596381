import { Css, useComputed } from "@homebound/beam";
import { ListFieldState, ObjectState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { AssetGallery, OpenGallery } from "src/components/assetGallery/AssetGallery";
import { SaveToDoAttachmentModel } from "src/components/to-dos/ToDoModal";
import { WithActions } from "src/components/to-dos/WithActions";
import { UppyUploader } from "src/components/uploader";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { sortBy } from "src/utils";

export type ToDoAttachmentsProps = {
  field: ListFieldState<SaveToDoAttachmentModel>;
};

export function ToDoAttachments({ field }: ToDoAttachmentsProps) {
  function saveTodoAttachment(file: UppyFile) {
    const downloadUrl = file.meta.downloadUrl as string;
    field.add({
      op: "create",
      asset: {
        downloadUrl,
        contentType: file.type,
        fileName: file.name,
        s3Key: file.meta.s3Key as string,
        sizeInBytes: file.size,
        attachmentUrl: downloadUrl,
        createdAt: new Date(),
      },
    });
  }

  function deleteTodoAttachment(attachment: ObjectState<SaveToDoAttachmentModel>) {
    if (attachment.id) {
      attachment.op.value = "delete";
    } else {
      field.remove(attachment.value);
    }
  }

  const orderedAttachments = useComputed(() => {
    return sortBy(
      field.rows.filter((a) => a.op.value !== "delete"),
      (x) => -x.asset.createdAt,
    );
  }, [field]);

  const renderAttachments = (openGallery: OpenGallery) => {
    return (
      <>
        {orderedAttachments.map((attachment) => {
          const asset = attachment.asset.value as AssetInfoFragment;
          return (
            <div key={attachment.id.value} data-testid={attachment.id.value}>
              <WithActions
                left={{ icon: "trash", tooltip: "delete", action: () => deleteTodoAttachment(attachment) }}
                right={{ icon: "download", tooltip: "download", action: asset.attachmentUrl }}
                footer={{ text: asset.fileName! }}
                onClick={() => openGallery(asset)}
              >
                <AssetPreview asset={asset} dimensions={{ width: 140, height: 90 }} />
              </WithActions>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Observer>
      {() => (
        <div css={Css.df.fdc.$}>
          <div css={Css.gray700.sm.my1.$}>Attachments ({orderedAttachments?.length ?? 0})</div>
          <div css={Css.df.gap1.$}>
            <UppyUploader
              onFinish={saveTodoAttachment}
              dragDropText=""
              maxNumberOfFiles={1}
              dragDropWidth={140}
              dragDropHeight={90}
            />
            <div css={Css.maxwPx(350).$}>
              <AssetGallery
                assets={orderedAttachments.map((i) => i.asset.value as AssetInfoFragment)}
                display="horizontal"
              >
                {renderAttachments}
              </AssetGallery>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
}
