import {
  Button,
  collapseColumn,
  column,
  Css,
  defaultTestId,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  simpleHeader,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { isAfter, isBefore, isEqual, isWithinInterval, startOfToday } from "date-fns";
import { useMemo } from "react";
import {
  InputMaybe,
  PersonalDashboard_ScheduleTaskFragment,
  ProjectFeature,
  SaveScheduleTaskInput,
  ScheduleStatus,
  TaskStatus,
  TaskStatusesFragment,
  useSaveScheduleTasksMutation,
} from "src/generated/graphql-types";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { UpcomingDueIn } from "src/routes/personal-dashboard/enums";
import { TaskStatusSelect } from "src/routes/projects/schedule-v2/components/TaskStatusSelect";
import { useTaskBillContext } from "src/routes/projects/schedule-v2/contexts/TaskBillModalContext";
import { assertNever } from "src/utils";
import { addDaysDateOnly, DateOnly, formatMonthDay, formatWithShortYear } from "src/utils/dates";
import { PersonalDashboardRefetchQueries } from "../../enums/consts";
import { TaskRowIcon, TaskTitleAndParent } from "./CellComponents";

type UpcomingTasksTableProps = {
  scheduleTasks: PersonalDashboard_ScheduleTaskFragment[];
  taskStatuses: TaskStatusesFragment[];
  onTaskSelect: (taskId: string, tabSelected?: string, scrollIntoViewType?: string) => void;
};

type TaskFormInput = Pick<SaveScheduleTaskInput, "id" | "status">;

export function UpcomingTasksTable({ scheduleTasks, taskStatuses, onTaskSelect }: UpcomingTasksTableProps) {
  const {
    filter: { dueIn },
    selectedTaskId,
  } = useDashboardFilterContext();
  const [saveScheduleTasks] = useSaveScheduleTasksMutation();
  const { openTaskBillModal: maybeOpenTaskBillModal } = useTaskBillContext();

  const { getFormState } = useFormStates<Partial<TaskFormInput>, PersonalDashboard_ScheduleTaskFragment>({
    config: formConfig,
    map: (scheduleTask) => ({
      id: scheduleTask.id,
      status: scheduleTask.status,
    }),
    autoSave: async (os) => {
      const showClickToPayFlag = scheduleTasks
        .find((task) => task.id === os.id.value)
        ?.project?.features.includes(ProjectFeature.ClickToPay);

      if (os.changedValue.status === TaskStatus.Complete && showClickToPayFlag) {
        maybeOpenTaskBillModal({
          taskId: os.value.id!,
          // `handleTaskSave` is called if the modal is skipped or if the task has no unbilled items
          // otherwise its called after the modal is submitted
          handleTaskSave: () =>
            saveScheduleTasks({
              variables: { input: { tasks: [{ status: os.status.changedValue, id: os.id.value }] } },
              refetchQueries: PersonalDashboardRefetchQueries,
            }),
        });
      } else {
        await saveScheduleTasks({
          variables: { input: { tasks: [{ ...os.changedValue, id: os.id.value }] } },
          refetchQueries: PersonalDashboardRefetchQueries,
        });
      }
      os.commitChanges();
    },
    getId: (t) => t.id,
  });

  const { rows, columns } = useMemo(
    () => ({
      columns: createColumns(getFormState, taskStatuses, selectedTaskId, onTaskSelect),
      rows: createRows(scheduleTasks, dueIn),
    }),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleTasks, dueIn, getFormState, taskStatuses, onTaskSelect],
  );

  return (
    <div css={Css.h100.$}>
      <GridTable
        stickyHeader
        as="virtual"
        rows={rows}
        columns={columns}
        style={{ allWhite: true, bordered: false, rowHover: false }}
        rowStyles={{
          header: { cellCss: Css.xsBd.gray900.$ },
          scheduleTask: { cellCss: Css.pxPx(12).$ },
        }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header"; id: string; data: undefined };
type GroupingRow = { kind: "grouping"; data: { title: string; subTitle: string }; children: TaskRow[] };
type TaskRow = { kind: "scheduleTask"; id: string; data: PersonalDashboard_ScheduleTaskFragment };
type Row = HeaderRow | GroupingRow | TaskRow;

function createColumns(
  getFormState: (input: PersonalDashboard_ScheduleTaskFragment) => ObjectState<Partial<TaskFormInput>>,
  taskStatuses: TaskStatusesFragment[],
  selectedTaskId: string | undefined,
  onTaskSelect: (taskId: string, tabSelected?: string, scrollIntoViewType?: string) => void,
): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({ header: emptyCell }),
    column<Row>({
      header: emptyCell,
      grouping: ({ title, subTitle }) => ({
        content: () => {
          const testId = defaultTestId(title);
          return (
            <div data-testid={`dueInGrouping_${testId}`}>
              <span css={Css.baseBd.pr1.$}>{title}</span>
              <span css={Css.base.$}>({subTitle})</span>
            </div>
          );
        },
        colspan: 2,
      }),
      scheduleTask: (scheduleTask) => <TaskTitleAndParent scheduleTask={scheduleTask} onTaskSelect={onTaskSelect} />,
      w: 4,
    }),
    column<Row>({
      w: "50px",
      header: emptyCell,
      grouping: emptyCell,
      scheduleTask: (scheduleTask) => {
        // returning an emptyCell instead of null as we don't want the dash -
        if (scheduleTask.checklistComplete === null) return emptyCell;
        return (
          <button onClick={() => onTaskSelect(scheduleTask.id, "details", "checklistItems")}>
            <Icon
              icon={scheduleTask.checklistComplete ? "checklistComplete" : "checklistNotComplete"}
              data-testid={scheduleTask.checklistComplete ? "checklistComplete" : "checklistNotComplete"}
            />
          </button>
        );
      },
    }),
    column<Row>({
      header: emptyCell,
      grouping: emptyCell,
      scheduleTask: (scheduleTask) => <TaskRowIcon scheduleTask={scheduleTask} />,
      w: "70px",
    }),
    column<Row>({
      header: "Due",
      grouping: emptyCell,
      scheduleTask: (scheduleTask) => (
        <span css={Css.xsMd.$}>{formatWithShortYear(scheduleTask.interval.endDate)}</span>
      ),
      w: 1,
    }),
    column<Row>({
      header: "Status",
      grouping: emptyCell,
      scheduleTask: (scheduleTask) => {
        const os = getFormState(scheduleTask);
        const { schedule } = scheduleTask;
        const disabled = schedule.isLocked || schedule.status === ScheduleStatus.Draft;
        return (
          <TaskStatusSelect
            statusField={os.status}
            options={taskStatuses}
            canComplete={scheduleTask.canComplete}
            canStart={scheduleTask.canStart}
            disabled={disabled}
            hideLabel
            dataTestId="upcomingTasks"
          />
        );
      },
      w: "150px",
    }),
    column<Row>({
      header: emptyCell,
      grouping: emptyCell,
      scheduleTask: ({ id }) => (
        <Button
          disabled={selectedTaskId === id}
          onClick={() => onTaskSelect(id, "details", undefined)}
          label="View Task"
        />
      ),
      w: "120px",
    }),
  ];
}

function createRows(
  scheduleTasks: PersonalDashboard_ScheduleTaskFragment[],
  dueInFilter: InputMaybe<UpcomingDueIn>,
): GridDataRow<Row>[] {
  const rowsTest = scheduleTasks.map((scheduleTask) => ({
    kind: "scheduleTask" as const,
    id: scheduleTask.id,
    data: scheduleTask,
  }));

  const groups = [
    { id: UpcomingDueIn.STALE, title: "Stale Tasks", children: rowsTest },
    { id: UpcomingDueIn.TOMORROW, title: "Due Tomorrow", children: [] },
    { id: UpcomingDueIn.ONE_WEEK, title: "Due in 1 Week", children: [] },
    { id: UpcomingDueIn.THREE_WEEKS, title: "Due in 3 Weeks", children: [] },
    { id: UpcomingDueIn.ONE_MONTH, title: "Due in 1 Month", children: [] },
    { id: UpcomingDueIn.OVER_ONE_MONTH, title: "More than 1 Month", children: [] },
  ];

  const filteredGroups = !dueInFilter ? groups : groups.filter((group) => group.id === dueInFilter);

  const groupedRows = filteredGroups.map((group) => ({
    kind: "grouping" as const,
    id: group.id,
    data: { title: group.title, subTitle: getDateRangeString(group.id) },
    children: getChildRowsForGrouping(group.id, scheduleTasks),
  }));

  return [simpleHeader, ...groupedRows];
}

function getChildRowsForGrouping(group: UpcomingDueIn, scheduleTasks: PersonalDashboard_ScheduleTaskFragment[]) {
  const { startDate, endDate } = getDateRangesForDueIn(group);

  const filteredTasks = scheduleTasks.filter((scheduleTask) => {
    const taskEndDate = scheduleTask.interval.endDate;

    if (!startDate && endDate) return isBefore(taskEndDate, endDate) || isEqual(taskEndDate, endDate);
    if (startDate && !endDate) return isAfter(taskEndDate, startDate) || isEqual(taskEndDate, startDate);

    return isWithinInterval(taskEndDate, { start: startDate, end: endDate });
  });

  return filteredTasks.map((scheduleTask) => ({
    kind: "scheduleTask" as const,
    id: `${group}-${scheduleTask.id}`,
    data: scheduleTask,
  }));
}

function getDateRangeString(dueIn: UpcomingDueIn) {
  const { startDate, endDate } = getDateRangesForDueIn(dueIn);

  if (startDate && !endDate) return `${formatMonthDay(startDate)} +`;
  if (!startDate && endDate) return "Before Today";
  if (startDate === endDate) return formatMonthDay(startDate);

  return `${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`;
}
function getDateRangesForDueIn(dueIn: UpcomingDueIn) {
  const today = new DateOnly(startOfToday());

  switch (dueIn) {
    case UpcomingDueIn.STALE:
      return { startDate: undefined, endDate: addDaysDateOnly(today, -1) };

    case UpcomingDueIn.TOMORROW:
      const tomorrow = addDaysDateOnly(today, 1);
      return { startDate: tomorrow, endDate: tomorrow };

    case UpcomingDueIn.ONE_WEEK:
      return { startDate: today, endDate: addDaysDateOnly(today, 6) };

    case UpcomingDueIn.THREE_WEEKS:
      return { startDate: today, endDate: addDaysDateOnly(today, 20) };

    case UpcomingDueIn.ONE_MONTH:
      return { startDate: today, endDate: addDaysDateOnly(today, 28) };

    case UpcomingDueIn.OVER_ONE_MONTH:
      return { startDate: addDaysDateOnly(today, 29), endDate: undefined };

    default:
      return assertNever(dueIn);
  }
}

const formConfig: ObjectConfig<Partial<TaskFormInput>> = {
  id: { type: "value" },
  status: { type: "value" },
};
