import {
  ButtonDatePicker,
  Css,
  dateRangeFilter,
  DateRangeFilterValue,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridStyle,
  GridTable,
  multiFilter,
  Palette,
  RightPaneLayout,
  RowStyles,
  ScrollableContent,
  Switch,
  treeFilter,
  useBreakpoint,
  usePersistedFilter,
  useRightPane,
  useTestIds,
} from "@homebound/beam";
import { noCase, sentenceCase } from "change-case";
import { format, subWeeks } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SearchBox } from "src/components";
import {
  DateOperation,
  InternalUser,
  JobLogDetailFragment,
  JobLogFilter,
  JobLogFlag,
  JobLogNoteType,
  NamedDevelopmentCohortsProjectsFragment,
  PotentialOperation2,
  useDevelopmentProjectsQuery,
  useJobLogsFilterDataQuery,
  useJobLogsProjectEditabilityQuery,
  useJobLogsQuery,
  useSaveJobLogMutation,
  WeatherType,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { JobLogNoteRightPaneContent } from "src/routes/projects/job-logs/JobLogNoteRightPaneContent";
import { developmentPaths, projectPaths } from "src/routes/routesDef";
import {
  createDevelopmentJobLogNoteDrawerUrl,
  createDevelopmentJobLogsGalleryUrl,
  createDevelopmentJobLogsUrl,
  createProjectJobLogNoteDrawerUrl,
  createProjectJobLogsGalleryUrl,
  createProjectJobLogsUrl,
} from "src/RouteUrls";
import { groupBy, hasData, isDefined, queryResult, renderLoadingOrError, sortBy } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { JobLogsGalleryView } from "./components/JobLogsGalleryView";
import { JobLogNoRowsImage } from "./components/JobLogsNoRowsImage";
import { JobLog } from "./JobLog";
import { weatherNames, weatherTypes } from "./JobLogWeatherSummary";

// project will be added in the filter from url param
export type JobLogFilterType = JobLogFilter & {
  dateRange?: DateRangeFilterValue<string>;
  schedulePhase?: string[];
};

export function JobLogsPage() {
  const { projectId, developmentId } = useParams<{ projectId: string; developmentId: string }>();
  const { name: projectName } = useProjectContext();

  const query = useDevelopmentProjectsQuery({
    variables: { developmentId },
    skip: !isDefined(developmentId),
  });
  const projectJobLogEditabilityQuery = useJobLogsProjectEditabilityQuery({
    variables: { projectId },
    skip: isDefined(developmentId),
  });
  // Make another query that gets the jobLog editability for the current user
  if (!isDefined(developmentId)) {
    return queryResult(projectJobLogEditabilityQuery, ({ project }) => (
      <JobLogsView projectIds={[projectId]} zipName={projectName} canEdit={project.canEditJobLogs} />
    ));
  }

  return queryResult(query, ({ development }) => {
    const parentIds = development.cohorts?.flatMap((c) => c.projects.map((p) => p.id)) ?? [];
    return <JobLogsView projectIds={parentIds} development={development} zipName={development.name} />;
  });
}

// Job log width in pixels
export const JobLogWidth = 690;
const SmallJobLogWidth = 455;
export const PAGE_SIZE = 25;

export type JobLogsViewProps = {
  projectIds: string[];
  development?: NamedDevelopmentCohortsProjectsFragment;
  zipName?: string;
  canEdit?: PotentialOperation2;
};

export function JobLogsView({ projectIds, development, canEdit, zipName }: JobLogsViewProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: filterData } = useJobLogsFilterDataQuery({ variables: { project: projectIds } });

  // If developmentId is not passed, then it is rendered from project menu
  const developmentId = development?.id;
  const isProject = !developmentId;

  const filterDefs: FilterDefs<JobLogFilterType> = useMemo(() => {
    const phaseOptionsGrouped = groupBy(
      (filterData?.scheduleTasks || []).filter((t) => !!t.schedulePhase),
      (t) => t.schedulePhase!.name,
    );

    const schedulePhaseOptions = Object.values(phaseOptionsGrouped).map((t) => ({
      value: t[0].schedulePhase!.id,
      label: t[0].schedulePhase!.name,
    }));

    const today = new Date();
    // For project - 2 weeks, for development - 1 week data
    const fromDate = subWeeks(today, isProject ? 2 : 1);
    const defaultFilter = {
      dateRange: {
        op: DateOperation.Between,
        value: { from: fromDate, to: today },
      },
    };

    return {
      dateRange: dateRangeFilter({
        label: "Date Range",
        defaultValue: defaultFilter.dateRange,
      }),
      ...(development && {
        project: treeFilter({
          defaultCollapsed: true,
          label: "Projects",
          filterBy: "leaf",
          getOptionLabel: (o) => o.name,
          getOptionValue: (o) => o.id,
          options:
            development?.cohorts
              ?.map((c) => ({
                id: c.id,
                name: c.name,
                children: c.projects.map((p) => ({ id: p.id, name: p.name })),
              }))
              // Ensure we only show cohorts with projects
              .filter((c) => c.children.length > 0) ?? [],
        }),
      }),
      schedulePhase: multiFilter({
        options: sortBy(schedulePhaseOptions, (t) => t.label),
        getOptionValue: ({ value }) => value,
        getOptionLabel: ({ label }) => label,
      }),
      noteType: multiFilter({
        options: sortBy(jobLogNoteTypes, (t) => t.value),
        getOptionValue: ({ value }) => value,
        getOptionLabel: ({ label }) => label,
      }),
      weatherType: multiFilter({
        options: sortBy(weatherTypes, (wt) => weatherNames[wt.value]),
        getOptionValue: ({ value }) => value,
        getOptionLabel: ({ value }) => weatherNames[value],
      }),
      flags: multiFilter({
        options: sortBy(jobLogFlags, (t) => t.value),
        getOptionValue: ({ value }) => value,
        getOptionLabel: ({ label }) => label,
      }),
    };
  }, [development, filterData, isProject]);

  const { setFilter, filter } = usePersistedFilter<JobLogFilterType>({
    storageKey: isProject ? "jobLogFilter" : `${developmentId}-jobLogFilter`,
    filterDefs,
  });
  // we are trying to build a list of weather ids. For example - Rain will bring back ["RAIN","SHOWER_DAY"]
  const weatherTypeFilter = filter.weatherType?.flatMap((value) => {
    const name = weatherNames[value];
    return Object.keys(weatherNames).filter(
      (weatherName) => weatherNames[weatherName as WeatherType] === name,
    ) as WeatherType[];
  });

  const scheduleTaskFilter = filter.schedulePhase?.flatMap(
    (v) => filterData?.scheduleTasks?.filter((t) => t.schedulePhase?.id === v).map((t) => t.id) ?? [],
  );

  const query = useJobLogsQuery({
    variables: {
      jobLogFilter: {
        noteType: filter.noteType,
        project: filter.project ? filter.project : projectIds,
        weatherType: weatherTypeFilter,
        flags: filter.flags,
        scheduleTasks: scheduleTaskFilter,
        // We will filter only if both from and to are provided
        ...(filter?.dateRange?.value?.from && filter?.dateRange?.value?.to
          ? {
              logDate: {
                op: DateOperation.Between,
                value: new DateOnly(new Date(filter.dateRange.value.from)),
                value2: new DateOnly(new Date(filter.dateRange.value.to)),
              },
            }
          : {}),
      },
      jobLogNoteFilter: {
        flags: filter.flags,
        scheduleTasks: scheduleTaskFilter,
      },
      page: { limit: PAGE_SIZE, offset: 0 },
    },
  });

  const { push, replace } = useHistory();
  const { data, refetch } = query;
  function setGallery(gallery: boolean) {
    if (isProject) {
      replace(gallery ? createProjectJobLogsGalleryUrl(projectId) : createProjectJobLogsUrl(projectId));
    } else {
      replace(gallery ? createDevelopmentJobLogsGalleryUrl(developmentId) : createDevelopmentJobLogsUrl(developmentId));
    }
  }
  const gallery =
    useRouteMatch(
      isProject ? createProjectJobLogsGalleryUrl(projectId) : createDevelopmentJobLogsGalleryUrl(developmentId),
    )?.isExact ?? false;
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const currentUser = (data?.currentInternalUser || {}) as InternalUser;
  const { openRightPane, closeRightPane } = useRightPane();
  const rowLookup = useRef<GridRowLookup<JobLogRow>>();
  const breakpoints = useBreakpoint();
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowStyles = useMemo(() => createRowStyles(breakpoints.mdAndDown), []);
  // Sorting logDate Desc, project name Asc
  const sortedJobLogs = useMemo(() => {
    return [...(data?.jobLogsPage.entities || [])].sort(
      (a, b) => b.logDate.getTime() - a.logDate.getTime() || a.project.name.localeCompare(b.project.name),
    );
  }, [data?.jobLogsPage.entities]);

  const rows = useMemo(() => createRows(sortedJobLogs), [sortedJobLogs]);
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => createColumns(currentUser, refetch), [currentUser.id, refetch, breakpoints.mdAndDown]);
  const [saveJobLog] = useSaveJobLogMutation();
  const disabledDays = [{ after: new Date() }, () => false];
  const daysWithJobLogs = query.data?.jobLogsPage.logDates ?? [];

  const jobLogNoteDrawerMatch = useRouteMatch<{ jobLogId: string; jobLogNoteId: string }>([
    projectPaths.jobLogNoteDrawer,
    developmentPaths.jobLogNoteDrawer,
  ]);
  const jobLogId = jobLogNoteDrawerMatch?.params?.jobLogId;
  const jobLogNoteId = jobLogNoteDrawerMatch?.params?.jobLogNoteId;

  const tid = useTestIds({}, "jobLogsPage");

  async function onDateSelect(date: Date) {
    const datePicked = date.toString();
    let jobLogForDate: JobLogDetailFragment | undefined = sortedJobLogs.find(
      (log: JobLogDetailFragment) => log.logDate.toString() === datePicked,
    );
    // if log for that day does not exist
    if (!jobLogForDate) {
      const { data } = await saveJobLog({
        variables: {
          input: {
            projectId: projectId,
            logDate: new DateOnly(date),
          },
        },
      });
      await refetch();
      jobLogForDate = data?.saveJobLog.jobLog;
    }

    if (jobLogForDate) {
      openRightPane({
        content: (
          <JobLogNoteRightPaneContent
            jobLogId={jobLogForDate.id}
            currentUser={currentUser}
            developmentId={developmentId}
            refetchJobLogs={refetch}
            projectId={projectId}
          />
        ),
      });
    }
  }

  const datePicker = (
    <ButtonDatePicker
      trigger={{ label: "Add new job log", variant: "primary", icon: "plus" }}
      value={new Date()}
      onSelect={onDateSelect}
      disabledDays={disabledDays}
      data-testid="addJobLog"
      dottedDays={daysWithJobLogs}
      disabled={canEdit?.disabledReasons[0]?.message}
    />
  );

  // Open job log drawer from route param
  useEffect(
    () => {
      if (jobLogId) {
        const currentRow = rows.find((r) => r.id === jobLogId);
        const jobLog = hasData(query) && query.data.jobLogsPage.entities.find((j) => j.id === jobLogId);
        const jobLogNote = (jobLog as JobLogDetailFragment)?.notes?.find((n) => n.id === jobLogNoteId);
        if (jobLog && currentRow && jobLogNoteId && jobLogNote) {
          push(
            `${
              isProject
                ? createProjectJobLogNoteDrawerUrl(jobLog.project.id, jobLogId, jobLogNoteId)
                : createDevelopmentJobLogNoteDrawerUrl(developmentId, jobLogId, jobLogNoteId)
            }${window.location.search}`,
          );

          openRightPane({
            content: (
              <JobLogNoteRightPaneContent
                jobLogId={jobLog.id}
                currentUser={currentUser}
                jobLogNote={jobLogNote}
                developmentId={developmentId}
                refetchJobLogs={refetch}
                projectId={projectId}
              />
            ),
          });
        }
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, jobLogId, jobLogNoteId],
  );

  // Close drawer on page unmount
  useEffect(
    () => {
      return () => {
        closeRightPane();
      };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // moreFilters responsiveness
  const [numberOfInlineFilters, setNumberOfInlineFilters] = useState(2);
  useEffect(() => {
    if (breakpoints.smOrMd) {
      setNumberOfInlineFilters(1);
    } else if (breakpoints.lg) {
      setNumberOfInlineFilters(2);
    }
  }, [breakpoints]);

  const hasNextPage = query.data?.jobLogsPage.pageInfo.hasNextPage ?? false;

  const maybeFetchNextPage = useCallback(async () => {
    if (hasNextPage) {
      await query.fetchMore({
        variables: { page: { offset: query.data?.jobLogsPage.entities?.length, limit: PAGE_SIZE } },
      });
    }
  }, [query, hasNextPage]);

  // Handle loading state
  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  return gallery ? (
    <JobLogsGalleryView
      jobLogs={sortedJobLogs}
      setGallery={setGallery}
      filter={filter}
      filterDefs={filterDefs}
      numberOfInlineFilters={numberOfInlineFilters}
      setFilter={setFilter}
      zipName={zipName}
      maybeFetchNextPage={maybeFetchNextPage}
      hasNextPage={hasNextPage}
    />
  ) : (
    <div>
      <PageHeader
        title="Job Logs"
        left={
          <>
            <Filters<JobLogFilterType>
              filter={filter}
              onChange={setFilter}
              filterDefs={filterDefs}
              numberOfInlineFilters={numberOfInlineFilters}
            />
            <Switch label="Gallery" selected={gallery} onChange={setGallery} labelStyle="inline" {...tid.gallery} />
          </>
        }
        right={
          <>
            <SearchBox onSearch={setSearchFilter} />
            {isProject && datePicker}
          </>
        }
      />
      <div css={Css.mt(-3).h100.$} data-testid="jobLogsPageWrapper">
        {rows.length > 0 ? (
          <ScrollableContent virtualized bgColor={Palette.Gray100}>
            <div css={Css.h100.mr3.$}>
              <RightPaneLayout paneWidth={448}>
                <GridTable
                  as="virtual"
                  rows={rows}
                  rowStyles={rowStyles}
                  columns={columns}
                  rowLookup={rowLookup}
                  style={style}
                  filter={searchFilter}
                  infiniteScroll={{
                    onEndReached: maybeFetchNextPage,
                  }}
                />
              </RightPaneLayout>
            </div>
          </ScrollableContent>
        ) : (
          <div css={Css.df.fdc.aic.$}>
            <div css={Css.ptPx(36).pb5.$}>
              <JobLogNoRowsImage />
            </div>
            <div css={Css.base.pb2.$}>No job logs found. Create your first job log for this project.</div>
            {isProject && datePicker}
          </div>
        )}
      </div>
    </div>
  );
}

function createColumns(currentUser: InternalUser, refetch: () => void): GridColumn<JobLogRow>[] {
  const jobLogColumn: GridColumn<JobLogRow> = {
    jobLog: (jobLog) => ({
      content: <JobLog jobLog={jobLog} currentUser={currentUser} refetchJobLogs={refetch} />,
      value: searchString(jobLog),
    }),
  };
  return [jobLogColumn];
}

function createRows(jobLogs: JobLogDetailFragment[]): GridDataRow<JobLogRow>[] {
  return jobLogs.map(createRow);
}

function createRow(jobLog: JobLogDetailFragment) {
  return {
    kind: "jobLog",
    id: jobLog.id,
    data: jobLog,
  } as JobLogRow;
}

function createRowStyles(compact: boolean): RowStyles<JobLogRow> {
  return {
    jobLog: {
      cellCss: Css.bcTransparent.important.mb0.bn.jcc.maxwPx(compact ? SmallJobLogWidth : JobLogWidth).$,
    },
  };
}

// TODO: Move to proper file?
export function formatDate(date: Date) {
  return format(date, "EEEE, MMMM d");
}

function searchString(jobLog: JobLogDetailFragment) {
  const { logDate, weatherType, notes, project } = jobLog;
  const formattedDate = formatDate(logDate);
  const noteStrings = notes.map((note) => {
    const noteType = note.type;
    const name = note.internalUser.name;
    const descriptions = note.content;
    const title = note.title;
    return `${noteType} ${name} ${descriptions} ${title}`;
  });
  const projectName = project.name;
  const searchStrings = [formattedDate, weatherType, projectName, ...noteStrings].map((s) => s && noCase(s));
  return searchStrings.join(" ");
}

export const jobLogNoteTypes = Object.entries(JobLogNoteType).map(([name, value]) => ({
  value: value,
  label: sentenceCase(name),
}));

export const jobLogFlags = Object.entries(JobLogFlag).map(([name, value]) => ({
  id: value,
  name: sentenceCase(name),
  value: value,
  label: sentenceCase(name),
}));

type JobLogRow = {
  kind: "jobLog";
  id: string;
  data: JobLogDetailFragment;
};

const style: GridStyle = {
  rowHoverColor: Palette.Gray100,
  rootCss: Css.wPx(JobLogWidth).mxa.$,
};
