import { Css } from "@homebound/beam";
import { emptyCellDash } from "src/components";

type InternalUser = {
  name: string;
  avatar: string;
};

export function internalUserAvatar(iu: InternalUser) {
  return (iu.avatar || iu.name) && <img src={iu.avatar} alt={iu.name} css={Css.br12.wPx(24).hPx(24).objectCover.$} />;
}

export function internalUserMenuLabel(iu: InternalUser) {
  return (
    <div css={Css.df.aifs.gap1.$}>
      {(iu.avatar || iu.name) && <img src={iu.avatar} alt={iu.name} css={Css.br12.wPx(24).hPx(24).fs0.objectCover.$} />}
      <span>{iu.name || emptyCellDash}</span>
    </div>
  );
}
