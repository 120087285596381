import { BoundSelectField } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import {
  CostSourceSelect_BidContractLineItemFragment,
  Maybe,
  useCostSourceSelectLazyQuery,
} from "src/generated/graphql-types";
import { CostSourceType } from "src/routes/projects/change-events/ChangeEventLineItemsTable";
import { CostSourceSelectFieldProps } from "./CostSourceSelectField";

type CostSourceBoundSelectFieldProps = Omit<CostSourceSelectFieldProps, "onSelect"> & {
  field: FieldState<Maybe<string>>;
  onSelect?: (
    id: string | undefined,
    selectedCostSource: Pick<CostSourceSelect_BidContractLineItemFragment, "unitCostInCents"> | undefined,
  ) => void;
};

export function CostSourceBoundSelectField(props: CostSourceBoundSelectFieldProps) {
  const { field, filter, filterFn, readOnly, label, value, onSelect, ...others } = props;
  const [load, { data }] = useCostSourceSelectLazyQuery({ variables: { filter }, fetchPolicy: "cache-first" });
  return (
    <BoundSelectField
      {...others}
      field={field}
      readOnly={readOnly}
      label={label ?? "Cost Source"}
      unsetLabel={CostSourceType.MANUAL}
      options={{
        current: value ? value : undefined,
        load,
        options: data?.bidContractLineItems.filter((bcli) => !filterFn || filterFn(bcli)),
      }}
      {...(onSelect ? { onSelect } : {})}
    />
  );
}
