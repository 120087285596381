import { Css } from "@homebound/beam";
import { ArrowSlide } from "src/routes/projects/expenses/components/ArrowSlide";

type ProjectsSliderProps = {
  projectPosition: number;
  totalProjects: number;
  getSliderPosition: Function;
};

export function ProjectsSlider({ projectPosition, totalProjects, getSliderPosition }: ProjectsSliderProps) {
  return (
    <div css={Css.df.ml7.mr5.$} data-testid="projectsSlider">
      <ArrowSlide direction="left" onArrowClick={() => getSliderPosition("prev")} />
      <div css={Css.df.aic.fd("column").$}>
        <div css={Css.xl2Sb.$} data-testid="projectPositionBy">{`${projectPosition}/${totalProjects}`}</div>
        <p data-testid="projectsLabel">Projects</p>
      </div>
      <ArrowSlide direction="right" onArrowClick={() => getSliderPosition("next")} />
    </div>
  );
}
