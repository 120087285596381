import {
  Button,
  ButtonMenu,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContent,
  Tag,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { useCallback, useRef } from "react";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { createBillPageUrl, createCommitmentChangeOrderUrl, createCommitmentsUrl } from "src/RouteUrls";
import { AssetGallery, OpenGallery } from "src/components/assetGallery/AssetGallery";
import {
  CommitmentStatus,
  DocumentType,
  ProjectFeature,
  SaveCommitmentChangeOrderInput,
  useCommitmentChangeOrderPageQuery,
  useDeleteCommitmentChangeOrderMutation,
  useSaveCommitmentChangeOrderMutation,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks";
import { useMaybeAllocateItemTaskModal } from "src/hooks/useMaybeAllocateItemTaskModal";
import { useStripStage } from "src/hooks/useStripStage";
import { PandaDocUploadButton } from "src/routes/components/PandaDocUploadButton";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { UploadPriceAgreementModal } from "src/routes/developments/components/UploadPriceAgreementModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { CommitmentBillsTable } from "src/routes/projects/commitments/CommitmentBillsTable";
import { CommitmentChangeOrderEditor } from "src/routes/projects/commitments/CommitmentChangeOrderEditor";
import { ProjectParams } from "src/routes/routesDef";
import { commitmentStatusToTagTypeMapper, fail, noop, queryResult } from "src/utils";
import { useProjectContext } from "../context/ProjectContext";
import { CommitmentBillsTableV4 } from "./components/CommitmentBillsTableV4";
import ExceededRevisedBudgetModal from "./components/ExceedRevisedBudgetModal";
import { PandaDocModalContent } from "./components/PandaDocModalContent";

type CommitmentPageLocationState = {
  projectItemIds: string[];
};

export function CommitmentChangeOrderPage() {
  useStripStage();
  const { features, lotType } = useProjectContext();
  const showClickToPayFlag = features.includes(ProjectFeature.ClickToPay);
  const { openModal, closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { projectId, commitmentId } = useParams<ProjectParams & { commitmentId: string }>();
  const location = useLocation<CommitmentPageLocationState>();
  const history = useHistory();
  const projectItemIds = location.state?.projectItemIds || [];
  const onCompleteRedirectUrl = createCommitmentsUrl(projectId);
  const { mode, isNew, id, onEdit, onCancel, onSave } = useModeParam({
    listPageUrl: onCompleteRedirectUrl,
  });
  const query = useCommitmentChangeOrderPageQuery({
    variables: { isNew, commitmentId, coId: id || "", projectItemFilter: { id: projectItemIds } },
  });
  const [deleteChangeOrder] = useDeleteCommitmentChangeOrderMutation();
  const [save] = useSaveCommitmentChangeOrderMutation({ onCompleted: () => onSave() });
  const readOnly = mode === "read";
  const onDelete = useCallback(async () => {
    await deleteChangeOrder({
      variables: { input: { id: id || fail("Missing change order id") } },
    });
    history.push(onCompleteRedirectUrl);
  }, [deleteChangeOrder, history, id, onCompleteRedirectUrl]);
  const onVoid = useCallback(async () => {
    const data = await save({
      variables: { input: { id: id || fail("Missing change order id"), status: CommitmentStatus.Voided } },
      onCompleted: noop, // Remove onSave call
      refetchQueries: ["CommitmentChangeOrderPage"],
    });

    if (data) {
      triggerNotice({
        message: `Change Order ${id} has been successfully voided`,
        icon: "success",
      });
    }
  }, [save, id, triggerNotice]);
  const openGalleryRef = useRef<OpenGallery | null>(null);
  const maybeOpenAllocateModal = useMaybeAllocateItemTaskModal();

  return queryResult(query, {
    data: ({ commitment, commitmentChangeOrder, projectItems }) => {
      const isBidCommitment = !!commitmentChangeOrder && commitmentChangeOrder.isBidCommitment === true;
      const asset = commitmentChangeOrder?.document?.asset;

      const handleMaybeOpenAllocationModal = (otherwise: VoidFunction) => {
        maybeOpenAllocateModal({
          projectItems: commitmentChangeOrder?.lineItems.map((li) => li.projectItem) || [],
          // original op to perform if item allocation not allowed
          otherwise,
          projectId,
          stage: commitment.projectStage.stage.code,
          showClickToPayFlag,
          lotType,
        });
      };

      return (
        <>
          <PageHeader
            title={
              commitmentChangeOrder
                ? `#${commitmentChangeOrder.accountingNumber} ${commitmentChangeOrder.displayName}`
                : "Create Change Order"
            }
            breadcrumb={{ label: "All Commitments", href: createCommitmentsUrl(projectId) }}
            right={
              <>
                {asset && (
                  <AssetGallery assets={[asset]}>
                    {(openGallery) => {
                      openGalleryRef.current = openGallery;
                    }}
                  </AssetGallery>
                )}
                <ButtonMenu
                  trigger={{ label: "Actions" }}
                  items={[
                    ...(commitmentChangeOrder
                      ? [
                          {
                            label: "Upload Price Agreement",
                            disabled: commitmentChangeOrder.status !== CommitmentStatus.Draft,
                            onClick: () => {
                              if (commitmentChangeOrder.canBeReleased.allowed) {
                                handleMaybeOpenAllocationModal(() =>
                                  openModal({
                                    content: (
                                      <UploadPriceAgreementModal
                                        parentId={commitment.projectStage.project.id}
                                        ownerId={commitmentChangeOrder.id}
                                        documentType={DocumentType.PriceAgreementChangeOrder}
                                      />
                                    ),
                                  }),
                                );
                              } else {
                                openModal({
                                  content: (
                                    <ExceededRevisedBudgetModal
                                      projectId={projectId}
                                      commitmentLikeType="CommitmentChangeOrder"
                                    />
                                  ),
                                });
                              }
                            },
                          },
                        ]
                      : []),
                    {
                      label: "Re-Upload to PandaDoc",
                      disabled:
                        !commitmentChangeOrder?.canCreatePandaDoc?.allowed ||
                        !commitmentChangeOrder.pandaDoc?.externalPandaDocUrl ||
                        isBidCommitment,
                      onClick: () => {
                        openModal({
                          content: <PandaDocModalContent ownerId={commitmentChangeOrder!.id} isReUpload />,
                        });
                      },
                    },
                    {
                      label: "View PDF",
                      disabled: !asset,
                      onClick: () => asset && openGalleryRef.current?.(asset),
                    },
                    {
                      label: "Void",
                      disabled:
                        !commitmentChangeOrder?.id ||
                        (commitmentChangeOrder?.canVoid &&
                          disableBasedOnPotentialOperation(commitmentChangeOrder.canVoid)),
                      onClick: () =>
                        openModal({
                          content: (
                            <>
                              <ModalHeader>Void Change Order</ModalHeader>
                              <ModalBody>This will void the change order and cannot be undone.</ModalBody>
                              <ModalFooter>
                                <Button variant="tertiary" label="Cancel" onClick={closeModal} />
                                <Button
                                  data-testid="confirmVoid"
                                  label="Void"
                                  onClick={async () => {
                                    await onVoid();
                                    closeModal();
                                  }}
                                  variant="danger"
                                />
                              </ModalFooter>
                            </>
                          ),
                        }),
                    },
                    {
                      label: "Add Bill",
                      disabled: !(
                        commitmentChangeOrder &&
                        commitmentChangeOrder.status === CommitmentStatus.Signed &&
                        readOnly
                      ),
                      onClick: commitmentChangeOrder
                        ? createBillPageUrl({ commitmentLikeId: commitmentChangeOrder.id })
                        : noop,
                    },
                    {
                      label: "Add Vendor Credit",
                      disabled: !(commitmentChangeOrder && readOnly),
                      onClick: commitmentChangeOrder
                        ? createBillPageUrl({ commitmentLikeId: commitmentChangeOrder.id, credit: true })
                        : noop,
                    },
                    {
                      label: "Delete",
                      disabled:
                        !commitmentChangeOrder?.id ||
                        (commitmentChangeOrder?.canDelete &&
                          disableBasedOnPotentialOperation(commitmentChangeOrder.canDelete)),
                      onClick: () =>
                        openModal({
                          content: (
                            <>
                              <ModalHeader>Delete Change Order</ModalHeader>
                              <ModalBody>This will delete the change order and cannot be undone.</ModalBody>
                              <ModalFooter>
                                <Button variant="tertiary" label="Cancel" onClick={closeModal} />
                                <Button
                                  data-testid="confirmDelete"
                                  label="Delete"
                                  onClick={async () => {
                                    await onDelete();
                                    closeModal();
                                  }}
                                  variant="danger"
                                />
                              </ModalFooter>
                            </>
                          ),
                        }),
                    },
                  ]}
                />
                {commitmentChangeOrder && !isBidCommitment && (
                  <PandaDocUploadButton
                    parent={commitmentChangeOrder}
                    onUpload={() => {
                      handleMaybeOpenAllocationModal(() =>
                        openModal({
                          content: <PandaDocModalContent ownerId={commitmentChangeOrder.id} isReUpload={false} />,
                        }),
                      );
                    }}
                  />
                )}
              </>
            }
            left={
              commitmentChangeOrder && (
                <span css={Css.ttc.$}>
                  <Tag
                    type={commitmentStatusToTagTypeMapper[commitmentChangeOrder.status]}
                    text={commitmentChangeOrder.statusText}
                  />
                </span>
              )
            }
          />
          <ScrollableContent>
            <>
              <CommitmentChangeOrderEditor
                commitment={commitment}
                changeOrder={commitmentChangeOrder}
                initialProjectItems={projectItems || []}
                mode={mode}
                projectId={projectId}
                onEdit={onEdit}
                onSave={async (input: SaveCommitmentChangeOrderInput) => {
                  const { data } = await save({ variables: { input } });
                  const changeOrderId = data?.saveCommitmentChangeOrder.commitmentChangeOrder.id;
                  if (changeOrderId && mode === "create") {
                    triggerNotice({
                      icon: "success",
                      message: "Change Order successfully created",
                      action: {
                        label: "Go to Change Order",
                        onClick: createCommitmentChangeOrderUrl(projectId, commitment.id, changeOrderId),
                        variant: "tertiary",
                      },
                    });
                  }
                }}
                onCancel={onCancel}
                onDelete={onDelete}
              />
              {readOnly && commitmentChangeOrder && showClickToPayFlag ? (
                <CommitmentBillsTableV4 bills={commitmentChangeOrder.bills} />
              ) : null}
              {readOnly && commitmentChangeOrder && !showClickToPayFlag ? (
                <CommitmentBillsTable
                  bills={commitmentChangeOrder.bills}
                  commitmentId={commitment.id}
                  projectId={projectId}
                />
              ) : null}
            </>
          </ScrollableContent>
        </>
      );
    },
  });
}
