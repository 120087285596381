import { AvatarGroup, Css, Palette, Tag } from "@homebound/beam";
import { addWeeks, differenceInDays, startOfToday } from "date-fns";
import { useParams } from "react-router";
import { CommentCountBubble, formatDate, Icon } from "src/components";
import { ToDoChecklistStatus } from "src/components/to-dos/ToDoChecklistStatus";
import { ToDoCardFragment, ToDoStatus, ToDoType } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/Routes";
import { nonEmpty } from "src/utils";

type ToDoCardProps = {
  toDo: ToDoCardFragment;
};

export function ToDoCard(props: ToDoCardProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  const { toDo } = props;
  const { name, dueDate, streams, parent, type, status, urgent, checklistProgress } = toDo;
  const parentName = parent?.name;
  const hasComments = nonEmpty(streams);
  const showFooter = dueDate || hasComments || urgent;
  let isOverdue = false;
  let isWithinWeek = false;
  let today, dueDateAsDate, weekFromNow, dateDiffToday, dateDiffWeek;
  if (dueDate) {
    today = startOfToday();
    dueDateAsDate = dueDate as Date;
    weekFromNow = addWeeks(today, 1);
    dateDiffToday = differenceInDays(today, dueDateAsDate);
    dateDiffWeek = differenceInDays(weekFromNow, dueDateAsDate);
    isOverdue = dateDiffToday > 0;
    isWithinWeek = dateDiffWeek <= 7 && dateDiffWeek >= 0;
  }
  const css = Css.df.fdc.jcsb.bshBasic.w100.p2.br4.bgWhite.bw1.bss.bcTransparent
    .if(isOverdue)
    .bgRed50.bcRed400.if(isWithinWeek)
    .bgYellow50.bcYellow400.if(status.code === ToDoStatus.Complete).bgGreen50.bcGreen400.$;
  const dueDateCss = Css.df.fdc.jcc.gray600.tiny
    .if(isOverdue)
    .red700.if(isWithinWeek)
    .yellow700.if(status.code === ToDoStatus.Complete).green700.$;

  return (
    <div data-testid="toDoCard" css={css}>
      <div css={Css.df.fdr.jcsb.$}>
        <div css={Css.df.fdc.fg1.br4.$}>
          {type.code !== ToDoType.General && (
            <div css={Css.df.fdr.aic.mb2.mtPx(4).h2.$}>
              <label css={Css.ttu.tinySb.$}>{toDo.type.name}</label>
            </div>
          )}
          <span title={name}>{name}</span>
          {parentName && !projectId && (
            <div css={Css.mt2.h2.$}>
              <Tag text={parentName} type="neutral" />
            </div>
          )}
        </div>
      </div>
      {showFooter && (
        <div>
          <div css={Css.df.fdr.jcsb.mt5.h2.$}>
            <div css={dueDateCss}>{dueDate && <span>Due {formatDate(dueDate, "medium")}</span>}</div>
            <div css={Css.df.gap1.fdr.jcsb.aic.$}>
              {checklistProgress && <ToDoChecklistStatus toDoId={toDo.id} checklistProgress={checklistProgress} />}
              {hasComments && <CommentCountBubble xss={Css.m0.pr0.$} streams={streams} />}
              {toDo.urgent && <Icon color={Palette.Red600} icon="flag" />}
            </div>
          </div>
          <div css={Css.df.fdr.jcsb.mt1.$}>
            {toDo.assignees && (
              <AvatarGroup
                avatars={toDo.assignees.map((assignee) => ({
                  src: assignee.avatarUrl,
                  name: assignee.name,
                }))}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
