import { Css, Icon } from "@homebound/beam";
import { unparse } from "papaparse";
import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";

export type CsvUploaderProps = {
  label?: string;
  errors: string[] | undefined;
  onError: (reason: string) => void;
  onDrop: (data: CsvRow[], file?: { name: string; type: string }) => void;
};

/**
 * Provides an "upload CSV file" box that parses the CSV with react-papaparse.
 *
 * Most of our CSV parsing started out client-side, but you can post the CSV to the
 * server by using `unparseCsvRows` to get back a string to HTTP POST to the backend.
 * Ideally we'd HTTP post directly to the backend, but our files aren't really that
 * big, and this gives a better UX, I guess.
 */
export function CsvUploader(props: CsvUploaderProps) {
  const { label = "Drag & drop files", onError, onDrop, errors } = props;

  const [fileName, setFileName] = useState<string | undefined>();
  const ref = useRef<CSVReader>(null);

  function handleOnClick(e: any) {
    ref?.current?.open(e);
  }

  function handleOnError(err: any, file: any, inputElem: any, reason: string) {
    onError(reason);
    ref.current?.removeFile();
    setFileName(undefined);
  }

  function handleOnDrop(data: CsvRow[], file?: File) {
    setFileName(file?.name);
    onDrop(data, file);
    ref.current?.removeFile();
  }

  const hasErrors = !!errors && errors.length > 0;

  return (
    <CSVReader ref={ref} onDrop={handleOnDrop} onError={handleOnError} config={{ skipEmptyLines: true }} noProgressBar>
      {/* We are using a function since we want to control the children that are being rendered in this component. */}
      {() => (
        <div>
          <button
            onClick={handleOnClick}
            css={{
              ...Css.hPx(75)
                .w100.mt3.br8.bcGray700.ba.bsDashed.relative.add("overflow", "hidden")
                .df.aic.jcc.cursorPointer.add("transition", "all 0.2s")
                .base.df.aic.blue700.if(hasErrors).red700.$,
              ":hover": Css.bgGray300.$,
              // Background
              ":before": Css.add("content", "''")
                .bgGray300.add("opacity", 0.25)
                .absolute.left0.right0.top0.bottom0.z(-1).$,
            }}
          >
            <span css={Css.mr1.$}>
              <Icon icon={fileName ? "file" : "upload"} />
            </span>
            {fileName || label}
          </button>
          <div css={Css.m2.$}>{errors?.map((e, i) => <li key={i}>{e}</li>)}</div>
        </div>
      )}
    </CSVReader>
  );
}

/** Drops the `errors` / `meta` fields that are Papa parse info the backend doesn't want. */
export function unparseCsvRows(rows: CsvRow[]): string {
  return unparse(
    rows.map((row) => row.data),
    { newline: "\n" },
  );
}

// Based on https://react-papaparse.js.org/docs#results
export type CsvRow = {
  // We assume we're using header=false, otherwise this would be an object
  data: string[];
  errors: CsvRowError[];
  meta?: CsvRowMeta;
};

type CsvRowError = {
  type: "Quotes" | "Delimiter" | "FieldMismatch"; // A generalization of the error
  code: "MissingQuotes" | "UndetectableDelimiter" | "TooFewFields" | "TooManyFields"; // Standardized error code
  message: string; // Human-readable details
  row: number; // Row index of parsed data where error is
};

type CsvRowMeta = {
  delimiter: string; // Delimiter used
  linebreak: string; // Line break sequence used
  aborted: boolean; // Whether process was aborted
  fields: string[]; // Array of field names
  truncated: false; // Whether preview consumed all input
};
