import {
  Button,
  column,
  Css,
  dateColumn,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  multiFilter,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  usePersistedFilter,
} from "@homebound/beam";
import { MutableRefObject, useMemo, useRef, useState } from "react";
import { QueryTable, SearchBox } from "src/components";
import { baseDownloadUrl } from "src/context";
import {
  GlobalOptionsDetailFragment,
  GlobalOptionsFilter,
  GlobalOptionsPageMetadataQuery,
  GlobalOptionStatus,
  useGlobalOptionsPageMetadataQuery,
  useGlobalOptionsQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { PageHeader } from "src/routes/layout/PageHeader";
import { GlobalOptionsTagColumn } from "src/routes/libraries/global-options/components/GlobalOptionsTagColumn";
import { useOptionDrawer } from "src/routes/libraries/global-options/GlobalOptionDrawer";
import { queryResult } from "src/utils";
import { formatWithShortYear } from "src/utils/dates";
import { openNewTab } from "src/utils/window";

export function GlobalOptionsPage() {
  const query = useGlobalOptionsPageMetadataQuery();
  useDocumentTitle("Global Options");
  return queryResult(query, {
    data: ({ globalOptionTypes, locations, developments }) => (
      <GlobalOptionsView
        globalOptionTypes={globalOptionTypes}
        globalOptionsLocations={locations}
        developmentsOptions={developments}
      />
    ),
  });
}

export type GlobalOptionsViewProps = {
  globalOptionTypes: GlobalOptionsPageMetadataQuery["globalOptionTypes"];
  globalOptionsLocations: GlobalOptionsPageMetadataQuery["locations"];
  developmentsOptions: GlobalOptionsPageMetadataQuery["developments"];
};

export function GlobalOptionsView({
  globalOptionTypes,
  globalOptionsLocations,
  developmentsOptions,
}: GlobalOptionsViewProps) {
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const { openOptionDrawer } = useOptionDrawer();

  const filterDefs: FilterDefs<GlobalOptionsFilter> = useMemo(() => {
    const goStatuses = [
      { name: "Active", status: GlobalOptionStatus.Active },
      { name: "Archived", status: GlobalOptionStatus.Archived },
    ];

    // Type
    const type = multiFilter({
      label: "Option type",
      options: globalOptionTypes.filter((got) => got.name !== "Plan Package"),
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    // Status
    const status = multiFilter({
      label: "Status",
      options: goStatuses,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ status }) => status,
    });
    // Location
    const locations = multiFilter({
      label: "Locations",
      options: globalOptionsLocations,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    // Developments
    const developments = multiFilter({
      label: "Developments",
      options: developmentsOptions.sortByKey("name"),
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    return { type, status, locations, developments };
  }, [globalOptionTypes, globalOptionsLocations, developmentsOptions]);

  const { filter, setFilter } = usePersistedFilter<GlobalOptionsFilter>({
    storageKey: "globalOptionsFilter",
    filterDefs,
  });

  const query = useGlobalOptionsQuery({ variables: { filter: { ...filter, isPlanPackageType: false } } });
  const rowLookup = useRef<GridRowLookup<Row>>();
  const rowStyles = useMemo(() => createRowStyles(rowLookup, openOptionDrawer), [rowLookup, openOptionDrawer]);

  return (
    <>
      <PageHeader
        title="Options"
        right={
          <>
            <Button
              label="Export"
              variant="secondary"
              onClick={async () => openNewTab(`${baseDownloadUrl()}/csv?type=globalOptions`)}
            />
            <Button
              label="Create option"
              variant="primary"
              onClick={() =>
                openOptionDrawer({
                  refetchOnCreate: async () => {
                    await query.refetch();
                  },
                })
              }
            />
          </>
        }
      />
      <div css={Css.my2.df.jcsb.$}>
        <div>
          <Filters<GlobalOptionsFilter> filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        </div>
        <SearchBox onSearch={setSearchFilter} />
      </div>
      <ScrollableContent virtualized>
        <QueryTable
          as="virtual"
          stickyHeader
          filter={searchFilter}
          sorting={{ on: "client" }}
          query={query}
          columns={columns}
          createRows={(data) => simpleDataRows(data?.globalOptions)}
          rowStyles={rowStyles}
          rowLookup={rowLookup}
          fallbackMessage="No global options found"
          style={{ allWhite: true, bordered: true }}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<GlobalOptionsDetailFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({ header: "Code", data: ({ code }) => code, w: "110px" }),
  column<Row>({ header: "Internal name", data: ({ name }) => name, mw: "240px" }),
  column<Row>({
    header: "Group",
    // For single option groups, don't needlessly repeat the name
    data: (data) => (data.group.isSingleOptionGroup ? "" : data.group.name),
    mw: "100px",
  }),
  column<Row>({ header: "Option type", data: ({ type }) => type.name, w: "160px" }),
  column<Row>({
    header: "Location",
    data: ({ locations }) => locations.map(({ name }) => name).join(", "),
    w: "280px",
  }),
  column<Row>({
    header: "Tag",
    data: (option) => <GlobalOptionsTagColumn option={option} />,
    wrapAction: false,
    clientSideSort: false,
    w: "280px",
  }),
  column<Row>({ header: "Status", data: ({ statusDetail }) => statusDetail.name, w: "100px" }),
  dateColumn<Row>({
    header: "Active date",
    data: ({ activeDate, archivedDate }) => ({
      content: activeDateToArchivedCell(activeDate, archivedDate),
    }),
    w: "160px",
    clientSideSort: false,
  }),
];

function activeDateToArchivedCell(
  activeDate: GlobalOptionsDetailFragment["activeDate"],
  archivedDate: GlobalOptionsDetailFragment["archivedDate"],
) {
  if (activeDate && archivedDate) {
    return `${formatWithShortYear(activeDate)} - ${formatWithShortYear(archivedDate)}`;
  }
  return activeDate ? `${formatWithShortYear(activeDate)} - present` : `none`;
}

function createRowStyles(
  lookup: MutableRefObject<GridRowLookup<Row> | undefined>,
  openOptionDrawer: ReturnType<typeof useOptionDrawer>["openOptionDrawer"],
): RowStyles<Row> {
  function openRow(row: GridDataRow<Row>): void {
    if (row.kind === "data") {
      const { prev, next } = lookup.current!.lookup(row)["data"];
      const globalOption = row["data"];
      const opts = {
        onPrevClick: prev && (() => openRow(prev)),
        onNextClick: next && (() => openRow(next)),
      };

      openOptionDrawer({ optionId: globalOption.id }, opts);
    }
  }

  return {
    data: { onClick: openRow },
  };
}
