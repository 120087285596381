import { BoundSelectField, BoundSelectFieldProps } from "@homebound/beam";
import { ItemFilter, ItemsDataFragment, useItemsSelectQuery } from "src/generated/graphql-types";

type ItemsBoundSelectFieldProps = Omit<
  BoundSelectFieldProps<ItemsDataFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  getOptionLabel?: (o: ItemsDataFragment) => string;
  getOptionValue?: (o: ItemsDataFragment) => string;
  filter?: ItemFilter;
};

export function ItemsBoundSelectField(props: ItemsBoundSelectFieldProps) {
  const {
    placeholder = "Select an Item",
    getOptionLabel = ({ fullCode, name }) => `${fullCode} ${name}`,
    getOptionValue = (o) => o.id,
    filter = {},
    ...otherProps
  } = props;
  const { data, loading } = useItemsSelectQuery({
    fetchPolicy: "cache-first",
    variables: { filter: filter },
  });
  return (
    <BoundSelectField
      {...otherProps}
      placeholder={loading ? "Loading..." : placeholder}
      options={data?.items ?? []}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
}
