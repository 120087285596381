import { Css, LoadingSkeleton, Palette, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { PieChart } from "src/components/charts/PieChart";
import { BillsChartsQuery, useBillsChartsQuery } from "src/generated/graphql-types";
import { formatCentsToPrice, formatNumberToString } from "src/utils";
import { BillsPageFilter, mapToFilter } from "./BillsPage";

export function BillsCharts({ filter, search }: { filter: BillsPageFilter; search: string }) {
  const { data, loading } = useBillsChartsQuery({ variables: { filter: { ...mapToFilter(filter), search } } });

  const testId = useTestIds({}, "billsCharts");

  const chartData = useMemo(() => {
    if (loading || !data?.billMetrics) return LOADING_DATA;
    return mapToChartData(data.billMetrics);
  }, [data, loading]);

  return (
    <div css={Css.w100.mb3.$}>
      <div css={Css.p2.df.gap4.mb1.bb.bw1.bcGray200.bgWhite.br8.bshBasic.$} {...testId}>
        {chartData.map(({ title, total, dataset, tooltipValueFormat }, index) => (
          <div key={title} css={Css.fg1.br.bw1.bcGray200.$} {...testId[title.replace(/ /g, "_")]}>
            <div css={Css.df.$}>
              <div css={Css.fg1.df.fdc.jcsb.$}>
                <span css={Css.smMd.gray700.$}>{title}</span>
                <span css={Css.xl3Sb.$}>{loading ? <LoadingSkeleton rows={1} columns={1} /> : total}</span>
              </div>
              <div css={Css.fg1.aic.jcc.ta("center").wPx(76).hPx(76).mwPx(76).mx4.$}>
                <PieChart
                  dataset={dataset}
                  loadingColor={LOADING_COLOR}
                  disabled={!total}
                  tooltipPosition={index >= 2 ? "left" : "right"}
                  tooltipValueFormat={tooltipValueFormat}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ChartTotal({ totalChartCount, totalChartAmount }: { totalChartCount: string; totalChartAmount: string }) {
  return (
    <div css={Css.ml2.xlBd.$}>
      <div>{totalChartCount} Bills</div>
      <div>{totalChartAmount}</div>
    </div>
  );
}

function mapToChartData(data: BillsChartsQuery["billMetrics"]) {
  if (!data) return [];
  const {
    openPayables,
    totalOpenPayables,
    agingOfPayables,
    totalAgingOfPayables,
    awaitingApprovals,
    totalAwaitingApprovals,
  } = data;

  return [
    {
      title: CHARTS[0],
      total: (
        <ChartTotal
          totalChartCount={formatNumberToString(totalOpenPayables, true)}
          totalChartAmount={formatCentsToPrice(openPayables.sum((item) => item.totalAmount))}
        />
      ),
      tooltipValueFormat: (value: number) => formatCentsToPrice(value),
      dataset: !openPayables
        ? EmptyData
        : openPayables.map(({ label, totalAmount }) => ({ id: `${label}`, value: totalAmount })),
    },
    {
      title: CHARTS[1],
      total: (
        <ChartTotal
          totalChartCount={formatNumberToString(totalAgingOfPayables, true)}
          totalChartAmount={formatCentsToPrice(agingOfPayables.sum((item) => item.totalAmount))}
        />
      ),
      tooltipValueFormat: (value: number) => formatCentsToPrice(value),
      dataset: !agingOfPayables
        ? EmptyData
        : agingOfPayables.map(({ label, totalAmount }) => ({ id: `${label}`, value: totalAmount })),
    },
    {
      title: CHARTS[2],
      total: (
        <ChartTotal
          totalChartCount={formatNumberToString(totalAwaitingApprovals, true)}
          totalChartAmount={formatCentsToPrice(awaitingApprovals.sum((item) => item.totalAmount))}
        />
      ),
      tooltipValueFormat: (value: number) => formatCentsToPrice(value),
      dataset: !awaitingApprovals
        ? EmptyData
        : awaitingApprovals.map(({ label, totalAmount }) => ({ id: `${label}`, value: totalAmount })),
    },
  ];
}

const EmptyData = [{ id: null, value: true }];
const LOADING_COLOR = [Palette["Gray200"]];
const CHARTS = ["Total Open Payables", "Payables Aging", "Bills Awaiting Approvals"];
const LOADING_DATA = CHARTS.map((title) => ({
  title,
  total: 0,
  tooltipValueFormat: undefined,
  dataset: EmptyData,
}));
