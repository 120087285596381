import {
  BoundCheckboxField,
  BoundTextField,
  Button,
  Css,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import {
  ChangeRequestsDependencyModalDocument,
  InputMaybe,
  useChangeRequestGroupModalQuery,
  useDeleteChangeRequestGroupMutation,
  useSaveChangeRequestGroupMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

type ChangeRequestGroupModalProps = {
  id: string;
};

export function ChangeRequestGroupModal({ id }: ChangeRequestGroupModalProps) {
  const { openModal, closeModal } = useModal();

  const { data } = useChangeRequestGroupModalQuery({ variables: { id } });
  const changeRequestGroup = data?.changeRequestGroup;

  const [saveChangeRequestGroup] = useSaveChangeRequestGroupMutation();
  const [deleteChangeRequestGroup] = useDeleteChangeRequestGroupMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: {
        id,
        name: changeRequestGroup?.name || "",
        salesforceOpportunityLink: changeRequestGroup?.salesforceOpportunityLink || "",
        underContract: changeRequestGroup?.underContract || false,
      },
    },
  });

  // Update form state when the data is loaded
  useEffect(() => {
    formState.set({
      id,
      name: changeRequestGroup?.name || "",
      salesforceOpportunityLink: changeRequestGroup?.salesforceOpportunityLink || "",
      underContract: changeRequestGroup?.underContract || false,
    });

    formState.commitChanges();
  }, [changeRequestGroup, formState, id]);

  const handleSubmit = useCallback(async () => {
    if (formState.valid) {
      await saveChangeRequestGroup({ variables: { input: formState.value } });
      closeModal();
    }
  }, [closeModal, formState, saveChangeRequestGroup]);

  const handleDeleteGroup = useCallback(async () => {
    await deleteChangeRequestGroup({
      variables: { input: { id } },
      refetchQueries: [ChangeRequestsDependencyModalDocument],
    });
    closeModal();
  }, [closeModal, deleteChangeRequestGroup, id]);

  const handleDelete = useCallback(async () => {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage="Are you sure you want to delete this group?"
          onConfirmAction={handleDeleteGroup}
          title="Delete Group"
          label="Delete"
          danger={true}
        />
      ),
    });
  }, [handleDeleteGroup, openModal]);

  return (
    <div>
      <Observer>
        {() => (
          <>
            <ModalHeader>Edit Group</ModalHeader>
            <ModalBody>
              <div css={Css.df.gap2.$}>
                <div css={Css.df.fdc.flexGrow(1).gap2.$}>
                  <BoundTextField label="Group Name" field={formState.name} />
                  <BoundTextField label="Salesforce Opportunity Link" field={formState.salesforceOpportunityLink} />
                  <BoundCheckboxField label="Client Confirmation" field={formState.underContract} />
                </div>
                <div css={Css.mt4.$}>
                  <IconButton icon="trash" onClick={handleDelete} />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="text" label="Cancel" onClick={closeModal} />
              <Button
                variant="primary"
                label="Save"
                onClick={handleSubmit}
                disabled={!formState.canSave() && formState.errors.join(", ")}
              />
            </ModalFooter>
          </>
        )}
      </Observer>
    </div>
  );
}

type ChangeRequestGroupForm = {
  id: string;
  name: InputMaybe<string>;
  salesforceOpportunityLink: InputMaybe<string>;
  underContract: InputMaybe<boolean>;
};

const formConfig: ObjectConfig<ChangeRequestGroupForm> = {
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  salesforceOpportunityLink: { type: "value" },
  underContract: { type: "value" },
};
