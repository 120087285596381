import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent, useSessionStorage } from "@homebound/beam";
import { AdminCostCodeFragment } from "src/generated/graphql-types";
import { CostCodeOverviewTab } from "./CostCodeOverviewTab";
import { ItemsDrawer } from "./ItemsDrawer";

type CostCodesSuperDrawerTabsProps = {
  costCode: AdminCostCodeFragment;
};

export function CostCodesSuperDrawerTabs({ costCode }: CostCodesSuperDrawerTabsProps) {
  const { id, name } = costCode;
  const [selectedTab, setSelectedTab] = useSessionStorage("costCodesSuperDrawerTab", "overview");

  const overviewTab = {
    name: "Overview",
    value: "overview",
    render: () => <CostCodeOverviewTab costCodeId={id} />,
  };

  const itemsTab = {
    name: "Items",
    value: "items",
    render: () => <ItemsDrawer id={id} />,
  };

  return (
    <>
      <SuperDrawerHeader title={name} />
      <SuperDrawerContent>
        <TabsWithContent tabs={[overviewTab, itemsTab]} selected={selectedTab} onChange={setSelectedTab} />
      </SuperDrawerContent>
    </>
  );
}
