import { useApolloClient } from "@apollo/client";
import {
  BoundSelectField,
  Button,
  Css,
  FieldGroup,
  FormLines,
  Icon,
  Palette,
  StaticField,
  Tooltip,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { createTradePartnerUrl, createTradePartnersUrl } from "src/RouteUrls";
import { Price } from "src/components";
import {
  CommitmentStatus,
  ProjectFeature,
  SaveScheduleTaskInput,
  ScheduleSettingDetailsFragment,
  ScheduleStatus,
  TaskDetailPaneFragment,
  TaskDetailPaneProjectItemDetailFragment,
  TaskFilterOptionsFragment,
  TaskTradePartnerFragment,
} from "src/generated/graphql-types";
import { BaselineChip } from "src/routes/components/BaselineChip";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { TaskChecklistItems } from "src/routes/projects/schedule-v2/detail-pane/TaskChecklistItems";
import { formatList, safeEntries, sum, tradePartnerStatusTypeToNameMapper } from "src/utils";
import { formatWithShortYear } from "src/utils/dates";
import { MaybeRenderForScheduleType } from "../MaybeRenderForScheduleType";
import { getScheduleTeamMembers } from "../Schedule";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { useTaskBillContext } from "../contexts/TaskBillModalContext";
import { setTaskPaneState } from "../contexts/scheduleStoreReducer";
import { DateCellField } from "../table/DateCellField";
import { DurationCellField } from "../table/DurationCellField";
import { ScheduleType } from "../table/ScheduleType";
import { cacheKeyFromId } from "../table/utils";
import { RoleSelectField } from "./RoleSelectField";
import { TaskDependencies } from "./TaskDependencies";
import { FormValue } from "./TaskDetailPane";
import { ScheduleFlags } from "./scheduleFlags/ScheduleFlags";

type TaskDetailsProps = {
  scheduleIsLocked: boolean;
  isTemplate: boolean;
  tradePartners: TaskTradePartnerFragment[];
  formState: FormValue;
  task: TaskDetailPaneFragment;
  scheduleSetting?: ScheduleSettingDetailsFragment | null;
  scheduleStatus?: ScheduleStatus;
  tasks: TaskFilterOptionsFragment[];
  onSave: (changedValue: SaveScheduleTaskInput) => void;
  onJump: (id: string) => void;
};

export function TaskDetails({
  scheduleIsLocked,
  isTemplate,
  tradePartners,
  formState,
  task,
  scheduleSetting,
  scheduleStatus,
  tasks,
  onSave,
  onJump,
}: TaskDetailsProps) {
  const [showBaselines, setShowBaselines] = useState<boolean>(false);
  const { openModal } = useModal();
  const client = useApolloClient();
  const {
    dispatch,
    scheduleState: {
      scheduleType,
      taskPaneState: { scrollIntoViewType },
    },
  } = useScheduleStore();
  const showClickToPayFlag = task?.project?.features.includes(ProjectFeature.ClickToPay);
  const showBaselineSection = task?.baselineInterval2?.startDate && task?.baselineInterval2?.endDate;
  const refFlags = useRef<HTMLDivElement | null>(null);
  const refChecklist = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (scrollIntoViewType === "flags" && refFlags.current) {
      refFlags.current.scrollIntoView({ behavior: "smooth" });
      dispatch(setTaskPaneState({ scrollIntoViewType: undefined }));
    }

    if (scrollIntoViewType === "checklistItems" && refChecklist.current) {
      refChecklist.current.scrollIntoView({ behavior: "smooth" });
      dispatch(setTaskPaneState({ scrollIntoViewType: undefined }));
    }
  }, [dispatch, scrollIntoViewType]);

  const onDelete = () => {
    let confirmationElement = <>Are you sure you want to delete this task?</>;
    // comments associated
    const commentCount = task.streams.map(({ commentCount }) => commentCount).reduce(sum, 0) || 0;
    const hasComments = commentCount > 0;
    // cost associated
    const costCount = task.projectItems.length || 0;
    const hasCost = costCount > 0;
    // comments and cost associated
    if (hasCost || hasComments) {
      const associatedText = hasComments && hasCost ? "comments and costs" : hasComments ? "comments" : "costs";
      confirmationElement = (
        <div>
          {hasCost && <div css={Css.mb2.ttu.$}>Warning</div>}
          {`You are about to delete a task with ${associatedText} associated, are you sure you want to delete?`}
        </div>
      );
    }

    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={confirmationElement}
          title="Delete Task"
          onConfirmAction={async () => {
            await onSave({ id: task.id, delete: true });
            client.cache.evict({ id: cacheKeyFromId(task.id) });
            dispatch(setTaskPaneState({ taskPaneId: undefined, tab: undefined }));
          }}
          label={isTemplate ? "Delete Task" : "Delete Task and Cost"}
          danger={hasCost || hasComments}
        />
      ),
    });
  };

  function OptionalFields() {
    // Template tasks and Cutoff and Key Phase `deadline` type tasks do not have trade partners
    if (!!task.deadlineType || isTemplate) return null;
    const uniqueCommittedTradePartners = task.committedTradePartners.uniqueByKey("id");
    return (
      <div css={Css.df.fdc.mt1.sm.mb1.$}>
        <div css={Css.df.aic.gap1.mb1.$}>
          <span css={Css.gray700.$}>Trade Partner</span>
          {uniqueCommittedTradePartners.length > 1 && (
            <Icon
              icon="error"
              color={Palette.Red600}
              tooltip="Only 1 trade partner per task is recommended. The trade partner schedule status only applies to the first trade partner."
            />
          )}
        </div>

        <div css={Css.df.fdc.bgGray100.br8.p2.gap1.$}>
          <Link
            to={task.tradePartner ? createTradePartnerUrl(task.tradePartner?.id) : createTradePartnersUrl()}
            target="_blank"
          >
            {task.tradePartner?.name ?? "--"}
          </Link>
          <div css={Css.df.fdr.aic.jcc.gap5.$}>
            <span css={Css.sm.gray700.$}>Scheduling</span>
            <BoundSelectField
              field={formState.tradePartnerStatus}
              label="Scheduling"
              disabled={scheduleIsLocked}
              options={safeEntries(tradePartnerStatusTypeToNameMapper)}
              getOptionLabel={(o) => o[1]}
              getOptionValue={(o) => o[0]}
              labelStyle="hidden"
              sizeToContent
              compact
            />
          </div>
        </div>
      </div>
    );
  }

  function TradePartnerList() {
    // Template tasks and Cutoff and Key Phase `deadline` type tasks do not have trade partners
    if (!!task.deadlineType || isTemplate) return null;
    // Make sure the clis we consider billable are on signed cos
    const taskAllocatedLineItems = task.projectItems
      .flatMap((pi) => pi.commitmentLineItems)
      .filter((cli) => cli.owner.status === CommitmentStatus.Signed);

    return (
      <div css={Css.df.fdc.gap1.$}>
        <div css={Css.df.fdc.mt1.sm.mb1.$}>
          {taskAllocatedLineItems.nonEmpty ? (
            <TradeBills allocatedTaskItems={taskAllocatedLineItems} />
          ) : (
            <span css={Css.smMd.$}>Task has no associated commitment trade partners</span>
          )}
        </div>
      </div>
    );
  }

  type AllocatedItemsByTrade = {
    trade: TaskDetailPaneProjectItemDetailFragment["commitmentLineItems"][number]["owner"]["tradePartner"];
    unbilledTaskItems: number;
  };

  function TradeBills({
    allocatedTaskItems,
  }: {
    allocatedTaskItems: TaskDetailPaneProjectItemDetailFragment["commitmentLineItems"];
  }) {
    const { openTaskBillModal } = useTaskBillContext();
    const tid = useTestIds({});

    const groupedByTrade = allocatedTaskItems.groupBy((li) => li.owner.tradePartner?.id ?? "");

    const itemsByTrade = Object.keys(groupedByTrade).reduce((input, key) => {
      const groupedItems: TaskDetailPaneProjectItemDetailFragment["commitmentLineItems"] = groupedByTrade[key];
      return input.concat({
        trade: groupedItems.first?.owner.tradePartner,
        unbilledTaskItems: groupedItems.sum((b) => b.pendingUnbilledInCents),
      } as AllocatedItemsByTrade);
    }, [] as AllocatedItemsByTrade[]);

    return (
      <>
        {itemsByTrade.map((cli) => (
          <div css={Css.df.fdc.w100.mb1.br8.bgGray100.aic.$} key={cli.trade?.id}>
            <div css={Css.dg.gtc("100px 100px 60px 60px").cg1.rg1.tiny.bgGray100.p2.aic.br8.$}>
              <Link css={Css.df.fdc.gap1.wbbw.$} data-testid="tradepartner" to={createTradePartnerUrl(cli.trade?.id)}>
                {cli.trade?.name}
              </Link>

              <div>Unbilled amount </div>

              <div css={Css.tinySb.$} {...tid.remainingBillable}>
                <Price valueInCents={cli.unbilledTaskItems} />
              </div>

              {cli.unbilledTaskItems !== 0 ? (
                cli.trade?.enableClickToPay ? (
                  <Button
                    label="Pay Trade"
                    onClick={() => {
                      openTaskBillModal({ taskId: task.id, tradePartnerId: cli.trade?.id });
                    }}
                    variant="text"
                    size="sm"
                  />
                ) : (
                  <Tooltip title="This trade is excluded from Click to Pay" placement="bottom">
                    <Button label="Pay Trade" onClick={() => {}} variant="text" disabled size="sm" />
                  </Tooltip>
                )
              ) : (
                <div>Bills Sent </div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  }

  function BaselineInterval() {
    const durationLabel = task.baselineInterval2?.durationInDays !== 1 ? "days" : "day";
    const startDate = task.baselineInterval2?.startDate ? formatWithShortYear(task.baselineInterval2?.startDate) : "";
    const endDate = task.baselineInterval2?.endDate ? formatWithShortYear(task.baselineInterval2?.endDate) : "";

    const baselineDeltaStart = {
      actualDate: task.interval.startDate.date,
      baselineDate: task.baselineInterval2?.startDate?.date,
    };
    const baselineDeltaEnd = {
      actualDate: task.interval.endDate.date,
      baselineDate: task.baselineInterval2?.endDate?.date,
    };
    const baselineDeltaDuration = {
      actualDuration: task.interval.durationInDays,
      baselineDuration: task.baselineInterval2?.durationInDays,
      baselineDate: task.baselineInterval2?.endDate?.date,
    };

    return (
      <div css={Css.df.mt2.$} data-testid="baselineInterval">
        <FieldGroup widths={["118px", "118px", "118px"]}>
          <div css={Css.df.aife.jcsb.$}>
            <StaticField label="Start" value={startDate} />
            <BaselineChip baselineDelta={baselineDeltaStart} status={task.status} scheduleSetting={scheduleSetting} />
          </div>
          <div css={Css.df.aife.jcsb.$}>
            <StaticField label="Finish" value={endDate} />
            <BaselineChip baselineDelta={baselineDeltaEnd} status={task.status} scheduleSetting={scheduleSetting} />
          </div>
          <div css={Css.df.aife.jcsb.$}>
            <StaticField label="Duration" value={`${task.baselineInterval2?.durationInDays} ${durationLabel}`} />
            <BaselineChip
              baselineDelta={baselineDeltaDuration}
              status={task.status}
              scheduleSetting={scheduleSetting}
            />
          </div>
        </FieldGroup>
      </div>
    );
  }

  function Baselines() {
    return (
      <>
        {showBaselines && <BaselineInterval />}
        <div css={Css.ml1.$}>
          <Button
            data-testid="baselineButton"
            variant="text"
            label={showBaselines ? "Hide Plan" : "Show Plan"}
            onClick={() => setShowBaselines(!showBaselines)}
          />
        </div>
      </>
    );
  }

  const teamMembers = useMemo(() => {
    return [...(task.assignee ? [task.assignee] : []), ...getScheduleTeamMembers(task.schedule.parent)].uniqueByKey(
      "id",
    );
  }, [task.assignee, task.schedule.parent]);

  return (
    <>
      <FormLines width="full">
        {task && (
          <div css={Css.df.gap2.$}>
            <DateCellField
              iconLeft={false}
              field="startDate"
              objectState={formState}
              editability={task}
              scheduleIsLocked={scheduleIsLocked}
              label="Start"
            />
            <DateCellField
              iconLeft={false}
              field="endDate"
              objectState={formState}
              editability={task}
              scheduleIsLocked={scheduleIsLocked}
              label="Finish"
            />
            <DurationCellField objectState={formState} editability={task} scheduleIsLocked={scheduleIsLocked} />
          </div>
        )}

        {scheduleStatus !== ScheduleStatus.Draft && showBaselineSection && <Baselines />}

        <TaskDependencies
          formState={formState}
          tasks={tasks}
          taskId={task.id}
          onSave={onSave}
          onJump={onJump}
          scheduleIsLocked={scheduleIsLocked}
          type="task"
        />

        {teamMembers && !isTemplate ? (
          <BoundSelectField
            label="Assignee"
            compact
            field={formState.internalUserId}
            options={teamMembers}
            fieldDecoration={(o) => (
              <img src={o.avatar} alt={o.name} css={Css.br100.wPx(24).hPx(24).objectCover.oh.$} />
            )}
            getOptionMenuLabel={(o) => (
              <div css={Css.df.aifs.gap1.$}>
                <img src={o.avatar} alt={o.name} css={Css.br100.wPx(24).hPx(24).objectCover.oh.$} />
                <span>{o.name}</span>
              </div>
            )}
            disabled={scheduleIsLocked}
          />
        ) : (
          <RoleSelectField formState={formState} scheduleIsLocked={scheduleIsLocked} />
        )}
        <OptionalFields />
        {showClickToPayFlag && <TradePartnerList />}
        <MaybeRenderForScheduleType renderForTypes={[ScheduleType.Project]}>
          <div ref={refFlags}>
            <ScheduleFlags task={task} />
          </div>
        </MaybeRenderForScheduleType>
      </FormLines>

      <div ref={refChecklist}>
        <TaskChecklistItems task={task} scheduleIsLocked={scheduleIsLocked} isTemplate={isTemplate} />
      </div>

      <div css={Css.p1.df.aic.jcc.bt.bcGray200.mxPx(-32).wPx(450).hPx(70).fixed.bottom0.bgWhite.$}>
        <Button
          data-testid="deleteTask"
          icon="trash"
          variant="tertiaryDanger"
          label="Delete Task"
          disabled={
            task.canDelete.allowed === false
              ? formatList(task.canDelete.disabledReasons.map((reason) => reason.message))
              : false
          }
          onClick={onDelete}
        />
      </div>
    </>
  );
}
