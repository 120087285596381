import { HasIdAndName, NestedOption, useComputed } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import {
  AllEnumDetails,
  AssetTreeSelectValuesFragment,
  ChangeRequestDrawer_ChangeRequestGroupFragment,
  ChangeType,
} from "src/generated/graphql-types";
import { CreateChangeRequestForm, getChangeRequestGroupNames } from "../CreateChangeRequestDrawer";
import { ChangeRequestFormDetails } from "./ChangeRequestFormDetails";

type AddAdditionalChangeRequestPageProps = {
  scopeSelectValues: NestedOption<HasIdAndName>[];
  changeRequestGroups: ChangeRequestDrawer_ChangeRequestGroupFragment[];
  enums: {
    changeRequestPriority: AllEnumDetails["changeRequestPriority"];
    changeType: AllEnumDetails["changeType"];
    changeRequestSource: AllEnumDetails["changeRequestSource"];
  };
  assets: AssetTreeSelectValuesFragment[];
  sortedChangeTypes: { code: ChangeType; name: string }[];
  formState: ObjectState<CreateChangeRequestForm>;
  currentPage: number;
};

export function AddAdditionalChangeRequestPage(props: AddAdditionalChangeRequestPageProps) {
  const { assets, changeRequestGroups, enums, scopeSelectValues, sortedChangeTypes, formState, currentPage } = props;

  const additionalChangeRequests = useComputed(() => {
    return formState.additionalChangeRequests.rows.map((os, i) => {
      const additionalChangeRequestGroupNames = getChangeRequestGroupNames(changeRequestGroups, os);
      return (
        <ChangeRequestFormDetails
          key={"cr" + (formState.title || i)}
          formState={os}
          enums={enums}
          scopeSelectValues={scopeSelectValues}
          changeRequestGroups={changeRequestGroups}
          sortedChangeTypes={sortedChangeTypes}
          assets={assets}
          currentPage={i + 1}
          changeRequestGroupNames={additionalChangeRequestGroupNames}
        />
      );
    });
  }, [formState.additionalChangeRequests]);
  // subtract 1 from the array since we start at page 1
  return additionalChangeRequests[currentPage - 1];
}
