import { Css, useTestIds } from "@homebound/beam";
import { Maybe } from "src/generated/graphql-types";

export type NumberProps = {
  id?: string;
  value: Maybe<number>;
  displayDirection?: boolean;
};

export function Number(props: NumberProps) {
  const { displayDirection = false, value } = props;
  const tid = useTestIds({}, "number");
  const directionStyles = typeof value === "number" && value < 0 ? Css.df.fdr.red600.$ : Css.df.fdr.green800.$;
  const prefix =
    displayDirection && typeof value === "number" && value >= 0 ? <span css={Css.sm.$}>+</span> : undefined;
  return (
    <span {...tid} css={Css.add(directionStyles).aic.$}>
      {prefix} <span>{!value || value < 0 ? value : `${value}`}</span>
    </span>
  );
}
