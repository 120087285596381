import { SelectField } from "@homebound/beam";
import {
  Maybe,
  ProductFilter,
  ProductSelect_ProductFragment,
  useProductSelectLazyQuery,
} from "src/generated/graphql-types";

export type ProductSelectFieldProps = {
  label?: string;
  /** We require a filter b/c we're not an autocomplete that can filter on the fly. */
  filter: ProductFilter;
  /** Optional filter fn to further filter the results. */
  filterFn?: (product: ProductSelect_ProductFragment) => boolean;
  value: Maybe<ProductSelect_ProductFragment>;
  onSelect: (selected: ProductSelect_ProductFragment | undefined) => void;
  disabled?: boolean;
  /** Product ids to disable. */
  disableOptions?: string[];
  readOnly?: boolean;
  unsetLabel?: string;
};

/**
 * A select field for selecting a product from the global product catalog.
 *
 * We only display the 1st 500 products that match the `filter`, so the expectation is that you've
 * provided a `filter` that is specific enough to not return too many results.
 */
export function ProductSelectField(props: ProductSelectFieldProps) {
  const { value, filterFn, onSelect, disabled, label = "Product", disableOptions, filter, ...others } = props;
  const [load, { data }] = useProductSelectLazyQuery({ variables: { filter: { ...filter }, first: 500 } });
  return (
    <SelectField<ProductSelect_ProductFragment, string>
      label={label}
      getOptionValue={(o) => o.id}
      getOptionLabel={optionDisplayName}
      disabledOptions={disableOptions}
      onSelect={(id, value) => onSelect(value)}
      value={value?.id}
      options={{
        current: value ?? undefined,
        load,
        options: data?.products.filter((p) => !filterFn || filterFn(p)),
      }}
      disabled={disabled}
      {...others}
    />
  );
}

function optionDisplayName(option: ProductSelect_ProductFragment) {
  return `${option.name}${option.sku ? ` ${option.sku}` : ""}${option.upc ? ` ${option.upc}` : ""}`;
}
