import { ButtonMenu, GridTableApi, MenuItem, useComputed, useModal, useToast } from "@homebound/beam";
import {
  HomeownerSelectionStatus,
  Named,
  ProjectItemInput,
  useDuplicateProjectItemsMutation,
  useSaveProjectItemsMutation,
} from "src/generated/graphql-types";
import { ObservableProjectItem } from "src/routes/projects/models/ObservableProjectItem";
import { ProjectItemStore, SpecsAndSelectionsRow } from "src/routes/projects/models/ProjectItemStore";
import { AllocateItemsModal } from "src/routes/projects/selections/bulkActions/AllocateItemsModal";
import { CreateCommitmentModal } from "src/routes/projects/selections/bulkActions/CreateCommitmentModal";
import { DeleteItemsModal } from "src/routes/projects/selections/bulkActions/DeleteItemsModal";
import { ProjectItemsExportModal } from "src/routes/projects/selections/bulkActions/ProjectItemsExportModal";
import { UpdateMarkupModal } from "src/routes/projects/selections/bulkActions/UpdateMarkupModal";
import { createProjectItemScopeOfWorkUrl, createScheduleOfValuesUrl } from "src/RouteUrls";
import { openNewTab } from "src/utils/window";

export type SpecsAndSelectionsBulkActionsMenuProps = {
  canEditProjectItemsDirectly: boolean;
  hasSignedContract: boolean;
  onSave: (...input: ProjectItemInput[]) => Promise<boolean>;
  projectId: string;
  projectStageId: string;
  scheduleTasks: Named[];
  store: ProjectItemStore;
  api: GridTableApi<SpecsAndSelectionsRow>;
};

export function SpecsAndSelectionsBulkActionsMenu(props: SpecsAndSelectionsBulkActionsMenuProps) {
  const {
    canEditProjectItemsDirectly,
    hasSignedContract,
    projectId,
    projectStageId,
    scheduleTasks,
    store,
    api,
    onSave,
  } = props;
  const [duplicateProjectItems] = useDuplicateProjectItemsMutation();
  const [saveProjectItems] = useSaveProjectItemsMutation();
  const { openModal } = useModal();
  const { showToast } = useToast();

  const [selectedProjectItems, selectedProjectItemIds] = useComputed(
    () =>
      api
        .getSelectedRows("projectItem")
        .reduce(
          (acc, row) => [acc[0].concat(row.data), acc[1].concat(row.id)],
          [[] as ObservableProjectItem[], [] as string[]],
        ),
    [api],
  );

  const hasZeroSelectedItems = selectedProjectItemIds.length === 0;
  const disabled = hasSignedContract || hasZeroSelectedItems;
  const pdfAction = (urlFunc: (projectItemIds: string[]) => string) => () => {
    openNewTab(urlFunc(selectedProjectItemIds));
    api.clearSelections();
  };

  const bulkMenuItems: MenuItem[] = [
    {
      disabled: hasZeroSelectedItems || !hasSignedContract,
      label: "Create Commitment",
      onClick: async () => {
        openModal({
          content: (
            <CreateCommitmentModal
              projectId={projectId}
              projectStageId={projectStageId}
              selectedProjectItemIds={selectedProjectItemIds}
            />
          ),
        });
      },
    },
    {
      disabled,
      label: "Create Schedule of Values (non-bought out items)",
      onClick: pdfAction(createScheduleOfValuesUrl),
    },
    {
      disabled,
      label: "Create Scope of Work (non-bought out items)",
      onClick: pdfAction(createProjectItemScopeOfWorkUrl),
    },
    {
      disabled: hasZeroSelectedItems || !canEditProjectItemsDirectly,
      label: "Delete Items",
      onClick: async () =>
        openModal({
          content: <DeleteItemsModal selectedProjectItemIds={selectedProjectItemIds} store={store} api={api} />,
        }),
    },
    {
      disabled: hasZeroSelectedItems || !canEditProjectItemsDirectly,
      label: "Duplicate Items",
      onClick: async () => {
        const duplicatedItems = await duplicateProjectItems({
          variables: { input: { projectItemIds: selectedProjectItemIds } },
          refetchQueries: ["SpecsAndSelectionItems"], // cache.modify was not always working for multiple items
        });
        const duplicatedPis = duplicatedItems.data!.duplicateProjectItems.projectItems;
        store.addProjectItems(duplicatedPis);
        store.selectedProjectItems.forEach((spi) => spi.toggleSelect());
        showToast({
          type: "success",
          message: `${duplicatedPis.length > 1 ? "Items have" : "Item has"} been duplicated`,
        });
      },
    },
    {
      disabled,
      label: "Finalize Product Selections",
      onClick: async () => {
        const selectedItemsWithSelection = selectedProjectItems
          // only include selected items that have a current selection
          .filter((selectedItem) => selectedItem.fragment.homeownerSelection?.selectedOption)
          .map((si) => ({ id: si.id, homeownerSelection: { status: HomeownerSelectionStatus.Finalized } }));
        await saveProjectItems({ variables: { input: { projectItems: selectedItemsWithSelection } } });
        api.clearSelections();
        showToast({
          type: "success",
          message: `${selectedItemsWithSelection.length} ${
            selectedItemsWithSelection.length === 1 ? "selection" : "selections"
          } finalized of ${selectedProjectItems.length} selected ${selectedProjectItems.length > 1 ? "items" : "item"}`,
        });
      },
    },
    {
      disabled,
      label: "Unfinalize Product Selections",
      onClick: async () => {
        const selectedItemsWithSelection = selectedProjectItems
          // only include selected items that have a current selection and are finalized
          .filter((pi) => {
            const hs = pi.fragment.homeownerSelection;
            return hs?.selectedOption && hs?.status.code === HomeownerSelectionStatus.Finalized;
          })
          .map((si) => ({ id: si.id, homeownerSelection: { status: HomeownerSelectionStatus.Selected } }));
        await saveProjectItems({ variables: { input: { projectItems: selectedItemsWithSelection } } });
        api.clearSelections();
        showToast({
          type: "success",
          message: `${selectedItemsWithSelection.length} ${
            selectedItemsWithSelection.length === 1 ? "selection" : "selections"
          } unfinalized of ${selectedProjectItems.length} selected ${
            selectedProjectItems.length > 1 ? "items" : "item"
          }`,
        });
      },
    },
    {
      disabled: hasZeroSelectedItems || store.disableProjectMarkup || !canEditProjectItemsDirectly,
      label: "Update Markup",
      onClick: () =>
        openModal({
          content: <UpdateMarkupModal selectedItems={selectedProjectItems} onSave={onSave} kind="projectItem" />,
        }),
    },
    {
      disabled,
      label: "Allocate Items",
      onClick: async () =>
        openModal({
          content: (
            <AllocateItemsModal
              selectedProjectItems={selectedProjectItems}
              onSave={onSave}
              scheduleTasks={scheduleTasks}
            />
          ),
        }),
    },
    {
      disabled: hasZeroSelectedItems,
      label: "Export to CSV",
      onClick: () =>
        openModal({
          content: (
            <ProjectItemsExportModal projectId={projectId} projectItemIds={selectedProjectItems.map((pi) => pi.id)} />
          ),
        }),
    },
  ];

  return <ButtonMenu items={bulkMenuItems} placement="right" trigger={{ label: "Actions" }} />;
}
