import { Chip, Css, Palette } from "@homebound/beam";
import {
  DesignPackageLocation,
  DesignPackageLocationDetail,
  DesignPackageRpoChipFragment,
} from "src/generated/graphql-types";
import { foldEnum, isDefined } from "src/utils";

export const planPackageTagColor = "#AFAFAF";
export const designUpgradeTagColor = "#F97316";

type OptionChipsProps = { rpos: DesignPackageRpoChipFragment[] };
export function OptionChips({ rpos }: OptionChipsProps) {
  const rpog = rpos.first!.optionGroup;
  const bgColor = rpog.isPlanPackage
    ? planPackageTagColor
    : rpog.forDesignUpgrade
      ? designUpgradeTagColor
      : rpog.group.designPackageTagColor;

  return (
    <Chip
      xss={Css.fs0.xsMd.if(isDefined(bgColor)).bgColor(bgColor!).white.$ as any}
      text={rpos
        // render names within a group in a consistent order
        .sortBy((rpo) => rpo.order)
        .map((rpo) => rpo.designPackageAbbreviation)
        .join(" • ")}
    />
  );
}

type DpLocationBadgeProps = { designPackage: { location: DesignPackageLocationDetail } };
export function DesignPackageLocationBadge({ designPackage }: DpLocationBadgeProps) {
  const backgroundColor = foldEnum(designPackage.location.code, {
    [DesignPackageLocation.Interior]: Palette.Blue200,
    [DesignPackageLocation.Exterior]: Palette.Orange200,
  });
  return <Chip text={designPackage.location.name} xss={{ backgroundColor }} />;
}
