import { Css, FullBleed, Icon } from "@homebound/beam";
import { useMemo } from "react";
import { Redirect, Switch, generatePath } from "react-router";
import { SideNavAndRouterConfig, SideNavProps, navLinksToRoutes } from "src/components/layout/SideNav";
import { SideNavLayout } from "src/components/layout/SideNavLayout";
import { LocationsPage } from "src/routes/admin/locations/LocationsPage";
import { adminPaths } from "../routesDef";
import { CostCodesPage } from "./costCodes/CostCodesPage";
import { FeatureFlagsPage } from "./featureFlags/FeatureFlagsPage";
import { ItemsPage } from "./items/ItemsPage";
import { AdminProjects } from "./projects/AdminProjects";
import { UserGroupsPage } from "./userGroups/UserGroups";
import { UsersPage } from "./users/UsersPage";

export function Admin() {
  const sideNavProps: SideNavProps = useMemo(
    () => ({
      sideNavConfig: adminNavLinks(),
      contrast: true,
      top: (
        <FullBleed>
          <div css={Css.p3.pb5.$}>
            <div css={Css.df.jcc.aic.br8.wPx(48).hPx(48).bgGray800.gray50.$}>
              <Icon icon="userCircle" />
            </div>
            <h1 css={Css.lgSb.mt1.gray100.$}>Admin</h1>
          </div>
        </FullBleed>
      ),
    }),
    [],
  );

  return (
    <SideNavLayout sideNavProps={sideNavProps}>
      <Switch>
        {navLinksToRoutes(adminNavLinks())}
        <Redirect to={generatePath(adminPaths.projects)} />
      </Switch>
    </SideNavLayout>
  );
}

function adminNavLinks(): SideNavAndRouterConfig[] {
  return [
    {
      title: "Feature Management",
      items: [
        {
          label: "Global",
          href: generatePath(adminPaths.featureFlags),
          path: adminPaths.featureFlags,
          component: FeatureFlagsPage,
        },
        {
          label: "Projects",
          href: generatePath(adminPaths.projects),
          path: adminPaths.projects,
          component: AdminProjects,
        },
      ],
    },
    {
      title: "User Management",
      items: [
        {
          label: "Users",
          href: generatePath(adminPaths.users),
          path: adminPaths.users,
          component: UsersPage,
        },
        {
          label: "User Groups",
          href: generatePath(adminPaths.userGroups),
          path: adminPaths.userGroups,
          component: UserGroupsPage,
        },
      ],
    },
    {
      title: "Data Management",
      items: [
        {
          label: "Items",
          href: generatePath(adminPaths.items),
          path: adminPaths.items,
          component: ItemsPage,
        },
        {
          label: "Cost Codes",
          href: generatePath(adminPaths.costCodes),
          path: adminPaths.costCodes,
          component: CostCodesPage,
        },
        {
          label: "Locations V2",
          href: generatePath(adminPaths.locations),
          path: adminPaths.locations,
          component: LocationsPage,
        },
      ],
    },
  ];
}
