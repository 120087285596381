import { Margin, Only, Properties } from "@homebound/beam";

type FormGapXStyles = Pick<Properties, Margin | "width">;

type FormGapProps<X> = {
  xss?: X;
};

export function FormGap<X extends Only<FormGapXStyles, X>>({ xss }: FormGapProps<X>) {
  return <div css={{ ...xss }} />;
}
