import { booleanFilter, FilterDefs, multiFilter, singleFilter, toggleFilter } from "@homebound/beam";
import {
  BuyoutStatus,
  Named,
  ProjectItemFilter,
  SpecsAndSelectionsCostCodeFragment,
  Stage,
} from "src/generated/graphql-types";
import { costTypeToNameMapper, safeEntries } from "src/utils";

/* This is like HomeownerSelectionStatus, but with Declined removed */
const selectionStatuses: SelectionStatus[] = ["Selected", "Finalized", "Undecided"];
type SelectionStatus = "Selected" | "Finalized" | "Undecided";

export type SpecsAndSelectionsFilter = Omit<ProjectItemFilter, "selectionStatus"> & {
  // Not really a filter, we flip between "Specs Table" & "Selections Table", which have different GQL queries.
  view?: "selections";
  // Beam's Filters cannot easily bind to the backend `location: [{ id: ... }]` filter, so make a locationId adapter
  locationId?: string[];
  // We add "Placeholder" that is not _really_ an additional selectionStatus, but convenient for users
  selectionStatus?: SelectionStatus[];
  unallocatedCosts?: boolean;
};

export function createSpecsAndSelectionsFilterDef(
  stage: Stage,
  costCodes: SpecsAndSelectionsCostCodeFragment[],
  locations: Named[],
): FilterDefs<SpecsAndSelectionsFilter> {
  return {
    // The precon stage doesn't have selections, so hide `View: Selections`
    ...(stage === Stage.Construction
      ? {
          view: singleFilter({
            options: [{ id: "selections" as const, name: "Selections" }],
            getOptionValue: (o) => o.id,
            getOptionLabel: (o) => o.name,
          }),
        }
      : {}),
    locationId: multiFilter({
      options: locations,
      getOptionValue: (location) => location.id,
      getOptionLabel: (location) => location.name,
    }),
    costCode: multiFilter({
      options: costCodes,
      getOptionValue: (costCode) => costCode.id,
      getOptionLabel: (costCode) => costCode.name,
    }),
    costType: multiFilter({
      options: safeEntries(costTypeToNameMapper),
      getOptionValue: ([code]) => code,
      getOptionLabel: ([_, name]) => name,
    }),
    buyoutStatus: multiFilter({
      options: safeEntries({
        [BuyoutStatus.BoughtOut]: "Yes",
        [BuyoutStatus.NotBoughtOut]: "No",
        [BuyoutStatus.PartiallyBoughtOut]: "Partially",
      }),
      getOptionValue: ([code]) => code,
      getOptionLabel: ([_, name]) => name,
    }),
    isPublished: booleanFilter({
      label: "Published in HOP?",
      options: [
        [undefined, "All"],
        [true, "Published"],
        [false, "Not published"],
      ],
    }),
    selectionStatus: multiFilter({
      options: selectionStatuses,
      getOptionValue: (o) => o,
      getOptionLabel: (o) => o,
    }),
    unallocatedCosts: toggleFilter({
      label: "Unallocated costs",
    }),
  };
}
