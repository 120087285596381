import { currentAuthToken } from "@homebound/auth-components";
import { Button, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar, useToast } from "@homebound/beam";
import { useCallback, useState } from "react";
import { jsonToCSV } from "react-papaparse";
import { CsvUploader } from "src/components/CsvUploader";
import { baseDownloadUrl } from "src/context";
import { useToggle } from "src/hooks";
import { BulkUploadCsvResponse } from "../item-catalog/components/BulkUploadBidItemModal";
import { useMaterialCatalog } from "./useMaterialCatalog";

export function UploadMaterialAttributesCsvModal() {
  const { upload, setCsvContent, csvContent, errors, addError, clearErrors } = useUploadCsv();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Upload Material Attributes</ModalHeader>
      <ModalBody>
        <CsvUploader
          errors={errors}
          onError={addError}
          onDrop={(content) => {
            clearErrors();
            setCsvContent(
              jsonToCSV(
                content.map(({ data }) => data),
                { newline: "\n" },
              ),
            );
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button
          label="Upload"
          variant={errors.nonEmpty ? "danger" : "primary"}
          disabled={!csvContent || errors.nonEmpty}
          onClick={upload}
        />
      </ModalFooter>
    </>
  );
}

function useUploadCsv() {
  const [csvContent, setCsvContent] = useState<string>();
  const [loading, toggleLoading] = useToggle(false);
  const { refetch } = useMaterialCatalog();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const { showToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);
  const addError = (msg: string) => setErrors((errs) => errs.concat(msg));
  const clearErrors = () => setErrors([]);

  const upload = useCallback(async () => {
    if (loading) return;
    if (!csvContent) return triggerNotice({ message: "Can't upload CSV without any line items" });
    toggleLoading();

    const response = await fetch(`${baseDownloadUrl()}/csv?type=saveMaterialAttributes`, {
      method: "POST",
      headers: { "content-type": "text/csv", Authorization: `Bearer ${await currentAuthToken()}` },
      body: csvContent,
    });
    toggleLoading(); // the rest happens fast enough to turn it off early so we don't have to keep toggling it off

    if (response.status !== 200) {
      const { message } = await response.json();
      if (message) showToast({ message, type: "error" });
      return;
    }

    const result = (await response.json()) as BulkUploadCsvResponse;
    const { errors } = result;

    if (errors.nonEmpty) {
      errors.sortByKey("row").forEach((e) => addError(`Row ${e.row}: ${e.message}`));
      return;
    }

    refetch();
    triggerNotice({ message: "Material Attributes successfully uploaded" });
    closeModal();
  }, [closeModal, csvContent, loading, refetch, showToast, toggleLoading, triggerNotice]);
  return { upload, csvContent, setCsvContent, errors, addError, clearErrors };
}
