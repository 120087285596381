import {
  column,
  condensedStyle,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  simpleHeader,
} from "@homebound/beam";
import { Fragment } from "react";
import { formatDate, markupCell, priceCell } from "src/components";
import {
  ProjectItemScopeChange,
  ProjectItemUnitOfMeasureFragment,
  SelectionOptions_HomeownerSelectionFragment,
} from "src/generated/graphql-types";

type ProjectItemScopeTableProps = {
  scopeHistory: ProjectItemScopeChange[];
  unitOfMeasure: ProjectItemUnitOfMeasureFragment;
  homeownerSelection: SelectionOptions_HomeownerSelectionFragment | undefined;
};

export function ProjectItemScopeTable(props: ProjectItemScopeTableProps) {
  const { unitOfMeasure, scopeHistory, homeownerSelection } = props;
  const cutoffDate = homeownerSelection?.projectCutoff?.task?.interval.startDate;

  return (
    <Fragment>
      <div css={Css.df.jcsb.aic.gap1.my2.$}>
        <div css={Css.baseMd.$}>Project Item Scope</div>
        {cutoffDate && <div css={Css.xs.gray700.$}>Selection Cutoff Date: {formatDate(cutoffDate)}</div>}
      </div>
      <GridTable
        id="projectItemScopeTable"
        columns={createColumns(unitOfMeasure)}
        rows={createRows(scopeHistory)}
        style={condensedStyle}
      />
    </Fragment>
  );
}

function createRows(scopeHistory: ProjectItemScopeChange[]): GridDataRow<Row>[] {
  const initialValue = { quantity: 0, costInCents: 0, priceInCents: 0 };
  const scopeHistorySums = scopeHistory.reduce(
    (acc, cur) => ({
      quantity: (cur.quantity ?? 0) + acc.quantity,
      costInCents: cur.costInCents + acc.costInCents,
      priceInCents: cur.priceInCents + acc.priceInCents,
    }),
    initialValue,
  );

  return [
    simpleHeader,
    ...scopeHistory.map((celi, i) => ({ kind: "modify" as const, id: `${celi.shortName}${i}`, data: celi })),
    { kind: "total" as const, id: "total", data: scopeHistorySums },
  ];
}

type HeaderRow = { kind: "header" };
type Row =
  | HeaderRow
  | { kind: "modify"; data: ProjectItemScopeChange }
  | { kind: "total"; data: { quantity: number; costInCents: number; priceInCents: number } };

function createColumns(uom: ProjectItemUnitOfMeasureFragment): GridColumn<Row>[] {
  const quantityUom = uom.name ?? "";
  const sourceColumn = column<Row>({
    header: "Source",
    modify: (row) => row.shortName,
    total: "",
  });
  const quantityColumn = column<Row>({
    header: "Quantity",
    modify: (row) => `${uom.useQuantity === false ? "" : row.quantity} ${quantityUom}`,
    total: (row) => `${uom.useQuantity === false ? "" : row.quantity} ${quantityUom}`,
  });
  const totalCostColumn = numericColumn<Row>({
    header: () => "Total Cost",
    modify: (row) => priceCell({ valueInCents: row.costInCents }),
    total: (row) => priceCell({ valueInCents: row.costInCents }),
  });
  const markupColumn = numericColumn<Row>({
    header: () => "Markup",
    modify: (row) => markupCell(row.costInCents, row.priceInCents),
    total: (row) => markupCell(row.costInCents, row.priceInCents),
  });
  const homeownerPriceColumn = numericColumn<Row>({
    header: () => "Homeowner Price",
    modify: (row) => priceCell({ valueInCents: row.priceInCents }),
    total: (row) => priceCell({ valueInCents: row.priceInCents }),
  });
  return [sourceColumn, quantityColumn, totalCostColumn, markupColumn, homeownerPriceColumn];
}
