import { Css, px, SelectField } from "@homebound/beam";
import { useEffect, useState } from "react";
import { GanttScheduleTaskFragment } from "src/generated/graphql-types";

type SearchScheduleGanttProps = {
  scheduleTasks?: GanttScheduleTaskFragment[];
  scrollGanttTaskIntoViewById: (taskId: string) => Promise<any> | undefined;
};

export function SearchScheduleGantt({ scheduleTasks, scrollGanttTaskIntoViewById }: SearchScheduleGanttProps) {
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedTaskId) {
      const maybePromise = scrollGanttTaskIntoViewById(selectedTaskId);

      // Clear out the selection once we scroll the user to the correct spot
      void maybePromise?.then(() => {
        setSelectedTaskId(undefined);
      });
    }
  }, [selectedTaskId, setSelectedTaskId, scrollGanttTaskIntoViewById]);

  const sortedTasksByName = scheduleTasks?.sortBy((t) => t.name) ?? [];
  const inputIsDisabled = !sortedTasksByName.length;

  return (
    <div css={Css.fb(px(250)).fs0.$}>
      <SelectField
        label="Search Tasks"
        placeholder="Search Tasks"
        options={sortedTasksByName}
        value={selectedTaskId}
        onSelect={setSelectedTaskId}
        disabled={inputIsDisabled}
        labelStyle="hidden"
        compact
      />
    </div>
  );
}
