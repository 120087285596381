import { Css, FilterDefs, Filters, multiFilter, useTestIds } from "@homebound/beam";
import { Dispatch, SetStateAction } from "react";
import { BidItemFilter, CostTypeDetail, useBidItemAttributesQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ItemCatalogQueryParams } from "../hooks/useItemCatalog";

type ItemCatalogFilterProps = {
  settings: ItemCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<ItemCatalogQueryParams>>;
};

export function ItemCatalogFilter({ settings, setSettings }: ItemCatalogFilterProps) {
  const result = useBidItemAttributesQuery();
  return queryResult(result, (data) => (
    <BidItemsFilter bidItemCostTypes={data.costTypes} settings={settings} setSettings={setSettings} />
  ));
}

type BidItemCostType = Omit<CostTypeDetail, "tradePartnerName">;

type FilterProps = {
  bidItemCostTypes: BidItemCostType[];
  settings: ItemCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<ItemCatalogQueryParams>>;
};

function BidItemsFilter({ bidItemCostTypes, settings, setSettings }: FilterProps) {
  const testIds = useTestIds({});
  const { page, perPage, ...filters } = settings;

  return (
    <div css={Css.wPx(200).bgGray100.p2.$} {...testIds}>
      <h1 css={Css.xlMd.pb2.$}>Filter</h1>
      <Filters
        filterDefs={createFilterDefs(bidItemCostTypes)}
        filter={filters}
        onChange={(value) => setSettings({ ...toNullValues(value, perPage) })}
        vertical
      />
    </div>
  );
}

function createFilterDefs(bidItemCostTypes: BidItemCostType[]): FilterDefs<BidItemFilter> {
  return {
    costType: multiFilter({
      label: "Type",
      options: bidItemCostTypes,
      getOptionValue: (o) => o.code,
      getOptionLabel: (o) => o.name,
    }),
  };
}

function toNullValues(filters: BidItemFilter, perPage: number): ItemCatalogQueryParams {
  const { costType } = filters;
  return {
    costType: costType?.nonEmpty ? costType : undefined,
    perPage: perPage,
    page: 1, // We reset the page to 1 whenever the filters are updated
  };
}
