import { Css, Only, Properties, useTestIds } from "@homebound/beam";
import { Fragment } from "react";
import { Percentage, Price, emptyCellDash } from ".";

type PricePercentageXss = Pick<Properties, "textAlign">;

export type PricePercentageProps<X> = {
  id?: string;
  percentage: number | undefined;
  amountInCents: number | undefined;
  dropZero?: boolean;
  xss?: X;
};

/** Displays $amount (percentage%) or empty if amountInCents is not set */
export function PricePercentage<X extends Only<PricePercentageXss, X>>(props: PricePercentageProps<X>) {
  const { id = "pricePercentage", percentage, amountInCents, xss, dropZero = false } = props;
  const rootId = useTestIds({}, id);
  const formattedSummary =
    typeof amountInCents !== "number" || (dropZero && amountInCents === 0) ? (
      emptyCellDash
    ) : (
      <Fragment>
        <Price valueInCents={amountInCents} />{" "}
        {typeof percentage === "number" && (
          <Fragment>
            (<Percentage percent={percentage} />)
          </Fragment>
        )}
      </Fragment>
    );

  return (
    <div {...rootId} css={{ ...Css.wsnw.$, ...xss }}>
      {formattedSummary}
    </div>
  );
}
