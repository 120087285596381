import { Button, Css, Icon, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useHistory } from "react-router";
import { createCommitmentUrl } from "src/RouteUrls";

type CommitmentCreatedModalProps = {
  commitmentId: string;
  projectId: string;
};

export function CommitmentCreatedModal(props: CommitmentCreatedModalProps) {
  const { commitmentId, projectId } = props;
  const history = useHistory();
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Commitment Created</ModalHeader>
      <ModalBody>
        <p css={Css.mb2.$}>Your draft commitment is ready to view in Trade Commitments.</p>
        <Button
          endAdornment={<Icon icon="linkExternal" />}
          label="Open Commitment"
          onClick={() => {
            // close global modal and redirect
            closeModal();
            history.push({ pathname: createCommitmentUrl(projectId, commitmentId) });
          }}
          variant="text"
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Done" onClick={closeModal} />
      </ModalFooter>
    </>
  );
}
