import {
  actionColumn,
  Button,
  column,
  Css,
  GridCellContent,
  GridColumn,
  GridDataRow,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContent,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { ReactNode } from "react";
import { useParams } from "react-router";
import { formatDate, QueryTable } from "src/components";
import {
  HomeownersTabHomeownerFragment,
  HomeownersTabQuery,
  useDeleteProjectCollaboratorMutation,
  useHomeownersTabQuery,
  useSendInviteEmailMutation,
} from "src/generated/graphql-types";
import { CreateHomeownerModal } from "src/routes/projects/settings/CreateHomeownerModal";

export function HomeownersTab() {
  const { projectId } = useParams<{ projectId: string }>();

  const query = useHomeownersTabQuery({ variables: { projectId } });
  const [sendInviteEmail] = useSendInviteEmailMutation();
  const [deleteProjectCollaborator] = useDeleteProjectCollaboratorMutation();
  const { openModal, closeModal } = useModal();

  async function onInvite(c: HomeownersTabHomeownerFragment) {
    await sendInviteEmail({
      variables: {
        input: {
          projectCollaboratorId: c.id,
        },
      },
    });
    await query.refetch();
  }

  async function deleteCollaborator(c: HomeownersTabHomeownerFragment) {
    await deleteProjectCollaborator({
      variables: {
        input: {
          projectCollaboratorId: c.id,
        },
      },
    });
    await query.refetch();
    closeModal();
  }

  function openDeleteModal(c: HomeownersTabHomeownerFragment) {
    openModal({
      content: <DeleteView collaborator={c} />,
      size: { width: "md", height: 264 },
    });
  }

  function DeleteView({ collaborator }: { collaborator: HomeownersTabHomeownerFragment }) {
    return (
      <>
        <ModalHeader>
          <span css={Css.xl2Sb.$}>Revoke homeowner access</span>
        </ModalHeader>
        <ModalBody>
          <p css={Css.sm.gray700.mb1.$}>Are you sure you want to remove Homeowner access?</p>
          <p css={Css.sm.gray700.$}>
            All previous actions and comments will remain in Blueprint history. Only access to this user account will be
            restricted.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button variant="tertiary" label="Cancel" onClick={closeModal} />
          <Button
            label="Revoke access"
            variant="danger"
            onClick={() => deleteCollaborator(collaborator)}
            data-testid="ModalViewRevokeAccess"
          />
        </ModalFooter>
      </>
    );
  }

  const columns = createColumns(onInvite, openDeleteModal);

  return (
    <>
      <div css={Css.df.aic.pb2.$}>
        <div css={Css.baseMd.$}>Homeowners</div>
        <div css={Css.mla.$}>
          <Button
            label="Add homeowner"
            onClick={() => {
              openModal({ content: <CreateHomeownerModal projectId={projectId} refetch={() => query.refetch()} /> });
            }}
            variant="tertiary"
          />
        </div>
      </div>
      <ScrollableContent>
        <QueryTable
          stickyHeader
          sorting={{ on: "client", initial: ["role", "ASC"] }}
          {...{ query, columns, createRows }}
        />
      </ScrollableContent>
    </>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: HomeownersTabHomeownerFragment };
type Row = HeaderRow | DataRow;

function createColumns(
  onInvite: (projectCollaborator: HomeownersTabHomeownerFragment) => void,
  openDeleteModal: (projectCollaborator: HomeownersTabHomeownerFragment) => void,
): GridColumn<Row>[] {
  const columns: GridColumn<Row>[] = [
    column<Row>({
      header: "Name",
      data: (c) => `${c.collaborator.firstName} ${c.collaborator.lastName}`,
    }),
    column<Row>({
      header: "Email",
      data: (c) => c.collaborator.email,
    }),
    column<Row>({
      header: "Phone",
      data: (c) => c.collaborator.phone,
    }),
    column<Row>({
      header: "Date Invited",
      data: (c) => dateInvited({ projectCollaborator: c, onInvite }),
    }),
    column<Row>({
      header: "Last Login",
      data: (c) => (c.collaborator.lastActive ? formatDate(c.collaborator.lastActive) : undefined),
    }),
    column<Row>({
      id: "role",
      header: "Role",
      data: (c) => c.role,
    }),
    actionColumn<Row>({
      header: "Delete",
      data: (c) => deleteCollaboratorButton({ projectCollaborator: c, openDeleteModal }),
    }),
  ];
  return columns;
}

type DateInvitedProps = {
  projectCollaborator: HomeownersTabHomeownerFragment;
  onInvite(projectCollaborator: HomeownersTabHomeownerFragment): void;
};

function dateInvited({ projectCollaborator, onInvite }: DateInvitedProps): GridCellContent {
  function content(): ReactNode {
    if (!projectCollaborator.inviteSent) {
      return <Button label="Invite ✉️" onClick={() => onInvite(projectCollaborator)} />;
    }
    return (
      <div>
        <span css={Css.pr2.$}>{formatDate(projectCollaborator.inviteSent, "short", "short")}</span>
        <Button variant="tertiary" label="Re-send ✉️" onClick={() => onInvite(projectCollaborator)} />
      </div>
    );
  }

  return {
    alignment: "left",
    content: content(),
    value: projectCollaborator.inviteSent,
  };
}

type DeleteCollaboratorButtonProps = {
  projectCollaborator: HomeownersTabHomeownerFragment;
  openDeleteModal(projectCollaborator: HomeownersTabHomeownerFragment): void;
};

function deleteCollaboratorButton({ projectCollaborator, openDeleteModal }: DeleteCollaboratorButtonProps): ReactNode {
  return <Button variant="tertiary" label="Revoke access" onClick={() => openDeleteModal(projectCollaborator)} />;
}

function createRows(data: HomeownersTabQuery | undefined): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(data?.project.collaborators.map((c) => ({
      kind: "data" as const,
      id: c.collaborator.id,
      data: c,
    })) || []),
  ];
}
