import { Css, Icon, Palette, Tag, TagType, Tooltip, useTestIds } from "@homebound/beam";
import {
  CalendarInterval,
  Maybe,
  ScheduleFlag,
  ScheduleFlagReason,
  ScheduleTask,
  TaskStatus,
  TaskStatusDetail,
} from "src/generated/graphql-types";
import { formatMonthDay } from "src/utils/dates";

type TaskSidebarListItemProps = {
  scheduleTask: Pick<ScheduleTask, "id" | "name"> & { interval: Pick<CalendarInterval, "endDate" | "startDate"> } & {
    statusDetail: Pick<TaskStatusDetail, "code" | "name">;
  } & {
    scheduleFlags: Pick<
      Omit<ScheduleFlag, "reason"> & {
        reason?: Maybe<Pick<ScheduleFlagReason, "id" | "title">>;
      },
      "id" | "reason"
    >[];
  };
  onClick: (taskId: string) => void;
};

export function TaskSidebarListItem({ scheduleTask, onClick }: TaskSidebarListItemProps) {
  const tid = useTestIds({}, "taskSidebarListItem");

  const {
    interval: { endDate, startDate },
    statusDetail: { name: taskStatus },
    scheduleFlags,
  } = scheduleTask;

  return (
    <div
      {...tid}
      css={Css.df.fdc.w100.mb1.br4.bgWhite.cursorPointer.onHover.bgBlue100.$}
      key={scheduleTask.id}
      onClick={() => onClick(scheduleTask.id)}
    >
      <div css={Css.df.jcsb.aic.py1.px2.br8.$}>
        <div css={Css.fb("160px").$}>{scheduleTask.name}</div>
        {scheduleFlags.nonEmpty && (
          <Tooltip title={scheduleFlags.map((flag) => flag.reason?.title).join(", ")}>
            <Icon icon="flag" color={Palette.Red700} inc={3} />
          </Tooltip>
        )}
        <Tag text={taskStatus} type={statusColorMap[scheduleTask.statusDetail.code]} />
        <div css={Css.fb("65px").$}>
          {formatMonthDay(startDate)} - {formatMonthDay(endDate)}
        </div>
      </div>
    </div>
  );
}

const statusColorMap: Record<TaskStatus, TagType | undefined> = {
  [TaskStatus.NotStarted]: undefined,
  [TaskStatus.InProgress]: "info",
  [TaskStatus.Complete]: "success",
  [TaskStatus.OnHold]: "caution",
  [TaskStatus.Delayed]: "warning",
};
