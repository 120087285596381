import {
  actionColumn,
  BoundNumberField,
  BoundSelectField,
  Button,
  column,
  Css,
  dateColumn,
  GridColumn,
  GridTable,
  IconButton,
  numericColumn,
  ScrollableContent,
  simpleHeader,
  useComputed,
} from "@homebound/beam";
import { action, makeAutoObservable, observable } from "mobx";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormActions, FormattedDate, HeaderBar, Icon } from "src/components";
import {
  DrawTaskFragment,
  SaveHomeownerContractDrawInput,
  SaveHomeownerContractInput,
  Stage,
  useHomeownerChangeOrderDrawScheduleQuery,
  useHomeownerContractDrawScheduleQuery,
  useSaveHomeownerContractChangeOrderDrawsMutation,
  useSaveHomeownerContractDrawsMutation,
} from "src/generated/graphql-types";
import { stayInPlace, useModeParam } from "src/hooks";
import { ProjectParams } from "src/routes/routesDef";
import { max, prorate, sortBy, sum } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";

export type DrawScheduleTabProps = {
  contractId: string;
  changeOrderId?: string;
  stage: Stage;
};

export function DrawScheduleTab({ contractId, stage, changeOrderId }: DrawScheduleTabProps) {
  const isChangeOrder = !!changeOrderId;
  const { mode, onEdit, onCancel, onSave } = useModeParam({ listPageUrl: stayInPlace });
  const { projectId } = useParams<ProjectParams>();
  const contractQuery = useHomeownerContractDrawScheduleQuery({
    variables: { projectId, stage, contractId },
    skip: isChangeOrder,
  });
  const changeOrderQuery = useHomeownerChangeOrderDrawScheduleQuery({
    variables: { projectId, stage, changeOrderId: changeOrderId! },
    skip: !isChangeOrder,
  });
  const [saveContractDraws] = useSaveHomeownerContractDrawsMutation();
  const [saveChangeOrderDraws] = useSaveHomeownerContractChangeOrderDrawsMutation();

  // Make a list of observables that we'll edit
  const [tasks, drawRows] = useMemo(
    () => {
      if (isChangeOrder) {
        const changeOrderTotal = changeOrderQuery.data?.homeownerContractChangeOrder.priceChangeInCents || 0;
        const tasks =
          changeOrderQuery.data?.project.stage.units.flatMap((u) => u.activities.flatMap((a) => a.tasks)) || [];
        const drawRows = observable([] as DrawRow[]);
        drawRows.push(
          ...(changeOrderQuery.data?.homeownerContractChangeOrder.draws.map((d) => {
            return new DrawRow(drawRows, tasks, changeOrderTotal, d.id, d.order, d.amountInCents, d.task.id, d.isDrawn);
          }) || []),
        );
        return [tasks, new DrawRows(changeOrderId!, changeOrderTotal, drawRows, tasks)];
      }

      const contractTotal = contractQuery.data?.homeownerContract.priceChangeInCents || 0;
      const tasks = contractQuery.data?.project.stage.units.flatMap((u) => u.activities.flatMap((a) => a.tasks)) || [];
      const drawRows = observable([] as DrawRow[]);
      drawRows.push(
        ...(contractQuery.data?.homeownerContract.draws.map((d) => {
          return new DrawRow(drawRows, tasks, contractTotal, d.id, d.order, d.amountInCents, d.task.id, d.isDrawn);
        }) || []),
      );
      return [tasks, new DrawRows(contractId, contractTotal, drawRows, tasks)];
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contractQuery, changeOrderQuery, contractId, changeOrderId],
  );

  // Drop those into a form state to bind against
  const readOnly = mode === "read";
  const formState = useFormState({
    config: formConfig,
    init: { input: drawRows, map: (dr) => dr },
    readOnly,
  });

  const columns = useMemo(() => createColumns(formState, tasks), [tasks, formState]);
  const rows = useComputed(() => {
    return [
      simpleHeader,
      ...sortedAndNotDeleted(formState.draws.rows).map((draw) => {
        return { kind: "draw" as const, id: draw.order.value.toString(), data: draw };
      }),
      // Only include the total if there are other rows
      ...(formState.draws.rows.length > 0 ? [{ kind: "total" as const, id: "total", data: formState }] : []),
    ];
  }, [formState]);

  if (isChangeOrder ? !hasData(changeOrderQuery) : !hasData(contractQuery)) {
    return renderLoadingOrError(isChangeOrder ? changeOrderQuery : contractQuery);
  }

  return (
    <div css={Css.dif.fdc.$}>
      <HeaderBar
        right={
          <FormActions
            formState={formState}
            mode={mode}
            onEdit={onEdit}
            onCancel={() => {
              // TODO Should FormActions just do this anyway?
              formState.revertChanges();
              onCancel();
            }}
            onSave={async () => {
              if (isChangeOrder) {
                await saveChangeOrderDraws({ variables: { input: drawRows.toInput() } });
              } else {
                await saveContractDraws({ variables: { input: drawRows.toInput() } });
              }
              onSave();
            }}
          />
        }
      />
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={rows}
          style={{ bordered: true, allWhite: true }}
          stickyHeader
          fallbackMessage="Scheduled draws for this contract will show here."
        />
        <div>
          <Button
            data-testid="addDraw"
            variant="tertiary"
            icon="plus"
            disabled={mode === "read"}
            onClick={() => drawRows.addDraw()}
            label="Draw"
          />
        </div>
      </ScrollableContent>
    </div>
  );
}

type Row =
  | { kind: "header" }
  | { kind: "draw"; data: ObjectState<DrawRow> }
  | { kind: "total"; data: ObjectState<DrawRows> };

function createColumns(formState: ObjectState<DrawRows>, tasks: DrawTaskFragment[]): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Draw",
      draw: ({ order, value }) => {
        return (
          <>
            {order.value}
            {!formState.readOnly && (
              <div css={Css.df.fdc.cursorPointer.usn.px1.$}>
                {value.canMoveUp && <Icon icon="sortUp" onClick={() => formState.value.moveUp(value)} />}
                {value.canMoveDown && <Icon icon="sortDown" onClick={() => formState.value.moveDown(value)} />}
              </div>
            )}
          </>
        );
      },
      total: "",
      w: "60px",
    }),
    column<Row>({
      header: "Task",
      draw: ({ taskId }) => <BoundSelectField field={taskId} options={tasks} placeholder="Select a task" />,
      total: "",
      // 480px is ~the length of the longest task name in initial testing
      w: "480px",
    }),
    dateColumn<Row>({
      header: "Schedule End Date",
      draw: ({ endDate }) => endDate.value && <FormattedDate date={endDate.value} />,
      total: "",
      w: "132px",
    }),
    column<Row>({
      header: "Task Status",
      draw: ({ taskStatus }) => taskStatus.value || "",
      total: "",
      w: "120px",
    }),
    numericColumn<Row>({
      header: "% Of Contract",
      draw: ({ percent }) => <BoundNumberField type="percent" field={percent} readOnly />,
      total: ({ totalPercent }) => {
        return (
          <BoundNumberField
            field={totalPercent}
            type="percent"
            // We're using a BoundNumberField so we can use the `error` prop, but
            // set readOnly true so that it never actually renders as an input.
            readOnly
            errorMsg={totalPercent.errors.join(",")}
          />
        );
      },
      w: "120px",
    }),
    numericColumn<Row>({
      header: "Draw Amount",
      draw: ({ amountInCents }) => <BoundNumberField type="cents" field={amountInCents} />,
      total: ({ totalAmountInCents }) => <BoundNumberField type="cents" field={totalAmountInCents} readOnly />,
      w: "140px",
    }),
    actionColumn<Row>({
      header: "",
      draw: (draw) =>
        formState.readOnly || !draw.canDelete.value ? (
          ""
        ) : (
          <IconButton
            icon="trash"
            data-testid="remove"
            onClick={action(() => {
              draw.delete.set(true);
              let order = 1;
              sortedAndNotDeleted(formState.draws.rows).forEach((d) => (d.order.value = order++));
            })}
          />
        ),
      total: "",
      w: "48px",
    }),
  ];
}

const formConfig: ObjectConfig<DrawRows> = {
  totalPercent: {
    type: "value",
    rules: [({ value: v }) => (v !== 100 ? "Must equal 100%" : undefined)],
    computed: true,
  },
  totalAmountInCents: {
    type: "value",
    computed: true,
  },
  draws: {
    type: "list",
    config: {
      id: { type: "value" },
      order: { type: "value" },
      taskId: { type: "value", rules: [required] },
      percent: {
        type: "value",
        computed: true,
        // Leaving this here because we'll come back to add this functionality back
        /* rules: [
          required,
          ({ value: v }) => (v !== null && v !== undefined && (v < 1 || v > 100) ? "Invalid" : undefined),
        ],*/
      },
      delete: { type: "value", isDeleteKey: true },
      endDate: { type: "value", computed: true },
      taskStatus: { type: "value", computed: true },
      amountInCents: { type: "value", rules: [required] },
      canMoveUp: { type: "value", computed: true },
      canMoveDown: { type: "value", computed: true },
      canDelete: { type: "value", computed: true },
      isDrawn: { type: "value", computed: true, isReadOnlyKey: true },
    },
  },
};

class DrawRows {
  constructor(
    private contractId: string,
    private contractTotal: number,
    // This might include deleted draws
    public readonly draws: DrawRow[],
    private readonly tasks: DrawTaskFragment[],
  ) {
    makeAutoObservable(this);
  }

  get totalPercent(): number | undefined | null {
    return this.draws
      .filter((d) => !d.delete)
      .map((d) => d.percent ?? 0)
      .reduce(sum, 0);
  }

  get totalAmountInCents(): number | undefined | null {
    return this.draws
      .filter((d) => !d.delete)
      .map((d) => d.amountInCents ?? 0)
      .reduce(sum, 0);
  }

  addDraw(): void {
    const nextOrder = this.draws.filter((d) => !d.delete).length + 1;
    this.draws.push(new DrawRow(this.draws, this.tasks, this.contractTotal, undefined, nextOrder, 0, undefined, false));
  }

  moveUp(draw: DrawRow): void {
    const prev = this.draws.filter((d) => !d.delete).find((d) => d.order === draw.order - 1)!;
    prev.order++;
    draw.order--;
  }

  moveDown(draw: DrawRow): void {
    const next = this.draws.filter((d) => !d.delete).find((d) => d.order === draw.order + 1)!;
    next.order--;
    draw.order++;
  }

  toInput(): SaveHomeownerContractInput {
    return {
      id: this.contractId,
      draws: this.draws.map((d) => d.toInput()),
    };
  }
}

// Ideally this would be a computed on `class DrawRow` directly, but for `formState.revertChanges` to
// work, it needs the full list of draws (including deleted), and right now ObjectState/ObjectConfig
// doesn't have a way alias/filter one collections (i.e. formState.allDraws) as another (i.e.
// formState.sortedDraws) and share the same entities/data/proxies between them.
function sortedAndNotDeleted(draws: readonly ObjectState<DrawRow>[]): ObjectState<DrawRow>[] {
  return sortBy(
    draws.filter((d) => !d.delete.value),
    (d) => d.order.value,
  );
}

/** An observable row that calcs its endDate, etc. as the user interacts with it. */
class DrawRow {
  delete: boolean = false;

  constructor(
    private readonly drawRows: DrawRow[],
    private readonly tasks: DrawTaskFragment[],
    private contractTotalAmountInCents: number,
    public id: string | undefined,
    // The 1-based order/draw number that we show to the user
    public order: number,
    public amountInCents: number | null | undefined,
    public taskId: string | null | undefined,
    public readonly isDrawn: boolean,
  ) {
    makeAutoObservable(this);
  }

  get percent(): number | undefined | null {
    const ratios = this.drawRows.filter((d) => !d.delete).map((d) => d.amountInCents ?? 0);
    const amounts = prorate(100, ratios);
    const myIndex = this.drawRows.filter((d) => !d.delete).indexOf(this);
    return amounts[myIndex];
  }

  get taskStatus(): string | undefined {
    return this.tasks.filter((t) => t.id === this.taskId).map((t) => t.status.name)[0];
  }

  get endDate(): DateOnly | undefined {
    return this.tasks.filter((t) => t.id === this.taskId).map((t) => t.interval.endDate)[0];
  }

  get canMoveUp(): boolean {
    const isFirst = this.order === 1;
    // Do +1 so that the immediately-follow drawn cannot be pushed up
    const isAfterMaxDrawnPlusOne = this.order > this.maxDrawnOrder + 1;
    return !this.isDrawn && !isFirst && isAfterMaxDrawnPlusOne;
  }

  get canMoveDown(): boolean {
    const isLast = this.order === this.drawRows.filter((d) => !d.delete).length;
    const isAfterMaxDrawn = this.order > this.maxDrawnOrder;
    return !this.isDrawn && !isLast && isAfterMaxDrawn;
  }

  get canDelete(): boolean {
    const isAfterMaxDrawn = this.order > this.maxDrawnOrder;
    return !this.isDrawn && isAfterMaxDrawn;
  }

  // Anything on-or-before this cannot be re-ordered b/c it would change draws that have already been invoiced.
  // Also this is a computed b/c the `this.drawRows` list is initially empty on `new DrawRow`.
  private get maxDrawnOrder(): number {
    return this.drawRows
      .filter((d) => d.isDrawn)
      .map((d) => d.order)
      .reduce(max, -1);
  }

  toInput(): SaveHomeownerContractDrawInput {
    const { id, order, amountInCents, taskId } = this;
    if (this.delete) {
      return { id, delete: this.delete };
    }
    return { id, order, amountInCents, taskId };
  }
}
