import {
  Chip,
  column,
  Css,
  GridColumn,
  GridDataRow,
  ScrollableContent,
  SelectField,
  simpleHeader,
  SimpleHeaderAndData,
  Tooltip,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { emptyCellDash, QueryTable } from "src/components";
import {
  AvailableTeamMembersFragment,
  TeamMembersQuery,
  useTeamMembersQuery,
  useUpdateProjectRoleMutation,
} from "src/generated/graphql-types";
import { ProjectParams } from "src/routes/routesDef";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";

export function TeamMembersTab() {
  const { projectId } = useParams<ProjectParams>();

  const query = useTeamMembersQuery({ variables: { projectId } });
  const availableAssignees = query.data?.project.availableAssignees || [];

  const columns = createColumns(projectId, availableAssignees);
  const rowStyles = { header: {}, data: { cellCss: Css.py1.aic.$ } };

  return (
    <>
      <div css={Css.mb2.gray700.$}>Changes will update all non-completed tasks in the schedule.</div>
      <ScrollableContent>
        <div css={Css.wPx(560).$}>
          <QueryTable
            query={query}
            createRows={createRows}
            columns={columns}
            rowStyles={rowStyles}
            stickyHeader
            style={{ allWhite: true, bordered: true }}
          />
        </div>
      </ScrollableContent>
    </>
  );
}

type MaybeTeamMember = TeamMembersQuery["project"]["teamMembers"][0];
type Row = SimpleHeaderAndData<MaybeTeamMember>;

function createColumns(projectId: string, availableAssignees: AvailableTeamMembersFragment[]): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => ({ content: "Role", colspan: 2 }),
      data: (teamMember) => (
        <div css={Css.df.fdr.w100.jcsb.aic.$}>
          <span>{teamMember.role.name}</span>
          {teamMember.user?.user.isActive === false && (
            <Tooltip title={`${teamMember.user.name} is a deactivated user and should be reassigned`}>
              <Chip text="Deactivated" type="warning" />
            </Tooltip>
          )}
        </div>
      ),
    }),
    column<Row>({
      header: "",
      data: (teamMember) => (
        <TeamMemberSelector projectId={projectId} availableAssignees={availableAssignees} teamMember={teamMember} />
      ),
    }),
  ];
}

function createRows(data: TeamMembersQuery | undefined): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(data?.project.teamMembers.map((tm) => ({ kind: "data" as const, id: tm.role.code, data: tm })) || []),
  ];
}

/**
 * Use a mini-component so that we can have a useState hook.
 *
 * I.e. so that the user's new assignee is used immediately. Ideally we wouldn't need this,
 * and could use an optimistic response, but the team member "entity" we're updating doesn't
 * have an `id` b/c to have a cache update to an immediate hit.
 */
function TeamMemberSelector(props: {
  projectId: string;
  teamMember: MaybeTeamMember;
  availableAssignees: AvailableTeamMembersFragment[];
}) {
  const { projectId, teamMember, availableAssignees } = props;
  const [updateProjectRole] = useUpdateProjectRoleMutation();
  const [teamMemberId, setTeamMemberId] = useState<string | undefined>(teamMember.user?.id);
  const isDisabled = teamMember.user?.user.isActive === false;

  const options = useMemo(() => {
    const undefinedOption = { id: "", name: emptyCellDash, avatar: "" };
    // Deactivated users aren't vectored through availableAssignees so the lookup will fail and the field
    // will appear unselected, even though the PTM is in fact assigned to a now-Deactivated user. So backdoor
    // them back into the Options array so it'll display.
    const maybeDisabledUser =
      isDisabled && teamMember.user ? (teamMember.user satisfies AvailableTeamMembersFragment) : undefined;
    return [undefinedOption, ...availableAssignees, maybeDisabledUser].compact();
  }, [availableAssignees, isDisabled, teamMember.user]);

  return (
    <SelectField
      compact
      labelStyle="hidden"
      data-testid="teamMember"
      label={teamMember.role.name}
      value={teamMemberId}
      options={options}
      fieldDecoration={internalUserAvatar}
      getOptionMenuLabel={internalUserMenuLabel}
      onSelect={async (userId) => {
        setTeamMemberId(userId);
        await updateProjectRole({ variables: { input: { projectId, role: teamMember.role.code, userId } } });
      }}
    />
  );
}
