import { Css, ScrollableParent } from "@homebound/beam";
import { ToDosBoard } from "src/components/to-dos/ToDosBoard";
import { useToDosPageDataQuery } from "src/generated/graphql-types";
import { queryResult, sortBy } from "src/utils";
import { nonClosedProjects } from "src/utils/projects";

export function ToDosPage() {
  const query = useToDosPageDataQuery({
    variables: { projectFilter: { status: nonClosedProjects } },
    fetchPolicy: "cache-first",
  });
  return queryResult(query, {
    data: ({ projects, cohorts, developments, markets, internalUsers }) => {
      const sortedProjects = sortBy(projects, (p) => p.name);
      const sortedCohorts = sortBy(cohorts, (p) => p.name);
      const sortedDevelopments = sortBy(developments, (d) => d.name);
      const sortedMarkets = sortBy(markets, (m) => m.name);

      return (
        <ScrollableParent xss={Css.h100.$}>
          <ToDosBoard
            teamMembers={internalUsers}
            projects={sortedProjects}
            cohorts={sortedCohorts}
            developments={sortedDevelopments}
            markets={sortedMarkets}
          />
        </ScrollableParent>
      );
    },
  });
}
