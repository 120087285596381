import { Global } from "@emotion/react";
import { Css, useTestIds } from "@homebound/beam";
import { LienWaiverCondition, LienWaiverType, PandaDocRole } from "src/generated/graphql-types";
import { Signature, SignatureDate } from "../../components/PandaDocField";
import { LienWaiverProps } from "../LienWaiverRouter";
import {
  amountInCents,
  buildAddress,
  claimantName,
  getExceptionsField,
  getSignatoryName,
  getTitleField,
  invoiceNumber,
  owner,
  payerName,
  throughDate,
} from "../utils";

const CA_OWNER = "MF-HB California PropCo, LLC";

const progressConditionCode: Record<LienWaiverType, Record<LienWaiverCondition, string>> = {
  [LienWaiverType.Progress]: {
    [LienWaiverCondition.Conditional]: "8132",
    [LienWaiverCondition.Unconditional]: "8134",
  },
  [LienWaiverType.Final]: {
    [LienWaiverCondition.Conditional]: "8136",
    [LienWaiverCondition.Unconditional]: "8138",
  },
};

export function CALienWaiver({ data }: LienWaiverProps) {
  const lienWaiver = data.lienWaiver!;
  const testIds = useTestIds({}, "CALienWaiver");
  const conditional = lienWaiver.condition.code;
  const type = lienWaiver.type.code;
  const isConditional = conditional === LienWaiverCondition.Conditional;
  const isFinal = lienWaiver.type.code === LienWaiverType.Final;
  return (
    <>
      <Global
        styles={{
          "@page": { size: "legal" },
          ul: Css.ml1.my2.$,
          li: Css.my1.$,
        }}
      />

      <div {...testIds.container} css={Css.p2.$}>
        <div css={Css.lgSb.tac.$}>
          {conditional} WAIVER AND RELEASE ON {type} PAYMENT
        </div>

        <div {...testIds.civilCode} css={Css.mb3.fwb.tac.$}>
          (California Civil Code § {progressConditionCode[type][conditional]})
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb3.fwb.$}>
            {isConditional ? (
              <>
                NOTICE: THIS DOCUMENT WAIVES THE CLAIMANT'S LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS EFFECTIVE
                ON RECEIPT OF PAYMENT. A PERSON SHOULD NOT RELY ON THIS DOCUMENT UNLESS SATISFIED THAT THE CLAIMANT HAS
                RECEIVED PAYMENT.
              </>
            ) : (
              <>
                NOTICE TO CLAIMANT: THIS DOCUMENT WAIVES AND RELEASES LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS
                UNCONDITIONALLY AND STATES THAT YOU HAVE BEEN PAID FOR GIVING UP THOSE RIGHTS. THIS DOCUMENT IS
                ENFORCEABLE AGAINST YOU IF YOU SIGN IT, EVEN IF YOU HAVE NOT BEEN PAID. IF YOU HAVE NOT BEEN PAID, USE A
                CONDITIONAL WAIVER AND RELEASE FORM.
              </>
            )}
          </p>
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.fwb.$}>Identifying Information</p>
          <div>
            <p>Name of Claimant: {claimantName(lienWaiver, "claimantName")}</p>
            <hr></hr>
            <p>Customer: {payerName(lienWaiver, "customer")}</p>
            <hr></hr>
            <p>Job Location: {buildAddress(lienWaiver)}</p>
            <hr></hr>
            <p>Owner: {owner(lienWaiver, CA_OWNER)}</p>
            <hr></hr>
            {!isFinal && (
              <>
                <p>Through Date: {throughDate(lienWaiver)}</p>
                <hr></hr>
              </>
            )}
            <p>Invoice Number: {invoiceNumber(lienWaiver)}</p>
            <hr></hr>
          </div>
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.ttl.ttc.fwb.$}>{conditional} Waiver and Release</p>
          <p css={Css.mb2.$}>
            This document waives and releases lien, stop payment notice, and payment bond rights the claimant has for
            labor and service provided, and equipment and material delivered, to the customer on this job
            {!isFinal && <> through the Through Date of this document</>}. Rights based upon labor or service provided,
            or equipment or material delivered, pursuant to a written change order that has been fully executed by the
            parties prior to the date that this document is signed by the claimant, are waived and released by this
            document, unless listed as an Exception below.
            {!isConditional && isFinal && <>The claimant has been paid in full.</>}
            {isConditional && (
              <>
                This document is effective only on the claimant's receipt of payment from the financial institution on
                which the following check is drawn:
              </>
            )}
          </p>

          {isConditional && (
            <div>
              <p>Maker of Check: {payerName(lienWaiver, "checkMaker")}</p>
              <hr></hr>
              <p>
                Amount of Check: <span css={Css.tac.$}>{amountInCents(lienWaiver)}</span>
              </p>
              <hr></hr>
              <p>Check Payable to: {claimantName(lienWaiver, "checkReceiver")}</p>
              <hr></hr>
            </div>
          )}

          {!isConditional && !isFinal && (
            <p css={Css.$}>The claimant has received the following progress payment: {amountInCents(lienWaiver)}</p>
          )}
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.fwb.$}>Exceptions</p>
          <div css={Css.mb2.$}>
            This document does not affect any of the following:
            {!isFinal && (
              <ol>
                <li>Retentions.</li>
                <li>Extras for which the claimant has not received payment.</li>
                {isConditional && (
                  <li>
                    The following progress payments for which the claimant has previously given a conditional waiver and
                    release but has not received payment:
                    <div css={Css.df.gap4.$}>
                      <ul css={Css.listReset.$}>
                        <li>Date(s) of waiver and release:</li>
                        <li>Amount(s) of unpaid progress payment(s):</li>
                      </ul>
                      <p>{getExceptionsField()}</p>
                    </div>
                  </li>
                )}
                <li>
                  Contract rights, including (A) a right based on rescission, abandonment, or breach of contract, and
                  (B) the right to recover compensation for work not compensated by the payment.
                </li>
              </ol>
            )}
          </div>
          {isFinal && <p> Disputed claims for extras in the amount of:</p>}
        </div>

        <div css={Css.mb2.$}>
          <p css={Css.mb2.fwb.$}>Signatures</p>
          <div css={Css.tdu.$}>
            <Signature role={PandaDocRole.Signatory} prefix="Claimant's Signature:" />
            {isConditional && !isFinal && <p>Claimant's Name: {getSignatoryName(lienWaiver)}</p>}
            <p>{getTitleField("Claimant's Title: ")}</p>
            <SignatureDate role={PandaDocRole.Signatory} prefix="Date:" />
          </div>
        </div>
      </div>
    </>
  );
}
