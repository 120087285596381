import {
  BoundCheckboxField,
  Button,
  Css,
  FormLines,
  Switch,
  Tooltip,
  useComputed,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useParams } from "react-router";
import { SubHeading } from "src/components/Typography";
import {
  DayOfWeek,
  Maybe,
  ProjectFeature,
  SaveScheduleInput,
  Scalars,
  ScheduleStatus,
  ScheduleTabQuery,
  ScheduleTab_ScheduleFragment,
  Stage,
  useSaveScheduleRemindersMutation,
  useSaveScheduleSettingsMutation,
  useScheduleTabQuery,
} from "src/generated/graphql-types";
import { queryResult, stageCodeToNameMapper } from "src/utils";
import { ListFieldState, ObjectConfig, ObjectState, useFormState } from "src/utils/formState";
import { useProjectContext } from "../context/ProjectContext";
import { PlanScheduleTab } from "./PlanScheduleTab";

export function ScheduleTab() {
  const { features } = useProjectContext();
  const isDynamicallyScheduled = features.includes(ProjectFeature.ProductConfigPlan);

  if (isDynamicallyScheduled) {
    return <PlanScheduleTab />;
  } else {
    return <LegacyScheduleTab />;
  }
}

export function LegacyScheduleTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useScheduleTabQuery({ variables: { projectId } });
  return queryResult(query, {
    data: (data) => <ScheduleTabView data={data} />,
  });
}

type ScheduleDataViewProps = {
  data: ScheduleTabQuery;
};

export function ScheduleTabView({ data }: ScheduleDataViewProps) {
  const { schedules } = data;
  const conSchedule = schedules.find((s) => s.stage === Stage.Construction);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: schedules,
      map: mapToFormState,
    },
  });

  const [saveSettings, { loading: saving }] = useSaveScheduleSettingsMutation();

  const onSubmit = async (rawScheduleSettings: ListFieldState<ScheduleSettingForStage>) => {
    // filter out completed stages
    const nonCompletedStages = rawScheduleSettings.value.filter(
      (stage) => stage.statusCode !== ScheduleStatus.Completed,
    );

    const scheduleSettings = nonCompletedStages.map(({ scheduleId }) => {
      const stage = rawScheduleSettings.value.find((r) => scheduleId === r.scheduleId);
      const workingDays = [
        ...(stage!.workingSaturday ? [DayOfWeek.Saturday] : []),
        ...(stage!.workingSunday ? [DayOfWeek.Sunday] : []),
      ];

      return {
        scheduleId: stage!.scheduleId,
        workingDays,
      };
    });

    await saveSettings({
      variables: { input: { scheduleSettings } },
      refetchQueries: ["ScheduleTab"],
      awaitRefetchQueries: true,
    });
  };

  return (
    <div data-testid="legacyScheduleSettings" css={Css.wPx(440).$}>
      <div css={Css.bgWhite.br8.bshBasic.p2.pr3.$}>
        <FormLines width="full">
          <h2 css={Css.lgSb.$}>Working Days</h2>
          <Observer>
            {() => {
              if (saving) {
                return <SubHeading>Saving...</SubHeading>;
              }
              return (
                <>
                  <div css={Css.df.$}>
                    {formState.scheduleSettings.rows.map((formStateForStage) => (
                      // Width added for right tooltip placement
                      <div
                        css={Css.wPx(120).$}
                        key={formStateForStage.scheduleId.originalValue}
                        data-testid="stageGrouping"
                      >
                        <h3 css={Css.mb1.baseMd.$}>
                          {stageCodeToNameMapper[formStateForStage.stageCode.originalValue]}
                        </h3>
                        <Tooltip
                          title="Working days cannot be changed when stage is complete"
                          disabled={formStateForStage.statusCode.value !== ScheduleStatus.Completed}
                          placement="right"
                        >
                          <FormLines>
                            <BoundCheckboxField
                              label="Saturday"
                              field={formStateForStage.workingSaturday}
                              disabled={formStateForStage.statusCode.value === ScheduleStatus.Completed}
                            />
                            <BoundCheckboxField
                              label="Sunday"
                              field={formStateForStage.workingSunday}
                              disabled={formStateForStage.statusCode.value === ScheduleStatus.Completed}
                            />
                          </FormLines>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                  <div css={Css.df.jcfe.gap1.$}>
                    <Button
                      disabled={!formState.dirty}
                      label="Save"
                      onClick={() => onSubmit(formState.scheduleSettings)}
                    />
                    <Button
                      disabled={!formState.dirty}
                      label="Cancel"
                      variant="tertiary"
                      onClick={() => formState.revertChanges()}
                    />
                  </div>
                </>
              );
            }}
          </Observer>
        </FormLines>
      </div>
      {conSchedule && <ScheduleReviewRemindersForm schedule={conSchedule} />}
    </div>
  );
}

type ScheduleSettingForStage = {
  scheduleId: Maybe<Scalars["ID"]>;
  workingSaturday: Maybe<boolean>;
  workingSunday: Maybe<boolean>;
  stageCode: Stage;
  statusCode: ScheduleStatus;
};

type FormValue = {
  scheduleSettings: ScheduleSettingForStage[];
};

const formConfig: ObjectConfig<FormValue> = {
  scheduleSettings: {
    type: "list",
    config: {
      scheduleId: { type: "value" },
      workingSaturday: { type: "value" },
      workingSunday: { type: "value" },
      stageCode: { type: "value" },
      statusCode: { type: "value" },
    },
  },
};

function mapToFormState(schedules: ScheduleTab_ScheduleFragment[]): FormValue {
  const scheduleSettings = schedules.map((schedule) => {
    const workingDays = schedule.scheduleSetting?.workingDays ?? [];
    return {
      scheduleId: schedule.id,
      workingSaturday: workingDays.includes(DayOfWeek.Saturday),
      workingSunday: workingDays.includes(DayOfWeek.Sunday),
      stageCode: schedule.stage,
      statusCode: schedule.status,
    };
  });

  return { scheduleSettings };
}

export function ScheduleReviewRemindersForm({ schedule }: { schedule: ScheduleTab_ScheduleFragment }) {
  const tid = useTestIds({}, "reviewReminders");
  const { triggerNotice } = useSnackbar();
  const [saveReminders] = useSaveScheduleRemindersMutation();
  const formState = useFormState({
    config: reviewRemindersFormConfig,
    init: {
      input: schedule,
      map: (schedule) => ({
        id: schedule.id,
        remindMonday: schedule.reviewReminderDays.includes(DayOfWeek.Monday),
        remindTuesday: schedule.reviewReminderDays.includes(DayOfWeek.Tuesday),
        remindWednesday: schedule.reviewReminderDays.includes(DayOfWeek.Wednesday),
        remindThursday: schedule.reviewReminderDays.includes(DayOfWeek.Thursday),
        remindFriday: schedule.reviewReminderDays.includes(DayOfWeek.Friday),
      }),
    },
  });
  const switchOn = useComputed(() => {
    const { remindMonday, remindTuesday, remindWednesday, remindThursday, remindFriday } = formState.value;
    return (remindMonday || remindTuesday || remindWednesday || remindThursday || remindFriday) ?? false;
  }, [formState]);

  async function onSubmit(rawFormState: ObjectState<ScheduleReviewReminders>) {
    const { id, remindMonday, remindTuesday, remindWednesday, remindThursday, remindFriday } = rawFormState.value;
    const input: SaveScheduleInput = {
      id,
      reviewReminderDays: [
        ...(remindMonday ? [DayOfWeek.Monday] : []),
        ...(remindTuesday ? [DayOfWeek.Tuesday] : []),
        ...(remindWednesday ? [DayOfWeek.Wednesday] : []),
        ...(remindThursday ? [DayOfWeek.Thursday] : []),
        ...(remindFriday ? [DayOfWeek.Friday] : []),
      ],
    };

    await saveReminders({
      variables: { input },
      onCompleted: () => {
        triggerNotice({ message: "Schedule Review Reminders saved" });
      },
    });
  }

  return (
    <div css={Css.mt2.bgWhite.br8.bshBasic.p2.pr3.$}>
      <h2 css={Css.lgSb.$}>Schedule Review Reminders</h2>
      <p css={Css.my1.$}>
        These are reminders to review and update the schedule daily. These are sent as To-Dos and emails to the
        Superintendent assigned to this project.
      </p>
      <Observer>
        {() => (
          <>
            <FormLines>
              <div css={Css.mt2.mb3.$}>
                <Switch
                  {...tid.switch}
                  label="Send Review Reminders"
                  selected={switchOn}
                  onChange={(checked) => {
                    if (checked) {
                      formState.remindMonday.set(true);
                      formState.remindTuesday.set(true);
                      formState.remindWednesday.set(true);
                      formState.remindThursday.set(true);
                      formState.remindFriday.set(true);
                    } else {
                      formState.remindMonday.set(false);
                      formState.remindTuesday.set(false);
                      formState.remindWednesday.set(false);
                      formState.remindThursday.set(false);
                      formState.remindFriday.set(false);
                    }
                  }}
                />
              </div>
              <BoundCheckboxField label="Monday" field={formState.remindMonday} />
              <BoundCheckboxField label="Tuesday" field={formState.remindTuesday} />
              <BoundCheckboxField label="Wednesday" field={formState.remindWednesday} />
              <BoundCheckboxField label="Thursday" field={formState.remindThursday} />
              <BoundCheckboxField label="Friday" field={formState.remindFriday} />
            </FormLines>
            <div css={Css.df.jcfe.gap1.$}>
              <Button disabled={!formState.dirty} label="Save" onClick={() => onSubmit(formState)} {...tid.save} />
              <Button
                disabled={!formState.dirty}
                label="Cancel"
                variant="tertiary"
                onClick={() => formState.revertChanges()}
                {...tid.cancel}
              />
            </div>
          </>
        )}
      </Observer>
    </div>
  );
}

type ScheduleReviewReminders = {
  id: Scalars["ID"];
  remindMonday: Maybe<boolean>;
  remindTuesday: Maybe<boolean>;
  remindWednesday: Maybe<boolean>;
  remindThursday: Maybe<boolean>;
  remindFriday: Maybe<boolean>;
};
const reviewRemindersFormConfig: ObjectConfig<ScheduleReviewReminders> = {
  id: { type: "value" },
  remindMonday: { type: "value" },
  remindTuesday: { type: "value" },
  remindWednesday: { type: "value" },
  remindThursday: { type: "value" },
  remindFriday: { type: "value" },
};
