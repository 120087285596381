import { CommentStreamVisibility, useCommentableInboxQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { Inbox } from "./Inbox";

export type InboxProps = {
  commentableId: string;
  visibility: CommentStreamVisibility;
  displayCommentableNames?: {
    closeStream: boolean;
    openStream: boolean;
  };
};

export function CommentableInbox({ commentableId, visibility, displayCommentableNames }: InboxProps) {
  const query = useCommentableInboxQuery({ variables: { id: commentableId } });
  return queryResult(query, ({ commentable }) => (
    <Inbox
      commentableId={commentable.id}
      streams={commentable.streams}
      visibility={visibility}
      displayCommentableNames={displayCommentableNames}
    />
  ));
}
