import { Icon, Palette, RouteTabWithContent, TabsWithContent } from "@homebound/beam";
import {
  createDevelopmentContractLineItemsUrl,
  createDevelopmentContractOverviewUrl,
  createDevelopmentContractPurchaseOrdersUrl,
  createDevelopmentContractUnitPricingUrl,
} from "src/RouteUrls";
import { BidContractRevisionFragment } from "src/generated/graphql-types";
import { useTabParam } from "src/hooks/useTabParam";
import { DevelopmentContractLineItemsPage } from "src/routes/developments/procurement/DevelopmentContractLineItemsPage";
import { DevelopmentContractOverviewPage } from "src/routes/developments/procurement/DevelopmentContractOverviewPage";
import { DevelopmentContractPurchaseOrdersPage } from "src/routes/developments/procurement/DevelopmentContractPurchaseOrdersPage";
import { developmentPaths } from "src/routes/routesDef";
import { DevelopmentContractUnitPricingPage } from "../procurement/DevelopmentContractUnitPricingPage";

type DevelopmentContractTabsProp = {
  selectedRevision: BidContractRevisionFragment;
};

export function DevelopmentContractTabs({ selectedRevision }: DevelopmentContractTabsProp) {
  const [selectedTab, setSelectedTab] = useTabParam("Overview");
  const tabs: RouteTabWithContent[] = [
    {
      href: createDevelopmentContractOverviewUrl(selectedRevision.bidContract.parent.id, selectedRevision.id),
      path: developmentPaths.contractOverview,
      name: "Overview",
      render: () => <DevelopmentContractOverviewPage />,
    },
    {
      href: createDevelopmentContractLineItemsUrl(selectedRevision.bidContract.parent.id, selectedRevision.id),
      path: developmentPaths.contractLineItems,
      name: "Plan-based Pricing",
      ...(selectedRevision.hasOutdatedScope
        ? { endAdornment: <Icon icon="errorCircle" inc={2} color={Palette.Gray900} /> }
        : {}),
      render: () => <DevelopmentContractLineItemsPage />,
    },
    {
      href: createDevelopmentContractUnitPricingUrl(selectedRevision.bidContract.parent.id, selectedRevision.id),
      path: developmentPaths.unitPricing,
      name: "Unit Pricing",
      render: () => <DevelopmentContractUnitPricingPage />,
    },
    ...(!selectedRevision.bidContract.isInternalEstimate
      ? [
          {
            href: createDevelopmentContractPurchaseOrdersUrl(
              selectedRevision.bidContract.parent.id,
              selectedRevision.id,
            ),
            path: developmentPaths.contractPurchaseOrders,
            name: "Purchase Orders",
            render: () => <DevelopmentContractPurchaseOrdersPage />,
          },
        ]
      : []),
  ];

  return <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} />;
}
