import {
  SaveHomeownerContractInput,
  useHomeownerContractChangeOrder2Query,
  useSaveHomeownerContractChangeOrderMutation,
} from "src/generated/graphql-types";
import { useModeParam } from "src/hooks/useModeParam";
import { DetailsEditor } from "src/routes/projects/homeowner-contracts/DetailsEditor";
import { createHomeownerContractsUrl } from "src/RouteUrls";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";

export type DetailsTabProps = {
  projectId: string;
  changeOrderId: string;
};

export function ChangeOrderDetailsTab(props: DetailsTabProps) {
  const { projectId, changeOrderId } = props;
  const { mode, onEdit, onSave, onCancel } = useModeParam({
    listPageUrl: createHomeownerContractsUrl(projectId),
  });
  const query = useHomeownerContractChangeOrder2Query({ variables: { changeOrderId } });
  const [saveChangeOrder] = useSaveHomeownerContractChangeOrderMutation();

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const stage = query.data.homeownerContractChangeOrder.contract.projectStage.stage.code;
  return (
    <DetailsEditor
      projectId={projectId}
      changeEvent={query.data.homeownerContractChangeOrder.changeEvent}
      contract={query.data.homeownerContractChangeOrder}
      mode={mode}
      onCancel={onCancel}
      onEdit={onEdit}
      stage={stage}
      onSave={async (input: SaveHomeownerContractInput) => {
        await saveChangeOrder({ variables: { input } });
        onSave();
      }}
    />
  );
}
