import { Css, IconButton, useModal } from "@homebound/beam";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

type WizardHeaderProps = React.PropsWithChildren<{
  pre: string | ReactNode;
  header: string;
  post: string;
  postBd?: string;
  /** Hides the button if true, but doesn't stop a user from hitting Esc and closing a wizard by accident. */
  uncloseable?: boolean;
  contentBackGroundColor?: typeof Css.bgGray100.$;
  /** When provided will navigate to this page when the close action is fired */
  onCloseReturnUrl?: string;
  stepDirty?: boolean;
}>;

export function WizardHeader(props: WizardHeaderProps) {
  const {
    pre,
    header,
    post,
    postBd,
    children,
    uncloseable,
    stepDirty,
    contentBackGroundColor = {},
    onCloseReturnUrl,
  } = props;
  const { closeModal, openModal } = useModal();
  const history = useHistory();
  return (
    <>
      <div css={Css.df.fdr.aifs.jcsb.px3.py4.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.lgBd.ttc.$}>{pre}</div>
          <div css={Css.xl3Sb.$}>{header}</div>
          <div css={Css.sm.wspw.$}>{post}</div>
          {postBd && <div css={Css.mtPx(-8).smBd.$}>{postBd}</div>}
        </div>
        {!uncloseable && (
          <IconButton
            icon="x"
            inc={5}
            onClick={() => {
              if (stepDirty) {
                openModal({
                  content: (
                    <ConfirmationModal
                      confirmationMessage="You have unsaved changes that will be lost. Do you want to discard these changes?"
                      onConfirmAction={() => history.push(onCloseReturnUrl ?? "")}
                      title="Discard changes?"
                      label="Discard changes"
                      danger
                    />
                  ),
                });
              } else {
                return onCloseReturnUrl ? history.push(onCloseReturnUrl ?? "") : closeModal();
              }
            }}
          />
        )}
      </div>
      <div css={Css.w100.bb.bcGray300.$} />
      <div css={{ ...Css.px3.h100.$, ...contentBackGroundColor }}>{children}</div>
    </>
  );
}
