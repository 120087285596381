import {
  BoundSelectField,
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { Link } from "react-router-dom";
import { createProjectScheduleUrl } from "src/RouteUrls";
import {
  AllocateProjectItemsTaskModalQuery,
  ProjectItem,
  SaveScheduleTaskInput,
  Stage,
  TaskStatus,
  useAllocateProjectItemsTaskModalQuery,
  useSaveProjectItemsMutation,
} from "src/generated/graphql-types";
import { DeepPartial, isDefined, queryResult, stageCodeToNameMapper } from "src/utils";
import { StatusIndicator } from "./StatusIndicator";

export type AllocateItemsTaskProps = {
  projectItems: DeepPartial<ProjectItem>[];
  onSave: VoidFunction | (() => Promise<void>);
  projectId: string;
  stage: Stage | undefined;
};

export function AllocateItemsTaskModal(props: AllocateItemsTaskProps) {
  const { projectId, stage, onSave, projectItems } = props;
  const allocateProjectQuery = useAllocateProjectItemsTaskModalQuery({
    variables: { projectId, stage: stage! },
    skip: !projectId || !stage,
  });

  return queryResult(allocateProjectQuery, ({ scheduleTasks }) => (
    <AllocateItemsTaskModalView
      onSave={onSave}
      scheduleTasks={scheduleTasks}
      projectItems={projectItems}
      projectId={projectId}
      stage={stage}
    />
  ));
}

export function AllocateItemsTaskModalView(
  props: AllocateItemsTaskProps & { scheduleTasks: AllocateProjectItemsTaskModalQuery["scheduleTasks"] },
) {
  const { scheduleTasks, projectItems, onSave, projectId, stage } = props;
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [saveProjectItems] = useSaveProjectItemsMutation();
  const tid = useTestIds({});

  const formState = useFormState({
    config: formConfig,
    init: {
      input: projectItems,
      map: (projectItems) => mapToForm(projectItems),
    },
  });

  const hasUnallocatedItems = useComputed(
    // with regards to `hasUnallocatedItems` form values, we only care if pis without tasks set aren't dirty
    () => formState.projectItems.rows.filter((os) => !os.task.value?.id).some((os) => !os.dirty),
    [formState],
  );
  const pisWithCompletedTasks = useComputed(
    () => formState.projectItems.rows.filter((os) => os.task.status.value === TaskStatus.Complete),
    [formState],
  );

  const completedTasksMessage = "These items are allocated to completed tasks";

  return (
    <>
      <ModalHeader>Unallocated item codes</ModalHeader>

      <ModalBody>
        <div>
          <div css={Css.smSb.gap1.mb1.$} {...tid.title}>
            The following item codes must be allocated to a schedule task before releasing these item codes. Select a
            task for each item.
          </div>
          {projectId && (
            <Link data-testid="scheduleLink" to={createProjectScheduleUrl(projectId, stage)} target="_blank">
              <div css={Css.df.fdr.gap1.my3.$}>
                View Schedule <Icon icon="linkExternal" inc={2} />
              </div>
            </Link>
          )}
        </div>

        {formState.projectItems.rows.map((os) => {
          return (
            <div key={os.id.value}>
              <BoundSelectField
                field={os.task.id}
                label={os.displayName.value}
                options={scheduleTasks}
                getOptionLabel={(tsk) => tsk.name}
                getOptionValue={(tsk) => tsk.id}
                fieldDecoration={(tsk) => <StatusIndicator status={tsk.status} />}
                labelStyle="left"
                disabled={
                  scheduleTasks.isEmpty && `No ${stage ? stageCodeToNameMapper[stage] : ""} Schedule Tasks found`
                }
                getOptionMenuLabel={(tsk) => (
                  <div css={Css.dg.gtc("auto auto").xs.aic.$}>
                    <StatusIndicator status={tsk.status} />
                    <span css={Css.ml3.$}>{tsk.name}</span>
                  </div>
                )}
                onSelect={(selectedTaskId) => {
                  const selectedTask = scheduleTasks.find((t) => t.id === selectedTaskId);
                  if (selectedTask) {
                    os.task.set(selectedTask);
                  }
                }}
              />
            </div>
          );
        })}

        {pisWithCompletedTasks.nonEmpty && (
          <Observer>
            {() => (
              <>
                <div css={Css.df.fdc.my5.$}>
                  <div css={Css.sm.mb1.$} {...tid.completedTaskMessage}>
                    {completedTasksMessage}
                  </div>
                  {pisWithCompletedTasks.map((pi) => {
                    return (
                      <div key={pi.id.value} css={Css.dg.gtc("1fr 1fr").aib.xs.$}>
                        <div {...tid.completedTaskItem}> {pi.displayName.value}</div>
                        <Link to={createProjectScheduleUrl(projectId!, stage, pi.task.id.value!)} target="_blank">
                          <div css={Css.df.fdr.aib.$}>{pi.task.name.value}</div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Observer>
        )}
      </ModalBody>

      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button
          label="Continue"
          variant="primary"
          disabled={hasUnallocatedItems && "All items must be allocated to a task"}
          onClick={async () => {
            const res = await saveProjectItems({
              variables: { input: { projectItems: mapToSavePisInput(formState) } },
            });

            if (res.data) {
              triggerNotice({ message: "line items succesfully allocated to tasks" });
              closeModal();
              onSave && (await onSave());
            }
          }}
        />
      </ModalFooter>
    </>
  );
}

type AllocateProjectItems = {
  id: string | undefined;
  displayName: string | undefined;
  allocationReason: string | undefined | null;
  task: Pick<SaveScheduleTaskInput, "id" | "name" | "status"> | undefined;
};

type AllocateItemsData = {
  projectItems: AllocateProjectItems[];
};

export const formConfig: ObjectConfig<AllocateItemsData> = {
  projectItems: {
    type: "list",
    config: {
      id: { type: "value" },
      displayName: { type: "value" },
      allocationReason: { type: "value" },
      task: {
        type: "object",
        config: {
          id: { type: "value" },
          name: { type: "value" },
          status: { type: "value" },
        },
      },
    },
  },
};

function mapToForm(projectItems: DeepPartial<ProjectItem>[]) {
  return {
    projectItems: projectItems.uniqueByKey("id").map((pi) => {
      return {
        id: pi.id,
        displayName: pi.displayName,
        allocationReason: pi.allocationReason,
        task: {
          id: pi.task?.id,
          name: pi.task?.name,
          status: pi.task?.status?.code,
        },
      };
    }),
  };
}

function mapToSavePisInput(formState: ObjectState<AllocateItemsData>) {
  const projectItemsInput = formState.projectItems.value
    .filter((pi) => isDefined(pi.task?.id))
    .map((pi) => ({
      id: pi.id,
      taskId: pi.task?.id,
      allocationReason: formState.projectItems.rows.first?.allocationReason.value,
    }));

  return projectItemsInput;
}
