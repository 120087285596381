import { Accordion, LoadingSkeleton, useTestIds } from "@homebound/beam";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { ActionItemsSchedulingQuery, useActionItemsStaleTasksQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { nonClosedProjects } from "src/utils/projects";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { ItemCard } from "../../components/ItemCard";
import { ActionItemTypes } from "../../enums";
import { ActionItemsContainer, EmptyActionItems, LINK_CSS } from "./ActionItemsPage";

export function ActionItemsStaleTasks() {
  const { filter } = useDashboardFilterContext();

  const query = useActionItemsStaleTasksQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      internalUser: filter.internalUser,
      project: filter.project,
      scheduleIsActive: true,
      stale: true,
      projectStatus: nonClosedProjects,
    },
    skip: !filter.internalUser,
  });

  return queryResult(query, {
    data: (data) => <ActionItemsStaleTasksLayout data={data} />,
    loading: () => <LoadingSkeleton rows={3} columns={3} size="lg" />,
    showLoading: "always",
  });
}

function ActionItemsStaleTasksLayout({ data }: { data: ActionItemsSchedulingQuery }) {
  const tids = useTestIds({}, "actionItemStaleTasks");
  const { scheduleTasks } = data;
  const { openTaskDetailPane, selectedTaskId } = useDashboardFilterContext();

  const staleTaskItems = useMemo(() => {
    return scheduleTasks.map((task) => (
      <ItemCard
        key={`stale_task_${task.id}`}
        actionType={ActionItemTypes.STALE_TASKS}
        onClick={() => openTaskDetailPane(task)}
        title={"Stale Task"}
        description={
          <p>
            A schedule task{" "}
            <span css={LINK_CSS} onClick={() => openTaskDetailPane(task)}>
              {task.name}
            </span>{" "}
            assigned to{" "}
            <span css={LINK_CSS} onClick={() => openTaskDetailPane(task)}>
              {task?.assignee?.name}
            </span>{" "}
            is {formatDistanceToNow(task.interval.endDate)} overdue.
          </p>
        }
        duration={formatDistanceToNow(task.interval.startDate, { addSuffix: true })}
        project={task.project}
        assignee={task.assignee?.user}
        disabledBtn={task.id === selectedTaskId}
        parent={task.parent}
      />
    ));
  }, [scheduleTasks, openTaskDetailPane, selectedTaskId]);

  return (
    <Accordion
      {...tids}
      title={`Stale Tasks (${staleTaskItems.length}${staleTaskItems.length === 100 ? "+" : ""})`}
      defaultExpanded
      topBorder={false}
    >
      {staleTaskItems.isEmpty && <EmptyActionItems title="No stale tasks" />}
      <ActionItemsContainer count={staleTaskItems.length}>{staleTaskItems}</ActionItemsContainer>
    </Accordion>
  );
}
