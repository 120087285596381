import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { Observer } from "mobx-react";

type EstimateAcceptedModalProps = {
  isChangeOrderEstimate: boolean;
  onClickCreate: () => void;
  disabledReason: string | undefined;
};

export function EstimateAcceptedModal(props: EstimateAcceptedModalProps) {
  const { onClickCreate, isChangeOrderEstimate, disabledReason } = props;
  const { closeModal } = useModal();

  return (
    <>
      <ModalHeader>Estimate Accepted</ModalHeader>
      <ModalBody>
        Your estimate is marked as accepted.
        <br />
        <br />
        <b>
          Create a {isChangeOrderEstimate ? "change order" : "contract"} to finalize this change. These changes will be
          reflected in the homeowner contract.
        </b>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Close" onClick={closeModal} />
              <Button
                label={isChangeOrderEstimate ? "Complete Change Order" : "Complete Contract"}
                disabled={disabledReason}
                onClick={() => {
                  closeModal();
                  onClickCreate();
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}
