import {
  Avatar,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  simpleHeader,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { sentenceCase } from "change-case";
import { subWeeks } from "date-fns";
import { useMemo } from "react";
import { useParams } from "react-router";
import { formatDate } from "src/components";
import {
  DateOperation,
  JobLogsTile_NotesFragment,
  JobLogsTileQueryResult,
  useJobLogsTileQuery,
} from "src/generated/graphql-types";
import { EmptyTile } from "src/routes/projects/dashboard/components/EmptyTile";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createProjectJobLogNoteDrawerUrl, createProjectJobLogsUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";

export function JobLogsTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();

  const logDate = useMemo(() => {
    const today = new DateOnly(new Date());
    const fromDate = new DateOnly(subWeeks(today, 2));
    return { op: DateOperation.Between, value: fromDate, value2: today };
  }, []);

  const query = useJobLogsTileQuery({ variables: { filter: { project: [projectId], logDate } } });
  const rows = useMemo(() => createRows(query.data), [query.data]);
  const columns = useMemo(() => createColumns(projectId), [projectId]);
  return (
    <Tile href={createProjectJobLogsUrl(projectId)} {...props}>
      {queryResult(query, ({ jobLogs }) => {
        // If only the header row is returned, then there are no job logs.
        if (rows.length === 1) {
          return (
            <EmptyTile>
              <div css={Css.maxwPx(180).$}>No Job Logs have been created in the last two weeks.</div>
            </EmptyTile>
          );
        }
        return (
          <GridTable
            columns={columns}
            rows={rows}
            style={{ allWhite: true, rowHeight: "fixed", rowHover: false, cellTypography: "xsMd" }}
          />
        );
      })}
    </Tile>
  );
}

type JobLogNoteRow = JobLogsTile_NotesFragment & { jobLogId: string };
type Row = SimpleHeaderAndData<JobLogNoteRow>;

function createRows(data: JobLogsTileQueryResult["data"]): GridDataRow<Row>[] {
  const jobLogNotes: JobLogNoteRow[] = [];
  if (data?.jobLogs) {
    // Add the job log id to each job log note so we can link to the job log note drawer.
    data.jobLogs.forEach((jl) => jl.notes?.forEach((jln) => jobLogNotes.push({ ...jln, jobLogId: jl.id })));
  }

  return [
    simpleHeader,
    ...jobLogNotes
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .map((jln) => ({ kind: "data" as const, id: jln.id, data: jln })),
  ];
}

function createColumns(projectId: string): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Name",
      data: ({ title, name, jobLogId, id }) => ({
        content: title ?? sentenceCase(name),
        onClick: createProjectJobLogNoteDrawerUrl(projectId, jobLogId, id),
      }),
    }),
    column<Row>({
      header: "Added",
      data: ({ createdAt }) => formatDate(createdAt),
      w: "88px",
    }),
    column<Row>({
      header: "By",
      data: ({ internalUser }) => <Avatar src={internalUser.avatar} name={internalUser.name} size="sm" />,
      w: "48px",
      align: "center",
    }),
  ];
}
