import { Button, Css, ModalBody, ModalFooter, ModalHeader, Switch, useModal } from "@homebound/beam";
import { useState } from "react";
import { useGlobalOptionsIconsQuery } from "src/generated/graphql-types";
import { DesignUpgradeIcons } from "../designPackageIconUtils";

type DesignUpgradeIconPickerModalProps = {
  onClose: (selectedIcon: string) => void;
  selectedIcon: string | undefined | null;
  optionName: string;
};

export function DesignUpgradeIconPickerModal({ selectedIcon, optionName, onClose }: DesignUpgradeIconPickerModalProps) {
  const { closeModal } = useModal();
  const [activeIcon, setActiveIcon] = useState(selectedIcon);
  const [showSelectedIcons, setShowSelectedIcons] = useState(true);

  const query = useGlobalOptionsIconsQuery({ variables: { filter: { hasDesignUpgradeIcon: true } } });
  const iconsInUse = new Set(query.data?.globalOptions.map(({ designUpgradeIconName }) => designUpgradeIconName) ?? []);

  // filter icons when we want to hide any that are already in use
  const icons = Object.values(DesignUpgradeIcons);
  const maybeFilteredIcons = showSelectedIcons ? icons : icons.filter((icon) => !iconsInUse.has(icon.displayName));

  return (
    <div>
      <ModalHeader children={<div css={Css.smMd.$}>Select icon for '{optionName}'</div>}></ModalHeader>
      <ModalBody>
        <div css={Css.mb2.mt1.$}>
          <Switch compact label="Show already selected" selected={showSelectedIcons} onChange={setShowSelectedIcons} />
        </div>
        <div css={Css.df.gap1.fww.p1.$}>
          {maybeFilteredIcons.map((icon) => {
            const Icon = icon;
            const isActive = activeIcon === icon.displayName;
            const iconIsInUse = iconsInUse.has(icon.displayName);
            return (
              <div
                data-testid={icon.displayName}
                key={icon.displayName}
                css={Css.pPx(3).cursorPointer.br4.if(isActive).bgBlue700.else.onHover.bshHover.$}
                onClick={() => setActiveIcon(icon.displayName)}
              >
                <Icon
                  data-testid={`${icon.displayName}Icon`}
                  color={iconIsInUse && !isActive ? "lightGrey" : isActive ? "white" : "black"}
                />
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap1.$}>
          <Button label="Cancel" variant="secondary" onClick={closeModal} />
          <Button
            label="Select"
            variant="primary"
            disabled={!activeIcon}
            onClick={() => {
              onClose(activeIcon!);
              closeModal();
            }}
          />
        </div>
      </ModalFooter>
    </div>
  );
}
