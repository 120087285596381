import {
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  selectColumn,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useSnackbar,
} from "@homebound/beam";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { chipCell, linkHeader, Price, priceCell, tagCell } from "src/components";
import { StepActions } from "src/components/stepper";
import {
  ReassignPurchaseOrdersCommitmentFragment,
  useReassignPurchaseOrdersQuery,
  useReviewOrdersStepReleaseCommitmentsMutation,
} from "src/generated/graphql-types";
import { createDevelopmentContractOverviewUrl, createDevelopmentPurchaseOrdersUrl } from "src/RouteUrls";
import { capitalize, commitmentStatusToTagTypeMapper, pluralize, queryResult } from "src/utils";
import { DevelopmentParams } from "../../routesDef";
import { ReassignPurchaseOrdersType } from "./enums";
import { ReassignPurchaseOrdersState } from "./ReassignPurchaseOrdersPage";

type SavedCommitmentsType = {
  id: string;
  oldCost: number;
};

export type ReassignPurchaseOrdersReleaseState = {
  savedCommitments: SavedCommitmentsType[];
} & ReassignPurchaseOrdersState;

export function ReassignPurchaseOrdersReleaseStep() {
  const location = useLocation<ReassignPurchaseOrdersReleaseState>();
  const selectedCommitments = location.state?.savedCommitments ?? [];
  const reassignType = location.state?.reassignType;
  const bcrId = location.state?.bcrId;

  const result = useReassignPurchaseOrdersQuery({
    variables: { filter: { commitmentIds: selectedCommitments.map((c) => c.id) } },
  });

  return queryResult(result, (data) => {
    const commitments: CommitmentRowType[] = data.commitments.map((c) => ({
      ...c,
      // Assign old cost to new commitment
      oldCost: selectedCommitments.find((sc) => sc.id === c.id)?.oldCost ?? 0,
    }));

    return <ReassignPurchaseOrdersReleaseView commitments={commitments} reassignType={reassignType} bcrId={bcrId} />;
  });
}

type ReassignPurchaseOrdersReleaseTableProps = {
  commitments: CommitmentRowType[];
  reassignType: ReassignPurchaseOrdersType;
  bcrId: string | undefined;
};

function ReassignPurchaseOrdersReleaseView({
  commitments,
  reassignType,
  bcrId,
}: ReassignPurchaseOrdersReleaseTableProps) {
  const history = useHistory();
  const tableApi = useGridTableApi<Row>();
  const { developmentId } = useParams<DevelopmentParams>();
  const [releaseCommitments, { loading }] = useReviewOrdersStepReleaseCommitmentsMutation();
  const { triggerNotice } = useSnackbar();
  const selectedCommitmentIds = useComputed(() => tableApi.getSelectedRowIds("commitment"), [tableApi]);
  const columns = useMemo(() => createColumns(), []);
  const rows = useMemo(() => createRows(commitments), [commitments]);

  return (
    <>
      <GridTable columns={columns} rows={rows} api={tableApi} stickyHeader />
      <StepActions>
        <Button
          disabled={loading || selectedCommitmentIds.length === 0}
          label="Release POs"
          onClick={async () => {
            const result = await releaseCommitments({ variables: { commitmentLikeIds: selectedCommitmentIds } });
            if (result.data) {
              triggerNotice({
                icon: "success",
                // e.g. "Trade partner successfully changed on 3 Purchase Orders"
                message: `${capitalize(reassignType)} successfully ${
                  reassignType === ReassignPurchaseOrdersType.MANAGE ? "applied" : "changed"
                } on ${commitments.length} Purchase ${pluralize(commitments, "Order")}`,
              });
              history.push(
                reassignType === ReassignPurchaseOrdersType.MANAGE
                  ? createDevelopmentContractOverviewUrl(developmentId, bcrId!)
                  : createDevelopmentPurchaseOrdersUrl(developmentId),
              );
            }
          }}
        />
      </StepActions>
    </>
  );
}

type CommitmentRowType = ReassignPurchaseOrdersCommitmentFragment & {
  oldCost: number;
};
type HeaderRow = { kind: "header" };
type CommitmentLikeRow = { kind: "commitment"; data: CommitmentRowType };
type Row = HeaderRow | CommitmentLikeRow;

const createColumns = (): GridColumn<Row>[] => [
  selectColumn<Row>({ w: "32px" }),
  column<Row>({
    header: "PO #",
    commitment: ({ accountingNumber, blueprintUrl }) => linkHeader(String(accountingNumber), blueprintUrl.path),
    w: 0.6,
  }),
  column<Row>({
    header: "Address",
    commitment: ({ project }) => project.buildAddress.street1,
  }),
  column<Row>({
    header: "Trade Partner",
    commitment: ({ tradePartner }) => tradePartner?.name || tradePartner?.id,
  }),
  column<Row>({
    header: "Cost Codes",
    commitment: ({ lineItems }) => chipCell(lineItems.map((li) => li.costCode.number)),
  }),
  numericColumn<Row>({
    header: "Contract Version",
    commitment: (row) => row.bidContractRevision?.version,
    w: 0.5,
  }),
  numericColumn<Row>({
    header: "Old Cost",
    commitment: ({ oldCost }) => priceCell({ valueInCents: oldCost }),
  }),
  numericColumn<Row>({
    header: "Proposed Cost",
    commitment: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
  }),
  numericColumn<Row>({
    header: "Cost Change",
    commitment: ({ committedInCents, oldCost }) => <Price valueInCents={committedInCents - oldCost} displayDirection />,
  }),
  column<Row>({
    header: "Status",
    commitment: ({ status, statusText }) => tagCell(commitmentStatusToTagTypeMapper[status], statusText),
    clientSideSort: false,
    w: 0.6,
  }),
];

const createRows = (commitmentLikes: CommitmentRowType[]): GridDataRow<Row>[] => [
  simpleHeader,
  ...commitmentLikes.map((cl) => ({
    kind: "commitment" as const,
    id: cl.id,
    data: cl,
    initSelected: true,
  })),
];
