import {
  column,
  dateColumn,
  GridTable,
  IconButton,
  simpleDataRows,
  SimpleHeaderAndData,
  Tooltip,
} from "@homebound/beam";
import { useMemo } from "react";
import { dateCell } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { AssetInfoFragment, DocumentEditorDetailFragment } from "src/generated/graphql-types";

export type DocumentVersionTableProps = {
  document: DocumentEditorDetailFragment;
};

export function DocumentVersionTable(props: DocumentVersionTableProps) {
  const { document } = props;
  const assets = document?.assets || [];
  const columns = useMemo(() => createColumns(), []);

  return (
    <GridTable
      id="documentVersionTable"
      columns={columns}
      rows={simpleDataRows(assets)}
      style={{ rowHeight: "fixed" }}
      sorting={{
        on: "client",
        initial: ["version", "DESC"],
      }}
    />
  );
}

function createColumns() {
  const clientSideSort = false;
  return [
    column<Row>({
      clientSideSort,
      id: "version",
      header: "Version",
      data: ({ version, fileName }) => {
        return {
          content: version,
          tooltip: fileName,
          value: parseInt(version, 10),
        };
      },
    }),
    dateColumn<Row>({
      clientSideSort,
      header: "Date Added",
      data: ({ createdAt }) => dateCell(createdAt),
    }),
    column<Row>({
      clientSideSort,
      header: {
        content: "Preview",
        alignment: "center",
      },
      data: (data) => {
        return {
          content: (
            <Tooltip title={data.fileName} placement="left">
              <AssetGallery assets={[data]}>
                {(openGallery) => <IconButton icon={"file"} onClick={() => openGallery(data)} />}
              </AssetGallery>
            </Tooltip>
          ),
          value: 1,
          alignment: "center",
        };
      },
    }),
  ];
}

type Row = SimpleHeaderAndData<AssetInfoFragment>;
