import {
  BoundNumberField,
  BoundSelectField,
  column,
  Css,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  selectColumn,
  simpleHeader,
  Tooltip,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useContext, useMemo } from "react";
import { chipCell, dateCell, linkHeader, priceCell, tagCell } from "src/components";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { WizardHeader } from "src/components/stepper/useStepperWizard/WizardHeader";
import {
  ReassignPurchaseOrdersCommitmentFragment,
  useAssignWorkToBidContractsMutation,
  useReassignPurchaseOrdersQuery,
} from "src/generated/graphql-types";
import { commitmentStatusToTagTypeMapper, fail, groupBy, isEmpty, queryResult } from "src/utils";
import { ReassignOption, ReassignPurchaseOrdersContext } from "../ReassignPurchaseOrdersWizard";

export function ReassignPurchaseOrdersStep() {
  const { selectedCommitmentIds, mode } = useContext(ReassignPurchaseOrdersContext);
  const query = useReassignPurchaseOrdersQuery({ variables: { filter: { commitmentIds: selectedCommitmentIds } } });

  return queryResult(query, (data) => {
    const commitments: CommitmentRowType[] = data.commitments.map((c) => ({
      fragment: c,
      bcrId: undefined,
      proposedCost: undefined,
    }));
    return (
      <WizardHeader
        pre={`CHANGE ${mode.toUpperCase()}S`}
        header={`Select new ${mode}`}
        post={`Select the proposed ${mode} for each Purchase Order. The proposed ${mode} MUST be able to cover all cost codes in each PO.
    To change trades for partial POs, you must first void the PO and then choose the new trade in the Lot Release workflow.`}
      >
        <ReassignPurchaseOrdersView commitments={commitments} mode={mode} />
      </WizardHeader>
    );
  });
}

type ReassignPurchaseOrdersSelectTableProps = {
  mode: ReassignOption;
  commitments: CommitmentRowType[];
};

export function ReassignPurchaseOrdersView(props: ReassignPurchaseOrdersSelectTableProps) {
  const { commitments, mode } = props;
  const { setChangeEventIds } = useContext(ReassignPurchaseOrdersContext);
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [assignWorkToBidContract, { loading }] = useAssignWorkToBidContractsMutation();
  const tableApi = useGridTableApi<Row>();
  const selectedCommitments = useComputed(() => tableApi.getSelectedRowIds("commitment"), [tableApi]);
  const formState = useFormState({ config: formConfig, init: { input: commitments, map: mapToForm } });
  const columns = useMemo(() => createColumns(mode), [mode]);
  const rows = useMemo(() => createRows(formState), [formState]);

  const handleContinue = async () => {
    // Get all selected commitments, filtering out the ones without an assigned TP/Version
    const selectedRows = formState.commitments.rows
      .filter((os) => os.dirty)
      .map(({ changedValue, fragment }) => ({
        commitmentId: fragment.value.id,
        bidContractRevisionId: changedValue.bcrId!,
      }))
      .filter((c) => selectedCommitments.includes(c.commitmentId));

    const { errors, data } = await assignWorkToBidContract({
      variables: {
        input: selectedRows.map(({ commitmentId, bidContractRevisionId }) => ({
          commitmentId,
          bidContractRevisionId,
          autoRelease: true,
        })),
      },
    });
    const changeEvents = data?.assignWorkToBidContract;
    // Banner is likely set from this error, so close the modal/wizard to reveal it
    if (errors?.nonEmpty) return closeModal();
    if (!changeEvents || changeEvents?.isEmpty) {
      triggerNotice({
        message: "Skipping Approvals because there are no changes to review.",
        icon: "success",
      });
      closeModal();
    } else {
      setChangeEventIds(changeEvents.map((ce) => ce.id));
    }
  };

  return (
    <div css={Css.mt3.$}>
      <div css={Css.pb8.oya.maxh("50VH").$}>
        <GridTable
          columns={columns}
          rows={rows}
          style={{ grouped: true }}
          stickyHeader
          rowStyles={rowStyles}
          api={tableApi}
          fallbackMessage="There are no available project items"
        />
      </div>
      <NextStepButton
        disabled={loading || selectedCommitments.isEmpty}
        onClick={handleContinue}
        label="Confirm Budgets"
      />
    </div>
  );
}
// Add border to split between previous / proposed values
const cellBorderStyles = Css.addIn("& > div:nth-of-type(7)", Css.bl.bcGray400.$).addIn(
  "& > div:nth-of-type(10)",
  Css.bl.bcGray400.$,
).$;

const rowStyles: RowStyles<Row> = {
  header: { rowCss: cellBorderStyles },
  commitment: { rowCss: cellBorderStyles },
};

export type CommitmentRowType = {
  bcrId: string | undefined | null;
  proposedCost: number | undefined | null;
  fragment: ReassignPurchaseOrdersCommitmentFragment;
};
type CommitmentRow = { kind: "commitment"; data: ObjectState<FormValue>["commitments"]["rows"][0] };
type Row = { kind: "header"; data: undefined; id: string } | CommitmentRow;

const createColumns = (reassignType: ReassignOption): GridColumn<Row>[] => {
  const tableColumns = [
    selectColumn<Row>(),
    column<Row>({
      header: "PO #",
      commitment: ({ fragment }) =>
        linkHeader(String(fragment.value.accountingNumber), fragment.value.blueprintUrl.path),
      w: 0.6,
    }),
    column<Row>({
      header: "Address",
      commitment: ({ fragment }) => fragment.value.project.buildAddress.street1,
    }),
    column<Row>({
      header: "Cost Codes",
      commitment: ({ fragment }) => chipCell(fragment.value.lineItems.map((li) => li.costCode.number)),
    }),
    dateColumn<Row>({
      header: "Released Date",
      commitment: ({ fragment }) => dateCell(fragment.value.executionDate),
    }),
    column<Row>({
      header: "Status",
      commitment: ({ fragment }) =>
        tagCell(commitmentStatusToTagTypeMapper[fragment.value.status], fragment.value.statusText),
      clientSideSort: false,
      w: 0.8,
    }),
    column<Row>({
      header: "Current Trade",
      commitment: ({ fragment }) => fragment.value.tradePartner?.name || fragment.value.tradePartner?.id,
    }),
    numericColumn<Row>({
      header: "Contract Version",
      commitment: ({ fragment }) => fragment.value.bidContractRevision?.version,
      align: "right",
    }),
    numericColumn<Row>({
      header: "Committed Cost",
      commitment: ({ fragment }) => priceCell({ valueInCents: fragment.value.committedInCents }),
    }),
  ];

  const selectTradePartnerColumns = [
    column<Row>({
      header: "Proposed Trade",
      commitment: (data) => {
        const potentialBcrs = data.fragment.value.potentialBidContractRevisions
          // Filter out BCLIs from internal revisions
          .filter((pr) => !!pr.bidContractRevision.bidContract.tradePartner)
          .filter((pr) => pr.bidContractRevision.bidContract.tradePartner?.id !== data.fragment.value.tradePartner?.id);

        // Filter potential BCRs to show one trade partner
        const oneTpPerBcrs = Object.values(
          groupBy(
            potentialBcrs,
            (bcr) => bcr.bidContractRevision.bidContract.tradePartner?.id || fail("Unexpected estimate BCLI"),
          ),
        ).map((tp) => {
          const alreadySelected = tp.find((bcr) => bcr.bidContractRevision.id === data.bcrId.value);
          return alreadySelected || tp.first!;
        });

        if (isEmpty(oneTpPerBcrs))
          return (
            <Tooltip
              title={"No other trades on this development are contracted for the cost codes in this PO."}
              placement="top"
            >
              <div css={Css.red600.$}>0 trades available</div>
            </Tooltip>
          );

        return (
          <BoundSelectField
            label="Proposed Trade"
            onSelect={(bcrId, selectedBcr) => {
              data.set({ bcrId, proposedCost: selectedBcr?.totalCostInCents });
            }}
            placeholder="Select a Trade Partner"
            options={oneTpPerBcrs}
            getOptionLabel={(bcr) => bcr.bidContractRevision.bidContract.tradePartner?.name || ""}
            getOptionValue={(bcr) => bcr.bidContractRevision.id}
            field={data.bcrId}
            data-testid="proposedTradePartner"
          />
        );
      },
      w: 1.4,
      clientSideSort: false,
    }),
    column<Row>({
      header: "Contract Version",
      commitment: (data) => {
        // Filter revisions of the current selected trade partner
        const bcrOptions = data.fragment.value.potentialBidContractRevisions;
        const selectedTradePartnerId = bcrOptions.find((pr) => pr.bidContractRevision?.id === data.bcrId.value)
          ?.bidContractRevision.bidContract.tradePartner?.id;
        const options = bcrOptions.filter(
          (o) => o.bidContractRevision.bidContract.tradePartner?.id === selectedTradePartnerId,
        );

        return data.bcrId.value && options.length > 0 ? (
          <BoundSelectField
            label="Contract Version"
            onSelect={(bcrId, selectedBcr) => {
              data.set({ bcrId, proposedCost: selectedBcr?.totalCostInCents });
            }}
            options={options}
            getOptionLabel={(r) => r.bidContractRevision.version}
            getOptionValue={(r) => r.bidContractRevision.id}
            readOnly={options.length <= 1}
            field={data.bcrId}
            data-testid="contractVersion"
          />
        ) : undefined;
      },
      clientSideSort: false,
      align: "right",
    }),
  ];

  const selectVersionColumns = [
    column<Row>({
      header: "Proposed Version",
      commitment: (data) => {
        // Filter revisions of the current selected trade partner and different to the one already assigned
        const bcrOptions = data.fragment.value.potentialBidContractRevisions;
        const options = bcrOptions.filter(
          (o) =>
            o.bidContractRevision.bidContract.tradePartner?.id === data.fragment.value.tradePartner?.id &&
            o.bidContractRevision.id !== data.fragment.value.bidContractRevision?.id,
        );

        if (isEmpty(options)) {
          return (
            <Tooltip
              title={"No other trades on this development are contracted for the cost codes in this PO."}
              placement="top"
            >
              <div css={Css.red600.$}>0 trades available</div>
            </Tooltip>
          );
        }

        return (
          <BoundSelectField
            label="Contract Version"
            onSelect={(bcrId, selectedBcr) => {
              data.set({ bcrId, proposedCost: selectedBcr?.totalCostInCents });
            }}
            options={options}
            getOptionLabel={(r) => r.bidContractRevision.version}
            getOptionValue={(r) => r.bidContractRevision.id}
            field={data.bcrId}
            data-testid="contractVersion"
          />
        );
      },
      clientSideSort: false,
      w: 1.2,
    }),
  ];

  const proposedCostColumn = [
    numericColumn<Row>({
      header: "Proposed Cost",
      commitment: (data) =>
        data.bcrId.value ? <BoundNumberField field={data.proposedCost} type="cents" readOnly /> : undefined,
      clientSideSort: false,
      align: "right",
    }),
  ];

  return [
    ...tableColumns,
    ...(reassignType === "trade partner" ? selectTradePartnerColumns : selectVersionColumns),
    ...proposedCostColumn,
  ];
};

const createRows = (formState: ObjectState<FormValue>): GridDataRow<Row>[] => {
  return [
    simpleHeader,
    ...formState.commitments.rows.map((c) => ({
      kind: "commitment" as const,
      id: c.value.fragment.id,
      data: c,
      initSelected: true,
    })),
  ];
};

type FormValue = {
  commitments: CommitmentRowType[];
};

const formConfig: ObjectConfig<FormValue> = {
  commitments: {
    type: "list",
    config: {
      bcrId: { type: "value" },
      proposedCost: { type: "value" },
      fragment: { type: "value" },
    },
  },
};

function mapToForm(commitments: CommitmentRowType[]) {
  return {
    commitments: commitments.map((c) => ({
      id: c.fragment.id,
      bcrId: undefined,
      proposedCost: undefined,
      fragment: c.fragment,
    })),
  };
}
