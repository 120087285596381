import { IconKey } from "src/components/Icon";

const fileExtensionToIconMap: Record<string, IconKey> = {
  pdf: "pdf",
  jpg: "image",
  jpeg: "image",
  png: "image",
  gif: "image",
};

export function bytesToSize(bytes: number) {
  const sizes = ["bytes", "kb", "mb", "gb", "tb"];
  const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function getIconKeyByFileName(name: string): IconKey {
  const fileExtension = name.split(".").pop()!.toLowerCase();
  return fileExtensionToIconMap[fileExtension] || "file";
}
