import {
  Button,
  Checkbox,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OffsetAndLimit,
  Pagination,
  Palette,
  RowStyles,
  selectColumn,
  TextField,
  useComputed,
  useGridTableApi,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { Dispatch, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  DevelopmentBidItemsModal_BidItemFragment,
  useDevelopmentBidItemsModalQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export type DevelopmentBidItemsModalOnSave = (
  items: DevelopmentBidItemsModal_BidItemFragment[],
  all: boolean,
  fileName?: string,
) => void;

export type DevelopmentBidItemsModalProps = {
  title: string;
  confirmationButtonText: string;
  onSave: DevelopmentBidItemsModalOnSave;
  subHeader: React.ReactNode;
  disabledOptions?: string[];
};

export function DevelopmentBidItemsModal(props: DevelopmentBidItemsModalProps) {
  const [search, setSearch] = useState("");
  const [pageSettings, setPageSettings] = useState<OffsetAndLimit>({ limit: 100, offset: 0 });
  const query = useDevelopmentBidItemsModalQuery({
    variables: {
      filter: { search },
      first: pageSettings.limit,
      offset: pageSettings.offset,
    },
  });

  return queryResult(query, ({ bidItemsPage }) => (
    <DevelopmentBidItemsModalDataView
      bidItems={bidItemsPage.bidItems}
      page={[{ ...pageSettings, totalCount: bidItemsPage.pageInfo.totalCount }, setPageSettings]}
      setSearch={setSearch}
      {...props}
    />
  ));
}

type DevelopmentBidItemsModalDataViewProps = DevelopmentBidItemsModalProps & {
  bidItems: DevelopmentBidItemsModal_BidItemFragment[];
  page: readonly [OffsetAndLimit & { totalCount: number }, Dispatch<OffsetAndLimit>];
  setSearch: Dispatch<string>;
};

function DevelopmentBidItemsModalDataView(props: DevelopmentBidItemsModalDataViewProps) {
  const { confirmationButtonText, bidItems, onSave, setSearch, disabledOptions = [], title, subHeader, page } = props;
  const { closeModal } = useModal();
  const tid = useTestIds({}, "DevelopmentBidItemsModal");

  const tableApi = useGridTableApi<Row>();
  const [customFileName, setCustomFileName] = useState<string>();
  const selectedRows = useComputed(
    () => tableApi.getSelectedRows("bidItem").filter((row) => !disabledOptions.includes(row.id)),
    [tableApi, disabledOptions],
  );

  const allSelected = selectedRows.nonEmpty && selectedRows.length === bidItems.length - disabledOptions.length;

  const columns = createColumns();
  const rows = useMemo(() => createRows(bidItems, disabledOptions), [bidItems, disabledOptions]);
  const rowStyles = useMemo(() => createRowStyles(selectedRows.map((r) => r.id)), [selectedRows]);

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <>
          {subHeader}
          <Checkbox
            label="Select all line items"
            selected={selectedRows.length > 0 && allSelected}
            disabled={selectedRows.length !== 0 && !allSelected} // Disable if some items selected, enable if 0 or All items selected
            onChange={(isSelected) =>
              isSelected ? bidItems.forEach(({ id }) => tableApi.selectRow(id)) : tableApi.clearSelections()
            }
          />
          <div css={Css.mt2.w50.$}>
            <TextField
              data-testid="customFileName"
              label="File Name"
              onChange={setCustomFileName}
              value={customFileName}
              compact
            />
          </div>
          <div css={Css.py2.$}>
            <SearchBox
              onSearch={(term) => setSearch(term.toLowerCase())}
              placeholder="Search line items"
              fullWidth
              clearable
            />
          </div>
          <div>
            <GridTable
              api={tableApi}
              rows={rows}
              columns={columns}
              rowStyles={rowStyles}
              style={{ rowHeight: "fixed" }}
              fallbackMessage="No matches"
              sorting={{ on: "client", initial: ["code", "ASC"] }}
            />
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.w100.fdc.$}>
          <Pagination page={page} totalCount={page[0].totalCount} />
          <div css={Css.df.mt2.jcfe.$}>
            <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
            <Button
              {...tid.confirm}
              label={confirmationButtonText}
              disabled={selectedRows.isEmpty}
              onClick={async () => {
                await onSave(
                  selectedRows.map((r) => r.data),
                  allSelected,
                  customFileName,
                );
                closeModal();
              }}
            />
          </div>
        </div>
      </ModalFooter>
    </>
  );
}

function createRowStyles(selectedRowIds: string[]): RowStyles<Row> {
  const isSelected = (rowId: string) => selectedRowIds.includes(rowId);

  return {
    bidItem: {
      rowCss: (row) => {
        const rowSelected = isSelected(row.id);
        return Css.mb1.ba.br4.bw(rowSelected ? "2px" : "1px").bc(rowSelected ? Palette.Blue700 : "lightgray").$;
      },
      onClick: (row, api) => api.selectRow(row.id, !isSelected(row.id)),
    },
  };
}

type Row = { kind: "bidItem"; id: string; data: DevelopmentBidItemsModal_BidItemFragment };

function createColumns(): GridColumn<Row>[] {
  return [
    selectColumn({ w: 0.1 }),
    column<Row>({
      id: "code",
      bidItem: ({ code }) => ({
        content: code,
        css: Css.smMd.gray900.$,
      }),
    }),
    column<Row>({
      id: "name",
      bidItem: ({ name }) => ({
        content: name,
        css: Css.gray700.$,
      }),
      align: "right",
      w: 1.5,
    }),
  ];
}

function createRows(
  bidItems: DevelopmentBidItemsModal_BidItemFragment[],
  disabledOptions: string[],
): GridDataRow<Row>[] {
  return bidItems.map((bi) => {
    const disabled = disabledOptions.includes(bi.id);
    return {
      id: bi.id,
      kind: "bidItem",
      selectable: disabled ? false : undefined,
      initSelected: disabled,
      data: bi,
    };
  });
}
