import { BoundSelectField, BoundSelectFieldProps } from "@homebound/beam";
import { useMemo } from "react";
import { ChangeEventReasonsDataFragment, useChangeEventReasonsQuery } from "src/generated/graphql-types";
import { filterArchivedUnlessSelected } from "src/utils/changeEventReasons";

type ChangeEventReasonsFieldProps = Omit<
  BoundSelectFieldProps<ChangeEventReasonsDataFragment, string>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  getOptionLabel?: (o: ChangeEventReasonsDataFragment) => string;
  getOptionValue?: (o: ChangeEventReasonsDataFragment) => string;
};

export function ChangeEventReasonsBoundSelectField(props: ChangeEventReasonsFieldProps) {
  const {
    placeholder = "Select a change event reason",
    getOptionLabel = (o) => o.name,
    getOptionValue = (o) => o.id,
    ...otherProps
  } = props;
  const { data, loading } = useChangeEventReasonsQuery({ fetchPolicy: "cache-first" });
  const options = useMemo(
    () => filterArchivedUnlessSelected(data?.changeEventReasons, otherProps.field.value),
    [data, otherProps.field.value],
  );

  return (
    <BoundSelectField
      {...otherProps}
      placeholder={loading ? "Loading..." : placeholder}
      options={options}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
}
