import {
  GanttScheduleTaskFragment,
  Maybe,
  ScheduleSettingDetailsFragment,
  TaskCalendarDetailsFragment,
  TaskDependencyType,
} from "src/generated/graphql-types";
import { assertNever } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { getBusinessDayHelpersForSchedule } from "./table/utils";

/**
 * Given a proposed new `startDate` for a successor task, calculates new `lagInDays` mutation inputs.
 *
 * * In Gantt view, this allows a user to simply drag a task to the true-to-life start date and the visually stretched dependency line durations are automatically calculated.
 * * In List view, this allows for a user to select a Start Date, then confirm the change via a modal
 */
export function calcDependencyLag({
  newStartDate,
  originalTaskData,
  scheduleSetting,
}: {
  newStartDate: DateOnly;
  scheduleSetting?: Maybe<ScheduleSettingDetailsFragment>;
  originalTaskData:
    | Pick<GanttScheduleTaskFragment, "interval" | "predecessorDependencies">
    | Pick<TaskCalendarDetailsFragment, "interval" | "predecessorDependencies">;
}) {
  const {
    predecessorDependencies,
    interval: { durationInDays },
  } = originalTaskData;

  const { addBusinessDays, differenceInBusinessDays } = getBusinessDayHelpersForSchedule(scheduleSetting ?? undefined);

  // Derive the new `endDate` based on the `newStartDate` + `duration` to ensure working days are accounted for
  // For example, a 1 day task dragged to *start* on a Friday should have its *end* pushed to Monday for the purposes of calculating dependency lag
  const newEndDate = addBusinessDays(newStartDate, durationInDays);

  const changes = predecessorDependencies.map((dep) => {
    const {
      type,
      lagInDays: originalLagInDays,
      predecessor: {
        name: predecessorName,
        interval: { endDate: predecessorEndDate, startDate: predecessorStartDate },
        id: predecessorId,
      },
    } = dep;

    const newLag = () => {
      switch (type) {
        case TaskDependencyType.StartFinish:
          // How many days from the *start* of the predecessor task is the new *end* date offset from
          return differenceInBusinessDays(newEndDate, predecessorStartDate);

        case TaskDependencyType.FinishFinish:
          // How many days from the *finish* of the predecessor task is the new *end* date offset from
          return differenceInBusinessDays(newEndDate, predecessorEndDate);

        case TaskDependencyType.StartStart:
          // How many days from the *start* of the predecessor task is the new intended *start* date
          return differenceInBusinessDays(newStartDate, predecessorStartDate);

        case TaskDependencyType.FinishStart:
          // How many days from the *finish* of the predecessor task is the new intended *start* date
          return differenceInBusinessDays(newStartDate, predecessorEndDate);

        default:
          return assertNever(type);
      }
    };

    return { predecessorId, lagInDays: newLag(), originalLagInDays, predecessorName, type };
  });

  // Prune down the keys returned in this list so the caller can simply pass the `mutationInput` to `saveScheduleTask.predecessorDependencies`
  const mutationInput = changes.map(({ predecessorId, lagInDays }) => ({ predecessorId, lagInDays }));

  return { changes, mutationInput };
}
