import { Css, useTestIds } from "@homebound/beam";
import { emptyCellDash } from "src/components/gridTableCells";
import { Maybe } from "src/generated/graphql-types";
import { formatNumberToString } from "src/utils";

type PercentageProps = {
  id?: string;
  percent: Maybe<number>;
  dropZero?: boolean;
  basisPoints?: boolean;
};

export function Percentage(props: PercentageProps) {
  const { id = "percentage", percent, dropZero = false, basisPoints = false } = props;
  const testId = useTestIds({}, id);
  const isNegative = !!percent && percent < 0;
  const formattedPercentage =
    typeof percent !== "number" || (dropZero && percent === 0)
      ? emptyCellDash
      : `${formatNumberToString(basisPoints ? (percent ?? 0) / 100 : percent, true)}%`;
  return (
    <span {...testId} css={Css.if(isNegative).red600.$}>
      {formattedPercentage}
    </span>
  );
}
