import {
  BoundNumberField,
  BoundTextField,
  Button,
  Css,
  GridDataRow,
  GridTable,
  ScrollableContent,
  column,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  ConstraintAndAllowanceCatalogPage_GlobalPlanConstraintItemFragment,
  SaveGlobalPlanConstraintItemInput,
  useConstraintAndAllowanceCatalogPageQuery,
  useSaveGlobalPlanConstraintItemMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { catalogTasksPath } from "src/routes/routesDef";
import { AddConstraintItemModal, ConstraintModalConfig } from "./components/AddConstraintItemModal";

export function ConstraintItemCatalogPage() {
  const { data } = useConstraintAndAllowanceCatalogPageQuery();
  const { openModal } = useModal();
  const [search, setSearch] = useState<string | undefined>();
  const [saveGlobalPlanConstraintItem] = useSaveGlobalPlanConstraintItemMutation();

  const { getFormState } = useFormStates<
    SaveGlobalPlanConstraintItemInput,
    ConstraintAndAllowanceCatalogPage_GlobalPlanConstraintItemFragment
  >({
    config: formConfig,
    getId: (v) => v.id,
    autoSave: async (os) => {
      await saveGlobalPlanConstraintItem({ variables: { input: os.changedValue } });
      os.commitChanges();
    },
  });

  const columns = useMemo(() => createColumns(getFormState), [getFormState]);
  const rows = useMemo(() => createRows(data?.globalPlanConstraintItems ?? []), [data]);

  return (
    <div>
      <PageHeader
        breadcrumb={[{ href: catalogTasksPath, label: "Task Catalog" }]}
        title="Constraint/ Allowance Catalog"
        right={
          <Button
            label="Create"
            onClick={() =>
              openModal({
                content: <AddConstraintItemModal constraintType={ConstraintModalConfig.ConstraintItem} />,
              })
            }
          />
        }
      />
      <div>
        <TableActions>
          <p>
            Constraints and allowances share the same items, but can be used as buffers either before or after a task.
          </p>
          <div css={Css.df.jcsb.$}>
            <SearchBox onSearch={setSearch} />
            {/* ToDo: Implement in another ticket */}
            {/* <ButtonMenu trigger={{ icon: "verticalDots" }} items={[{ label: "Delete", onClick: noop }]} /> */}
          </div>
        </TableActions>
      </div>
      <ScrollableContent virtualized>
        <GridTable
          columns={columns}
          rows={rows}
          as="virtual"
          filter={search}
          rowStyles={{ data: { cellCss: Css.px3.$ } }}
        />
      </ScrollableContent>
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ConstraintAndAllowanceCatalogPage_GlobalPlanConstraintItemFragment };

export type Row = HeaderRow | DataRow;

function createColumns(
  getFormState: (
    input: ConstraintAndAllowanceCatalogPage_GlobalPlanConstraintItemFragment,
  ) => ObjectState<SaveGlobalPlanConstraintItemInput>,
) {
  return [
    column<Row>({
      header: "Constraint/Allowance Name",
      data: (row) => ({
        content: () => {
          const os = getFormState(row);
          return <BoundTextField label="ConstraintOrAllowanceName" field={os.name} />;
        },
        value: () => row.name,
      }),
      w: "600px",
    }),
    column<Row>({
      header: "Lag / Lead",
      data: (row) => {
        const os = getFormState(row);
        return <BoundNumberField label="lagInDays" type="days" field={os.lagInDays} />;
      },
      w: "150px",
    }),
    column<Row>({
      header: "Times Used",
      data: ({ numberOfTimesUsed }) => <span css={Css.sm.$}>{numberOfTimesUsed}</span>,
    }),
  ];
}

function createRows(data: ConstraintAndAllowanceCatalogPage_GlobalPlanConstraintItemFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...data?.map((gpci) => ({
      kind: "data" as const,
      id: gpci.id,
      data: gpci,
    })),
  ];
}

const formConfig: ObjectConfig<SaveGlobalPlanConstraintItemInput> = {
  id: { type: "value" },
  name: { type: "value" },
  lagInDays: { type: "value" },
};
