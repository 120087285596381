import {
  Button,
  Checkbox,
  Css,
  DateRangeField,
  FieldGroup,
  FormLines,
  GroupByHook,
  MultiSelectField,
  SelectField,
} from "@homebound/beam";
import { useState } from "react";
import { TaskAssigneeFragment, TaskStatusesFragment, TaskTradePartnerFragment } from "src/generated/graphql-types";
import { TaskAssigneeMultiSelectField } from "./components/TaskAssigneeMultiSelectField";
import { CustomTaskFilter } from "./table/filterUtils";
import { ScheduleListGroupBy } from "./table/utils";

type ScheduleFilterModalProps = {
  filterOptions?: {
    assignees: TaskAssigneeFragment[];
    tradePartners: TaskTradePartnerFragment[];
    taskStatuses: TaskStatusesFragment[];
  };
  filter: CustomTaskFilter;
  setFilter: (filter: CustomTaskFilter) => void;
  groupBy: GroupByHook<ScheduleListGroupBy>;
  close: () => void;
};

export function ScheduleFilterModal({
  filterOptions = { assignees: [], tradePartners: [], taskStatuses: [] },
  filter,
  setFilter,
  groupBy,
  close,
}: ScheduleFilterModalProps) {
  // using localState as we have custom filter option with dateRangeField that is not compatible with formState
  const [modalFilter, setModalFilter] = useState<CustomTaskFilter & { groupBy?: ScheduleListGroupBy }>({
    ...filter,
    ...(filter.startDateRange?.from && filter.startDateRange.to
      ? {
          startDateRange: {
            to: new Date(filter.startDateRange.to),
            from: new Date(filter.startDateRange.from),
          },
        }
      : {}),
    ...(filter.endDateRange?.from && filter.endDateRange.to
      ? {
          endDateRange: {
            to: new Date(filter.endDateRange.to),
            from: new Date(filter.endDateRange.from),
          },
        }
      : {}),
    groupBy: filter.groupBy ?? groupBy.value,
  });

  // sends filter information to our projectTableTasksQuery
  const onSubmit = () => {
    const filter = modalFilter;
    setFilter(filter);
    close();
  };

  const { taskStatuses, tradePartners, assignees } = filterOptions;

  return (
    <>
      <FormLines>
        <FieldGroup>
          <MultiSelectField
            onSelect={(val) =>
              setModalFilter((prevState) => ({ ...prevState, tradePartner: val.isEmpty ? undefined : val }))
            }
            label="Trade Partner"
            options={tradePartners}
            values={modalFilter.tradePartner ?? []}
            disabled={
              filterOptions.tradePartners.length === 0 &&
              "There are no trade partners assigned to tasks on this project"
            }
          />
          <TaskAssigneeMultiSelectField
            options={assignees}
            values={modalFilter.internalUser ?? []}
            onSelect={(val) =>
              setModalFilter((prevState) => ({
                ...prevState,
                internalUser: val?.isEmpty ? undefined : val,
              }))
            }
          />
        </FieldGroup>
        <FieldGroup>
          <DateRangeField
            value={modalFilter.startDateRange}
            onChange={(val) => {
              val &&
                setModalFilter((prevState) => ({
                  ...prevState,
                  startDateRange: { to: val.to, from: val.from },
                }));
            }}
            label="Start date range"
            hideCalendarIcon={true}
            placeholder="Select Date Range"
          />
          <DateRangeField
            value={modalFilter.endDateRange}
            onChange={(range) =>
              range &&
              setModalFilter((prevState) => ({
                ...prevState,
                endDateRange: { to: range.to, from: range.from },
              }))
            }
            label="Finish date range"
            hideCalendarIcon={true}
            placeholder="Select Date Range"
          />
        </FieldGroup>
        <FieldGroup>
          <SelectField
            onSelect={(val) => setModalFilter((prevState) => ({ ...prevState, groupBy: val }))}
            label="Group by"
            options={groupBy.options}
            value={modalFilter.groupBy}
          />
          <MultiSelectField
            onSelect={(val) => setModalFilter((prevState) => ({ ...prevState, status: val.isEmpty ? undefined : val }))}
            label="Status"
            data-testid="statusScheduleFilterModal"
            options={taskStatuses}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ code }) => code}
            values={modalFilter.status ?? []}
          />
        </FieldGroup>
        <h3 css={Css.sm.gray700.$}>Other Filters</h3>
        <FieldGroup>
          <Checkbox
            label="Milestones"
            selected={!!modalFilter.isGlobalMilestoneTask}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, isGlobalMilestoneTask: val }));
            }}
          />
          <Checkbox
            label="Critical Paths"
            selected={!!modalFilter.isCriticalPath}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, isCriticalPath: val }));
            }}
          />
        </FieldGroup>
        <FieldGroup>
          <Checkbox
            label="Stale Tasks"
            selected={!!modalFilter.stale}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, stale: val }));
            }}
          />
          <Checkbox
            label="My Tasks"
            selected={!!modalFilter.myTasks}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, myTasks: val }));
            }}
          />
        </FieldGroup>
        <FieldGroup>
          <Checkbox
            selected={!!modalFilter.homeownerVisible}
            label="Homeowner Visible"
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, homeownerVisible: val }));
            }}
          />
          <Checkbox
            selected={!!modalFilter.requiredDocuments}
            label="Required Documents"
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, requiredDocuments: val }));
            }}
          />
        </FieldGroup>
        <FieldGroup>
          <Checkbox
            selected={!!modalFilter.hasChecklist}
            label="Checklists"
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, hasChecklist: val }));
            }}
          />
          <Checkbox
            label="Schedule Flags"
            selected={!!modalFilter.hasScheduleFlags}
            onChange={(val) => {
              setModalFilter((prevState) => ({ ...prevState, hasScheduleFlags: val }));
            }}
          />
        </FieldGroup>
      </FormLines>
      <div css={Css.df.gap1.jcfe.$}>
        <Button variant="tertiary" label="Cancel" onClick={close} />
        <Button label="Apply" onClick={onSubmit} />
      </div>
    </>
  );
}
