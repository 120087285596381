import { Avatar, Button, Css } from "@homebound/beam";
import { format } from "date-fns";
import { formatDate } from "src/components";
import { HomeownerNotesForProjectFragment } from "src/generated/graphql-types";
import { JobLogImages } from "src/routes/projects/job-logs/JobLogImages";
import { nonEmpty, sanitizeHtml } from "src/utils";

type HomeownerNoteProps = {
  homeownerNote: HomeownerNotesForProjectFragment;
  onEdit: (id: string) => void;
};

export function HomeownerNote(props: HomeownerNoteProps) {
  const { homeownerNote, onEdit } = props;
  const { id, description, createdAt, createdBy, title, jobLogImages } = homeownerNote;
  const createdAtAsDate = new Date(createdAt);
  const formattedDate = format(createdAtAsDate, "MMM d");
  const formattedTime = formatDate(createdAtAsDate, "none", "short");
  const detailsPhrase = `${createdBy.name}, ${formattedDate} at ${formattedTime}`;

  return (
    <div
      data-testid="homeownerNote"
      css={
        Css.bgWhite.bshBasic
          .borderRadius("8px")
          .bw1.bss.bcGray300.w100.p3.cursorPointer.add("transition", "visibility 100ms").m3.$
      }
      key={id}
    >
      <div css={Css.df.fdc.$}>
        <div css={Css.gray900.mb2.pl4.base.$}>
          {" "}
          <b>{title}</b>
        </div>
        <div css={Css.gray900.xs.pl4.mb1.$} dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}></div>
        <div css={Css.dn.if(nonEmpty(jobLogImages)).mb1.db.pl4.$}>
          <JobLogImages compact parentId={id} description={description} images={jobLogImages} display={"horizontal"} />
        </div>
        <div css={Css.df.fdr.jcsb.mt1.$}>
          <div css={Css.df.$}>
            <Avatar src={createdBy.avatarUrl} name={createdBy.name} size="sm" />
            <div css={Css.df.fdc.jcc.mr1.$}>
              <span css={Css.gray700.tiny.pl1.mtPx(4).mbPx(4).$}>{detailsPhrase}</span>
            </div>
          </div>
          <Button label="Edit" variant="tertiary" icon="pencil" onClick={() => onEdit(homeownerNote.id)} />
        </div>
      </div>
    </div>
  );
}
