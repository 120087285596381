import { ChipSelectField, Css, ToggleChip, useSnackbar } from "@homebound/beam";
import { useCallback } from "react";
import {
  ProjectAdminFragment,
  ProjectFeature,
  ProjectFeatureDetail,
  useToggleProjectFeatureMutation,
} from "src/generated/graphql-types";

type ProjectFeaturesCellProps = {
  project: ProjectAdminFragment;
  features: ProjectFeatureDetail[];
};

export function ProjectFeaturesCell({ project, features }: ProjectFeaturesCellProps) {
  const [toggleProjectFeatureMutation] = useToggleProjectFeatureMutation();
  const { triggerNotice } = useSnackbar();

  const [assignedFeatures, unassignedFeatures]: [ProjectFeatureDetail[], ProjectFeatureDetail[]] = features.reduce(
    (acc, feature) => {
      acc[project.features.some((f) => f === feature.code) ? 0 : 1].push(feature);
      return acc;
    },
    [[] as ProjectFeatureDetail[], [] as ProjectFeatureDetail[]],
  );

  const featureCodeToName: Record<ProjectFeature, string> = features.reduce(
    (acc: Record<ProjectFeature, string>, featureDetail: ProjectFeatureDetail) => {
      acc[featureDetail.code] = featureDetail.name;
      return acc;
    },
    {} as Record<ProjectFeature, string>,
  );

  const onSave = useCallback(
    async (selectedFeature: ProjectFeature, enable: boolean) => {
      await toggleProjectFeatureMutation({
        variables: {
          input: {
            projectId: project.id,
            feature: selectedFeature,
            enable,
          },
        },
      });
      triggerNotice({
        icon: "success",
        message: `${project.buildAddress.street1} (${project.id}) ${enable ? "enabled" : "disabled"} feature ${
          featureCodeToName[selectedFeature]
        }`,
      });
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id, toggleProjectFeatureMutation],
  );

  return (
    <div css={Css.df.gap1.add("flexWrap", "wrap").$}>
      {assignedFeatures.map((f) => (
        <ToggleChip key={f.code} text={f.name} onClick={() => onSave(f.code, false)} />
      ))}
      <ChipSelectField
        label="Add Feature"
        value={undefined as any}
        placeholder="+ Add"
        getOptionValue={(o) => o.code}
        getOptionLabel={(o) => o.name ?? ""}
        options={unassignedFeatures}
        disabled={unassignedFeatures.length === 0 ? "There are no additional features to select" : undefined}
        onSelect={(f) => onSave(f, true)}
      />
    </div>
  );
}
