import { Css, ScrollableParent, useBreakpoint } from "@homebound/beam";
import { PropsWithChildren } from "react";
import { SideNav, SideNavProps, sideNavCollapsedWidth } from "src/components/layout/SideNav";
import { SideNavContextProvider, useSideNavContext } from "src/components/layout/SideNavContext";
import { topNavHeight } from "src/routes/layout/GlobalNav";

type SideNavLayoutProps = {
  sideNavProps: SideNavProps;
  layoutProps?: { contentCss: Parameters<typeof ScrollableParent>["0"]["xss"] };
};

export function SideNavLayout(props: PropsWithChildren<SideNavLayoutProps>) {
  return (
    <SideNavContextProvider>
      <SideNavLayoutContent {...props} />
    </SideNavContextProvider>
  );
}

function SideNavLayoutContent(props: PropsWithChildren<SideNavLayoutProps>) {
  const { children, sideNavProps, layoutProps } = props;
  const { sideNavState, setSideNavState } = useSideNavContext();
  const bp = useBreakpoint();

  return (
    <div css={Css.df.oh.fg1.$}>
      <SideNav {...sideNavProps} />
      {/* When the side-nav is expanded, and we are at the medium breakpoint and down,
       then provide an overlay when clicked/touched will close the side nav */}
      {sideNavState === "expanded" && bp.mdAndDown && (
        <div
          css={
            Css.absolute.right0.bottom0.left0
              .topPx(topNavHeight)
              // custom z-index of 900 to account for intended stacking of sidebar & "snackbar" toast messages
              .add("backgroundColor", "rgba(36,36,36,0.2)")
              .add("zIndex", 900).$
          }
          onClick={() => setSideNavState("collapse")}
          aria-label="Close navigation"
        />
      )}
      <ScrollableParent
        xss={{
          ...Css.fg1.px3.$,
          // The side nav uses a `position: absolute` when `bp.mdAndDown` is true. Add extra margin to account for this.
          ...(bp.mdAndDown ? Css.mlPx(sideNavCollapsedWidth).$ : {}),
          ...(layoutProps?.contentCss || {}),
        }}
      >
        {children}
      </ScrollableParent>
    </div>
  );
}
