import {
  column,
  condensedStyle,
  GridColumn,
  GridTable,
  numericColumn,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import { ProjectItemCommitmentLineItemFragment, useProjectItemCommitmentTabQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type SelectionsCommitmentsTableProps = {
  projectItemId: string;
};

export function ProjectItemCommitmentsTable({ projectItemId }: SelectionsCommitmentsTableProps) {
  const query = useProjectItemCommitmentTabQuery({ variables: { projectItemId } });

  return queryResult(query, ({ projectItem }) => {
    const columns: GridColumn<Row>[] = [
      column<Row>({
        header: "Commitment",
        data: ({ owner }) => {
          const path = owner.blueprintUrl.path;
          return <Link to={path}>{owner.displayName}</Link>;
        },
      }),
      column<Row>({
        header: "PO #",
        data: ({ owner }) => owner.accountingNumber,
      }),
      numericColumn<Row>({ header: "Quantity", data: (row) => row.quantity }),
      numericColumn<Row>({ header: "Committed Cost", data: (row) => <Price valueInCents={row.committedInCents} /> }),
    ];

    const rows = simpleDataRows(projectItem.commitmentLineItems);

    return <GridTable columns={columns} rows={rows} style={condensedStyle} />;
  });
}

type Row = SimpleHeaderAndData<ProjectItemCommitmentLineItemFragment>;
