import { ButtonMenu, Css, useTestIds } from "@homebound/beam";
import { DashboardTile, DashboardTileSize } from "src/generated/graphql-types";
import { TileComponentConfig } from "src/routes/projects/dashboard/config";

type AddNewTileProps = {
  onAdd: ([tile, size]: [DashboardTile, DashboardTileSize]) => void;
  availableTiles: [DashboardTile, TileComponentConfig][];
};

export function AddNewTile({ availableTiles, onAdd }: AddNewTileProps) {
  const tid = useTestIds({});
  const cardStyles = Css.bgGray200.bshBasic.p2.br8.df.aic.jcc.$;

  return (
    <div css={cardStyles} {...tid.addTile}>
      <ButtonMenu
        items={availableTiles.map(([tile, config]) => ({
          label: config.title,
          onClick: () => onAdd([tile, config.defaultSize]),
        }))}
        trigger={{ label: "Add a Widget" }}
        disabled={availableTiles.length === 0 ? "No widgets available" : undefined}
      />
    </div>
  );
}
