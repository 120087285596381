import {
  BoundSelectField,
  BoundTextField,
  Css,
  ScrollableContent,
  StaticField,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { createChangeEventUrl } from "src/RouteUrls";
import { CommentFeed, Divider } from "src/components";
import { MarkupHeader, PriceHeader } from "src/components/tableHeaders";
import {
  EstimatePageQuery,
  EstimateStatus,
  SaveEstimateInput,
  useSaveEstimateMutation,
} from "src/generated/graphql-types";
import { EstimateAcceptedModal } from "src/routes/projects/estimates/EstimateAcceptedModal";
import { ObjectConfig, useFormState } from "src/utils/formState";
import { safeEntries } from "src/utils/utils";
import { createContractDisabledReason } from "./utils";

export type EstimateOverviewProps = {
  createChangeorder: () => void;
  estimate: EstimatePageQuery["projectEstimate"];
};

export function EstimateOverviewTab(props: EstimateOverviewProps) {
  const { createChangeorder, estimate } = props;
  const testIds = useTestIds({}, "estimate");
  const { openModal } = useModal();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: estimate,
      map: (estimate) => ({
        id: estimate.id,
        title: estimate.title,
        status: estimate.status.code,
      }),
    },
    autoSave: async (dc) => {
      const { status } = dc.changedValue;
      const input = formState.value;
      await saveEstimate({ variables: { input } });
      // If approving the estimate trigger estimate accepted modal
      if (status && status === EstimateStatus.Accepted) {
        openModal({
          content: (
            <EstimateAcceptedModal
              disabledReason={createContractDisabledReason(estimate)}
              isChangeOrderEstimate={isChangeOrderEstimate}
              onClickCreate={createChangeorder}
            />
          ),
        });
      }
    },
  });

  const [saveEstimate] = useSaveEstimateMutation();

  const { projectStage, totalPriceInCents, totalCostInCents } = estimate;

  const isChangeOrderEstimate = estimate.changeEvent?.id !== undefined;

  const statusReadOnlyReason = estimate.canEditStatus.disabledReasons.map((dr) => dr.message).join(" ");

  return (
    <>
      <ScrollableContent>
        <h2 css={Css.baseMd.mb(3).$}>Details</h2>
        <div css={Css.df.fdc.gap2.mb(4).$}>
          <div css={Css.df.gap(15).$}>
            {/* Because this is part of the form is always read-only, we can use PriceHeader to get the look we want.*/}
            <PriceHeader label="Cost" price={totalCostInCents} />
            <MarkupHeader label="Markup" budget={totalCostInCents} price={totalPriceInCents} />
            <PriceHeader label="Price" price={totalPriceInCents} />
          </div>
          <BoundTextField label="Name" field={formState.title} />
          <BoundSelectField
            field={formState.status}
            readOnly={!estimate.canEditStatus.allowed ? statusReadOnlyReason : undefined}
            options={safeEntries(EstimateStatus)}
            getOptionLabel={([name]) => name}
            getOptionValue={([_, code]) => code}
          />
          <StaticField value={estimate.type.name} label="Type" />
        </div>

        <h2 css={Css.baseMd.mb(2).$}>Contract Information</h2>
        <div css={Css.df.fdc.gap2.mb(4).$}>
          <StaticField label="Contract Type" value={isChangeOrderEstimate ? "H/O Change Order" : "Prime Contract"} />
          <StaticField label="Related Contract" value={estimate.projectStage.stage.name} />
          {estimate.changeEvent && (
            <div>
              <StaticField label="Associated Change Event">
                <Link to={createChangeEventUrl(projectStage.project.id, estimate.changeEvent?.id)}>
                  {estimate.changeEvent?.name}
                </Link>
              </StaticField>
            </div>
          )}
        </div>
        <Divider />
        <CommentFeed commentable={estimate} />
      </ScrollableContent>
    </>
  );
}

type FormInput = Pick<SaveEstimateInput, "id" | "status" | "title">;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  status: { type: "value" },
  title: { type: "value" },
};
