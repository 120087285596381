import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useParams } from "react-router-dom";
import {
  SaveDevelopmentInput,
  useDevelopmentDetailQuery,
  useDevelopmentsPageMetadataQuery,
  useSaveDevelopmentsMutation,
} from "src/generated/graphql-types";

export function CreateOrUpdateDevelopmentModal() {
  const { openModal } = useModal();
  const { developmentId } = useParams<{ developmentId: string }>();
  const btnText = `${developmentId ? "Edit" : "Create new"} development`;

  return (
    <Button
      data-testid="openDevelopmentModalButton"
      label={btnText}
      onClick={() => openModal({ content: <DevelopmentForm developmentId={developmentId} /> })}
      variant={developmentId ? "secondary" : undefined}
    />
  );
}

type DevelopmentFormProps = {
  developmentId?: string;
};

// Exported for storybook
export function DevelopmentForm({ developmentId }: DevelopmentFormProps) {
  const { closeModal } = useModal();
  const { data: selectOptions, loading: selectOptionsLoading } = useDevelopmentsPageMetadataQuery({
    fetchPolicy: "cache-first",
  });

  const { data: developmentData, loading: developmentDataLoading } = useDevelopmentDetailQuery({
    variables: { developmentId: developmentId ?? "" },
    skip: !developmentId,
  });

  const [saveDevelopments, { loading: saveDevelopmentsLoading }] = useSaveDevelopmentsMutation({
    // only refetch all developments when creating a new one from the developments listing page
    ...(!developmentId ? { refetchQueries: ["Developments"] } : {}),
  });

  const loading = selectOptionsLoading || developmentDataLoading || saveDevelopmentsLoading;

  const formState = useFormState({
    config: formConfig,
    init: {
      input: developmentData?.development,
      map: (dev) => ({
        id: dev.id,
        name: dev.name,
        marketId: dev.market.id,
      }),
    },
  });

  const onSubmit = async () => {
    await saveDevelopments({
      variables: { developments: { developments: [{ ...formState.value }] } },
    });
    closeModal();
  };

  // Developments with existing cohorts cannot have their market updated
  const shouldDisableMarketSelect = !!developmentData?.development.cohorts?.length;

  return (
    <>
      <ModalHeader>{`${developmentId ? "Edit" : "Create New"} Development`}</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField field={formState.name} label="Development Name" compact={true} disabled={loading} />
          <BoundSelectField
            options={selectOptions?.markets ?? []}
            field={formState.marketId}
            label="Market"
            disabled={loading || shouldDisableMarketSelect}
            compact
          />
          {shouldDisableMarketSelect && (
            <div css={Css.xs.gray700.mt1.$}>
              Market can only be changed when all projects from the current market have been removed from the
              development
            </div>
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              data-testid="saveButton"
              label={developmentId ? "Save" : "Create Development"}
              onClick={onSubmit}
              disabled={!formState.valid || loading}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const formConfig: ObjectConfig<SaveDevelopmentInput> = {
  id: { type: "value" },
  marketId: { type: "value", rules: [required] },
  name: { type: "value", rules: [required] },
};
