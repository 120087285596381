import { useSnackbar, useSuperDrawer } from "@homebound/beam";
import {
  ProductStatus,
  ProductSuperDrawer_ProductFragment,
  useSaveProductsMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { ProductSuperDrawer } from "./product-super-drawer/ProductSuperDrawer";

type DuplicationModalProps = {
  product: ProductSuperDrawer_ProductFragment;
};

export function ProductDuplicationModal({ product }: DuplicationModalProps) {
  const { triggerNotice } = useSnackbar();
  const { closeDrawer, openInDrawer } = useSuperDrawer();
  const [saveProducts] = useSaveProductsMutation();

  return (
    <ConfirmationModal
      confirmationMessage="Do you want to duplicate this product? A new one will be created with the same information"
      onConfirmAction={async () => {
        const { data } = await saveProducts({
          variables: {
            input: {
              products: [
                // Map the current product's information
                {
                  name: `${product.name} [COPY]`,
                  description: product.description,
                  sku: product.sku,
                  msrpInCents: product.msrpInCents,
                  specifications: product.specifications,
                  manufacturerUrl: product.manufacturerUrl,
                  status: ProductStatus.Active,
                  itemIds: product.items.map((item) => item.id),
                  images: product.images.map((img) => ({ id: img.id })),
                  attributes: product.attributes.map(({ type, value }) => ({ type, value })),
                  dimensions: product.dimensions.map(({ dimensionType, rawValue }) => ({
                    dimensionType: dimensionType.code,
                    rawValue,
                  })),
                },
              ],
            },
          },
        });

        if (!data) {
          return triggerNotice({ message: "There was an error duplicating the product" });
        }

        const [addedProduct] = data.saveProducts.products;

        triggerNotice({
          icon: "success",
          message: "Product successfully duplicated",
          action: {
            label: "Go to Product",
            onClick: () =>
              openInDrawer({
                content: <ProductSuperDrawer productId={addedProduct.id} isEdit={true} />,
              }),
            variant: "tertiary",
          },
        });

        closeDrawer();
      }}
      title="Duplicate product"
      label="Confirm"
    />
  );
}
