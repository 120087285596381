import { AvatarGroup, Css, Icon, Palette, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { Card } from "src/components/Card";
import { useToDoModal } from "src/components/to-dos/ToDoModal";
import { ToDoStatus, ToDoTab_ChangeRequestToDoFragment } from "src/generated/graphql-types";
import { daysAgo } from "src/utils";

type ChangeRequestToDosTabProps = {
  toDos: ToDoTab_ChangeRequestToDoFragment[];
  refetch: () => void;
};

export function ChangeRequestToDosTab({ toDos, refetch }: ChangeRequestToDosTabProps) {
  const [completed, inProgress] = useMemo(
    () => toDos.partition(({ status }) => status.code === ToDoStatus.Complete),
    [toDos],
  );

  const tids = useTestIds({}, "changeRequestToDosTab");

  return (
    <div {...tids}>
      <ChangeRequestToDoCardGroup toDos={inProgress} title="In Progress" refetch={refetch} />
      <ChangeRequestToDoCardGroup toDos={completed} title="Complete" refetch={refetch} />
    </div>
  );
}

function ChangeRequestToDoCardGroup({
  toDos,
  title,
  refetch,
}: {
  toDos: ToDoTab_ChangeRequestToDoFragment[];
  title: string;
  refetch: () => void;
}) {
  return (
    <>
      <h3 css={Css.smSb.mt2.$}>
        {title} ({toDos.length})
      </h3>
      {toDos.isEmpty ? (
        <div css={Css.bgGray100.br4.p2.mt1.tac.$}>No '{title}' To-Dos</div>
      ) : (
        toDos.map((toDo) => <ChangeRequestToDoCard toDo={toDo} key={toDo.id} refetch={refetch} />)
      )}
    </>
  );
}

function ChangeRequestToDoCard({ toDo, refetch }: { toDo: ToDoTab_ChangeRequestToDoFragment; refetch: () => void }) {
  const { id, name, dueDate, assignees, status, urgent } = toDo;
  const tids = useTestIds({}, "changeRequestToDoCard");

  const { open } = useToDoModal({ refreshQueries: refetch });

  // We show a partially transparent overlay over completed to-dos to de-emphasize them.
  const showCompleteOverlay = status.code === ToDoStatus.Complete;

  return (
    <Card xss={Css.mt2.$} onClick={() => open(id)}>
      <div {...tids} css={Css.relative.$}>
        {showCompleteOverlay && <div css={Css.absolute.left0.w100.h100.bgWhite.z2.add("opacity", 0.5).$} />}
        <div css={Css.df.jcsb.aib.$}>
          <h4 css={Css.sm.mb1.$}>{name}</h4>
          <div css={Css.fs0.pl1.xsMd.gray700.df.jcsb.$}>
            {dueDate && <span>Due {daysAgo(dueDate, { businessDays: false })}</span>}
          </div>
        </div>
        <div css={Css.df.jcsb.aib.$}>
          <AvatarGroup avatars={assignees.map(({ name, avatarUrl }) => ({ name, src: avatarUrl }))} />
          {urgent && (
            // 3px padding bottom to align bottom with the avatar border offset
            <div css={Css.pbPx(3).$}>
              <Icon color={Palette.Red600} icon="flag" />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
