import { Css, GridColumn, GridDataRow, GridTable, ScrollableContent, column, simpleHeader } from "@homebound/beam";
import { useMemo } from "react";
import { ChangeType, ChangeTypesQuery, useChangeTypesQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { changeLogPaths } from "src/routes/routesDef";

export function ChangeTypesPage() {
  const { data } = useChangeTypesQuery();

  const columns = useMemo(() => createColumns(), []);

  const rows: GridDataRow<Row>[] = useMemo(() => createRows(data?.enumDetails), [data?.enumDetails]);

  return (
    <div css={Css.df.fdc.fg1.$}>
      <PageHeader
        title="Change Types"
        breadcrumb={{ href: changeLogPaths.base, label: "Change Log" }}
        xss={Css.px3.mb0.$}
      />
      <div css={Css.p5.$}>
        <ScrollableContent>
          <GridTable columns={columns} rows={rows} />
        </ScrollableContent>
      </div>
    </div>
  );
}

const ChangeTypeToExampleMap: Record<ChangeType, string> = {
  [ChangeType.ProgramChange]: "Square footage, bed/bath counts",
  [ChangeType.ArchitechturalDesign]: "Center windows in bedrooms #2 and #5",
  [ChangeType.StructuralDesign]: "Insufficient framing specified for new window",
  [ChangeType.MepDesign]: "Add can light in prep pantry",
  [ChangeType.AddRemoveModifyProducts]: "Remove the hand-held shower in the primary bathrooms, for a partiucular lot",
  [ChangeType.ModifyOptionsProgram]: "New option added to a design package",
  [ChangeType.NewDesignPackage]: "Market refresh to include alternate schemes",
  [ChangeType.UpdateExistingDesignPackage]: "Changing a countertop selection due to negative buyer feedback",
  [ChangeType.NewExteriorStyle]: "Change to the exterior elevation",
  [ChangeType.ImplementationError]: "Incorrect doors installed",
  [ChangeType.ProductRefresh]: "-",
  [ChangeType.Na]: "-",
};

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: { changeType: string; example: string } };

type Row = HeaderRow | DataRow;

const createColumns = (): GridColumn<Row>[] => [
  column<Row>({
    header: "Change Type",
    data: ({ changeType }) => ({
      content: () => changeType,
    }),
    w: "380px",
  }),
  column<Row>({
    header: "Example",
    data: ({ example }) => ({
      content: () => example,
    }),
  }),
];

function createRows(enumDetails?: ChangeTypesQuery["enumDetails"]): GridDataRow<Row>[] {
  const changeTypes = enumDetails?.changeType ?? [];
  return [
    simpleHeader,
    ...changeTypes.map((ct) => ({
      id: ct.code,
      kind: "data" as const,
      data: {
        changeType: ct.name,
        example: ChangeTypeToExampleMap[ct.code as ChangeType],
      },
    })),
  ];
}
