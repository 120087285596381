import { useApolloClient } from "@apollo/client";
import {
  Button,
  Css,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { useState } from "react";
import { FileUploadProgress, UppyUploader } from "src/components";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  BillPageV2Document,
  DocumentType,
  LienWaiversPageDocument,
  SaveDocumentInput,
  useSaveLienWaiverDocumentMutation,
} from "src/generated/graphql-types";

type UploadLienWaiverModalProps = {
  parentId: string;
  ownerId: string;
  documentType: DocumentType;
};

export function UploadLienWaiverModal({ parentId, ownerId, documentType }: UploadLienWaiverModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState<UppyFile | undefined>();
  const [saveDocuments] = useSaveLienWaiverDocumentMutation();
  const client = useApolloClient();

  async function handleSaveDocument(file: UppyFile, ownerId: string) {
    const saveDocumentInput: SaveDocumentInput = {
      documentType,
      name: file.name,
      sizeInBytes: file.size,
      parentId,
      ownerId,
      asset: {
        contentType: file.type,
        fileName: file.name,
        s3Key: file.meta.s3Key as string,
        sizeInBytes: file.size,
      },
    };

    // Create an entry in the Document table
    await saveDocuments({ variables: { input: saveDocumentInput } });

    triggerNotice({
      icon: "success",
      message: "Document successfully uploaded. Lien Waiver is being updated to signed",
      persistent: true,
    });

    await client.refetchQueries({ include: [BillPageV2Document, LienWaiversPageDocument] });
    closeModal();
  }

  return (
    <>
      <ModalHeader>Upload lien waiver</ModalHeader>
      <ModalBody>
        <div css={Css.mb2.$}>Attach the lien waiver document.</div>
        {!uploadedFile ? (
          <UploaderProvider>
            <UppyUploader
              allowedFileTypes={["application/pdf"]}
              onFinish={(file) => setUploadedFile(file)}
              dragDropText="Drag & drop file"
              dragDropWidth={430}
              maxNumberOfFiles={1}
            />
            <FileUploadProgress hideOnComplete />
          </UploaderProvider>
        ) : (
          <div css={Css.ba.bsDashed.br4.pPx(4).$}>
            <div css={Css.bgGray100.smMd.gray700.df.p1.aic.jcsb.$}>
              <div css={Css.df.aic.$}>
                <Icon icon="document" />
                <div css={Css.ml1.$}>{uploadedFile.name}</div>
              </div>
              <div>
                <IconButton compact icon="x" onClick={() => setUploadedFile(undefined)} />
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Upload" disabled={!uploadedFile} onClick={() => handleSaveDocument(uploadedFile!, ownerId)} />
      </ModalFooter>
    </>
  );
}
