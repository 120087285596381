import { LienWaiverPdfQuery, useLienWaiverPdfQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { CALienWaiver } from "./CA/CALienWaiver";
import { COLienWaiver } from "./CO/COLienWaiver";
import { FLLienWaiver } from "./FL/FLLienWaiver";
import { GenericLienWaiver } from "./Generic/GenericLienWaiver";
import { TXLienWaiver } from "./TX/TXLienWaiver";

export type LienWaiverProps = {
  data: LienWaiverPdfQuery;
};

const stateLienWaver: Record<string, Function> = Object.freeze({
  CA: (data: LienWaiverPdfQuery) => <CALienWaiver data={data} />,
  TX: (data: LienWaiverPdfQuery) => <TXLienWaiver data={data} />,
  FL: (data: LienWaiverPdfQuery) => <FLLienWaiver data={data} />,
  CO: (data: LienWaiverPdfQuery) => <COLienWaiver data={data} />,
});

const generic = (data: LienWaiverPdfQuery) => <GenericLienWaiver data={data} />;

export function LienWaiverRouter() {
  const [{ lienWaiverId }] = useQueryParams({
    lienWaiverId: StringParam,
  });
  const query = useLienWaiverPdfQuery({ variables: { lienWaiverId: lienWaiverId! } });

  return queryResult(query, (data) => {
    const state = data.lienWaiver?.project.buildAddress.state.toUpperCase();
    const render = stateLienWaver[state] ?? generic;
    return render(data);
  });
}
