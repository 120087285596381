import { Button, ButtonMenu, Css, Switch, useSuperDrawer } from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  SelectionPricingMode,
  SelectionsTab_HomeownerSelectionFragment,
  SelectionsTab_PotentialBidItemFragment,
  SelectionsTabOptionFragment,
  useSaveHomeownerSelectionMutation,
  useSaveHomeownerSelectionOptionMutation,
  WritableHomeownerSelectionFieldsFragment,
} from "src/generated/graphql-types";
import { useReSortById } from "src/hooks/useReSortById";
import { HomeownerSelectionOptionForm } from "src/routes/projects/selections/options/homeownerSelectionOptionDetails/HomeownerSelectionOptionForm";
import { SelectionOptionsTable } from "src/routes/projects/selections/options/SelectionOptionsTable";
import { isDefined } from "src/utils";
import { simpleSearch } from "src/utils/simpleSearch";

type SelectionOptionsProps = {
  homeownerSelection: SelectionsTab_HomeownerSelectionFragment;
  potentialBidItems: SelectionsTab_PotentialBidItemFragment[];
  bidItemId: string | null | undefined;
};

export function SelectionOptions({ homeownerSelection, bidItemId, potentialBidItems }: SelectionOptionsProps) {
  const { id: selectionId, productOptions, selectionPricingMode } = homeownerSelection;
  const allOptions = useMemo(
    () => optionsByPotentialBidItems(productOptions, potentialBidItems, bidItemId),
    [bidItemId, potentialBidItems, productOptions],
  );

  const { openDrawerDetail } = useSuperDrawer();
  const [saveHomeownerSelectionMutation] = useSaveHomeownerSelectionMutation();
  const saveHomeownerSelection = useCallback(
    async (newValues: Partial<WritableHomeownerSelectionFields>) => {
      await saveHomeownerSelectionMutation({
        variables: { input: { id: selectionId, ...newValues } },
      });
    },
    [selectionId, saveHomeownerSelectionMutation],
  );

  const [saveHomeownerSelectionOptionMutation] = useSaveHomeownerSelectionOptionMutation();
  const addNewCustomProductHomeownerSelectionOption = useCallback(
    (productId: string) => async () => {
      await saveHomeownerSelectionOptionMutation({
        variables: { input: { productId, homeownerSelectionId: homeownerSelection.id, isHomeownerVisible: true } },
      });
    },
    [homeownerSelection.id, saveHomeownerSelectionOptionMutation],
  );

  const [searchFilter, setSearchFilter] = useState<string | undefined>("");
  const preFilteredOptions = useReSortById(allOptions, sortSelectionOptions, (o) => o.product.id);
  const filteredOptions = simpleSearch(preFilteredOptions, searchFilter, toSearchString).filter(
    (o) => !o.isSelectedOption,
  );

  const menuItems = [
    ...homeownerSelection.potentialCustomProducts.map((customProduct) => ({
      label: customProduct.name,
      disabled:
        !!homeownerSelection.productOptions?.find((o) => o.product.id === customProduct.id) ||
        !homeownerSelection?.canEdit.allowed,
      onClick: addNewCustomProductHomeownerSelectionOption(customProduct.id),
    })),
    {
      disabled: !homeownerSelection?.canEdit.allowed,
      label: "Add Custom Product",
      onClick: () =>
        openDrawerDetail({
          content: (
            <HomeownerSelectionOptionForm
              homeownerSelectionId={homeownerSelection.id}
              showPricingType={selectionPricingMode === SelectionPricingMode.PriceDelta}
            />
          ),
        }),
    },
  ];

  return (
    <>
      <div css={Css.sticky.mt3.topPx(-24).bgWhite.z1.$} data-testid="selectionOptions">
        <div css={Css.df.jcsb.aic.gap1.$}>
          <div css={Css.baseMd.my2.mwPx(136).$}>Selection Options</div>
          <Switch
            compact
            disabled={!homeownerSelection?.canEdit.allowed}
            label="Published"
            onChange={(isPublished) => saveHomeownerSelection({ isPublished })}
            selected={homeownerSelection.isPublished}
          />
          <div css={Css.mwPx(200).maxwPx(300).$}>
            <SearchBox onSearch={setSearchFilter} />
          </div>

          <div css={Css.mwPx(166).$}>
            {homeownerSelection.potentialCustomProducts.length > 0 ? (
              <ButtonMenu trigger={{ label: "Add Custom Product" }} items={menuItems} />
            ) : (
              <Button
                disabled={!homeownerSelection?.canEdit.allowed}
                label="Add Custom Product"
                variant="secondary"
                onClick={() =>
                  openDrawerDetail({
                    content: (
                      <HomeownerSelectionOptionForm
                        homeownerSelectionId={homeownerSelection.id}
                        showPricingType={selectionPricingMode === SelectionPricingMode.PriceDelta}
                      />
                    ),
                  })
                }
              />
            )}
          </div>
        </div>
      </div>

      <SelectionOptionsTable
        selection={homeownerSelection}
        options={filteredOptions}
        potentialBidItems={potentialBidItems}
        view="selectionOptionsList"
      />
    </>
  );
}

function toSearchString(option: SelectionsTabOptionFragment): string {
  const { product } = option;
  const brand = product.attributes.find((a) => a.type === "BRAND")?.value;
  const finish = product.attributes
    .filter((a) => a.type === "FINISH")
    ?.map((f) => f.value)
    .join(" ");

  return `${product.name} ${brand} ${finish} ${product.sku}`.toLowerCase();
}

type WritableHomeownerSelectionFields = Omit<WritableHomeownerSelectionFieldsFragment, "id" | "__typename">;

/** Sort selection options by isHomeownerVisible and name */
function sortSelectionOptions(selectionOptions: SelectionsTabOptionFragment[]) {
  return selectionOptions.sort((a, b) => {
    if (a.isHomeownerVisible && !b.isHomeownerVisible) return -1;
    if (b.isHomeownerVisible && !a.isHomeownerVisible) return 1;
    else {
      // "en": Transform non-english characters like ç to c
      // "sensitivity: base": Lowercase strings before comparison
      return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
    }
  });
}

// If a bid item is selected, only show options for that bid item
// If a bid item is not selected, only show options for products that are in potential bid items
function optionsByPotentialBidItems(
  options: SelectionsTabOptionFragment[],
  potentialBidItems: SelectionsTab_PotentialBidItemFragment[],
  bidItemId: string | null | undefined,
) {
  if (isDefined(bidItemId)) {
    return options.filter((o) => o.product.bidItems.some((bi) => bi.id === bidItemId));
  }

  const potentialProductsIds = potentialBidItems.flatMap(({ products }) => products).map(({ id }) => id);
  return options.filter((o) => potentialProductsIds.includes(o.product.id));
}
