import { ReactPortal } from "react";
import { createPortal } from "react-dom";
import { useStepperContext } from "src/components/stepper/StepperContext";

// Helper component to enable appending content the <StepperProvider /> via a portal
export function StepActions({ children }: { children: JSX.Element }): ReactPortal | JSX.Element {
  const { stepActionsEl } = useStepperContext();

  // a fallback for tests should stepperActionsEl not exist so tests can find the elements it needs
  if (!stepActionsEl) {
    return children;
  }

  return createPortal(children, stepActionsEl);
}
