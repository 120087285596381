import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar, useTestIds } from "@homebound/beam";
import { useDeleteUploadedPandaDocMutation } from "src/generated/graphql-types";
import { pandaDocOwnerName } from "./PandaDocModalContent";

export function DeletePandaDocModal({ id, ownerId }: { id: string; ownerId: string }) {
  const [deletePandaDoc] = useDeleteUploadedPandaDocMutation();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  const testIds = useTestIds({}, "pdDeleteModal");
  const ownerType = pandaDocOwnerName(ownerId);

  return (
    <>
      <ModalHeader>Delete Uploaded {pandaDocOwnerName(ownerId, true)} from PandaDoc</ModalHeader>
      <ModalBody>
        <p css={Css.sm.gray900.mb2.$}>Are you sure you want to delete this uploaded {ownerType} from PandaDoc?</p>
        <p css={Css.sm.gray700.$}>
          You will no longer be able to access the file. If you make changes afterwards, you will need to upload the{" "}
          {ownerType} to PandaDoc again.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Delete"
          onClick={async () => {
            try {
              const { data, errors } = await deletePandaDoc({
                variables: { input: { id } },
              });
              // const pandaDocId = data?.savePandaDoc.pandaDoc.id;
              if (!data) {
                triggerNotice({
                  message: errors?.toString() ?? "Deleting PandaDoc failed",
                });
              } else {
                triggerNotice({
                  message: `${ownerType} was deleted!`,
                  icon: "success",
                });
              }
              closeModal();
            } catch (e) {
              // close modal if error as toast error popup will be displayed
              closeModal();
            }
          }}
          {...testIds.PandaDocDeleteTrigger}
        />
      </ModalFooter>
    </>
  );
}
