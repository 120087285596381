import { Css, IconButton, px } from "@homebound/beam";
// @ts-ignore
import pdfWorker from "pdfjs-dist/build/pdf.worker.js?url";
import { useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { AssetInfoFragment } from "src/generated/graphql-types";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

export type PdfViewerProps = {
  assets: Partial<AssetInfoFragment[]>;
  handlePdfClose?: () => void;
  handlePdfDelete?: (assetId: string) => void;
  hasHeader?: boolean;
  title?: string;
  pdfPageWidth?: number;
};

export function PdfViewer({
  assets,
  title,
  hasHeader = false,
  handlePdfClose,
  handlePdfDelete,
  pdfPageWidth,
}: PdfViewerProps) {
  const numFiles = assets.length;
  const [currentFile, setCurrentFile] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  // set initial page when change the file
  useEffect(() => setCurrentPage(1), [currentFile]);

  const { downloadUrl, attachmentUrl, id } = useMemo(() => assets[currentFile - 1]!, [assets, currentFile]);

  function HeaderControls() {
    const hasMultiplePages = numPages > 1;
    const hasMultipleFiles = numFiles > 1;
    if (!hasMultipleFiles && !hasMultiplePages) return null;
    return (
      <div css={Css.z1.bgWhite.absolute.top2.left("50%").add("transform", "translateX(-50%)").if(!hasHeader).top0.$}>
        {/* File Controls */}
        {hasMultipleFiles ? (
          <div css={Css.df.aic.jcsa.$}>
            <IconButton
              icon="chevronLeft"
              onClick={() => setCurrentFile(currentFile - 1)}
              disabled={currentFile === 1}
            />
            <span css={Css.sm.gray800.px1.$}>
              {currentFile} of {numFiles} files
            </span>
            <IconButton
              icon="chevronRight"
              onClick={() => setCurrentFile(currentFile + 1)}
              disabled={currentFile === numFiles}
            />
          </div>
        ) : null}

        {/* Page Controls */}
        {hasMultiplePages ? (
          <div css={Css.df.aic.jcsa.$}>
            <IconButton
              icon="chevronLeft"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            <span css={Css.sm.gray800.px1.$}>
              {currentPage} of {numPages} pages
            </span>
            <IconButton
              icon="chevronRight"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === numPages}
            />
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div css={Css.oyh.relative.mh100.df.fdc.$} data-testid="pdfViewer">
      {hasHeader && (
        <header css={Css.px4.df.fs0.aic.jcsb.bgWhite.$} data-testid="pdfViewerHeader">
          <h2 css={Css.my2.lgSb.fwn.wsnw.maxw(px(360)).oh.add("textOverflow", "ellipsis").$}>{title}</h2>
          <HeaderControls />
          <div css={Css.df.aic.gap1.$}>
            {downloadUrl && <IconButton onClick={downloadUrl} openInNew icon="linkExternal" />}
            {attachmentUrl && <IconButton onClick={attachmentUrl} icon="download" />}
            {handlePdfDelete && (
              <IconButton
                icon="trash"
                tooltip="delete file"
                onClick={() => {
                  setCurrentFile(1); // back to the first file
                  handlePdfDelete(id);
                }}
              />
            )}
            {handlePdfClose && <IconButton icon="x" onClick={handlePdfClose} />}
          </div>
        </header>
      )}
      <Document
        file={downloadUrl}
        onLoadSuccess={({ numPages }: { numPages: number }) => setNumPages(numPages)}
        css={Css.fg1.$}
        onLoadError={(err) => console.error(err)}
      >
        <Page
          loading=""
          pageNumber={currentPage}
          width={pdfPageWidth || 800}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
      {!hasHeader && <HeaderControls />}
    </div>
  );
}
