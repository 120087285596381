import {
  Button,
  Css,
  dateRangeFilter,
  DateRangeFilterValue,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridStyle,
  GridTable,
  Palette,
  RightPaneLayout,
  RowStyles,
  ScrollableContent,
  useBreakpoint,
  usePersistedFilter,
  useRightPane,
} from "@homebound/beam";
import { noCase } from "change-case";
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { SearchBox } from "src/components";
import {
  DateOperation,
  HomeownerNotesForProjectFragment,
  useHomeownerNotesForProjectQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { createProjectJobLogsGalleryUrl } from "src/RouteUrls";
import { hasData, renderLoadingOrError } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { StringParam, useQueryParams } from "use-query-params";
import { HomeownerNoteRightPane } from "../job-logs/components/HomeownerNoteRightPane";
import { JobLogNoRowsImage } from "../job-logs/components/JobLogsNoRowsImage";
import { HomeownerNote } from "./components/HomeownerNote";

// project will be added in the filter from url param
export type HomeownerNotesFilterType = {
  dateRange?: DateRangeFilterValue<string>;
};

// Homeowner Note width in pixels
const HomeownerNoteWidth = 690;
const SmallHomeownerNoteWidth = 455;

export function HomeownerNotesPage() {
  const { projectId } = useParams<{ projectId: string }>();
  const [{ homeownerNoteId }] = useQueryParams({ homeownerNoteId: StringParam });

  const filterDefs: FilterDefs<HomeownerNotesFilterType> = useMemo(() => {
    return {
      dateRange: dateRangeFilter({ label: "Date Range" }),
    };
  }, []);
  const { setFilter, filter } = usePersistedFilter<HomeownerNotesFilterType>({
    storageKey: "homeownerNoteFilter",
    filterDefs,
  });
  const query = useHomeownerNotesForProjectQuery({
    variables: {
      filter: {
        project: [projectId],
        // We will filter only if both from and to are provided
        ...(filter?.dateRange?.value?.from && filter?.dateRange?.value?.to
          ? {
              createdDate: {
                op: DateOperation.Between,
                value: new DateOnly(new Date(filter.dateRange.value.from)),
                value2: new DateOnly(new Date(filter.dateRange.value.to)),
              },
            }
          : {}),
      },
    },
  });
  const { data } = query;
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const rowLookup = useRef<GridRowLookup<HomeownerNoteRow>>();
  const breakpoints = useBreakpoint();
  const { closeRightPane, openRightPane } = useRightPane();
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowStyles = useMemo(() => createRowStyles(breakpoints.mdAndDown), []);

  const rows = useMemo(() => createRows(data?.homeownerNotes || []), [data?.homeownerNotes]);
  const columns = useMemo(
    () =>
      createColumns((homeownerNoteId: string) =>
        openRightPane({
          content: (
            <HomeownerNoteRightPane
              homeownerNoteId={homeownerNoteId}
              onClose={() => {
                closeRightPane();
              }}
              onSuccess={() => {
                closeRightPane();
              }}
            />
          ),
        }),
      ),
    [closeRightPane, openRightPane],
  );

  // moreFilters responsiveness
  const [numberOfInlineFilters, setNumberOfInlineFilters] = useState(2);
  useEffect(() => {
    if (breakpoints.smOrMd) {
      setNumberOfInlineFilters(1);
    } else if (breakpoints.lg) {
      setNumberOfInlineFilters(2);
    }
  }, [breakpoints]);

  // Scroll to homeownerNoteId, I don't know if there's a better way to know if grid table is stable to scroll to
  useEffect(() => {
    if (homeownerNoteId && rowLookup.current) {
      setTimeout(() => {
        rowLookup.current!.scrollTo("homeownerNote", homeownerNoteId);
      }, 50);
    }
  }, [homeownerNoteId]);

  // Handle loading state
  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  return (
    <div>
      <PageHeader
        title="Homeowner Notes"
        left={
          <Filters<HomeownerNotesFilterType>
            filter={filter}
            onChange={setFilter}
            filterDefs={filterDefs}
            numberOfInlineFilters={numberOfInlineFilters}
          />
        }
        right={
          <div css={Css.df.gap1.$}>
            <SearchBox onSearch={setSearchFilter} />
            <Button
              label="Add new note"
              variant="primary"
              onClick={createProjectJobLogsGalleryUrl(projectId)}
              icon="plus"
            />
          </div>
        }
      />
      <div css={Css.mt(-3).h100.$} data-testid="homeownerNotesPageWrapper">
        {rows.length > 0 ? (
          <ScrollableContent virtualized bgColor={Palette.Gray100}>
            <div css={Css.h100.mr3.$}>
              <RightPaneLayout paneWidth={448}>
                <GridTable
                  as="virtual"
                  rows={rows}
                  rowStyles={rowStyles}
                  columns={columns}
                  rowLookup={rowLookup}
                  style={style}
                  filter={searchFilter}
                />
              </RightPaneLayout>
            </div>
          </ScrollableContent>
        ) : (
          <div css={Css.df.fdc.aic.$}>
            <div css={Css.ptPx(36).pb5.$}>
              <JobLogNoRowsImage />
            </div>
            <div css={Css.base.pb2.$}>No homeowner notes found.</div>
          </div>
        )}
      </div>
    </div>
  );
}

function createColumns(onEdit: (homeownerNoteId: string) => void): GridColumn<HomeownerNoteRow>[] {
  const homeownerNoteColumn: GridColumn<HomeownerNoteRow> = {
    homeownerNote: (homeownerNote) => ({
      content: <HomeownerNote homeownerNote={homeownerNote} onEdit={onEdit} />,
      value: searchString(homeownerNote),
    }),
  };
  return [homeownerNoteColumn];
}

function createRows(homeownerNotes: HomeownerNotesForProjectFragment[]): GridDataRow<HomeownerNoteRow>[] {
  return homeownerNotes.map((homeownerNote) => ({
    kind: "homeownerNote",
    id: homeownerNote.id,
    data: homeownerNote,
  }));
}

function createRowStyles(compact: boolean): RowStyles<HomeownerNoteRow> {
  return {
    homeownerNote: {
      cellCss: Css.bcTransparent.important.mb0.bn.jcc.maxwPx(compact ? SmallHomeownerNoteWidth : HomeownerNoteWidth).$,
    },
  };
}

// TODO: Move to proper file?
export function formatDate(date: Date) {
  return format(date, "EEEE, MMMM d");
}

function searchString(homeownerNote: HomeownerNotesForProjectFragment) {
  const { createdAt, createdBy, description, title } = homeownerNote;
  const formattedDate = formatDate(createdAt);
  const searchStrings = [formattedDate, createdBy.name, description, title].map((s) => s && noCase(s));
  return searchStrings.join(" ");
}

type HomeownerNoteRow = {
  kind: "homeownerNote";
  id: string;
  data: HomeownerNotesForProjectFragment;
};

const style: GridStyle = {
  rowHoverColor: Palette.Gray100,
  rootCss: Css.wPx(HomeownerNoteWidth).mxa.$,
};
