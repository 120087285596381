import {
  CheckboxGroup,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  simpleHeader,
} from "@homebound/beam";
import { chipCell, dateCell, linkHeader, priceCell, tagCell } from "src/components";
import {
  ApprovalChangeEventImpactedPosTableTabQuery,
  ChangeEvent_CommitmentChangeOrderFragment,
  ChangeEvent_CommitmentFragment,
  useApprovalChangeEventImpactedPosTableTabQuery,
  useSaveApprovalChangeEventMutation,
} from "src/generated/graphql-types";
import { commitmentStatusToTagTypeMapper, partition, queryResult } from "src/utils";

type ApprovalChangeEventImpactedPosTableProps = {
  changeEventId: string | undefined;
};

export function ApprovalChangeEventImpactedPosTable({ changeEventId }: ApprovalChangeEventImpactedPosTableProps) {
  const query = useApprovalChangeEventImpactedPosTableTabQuery({ variables: { changeEventId: changeEventId! } });
  return queryResult(query, (data) => <ApprovalChangeEventImpactedPosTableDataView changeEvent={data.changeEvent} />);
}

function ApprovalChangeEventImpactedPosTableDataView({ changeEvent }: ApprovalChangeEventImpactedPosTableTabQuery) {
  const [commitments, changeOrders] = partition(
    // TODO: use changeEvent.impactedCommitments
    changeEvent.lineItems.flatMap((li) => li.projectItem.commitmentLineItems.map((cli) => cli.owner)).uniqueByKey("id"),
    (commitmentLike) => commitmentLike.__typename === "Commitment",
  );
  const rows = createRows(
    commitments as ChangeEvent_CommitmentFragment[],
    changeOrders as ChangeEvent_CommitmentChangeOrderFragment[],
  );
  const columns = createColumns();
  const [saveApprovalChangeEvent] = useSaveApprovalChangeEventMutation();

  return (
    <div css={Css.mb7.$}>
      <div css={Css.smSb.h3.mbPx(10).$}>On Approval completion, create draft commitments for...</div>
      <CheckboxGroup
        // Provide label to appease Aria then hide it
        label="On Approval completion, create draft commitments for..."
        labelStyle="hidden"
        columns={changeEvent.impactedTrades.length > 4 ? 3 : 1} // Only use 1 col until we have enough items to justify 3 col
        options={changeEvent.impactedTrades.map((tp) => ({ label: tp.name, value: tp.id })).sortByKey("label")}
        values={changeEvent.tradesToDraftCommitmentsFor.map((tp) => tp.id)}
        onChange={(vals) =>
          saveApprovalChangeEvent({ variables: { input: { id: changeEvent.id, tradesToDraftCommitmentsFor: vals } } })
        }
      />
      <div css={Css.smSb.h3.mbPx(10).mt4.$}>Impacted Purchase Orders</div>
      <GridTable
        columns={columns}
        rows={rows}
        style={{ bordered: true }}
        fallbackMessage="No impacted purchase orders"
        sorting={{ on: "client", initial: ["accountingNumber", "ASC"] }}
      />
    </div>
  );
}

function createRows(
  commitments: ChangeEvent_CommitmentFragment[],
  changeOrders: ChangeEvent_CommitmentChangeOrderFragment[],
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...commitments.map((c) => ({
      kind: "commitment" as const,
      id: c.id,
      data: c,
    })),
    ...changeOrders.map((co) => ({
      kind: "changeOrder" as const,
      id: co.id,
      data: co,
    })),
  ];
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Commitment",
      commitment: ({ blueprintUrl, accountingNumber }) => linkHeader(`PO #${accountingNumber}`, blueprintUrl.path),
      changeOrder: ({ blueprintUrl, accountingNumber }) => linkHeader(`CO #${accountingNumber}`, blueprintUrl.path),
      w: "101px",
    }),
    column<Row>({
      header: "Trade partner",
      commitment: ({ tradePartner }) => tradePartner?.name,
      changeOrder: ({ tradePartner }) => tradePartner?.name,
      w: "150px",
    }),
    column<Row>({
      header: "Address",
      commitment: ({ project }) => project.buildAddress.street1,
      changeOrder: () => emptyCell,
      w: "140px",
    }),
    column<Row>({
      header: "Item codes",
      commitment: ({ items }) =>
        chipCell(
          items.map((i) => i.fullCode),
          3,
        ),
      changeOrder: ({ items }) =>
        chipCell(
          items.map((i) => i.fullCode),
          3,
        ),
      w: "220px",
    }),
    column<Row>({
      header: "Release date",
      commitment: ({ executionDate }) => dateCell(executionDate),
      changeOrder: ({ executionDate }) => dateCell(executionDate),
      w: "123px",
    }),
    column<Row>({
      header: "Status",
      commitment: ({ status }) => tagCell(commitmentStatusToTagTypeMapper[status], status),
      changeOrder: ({ status }) => tagCell(commitmentStatusToTagTypeMapper[status], status),
      w: "100px",
    }),
    column<Row>({
      header: "Version",
      commitment: ({ bidContractRevision }) => bidContractRevision?.version,
      changeOrder: emptyCell,
      w: "70px",
    }),
    column<Row>({
      header: "Total Cost",
      commitment: ({ costChangeInCents }) => priceCell({ valueInCents: costChangeInCents }),
      changeOrder: ({ costChangeInCents }) => priceCell({ valueInCents: costChangeInCents }),
      align: "right",
      w: "108px",
      sticky: "right",
    }),
  ];
}

type Row = { kind: "header" } | CommitementRow | CommitementChangeOrderRow;

type CommitementRow = {
  kind: "commitment";
  id: string;
  data: ChangeEvent_CommitmentFragment;
};

type CommitementChangeOrderRow = {
  kind: "changeOrder";
  id: string;
  data: ChangeEvent_CommitmentChangeOrderFragment;
};
