import { Button, Css } from "@homebound/beam";

type ExpenseAllocationSuccessProps = {
  totalProjectsDone: number;
  totalProjects: number;
  getProjectSlider: () => void;
};

export function ExpenseAllocationSuccess({
  totalProjects,
  totalProjectsDone,
  getProjectSlider,
}: ExpenseAllocationSuccessProps) {
  const allProjectsAllocated = totalProjects === totalProjectsDone;
  const isOnlyOneProject = totalProjects === 1;
  const message = allProjectsAllocated
    ? `You've successfully allocated all ${isOnlyOneProject ? "expenses for the project" : "projects"}! 🤗`
    : `You've allocated ${totalProjectsDone} of ${totalProjects} projects.`;
  return (
    <>
      <div css={Css.df.aic.h7.bgBlue50.$}>
        <span data-testid="allocationSuccessMessage" css={Css.baseMd.fwb.mr2.ml1.gray900.$}>
          {message}
        </span>
        {/* 1 project */}
        {!allProjectsAllocated && (
          <div css={Css.mb0.$}>
            <Button variant="primary" label="Next Project" onClick={getProjectSlider} />
          </div>
        )}
      </div>
    </>
  );
}
