import { Css, LoadingSkeleton, Palette, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { createPortal } from "react-dom";
import { PieChart } from "src/components/charts/PieChart";
import { LotSummaryChartsQuery, NamedFragment, useLotSummaryChartsQuery } from "src/generated/graphql-types";

export function LotSummaryCharts({
  developmentId,
  summaryProjectIds = [],
}: {
  developmentId: string;
  summaryProjectIds?: string[];
}) {
  // query the milestones from all lots on the provided development
  const { data, loading } = useLotSummaryChartsQuery({
    variables: { developmentId, projectIds: summaryProjectIds },
  });
  const cohortColors = useMemo(
    () => getCohortColors(data?.developmentLotSummaryCharts?.development.cohorts?.compact()),
    [data],
  );
  const testId = useTestIds({ developmentId }, "lotSummaryCharts");

  // one color per cohort, set the max amount of colors per cohort
  const colors = useMemo(
    () => (loading ? LOADING_COLOR : getColors(Object.values(cohortColors).map(({ color }) => color))),
    [loading, cohortColors],
  );

  const chartData = useMemo(() => {
    if (loading || !data?.developmentLotSummaryCharts) return LOADING_DATA;
    return mapToChartData(data.developmentLotSummaryCharts);
  }, [data, loading]);

  return (
    <div css={Css.w100.mb2.$}>
      <div css={Css.p2.df.gap4.mb1.bb.bw1.bcGray200.bgWhite.oxa.oyh.br8.bshBasic.$} {...testId}>
        <LotSummaryChartsLegendPortal cohortColors={cohortColors} />
        {chartData.map(({ title, total, dataset }, index) => (
          <div
            css={Css.fg1.if(index < chartData.length - 1).br.bw1.bcGray200.$}
            key={title}
            {...testId[title.replace(/ /g, "_")]}
          >
            <div css={Css.df.$}>
              <div css={Css.fg1.df.fdc.jcsb.$}>
                <span css={Css.smMd.gray700.$}>{title}</span>
                <span css={Css.xl3Sb.$}>{loading ? <LoadingSkeleton rows={1} columns={1} /> : total}</span>
              </div>
              <div css={Css.fg1.aic.jcc.ta("center").wPx(76).hPx(76).mwPx(76).mx4.$}>
                <PieChart
                  dataset={dataset}
                  colors={colors}
                  loadingColor={LOADING_COLOR}
                  disabled={!total}
                  tooltipPosition={index >= 3 ? "left" : "right"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export type CohortsColor = Record<
  string,
  {
    name: string;
    color: string;
  }
>;

function mapToChartData(data: LotSummaryChartsQuery["developmentLotSummaryCharts"]) {
  if (!data) return [];
  const {
    acquired,
    totalAcquired,
    permitSubmitted,
    totalPermitSubmitted,
    permitReceived,
    totalPermitReceived,
    verticalMobilization,
    totalVerticalMobilization,
    verticalComplete,
    totalVerticalComplete,
    delivered,
    totalDelivered,
  } = data;

  return [
    {
      title: CHARTS[0],
      total: totalAcquired,
      dataset: !totalAcquired
        ? EmptyData
        : acquired.map(({ cohort, total }) => ({ id: `Lots acquired in ${cohort.name}`, value: total })),
    },
    {
      title: CHARTS[1],
      total: totalPermitSubmitted,
      dataset: !totalPermitSubmitted
        ? EmptyData
        : permitSubmitted.map(({ cohort, total }) => ({
            id: `Lots with permit submitted in ${cohort.name}`,
            value: total,
          })),
    },
    {
      title: CHARTS[2],
      total: totalPermitReceived,
      dataset: !totalPermitReceived
        ? EmptyData
        : permitReceived.map(({ cohort, total }) => ({
            id: `Lots with permit received in ${cohort.name}`,
            value: total,
          })),
    },
    {
      title: CHARTS[3],
      total: totalVerticalMobilization,
      dataset: !totalVerticalMobilization
        ? EmptyData
        : verticalMobilization.map(({ cohort, total }) => ({
            id: `Lots with vertical mobilization in ${cohort.name}`,
            value: total,
          })),
    },
    {
      title: CHARTS[4],
      total: totalVerticalComplete,
      dataset: !totalVerticalComplete
        ? EmptyData
        : verticalComplete.map(({ cohort, total }) => ({
            id: `Lots with vertical complete in ${cohort.name}`,
            value: total,
          })),
    },
    {
      title: CHARTS[5],
      total: totalDelivered,
      dataset: !totalDelivered
        ? EmptyData
        : delivered.map(({ cohort, total }) => ({
            id: `Lots delivered in ${cohort.name}`,
            value: total,
          })),
    },
  ];
}

function getCohortColors(cohorts?: NamedFragment[]) {
  const colors = ["Blue200", "Blue400", "Blue600", "Blue800"];
  const cohortColors: CohortsColor =
    cohorts?.reduce(
      (prev, { id, name }, index) => ({
        ...prev,
        [id]: {
          name,
          color: index >= colors.length ? colors[Math.floor(Math.random() * colors.length)] : colors[index],
        },
      }),
      {},
    ) ?? {};
  return cohortColors;
}

export function LotSummaryChartsLegendContainer() {
  const testId = useTestIds({}, HEADER_TEST_ID);
  return <div {...testId[HEADER_LEGEND_CONTAINER]}></div>;
}

function LotSummaryChartsLegendPortal({ cohortColors }: { cohortColors: CohortsColor }) {
  const container = document.querySelector(`[data-testid="${HEADER_TEST_ID}_${HEADER_LEGEND_CONTAINER}"]`);

  if (!container) return <></>;

  return createPortal(<LotSummaryChartsLegend cohortColors={cohortColors} />, container);
}

export function LotSummaryChartsLegend({ cohortColors }: { cohortColors: CohortsColor }) {
  const testId = useTestIds(cohortColors, HEADER_TEST_ID);
  return (
    <div css={Css.df.w100.jcfe.gap3.pb2.$} {...testId.cohortLegend}>
      {Object.entries(cohortColors).map(([id, { name, color }]) => (
        <div css={Css.df.jcc.aic.gap1.$} key={id}>
          <div
            css={Css.h1.w1.br100.add({ ...(Css[`bg${color}` as keyof typeof Css] as typeof Css).$ }).$}
            {...testId[`${name.replace(/ /g, "")}_color`]}
          />
          {name}
        </div>
      ))}
    </div>
  );
}

const HEADER_TEST_ID = "lotSummaryPageHeader";
const HEADER_LEGEND_CONTAINER = "cohortLegendContainer";

const EmptyData = [{ id: null, value: true }];
const LOADING_COLOR = getColors(["Gray200"]);
const CHARTS = [
  "Acquired",
  "Permit submitted",
  "Permit received",
  "Vertical mobilization",
  "Vertical complete",
  "Delivered",
];
const LOADING_DATA = CHARTS.map((title) => ({
  title,
  total: 0,
  dataset: EmptyData,
}));

function getColors(colors: string[]) {
  return colors.map(
    (color) =>
      (Palette.hasOwnProperty(color) &&
        // @ts-ignore
        Palette[color]) ||
      color,
  );
}
