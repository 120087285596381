import { Css, Only, Tooltip, useTestIds } from "@homebound/beam";
import { Price } from "src/components/Price";
import { TableHeaderXss } from "src/components/tableHeaders/utils";
import { Maybe } from "src/generated/graphql-types";

type PriceHeaderProps<X> = {
  id?: string;
  label: string;
  price: Maybe<number>;
  xss?: X;
  labelOnBottom?: boolean;
  displayDirection?: boolean;
  tooltipText?: string;
};

export function PriceHeader<X extends Only<TableHeaderXss, X>>(props: PriceHeaderProps<X>) {
  const {
    id = "priceHeader",
    label,
    price,
    xss = {},
    labelOnBottom = false,
    displayDirection = false,
    tooltipText = "",
  } = props;

  const testId = useTestIds({}, id);
  return (
    <Tooltip title={tooltipText} placement="top">
      <div {...testId} css={Css.df.fdc.if(labelOnBottom).fdcr.$}>
        <div css={{ ...Css.tiny.wsnw.tar.$, ...xss }}>{label}</div>
        <div css={{ ...Css.sm.mt1.tar.if(labelOnBottom).mt0.mb1.$, ...xss }}>
          <Price valueInCents={price} dropZero displayDirection={displayDirection} />
        </div>
      </div>
    </Tooltip>
  );
}
