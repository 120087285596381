import { ApolloCache, StoreObject } from "@apollo/client/cache";
import { DefaultContext, MutationUpdaterFunction, OperationVariables } from "@apollo/client/core/types";

/**
 * A utility method for easily adding newly created entities into the cache
 * @getNewItems A function to pick the newly created entities out of your mutation response
 * @queryField The top level Query field to add the new items to
 *
 * NOTE: To avoid additional round trips to the server, ensure that the mutation response contains all needed fields
 *       for the current page. Also, filters applied to the Query field will not be enforced on newly created items inserted
 *       in this way. That has been the standard approach so far in our code base.
 */
export function updateQuery<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  getNewItems: (data: TData) => StoreObject[],
  queryField: string,
): MutationUpdaterFunction<TData, TVariables, TContext, TCache> {
  return (cache, result) => {
    if (result.data) {
      const newItems = getNewItems(result.data);
      cache.modify({
        fields: {
          [queryField]: (existingItems: any) => {
            return [...existingItems, ...newItems.map((item) => ({ __ref: cache.identify(item) }))];
          },
        },
      });
    }
  };
}
