import {
  column,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { Fragment } from "react";
import { FormattedDate, priceCell } from "src/components";
import { SectionTitle } from "src/components/contracts";
import { Divider } from "src/components/Divider";
import { CommitmentBillFragment } from "src/generated/graphql-types";
import { createBillUrl } from "src/RouteUrls";

export type CommitmentBillsTableProps = {
  bills: CommitmentBillFragment[];
  commitmentId: string;
  projectId: string;
};

export function CommitmentBillsTable(props: CommitmentBillsTableProps) {
  const { bills, commitmentId, projectId } = props;

  return (
    <Fragment>
      <Divider />
      <GridTable
        id="billLinesItemsTable"
        rowStyles={createRowStyles(projectId, commitmentId)}
        columns={createBillColumns()}
        rows={createBillRows(bills)}
        fallbackMessage="Bills for this commitment will show here."
      />
    </Fragment>
  );
}

type Row = SimpleHeaderAndData<CommitmentBillFragment>;

function createRowStyles(projectId: string, commitmentId: string): RowStyles<Row> {
  return {
    header: {},
    data: {
      rowLink: ({ id }) => createBillUrl(id),
    },
  };
}

function createBillRows(bills: CommitmentBillFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(bills);
}

function createBillColumns(): GridColumn<Row>[] {
  const billNumberColumn = column<Row>({
    header: () => <SectionTitle title="Bills & Credits" />,
    data: ({ tradePartnerNumber, isTradePartnerCredit }) =>
      isTradePartnerCredit ? `Credit #${tradePartnerNumber}` : `Bill #${tradePartnerNumber}`,
  });

  const approvalsColumn = column<Row>({
    header: () => "Approval",
    data: ({ approval }) => (approval ? approval.status.name : ""),
    w: 4,
  });

  const billDateColumn = dateColumn<Row>({
    header: () => "Bill Date",
    data: ({ billDate }) => <FormattedDate date={billDate} />,
    w: "110px",
  });

  const dueDateColumn = dateColumn<Row>({
    header: () => "Due Date",
    data: ({ dueDate }) => dueDate && <FormattedDate date={dueDate} />,
    w: "110px",
  });

  const billedColumn = numericColumn<Row>({
    header: () => "Billed",
    data: ({ billedInCents, isTradePartnerCredit }) =>
      priceCell({ valueInCents: isTradePartnerCredit ? -billedInCents : billedInCents }),
    w: "110px",
  });

  const paidToDateColumn = numericColumn<Row>({
    header: () => "Paid to Date",
    data: ({ paidInCents, isTradePartnerCredit }) =>
      priceCell({ valueInCents: isTradePartnerCredit ? -paidInCents : paidInCents }),
    w: "140px",
  });

  const balanceDueColumn = numericColumn<Row>({
    header: () => "Balance Due",
    data: ({ balanceInCents, isTradePartnerCredit }) =>
      priceCell({ valueInCents: isTradePartnerCredit ? -balanceInCents : balanceInCents }),
    w: "140px",
  });

  const paidDateColumn = dateColumn<Row>({
    header: () => "Paid Date",
    data: ({ paidDate }) => paidDate && <FormattedDate date={paidDate} />,
    w: "110px",
  });

  return [
    billNumberColumn,
    approvalsColumn,
    billDateColumn,
    dueDateColumn,
    billedColumn,
    paidToDateColumn,
    balanceDueColumn,
    paidDateColumn,
  ];
}
