import { useRef } from "react";
import { keyBy } from "src/utils";

/**
 * Returns a resorted array of objects based on an array of the objects' ids
 *
 * @param currentArray - Array of objects with an `id` property
 * @param stableArray - Original array of objects representing the initial/frozen order of the currentArray
 */
export function maybeReSortObjects<T>(
  currentArray: T[],
  stableArray: T[],
  initialSortFunction: (toSort: T[]) => T[],
  idFunction: (o: T) => string,
): T[] {
  // if the list length has changed, sort using the initialSortFunction
  if (currentArray.length !== stableArray.length) {
    return initialSortFunction(currentArray.slice());
  }
  const newObjectsById = keyBy(currentArray, idFunction);
  return stableArray.map((oldObject) => newObjectsById[idFunction(oldObject)]);
}

/**
 * Sorts a list and then remembers/freezes the original sort order, until the
 * caller re-mounts or the array length is changed.
 *
 * This provides the user with a stable order, even as they mutate attributes that are technically
 * used in the sort function. I.e. "order by selected items first, then name"...but
 * as rows are toggled selected yes/no, don't have them jump around in the UI.
 *
 * @param sortableList - a list of objects to be sorted
 * @param initialSortFunction - the function that will be used to initially sort the `sortableList`
 * @param idFunction - a function to specify what id to use (enables using a nested it)
 */
export function useReSortById<T>(
  sortableList: T[],
  initialSortFunction: (toSort: T[]) => T[],
  idFunction: (o: T) => string,
): T[] {
  const sortedList = useRef<T[] | null>(null);

  if (sortedList.current) {
    sortedList.current = maybeReSortObjects(sortableList, sortedList.current, initialSortFunction, idFunction);
  } else {
    // on refresh, sort by initial sort function
    sortedList.current = initialSortFunction(sortableList.slice());
  }
  return sortedList.current;
}
