import { Css, IconButton, IconButtonProps } from "@homebound/beam";

export type HoverDeleteProps = {
  ["data-testid"]?: string;
  visible: boolean;
  disabled?: boolean;
  onClick: () => void;
  icon?: IconButtonProps["icon"];
  compact?: boolean;
};

export function HoverDelete(props: HoverDeleteProps) {
  const { visible, disabled, onClick, compact } = props;
  return (
    <div css={Css.dn.if(!!compact).h2.if(visible).db.$}>
      <IconButton
        data-testid={props["data-testid"]}
        icon={props.icon ?? "xCircle"}
        disabled={disabled}
        onClick={onClick}
        compact={compact}
      />
    </div>
  );
}
