import { FetchResult } from "@apollo/client/link/core";
import { Button, Checkbox, Css, HasIdAndName, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useState } from "react";
import { CsvRow, CsvUploader } from "src/components/CsvUploader";
import { isCsv } from "src/components/CsvUtils";
import {
  ChangeEventLineItemsTabItemFragment,
  CostType,
  CreateChangeEventLineItemsTabMutation,
  SaveChangeEventLineItemInput,
  SpecsAndSelections_UnitOfMeasureFragment,
  useCreateChangeEventLineItemsTabMutation,
} from "src/generated/graphql-types";
import { dollarStringToCents } from "src/utils";

type ImportChangeEventLineItemsModalProps = {
  onImport: (inputs: FetchResult<CreateChangeEventLineItemsTabMutation>) => Promise<void>;
  changeEventId: string;
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[];
  locations: HasIdAndName[];
  items: ChangeEventLineItemsTabItemFragment[];
  storybookChangeEventLineItems?: Partial<SaveChangeEventLineItemInput>[];
  storybookError?: string;
};

export function ImportChangeEventLineItemsModal(props: ImportChangeEventLineItemsModalProps) {
  const { onImport, changeEventId, locations, unitsOfMeasure, items, storybookChangeEventLineItems, storybookError } =
    props;
  const { closeModal } = useModal();
  const [changeEventLineItems, setChangeEventLineItems] = useState<SaveChangeEventLineItemInput[] | null>(
    storybookChangeEventLineItems ?? null,
  );
  // We use null so that errors can be falsey
  const [errors, setErrors] = useState<string[] | null>(storybookError ? [storybookError] : null);
  // If ignoreContracted is true, dont create celi for project items with signed commitments
  const [ignoreContracted, setIgnoreContracted] = useState(false);
  const [saveChangeEventLineItems] = useCreateChangeEventLineItemsTabMutation();

  function handleError(reason: string[]) {
    setErrors([...reason]);
    setChangeEventLineItems(null);
  }

  async function handlePrimaryClick() {
    try {
      const result = await saveChangeEventLineItems({
        variables: { input: { changeEventLineItems: changeEventLineItems ?? [], ignoreContracted } },
      });
      await onImport(result);
      closeModal();
    } catch (e: any) {
      handleError([e.message]);
    }
  }

  function handleOnDrop(data: CsvRow[], file?: { type: string; name: string }) {
    // Handle when an incorrect file is dropped. Note that we cannot detect the
    // dropped filetype until after processing.
    if (!isCsv(file)) {
      return handleError(["Incorrect file type. Please make sure the file is a .csv file"]);
    }
    // Clear error and changeEventLineItem otherwise
    setErrors(null);
    setChangeEventLineItems(null);

    const { headers, indexOfHeaders, columnIndexes } = findHeaders(data);
    if (headers === undefined) {
      return handleError(["No headers founds; please check format of CSV and upload again"]);
    }
    const processedCsvRows = data.slice(indexOfHeaders + 1, data.length).map((row, index) =>
      processToChangeEventLineItemsInput(
        row,
        {
          headers,
          columnIndexes,
          unitsOfMeasure,
          locations,
          items,
          changeEventId,
        },
        index + 2, // +2 creates a row number for the user (skip the header + be 1-based)
      ),
    );
    const processingErrors = processedCsvRows.flatMap((pcr) => pcr.errors);
    const validChangeEventLineItemInputs = processedCsvRows
      .filter((pcr) => pcr.errors.length === 0)
      .map((pcr) => pcr.saveChangeEventLineItemInput);
    if (validChangeEventLineItemInputs.length === 0) {
      processingErrors.push("No valid change event line items were found");
    }
    if (processingErrors.length) {
      return handleError(processingErrors);
    }
    setChangeEventLineItems(validChangeEventLineItemInputs);
  }

  return (
    <>
      <ModalHeader>Import Change Event Line Items</ModalHeader>
      <ModalBody>
        <div>Easily import change event line items via a .csv file.</div>
        <div css={Css.mt3.$}>
          <Checkbox label="Ignore Contracted Line Items" selected={ignoreContracted} onChange={setIgnoreContracted} />
        </div>
        {!changeEventLineItems ? (
          <CsvUploader errors={errors || []} onError={(error) => handleError([error])} onDrop={handleOnDrop} />
        ) : (
          <div css={Css.mt3.$}>
            We have found {changeEventLineItems.length} line items to import! Click on `import` when you are ready!
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Import"
          disabled={!changeEventLineItems || changeEventLineItems?.length === 0}
          onClick={handlePrimaryClick}
        />
      </ModalFooter>
    </>
  );
}

/** A fuzzy mapper to get and send our costType enum over the wire */
export function fuzzilyMapCostTypes(costTypeName: string) {
  if (!costTypeName) return undefined;
  const ct = costTypeName.toUpperCase();
  return Object.values(CostType).find((e) => e === ct || e.includes(ct) || ct.includes(e)) ?? undefined;
}

export function findHeaders(rowData: CsvRow[]): {
  headers: CsvRow;
  indexOfHeaders: number;
  columnIndexes: number[];
} {
  const requiredHeaders = [
    "Item Code",
    "Cost Type",
    "Item Name",
    "Location",
    "Specifications",
    "Trade Note",
    "Internal Note",
    "Cost",
    "Units",
    "Unit of Measure",
    "Price",
  ];
  // Note that we don't really support "BP Product Id", b/c we don't have a column on SaveChangeEventLineItemInput
  // for it, but we're keeping it so maintain parity of the upload format with the ITI template upload format.
  const unrequiredHeaders = ["Unit Cost", "Blueprint Product Id", "Markup Amount", "Markup %", ""];
  const indexOfHeaders = rowData.findIndex((row) =>
    requiredHeaders.every((requiredHeader) => row.data.includes(requiredHeader)),
  );
  const csvRow = rowData[indexOfHeaders];
  const columnIndexes: number[] = [];
  if (csvRow) {
    const filteredHeaders = csvRow.data.filter((header) => !unrequiredHeaders.includes(header));
    filteredHeaders.forEach((header) => {
      columnIndexes.push(csvRow.data.indexOf(header));
    });
    csvRow.data = filteredHeaders;
  }
  return { headers: csvRow, indexOfHeaders, columnIndexes };
}

type ProcessToChangeEventLineItemInputState = {
  headers: CsvRow;
  columnIndexes: number[];
  items: ChangeEventLineItemsTabItemFragment[];
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[];
  locations: HasIdAndName[];
  changeEventId: string;
};

export function processToChangeEventLineItemsInput(
  row: CsvRow,
  { headers, columnIndexes, items, locations, unitsOfMeasure, changeEventId }: ProcessToChangeEventLineItemInputState,
  rowIndex: number,
): { saveChangeEventLineItemInput: SaveChangeEventLineItemInput; errors: string[] } {
  const errors: string[] = [...row.errors.map((e) => e.message), ...headers.errors.map((e) => e.message)];
  const input = row.data.reduce<SaveChangeEventLineItemInput>(
    (acc, cell, index) => {
      if (!columnIndexes.includes(index)) return acc;
      const header = headers.data[columnIndexes.indexOf(index)];
      try {
        const key = csvKeyToChangeEventLineItemKey(header);
        const value = csvValueToChangeEventLineItemValue(items, key, cell, unitsOfMeasure, locations, rowIndex, errors);
        // Check if the key is valid and the value is not undefined
        if (key?.length && value !== undefined) {
          return { ...acc, [key]: value };
        } else if (key !== "locationId") {
          pushError(`The value for ${header} is missed or invalid [${cell}]`, rowIndex, errors);
        }
      } catch (e) {
        pushError(`Invalid value for ${header} [${cell}]: ${e}`, rowIndex, errors);
      }

      return acc;
    },
    { changeEventId, matchExisting: true },
  );
  return { saveChangeEventLineItemInput: input, errors };
}

function pushError(errorMessage: string, index: number, errors: string[]) {
  errors.push(`[Row ${index}] ${errorMessage}`);
}

const headerToPropertyMapper = new Map<string, keyof SaveChangeEventLineItemInput>([
  ["ID", "projectItemId"],
  ["Cost", "proposedTotalCostInCents"],
  ["Cost Type", "costType"],
  ["Internal Note", "internalNote"],
  ["Item Code", "itemId"],
  ["Item Name", "name"],
  ["Location", "locationId"],
  ["Price", "proposedTotalPriceInCents"],
  ["Specifications", "specifications"],
  ["Trade Note", "tradePartnerNote"],
  ["Unit of Measure", "unitOfMeasureId"],
  ["Units", "proposedQuantity"],
]);

function csvKeyToChangeEventLineItemKey(key: string): keyof SaveChangeEventLineItemInput {
  const property = headerToPropertyMapper.get(key);
  if (property === undefined) {
    throw new Error(`Invalid header in file: '${key}'`);
  }
  return property;
}

function csvValueToChangeEventLineItemValue(
  items: ChangeEventLineItemsTabItemFragment[],
  key: keyof SaveChangeEventLineItemInput | undefined,
  value: string,
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[],
  locations: HasIdAndName[],
  index: number,
  errors: string[],
) {
  switch (key) {
    case undefined:
      return undefined;
    case "itemId":
      const itemCode = value.toLowerCase();
      const item = items.find((i) => i.fullCode === itemCode);
      if (!itemCode) {
        pushError(`Item Code is required`, index, errors);
      } else if (!item) {
        pushError(`Item with code ${itemCode} could not be found`, index, errors);
      }
      return item?.id || "";
    case "costType":
      return fuzzilyMapCostTypes(value);
    case "locationId":
      // Find location with a name that matches the location name from sage, otherwise ignore the field
      const ciLocationName = value.toLocaleLowerCase();
      const location = locations.find((l) => l.name.toLocaleLowerCase() === ciLocationName);
      return location?.id;
    case "unitOfMeasureId":
      // Find the id of a unit that matches the name coming in from sage, otherwise ignore it
      const ciName = value.toLocaleLowerCase();
      const uom = unitsOfMeasure.find(
        (u) =>
          u.sageName?.toLocaleLowerCase() === ciName ||
          u.name.toLocaleLowerCase() === ciName ||
          u.abbreviation.toLocaleLowerCase() === ciName,
      );
      return uom?.id;
    case "proposedTotalPriceInCents":
    case "proposedTotalCostInCents":
      return value === undefined || value === "" ? undefined : dollarStringToCents(value);
    case "proposedQuantity":
      return value === undefined || value === "" ? undefined : parseInt(value);
    case "name":
      return value === undefined || value === "" ? undefined : value;
    case "projectItemId":
      if (value && !value.startsWith("pi:")) {
        pushError(`Expected tagged ProjectItem ID (such as pi:2345). Instead got [${value}]`, index, errors);
        return "";
      }
      return value;
    default:
      return value;
  }
}
