import { Css, Tag, Tooltip } from "@homebound/beam";

export function SpecsAndSelectionsHeaderTag({ hasSignedContract }: { hasSignedContract: boolean | undefined }) {
  return (
    <Tooltip
      title={hasSignedContract ? "Homeowner contract has been executed" : "Homeowner contract has not been created"}
      placement="right"
    >
      {/* wrapping element required around Tag to avoid a forwardRef warning - TODO: update Tooltip in Beam */}
      <span css={Css.df.add("cursor", "default").$}>
        <Tag
          data-testid="stageStatus"
          text={hasSignedContract ? "Contracted" : "In Progress"}
          type={hasSignedContract ? "success" : "info"}
        />
      </span>
    </Tooltip>
  );
}
