import { BoundSelectField } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { BidItemSelectFieldProps } from "src/components/autoPopulateSelects/BidItemSelectField";
import { emptyCellDash } from "src/components/gridTableCells";
import { BidItemSelect_BidItemFragment, Maybe, useBidItemSelectLazyQuery } from "src/generated/graphql-types";

type BidItemBoundSelectFieldProps = Omit<BidItemSelectFieldProps, "onSelect"> & {
  field: FieldState<Maybe<string>>;
  onSelect?: (id: string | undefined, selectedBidItem: BidItemSelect_BidItemFragment | undefined) => void;
};

export function BidItemBoundSelectField(props: BidItemBoundSelectFieldProps) {
  const { field, filter, readOnly, value, onSelect, ...others } = props;
  const [load, { data }] = useBidItemSelectLazyQuery({ variables: { filter }, fetchPolicy: "cache-first" });
  return (
    <BoundSelectField
      {...others}
      field={field}
      readOnly={readOnly}
      getOptionLabel={(o) => o.displayName}
      getOptionValue={(o) => o.id}
      unsetLabel={emptyCellDash}
      // Use `as` b/c we only require a DisplayNamed current value, but pass back full BI+product fragments
      options={{ current: value as BidItemSelect_BidItemFragment, load, options: data?.potentialBidItems }}
      {...(onSelect ? { onSelect } : {})}
    />
  );
}
