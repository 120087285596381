import { DevelopmentCommitmentV2ProjectsTabProjectFragment } from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

export class DevelopmentCommitmentProject extends Selectable {
  buildAddress: DevelopmentCommitmentV2ProjectsTabProjectFragment["buildAddress"];
  name: string;
  stages: DevelopmentCommitmentV2ProjectsTabProjectFragment["stages"];
  teamMembers: DevelopmentCommitmentV2ProjectsTabProjectFragment["teamMembers"];
  fragment: DevelopmentCommitmentV2ProjectsTabProjectFragment;

  constructor(p: DevelopmentCommitmentV2ProjectsTabProjectFragment) {
    super(p.id, false);
    this.buildAddress = p.buildAddress;
    this.name = p.name;
    this.stages = p.stages;
    this.teamMembers = p.teamMembers;
    this.fragment = p;
    makeSimpleAutoObservable(this);
  }
}
