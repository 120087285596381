import { Fragment, ReactNode } from "react";
import { PotentialOperation2 } from "../../generated/graphql-types";

export function disableBasedOnPotentialOperation(potentialOperation?: PotentialOperation2): boolean | ReactNode {
  if (!potentialOperation || potentialOperation.allowed) {
    return false;
  }

  return (
    <Fragment>
      {potentialOperation.disabledReasons.map((dr, i) => (
        <div key={i}>{dr.message}</div>
      ))}
    </Fragment>
  );
}
