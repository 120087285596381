import { Button, Css, useTestIds } from "@homebound/beam";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  DocumentEditorDetailFragment,
  PandaDocButtonDataFragment,
  PandaDocStatus,
  PotentialOperation2,
} from "src/generated/graphql-types";
import { isDefined, noop } from "src/utils";

export type HasPandaDoc = {
  canCreatePandaDoc?: PotentialOperation2 | null;
  document?: DocumentEditorDetailFragment | null;
  pandaDoc?: PandaDocButtonDataFragment | null;
};

type PandaDocUploadButtonProps<P extends HasPandaDoc> = {
  onUpload: () => void;
  parent: P;
};

export function PandaDocUploadButton<P extends HasPandaDoc>({ onUpload, parent }: PandaDocUploadButtonProps<P>) {
  const testIds = useTestIds({}, "pdub");
  const canUpload = parent.canCreatePandaDoc?.allowed;
  const isUploadInProgress = canUpload && parent.pandaDoc?.status === PandaDocStatus.Pending;
  const asset = parent.document?.asset;
  const documentUrl = asset?.downloadUrl;

  /**
   * Users can Trigger an Upload to PandaDoc, Go To (View) Panda Doc, or if the
   * document was uploaded manually and has no associated PandaDoc, view the
   * document in a new tab.
   */
  const wasManuallyUploaded = asset && documentUrl && !isDefined(parent.pandaDoc);
  if (wasManuallyUploaded)
    return (
      <AssetGallery assets={[asset]}>
        {(openGallery) => <Button label="View PDF" onClick={() => openGallery(asset)} />}
      </AssetGallery>
    );

  const didUploadFail = parent.pandaDoc?.status === PandaDocStatus.UploadFailed;
  if (didUploadFail)
    return (
      <Button
        label="Upload Failed - Retry?"
        tooltip={parent.pandaDoc?.externalError}
        disabled={maybeCantUploadReason(parent)}
        onClick={canUpload ? onUpload : noop}
        variant="danger"
        {...testIds.button}
      />
    );

  const uploadUrl = parent.pandaDoc?.externalPandaDocUrl;
  if (uploadUrl) return <Button label={"View in PandaDoc"} onClick={uploadUrl} {...testIds.button} />;

  return (
    <Button
      label="Upload to PandaDoc"
      onClick={canUpload ? onUpload : noop}
      disabled={isUploadInProgress || maybeCantUploadReason(parent)}
      tooltip={isUploadInProgress ? <span {...testIds.tooltip}>upload in progress</span> : null}
      {...testIds.button}
    />
  );
}

const maybeCantUploadReason = (parent: HasPandaDoc) =>
  !parent.canCreatePandaDoc?.allowed && (
    <ul css={Css.m0.pl2.$}>
      {parent.canCreatePandaDoc?.disabledReasons.map((dr) => <li key={dr.message}>{dr.message}</li>)}
    </ul>
  );
