import { Only, Properties } from "@homebound/beam";
import { calculateMarkup } from "src/utils";
import { PricePercentage, PricePercentageProps } from ".";

type MarkupSummaryXss = Pick<Properties, "textAlign">;

type MarkupSummaryProps<X> = Omit<PricePercentageProps<X>, "percentage" | "amountInCents"> & {
  budget: number;
  price: number | undefined;
};

/** Given budget & price, calcs the markup and shows `$price (markup%)`. */
export function MarkupSummary<X extends Only<MarkupSummaryXss, X>>(props: MarkupSummaryProps<X>) {
  const { id = "markupSummary", budget, price, ...others } = props;
  const { markupAmount, markupPercentage } = calculateMarkup(budget, price);
  return (
    <PricePercentage
      // Allow `amountInCents` to maintain `undefined` if price really is undefined. This ensures we can display an empty node if applicable.
      amountInCents={price === undefined ? price : markupAmount}
      percentage={markupPercentage}
      {...{ id, ...others }}
    />
  );
}
