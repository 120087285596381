import { ChipType, TagType } from "@homebound/beam";
import { isAfter } from "date-fns";
import {
  ApprovalStatus,
  ApprovalsDashboard_ApprovalFragment,
  ApproverDetailFragment,
  ApproverStatus,
  ChangeEventStatus,
  ChangeEventType,
  CommitmentStatus,
  ContractType,
  CostType,
  EstimateStatus,
  HomeownerSelectionStatus,
  ItemTemplateStatus,
  ProductStatus,
  ProjectStatus,
  ScheduleTemplateStatus,
  Stage,
  TaskDeadlineType,
  TaskDependencyType,
  TimeFrequency,
  TradePartnerOnboardingStatus,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { fail, foldEnum } from "./utils";

export const stageCodeToNameMapper: Record<Stage, string> = {
  [Stage.PreConPlanning]: "Planning",
  [Stage.PreConExecution]: "Pre-Con",
  [Stage.Construction]: "Construction",
  [Stage.Development]: "Development",
};

export const contractTypeToNameMapper: Record<ContractType, string> = {
  [ContractType.Fixed]: "Fixed",
  [ContractType.BudgetReallocation]: "Budget Reallocation",
  [ContractType.CostPlus]: "Cost Plus",
};

/** Stage slugs used for URLs, i.e. `https://...schedule/planning`, etc. */
export type StageUrlSlug = "planning" | "pre-con" | "construction" | "dev";

export const stageSlugToStageCode: Record<StageUrlSlug, Stage> = {
  planning: Stage.PreConPlanning,
  "pre-con": Stage.PreConExecution,
  construction: Stage.Construction,
  dev: Stage.Development,
};

/** Returns the `Stage` for `slug` or `undefined` is the stage is incorrect. */
export function getStageFromSlug(slug: StageUrlSlug): Stage {
  return stageSlugToStageCode[slug] || fail(`Attempting to convert invalid stage slug (${slug}) to stage.`);
}

export function getSlugFromStage(stage: Stage): StageUrlSlug {
  return stageCodeToNameMapper[stage].toLowerCase() as StageUrlSlug;
}

export const itemTemplateStatusToNameMapper: Record<ItemTemplateStatus, string> = {
  [ItemTemplateStatus.Active]: "Active",
  [ItemTemplateStatus.Archived]: "Archived",
  [ItemTemplateStatus.Draft]: "Draft",
  [ItemTemplateStatus.SystemDraft]: "Active",
};

export const costTypeToNameMapper: Record<CostType, string> = {
  [CostType.Labor]: "Labor",
  [CostType.Materials]: "Materials",
  [CostType.Subcontractor]: "Subcontractor",
  [CostType.Equipment]: "Equipment",
  [CostType.Other]: "Other",
};

export const selectionStatusToNameMapper: Record<HomeownerSelectionStatus, string> = {
  [HomeownerSelectionStatus.Declined]: "Declined",
  [HomeownerSelectionStatus.Finalized]: "Finalized",
  [HomeownerSelectionStatus.Selected]: "Selected",
  [HomeownerSelectionStatus.Undecided]: "Undecided",
};

export const projectStatusDetails: Record<ProjectStatus, string> = {
  [ProjectStatus.Active]: "Active",
  [ProjectStatus.Completed]: "Construction Complete",
  [ProjectStatus.Hold]: "Hold",
  [ProjectStatus.Closed]: "Disqualified",
  [ProjectStatus.UnderWarranty]: "Under Warranty",
};

export const changeEventStatusToNameMapper: Record<ChangeEventStatus, string> = {
  [ChangeEventStatus.Draft]: "Draft",
  [ChangeEventStatus.Proposed]: "Proposed",
  [ChangeEventStatus.PendingSignature]: "Unsigned",
  [ChangeEventStatus.Requested]: "Requested",
  [ChangeEventStatus.Accepted]: "Accepted",
  [ChangeEventStatus.Rejected]: "Rejected",
  [ChangeEventStatus.Void]: "Void",
};

export const commitmentStatusToTagTypeMapper: Record<CommitmentStatus, TagType> = {
  [CommitmentStatus.Draft]: "info",
  [CommitmentStatus.Uploaded]: "caution",
  [CommitmentStatus.Sent]: "neutral",
  [CommitmentStatus.PartiallySigned]: "caution",
  [CommitmentStatus.Signed]: "success",
  [CommitmentStatus.Voided]: "warning",
};

export function commitmentStatusTagTypeMapper(status: CommitmentStatus, statusText: string | undefined): TagType {
  if (statusText === "Pending Approval") return "caution";

  return foldEnum(status, {
    [CommitmentStatus.Draft]: "info",
    [CommitmentStatus.Uploaded]: "caution",
    [CommitmentStatus.Sent]: "neutral",
    [CommitmentStatus.PartiallySigned]: "caution",
    [CommitmentStatus.Signed]: "success",
    [CommitmentStatus.Voided]: "warning",
  });
}

export const commitmentStatusToDevelopmentTagTypeMapper: Record<CommitmentStatus, TagType> = {
  [CommitmentStatus.Draft]: "info",
  [CommitmentStatus.Uploaded]: "warning", // commitments generated by a development should never be in this state
  [CommitmentStatus.Sent]: "warning", // commitments generated by a development should never be in this state
  [CommitmentStatus.PartiallySigned]: "warning", // commitments generated by a development should never be in this state
  [CommitmentStatus.Signed]: "caution",
  [CommitmentStatus.Voided]: "neutral",
};

export const changeEventStatusToTagTypeMapper: Record<ChangeEventStatus, TagType> = {
  [ChangeEventStatus.Draft]: "info",
  [ChangeEventStatus.Proposed]: "caution",
  [ChangeEventStatus.PendingSignature]: "caution",
  [ChangeEventStatus.Requested]: "caution",
  [ChangeEventStatus.Accepted]: "success",
  [ChangeEventStatus.Rejected]: "neutral",
  [ChangeEventStatus.Void]: "warning",
};

export const estimateStatusToTagTypeMapper: Record<EstimateStatus, TagType> = {
  [EstimateStatus.Accepted]: "success",
  [EstimateStatus.Draft]: "info",
  [EstimateStatus.Rejected]: "neutral",
};

export const taskDeadlineTypeToNameMapper: Record<TaskDeadlineType, string> = {
  [TaskDeadlineType.Cutoff]: "Cutoff",
  [TaskDeadlineType.KeyMilestone]: "Key Milestone",
};

export const scheduleStatusToTagType: Record<ScheduleTemplateStatus, TagType> = {
  [ScheduleTemplateStatus.Published]: "success",
  [ScheduleTemplateStatus.Draft]: "info",
  [ScheduleTemplateStatus.Archived]: "neutral",
};

export const tradePartnerOnboardingStatusToNameMapper: Record<TradePartnerOnboardingStatus, string> = {
  [TradePartnerOnboardingStatus.InProgress]: "In Progress",
  [TradePartnerOnboardingStatus.Complete]: "Complete",
  [TradePartnerOnboardingStatus.DoNotUse]: "Do Not Use",
  [TradePartnerOnboardingStatus.Inactive]: "Inactive",
};

export const taskDependencyTypeToAbbreviationMapper: Record<TaskDependencyType, string> = {
  [TaskDependencyType.FinishFinish]: "FF",
  [TaskDependencyType.FinishStart]: "FS",
  [TaskDependencyType.StartFinish]: "SF",
  [TaskDependencyType.StartStart]: "SS",
};

export const productStatusToTagTypeMapper: Record<ProductStatus, TagType> = {
  [ProductStatus.Active]: "success",
  [ProductStatus.Archived]: "caution",
  [ProductStatus.Draft]: "info",
};

export const timeFrequencyToNameMapper: Record<TimeFrequency, string> = {
  [TimeFrequency.Days]: "Days",
  [TimeFrequency.Weeks]: "Weeks",
  [TimeFrequency.Months]: "Months",
};

export function changeEventTypeToNameMapper(type: ChangeEventType) {
  return foldEnum(type, {
    [ChangeEventType.External]: "Chargeable",
    [ChangeEventType.Internal]: "Non Chargeable",
    [ChangeEventType.BudgetReallocation]: "Budget Reallocation",
  });
}

export function approvalIsOverdue(approval: Pick<ApprovalsDashboard_ApprovalFragment, "dueOn" | "status">) {
  return (
    approval.dueOn && isAfter(new Date(), approval.dueOn?.date) && approval.status.code === ApprovalStatus.Requested
  );
}

export type TagTypeMapper = [type: TagType, label: string];

export function approvalStatusToTagTypeMapper(
  approval: Pick<ApprovalsDashboard_ApprovalFragment, "dueOn" | "status">,
): TagTypeMapper {
  if (
    /** `Overdue` approval status doesn't exist on the BE.
     * Calc the logic for when to display the overdue status here */
    approvalIsOverdue(approval)
  ) {
    return ["warning", "OVERDUE"];
  }

  return foldEnum(approval.status.code, {
    [ApprovalStatus.ChangesNeeded]: ["caution", approval.status.name],
    [ApprovalStatus.Requested]: ["info", approval.status.name],
    [ApprovalStatus.Approved]: ["success", approval.status.name],
    [ApprovalStatus.Rejected]: ["neutral", approval.status.name],
  });
}

export function approverChipTypeMapper(
  status: ApproverDetailFragment["status"],
  requireSignature: ApproverDetailFragment["requireSignature"] = false,
): [type: ChipType, label: string] {
  return foldEnum(status.code, {
    [ApproverStatus.ActionRequired]: ["info", `Awaiting ${requireSignature ? "signature" : "approval"}`],
    [ApproverStatus.Pending]: ["info", "Awaiting approval"],
    [ApproverStatus.ChangesRequested]: ["caution", status.name],
    [ApproverStatus.Approved]: ["success", status.name],
    [ApproverStatus.Rejected]: ["warning", status.name],
  });
}

export const tradePartnerStatusTypeToNameMapper: Record<TradePartnerTaskStatus, string> = {
  [TradePartnerTaskStatus.NeedsConfirmation]: "Needs Confirmation",
  [TradePartnerTaskStatus.Confirmed]: "Confirmed",
  [TradePartnerTaskStatus.NeedsReconfirmation]: "Needs Reconfirmation",
  [TradePartnerTaskStatus.Unavailable]: "Unavailable",
  [TradePartnerTaskStatus.CompletedJob]: "Completed Job",
};
