import {
  Button,
  Css,
  GridColumn,
  GridTable,
  IconButton,
  ModalProps,
  Palette,
  actionColumn,
  column,
  emptyCell,
  simpleDataRows,
  useModal,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import {
  BusinessFunctionAssetsQuery,
  BusinessFunctionAssets_ChangeRequestAssetFragment,
  BusinessFunctionType,
  Order,
  useBusinessFunctionAssetsQuery,
} from "src/generated/graphql-types";
import { capitalize, queryResult } from "src/utils";
import { BusinessFunctionAssetsModal } from "./BusinessFunctionAssetsModal";

export type BusinessFunctionDetails = { code: BusinessFunctionType; name: string };
export type BusinessFunctionEnums = BusinessFunctionAssetsQuery["enumDetails"];

export function BusinessFunctionAssets({ businessFunctionType }: { businessFunctionType: BusinessFunctionDetails }) {
  const query = useBusinessFunctionAssetsQuery({
    variables: {
      businessFunctionType: businessFunctionType.code,
      page: {
        offset: 0,
        limit: 100,
      },
      orderBy: { name: Order.Asc },
    },
  });

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.changeRequestAssets.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data.changeRequestAssets.entities.length,
            limit: 100,
          },
          orderBy: { name: Order.Asc },
        },
      });
    }
  }, [query]);

  return queryResult(query, (result) => {
    const {
      enumDetails,
      changeRequestAssets: { entities: changeRequestAssets },
    } = result;

    return (
      <BusinessFunctionAssetsContent
        changeRequestAssets={changeRequestAssets}
        enums={enumDetails}
        businessFunctionType={businessFunctionType}
        maybeFetchMore={maybeFetchMore}
      />
    );
  });
}

type BusinessFunctionAssetsContentProps = {
  changeRequestAssets: BusinessFunctionAssets_ChangeRequestAssetFragment[];
  enums: BusinessFunctionEnums;
  businessFunctionType: BusinessFunctionDetails;
  maybeFetchMore: () => Promise<void>;
};

export function BusinessFunctionAssetsContent({
  changeRequestAssets,
  enums,
  businessFunctionType,
  maybeFetchMore,
}: BusinessFunctionAssetsContentProps) {
  const { openModal } = useModal();

  const columns = useMemo(
    () => createColumns(openModal, enums, businessFunctionType),
    [enums, openModal, businessFunctionType],
  );

  const rows = useMemo(() => createRows(changeRequestAssets), [changeRequestAssets]);

  return (
    <div css={Css.br8.bgWhite.bshBasic.p3.m3.$}>
      <div css={Css.df.jcsb.$}>
        <h2 css={Css.baseMd.mb2.$}>{`${businessFunctionType.name} Assets*`}</h2>
        <Button
          label="Add New Asset"
          onClick={() =>
            openModal({
              content: <BusinessFunctionAssetsModal enums={enums} businessFunctionType={businessFunctionType} />,
            })
          }
          variant="secondary"
        />
      </div>
      <GridTable
        rows={rows}
        columns={columns}
        infiniteScroll={{ onEndReached: maybeFetchMore }}
        fallbackMessage="No assets found"
        style={{
          cellCss: Css.bgWhite.smMd.mt1.aic.$,
          rowHoverColor: Palette.White,
          headerCellCss: Css.sm.gray700.$,
          emptyCell: "--",
          firstRowMessageCss: Css.mt1.$,
        }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: BusinessFunctionAssets_ChangeRequestAssetFragment };

type Row = HeaderRow | DataRow;

function createRows(data: BusinessFunctionAssets_ChangeRequestAssetFragment[]) {
  return simpleDataRows(data);
}

function createColumns(
  openModal: (props: ModalProps) => void,
  enums: BusinessFunctionEnums,
  businessFunctionType: BusinessFunctionDetails,
): GridColumn<Row>[] {
  return [
    column<Row>({ header: "Asset Name*", data: ({ name }) => name }),
    column<Row>({
      header: () => ({
        content: () => (
          <div css={Css.df.gap1.$}>
            <span>Applicable Scope*</span>
          </div>
        ),
        tooltip: "The list of scopes at which this asset may be affected.",
      }),
      data: ({ scopes }) => scopes.map((s) => capitalize(s)).join(", "),
    }),
    column<Row>({
      header: () => ({
        content: () => (
          <div css={Css.df.gap1.$}>
            <span>Implementer*</span>
          </div>
        ),
        tooltip: "The person responsible for implementing a particular change to an asset.",
      }),
      data: ({ implementer }) => implementer.name,
    }),
    column<Row>({
      header: () => ({
        content: () => (
          <div css={Css.df.w("min-content").pr1.$}>
            <span>Implementation Reviewer*</span>
          </div>
        ),
        tooltip: "The person responsible for ensuring that the change has been implemented accurately.",
      }),
      data: ({ implementationReviewer }) => implementationReviewer?.name,
    }),
    column<Row>({
      header: () => ({
        content: () => (
          <div css={Css.df.gap1.$}>
            <span>Platform*</span>
          </div>
        ),
        tooltip: "The platform where this asset resides",
        css: Css.asc.$,
      }),
      data: ({ platform }) => platform.name,
    }),
    actionColumn<Row>({
      header: emptyCell,
      data: (row) => (
        <div css={Css.df.jcfe.w100.mr3.$}>
          <IconButton
            icon="pencil"
            data-testid="editAsset"
            onClick={() =>
              openModal({
                content: (
                  <BusinessFunctionAssetsModal enums={enums} row={row} businessFunctionType={businessFunctionType} />
                ),
              })
            }
          />
        </div>
      ),
    }),
  ];
}
