import {
  column,
  dateColumn,
  GridColumn,
  GridTable,
  numericColumn,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import startCase from "lodash/startCase";
import { useParams } from "react-router";
import { chipCell, dateCell, linkHeader, priceCell, tagCell } from "src/components";
import { CommitmentsForChangeEventFragment, useCommitmentsForChangeEventQuery } from "src/generated/graphql-types";
import { ChangeEventParams } from "src/routes/routesDef";
import { commitmentStatusToTagTypeMapper, queryResult } from "src/utils";

export function ChangeEventCommitmentsTab() {
  const { changeEventId } = useParams<ChangeEventParams>();
  const query = useCommitmentsForChangeEventQuery({ variables: { changeEventId } });

  return queryResult(query, (data) => <CommitmentsForChangeEventsTable commitments={data.changeEvent.commitments} />);
}

type CommitmentsForChangeEventsTableProps = {
  commitments: CommitmentsForChangeEventFragment[];
};

function CommitmentsForChangeEventsTable({ commitments }: CommitmentsForChangeEventsTableProps) {
  return (
    <ScrollableContent>
      <GridTable
        columns={columns}
        rows={simpleDataRows(commitments)}
        fallbackMessage="This Change Event has not yet generated any Purchase Orders"
        sorting={{ on: "client" }}
        stickyHeader
        style={{ bordered: true, allWhite: true }}
      />
    </ScrollableContent>
  );
}

type Row = SimpleHeaderAndData<CommitmentsForChangeEventFragment>;
const columns: GridColumn<Row>[] = [
  column<Row>({
    header: "PO #",
    data: ({ accountingNumber, blueprintUrl }) => linkHeader(String(accountingNumber), blueprintUrl.path),
    w: "125px",
  }),
  column<Row>({
    header: "Trade Partner",
    data: ({ tradePartner }) => tradePartner?.name,
  }),
  column<Row>({
    header: "Cost Codes",
    data: ({ lineItems }) => chipCell(lineItems.map(({ costCode }) => costCode.number)),
    clientSideSort: false,
    w: 2,
  }),
  column<Row>({
    header: "Type",
    data: ({ __typename, commitmentType }) => `${startCase(__typename)} - ${commitmentType.name}`,
    w: 2,
  }),
  numericColumn<Row>({
    header: "Total Committed",
    data: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
  }),
  dateColumn<Row>({
    header: "Last Edited",
    data: ({ updatedAt }) => dateCell(updatedAt),
  }),
  dateColumn<Row>({
    header: "Signed on",
    data: ({ executionDate }) => dateCell(executionDate),
  }),
  column<Row>({
    header: "Status",
    data: ({ status, statusText }) => tagCell(commitmentStatusToTagTypeMapper[status], statusText),
    w: "125px",
  }),
];
