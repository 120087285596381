import { useToast } from "@homebound/beam";
import { isEqual } from "date-fns";
import {
  LotType,
  Project,
  ScheduleStatus,
  Stage,
  useScheduleParentGlobalMilestoneTasksQuery,
} from "src/generated/graphql-types";

export type ScheduleOutOfSyncToastProps = {
  scheduleParentId: string;
  scheduleStatus: ScheduleStatus;
  stage: Stage;
};

// This component is only rendered on Construction schedules
export function ScheduleOutOfSyncToast({ scheduleParentId, scheduleStatus, stage }: ScheduleOutOfSyncToastProps) {
  const { showToast } = useToast();
  useScheduleParentGlobalMilestoneTasksQuery({
    variables: { scheduleParent: scheduleParentId },
    // only run this query on construction schedules
    skip: stage !== Stage.Construction,
    onCompleted: ({ scheduleTasks, schedules }) => {
      const beginningMilestone = scheduleTasks.find((t) => t.globalTask?.beginsStage === Stage.Construction);
      const startDeterminingMilestone = scheduleTasks.find(
        (t) => t.globalTask?.determinesStartDateForStage === Stage.Construction,
      );
      const preConSchedule = schedules.find((s) => s.stage === Stage.PreConExecution);
      if (!beginningMilestone || !startDeterminingMilestone || !preConSchedule) {
        return false;
      }
      /**
       * Show a toast for out of sync milestones on
       * - Draft Construction schedules
       * - Active Construction schedules for Boyl lot types
       */
      if (
        (scheduleStatus === ScheduleStatus.Draft ||
          (scheduleStatus === ScheduleStatus.Active &&
            (preConSchedule.parent as Project).lotType?.code === LotType.Boyl)) &&
        !isEqual(beginningMilestone.interval.startDate, startDeterminingMilestone.interval.endDate)
      ) {
        showToast({
          type: "info",
          message:
            "Pre-Con and Construction Ready To Start milestones are un-synced. Move the pre-con RTS to the same or later date as the construction RTS to re-sync.",
        });
      }
    },
  });
  return <></>;
}
