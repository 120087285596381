import { DesignUpgradeIcons, IconName } from "../designPackageIconUtils";

type DesignUpgradeIconProps = {
  iconName: IconName;
  size: number;
  color: string;
};

export function DesignUpgradeIcon({ iconName, size, color }: DesignUpgradeIconProps) {
  const Icon = DesignUpgradeIcons[iconName];
  return !Icon ? null : <Icon size={size} color={color} />;
}
