import { Css } from "@homebound/beam";
import { useEffect, useRef, useState } from "react";
import { DashboardCommentsWidget } from "./DashboardCommentsWidget";
import { ProjectsWidget } from "./ProjectsWidget";

export function PersonalDashboardRightPane() {
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [isProjectsExpanded, setIsProjectsExpanded] = useState(false);
  const widgetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (widgetRef.current && isCommentsExpanded) {
      setTimeout(() => widgetRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }));
    }
  }, [isCommentsExpanded]);

  const commentsWidgetHeight = isCommentsExpanded ? "100%" : "50%";
  const projectWidgetHeight = isProjectsExpanded ? "100%" : "calc(50% - 24px)";
  return (
    <div css={Css.df.fdc.gap3.h100.oya.$}>
      <div css={Css.fg1.fs0.fb(projectWidgetHeight).maxh(projectWidgetHeight).$}>
        <ProjectsWidget isExpanded={isProjectsExpanded} onExpand={(expanded) => setIsProjectsExpanded(expanded)} />
      </div>
      <div ref={widgetRef} css={Css.fg1.fs0.fb(commentsWidgetHeight).$}>
        <DashboardCommentsWidget
          isExpanded={isCommentsExpanded}
          onExpand={(expanded) => setIsCommentsExpanded(expanded)}
        />
      </div>
    </div>
  );
}
