import { IObservableArray, observable } from "mobx";
import {
  SpecsAndSelections_ItemTemplateItemFragment,
  SpecsAndSelections_ProjectItemFragment,
} from "src/generated/graphql-types";
import { observableAggregate } from "src/models/observableAggregate";
import { ObservableProjectItem } from "src/routes/projects/models/ObservableProjectItem";
import { SpecsAndSelectionsRollup } from "src/routes/projects/models/SpecsAndSelectionsRollup";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

export class ProjectItemStore extends SpecsAndSelectionsRollup<ObservableProjectItemGroup> {
  // Our base class children is divisions, but keep a list of our leaf items as well
  private readonly items: IObservableArray<ObservableProjectItem>;

  constructor(
    projectItems: SpecsAndSelections_ProjectItemFragment[],
    groupBy: "costCode" | "tradeCategory" | "cutoff",
  ) {
    const items = observable(projectItems.map((pi) => new ObservableProjectItem(pi)));
    let children: ObservableProjectItemGroup[];
    if (groupBy === "costCode") {
      children = observableAggregate(items, (pi) => pi.fragment.item.costCode.id, ObservableCostCodeGroup, {
        sortChildrenBy: (pi) => pi.fragment.item.itemCode,
      }) as any;
    } else if (groupBy === "cutoff") {
      children = observableAggregate(
        items,
        (pi) => pi.fragment.homeownerSelection?.projectCutoff?.id || "unassigned",
        ObservableCutoffGroup,
        {
          sortChildrenBy: (pi) => pi.fragment.item.itemCode,
        },
      ) as any;
    } else {
      children = observableAggregate(
        items,
        (pi) => pi.fragment.item.tradeCategory?.id || "unassigned",
        ObservableTradeCategoryGroup,
        { sortChildrenBy: (pi) => pi.fragment.item.itemCode },
      ) as any;
    }
    super("total", "Total", children);
    makeSimpleAutoObservable(this);
    this.items = items;
  }

  addProjectItems(projectItems: SpecsAndSelections_ProjectItemFragment[]) {
    projectItems.forEach((pi) => {
      const existing = this.items.find((i) => i.id === pi.id);
      if (existing) {
        existing.update(pi);
      } else {
        this.items.push(new ObservableProjectItem(pi));
      }
    });
  }

  removeProjectItems(projectItemIds: string[]) {
    this.items.filter((i) => projectItemIds.includes(i.id)).forEach((i) => this.items.remove(i));
  }

  clearSelections() {
    this.unselect();
  }

  get groupedBy() {
    return this.children;
  }

  get projectItems() {
    return this.items;
  }

  get selectedProjectItems() {
    return this.projectItems.filter((item) => item.selected);
  }

  get disableProjectMarkup() {
    return this.projectItems.some((pi) => pi.disableMarkup);
  }
}

// The below row types will be used for both Specs and Selections tables, so storing them here as a shared spot.
type HeaderRow = { kind: "header"; id: string; data: undefined };
type TotalsRow = { kind: "totals"; data: ProjectItemStore; id: string };
type GroupedByRow = { kind: "group"; data: SpecsAndSelectionsRollup<any>; id: string; sortValue?: string };
type ProjectItemRow = { kind: "projectItem"; data: ObservableProjectItem; id: string };
type LineItemRow = { kind: "lineItem"; data: SpecsAndSelections_ItemTemplateItemFragment; id: string };
export type SpecsAndSelectionsRow = HeaderRow | TotalsRow | GroupedByRow | ProjectItemRow | LineItemRow;

type ObservableProjectItemGroup = ObservableTradeCategoryGroup | ObservableCostCodeGroup | ObservableCutoffGroup;

class ObservableCostCodeGroup extends SpecsAndSelectionsRollup<ObservableProjectItem> {
  sortValue?: string;

  constructor(projectItems: ObservableProjectItem[]) {
    const cc = projectItems[0].fragment.item.costCode;
    super(cc.id, cc.name, projectItems);
    this.sortValue = cc.name;
    makeSimpleAutoObservable(this);
  }
}

class ObservableTradeCategoryGroup extends SpecsAndSelectionsRollup<ObservableProjectItem> {
  sortValue?: string;

  constructor(projectItems: ObservableProjectItem[]) {
    const tc = projectItems[0].fragment.item.tradeCategory;
    super(tc?.id || "tc:0", tc?.name || "Unassigned", projectItems);
    this.sortValue = tc?.name;
    makeSimpleAutoObservable(this);
  }
}

class ObservableCutoffGroup extends SpecsAndSelectionsRollup<ObservableProjectItem> {
  sortValue?: string;

  constructor(projectItems: ObservableProjectItem[]) {
    const projectCutoff = projectItems[0].fragment.homeownerSelection?.projectCutoff;
    super(
      projectCutoff?.id || "projectCutoff:0",
      projectCutoff?.name || projectCutoff?.cutoff?.name || "No Cutoff Assigned",
      projectItems,
    );
    // unassigned cutoff group will be first by default as id = projectCutoff:0
    this.sortValue = projectCutoff?.id;
    makeSimpleAutoObservable(this);
  }
}
