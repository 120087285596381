import { useSuperDrawer } from "@homebound/beam";
import { Redirect, useHistory } from "react-router";
import {
  ProjectItemInput,
  Stage,
  useDeleteProjectItemMutation,
  useSaveProjectItemDetailsMutation,
  useSpecTabQuery,
} from "src/generated/graphql-types";
import { ProjectItemDetailsView } from "src/routes/projects/selections/ProjectItemDetailsView";
import { createProjectDashboardUrl, createProjectSelectionsUrl } from "src/RouteUrls";
import { doInNextLoop } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { BooleanParam, useQueryParams } from "use-query-params";

type SpecTabProps = {
  projectId: string;
  projectItemId: string;
  stage: Stage;
};

export function SpecTab(props: SpecTabProps) {
  const { projectId, projectItemId, stage } = props;
  const query = useSpecTabQuery({ variables: { projectItemId } });
  const [{ edit }, setQueryParams] = useQueryParams({ edit: BooleanParam });
  const [saveProjectItem] = useSaveProjectItemDetailsMutation();
  const [deleteProjectItem] = useDeleteProjectItemMutation();
  const history = useHistory();
  const { closeDrawer } = useSuperDrawer();

  // props.location.state is not available if link is visited directly, so we default to false in that case

  const allSelectionsUrl = createProjectSelectionsUrl(projectId, stage);

  async function onSave(input: ProjectItemInput) {
    await saveProjectItem({ variables: { input } });
    doInNextLoop(() => setQueryParams({ edit: false }));
  }

  async function onDelete() {
    await deleteProjectItem({
      variables: { input: { id: projectItemId } },
      refetchQueries: ["SpecsAndSelectionItems"],
    });
    history.push(allSelectionsUrl);
    closeDrawer();
  }

  const onCancel = () => setQueryParams({}, "push");
  const onEdit = () => setQueryParams({ edit: true });

  return queryResult(query, {
    data: (data) => {
      const { projectItem, unitsOfMeasure, costConfidences } = data;
      const {
        canEditProjectItemsDirectly,
        project: { id: queriedProjectId },
      } = projectItem.projectStage;

      if (queriedProjectId !== projectId) {
        return <Redirect to={createProjectDashboardUrl(projectId)} />;
      }

      // If we have a final estimate or contract, lock the project item details editor
      const mode = !canEditProjectItemsDirectly ? "locked" : edit ? "update" : "read";

      return (
        <ProjectItemDetailsView
          projectItem={projectItem}
          unitsOfMeasure={unitsOfMeasure}
          costConfidences={costConfidences || []}
          mode={mode}
          onCancel={onCancel}
          onEdit={onEdit}
          onSave={onSave}
          onDelete={onDelete}
        />
      );
    },
  });
}
