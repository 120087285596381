import { ProgramDataLike } from "src/generated/graphql-types";

export type ProgramDataNumerics = Omit<
  ProgramDataLike,
  "createdAt" | "updatedAt" | "garageConfiguration" | "basementConfig" | "windowColor" | "primaryBedroom"
>;

export function programDataRange(min: ProgramDataNumerics, max: ProgramDataNumerics, key: keyof ProgramDataNumerics) {
  return min?.[key] === max?.[key] ? min?.[key] : `${min?.[key]} - ${max?.[key]}`;
}

// calculate the bath count of full and half baths by combining them
export function programDataBathRange(min: ProgramDataNumerics, max: ProgramDataNumerics) {
  return min?.fullBaths === max.fullBaths && min?.halfBaths === max.halfBaths
    ? (min?.fullBaths || 0) + (min?.halfBaths || 0) * 0.5
    : `${(min?.fullBaths || 0) + (min?.halfBaths || 0) * 0.5} - ${(max.fullBaths || 0) + (max.halfBaths || 0) * 0.5}`;
}
