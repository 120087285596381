import { Css, Only, Properties } from "@homebound/beam";
import {
  EstimateDetailExportFragment,
  SpecsAndSelections_ProjectItemFragment,
  useEstimateExportPageQuery,
} from "src/generated/graphql-types";
import { overlayEstimateLineItemOnProjectItem } from "src/routes/projects/estimates/EstimateLineItemsTab";
import { groupBy, queryResult, safeEntries, sum } from "src/utils";
import { StringParam, useQueryParam } from "use-query-params";

export function EstimateExportPdf() {
  const [estimate] = useQueryParam("estimate", StringParam);

  const query = useEstimateExportPageQuery({ variables: { estimateId: estimate! } });

  return queryResult(query, {
    data: (data) => (
      <EstimateExportPageData
        projectItems={data.projectEstimate.lineItems.map(overlayEstimateLineItemOnProjectItem)}
        estimate={data.projectEstimate}
      />
    ),
  });
}

type EstimateExportPageDataProps = {
  projectItems: SpecsAndSelections_ProjectItemFragment[];
  estimate: EstimateDetailExportFragment;
};

function EstimateExportPageData({ projectItems, estimate }: EstimateExportPageDataProps) {
  // Q: Ordering of the divisions?
  const pisByDivision = groupBy(projectItems, (pi) => pi.item.costCode.division.name);

  return (
    <div css={Css.w100.$}>
      <div css={Css.dg.gtc("2fr 1fr").base.fwb.bb.add("borderBottomWidth", "2px").bcGray800.py1.$}>
        <div>Category</div>
        <div css={Css.tar.$}>Price</div>
      </div>
      {safeEntries(pisByDivision).map(([divisionName, projectItems], index) => (
        <DivisionSummary
          key={projectItems[0].item.costCode.division.id}
          index={index}
          divisionName={divisionName}
          projectItems={projectItems}
        />
      ))}
      <SummaryRow xss={Css.bt.bcGray800.$} label="Subtotal" valueInCents={estimate.totalCostInCents} />
      <SummaryRow label="Homebound Overhead & Fee" valueInCents={estimate.totalMarkupInCents} />
      <SummaryRow
        xss={Css.bt.bcGray800.add("borderTopWidth", "2px").base.$}
        label="Total Construction Estimate"
        valueInCents={estimate.totalPriceInCents}
      />
    </div>
  );
}

type DivisionSummaryProps = {
  index: number;
  divisionName: string;
  projectItems: SpecsAndSelections_ProjectItemFragment[];
};

function DivisionSummary(props: DivisionSummaryProps) {
  const { divisionName, projectItems, index: divisionIndex } = props;
  const pisByCostCode = safeEntries(groupBy(projectItems, (pi) => pi.item.costCode.name));

  return (
    <>
      {pisByCostCode.map(([costCodeName, pis], costCodeIndex) => (
        <div
          key={pis[0].item.costCode.id}
          css={
            Css.dg.py1
              .gtc("1fr 1fr 1fr")
              .if(costCodeIndex === 0 && divisionIndex !== 0)
              .add("borderTopStyle", "dotted")
              .add("borderTopWidth", "1px").bcGray800.$
          }
        >
          <div css={Css.sm.fwb.$}>{costCodeIndex === 0 ? divisionName : ""}</div>
          <div>{pisByCostCode.length > 1 ? costCodeName : ""}</div>
          <div css={Css.tar.$}>{price(pis.map((pi) => pi.totalCostInCents).reduce(sum, 0))}</div>
        </div>
      ))}
    </>
  );
}

type SummaryRowXss = Pick<Properties, "borderTopStyle" | "borderTopWidth" | "fontSize">;

type SummaryRowProps = {
  label: string;
  valueInCents: number;
  xss?: SummaryRowXss;
};

// <X extends Only<IconXss, X>>(props: IconProps<X>): ReactElement {
function SummaryRow<X extends Only<SummaryRowXss, X>>(props: SummaryRowProps) {
  const { label, valueInCents, xss } = props;
  return (
    <div css={{ ...Css.dg.py1.gtc("2fr 1fr").fwb.sm.$, ...xss }}>
      <div css={Css.tar.$}>{label}</div>
      <div css={Css.tar.$}>{price(valueInCents)}</div>
    </div>
  );
}

function price(valueInCents: number) {
  const costWithoutCents = (valueInCents / 100) | 0;
  return `$${costWithoutCents.toLocaleString("en-US")}`;
}
