import {
  column,
  dateColumn,
  GridColumn,
  GridTable,
  numericColumn,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { useParams } from "react-router";
import { dateCell, linkHeader, priceCell, tagCell } from "src/components";
import { ChangeEventEstimatesTabQuery, useChangeEventEstimatesTabQuery } from "src/generated/graphql-types";
import { ChangeEventParams } from "src/routes/routesDef";
import { estimateStatusToTagTypeMapper, queryResult } from "src/utils";

type Estimates = ChangeEventEstimatesTabQuery["changeEvent"]["estimates"];

export function ChangeEventEstimatesTab() {
  const { changeEventId } = useParams<ChangeEventParams>();
  const query = useChangeEventEstimatesTabQuery({ variables: { id: changeEventId } });
  return queryResult(query, (data) => <EstimatesTable estimates={data.changeEvent.estimates} />);
}

type EstimatesTableProps = {
  estimates: Estimates;
};

function EstimatesTable({ estimates }: EstimatesTableProps) {
  return (
    <ScrollableContent>
      <GridTable
        columns={columns}
        rows={simpleDataRows(estimates)}
        fallbackMessage="No Info"
        sorting={{ on: "client", initial: ["lastUpdated", "DESC"] }}
        stickyHeader
        style={{ bordered: true, allWhite: true }}
      />
    </ScrollableContent>
  );
}

type Row = SimpleHeaderAndData<Estimates[0]>;
const columns: GridColumn<Row>[] = [
  column<Row>({
    header: "Estimate",
    data: ({ name, blueprintUrl }) => linkHeader(name, blueprintUrl.path),
    w: 2,
  }),
  column<Row>({
    header: "Type",
    data: ({ type }) => type.name,
  }),
  numericColumn<Row>({
    header: "Cost",
    data: ({ totalCostInCents }) => priceCell({ valueInCents: totalCostInCents }),
  }),
  numericColumn<Row>({
    header: "Total Price",
    data: ({ totalPriceInCents }) => priceCell({ valueInCents: totalPriceInCents }),
  }),
  dateColumn<Row>({
    id: "lastUpdated",
    header: "Last Updated",
    data: ({ updatedAt }) => dateCell(updatedAt),
    align: "right",
  }),
  column<Row>({
    header: "Status",
    data: ({ status }) => tagCell(estimateStatusToTagTypeMapper[status.code], status.name),
  }),
];
