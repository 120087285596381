import { Accordion, LoadingSkeleton, Tag, useTestIds } from "@homebound/beam";
import { formatDistanceToNow, isSameDay } from "date-fns";
import { useMemo } from "react";
import { formatDate } from "src/components";
import {
  ActionItemsSchedulingQuery,
  PersonalDashboard_ScheduleTaskFragment,
  TimeFrequency,
  TradePartnerAvailabilityRequestStatus,
  TradePartnerTaskStatus,
  useActionItemsSchedulingQuery,
} from "src/generated/graphql-types";
import { formatLeadTime } from "src/routes/trade-partners/trade-category-lead-times/utils";
import { queryResult } from "src/utils";
import { nonClosedProjects } from "src/utils/projects";
import { openNewTab } from "src/utils/window";
import { useDashboardFilterContext } from "../../DashboardFilterContext";
import { ItemCard } from "../../components/ItemCard";
import { ActionItemTypes } from "../../enums";
import { ActionItemsContainer, EmptyActionItems, LINK_CSS } from "./ActionItemsPage";

export function ActionItemsScheduling() {
  const { filter } = useDashboardFilterContext();

  const query = useActionItemsSchedulingQuery({
    variables: {
      internalUser: filter.internalUser,
      project: filter.project,
      scheduleIsActive: true,
      projectStatus: nonClosedProjects,
    },
    skip: !filter.internalUser,
  });

  return queryResult(query, {
    data: (data) => {
      return <ActionItemsSchedulingLayout data={data} />;
    },
    loading: () => <LoadingSkeleton rows={3} columns={3} size="lg" />,
    showLoading: "always",
  });
}

function ActionItemsSchedulingLayout({ data }: { data: ActionItemsSchedulingQuery }) {
  const tids = useTestIds({}, "actionItemScheduling");
  const { scheduleTasks } = data;

  const schedulingItems = useMemo(
    () =>
      scheduleTasks
        .filter((task) => task.tradePartner !== null)
        .map((task) => <ActionItemScheduling key={task.id} task={task} />),
    [scheduleTasks],
  );

  return (
    <Accordion
      {...tids}
      title={`Scheduling (${schedulingItems.length}${schedulingItems.length === 100 ? "+" : ""})`}
      defaultExpanded
      topBorder={false}
    >
      {schedulingItems.isEmpty && <EmptyActionItems title="No tasks to schedule" />}
      <ActionItemsContainer count={schedulingItems.length}>{schedulingItems}</ActionItemsContainer>
    </Accordion>
  );
}

type ActionItemSchedulingProps = {
  task: PersonalDashboard_ScheduleTaskFragment;
};

function ActionItemScheduling({ task }: ActionItemSchedulingProps) {
  const {
    openTaskDetailPane,
    openTradePartnerAvailabilityRequestDetailPane,
    selectedTPAvailabilityRequestTask,
    selectedTaskId,
  } = useDashboardFilterContext();

  const currentRequest = useMemo(
    () => task.tradePartnerAvailabilityRequests?.sortByKey("createdAt").last,
    [task.tradePartnerAvailabilityRequests],
  );

  const rescheduleDates = useMemo(() => currentRequest?.rescheduleDates ?? [], [currentRequest?.rescheduleDates]);

  const description = useMemo(() => {
    const taskName = (
      <span css={LINK_CSS} onClick={() => openTaskDetailPane(task)}>
        {task.name}
      </span>
    );

    const tradePartnerName = (
      <span css={LINK_CSS} onClick={() => openTaskDetailPane(task)}>
        {task?.tradePartner?.name}
      </span>
    );

    const prevTPAR = currentRequest && currentRequest.emails?.length > 0 && (
      <>
        {" "}
        You have already requested availability{" "}
        <span css={LINK_CSS} onClick={() => openNewTab(`${currentRequest.blueprintUrl.path}`)}>
          {currentRequest.emails?.length} time(s).
        </span>
      </>
    );

    if (task.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsConfirmation) {
      if (currentRequest?.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded) {
        if (rescheduleDates.some((d) => isSameDay(d, task.interval.startDate))) {
          return (
            <p>
              The date for task {taskName} has changed to match the trade partner’s availability. Send a confirmation
              email to the trade partner to let them know that you have accepted their available dates and updated the
              schedule.
              {prevTPAR}
            </p>
          );
        }
        return (
          <p>
            The date for task {taskName} has changed. Send a confirmation email to the trade partner to let them know
            that you have changed their requested availability and updated the schedule.
          </p>
        );
      }

      return (
        <p>
          It's time to request availability from {tradePartnerName} about an upcoming task {taskName} in{" "}
          {formatLeadTime(task.tradePartner?.leadTimeInDays ?? 0, { code: TimeFrequency.Weeks, name: "weeks" })}.
          {prevTPAR}
        </p>
      );
    }

    if (task.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsReconfirmation) {
      return (
        <p>
          The date for task {taskName} has changed. It’s time to request new availability from {tradePartnerName}.
          {prevTPAR}
        </p>
      );
    }

    if (task.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable) {
      return (
        <p>
          {tradePartnerName} is unavailable for this upcoming task {taskName} on the original dates but provided new
          dates [{rescheduleDates.map((d) => formatDate(d, "monthShort")).join(", ")}]. Review the dates and confirm.
          {prevTPAR}
        </p>
      );
    }
  }, [currentRequest, openTaskDetailPane, rescheduleDates, task]);

  const title = useMemo(() => {
    return (
      <>
        Request Trade Partner for Availability{" "}
        <Tag
          text={
            currentRequest && currentRequest?.status.code === TradePartnerAvailabilityRequestStatus.Waiting
              ? "Waiting"
              : task.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsConfirmation
                ? "Needs Confirmation"
                : task.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable
                  ? "Unavailable"
                  : "Needs Reconfirmation"
          }
          type="caution"
          data-testid="title"
        />
      </>
    );
  }, [currentRequest, task.tradePartnerStatus.code]);

  const [cta, onClick] = useMemo(() => {
    if (task.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable) {
      if (rescheduleDates.some((d) => isSameDay(d, task.interval.startDate))) {
        return ["Notify Trade of New Dates", () => openTradePartnerAvailabilityRequestDetailPane(task)];
      }
      return ["Review and Confirm Dates", () => openTaskDetailPane(task)];
    }

    if (
      task.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsConfirmation &&
      currentRequest?.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded
    ) {
      return ["Notify Trade of New Dates", () => openTradePartnerAvailabilityRequestDetailPane(task)];
    }

    return ["Request Availability", () => openTradePartnerAvailabilityRequestDetailPane(task)];
  }, [openTaskDetailPane, openTradePartnerAvailabilityRequestDetailPane, rescheduleDates, task, currentRequest]);
  return (
    <ItemCard
      key={`scheduling_task_${task.id}`}
      actionType={ActionItemTypes.SCHEDULING}
      onClick={onClick}
      title={title}
      description={description}
      duration={formatDistanceToNow(task.interval.startDate, { addSuffix: true })}
      project={task.project}
      assignee={task.assignee?.user}
      disabledBtn={task.id === (selectedTPAvailabilityRequestTask || selectedTaskId)}
      parent={task.parent}
      overrideCTA={cta}
    />
  );
}
