import { Css, GridDataRow } from "@homebound/beam";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  getDaysInMonth,
  isFirstDayOfMonth,
  lastDayOfMonth,
} from "date-fns";
import { useMemo } from "react";
import { Icon } from "src/components";
import {
  ScheduleExportPageQuery,
  ScheduleExportTaskFragment,
  SchedulePhaseDetailsFragment,
  ScheduleTaskDependency,
  TaskScheduleDetailsFragment,
} from "src/generated/graphql-types";
import { maybeFilterEmptySchedulePhasesAndSubPhases } from "src/routes/projects/schedule-v2/ProjectScheduleTableV2";
import { ScheduleRow } from "src/routes/projects/schedule-v2/table/utils";
import { postProcessChildren, preProcessQueryResults } from "src/utils/schedules";

export function ScheduleWithGanttExportPdf({
  data,
  startDate,
  endDate,
}: {
  data?: ScheduleExportPageQuery;
  startDate: Date;
  endDate: Date;
}) {
  const [schedule] = data?.schedules ?? [];
  const { parent, stage } = schedule;

  // Get all schedule phases and schedule sub phases in the correct order
  const schedulePhasesById = preProcessQueryResults(
    schedule.phases as SchedulePhaseDetailsFragment[],
    data?.scheduleTasks as TaskScheduleDetailsFragment[],
  );
  const schedulePhase = maybeFilterEmptySchedulePhasesAndSubPhases(schedulePhasesById, true)
    .map((schedulePhaseRow) =>
      // sets the children of the schedulePhaseRow & scheduleSubPhasePhases
      postProcessChildren(schedulePhaseRow),
    )
    .sortBy((row) => row.data?.interval?.startDate.getTime() ?? 0);
  const flatRows = useMemo(() => getFlatRows(schedulePhase), [schedulePhase]);
  const taskNumberMap = useMemo(() => {
    let taskNumber = 1;
    return flatRows
      .filter((row) => row.kind === "task")
      .keyBy(
        (row) => row.id,
        () => taskNumber++,
      );
  }, [flatRows]);

  const months = eachMonthOfInterval({ start: startDate, end: endDate });
  const firstDayOfMonth = months[0];
  const lastDayOfLastMonth = lastDayOfMonth(endDate);
  const monthsAndRemainingDays = getMonthsAndRemainingDaysInRange(startDate, endDate);
  const ganttColumnCount = monthsAndRemainingDays.reduce((acc, { daysInMonth }) => acc + daysInMonth, 0);
  const ganttHeaders = getGanttHeaders(monthsAndRemainingDays);

  const ganttPxSize = 700 / ganttColumnCount;

  return (
    <>
      <table css={Css.ba.bcGray900.tiny.add({ borderSpacing: 0, WebkitPrintColorAdjust: "exact" }).$}>
        <colgroup span={10} />
        <colgroup span={ganttColumnCount} />
        <thead css={Css.w100.$}>
          <tr css={Css.bt.bcGray900.$}>
            <th colSpan={5} css={Css.br.bcGray900.$}>
              {parent.name} - {stage} Schedule
            </th>
            <th css={Css.br.bcGray900.$} colSpan={5}>
              {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
            </th>
            <th colSpan={ganttColumnCount}>Export Date: {new Date().toLocaleDateString()}</th>
          </tr>
          <tr>
            <th css={Css.bb.bt.bcGray900.$}></th>
            <th css={Css.bb.bt.bcGray900.$}></th>
            <th css={Css.bb.bt.bcGray900.$}></th>
            <th css={Css.bb.bt.br.bcGray900.pr2.$}>Task #</th>
            <th css={Css.bb.bt.br.bcGray900.$}>Name</th>
            <th css={Css.bb.bt.br.pPx(2).bcGray900.$}>Duration</th>
            <th css={Css.bb.bt.br.bcGray900.$}>Start Date</th>
            <th css={Css.bb.bt.br.bcGray900.$}>End Date</th>
            <th css={Css.bb.bt.br.bcGray900.$}>Predecessors</th>
            <th css={Css.bb.bt.bcGray900.$}>Successors</th>
            {ganttHeaders}
          </tr>
        </thead>
        <tfoot>
          <tr css={Css.bt.bcGray900.$}>
            <td colSpan={7}>
              <div css={Css.df.jcsa.aic.$}>
                <div css={Css.df.aic.jcsb.$}>
                  <span>Phase </span>
                  <div css={Css.bgYellow300.wPx(16).hPx(8).ml1.$}></div>
                </div>
                <div css={Css.df.aic.jcsb.$}>
                  <span>SubPhase </span>
                  <div css={Css.bgBlue300.wPx(16).hPx(8).ml1.$}></div>
                </div>
                <div css={Css.df.aic.jcsb.$}>
                  <span>Task </span>
                  <div css={Css.bgGray300.wPx(16).hPx(8).ml1.$}></div>
                </div>
                <div css={Css.df.aic.jcsb.$}>
                  <span>Critical Path </span>
                  <div css={Css.bgRed300.wPx(16).hPx(8).ml1.$}></div>
                </div>
                <div css={Css.df.aic.jcsb.$}>
                  <span>Milestone</span>
                  <Icon icon="diamond" pxSize={10} />
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
        <tbody css={Css.$}>{getRows(flatRows, firstDayOfMonth, lastDayOfLastMonth, taskNumberMap, ganttPxSize)}</tbody>
      </table>
    </>
  );
}

function getRows(
  rows: GridDataRow<ScheduleRow>[],
  startDate: Date,
  endDate: Date,
  taskNumberMap: Record<string, number>,
  ganttPxSize: number,
) {
  return rows.map((row) => {
    if (!row.data) {
      return null;
    }
    const { name, interval } = row.data;
    const taskOnPhase = row.kind === "task" && !row.data.scheduleSubPhase?.id;
    return (
      <tr key={row.id} css={Css.h4.$}>
        {/* List view cells */}
        <td css={{ ...getListColor(row.kind, 1), ...Css.bb.bcGray800.wPx(4).$ }}></td>
        <td css={{ ...getListColor(row.kind, 2), ...Css.bb.bcGray800.wPx(4).if(taskOnPhase).bgGray300.$ }}></td>
        <td css={{ ...getListColor(row.kind, 3), ...Css.bb.bcGray800.wPx(4).$ }}></td>
        <td css={{ ...getListColor(row.kind), ...Css.bb.bcGray800.w2.$ }}>{taskNumberMap[row.id]}</td>
        <td css={{ ...getListColor(row.kind), ...Css.bb.bcGray800.wPx(200).$ }}>{name}</td>
        <td css={{ ...getListColor(row.kind), ...Css.bb.bcGray800.$ }}>{interval?.durationInDays}</td>
        <td css={{ ...getListColor(row.kind), ...Css.w8.bb.bcGray800.$ }}>
          {interval ? format(interval.startDate.getTime(), "dd-MMM-yy") : undefined}
        </td>
        <td css={{ ...getListColor(row.kind), ...Css.mwPx(64).bb.bcGray800.$ }}>
          {interval ? format(interval.endDate.getTime(), "dd-MMM-yy") : undefined}
        </td>
        {row.kind === "task" ? (
          <>
            <ExportDependencyCell task={row.data} dependencyType="predecessor" taskNumberMap={taskNumberMap} />
            <ExportDependencyCell task={row.data} dependencyType="successor" taskNumberMap={taskNumberMap} />
          </>
        ) : (
          <>
            <td css={{ ...getListColor(row.kind), ...Css.bb.bcGray800.wminc.$ }}></td>
            <td css={{ ...getListColor(row.kind), ...Css.bb.bcGray800.$ }}></td>
          </>
        )}
        {getGanttColumnList(row, startDate, endDate, ganttPxSize)}
      </tr>
    );
  });
}

// Using our Grid sorting functions for PoC purposes, even though GridTable isn't used in this component
function getFlatRows(phases: GridDataRow<ScheduleRow>[]) {
  // create a flat list of data
  const rows: GridDataRow<ScheduleRow>[] = [];
  phases.forEach((phase) => {
    if (phase.children?.isEmpty || phase.children?.every((sp) => sp.children?.isEmpty)) return;
    rows.push(phase);
    phase.children?.forEach((subPhase) => {
      if (subPhase.children?.isEmpty) return;
      rows.push(subPhase);
      subPhase.children?.forEach((task) => {
        rows.push(task);
      });
    });
  });
  return rows;
}

function getMonthsAndRemainingDaysInRange(start: Date, end: Date): { daysInMonth: number; month: Date }[] {
  const months = eachMonthOfInterval({ start, end });
  return months.map((month) => ({
    month,
    daysInMonth: getDaysInMonth(month),
  }));
}

function getGanttHeaders(monthsAndRemainingDays: { daysInMonth: number; month: Date }[]) {
  const monthsAndDays = monthsAndRemainingDays;
  return (
    <>
      {monthsAndDays.map(({ month, daysInMonth }) => {
        return (
          <th
            key={month.getTime()}
            css={Css.ba.bcGray900.overflow("clip").w(`calc(700px/${monthsAndDays.length})`).$}
            colSpan={daysInMonth}
          >
            {month.toLocaleDateString(undefined, { month: "short", year: "2-digit" })}
          </th>
        );
      })}
    </>
  );
}

// return a list of <td> elements for each day in the range
function getGanttColumnList(row: GridDataRow<ScheduleRow>, start: Date, end: Date, ganttPxSize: number) {
  const days = eachDayOfInterval({ start, end });
  const startDateTime = row.data?.interval?.startDate.getTime()!;
  return days.map((day) => {
    const time = day.getTime();
    const isStart = time === startDateTime;
    const isMilestone = isStart && row.data?.interval?.durationInDays === 0;
    const isCriticalPath = row.kind === "task" && row.data?.isCriticalPath;
    const isWithinRange = time >= startDateTime && time <= row.data?.interval?.endDate.getTime()!;
    return (
      <td
        key={`${row.id}-${time}`}
        data-col-id={`${row.id}-${time}`}
        data-taskstart={startDateTime}
        data-taskend={row.data?.interval?.endDate.getTime()}
        css={Css.px0.mwPx(1).wPx(ganttPxSize).if(isFirstDayOfMonth(time)).bl.bsDashed.bcGray300.else.bn.$}
      >
        {isStart ? (
          <div css={Css.position("relative").$}>
            {isMilestone ? (
              <div css={Css.position("absolute").bottom0.$}>
                <Icon icon="diamond" pxSize={10} />
              </div>
            ) : (
              <div css={{ ...getGanttColor(row.kind, isCriticalPath), ...Css.h1.$ }}></div>
            )}
            <div css={Css.position("absolute").bottom1.wmaxc.$}>{row.data?.name}</div>
          </div>
        ) : isWithinRange ? (
          <div css={{ ...getGanttColor(row.kind, isCriticalPath), ...Css.h1.$ }}></div>
        ) : null}
      </td>
    );
  });
}

function getGanttColor(type: "phase" | "subPhase" | "task" | "header", isCriticalPath?: boolean) {
  switch (type) {
    case "phase":
      return Css.bgYellow300.$;
    case "subPhase":
      return Css.bgBlue300.$;
    case "task":
      return isCriticalPath ? Css.bgRed300.$ : Css.bgGray300.$;
    default:
      return Css.$;
  }
}

function getListColor(type: "phase" | "subPhase" | "task" | "header", column?: 1 | 2 | 3) {
  if (!column) return getGanttColor(type);
  switch (column) {
    case 1:
      return Css.bgYellow300.$;
    case 2:
      return type === "phase" ? Css.bgYellow300.$ : Css.bgBlue300.$;
    case 3:
      return type === "phase" ? Css.bgYellow300.$ : type === "subPhase" ? Css.bgBlue300.$ : Css.bgGray300.$;
    default:
      return Css.$;
  }
}

function ExportDependencyCell({
  task,
  dependencyType,
  taskNumberMap,
}: {
  task: ScheduleExportTaskFragment;
  dependencyType: keyof Pick<ScheduleTaskDependency, "predecessor" | "successor">;
  taskNumberMap: Record<string, number>;
}) {
  const taskDependencies =
    dependencyType === "predecessor"
      ? task.predecessorDependencies
          .filter((td) => taskNumberMap[td[dependencyType].id])
          .map((td) => {
            const taskNumber = taskNumberMap[td[dependencyType].id];

            return `${taskNumber}${td.lagInDays > 0 ? `+${td.lagInDays}` : td.lagInDays < 0 ? `${td.lagInDays}` : ""}`;
          })
      : task.successorDependencies
          .filter((td) => taskNumberMap[td[dependencyType].id])
          .map((td) => {
            const taskNumber = taskNumberMap[td[dependencyType].id];

            return `${taskNumber}${td.lagInDays > 0 ? `+${td.lagInDays}` : td.lagInDays < 0 ? `${td.lagInDays}` : ""}`;
          });
  const dependencyString = taskDependencies.isEmpty ? "--" : taskDependencies.join(", ");
  return (
    <td css={{ ...getListColor("task"), ...Css.bb.bcGray800.$ }}>
      <div css={Css.df.aic.jcc.$}>{dependencyString}</div>
    </td>
  );
}
