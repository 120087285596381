import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { BidContractRevisionFragment, useBidContractRevisionQuery } from "src/generated/graphql-types";
import { DevelopmentContractHeader } from "src/routes/developments/components/DevelopmentContractHeader";
import { DevelopmentContractTabs } from "src/routes/developments/components/DevelopmentContractTabs";
import { DevelopmentContractParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export function DevelopmentContractPage() {
  const { bidContractRevisionId } = useParams<DevelopmentContractParams>();
  const query = useBidContractRevisionQuery({ variables: { bidContractRevisionId } });
  return queryResult(query, {
    data: ({ bidContractRevision }) => {
      return <DevelopmentContractView bidContractRevision={bidContractRevision} />;
    },
  });
}

type DevelopmentContractProp = {
  bidContractRevision: BidContractRevisionFragment;
};

function DevelopmentContractView({ bidContractRevision }: DevelopmentContractProp) {
  return (
    <>
      <DevelopmentContractHeader selectedRevision={bidContractRevision} />
      <div css={Css.mt3.$}>
        <DevelopmentContractTabs selectedRevision={bidContractRevision} />
      </div>
    </>
  );
}
