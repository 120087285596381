import {
  BoundNumberField,
  BoundSelectField,
  Button,
  column,
  Css,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  selectColumn,
  simpleHeader,
  Tooltip,
  useComputed,
  useGridTableApi,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { chipCell, dateCell, linkHeader, priceCell, tagCell } from "src/components";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  CommitmentStatus,
  ReassignPurchaseOrdersCommitmentFragment,
  useReassignPurchaseOrdersQuery,
  useSaveReassignPurchaseOrdersMutation,
} from "src/generated/graphql-types";
import { createDevelopmentContractOverviewUrl, createDevelopmentReassignPurchaseOrdersReleaseUrl } from "src/RouteUrls";
import {
  commitmentStatusToTagTypeMapper,
  fail,
  foldEnum,
  formatList,
  groupBy,
  isEmpty,
  pluralize,
  queryResult,
} from "src/utils";
import { DevelopmentParams } from "../../routesDef";
import { ReassignPurchaseOrdersType } from "./enums";
import { ReassignPurchaseOrdersState } from "./ReassignPurchaseOrdersPage";
import { ReassignPurchaseOrdersReleaseState } from "./ReassignPurchaseOrdersReleaseStep";

type ReassignPurchaseOrdersSelectState = {
  ids: string[];
} & ReassignPurchaseOrdersState;

export function ReassignPurchaseOrdersSelectStep() {
  const location = useLocation<ReassignPurchaseOrdersSelectState>();
  const selectedCommitments = location.state?.ids || [];
  const { reassignType, bcrId } = location.state;
  const result = useReassignPurchaseOrdersQuery({
    variables: { filter: { commitmentIds: selectedCommitments } },
  });

  return queryResult(result, (data) => {
    const commitments: CommitmentRowType[] = data.commitments.map((c) => ({
      fragment: c,
      bcrId: undefined,
      proposedCost: undefined,
    }));
    return <ReassignPurchaseOrdersSelectView commitments={commitments} reassignType={reassignType} bcrId={bcrId} />;
  });
}

type ReassignPurchaseOrdersSelectTableProps = {
  commitments: CommitmentRowType[];
  reassignType: ReassignPurchaseOrdersType;
  bcrId: string | undefined;
};

function ReassignPurchaseOrdersSelectView({
  commitments,
  reassignType,
  bcrId,
}: ReassignPurchaseOrdersSelectTableProps) {
  const tableApi = useGridTableApi<Row>();
  const { developmentId } = useParams<DevelopmentParams>();
  const { nextStep } = useStepperContext();
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const [saveBidToCommitments, { loading }] = useSaveReassignPurchaseOrdersMutation();
  const formState = useFormState({
    config: formConfig,
    init: { input: commitments, map: mapToForm },
  });
  const disableButton = useComputed(() => !formState.dirty, [formState]);
  const columns = useMemo(() => createColumns(developmentId, reassignType), [developmentId, reassignType]);
  const rows = useMemo(() => createRows(formState), [formState]);
  const selectedCommitments = useComputed(() => tableApi.getSelectedRowIds("commitment"), [tableApi]);

  const handleContinue = async () => {
    const oldCosts: number[] = [];
    const tradePartners: string[] = [];
    const mappings = formState.commitments.rows
      .filter((os) => os.dirty)
      .map(({ changedValue, fragment }) => {
        oldCosts.push(fragment.value.committedInCents);
        // Make a list of the trade partners that are notified via email
        if (fragment.value.status !== CommitmentStatus.Draft && fragment.value.tradePartner) {
          tradePartners.push(fragment.value.tradePartner.name);
        }
        return {
          commitmentId: fragment.value.id,
          bidContractRevisionId: changedValue.bcrId!,
        };
      })
      .filter((c) => selectedCommitments.includes(c.commitmentId));

    if (mappings.isEmpty) {
      return triggerNotice({
        icon: "warning",
        message: `You must change the ${reassignType} of at least one Purchase Order.`,
      });
    }

    const result = await saveBidToCommitments({ variables: { input: mappings } });

    if (result.data) {
      const savedCommitments = result.data?.saveBidToCommitments.commitments;
      // POs that didn't have available trade partner/bid contract revisions
      const unsavedCommitments = commitments.length - savedCommitments.length;
      if (unsavedCommitments > 0) {
        triggerNotice({
          icon: "error",
          message: `${unsavedCommitments} Purchase ${pluralize(
            unsavedCommitments,
            "order",
          )} failed to void & generate new ${pluralize(unsavedCommitments, "commitment")}`,
        });
      }

      // POs were successfully edited
      triggerNotice({
        icon: "success",
        message: `${savedCommitments.length} Purchase ${pluralize(
          savedCommitments,
          "order has",
          "orders have",
        )} been voided ${
          tradePartners.length ? `and an Email has been sent to ${formatList(tradePartners.unique())}` : ""
        }`,
      });
      // updates the current step
      nextStep();

      history.push({
        pathname: createDevelopmentReassignPurchaseOrdersReleaseUrl(developmentId),
        // Results length should always be the same as the mappings (with the same order)
        state: {
          savedCommitments: savedCommitments.map((c, i) => ({
            id: c.id,
            oldCost: oldCosts[i],
          })),
          reassignType: reassignType,
          bcrId,
        } as ReassignPurchaseOrdersReleaseState,
      });
    }
  };

  return (
    <>
      <GridTable
        columns={columns}
        rows={rows}
        stickyHeader
        rowStyles={rowStyles}
        api={tableApi}
        fallbackMessage="No available purchase orders versions to manage"
      />
      <StepActions>
        <Button disabled={loading || disableButton} label="Void & Continue" onClick={handleContinue} />
      </StepActions>
    </>
  );
}

// Add border to split between previous / proposed values
const cellBorderStyles = Css.addIn("& > div:nth-of-type(6)", Css.bl.bcGray400.$).addIn(
  "& > div:nth-of-type(9)",
  Css.bl.bcGray400.$,
).$;

const rowStyles: RowStyles<Row> = {
  header: { rowCss: cellBorderStyles },
  commitment: { rowCss: cellBorderStyles },
};

type CommitmentRowType = {
  bcrId: string | undefined | null;
  proposedCost: number | undefined | null;
  fragment: ReassignPurchaseOrdersCommitmentFragment;
};
type CommitmentRow = { kind: "commitment"; data: ObjectState<FormValue>["commitments"]["rows"][0] };
type Row = { kind: "header"; data: undefined; id: string } | CommitmentRow;

const createColumns = (developmentId: string, reassignType: ReassignPurchaseOrdersType): GridColumn<Row>[] => {
  const tableColumns = [
    selectColumn<Row>(),
    column<Row>({
      header: "PO #",
      commitment: ({ fragment }) =>
        linkHeader(String(fragment.value.accountingNumber), fragment.value.blueprintUrl.path),
      w: 0.6,
    }),
    column<Row>({
      header: "Address",
      commitment: ({ fragment }) => fragment.value.project.buildAddress.street1,
    }),
    column<Row>({
      header: "Cost Codes",
      commitment: ({ fragment }) => chipCell(fragment.value.lineItems.map((li) => li.costCode.number)),
    }),
    dateColumn<Row>({
      header: "Released Date",
      commitment: ({ fragment }) => dateCell(fragment.value.executionDate),
    }),
    column<Row>({
      header: "Status",
      commitment: ({ fragment }) =>
        tagCell(commitmentStatusToTagTypeMapper[fragment.value.status], fragment.value.statusText),
      clientSideSort: false,
      w: 0.8,
    }),
    column<Row>({
      header: "Current Trade",
      commitment: ({ fragment }) => fragment.value.tradePartner?.name || fragment.value.tradePartner?.id,
    }),
    numericColumn<Row>({
      header: "Contract Version",
      commitment: ({ fragment }) => {
        return fragment.value.bidContractRevision?.id
          ? linkHeader(
              fragment.value.bidContractRevision!.version,
              createDevelopmentContractOverviewUrl(developmentId, fragment.value.bidContractRevision?.id),
            )
          : undefined;
      },
      align: "right",
    }),
    numericColumn<Row>({
      header: "Committed Cost",
      commitment: ({ fragment }) => priceCell({ valueInCents: fragment.value.committedInCents }),
    }),
  ];

  const selectTradePartnerColumns = [
    column<Row>({
      header: "Proposed Trade",
      commitment: (data) => {
        const potentialBcrs = data.fragment.value.potentialBidContractRevisions
          // Filter out BCLIs from internal revisions
          .filter((pr) => !!pr.bidContractRevision.bidContract.tradePartner)
          .filter((pr) => pr.bidContractRevision.bidContract.tradePartner?.id !== data.fragment.value.tradePartner?.id);

        // Filter potential BCRs to show one trade partner
        const oneTpPerBcrs = Object.values(
          groupBy(
            potentialBcrs,
            (bcr) => bcr.bidContractRevision.bidContract.tradePartner?.id || fail("Unexpected estimate BCLI"),
          ),
        ).map((tp) => {
          const alreadySelected = tp.find((bcr) => bcr.bidContractRevision.id === data.bcrId.value);
          return alreadySelected || tp.first!;
        });

        if (isEmpty(oneTpPerBcrs))
          return (
            <Tooltip
              title={"No other trades on this development are contracted for the cost codes in this PO."}
              placement="top"
            >
              <div css={Css.red600.$}>0 trades available</div>
            </Tooltip>
          );

        return (
          <BoundSelectField
            label="Proposed Trade"
            onSelect={(bcrId, selectedBcr) => {
              data.set({ bcrId, proposedCost: selectedBcr?.totalCostInCents });
            }}
            placeholder="Select a Trade Partner"
            options={oneTpPerBcrs}
            getOptionLabel={(bcr) => bcr.bidContractRevision.bidContract.tradePartner?.name || ""}
            getOptionValue={(bcr) => bcr.bidContractRevision.id}
            field={data.bcrId}
            data-testid="proposedTradePartner"
          />
        );
      },
      w: 1.4,
      clientSideSort: false,
    }),
    column<Row>({
      header: "Contract Version",
      commitment: (data) => {
        // Filter revisions of the current selected trade partner
        const bcrOptions = data.fragment.value.potentialBidContractRevisions;
        const selectedTradePartnerId = bcrOptions.find((pr) => pr.bidContractRevision?.id === data.bcrId.value)
          ?.bidContractRevision.bidContract.tradePartner?.id;
        const options = bcrOptions.filter(
          (o) => o.bidContractRevision.bidContract.tradePartner?.id === selectedTradePartnerId,
        );

        return data.bcrId.value && options.length > 0 ? (
          <BoundSelectField
            label="Contract Version"
            onSelect={(bcrId, selectedBcr) => {
              data.set({ bcrId, proposedCost: selectedBcr?.totalCostInCents });
            }}
            options={options}
            getOptionLabel={(r) => r.bidContractRevision.version}
            getOptionValue={(r) => r.bidContractRevision.id}
            readOnly={options.length <= 1}
            field={data.bcrId}
            data-testid="contractVersion"
          />
        ) : undefined;
      },
      clientSideSort: false,
      align: "right",
    }),
  ];

  const selectVersionColumns = [
    column<Row>({
      header: "Proposed Version",
      commitment: (data) => {
        // Filter revisions of the current selected trade partner and different to the one already assigned
        const bcrOptions = data.fragment.value.potentialBidContractRevisions;
        const options = bcrOptions.filter(
          (o) =>
            o.bidContractRevision.bidContract.tradePartner?.id === data.fragment.value.tradePartner?.id &&
            o.bidContractRevision.id !== data.fragment.value.bidContractRevision?.id,
        );

        if (isEmpty(options)) {
          return (
            <Tooltip
              title={"No other trades on this development are contracted for the cost codes in this PO."}
              placement="top"
            >
              <div css={Css.red600.$}>0 trades available</div>
            </Tooltip>
          );
        }

        if (reassignType === ReassignPurchaseOrdersType.MANAGE) {
          const preferredBcr = data.fragment.value.preferredBidContractRevision;
          // Set the preferred BCR as the default
          if (preferredBcr && data.bcrId.value !== preferredBcr.bidContractRevision.id && !data.bcrId.dirty) {
            data.set({ bcrId: preferredBcr.bidContractRevision.id, proposedCost: preferredBcr.totalCostInCents });
          }
        }

        return (
          <BoundSelectField
            label="Contract Version"
            onSelect={(bcrId, selectedBcr) => {
              data.set({ bcrId, proposedCost: selectedBcr?.totalCostInCents });
            }}
            options={options}
            getOptionLabel={(r) => r.bidContractRevision.version}
            getOptionValue={(r) => r.bidContractRevision.id}
            field={data.bcrId}
            data-testid="contractVersion"
          />
        );
      },
      clientSideSort: false,
      w: 1.2,
    }),
  ];

  const proposedCostColumn = [
    numericColumn<Row>({
      header: "Proposed Cost",
      commitment: (data) =>
        data.bcrId.value ? <BoundNumberField field={data.proposedCost} type="cents" readOnly /> : undefined,
      clientSideSort: false,
      align: "right",
    }),
  ];

  return [
    ...tableColumns,
    ...foldEnum(reassignType, {
      [ReassignPurchaseOrdersType.TRADE_PARTNER]: selectTradePartnerColumns,
      [ReassignPurchaseOrdersType.VERSION]: selectVersionColumns,
      [ReassignPurchaseOrdersType.MANAGE]: selectVersionColumns,
    }),
    ...proposedCostColumn,
  ];
};

const createRows = (formState: ObjectState<FormValue>): GridDataRow<Row>[] => {
  return [
    simpleHeader,
    ...formState.commitments.rows.map((c) => ({
      kind: "commitment" as const,
      id: c.value.fragment.id,
      data: c,
      initSelected: true,
    })),
  ];
};

type FormValue = {
  commitments: CommitmentRowType[];
};

const formConfig: ObjectConfig<FormValue> = {
  commitments: {
    type: "list",
    config: {
      bcrId: { type: "value" },
      proposedCost: { type: "value" },
      fragment: { type: "value" },
    },
  },
};

function mapToForm(commitments: CommitmentRowType[]) {
  return {
    commitments: commitments.map((c) => ({
      id: c.fragment.id,
      bcrId: undefined,
      proposedCost: undefined,
      fragment: c.fragment,
    })),
  };
}
