import { Css, IconButton, Palette, SelectField } from "@homebound/beam";
import { Dispatch, SetStateAction } from "react";

export type PageSettings = {
  page: number;
  perPage: number;
};

type PaginationProps = {
  label: string;
  hasNextPage: boolean;
  settings: PageSettings;
  setSettings: Dispatch<SetStateAction<PageSettings>>;
  hideIfSinglePage?: boolean;
};

export function Pagination(props: PaginationProps) {
  const { hasNextPage, label, settings, setSettings } = props;
  const { perPage } = settings;

  // 50, 100, 150, 200, 250
  const pageOptions = Array(5)
    .fill(0)
    .map((_, i) => ({ id: (i + 1) * 50, name: ((i + 1) * 50).toString() }));

  const isSinglePage = settings.page === 1 && !hasNextPage;
  if (isSinglePage && props.hideIfSinglePage) {
    return null;
  }

  return (
    <div css={Css.df.bcGray300.bt.xs.gray500.px1.ml2.pt2.$}>
      <div css={Css.df.mya.mr2.$}>{label} per page:</div>
      <div css={Css.wPx(78).$}>
        <SelectField
          labelStyle="hidden"
          label={`${label} per page`}
          options={pageOptions}
          value={perPage}
          onSelect={(val) => {
            setSettings({ page: 1, perPage: val! });
          }}
        />
      </div>
      <div css={Css.mla.mya.df.$}>
        <IconButton
          icon="chevronLeft"
          color={Palette.Blue700}
          onClick={() => setSettings({ page: settings.page! - 1, perPage })}
          disabled={settings.page === 1}
        />
        <IconButton
          icon="chevronRight"
          color={Palette.Blue700}
          onClick={() => setSettings({ page: settings.page! + 1, perPage })}
          disabled={!hasNextPage}
        />
      </div>
    </div>
  );
}

export const initPageSettings = { page: 1, perPage: 100 };

export function toFirstAndOffset(page: number, perPage: number) {
  return {
    first: perPage,
    // E.g. on first page the offset is 0, second page the offset is 100, then 200, etc.
    offset: (page - 1) * perPage,
  };
}

export function toLimitAndOffset(page: number, perPage: number) {
  return {
    limit: perPage,
    offset: (page - 1) * perPage,
  };
}
