import { Css, Icon, maybeTooltip } from "@homebound/beam";

type OptionTypes = { [optionType: string]: { code?: string; name: string }[] };
export type ConfigChangesProps = {
  fieldsChanged: { attributeName: string; oldValue?: string; newValue: string }[];
  optionsAdded: OptionTypes;
  optionsRemoved: OptionTypes;
};

export function ConfigChanges(props: ConfigChangesProps) {
  const { fieldsChanged, optionsAdded, optionsRemoved } = props;
  const showAddedGroup = Object.keys(optionsAdded).length > 0;
  const showDeletedGroup = Object.keys(optionsRemoved).length > 0;

  return (
    <>
      {fieldsChanged.length > 0 && (
        <span css={Css.dg.gtc("24px auto").gap2.mb1.$}>
          <span css={Css.dg.gtr("auto 1fr").gap1.$}>
            {/* TODO: ADD this svg to beam icons as */}
            {/* <Icon icon="transfer" /> */}
            <span css={Css.br100.bgYellow300.w3.h3.dg.jcc.aic.$}>
              {maybeTooltip({
                title: "Fields changed",
                placement: "top",
                children: (
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.25 6L14 3L10.25 0V2.24925H4.25V3.74925H10.25V4.87462V6ZM9.75 6.25H3.75V4L0 7L3.75 10V7.75H9.75V6.25Z"
                      fill="#785936"
                    />
                  </svg>
                ),
              })}
            </span>
            {(showAddedGroup || showDeletedGroup) && (
              <span css={Css.dg.gtc("auto auto").$}>
                <span css={Css.br.bw1.bcGray200.$} />
                <span />
              </span>
            )}
          </span>
          <span css={Css.pb2.dg.gap1.$}>
            {fieldsChanged.map(({ attributeName, oldValue, newValue }) => (
              <p key={attributeName} css={Css.xs.$}>
                <b css={Css.xsSb.$}>{attributeName}</b> changed{" "}
                {oldValue ? (
                  <>
                    from <b css={Css.xsSb.$}>{oldValue}</b>
                  </>
                ) : (
                  ""
                )}{" "}
                to <b css={Css.xsSb.$}>{newValue}</b>
              </p>
            ))}
          </span>
        </span>
      )}
      {showAddedGroup && (
        <span css={Css.dg.gtc("24px auto").gap2.mb1.$}>
          <span css={Css.dg.gtr("auto 1fr").gap1.$}>
            <span css={Css.br100.bgBlue200.w3.h3.dg.jcc.aic.$}>
              <Icon tooltip="Options added" inc={2} icon="plus" />
            </span>
            {showDeletedGroup && (
              <span css={Css.dg.gtc("auto auto").$}>
                <span css={Css.br.bw1.bcGray200.$} />
                <span />
              </span>
            )}
          </span>
          <span css={Css.pb2.dg.gap1.$}>
            {Object.keys(optionsAdded).map((optionType) => {
              const options = optionsAdded[optionType];
              return options.map(({ name, code }) => (
                <span key={name}>
                  <p css={Css.tinyMd.gray700.$}>
                    {code ? `${code} • ` : ""}
                    {optionType}
                  </p>
                  <p>
                    <b css={Css.xsSb.$}>{name}</b>
                  </p>
                </span>
              ));
            })}
          </span>
        </span>
      )}
      {showDeletedGroup && (
        <span css={Css.dg.gtc("24px auto").gap2.mb1.$}>
          <span css={Css.dg.gtr("auto 1fr").gap1.$}>
            <span css={Css.br100.bgRed100.w3.h3.dg.jcc.aic.$}>
              <Icon tooltip="Options removed" icon="minus" />
            </span>
          </span>
          <span css={Css.pb2.dg.gap1.$}>
            {Object.keys(optionsRemoved).map((optionType) => {
              const options = optionsRemoved[optionType];
              return options.map(({ name, code }) => (
                <span key={name}>
                  <p css={Css.tinyMd.gray700.$}>
                    {code ? `${code} • ` : ""}
                    {optionType}
                  </p>
                  <p>
                    <b css={Css.xsSb.$}>{name}</b>
                  </p>
                </span>
              ));
            })}
          </span>
        </span>
      )}
    </>
  );
}
