import { Avatar, BoundMultiSelectField, Css } from "@homebound/beam";
import { Observer } from "mobx-react";
import { CommentEditor } from "src/components/index";
import {
  CommentFeedTradePartnerDetailsFragment,
  CommentStreamVisibility,
  InternalUser,
  Maybe,
  Scalars,
} from "src/generated/graphql-types";
import { sortBy } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { CommentSaveFunction } from "./CommentFeed";

export type CommentStreamTradePartnerFormProps = {
  streamVisibility: CommentStreamVisibility;
  saveComment: CommentSaveFunction;
  currentUser: InternalUser;
  tradePartners?: CommentFeedTradePartnerDetailsFragment[];
  commentableId: string;
};

export function CommentStreamTradePartnerForm(props: CommentStreamTradePartnerFormProps) {
  const { streamVisibility, saveComment, currentUser, tradePartners, commentableId } = props;
  const { name, avatar } = currentUser;
  type FormValue = { tradePartnerContactIds?: Maybe<Array<Scalars["ID"]>> };
  const formConfig: ObjectConfig<FormValue> = {
    tradePartnerContactIds: { type: "value", rules: [required] },
  };
  const form = useFormState({
    config: formConfig,
  });
  const tradePartnerContacts = tradePartners?.flatMap((tp) => {
    const tradePartnerName = tp.name;
    const contacts = sortBy(tp.contacts, (c) => c.name);
    const contactOptions = contacts.map((c) => {
      const { id, name, email } = c;
      const emailText = email ? `<${email}>` : "(add email to contact)";
      const label = `${tradePartnerName} - ${name} ${emailText}`;
      return { id, email, name, label };
    });
    return contactOptions;
  });

  async function handleSave(text: string, html: string, mentions?: string[]) {
    const tradePartnerContactIds = form.tradePartnerContactIds?.value;
    if (form.canSave() && tradePartnerContactIds && tradePartnerContacts) {
      const contactNames = tradePartnerContacts
        .filter(({ id }) => tradePartnerContactIds.includes(id))
        .map(({ name }) => name);
      const contactsPhrase = contactNames.join(", ");
      const confirmText = `You are about to send this message externally to [${contactsPhrase}]. Are you sure you want to proceed?`;
      if (window.confirm(confirmText)) {
        await saveComment(text, html, [], { streamVisibility, tradePartnerContactIds }, mentions);
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <div css={Css.mt4.$}>
      <BoundMultiSelectField
        data-testid="tradePartnerContacts"
        label="Trade Partner"
        field={form.tradePartnerContactIds}
        options={tradePartnerContacts ?? []}
        getOptionValue={({ id }) => id}
        getOptionLabel={({ label }) => label}
        disabledOptions={tradePartnerContacts?.filter((tpc) => !tpc.email).map(({ id }) => id)}
      />
      <div css={Css.df.mt4.$}>
        <div css={Css.mr2.$}>
          <Avatar name={name} src={avatar} />
        </div>
        <Observer>
          {() => (
            <CommentEditor
              commentableId={commentableId}
              streamVisibility={streamVisibility}
              forceDisable={!form.valid}
              onSave={(text, html, _, mentions) => handleSave(text, html, mentions)}
              submitText="Add"
            />
          )}
        </Observer>
      </div>
    </div>
  );
}
