import { Maybe, Stage } from "src/generated/graphql-types";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { assertNever } from "src/utils";
import { EmptyScheduleStates, ScheduleViewType } from "../scheduleUtils";

export type ScheduleStoreState = {
  taskPaneState: TaskPaneState;
  isScheduleExportModalOpen: boolean;
  scheduleType: ScheduleType;
  scheduleViewType: ScheduleViewType;
  taskNumberMap: Record<string, number>;
  emptyScheduleState: Maybe<EmptyScheduleStates>;
  stage: Stage;
  delayFeatureFlagIsOn: boolean;
};
// TODO: #SC-36798 - make scrollIntoViewType and taskPaneState "tab" into enums
export type TaskPaneState = {
  taskPaneId: string | undefined;
  scrollIntoViewType: "comments" | "predecessor" | "successor" | "flags" | "checklistItems" | undefined;
  tab: string;
};

export const INITIAL_STATE: ScheduleStoreState = {
  taskPaneState: {
    taskPaneId: undefined,
    scrollIntoViewType: undefined,
    tab: "details",
  },
  isScheduleExportModalOpen: false,
  scheduleType: ScheduleType.Project,
  stage: Stage.Construction,
  scheduleViewType: ScheduleViewType.List,
  taskNumberMap: {},
  emptyScheduleState: EmptyScheduleStates.LoadingState,
  delayFeatureFlagIsOn: false,
};

// Action Types
enum ScheduleStoreActions {
  SET_TASK_PANE_STATE = "SET_TASK_PANE_STATE",
  SET_SEARCH_TERM = "SET_SEARCH_TERM",
  SET_SCHEDULE_VIEW_TYPE = "SET_SCHEDULE_VIEW_TYPE",
  SET_TASK_NUMBER_MAP = "SET_TASK_NUMBER_MAP",
  SET_EMPTY_SCHEDULE_STATE = "SET_EMPTY_SCHEDULE_STATE",
  SET_SCHEDULE_STAGE = "SET_SCHEDULE_STAGE",
}

// Action Types
type SetTaskPaneStateAction = {
  type: ScheduleStoreActions.SET_TASK_PANE_STATE;
  taskPaneState: Partial<TaskPaneState>;
};

type SetEmptyScheduleState = {
  type: ScheduleStoreActions.SET_EMPTY_SCHEDULE_STATE;
  emptyScheduleState: ScheduleStoreState["emptyScheduleState"];
};

type SetScheduleStage = {
  type: ScheduleStoreActions.SET_SCHEDULE_STAGE;
  stage: ScheduleStoreState["stage"];
};

type SetScheduleViewType = {
  type: ScheduleStoreActions.SET_SCHEDULE_VIEW_TYPE;
  scheduleViewType: ScheduleViewType;
};

type SetTaskNumberMapAction = {
  type: ScheduleStoreActions.SET_TASK_NUMBER_MAP;
  taskNumberMap: Record<string, number>;
};

export type Actions =
  | SetTaskPaneStateAction
  | SetScheduleViewType
  | SetTaskNumberMapAction
  | SetEmptyScheduleState
  | SetScheduleStage;

// Exported Actions
export const setTaskPaneState = (taskPaneState: Partial<TaskPaneState>): SetTaskPaneStateAction => ({
  type: ScheduleStoreActions.SET_TASK_PANE_STATE,
  taskPaneState,
});

export const setEmptyScheduleState = (
  emptyScheduleState: ScheduleStoreState["emptyScheduleState"],
): SetEmptyScheduleState => ({
  type: ScheduleStoreActions.SET_EMPTY_SCHEDULE_STATE,
  emptyScheduleState,
});

export const setScheduleStage = (stage: ScheduleStoreState["stage"]): SetScheduleStage => ({
  type: ScheduleStoreActions.SET_SCHEDULE_STAGE,
  stage,
});

export const setScheduleViewType = (scheduleViewType: ScheduleViewType): SetScheduleViewType => ({
  type: ScheduleStoreActions.SET_SCHEDULE_VIEW_TYPE,
  scheduleViewType,
});

export const setTaskNumberMap = (taskNumberMap: Record<string, number>): SetTaskNumberMapAction => ({
  type: ScheduleStoreActions.SET_TASK_NUMBER_MAP,
  taskNumberMap,
});

export function scheduleReducer(state: ScheduleStoreState, action: Actions): ScheduleStoreState {
  const { type } = action;

  switch (type) {
    case ScheduleStoreActions.SET_TASK_PANE_STATE:
      return {
        ...state,
        taskPaneState: { ...state.taskPaneState, ...action.taskPaneState },
      };
    case ScheduleStoreActions.SET_EMPTY_SCHEDULE_STATE:
      return {
        ...state,
        emptyScheduleState: action.emptyScheduleState,
      };
    case ScheduleStoreActions.SET_SCHEDULE_VIEW_TYPE:
      return {
        ...state,
        taskNumberMap: {},
        scheduleViewType: action.scheduleViewType,
      };
    case ScheduleStoreActions.SET_SCHEDULE_STAGE:
      return {
        ...state,
        stage: action.stage,
      };
    case ScheduleStoreActions.SET_TASK_NUMBER_MAP:
      return {
        ...state,
        taskNumberMap: action.taskNumberMap,
      };
    default:
      return assertNever(type);
  }
}
