import { AvatarGroup, Chip, Css, Icon, IconButton, Tag } from "@homebound/beam";
import { formatDistance } from "date-fns";
import { useMemo } from "react";
import { CommentStreamVisibility, Inbox_CommentStreamFragment, InternalUser } from "src/generated/graphql-types";
import { sanitizeHtml } from "src/utils";

export type CommentStreamCardProps = {
  stream: Inbox_CommentStreamFragment;
  currentUser: InternalUser | null | undefined;
  onOpenStream: () => void;
  displayCommentableNames?: boolean;
};

export function CommentStreamCard({
  stream,
  currentUser,
  onOpenStream,
  displayCommentableNames,
}: CommentStreamCardProps) {
  const { latestComment, unreadCount, tradePartnerUsers, commentable } = stream;

  const avatars = useMemo(
    () =>
      [
        ...commentable.followers.map(({ name, avatar }) => ({ name, src: avatar })),
        ...tradePartnerUsers.map(({ name, user }) => ({ name, src: user.avatarUrl })),
      ].uniqueByKey("src"),
    [commentable.followers, tradePartnerUsers],
  );

  const [isTPAR, projectName, entityName] = useMemo(() => {
    if (commentable.__typename === "TradePartnerAvailabilityRequest") {
      return [true, commentable.task.project?.name, commentable.task.name];
    }
    if (commentable.__typename === "BidPackageRequest") {
      return [false, undefined, `Invitation to Bid: ${commentable.bidPackage.name}`];
    }
    return [false, (commentable as any)?.project?.name, stream.commentable.name];
  }, [stream.commentable]);

  return (
    <div css={Css.df.cursorPointer.$} onClick={onOpenStream}>
      <div css={Css.df.fg1.fdc.gap1.$}>
        <div css={Css.df.gap1.$}>
          {latestComment && (
            <div css={Css.df.fdc.w100.gap1.$}>
              <div data-testid={`commentStreamCard:${stream.id}`} css={Css.df.fdr.aic.gap1.smMd.$}>
                <span>
                  {latestComment.author.internalUser?.id === currentUser?.id ? "You" : latestComment.author.name}
                </span>
                {stream?.streamVisibility.code === CommentStreamVisibility.Trades && (
                  <Tag type="caution" text="TRADE" />
                )}
                <span css={Css.sm.gray700.$}>
                  {formatDistance(latestComment.createdAt, new Date(), { addSuffix: false })}
                </span>
                {isTPAR && <Icon icon="checkCircle" inc={2} />}
                {displayCommentableNames && projectName && (
                  <Chip text={projectName} title={projectName} compact={false} />
                )}
                {displayCommentableNames && entityName && <Chip text={entityName} title={entityName} compact={false} />}
              </div>
              <div
                css={Css.sm.lineClamp3.oh.$}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(latestComment.html) }}
              />
            </div>
          )}
        </div>
        <AvatarGroup avatars={avatars} />
      </div>
      <div css={Css.df.aic.$}>
        <div
          css={
            Css.wPx(20)
              .hPx(20)
              .df.aic.jcc.br100.if(unreadCount > 0).bgBlue700.else.bgBlue200.$
          }
        >
          <span css={Css.tinySb.white.$}>{unreadCount}</span>
        </div>
        <IconButton icon="chevronRight" label="View" onClick={onOpenStream} />
      </div>
    </div>
  );
}
