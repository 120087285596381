import { Css, useTestIds } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { createDesignCatalogUrl, createPlanPackageUrl } from "src/RouteUrls";
import {
  NamedFragment,
  ReadyPlanAssetType,
  ReviewStepQuery,
  SaveProductOfferingInput,
  useReviewStepQuery,
} from "src/generated/graphql-types";
import { ReadyOnlyOptionsTable } from "src/routes/developments/product-offerings/components/ReadOnlyOptionsTable";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { queryResult } from "src/utils";
import { openNewTab } from "src/utils/window";
import { ProductOfferingStepActions } from "./ProductOfferingStepActions";

type ReviewStepProps = {
  formState: ObjectState<Partial<SaveProductOfferingInput>>;
  planPackage: ReviewStepQuery["planPackage"];
  exteriorDesignPackage: NamedFragment;
  interiorDesignPackage: NamedFragment;
  rpos: ReviewStepQuery["readyPlanOptions"];
};
export function ReviewStep({ formState }: { formState: ReviewStepProps["formState"] }) {
  const query = useReviewStepQuery({
    variables: {
      planPackageId: formState.planPackageId.value || "",
      exteriorDesignPackageId: formState.exteriorDesignPackageId.value || "",
      interiorDesignPackageId: formState.interiorDesignPackageId.value || "",
      readyPlanOptionsFilter: {
        id: formState.externalReadyPlanOptions.value.map((rpo) => rpo.externalReadyPlanOptionId),
      },
    },
  });
  return queryResult(query, ({ planPackage, exteriorDesignPackage, interiorDesignPackage, readyPlanOptions }) => (
    <ReviewStepView
      formState={formState}
      planPackage={planPackage}
      exteriorDesignPackage={exteriorDesignPackage}
      interiorDesignPackage={interiorDesignPackage}
      rpos={readyPlanOptions}
    />
  ));
}

function ReviewStepView(props: ReviewStepProps) {
  const { planPackage, exteriorDesignPackage, interiorDesignPackage, formState, rpos } = props;
  const tid = useTestIds({});
  const elevationOptions = rpos.filter((rpo) => rpo.active && rpo.type.isElevation);
  const coverPhotoUrl =
    planPackage.assets?.find((asset) => asset?.type.code === ReadyPlanAssetType.CoverPhoto)?.asset.planPackagePreview ??
    PLAN_FALLBACK_IMG;

  return (
    <div>
      <PageHeader
        title={formState.name.value || ""}
        left={
          <div css={Css.df.fdc.gap1.ml("150px").$}>
            <div css={Css.xl3Bd.$}>{planPackage.name}</div>
            <div
              css={Css.base.blue600.onHover.cursorPointer.$}
              onClick={() => openNewTab(createPlanPackageUrl(planPackage.id))}
            >
              {planPackage.displayCode}
            </div>
          </div>
        }
        titleSize="xl3Bd"
      />
      <div css={Css.bshBasic.br12.df.fdr.bgWhite.$} {...tid.imageAsset}>
        <img src={coverPhotoUrl} alt="Product Offering cover photo" {...tid.coverPhoto} css={Css.w("38%").ha.$} />

        <div css={Css.ml8.pt8.$}>
          <div css={Css.xl2Sb.$}>Key Specs</div>
          <div css={Css.pt2.$}>
            <div css={Css.df.fdc.gap1.$}>
              <div css={Css.baseSb.$}>Elevations</div>
              {elevationOptions?.map((rpo) => (
                <div key={rpo.id} css={Css.sm.$}>
                  {rpo.displayName}
                </div>
              ))}
            </div>
          </div>

          <div css={Css.pt4.pb8.$}>
            <div css={Css.mb1.baseSb.$}>Linked Data</div>
            <div css={Css.gap3.df.fdr.smMd.$}>
              <div
                {...tid.planPackageName}
                css={Css.smMd.blue600.onHover.cursorPointer.$}
                onClick={() => openNewTab(createPlanPackageUrl(planPackage.id))}
              >
                {planPackage?.name}
              </div>
              <div
                {...tid.exteriorDesignPackageName}
                css={Css.smMd.blue600.onHover.cursorPointer.$}
                onClick={() => openNewTab(createDesignCatalogUrl(exteriorDesignPackage.id))}
              >
                {exteriorDesignPackage?.name}
              </div>
              <div
                {...tid.interiorDesignPackageName}
                css={Css.smMd.blue600.onHover.cursorPointer.$}
                onClick={() => openNewTab(createDesignCatalogUrl(interiorDesignPackage.id))}
              >
                {interiorDesignPackage?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div css={Css.w100.bgWhite.p4.my6.$}>
        <ReadyOnlyOptionsTable readyPlanOptions={rpos} />
      </div>
      <ProductOfferingStepActions formState={formState} />
    </div>
  );
}
