import { Css } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useKey } from "react-use";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { isDefined } from "src/utils";
import { CheckRow, NewDesignPackageOverviewPostit, NewDesignPackageWizardPage } from "./NewDesignPackageAtoms";
import { useNewDesignPackageContext } from "./NewDesignPackageContext";

export function NewDesignPackageOptions() {
  const { formState, globalOptionGroups } = useNewDesignPackageContext();

  useShiftAToSelectAll();

  return (
    <NewDesignPackageWizardPage>
      <NewDesignPackageOverviewPostit />
      <div css={Css.xl3.$}>Choose your Package Options</div>
      <div css={Css.base.$}>
        Package Options are the options our buyers select to let us know what style of home they’d like and how much
        they want to spend. Please select any you’d like to be able to utilize in this Design Package.
      </div>
      {globalOptionGroups.isEmpty && <div css={Css.xl.$}>Loading...</div>}
      {globalOptionGroups
        .filter((gog) => gog.globalOptions.nonEmpty)
        .map((gog) => (
          <div key={gog.id} css={Css.w100.$}>
            <div css={Css.xl2.mb2.$}>{gog.name}</div>
            {gog.globalOptions
              .filter((go) => !isDefined(go.archivedDate) || formState.withGlobalOptionIds.value?.includes(go.id))
              .sortBy((d) => d.defaultOrder ?? gog.globalOptions.length)
              .map((go, index, gos) => (
                <Observer key={go.id}>
                  {() => (
                    <CheckRow
                      label={go.name}
                      selected={!!formState.withGlobalOptionIds.value?.includes(go.id)}
                      onChange={(val) => {
                        if (val) {
                          formState.withGlobalOptionIds.value = [...formState.withGlobalOptionIds.value!, go.id];
                        } else {
                          formState.withGlobalOptionIds.value = formState.withGlobalOptionIds.value?.filter(
                            (id) => id !== go.id,
                          );
                        }
                      }}
                      bb={index !== gos.length - 1}
                    >
                      <div css={Css.df.fdc.$}>
                        <div css={Css.base.$}>
                          {go.name}{" "}
                          {go.designPackageAbbreviation && (
                            <span css={Css.sm.gray700.$}>({go.designPackageAbbreviation})</span>
                          )}
                        </div>
                      </div>
                    </CheckRow>
                  )}
                </Observer>
              ))}
          </div>
        ))}
      <NextStepButton exitButton label="Choose Package Upgrades" />
    </NewDesignPackageWizardPage>
  );
}

/** Enabled a Shift+A hotkey to enable all options on this page */
function useShiftAToSelectAll() {
  const { formState, globalOptionGroups } = useNewDesignPackageContext();
  // 'A' vs 'a' are different. `shift+a` isn't a thing. 'A' is though.
  useKey("A", (e) => {
    globalOptionGroups
      .flatMap((gog) => gog.globalOptions)
      .forEach((go) => {
        formState.withGlobalOptionIds.value = [...formState.withGlobalOptionIds.value!, go.id];
      });
  });
}
