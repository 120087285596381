import { useSnackbar } from "@homebound/beam";
import { ItApi_SaveItivResultFragment } from "src/generated/graphql-types";
import { DeleteConfirmationModal } from "src/routes/components/DeleteConfirmationModal";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

type DeleteItemTemplateVersionsProps = {
  itApi: ItemTemplateApi;
  selectedItems: string[];
  onDelete?: (result: ItApi_SaveItivResultFragment) => Promise<void>;
};

export function DeleteItemTemplateVersionsModal(props: DeleteItemTemplateVersionsProps) {
  const { selectedItems, onDelete, itApi } = props;
  const itemLabel = selectedItems.length === 1 ? "item" : "items";

  const { triggerNotice } = useSnackbar();
  const onDeleteConfirmed = async () => {
    // defaulting to cascade false for now - that option will be deprecated with the ItemSlot refactor anyway
    const { data } = await itApi.deleteItiv(selectedItems.map((id) => ({ id, remove: true })));

    if (onDelete && data && data.saveItemTemplateItemVersions) await onDelete(data.saveItemTemplateItemVersions);

    const deleteItems =
      data!.saveItemTemplateItemVersions.deleted.length + data!.saveItemTemplateItemVersions.removed.length;
    triggerNotice({
      message: `${deleteItems === 1 ? "Item has" : `${deleteItems} items have`} been deleted`,
      icon: "success",
    });
  };

  return (
    <DeleteConfirmationModal
      entityType="Item"
      confirmationMessage={`Are you sure you want to delete ${
        selectedItems.length === 1 ? "this" : `these ${selectedItems.length}`
      } ${itemLabel}? This action cannot be undone.`}
      onConfirmDelete={onDeleteConfirmed}
    />
  );
}
