import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useSnackbar } from "@homebound/beam";
import {
  CohortDevelopmentDetailsFragment,
  DevelopmentProjectDetailFragment,
  ProjectsForLotSequenceFragment,
  useUpdateProjectCohortMutation,
} from "src/generated/graphql-types";

type MoveModalProps = {
  cohortDevelopment: CohortDevelopmentDetailsFragment;
  project: ProjectsForLotSequenceFragment | DevelopmentProjectDetailFragment;
};

export function MoveProjectModal({ cohortDevelopment, project }: MoveModalProps) {
  const { closeModal } = useModal();
  const { name: projectName, cohort } = project;
  const { id: cohortId, name: cohortName, development } = cohortDevelopment;
  const [updateProjectCohort] = useUpdateProjectCohortMutation();
  const { triggerNotice } = useSnackbar();

  // If we're moving a project to a cohort within the same development, then our PRPC will not be reset
  const willResetConfig = development?.id !== cohort?.development?.id;

  async function onSubmit() {
    await updateProjectCohort({
      variables: { input: { id: project.id, cohortId } },
      update: (cache, { data }) => {
        // Update the project's cohort in the cache
        cache.modify({
          id: cache.identify(project),
          fields: {
            cohort() {
              return data?.saveProject?.project.id ?? null;
            },
          },
        });
      },
    });
    closeModal();
    triggerNotice({ message: `Project ${projectName} moved to ${development?.name}`, icon: "success" });
  }

  return (
    <>
      <ModalHeader>Are you sure you want to move this project?</ModalHeader>
      <ModalBody>
        You're moving
        <span css={Css.smBd.$}> {projectName} </span>
        from
        <span css={Css.smBd.$}> {cohort?.development?.name}</span>
        <span css={Css.smBd.$}> - {cohort?.name} </span>
        to
        <span css={Css.smBd.$}> {development?.name}</span>
        <span css={Css.smBd.$}> - {cohortName}. </span>
        {willResetConfig && <span>This will reset the project configuration. </span>}
        Are you sure you want to continue?
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={() => closeModal()} />
        <Button label="Move project" onClick={onSubmit} />
      </ModalFooter>
    </>
  );
}
