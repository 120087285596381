import {
  collapseColumn,
  column,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  simpleHeader,
  useComputed,
  useTestIds,
} from "@homebound/beam";
import { useMemo } from "react";
import { markupCell, MarkupSummary, priceCell } from "src/components";
import {
  CostCodeFragment,
  CostDivisionFragment,
  HomeownerContractChangeOrderWithProjectItemsFragment,
  HomeownerContractLineItemFragment,
  HomeownerContractWithProjectItemsFragment,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { costTypeToNameMapper } from "src/utils";
import { CostData, groupLineItemsByDivisionAndCostCode, lineItemTotals } from "./lineItemsTableCalc";

export type LineItemsTableProps = {
  contract: HomeownerContractWithProjectItemsFragment | HomeownerContractChangeOrderWithProjectItemsFragment;
  textFilter?: string;
};

export function LineItemsTable(props: LineItemsTableProps) {
  const { contract, textFilter = "" } = props;
  const testIds = useTestIds({}, "lineItemsTable");
  const { clientNoun } = useProjectContext();
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => createColumns(clientNoun), []);
  const rows = useComputed(() => createLineItemRows(contract), [contract]);

  return (
    <div {...testIds}>
      <GridTable
        rows={rows}
        columns={columns}
        style={{ grouped: true, rowHeight: "fixed", allWhite: true, bordered: true }}
        filter={textFilter}
        stickyHeader
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type TotalsRow = { kind: "totals"; data: CostData };
type DivisionRow = { kind: "division"; data: { costDivision: CostDivisionFragment; costData: CostData } };
type CostCodeRow = { kind: "costCode"; data: { costCode: CostCodeFragment; costData: CostData } };
type LineItemRow = { kind: "lineItem"; data: HomeownerContractLineItemFragment };
type LineItemTableRow = HeaderRow | TotalsRow | DivisionRow | CostCodeRow | LineItemRow;

function createLineItemRows(
  contract: HomeownerContractWithProjectItemsFragment | HomeownerContractChangeOrderWithProjectItemsFragment,
): GridDataRow<LineItemTableRow>[] {
  const groupByDivision = groupLineItemsByDivisionAndCostCode(contract);
  return [
    { kind: "totals" as const, id: "totals", data: lineItemTotals(contract.lineItems) },
    simpleHeader,
    ...groupByDivision.map((summary) => {
      const { division, total, costCodes } = summary;
      return {
        kind: "division" as const,
        id: division.id,
        data: {
          costDivision: division,
          costData: total,
        },
        children: costCodes.map(({ costCode, total, lineItems }) => {
          return {
            kind: "costCode" as const,
            id: costCode.id,
            data: {
              costCode,
              costData: total,
            },
            children: lineItems.map((li) => {
              return { kind: "lineItem" as const, id: li.id, data: li };
            }),
          };
        }),
      };
    }),
  ];
}

function createColumns(clientNoun: string): GridColumn<LineItemTableRow>[] {
  const nameColumn = column<LineItemTableRow>({
    header: emptyCell,
    totals: () => "Totals",
    division: ({ costDivision }) => ({
      content: <div data-testid="divisionName">{costDivision.displayName}</div>,
      colspan: 4,
    }),
    costCode: ({ costCode }) => ({ content: <div data-testid="costCodeName">{costCode.displayName}</div>, colspan: 4 }),
    lineItem: ({ projectItem }) => <div data-testid="lineItemName">{projectItem.displayName}</div>,
  });

  const costTypeColumn = column<LineItemTableRow>({
    header: "Cost Type",
    totals: emptyCell,
    division: "",
    costCode: "",
    lineItem: ({ projectItem }) => <span data-testid="costtype">{costTypeToNameMapper[projectItem.costType]}</span>,
    w: "100px",
  });

  const quantityColumn = numericColumn<LineItemTableRow>({
    header: "Quantity",
    totals: emptyCell,
    division: "",
    costCode: "",
    lineItem: ({ projectItem, quantity }) => (
      <span data-testid="quantity">{projectItem.unitOfMeasure?.useQuantity === false ? "N/A" : quantity}</span>
    ),
    w: "100px",
  });

  const unitOfMeasureColumn = column<LineItemTableRow>({
    header: "Unit",
    totals: emptyCell,
    division: "",
    costCode: "",
    lineItem: ({ projectItem }) => <span data-testid="unit">{projectItem.unitOfMeasure?.name}</span>,
    w: "120px",
  });

  const budgetColumn = numericColumn<LineItemTableRow>({
    header: () => "Budgeted Cost",
    totals: ({ budgetChangeInCents }) => priceCell({ valueInCents: budgetChangeInCents }),
    division: ({ costData }) => priceCell({ valueInCents: costData.budgetChangeInCents }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.budgetChangeInCents }),
    lineItem: ({ budgetChangeInCents }) => priceCell({ valueInCents: budgetChangeInCents }),
    w: "160px",
  });

  const markupColumn = numericColumn<LineItemTableRow>({
    header: "Markup",
    totals: ({ budgetChangeInCents, priceChangeInCents }) => (
      <MarkupSummary dropZero budget={budgetChangeInCents || 0} price={priceChangeInCents || 0} />
    ),
    division: ({ costData }) => markupCell(costData.budgetChangeInCents || 0, costData.priceChangeInCents || 0),
    costCode: ({ costData }) => markupCell(costData.budgetChangeInCents || 0, costData.priceChangeInCents || 0),
    lineItem: ({ budgetChangeInCents, priceChangeInCents }) =>
      markupCell(budgetChangeInCents || 0, priceChangeInCents || 0),
    w: "200px",
  });

  const priceColumn = numericColumn<LineItemTableRow>({
    header: () => `${clientNoun} Price`,
    totals: ({ priceChangeInCents }) => priceCell({ valueInCents: priceChangeInCents }),
    division: ({ costData }) => priceCell({ valueInCents: costData.priceChangeInCents }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.priceChangeInCents }),
    lineItem: ({ priceChangeInCents }) => priceCell({ valueInCents: priceChangeInCents }),
    w: "160px",
  });

  return [
    collapseColumn<LineItemTableRow>(),
    nameColumn,
    costTypeColumn,
    quantityColumn,
    unitOfMeasureColumn,
    budgetColumn,
    markupColumn,
    priceColumn,
  ];
}
