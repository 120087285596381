import { Css, DnDGridItemHandle, DnDGridItemProps, IconButton, useTestIds } from "@homebound/beam";
import { TileBaseProps } from "src/routes/projects/dashboard/components/Tile";

type EditTileActionsProps = TileBaseProps & {
  dragHandleProps: DnDGridItemProps["dragHandleProps"];
};
export function EditTileActions({ userDashboardConfig, removeTile, dragHandleProps }: EditTileActionsProps) {
  // const { size } = config.value;
  const tid = useTestIds({}, "editActions");
  return (
    <div css={Css.df.aic.gap1.fs0.$} {...tid}>
      {/* TODO: Support various sizes of tiles
      <ButtonGroup
       size="sm"
       buttons={[
         {
           text: "S",
           active: size === "SMALL",
           onClick: () => updateSize(config, "SMALL"),
         },
         {
           text: "M",
           active: size === "MEDIUM",
           onClick: () => updateSize(config, "MEDIUM"),
         },
         {
           text: "L",
           active: size === "LARGE",
           onClick: () => updateSize(config, "LARGE"),
         },
       ]}
      /> */}
      <DnDGridItemHandle dragHandleProps={dragHandleProps} />
      <IconButton icon="trash" onClick={() => removeTile(userDashboardConfig)} />
    </div>
  );
}
