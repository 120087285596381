import { Css } from "@homebound/beam";
import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { HasChildren } from "src/utils";
import { arePropsEqual } from "src/utils/performance";

export type KanbanColumn<Item extends KanbanItem> = { id: string; items: Item[] };
export type KanbanItem = { id: string };
export type OnItemMoveFnOpts<Item> = { columnId?: string; below?: Item; above?: Item };
export type OnItemMoveFn<Item> = (item: Item, opts: OnItemMoveFnOpts<Item>) => void;
export type OnItemClick<Item> = (item: Item) => void;

type KanbanBoardProps<Column extends KanbanColumn<Item>, Item extends KanbanItem> = {
  columns: Column[];
  onItemMove: OnItemMoveFn<Item>;
  onItemClick: OnItemClick<Item>;
  renderColumnHeader: (column: Column) => React.ReactNode;
  renderCard: (props: Item) => React.ReactNode;
  renderFooterComponent?: (column: Column) => React.ReactNode;
};

export function KanbanBoard<Column extends KanbanColumn<Item>, Item extends KanbanItem>(
  props: KanbanBoardProps<Column, Item>,
) {
  const { onItemMove, columns } = props;
  // Map columns by id for lookup in drag events
  const columnsById = Object.fromEntries(columns.map((col) => [col.id, col]));

  /** See if the drag was successful and update the local/remote state appropriately */
  async function onDragEnd(result: DropResult) {
    const { destination, source } = result;

    // Drag aborted
    if (!destination) {
      return;
    }

    // Dragged back to the original location
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const opts = {} as OnItemMoveFnOpts<Item>;
    const item = columnsById[source.droppableId].items[source.index];
    const items = columnsById[destination.droppableId].items;
    const displacedItem = items[destination.index];

    if (destination.droppableId !== source.droppableId) {
      // Move across columns
      opts["columnId"] = destination.droppableId;
      if (displacedItem) {
        // If we have a displaced item, then we always place the moving item above it
        opts["above"] = displacedItem;
      } else if (items.length > 0) {
        // If the column isn't empty and there's no displaced item, then we're inserting at the bottom of the list
        opts["below"] = items[items.length - 1];
      }
      // We don't need to move if the column is empty
    } else if (displacedItem !== undefined) {
      // Move within column. This is dependent on the direction we are moving. If we're moving downwards, then we move
      // below the displaced item. Otherwise, move above it.
      opts[destination.index > source.index ? "below" : "above"] = displacedItem;
    }

    onItemMove(item, opts);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div css={Css.gap1.df.h("inherit").$}>
        {columns.map((column) => (
          <BoardColumn key={column.id} column={column} boardProps={props} />
        ))}
      </div>
    </DragDropContext>
  );
}

type BoardColumnProps<Column extends KanbanColumn<Item>, Item extends KanbanItem> = {
  column: Column;
  boardProps: KanbanBoardProps<Column, Item>;
};

function BoardColumn<Column extends KanbanColumn<Item>, Item extends KanbanItem>(
  props: BoardColumnProps<Column, Item>,
) {
  const { column, boardProps } = props;
  const { id, items } = column;
  const { renderColumnHeader, renderCard, onItemClick, renderFooterComponent } = boardProps;
  return (
    <div css={Css.bgGray100.br4.fa.df.fdc.p1.w("100px").$}>
      {/* Width is ignored but needs to be set to ensure same column width */}
      {renderColumnHeader(column)}
      <Droppable droppableId={id}>
        {(provided) => (
          <div css={Css.df.fdc.gap1.oys.h100.$} ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <BoardCard key={item.id} index={index} item={item} columnId={id} onItemClick={onItemClick}>
                {renderCard(item)}
              </BoardCard>
            ))}
            {provided.placeholder}
            {renderFooterComponent && (
              <FooterCard itemLength={items.length}>{renderFooterComponent(column)}</FooterCard>
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}

const FooterCard = React.memo((props: HasChildren & { itemLength: number }) => {
  // Inserting a div without making it 'draggable' only broke the UI,
  // so I followed the methodology in this issue: https://github.com/atlassian/react-beautiful-dnd/issues/167
  const { children } = props;
  return (
    <Draggable draggableId={"footer-card"} index={props.itemLength} isDragDisabled={true}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {children}
        </div>
      )}
    </Draggable>
  );
}, arePropsEqual);

type BoardCardProps<Item extends KanbanItem> = {
  item: Item;
  index: number;
  columnId: string;
  onItemClick: OnItemClick<Item>;
} & HasChildren;

const BoardCard = React.memo(<Item extends KanbanItem>(props: BoardCardProps<Item>) => {
  const { item, index, children, columnId, onItemClick } = props;
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          data-testid={`${columnId}-boardCard`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => onItemClick(item)}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}, arePropsEqual) as <Item extends KanbanItem>(props: BoardCardProps<Item>) => JSX.Element;
