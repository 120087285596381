import { Css, Margin, Only, Padding, useTestIds, Xss } from "@homebound/beam";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export function Card<X extends Only<Xss<Margin | Padding | "display" | "outline" | "width" | "minHeight">, X>>(props: {
  children: ReactNode;
  xss?: X;
  onClick?: VoidFunction | string;
}) {
  const { children, xss, onClick } = props;
  const testIds = useTestIds(props);
  const sharedStyles = Css.bgWhite.bshBasic.ba.bcGray200.p2.br8.oh.$;
  const clickableStyles = { ...sharedStyles, ...Css.w100.ta("left").onHover.bshHover.$ };

  // Assume links are internal for this component, if we need to support external links we can copy/import beam's `isAbsoluteUrl` logic
  if (typeof onClick === "string") {
    return (
      <Link
        css={{ ...clickableStyles, ...Css.db.$, ...xss }}
        // Resets the default anchor tag styles
        className="navLink"
        to={onClick}
        {...testIds}
      >
        {children}
      </Link>
    );
  }

  if (onClick) {
    return (
      <button css={{ ...clickableStyles, ...xss }} onClick={onClick} {...testIds}>
        {children}
      </button>
    );
  }

  return <div css={{ ...sharedStyles, ...xss }}>{children}</div>;
}
