import { IconButton, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

type ExitIconButtonProps<T> = {
  formState: ObjectState<T>;
  confirmUrl: string;
};

export function ExitIconButton<T>({ formState, confirmUrl }: ExitIconButtonProps<T>) {
  const history = useHistory();
  const { openModal } = useModal();

  return (
    <IconButton
      data-testid="exit"
      icon="x"
      inc={5}
      onClick={() => {
        if (formState.dirty) {
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage="You have unsaved changes that will be lost. Do you want to discard these changes?"
                onConfirmAction={() => history.push(confirmUrl)}
                title="Discard changes?"
                label="Discard changes"
                danger
              />
            ),
          });
        } else {
          history.push(confirmUrl);
        }
      }}
    />
  );
}
