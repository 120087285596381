import { multiFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useMarketFilterMetadataQuery } from "src/generated/graphql-types";

export function useMarketFilter() {
  const { data } = useMarketFilterMetadataQuery({ fetchPolicy: "cache-first" });
  const marketFilter = useMemo(
    () =>
      multiFilter({
        options: data?.markets ?? [],
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      }),
    [data],
  );

  return { marketFilter, options: data?.markets ?? [] };
}
