import { useEffect, useState } from "react";
import {
  MaterialAssemblyForm_MaterialVariantFragment,
  MaterialAssemblyForm_MvPageInfoFragment,
  MaterialVariantsFilter,
  useMaterialAssemblyForm_MaterialVariantsLazyQuery,
} from "src/generated/graphql-types";

/** Lazy load all Material Variants*/
export function useLazyMaterialVariants(filter: MaterialVariantsFilter) {
  const [loadVariants] = useMaterialAssemblyForm_MaterialVariantsLazyQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  const [pageInfo, setPageInfo] = useState<MaterialAssemblyForm_MvPageInfoFragment | undefined>();
  const [variants, setVariants] = useState<MaterialAssemblyForm_MaterialVariantFragment[]>([]);

  // Load all material variants, page by page
  useEffect(() => {
    // Stop loading if there are no more pages
    if (!!pageInfo && !pageInfo.hasNextPage) {
      return;
    }
    void (async () => {
      const { data } = await loadVariants({
        variables: {
          filter,
          page: {
            limit: 500,
            offset: pageInfo?.nextPage?.offset ?? 0,
          },
        },
      });

      if (data?.materialVariants.entities) {
        setVariants((prev) => prev.concat(data.materialVariants.entities));
      }

      if (data?.materialVariants.pageInfo) {
        setPageInfo(data.materialVariants.pageInfo);
      }
    })();
  }, [pageInfo]);

  return variants;
}
