import { Css } from "@homebound/beam";
import { HbLoadingSpinner } from "src/components/HbLoadingSpinner";

// TODO: This is barely being used. Might just want to use HbLoadingSpinner directly
export function Loading() {
  return (
    <div css={Css.df.aic.jcc.h100.$}>
      <HbLoadingSpinner />
    </div>
  );
}
