import { BoundCheckboxField, BoundTextAreaField, Button, Css, IconButton, useComputed } from "@homebound/beam";
import { ListFieldState, ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { IncrementalCollectionOp, SaveTodoChecklistItem } from "src/generated/graphql-types";

export type ToDoChecklistProps = {
  field: ListFieldState<SaveTodoChecklistItem>;
  isComplete?: boolean;
  isNewToDo?: boolean;
};

export function ToDoChecklist({ field, isComplete, isNewToDo }: ToDoChecklistProps) {
  const addItem = useCallback(() => {
    field.add({ op: IncrementalCollectionOp.Include, description: "" });
  }, [field]);

  const removeItem = useCallback(
    (checklistItem: ObjectState<SaveTodoChecklistItem>) => {
      if (checklistItem.originalValue.id) {
        checklistItem.op.value = IncrementalCollectionOp.Delete;
      } else {
        field.remove(checklistItem.value);
      }
    },
    [field],
  );

  /** Because the ToDoModal form switches to auto-save after the initial save, we want to avoid
   * allowing the user to add a new item before the previous item is saved. Because the form re-initializes with the new values,
   * we can accidentally clobber the unsaved listing item when moving quickly.
   */
  const shouldDisableAddButton = useComputed(() => {
    if (isComplete) return "Cannot add items to a completed To-Do.";
    if (!isNewToDo && field.dirty) return true;
    return false;
  }, [isNewToDo, isComplete, field]);

  return (
    <Observer>
      {() => (
        <div css={Css.mhPx(200).$}>
          {field.rows.map((checklistItem) => (
            <div key={checklistItem.id.value ?? "new"} css={Css.df.aic.gap2.mb1.$}>
              <BoundCheckboxField field={checklistItem.isComplete} checkboxOnly />
              <BoundTextAreaField
                field={checklistItem.description}
                labelStyle="hidden"
                preventNewLines
                // Only auto focus when adding a new item
                autoFocus={!checklistItem.originalValue.id}
                disabled={checklistItem.isComplete.value}
              />
              <IconButton
                compact
                icon="trash"
                onClick={() => removeItem(checklistItem)}
                disabled={checklistItem.isComplete.value}
                data-testid="deleteChecklistItem"
              />
            </div>
          ))}
          {field.rows.isEmpty && (
            <div data-testid="noChecklistItems" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
              There are no checklist items on this To-Do
            </div>
          )}
          <div css={Css.mt2.$}>
            <Button
              label="+ Add an item"
              variant="tertiary"
              data-testid="addChecklistItem"
              onClick={addItem}
              disabled={shouldDisableAddButton}
            />
          </div>
        </div>
      )}
    </Observer>
  );
}
