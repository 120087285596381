import { BoundSelectField, BoundTextAreaField, BoundTextField, Css, FormLines } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import {
  Development,
  SaveProductOfferingInput,
  useOfferingDetailsStep_DevelopmentsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ProductOfferingStepActions } from "./ProductOfferingStepActions";

type OfferingDetailsStepProps = {
  developments: Partial<Development>[];
  formState: ObjectState<SaveProductOfferingInput>;
};

export function OfferingDetailsStep({ formState }: { formState: OfferingDetailsStepProps["formState"] }) {
  const query = useOfferingDetailsStep_DevelopmentsQuery();
  return queryResult(query, ({ developments }) => (
    <OfferingDetailsStepView formState={formState} developments={developments} />
  ));
}

function OfferingDetailsStepView({ formState, developments }: OfferingDetailsStepProps) {
  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.xl3Sb.$}>Create New Offering</div>
        <div css={Css.base.pt2.$}>Review the details below inherited from the base plan and modify as needed.</div>
        <div css={Css.base.pbPx(60).$}>Changes made here will not impact the base plan.</div>
        <div css={Css.w50.p4.bgWhite.bshBasic.br12.$}>
          <div css={Css.lgSb.mb2.$}>Product Offering Overview</div>
          <FormLines labelStyle="left" compact width="full">
            <BoundTextField label="Offering Name" field={formState.name} />
            <BoundSelectField
              label="Development"
              options={developments}
              field={formState.developmentId}
              getOptionLabel={(d) => d.name ?? ""}
              getOptionValue={(d) => d.id ?? ""}
            />
            <BoundTextAreaField label="Offering Description" field={formState.description} />
          </FormLines>
        </div>
      </div>
      <ProductOfferingStepActions />
    </>
  );
}
