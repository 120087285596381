import {
  Avatar,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridStyle,
  GridTable,
  Palette,
  useBreakpoint,
  useTestIds,
} from "@homebound/beam";
import { formatDate } from "src/components";
import { Card } from "src/components/Card";
import { History, HistoryFeedInfoFragment } from "src/generated/graphql-types";
import { sanitizeHtml, sortBy } from "src/utils";

type HistoryProps = {
  historyItems: History[];
};

export function BillHistory({ historyItems }: HistoryProps) {
  const testIds = useTestIds({}, "historyFeed");
  const { sm } = useBreakpoint();

  return (
    <Card>
      {!historyItems.length ? (
        <span css={Css.mt1.$} {...testIds.empty}>
          No history available
        </span>
      ) : (
        <div {...testIds}>
          <GridTable
            rows={createHistoryRows(sortBy(historyItems, (historyItem) => -historyItem.recordedAt))}
            columns={historyColumns(sm)}
            style={compact}
          />
        </div>
      )}
    </Card>
  );
}

const compact: GridStyle = {
  rootCss: Css.dig.xs.gray900.w100.$,
  cellCss: Css.py(0.5).sm.$,
  rowHoverColor: Palette.Gray200,
};

type HistoryRow = { kind: "data"; id: string; data: HistoryFeedInfoFragment };

export const historyColumns: (sm?: boolean) => GridColumn<HistoryRow>[] = (sm) => [
  column<HistoryRow>({
    data: (data) => {
      const titleSplit = data.title.split(" ");
      const name = titleSplit[0] === "Blueprint" ? titleSplit[0] : [titleSplit[0], titleSplit[1]].join(" ");
      return <Avatar name={name} src={data.avatar} />;
    },
    w: sm ? 0.5 : 0.2,
  }),
  column<HistoryRow>({
    data: (data) => {
      const hasHTMLContent = /<\/?[a-z][\s\S]*>/i.test(data.subtitle || "");
      return (
        <div css={Css.gray700.$}>
          <div css={Css.mb1.xsMd.$}>{data.title}</div>
          {hasHTMLContent ? (
            <div
              css={Css.xs.wbbw.pb2.df.fdc.aifs.$}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.subtitle || "") }}
            />
          ) : (
            <div css={Css.xs.wbbw.$}>{data.subtitle}</div>
          )}
        </div>
      );
    },
    w: 1,
  }),
  column<HistoryRow>({
    data: (data) => ({
      alignment: "right",
      content: <div css={Css.gray700.tinySb.$}>{formatDate(data.recordedAt, "short", "short")}</div>,
    }),
    w: 1,
  }),
];

function createHistoryRows(historyItems: History[]): GridDataRow<HistoryRow>[] {
  return historyItems.map((history, index) => ({ kind: "data", id: index.toString(), data: history }));
}
