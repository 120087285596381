import { Css, ScrollableParent, Step, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { StepperProvider, stepperBarHeight, useStepperContext } from "src/components/stepper";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import { FeatureFlagType, ScheduleDraftMode_PlanTaskFragment } from "src/generated/graphql-types";
import { useHiddenProjectSideNav } from "src/hooks";
import { foldEnum } from "src/utils";
import { DelayFlagPublishStep } from "./DelayFlagPublishStep";
import { TradeConfirmPublishStep } from "./TradeConfirmPublishStep";

type PublishDraftScheduleStepperProps = {
  scheduleParentId: string;
  draftTasks: ScheduleDraftMode_PlanTaskFragment[];
};

enum PublishDraftScheduleStep {
  DelayFlags = "DelayFlags",
  TradeConfirm = "TradeConfirm",
}

export function PublishDraftScheduleStepper({ scheduleParentId, draftTasks }: PublishDraftScheduleStepperProps) {
  useHiddenProjectSideNav();

  const tids = useTestIds({}, "publishDraftScheduleStepper");
  const showTradeComms = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);

  const initialSteps: Step[] = useMemo(
    () => [
      {
        label: "Add Delay Flags",
        disabled: false,
        state: "incomplete" as const,
        value: PublishDraftScheduleStep.DelayFlags,
      },
      ...(showTradeComms
        ? [
            {
              label: "Email Trades",
              disabled: false,
              state: "incomplete" as const,
              value: PublishDraftScheduleStep.TradeConfirm,
            },
          ]
        : []),
    ],
    [showTradeComms],
  );

  return (
    <div {...tids}>
      <StepperProvider steps={initialSteps}>
        <ScrollableParent xss={Css.mbPx(stepperBarHeight).$}>
          <PublishDraftScheduleStepperView scheduleParentId={scheduleParentId} draftTasks={draftTasks} />
        </ScrollableParent>
      </StepperProvider>
    </div>
  );
}

function PublishDraftScheduleStepperView({ scheduleParentId, draftTasks }: PublishDraftScheduleStepperProps) {
  const { currentStep } = useStepperContext();
  const showTradeComms = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);

  return (
    <>
      {foldEnum(currentStep.value, {
        [PublishDraftScheduleStep.DelayFlags]: (
          <DelayFlagPublishStep draftTasks={draftTasks} scheduleParentId={scheduleParentId} />
        ),
        ...(showTradeComms && {
          [PublishDraftScheduleStep.TradeConfirm]: (
            <TradeConfirmPublishStep scheduleParentId={scheduleParentId} draftTasks={draftTasks} />
          ),
        }),
      })}
    </>
  );
}
