import { useModal } from "@homebound/beam";
import { useCallback } from "react";
import {
  DevelopmentBulkScheduleSaveScheduleTaskMutation,
  ResultingDelayResponseFragment,
  SaveGanttScheduleTasksMutation,
  SaveScheduleTasksMutation,
} from "src/generated/graphql-types";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { ScheduleFlagModal } from "src/routes/projects/schedule-v2/detail-pane/scheduleFlags/ScheduleFlagModal";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";

type DelayTriggeringMutation =
  | DevelopmentBulkScheduleSaveScheduleTaskMutation
  | SaveGanttScheduleTasksMutation
  | SaveScheduleTasksMutation;
// Hook that returns a callback capable of opening a delay flag prompt modal on completion of a saveScheduleTasks mutation
export function useDelayFlagModal(): {
  openDelayFlagModal: (resultingDelay: ResultingDelayResponseFragment) => void;
  onCompleted: (result: DelayTriggeringMutation) => void;
} {
  const { openModal } = useModal();
  const {
    scheduleState: { scheduleType },
  } = useScheduleStore();

  const openDelayFlagModal = useCallback(
    (resultingDelay: ResultingDelayResponseFragment) => {
      if (scheduleType === ScheduleType.Project) {
        // If there is no original duration, the task is newly created. In this situation, the delay's duration should be the duration of the new task
        openModal({
          content: <ScheduleFlagModal resultingDelay={resultingDelay} />,
        });
      }
    },
    [openModal, scheduleType],
  );

  function onCompleted(result: DelayTriggeringMutation) {
    if (result.saveScheduleTasks.resultingDelays[0]) {
      return openDelayFlagModal(result.saveScheduleTasks.resultingDelays[0]);
    }
  }

  return { onCompleted, openDelayFlagModal };
}

export function delayOnComplete(
  result: DelayTriggeringMutation,
  onCompleted: (resultingDelay: ResultingDelayResponseFragment) => void,
) {
  if (result.saveScheduleTasks.resultingDelays[0]) {
    return onCompleted(result.saveScheduleTasks.resultingDelays[0]);
  }
}
