import {
  Button,
  Css,
  FilterDefs,
  Filters,
  LoadingSkeleton,
  multiFilter,
  toggleFilter,
  useComputed,
  useGridTableApi,
  usePersistedFilter,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { createDevelopmentReviewCostsUrl } from "src/RouteUrls";
import { SearchBox } from "src/components";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  DevelopmentScopeDevelopmentFragment,
  FeatureFlagType,
  ItemTemplateFilter,
  ItemTemplateStatus,
  Maybe,
  Stage,
  useDevelopmentScopeTemplatesMetadataQuery,
  useDevelopmentScopeTemplatesQuery,
} from "src/generated/graphql-types";
import {
  DevelopmentScopeTemplatesTable,
  DevelopmentScopeTemplatesTableRow,
} from "src/routes/developments/templates/DevelopmentScopeTemplatesTable";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { queryResult } from "src/utils";
import { AssignElevationsModalButton } from "../components/AssignElevationsModalButton";
import { CostMappingSummaryCard } from "./components/CostMappingSummaryCard";
import { DevelopmentScopeTemplateButtonMenu } from "./components/DevelopmentScopeTemplateButtonMenu";

export function DevelopmentScopeTemplatesPage() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const metadataQuery = useDevelopmentScopeTemplatesMetadataQuery({
    variables: { developmentId },
    fetchPolicy: "cache-and-network",
  });
  return queryResult(metadataQuery, {
    data: ({ development }) => <DevelopmentScopeTemplates development={development} />,
  });
}

type DevelopmentScopeTemplatesProp = {
  development: DevelopmentScopeDevelopmentFragment;
};

function DevelopmentScopeTemplates({ development }: DevelopmentScopeTemplatesProp) {
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const { costMappingProgress } = development;
  const filterDefs: FilterDefs<ItemTemplateFilterWithArchive> = useMemo(() => {
    const status = multiFilter({
      label: "Status",
      options: [
        { name: "Active", code: ItemTemplateStatus.Active },
        { name: "Draft", code: ItemTemplateStatus.Draft },
      ],
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ code }) => code,
    });
    const archived = toggleFilter({ label: "Show Archived" });
    return { status, archived };
  }, []);

  const { filter, setFilter } = usePersistedFilter<ItemTemplateFilterWithArchive>({
    // ensure filter storage key is unique to this development as the filterable options change per development.
    storageKey: `developmentTemplateFilter_${development.id}`,
    filterDefs,
  });

  const query = useDevelopmentScopeTemplatesQuery({
    variables: { filter: { development: [development.id], isLatest: true, ...mapToFilter(filter) } },
    fetchPolicy: "cache-and-network",
  });

  const digitalBuildingExperimentsEnabled = useFeatureFlag(FeatureFlagType.DigitalBuildingExperiments);

  const { totalScheduleTemplateItemMappings, totalMappingsForDevelopment, showCostMappingProgress } =
    costMappingProgress;

  const onSave = useCallback(() => query.refetch(), [query]);

  const fallbackMessage = query.loading
    ? "Loading..."
    : searchFilter?.length
      ? `There are no scope templates that match "${searchFilter}".`
      : Object.keys(filter).length > 0
        ? "There are no scope templates that match the applied filters."
        : "There are no scope templates for this development yet.";

  const tableApi = useGridTableApi<DevelopmentScopeTemplatesTableRow>();

  const selectedLineItemIds = useComputed(() => tableApi.getSelectedRowIds("itemTemplate"), [tableApi]);

  return (
    <>
      <PageHeader
        title="Templates"
        right={
          <>
            {digitalBuildingExperimentsEnabled && (
              <Button
                variant="tertiary"
                label="Cost comparison 🧪"
                onClick={createDevelopmentReviewCostsUrl(development.id)}
              />
            )}
            <AssignElevationsModalButton onSave={onSave} />
          </>
        }
      />
      {showCostMappingProgress && (
        <div css={Css.mb4.df.$}>
          <CostMappingSummaryCard
            progress={Math.round((totalScheduleTemplateItemMappings / totalMappingsForDevelopment) * 100)}
            progressBarText={"complete"}
            developmentId={development.id}
            mapCount={`${totalScheduleTemplateItemMappings}/${totalMappingsForDevelopment}`}
          />
        </div>
      )}

      {queryResult(query, {
        // `loading` will only be displayed on initial load. Any subsequent "loading" of the query (when the filters change) will not show this state.
        loading: () => (
          <>
            <LoadingSkeleton rows={1} columns={1} />
            <LoadingSkeleton rows={5} columns={5} />
          </>
        ),
        data: ({ itemTemplates }) => (
          <>
            <TableActions>
              <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} />
              <div css={Css.df.gap1.$}>
                <SearchBox onSearch={setSearchFilter} />
                <DevelopmentScopeTemplateButtonMenu
                  developmentId={development.id}
                  itemTemplates={itemTemplates.filter((it) =>
                    selectedLineItemIds.some((selectedIt) => it.id === selectedIt),
                  )}
                  tableRefetch={() => query.refetch()}
                />
              </div>
            </TableActions>
            <DevelopmentScopeTemplatesTable
              onSave={onSave}
              searchFilter={searchFilter}
              developmentId={development.id}
              itemTemplates={itemTemplates}
              fallbackMessage={fallbackMessage}
              tableApi={tableApi}
            />
          </>
        ),
      })}
    </>
  );
}

function mapToFilter(filter: ItemTemplateFilterWithArchive): ItemTemplateFilter {
  const { archived, status, ...others } = filter;
  const defaultStatus = [ItemTemplateStatus.Active, ItemTemplateStatus.Draft];
  const isArchived: ItemTemplateStatus[] = archived ? [ItemTemplateStatus.Archived] : [];
  let selectedStatus: ItemTemplateStatus[] = [];

  if (status) selectedStatus = [...status];

  return {
    ...others,
    stage: [Stage.Construction],
    status: [
      ...(isArchived.nonEmpty
        ? [...isArchived, ...(selectedStatus.length <= 0 ? defaultStatus : selectedStatus)]
        : selectedStatus.length > 0
          ? selectedStatus
          : defaultStatus),
    ],
  };
}

type ItemTemplateFilterWithArchive = ItemTemplateFilter & {
  archived: Maybe<boolean>;
};
