import { BoundRichTextField, Css } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { ReactNode } from "react";
import { Card } from "src/components/Card";
import {
  SaveDevelopmentCommitmentInput,
  useDevelopmentCommitmentScheduleAndScopeTabQuery,
  useSaveDevelopmentCommitmentMutation,
} from "src/generated/graphql-types";
import { hasData, renderLoadingOrError } from "src/utils";

export type ScheduleAndScopeTabProps = {
  developmentCommitmentId: string;
  showSummary?: boolean;
};

export function ScheduleAndScopeTab({ developmentCommitmentId }: ScheduleAndScopeTabProps) {
  const query = useDevelopmentCommitmentScheduleAndScopeTabQuery({
    variables: { id: developmentCommitmentId },
  });

  const [saveDevelopmentCommitment] = useSaveDevelopmentCommitmentMutation();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: query.data?.developmentCommitment,
      map: (edc) => ({
        id: edc.id,
        contractSchedule: edc.contractSchedule,
        contractExhibits: edc.contractExhibits,
        contractExclusions: edc.contractExclusions,
        contractTerms: edc.contractTerms,
      }),
    },
    autoSave: async (dc) => {
      await saveDevelopmentCommitment({ variables: { input: { ...dc.changedValue } } });
      dc.commitChanges();
    },
  });

  if (!hasData(query)) {
    return renderLoadingOrError(query);
  }

  const dc = query.data.developmentCommitment;

  return (
    <Card>
      <h2 css={Css.baseMd.$}>Schedule and Scope</h2>
      <div css={Css.df.$}>
        {/* using css to adjust default height of BoundRichTextField and provide vertical scroll bar when needed */}
        <Form xss={{}}>
          <div css={{ ...Css.mt3.$, "[role='textbox']": Css.mh("40px").maxh("320px").oya.$ }}>
            <BoundRichTextField field={formState.contractSchedule} label="Schedule" readOnly={!dc.canBeEdited} />
          </div>
          <div css={{ ...Css.mt3.$, "[role='textbox'], [data-readonly='true']": Css.maxh("320px").oya.$ }}>
            <BoundRichTextField
              field={formState.contractExhibits}
              label="Drawings and Exhibits"
              readOnly={!dc.canBeEdited}
            />
          </div>
          <div css={{ ...Css.mt3.$, "[role='textbox'], [data-readonly='true']": Css.maxh("320px").oya.$ }}>
            <BoundRichTextField
              field={formState.contractExclusions}
              label="Exclusions (optional)"
              readOnly={!dc.canBeEdited}
            />
          </div>
          <div css={{ ...Css.mt3.$, "[role='textbox'], [data-readonly='true']": Css.maxh("320px").oya.$ }}>
            <BoundRichTextField
              field={formState.contractTerms}
              label="Terms &amp; Conditions"
              readOnly={!dc.canBeEdited}
            />
          </div>
        </Form>
      </div>
    </Card>
  );
}

type ContractInfo = Pick<
  SaveDevelopmentCommitmentInput,
  "id" | "contractExhibits" | "contractExclusions" | "contractSchedule" | "contractTerms"
>;

const formConfig: ObjectConfig<ContractInfo> = {
  id: { type: "value" },
  contractSchedule: { type: "value" },
  contractExhibits: { type: "value" },
  contractExclusions: { type: "value" },
  contractTerms: { type: "value" },
};

function Form({ children, xss }: { children: ReactNode; xss: Record<string, any> }) {
  return <form css={xss}>{children}</form>;
}
