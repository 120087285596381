import { Css, FieldGroup, FormLines, useComputed, useModal, useSnackbar } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import {
  InputMaybe,
  ScheduleDraftMode_PlanTaskFragment,
  useScheduleDraftModeScheduleFlagReasonsQuery,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { BoundScheduleFlagReasonSelects } from "../components/BoundScheduleFlagReasonSelects";
import { GetFormState } from "./DraftScheduleTable";
import { UserAddedScheduleFlagsInput } from "./scheduleDraftStore";

type DelayFlagModalProps = {
  planTask: ScheduleDraftMode_PlanTaskFragment;
  getFormState: GetFormState;
  setUserAddedScheduleFlags: (input: UserAddedScheduleFlagsInput[]) => void;
};

export function ScheduleDraftModeDelayFlagModal({
  planTask,
  getFormState,
  setUserAddedScheduleFlags,
}: DelayFlagModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const os = useMemo(() => getFormState(planTask), [getFormState, planTask]);
  const { data } = useScheduleDraftModeScheduleFlagReasonsQuery();
  const { scheduleFlagReasons } = data || { scheduleFlagReasons: [] };

  const userAddedScheduleFlagReasons = useComputed(
    () => scheduleFlagReasons.filter((flag) => flag.id === os.flagReasonId.value) ?? [],
    [scheduleFlagReasons, os.flagReasonId.value],
  );

  const onSubmit = useCallback(() => {
    setUserAddedScheduleFlags([
      // set userAddedScheduleFlags with information to use in the delete modal
      ...userAddedScheduleFlagReasons.map((flag) => ({
        durationInDays: 0,
        reasonId: os.flagReasonId.value,
        clientId: generateClientId(planTask.id, os.flagReasonId.value),
        title: flag.title,
        taskId: planTask.id,
        taskName: planTask.name,
        scheduleFlagReasonType: {
          name: flag.scheduleFlagReasonType.name,
          code: flag.scheduleFlagReasonType.code,
        },
      })),
    ]);
    closeModal();
    // clear reason id so a user can add another delay flag to a task
    os.flagReasonId.set(null);
    triggerNotice({ message: "Delay flag has been added." });
  }, [planTask, closeModal, triggerNotice, os.flagReasonId, setUserAddedScheduleFlags, userAddedScheduleFlagReasons]);

  return (
    <Observer>
      {() => (
        <ConfirmationModal
          confirmationMessage={
            <FormLines>
              <div css={Css.sm.$}>Select a reason for the delay below.</div>
              <FieldGroup>
                <BoundScheduleFlagReasonSelects field={os.flagReasonId} scheduleFlagReasons={scheduleFlagReasons} />
                <></>
              </FieldGroup>
            </FormLines>
          }
          label="Add Delay"
          disabled={!os.flagReasonId.value}
          onConfirmAction={onSubmit}
          title="Add a Delay"
        />
      )}
    </Observer>
  );
}

export function generateClientId(taskId: string, reasonId: InputMaybe<string>) {
  return `wip::scheduleFlag::${taskId}::${reasonId}`;
}
