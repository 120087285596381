import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { QueryTable } from "src/components";
import { useProjectDashboardFinancialsQuery } from "src/generated/graphql-types";
import {
  createFinancialsTableColumns,
  createFinancialsTableRows,
} from "src/routes/projects/dashboard/components/Financials/utils";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createBudgetUrl } from "src/RouteUrls";

export function FinancesTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useProjectDashboardFinancialsQuery({ variables: { projectId } });

  return (
    <Tile href={createBudgetUrl(projectId)} {...props} xss={Css.pt0.$}>
      <QueryTable
        query={query}
        createRows={createFinancialsTableRows}
        columns={createFinancialsTableColumns(true)}
        style={{ allWhite: true, rowHeight: "fixed" }}
      />
    </Tile>
  );
}
