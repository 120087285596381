import { useParams } from "react-router";
import { useDevelopmentScopeTemplatePageQuery } from "src/generated/graphql-types";
import { ScopeTemplateHeader } from "src/routes/developments/templates/components/ScopeTemplateHeader";
import { ItemTemplateItemParams } from "src/routes/routesDef";
import { ItemTemplateLineItemsTab } from "src/routes/settings/itemTemplates/ItemTemplateLineItemsTab";
import { ItemTemplateAnomaliesProvider } from "src/routes/settings/itemTemplates/anomalies/ItemTemplateAnomaliesContext";
import { queryResult } from "src/utils";

export function DevelopmentScopeTemplatePage() {
  const { itemTemplateId } = useParams<ItemTemplateItemParams>();
  const query = useDevelopmentScopeTemplatePageQuery({ variables: { itemTemplateId } });
  return (
    <ItemTemplateAnomaliesProvider templateId={itemTemplateId}>
      {queryResult(query, ({ itemTemplate }) => (
        <>
          <ScopeTemplateHeader itemTemplate={itemTemplate} />
          <ItemTemplateLineItemsTab
            mode={"development"}
            itemTemplateId={itemTemplate.id}
            isVersioned={itemTemplate.isVersioned}
            templateStatus={itemTemplate.statusDetail}
          />
        </>
      ))}
    </ItemTemplateAnomaliesProvider>
  );
}
