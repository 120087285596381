import { Button, Css, FormDivider, useTestIds } from "@homebound/beam";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createDevelopmentScopeTemplatesUrl } from "src/RouteUrls";
import { StepActions, useStepperContext } from "src/components/stepper";
import { ScheduleTemplateWithMappingInfo } from "src/routes/developments/cost-mapping/DevelopmentScheduleCostMappingPage";
import { DevelopmentParams } from "src/routes/routesDef";
import { CostMappingSummaryCard } from "../../templates/components/CostMappingSummaryCard";
import ScheduleTemplateSelector from "../components/ScheduleTemplateSelector";

type SummaryStepProps = {
  scheduleTemplates: ScheduleTemplateWithMappingInfo[];
  onTemplateSelect: (mstf: ScheduleTemplateWithMappingInfo) => void;
};

export function SummaryStep({ onTemplateSelect, scheduleTemplates }: SummaryStepProps) {
  const [backToMapStep, setBackToMapStep] = useState(false);
  const { developmentId } = useParams<DevelopmentParams>();
  const history = useHistory();
  const tid = useTestIds({}, "summaryStep");

  const { setSteps, prevStep } = useStepperContext();

  useEffect(() => {
    setSteps((prevState) => {
      return [
        { ...prevState[0], disabled: true },
        { ...prevState[1], disabled: true, state: backToMapStep ? "incomplete" : "complete" },
        { ...prevState[2], state: "incomplete", disabled: false },
      ];
    });
  }, [backToMapStep, setSteps]);

  const { total, mapped } = useMemo(
    () => ({ total: scheduleTemplates.sum((st) => st.total), mapped: scheduleTemplates.sum((st) => st.mapped) }),
    [scheduleTemplates],
  );

  const onContinueMapping = useCallback(
    (template: ScheduleTemplateWithMappingInfo) => {
      setBackToMapStep(true);
      onTemplateSelect(template);
      prevStep();
    },
    [onTemplateSelect, prevStep, setBackToMapStep],
  );

  return (
    <>
      <header css={Css.mtPx(2).mb2.$}>
        <h1 css={Css.xl3Sb.mb2.gray900.$} {...tid.steptitle}>
          Nice work, here's what's left...
        </h1>
      </header>
      <div css={Css.bgGray100.df.br8.fdc.aic.$}>
        <div css={Css.bgWhite.m8.br8.bss.p6.bcGray200.bw("2px").mh("75vh").$}>
          <h1 css={Css.xl2Sb.gray900.$} {...tid.title}>
            Summary
          </h1>
          <div css={Css.my("32px").maxw("66%").$}>
            <CostMappingSummaryCard
              hideCardLabel
              mapCount={`${mapped}/${total}`}
              progress={Math.round((mapped / total) * 100)}
              progressBarText="complete"
              description={"Total mapped items"}
            />
          </div>
          <FormDivider />
          <h1 css={Css.xlSb.my3.gray900.$} {...tid.continueMapping}>
            Continue mapping
          </h1>
          <ScheduleTemplateSelector
            isSummaryStep
            handleSelection={onContinueMapping}
            scheduleTemplates={scheduleTemplates}
          />
          <StepActions>
            <div>
              <Button
                label="Exit"
                onClick={() => {
                  history.push(createDevelopmentScopeTemplatesUrl(developmentId));
                }}
              />
            </div>
          </StepActions>
        </div>
      </div>
    </>
  );
}
