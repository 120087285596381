import { TaskFilterOptionsFragment } from "src/generated/graphql-types";
import { isDefined, unique } from "src/utils";

export enum ScheduleViewType {
  List = "List",
  Gantt = "Gantt",
  StandaloneTaskDetailPane = "StandaloneTaskDetailPane",
  Calendar = "Calendar",
}

export enum EmptyScheduleStates {
  LoadingState = "ShowLoadingState",
  ApplyTemplateNullState = "ApplyTemplateNullState",
}

/** Given a list of scheduleTasks with assignees and trade partners preloaded, return an unique list of both for filtering*/
export function prepareFilterOptions(scheduleTasks: TaskFilterOptionsFragment[]) {
  const assignees = unique(
    scheduleTasks
      .map(({ assignee }) => assignee)
      .filter(isDefined)
      .sort((a, b) => a.name.localeCompare(b.name)),
  );

  const tradePartners = unique(
    scheduleTasks
      .map(({ tradePartner }) => tradePartner)
      .filter(isDefined)
      .sort((a, b) => a.name.localeCompare(b.name)),
  );

  return { assignees, tradePartners };
}
