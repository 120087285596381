import { Css } from "@homebound/beam";
import { HistoryFeed } from "src/components";
import { TaskDetailsPage_PlanTaskFragment } from "src/generated/graphql-types";
import { TaskDetailCard, TaskDetailCardType } from "./TaskDetailCard";

type HistoryCardProps = {
  task: TaskDetailsPage_PlanTaskFragment;
};

export function HistoryCard({ task }: HistoryCardProps) {
  const { history } = task;
  return (
    <TaskDetailCard gridColumn={2} gridRow={3} cardType={TaskDetailCardType.History} maxHeight={650}>
      <h2 css={Css.xlBd.mb1.$}>History</h2>
      <div css={Css.oa.$}>
        <HistoryFeed historyItems={history} renderAsCard={false} />
      </div>
    </TaskDetailCard>
  );
}
