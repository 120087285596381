import { BoundSelectField, Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import {
  BillReviewReason,
  BillType,
  SaveCommentInput,
  useBillEditor_ReviewReasonsDetailsQuery,
} from "src/generated/graphql-types";
import { formatCentsToPrice } from "src/utils";
import { BillFormInput, BillFormLineItem } from "../BillEditorV3";

export type AddToPurchaseOrdersLogModalProps = {
  handleSave: (pendingReview?: boolean, comment?: SaveCommentInput) => Promise<void>;
  formState: ObjectState<BillFormInput>;
  selectedLineItems: BillFormLineItem[];
};

export function AddToBillReviewLogModal(props: AddToPurchaseOrdersLogModalProps) {
  const { handleSave, formState, selectedLineItems } = props;
  const { closeModal } = useModal();
  const isOverhead = formState.type.value === BillType.Overhead;
  const query = useBillEditor_ReviewReasonsDetailsQuery({ skip: isOverhead, nextFetchPolicy: "cache-first" });

  return (
    <>
      <ModalHeader>Add Bill to the Review Log</ModalHeader>
      <ModalBody>
        {formState.billedInCents.value ? (
          <div>
            A {capitalCase(formState.type.value!)} bill for {formatCentsToPrice(formState.billedInCents.value)} will be
            added to the log for future review.
          </div>
        ) : selectedLineItems.nonEmpty ? (
          <div css={Css.mb2.$}>
            <div css={Css.mb2.$}>
              A draft Bill with the following line items will be created and added to the log for future review:
            </div>
            {selectedLineItems.map((li) => (
              <li key={li.id}>
                <span css={Css.smBd.$}>{li.displayName}</span>:{` ${formatCentsToPrice(li.amountInCents!)}`}
              </li>
            ))}
          </div>
        ) : (
          <div>An empty draft bill will be created and added to the log for future review.</div>
        )}

        {!isOverhead && query.data && (
          <>
            <div css={Css.mt2.mb1.$}>Please select the reason why the bill is being added to the log:</div>
            <div>
              <BoundSelectField
                field={formState.reviewReason}
                options={query.data.enumDetails.billReviewReason
                  // "Insufficient budget" should only be used for overhead bills
                  // "Ai Generated" is reserved for bills generated from the parsing service
                  .filter(
                    (val) => ![BillReviewReason.InsufficientBudget, BillReviewReason.AutoParsed].includes(val.code),
                  )
                  .map((val) => ({ id: val.code, name: val.name }))}
                placeholder="Select a reason"
                labelStyle="hidden"
              />
            </div>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label="Confirm"
              onClick={async () => {
                if (isOverhead) {
                  formState.reviewReason.set(BillReviewReason.InsufficientBudget);
                }
                await handleSave(true);
                closeModal();
              }}
              disabled={!isOverhead && !formState.reviewReason.value}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}
