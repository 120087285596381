import { ButtonGroup, Css, IconButton } from "@homebound/beam";
import { useState } from "react";
import { EmptyStateWrapper } from "src/routes/personal-dashboard/components/EmptyStateWrapper";
import { ProjectsWidgetFinancials } from "src/routes/personal-dashboard/components/ProjectsWidgetFinancials";
import { ProjectsWidgetSchedule } from "src/routes/personal-dashboard/components/ProjectsWidgetSchedule";

type ProjectsWidgetProps = {
  isExpanded: boolean;
  onExpand: (x: boolean) => void;
};

export function ProjectsWidget({ isExpanded, onExpand }: ProjectsWidgetProps) {
  const [showFinancialsTab, setShowFinancialsTab] = useState(false);

  return (
    <div css={Css.bgWhite.p3.br8.f1.df.fdc.h100.mb3.$}>
      <span css={Css.df.jcsb.mb3.$}>
        <h1 css={Css.xlBd.$}>Projects</h1>
        <ButtonGroup
          size="xs"
          buttons={[
            { text: "Schedule", active: !showFinancialsTab, onClick: () => setShowFinancialsTab(false) },
            { text: "Financials", active: showFinancialsTab, onClick: () => setShowFinancialsTab(true) },
          ]}
        />
        <IconButton
          onClick={() => onExpand(!isExpanded)}
          icon={!isExpanded ? "expand" : "collapse"}
          tooltip={!isExpanded ? "Expand" : "Collapse"}
          inc={2}
        />
      </span>
      <EmptyStateWrapper title={showFinancialsTab ? "project financials" : "project schedules"}>
        <div css={Css.h("85%").$}>{showFinancialsTab ? <ProjectsWidgetFinancials /> : <ProjectsWidgetSchedule />}</div>
      </EmptyStateWrapper>
    </div>
  );
}
