import { Button, Css, IconButton, Palette, Tag, Tooltip, useModal, useTestIds } from "@homebound/beam";
import { useContext } from "react";
import { isDefined } from "src/utils";
import { designUpgradeTagColor, planPackageTagColor } from "../../designCatalogAtoms";
import { ColumnView } from "../DesignPackageCompareMode";
import { DesignPackageCompareModeContext } from "../DesignPackageCompareModeContext";
import { CompareCell } from "./CompareCell";
import { CompareConfigModal } from "./CompareConfigModal";

type CompareColumnProps = {
  columnIndex: number;
  numGridColumns: ColumnView;
};

export function CompareColumn(props: CompareColumnProps) {
  const { columnIndex, numGridColumns } = props;
  const openConfigModal = useCompareConfigModal();
  const { columns, removeColumn, slots, itivs, designPackage } = useContext(DesignPackageCompareModeContext);
  const tid = useTestIds({}, `column${columnIndex}`);

  const thisColRpoIds = columns[columnIndex];
  const columnOptions = thisColRpoIds?.map((rpoId) => designPackage.options.find((rpo) => rpo.id === rpoId)).compact();

  /**
   * Because we allow users to toggle between a single column view and a grid view, we need to adjust the column width.
   *
   * Only when single column view is enabled, if the total width of columns (each initially 480px) exceeds the window's width,
   * set their width to 345px to fit more columns on the screen.
   */
  const columnMaxWidth =
    numGridColumns === ColumnView.Single ? (columns.length * 480 > window.innerWidth ? 345 : 480) : 724;

  const itivsBySlot = thisColRpoIds?.isEmpty
    ? [] // brand new col: thisCol = `[]` and `[empty].every() ==> true`, so skip filtering
    : slots.map((placeholderId) =>
        itivs
          // restrict all ITIVs down to just the product family of cards for this placeholder iti
          .filter((itiv) => itiv.scope.placeholder?.id === placeholderId)
          // find which one (or more?) of those apply to the current column of options
          .filter((itiv) => thisColRpoIds.every((opt) => itiv.scope.options.some((o) => o.id === opt)))
          // only include ITIVs whose upgrades and plans strictly match this column
          .filter((itiv) =>
            itiv.scope.options
              .filter((rpo) => rpo.optionGroup.forDesignUpgrade || rpo.optionGroup.isPlanPackage)
              .every((rpo) => thisColRpoIds.includes(rpo.optionGroup.id)),
          ),
      );

  return (
    <div
      {...tid}
      css={
        Css.bgWhite.br8.fg1.fs1
          .fb("auto")
          .maxwPx(columnMaxWidth)
          .mwPx(numGridColumns === ColumnView.Single ? 345 : 724).$
      }
    >
      <div /* Column Header */ css={Css.df.jcsb.p2.bb.bcGray300.$}>
        {itivsBySlot.nonEmpty ? (
          <div css={Css.df.gap1.$}>
            {columnOptions.map((rpo) => {
              const bgColor = rpo.optionGroup.isPlanPackage
                ? planPackageTagColor
                : rpo.optionGroup.forDesignUpgrade
                  ? designUpgradeTagColor
                  : rpo.optionGroup.group.designPackageTagColor;
              return (
                <div key={rpo.id} css={Css.py1.$}>
                  <Tag
                    xss={Css.xsSb.if(isDefined(bgColor)).bgColor(bgColor!).$ as any}
                    text={rpo.designPackageAbbreviation}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div css={Css.xlMd.$}>Column {columnIndex + 1}</div>
        )}
        <div css={Css.df.gap1.$}>
          {itivsBySlot.nonEmpty ? (
            <IconButton icon="cog" onClick={() => openConfigModal(columnIndex)} />
          ) : (
            <Button label="Configure" icon="cog" onClick={() => openConfigModal(columnIndex)} />
          )}
          <IconButton icon="trash" color={Palette.Gray500} onClick={() => removeColumn(columnIndex)} />
        </div>
      </div>
      <div /* Column ITIVs */ css={Css.bgWhite.p2.br8.gap2.dg.gtc(`repeat(${numGridColumns}, 1fr)`).jic.aic.$}>
        {itivsBySlot.nonEmpty ? (
          itivsBySlot.map((itivs) => (
            <Tooltip
              key={itivs.first?.id || Math.random()}
              title={<div>{itivs.nonEmpty ? itivs.first?.bidItem?.name : "No Product Selected"}</div>}
            >
              <div {...tid.row}>
                <CompareCell itivs={itivs} />
              </div>
            </Tooltip>
          ))
        ) : (
          <EmptyState columnView={numGridColumns} />
        )}
      </div>
    </div>
  );
}

function EmptyState(props: { columnView?: ColumnView }) {
  const showGrid = props.columnView === ColumnView.Grid;
  // If grid column view, show 6 slots, otherwise show 3 slots
  const numberOfSlots = showGrid ? 6 : 3;
  return Array.from({ length: numberOfSlots }).map((_, i) => (
    <div data-testid="emptyStateSlot" key={i} css={Css.hPx(220).wPx(220).bgGray200.br4.$} />
  ));
}

export function useCompareConfigModal() {
  const ctx = useContext(DesignPackageCompareModeContext);
  const { openModal } = useModal();

  return (columnIndex: number) => {
    openModal({
      content: (
        <DesignPackageCompareModeContext.Provider value={ctx}>
          <CompareConfigModal columnIndex={columnIndex} />
        </DesignPackageCompareModeContext.Provider>
      ),
    });
  };
}
