import { Css, Loader } from "@homebound/beam";

export function TemplateLoader() {
  return (
    <div data-testid="templateLoader" css={Css.bgGray100.hPx(500).df.fdc.aic.jcc.$}>
      <Loader size="lg" />
      <div data-testid="loadingMessage" css={Css.xl2Sb.mt6.$}>
        Sit tight, we're assembling your template
      </div>
    </div>
  );
}
