import {
  BoundNumberField,
  Button,
  ButtonMenu,
  checkboxFilter,
  collapseColumn,
  column,
  Css,
  emptyCell,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  numericColumn,
  Palette,
  RowStyles,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  singleFilter,
  Tooltip,
  useComputed,
  useGridTableApi,
  useGroupBy,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { emptyCellDash, priceCell, SearchBox } from "src/components";
import { baseDownloadUrl } from "src/context";
import {
  BidContractLineItemsPage_BidContractLineItemFragment,
  BidContractLineItemsPage_BidContractRevisionFragment,
  BidContractRevisionStatus,
  SaveBidContractLineItemInput,
  useBidContractRevisionLineItemsPageQuery,
  useDeleteBidContractLineItemsMutation,
  useSaveBidContractLineItemMutation,
  useSaveBidContractLineItemsMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PriceAdjustModal } from "src/routes/developments/components/PriceAdjustModal";
import {
  DevelopmentTemplateItemsModal,
  DevelopmentTemplateItemsModalOnSave,
  DevelopmentTemplateItemsModalSize,
} from "src/routes/developments/procurement/components/DevelopmentTemplateItemsModal";
import { ImportPricingModal } from "src/routes/developments/procurement/components/ImportPricingModal";
import { DevelopmentContractUploadType } from "src/routes/developments/procurement/enums";
import { TableActions } from "src/routes/layout/TableActions";
import { DevelopmentContractParams } from "src/routes/routesDef";
import { foldEnum, groupBy, pluralize, queryResult, sum } from "src/utils";
import { maybeAddIdColumn } from "src/utils/idColumn";
import { openInSelf } from "src/utils/window";

export function DevelopmentContractLineItemsPage() {
  const { bidContractRevisionId } = useParams<DevelopmentContractParams>();
  const query = useBidContractRevisionLineItemsPageQuery({ variables: { bidContractRevisionId } });
  return queryResult(query, ({ bidContractRevision }) => {
    return <BidContractLineItemsTable selectedRevision={bidContractRevision} />;
  });
}

type BidContractLineItemsProps = {
  selectedRevision: BidContractLineItemsPage_BidContractRevisionFragment;
};

function BidContractLineItemsTable(props: BidContractLineItemsProps) {
  const { selectedRevision } = props;
  const { id, bidContract } = selectedRevision;
  const lineItems = selectedRevision.lineItems.filter((bcli) => bcli.ofIti) as LineItem[];
  const { parent } = bidContract;
  const tableApi = useGridTableApi<Row>();
  const [saveBidContractLineItem] = useSaveBidContractLineItemMutation();
  const [deleteBidContractLineItems] = useDeleteBidContractLineItemsMutation();
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const { developmentId } = useParams<DevelopmentContractParams>();
  const history = useHistory();

  const { getFormState } = useFormStates<
    SaveBidContractLineItemInput,
    BidContractLineItemsPage_BidContractLineItemFragment
  >({
    config: formConfig,
    getId: (v) => v.id,
    autoSave: async (os) => {
      await saveBidContractLineItem({ variables: { input: os.changedValue } });
    },
    readOnly: !selectedRevision.canEdit.allowed,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<FilterOpts>({});
  const filterDefs = useMemo(
    () => ({
      view: singleFilter({
        options: [{ id: "selections" as const, name: "Selections" }],
        getOptionLabel: (o) => o.name,
        getOptionValue: (o) => o.id,
      }),
      updatedItemsOnly: checkboxFilter({
        label: "Updated items only",
      }),
    }),
    [],
  );

  const groupBy = useGroupBy<GroupByKeys>({
    plan: "Plan",
    code: "Cost Code",
    specOption: "Spec Option",
    costType: "Cost Type",
    elevation: "Elevation",
  });

  const columns = useMemo(
    () => createColumns(getFormState, selectedRevision, groupBy.value, history),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFormState, groupBy.value, selectedRevision, developmentId],
  );
  const rows = useMemo(
    () => {
      return createRows(
        lineItems
          .filter((li) => (filter.view === "selections" ? !!li.itemTemplateItem.baseProduct?.id : {}))
          .filter((li) => !filter.updatedItemsOnly || li.scopeUpdated),
        groupBy.value,
      );
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.view, groupBy.value, lineItems],
  );

  const selectedLineItemIds = useComputed(
    () => tableApi.getSelectedRowIds("lineItem").map((bcli) => bcli.split("-")[1]),
    [tableApi],
  );

  const actionMenuItems = useMemo(
    () => [
      {
        label: "Bulk Cost Change",
        disabled: !selectedRevision.canEdit.allowed || !selectedLineItemIds.length,
        onClick: () =>
          openModal({
            content: (
              <PriceAdjustModal
                bidContractRevisionId={selectedRevision.id}
                bclis={tableApi.getSelectedRows("lineItem").map((r) => r.data)}
              />
            ),
          }),
      },
      {
        label: "Remove",
        disabled: !canEditLineItems(selectedRevision, selectedLineItemIds),
        onClick: async () =>
          openModal({
            content: (
              <ConfirmationModal
                onConfirmAction={async () => {
                  await deleteBidContractLineItems({
                    variables: { bidContractRevisionId: id, lineItemIds: selectedLineItemIds },
                  });
                  triggerNotice({
                    message: `${pluralize(
                      selectedLineItemIds,
                      "Item has",
                      `${selectedLineItemIds.length} items have`,
                    )} been deleted`,
                    icon: "success",
                  });
                }}
                title="Delete Items"
                label="Delete Items"
                confirmationMessage={
                  <div>
                    <p css={Css.smMd.mb2.$}>
                      {selectedLineItemIds.length} {pluralize(selectedLineItemIds, "item")} selected
                    </p>
                    <p>
                      Are you sure you want to delete {pluralize(selectedLineItemIds, "this item", "these items")}? This
                      action cannot be undone.
                    </p>
                  </div>
                }
              />
            ),
          }),
      },
      {
        label: "Download CSV",
        disabled: !selectedLineItemIds.length,
        onClick: () =>
          openInSelf(
            `${baseDownloadUrl()}/csv?type=planBasedBidLineItems&bcrId=${
              selectedRevision.id
            }&bcliIds=${selectedLineItemIds.join()}`,
          ),
      },
    ],
    [deleteBidContractLineItems, id, openModal, selectedLineItemIds, selectedRevision, tableApi, triggerNotice],
  );

  const excludedItiIds = lineItems.map((li) => li.itemTemplateItem.id).compact();

  return (
    <>
      <TableActions>
        <div css={Css.df.gap1.$}>
          <Filters groupBy={groupBy} filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        </div>
        <div css={Css.df.gap1.$}>
          <ImportPricingButton bidContractId={bidContract.id} />
          <SearchBox onSearch={setSearchTerm} />
          <AddCostCodeButton
            bidContractRevisionId={selectedRevision.id}
            developmentId={parent.id}
            excludedItiIds={excludedItiIds}
            disabled={!selectedRevision.canEdit.allowed}
          />
          <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />
        </div>
      </TableActions>
      <ScrollableContent>
        <GridTable
          stickyHeader
          api={tableApi}
          columns={columns}
          fallbackMessage="No Line Items found for Bid Contract."
          rows={rows}
          sorting={{ on: "client" }}
          filter={searchTerm}
          style={{ grouped: true, rowHeight: "fixed" }}
          rowStyles={maybeRowStyles(selectedRevision.version)}
        />
      </ScrollableContent>
    </>
  );
}

function createColumns(
  getFormState: (
    input: BidContractLineItemsPage_BidContractLineItemFragment,
  ) => ObjectState<SaveBidContractLineItemInput>,
  selectedRevision: BidContractLineItemsPage_BidContractRevisionFragment,
  groupByKey: GroupByKeys,
  history: any,
): GridColumn<Row>[] {
  const collapseCol = collapseColumn<Row>({
    lineItem: (bcli) => {
      return bcli.scopeUpdated ? (
        <Tooltip
          title={`This item has updated scope, current bid is on scope from ${bcli.itemTemplateItem.first.displayVersion}`}
        >
          <Icon icon="errorCircle" inc={2} color={Palette.Gray900} />
        </Tooltip>
      ) : (
        emptyCell
      );
    },
  });
  const selectCol = selectColumn<Row>();
  const isSigned = selectedRevision.status.code === BidContractRevisionStatus.Signed;
  const showProposedColumns = Number(selectedRevision.version) > 1 && !isSigned;
  const maybeProposed = showProposedColumns ? "Proposed " : "";

  const idColumn = column<Row>({
    header: "ID",
    elevation: () => "",
    plan: () => "",
    option: () => "",
    baseHouse: "",
    group: (rows) => "",
    lineItem: (bcli) => bcli.id,
    w: "248px",
  });

  const itemCol = column<Row>({
    header: "Item",
    elevation: (elevation) => ({ content: elevation, colspan: 5 }),
    plan: (displayName) => ({
      colspan: 5,
      content: displayName,
    }),
    option: (rows) => ({
      colspan: 5,
      content: () =>
        rows[0].itemTemplateItem.otherOptionIds
          ?.map((id) => rows[0].itemTemplateItem.options?.find((rpo) => rpo.id === id)?.displayName)
          .join(", ") ?? "-",
    }),
    baseHouse: "Base House",
    group: (rows) =>
      foldEnum(groupByKey, {
        plan:
          rows[0].itemTemplateItem.elevationIds
            .map((id) => rows[0].itemTemplateItem.options?.find((rpo) => rpo.id === id)?.name)
            .compact()
            .join(", ") ?? "-",
        code: rows[0].itemTemplateItem.item.costCode.number,
        specOption:
          rows[0].itemTemplateItem.specOptionIds
            .map((id) => rows[0].itemTemplateItem.options?.find((rpo) => rpo.id === id)?.name)
            .compact()
            .join(", ") ?? "-",
        costType: rows[0].itemTemplateItem.costTypeDetail.name,
        elevation: rows[0].itemTemplateItem.parent.displayName,
      }),
    lineItem: ({ itemTemplateItem }) => ({
      content: itemTemplateItem.displayName,
      onClick: () => {
        history.push({
          pathname: itemTemplateItem.blueprintUrl.path,
        });
      },
    }),
    w: "248px",
  });

  const costTypeCol = column<Row>({
    header: "Cost Type",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem }) => itemTemplateItem.costTypeDetail.name,
    w: "110px",
  });

  const selectionCol = column<Row>({
    header: "Selection",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem }) => {
      const images = itemTemplateItem.baseProduct?.images;
      const imgUrl = images && images.length ? images[0].previewUrl : undefined;
      return {
        content: () =>
          imgUrl ? (
            <>
              <img alt="base product" loading="lazy" css={Css.objectContain.pr1.wPx(36).hPx(36).$} src={imgUrl} />
              <span css={Css.truncate.$}>
                <Tooltip title={itemTemplateItem.baseProduct?.name} placement="top">
                  {itemTemplateItem.baseProduct?.name}
                </Tooltip>
              </span>
            </>
          ) : (
            emptyCellDash
          ),
        value: itemTemplateItem.baseProduct?.name ?? "",
      };
    },
    w: "120px",
  });

  const specOptionCol = column<Row>({
    header: "Spec Options",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem }) =>
      itemTemplateItem.specOptionIds
        .map((id) => itemTemplateItem.options?.find((rpo) => rpo.id === id)?.name)
        .compact()
        .join(", ") || "-",
    w: "126px",
  });

  const qtyCol = numericColumn<Row>({
    header: "QTY",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem }) => {
      return !itemTemplateItem.unitOfMeasure.useQuantity ? "N/A" : itemTemplateItem.quantity;
    },
    w: "72px",
  });

  const unitCol = column<Row>({
    header: "Unit",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem }) => itemTemplateItem.unitOfMeasure.name,
    w: "64px",
  });

  const unitCostCol = numericColumn<Row>({
    header: `${maybeProposed}Unit Cost`,
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: (row) => {
      if (!row.itemTemplateItem.unitOfMeasure.useQuantity || row.itemTemplateItem.quantity === 0) {
        return "N/A";
      }
      return priceCell({ valueInCents: row.unitCostInCents });
    },
    w: "152px",
  });

  function calculatedTotalCostInCents(rows: LineItem[]) {
    const totalCost = rows.map((bcli) => getFormState(bcli).totalCostInCents.value || 0).reduce(sum, 0);
    return priceCell({ valueInCents: totalCost });
  }

  const totalCostCol = numericColumn<Row>({
    header: `${maybeProposed}Total Cost`,
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: (rows) => {
      return calculatedTotalCostInCents(rows);
    },
    option: (rows) => {
      return calculatedTotalCostInCents(rows);
    },
    group: (rows) => {
      return calculatedTotalCostInCents(rows);
    },
    lineItem: (row) => {
      const isOptionItem = row.itemTemplateItem.otherOptionIds.nonEmpty;
      const os = getFormState(row);
      return {
        content: () => <BoundNumberField displayDirection={isOptionItem} type="cents" field={os.totalCostInCents} />,
        value: row.totalCostInCents,
      };
    },
    mw: "152px",
    w: 1,
  });

  const previousUnitCostCol = numericColumn<Row>({
    header: "Previous Unit Cost",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: (row) => {
      if (!row.itemTemplateItem.unitOfMeasure.useQuantity || row.itemTemplateItem.quantity === 0) {
        return "N/A";
      }
      return priceCell({ valueInCents: row.previousLineItem?.unitCostInCents });
    },
    mw: "152px",
  });

  const previousTotalCostCol = numericColumn<Row>({
    header: "Previous Total Cost",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: (rows) =>
      priceCell({ valueInCents: rows.map((bcli) => bcli.previousLineItem?.totalCostInCents || 0).reduce(sum, 0) }),
    lineItem: (row) => {
      return priceCell({ valueInCents: row.previousLineItem?.totalCostInCents || 0 });
    },
    mw: "152px",
  });

  const totalCostDeltaCol = numericColumn<Row>({
    header: "Total Cost Delta",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: (row) => {
      const os = getFormState(row);
      const previousCost = row.previousLineItem?.totalCostInCents || 0;
      const totalCost = os.totalCostInCents.value ?? 0;
      return priceCell({ valueInCents: totalCost! - previousCost });
    },
    mw: "152px",
  });

  const elevationCol = column<Row>({
    header: "Elevations",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem: iti }) => {
      const elevations = iti.elevationIds.map((id) => iti.options?.find((rpo) => rpo.id === id)).compact();
      return elevations?.nonEmpty ? elevations.map((rpo) => rpo.maybeShortName).join(", ") : "All";
    },
    w: "200px",
  });

  const optionCol = column<Row>({
    header: "Options",
    elevation: emptyCell,
    plan: emptyCell,
    baseHouse: emptyCell,
    option: emptyCell,
    group: emptyCell,
    lineItem: ({ itemTemplateItem: iti }) => {
      const options = iti.otherOptionIds.map((id) => iti.options?.find((rpo) => rpo.id === id)).compact();
      return options?.nonEmpty ? options.map((rpo) => rpo.maybeShortName).join(", ") : "All";
    },
    w: "200px",
  });

  const columns = [
    collapseCol,
    selectCol,
    ...maybeAddIdColumn(idColumn),
    itemCol,
    costTypeCol,
    selectionCol,
    ...(groupByKey !== GroupByKeys.specOption ? [specOptionCol] : []), // Not necessary when on spec group by
    elevationCol,
    ...(![GroupByKeys.plan, GroupByKeys.elevation].includes(groupByKey) ? [optionCol] : []), // Show options when there is not an options grouping
    qtyCol,
    unitCol,
    ...(showProposedColumns ? [previousUnitCostCol, previousTotalCostCol] : []),
    ...(showProposedColumns ? [unitCostCol] : []),
    totalCostCol,
    ...(showProposedColumns ? [totalCostDeltaCol] : []),
  ];

  return columns;
}

enum GroupByKeys {
  plan = "plan",
  code = "code",
  specOption = "specOption",
  costType = "costType",
  elevation = "elevation",
}

function createRows(lineItems: LineItem[], groupByKey: GroupByKeys): GridDataRow<Row>[] {
  const groupedBclis = foldEnum(groupByKey, {
    plan: () => groupBclisByPlan(lineItems),
    elevation: () => groupBclisByElevation(lineItems),
    else: () => groupBclisBy(lineItems, groupByKey),
  });
  return [simpleHeader, ...groupedBclis];
}

type LineItem = Omit<BidContractLineItemsPage_BidContractLineItemFragment, "itemTemplateItem"> & {
  // force to be non-nullable field based on filter for ofIti
  itemTemplateItem: NonNullable<BidContractLineItemsPage_BidContractLineItemFragment["itemTemplateItem"]>;
};
type HeaderRow = { kind: "header"; id: string; data: undefined };
type PlanTemplateRow = { kind: "plan"; id: string; data: string };
type ElevationRow = { kind: "elevation"; id: string; data: string };
type TemplateRow = {
  kind: "group";
  id: string;
  data: LineItem[];
};
type LineItemRow = {
  kind: "lineItem";
  data: LineItem;
};
type BaseHouseRow = {
  kind: "baseHouse";
  id: string;
  data: LineItem[];
};
type OptionRow = {
  kind: "option";
  id: string;
  data: LineItem[];
};
type Row = HeaderRow | TemplateRow | LineItemRow | PlanTemplateRow | ElevationRow | BaseHouseRow | OptionRow;

// add border to split between previous / proposed values
const cellBorderStyles = Css.addIn("& > div:nth-of-type(11)", Css.bl.bcGray400.$).$;

const rowStyles: RowStyles<Row> = {
  lineItem: { rowCss: cellBorderStyles },
  header: { rowCss: cellBorderStyles },
  group: { rowCss: cellBorderStyles },
  plan: { rowCss: cellBorderStyles },
  elevation: { rowCss: cellBorderStyles },
};

function maybeRowStyles(version: string) {
  if (Number(version) > 1) return rowStyles;
  return undefined;
}

function createGroupRow([itId, bclis]: [itId: string, bclis: LineItem[]]): GridDataRow<Row> {
  return {
    kind: "group" as const,
    // Create a unique id for the group row based on the item template id and the ids of the line items
    id: `${itId} ${bclis.map((bcli) => bcli.id).join("-")}`,
    data: bclis,
    children: bclis.map((bcli) => ({
      kind: "lineItem" as const,
      // Note: Format bcligr-<bcli.id> to avoid collisions with group ids and when selecting rows on the table, we split on the dash to get the bcli id of the line item
      id: `bcligr-${bcli.id}`,
      data: bcli,
    })),
  };
}

function createElevationGroupRow([itId, bclis]: [itId: string, bclis: LineItem[]]): GridDataRow<Row> {
  const [baseHouse, options] = bclis.partition(({ itemTemplateItem }) => itemTemplateItem.otherOptionIds.isEmpty);
  const optionsGroup = options.groupBy(
    ({ itemTemplateItem: iti }) =>
      iti?.otherOptionIds
        .map((id) => iti.options?.find((rpo) => rpo.id === id)?.displayName)
        .compact()
        .join() || "none",
  );

  return {
    kind: "group" as const,
    id: `eg-${itId}-${bclis.map((bcli) => bcli.itemTemplateItem.elevationIds.join())}`,
    data: bclis,
    children: [
      ...(baseHouse.nonEmpty
        ? [
            {
              kind: "baseHouse" as const,
              id: `egbh-${itId}-${baseHouse.map((bcli) => bcli.itemTemplateItem.elevationIds.join())}`,
              data: baseHouse,
              pin: "first" as const,
              children: baseHouse.map((bcli) => ({
                kind: "lineItem" as const,
                // Note: Format egbhli<it.id><go.id>-<bcli.id> to avoid collisions with group ids and when selecting rows on the table, we split on the dash to get the bcli id of the line item
                id: `egbhli${itId}${baseHouse.map((bcli) => bcli.itemTemplateItem.elevationIds.join())}-${bcli.id}`,
                data: bcli,
              })),
            },
          ]
        : []),
      ...Object.entries(optionsGroup).map(createOptionsGroupRow),
    ],
  };
}

function createOptionsGroupRow([itId, bclis]: [itId: string, bclis: LineItem[]]): GridDataRow<Row> {
  return {
    kind: "option" as const,
    id: `pbho-${itId} ${bclis.map((bcli) => bcli.id).join("-")}`,
    data: bclis,
    children: bclis.map((bcli) => ({
      kind: "lineItem" as const,
      id: `pbholi-${bcli.id}`,
      data: bcli,
    })),
  };
}

function groupBclisBy(lineItems: LineItem[], groupByKey: GroupByKeys) {
  const groupedItems = lineItems
    ? groupBy(lineItems, (li) =>
        foldEnum(groupByKey, {
          plan: li.itemTemplateItem.parent.id,
          code: li.itemTemplateItem.item.costCode.number,
          specOption:
            li.itemTemplateItem.specOptionIds
              .map((id) => li.itemTemplateItem.options?.find((rpo) => rpo.id === id)?.name)
              .compact()
              .join(", ") || "-",
          costType: li.itemTemplateItem.costTypeDetail.name,
          elevation:
            li.itemTemplateItem.elevationIds
              .map((id) => li.itemTemplateItem.options?.find((rpo) => rpo.id === id)?.name)
              .compact()
              .join(", ") || "-",
        }),
      )
    : [];

  return Object.entries(groupedItems).map(createGroupRow);
}

function groupBclisByPlan(lineItems: LineItem[]) {
  const groupedChildLineItems = lineItems.groupBy((li) => li.itemTemplateItem.readyPlan?.id ?? "");
  return Object.entries(groupedChildLineItems).map(([itId, bclis]) => {
    const planItem = bclis.find((li) => li.itemTemplateItem.readyPlan?.id === itId)!;
    const baseHouse = bclis.filter((li) => li.itemTemplateItem.otherOptionIds.isEmpty);
    // Ignore the bclies without otherOptionIds
    const { none, ...optionGroups } = bclis.groupBy((li) =>
      li.itemTemplateItem?.otherOptionIds.nonEmpty
        ? li.itemTemplateItem?.otherOptionIds
            .map((id) => li.itemTemplateItem?.options?.find((rpo) => rpo.id === id)?.displayName)
            .compact()
            .join(", ")
        : "none",
    );

    return {
      kind: "plan" as const,
      id: `p-${itId}`,
      data: planItem?.itemTemplateItem.readyPlan?.name ?? "-",
      children: [
        ...(baseHouse.nonEmpty
          ? [
              {
                kind: "baseHouse" as const,
                id: `pbh-${itId}`,
                data: baseHouse,
                pin: "first" as const,
                children: baseHouse.map((bcli) => ({
                  kind: "lineItem" as const,
                  id: `pbhli-${bcli.id}`,
                  data: bcli,
                })),
              },
            ]
          : []),
        ...Object.entries(optionGroups).map(createOptionsGroupRow),
      ],
    };
  });
}

function groupBclisByElevation(lineItems: LineItem[]) {
  const [noElevationItems, elevationItems] = lineItems.partition((li) => li.itemTemplateItem.elevationIds.isEmpty);
  const groupedChildLineItems = elevationItems.groupByObject((li) => li.itemTemplateItem.elevationIds.join());

  // Checks if the variable 'groupedChildLineItems' has a property with the name 'noElevationDefined'.
  // then deletes the property from 'groupedChildLineItems'.
  // and adds the values to each entry.
  if (noElevationItems.length > 0) {
    groupedChildLineItems.forEach(([, bclis], i) => {
      groupedChildLineItems[i][1] = [...noElevationItems, ...bclis];
    });
  }

  return groupedChildLineItems.map(([elevIds, bclis]) => {
    const item = bclis.find((li) => li.itemTemplateItem.elevationIds.join() === elevIds)!;
    const optionItemsByElevation = bclis;
    const optionItemsByPlan = optionItemsByElevation.groupBy((li) => li.itemTemplateItem.parent.id);
    const elevations = elevIds
      .split(",")
      .map((id) => item.itemTemplateItem.options?.find((rpo) => rpo.id === id))
      .compact();

    return {
      kind: "elevation" as const,
      id: `e-${item.id} ${elevations.map((rpo) => rpo.name).join(", ")}}`,
      data: elevations.map((rpo) => `${rpo.name} ${rpo.shortName ? `${rpo.shortName}` : ""}`).join(", "),
      children: Object.entries(optionItemsByPlan).map(createElevationGroupRow),
    };
  });
}

const formConfig: ObjectConfig<SaveBidContractLineItemInput> = {
  id: { type: "value" },
  totalCostInCents: { type: "value" },
};

type FilterOpts = Partial<{
  view: string;
  updatedItemsOnly: boolean;
}>;

function ImportPricingButton({ bidContractId }: { bidContractId: string }) {
  const { openModal } = useModal();

  return (
    <Button
      icon="upload"
      label="Import Pricing"
      onClick={() => {
        openModal({
          content: (
            <ImportPricingModal bidContractId={bidContractId} uploadType={DevelopmentContractUploadType.PlanBased} />
          ),
        });
      }}
      size="sm"
      variant="text"
    />
  );
}

export function canEditLineItems(
  bidContractRevision: BidContractLineItemsPage_BidContractRevisionFragment,
  selectedLineItemsCount: string[],
): boolean {
  return bidContractRevision.canEdit.allowed && selectedLineItemsCount?.length > 0;
}

type AddCostCodeButtonProps = {
  bidContractRevisionId: string;
  developmentId: string;
  excludedItiIds?: string[];
  disabled?: boolean;
};

export function AddCostCodeButton(props: AddCostCodeButtonProps) {
  const { bidContractRevisionId, developmentId, excludedItiIds, disabled } = props;
  const { closeModal, openModal } = useModal();
  const [saveBidContractLineItems] = useSaveBidContractLineItemsMutation();

  const onSave: DevelopmentTemplateItemsModalOnSave = async (input) => {
    await saveBidContractLineItems({
      variables: {
        bidContractRevisionId,
        lineItems: input.flatMap((i) =>
          i.templateItemIds.map((id) => ({
            revisionId: bidContractRevisionId,
            itemTemplateItemId: id,
            // We don't know what the TP will actually charge here, only what
            // we anticipate it costing so using $0 to signify a cost is still needed
            totalCostInCents: 0,
          })),
        ),
      },
    });
    closeModal();
  };

  return (
    <Button
      label="Add Cost Code"
      onClick={() => {
        openModal({
          content: (
            <DevelopmentTemplateItemsModal
              confirmationButtonText="Save cost codes"
              developmentId={developmentId}
              excludedItiIds={excludedItiIds}
              onSave={onSave}
              headerText="Manage Cost Codes"
              selectAllText="Select all cost codes"
              subHeader={
                <>
                  <div css={Css.mt1.mb1.baseMd.$}>Select item codes to include in this dev contract.</div>
                  <div css={Css.mb2.$}>Line items from ALL templates on this development will be added.</div>
                </>
              }
            />
          ),
          size: DevelopmentTemplateItemsModalSize,
        });
      }}
      size="sm"
      variant="secondary"
      disabled={disabled}
    />
  );
}
