import { ScrollableContent } from "@homebound/beam";
import { HistoryFeed } from "src/components";
import { useChangeEventHistoryQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type ChangeEventHistoryTabProps = {
  changeEventId: string;
};

export function ChangeEventHistoryTab({ changeEventId }: ChangeEventHistoryTabProps) {
  const query = useChangeEventHistoryQuery({ variables: { changeEventId } });
  return queryResult(query, {
    data: ({ changeEvents }) => {
      const history = changeEvents[0]?.history || [];
      return (
        <ScrollableContent>
          <HistoryFeed historyItems={history} />
        </ScrollableContent>
      );
    },
  });
}
