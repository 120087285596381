import { BoundSelectField, BoundSelectFieldProps } from "@homebound/beam";
import { MadTypeFragment, MaterialAttributeDimensionType, useMadTypesQuery } from "src/generated/graphql-types";

type MADTypeSelectFieldProps = Omit<
  BoundSelectFieldProps<MadTypeFragment, MaterialAttributeDimensionType>,
  "options" | "multiselect" | "getOptionLabel" | "getOptionValue"
> & {
  getOptionLabel?: (o: MadTypeFragment) => string;
  getOptionValue?: (o: MadTypeFragment) => MaterialAttributeDimensionType;
};

export function MADTypeBoundSelectField(props: MADTypeSelectFieldProps) {
  const {
    placeholder = "Select a Type",
    getOptionLabel = (o) => o.name,
    getOptionValue = (o) => o.code,
    ...otherProps
  } = props;
  const { data, loading } = useMadTypesQuery({ fetchPolicy: "cache-first" });

  return (
    <BoundSelectField
      {...otherProps}
      placeholder={loading ? "Loading..." : placeholder}
      options={data?.enumDetails.materialAttributeDimensionType ?? []}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
}
