import { Css, PresentationProvider, SelectField, StaticField, Tag, useComputed } from "@homebound/beam";
import countBy from "lodash/countBy";
import { useMemo } from "react";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  AssetInfoFragment,
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialBrandFragment,
  MaterialType,
} from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { MaterialDimensionsForm } from "../MaterialDimensionsForm";
import { useMaterialSuperDrawerContext } from "./MaterialSuperDrawerContext";

export type MaterialSuperDrawerViewProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

export function MaterialSuperDrawerView(props: MaterialSuperDrawerViewProps) {
  const { items } = props;
  const { formState, selectedVariant, setSelectedVariant, listing, variants } = useMaterialSuperDrawerContext();

  // Ignore mavIds that are common across all variants
  // I.e. `([mav:1, mav:2], [mav:2, mav:3], [mav:4, mav:2])` ==> `[mav:1, mav:3, mav:4]` (mav2 is excluded)
  // I.e. `([mav:1, mav:3], [mav:1, mav:4], [mav:2, mav:3], [mav:2, mav:4])` ==> `[mav:1, mav:2, mav:3, mav:4]` (non are excluded)
  const mavsToDisplay = useComputed(() => {
    // Flatten the MAVIds and count occurrences
    const flattened = formState.variants.rows.flatMap((mv) => mv.mavIds.value ?? []);
    const counts = countBy(flattened);

    const variantCount = formState.variants.rows.length;

    // Filter values that do not appear in all variants
    return Object.keys(counts).filter((key) => counts[key] < variantCount);
  }, [formState]);

  const item = useMemo(() => items.find((i) => i.id === listing?.item.id), [listing?.item.id, items]);

  const variantFormState = useComputed(
    () => formState.variants.rows?.find((v) => v.id.value === selectedVariant?.id),
    [formState, selectedVariant],
  );

  const type = useComputed(() => formState.type.value, [formState]);
  const images = useComputed(
    () =>
      (variantFormState?.images.rows ?? [])
        .map((i) => ({
          image: i,
          asset: {
            ...i.value.asset,
            attachmentUrl: i.attachmentUrl.value,
            downloadUrl: i.downloadUrl.value,
          } as AssetInfoFragment,
        }))
        .sortBy((i) => i.image.sortOrder.value ?? 0),
    [variantFormState],
  );

  if (!variantFormState) {
    return null;
  }

  const featuredImage = images.first;

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.w100.bgWhite.p1.br8.$}>
        {variants.length > 1 && (
          <div>
            <SelectField
              data-testid="variantSelector"
              label={`Variants ${variants.length}:`}
              value={selectedVariant?.id}
              options={variants}
              getOptionLabel={(mv) =>
                mv.materialAttributeValues
                  .filter((mav) => mavsToDisplay.includes(mav.id))
                  .sortBy((mav) => mav.dimension.sortOrder)
                  .map((mav) => `${mav.dimension.name}: ${mav.textValue}`)
                  .join(", ")
              }
              getOptionValue={(mv) => mv.id}
              onSelect={(id) => setSelectedVariant(id)}
            />
          </div>
        )}
        <div css={Css.df.gap2.mt3.$}>
          <div css={Css.wPx(380).hPx(380).bgColor("#F1F6FE").$}>
            <AssetGallery assets={images.map((i) => i.asset)} display="horizontal">
              {(openGallery) =>
                featuredImage && (
                  <div key={featuredImage.asset.id} css={Css.relative.p1.bgWhite.ba.gray300.br4.$}>
                    <AssetPreview
                      asset={featuredImage.asset}
                      dimensions={{ width: 360, height: 360 }}
                      onClick={() => openGallery(featuredImage.asset)}
                    />
                  </div>
                )
              }
            </AssetGallery>
          </div>
          <div css={Css.df.fdc.gap2.$}>
            <PresentationProvider fieldProps={{ labelStyle: "above" }}>
              <div css={Css.df.aife.gap2.$}>
                <StaticField label="Material Code" value={selectedVariant?.code} />
                <div>
                  <Tag
                    text={selectedVariant?.isArchived ? "Archived" : "Active"}
                    type={selectedVariant?.isArchived ? "caution" : "success"}
                  />
                </div>
              </div>
              {type !== MaterialType.Placeholder && (
                <StaticField label="SKU / Model Number" value={selectedVariant?.modelNumber ?? undefined} />
              )}
              <MaterialDimensionsForm
                type={type}
                variant={variantFormState}
                dimensions={item?.materialAttributeDimensions ?? []}
              />
              {selectedVariant?.designFeedback && (
                <StaticField label="Design Feedback">
                  <div dangerouslySetInnerHTML={{ __html: selectedVariant.designFeedback }} />
                </StaticField>
              )}
            </PresentationProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
