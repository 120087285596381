import {
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import pick from "lodash/pick";
import { Observer } from "mobx-react";
import { UppyUploader } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { AssetGallery, OpenGallery } from "src/components/assetGallery/AssetGallery";
import { WithActions } from "src/components/to-dos/WithActions";
import {
  AssetInfoFragment,
  RequirementsTabTradePartnerRequirementFragment,
  SaveAttachmentInput,
  SaveTradePartnerRequirementInput,
  TradePartnerRequirementStatusDataFragment,
  TradePartnerRequirementType,
  TradePartnerRequirementTypeDataFragment,
  useSaveTradePartnerRequirementMutation,
} from "src/generated/graphql-types";
import { AssetPreview } from "../projects/assets/AssetPreview";

export type TradePartnerRequirementEditorProps = {
  requirement?: RequirementsTabTradePartnerRequirementFragment;
  tradePartnerId?: string;
  tradePartnerRequirementTypes: TradePartnerRequirementTypeDataFragment[];
  tradePartnerRequirementStatuses: TradePartnerRequirementStatusDataFragment[];
};

export function TradePartnerRequirementEditor({
  requirement,
  tradePartnerId,
  tradePartnerRequirementTypes,
  tradePartnerRequirementStatuses,
}: TradePartnerRequirementEditorProps) {
  const { closeModal } = useModal();
  const [saveTradePartnerDocument] = useSaveTradePartnerRequirementMutation();

  const formState = useFormState({
    init: {
      input: requirement,
      // @ts-ignore avoids a type error due to activesupport's uniqueByKey's `key` string literal type
      map: (requirement) => ({
        ...requirement,
        type: requirement.type.code,
        status: requirement.status.code,
      }),
    },
    config: formConfig,
  });

  async function onSaveTradePartnerRequirement() {
    const { subType, type, attachments, ...rest } = formState.value;
    await saveTradePartnerDocument({
      variables: {
        input: {
          tradePartnerId,
          ...rest,
          type: subType || type,
          attachments: attachments?.map(({ asset, ...props }) => ({
            ...props,
            // the id needed to be forced to empty when adding new attachment to comply with typings and show it on the gallery right away
            // setting it to undefined in case is missing
            asset: pick({ ...asset, id: asset.id || undefined }, [
              // dropping downloadUrl, attachmentUrl and createdAt that comes from the detailed gallery, to get the AssetInput shape
              "contentType",
              "delete",
              "fileName",
              "id",
              "s3Key",
              "sizeInBytes",
            ]),
          })),
        },
      },
      refetchQueries: ["TradePartnerRequirements"],
    });
    closeModal();
  }

  function updateAttachmentFormState(file: UppyFile) {
    const { meta, type, name, size } = file;
    const { downloadUrl, s3Key } = meta;
    // Adapt the UppyFile to AssetInfoFragment so we can immediately preview it
    formState.attachments.add({
      asset: {
        id: "",
        downloadUrl: downloadUrl as string,
        contentType: type as string,
        fileName: name,
        s3Key: s3Key as string,
        sizeInBytes: size,
        attachmentUrl: downloadUrl as string,
        createdAt: new Date(),
        version: "1",
      },
    });
  }

  const orderedAttachments = useComputed(
    () => formState.attachments.rows.filter((a) => !a.asset.delete.value).sortBy((x) => -x.asset.fileName),
    [formState],
  );

  function deleteAttachment(attachment: ObjectState<FormAttachment>) {
    if (attachment.id.value) {
      attachment.asset.delete.value = true;
    } else {
      formState.attachments.remove(attachment.value);
    }
  }

  const renderAttachments = (openGallery: OpenGallery) => {
    return (
      <>
        {orderedAttachments.map((attachment) => {
          const asset = attachment.asset.value;
          return (
            <div key={attachment.id.value || attachment.asset.fileName.value} data-testid={attachment.id.value}>
              <WithActions
                left={{ icon: "trash", tooltip: "delete", action: () => deleteAttachment(attachment) }}
                right={{ icon: "download", tooltip: "download", action: asset.attachmentUrl }}
                footer={asset.fileName ? { text: asset.fileName } : undefined}
                onClick={() => openGallery(asset)}
              >
                <AssetPreview asset={asset} dimensions={{ width: 140, height: 90 }} />
              </WithActions>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>{requirement ? "Edit Requirement" : "Add Requirement"}</ModalHeader>
          <ModalBody>
            <FormLines width="full">
              <BoundSelectField
                field={formState.type}
                options={tradePartnerRequirementTypes.filter((req) => !req.isTsa)}
                getOptionLabel={(o) => o?.name}
                getOptionValue={(o) => o?.code}
                readOnly={!!requirement}
                onSelect={(val) => {
                  formState.type.set(val);
                  formState.subType.set(null);
                }}
              />
              {formState.type.value === TradePartnerRequirementType.TradeSpecificAgreement && (
                <BoundSelectField
                  field={formState.subType}
                  options={tradePartnerRequirementTypes.filter((req) => req.isTsa)}
                  getOptionLabel={(o) => o?.name}
                  getOptionValue={(o) => o?.code}
                  readOnly={!!requirement}
                />
              )}
              <>
                {formState.type.value === TradePartnerRequirementType.IndustryLicense && (
                  <BoundTextField field={formState.licenseNumber} />
                )}
              </>
              <BoundSelectField
                field={formState.status}
                options={tradePartnerRequirementStatuses}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.code}
                label="Eligibility"
              />
              <>
                {(formState.type.value === TradePartnerRequirementType.Msa ||
                  formState.type.value === TradePartnerRequirementType.TradeSpecificAgreement) && (
                  <BoundBeamDateField field={formState.signedDate} />
                )}
              </>
              <BoundBeamDateField field={formState.expirationDate} />
              <BoundTextAreaField field={formState.internalNote} />
              <div css={Css.gray700.sm.my1.$}>Attachments ({orderedAttachments?.length ?? 0})</div>
              <div data-testid="uploader">
                <UppyUploader
                  onFinish={updateAttachmentFormState}
                  dragDropText="Drag &amp; Drop or Click to Upload Attachment"
                  maxNumberOfFiles={1}
                  dragDropWidth={"100%"}
                />
              </div>
              <div css={Css.df.gap1.oxs.pb2.$}>
                <AssetGallery assets={orderedAttachments.map((i) => i.asset.value)} display="horizontal">
                  {renderAttachments}
                </AssetGallery>
              </div>
            </FormLines>
          </ModalBody>
          <ModalFooter>
            <div css={Css.pr1.$}>
              <Button data-testid="cancel" label="Cancel" onClick={() => closeModal()} variant="secondary" />
            </div>
            <Button
              data-testid="submit"
              label="Submit"
              onClick={onSaveTradePartnerRequirement}
              disabled={!formState.dirty || !formState.valid}
            />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type FormAttachment = SaveAttachmentInput & { asset: AssetInfoFragment };
type FormInput = SaveTradePartnerRequirementInput & {
  subType?: TradePartnerRequirementType | null;
  attachments?: FormAttachment[];
};

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  licenseNumber: { type: "value" },
  expirationDate: { type: "value" },
  signedDate: { type: "value" },
  type: { type: "value", rules: [required] },
  status: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  subType: { type: "value" },
  attachments: {
    type: "list",
    config: {
      id: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          s3Key: { type: "value" },
          fileName: { type: "value" },
          contentType: { type: "value" },
          sizeInBytes: { type: "value" },
          delete: { type: "value" },
          downloadUrl: { type: "value" },
          attachmentUrl: { type: "value" },
          createdAt: { type: "value" },
          version: { type: "value" },
        },
      },
    },
  },
};
