import {
  Button,
  column,
  Css,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridTable,
  ModalBody,
  ModalFooter,
  ModalHeader,
  numericColumn,
  RowStyles,
  simpleHeader,
  Tag,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { dateCell, Percentage, Price, priceCell } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import {
  ProjectItemBillDetailsQueryHookResult,
  ProjectItemBillLineItemFragment,
  useProjectItemBillDetailsQuery,
} from "src/generated/graphql-types";
import { createBillUrl } from "src/RouteUrls";
import { calcPercent, sum } from "src/utils";
import { queryResult } from "src/utils/queryResult";

export type BillsModalProps = {
  projectItemId: string;
};

export function BillsModal({ projectItemId }: BillsModalProps) {
  const { closeModal } = useModal();
  const query = useProjectItemBillDetailsQuery({ variables: { projectItemId } });

  return (
    <>
      <ModalHeader>{query.data?.projectItem.displayName}</ModalHeader>
      <ModalBody>
        <BillsModalContent query={query} />
      </ModalBody>
      <ModalFooter>
        <Button label="Close" variant="secondary" onClick={closeModal} />
      </ModalFooter>
    </>
  );
}

// Separate function for displaying the modal body in order to utilize `queryResult` for showing the 'Loading...' note inside the <ModalBody> only.
function BillsModalContent({ query }: { query: ProjectItemBillDetailsQueryHookResult }) {
  const tid = useTestIds({}, "billsModal");

  return queryResult(query, {
    data: (data) => {
      const { totalCommittedCostInCents, billLineItems } = data.projectItem;
      const totalBilledInCents = billLineItems.map((bli) => bli.amountInCents).reduce(sum, 0);
      const progress = calcPercent(totalBilledInCents, totalCommittedCostInCents);
      const billDetails = [
        {
          label: "Committed Cost",
          value: <Price id="committedCost" valueInCents={totalCommittedCostInCents} />,
        },
        {
          label: "Billed",
          value: <Price id="billed" valueInCents={totalBilledInCents} />,
        },
        {
          label: "Progress",
          value: <Percentage id="progress" percent={progress} />,
        },
      ];
      return (
        <div css={Css.sm.$}>
          <div css={Css.df.mb3.gap(6).$}>
            {billDetails.map((bd, idx) => (
              <div key={idx}>
                <div css={Css.tinySb.mb1.gray700.$} {...tid.detailLabel}>
                  {bd.label}
                </div>
                {bd.value}
              </div>
            ))}
          </div>
          <GridTable
            columns={createColumns(data.projectItem.project.id)}
            rows={createRows(billLineItems, totalBilledInCents)}
            rowStyles={createRowStyles()}
          />
        </div>
      );
    },
  });
}

type HeaderRow = { kind: "header" };
type LineItemRow = { kind: "lineItem"; data: ProjectItemBillLineItemFragment };
type TotalRow = { kind: "total"; data: { totalBilledInCents: number } };
type Row = HeaderRow | LineItemRow | TotalRow;

function createColumns(projectId: string): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Bill Number",
      lineItem: ({ bill }) => (
        <Link to={createBillUrl(bill.id)} data-testid="billLink" target="_blank">
          {bill.tradePartnerNumber}
        </Link>
      ),
      total: () => ({ alignment: "right", colspan: 4, content: "Total:" }),
      w: "120px",
    }),
    column<Row>({
      header: "Trade Partner",
      lineItem: ({ bill }) => bill.tradePartner.name,
      total: "",
    }),
    column<Row>({
      header: "Status",
      lineItem: ({ bill }) => {
        const [billType, text] = billStatusTagMapper(bill);
        return <Tag type={billType} text={text} />;
      },
      total: "",
    }),
    dateColumn<Row>({
      header: "Due Date",
      lineItem: ({ bill }) => dateCell(bill.dueDate),
      total: "",
      w: "120px",
    }),
    numericColumn<Row>({
      header: "Billed",
      lineItem: ({ amountInCents }) => priceCell({ valueInCents: amountInCents }),
      total: ({ totalBilledInCents }) => priceCell({ valueInCents: totalBilledInCents }),
      w: "120px",
    }),
  ];
}

function createRows(billLineItems: ProjectItemBillLineItemFragment[], totalBilledInCents: number): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(billLineItems.map((bli) => ({ kind: "lineItem" as const, id: bli.id, data: bli })) || []),
    { kind: "total" as const, id: "total", data: { totalBilledInCents } },
  ];
}

function createRowStyles(): RowStyles<Row> {
  return { header: {}, lineItem: { cellCss: Css.sm.py1.$ }, total: { cellCss: Css.sm.py1.$ } };
}
