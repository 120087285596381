import { ReactElement } from "react";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";

// https://react.dev/reference/react-dom/server/renderToString#removing-rendertostring-from-the-client-code
export function renderToString(element: ReactElement): string {
  const div = document.createElement("div");
  const root = createRoot(div);
  flushSync(() => root.render(element));
  return div.innerHTML;
}
