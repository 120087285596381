import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";

// Apply global styles for the print friendly "PDF" pages.
export function PdfCssReset() {
  return (
    <Global
      styles={[
        // Adding in `important` to font size styling for Storybook.
        // `<CssReset />` adds in `body { line-height: inherit; }` which we override by defining `LocalCssReset` _after_ `<CssReset />`.
        // We're applying the same styles in the same order in Storybook's `preview.tsx`, but it seems to not be respecting that order
        // Add a margin for when the user is just looking at the page, but remove it for actual pdf generation (ie, printing)
        Css.addIn("body", { ...Css.sm.important.$, ...Css.bgWhite.m2.gray800.ifPrint.m0.$ }).addIn(
          "html",
          Css.add("WebkitFontSmoothing", "antialiased").$,
        ).$,
      ]}
    />
  );
}
