import { TabsWithContent, TabWithContent } from "@homebound/beam";
import { useParams } from "react-router-dom";
import { ContractType, Stage, useHomeownerContractQuery } from "src/generated/graphql-types";
import { useStripStage } from "src/hooks/useStripStage";
import { useTabParam } from "src/hooks/useTabParam";
import { PageHeader } from "src/routes/layout/PageHeader";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { DetailsTab } from "src/routes/projects/homeowner-contracts/DetailsTab";
import { DrawScheduleTab } from "src/routes/projects/homeowner-contracts/DrawScheduleTab";
import { ProjectParams } from "src/routes/routesDef";
import { createHomeownerContractsUrl } from "src/RouteUrls";
import { queryResult } from "src/utils/queryResult";
import { InvoiceScheduleTab } from "./InvoiceScheduleTab";

const drawSchedulesSupportedInStages = [Stage.PreConExecution, Stage.Construction];

export function HomeownerContractPage() {
  useStripStage();
  const { projectId, contractId } = useParams<ProjectParams & { contractId: string }>();
  const { clientContract } = useProjectContext();
  const [tab, setTab] = useTabParam("details");
  const query = useHomeownerContractQuery({ variables: { contractId } });

  return queryResult(query, {
    data(data) {
      const tabs: TabWithContent[] = [];

      tabs.push({
        name: "Details",
        value: "details",
        render: () => <DetailsTab contractId={contractId} projectId={projectId} />,
      });

      const { contractType, projectStage } = data.homeownerContract;
      if (contractType === ContractType.Fixed && drawSchedulesSupportedInStages.includes(projectStage.stage.code)) {
        tabs.push({
          name: "Draw Schedule",
          value: "draws",
          render: () => <DrawScheduleTab contractId={contractId} stage={projectStage.stage.code} />,
        });
      }

      if (!!projectStage.project.invoiceSchedule) {
        tabs.push({
          name: "Invoice Schedule",
          value: "invoice",
          render: () => <InvoiceScheduleTab invoiceSchedule={projectStage.project.invoiceSchedule!} />,
        });
      }

      return (
        <>
          <PageHeader
            title={data.homeownerContract.name}
            breadcrumb={{
              label: `All ${clientContract}s`,
              href: createHomeownerContractsUrl(projectId),
            }}
          />
          <TabsWithContent selected={tab} onChange={setTab} tabs={tabs} />
        </>
      );
    },
  });
}
