import { getStage } from "src/context";

/**
 * Gets the host for the HOP application through the environment (stage).
 */
export function getHOPHost() {
  switch (getStage()) {
    case "local":
      return "http://localhost:3001";
    case "dev":
      return "https://app.dev-homebound.com";
    case "qa":
      return "https://app.qa-homebound.com";
    default:
      return "https://app.homebound.com";
  }
}

export function createHopProjectUrl(projectId: string) {
  return `${getHOPHost()}/project/${projectId}/overview`;
}
