import { Button, Chips, Css, useModal } from "@homebound/beam";
import { ApprovalSubject_BillFragment } from "src/generated/graphql-types";
import { BillDocumentModal } from "src/routes/bills/components/BillDocumentModal";
import { fail, formatList } from "src/utils";
import { useApprovalContext } from "../ApprovalSuperDrawer";
import { OverviewFormLabel } from "../approvalAtoms";

export function ApprovalOverviewBillExtension() {
  const { approvals } = useApprovalContext();
  const billApprovals =
    approvals?.filter((a) => a.subject.__typename === "Bill") ??
    fail("Superdrawer should've loaded an Approval, but didn't");
  // We want to eventually support multiple bills in this view, for now get the
  // approval values from the first bill
  const bills = billApprovals.map((b) => b.subject) as ApprovalSubject_BillFragment[];
  const [bill] = bills;
  const scheduleTaskItems = bills
    .flatMap((b) => b.lineItems.flatMap((li) => li.scheduleTask?.projectItems))
    .unique()
    .compact();

  const { openModal } = useModal();
  function openDocumentModal() {
    openModal({
      content: <BillDocumentModal billDisplayName={bill.name} documents={bill.documents} />,
      size: "xxl",
    });
  }

  return (
    <>
      <OverviewFormLabel>Approval Type</OverviewFormLabel>
      <span>{bill.type.name}</span>
      <OverviewFormLabel>Trade Partners</OverviewFormLabel>
      <span>{bill.tradePartner.name}</span>
      <OverviewFormLabel>POs</OverviewFormLabel>
      <span>{formatList(bill.parents.map((parent) => `#${parent.accountingNumber}`))}</span>
      <OverviewFormLabel>Impacted CCs</OverviewFormLabel>
      <Chips
        values={bills.flatMap((b) => b.lineItems.flatMap((li) => li.projectItem.item.fullCode)).unique()}
        compact
      />
      {scheduleTaskItems.nonEmpty && (
        <div>
          <OverviewFormLabel>Schedule tasks</OverviewFormLabel>
          {scheduleTaskItems.map((pi) => (
            <div key={pi.id}>{pi.name}</div>
          ))}
        </div>
      )}
      <OverviewFormLabel>Note</OverviewFormLabel>
      <span>{bill.internalNote}</span>
      <br />
      <div css={Css.pb1.$}>
        <Button
          label="Bill File"
          onClick={() => openDocumentModal()}
          variant="secondary"
          icon={"document"}
          size="sm"
          disabled={bill.documents.isEmpty && "This bill is missing an attached file"}
        />
      </div>
      {/* TODO: ProjectItems table here since it doesn't have its own tab? */}
    </>
  );
}
