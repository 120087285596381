import { Button, Checkbox, Css, FormLines, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useState } from "react";
import { baseDownloadUrl } from "src/context";
import { count } from "src/utils";
import { openNewTab } from "src/utils/window";

type ProjectItemsExportModalProps = {
  projectId: string;
  projectItemIds: string[];
};

/** Allow the user to configure a few things about the PI export. */
export function ProjectItemsExportModal(props: ProjectItemsExportModalProps) {
  const { projectId, projectItemIds } = props;
  const [includeRemovedScope, setIncludeRemovedScope] = useState<boolean>(true);
  const [includeRevised, setIncludeRevised] = useState<boolean>(false);
  const { closeModal } = useModal();
  return (
    <>
      <ModalHeader>Export</ModalHeader>
      <ModalBody>
        <p css={Css.pb2.$}>{count(projectItemIds, "item")} selected.</p>
        <FormLines>
          <Checkbox label="Include Removed Scope" selected={includeRemovedScope} onChange={setIncludeRemovedScope} />
          <Checkbox label="Include Revised Budget Column" selected={includeRevised} onChange={setIncludeRevised} />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Export"
          onClick={() => {
            openNewTab(
              `${baseDownloadUrl()}/csv?type=projectItems&revised=${includeRevised}&removed=${includeRemovedScope}&projectId=${projectId}&projectItemIds=${projectItemIds.join(
                ",",
              )}`,
            );
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
