import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent, Tag, useSessionStorage } from "@homebound/beam";
import { useProductSuperDrawerDetailsQuery } from "src/generated/graphql-types";
import { productStatusToTagTypeMapper, queryResult } from "src/utils";
import { ProductOverviewTab } from "./ProductOverviewTab";

export type ProductSuperDrawerProps = {
  productId: string;
  /** allows to open the overview tab in edit mode */
  isEdit?: boolean;
};

export function ProductSuperDrawer({ productId, isEdit }: ProductSuperDrawerProps) {
  const [selectedTab, setSelectedTab] = useSessionStorage("productSuperDrawerTab", "overview");
  const productDetailQuery = useProductSuperDrawerDetailsQuery({ variables: { productId } });

  return queryResult(productDetailQuery, {
    data: ({ products: [product], attributes, ...otherOptions }) => {
      const overviewTab = {
        name: "Overview",
        value: "overview",
        render: () => (
          <ProductOverviewTab
            product={product}
            attributes={attributes}
            isEdit={isEdit}
            {...otherOptions}
            productOptionsRefetch={productDetailQuery.refetch}
          />
        ),
      };

      return (
        <SuperDrawerContent>
          <SuperDrawerHeader
            title={product.name}
            left={<Tag type={productStatusToTagTypeMapper[product.status.code]} text={product.status.name} />}
          />
          <TabsWithContent tabs={[overviewTab]} selected={selectedTab} onChange={setSelectedTab} />
        </SuperDrawerContent>
      );
    },
  });
}
