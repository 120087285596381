import { Css, Icon, Palette } from "@homebound/beam";

type Direction = "left" | "right";

type ArrowSlideProps = {
  direction: Direction;
  onArrowClick: Function;
};

export function ArrowSlide({ direction, onArrowClick }: ArrowSlideProps) {
  return (
    <button
      type="button"
      css={{
        ...Css.bgBlue50.df.aic.jcc.w5.h5.borderRadius("40px").transition.$,
        WebkitTransform: direction === "right" ? "rotate(180deg)" : "",
        ":hover": Css.bgBlue200.cursorPointer.$,
      }}
      onClick={() => onArrowClick()}
      data-testid={`arrowSlide${direction[0].toUpperCase()}${direction.slice(1)}`}
    >
      <Icon icon="arrowBack" color={Palette.Blue700} />
    </button>
  );
}
