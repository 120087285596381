import { Css, DnDGrid, Palette, useResponsiveGrid, useTestIds } from "@homebound/beam";
import React from "react";

type DashboardGridProps = {
  children: React.ReactNode;
  onReorder: (ids: string[]) => void;
};

export function DashboardGrid({ children, onReorder }: DashboardGridProps) {
  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 289, columns: 4, gap: 20 });
  const tid = useTestIds({});
  return (
    <div css={Css.maxwPx(1400).ma.pxPx(20).$}>
      <DnDGrid
        onReorder={onReorder}
        gridStyles={{ ...gridStyles, ...Css.add("gridAutoRows", "320px").$ }}
        activeItemStyles={
          Css.boxShadow(
            `0 0 0 4px ${Palette.Blue700}, 0px 20px 25px -5px rgba(0,0,0,0.1), 0px 10px 10px -5px rgba(0,0,0,0.04)`,
          ).$
        }
        {...tid.tiles}
      >
        {children}
      </DnDGrid>
    </div>
  );
}
