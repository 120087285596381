import { Button, Css } from "@homebound/beam";
import { ProgressPill } from "src/components";
import { createDevelopmentCostMapPageUrl } from "src/RouteUrls";

type ProgressPillProps = {
  progress: number;
  progressBarText?: string;
  developmentId?: string;
  mapCount: string;
  hideCardLabel?: boolean;
  description?: string;
};
export const CostMappingSummaryCard = ({
  hideCardLabel,
  progress,
  progressBarText,
  developmentId,
  mapCount,
  description = "Mapped items",
}: ProgressPillProps) => {
  return (
    <div data-testid="costMappingLink" css={Css.p2.br12.bgWhite.ba.bcGray200.mw("459px").$}>
      <header css={Css.mb3.df.jcsb.$}>
        <h2 css={Css.xl2Sb.gray900.$}>
          <span data-testid="mapCount">{`${mapCount} ${progress === 100 ? "🎉" : ""}`}</span>
          <span css={Css.db.xsMd.gray600.pt1.$}>{description}</span>
        </h2>
        {!hideCardLabel && (
          <div>
            <Button
              variant="tertiary"
              label={progress === 100 ? "View allocations" : "Map items"}
              onClick={createDevelopmentCostMapPageUrl(developmentId!)}
            />
          </div>
        )}
      </header>
      <div>
        <ProgressPill
          fullWidth
          bottomLabel
          text={progressBarText}
          textWhiteSpace="nowrap"
          changeColorOnCompleted
          progress={progress}
        />
      </div>
    </div>
  );
};
