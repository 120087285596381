import { Css, GridTable, actionColumn, column, emptyCell, simpleHeader } from "@homebound/beam";
import { useMemo } from "react";
import { createMilestoneDetailsPageUrl } from "src/RouteUrls";
import { ProgressPill, formatDate } from "src/components";
import { ScheduleDraftMode_PlanMilestoneFragment } from "src/generated/graphql-types";
import { MilestoneActionCell } from "src/routes/projects/dynamic-schedules/draft-mode/DraftScheduleTable";
import { pluralize } from "src/utils";
import { ScheduleDateVariance } from "../components/ScheduleVariance";
import { ScheduleSnapshotsHookResult, useScheduleSnapshots } from "../components/useScheduleSnapshots";

type DynamicSchedulesMilestoneTableProps = {
  planMilestones: ScheduleDraftMode_PlanMilestoneFragment[];
  projectId: string;
  hasDraftChanges: boolean;
};

// TODO: Move into components or draft dir or wherever we decide given the size of this refactor
export function DynamicSchedulesMilestoneTable({
  planMilestones,
  projectId,
  hasDraftChanges,
}: DynamicSchedulesMilestoneTableProps) {
  const { getPlanMilestoneSnapshot } = useScheduleSnapshots(projectId);
  const columns = useMemo(
    () => createColumns(getPlanMilestoneSnapshot, hasDraftChanges),
    [getPlanMilestoneSnapshot, hasDraftChanges],
  );
  const rows = useMemo(() => createRows(planMilestones), [planMilestones]);

  return (
    <div css={Css.w100.h("calc(100vh - 160px)").$}>
      <GridTable
        as="virtual"
        columns={columns}
        rows={rows}
        rowStyles={{
          header: { cellCss: Css.bgGray100.$ },
          data: {
            rowLink: (row) => createMilestoneDetailsPageUrl(projectId, row.data.id),
          },
        }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ScheduleDraftMode_PlanMilestoneFragment };

export type Row = HeaderRow | DataRow;

function createColumns(
  getPlanMilestoneSnapshot: ScheduleSnapshotsHookResult["getPlanMilestoneSnapshot"],
  hasDraftChanges: boolean,
) {
  return [
    column<Row>({
      header: { content: "Milestones", css: Css.lgSb.jcfs.gray900.pl0.aife.$ },
      data: ({ name }) => name,
      mw: "120px",
    }),
    column<Row>({
      header: "Est. Start",
      data: ({ estStartDate }) => formatDate(estStartDate?.date, "monthShort"),
      w: "120px",
    }),
    column<Row>({
      header: "Est. End",
      data: ({ estEndDate, id }) => (
        <>
          {formatDate(estEndDate?.date, "monthShort")}
          <ScheduleDateVariance current={estEndDate} baseline={getPlanMilestoneSnapshot(id)?.estEndDate} />
        </>
      ),
      w: "140px",
    }),
    column<Row>({
      header: "Progress",
      data: ({ progress }) => <ProgressPill changeColorOnCompleted progress={progress} />,
      w: "180px",
    }),
    column<Row>({
      header: "Duration",
      data: ({ durationInDays }) => (
        <span>
          {durationInDays} {pluralize(durationInDays, "day", "days")}
        </span>
      ),
      w: "90px",
    }),
    actionColumn<Row>({
      header: emptyCell,
      data: (row) => <MilestoneActionCell milestone={row} hasDraftChanges={hasDraftChanges} />,
      w: "48px",
    }),
  ];
}

function createRows(data: ScheduleDraftMode_PlanMilestoneFragment[]) {
  return [
    simpleHeader,
    ...data.map((pm) => ({
      id: pm.id,
      kind: "data" as const,
      data: pm,
    })),
  ];
}
