import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  RightPaneLayout,
  ScrollableContent,
  simpleHeader,
  useTestIds,
} from "@homebound/beam";
import { areIntervalsOverlapping } from "date-fns";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TaskLookaheadCohortDetailsFragment, TaskLookaheadQuery } from "src/generated/graphql-types";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { fail } from "src/utils";
import { formatMonthDay, formatMonthDayYear } from "src/utils/dates";
import { WeekInterval } from "../useWeekRangeFilter";
import { ProjectTasksCell } from "./ProjectTasksCell";

type TaskLookaheadTableProps = {
  queryData: TaskLookaheadQuery;
  includedWeekIntervals: WeekInterval[];
  developmentId: string;
  loading: boolean;
};

type HeaderRow = { kind: "header"; data: undefined };
type CohortRow = { kind: "cohort"; data: TaskLookaheadCohortDetailsFragment };
export type ProjectRow = {
  kind: "project";
  data: { id: string; weekToTaskMapping: { [k: string]: Set<string> }; name: string; loading: boolean };
};
type Row = HeaderRow | CohortRow | ProjectRow;

export type CellSelectionState = { projectId: string; weekId: number } | undefined;

export function TaskLookaheadTable({
  queryData,
  includedWeekIntervals,
  developmentId,
  loading,
}: TaskLookaheadTableProps) {
  const tid = useTestIds({}, "taskLookaheadTable");
  const cellSelectionState = useState<CellSelectionState>(undefined);

  const preparedData = useMemo(() => prepareData(includedWeekIntervals, queryData), [includedWeekIntervals, queryData]);
  const rows = useMemo(
    () => createRows(queryData.cohorts, preparedData, loading),
    [queryData.cohorts, preparedData, loading],
  );
  const columns = useMemo(
    () => createColumns(includedWeekIntervals, cellSelectionState),
    [includedWeekIntervals, cellSelectionState],
  );

  // Because we don't have a way to set a min-width on a table column while also allowing them to have flexible size,
  // setting a min-with on the table container allows us to opt-in to horizontal scroll only when needed.
  const tableMinWidthPx = useMemo(() => includedWeekIntervals.length * 105, [includedWeekIntervals]);

  return (
    <ScrollableContent omitBottomPadding>
      <RightPaneLayout>
        <div {...tid} css={Css.h100.mwPx(tableMinWidthPx).$}>
          <GridTable
            columns={columns}
            rows={rows}
            style={{ grouped: true, allWhite: true, rowHover: false }}
            persistCollapse={`TaskLookaheadTable${developmentId}`}
            stickyHeader
            rowStyles={{ project: { cellCss: Css.p0.$ }, cohort: {} }}
          />
        </div>
      </RightPaneLayout>
    </ScrollableContent>
  );
}

function prepareData(includedWeekIntervals: WeekInterval[], { cohorts, scheduleTasks }: TaskLookaheadQuery) {
  const projects = cohorts.flatMap((c) => c.projects);
  /**
   * Create a nested mapping of projects -> weeks (by weekID) -> tasks
   *  {
   *    [k: ProjectId]: {
   *      [k: WeekId]: Set<TaskId>,
   *    }
   *  }
   */
  const projectToWeekColumnMap = Object.fromEntries(
    projects.map((p) => {
      const weekIdToTaskIds = Object.fromEntries(
        includedWeekIntervals.map(({ weekID }) => [weekID, new Set<string>()]),
      );
      return [p.id, weekIdToTaskIds];
    }),
  );

  // Now iterate through all the tasks and add their IDs to the corresponding Project-to-Week bucket(s)
  scheduleTasks.forEach((task) => {
    const projectFromMap = projectToWeekColumnMap[task.project?.id!] || fail("Project not found in map");

    // Iterate through each of the week intervals and add the task to the week if it falls within the interval
    // Note that a task may span multiple weeks so we can't return on the first match
    includedWeekIntervals.forEach((weekInterval) => {
      const taskListForProjectWeek = projectFromMap[weekInterval.weekID];

      // Use `inclusive: true` to include tasks that only overlap via the week boundaries
      const taskIsWithinWeekInterval = areIntervalsOverlapping(
        { start: task.interval.startDate, end: task.interval.endDate },
        { start: weekInterval.start, end: weekInterval.end },
        { inclusive: true },
      );

      if (taskIsWithinWeekInterval) taskListForProjectWeek.add(task.id);
    });
  });

  return projectToWeekColumnMap;
}

function createRows(
  cohorts: TaskLookaheadCohortDetailsFragment[],
  preparedData: ReturnType<typeof prepareData>,
  loading: boolean,
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...cohorts?.map((c) => ({
      kind: "cohort" as const,
      data: c,
      id: c.id,
      initCollapsed: false,
      children: [
        ...c.projects.map((p) => ({
          kind: "project" as const,
          id: p.id,
          data: {
            weekToTaskMapping: preparedData[p.id],
            id: p.id,
            name: p.name,
            loading,
          },
        })),
      ],
    })),
  ];
}

function createColumns(
  includedWeekIntervals: WeekInterval[],
  cellSelectionState: [CellSelectionState, React.Dispatch<CellSelectionState>],
): GridColumn<Row>[] {
  const columnsForWeeks = includedWeekIntervals.map((weekInterval) =>
    column<Row>({
      header: () => ({
        content: (
          // Separate divs and flexWrap parent gives us consistent newline wrapping after the "-" when needed
          <div css={Css.df.add("flexWrap", "wrap").$}>
            <div>{formatMonthDay(weekInterval.start)}&nbsp;-</div>
            <div>&nbsp;{formatMonthDay(weekInterval.end)}</div>
          </div>
        ),
        tooltip: `${formatMonthDayYear(weekInterval.start)} - ${formatMonthDayYear(weekInterval.end)}`,
      }),
      cohort: emptyCell,
      project: ({ weekToTaskMapping, loading, id }) => (
        <ProjectTasksCell
          weekToTaskMapping={weekToTaskMapping}
          loading={loading}
          weekInterval={weekInterval}
          id={id}
          cellSelectionState={cellSelectionState}
        />
      ),
    }),
  );

  const nameColumn = column<Row>({
    header: "Projects",
    cohort: ({ name }) => name,
    project: ({ id, name }) => (
      <Link css={Css.p1.$} to={createProjectScheduleUrl(id)}>
        {name}
      </Link>
    ),
    w: "200px",
  });

  return [collapseColumn<Row>(), nameColumn, ...columnsForWeeks];
}
