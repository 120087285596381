import { BoundSelectField } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useState } from "react";
import { InputMaybe, useScheduleTaskPhasesQuery } from "src/generated/graphql-types";
import { FormValue } from "../detail-pane/TaskDetailPane";

type AddScheduleTaskPhasesProps = {
  formState: FormValue;
  scheduleId: string;
};

type SubPhase = {
  id: string;
  name: string;
  globalPhase: {
    id: string;
  };
};

export function AddScheduleTaskPhases({ formState, scheduleId }: AddScheduleTaskPhasesProps) {
  const phaseQuery = useScheduleTaskPhasesQuery({
    variables: { scheduleId },
  });
  const schedulephases = phaseQuery.data?.schedule.phases;
  const globalPhases = schedulephases?.map((phase) => phase.globalPhase) || [];
  const globalSubPhases =
    schedulephases?.flatMap((phase) => phase.scheduleSubPhases.flatMap((ssp) => ssp.globalSubPhase)) || [];
  const [subPhasesByPhase, setSubPhasesByPhase] = useState<SubPhase[]>(
    globalSubPhases?.filter((subPhase) => subPhase.globalPhase.id === formState.schedulePhase.value),
  );

  // In this case if a user selects the SubPhase before a phase we would like to populate the correct phase in the modal.
  function onSubPhaseSelect(value: InputMaybe<string>) {
    formState.scheduleSubPhase.set(value);
    const globalSubPhase = globalSubPhases?.find((phase) => phase.id === value);
    if (formState.schedulePhase.value !== globalSubPhase?.globalPhase.id) {
      formState.schedulePhase.set(globalSubPhase?.globalPhase.id);
    }
  }

  function onPhaseSelect(value: InputMaybe<string>) {
    formState.schedulePhase.set(value);
    formState.scheduleSubPhase.set(undefined);
    setSubPhasesByPhase(globalSubPhases?.filter((subPhase) => subPhase.globalPhase.id === value));
  }

  return (
    <Observer>
      {() => (
        <>
          <BoundSelectField
            field={formState.schedulePhase}
            label="Phase*"
            compact
            options={globalPhases || []}
            data-testid="phase_select"
            onSelect={onPhaseSelect}
            disabled={globalPhases.isEmpty}
          />

          <BoundSelectField
            onSelect={onSubPhaseSelect}
            field={formState.scheduleSubPhase}
            label="SubPhase"
            compact
            options={subPhasesByPhase}
            data-testid="subPhase_select"
            disabled={formState.schedulePhase.value === undefined || subPhasesByPhase.isEmpty}
          />
        </>
      )}
    </Observer>
  );
}
