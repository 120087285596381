import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent, useSessionStorage } from "@homebound/beam";
import { ItemEditorProps, ItemEditorTab } from "./ItemEditorTab";
import { MaterialCatalogEditorTab } from "./MaterialCatalogEditorTab";

export function AdminItemsDrawer(props: ItemEditorProps) {
  const { selectedItem, isMaterialCatalogEnabled } = props;
  const [selectedTab, setSelectedTab] = useSessionStorage("AdminItemsDrawer", "overview");

  const overviewTab = {
    name: "Overview",
    value: "overview",
    render: () => <ItemEditorTab {...props} />,
  };

  const attributesTab = {
    name: "Material Catalog",
    value: "materialCatalog",
    render: () => <MaterialCatalogEditorTab itemId={selectedItem!.id} />,
  };

  const tabs = [overviewTab];
  if (selectedItem && isMaterialCatalogEnabled) tabs.push(attributesTab);

  return (
    <>
      <SuperDrawerHeader hideControls title={selectedItem ? `Update ${selectedItem.name}` : "Create New Item"} />
      <SuperDrawerContent>
        <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} />
      </SuperDrawerContent>
    </>
  );
}
