import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { createBusinessFunctionsUrl } from "src/RouteUrls";
import { BusinessFunctionRoles } from "src/components/change-log/components/BusinessFunctionRoles";
import { useBusinessFunctionDetailsQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { BusinessFunctionAssets } from "./components/BusinessFunctionAssets";

export function BusinesFunctionDetailPage() {
  const { businessFunctionId } = useParams<{ businessFunctionId: string }>();
  const query = useBusinessFunctionDetailsQuery({ variables: { businessFunctionId } });
  return queryResult(query, ({ businessFunction }) => (
    <div>
      <PageHeader
        title={businessFunction.name}
        breadcrumb={{ href: createBusinessFunctionsUrl(), label: "Functions" }}
        xss={Css.px3.$}
      />
      <BusinessFunctionRoles configurationData={businessFunction} />
      <BusinessFunctionAssets businessFunctionType={businessFunction.businessFunctionType} />
    </div>
  ));
}
