import {
  BoundNumberField,
  BoundSelectField,
  column,
  condensedStyle,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  simpleHeader,
} from "@homebound/beam";
import { FormActions, FormMode, HeaderBar, markupCell, priceTotal } from "src/components";
import {
  CostConfidence,
  CostConfidenceDetail,
  CostType,
  HomeownerSelectionStatus,
  Maybe,
  ProjectItemDetailsPageDetailsFragment,
  ProjectItemInput,
  UnitOfMeasure,
} from "src/generated/graphql-types";
import { calculateForm } from "src/routes/projects/selections/recalc";
import { calculateMarkupPercentage } from "src/utils";
import { ObjectConfig, ObjectState, useFormState } from "src/utils/formState";
import { ProjectItemScopeTable } from "./ProjectItemScopeTable";

type UnitOfMeasureData = Omit<
  UnitOfMeasure,
  "variables" | "abbreviation" | "shortName" | "itemTemplateItems" | "bidItems"
>;

export type ProjectItemDetailsEditorProps = {
  projectItem: ProjectItemDetailsPageDetailsFragment;
  unitsOfMeasure: UnitOfMeasureData[];
  costConfidences: CostConfidenceDetail[];
  // When canEditProjectItemsDirectly is false, we are locked, i.e. don't even show Edit
  mode: FormMode | "locked";
  onCancel: () => void;
  onEdit: () => void;
  onSave: (input: ProjectItemInput) => Promise<void>;
  onDelete: () => Promise<void>;
};

export function ProjectItemDetailsEditor(props: ProjectItemDetailsEditorProps) {
  const { projectItem, unitsOfMeasure, costConfidences, mode, onCancel, onDelete, onEdit, onSave } = props;
  const { scopeHistory, unitOfMeasure, homeownerSelection } = projectItem;

  // ProjectStage.canEditProjectItemsDirectly no longer guarantees the editor will be readonly, so we need to use the
  // prop to make individual fields readonly
  // TODO: Remove all `isSelection` logic - This component is only used by the `SpecTab`, so `isSelection` should always be false
  const isSelection = projectItem.homeownerSelection?.id !== undefined;
  const { canEditProjectItemsDirectly, hasSignedContract } = projectItem.projectStage;
  const shouldShowPresignedTable = canEditProjectItemsDirectly || !hasSignedContract;
  const readOnly = mode === "read" || mode === "locked" || (!canEditProjectItemsDirectly && !hasSignedContract);

  const formState = useFormState({ config: formConfig, init: { input: projectItem, map: mapToForm }, readOnly });
  formState.costConfidence.readOnly = !projectItem.canEditCostConfidence.allowed;

  const presignedColumns: GridColumn<PresignedRow>[] = createPresignedColumns(
    unitsOfMeasure,
    costConfidences,
    isSelection,
    readOnly,
  );

  return (
    <div>
      <HeaderBar
        right={
          mode !== "locked" && (
            <FormActions
              {...{
                formState,
                mode,
                onEdit,
                onDelete,
                onCancel,
                onSave: async () => {
                  await onSave(mapToInput(formState.value, isSelection));
                },
              }}
            />
          )
        }
      />

      {shouldShowPresignedTable ? (
        <GridTable
          id="preSignedTable"
          columns={presignedColumns}
          rows={createPresignedRows(isSelection, formState)}
          style={condensedStyle}
        />
      ) : (
        <ProjectItemScopeTable
          homeownerSelection={homeownerSelection ?? undefined}
          scopeHistory={scopeHistory}
          unitOfMeasure={unitOfMeasure}
        />
      )}
    </div>
  );
}

// This section of the page is not really a table per-se, in that we don't show
// ~10-50-100 project items: we only show the single/current project item.
// However, the UI is laid out fairly table-ish, hence using GridTable.
type HeaderRow = { kind: "header" };

type SpecOrSelectionRow = ({ kind: "spec" } | { kind: "selection" }) & { data: ProjectItemFormState };
type PresignedRow = HeaderRow | SpecOrSelectionRow;

function createPresignedColumns(
  unitsOfMeasure: UnitOfMeasureData[],
  costConfidences: CostConfidenceDetail[],
  isSelection: boolean,
  readOnly: boolean,
): GridColumn<PresignedRow>[] {
  const formOpts = { canEditPrice: !readOnly };
  // We share the quantity field logic for both specs and selections
  function getQuantityField(projectItem: ProjectItemFormState) {
    return projectItem.useQuantity.value === false ? (
      "N/A"
    ) : (
      <BoundNumberField
        field={projectItem.quantity}
        onChange={(newQuantity) => {
          projectItem.set(calculateForm(projectItem.value, "quantity", newQuantity, formOpts));
        }}
      />
    );
  }

  const columns: GridColumn<PresignedRow>[] = [
    numericColumn<PresignedRow>({
      header: "Quantity",
      selection: getQuantityField,
      spec: getQuantityField,
    }),
    column<PresignedRow>({
      header: "Unit",
      selection: (projectItem) => projectItem.unitOfMeasureName.value,
      spec: (projectItem) => (
        <BoundSelectField
          label="Unit"
          field={projectItem.unitOfMeasureId}
          options={unitsOfMeasure}
          onSelect={(uomId) => {
            projectItem.unitOfMeasureId.set(uomId);
          }}
        />
      ),
    }),
    numericColumn<PresignedRow>({
      header: "Unit Cost",
      selection: (projectItem) => priceTotal({ valueInCents: projectItem.unitCostInCents.value ?? 0 }),
      spec: (projectItem) => {
        const displayCost = projectItem.useQuantity.value && projectItem.quantity.value !== 0;
        return displayCost === false ? (
          <span data-testid="unitCostInCents">N/A</span>
        ) : (
          <BoundNumberField
            type="cents"
            field={projectItem.unitCostInCents}
            onChange={(newCost) => {
              projectItem.set(calculateForm(projectItem.value, "unitCostInCents", newCost, formOpts));
            }}
          />
        );
      },
    }),
    column<PresignedRow>({
      header: "Cost Confidence",
      spec: (projectItem) => (
        <BoundSelectField
          options={costConfidences}
          getOptionLabel={(o) => o?.name}
          getOptionValue={(o) => o?.code}
          field={projectItem.costConfidence}
        />
      ),
      selection: (projectItem) => (
        <BoundSelectField
          options={costConfidences}
          getOptionLabel={(o) => o?.name}
          getOptionValue={(o) => o?.code}
          field={projectItem.costConfidence}
        />
      ),
    }),
    numericColumn<PresignedRow>({
      header: "Total Estimated Cost",
      selection: (projectItem) => <BoundNumberField readOnly field={projectItem.totalCostInCents} />,
      spec: (projectItem) => (
        <BoundNumberField
          type="cents"
          field={projectItem.totalCostInCents}
          onChange={(newTotalCost) => {
            projectItem.set(calculateForm(projectItem.value, "totalCostInCents", newTotalCost, formOpts));
          }}
        />
      ),
    }),
    numericColumn<PresignedRow>({
      header: () => (isSelection || readOnly ? "Markup" : "Markup %"),
      selection: (projectItem) =>
        markupCell(projectItem.totalCostInCents.value ?? 0, projectItem.totalPriceInCents.value ?? 0),
      spec: (projectItem) =>
        projectItem.readOnly ? (
          markupCell(projectItem.totalCostInCents.value ?? 0, projectItem.totalPriceInCents.value ?? 0)
        ) : (
          <BoundNumberField
            type="percent"
            numFractionDigits={2}
            field={projectItem.markupPercentage}
            onChange={(newPercentage) => {
              projectItem.set(calculateForm(projectItem.value, "markupPercentage", newPercentage, formOpts));
            }}
          />
        ),
    }),
    ...(isSelection || readOnly
      ? []
      : [
          numericColumn<PresignedRow>({
            header: "Markup $",
            selection: "",
            spec: (projectItem) => (
              <BoundNumberField
                type="cents"
                field={projectItem.totalMarkupInCents}
                onChange={(newMarkupAmount) => {
                  projectItem.set(calculateForm(projectItem.value, "totalMarkupInCents", newMarkupAmount, formOpts));
                }}
              />
            ),
          }),
        ]),
    numericColumn<PresignedRow>({
      header: "Homeowner Price",
      // Note that the formState totalPriceInCents is not marked as readOnly b/c we do technically
      // recalc it inside calculateForm, so here we just disable the UI fields directly.
      selection: (projectItem) => <BoundNumberField readOnly field={projectItem.totalPriceInCents} />,
      spec: (projectItem) => <BoundNumberField readOnly field={projectItem.totalPriceInCents} />,
    }),
  ];
  return columns;
}

function createPresignedRows(
  isSelection: boolean,
  projectItem: ObjectState<ProjectItemFormValue>,
): GridDataRow<PresignedRow>[] {
  return [simpleHeader, { kind: isSelection ? "selection" : "spec", id: "projectItem", data: projectItem }];
}

type ProjectItemFormState = ObjectState<ProjectItemFormValue>;

type ProjectItemFormValue = {
  id: string;
  name: string;
  selectionName: string;
  unitOfMeasureName: string | undefined;
  unitOfMeasureId: string | null | undefined;
  unitCostInCents: Maybe<number>;
  quantity: Maybe<number>;
  totalPriceInCents: Maybe<number>;
  specifications: Maybe<string>;
  tradePartnerNote: Maybe<string>;
  internalNote: Maybe<string>;
  costType: CostType;
  status: HomeownerSelectionStatus | undefined;
  isPublished: boolean | undefined;
  totalCostInCents: Maybe<number>;
  markupPercentage: Maybe<number>;
  totalMarkupInCents: Maybe<number>;
  locationId?: string;
  costConfidence: CostConfidence | null | undefined;
  useQuantity?: boolean;
};

const formConfig: ObjectConfig<ProjectItemFormValue> = {
  id: { type: "value" },
  name: { type: "value" },
  selectionName: { type: "value" },
  costType: { type: "value", readOnly: true },
  specifications: { type: "value" },
  tradePartnerNote: { type: "value" },
  internalNote: { type: "value" },
  unitCostInCents: { type: "value" },
  quantity: { type: "value" },
  unitOfMeasureId: { type: "value" },
  totalPriceInCents: { type: "value" },
  totalCostInCents: { type: "value" },
  markupPercentage: { type: "value" },
  totalMarkupInCents: { type: "value" },
  status: { type: "value" },
  isPublished: { type: "value" },
  unitOfMeasureName: { type: "value" },
  locationId: { type: "value" },
  costConfidence: { type: "value" },
  useQuantity: { type: "value" },
};

function mapToInput(projectItem: ProjectItemFormValue, isSelection: boolean): ProjectItemInput {
  // exclude totalCostInCents and markup fields, as they're client-side only
  // exclude name & unitOfMeasureName as they are not editable
  // exclude useQuantity, it is part of unitOfMeasure and not editable
  const {
    status,
    isPublished,
    totalCostInCents,
    totalMarkupInCents,
    markupPercentage,
    unitOfMeasureName,
    selectionName,
    useQuantity,
    ...rest
  } = projectItem;
  // The homeowner selection should only be included in the input if this is actually
  // a selection. Otherwise, this will be a blank object (since isPublished/status are undefined)
  // and saveProjectItem will try to create a homeowner selection entity, but fail validation since it got
  // no data
  const homeownerSelection = isSelection ? { isPublished, status } : undefined;
  return { homeownerSelection, ...rest };
}

function mapToForm(projectItem: ProjectItemDetailsPageDetailsFragment): ProjectItemFormValue {
  const totalCostInCents = projectItem.quantity
    ? projectItem.unitCostInCents * projectItem.quantity
    : projectItem.unitCostInCents;

  return {
    id: projectItem.id,
    name: projectItem.name,
    selectionName: projectItem?.homeownerSelection?.selectedOption?.name || projectItem.name,
    costType: projectItem.costType,
    quantity: projectItem.quantity,
    unitOfMeasureId: projectItem.unitOfMeasure?.id,
    unitOfMeasureName: projectItem.unitOfMeasure?.name,
    unitCostInCents: projectItem.unitCostInCents,
    specifications: projectItem.specifications,
    tradePartnerNote: projectItem.tradePartnerNote,
    internalNote: projectItem.internalNote,
    totalPriceInCents: projectItem.totalPriceInCents,
    totalCostInCents,
    markupPercentage: calculateMarkupPercentage(projectItem.totalPriceInCents, totalCostInCents),
    totalMarkupInCents: projectItem.totalPriceInCents - totalCostInCents,
    status: projectItem.homeownerSelection?.status.code,
    isPublished: projectItem.homeownerSelection?.isPublished,
    locationId: projectItem.location?.id,
    costConfidence: projectItem.costConfidence.code,
    useQuantity: projectItem.unitOfMeasure?.useQuantity,
  };
}
