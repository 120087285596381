import { Css, IconButton, Palette, useBreakpoint, useTestIds } from "@homebound/beam";
import { useDashboardFilterContext } from "../DashboardFilterContext";

type PersonalDashboardTitleProps = {
  title: string;
  hideIcon?: true;
};

export const PersonalDashboardTitle = ({ title, hideIcon }: PersonalDashboardTitleProps) => {
  const { fullScreen, toggleFullScreen } = useDashboardFilterContext();
  const { sm } = useBreakpoint();
  const tids = useTestIds({}, title);

  return (
    <div css={Css.df.w100.jcsb.$}>
      <h3 css={Css.xlBd.if(sm).baseSb.$}>{title}</h3>
      {!hideIcon && (
        <IconButton
          color={Palette.Gray900}
          contrast={true}
          icon={fullScreen ? "collapse" : "expand"}
          tooltip={fullScreen ? "Collapse" : "Expand"}
          onClick={toggleFullScreen}
          inc={2}
          {...tids}
        />
      )}
    </div>
  );
};
