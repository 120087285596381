import { multiFilter } from "@homebound/beam";
import { GlobalOptionTagsFilter, useUseGlobalOptionTagsFilterLazyQuery } from "src/generated/graphql-types";

export function useGlobalOptionTagsFilter(filter: GlobalOptionTagsFilter = {}) {
  const [load, { data }] = useUseGlobalOptionTagsFilterLazyQuery({ variables: { filter }, fetchPolicy: "cache-first" });
  return multiFilter({
    label: "Tag",
    options: { current: undefined, load, options: data?.globalOptionTags ?? [] },
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
  });
}
