import { Css } from "@homebound/beam";
import { ReactNode } from "react";

export function LabelledText({ label, children }: { label: string; children: ReactNode }) {
  return (
    <label css={Css.gray700.smMd.wsnw.$}>
      {label}
      <div css={Css.mt1.smMd.gray800.$} aria-label={label}>
        {children}
      </div>
    </label>
  );
}
