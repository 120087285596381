import { Css, RouteTabWithContent, TabsWithContent } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { createPlanPackageTakeoffUrl, createPlanPackageTakeoffsMeasurementTabUrl } from "src/RouteUrls";
import { SideNavProps } from "src/components/layout/SideNav";
import { SideNavLayout } from "src/components/layout/SideNavLayout";
import { PlanPackageTakeoffPageQuery, usePlanPackageTakeoffPageQuery } from "src/generated/graphql-types";
import { TakeoffsManagerItemsTab } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerItemsTab";
import { TakeoffsManagerMeasurementsTab } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerMeasurementsTab";
import { TakeoffsHeader } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsHeader";
import {
  ItemTemplateItemParams,
  PlanPackageDetailsParams,
  planPackageTakeoffsMeasurementTabPath,
  planPackageTakeoffsPath,
} from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { PlanPackageTakeoffSideBar } from "./PlanPackageTakeoffSideBar";
import { TakeoffsManagerProvider } from "./TakeoffsManagerContext";

export function PlanPackageTakeoffPage() {
  const { itemTemplateId, planPackageId } = useParams<ItemTemplateItemParams & PlanPackageDetailsParams>();
  const query = usePlanPackageTakeoffPageQuery({ variables: { itemTemplateId, planPackageId } });

  return queryResult(query, (data) => (
    <TakeoffsManagerProvider itemTemplate={data.itemTemplate}>
      <TakeoffPageContent {...data} />
    </TakeoffsManagerProvider>
  ));
}

function TakeoffPageContent(props: PlanPackageTakeoffPageQuery) {
  const { itemTemplate, planPackage } = props;
  const { id } = itemTemplate;
  const { planPackageId } = useParams<PlanPackageDetailsParams>();

  const sideNavProps: SideNavProps = {
    sideNavConfig: [],
    top: <PlanPackageTakeoffSideBar {...props} />,
    navWidth: 265,
  };

  const tabs: RouteTabWithContent[] = useMemo(
    () => [
      {
        name: "Items",
        path: planPackageTakeoffsPath,
        href: createPlanPackageTakeoffUrl(planPackageId, id),
        render: () => <TakeoffsManagerItemsTab id={id} />,
      },
      {
        name: "Measurements",
        path: planPackageTakeoffsMeasurementTabPath,
        href: createPlanPackageTakeoffsMeasurementTabUrl(planPackageId, id),
        render: () => <TakeoffsManagerMeasurementsTab />,
      },
    ],
    [planPackageId, id],
  );

  return (
    <SideNavLayout sideNavProps={sideNavProps} layoutProps={{ contentCss: Css.pb3.mr2.$ }}>
      <TakeoffsHeader planPackage={planPackage} />
      <TabsWithContent tabs={tabs} includeBottomBorder contentXss={Css.pl3.pr2.$} />
    </SideNavLayout>
  );
}
