import { Css } from "@homebound/beam";
import { useMemo } from "react";
import { ToDoModal_ToDoFragment } from "src/generated/graphql-types";
import { openNewTab } from "src/utils/window";

export type ToDoLinksProps = {
  toDo: ToDoModal_ToDoFragment;
};

export type ToDoLink = {
  key: string;
  entityTypeName: string;
  entityName: string;
  blueprintUrl: string;
};

export function ToDoLinks({ toDo }: ToDoLinksProps) {
  const links = useMemo(
    () => [
      ...(toDo.changeRequest
        ? [
            {
              key: toDo.changeRequest.id,
              entityTypeName: "Change Request",
              entityName: toDo.changeRequest.name,
              blueprintUrl: toDo.changeRequest.blueprintUrl.path,
            },
          ]
        : []),
    ],
    [toDo.changeRequest],
  );

  return (
    <div css={Css.df.fdc.gap1.$}>
      <span css={Css.sm.gray700.$}>Links ({links.length})</span>
      {links.map(({ key, entityTypeName, entityName, blueprintUrl }) => (
        <div
          key={key}
          data-testid={key}
          css={Css.df.fdc.br8.bgGray100.p2.xs.blue700.cursorPointer.$}
          onClick={() => openNewTab(blueprintUrl)}
        >
          {entityTypeName}: {entityName}
        </div>
      ))}
    </div>
  );
}
