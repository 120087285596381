import { CollapseToggle, GridDataRow } from "@homebound/beam";
import { HeaderRow, PhaseRow, SubPhaseRow } from "src/routes/projects/schedule-v2/table/utils";

type CollapseCellProps = HeaderRow | PhaseRow | SubPhaseRow;

export function CollapseCell(data: any, { row }: { row: CollapseCellProps }) {
  return (
    <div>
      <CollapseToggle row={row as GridDataRow<any>} compact={row.kind === "subPhase"} />
    </div>
  );
}
