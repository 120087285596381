import { useApolloClient } from "@apollo/client";
import { currentAuthToken } from "@homebound/auth-components";
import {
  BoundSelectField,
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  StaticField,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useCallback, useState } from "react";
import { jsonToCSV } from "react-papaparse";
import { CsvUploader } from "src/components/CsvUploader";
import { isValidCsvContent } from "src/components/CsvUtils";
import { baseDownloadUrl } from "src/context";
import {
  BidContractType,
  BidPackageDetailPageDocument,
  BidPackageDetailRequestFragment,
  useCreateBidContractMutation,
} from "src/generated/graphql-types";
import { DevelopmentContractUploadType } from "src/routes/developments/procurement/enums";
import {
  getDevContractUploadCsvRequiredColumns,
  getDevContractUploadCsvType,
} from "src/routes/developments/procurement/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { openInSelf } from "src/utils/window";

// This is largely copied from src/routes/developments/procurement/components/CreateDevelopmentContractButton.tsx
type ImportBidModalProps = {
  requests: BidPackageDetailRequestFragment[];
  bidPackageName: string;
  developmentIds: string[];
  bidItemsIds: string[];
};

export function ImportBidModal({ bidPackageName, developmentIds, requests, bidItemsIds }: ImportBidModalProps) {
  const { closeModal } = useModal();
  const { upload, errors, loading, csvContent, setCsvContent, setUploadType, addError, clearErrors } =
    useUploadBidRequestCsv({ developmentIds, requests });
  const tradePartners = requests.map((request) => request.tradePartner);
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { tradePartner: tradePartners[0].id },
    },
  });
  const onDownloadTemplate = (bidItemsIds: string[]) => {
    const bidIds = bidItemsIds.join(",");
    const params = new URLSearchParams({
      type: "developmentBidItems",
      bidItemIds: bidIds,
    });
    openInSelf(`${baseDownloadUrl()}/csv?${params.toString()}`);
  };

  return (
    <>
      <ModalHeader>Import Items: {bidPackageName}</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap3.$}>
          <div>
            Import items from a{" "}
            <span
              css={Css.blue600.cursorPointer.$}
              data-testid="csvTemplateLink"
              onClick={() => onDownloadTemplate(bidItemsIds)}
            >
              .csv template
            </span>
          </div>
          <BoundSelectField field={formState.tradePartner} label="Select Trade:" options={tradePartners} />

          <StaticField label="Upload Document">
            <div css={Css.df.fdc.$}>
              <div css={Css.df.fdr.aic.gap1.mb1.$}>
                <Icon icon="error" inc={2} color={Palette.Red400} />
                <span css={Css.tiny.$}>
                  New uploaded costs will override any existing cost entries for the selected trade
                </span>
              </div>

              <div css={Css.w100.mt("-24px").$}>
                <CsvUploader
                  label="Click or Drag to Upload"
                  onError={addError}
                  errors={errors}
                  onDrop={(content) => {
                    clearErrors();
                    try {
                      const uploadType = getDevContractUploadCsvType(content);
                      const evaluation = isValidCsvContent({
                        content,
                        requiredColumns: getDevContractUploadCsvRequiredColumns(uploadType),
                      });
                      if (!evaluation.valid) return addError(evaluation.reasons);
                      setUploadType(uploadType);
                    } catch (error) {
                      return addError((error as Error).message);
                    }

                    setCsvContent(
                      jsonToCSV(
                        content.map(({ data }) => data),
                        { newline: "\n" }, // default is `\r\n` which breaks the parser on the backend
                      ),
                    );
                  }}
                />
              </div>
            </div>
          </StaticField>
        </div>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
              <Button
                label="Import"
                disabled={loading || formState.errors.toString() || errors?.toString() || !csvContent}
                onClick={() => upload(formState.value)}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValues = {
  tradePartner?: string | null;
};

const formConfig: ObjectConfig<FormValues> = {
  tradePartner: { type: "value", rules: [required] },
};

function useUploadBidRequestCsv({
  requests,
  developmentIds,
}: {
  developmentIds: string[];
  requests: BidPackageDetailRequestFragment[];
}) {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [csvContent, setCsvContent] = useState("");
  const [uploadType, setUploadType] = useState<DevelopmentContractUploadType>();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const [createBidContract] = useCreateBidContractMutation();
  const addError = (err: unknown) => setErrors((existing) => existing.concat(Array.isArray(err) ? err : String(err)));
  const clearErrors = () => setErrors([] as string[]);
  const client = useApolloClient();

  const upload = useCallback(
    async (value: FormValues) => {
      if (loading) return;
      setLoading(true);

      let bidContractId = requests.find((r) => r.tradePartner.id === value.tradePartner)?.bidContract?.id;

      if (!bidContractId) {
        const { data, errors } = await createBidContract({
          variables: {
            input: {
              tradePartnerId: value.tradePartner,
              developmentIds,
              bidRequestId: requests.find((r) => r.tradePartner.id === value.tradePartner)?.id,
              startDate: new DateOnly(new Date()),
              type:
                uploadType === DevelopmentContractUploadType.Itemized
                  ? BidContractType.UnitBased
                  : BidContractType.ItemTemplateBased,
            },
          },
          errorPolicy: "ignore",
        });

        bidContractId = data?.saveBidContract.bidContract.id;

        if (!bidContractId) {
          triggerNotice({
            message: errors?.toString() ?? "Bid Contract Creation failed",
          });
          setLoading(false);
          return;
        }
      }

      const response = await fetch(`${baseDownloadUrl()}/csv?type=${uploadType}&bidContractId=${bidContractId}`, {
        method: "POST",
        headers: { "content-type": "text/csv", Authorization: `Bearer ${await currentAuthToken()}` },
        body: csvContent,
      });

      if (response.status !== 200) {
        // Backend may have identified errors in the CSV (Like unfilled Total Cost columns or poorly-formatted numbers)
        // so pop them into the Errors array here.
        const { message } = await response.json();
        if (message) addError(message);
        setLoading(false);
        return;
      }

      // using Apollo Client directly instead of prop drilling refetch to update cache
      await client.refetchQueries({ include: [BidPackageDetailPageDocument] });
      triggerNotice({
        message: "Data imported successfully",
      });

      closeModal();
    },

    [closeModal, createBidContract, csvContent, loading, triggerNotice, uploadType, requests, developmentIds, client],
  );

  return { upload, errors, loading, setUploadType, setCsvContent, addError, clearErrors, csvContent };
}
