import { observable } from "mobx";
import {
  CostSourceSelect_BidContractLineItemFragment,
  CostType,
  DisplayNamedFragment,
  Maybe,
  ProductSelect_ProductFragment,
  SpecsAndSelections_ProjectItemFragment,
} from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { deriveFromInput } from "src/routes/projects/selections/recalc";
import { formatNumberToString } from "src/utils";
import { ObjectConfig } from "src/utils/formState";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

/**
 * ObservableProjectItem is where the UX of the cost/margin/price interdependencies
 * are implemented in the various `update[Field]` methods on this class.
 */
export class ObservableProjectItem extends Selectable {
  fragment!: SpecsAndSelections_ProjectItemFragment;
  // These are the only fields from `fragment` that are editable
  costType!: CostType;
  unitOfMeasureId: string | undefined;
  locationId: string | undefined;
  quantity: Maybe<number>;
  markupAmount: number | null | undefined;
  markupPercent: number | null | undefined;
  unitCostInCents: number | null | undefined;
  totalCostInCents!: number;
  totalPriceInCents!: number;
  taskId!: string | undefined;
  bidItemId: string | undefined;
  bidItem: DisplayNamedFragment | undefined; // bidItem is updated on change
  bidContractLineItemId: string | undefined;
  bidContractLineItem: CostSourceSelect_BidContractLineItemFragment | undefined;
  product: ProductSelect_ProductFragment | undefined;
  productId: string | undefined;
  isSelectionPublished: boolean | undefined;

  constructor(projectItem: SpecsAndSelections_ProjectItemFragment) {
    super(projectItem.id, false);
    this.update(projectItem);
    makeSimpleAutoObservable(this, { fragment: observable.ref });
  }

  update(projectItem: SpecsAndSelections_ProjectItemFragment) {
    this.id = projectItem.id;
    this.fragment = projectItem;
    this.costType = projectItem.costType;
    this.locationId = projectItem.location?.id;
    this.unitOfMeasureId = projectItem.unitOfMeasure?.id;
    this.quantity = projectItem.quantity;
    const { markupPercentage, totalMarkupInCents, unitCostInCents } = deriveFromInput({
      quantity: projectItem.quantity,
      totalCostInCents: projectItem.totalCostInCents,
      totalPriceInCents: projectItem.totalPriceInCents,
    });
    this.markupAmount = totalMarkupInCents;
    this.markupPercent = markupPercentage;
    this.unitCostInCents = unitCostInCents;
    this.totalCostInCents = projectItem.totalCostInCents;
    this.totalPriceInCents = projectItem.totalPriceInCents;
    this.taskId = projectItem.task?.id;
    this.bidItemId = projectItem.bidItem?.id;
    this.bidItem = projectItem.bidItem ?? undefined;
    this.bidContractLineItemId = projectItem.bidContractLineItem?.id;
    this.bidContractLineItem = projectItem.bidContractLineItem ?? undefined;
    this.isSelectionPublished = projectItem.homeownerSelection?.isPublished;
    // Ideally we could combine productId+product, but the auto-save works best this way
    this.productId = projectItem.product?.id;
    this.product = projectItem.product ?? undefined;
  }

  get canEditCost(): boolean {
    return !this.fragment.homeownerSelection && this.fragment.canEditCost.allowed && !this.bidContractLineItemId;
  }

  get canEditPrice(): boolean {
    return !this.fragment.homeownerSelection && this.fragment.canEditScope.allowed;
  }

  get disableMarkup(): boolean {
    return this.fragment.project.canEditPrice.allowed === false;
  }

  get alertMarkup(): string | undefined {
    const { projectMarkupBasisPoints, totalPriceInCents } = this.fragment;
    if (!this.disableMarkup || !projectMarkupBasisPoints || totalPriceInCents === 0) return undefined;

    const projectMarkup = projectMarkupBasisPoints / 100;
    return projectMarkup !== this.markupPercent
      ? `Markup does not match project markup ${formatNumberToString(projectMarkup)}%`
      : undefined;
  }
}

export type ProjectItemFormInput = {
  id: string;
  costType: Maybe<CostType>;
  locationId: Maybe<string>;
  unitOfMeasureId: Maybe<string>;
  quantity: Maybe<number>;
  markupAmount: Maybe<number>;
  markupPercent: Maybe<number>;
  unitCostInCents: Maybe<number>;
  totalCostInCents: Maybe<number>;
  totalPriceInCents: Maybe<number>;
  taskId: Maybe<string>;
  bidItemId: Maybe<string>;
  bidContractLineItemId: Maybe<string>;
  // Used for selection-less products
  productId: Maybe<string>;
  // Used by the SelectionsTable
  isSelectionPublished: Maybe<boolean>;
  // This isn't to the server, but putting it here for useFormStates.addRules to access
  canEditPrice: boolean;
};

// This form is auto-saved in SpecsAndSelectionsTable's autoSave callback that's
// passed to useFormStates in SpecsTable and SelectionsTable
export const projectItemFormConfig: ObjectConfig<ProjectItemFormInput> = {
  id: { type: "value" },
  costType: { type: "value" },
  locationId: { type: "value" },
  unitOfMeasureId: { type: "value" },
  quantity: { type: "value" },
  markupAmount: { type: "value" },
  markupPercent: { type: "value" },
  unitCostInCents: { type: "value" },
  totalCostInCents: { type: "value" },
  totalPriceInCents: { type: "value" },
  taskId: { type: "value" },
  bidItemId: { type: "value" },
  bidContractLineItemId: { type: "value" },
  productId: { type: "value" },
  canEditPrice: { type: "value", computed: true },
  isSelectionPublished: { type: "value" },
};
