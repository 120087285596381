import { ButtonModal, Css, TabContent, Tabs, useTestIds } from "@homebound/beam";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router";
import { Followers } from "src/components/index";
import {
  CommentFeedFragment,
  CommentStreamDetailFragment,
  CommentStreamVisibility,
  InternalUser,
  InternalUserDataFragment,
  useCommentFeedTradePartnersQuery,
  useCurrentInternalUserQuery,
  useDeleteCommentMutation,
  useSaveCommentMutation,
  useSaveFollowersMutation,
} from "src/generated/graphql-types";
import { CommentableInbox } from "src/routes/inbox/components/CommentableInbox";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { projectsPath } from "src/routes/routesDef";
import { isDefined, queryResult } from "src/utils";
import { SaveAttachmentModel, formatAttachments } from "../boundAttachments/BoundAttachments";
import { CommentStream } from "./CommentStream";
import { CommentStreams } from "./CommentStreams";

type SaveCommentOpts = {
  commentId?: string;
  commentStreamId?: string;
  streamVisibility?: CommentStreamVisibility;
  tradePartnerContactIds?: string[];
};

export type CommentSaveFunction = (
  text: string,
  html: string,
  attachments: SaveAttachmentModel[],
  opts: SaveCommentOpts,
  tags?: string[],
) => Promise<void>;

export type CommentDeleteFunction = (commentId: string) => Promise<void>;

export type CommentFeedProps = {
  commentable: CommentFeedFragment;
  showFollowers?: boolean;
  showCommentTitle?: boolean;
  maxHeight?: number;
  inlineCommentTitle?: ReactNode;
};

export function CommentFeed(props: CommentFeedProps) {
  const { commentable, showFollowers = true, showCommentTitle = true, maxHeight, inlineCommentTitle } = props;
  const tid = useTestIds(props, "commentFeed");
  const hasProjectRoute = !!useRouteMatch(projectsPath);

  const {
    scheduleState: {
      taskPaneState: { scrollIntoViewType },
    },
  } = useScheduleStore();

  const [currentTab, setCurrentTab] = useState<CommentStreamVisibility>(CommentStreamVisibility.Internal);
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (scrollIntoViewType === "comments" && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollIntoViewType]);

  const [saveCommentMutation] = useSaveCommentMutation({ refetchQueries: ["DynamicScheduleSidePane"] });
  const [deleteCommentMutation] = useDeleteCommentMutation({ refetchQueries: ["DynamicScheduleSidePane"] });
  const [saveFollowersMutation] = useSaveFollowersMutation({ refetchQueries: ["DynamicScheduleSidePane"] });

  const { data: currentInternalUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });
  const currentUser = currentInternalUserData?.currentInternalUser;

  const internalStream = getStreamForVisibility(commentable.streams, CommentStreamVisibility.Internal);
  const homeownerStream = getStreamForVisibility(commentable.streams, CommentStreamVisibility.Homeowner);
  const currentFollowers = commentable.followers || [];

  // Write some small helpers around the actual mutations
  const saveComment: CommentSaveFunction = async (text, html, attachments = [], opts, tags) => {
    const { commentId, commentStreamId, streamVisibility, tradePartnerContactIds } = opts;

    await saveCommentMutation({
      variables: {
        input: {
          text,
          html,
          tradePartnerContactIds,
          commentStreamId,
          streamVisibility,
          id: commentId,
          commentableId: commentable.id,
          attachments: formatAttachments(attachments),
          internalUserTags: tags,
        },
      },
    });
  };

  const deleteComment: CommentDeleteFunction = async (commentId) => {
    await deleteCommentMutation({ variables: { input: { id: commentId } } });
  };

  async function saveFollowers(followerIds: string[], fromTag: boolean) {
    await saveFollowersMutation({
      variables: { input: { followableId: commentable.id, followerIds, fromTag } },
    });
  }

  // There is only one Internal tab for now, but in the future we will support additional comment streams
  const tabs = [
    {
      name: "Internal",
      value: CommentStreamVisibility.Internal,
      render: () => renderStream(internalStream, CommentStreamVisibility.Internal),
    },
  ];

  if (commentable.supportedVisibilities.includes(CommentStreamVisibility.Homeowner)) {
    tabs.push({
      name: "Homeowner",
      value: CommentStreamVisibility.Homeowner,
      render: () => renderStream(homeownerStream, CommentStreamVisibility.Homeowner),
    });
  }

  function renderStream(stream: CommentStreamDetailFragment | undefined, visibility: CommentStreamVisibility) {
    return (
      <CommentStream
        showAvatar={!isDefined(inlineCommentTitle)}
        stream={stream}
        streamVisibility={visibility}
        saveComment={saveComment}
        deleteComment={deleteComment}
        currentUser={(currentUser || {}) as InternalUser}
        commentableId={commentable.id}
        maxHeight={maxHeight}
      />
    );
  }

  if (commentable.supportedVisibilities.includes(CommentStreamVisibility.Trades)) {
    const tradesStreams = getStreamsForVisibility(commentable.streams, CommentStreamVisibility.Trades);
    tabs.push({
      name: "Trade Partner",
      value: CommentStreamVisibility.Trades,
      render: () =>
        !hasProjectRoute ? (
          <CommentableInbox commentableId={commentable.id} visibility={CommentStreamVisibility.Trades} />
        ) : (
          <TradeCommentStream
            tradesStreams={tradesStreams}
            saveComment={saveComment}
            deleteComment={deleteComment}
            currentUser={(currentUser || {}) as InternalUser}
            commentableId={commentable.id}
            maxHeight={maxHeight}
          />
        ),
    });
  }

  return (
    <>
      <div css={Css.df.aic.jcsb.$} ref={ref}>
        {/* If we only have a single tab, then it would be the "Internal" comments. We will remove the tabs UI, as it is unnecessary, but we still want to be explicit that these are "Internal" comments.*/}
        {showCommentTitle ? <div css={Css.base.$}>{tabs.length === 1 ? "Internal Comments" : "Comments"}</div> : <></>}
        {showFollowers && (
          <ButtonModal
            {...tid.followers}
            content={(close) => (
              <Followers
                currentFollowers={currentFollowers}
                onSave={async (followerIds) => {
                  await saveFollowers(followerIds, false);
                  close();
                }}
                onCancel={close}
              />
            )}
            trigger={{ variant: "secondary", label: `Watching (${currentFollowers.length})` }}
            placement="right"
          />
        )}
      </div>
      {inlineCommentTitle ? (
        <div css={Css.df.jcsb.$}>
          {inlineCommentTitle}
          <Tabs selected={currentTab} onChange={setCurrentTab} tabs={tabs} />
        </div>
      ) : (
        <Tabs selected={currentTab} onChange={setCurrentTab} tabs={tabs} />
      )}

      <TabContent contentXss={Css.fg1.pt1.$} selected={currentTab} tabs={tabs} />
    </>
  );
}

export function getStreamsForVisibility(streams: CommentStreamDetailFragment[], visibility: CommentStreamVisibility) {
  return streams.filter(({ streamVisibility }) => streamVisibility.code === visibility);
}

export function getStreamForVisibility(streams: CommentStreamDetailFragment[], visibility: CommentStreamVisibility) {
  return streams.find(({ streamVisibility }) => streamVisibility.code === visibility);
}

type TradeCommentStreamProps = {
  tradesStreams: CommentStreamDetailFragment[];
  saveComment: CommentSaveFunction;
  deleteComment: CommentDeleteFunction;
  currentUser: InternalUserDataFragment;
  commentableId: string;
  maxHeight?: number;
};

/**
 * Mainy serves to isolate the Query to only invoke when it's mounted instead of
 * before the tab is even clicked on. It's possible for multiple Comment Streams to
 * mount at once and each one will try to fetch the -entire- list of Trades even though
 * this tab is rarely used so there's little benefit to pre-loading it, much less
 * multiple times.
 */
function TradeCommentStream({
  tradesStreams,
  saveComment,
  deleteComment,
  currentUser,
  commentableId,
  maxHeight,
}: TradeCommentStreamProps) {
  const query = useCommentFeedTradePartnersQuery({ variables: { filter: {} } });
  // Adjust for the additional levels of tabs for the trade stream view
  const adjustedMaxHeight = maxHeight ? maxHeight - 130 : undefined;
  return queryResult(query, (data) => (
    <CommentStreams
      streams={tradesStreams}
      streamVisibility={CommentStreamVisibility.Trades}
      saveComment={saveComment}
      deleteComment={deleteComment}
      currentUser={(currentUser || {}) as InternalUser}
      tradePartners={data?.tradePartners}
      commentableId={commentableId}
      maxHeight={adjustedMaxHeight}
    />
  ));
}
