import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useCallback, useState } from "react";
import { ItemsSelectField } from "src/components/autoPopulateSelects/ItemsSelectField";
import { baseDownloadUrl } from "src/context";
import { openInSelf } from "src/utils/window";

export function DownloadMaterialListingsCsvModal() {
  const [itemId, setItemId] = useState<string>();
  const { closeModal } = useModal();

  const download = useCallback((itemId: string) => {
    openInSelf(`${baseDownloadUrl()}/csv?type=materialListings&itemId=${itemId}`);
  }, []);

  return (
    <>
      <ModalHeader>Download Material Listings</ModalHeader>
      <ModalBody>
        <ItemsSelectField label="Item" value={itemId} onSelect={(id) => setItemId(id)} />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
        <Button label="Download" disabled={!itemId} onClick={() => download(itemId!)} />
      </ModalFooter>
    </>
  );
}
