import { column, Css, GridColumn, GridDataRow, GridTable, simpleHeader } from "@homebound/beam";
import { priceCell } from "src/components";
import {
  ApprovalChangeEventBreakDownTabQuery,
  ChangeEventBreakDownTab_ChangeEventLineItemFragment,
} from "src/generated/graphql-types";

type ApprovalChangeEventLineItemTableProps = {
  changeEvent: ApprovalChangeEventBreakDownTabQuery["changeEvent"];
};

export function ApprovalChangeEventLineItemTable({ changeEvent }: ApprovalChangeEventLineItemTableProps) {
  const rows = createRows(changeEvent.lineItems.compact());
  const columns = createColumns();

  return (
    // Wrap gridTable in div to avoid the fullbleed behavior of tables (fullbleed overrides margins set between the table & other sections)
    <div>
      <GridTable rows={rows} columns={columns} style={{ bordered: true, rowHover: false }} />
    </div>
  );
}

function createRows(items: ChangeEventBreakDownTab_ChangeEventLineItemFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...items.map((i) => ({
      kind: "item" as const,
      id: i.id,
      data: i,
    })),
  ];
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Item Code",
      item: ({ projectItem }) => <div css={Css.mwPx(280).$}>{projectItem.displayName}</div>,
    }),
    column<Row>({
      header: "Underwritten cost",
      item: ({
        projectItem: {
          budgetFinancials: { original },
        },
      }) => priceCell({ valueInCents: original }),
      align: "right",
    }),
    column<Row>({
      header: "Current cost",
      item: ({
        projectItem: {
          budgetFinancials: { revisedInternalBudget },
        },
      }) => priceCell({ valueInCents: revisedInternalBudget }),
      align: "right",
    }),
    column<Row>({
      header: "Proposed cost",
      item: ({ proposedTotalCostInCents }) => priceCell({ valueInCents: proposedTotalCostInCents }),
      align: "right",
    }),
    column<Row>({
      header: "Cost change",
      item: ({ totalCostInCents }) =>
        priceCell({
          valueInCents: totalCostInCents,
          displayDirection: true,
          invertColors: true, // cost cuts are good, cost increases are "bad"
          zeroIs: "negative", // 0 may as well be a cut
        }),
      align: "right",
    }),
  ];
}

type Row = { kind: "header" } | ItemRow;

type ItemRow = {
  kind: "item";
  id: string;
  data: ChangeEventBreakDownTab_ChangeEventLineItemFragment;
};
