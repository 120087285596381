import { Css, Icon, Tooltip } from "@homebound/beam";
import { useCallback } from "react";
import { ToDoTabs } from "src/components/to-dos/ToDoModal";
import { ToDoChecklistProgress } from "src/generated/graphql-types";

type ToDoChecklistStatusProps = {
  checklistProgress: ToDoChecklistProgress;
  toDoId: string;
  openTodoModal?: (toDoId: string, tab: string) => void;
};

export function ToDoChecklistStatus({ checklistProgress, toDoId, openTodoModal }: ToDoChecklistStatusProps) {
  const { completed, total } = checklistProgress;

  const onChecklistClick = useCallback(() => {
    if (openTodoModal) openTodoModal(toDoId, ToDoTabs.checklist);
  }, [toDoId, openTodoModal]);

  // Current design is to hide the checklist icon if there are no checklist items.
  if (total === 0) return null;

  const allComplete = completed === total;

  const tooltipText = allComplete
    ? "All checklist items complete"
    : `${checklistProgressSummary(checklistProgress)} checklist items complete`;

  const icon = () => (
    <Icon data-testid="toDoChecklistStatus" icon={allComplete ? "checklistComplete" : "checklistNotComplete"} />
  );

  return (
    <Tooltip title={tooltipText}>
      {/* In Kanban view, we don't expect the icon to be clickable as the whole card is a Drag/Drop handle */}
      {openTodoModal ? (
        <button css={Css.onHover.bgGray100.$} onClick={onChecklistClick}>
          {icon()}
        </button>
      ) : (
        icon()
      )}
    </Tooltip>
  );
}

export function checklistProgressSummary(checklistProgress?: ToDoChecklistProgress) {
  if (!checklistProgress || !checklistProgress.total) return "(0)";

  return `(${checklistProgress.completed}/${checklistProgress.total})`;
}
