import { Avatar, Chip, Css, Icon, Palette, useTestIds } from "@homebound/beam";
import { useHistory } from "react-router-dom";
import { createApprovalDashboardUrl } from "src/RouteUrls";
import {
  ApprovalTab_ApproverFragment,
  ChangeRequestDetailsFragment,
  ChangeRequestSource,
} from "src/generated/graphql-types";
import { useApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { ApprovalAvatar } from "src/routes/components/Approval/ApproverDetail";
import { approverChipTypeMapper, centsToDollars } from "src/utils";

type ChangeRequestApprovalsTabProps = {
  changeRequest: ChangeRequestDetailsFragment;
};

export function ChangeRequestApprovalsTab({ changeRequest }: ChangeRequestApprovalsTabProps) {
  const { approval } = changeRequest;
  const openApproval = useApprovalSuperDrawer();
  const { push } = useHistory();

  const tids = useTestIds({}, "changeRequestApprovalsTab");

  if (!approval)
    return (
      <div css={Css.bgGray100.br4.p2.mt1.tac.$}>
        {changeRequest.source.code === ChangeRequestSource.BoylCustomerRequestPersonalization
          ? "Requests with a change source of BOYL Change Request / Personalization are auto-approved."
          : "No Approvals"}
      </div>
    );

  return (
    <div {...tids}>
      <div css={Css.baseSb.mb3.$}>Approver Order</div>
      {approval.approvers.map((approver) => (
        <ChangeRequestApprover approver={approver} key={approver.id} />
      ))}
      <div css={Css.df.mt2.aic.$}>
        <Icon icon="link" xss={Css.mr1.$} inc={2} />
        <div css={Css.gray700.$}>Linked to</div>
        &nbsp;
        <div
          css={Css.blue700.cursorPointer.$}
          onClick={() => {
            // redirect to approvals dashboard
            push(createApprovalDashboardUrl(approval.id));
            // and then open approval
            openApproval(approval.id);
          }}
        >
          Approval
        </div>
      </div>
    </div>
  );
}

function ChangeRequestApprover({ approver }: { approver: ApprovalTab_ApproverFragment }) {
  const { user, wasAutoApproved, status, subjectData, requireSignature } = approver;
  const tids = useTestIds({}, "changeRequestApproverOrderList");
  const [type, label] = status ? approverChipTypeMapper(status, requireSignature) : [];

  return (
    <div css={Css.df.py1.aic.smMd.gap1.$} {...tids}>
      {user.__typename === "InternalUser" ? (
        <Avatar {...tids.avatar} src={user.avatar} name={user.name} showName size="sm" />
      ) : user.__typename === "TradePartnerUser" ? (
        <ApprovalAvatar {...tids.avatar} name={user.name} caption={user.tradePartner.name} />
      ) : (
        <ApprovalAvatar {...tids.avatar} name={user.name} caption="HO" />
      )}
      <Chip
        text={wasAutoApproved ? "Auto-Approved" : (label ?? "")}
        type={wasAutoApproved ? "success" : type}
        {...tids.approverStatus}
      />
      {wasAutoApproved && (
        <>
          <Icon
            icon="infoCircle"
            color={Palette.Gray600}
            inc={2}
            tooltip="This step has been auto-approved (this user was not contacted), because this amount was less than the auto-approval threshold."
          />
          {subjectData?.autoApprovalAmountInCents && (
            <div css={Css.gray700.xsMd.$}>(Less than ${centsToDollars(subjectData?.autoApprovalAmountInCents)})</div>
          )}
        </>
      )}
    </div>
  );
}
