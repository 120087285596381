import { Button, Css, Icon, Palette } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useEffect, useState } from "react";
import { HoverDelete } from "src/components/HoverDelete";
import {
  SaveScheduleFlagInput,
  ScheduleFlagDetailFragment,
  TaskDetailPaneFragment,
  useDeleteScheduleFlagMutation,
  useSaveScheduleFlagMutation,
  useScheduleFlagReasonsQuery,
} from "src/generated/graphql-types";
import { pluralize } from "src/utils";
import { ScheduleFlag } from "./ScheduleFlag";

type ScheduleFlagsProps = {
  task: TaskDetailPaneFragment;
};

export function ScheduleFlags({ task }: ScheduleFlagsProps) {
  const [creating, setCreating] = useState(false);
  const [editingFlag, setEditingFlag] = useState<ScheduleFlagDetailFragment | null>(null);
  const [hoveredId, setHoveredId] = useState("");
  const [deleteFlagMutation] = useDeleteScheduleFlagMutation();
  const [saveScheduleFlag] = useSaveScheduleFlagMutation();
  const { data } = useScheduleFlagReasonsQuery();

  const deleteFlag = async ({ id }: ScheduleFlagDetailFragment) => {
    await deleteFlagMutation({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const onClose = () => {
    setCreating(false);
    setEditingFlag(null);
  };

  const save = async (formState: ObjectState<Partial<SaveScheduleFlagInput>>) => {
    const { reasonId, description, durationInDays } = formState.value;
    await saveScheduleFlag({
      variables: {
        input: {
          id: editingFlag?.id,
          reasonId: reasonId,
          scheduleTaskId: task.id,
          durationInDays,
          description,
        },
      },
    });
    onClose();
  };

  const { scheduleFlagReasons } = data || { scheduleFlagReasons: [] };
  const { scheduleFlags } = task;

  // When switching tasks, close the form
  useEffect(onClose, [task]);

  const displayScheduleFlag = creating || editingFlag !== null;

  return (
    <>
      <div css={Css.smMd.mb1.$}>
        <span css={Css.df.aic.jcsb.sm.gray700.$}>
          Flags
          <Button
            label="Add a Flag"
            variant="tertiary"
            onClick={() => setCreating(true)}
            disabled={displayScheduleFlag}
          />
        </span>
      </div>
      {displayScheduleFlag && (
        <ScheduleFlag scheduleFlag={editingFlag} reasons={scheduleFlagReasons} onClose={onClose} onSave={save} />
      )}
      {scheduleFlags.isEmpty && !creating ? (
        <div data-testid="noFlags" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no flags associated to this task
        </div>
      ) : (
        <div data-testid="taskFlags" css={Css.df.fdc.gap1.mb1.br8.$}>
          {scheduleFlags
            .filter(({ id }) => id !== editingFlag?.id)
            .map((flag) => {
              const { id, durationInDays, reason, description } = flag;
              return (
                <div
                  key={id}
                  css={Css.df.p1.bgGray100.mhPx(70).$}
                  onPointerEnter={() => setHoveredId(id)}
                  onPointerLeave={() => setHoveredId("")}
                >
                  <div css={Css.df.aic.$}>
                    <Icon icon="flag" color={Palette.Red700} />
                  </div>
                  <div css={Css.df.fdc.jcc.fg1.m1.tiny.$}>
                    <span data-testid="title" css={Css.fwb.$}>
                      {durationInDays} {pluralize(durationInDays ?? 1, "Day")} {reason?.scheduleFlagReasonType.name} -{" "}
                      {reason?.title}
                    </span>
                    <span>{description}</span>
                  </div>
                  <div css={Css.df.aic.gap1.$}>
                    <HoverDelete visible={hoveredId === id} icon="pencil" onClick={() => setEditingFlag(flag)} />
                    <HoverDelete
                      data-testid="deleteFlag"
                      visible={hoveredId === id}
                      icon="x"
                      onClick={() => deleteFlag(flag)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}
