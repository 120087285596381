import { InputMaybe, TimeFrequency, TimeFrequencyDetail } from "src/generated/graphql-types";
import { timeFrequencyToNameMapper } from "src/utils";

function convertTimeFrequencyToLeadTimeInDays(days: number, timeFrequency: TimeFrequencyDetail) {
  switch (timeFrequency.code) {
    case TimeFrequency.Weeks:
      return Math.floor(days / 7);
    case TimeFrequency.Months:
      return Math.floor(days / 30);
    default:
      return days;
  }
}

export function formatLeadTime(leadTimeInDays: number, leadTimeFrequency: TimeFrequencyDetail) {
  const number = convertTimeFrequencyToLeadTimeInDays(leadTimeInDays, leadTimeFrequency);
  const timeFrequency = timeFrequencyToNameMapper[leadTimeFrequency.code];
  // pluralize won't work here since the word starts with an s (we are de-pluralizing)
  return `${number} ${number === 1 ? timeFrequency.slice(0, -1) : timeFrequency}`;
}

export function getLeadTimeInDays(leadTime: InputMaybe<number>, frequency: InputMaybe<TimeFrequency>): number {
  return frequency === TimeFrequency.Weeks
    ? Number(leadTime) * 7
    : frequency === TimeFrequency.Months
      ? Number(leadTime) * 30
      : Number(leadTime);
}
