import { Css, FilterDefs, Filters, multiFilter, useComputed, useFilter, useSnackbar } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createPlanPackageUrl } from "src/RouteUrls";
import { SearchBox, useNavigationCheck } from "src/components";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import {
  AddOptionsGlobalOptionTypeFragment,
  useAddOptionsMetadataQuery,
  useAddOptionsPageQuery,
  useSaveReadyPlanMutation,
} from "src/generated/graphql-types";
import { OptionDetailsTable } from "src/routes/developments/plan-and-options/components/OptionDetailsTable";
import {
  mapReadyPlanToFormState,
  saveReadyPlanOptionsProgramData,
} from "src/routes/developments/plan-and-options/components/utils";
import { TableActions } from "src/routes/layout/TableActions";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { addRpoConfig } from "./utils";

type OptionDetailsStepProps = {
  id: string;
  setStepDirty: (dirty: boolean) => void;
};

export function OptionDetailsStep({ id, setStepDirty }: OptionDetailsStepProps) {
  // const tid = useTestIds({}, "optionDetailsStep");
  const { triggerNotice } = useSnackbar();
  const history = useHistory();
  const { data: metadata } = useAddOptionsMetadataQuery();
  const query = useAddOptionsPageQuery({ variables: { id } });
  const [save] = useSaveReadyPlanMutation();
  const globalOptionTypes = useMemo(() => query.data?.globalOptionTypes ?? [], [query.data?.globalOptionTypes]);

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const filterDefs: FilterDefs<OptionTypeFilter> = useMemo(() => {
    const option = multiFilter({
      label: "Filter by",
      options: globalOptionTypes,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { option };
  }, [globalOptionTypes]);
  const { filter, setFilter } = useFilter({ filterDefs });

  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    const options = globalOptionTypes.map(({ id }) => id);
    return globalOptionTypes.filter(
      (got) =>
        !got.isSpecLevel && (!filter?.option?.length ? options.includes(got.id) : filter.option.includes(got.id)),
    );
  }, [globalOptionTypes, filter.option]);

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      query: query,
      map: ({ readyPlan }) => mapReadyPlanToFormState(readyPlan),
    },
  });

  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const onSave = async () => {
    // Save Plan Details
    const toSaveReadyPlanDetails = formState.changedValue;
    // Check if any change other than the id exists to execute the save
    const realChanges = Object.keys(toSaveReadyPlanDetails).find((k) => k !== "id");
    if (realChanges && query.data?.readyPlan) {
      await saveReadyPlanOptionsProgramData(formState, query.data?.readyPlan, save);
      formState.commitChanges();
    }

    triggerNotice({
      message: `Your Ready Plan Options have been updated!`,
    });

    history.push(createPlanPackageUrl(id));
  };

  return (
    <StepLayout
      header={
        <PlanPackageEditorHeader
          title="Option Details"
          subtitle="Enter the program data for the Plan Options."
          tooltip="Decide if any of the options that you selected in the previous step will add or subtract square footage or bedrooms, bathrooms, garages, or carport."
        />
      }
      body={
        <div css={Css.pt3.df.fdc.fg1.$}>
          <TableActions onlyRight>
            <div css={Css.df.gap1.jcfe.$}>
              <Filters<OptionTypeFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
              <SearchBox onSearch={setSearchFilter} clearable updateQueryString={false} />
            </div>
          </TableActions>

          <div css={Css.fg1.pb1.$}>
            <OptionDetailsTable
              formState={formState}
              searchFilter={searchFilter}
              optionsTypes={optionsTypes}
              metadata={metadata}
              filter={{}}
              showActions={false}
            />
          </div>
          <NextStepButton
            label="Finish"
            disabled={false}
            onClick={onSave}
            onCloseReturnUrl={createPlanPackageUrl(id)}
            exitButton={{
              variant: "secondary",
              label: "Save & Exit",
              onClick: onSave,
            }}
          />
        </div>
      }
    />
  );
}

type OptionTypeFilter = { option: string[] };
