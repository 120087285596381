import {
  column,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  RowStyles,
  simpleHeader,
  useSuperDrawer,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { chipCell } from "src/components";
import { ItemCatalogDetailsFragment } from "src/generated/graphql-types";
import { BidItemParams } from "src/routes/routesDef";
import { createBidItemDetailsUrl, createItemCatalogUrl } from "src/RouteUrls";
import { BidItemSuperDrawer } from "./BidItemSuperDrawer";

export type ItemCatalogTableProps = {
  filteredBidItems: ItemCatalogDetailsFragment[];
};

export function ItemCatalogTable({ filteredBidItems }: ItemCatalogTableProps) {
  const { openInDrawer } = useSuperDrawer();
  const { push } = useHistory();
  const rows = useMemo(() => createRows(filteredBidItems), [filteredBidItems]);
  const rowLookup = useRef<GridRowLookup<Row>>();
  const tid = useTestIds({});
  const { bidItemId } = useParams<BidItemParams>();

  const openRow = useCallback(
    (bidItemId: string) => {
      if (!rowLookup.current) return;

      const list = rowLookup.current.currentList();
      const bidItemRows = list.filter((l) => l.kind === "item");
      const biRow = bidItemRows.find((row) => row.id === bidItemId);
      if (!biRow) return;

      /**
       * Update URL with the prev/next bidItem id. We do not need to
       * explicity call openRow since the useEffect below watches for URL
       * changes and triggers a SuperDrawer update.
       */
      const { prev, next } = rowLookup.current!.lookup(biRow)["item"];
      openInDrawer({
        content: <BidItemSuperDrawer bidItemId={biRow.id} />,
        onClose: () => {
          // When closing the SuperDrawer, remove /:bidItemId and retain query parameters
          push(createItemCatalogUrl());
        },
        onPrevClick: prev && (() => push(createBidItemDetailsUrl(prev.id))),
        onNextClick: next && (() => push(createBidItemDetailsUrl(next.id))),
      });
    },
    [openInDrawer, push],
  );

  // Attempt to open the SuperDrawer based on the queryParam bidItemId - i.e. libraries/item-catalog/bi:1
  useEffect(() => {
    if (bidItemId) {
      openRow(bidItemId);
    }
  }, [bidItemId, openRow]);

  const columns = useMemo(() => createColumns(), []);
  const rowStyles = useMemo(() => createRowStyles(createBidItemDetailsUrl, push), [push]);

  return (
    <GridTable
      {...tid}
      columns={columns}
      rows={rows}
      rowStyles={rowStyles}
      rowLookup={rowLookup}
      stickyHeader
      sorting={{ on: "client" }}
    />
  );
}

type HeaderRow = { kind: "header"; id: string };
type DetailRow = { kind: "item"; data: ItemCatalogDetailsFragment };
type Row = HeaderRow | DetailRow;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Bid item code",
      item: (p) => p.code,
    }),
    column<Row>({
      header: "Description",
      item: (p) => p.name,
    }),
    column<Row>({
      header: "Cost type",
      item: ({ costType }) => costType.name,
    }),
    column<Row>({
      header: "Assc. item code(s)",
      item: ({ items }) => chipCell(items.map((i) => ({ title: i.name, text: i.fullCode }))),
    }),

    column<Row>({
      header: "UoM",
      item: ({ unitOfMeasure }) => unitOfMeasure?.name,
    }),
  ];
}

function createRows(filteredBidItems: ItemCatalogDetailsFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...filteredBidItems.map((p) => ({
      id: p.id,
      kind: "item" as const,
      data: p,
    })),
  ];
}

function createRowStyles(createBidItemDetailsUrl: (id: string) => string, push: (url: string) => void): RowStyles<Row> {
  function openBidItemDrawer(row: GridDataRow<Row>): void {
    if (row.kind === "item") push(createBidItemDetailsUrl(row.id));
  }
  return {
    item: { onClick: openBidItemDrawer },
  };
}
