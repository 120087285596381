import { Direction } from "@homebound/beam";
import { SchedulePhaseDetailsFragment, TaskScheduleDetailsFragment } from "src/generated/graphql-types";
import { Sortable, sortBy } from "src/utils";

export enum TableSortableCols {
  TaskName = "TaskName",
  TaskStart = "TaskStart",
  TaskStartBaseline = "TaskStartBaseline",
  TaskEnd = "TaskEnd",
  TaskEndBaseline = "TaskEndBaseline",
  TaskDuration = "TaskDuration",
  TaskDurationBaseline = "TaskDurationBaseline",
  TaskStatus = "TaskStatus",
}

export type TableOrder = [orderBy: TableSortableCols | undefined, direction: Direction | undefined];
type TaskComparatorFn = (task: TaskScheduleDetailsFragment) => Sortable;

export function sortTableTasks(
  sortOrder: TableOrder,
  tasks: TaskScheduleDetailsFragment[],
): TaskScheduleDetailsFragment[] {
  const [orderBy, direction] = sortOrder;
  // `orderBy` of `undefined` represents an unsorted table
  if (!orderBy) return tasks;

  const sortFns: Record<TableSortableCols, TaskComparatorFn> = {
    [TableSortableCols.TaskName]: (task) => task.name,
    [TableSortableCols.TaskStart]: (task) => task.interval.startDate,
    [TableSortableCols.TaskStartBaseline]: (task) => task.baselineInterval2?.startDate,
    [TableSortableCols.TaskEnd]: (task) => task.interval.endDate,
    [TableSortableCols.TaskEndBaseline]: (task) => task.baselineInterval2?.endDate,
    [TableSortableCols.TaskDuration]: (task) => task.interval.durationInDays,
    [TableSortableCols.TaskDurationBaseline]: (task) => task.baselineInterval2?.durationInDays,
    [TableSortableCols.TaskStatus]: (task) => task.statusDetail.sortOrder,
  };

  // Overwrite sortFns for endDate to take milestones tasks into account
  if (orderBy === TableSortableCols.TaskEnd) {
    return [...tasks].sort(newComparator((task) => task, direction));
  } else {
    return sortBy(tasks, sortFns[orderBy], direction);
  }
}

export function sortTablePhases(
  sortOrder: TableOrder,
  phases: SchedulePhaseDetailsFragment[],
): SchedulePhaseDetailsFragment[] {
  const [orderBy, direction] = sortOrder;
  if (!orderBy) return phases;

  const sortFns: Record<TableSortableCols, (task: SchedulePhaseDetailsFragment) => Sortable> = {
    [TableSortableCols.TaskName]: notSupported,
    [TableSortableCols.TaskStart]: (phase) => phase.interval?.startDate,
    [TableSortableCols.TaskEnd]: (phase) => phase.interval?.endDate,
    [TableSortableCols.TaskStartBaseline]: notSupported,
    [TableSortableCols.TaskEndBaseline]: notSupported,
    [TableSortableCols.TaskDuration]: notSupported,
    [TableSortableCols.TaskDurationBaseline]: notSupported,
    [TableSortableCols.TaskStatus]: notSupported,
  };

  return sortBy(phases, sortFns[orderBy], direction);
}

const notSupported = () => 1;

// Special handling for end date to take milestones tasks into account.
function newComparator<T>(f: (x: T) => any, direction?: Direction): (a: T, b: T) => number {
  return (a, b) => {
    const av = f(a);
    const bv = f(b);
    const avEndDate = av.interval.endDate.getTime();
    const bvEndDate = bv.interval.endDate.getTime();

    if (avEndDate === bvEndDate) {
      // If end dates are the same sort by milestone ascending
      const avMilestone = Number(av.globalTask?.isMilestone ?? false);
      const bvMilestone = Number(bv.globalTask?.isMilestone ?? false);
      return (avMilestone - bvMilestone) * 1;
    } else {
      // If not, sort by end date with specific direction
      const sortDirection = direction === "DESC" ? -1 : 1;
      return (avEndDate - bvEndDate) * sortDirection;
    }
  };
}
