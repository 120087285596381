import { Css, Icon } from "@homebound/beam";
import { ApprovalSubject_ChangeEventFragment } from "src/generated/graphql-types";
import { LotConfigurationDetailsStaticField } from "src/routes/projects/components/LotConfigurationDetailsStaticField";
import { HeroMetric } from "../HeroMetric";

type ApprovalSummaryProps = {
  subjects: ApprovalSubject_ChangeEventFragment[];
};

export function ApprovalSummary({ subjects }: ApprovalSummaryProps) {
  const loneSubject = subjects.length === 1 && subjects.first!;
  const currentRevisedBudget = subjects.sum((s) => s.originalTotalCostInCents);
  const proposedRevisedBudget = subjects.sum((s) => s.proposedTotalCostInCents);
  const budgetImpact = proposedRevisedBudget - currentRevisedBudget;
  return (
    <div css={Css.df.fdc.gap3.mt2.aifs.maxwPx(486).$}>
      <div css={Css.$}>
        <div css={Css.baseSb.df.fdc.gap1.$} data-testid="approvalSummary_project">
          <div css={Css.df.fdr.gap1.$}>
            <Icon icon="house" inc={3} />
            {loneSubject ? loneSubject.project?.name : `${subjects.length} Projects`}
          </div>
          {loneSubject && (
            <LotConfigurationDetailsStaticField projectId={loneSubject.project.id} layout="row" size="xsMd" />
          )}
        </div>
      </div>
      <div css={Css.bgGray100.p2.br8.df.fdr.gap3.$}>
        <HeroMetric
          value={currentRevisedBudget}
          subTitle1="Current revised budget"
          size="xs"
          rounded={false}
          data-testid="revisedMetric"
          trim={false}
        />
        <HeroMetric
          value={proposedRevisedBudget}
          subTitle1="Proposed revised budget"
          size="xs"
          rounded={false}
          data-testid="proposedMetric"
          trim={false}
        />
        <HeroMetric
          value={budgetImpact}
          subTitle1="Budget impact"
          greenIf="negative"
          size="xs"
          rounded={false}
          data-testid="impactMetric"
          displayDirection
          trim={false}
          colorXss={budgetImpact === 0 ? Css.$ : undefined}
        />
      </div>
    </div>
  );
}
