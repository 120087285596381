import { BoundChipSelectField } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useContext, useEffect, useState } from "react";
import {
  GlobalSubPhaseMetaData,
  SchedulePhaseContext,
} from "src/routes/projects/schedule-v2/contexts/SchedulePhaseContext";
import { TaskDetailsFormInput } from "src/routes/projects/schedule-v2/table/utils";

type SubPhaseCellProps = {
  objectState: ObjectState<Partial<TaskDetailsFormInput>>;
  scheduleIsLocked: boolean;
};

export function SubPhaseCell({ objectState, scheduleIsLocked }: SubPhaseCellProps) {
  const { globalSubPhases } = useContext(SchedulePhaseContext);
  const [options, setOptions] = useState(globalSubPhases);
  // If the context changes, reset the options
  useEffect(() => setOptions(globalSubPhases), [globalSubPhases]);

  return (
    <BoundChipSelectField
      getOptionValue={subPhaseOptionValue}
      getOptionLabel={subPhaseOptionLabel}
      options={options}
      placeholder="+ SubPhase"
      field={objectState.scheduleSubPhase}
      onCreateNew={async (subPhase) => {
        setOptions((prev) => [...prev, { label: subPhase, value: subPhase }]);
        objectState.scheduleSubPhase.set(subPhase);
      }}
      disabled={scheduleIsLocked}
      clearable={true}
    />
  );
}

function subPhaseOptionValue(gsp: GlobalSubPhaseMetaData) {
  return gsp.value;
}

function subPhaseOptionLabel(gsp: GlobalSubPhaseMetaData) {
  return gsp.label;
}
