import { Css } from "@homebound/beam";
import { Icon } from "src/components/Icon";
import { PersonalDashboard_ScheduleTaskFragment } from "src/generated/graphql-types";
import { TaskParentTag } from "../../components/TaskParentTag";

type ScheduleTaskRowDataProps = {
  scheduleTask: PersonalDashboard_ScheduleTaskFragment;
  onTaskSelect: (taskId: string) => void;
};

export function TaskRowIcon({ scheduleTask }: Omit<ScheduleTaskRowDataProps, "onTaskSelect">) {
  const { isGlobalMilestoneTask, isCriticalPath } = scheduleTask;

  if (!isGlobalMilestoneTask && !isCriticalPath) return null;

  return (
    <>
      {isGlobalMilestoneTask ? (
        <div data-testid="milestoneIcon" css={Css.p1.bgBlue100.br4.$}>
          <Icon icon="diamond" />
        </div>
      ) : (
        <div data-testid="criticalPath" css={Css.p1.$}>
          <Icon icon="criticalPath" color="#646464" />
        </div>
      )}
    </>
  );
}

export function TaskTitleAndParent({ scheduleTask, onTaskSelect }: ScheduleTaskRowDataProps) {
  return (
    <div data-testid="taskTitleAndParent">
      <div
        data-testid="taskTitle"
        css={Css.sm.pb1.blue700.cursorPointer.onHover.tdu.$}
        onClick={() => onTaskSelect(scheduleTask.id)}
      >
        {scheduleTask.name}
      </div>
      <TaskParentTag taskParent={scheduleTask.parent} />
    </div>
  );
}
