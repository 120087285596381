import { Css } from "@homebound/beam";
import { ReactNode } from "react";

export function SectionTitle({ title, right }: { title: ReactNode; right?: ReactNode }) {
  return (
    <div css={Css.df.jcsb.gap2.mb1.$}>
      <h3 css={Css.lgSb.$}>{title}</h3>
      {right}
    </div>
  );
}
