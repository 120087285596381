import {
  Accordion,
  AutoSaveIndicator,
  BoundSelectField,
  Button,
  Chip,
  Css,
  dateFilter,
  FilterDefs,
  Filters,
  FullBleed,
  multiFilter,
  Palette,
  RouteTabWithContent,
  ScrollableContent,
  SelectField,
  singleFilter,
  TabsWithContent,
  Tag,
  toggleFilter,
  usePersistedFilter,
  useSessionStorage,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { snakeCase } from "change-case";
import { subWeeks } from "date-fns";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { SearchBox } from "src/components";
import {
  AddOptionsGlobalOptionTypeFragment,
  BasementConfigDetailFragment,
  GarageConfigDetailFragment,
  Maybe,
  PlanDataOptionDetailsFragment,
  ReadyPlanOptionDetailFragment,
  ReadyPlanOptionsFilter,
  ReadyPlanPageMetadataQuery,
  SaveReadyPlanInput,
  useAddOptionsMetadataQuery,
  useReadyPlanOptionsListQuery,
  useReadyPlanPageMetadataQuery,
  useSaveReadyPlanMutation,
} from "src/generated/graphql-types";
import { EmptyState } from "src/routes/components/EmptyState";
import { cdCompletePercentageOptions } from "src/routes/developments/plan-and-options/utils";
import { PageHeader } from "src/routes/layout/PageHeader";
import {
  UserEventFilterWithDateFilterValue,
  UserEventsTable,
} from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { useStorageFilter } from "src/routes/projects/budget/components/useStorageFilter";
import { developmentPaths, PlanDetailsParams } from "src/routes/routesDef";
import {
  createDevelopmentPlanAndOptionsDetailsActivityUrl,
  createDevelopmentPlanAndOptionsDetailsOptionDataUrl,
  createDevelopmentPlanAndOptionsDetailsPlanDataUrl,
  createDevelopmentPlanAndOptionsUrl,
  createReadyPlanEditOptionsUrl,
} from "src/RouteUrls";
import {
  DateFilterOperation,
  dateFilterOperations,
  formatNumberToString,
  pluralize,
  queryResult,
  readyPlanStatusToTagType,
} from "src/utils";
import { DateOnly } from "src/utils/dates";
import { inchesToFeet } from "src/utils/valueAdaptors";
import { EmptyPlansDetailImage } from "./components/EmptyStateSvgs";
import { OptionDetailsTable, PlanOptionGroupBy } from "./components/OptionDetailsTable";
import { OptionDetailCard } from "./components/PlanDetailsCard";
import { addRpoConfig, mapReadyPlanToFormState, saveReadyPlanOptionsProgramData } from "./components/utils";

export function PlanDetailsPage() {
  const { readyPlanId, developmentId } = useParams<PlanDetailsParams>();
  const planPageMetadataQuery = useReadyPlanPageMetadataQuery({ variables: { id: readyPlanId } });

  return queryResult(planPageMetadataQuery, {
    data: (data) => <DataView readyPlanId={readyPlanId} developmentId={developmentId} metadata={data} />,
  });
}

type DataViewProps = {
  readyPlanId: string;
  developmentId: string;
  metadata: ReadyPlanPageMetadataQuery;
};

const formConfig: ObjectConfig<SaveReadyPlanInput> = {
  constructionDocumentCompletePercentage: { type: "value", rules: [required] },
  status: { type: "value", rules: [required] },
};

function DataView({ readyPlanId, developmentId, metadata }: DataViewProps) {
  const { readyPlans = [], readyPlanStatuses = [] } = metadata;
  const { status, displayName } = readyPlans[0];

  const [saveReadyPlan] = useSaveReadyPlanMutation();
  const formState = useFormState({
    config: formConfig,
    init: {
      input: readyPlans[0],
      map: (rp) => ({
        constructionDocumentCompletePercentage: rp.constructionDocumentCompletePercentage,
        status: rp.status.code,
      }),
    },
    autoSave: async (state) => {
      await saveReadyPlan({
        variables: {
          input: {
            id: readyPlanId,
            constructionDocumentCompletePercentage: state.constructionDocumentCompletePercentage.value,
            status: state.status.value,
          },
        },
      });
    },
  });

  return (
    <>
      <PageHeader
        title={displayName}
        backButton={createDevelopmentPlanAndOptionsUrl(developmentId)}
        left={
          <>
            <Tag text={status.name} type={readyPlanStatusToTagType[status.code]} data-testid="tagStatus" />
            <AutoSaveIndicator />
          </>
        }
        right={
          <>
            <div css={Css.wPx(120).$}>
              <BoundSelectField
                data-testid="cdCompletePercentage"
                label={"CD"}
                labelStyle="inline"
                compact
                field={formState.constructionDocumentCompletePercentage}
                options={cdCompletePercentageOptions}
                getOptionLabel={(o) => o.label}
                getOptionValue={(o) => o.value}
              />
            </div>
            <div css={Css.wPx(120).$}>
              <BoundSelectField
                data-testid="status"
                label={"Status"}
                labelStyle="hidden"
                compact
                field={formState.status}
                options={readyPlanStatuses}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.code}
              />
            </div>
            <Button label="Edit options" onClick={createReadyPlanEditOptionsUrl(developmentId, readyPlanId)} />
          </>
        }
        breadcrumb={{ label: "ReadyPlan", href: createDevelopmentPlanAndOptionsUrl(developmentId) }}
        xss={Css.m0.bgWhite.py4.$}
      />
      <PlanDataDetails readyPlanId={readyPlanId} developmentId={developmentId} metadata={metadata} />
    </>
  );
}

function PlanDataDetails({
  readyPlanId,
  developmentId,
  metadata,
}: {
  readyPlanId: string;
  developmentId: string;
  metadata: ReadyPlanPageMetadataQuery;
}) {
  const tabs: RouteTabWithContent[] = useMemo(() => {
    const tabs = [
      {
        value: "planData",
        name: "Plan Data",
        href: createDevelopmentPlanAndOptionsDetailsPlanDataUrl(developmentId, readyPlanId),
        path: [developmentPaths.planDetails, developmentPaths.planData],
        render: () => <PlanData metadata={metadata} readyPlanId={readyPlanId} />,
      },
      {
        value: "optionData",
        name: "Option Data",
        href: createDevelopmentPlanAndOptionsDetailsOptionDataUrl(developmentId, readyPlanId),
        path: developmentPaths.optionData,
        render: () => <TableView metadata={metadata} />,
      },
      {
        value: "activity",
        name: "Activity",
        href: createDevelopmentPlanAndOptionsDetailsActivityUrl(developmentId, readyPlanId),
        path: developmentPaths.activity,
        render: () => <Activity metadata={metadata} />,
      },
    ];
    return tabs;
  }, [developmentId, metadata, readyPlanId]);

  return (
    <div css={Css.py2.$}>
      <TabsWithContent alwaysShowAllTabs tabs={tabs} />
    </div>
  );
}

type PlanDataProps = {
  metadata: ReadyPlanPageMetadataQuery;
  readyPlanId: string;
};

export function PlanData({ metadata, readyPlanId }: PlanDataProps) {
  const tid = useTestIds({}, "planData");
  const programData = metadata.readyPlans[0].programData;
  const options = metadata.readyPlans[0].options
    .filter((rpo) => rpo.type.isElevation)
    .map((e) => ({ id: e.id, name: e.name }));

  const filterDefs: FilterDefs<FilterOptionData> = useMemo(() => {
    const showOptionData = toggleFilter({ label: "Show Option Data" });
    const id = singleFilter({
      label: "View Elevation",
      options: options,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });
    return { showOptionData, id };
  }, [options]);

  const { filter, setFilter } = usePersistedFilter<FilterOptionData>({
    storageKey: "readyPlanOptionsFilter",
    filterDefs,
  });

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const { data, loading } = useReadyPlanOptionsListQuery({
    variables: {
      filter: {
        readyPlan: [readyPlanId],
        isParentOption: true,
        active: true,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const elevations = useMemo(() => {
    return (
      data?.readyPlanOptions
        .filter((rpo) =>
          [rpo.name, rpo.globalOption.name, rpo.globalOption.code]
            .map((s) => s?.toLowerCase())
            .some((s) => s?.includes(searchFilter?.toLowerCase()!)),
        )
        .filter((rpo) => (filter.id ? rpo.id === filter.id && rpo.type.isElevation : rpo.type.isElevation)) ?? []
    );
  }, [data?.readyPlanOptions, filter.id, searchFilter]);

  const otherOptions = useMemo(() => {
    return data?.readyPlanOptions.filter((rpo) => !rpo.type.isElevation) ?? [];
  }, [data?.readyPlanOptions]);

  return (
    <>
      <div css={Css.df.aic.gap1.jcfe.$}>
        <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <SearchBox onSearch={setSearchFilter} />
      </div>
      <ScrollableContent>
        <div css={Css.bgGray100.mh100.pxPx(60).$}>
          <div css={Css.pb3.$} {...tid.elevation}>
            <h1 css={Css.xlBd.gray900.pb3.$} {...tid.elevationTitle}>
              Elevation & Square Footages
            </h1>
            {!loading && elevations.length === 0 && (
              <EmptyState svg={<EmptyPlansDetailImage />} message="There are no elevations selected yet" />
            )}
            {!loading && elevations.length > 0 && (
              <div css={Css.dg.gtc("repeat(auto-fill, 535px)").gap3.add("alignContent", "flex-start").$}>
                {elevations.map((e) => (
                  <PlanDataCard
                    key={e.id}
                    tid={tid}
                    lines={squareFootageLines(e.programData, otherOptions)}
                    title="Square Footage"
                    showOptionData={filter.showOptionData}
                    subtitle={`${e.globalOption.code} - ${e.globalOption.name}`}
                    subTitleFooter={`Exterior Scheme(s): ${
                      e.childOptions.length
                        ? e.childOptions.map((exteriorScheme) => exteriorScheme.globalOption.name).join(", ")
                        : "None"
                    }`}
                    icon="ruler"
                    tooltip="TBD"
                  />
                ))}
              </div>
            )}
          </div>
          <div css={Css.pb3.$} {...tid.options}>
            <h1 css={Css.xlBd.gray900.pb3.$} {...tid.OtherTitle}>
              Bed, Bath, Garage
            </h1>
            <div css={Css.df.gap3.add("flexWrap", "wrap").$}>
              <div css={Css.df.fdc.gap3.$}>
                <PlanDataCard
                  tid={tid}
                  lines={generalLines(programData, otherOptions, metadata.readyPlans[0].description)}
                  title="General"
                  showOptionData={filter.showOptionData}
                  icon="projects"
                />
                <PlanDataCard
                  tid={tid}
                  lines={bedroomLines(programData, otherOptions)}
                  title="Bedrooms"
                  showOptionData={filter.showOptionData}
                  icon="bed"
                />
                <PlanDataCard
                  tid={tid}
                  lines={bathroomLines(programData, otherOptions)}
                  title="Bathrooms"
                  showOptionData={filter.showOptionData}
                  icon="bath"
                />
                <PlanDataCard
                  tid={tid}
                  lines={diningLines(programData, otherOptions)}
                  title="Dining"
                  showOptionData={filter.showOptionData}
                  icon="chair"
                />
              </div>
              <div css={Css.df.fdc.gap3.$}>
                <PlanDataCard
                  tid={tid}
                  lines={entertainingLines(programData, otherOptions)}
                  title="Entertaining"
                  showOptionData={filter.showOptionData}
                  icon="image"
                />
                <PlanDataCard
                  tid={tid}
                  lines={workFromHomeLines(programData, otherOptions)}
                  title="Work from Home"
                  showOptionData={filter.showOptionData}
                  icon="task"
                />
                <PlanDataCard
                  tid={tid}
                  lines={garageLines(programData, otherOptions)}
                  title="Garage"
                  showOptionData={filter.showOptionData}
                  icon="car"
                />
                <PlanDataCard
                  tid={tid}
                  lines={basementLines(programData)}
                  title="Basement"
                  icon="basement"
                  showOptionData={filter.showOptionData}
                  alignIcon={true}
                />
              </div>
            </div>
          </div>
        </div>
      </ScrollableContent>
    </>
  );
}

type PlanDataCardProps = {
  tid: Record<string, object>;
  lines: CardLine[];
  title: string;
  showOptionData?: boolean;
  icon?: string;
  subtitle?: string;
  subTitleFooter?: string;
  alignIcon?: boolean;
  tooltip?: string;
};

export function PlanDataCard(props: PlanDataCardProps) {
  const { tid, lines, subtitle, subTitleFooter, title, showOptionData, icon, alignIcon, tooltip } = props;
  return (
    <OptionDetailCard title={title} icon={icon} width={535} alignIcon={alignIcon} tooltip={tooltip}>
      <div css={Css.mt3.$}>
        {subtitle && (
          <div>
            <h2 css={Css.baseMd.gray900.mt1.$} {...tid.name}>
              {subtitle}
            </h2>
            <span {...tid.exteriorSchemes}>{subTitleFooter}</span>
          </div>
        )}
        <div css={Css.gap1.aic.my2.$}>
          {lines.map(({ label, value, unit, affectedOptions, sum, displayUnit }) => {
            return (
              <div css={Css.mb2.$} key={label}>
                <div css={Css.df.jcsb.wPx(425).$}>
                  <div css={Css.sm.gray700.h100.mr1.$} data-testid={snakeCase(`${label.toLowerCase()}_label`)}>
                    {label}
                  </div>
                  {value && (
                    <p data-testid={snakeCase(`${label.toLowerCase()}_value`)}>
                      {`${isNumber(value) ? formatNumberToString(value, true, 3) : value} ${
                        displayUnit && unit ? isNumber(value) && getDisplayUnit(value, unit) : ""
                      }`}
                    </p>
                  )}
                </div>
                {showOptionData && affectedOptions.length === 1 && (
                  <div css={Css.df.jcsb.gap2.aic.wPx(480).mhPx(40).p1.baseMd.bgGray100.br8.my1.$} {...tid.optionData}>
                    {affectedOptions.map(({ code, label, value }) => {
                      return (
                        <div key={label} css={Css.df.jcsb.aic.wPx(420).$} {...tid.optionDataTitle}>
                          <p css={Css.smMd.ml1.wPx(250).$}>{`${code} - ${label}`}</p>
                          <Chip
                            type="success"
                            text={`${displayNumberSign(value)}  ${
                              unit ? isNumber(value) && getDisplayUnit(value, unit) : ""
                            }`}
                            compact
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                {showOptionData && affectedOptions.length > 1 && (
                  <div css={Css.my1.$} {...tid.optionData}>
                    <Accordion
                      title={
                        <div css={Css.df.w100.jcsb.aic.$}>
                          <span>{`${affectedOptions.length} ${pluralize(affectedOptions, "option")} affect this`}</span>
                          <Chip
                            type="success"
                            text={`${displayNumberSign(sum)} ${unit ? isNumber(sum) && getDisplayUnit(sum, unit) : ""}`}
                            compact
                          />
                        </div>
                      }
                      size="md"
                      compact
                    >
                      {affectedOptions.map(({ code, label, value }) => {
                        return (
                          <div key={label} css={Css.df.jcsb.w100.aic.sm.gray900.mb1.pr4.$}>
                            <p css={Css.smMd.wPx(250).$}>{`${code} - ${label}`}</p>
                            <Chip
                              type="success"
                              text={`${displayNumberSign(value)}  ${
                                unit ? isNumber(value) && getDisplayUnit(value, unit) : ""
                              }`}
                              compact
                            />
                          </div>
                        );
                      })}
                    </Accordion>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </OptionDetailCard>
  );
}

function displayNumberSign(value: number) {
  const stringifiedNumber = formatNumberToString(value, true, 3);
  return `${value > 0 ? "+" : ""}${stringifiedNumber}`;
}

function getDisplayUnit(value: number, unit: string) {
  return unit && unit.endsWith("ft") ? unit : isNumber(value) && unit ? pluralize(value, unit) : unit;
}

export function squareFootageLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Sellable SF", field: "sellableSqft", unit: "sq ft" }),
    line({ programData, options, label: "Net Buildable SF", field: "netBuildableSqft", unit: "sq ft" }),
    line({ programData, options, label: "Gross Buildable SF", field: "grossBuildableSqft", unit: "sq ft" }),
    line({ programData, options, label: "Permitable SF", field: "permittableSqft", unit: "sq ft" }),
    line({ programData, options, label: "Sellable Above Ground SF", field: "sellableAboveGroundSqft", unit: "sq ft" }),
    line({ programData, options, label: "Gross Below Ground SF", field: "grossBelowGroundSqft", unit: "sq ft" }),
    line({ programData, options, label: "Sellable Below Ground SF", field: "sellableBelowGroundSqft", unit: "sq ft" }),
    line({
      programData,
      options,
      label: "Unfinished Below Ground SF",
      field: "unfinishedBelowGroundSqft",
      unit: "sq ft",
    }),
    line({ programData, options, label: "Impervious SF", field: "imperviousSqft", unit: "sq ft" }),
    line({ programData, options, label: "Plan Depth FT", field: "depthInFeet", unit: "sq ft" }),
    line({ programData, options, label: "Plan Width FT", field: "widthInFeet", unit: "sq ft" }),
    line({ programData, options, label: "Lot Min Size SF", field: "minLotSizeInSqft", unit: "sq ft" }),
    line({ programData, options, label: "Lot Min Depth FT", field: "minLotDepthInFeet", unit: "sq ft" }),
    line({ programData, options, label: "Lot Min Width FT", field: "minLotWidthInFeet", unit: "sq ft" }),
    line({ programData, options, label: "Building Height FT", field: "buildingHeightInFeet", unit: "ft" }),
  ];
}

export function generalLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
  description: Maybe<string>,
): CardLine[] {
  return [
    line({ programData, options, label: "Stories", field: "stories" }),
    { label: "Description", value: description, sum: 0, affectedOptions: [] },
  ];
}

export function bedroomLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Bedrooms", field: "bedrooms", unit: "bedroom" }),
    line({ programData, options, label: "Closets in Primary Suite", field: "closetsInPrimarySuite", unit: "bedroom" }),
    line({ programData, options, label: "First Floor Bedrooms", field: "firstFloorBedrooms", unit: "bedroom" }),
    line({ programData, options, label: "Primary Bedroom", field: "primaryBedroom", subField: "name" }),
  ];
}

export function bathroomLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Full Baths", field: "fullBaths", unit: "bathroom" }),
    line({ programData, options, label: "Half Baths", field: "halfBaths", unit: "bathroom" }),
  ];
}

function diningLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Formal", field: "diningRoom" }),
    line({ programData, options, label: "Casual", field: "casualDining" }),
  ];
}

function entertainingLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Media Rooms", field: "mediaRooms" }),
    line({ programData, options, label: "Lofts/Game Rooms/Flex Rooms", field: "loftGameFlexRooms" }),
  ];
}

function workFromHomeLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Offices", field: "offices" }),
    line({ programData, options, label: "Workspaces", field: "workspaces" }),
  ];
}

export function garageLines(
  programData: PlanDataOptionDetailsFragment | null | undefined,
  options: ReadyPlanOptionDetailFragment[] = [],
): CardLine[] {
  return [
    line({ programData, options, label: "Attached", field: "garageAttached", unit: "garage" }),
    line({ programData, options, label: "Detached", field: "garageDetached", unit: "garage" }),
    line({ programData, options, label: "Carport", field: "garagePort", unit: "garage" }),
    line({
      programData,
      options,
      label: "Garage Load",
      field: "garageConfiguration",
      subField: "name",
      unit: "garage",
    }),
  ];
}

export function basementLines(programData: PlanDataOptionDetailsFragment | null | undefined): CardLine[] {
  return [line({ programData, options: [], label: "Basement", field: "basementConfig", subField: "name" })];
}

function line({ programData, options, label, field, subField, unit, displayUnit, inchToFeet }: Line) {
  let fieldValue;
  let subFieldValue;

  if (programData) {
    const val = programData[field as keyof PlanDataOptionDetailsFragment] ?? null;
    subFieldValue =
      val && subField && !isNumber(val) && isObject(val)
        ? val[(subField as keyof GarageConfigDetailFragment) || (subField as keyof BasementConfigDetailFragment)]
        : null;
    fieldValue = isNumber(val) ? (inchToFeet ? inchesToFeet.toValue(val) : val) : null;
  }

  const affectedOptions = options
    .map((o) => {
      const value = o.programData ? o.programData[field as keyof PlanDataOptionDetailsFragment] : null;
      if (isNumber(value)) {
        return {
          code: o.globalOption.code,
          label: o.name,
          value: value,
        };
      } else {
        return undefined;
      }
    })
    .compact();

  const sum = affectedOptions.reduce((total, o) => total + (o?.value || 0), 0);

  return {
    label,
    value: fieldValue ?? subFieldValue,
    affectedOptions,
    sum,
    unit,
    displayUnit,
  };
}

type FilterOptionData = {
  id?: string;
  showOptionData?: boolean;
};

type Line = {
  programData: PlanDataOptionDetailsFragment | null | undefined;
  options: ReadyPlanOptionDetailFragment[];
  label: string;
  field: keyof PlanDataOptionDetailsFragment;
  subField?: SubField;
  unit?: string;
  displayUnit?: boolean;
  inchToFeet?: boolean;
};

type SubField = "name";

type AffectedOption = {
  code: string;
  label: string;
  value: number;
};

type CardLine = {
  label: string;
  value: number | string | null | undefined;
  affectedOptions: AffectedOption[];
  sum: number;
  unit?: string;
  displayUnit?: boolean;
};

function TableView({ metadata }: { metadata: ReadyPlanPageMetadataQuery }) {
  const { data: metadataProgramData } = useAddOptionsMetadataQuery();
  const readyPlan = metadata.readyPlans[0];
  const [saveRp] = useSaveReadyPlanMutation();

  const formState = useFormState({
    config: addRpoConfig,
    init: {
      input: readyPlan,
      map: (readyPlan) => mapReadyPlanToFormState(readyPlan),
    },
    autoSave: async (formState) => {
      await saveReadyPlanOptionsProgramData(formState, readyPlan, saveRp);
    },
  });
  const optionsTypes: AddOptionsGlobalOptionTypeFragment[] = useMemo(() => {
    // NOTE: We are relying on the fact that the server will sort the GlobalOption types by order
    return metadata.globalOptionTypes;
  }, [metadata.globalOptionTypes]);

  const { globalOptionTypes = [], locations = [] } = metadata;
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const filterDefs: FilterDefs<ReadyPlanOptionsFilter> = useMemo(() => {
    const type = multiFilter({
      label: "Option Type",
      options: globalOptionTypes.filter((got) => !got.isElevation && !got.isPlanPackage),
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const location = multiFilter({
      label: "Location",
      options: [{ id: null, name: "None" } as any, ...locations],
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
    });

    const active = toggleFilter({ label: "Show Archived" });

    return { type, location, active };
  }, [globalOptionTypes, locations]);

  const { filter, setFilter } = usePersistedFilter<ReadyPlanOptionsFilter>({
    storageKey: "tableFilter",
    filterDefs,
  });
  const [planOptionGroupBy, setPlanOptionGroupBy] = useSessionStorage("planOptionGroupBy", "optionType");
  return (
    <>
      <div css={Css.df.aic.jcfe.gap1.$}>
        <div css={Css.h100.$}>
          <SelectField
            compact
            sizeToContent
            label="Group by"
            labelStyle={"inline"}
            options={[
              { id: "optionType", name: "Option Type" },
              { id: "location", name: "Location" },
              { id: "none", name: "None" },
            ]}
            value={planOptionGroupBy}
            onSelect={(gb) => gb && setPlanOptionGroupBy(gb)}
          />
        </div>
        <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        <SearchBox onSearch={setSearchFilter} />
      </div>
      <ScrollableContent virtualized bgColor={Palette.Gray100}>
        <div css={Css.h100.w("calc(100% - 24px)").$}>
          <OptionDetailsTable
            formState={formState}
            searchFilter={searchFilter}
            filter={filter}
            optionsTypes={optionsTypes}
            metadata={metadataProgramData}
            groupBy={planOptionGroupBy as PlanOptionGroupBy}
            showActions={true}
          />
        </div>
      </ScrollableContent>
    </>
  );
}

function Activity({ metadata }: { metadata: ReadyPlanPageMetadataQuery }) {
  const tid = useTestIds({}, "activity");
  const readyPlan = metadata.readyPlans[0];
  const optionIds = readyPlan.options.map((rpo) => rpo.id);

  const filterDefs: FilterDefs<UserEventFilterWithDateFilterValue> = useMemo(() => {
    return {
      createdAt: dateFilter({
        label: "Created at",
        operations: dateFilterOperations,
        getOperationLabel: ({ label }) => label,
        getOperationValue: ({ value }) => value,
        defaultValue: { op: DateFilterOperation.After, value: new DateOnly(subWeeks(new Date(), 1)) },
      }),
    };
  }, []);

  const { setFilter, filter } = useStorageFilter<UserEventFilterWithDateFilterValue>({
    storageKey: "planActivityTabFilter",
    filterDefs,
  });

  return (
    <>
      <ScrollableContent>
        <FullBleed>
          <div css={Css.bgGray100.$}>
            <div css={Css.p3.bgGray100.mh100.pxPx(60).$}>
              <div css={Css.pb3.$} {...tid.elevation}>
                <h1 css={Css.xlBd.gray900.pb3.$} {...tid.title}>
                  Activity
                </h1>
                <div css={Css.p3.tac.bgWhite.$}>
                  <div css={Css.baseSb.gray900.pb3.bb.bcGray200.mb1.tar.df.jcfe.$} {...tid.filter}>
                    <Filters<UserEventFilterWithDateFilterValue>
                      filter={filter}
                      onChange={setFilter}
                      filterDefs={filterDefs}
                    />
                  </div>
                  {filter.createdAt && (
                    <UserEventsTable
                      currentUser={{ id: metadata.currentUser?.id }}
                      filter={{
                        createdAt: { op: filter.createdAt.op, value: filter.createdAt.value },
                        parent: [readyPlan.id, ...optionIds],
                      }}
                      queryFilter={{ parentType: ["rp", "rpo", "rpopdm"] }}
                      embedded
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </FullBleed>
      </ScrollableContent>
    </>
  );
}
