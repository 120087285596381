import {
  Button,
  ButtonMenu,
  column,
  emptyCell,
  GridColumn,
  GridDataRow,
  ScrollableContent,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { Link, useParams } from "react-router-dom";
import { dateCell, priceCell, QueryTable } from "src/components";
import {
  Estimate_MaterialCostPageFragment,
  LineItem_MaterialCostPageFragment,
  MaterialCostPageQuery,
  useMaterialCostPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { MaterialParams } from "src/routes/routesDef";
import { createBidPackagesDetailUrl, createMaterialDetailsUrl } from "src/RouteUrls";
import { BidEstimateModal } from "./BidEstimateModal";

export function MaterialCostPage() {
  const { variantId } = useParams<MaterialParams>();
  const { openModal } = useModal();
  const query = useMaterialCostPageQuery({ variables: { variantId } });

  function onEdit(bidEstimate: Estimate_MaterialCostPageFragment) {
    openModal({
      content: <BidEstimateModal materialVariantId={variantId} bidEstimate={bidEstimate} />,
    });
  }

  return (
    <>
      <PageHeader
        title="Cost Tracking"
        breadcrumb={[
          { label: "Materials Catalog", href: createMaterialDetailsUrl(variantId) },
          { label: "Cost Tracking", href: "#" },
        ]}
        right={
          <Button
            label="Add Cost"
            onClick={() =>
              openModal({
                content: <BidEstimateModal materialVariantId={variantId} />,
              })
            }
          />
        }
      />
      <ScrollableContent>
        <div>
          <QueryTable columns={createColumns(onEdit)} query={query} createRows={createRows} />
        </div>
      </ScrollableContent>
    </>
  );
}

function createColumns(onEdit: (bidEstimate: Estimate_MaterialCostPageFragment) => void): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Date added",
      estimate: ({ createdAt }) => dateCell(createdAt),
      lineItem: ({ createdAt }) => dateCell(createdAt),
    }),
    column<Row>({
      header: "Contributor",
      estimate: ({ createdBy }) => createdBy.name,
      lineItem: ({ revision }) => revision.bidContract.tradePartner?.name,
    }),
    column<Row>({
      header: "Market",
      estimate: ({ markets }) => markets.map((m) => m.name).join(", "),
      lineItem: ({ revision }) =>
        revision.bidContract.developments
          .flatMap((d) => d.market)
          .map((m) => m.name)
          .unique()
          .join(", "),
    }),
    column<Row>({
      header: "Type",
      estimate: () => "Manual",
      lineItem: () => "Bid",
    }),
    column<Row>({
      header: "Source",
      estimate: ({ sourceUrl }) =>
        sourceUrl && (
          <Link to={{ pathname: sourceUrl }} target="_blank">
            {sourceUrl}
          </Link>
        ),
      lineItem: ({ revision }) =>
        revision.bidContract.bidRequest?.bidPackage.bidPackageGroup?.id && (
          <Link
            to={createBidPackagesDetailUrl(
              revision.bidContract.bidRequest?.bidPackage.bidPackageGroup?.id,
              revision.bidContract.bidRequest?.bidPackage.id,
            )}
          >
            {revision.bidContract.bidRequest?.bidPackage.name}
          </Link>
        ),
    }),
    column<Row>({
      header: "UOM",
      estimate: ({ bidItemEstimate }) => bidItemEstimate?.unitOfMeasure.abbreviation,
      lineItem: ({ bidItem }) => bidItem?.unitOfMeasure.abbreviation,
    }),
    column<Row>({
      header: "Cost",
      estimate: ({ costInMills }) =>
        priceCell({ valueInCents: costInMills / 10, minDecimalPlaces: 3, maxDecimalPlaces: 3, trim: true }),
      lineItem: (row) =>
        row.unitCostInMills &&
        priceCell({ valueInCents: row.unitCostInMills / 10, minDecimalPlaces: 3, maxDecimalPlaces: 3, trim: true }),
    }),
    column<Row>({
      align: "right",
      header: emptyCell,
      estimate: (e) => (
        <ButtonMenu
          trigger={{ icon: "verticalDots" }}
          items={[
            {
              label: "Edit",
              onClick: () => onEdit(e),
            },
          ]}
        />
      ),
      lineItem: emptyCell,
    }),
  ];
}

type HeaderRow = { kind: "header"; data: undefined };
type EstimateRow = { kind: "estimate"; data: Estimate_MaterialCostPageFragment };
type LineItemRow = { kind: "lineItem"; data: LineItem_MaterialCostPageFragment };
type Row = HeaderRow | EstimateRow | LineItemRow;

function createRows(data: MaterialCostPageQuery | undefined): GridDataRow<Row>[] {
  const entities = data?.materialVariantCostSources.entities;
  const rows = entities?.map((e) =>
    e.__typename === "BidItemEstimate"
      ? { kind: "estimate" as const, id: e.id, data: e as Estimate_MaterialCostPageFragment }
      : { kind: "lineItem" as const, id: e.id, data: e as LineItem_MaterialCostPageFragment },
  );
  return [simpleHeader, ...(rows ?? [])];
}
