import { Css, useComputed } from "@homebound/beam";
import { ListFieldState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { TreeData, TreeNode, TreeNodeProps } from "react-dropdown-tree-select";
import { DropdownTreeSelect } from "src/components/DropdownTreeSelect";
import {
  NamedDevelopmentCohortsProjectsFragment,
  NamedFragment,
  ProjectDocumentAssociationInput,
  SaveDocumentInput,
} from "src/generated/graphql-types";

export type BoundAssociatedProjectsTreeSelectProps<T> = {
  development: NamedDevelopmentCohortsProjectsFragment;
  field: ListFieldState<ProjectDocumentAssociationInput>;
  readOnly?: boolean;
  expandAll?: boolean;
};

export function BoundAssociatedProjectsTreeSelect<I extends Pick<SaveDocumentInput, "projectDocumentAssociations">>({
  development,
  field,
  readOnly = false,
  expandAll = false,
}: BoundAssociatedProjectsTreeSelectProps<I>) {
  const stateByTasks = useComputed(() => (field.value ?? []).keyBy(({ projectId }) => projectId), [field.value]);
  const { data, projectsByCohort = {} } = useMemo(() => {
    return shapeDevelopment(development, stateByTasks, expandAll);
  }, [development, expandAll, stateByTasks]);

  const onSelect = (changed: TreeNode, nodes: TreeNode[]) => {
    const { nodeType, checked } = changed;
    switch (nodeType) {
      case "development":
        // process inputs for all projects
        Object.values(projectsByCohort)
          .flat()
          .forEach((project) => {
            projectNodeToInput({ checked, value: project.id }, field);
          });
        break;
      case "cohort":
        // process inputs for all projects in cohort
        projectsByCohort[changed.value].forEach((project) => {
          projectNodeToInput({ checked, value: project.id }, field);
        });
        break;
      case "project":
      default:
        // Process project node into an input
        projectNodeToInput(changed, field);
        break;
    }
    field.maybeAutoSave();
  };

  return (
    <Observer>
      {() => (
        <div>
          <label css={Css.gray700.$}>Projects</label>
          <div css={Css.mtPx(4).addIn(".dropdown-content", Css.w100.$).$}>
            <DropdownTreeSelect
              data={data ?? {}}
              readOnly={readOnly}
              onChange={onSelect}
              showPartiallySelected={true}
              texts={{ placeholder: "Select Projects" }}
            />
          </div>
        </div>
      )}
    </Observer>
  );
}

function shapeDevelopment(
  development: NamedDevelopmentCohortsProjectsFragment,
  stateByTasks: Record<string, ProjectDocumentAssociationInput>,
  expandAll: boolean,
): { data: TreeData; projectsByCohort?: Record<string, NamedFragment[]> } {
  const projectsByCohort = development.cohorts?.keyBy(
    (cohort) => cohort.id,
    (cohort) => cohort.projects,
  );
  const data = {
    nodeType: "development",
    label: development.name,
    value: development.id,
    expanded: expandAll,
    children: development.cohorts
      ?.map((cohort) => {
        return cohort.projects.isEmpty
          ? undefined
          : {
              nodeType: "cohort",
              label: cohort.name,
              value: cohort.id,
              expanded: expandAll,
              children: cohort.projects.map((project) => ({
                nodeType: "project",
                label: project.name,
                value: project.id,
                checked: stateByTasks[project.id] !== undefined,
                expanded: expandAll,
              })),
            };
      })
      .filter((child) => child !== undefined),
  };
  return { data, projectsByCohort };
}

function projectNodeToInput<T>(
  node: Pick<TreeNodeProps, "value" | "checked">,
  field: ListFieldState<ProjectDocumentAssociationInput>,
) {
  // Look for an existing value in the field for this node
  const indexOfNode = (field.value as ProjectDocumentAssociationInput[]).findIndex(
    ({ projectId }) => projectId === node.value,
  );

  // If the node has an entry in the field, remove it; it will be re-added below
  if (indexOfNode >= 0) {
    field.remove(indexOfNode);
  }
  const newValue: ProjectDocumentAssociationInput = { projectId: node.value };

  // If the node is being unchecked, add a delete flag to the input
  if (!node.checked) {
    newValue.delete = true;
  }
  // If the node is being checked or was already checked (in the case of going from a sinlge project to a full cohort/development being selected), add it to the field
  field.add(newValue);
}
