import { Font, Only, Properties, useTestIds } from "@homebound/beam";
import { format, parseISO } from "date-fns";
import { emptyCellDash as mdashEl } from "src/components";

/**
 * Date format styles.
 *
 * none: Specifies no style, i.e. do not display.
 * short: Specifies a short style, typically numeric only, such as “11/23/37” or “3:30 PM”.
 * medium: Specifies a medium style, typically with abbreviated text, such as “Nov. 23, 1937” or “3:30:32 PM”.
 * long: Specifies a long style, such as “November 3, 1937” or “3:30:32 PM”.
 * monthShort: Specifies a short style with month and day, such as “Nov 23”.
 * weekDayMonthShort: Specifies a short style with weekday, month, and day, such as “Tue, Nov 23”.
 */
export const formattedDateStyles = ["none", "short", "medium", "long", "monthShort", "weekDayMonthShort"] as const;
export type FormattedDateStyle = (typeof formattedDateStyles)[number];

export type FormattedDateProps<X> = {
  id?: string;
  xss?: X;
  date: Date | string | undefined;
  dateFormatStyle?: FormattedDateStyle;
  timeFormatStyle?: FormattedDateStyle;
  mdash?: boolean;
};

type FormattedDateXss = Pick<Properties, Font | "color">;

const dateStyleFormats: Record<FormattedDateStyle, string> = {
  none: "",
  short: "MM/dd/yy",
  medium: "MMM. dd, yyyy",
  long: "MMMM d, yyyy",
  monthShort: "MMM do",
  weekDayMonthShort: "EEE, MMM do",
};

const timeStyleFormats: Record<FormattedDateStyle, string> = {
  none: "",
  short: "hh:mm aaa",
  medium: "hh:mm:ss aaa",
  long: "hh:mm:ss aaa",
  monthShort: "hh:mm aaa",
  weekDayMonthShort: "hh:mm aaa",
};

/**
 * Formats a date with standard date/time styles and return a component.
 *
 * examples:
 *
 *  dateFormatStyle=short, timeFormatStyle=none (default) -> 11/01/20
 *
 *  dateFormatStyle=short, timeFormatStyle=short -> 11/01/20 03:30 pm
 *
 *  dateFormatStyle=none,  timeFormatStyle=short -> 03:30 pm
 *
 *  dateFormatStyle=monthShort, timeFormatStyle=none -> Nov 1st
 *
 *  dateFormatStyle=monthShort, timeFormatStyle=short -> Nov 1st 03:30 pm
 */
export function FormattedDate<X extends Only<FormattedDateXss, X>>(props: FormattedDateProps<X>) {
  const { xss, date, dateFormatStyle = "short", timeFormatStyle = "none", id = "FormattedDate", mdash } = props;

  const testIds = useTestIds({}, id);
  const formattedDate = formatDate(date, dateFormatStyle, timeFormatStyle);

  // TODO: figure out what to do here w/ date/time format. It seems like the intention
  // was to provide more detail in the hover? so instead of a 2 digit year a 4 digit year
  // maybe we should just bump up to next level of detail for date/time if specified?...
  const formattedHoverDate = date ? formatDate(date, "medium") : "";

  const emptyContent = mdash ? mdashEl : "";
  const content = date === undefined ? emptyContent : formattedDate;

  return (
    <div css={xss} {...testIds} title={date ? formattedHoverDate : undefined}>
      {content}
    </div>
  );
}

/** Formats a date with standard date/time styles and return a string */
export function formatDate(
  rawDate: Date | string | undefined,
  dateFormatStyle: FormattedDateStyle = "short",
  timeFormatStyle: FormattedDateStyle = "none",
) {
  if (rawDate) {
    const formatStr =
      [dateStyleFormats[dateFormatStyle], timeStyleFormats[timeFormatStyle]].filter((f) => f.length).join(" ") ||
      dateStyleFormats["short"]; // if dateFormatStyle=none & timeFormatStyle=none, use default date format
    const date = typeof rawDate === "string" ? parseISO(rawDate) : rawDate;

    return format(date, formatStr);
  }
}
