import { useTestIds } from "@homebound/beam";
import { Fragment } from "react";
import { Icon } from "src/components";
import { Stage } from "src/generated/graphql-types";

type NoSignedContractScreenProps = {
  projectId: string;
  stage?: Stage;
};

export function NoSignedContractScreen(props: NoSignedContractScreenProps) {
  const { projectId, stage = Stage.PreConExecution } = props;
  const testIds = useTestIds({}, "pre-approval-notice");

  return (
    <Fragment>
      <Icon icon="file" inc={13} />
      <div {...testIds}>{renderStageMessage(projectId, stage)}</div>
    </Fragment>
  );
}

function renderStageMessage(projectId: string, stage: Stage) {
  switch (stage) {
    case Stage.PreConPlanning:
      return <p>There are no contracts associated with the Planning Stage. Please select a different stage.</p>;
    default:
      return <div>Unable to find a signed contract.</div>;
  }
}
