import { Css, FormHeading, px } from "@homebound/beam";
import { DocumentEditorDetailFragment, DocumentType } from "src/generated/graphql-types";
import { FieldState } from "src/utils/formState";
import { crudSectionSpacingTopCss } from "src/utils/styles/spacing";
import { BoundFileUploader } from "../formFields";

type FormFileUploaderSectionProps = {
  documentFormState: FieldState<DocumentEditorDetailFragment | null | undefined>;
  documentType: DocumentType;
  message: string;
  projectId: string;
};

export function FormFileUploaderSection({
  documentFormState,
  documentType,
  message,
  projectId,
}: FormFileUploaderSectionProps) {
  return (
    <div css={crudSectionSpacingTopCss}>
      <FormHeading title="Files" />
      <div css={Css.mt2.maxw(px(500)).$}>
        <BoundFileUploader field={documentFormState} {...{ documentType, message, projectId }} />
      </div>
    </div>
  );
}
