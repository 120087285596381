import {
  Css,
  dateFilter,
  FilterDefs,
  Filters,
  multiFilter,
  useResponsiveGrid,
  useResponsiveGridItem,
} from "@homebound/beam";
import { subWeeks } from "date-fns";
import { useMemo } from "react";
import { useParams } from "react-router";
import { ActivityTabDataQuery, useActivityTabDataQuery } from "src/generated/graphql-types";
import {
  otherJobLogParentTypes,
  UserEventFilterWithDateFilterValue,
  UserEventsTable,
} from "src/routes/my-blueprint/activity-feed/UserEventsTable";
import { ProjectParams } from "src/routes/routesDef";
import { DateFilterOperation, dateFilterOperations, queryResult } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { useStorageFilter } from "../../budget/components/useStorageFilter";
import { RecentActivityRail } from "./RecentActivityRail/RecentActivityRail";

export function ActivityTab() {
  const { projectId } = useParams<ProjectParams>();

  const query = useActivityTabDataQuery();

  return queryResult(query, (data) => <ActivityTabView projectId={projectId} data={data} />);
}

function ActivityTabView({
  projectId,
  data: { internalUsers, userEventParentTypes, currentUser },
}: {
  projectId?: string;
  data: ActivityTabDataQuery;
}) {
  const filteredTypes = useMemo(
    () => userEventParentTypes.filter((type) => !["comment", "t", ...otherJobLogParentTypes].includes(type.id)),
    [userEventParentTypes],
  );

  const filterDefs: FilterDefs<UserEventFilterWithDateFilterValue> = useMemo(() => {
    return {
      createdAt: dateFilter({
        label: "Created at",
        operations: dateFilterOperations,
        getOperationLabel: ({ label }) => label,
        getOperationValue: ({ value }) => value,
        defaultValue: { op: DateFilterOperation.After, value: new DateOnly(subWeeks(new Date(), 1)) },
      }),
      parentType: multiFilter({
        label: "Type",
        options: filteredTypes ?? [],
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
      createdBy: multiFilter({
        label: "Created by",
        options: internalUsers,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
      createdFor: multiFilter({
        label: "Created for",
        options: internalUsers,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
    };
  }, [filteredTypes, internalUsers]);

  const { setFilter, filter } = useStorageFilter<UserEventFilterWithDateFilterValue>({
    storageKey: "projectActivityTabFilter",
    filterDefs,
  });

  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 276, columns: 4, gap: 24 });
  const { gridItemProps } = useResponsiveGridItem({ colSpan: 3 });

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.mb1.tar.df.jcfe.$}>
        <Filters<UserEventFilterWithDateFilterValue> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
      </div>
      <div css={{ ...gridStyles, ...Css.aifs.$ }}>
        <div css={Css.bgWhite.p3.br8.bshBasic.$} {...gridItemProps}>
          <h2 css={Css.xlBd.$}>Activity Feed</h2>
          <UserEventsTable currentUser={{ id: currentUser?.id }} filter={filter} queryFilter={{ projectId }} />
        </div>
        <RecentActivityRail />
      </div>
    </div>
  );
}
