import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextAreaField,
  Button,
  Css,
  ScrollableContent,
  ScrollableParent,
  SuperDrawerWidth,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { createProjectUrl } from "src/RouteUrls";
import { Icon } from "src/components";
import { BoundAttachments, attachmentConfig } from "src/components/boundAttachments/BoundAttachments";
import {
  WarrantyTicketItemStatus,
  WarrantyTicketItemStatusesFragment,
  WarrantyTicketItemTypeDetail,
  WarrantyTicketPageDetailsFragment,
  useDeleteWarrantyTicketItemMutation,
} from "src/generated/graphql-types";
import { count, pluralize } from "src/utils";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { LotDetailDrawer } from "../developments/lot-summary/sequence-sheet/components/lot-detail/LotDetailDrawer";
import { getWarrantyTicketDurationChip } from "./WarrantyPage";
import { TicketFormInput, TicketItemFormInput } from "./WarrantyTicketPage";
import { WarrantyListCard } from "./components/WarrantyListCard";

export type WarrantyListViewProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  warrantyTicketItemStatuses: WarrantyTicketItemStatusesFragment[];
  warrantyTicketItemTypes: WarrantyTicketItemTypeDetail[];
  formState: ObjectState<Partial<TicketFormInput>>;
};

export function WarrantyListView(props: WarrantyListViewProps) {
  const { formState, warrantyTicket, warrantyTicketItemStatuses, warrantyTicketItemTypes } = props;
  const [showAddNew, setShowAddNew] = useState(false);
  const { openInDrawer } = useSuperDrawer();
  const [deleteWarrantyTicketItem] = useDeleteWarrantyTicketItemMutation();
  const { openModal } = useModal();

  const homeownerVisibleDisabled = formState.homeownerVisible.value
    ? undefined
    : "Homeowner visibility has been disabled at the ticket level";

  const durationChip = getWarrantyTicketDurationChip(warrantyTicket);

  return (
    <div css={Css.w100.bgWhite.py5.px6.df.fdc.fg1.br8.$}>
      <ScrollableParent>
        <div css={Css.df.jcsb.$} data-testid="warrantyListHeader">
          <div css={Css.df.gap2.aic.$}>
            <div css={Css.xl2Sb.$}>{warrantyTicket.title}</div>
            <div
              css={Css.sm.$}
            >{`${warrantyTicket.totalCompletedItems} of ${warrantyTicket.totalAvailableItems} Completed`}</div>
            {durationChip ? durationChip.content() : <></>}
          </div>
          <div>
            <BoundSelectField
              label="Urgent"
              labelStyle="hidden"
              options={[
                { label: "Not Urgent", value: false },
                { label: "Urgent", value: true },
              ]}
              getOptionLabel={(o) => o.label}
              getOptionValue={(o) => o.value}
              field={formState.urgent}
              onSelect={(_, opt) => formState.urgent.set(opt !== undefined ? opt.value : !formState.urgent.value)}
            />
          </div>
        </div>
        <div data-testid="WarrantyProjectDetails" css={Css.xsMd.df.gap1.fdc.pt2.$}>
          <div css={Css.smMd.df.gap1.aic.$}>
            <Icon inc={2} icon="house" />
            <Link to={createProjectUrl(warrantyTicket.project.id)} target="_blank">
              {warrantyTicket.project.buildAddress.street1}
            </Link>
            {warrantyTicket.project.lotType?.name}
          </div>
          <div css={Css.df.gap1.aic.$}>
            <Icon inc={2} icon="floorPlan" />
            {warrantyTicket.project.readyPlanConfig?.readyPlan?.displayName} -{" "}
            {warrantyTicket.project.readyPlanConfig?.elevationOption?.readyPlanOption.name}
            {warrantyTicket.project.cohort?.development?.id && (
              <Button
                variant="text"
                label="See Configuration"
                onClick={() =>
                  openInDrawer({
                    content: (
                      <LotDetailDrawer
                        configId={warrantyTicket.project.readyPlanConfig?.id}
                        developmentId={warrantyTicket.project.cohort!.development!.id}
                        disableEdit
                      />
                    ),
                    width: SuperDrawerWidth.Small,
                  })
                }
              />
            )}
          </div>
          <div css={Css.df.gap1.aic.$}>
            <Icon inc={2} icon="calendar" />
            Submitted {warrantyTicket.createdAt.toDateString()}
          </div>
          <div css={Css.df.gap1.aic.$}>
            <Icon inc={2} icon="link" />
            {count(warrantyTicket.items, "Request")} Attached
          </div>
        </div>
        <div css={Css.df.fdc.pt4.$} data-testid="WarrantyRequest">
          <div css={Css.df.gap1.$}>
            <div css={Css.lgSb.$}>{pluralize(warrantyTicket.items.length, "Request")}</div>
            <Button variant="tertiary" onClick={() => setShowAddNew(true)} label="+ Add New" />
          </div>
          <ScrollableContent>
            <Observer>
              {() =>
                formState.items.rows.length > 0 ? (
                  <div css={Css.df.fdc.gap1.pt2.$}>
                    {showAddNew && (
                      <AddNewRequest
                        warrantyTicketItemTypes={warrantyTicketItemTypes}
                        setShowAddNew={setShowAddNew}
                        formState={formState}
                        homeownerVisibleDisabled={homeownerVisibleDisabled}
                      />
                    )}
                    {formState.items.rows.map((item, i) => (
                      <WarrantyListCard
                        key={item.id.value!}
                        itemState={item}
                        warrantyTicketItemStatuses={warrantyTicketItemStatuses}
                        warrantyTicketItemTypes={warrantyTicketItemTypes}
                        warrantyTicketItem={warrantyTicket.items[i]}
                        onDelete={() => {
                          openModal({
                            content: (
                              <ConfirmationModal
                                danger
                                title="Confirm Delete"
                                label="Delete item"
                                confirmationMessage="Are you sure you want to delete this item?"
                                onConfirmAction={async () => {
                                  if (item.id.value) {
                                    await deleteWarrantyTicketItem({ variables: { input: { id: item.id.value } } });
                                  }
                                  formState.items.remove(i);
                                }}
                              />
                            ),
                          });
                        }}
                        homeownerVisibleDisabled={homeownerVisibleDisabled}
                      />
                    ))}
                  </div>
                ) : showAddNew ? (
                  <AddNewRequest
                    warrantyTicketItemTypes={warrantyTicketItemTypes}
                    setShowAddNew={setShowAddNew}
                    formState={formState}
                    homeownerVisibleDisabled={homeownerVisibleDisabled}
                  />
                ) : (
                  emptyList(setShowAddNew)
                )
              }
            </Observer>
          </ScrollableContent>
          {/* </ScrollableParent> */}
        </div>
      </ScrollableParent>
    </div>
  );
}

function emptyList(setShowAddNew: (show: boolean) => void) {
  return (
    <div css={Css.w100.hPx(526).bgGray100.df.fdc.aic.jcc.gap2.mt2.$}>
      <img src="/images/add-new-icon.svg" alt="add new" />
      <div css={Css.base.gray900.tac.$}>
        There are no request items <br /> associated yet
      </div>
      <Button label="Add New" onClick={() => setShowAddNew(true)}></Button>
    </div>
  );
}

type AddNewRequestProps = {
  warrantyTicketItemTypes: WarrantyTicketItemTypeDetail[];
  setShowAddNew: (show: boolean) => void;
  formState: ObjectState<Partial<TicketFormInput>>;
  homeownerVisibleDisabled?: string;
};

function AddNewRequest({
  warrantyTicketItemTypes,
  setShowAddNew,
  formState,
  homeownerVisibleDisabled,
}: AddNewRequestProps) {
  const newState = useFormState({
    config: itemFormConfig,
    init: {
      onlyOnce: true,
      input: {
        status: WarrantyTicketItemStatus.Accepted,
        description: "",
        attachments: [],
        homeownerVisible: true,
      },
    },
  });

  const onSave = () => {
    const { attachments, description, type, homeownerVisible, status } = newState.value;
    formState.items.add({ type, description, status, attachments, homeownerVisible });
    setShowAddNew(false);
  };

  const onCancel = () => {
    setShowAddNew(false);
  };

  return (
    <div css={Css.w100.mh("516px").bgGray100.df.aic.jcc.mt2.py4.$}>
      <div css={Css.w75.df.fdc.jcc.gap2.$}>
        <div css={Css.w100.tal.baseSb.$}>Add New Request</div>
        <BoundTextAreaField field={newState.description} required fullWidth />
        <BoundSelectField
          options={warrantyTicketItemTypes}
          field={newState.type}
          getOptionValue={(ts) => ts.code}
          getOptionLabel={(ts) => ts.name}
          required
          fullWidth
        />
        <BoundAttachments field={newState.attachments} />
        <div css={Css.df.aic.w100.$}>
          <div>
            <Observer>{() => <Button label="Save" onClick={onSave} disabled={!newState.valid} />}</Observer>
            <Button label="Cancel" onClick={onCancel} variant="tertiary" />
          </div>

          <div css={Css.mla.$}>
            <BoundSwitchField
              field={newState.homeownerVisible}
              labelStyle="inline"
              disabled={homeownerVisibleDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const itemFormConfig: ObjectConfig<TicketItemFormInput> = {
  id: { type: "value" },
  status: { type: "value", rules: [required] },
  description: { type: "value", rules: [required] },
  type: { type: "value", rules: [required] },
  homeownerVisible: { type: "value" },
  attachments: {
    type: "list",
    config: attachmentConfig,
  },
};
