import { Css, IconKey, Palette } from "@homebound/beam";
import { Icon } from "src/components";
import { LotSummaryDetailTable } from "./LotSummaryDetailTable";
// Row is label / value pair
export type Row = { label: string; value: string | number | null | undefined };
// Rows is a collection of rows on a single line
export type Rows = Row[];

type LotSummaryDetailsSectionProps = {
  title: string;
  icon: IconKey;
  rows: Rows[];
  columns: number;
};

export function LotSummaryDetailsSection({ title, icon, rows, columns }: LotSummaryDetailsSectionProps) {
  const titleId = formatLabel(title);

  return (
    <div data-testid={titleId} css={Css.df.mt4.$}>
      <div data-testid={`${titleId}-icon`} css={Css.bgGray200.wPx(28).hPx(28).br100.df.aic.jcc.mr2.$}>
        <Icon icon={icon} pxSize={20} color={Palette.Gray900} />
      </div>
      <div data-testid={`${titleId}-content`} css={Css.w100.$}>
        <h2 css={Css.lgSb.mb1.$}>{title}</h2>
        <LotSummaryDetailTable columns={columns} rows={rows} />
      </div>
    </div>
  );
}

export function formatLabel(label: string) {
  return label.toLowerCase().replace(/\//g, "").replace(/\s+/g, "-");
}
