import { getStage } from "src/context";

/**
 * Gets the host for the Trade Portal application through the environment (stage).
 */
export function getTradePortalLink() {
  switch (getStage()) {
    case "local":
      return "http://localhost:3005";
    case "dev":
      return "https://trade.dev-homebound.com";
    case "qa":
      return "https://trade.qa-homebound.com";
    default:
      return "https://trade.homebound.com";
  }
}

export function createTradePortalPurchaseOrderUrl(projectId: string, commitmentLikeId: string) {
  return `${getTradePortalLink()}/projects/${projectId}/purchase-orders/${commitmentLikeId}`;
}
