import { Css } from "@homebound/beam";
import random from "lodash/random";
import { useMemo } from "react";

type HbLoadingSpinnerProps = {
  noQuips?: boolean;
  /** Contextually relevant quips, like "Executing Relentlessly". */
  extraQuips?: ReadonlyArray<string>;
  extraQuipsOnly?: boolean;
};

export function HbLoadingSpinner({ noQuips, extraQuips = [], extraQuipsOnly }: HbLoadingSpinnerProps) {
  const quip = useMemo(() => {
    const allQuips = extraQuips.nonEmpty && extraQuipsOnly ? extraQuips : [...LOADING_QUIPS, ...extraQuips];
    return allQuips[random(allQuips.length - 1)];
  }, [extraQuips, extraQuipsOnly]);

  return (
    <div css={Css.df.fdc.jcc.aic.$}>
      <img src="/images/hb_loading.gif" css={Css.add({ filter: "invert(1)" }).$} alt="loading" />
      <div>{noQuips ? "Loading..." : quip}</div>
    </div>
  );
}

const LOADING_QUIPS: ReadonlyArray<string> = [
  "Loading",
  "HOM is HOW",
  "One Team",
  "On a Mission",
  "Executing Relentlessly",
  "Building Better",
  "In Service of Our Customers",
  "Scaling Massively",
  "#LoveIt",
  "Join #product-feedback to help us build BluePrint for YOU",
  "Loading the blue into BluePrint",
].map((quip) => quip.concat("..."));
