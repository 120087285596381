import { ReactElement, ReactNode } from "react";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";

export type SingleChangeTextProps = {
  attribute?: { name: string; oldValue?: string; newValue: string };
  optionAdded?: { name: string; type: string };
  optionDeleted?: { name: string; type: string };
  createdBy: string;
  dateTag: ReactNode;
  projectTag: ReactNode;
  name?: string;
  ConfigurationTag: ({ children }: { children: ReactNode }) => ReactElement;
};

export function SingleChangeText(props: SingleChangeTextProps) {
  const { attribute, optionAdded, optionDeleted, createdBy, dateTag, projectTag, ConfigurationTag, name } = props;

  const forName = name ? (
    <>
      {" "}
      for <span css={weightedStyle}>{name}</span>
    </>
  ) : null;

  if (attribute) {
    return (
      <>
        <ConfigurationTag>
          <span css={weightedStyle}>{attribute.name}</span>
        </ConfigurationTag>{" "}
        was updated {projectTag}
        {attribute.oldValue ? (
          <>
            from <span css={weightedStyle}>{attribute.oldValue}</span>
          </>
        ) : (
          ""
        )}{" "}
        to <span css={weightedStyle}>{attribute.newValue}</span>
        {forName} by <span css={weightedStyle}>{createdBy}</span>
        {dateTag}
      </>
    );
  } else if (optionAdded) {
    return (
      <>
        <ConfigurationTag>
          <span css={weightedStyle}>{optionAdded.name}</span>
        </ConfigurationTag>{" "}
        was added to <span css={weightedStyle}>{optionAdded.type}</span> {projectTag} by{" "}
        <span css={weightedStyle}>{createdBy}</span>
        {dateTag}
      </>
    );
  } else if (optionDeleted) {
    return (
      <>
        <ConfigurationTag>
          <span css={weightedStyle}>{optionDeleted.name}</span>
        </ConfigurationTag>{" "}
        was removed from <span css={weightedStyle}>{optionDeleted.type}</span> {projectTag} by{" "}
        <span css={weightedStyle}>{createdBy}</span>
        {dateTag}
      </>
    );
  }

  return (
    <>
      <ConfigurationTag>
        <span css={weightedStyle}>1 lot configuration update</span>
      </ConfigurationTag>{" "}
      was made {projectTag} by <span css={weightedStyle}>{createdBy}</span>
      {dateTag}
    </>
  );
}
