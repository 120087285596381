import {
  Button,
  column,
  dateColumn,
  FilterDefs,
  Filters,
  GridColumn,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  singleFilter,
  usePersistedFilter,
} from "@homebound/beam";
import { useState } from "react";
import { dateCell, QueryTable } from "src/components";
import { SearchBox } from "src/components/SearchBox";
import {
  ItemTemplateFilter,
  ItemTemplatesQuery,
  SettingsItemTemplateFragment,
  Stage,
  useItemTemplatesQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { createItemTemplateUrl } from "src/RouteUrls";
import { itemTemplateStatusToNameMapper, stageCodeToNameMapper } from "src/utils/mappers";

export function ItemTemplatesPage() {
  const [search, setSearch] = useState<string | undefined>();
  const { filter, setFilter } = usePersistedFilter({
    filterDefs,
    storageKey: "ItemTemplatesPage.filters",
  });
  const query = useItemTemplatesQuery({
    variables: { filter: { isReadyHomeTemplate: false, ...filter } },
  });

  const rowStyles: RowStyles<Row> = {
    data: { rowLink: (row) => createItemTemplateUrl(row.id) },
  };

  return (
    <>
      <div data-testid="itemTemplatesPage">
        <PageHeader
          title="Spec &amp; Selection Templates"
          right={<Button variant="secondary" label="Create" onClick={createItemTemplateUrl()} />}
        />
        <TableActions>
          <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
          <SearchBox onSearch={setSearch} />
        </TableActions>
      </div>
      <ScrollableContent virtualized>
        <QueryTable<Row, ItemTemplatesQuery>
          stickyHeader
          sorting={{ on: "client" }}
          as="virtual"
          style={{ rowHeight: "fixed", allWhite: true, bordered: true }}
          {...{
            query,
            createRows: (data) => simpleDataRows(data?.itemTemplates),
            columns,
            rowStyles,
            filter: search,
          }}
        />
      </ScrollableContent>
    </>
  );
}

const filterDefs: FilterDefs<ItemTemplateFilter> = {
  // @ts-ignore - This actually works correctly, since the `stage` value will be converted to a `[stage]`
  stage: singleFilter({
    label: "Stage",
    options: [{ code: Stage.PreConExecution }, { code: Stage.Construction }],
    getOptionValue: (s) => s.code,
    getOptionLabel: (s) => stageCodeToNameMapper[s.code],
  }),
};

type Row = SimpleHeaderAndData<SettingsItemTemplateFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({ header: "Template", data: (data) => data.name, w: 4 }),
  column<Row>({ header: "Status", data: (data) => itemTemplateStatusToNameMapper[data.status] }),
  // column<Row>({ header: "Projects Using", data: (data) => 0 }),
  dateColumn<Row>({ header: "Created", data: (data) => dateCell(data.createdAt), w: 2 }),
  dateColumn<Row>({ header: "Last Updated", data: (data) => dateCell(data.updatedAt), w: 2 }),
];
