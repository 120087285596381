import { pluralize } from "src/utils";

export function renderListItem(count: number, action: string, baseText: string) {
  return (
    count > 0 && (
      <li>
        {/* Removed 2 takeoff items */}
        {action} {count} {pluralize(count, baseText)}
      </li>
    )
  );
}

export function renderAffectedListItem(count: number, text: string) {
  return (
    <li>
      {/* 2 Product Offerings */}
      {count} {pluralize(count, text)}
    </li>
  );
}
