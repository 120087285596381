import { Css, Tag, useHover, useSuperDrawer, useTestIds } from "@homebound/beam";
import { ProductAttributeType, ProductCatalogDetailsFragment, ProductStatus } from "src/generated/graphql-types";
import { formatList, productStatusToTagTypeMapper } from "src/utils";
import { PRODUCT_FALLBACK_IMG_URL } from "./product-images-viewer/ProductImageViewer";
import { ProductSuperDrawer } from "./product-super-drawer/ProductSuperDrawer";
export type ProductCatalogListViewsProps = {
  filteredProducts: ProductCatalogDetailsFragment[];
};

export function ProductCatalogTileView({ filteredProducts }: ProductCatalogListViewsProps) {
  return (
    <div css={Css.df.add("flexWrap", "wrap").gap1.$}>
      {filteredProducts.map((product) => (
        <ProductDetails key={product.id} product={product} />
      ))}
    </div>
  );
}

type ProductDetailProps = {
  product: ProductCatalogDetailsFragment;
};

function ProductDetails({ product }: ProductDetailProps) {
  const { openInDrawer } = useSuperDrawer();
  const tid = useTestIds({}, "product");
  const { isHovered, hoverProps } = useHover({});

  const finishedAttributes = product.attributes
    .filter((atr) => atr.type === ProductAttributeType.Finish)
    .map(({ value }) => value);

  return (
    <button
      {...hoverProps}
      {...tid.details}
      css={{
        ...Css.wPx(208)
          .tal.hPx(300)
          .bshBasic.br8.bgWhite.relative.cursorPointer.m1.p2.if(product.status.code === ProductStatus.Archived)
          .bgGray400.$,
        ":hover": Css.bshHover.$,
      }}
      onClick={() => openInDrawer({ content: <ProductSuperDrawer productId={product.id} /> })}
    >
      {isHovered && (
        <div css={Css.absolute.z1.$}>
          <Tag {...tid.status} type={productStatusToTagTypeMapper[product.status.code]} text={product.status.code} />
        </div>
      )}
      <div css={Css.df.aic.mb1.wPx(176).hPx(134).if(!product.images.first).p1.$}>
        <img
          {...tid.image}
          css={
            Css.maxw100.maxh100.ma.p1
              .if(!product.images.first)
              .br8.p4.bgGray100.w100.h100.if(product.status.code === ProductStatus.Archived)
              // Matches the gray background color for archived products
              .add("filter", "brightness(0.79)").$
          }
          src={product.images.first?.downloadUrl || PRODUCT_FALLBACK_IMG_URL}
          alt={product.name}
        />
      </div>
      <div {...tid.description} css={Css.hPx(80).mb2.oa.$}>
        <label css={Css.smBd.$}>
          {product.attributes.find((attr) => attr.type === ProductAttributeType.Brand)?.value}
        </label>{" "}
        {product.name}
      </div>
      {/* reduces margin if the model text overflows or has more than one finishedAttribute */}
      <div css={Css.if(finishedAttributes.length > 1).mt(-1).$}>
        <div {...tid.model} css={Css.xs.if((product.sku?.length ?? 0) > 18).mt(-1).$}>
          Model: {product.sku ?? "Not available"}
        </div>
        <div {...tid.finish} css={Css.xs.$}>
          Finish: {finishedAttributes.nonEmpty ? formatList(finishedAttributes) : "Not available"}
        </div>
      </div>
    </button>
  );
}
