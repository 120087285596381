import {
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  ScrollableContent,
  ScrollableParent,
  column,
  selectColumn,
  simpleDataRows,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo } from "react";
import { Card } from "src/components/Card";
import {
  ModalBidPackageFragment,
  ModalPotentialBidPackageLineItemFragment,
  useBidPackageLineItemModalQuery,
  useSaveBidPackagesMutation,
} from "src/generated/graphql-types";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { queryResult } from "src/utils";

type BidPackageLineItemModalProps = {
  bidPackageId: string;
};

export function BidPackageLineItemModal({ bidPackageId }: BidPackageLineItemModalProps) {
  const query = useBidPackageLineItemModalQuery({
    variables: {
      bidPackageId,
    },
  });
  return queryResult(query, ({ bidPackage }) => <BidPackageLineItemView bidPackage={bidPackage} />);
}

type BidPackageLineItemViewProps = {
  bidPackage: ModalBidPackageFragment;
};

function BidPackageLineItemView({ bidPackage }: BidPackageLineItemViewProps) {
  const { closeModal } = useModal();
  const [saveBidPackages] = useSaveBidPackagesMutation({
    refetchQueries: ["ReviewScopeStep_BidPackageGroup"],
  });
  const tableApi = useGridTableApi<Row>();
  const rows = useMemo(() => createRows(bidPackage), [bidPackage]);

  const selectedBidItemIds = useComputed(() => tableApi.getSelectedRows("data").map((r) => r.data.id), [tableApi]);

  // Select all line items that are already in the bid package
  useEffect(() => {
    bidPackage.lineItems.forEach((li) => {
      tableApi.selectRow(li.bidItem.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveAndExit() {
    await saveBidPackages({
      variables: {
        input: [
          {
            id: bidPackage.id,
            lineItems: [
              // Persist line item ids that weren't deselected
              ...bidPackage.lineItems
                .filter((li) => selectedBidItemIds.includes(li.bidItem.id))
                .map((li) => ({
                  id: li.id,
                })),
              // Add line items that were newly selected
              ...selectedBidItemIds
                .filter((id) => !bidPackage.lineItems.some((li) => li.bidItem.id === id))
                .map((id) => ({
                  bidItemId: id,
                })),
            ],
          },
        ],
      },
    });
    closeModal();
  }

  return (
    // Don't overlap the save and return button
    <ScrollableParent xss={Css.mt6.h("calc(100% - 144px)").$}>
      <div css={Css.absolute.right4.top3.$}>
        <ExitIconButton
          showModal={
            !isEqual(
              selectedBidItemIds,
              bidPackage.lineItems.map((li) => li.bidItem.id),
            )
          }
          onCloseConfirmed={closeModal}
        />
      </div>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.xl3Sb.$}>{bidPackage.name} Line Item Detail</div>
        <div css={Css.base.pt2.$}>You can manually toggle certain line items on and off here if you need to.</div>
      </div>
      <ScrollableContent virtualized>
        <Card xss={Css.mt4.wfc.mxa.$}>
          <GridTable<Row>
            api={tableApi}
            stickyHeader
            rows={rows}
            columns={columns}
            fallbackMessage="There are no Line Items For this Bid Category"
            sorting={{ on: "client" }}
          />
        </Card>
      </ScrollableContent>
      <div css={Css.fixed.bottom0.w100.px6.py3.bshHover.df.$}>
        <div css={Css.mla.$}>
          <Button
            label="Save & Return to Packages"
            disabled={selectedBidItemIds.length === 0 && bidPackage.potentialBidPackageLineItems.length !== 0}
            onClick={saveAndExit}
            size="lg"
          />
        </div>
      </div>
    </ScrollableParent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ModalPotentialBidPackageLineItemFragment };
type Row = HeaderRow | DataRow;

const columns: GridColumn<Row>[] = [
  selectColumn<Row>(),
  column<Row>({ header: "Cost code", data: (bi) => bi.items[0]?.costCode.displayName, w: "180px" }),
  column<Row>({ header: "Name", data: (bi) => bi.name, w: "230px" }),
  column<Row>({ header: "Material code", data: (bi) => bi.parentMaterialVariant?.code, w: "200px" }),
];

function createRows(bidPackage: ModalBidPackageFragment): GridDataRow<Row>[] {
  return simpleDataRows(bidPackage.potentialBidPackageLineItems);
}
