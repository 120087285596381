import { Css } from "@homebound/beam";
import { HasChildren } from "src/utils";

/**
 * Renders children as normal unless we detect we're in a print environment
 * (Literally Cmd+P or Print Preview). If so, hides content.
 */
export function HideFromPrint({ children }: HasChildren) {
  return <div css={Css.ifPrint.dn.$}>{children}</div>;
}
