import { Css } from "@homebound/beam";
import { useParams } from "react-router";
import { createDevelopmentLotSummaryUrl } from "src/RouteUrls";
import { useDevelopmentDetailQuery } from "src/generated/graphql-types";
import { DevelopmentHolidayCard } from "src/routes/developments/settings/components/DevelopmentHolidayCard";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export function DevelopmentSettingsPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const developmentDetailQuery = useDevelopmentDetailQuery({ variables: { developmentId: developmentId } });
  return (
    <div>
      <PageHeader
        title="Development Settings"
        breadcrumb={{ href: createDevelopmentLotSummaryUrl(developmentId), label: "All Developments" }}
      />
      <div css={Css.df.w100.fdc.aic.$}>
        {queryResult(developmentDetailQuery, ({ development }) => (
          <DevelopmentHolidayCard
            developmentId={developmentId}
            schedulingExclusionDates={development.schedulingExclusionDates}
          />
        ))}
      </div>
    </div>
  );
}
