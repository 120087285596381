import {
  BoundTextField,
  Button,
  Css,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  SelectField,
  Tooltip,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, Rule, required, useFormState } from "@homebound/form-state";
import { Maybe } from "graphql/jsutils/Maybe";
import { Observer } from "mobx-react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { createPlanPackageEditUrl } from "src/RouteUrls";
import {
  PlanPackageDetailsFragment,
  PlanPackageFragment,
  ReadyPlanType,
  useCopyPlanPackageMutation,
} from "src/generated/graphql-types";

type CopyPlanPackageModalProps = {
  planPackage: PlanPackageFragment | PlanPackageDetailsFragment;
};

/** Copies a plan package and navigates to the edit page after successful creation */
export function CopyPlanPackageModal({ planPackage }: CopyPlanPackageModalProps) {
  const { id, name, type, displayCode } = planPackage;
  const { closeModal } = useModal();
  const history = useHistory();
  const [createCopyMutation] = useCopyPlanPackageMutation();
  const [copyMutationError, setCopyMutationError] = useState<string>();

  const copyPackageForm = useFormState({
    config: copyPlanConfig,
    init: {
      input: { id, name },
      map: ({ id, name }) => {
        return {
          sourcePackageId: id,
          name: "(Copy) " + name,
          code: "",
        };
      },
    },
  });

  async function handleCreateCopy() {
    setCopyMutationError(undefined);
    const { sourcePackageId, name, code } = copyPackageForm.value;
    const { data } = await createCopyMutation({
      variables: { input: { sourcePackageId, name: name!, code: code! } },
      onError: ({ message }) => {
        // Friendlier error message for unique name constraint violation
        if (message.includes("ready_plans_name_unique_where_development")) {
          setCopyMutationError("Name already exists. Please choose a different name.");
          copyPackageForm.name.focus();
        } else {
          message.includes("Code must be unique") && copyPackageForm.code.focus();
          setCopyMutationError(message);
        }
      },
    });
    const { planPackage } = data?.copyPlanPackage || {};

    if (planPackage) {
      history.push(createPlanPackageEditUrl(planPackage.id));
      closeModal();
    }
  }

  return (
    <>
      <ModalHeader>Copy Plan Package</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.w100.bb.my2.pb2.gap2.bcGray700.$}>
          <div css={Css.df.fdc.gap1.$}>
            <div css={Css.dg.gtc("1fr 2fr").aic.gap1.$}>
              <div css={Css.gray700.$}>Plan Package</div>
              <div css={Css.base.$}>{name}</div>
              <div css={Css.gray700.$}>Plan Package Code</div>
              <div css={Css.base.$}>{displayCode ?? "-"}</div>
            </div>
          </div>
        </div>

        <div css={Css.df.fdc.gap1.$}>
          <div css={Css.dg.gtc("1fr 2fr").aic.gap1.$}>
            <div css={Css.gray700.$}>New Plan Package Name</div>
            <BoundTextField hideErrorMessage labelStyle="hidden" fullWidth field={copyPackageForm.name} label="Name" />
            <div css={Css.df.aic.gap1.$}>
              <div css={Css.gray700.$}>New Plan Package Code</div>
              <Tooltip placement="right" title="Code used to reference the plan throughout Blueprint">
                <Icon icon="infoCircle" color={Palette.Gray900} inc={2} />
              </Tooltip>
            </div>
            <div css={Css.df.gapPx(4).aic.$}>
              <SelectField
                label="Type"
                onSelect={() => undefined}
                value={type.code}
                labelStyle="hidden"
                options={Object.values(ReadyPlanType).map((rptype) => ({ value: rptype, label: rptype }))}
                getOptionLabel={(val) => val.label}
                getOptionValue={(val) => val.value}
                disabled={"Type may be changed after copy completion"}
              />
              {" - "}
              <BoundTextField
                hideErrorMessage
                labelStyle={"hidden"}
                fullWidth
                field={copyPackageForm.code}
                label="Code"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {copyMutationError && (
          <div data-testid="copyMutationError" css={Css.red600.df.aic.$}>
            <Icon icon="error" inc={3} color={Palette.Red600} />
            <span css={Css.ml1.$}>{copyMutationError}</span>
          </div>
        )}
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label="Create Copy"
              disabled={!copyPackageForm.valid && copyPackageForm.errors.join(", ")}
              onClick={handleCreateCopy}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const threeDigitEnforcement: Rule<Maybe<string>> = ({ value }) => {
  if (value === undefined || value === null) {
    return undefined;
  } else if (!/^\d{3}$/.test(value)) {
    return "Code must be 3 digits.";
  }
};

type CreateCopyFormValue = {
  sourcePackageId: string;
  name: Maybe<string>;
  code: Maybe<string>;
};

const copyPlanConfig: ObjectConfig<CreateCopyFormValue> = {
  sourcePackageId: { type: "value", readOnly: true },
  name: { type: "value", rules: [required] },
  code: { type: "value", rules: [required, threeDigitEnforcement] },
};
