import { HistoryFeed } from "src/components/HistoryFeed";
import { useChangeRequestHistoryQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type HistoryTabProps = {
  changeRequestId: string;
};

export function HistoryTab({ changeRequestId }: HistoryTabProps) {
  const query = useChangeRequestHistoryQuery({ variables: { id: changeRequestId } });
  return queryResult(query, (result) => {
    const { changeRequest } = result;
    return <HistoryFeed historyItems={changeRequest.history} renderAsCard={false} />;
  });
}
