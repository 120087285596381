import { Palette, Tooltip } from "@homebound/beam";
import { Icon } from "src/components";

type HeaderTooltipProps = {
  title: string;
};

export function PlanDetailsTooltip({ title }: HeaderTooltipProps) {
  return (
    <Tooltip placement="top" title={title}>
      <Icon icon="infoCircle" color={Palette.Gray600} pxSize={18} />
    </Tooltip>
  );
}
