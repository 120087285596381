/*
  Copied and adjusted from gql-service.  Should only be changed functionally in tandem with backend version.
  TODO: move this into a separate repo/library
 */
import { fail } from "src/utils";

type Lex = LexoRank | string;
export class LexoRank {
  static STEP_AMOUNT = BigInt(8);
  private readonly value: string;
  private readonly bucket: string;

  constructor(value: string, bucket = "0") {
    if (!LexoRank.isValidLexValue(value)) {
      fail(`Invalid lex value "${value}"`);
    }
    if (!LexoRank.isValidLexBucket(bucket)) {
      fail(`Invalid lex bucket "${bucket}"`);
    }

    this.value = value;
    this.bucket = bucket;
  }

  static from(lex: Lex) {
    const { value, bucket } = lex instanceof LexoRank ? (lex as any) : LexoRank.parse(lex);
    return new LexoRank(value, bucket);
  }

  // Generates a new LexoRank that should sort after every other existing LexoRank.  We use the current time in
  // milliseconds as our seed.  This provides an ever escalating value that should provide enough precision to avoid
  // collisions. It should also provide enough of a gap between values that it would take thousands of moves between any
  // two specific values before they would become longer than our column width (varchar(256)).  This timestamp is then
  // converted into a base-36 string (ie, [0-9a-z]+). Until May 25, 2059 this will provide an 8-9 character string
  // (depending on if the current timestamp mod 36 is 0 or not), after which the value will become a 9-10 digit string
  // and the order will need to be rebalanced. As an example, the current value, as of 2021-09-13T19:08:32.874Z, is
  // '0|ktj0rk6i`
  static max() {
    let value = Date.now();
    if (value % 36 === 0) {
      value = value * 36 + 1; // if our value would end in zero, shift left by 1 digit and add 1
    }
    return new LexoRank(value.toString(36));
  }

  private static parse(lex: string) {
    const regex = /^(?<bucket>[0-2])\|(?<value>[0-9a-z]*[1-9a-z])$/;
    const { groups } = regex.exec(lex) ?? fail(`Invalid lex string: ${lex}`);
    return { value: groups!.value, bucket: groups!.bucket };
  }

  private static isValidLexValue(value: string) {
    const regex = /^[0-9a-z]*[1-9a-z]$/;
    return regex.test(value);
  }

  private static isValidLexBucket(bucket: string) {
    const regex = /^[0-2]$/;
    return regex.test(bucket);
  }

  private get bucketValue(): number {
    return parseInt(this.bucket);
  }

  toString() {
    return `${this.bucket}|${this.value}`;
  }

  lessThan(lex: Lex) {
    const other = LexoRank.from(lex);
    const len = Math.max(this.value.length, other.value.length);

    for (let idx = 0; idx < len; idx++) {
      const charA = this.value[idx];
      const charB = other.value[idx];

      if (charA === charB) {
        continue;
      }

      return !charB
        ? false // a is more specific
        : !charA
          ? true // b is more specific
          : charA < charB;
    }

    return false;
  }

  inNextBucket() {
    return new LexoRank(this.value, ((this.bucketValue + 1) % 3).toString());
  }

  increment() {
    // convert our value to a big int
    let value = [...this.value].reduce((total, char) => total * BigInt(36) + BigInt(parseInt(char, 36)), BigInt(0));
    // add an arbitrary value that allows ample space for `between` calls
    value += LexoRank.STEP_AMOUNT;

    if ((value as bigint).toString(36).length > this.value.length) {
      // if our new value is longer as a string than our previous value, we need to shift instead of add
      value = (value - LexoRank.STEP_AMOUNT) * BigInt(36) + BigInt(1); // ensure we don't end in a zero
    } else if (value % BigInt(36) === BigInt(0)) {
      // ensure our value doesn't end in a zero
      value += BigInt(1);
    }

    // ensure the new lexo rank is at least as long as the previous rank so that it will sort below it
    return new LexoRank((value as bigint).toString(36), this.bucket);
  }

  decrement() {
    // convert our value to a big int
    let value = [...this.value].reduce((total, char) => total * BigInt(36) + BigInt(parseInt(char, 36)), BigInt(0));
    // subtract an arbitrary value that allows ample space for `between` calls
    value -= LexoRank.STEP_AMOUNT;

    if (value <= BigInt(0)) {
      // we cannot decrement below or to a string of zeros, so find a value between this and the minimum valid value
      return this.between("0|01");
    } else if (value % BigInt(36) === BigInt(0)) {
      // ensure our value doesn't end in a zero
      value -= BigInt(1);
    }

    // ensure the new lexo rank is at least as long as the previous rank so that it will sort below it
    return new LexoRank((value as bigint).toString(36).padStart(this.value.length, "0"), this.bucket);
  }

  private valueAt(index: number) {
    return index < this.value.length ? parseInt(this.value.charAt(index), 36) : 0;
  }

  private charAt(index: number) {
    return this.value.charAt(index);
  }

  between(lex: Lex): LexoRank {
    const other = LexoRank.from(lex);
    if (this.bucket !== other.bucket) {
      fail("LexoRanks must be in the same bucket");
    }

    // figure out which value is our lowest
    const [lower, upper] = (this.lessThan(other) ? [this, other] : [other, this]) as LexoRank[];

    // find the index where the values diverge from each other
    let index = [...lower.value].findIndex((c, i) => c !== upper.charAt(i));
    index = index === -1 ? lower.value.length : index;
    // grab out our common prefix in both values
    let result = lower.value.slice(0, Math.max(index, 0));

    if (lower.value === result) {
      // if our lower value is a substring of our upper value
      while (upper.charAt(index) === "0") {
        result += "0"; // insert matching '0's from our upper value since nothing can sort before them
        index++;
      }
      if (upper.charAt(index) === "1") {
        // lex strings can't end in a 0 which is our only remaining value to sort above, so add a 0 to sort above then
        // add a halfway between value (i)
        result += "0i";
      } else {
        // otherwise add a value halfway between our upper's value and '0'
        result += Math.ceil(parseInt(upper.charAt(index), 36) / 2).toString(36);
      }
    } else if (upper.valueAt(index) - lower.valueAt(index) === 1) {
      // if our numbers are separated by one lexicographical value, then we need to take the lower value
      // and add characters
      result += lower.charAt(index); // add the different character from our lower value
      index++; // advance one character
      while (lower.charAt(index) === "z") {
        result += "z"; // insert matching 'z's from our lower value since nothing can sort after them
        index++;
      }
      // add a value halfway between our lower value and 'z'
      result += Math.floor((lower.valueAt(index) + 36) / 2).toString(36);
    } else {
      // otherwise just split the difference between the two non-matching characters
      result += Math.ceil((upper.valueAt(index) + lower.valueAt(index)) / 2).toString(36);
    }

    return new LexoRank(result, lower.bucket);
  }
}
