import {
  BoundSelectField,
  Button,
  Css,
  FormLines,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import {
  ApplyScheduleTemplateInput,
  ScheduleSettingDetailsFragment,
  ScheduleTemplateStatus,
  Stage,
  useApplyScheduleTemplatesMutation,
  useScheduleTemplatesQuery,
} from "src/generated/graphql-types";
import { Actions, setEmptyScheduleState } from "./contexts/scheduleStoreReducer";
import { EmptyScheduleStates } from "./scheduleUtils";
import { getDisabledDays } from "./table/DateCellField";

type ApplyScheduleTemplateModalFormProps = {
  stage: Stage;
  scheduleParentId: string;
  scheduleSetting: ScheduleSettingDetailsFragment | null | undefined;
  scheduleStoreDispatch: React.Dispatch<Actions>;
  marketId?: string;
};

export function ApplyScheduleTemplateModalForm({
  stage,
  scheduleParentId,
  marketId,
  scheduleSetting,
  scheduleStoreDispatch,
}: ApplyScheduleTemplateModalFormProps) {
  const { closeModal } = useModal();
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { data: scheduleTemplatesData, loading } = useScheduleTemplatesQuery({
    variables: {
      filter: {
        status: [ScheduleTemplateStatus.Published],
        stage: [stage],
        market: marketId ? [marketId] : [],
      },
    },
  });
  const [applyScheduleTemplates] = useApplyScheduleTemplatesMutation({
    onCompleted: () => {
      scheduleStoreDispatch(setEmptyScheduleState(null));
    },
  });
  const disabledDays = useMemo(() => getDisabledDays(scheduleSetting), [scheduleSetting]);

  useEffect(() => {
    if (scheduleTemplatesData && !scheduleTemplatesData?.scheduleTemplates.length) {
      setIsError(true);
      setErrorText("There are no active templates available for this Stage and/or Market.");
    }
  }, [scheduleTemplatesData]);

  const formState = useFormState({
    config: formConfig,
  });

  const onSubmit = async () => {
    // Close the modal and place the schedule into a loading state
    scheduleStoreDispatch(setEmptyScheduleState(EmptyScheduleStates.LoadingState));
    closeModal();

    await applyScheduleTemplates({
      refetchQueries: ["SchedulePhases"],
      variables: {
        scheduleTemplates: {
          scheduleTemplates: [
            {
              ...mapToInput(formState.value),
              scheduleParentId,
              stage,
              replaceExisting: false,
            },
          ],
        },
      },
    });
  };

  return (
    <>
      <ModalHeader>Add a schedule template</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundBeamDateField
            field={formState.startDate}
            placeholder="Date"
            label="Schedule start date"
            disabledDays={disabledDays}
            disabled={isError}
          />
          {isError ? (
            <div css={Css.red600.sm.df.mtPx(4).$}>
              <span css={Css.fs0.$}>
                <Icon icon="error" />
              </span>
              <span css={Css.ml1.mtPx(2).$} data-testid="errorMessage">
                {errorText}
              </span>
            </div>
          ) : (
            <BoundSelectField
              options={scheduleTemplatesData?.scheduleTemplates ?? []}
              field={formState.scheduleTemplateId}
              label="Select template"
              placeholder="Search templates"
              compact
            />
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => <Button label={"Add template"} onClick={onSubmit} disabled={!formState.valid || loading} />}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = Pick<ApplyScheduleTemplateInput, "startDate" | "scheduleParentId" | "stage"> & {
  scheduleTemplateId: string | null | undefined;
};

const formConfig: ObjectConfig<FormValue> = {
  startDate: { type: "value", rules: [required] },
  scheduleTemplateId: { type: "value", rules: [required] },
  scheduleParentId: { type: "value" },
  stage: { type: "value" },
};

function mapToInput(form: FormValue): ApplyScheduleTemplateInput {
  const { scheduleTemplateId, ...others } = form;
  return {
    ...others,
    // ensures that we are sending back a string
    scheduleTemplateId: String(form.scheduleTemplateId),
  };
}
