import { checkboxFilter, dateFilter, DateFilterValue, FilterDefs, multiFilter, singleFilter } from "@homebound/beam";
import { capitalCase } from "change-case";
import subWeeks from "date-fns/subWeeks";
import { useRouteMatch } from "react-router";
import {
  ApprovalStatus,
  ApprovalSubjectType,
  DateOperation,
  InputMaybe,
  PersonalDashboard_CohortFragment,
  PersonalDashboard_DevelopmentFragment,
  PersonalDashboard_InternalUserFragment,
  PersonalDashboard_MarketFragment,
  PersonalDashboard_ProjectFragment,
  PersonalDashboard_UserEventParentTypeFragment,
  Scalars,
  ToDoType,
  ToDoTypeDetail,
} from "src/generated/graphql-types";
import {
  ActionItemTypes,
  PersonalDashboardRoutes,
  UpcomingDueIn,
  UpcomingView,
} from "src/routes/personal-dashboard/enums";
import { useUpcomingViewParam } from "src/routes/personal-dashboard/pages/upcoming/UpcomingPage";
import { Section } from "src/routes/personal-dashboard/PersonalDashboard";
import { dateFilterOperations } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { personalDashboardPaths } from "../routesDef";
import { usePersonalDashboardActivityPageRouteMatch } from "./components/usePersonalDashboardActivityPageRouteMatch";

type FilterProps = {
  internalUsers: PersonalDashboard_InternalUserFragment[];
  projects: PersonalDashboard_ProjectFragment[];
  cohorts: PersonalDashboard_CohortFragment[];
  developments: PersonalDashboard_DevelopmentFragment[];
  markets: PersonalDashboard_MarketFragment[];
  userEventTypes: PersonalDashboard_UserEventParentTypeFragment[];
  currentUserId?: string | null;
  section: Section;
  toDoTypes: ToDoTypeDetail[];
};

export type PersonalDashboardFilter = {
  id?: InputMaybe<Array<Scalars["ID"]>>;
  project?: InputMaybe<Array<Scalars["ID"]>>;
  development?: InputMaybe<Array<Scalars["ID"]>>;
  cohort?: InputMaybe<Array<Scalars["ID"]>>;
  market?: InputMaybe<Array<Scalars["ID"]>>;
  // Action item filters
  actionItemTypes?: InputMaybe<Array<ActionItemTypes>>;
  // Upcoming filters
  dueIn?: InputMaybe<UpcomingDueIn>;
  // To do's filters
  type?: InputMaybe<Array<ToDoType>>;
  dueDate?: DateFilterValue<string>;
  createdBy?: InputMaybe<Array<Scalars["ID"]>>;
  watching?: InputMaybe<Scalars["Boolean"]>;
  urgent?: InputMaybe<Scalars["Boolean"]>;
  // Approval filters
  requested?: InputMaybe<Array<Scalars["ID"]>>;
  approvalStatus?: InputMaybe<ApprovalStatus | "overdue">;
  approvalSubjectType?: InputMaybe<Array<ApprovalSubjectType>>;
  // Activity filters
  createdAt?: DateFilterValue<string>;
  parentType?: InputMaybe<Array<Scalars["ID"]>>;
  createdFor?: InputMaybe<Array<Scalars["ID"]>>;
  internalUser?: InputMaybe<Array<Scalars["ID"]>>;
};

export function useHeaderFilterDefs(props: FilterProps): FilterDefs<PersonalDashboardFilter> {
  const { internalUsers, projects, cohorts, developments, markets, userEventTypes, currentUserId, section, toDoTypes } =
    props;
  const currentTeamMember = internalUsers.find(({ id }) => id === currentUserId);
  const otherTeamMembers = internalUsers.filter(({ id }) => id !== currentUserId);
  // Put (Me) option first
  const teamMemberOptions = currentTeamMember ? [currentTeamMember, ...otherTeamMembers] : internalUsers;
  const defaultUserValue = currentTeamMember ? [currentTeamMember.id] : [];

  const { upcomingView } = useUpcomingViewParam();
  const { isDashboardActivityPage } = usePersonalDashboardActivityPageRouteMatch();
  const isDashboardTodosPage = !!useRouteMatch([personalDashboardPaths.toDos]);
  const isApprovalsPage = !!useRouteMatch([personalDashboardPaths.approvals]);
  // using for both relevantTo and assignee
  const internalUser = multiFilter({
    // we render the internalUser filter label based on current tab page
    label: isApprovalsPage ? "Waiting On" : isDashboardActivityPage ? "Relevant To" : "Assigned to",
    options: teamMemberOptions,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ name, id }) => (id === currentUserId ? `${name} (Me)` : name),
    defaultValue: defaultUserValue,
    nothingSelectedText: isDashboardActivityPage || isDashboardTodosPage ? "All" : "None",
  });

  const upcomingDueInFilter = {
    dueIn: singleFilter({
      options: [
        { name: "Stale Tasks", id: UpcomingDueIn.STALE },
        { name: "Tomorrow", id: UpcomingDueIn.TOMORROW },
        { name: "1 Week", id: UpcomingDueIn.ONE_WEEK },
        { name: "3 Weeks", id: UpcomingDueIn.THREE_WEEKS },
        { name: "1 Month", id: UpcomingDueIn.ONE_MONTH },
      ],
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ name }) => name,
    }),
  };

  const development = multiFilter({
    label: "Development",
    options: developments,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ name }) => name,
  });

  const cohort = multiFilter({
    label: "Cohorts",
    options: cohorts,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ name }) => name,
  });

  // custom filter for activities page
  const activitiesFilter = {
    internalUser,
    parentType: multiFilter({
      label: "Type",
      options: userEventTypes ?? [],
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ name }) => name,
    }),
    project: multiFilter({
      options: projects,
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ name }) => name,
    }),
    createdAt: dateFilter({
      label: "Created at",
      operations: dateFilterOperations,
      getOperationLabel: ({ label }) => label,
      getOperationValue: ({ value }) => value,
      defaultValue: {
        op: DateOperation.After,
        value: new DateOnly(subWeeks(new Date(), 1)),
      },
    }),
    createdBy: multiFilter({
      label: "Created by",
      options: teamMemberOptions,
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ name }) => name,
    }),
    createdFor: multiFilter({
      label: "Created for",
      options: teamMemberOptions,
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ name }) => name,
    }),
    cohort,
    development,
  };

  const routeFilters = {
    [PersonalDashboardRoutes.ACTIONS]: {
      actionItemTypes: multiFilter({
        label: "Type",
        options: [
          { name: "Stale Tasks", id: ActionItemTypes.STALE_TASKS },
          { name: "Approvals", id: ActionItemTypes.APPROVALS },
          { name: "Scheduling", id: ActionItemTypes.SCHEDULING },
          { name: "Delays", id: ActionItemTypes.DELAYS },
          { name: "Unreleased Click to Pay Bills", id: ActionItemTypes.DEFERRED_BILLS },
          { name: "Bills Pending Review", id: ActionItemTypes.BILLS_PENDING_REVIEW },
        ],
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
    },
    [PersonalDashboardRoutes.UPCOMING]: {
      ...(upcomingView === UpcomingView.Calendar ? {} : upcomingDueInFilter),
    },
    [PersonalDashboardRoutes.TO_DOS]: {
      development,
      cohort,
      market: multiFilter({
        options: markets,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
      type: multiFilter({
        options: toDoTypes ?? [],
        getOptionValue: ({ code }) => code,
        getOptionLabel: ({ name }) => name,
      }),
      dueDate: dateFilter({
        label: "Due Date",
        operations: dateFilterOperations,
        getOperationLabel: ({ label }) => label,
        getOperationValue: ({ value }) => value,
      }),
      createdBy: multiFilter({
        label: "Created by",
        options: teamMemberOptions,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name, id }) => (id === currentUserId ? `${name} (Me)` : name),
        defaultValue: defaultUserValue,
      }),
      watching: checkboxFilter({
        label: "Watching",
      }),
      urgent: checkboxFilter({
        label: "Urgent",
      }),
    },
    [PersonalDashboardRoutes.APPROVALS]: {
      requested: multiFilter({
        label: "Requested By",
        options: teamMemberOptions,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name, id }) => (id === currentUserId ? `${name} (Me)` : name),
        nothingSelectedText: "None",
      }),
      approvalStatus: singleFilter({
        label: "Status",
        options: [
          ...Object.values(ApprovalStatus).map((status) => ({ label: capitalCase(status), value: status })),
          { label: "Overdue" as const, value: "overdue" as const } as const,
        ],
        getOptionLabel: ({ label }) => label,
        getOptionValue: ({ value }) => value,
      }),
      approvalSubjectType: multiFilter({
        label: "Subject",
        options: [
          ...Object.values(ApprovalSubjectType).map((subject) => ({ label: capitalCase(subject), value: subject })),
        ],
        getOptionLabel: ({ label }) => label,
        getOptionValue: ({ value }) => value,
        nothingSelectedText: "None",
      }),
    },
    [PersonalDashboardRoutes.ACTIVITY]: {
      ...(isDashboardActivityPage ? activitiesFilter : {}),
    },
  };

  return {
    // UserEvents filter does not take assignee, it takes relevantTo
    ...(!isDashboardActivityPage && {
      internalUser,
      project: multiFilter({
        options: projects,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
        disabled: projects?.length === 0 ? "Select a user in the 'Assigned to' filter to see projects" : undefined,
      }),
    }),
    ...routeFilters[section ? section : PersonalDashboardRoutes.ACTIONS],
  };
}
