import { Button, ModalBody, ModalFooter, ModalHeader, SelectField, useModal } from "@homebound/beam";
import { useState } from "react";
import { InvoiceProjectItemFragment } from "src/generated/graphql-types";

type AddInvoiceItemModalProps = {
  projectItems: InvoiceProjectItemFragment[];
  onAdd: (projectItem: string) => void;
};

export function AddInvoiceItemModal(props: AddInvoiceItemModalProps) {
  const { projectItems, onAdd } = props;
  const { closeModal } = useModal();
  const [projectItemId, setProjectItemId] = useState<string>("");

  return (
    <>
      <ModalHeader>Add Line Item</ModalHeader>
      <ModalBody>
        <SelectField
          label="Project Item"
          value={projectItemId}
          onSelect={(pId) => pId && setProjectItemId(pId)}
          options={projectItems}
          getOptionValue={(o) => o.id}
          getOptionLabel={(o) => o.displayName}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Add"
          disabled={!projectItemId}
          onClick={() => {
            onAdd(projectItemId);
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
