import { DcLineItemsTabCommitmentLineItemFragment, HomeownerSelectionStatus, Maybe } from "src/generated/graphql-types";
import { Selectable } from "src/models";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

export type LineItemWithCommitmentId = DcLineItemsTabCommitmentLineItemFragment & {
  commitmentId: string;
};

export class ObservableLineItem extends Selectable {
  commitmentId: string;
  costCode: string;
  costCodeId: string;
  location: Maybe<string>;
  locationId: Maybe<string>;
  costType: string;
  quantity: Maybe<number>;
  unit: string;
  totalUnbilledinCents: Maybe<number>;
  totalCommittedInCents: Maybe<number>;
  totalPaidToDate: Maybe<number>;
  totalBalanceDue: Maybe<number>;
  totalBilledInCents: Maybe<number>;
  projectName: string;
  projectIds: string[];
  isSelection: boolean;
  selectionStatus: Maybe<HomeownerSelectionStatus>;
  itemName: string;
  fullCode: string;

  constructor(cli: LineItemWithCommitmentId) {
    super(cli.id);
    this.commitmentId = cli.commitmentId;
    this.costCode = cli.costCode.displayName;
    this.costCodeId = cli.costCode.id;
    this.location = cli.projectItem.location.name;
    this.locationId = cli.projectItem.location.id;
    this.costType = cli.projectItem.costType;
    this.quantity = cli.quantity;
    this.unit = cli.projectItem.unitOfMeasure.name;
    this.totalCommittedInCents = cli.committedInCents;
    this.totalBilledInCents = cli.billedInCents;
    this.totalUnbilledinCents = cli.unbilledInCents;
    this.totalPaidToDate = cli.paidInCents;
    this.totalBalanceDue = cli.balanceInCents;
    this.projectName = cli.projectItem.project.name;
    this.projectIds = [cli.projectItem.project.id];
    this.isSelection = cli.projectItem.item.isSelection;
    this.selectionStatus = cli.projectItem.homeownerSelection?.status.code;
    this.itemName = cli.projectItem.name;
    this.fullCode = cli.projectItem.item.fullCode;
    makeSimpleAutoObservable(this);
  }
}
