import { Button, ButtonGroup, ButtonMenu, Css, Icon, Tag, useTestIds } from "@homebound/beam";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  addEntityParam,
  createDevelopmentProductOfferingUrl,
  createProductOfferingConfigsUrl,
  createProductOfferingUrl,
} from "src/RouteUrls";
import { ProgressPill } from "src/components";
import { ReadyPlanVersionHeader } from "src/components/ReadyPlanVersionHeader";
import { ProductOfferingsDetailFragment, useProductOfferingsPageQuery } from "src/generated/graphql-types";
import { usePersistedToggle } from "src/hooks";
import { EmptyState } from "src/routes/components/EmptyState";
import { PageHeader } from "src/routes/layout/PageHeader";
import { PLAN_FALLBACK_IMG } from "src/routes/libraries/plan-package/takeoffs/utils";
import { DevelopmentParams } from "src/routes/routesDef";
import { queryResult, readyPlanStatusToTagType } from "src/utils";
import { programDataBathRange, programDataRange } from "src/utils/programData";
import { EmptyPlanAndOptionImage } from "../plan-and-options/components/EmptyStateSvgs";
import { ProductOfferingsTable } from "./ProductOfferingsTable";
import { UpdatesAvailableButton } from "./components/UpdatesAvailable";

export function ProductOfferingsPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const history = useHistory();
  const query = useProductOfferingsPageQuery({ variables: { development: developmentId } });

  return queryResult(query, {
    data: ({ productOfferings }) => (
      <>
        <PageHeader
          title="Product Offerings"
          right={
            <>
              {productOfferings.entities.length !== 0 && (
                <>
                  {developmentId && (
                    <ButtonMenu
                      trigger={{ label: "Actions" }}
                      items={[
                        { label: "Compare Configurations", onClick: createProductOfferingConfigsUrl(developmentId) },
                      ]}
                    />
                  )}
                  <Button
                    label="Create New"
                    onClick={() => history.push(createProductOfferingUrl(addEntityParam, developmentId))}
                  />
                </>
              )}
            </>
          }
        />
        <ProductOfferingsPageView developmentId={developmentId} productOfferings={productOfferings.entities} />;
      </>
    ),
  });
}

type ProductOfferingsPageViewProps = {
  productOfferings: ProductOfferingsDetailFragment[];
  developmentId?: string;
};

export function ProductOfferingsPageView({ productOfferings, developmentId }: ProductOfferingsPageViewProps) {
  const { length } = productOfferings;
  const history = useHistory();
  const [onTileView, toggleView] = usePersistedToggle("productOfferingsTileView", true);
  return length === 0 ? (
    <EmptyState
      message="There are no Product Offerings for this development yet"
      svg={<EmptyPlanAndOptionImage />}
      button={
        <Button
          label="Create Product Offering"
          onClick={() => history.push(createProductOfferingUrl(addEntityParam, developmentId))}
        />
      }
    />
  ) : (
    <>
      <div css={Css.df.gap1.aic.pb2.$}>
        <div css={Css.smBd.$}>View</div>
        <ButtonGroup
          buttons={[
            {
              icon: "tile",
              tooltip: "Tile View",
              active: onTileView,
              onClick: toggleView,
            },
            {
              icon: "list",
              tooltip: "List View",
              active: !onTileView,
              onClick: toggleView,
            },
          ]}
        />
      </div>
      {onTileView ? (
        <div data-testid="productOfferingList" css={Css.dg.gap3.pb2.jcc.gtc("repeat(auto-fill, 369px)").$}>
          {productOfferings.map((po) => (
            <ProductOfferingCard key={po.id} productOffering={po} />
          ))}
        </div>
      ) : (
        <div>
          <ProductOfferingsTable productOfferings={productOfferings} />
        </div>
      )}
    </>
  );
}

type ProductOfferingCardProp = {
  productOffering: ProductOfferingsDetailFragment;
};

export function ProductOfferingCard({ productOffering }: ProductOfferingCardProp) {
  const tid = useTestIds({}, "productOfferingCard");
  const { name, id, status, coverAsset, minProgramData, maxProgramData, options, planPackage, bidOutPercentage } =
    productOffering;
  const coverPhotoUrl = coverAsset?.previewUrl ?? PLAN_FALLBACK_IMG;
  const elevationsCount = options?.filter((option) => option?.type.isElevation).length ?? 0;
  return (
    <Link
      {...tid}
      css={Css.bgWhite.wPx(369).br8.bshBasic.p3.df.fdc.jcsb.gap3.aifs.onHover.bshHover.$}
      to={createDevelopmentProductOfferingUrl(productOffering.development!.id, id)}
    >
      <div css={Css.w100.color("black").$}>
        <div css={Css.relative.$}>
          <img src={coverPhotoUrl} alt="Product Offering cover photo" {...tid.coverPhoto} css={Css.hPx(184).ma.$} />
          <div css={Css.absolute.top1.left1.$}>
            <Tag text={status.name} type={readyPlanStatusToTagType[status.code]} {...tid.status} />
          </div>
        </div>
        <div css={Css.mt3.$}>
          <div css={Css.df.jcsb.aic.$}>
            <div css={Css.df.gap("4px").$}>
              <h4 css={Css.xlSb.mbPx(4).$} {...tid.name}>
                {name}
              </h4>
              <ReadyPlanVersionHeader readyPlan={productOffering} />
              <UpdatesAvailableButton
                productOfferingId={productOffering.id}
                updates={productOffering.availableUpdates}
                showText={false}
              />
            </div>
            <Icon icon="verticalDots" />
          </div>
          <div css={Css.df.fdc.aic.w100.mt1.mb2.$}>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.w50.$}>{programDataRange(minProgramData, maxProgramData, "sellableSqft")} sq ft</span>
              <span css={Css.sm.w50.$}>{elevationsCount} elevations</span>
            </div>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.w50.$}>{programDataRange(minProgramData, maxProgramData, "bedrooms")} bed</span>
              <span css={Css.sm.w50.$}>Width: {programDataRange(minProgramData, maxProgramData, "widthInFeet")}</span>
            </div>
            <div css={Css.df.w100.$}>
              <span css={Css.sm.w50.$}>{programDataBathRange(minProgramData, maxProgramData)} bath</span>
              <span css={Css.sm.w50.$}>Depth: {programDataRange(minProgramData, maxProgramData, "depthInFeet")}</span>
            </div>
          </div>
          <div css={Css.smMd.mb1.$}>{planPackage.code}</div>
          <ProgressPill progress={bidOutPercentage} changeColorOnCompleted />
        </div>
      </div>
    </Link>
  );
}
