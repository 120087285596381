import { Css } from "@homebound/beam";
import { ReactNode } from "react";
import { Card } from "src/components/Card";
import { SectionTitle } from "./SectionTitle";

export type DetailItem = {
  component: ReactNode;
  spanColumns?: boolean;
};

type DetailsProps = {
  detailItems: DetailItem[];
};

export function Details(props: DetailsProps) {
  const { detailItems } = props;
  return (
    <div data-testid="contract-details">
      <SectionTitle title="Details" />
      <Card xss={Css.mt2.$}>
        <div data-testid="contract-details-grid" css={Css.dg.gtc("1fr 1fr").gap(3).$}>
          {detailItems.map((item: DetailItem, idx) => (
            <div key={idx} css={Css.if(!!item.spanColumns).add({ gridColumn: "1/3" }).$}>
              {item.component}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}
