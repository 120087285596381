import { Css, useResponsiveGrid, useResponsiveGridItem } from "@homebound/beam";
import { useParams } from "react-router";
import { QueryTable } from "src/components";
import { useProjectDashboardFinancialsQuery } from "src/generated/graphql-types";
import {
  createFinancialsTableColumns,
  createFinancialsTableRows,
} from "src/routes/projects/dashboard/components/Financials/utils";
import { RecentActivityRail } from "src/routes/projects/dashboard/components/RecentActivityRail/RecentActivityRail";

export function FinancialsTab() {
  const { projectId } = useParams<{ projectId: string }>();
  const query = useProjectDashboardFinancialsQuery({ variables: { projectId } });
  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 276, columns: 4, gap: 24 });
  const { gridItemProps } = useResponsiveGridItem({ colSpan: 3 });

  return (
    <div css={{ ...gridStyles, ...Css.aifs.$ }}>
      <div css={Css.bgWhite.p3.br8.bshBasic.$} {...gridItemProps}>
        <h2 css={Css.xlBd.$}>Financial Summary</h2>
        <QueryTable
          query={query}
          createRows={createFinancialsTableRows}
          columns={createFinancialsTableColumns()}
          style={{ allWhite: true, cellTypography: "sm" }}
        />
      </div>

      <RecentActivityRail />
    </div>
  );
}
