import { Button, Css, Icon, IconKey, Palette } from "@homebound/beam";

export function CostSourceCell({ url, label, icon }: { url: string | undefined; label: string; icon: IconKey }) {
  return (
    <div css={Css.df.gap1.aic.$}>
      <Button label={label} onClick={() => url} variant="text" />
      <Icon icon={icon} color={Palette.Green500} inc={2} />
    </div>
  );
}
