import { useApolloClient } from "@apollo/client";
import { currentAuthToken } from "@homebound/auth-components";
import {
  BoundSelectField,
  Button,
  ButtonProps,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useCallback, useState } from "react";
import { jsonToCSV } from "react-papaparse";
import { useRouteMatch } from "react-router";
import { createDevelopmentContractUrl } from "src/RouteUrls";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { CsvUploader } from "src/components/CsvUploader";
import { isValidCsvContent } from "src/components/CsvUtils";
import { TradePartnerSelectField } from "src/components/TradePartnerSelectField";
import { baseDownloadUrl } from "src/context";
import {
  DevelopmentContractDocument,
  Maybe,
  useCreateBidContractMutation,
  useDevelopmentTradePartnersQuery,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { DevelopmentContractUploadType } from "../enums";
import { getDevContractUploadCsvRequiredColumns, getDevContractUploadCsvType } from "../utils";

type CreateBidRequestButtonProps = {
  variant?: ButtonProps["variant"];
};

export function CreateDevelopmentContractButton({ variant = "secondary" }: CreateBidRequestButtonProps) {
  const { openModal } = useModal();
  return (
    <Button
      variant={variant}
      label="Create Contract"
      size="sm"
      onClick={() => {
        openModal({
          content: <CreateDevelopmentContractModal />,
        });
      }}
    />
  );
}

export function CreateDevelopmentContractModal() {
  const { closeModal } = useModal();
  const { upload, errors, loading, setCsvContent, setUploadType, addError, clearErrors } = useUploadBidRequestCsv();
  const match = useRouteMatch<{ developmentId: string }>("/developments/:developmentId");
  const { data } = useDevelopmentTradePartnersQuery({ variables: { developmentId: match!.params.developmentId } });
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { tradePreference: true, startDate: undefined, tradePartner: undefined },
    },
  });

  return (
    <>
      <ModalHeader>Create Development Contract</ModalHeader>
      <ModalBody>
        <h2 css={Css.lgSb.pb2.$}>Details</h2>
        <TradePartnerSelectField
          field={formState.tradePartner}
          disabledOptions={data?.development.bidContracts.map((bc) => bc.tradePartner?.id).compact()}
        />
        <div css={Css.pt2.$}>
          <BoundSelectField
            field={formState.tradePreference}
            options={[
              { value: true, label: "Primary" },
              { value: false, label: "Secondary" },
            ]}
            label="Trade Preference"
            getOptionLabel={(i) => i.label}
            getOptionValue={(i) => i.value}
          />
        </div>
        <div css={Css.pt2.$}>
          <BoundBeamDateField field={formState.startDate} label="Effective Start Date" />
        </div>
        <h2 css={Css.lgSb.pt2.$}>Import pricing from .csv (optional)</h2>
        <CsvUploader
          onError={addError}
          errors={errors}
          onDrop={(content) => {
            clearErrors();
            try {
              const uploadType = getDevContractUploadCsvType(content);
              const evaluation = isValidCsvContent({
                content,
                requiredColumns: getDevContractUploadCsvRequiredColumns(uploadType),
              });
              if (!evaluation.valid) return addError(evaluation.reasons);
              setUploadType(uploadType);
            } catch (error) {
              return addError((error as Error).message);
            }

            setCsvContent(
              jsonToCSV(
                content.map(({ data }) => data),
                { newline: "\n" }, // default is `\r\n` which breaks the parser on the backend
              ),
            );
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
              <Button
                data-testid="submitButton"
                label="Create"
                disabled={loading || formState.errors.toString() || errors?.toString()}
                onClick={() => upload(formState.value)}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValues = {
  tradePartner?: string | null;
  tradePreference?: boolean | null;
  startDate: Maybe<DateOnly>;
};

const formConfig: ObjectConfig<FormValues> = {
  tradePartner: { type: "value", rules: [required] },
  tradePreference: { type: "value", rules: [required] },
  startDate: { type: "value", rules: [required] },
};

function useUploadBidRequestCsv() {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [csvContent, setCsvContent] = useState("");
  const [uploadType, setUploadType] = useState<DevelopmentContractUploadType>();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();
  const [createBidContract] = useCreateBidContractMutation();
  const match = useRouteMatch<{ developmentId: string }>("/developments/:developmentId");
  const addError = (err: unknown) => setErrors((existing) => existing.concat(Array.isArray(err) ? err : String(err)));
  const clearErrors = () => setErrors([] as string[]);
  const client = useApolloClient();

  const upload = useCallback(
    async (values: FormValues) => {
      if (loading) return;
      setLoading(true);

      const { data, errors } = await createBidContract({
        variables: {
          input: {
            isPrimary: values.tradePreference,
            tradePartnerId: values.tradePartner,
            parentId: match!.params.developmentId,
            startDate: values.startDate,
          },
        },
        errorPolicy: "ignore",
      });

      const bidContractId = data?.saveBidContract.bidContract.id;
      // A bid contract will always have an unsigned revision upon creation
      const bidContractRevisionId = data?.saveBidContract.bidContract.unsignedRevision?.id;

      if (!bidContractId) {
        triggerNotice({
          message: errors?.toString() ?? "Bid Contract Creation failed",
        });
        setLoading(false);
        return;
      }

      if (!csvContent) {
        triggerNotice({
          message: "Bid contract created without any line items",
          action: {
            label: "Go to Contract",
            onClick: createDevelopmentContractUrl(match!.params.developmentId, bidContractRevisionId!),
            variant: "tertiary",
          },
        });
        setLoading(false);
        // using Apollo Client directly instead of prop drilling refetch to update cache
        await client.refetchQueries({ include: [DevelopmentContractDocument] });
        closeModal();
        return;
      }

      const response = await fetch(`${baseDownloadUrl()}/csv?type=${uploadType}&bidContractId=${bidContractId}`, {
        method: "POST",
        headers: { "content-type": "text/csv", Authorization: `Bearer ${await currentAuthToken()}` },
        body: csvContent,
      });

      if (response.status !== 200) {
        // Backend may have identified errors in the CSV (Like unfilled Total Cost columns or poorly-formatted numbers)
        // so pop them into the Errors array here.
        const { message } = await response.json();
        if (message) addError(message);
        setLoading(false);
        return;
      }

      // using Apollo Client directly instead of prop drilling refetch to update cache
      await client.refetchQueries({ include: [DevelopmentContractDocument] });
      triggerNotice({
        message: "Bid contract successfully created",
        action: {
          label: "Go to Contract",
          onClick: createDevelopmentContractUrl(match!.params.developmentId, bidContractRevisionId!),
          variant: "tertiary",
        },
      });

      closeModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal, createBidContract, csvContent, loading, match, triggerNotice, uploadType],
  );

  return { upload, errors, loading, setUploadType, setCsvContent, addError, clearErrors };
}
