import { Css } from "@homebound/beam";
import { snakeCase } from "change-case";
import { ReactNode } from "react";

type PlanPackageDetailsCardProps = {
  title: string;
  children: ReactNode;
  width: number;
};

export function PlanPackageDetailsCard({ title, children, width }: PlanPackageDetailsCardProps) {
  return (
    <div css={Css.wPx(width).p3.bgWhite.br8.bshBasic.$} data-testid={snakeCase(`section_${title.toLowerCase()}`)}>
      <div css={Css.gray700.pt2.$}>{children}</div>
    </div>
  );
}
