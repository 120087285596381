import { Button, Css, FullBleed, useTestIds } from "@homebound/beam";
import { createBidPackagesDetailUrl, createBidPackagesUrl } from "src/RouteUrls";
import { FormattedDate, emptyCellDash } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import { useFullscreenModal } from "src/components/useFullscreenModal";
import { BidPackageDetailPageQuery } from "src/generated/graphql-types";
import { PageHeaderBreadcrumbs } from "src/routes/layout/PageHeader";
import { AwardBidModal } from "./AwardBidModal";

export function BidPackageHeader({ bidPackage }: { bidPackage: BidPackageDetailPageQuery["bidPackage"] }) {
  const { id, name, developments, bidPackageGroup, status, dueDate, estimatedConstructionDate } = bidPackage;
  const tid = useTestIds({});
  const { openFullscreen } = useFullscreenModal();

  return (
    <FullBleed>
      <header css={Css.df.aic.py2.mhPx(64).bb.bw1.bcGray200.bgWhite.ifMdAndDown.fdc.$}>
        <div>
          <PageHeaderBreadcrumbs
            breadcrumb={[
              { href: createBidPackagesUrl(), label: "Bids" },
              { href: createBidPackagesUrl(developments.first?.id), label: `${developments.first?.name}` },
              {
                // TODO: Make bidPackageGroup required in schema on the BE (instead of forcing it here)
                // since this will always exist as a bidPackage parent
                href: createBidPackagesDetailUrl(bidPackageGroup!.id, id),
                label: name,
              },
            ]}
            linkXss={Css.blue700.cursorPointer.$}
          />

          {/** Customize our own header since the pageHeader component limits the ability to render header styles per the figma */}
          <div css={Css.dg.gtc("8fr 4fr 4.5fr 1fr").aic.$}>
            <h1 css={Css.xlSb.$} {...tid.name}>
              {name}
            </h1>

            <div css={Css.df.fdr.gap5.$}>
              <div css={Css.df.fdc.wsnw.$}>
                <div css={Css.xsMd.gray700.gap2.$}>Status</div>
                <div css={Css.df.fdr.aic.gap1.$}>
                  <StatusIndicator status={status.code} type="BidPackage" />
                  <div {...tid.status}>{status.name}</div>
                </div>
              </div>

              <div css={Css.bl.br.gray200.$} />

              <div css={Css.df.fdc.wsnw.$}>
                <div css={Css.xsMd.gray700.$}>Bid Due Date</div>
                {/* todo confirm historical siutation check style */}
                {dueDate ? (
                  <FormattedDate date={dueDate} xss={Css.baseBd.$} />
                ) : (
                  <div css={Css.baseBd.$}>Historical</div>
                )}
              </div>

              <div css={Css.bl.br.gray200.$} />

              <div css={Css.df.fdc.wsnw.$}>
                <div css={Css.xsMd.gray700.$}>Est Work Start</div>
                {estimatedConstructionDate ? (
                  <FormattedDate date={estimatedConstructionDate} xss={Css.baseBd.$} />
                ) : (
                  emptyCellDash
                )}
              </div>
            </div>
            <div />

            <div>
              <Button
                label="Award Bid"
                variant="primary"
                onClick={() => openFullscreen(<AwardBidModal bidPackage={bidPackage} />)}
              />
            </div>
          </div>
        </div>
      </header>
    </FullBleed>
  );
}
