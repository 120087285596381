import { ReactNode } from "react";
import { Stage } from "src/generated/graphql-types";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { ScheduleType } from "./table/ScheduleType";

type MaybeRenderForScheduleTypeProps = {
  children: ReactNode;
  renderForTypes: ScheduleType[];
  /** Allows for further limiting the display based on the `Stage` of the schedule.
   * Primarily useful for hiding elements for Development Templates (as Development is both a schedule type and a Stage)
   */
  optionalHideForStages?: Stage[];
};

export function MaybeRenderForScheduleType({
  children,
  renderForTypes,
  optionalHideForStages,
}: MaybeRenderForScheduleTypeProps) {
  const {
    scheduleState: { scheduleType, stage },
  } = useScheduleStore();

  if (!renderForTypes.includes(scheduleType)) return null;
  if (optionalHideForStages && optionalHideForStages.includes(stage)) return null;

  return <>{children}</>;
}
