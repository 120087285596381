import {
  BoundSwitchField,
  BoundTextField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectField,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer, useLocalObservable } from "mobx-react";
import {
  SaveCohortInput,
  useCohortDetailsForFormQuery,
  usePopulateCohortFormOptionsQuery,
  useSaveCohortsMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type CreateOrUpdateCohortModalProps = {
  cohortId?: string;
  marketId?: string;
  developmentId?: string;
  newOrUpdateCohortFromDevelopment?: () => void;
};

export function CreateOrUpdateCohortModal({
  marketId,
  developmentId,
  cohortId,
  newOrUpdateCohortFromDevelopment,
}: CreateOrUpdateCohortModalProps) {
  const { openModal } = useModal();
  const cohortAction = cohortId === undefined ? "Create new cohort" : "Edit";

  return (
    <Button
      data-testid="openCohortsModalButton"
      label={cohortAction}
      variant={cohortId ? "tertiary" : "secondary"}
      onClick={() =>
        openModal({
          content: (
            <CohortForm
              cohortId={cohortId}
              marketId={marketId}
              developmentId={developmentId}
              newOrUpdateCohortFromDevelopment={newOrUpdateCohortFromDevelopment}
            />
          ),
        })
      }
    />
  );
}

// Exported for storybook
export function CohortForm({
  cohortId,
  marketId,
  developmentId,
  newOrUpdateCohortFromDevelopment,
}: CreateOrUpdateCohortModalProps) {
  const { data: formOptions } = usePopulateCohortFormOptionsQuery();
  const cohortDetailsResult = useCohortDetailsForFormQuery({
    variables: { cohortId: cohortId ?? "" },
    skip: cohortId === undefined,
  });
  const [saveCohorts] = useSaveCohortsMutation();
  const { closeModal } = useModal();
  // Need an observable state for the Add Another Project button's readonly logic
  const newProjectInput = useLocalObservable(() => ({
    isDisplayed: cohortId ? false : true,
    toggle() {
      newProjectInput.isDisplayed = !newProjectInput.isDisplayed;
    },
  }));
  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { marketId, developmentId },
    },
  });

  const formContent = (
    <Observer>
      {() => (
        <>
          <ModalHeader>{`${cohortId ? "Edit" : "Add New"} Cohort`}</ModalHeader>
          <ModalBody>
            <h3 css={Css.baseMd.pb2.$}>Details</h3>
            <div css={Css.mb2.$}>
              <BoundTextField field={formState.name} label="Cohort Name" compact={true} />
              <BoundSwitchField
                field={formState.isBoolDefaultForDevelopment}
                label="Default Cohort for New BOOL Projects in Development"
              />
            </div>
            <div css={Css.mt3.$}>
              <h3 css={Css.baseMd.$}>Projects</h3>
              <div css={Css.mb2.sm.gray700.$}>Only projects not assigned to another cohort can be added.</div>
              {formState.projectIds.value?.map((project) => {
                return (
                  <div css={Css.mb1.pl1.df.$} key={project}>
                    <SelectField
                      label="Project"
                      labelStyle="hidden"
                      data-testid="projectSelectField"
                      value={project}
                      onSelect={() => {}}
                      options={formOptions?.projects ?? []}
                      compact={true}
                      readOnly={true}
                    />
                    <Button
                      data-testid="deleteProject"
                      variant="tertiary"
                      label={""}
                      aria-label={`Delete project ${project} from cohort`}
                      icon={"x"}
                      onClick={() => {
                        formState.projectIds.set(formState.projectIds.value?.filter((fv) => fv !== project) ?? []);
                      }}
                    />
                  </div>
                );
              }) ?? []}
              {newProjectInput.isDisplayed && (
                <div css={Css.mb1.$}>
                  <SelectField
                    label="Project"
                    labelStyle="hidden"
                    data-testid="projectSelectField"
                    value={"" as string}
                    onSelect={(newProject) => {
                      if (newProject) {
                        formState.projectIds.set([...(formState.projectIds.value ?? []), newProject]);
                        newProjectInput.toggle();
                      }
                    }}
                    options={
                      formOptions?.projects.filter(
                        (p) => !formState.projectIds.value?.includes(p.id) && p.market.id === formState.marketId.value,
                      ) ?? []
                    }
                    disabled={formState.marketId.value === undefined || formState.marketId.value === null}
                  />
                </div>
              )}
              <Button
                data-testid="addAnotherProject"
                label="Add Another Project"
                variant="tertiary"
                onClick={() => {
                  newProjectInput.toggle();
                }}
                disabled={
                  !formOptions?.projects.some(
                    (p) => p.market.id === formState.marketId.value && !formState.projectIds.value?.includes(p.id),
                  ) || newProjectInput.isDisplayed
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button data-testid="cancelButton" variant="tertiary" label="Cancel" onClick={closeModal} />
            <Button
              data-testid="saveButton"
              label={cohortId ? "Save" : "Add Cohort"}
              onClick={async () => {
                await saveCohorts({
                  variables: { cohorts: { cohorts: [{ ...formState.value }] } },
                  // Update our cache with the new cohort
                  update: (cache, { data }) => {
                    const newCohort = data?.saveCohorts?.cohorts.map((c) => ({ __ref: cache.identify(c) })) ?? [];
                    cache.modify({
                      fields: {
                        cohorts(existingCohorts = []) {
                          return [...existingCohorts, ...newCohort];
                        },
                      },
                    });
                  },
                });
                closeModal();
                if (newOrUpdateCohortFromDevelopment) {
                  newOrUpdateCohortFromDevelopment();
                }
              }}
              disabled={!formState.valid}
            />
          </ModalFooter>
        </>
      )}
    </Observer>
  );

  if (cohortId) {
    return queryResult(cohortDetailsResult, {
      data: (data) => {
        formState.set({
          id: cohortId,
          name: data.cohort.name,
          isBoolDefaultForDevelopment: data.cohort.isBoolDefaultForDevelopment,
          marketId: marketId,
          developmentId: developmentId,
          projectIds: data.cohort.projects?.map((p) => p.id),
        });
        // Save so that the state can be clean in case validation needs to be added
        formState.commitChanges();
        return formContent;
      },
    });
  }

  return formContent;
}

const formConfig: ObjectConfig<SaveCohortInput> = {
  id: { type: "value" },
  marketId: { type: "value", rules: [required] },
  name: { type: "value", rules: [required] },
  isBoolDefaultForDevelopment: { type: "value" },
  developmentId: { type: "value", rules: [required] },
  projectIds: { type: "value" },
};
