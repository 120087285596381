import { useParams } from "react-router";
import { ToDosBoard } from "src/components/to-dos/ToDosBoard";
import { useTeamMembersQuery } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/Routes";
import { queryResult } from "src/utils";

export function ToDosPage() {
  const { projectId } = useParams<ProjectRouteProps>();
  const query = useTeamMembersQuery({ variables: { projectId: projectId } });
  return queryResult(query, {
    data: (result) => {
      return <ToDosBoard projectId={projectId} teamMembers={result.project.availableAssignees} />;
    },
  });
}
