import { AuthViewProps } from "@homebound/auth-components";
import { Avatar, Css } from "@homebound/beam";
import { useCurrentUserQuery } from "src/generated/graphql-types";

type UserProfileProps = AuthViewProps;

export function UserProfile({ user }: UserProfileProps) {
  const { data, error } = useCurrentUserQuery();

  return user ? (
    <div css={Css.base.$}>
      <div css={Css.my2.$}>
        <div>
          <Avatar src={user.picture} name={user.name} showName size="xl" />
          <div>
            <p>{user!.email}</p>
            <p>{user!.phone}</p>
          </div>
        </div>
      </div>
      <div>
        <h2>Backend User Profile</h2>
        <div>
          {data && data.currentUser && (
            <div>
              <p>{data.currentUser!.name}</p>
              <p>{data.currentUser!.email}</p>
            </div>
          )}
          {error && error.message}
        </div>
      </div>
    </div>
  ) : (
    <p>No profile found</p>
  );
}
