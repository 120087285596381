import { Css } from "@homebound/beam";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { PersonalDashboardFilter } from "src/routes/personal-dashboard/useHeaderFilterDefs";
import { isDefined } from "src/utils";

type EmptyStateWrapperProps = {
  children: React.ReactNode;
  title: string;
};

export function EmptyStateWrapper({ children, title }: EmptyStateWrapperProps) {
  const { filter } = useDashboardFilterContext();

  if (shouldShowEmptyState(filter)) {
    return (
      <div data-testid="dashboardEmptyState" css={Css.w100.bgGray100.h100.df.jcsa.aic.br8.$}>
        <div css={Css.tac.maxwPx(320).$}>
          <p css={Css.smBd.pb1.$}>Nothing Selected</p>
          <p css={Css.base.$}>There are no {title} because nothing is selected in the filters.</p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

export function shouldShowEmptyState(filter: PersonalDashboardFilter) {
  return !isDefined(filter.internalUser) || filter?.internalUser.isEmpty;
}
