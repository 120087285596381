import { Css, RichTextField, useModal, useSnackbar } from "@homebound/beam";
import { useState } from "react";
import {
  AggregateVersionStatus,
  PlanPackagePage_AggregateVersionFragment,
  useSavePlanPackageAggregateVersionMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

type PublishAggregateVersionModalProps = {
  copyText: string;
  draftAggregateVersion: PlanPackagePage_AggregateVersionFragment;
  refetchQueries?: string[];
  readyPlanName: string;
};

export function PublishAggregateVersionModal({
  copyText,
  draftAggregateVersion,
  refetchQueries,
  readyPlanName,
}: PublishAggregateVersionModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [versionNotes, setVersionNotes] = useState<string | undefined>();
  const [savePlanPackageAggregateVersion] = useSavePlanPackageAggregateVersionMutation({
    refetchQueries,
  });

  const { version } = draftAggregateVersion ?? {};

  async function publishPlanPackage() {
    await savePlanPackageAggregateVersion({
      variables: {
        input: { id: draftAggregateVersion.id, status: AggregateVersionStatus.Active, versionNotes },
      },
    });
    triggerNotice({
      message: (
        <>
          v{version} of <span css={Css.tdu.$}>{readyPlanName}</span> has been published.
        </>
      ),
    });
    closeModal();
  }

  return (
    <ConfirmationModal
      title={`Ready to Publish v${version}?`}
      label="Publish"
      onConfirmAction={async () => await publishPlanPackage()}
      confirmationMessage={
        <div css={Css.df.fdc.gap2.$}>
          <p>{copyText}</p>
          <RichTextField label="Version Notes" value={versionNotes} onChange={(val) => setVersionNotes(val)} />
        </div>
      }
    />
  );
}
