import {
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  GridColumn,
  GridTable,
  IconButton,
  ScrollableContent,
  Tag,
  actionColumn,
  column,
  numericColumn,
  simpleHeader,
  useComputed,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { action, observable } from "mobx";
import { useMemo } from "react";
import { FormActions } from "src/components";
import {
  BidPackageDetailPage_BidContractRevisionFragment,
  NamedFragment,
  useBillTermsTab_SaveBidContractRevisionMutation,
} from "src/generated/graphql-types";
import { stayInPlace, useModeParam } from "src/hooks";
import { BidDrawRow, BidDrawRows } from "./models/BidDrawRows";

type BillTermsTableProps = {
  basePlanGlobalTasks: NamedFragment[];
  revision: BidPackageDetailPage_BidContractRevisionFragment;
};

export function BillTermsTable({ revision, basePlanGlobalTasks }: BillTermsTableProps) {
  // mode params allows us to persist the read/edit mode if users navigate away and back to this tab
  const { mode, onEdit, onCancel, onSave } = useModeParam({ listPageUrl: stayInPlace });
  const [saveBidContractRevision] = useBillTermsTab_SaveBidContractRevisionMutation();

  const drawRows = useMemo(() => {
    const drawRows = observable([] as BidDrawRow[]);
    drawRows.push(
      ...(
        revision.draws.map((d) => {
          return new BidDrawRow(
            d.id,
            (d.amountInBasisPoints || 0) / 100,
            d.description,
            d.globalPlanTask?.id,
            d.isDeposit,
          );
        }) || []
      )
        // order deposit first, then draws
        .sortBy((d) => (d.isDeposit ? 0 : 1)),
    );
    return new BidDrawRows(revision.id, drawRows, basePlanGlobalTasks);
  }, [basePlanGlobalTasks, revision.draws, revision.id]);

  const readOnly = mode === "read";
  const formState = useFormState({
    config: formConfig,
    init: { input: drawRows, map: (dr) => dr },
    readOnly: readOnly || !revision.canEdit.allowed,
  });

  const columns = useMemo(() => createColumns(formState, basePlanGlobalTasks), [basePlanGlobalTasks, formState]);
  const rows = useComputed(() => {
    return [
      simpleHeader,
      ...formState.draws.rows.map((draw, idx) => ({
        kind: "draw" as const,
        id: draw.value.id || idx.toString(),
        data: draw,
      })),
      ...(formState.draws.rows.length > 0 ? [{ kind: "total" as const, id: "total", data: formState }] : []),
    ];
  }, [formState]);

  return (
    <div css={Css.df.fdc.$}>
      {revision.canEdit.allowed ? (
        <div css={Css.df.jcfe.mb3.$}>
          <Button
            variant="secondary"
            icon="plus"
            disabled={
              !revision.canAddDraws.allowed && revision.canAddDraws.disabledReasons.map((r) => r.message).join(", ")
            }
            onClick={() => {
              drawRows.addDraw();
              onEdit();
            }}
            label="Add Bill Term"
            data-testid="addBillTerm"
          />
          <FormActions
            formState={formState}
            mode={mode}
            onEdit={onEdit}
            onCancel={() => {
              formState.revertChanges();
              onCancel();
            }}
            editDisableReason={revision.canEdit.disabledReasons.map((r) => r.message).join(", ")}
            onSave={async () => {
              await saveBidContractRevision({ variables: { input: drawRows.toInput() } });
              onSave();
            }}
          />
        </div>
      ) : (
        <div css={Css.ml1.mb2.$}>
          <Tag type="neutral" text={revision.canAddDraws.disabledReasons.map((r) => r.message).join(", ")} />
        </div>
      )}
      <ScrollableContent>
        <GridTable
          columns={columns}
          rows={rows}
          style={{ bordered: true, allWhite: true }}
          stickyHeader
          fallbackMessage="There are no bill terms for this trade. Payment will default to the existing payment schedule for the trade."
          as="table"
        />
      </ScrollableContent>
    </div>
  );
}

const formConfig: ObjectConfig<BidDrawRows> = {
  totalPercent: {
    type: "value",
    rules: [
      (os: any) => {
        const fs = (os satisfies any).object;
        return fs && fs.draws.value.length && os.value !== 100 ? "Sum of draws must equal 100%" : undefined;
      },
    ],
    computed: true,
  },
  draws: {
    type: "list",
    config: {
      id: { type: "value" },
      amountInPercent: { type: "value", rules: [({ value: v }) => (!v ? "amount required" : undefined)] },
      description: { type: "value" },
      taskId: {
        type: "value",
        rules: [
          (os: any) => {
            const fs = (os satisfies any).object;
            return fs && fs.isDeposit.value === false && !os.value ? "Non deposit draws must have a task" : undefined;
          },
        ],
      },
      isDeposit: {
        type: "value",
        rules: [required],
      },
    },
  },
};

type Row =
  | { kind: "header" }
  | { kind: "draw"; data: ObjectState<BidDrawRow> }
  | { kind: "total"; data: ObjectState<BidDrawRows> };

function createColumns(formState: ObjectState<BidDrawRows>, tasks: NamedFragment[]): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Bill Terms",
      draw: (draw) => (
        <BoundSelectField
          field={draw.isDeposit}
          options={[
            { label: "Deposit", value: true },
            { label: "Draw", value: false },
          ]}
          getOptionLabel={(o) => o.label}
          getOptionValue={(o) => o.value}
          readOnly={formState.readOnly}
          onSelect={(val) => {
            if (val === true) {
              // Auto clear draw's taskId when changing draw type to deposit (deposits should not have tasks)
              draw.taskId.set(null);
            }
            draw.isDeposit.set(val);
          }}
          disabledOptions={
            // Disable selecting "deposit" type if a deposit already exists
            formState.draws.rows.some((d) => d.isDeposit.value === true)
              ? [{ value: true, reason: "a deposit already exists" }]
              : []
          }
          errorMsg={draw.isDeposit.touched ? draw.isDeposit.errors.join(", ") : undefined}
        />
      ),
      total: "",
      w: "150px",
    }),
    column<Row>({
      header: "Associated Task",
      draw: ({ taskId, isDeposit }) => (
        <BoundSelectField
          field={taskId}
          options={tasks}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
          placeholder="Select a task"
          readOnly={formState.readOnly}
          // Disable selecting tasks already assigned to a draw
          disabledOptions={formState.draws.rows
            .map((d) => d.value.taskId)
            .filter((id) => id !== taskId.value)
            .compact()
            .map((id) => ({ value: id, reason: "task already assigned to another draw" }))}
          errorMsg={taskId.touched ? taskId.errors.join(", ") : undefined}
          // Disable selecting tasks for deposits
          disabled={isDeposit.value === true && "deposits should not have tasks"}
        />
      ),
      total: "",
      w: "480px",
    }),
    column<Row>({
      header: "Description",
      draw: ({ description }) => <BoundTextField field={description} readOnly={formState.readOnly} />,
      total: "",
      w: "500px",
    }),
    numericColumn<Row>({
      header: "% Of Contract",
      draw: ({ amountInPercent }) => (
        <BoundNumberField
          type="percent"
          field={amountInPercent}
          readOnly={formState.readOnly}
          errorMsg={
            amountInPercent.touched
              ? amountInPercent.errors.join(", ") || formState.totalPercent.errors.join(", ")
              : undefined
          }
        />
      ),
      total: ({ totalPercent }) => {
        return <BoundNumberField field={totalPercent} type="percent" readOnly />;
      },
      w: "120px",
    }),
    actionColumn<Row>({
      header: "",
      draw: (draw) =>
        formState.readOnly ? (
          ""
        ) : (
          <IconButton
            icon="trash"
            onClick={action(() => {
              // explicitly remove a draw from formState on deletion (instead of just filtering it from the table view)
              // in order to correctly use form validations without having to save first
              formState.draws.remove(draw.value);
            })}
          />
        ),
      total: "",
      w: "60px",
    }),
  ];
}
