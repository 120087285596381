import {
  Banner,
  BoundCheckboxField,
  BoundMultiSelectField,
  BoundTextField,
  BoundTreeSelectField,
  Button,
  Css,
  HasIdAndName,
  NestedOption,
  Tooltip,
  useModal,
  useSnackbar,
} from "@homebound/beam";

import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { addEntityParam } from "src/RouteUrls";
import {
  ConstraintItemIncrementalCollectionOp,
  IncrementalCollectionOp,
  Maybe,
  MilestoneCatalogForm_GlobalPlanMilestoneFragment,
  MilestoneCatalogForm_GlobalPlanMilestoneGroupFragment,
  SaveGlobalPlanMilestoneInput,
  Scalars,
  useMilestoneCatalogFormDropdownQuery,
  useMilestoneCatalogFormQuery,
  useSaveGlobalPlanMilestonesMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { IdOrAddParams, milestonesCatalogPath } from "src/routes/routesDef";
import { isDefined, mapFormInputToIncrementalOp, queriesResult } from "src/utils";
import { AddConstraintItemModal, ConstraintModalConfig } from "../task-catalog/components/AddConstraintItemModal";
import { LabelledTextWithTooltip } from "../task-catalog/components/TaskCatalogForm";
import { AddAdditionalMilestoneCard } from "./components/AddAdditionalMilestoneCard";
import { AddGlobalPlanMilestoneGroupModal } from "./components/AddGlobalPlanMilestoneGroupModal";

export function MilestoneCatalogForm() {
  const { idOrAdd } = useParams<IdOrAddParams>();
  const isFormNew = idOrAdd === addEntityParam;

  const formQuery = useMilestoneCatalogFormQuery({
    variables: { id: idOrAdd },
    skip: isFormNew,
  });

  const dropdownQuery = useMilestoneCatalogFormDropdownQuery({});

  return queriesResult([formQuery, dropdownQuery] as const, {
    data: (formData, dropdownData) => (
      <MilestoneCatalogFormView
        globalPlanMilestone={formData?.globalPlanMilestone}
        globalPlanConstraintItems={dropdownData.globalPlanConstraintItems}
        globalPlanMilestoneGroups={dropdownData.globalPlanMilestoneGroups}
        isFormNew={isFormNew}
      />
    ),
  });
}

type MilestoneCatalogFormViewProps = {
  globalPlanMilestone: MilestoneCatalogForm_GlobalPlanMilestoneFragment;
  globalPlanConstraintItems: HasIdAndName[];
  globalPlanMilestoneGroups: MilestoneCatalogForm_GlobalPlanMilestoneGroupFragment[];
  isFormNew: boolean;
};

export function MilestoneCatalogFormView({
  globalPlanConstraintItems,
  globalPlanMilestone,
  globalPlanMilestoneGroups,
  isFormNew,
}: MilestoneCatalogFormViewProps) {
  const [saveGlobalPlanMilestones] = useSaveGlobalPlanMilestonesMutation();
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const isSystemPlanMilestone = useMemo(
    () => globalPlanMilestone && isDefined(globalPlanMilestone.systemPlanMilestone),
    [globalPlanMilestone],
  );
  const [showSystemMilestoneBanner, setShowSystemMilestoneBanner] = useState(false);

  useEffect(() => {
    if (isSystemPlanMilestone) {
      setShowSystemMilestoneBanner(true);
    }
  }, [isSystemPlanMilestone]);

  const formState = useFormState({
    config: formValue,
    init: {
      input: globalPlanMilestone,
      map: (gpm) => {
        const { constraints, groups, id, ...others } = gpm;
        return {
          id: isFormNew ? undefined : id,
          constraints: constraints.map((c) => c.id),
          groups: groups.map((group) => group.id),
          isAllDefaultSelected: false,
          // intentionally set to empty array as we never will edit multiple milestones at once
          additionalMilestoneCards: [],
          ...others,
        };
      },
    },
  });

  const onSubmit = useCallback(async () => {
    // removing properties we don't want to send to the backend
    const { additionalMilestoneCards, isAllDefaultSelected, ...others } = formState.value;
    await saveGlobalPlanMilestones({
      variables: {
        input: {
          globalPlanMilestones: [
            mapToInput(others, globalPlanMilestone, isFormNew),
            // merge in the additional milestones if we have them
            ...(additionalMilestoneCards?.nonEmpty
              ? additionalMilestoneCards.map((value) => {
                  return {
                    ...mapToInput(value, globalPlanMilestone, isFormNew),
                  };
                })
              : []),
          ],
        },
      },
      onCompleted: () => {
        triggerNotice({
          message: isFormNew ? "A new milestone was created!" : "Milestone was successfully updated.",
        });
      },
    });
    // redirect back to Milestone Catalog Page once created
    history.push(milestonesCatalogPath);
  }, [formState.value, saveGlobalPlanMilestones, triggerNotice, isFormNew, history, globalPlanMilestone]);

  const treeSelectOptions = useMemo(
    () => formatTreeSelectOptions(globalPlanMilestoneGroups ?? []),
    [globalPlanMilestoneGroups],
  );

  const { openModal } = useModal();

  const boundCheckboxField = (
    <BoundCheckboxField
      label="Set as default milestone"
      field={formState.isDefault}
      disabled={!isFormNew}
      onChange={(val) => {
        formState.isDefault.set(val);
        formState.isAllDefaultSelected.set(null);
      }}
    />
  );

  return (
    <Observer>
      {() => (
        <div data-testid="milestoneCatalogForm">
          <div>
            <PageHeader
              title={isFormNew ? "Create New Milestone" : "Edit Milestone"}
              right={
                <div css={Css.df.aic.$}>
                  <div css={Css.mr1.$}>
                    <Button label={isFormNew ? "Create" : "Save"} onClick={onSubmit} disabled={!formState.valid} />
                  </div>
                </div>
              }
            />
            <div css={Css.df.fdc.maxwPx(600).ma.gap2.$}>
              {showSystemMilestoneBanner && (
                <Banner
                  message="This is a system milestone and is not editable."
                  type="alert"
                  onClose={() => setShowSystemMilestoneBanner(false)}
                />
              )}
              <LabelledTextWithTooltip
                label="Group*"
                tooltip="One or more tags that identifies which group or groups that this particular milestone belongs to (example: “Design Review”)."
              >
                <div css={Css.mt1.$}>
                  <BoundTreeSelectField
                    field={formState.groups}
                    label="Group*"
                    labelStyle="hidden"
                    fullWidth
                    defaultCollapsed
                    options={treeSelectOptions}
                    onSelect={(options) => formState.groups.set(options.leaf.values)}
                    readOnly={isSystemPlanMilestone}
                  />
                </div>
              </LabelledTextWithTooltip>
              {!isSystemPlanMilestone && (
                <Button
                  label="Add New"
                  variant="text"
                  onClick={() =>
                    openModal({
                      content: <AddGlobalPlanMilestoneGroupModal formState={formState} />,
                    })
                  }
                />
              )}
              {isFormNew && (
                <BoundCheckboxField
                  label="Set all as default milestones"
                  field={formState.isAllDefaultSelected}
                  onChange={(val) => {
                    formState.isAllDefaultSelected.set(val);
                    formState.isDefault.set(val);
                    formState.additionalMilestoneCards.rows.forEach((milestone) => {
                      // set the additonal milestones to the same value as the parent
                      milestone.isDefault.set(val);
                    });
                  }}
                />
              )}
              <div css={Css.bgWhite.br8.p3.df.fdc.gap2.$}>
                <BoundTextField
                  label="Milestone Name*"
                  field={formState.name}
                  required
                  fullWidth
                  readOnly={isSystemPlanMilestone}
                />
                <BoundMultiSelectField
                  field={formState.constraints}
                  label="Constraints"
                  options={globalPlanConstraintItems ?? []}
                  disabled={isSystemPlanMilestone}
                />
                {!isSystemPlanMilestone && (
                  <Button
                    label="Add New"
                    variant="text"
                    onClick={() =>
                      openModal({
                        content: (
                          <AddConstraintItemModal
                            constraintType={ConstraintModalConfig.ConstraintItem}
                            formState={formState}
                          />
                        ),
                      })
                    }
                  />
                )}
                <div css={Css.mt2.$}>
                  {isFormNew ? (
                    boundCheckboxField
                  ) : (
                    <Tooltip title="Changing this milestone’s default status is disabled after the milestone is created for now.">
                      {boundCheckboxField}
                    </Tooltip>
                  )}
                </div>
              </div>
              {/* we are only able to add additional milestones in "create" mode */}
              {isFormNew && (
                <AddAdditionalMilestoneCard
                  formState={formState}
                  openModal={openModal}
                  globalPlanConstraintItems={globalPlanConstraintItems}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
}

export type SaveAdditionalMilestoneCardFormValue = Pick<SaveGlobalPlanMilestoneInput, "id" | "archived"> & {
  constraints: Maybe<Array<Scalars["ID"]>>;
  name: Maybe<Scalars["String"]>;
  isDefault: Maybe<boolean>;
  groups: Maybe<Array<Scalars["ID"]>>;
};

export type SaveGlobalPlanMilestoneFormValue = Pick<
  SaveGlobalPlanMilestoneInput,
  "id" | "archived" | "isDefault" | "name"
> & {
  constraints: Maybe<Array<Scalars["ID"]>>;
  groups: Maybe<Array<Scalars["ID"]>>;
  isAllDefaultSelected: Maybe<boolean>;
  additionalMilestoneCards: SaveAdditionalMilestoneCardFormValue[];
};

export const additionalMilestoneCardConfig: ObjectConfig<SaveAdditionalMilestoneCardFormValue> = {
  constraints: { type: "value" },
  name: { type: "value", rules: [required] },
  isDefault: { type: "value" },
  groups: { type: "value" },
};

export const formValue: ObjectConfig<SaveGlobalPlanMilestoneFormValue> = {
  name: { type: "value", rules: [required] },
  isDefault: { type: "value" },
  isAllDefaultSelected: { type: "value" },
  constraints: { type: "value" },
  groups: { type: "value", rules: [required] },
  additionalMilestoneCards: { type: "list", config: additionalMilestoneCardConfig },
};

export function formatTreeSelectOptions(
  groups: MilestoneCatalogForm_GlobalPlanMilestoneGroupFragment[],
): NestedOption<HasIdAndName>[] {
  const groupsByBusinessFunctionType = groups.groupBy((group) => group.businessFunctionType.name);
  return (
    Object.keys(groupsByBusinessFunctionType)
      .map((bf) => ({
        id: bf,
        name: bf,
        children: groupsByBusinessFunctionType[bf]?.map((group) => ({
          id: group.id,
          name: group.name,
        })),
      }))
      // we only want to show business function types that have groups
      .filter((bf) => bf.children?.length)
      .sortByKey("name")
  );
}

// one-off helper to format constraints since it takes in a differnt type of Incremental Collection type
function mapToConstraintInput(constraints: Maybe<string[]>, existingValues: HasIdAndName[]) {
  // if we dont have an input then return nothing
  if (!constraints) return [];

  const existingValuesAsIds = existingValues?.map((ci) => ci.id) ?? [];

  const removedValues =
    existingValues
      ?.filter((existingValue) => !constraints?.find((input) => input === existingValue.id))
      .map((removedCi) => ({
        id: removedCi.id,
        op: ConstraintItemIncrementalCollectionOp.Remove,
      })) ?? [];

  const addedValues = constraints
    .filter((input) => !existingValuesAsIds.includes(input ?? ""))
    .map((addedCi) => ({
      id: addedCi,
      op: ConstraintItemIncrementalCollectionOp.Include,
    }));

  return [...removedValues, ...addedValues];
}

function mapToInput(
  formValue:
    | Omit<SaveGlobalPlanMilestoneFormValue, "additionalMilestoneCards" | "isAllDefaultSelected">
    | SaveAdditionalMilestoneCardFormValue,
  data: MilestoneCatalogForm_GlobalPlanMilestoneFragment | undefined,
  isFormNew: boolean,
): SaveGlobalPlanMilestoneInput {
  const { groups, constraints, id, ...others } = formValue;

  const groupsInput = mapFormInputToIncrementalOp(groups, data?.groups, {
    existingValueToId: (group) => group.id,
    removedMapper: (removedGroup) => ({ id: removedGroup.id, op: IncrementalCollectionOp.Remove }),
    addedMapper: (addedId) => ({ id: addedId, op: IncrementalCollectionOp.Include }),
  });

  const constraintsInput = mapToConstraintInput(constraints, data?.constraints ?? []);

  return {
    id: !isFormNew ? data?.id : undefined,
    ...(groupsInput?.nonEmpty && { groups: groupsInput }),
    ...(constraintsInput?.nonEmpty && { constraints: constraintsInput }),
    ...others,
  };
}
