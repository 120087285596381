import { useEffect } from "react";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";

export function useProjectTitle(title?: string) {
  const setDocumentTitle = useDocumentTitle();
  const projectContext = useProjectContext();

  useEffect(() => {
    const { isSet, name } = projectContext;
    if (isSet) {
      // Show only the Project's `name` if the title was not supplied
      setDocumentTitle(`${title ? `${title} | ` : ""}${name}`);
    } else if (title) {
      // Set the `document.title` based on the `title` prop, if available
      setDocumentTitle(title);
    }
  }, [projectContext, title, setDocumentTitle]);
}
