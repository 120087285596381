import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal, useTestIds } from "@homebound/beam";
import { useCallback } from "react";
import { CsvUploader } from "src/components/CsvUploader";
import { useItemTemplateItemsUploader } from "src/routes/settings/itemTemplates/useItemTemplateItemsUploader";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

type ImportItemTemplateItemsModalProps = { itApi: ItemTemplateApi };

/** Provides "From Import" for both v1 and v2 templates. */
export function ImportItemTemplateItemsModal({ itApi }: ImportItemTemplateItemsModalProps) {
  const { closeModal } = useModal();

  const tid = useTestIds({});
  const { errors, addError, handleOnDrop, handleOnClick, importDataReady } = useItemTemplateItemsUploader(itApi);

  const onImportClick = useCallback(async () => {
    await handleOnClick();
    closeModal();
  }, [handleOnClick, closeModal]);

  return (
    <>
      <ModalHeader>Import from File</ModalHeader>
      <ModalBody>
        <div css={Css.sm.gray800.$}>Import items via .csv file.</div>
        <CsvUploader errors={errors} onError={addError} onDrop={handleOnDrop} />
        <div css={Css.tiny.gray800.mt2.mb3.$}>
          Note:
          <ul css={Css.m0.ml2.p0.$}>
            <li>File must have columns for Item Code, Cost Type and Item Name.</li>
            <li>
              Optional columns: Location, Specifications, Cost, Units, Blueprint Product ID, Bid Item ID, Bid Item Code
            </li>
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap1.$}>
          <Button label="Cancel" variant="tertiary" onClick={closeModal} {...tid.cancel} />
          <Button
            label="Import"
            variant="primary"
            onClick={onImportClick}
            // Our import button is disabled until the import data is ready or there are errors
            disabled={!importDataReady || errors.length > 0}
            {...tid.import}
          />
        </div>
      </ModalFooter>
    </>
  );
}
