import { Button, ButtonMenu, Css, HbLoadingSpinner, Icon } from "@homebound/beam";
import { useState } from "react";
import { createProductPageUrl } from "src/RouteUrls";
import { SearchBox } from "src/components";
import { Pagination } from "src/components/Pagination";
import { PageHeader } from "src/routes/layout/PageHeader";
import { tableHeightWithPagination } from "src/utils";
import { ProductCatalogFilter } from "./components/ProductCatalogFilter";
import { ProductCatalogListView } from "./components/ProductCatalogListView";
import { ProductCatalogTileView } from "./components/ProductCatalogTileView";
import { ProductCatalogViewType } from "./enums";
import { useProductCatalog } from "./hooks/useProductCatalog";

export function ProductCatalogPage() {
  const { products, setSettings, setSearch, settings, loading, hasNextPage } = useProductCatalog();
  const [viewType, setViewType] = useState(ProductCatalogViewType.TILE);

  return (
    <div css={Css.w100.h100.$}>
      <PageHeader
        title="Product Catalog"
        hideTitle
        left={
          <div>
            <div css={Css.xl2Bd.$}>Product Catalog</div>
            <div css={Css.gray700.$}>
              Here you can search for products, add or update product details and costs, or compare market pricing
            </div>
          </div>
        }
        right={
          <div css={Css.mla.mya.df.$}>
            <div css={Css.mr1.$}>
              <ButtonMenu disabled trigger={{ label: "Actions" }} items={[]} />
            </div>
            <Button label="Add New" onClick={createProductPageUrl()} />
          </div>
        }
      />

      <section css={Css.df.$}>
        <div>
          <ProductCatalogFilter settings={settings} setSettings={setSettings} />
        </div>

        <div css={Css.fg1.$}>
          <div css={Css.df.pb2.pt3.$}>
            <div css={Css.df.mla.$}>
              <label css={Css.smBd.mya.pr1.$}>View</label>
              <div css={Css.br4.ba.bcGray300.df.$}>
                <button
                  css={Css.br.pxPx(2).bcGray300.if(viewType === ProductCatalogViewType.TILE).bgGray200.$}
                  onClick={() => setViewType(ProductCatalogViewType.TILE)}
                >
                  <Icon icon="tile" />
                </button>
                <button
                  css={Css.pxPx(2).if(viewType === ProductCatalogViewType.LIST).bgGray200.$}
                  onClick={() => setViewType(ProductCatalogViewType.LIST)}
                >
                  <Icon icon="list" />
                </button>
              </div>
            </div>
            <div css={Css.ml2.$}>
              <SearchBox data-testid="searchTerm" debounceDelayInMs={500} onSearch={setSearch} />
            </div>
          </div>
          <div css={Css.pl2.pb2.h(tableHeightWithPagination).oa.$}>
            {loading ? (
              <HbLoadingSpinner />
            ) : viewType === ProductCatalogViewType.TILE ? (
              <ProductCatalogTileView filteredProducts={products} />
            ) : (
              <ProductCatalogListView filteredProducts={products} />
            )}
          </div>
          <Pagination label="Products" hasNextPage={hasNextPage} settings={settings} setSettings={setSettings} />
        </div>
      </section>
    </div>
  );
}
