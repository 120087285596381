import { createContext, PropsWithChildren, useContext } from "react";
import { FeatureFlagType } from "src/generated/graphql-types";

export type DevelopmentContextProps = {
  id?: string;
  featureFlags: FeatureFlagType[];
};

const DevelopmentContext = createContext<DevelopmentContextProps>({
  id: undefined,
  featureFlags: [],
});

type DevelopmentContextProviderProps = PropsWithChildren<DevelopmentContextProps>;

export function DevelopmentContextProvider(props: DevelopmentContextProviderProps) {
  const { children, ...contextValue } = props;
  return <DevelopmentContext.Provider value={contextValue}>{children}</DevelopmentContext.Provider>;
}

export function useDevelopmentContext() {
  return useContext(DevelopmentContext);
}
