import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  ModalProps,
  numericColumn,
  RowStyles,
  simpleHeader,
  SortHeader,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { MutableRefObject, useMemo, useRef } from "react";
import { linkHeader, priceCell, priceTotal } from "src/components";
import {
  BudgetPage_CostCodeFragment,
  BudgetPage_ProjectItemFragment,
  BudgetSummaryQueryResult,
  CostClassificationType,
  InputMaybe,
  Stage,
} from "src/generated/graphql-types";
import { CostData, sumCostData } from "src/routes/projects/budget/budgetCalc";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { createProjectExpensesUrl } from "src/RouteUrls";
import { groupBy, isDefined, sortBy, subtract, sum } from "src/utils";
import { maybeAddIdColumn } from "src/utils/idColumn";
import { BudgetPageFilter } from "./BudgetPage";
import { BudgetSuperDrawer } from "./components/BudgetSuperDrawer";

export function BudgetTable(props: {
  data: Exclude<BudgetSummaryQueryResult["data"], undefined>;
  searchFilter?: string;
  filter: BudgetPageFilter | undefined;
  setFilteredPIs: (PIs: BudgetPage_ProjectItemFragment[]) => void;
}) {
  const { data, searchFilter, filter, setFilteredPIs } = props;
  const { openModal } = useModal();
  const { openInDrawer } = useSuperDrawer();
  const { initialBudget } = useProjectContext();
  const columns = useMemo(() => createColumns(openModal, initialBudget), [openModal, initialBudget]);
  const tableRows = useMemo(
    () =>
      createRows(
        data,
        {
          costCode: filter?.costCode,
          costClassification: filter?.costClassification,
          stage: filter?.stage,
        },
        setFilteredPIs,
      ),
    [data, filter, setFilteredPIs],
  );
  const rowLookup = useRef<GridRowLookup<Row>>();
  const rowStyles = useMemo(
    () => createRowStyles(openInDrawer, rowLookup, data.project.id),
    [data.project.id, openInDrawer],
  );

  return (
    <GridTable
      style={{ bordered: true, allWhite: true }}
      columns={columns}
      rows={tableRows}
      stickyHeader
      sorting={{ on: "client", initial: ["projectItem", "ASC"], primary: ["projectItem", "ASC"] }}
      filter={searchFilter}
      rowLookup={rowLookup}
      rowStyles={rowStyles}
    />
  );
}

// Declare types for each kind of row we're going to have
type TotalsRow = { kind: "totals"; id: string; data: { costData: CostData } };
type HeaderRow = { kind: "header"; id: string; data: unknown };
type ExpandableHeaderRow = { kind: "expandableHeader"; id: string; data: unknown };

type CostClassRow = {
  kind: "costClass";
  id: string;
  data: {
    name: string;
  };
};

type CostCodeRow = {
  kind: "costCode";
  id: string;
  data: {
    costData: CostData;
    name: string;
    projectItemIds: string[];
    costClass: string | undefined;
  };
};
type ProjectItemRow = {
  kind: "projectItem";
  id: string;
  data: {
    id: string;
    costData: CostData;
    name: string;
    costCode: Pick<BudgetPage_CostCodeFragment, "id" | "displayName" | "costClassification">;
  };
};

type UnallocatedExpensesRow = {
  kind: "unallocatedExpenses";
  id: string;
  data: { link: string; expensesTotal: number };
};

type ExpenseItemRow = {
  kind: "expenseItem";
  id: string;
  data: {
    id: string;
    costCode: string;
    vendorName: string;
    description: string;
    unallocatedAmountInCents: number;
  };
};

type Row =
  | ExpandableHeaderRow
  | HeaderRow
  | TotalsRow
  | CostClassRow
  | CostCodeRow
  | ProjectItemRow
  | UnallocatedExpensesRow
  | ExpenseItemRow;

// Define each column we'll have
function createColumns(openModal: (props: ModalProps) => void, initialBudget: string): GridColumn<Row>[] {
  const isBool = initialBudget.includes("Underwritten");

  const idColumn = column<Row>({
    totals: emptyCell,
    expandableHeader: emptyCell,
    header: "ID",
    costClass: emptyCell,
    costCode: emptyCell,
    projectItem: (row) => row.id,
    unallocatedExpenses: emptyCell,
    expenseItem: (row) => row.id,
    mw: "100px",
  });

  const descriptionColumn = column<Row>({
    id: "costCode",
    totals: "Totals",
    expandableHeader: emptyCell,
    header: "Cost Code",
    costClass: (row) => row.name,
    unallocatedExpenses: (data) => linkHeader("Unallocated Expenses", data.link),
    expenseItem: (row) => ({
      content: (
        <>
          {row.costCode !== "" ? (
            <div css={Css.dib.$}>{`${row.costCode} ${row.vendorName} ${row.description}`}</div>
          ) : (
            <div>
              <span css={Css.dib.if(row.costCode === "").red600.$}>{`No Code `}</span>
              <span>{` ${row.vendorName} ${row.description}`}</span>
            </div>
          )}
        </>
      ),
      value: `${row.costCode} ${row.vendorName} ${row.description}`,
    }),
    costCode: (row) => row.name,
    // Adds the parent division's name to the `value` attribute so when searching cost division to filter, then we match on all children within a cost division
    projectItem: (row) => ({ content: row.name, value: `${row.name} ${row.costCode.displayName}` }),
    w: 2,
    mw: "220px",
  });

  const originalColumn = numericColumn<Row>({
    id: "original",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.original }),
    expandableHeader: emptyCell,
    header: () => ({
      content: <SortHeader content={initialBudget.replace("Budget", "")} iconOnLeft sortKey="original" />,
      tooltip: isBool ? "Total underwritten budget" : "Total cost from the original contract",
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.original }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.original }),
    w: "140px",
  });

  const awardColumn = numericColumn<Row>({
    id: "awarded",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.awarded }),
    expandableHeader: emptyCell,
    header: () => ({
      content: <SortHeader content="Awarded" iconOnLeft sortKey="awarded" />,
      tooltip: `${initialBudget} + awarded change events`,
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.awarded }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.awarded }),
    w: "140px",
  });

  const changeEventsColumn = numericColumn<Row>({
    id: "changeEvents",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.changeEvents }),
    expandableHeader: "Change Events",
    header: {
      content: <SortHeader content="Change Events" iconOnLeft sortKey="changeEvents" />,
      tooltip: "Sum of all accepted change events",
      alignment: "center",
    },
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.changeEvents }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.changeEvents }),
    w: "180px",
    expandColumns: [
      numericColumn<Row>({
        id: "nonChargeableChangeEvents",
        totals: ({ costData }) => priceTotal({ valueInCents: costData.nonChargeableChangeEvents }),
        expandableHeader: emptyCell,
        header: () => ({
          content: <SortHeader content="Non Chargeable Change Events" iconOnLeft sortKey="nonChargeableChangeEvents" />,
          tooltip: "Change events that are not chargeable to the client",
          alignment: "center",
        }),
        costClass: emptyCell,
        unallocatedExpenses: emptyCell,
        expenseItem: emptyCell,
        projectItem: ({ costData }) => priceCell({ valueInCents: costData.nonChargeableChangeEvents }),
        costCode: ({ costData }) => priceCell({ valueInCents: costData.nonChargeableChangeEvents }),
        w: "260px",
      }),
      numericColumn<Row>({
        id: "chargeableChangeEvents",
        totals: ({ costData }) => priceTotal({ valueInCents: costData.chargeableChangeEvents }),
        expandableHeader: emptyCell,
        header: () => ({
          content: <SortHeader content="Chargeable Change Events" iconOnLeft sortKey="chargeableChangeEvents" />,
          tooltip: "Change events that are chargeable to the client",
          alignment: "center",
        }),
        costClass: emptyCell,
        unallocatedExpenses: emptyCell,
        expenseItem: emptyCell,
        projectItem: ({ costData }) => priceCell({ valueInCents: costData.chargeableChangeEvents }),
        costCode: ({ costData }) => priceCell({ valueInCents: costData.chargeableChangeEvents }),
        w: "240px",
      }),

      numericColumn<Row>({
        id: "proposedChangeEvents",
        totals: ({ costData }) => priceTotal({ valueInCents: costData.proposedChangeEvents }),
        expandableHeader: emptyCell,
        header: () => ({
          content: <SortHeader content="Proposed Change Events" iconOnLeft sortKey="proposedChangeEvents" />,
          tooltip: "Change events that have a proposed status",
          alignment: "center",
        }),
        costClass: emptyCell,
        unallocatedExpenses: emptyCell,
        expenseItem: emptyCell,
        projectItem: ({ costData }) => priceCell({ valueInCents: costData.proposedChangeEvents }),
        costCode: ({ costData }) => priceCell({ valueInCents: costData.proposedChangeEvents }),
        w: "240px",
      }),

      numericColumn<Row>({
        id: "reallocations",
        totals: ({ costData }) => priceTotal({ valueInCents: costData.reallocations }),
        expandableHeader: emptyCell,
        header: () => ({
          content: <SortHeader content="Budget Reallocations" iconOnLeft sortKey="reallocations" />,
          tooltip: "Redistributed costs between line items",
          alignment: "center",
        }),
        costClass: emptyCell,
        unallocatedExpenses: emptyCell,
        expenseItem: emptyCell,
        projectItem: ({ costData }) => priceCell({ valueInCents: costData.reallocations }),
        costCode: ({ costData }) => priceCell({ valueInCents: costData.reallocations }),
        w: "240px",
      }),
    ],
  });

  const revisedInternalBudgetColumn = numericColumn<Row>({
    id: "revisedInternalBudget",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.revisedInternalBudget }),
    expandableHeader: "Revised Internal Budget",
    header: () => ({
      content: <SortHeader content="Revised Internal Budget" iconOnLeft sortKey="revisedInternalBudget" />,
      tooltip: `${initialBudget} + all accepted change events`,
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.revisedInternalBudget }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.revisedInternalBudget }),
    w: "240px",
    expandColumns: [
      numericColumn<Row>({
        id: "revisedExternalBudget",
        totals: ({ costData }) => priceTotal({ valueInCents: costData.revisedExternalBudget }),
        expandableHeader: emptyCell,
        header: {
          content: <SortHeader content="Revised External Budget" iconOnLeft sortKey="revisedExternalBudget" />,
          tooltip: `${initialBudget} + chargeable change events`,
          alignment: "center",
        },
        costClass: emptyCell,
        unallocatedExpenses: emptyCell,
        expenseItem: emptyCell,
        projectItem: ({ costData }) => priceCell({ valueInCents: costData.revisedExternalBudget }),
        costCode: ({ costData }) => priceCell({ valueInCents: costData.revisedExternalBudget }),
        w: "240px",
      }),
    ],
  });

  const committedColumn = numericColumn<Row>({
    id: "committed",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.committed }),
    expandableHeader: emptyCell,
    header: () => ({
      content: <SortHeader content="Committed" iconOnLeft sortKey="committed" />,
      tooltip: "Sum of all signed POs and expenses",
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: (data) => priceCell({ valueInCents: data.expensesTotal }),
    expenseItem: (data) => priceCell({ valueInCents: data.unallocatedAmountInCents }),
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.committed }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.committed }),
    w: "160px",
  });

  const availableColumn = numericColumn<Row>({
    id: "uncommitted",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.uncommitted }),
    expandableHeader: emptyCell,
    header: () => ({
      content: <SortHeader content="Available" iconOnLeft sortKey="uncommitted" />,
      tooltip: "Revised Internal Budget - Committed",
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.uncommitted }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.uncommitted }),
    w: "160px",
  });

  const tradeBilledColumn = numericColumn<Row>({
    id: "tradeBilled",
    totals: ({ costData }) => priceCell({ valueInCents: costData.tradeBilled }),
    expandableHeader: "Trade Billed",
    header: () => ({
      content: <SortHeader content="Trade Billed" iconOnLeft sortKey="tradeBilled" />,
      tooltip: "Approved bills for trade partners",
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: (data) => priceCell({ valueInCents: data.expensesTotal }),
    expenseItem: (row) => priceCell({ valueInCents: row.unallocatedAmountInCents }),
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.tradeBilled }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.tradeBilled }),
    w: "200px",
    expandColumns: [
      numericColumn<Row>({
        id: "tradePaidToDate",
        totals: ({ costData }) => priceCell({ valueInCents: costData.tradePaidToDate }),
        expandableHeader: "Trade Paid to Date",
        header: () => ({
          content: <SortHeader content="Trade Paid to Date" iconOnLeft sortKey="tradePaidToDate" />,
          tooltip: "Amount paid to trade partners against bills",
          alignment: "center",
        }),
        costClass: emptyCell,
        unallocatedExpenses: (data) => priceCell({ valueInCents: data.expensesTotal }),
        expenseItem: (row) => priceCell({ valueInCents: row.unallocatedAmountInCents }),
        projectItem: ({ costData }) => priceCell({ valueInCents: costData.tradePaidToDate }),
        costCode: ({ costData }) => priceCell({ valueInCents: costData.tradePaidToDate }),
        w: "200px",
      }),
    ],
  });

  const unpaidCommitmentsColumn = numericColumn<Row>({
    id: "unpaidCommitments",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.unpaidCommitments }),
    expandableHeader: emptyCell,
    header: () => ({
      content: <SortHeader content="Unpaid Commitments" iconOnLeft sortKey="unpaidCommitments" />,
      tooltip: "Unbilled amount from existing commitments, includes unapproved bills",
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.unpaidCommitments }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.unpaidCommitments }),
    w: "200px",
  });

  const projectedColumn = numericColumn<Row>({
    id: "projected",
    totals: ({ costData }) => priceTotal({ valueInCents: costData.projected }),
    expandableHeader: emptyCell,
    header: () => ({
      content: <SortHeader content="Projected" iconOnLeft sortKey="projected" />,
      tooltip: "Revised Internal Budget + Proposed Change Events + Requested Change Events",
      alignment: "center",
    }),
    costClass: emptyCell,
    unallocatedExpenses: emptyCell,
    expenseItem: emptyCell,
    projectItem: ({ costData }) => priceCell({ valueInCents: costData.projected }),
    costCode: ({ costData }) => priceCell({ valueInCents: costData.projected }),
    w: "140px",
  });

  return [
    collapseColumn<Row>({ totals: emptyCell }),
    descriptionColumn,
    ...maybeAddIdColumn(idColumn),
    originalColumn,
    awardColumn,
    changeEventsColumn,
    revisedInternalBudgetColumn,
    committedColumn,
    availableColumn,
    tradeBilledColumn,
    unpaidCommitmentsColumn,
    projectedColumn,
  ].compact();
}

type BudgetTableRowFilter = {
  costCode: InputMaybe<string[]>;
  costClassification: InputMaybe<string[]> | null;
  stage: Stage | undefined;
};

function createRows(
  data: Exclude<BudgetSummaryQueryResult["data"], undefined>,
  filter: BudgetTableRowFilter | undefined,
  setFilteredPIs: (PIs: BudgetPage_ProjectItemFragment[]) => void,
): Row[] {
  const projectItems = data.project.stages
    .filter((s) => s.hasSignedContract)
    .filter((s) => !filter?.stage || filter.stage === s.stage.code)
    .flatMap((s) => s.projectItems)
    .filter((pi) => {
      if (!filter?.costClassification && !filter?.costCode) return pi;
      return Object.entries(filter).every(([k, v]) =>
        k === "costClassification" && isDefined(v)
          ? v.includes(pi.item.costCode[k]?.code as CostClassificationType)
          : k === "costCode" && isDefined(v)
            ? v.includes(pi.item[k].id)
            : pi,
      );
    })
    .uniqueByKey("id");

  setFilteredPIs(projectItems);

  const expenseItemsRows: ExpenseItemRow[] = data.project.stages
    .filter((ps) => !filter?.stage || filter.stage === ps.stage.code)
    .flatMap((ps) => ps.unallocatedExpenses)
    .map((e) => {
      return {
        kind: "expenseItem",
        id: e.id,
        data: {
          id: e.id,
          costCode: e.costCode ? e.costCode.displayName : "",
          vendorName: e.intacctVendorName,
          description: e.description,
          unallocatedAmountInCents: e.unallocatedAmountInCents,
        },
      };
    });

  const unallocatedExpenseRows: () => UnallocatedExpensesRow = () => ({
    kind: "unallocatedExpenses" as const,
    id: "unallocatedExpenses",
    data: {
      link: createProjectExpensesUrl(data.project.id),
      expensesTotal: expenseItemsRows.sum((row) => row.data.unallocatedAmountInCents),
    },
    children: expenseItemsRows,
    pin: "first" as const,
  });

  const projectItemRows: ProjectItemRow[] = projectItems.map((pi) => {
    const {
      original,
      awarded,
      changeEvents,
      nonChargeableChangeEvents,
      chargeableChangeEvents,
      reallocations,
      proposedChangeEvents,
      revisedInternalBudget,
      revisedExternalBudget,
      committed,
      uncommitted,
      tradeBilled,
      tradePaidToDate,
      unpaidCommitments,
      projected,
    } = pi.budgetFinancials;

    return {
      kind: "projectItem",
      id: pi.id,
      data: {
        id: pi.id,
        name: pi.displayName,
        costCode: pi.item.costCode,
        costData: {
          original,
          awarded,
          changeEvents,
          nonChargeableChangeEvents,
          chargeableChangeEvents,
          reallocations,
          proposedChangeEvents,
          revisedInternalBudget,
          revisedExternalBudget,
          committed,
          uncommitted,
          tradeBilled,
          tradePaidToDate,
          unpaidCommitments,
          projected,
        },
      },
    };
  });

  const costCodeGroupBy = groupBy(projectItemRows, (pi) => pi.data.costCode.id);
  const costCodeRows: CostCodeRow[] = Object.keys(costCodeGroupBy).map((key) => {
    const projectItemRows = costCodeGroupBy[key];
    const { costCode } = projectItemRows[0].data;
    return {
      kind: "costCode",
      id: key,
      data: {
        name: costCode.displayName,
        costData: projectItemRows.map((pi) => pi.data.costData).reduce(sumCostData, {}),
        projectItemIds: projectItemRows.flatMap((pi) => pi.id),
        costClass: costCode.costClassification?.name,
      },
      children: projectItemRows,
    };
  });

  const costClassGroupBy = groupBy(costCodeRows, (cc) => cc.data.costClass || "");
  const costClassGrandParentRows: CostClassRow[] = Object.keys(costClassGroupBy).map((key, i) => {
    const costCodeParentRows: CostCodeRow[] = costClassGroupBy[key];
    return {
      kind: "costClass",
      id: String(i),
      data: {
        name: key ? `${key} Costs` : "Unassigned Cost Classification",
      },
      children: costCodeParentRows,
    };
  });

  return [
    { kind: "expandableHeader" as const, id: "expandableHeader", data: {} },
    simpleHeader,
    {
      kind: "totals" as const,
      id: "totals",
      data: {
        costData: {
          ...costCodeRows.map((cd) => cd.data.costData).reduce(sumCostData, {}),
          // For `tradePaidToDate` (aka "actuals") & `tradeBilled` calc the total costs from costcodes and unallocated expense group rows
          tradeBilled: sum(
            costCodeRows.sum((cd) => cd.data.costData.tradeBilled || 0),
            unallocatedExpenseRows().data.expensesTotal || 0,
          ),
          tradePaidToDate: sum(
            costCodeRows.sum((cd) => cd.data.costData.tradePaidToDate || 0),
            unallocatedExpenseRows().data.expensesTotal || 0,
          ),
          // For `committed` & `uncommitted` calc the total costs from costcodes and unallocated expense group rows
          committed: sum(
            costCodeRows.sum((cd) => cd.data.costData.committed || 0),
            unallocatedExpenseRows().data.expensesTotal || 0,
          ),
          uncommitted: subtract(
            costCodeRows.sum((cd) => cd.data.costData.uncommitted || 0),
            unallocatedExpenseRows().data.expensesTotal || 0,
          ),
        },
      },
    },
    ...(expenseItemsRows.nonEmpty ? [unallocatedExpenseRows()] : []),
    // 3 level nesting with cost classification -> "grand parent row", cost code -> "parent row", projectItems -> "children row"
    // Sort order Unclassified -> Soft -> Hard
    ...sortBy(costClassGrandParentRows, (cc) => cc.data.name, "DESC"),
  ];
}

function createRowStyles(
  openInDrawer: ReturnType<typeof useSuperDrawer>["openInDrawer"],
  lookup: MutableRefObject<GridRowLookup<Row> | undefined>,
  projectId: string,
): RowStyles<Row> {
  function openRow(row: GridDataRow<Row>): void {
    if (row.kind === "projectItem" || row.kind === "costCode") {
      const isPiRow = row.kind === "projectItem";
      const { prev, next } = lookup.current!.lookup(row);

      openInDrawer({
        onPrevClick: prev && (() => openRow(prev)),
        onNextClick: next && (() => openRow(next)),
        content: (
          <BudgetSuperDrawer
            projectItemIds={isPiRow ? [row.data.id] : row.data.projectItemIds}
            title={row.data.name}
            projectId={projectId}
            isPiRow={isPiRow}
          />
        ),
      });
    }
  }
  return {
    totals: { cellCss: Css.smMd.buttonBase.br0.$ },
    costClass: { cellCss: Css.smMd.buttonBase.br0.$ },
    costCode: { cellCss: Css.smMd.buttonBase.br0.$, onClick: openRow },
    projectItem: { onClick: openRow },
  };
}
