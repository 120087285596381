import {
  BoundSwitchField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  ReadyPlanOptionDetailFragment,
  SaveReadyPlanOptionInput,
  useSaveReadyPlanOptionsModalMutation,
} from "src/generated/graphql-types";
import { ArchiveOptionModalButton } from "src/routes/developments/plan-and-options/components/ArchiveOptionModalButton";
import { AddReadyPlanOption } from "./utils";

type EditElevationOptionModalProps = {
  readyPlanOption: Pick<
    ReadyPlanOptionDetailFragment,
    "id" | "active" | "name" | "description" | "homeownerChoice" | "childOptions" | "globalOption"
  >;
  fState?: ObjectState<AddReadyPlanOption>;
};

export function EditElevationOptionModal({ readyPlanOption, fState }: EditElevationOptionModalProps) {
  const { id, active, name, childOptions, globalOption } = readyPlanOption;
  const { closeModal } = useModal();
  const formState = useFormState({
    config,
    init: {
      input: readyPlanOption,
      map: ({ id, name, description, homeownerChoice }) => ({
        id,
        name,
        description,
        homeownerChoice,
      }),
    },
  });
  const [save] = useSaveReadyPlanOptionsModalMutation();

  const onSave = useCallback(
    async () => {
      const result = await save({
        variables: {
          input: {
            readyPlanOptions: [{ active: true, ...formState.changedValue }],
          },
        },
      });
      const rpoResult = result.data!.saveReadyPlanOptions.readyPlanOptions[0];
      fState?.name?.set(rpoResult.name);
      fState?.active?.set(rpoResult.active);
      fState?.globalOptionDescription?.set(rpoResult.description);
      fState?.childOptions.set(rpoResult.childOptions);
      fState?.commitChanges();
      closeModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState, fState, childOptions],
  );

  return (
    <>
      <ModalHeader>
        <div css={Css.xs.gray700.$}>{globalOption.code}</div>
        {name}
      </ModalHeader>
      <ModalBody>
        <FormLines>
          <p css={Css.baseMd.gray900.$}>Option Name</p>
          <BoundTextField
            field={formState.name}
            helperText="Changing the option name will only change it for this development"
            labelStyle="hidden"
          />
        </FormLines>
        <FormLines>
          <div css={Css.df.aic.jcsb.gap1.$}>
            <p css={Css.baseMd.gray900.$} data-testid="optionTypeNameLabel">
              Elevation
            </p>
            <BoundSwitchField label="Homeowner Choice" labelStyle="filter" field={formState.homeownerChoice} />
          </div>
        </FormLines>
        <FormLines>
          <BoundTextAreaField
            labelStyle="hidden"
            field={formState.description}
            placeholder="Add description"
            xss={Css.sm.$}
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        {active && (
          <div css={Css.mra.$}>
            <ArchiveOptionModalButton optionId={id} />
          </div>
        )}
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label={active ? "Save" : "Activate"}
              disabled={active ? !formState.dirty : false}
              onClick={onSave}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = Omit<SaveReadyPlanOptionInput, "childOptions">;

const config: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value" },
  description: { type: "value" },
  homeownerChoice: { type: "value" },
};
