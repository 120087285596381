import { useModal } from "@homebound/beam";
import { useCallback } from "react";
import { AllocateItemsTaskModal, AllocateItemsTaskProps } from "src/components/AllocateItemsTaskModal";
import { LotType } from "src/generated/graphql-types";
import { isUnallocatedCost } from "src/routes/projects/components/utils";

export type MaybeAllocateItemTaskModalProps = Pick<AllocateItemsTaskProps, "projectId" | "projectItems" | "stage"> & {
  otherwise: VoidFunction;
  lotType: LotType | undefined;
  showClickToPayFlag: boolean;
  isChangeEventPage?: boolean;
};

/** Hook to validate if item allocation should occur. Opens allocation modal if allowed,
 *  otherwise perform the original op
 */
export function useMaybeAllocateItemTaskModal() {
  const { openModal } = useModal();
  const open = useCallback(
    ({
      otherwise,
      projectId,
      lotType,
      stage,
      projectItems,
      showClickToPayFlag,
      isChangeEventPage,
    }: MaybeAllocateItemTaskModalProps) => {
      const unallocatedProjectItems = isChangeEventPage
        ? // On the CE page, celi pis can be brand new (without any allocated costs yet). For these celis we just check if underlying pis are allocated to tasks
          projectItems.filter((pi) => !pi.task?.id)
        : projectItems.filter(isUnallocatedCost);

      // If there are no unallocated items, just perform the original op, exclude HBL when showClickToPayFlag is false
      if ((lotType === LotType.Hbl && !showClickToPayFlag) || unallocatedProjectItems.isEmpty) {
        otherwise();
        return;
      }

      // Otherwise, open the modal to allocate the unallocated items
      openModal({
        content: (
          <AllocateItemsTaskModal projectItems={projectItems} onSave={otherwise} projectId={projectId} stage={stage} />
        ),
      });
    },
    [openModal],
  );

  return open;
}
