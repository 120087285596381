import { FetchResult } from "@apollo/client/link/core/types";
import { Css, RightPaneLayout, Tag, useBreakpoint, useToast } from "@homebound/beam";
import isArray from "lodash/isArray";
import { useHistory, useParams } from "react-router-dom";
import { createBillUrl, createCommitmentsUrl } from "src/RouteUrls";
import { CommentFeed, Divider } from "src/components";
import { billStatusTagMapper, getBillParentsLabel, getBillPrefix } from "src/components/detailPane/bill/utils";
import {
  BillStatus,
  ProjectFeature,
  useBillQuery,
  useDeleteBillMutation,
  useNewBillChangeOrderDataQuery,
  useNewBillCommitmentDataQuery,
  useSaveBillMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { doInNextLoop, fail, pushInNextLoop } from "src/utils";
import { isDefined } from "src/utils/arrays";
import { hasData, queryResult, renderLoadingOrError } from "src/utils/queryResult";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { useProjectContext } from "../context/ProjectContext";
import { BillEditor } from "./BillEditor";
import { BillEditorV2 } from "./BillEditorV2";

type RouteParams = {
  projectId: string;
  commitmentId: string;
  // If we're creating a new bill, this will be unset
  billId?: string;
};
export function BillPage() {
  const { projectId, billId } = useParams<RouteParams>();
  const [{ edit, changeOrderId, commitmentId, stage: stageSlug, credit }, setQueryParams] = useQueryParams({
    edit: BooleanParam,
    changeOrderId: StringParam,
    commitmentId: StringParam,
    stage: StringParam,
    credit: BooleanParam,
  });
  const isNew = !billId;
  const query = useBillQuery({ variables: { billId: billId! }, skip: isNew });
  const commitmentQuery = useNewBillCommitmentDataQuery({
    variables: { commitmentId: commitmentId! },
    skip: !(isNew && !!commitmentId),
  });
  const changeOrderQuery = useNewBillChangeOrderDataQuery({
    variables: { changeOrderId: changeOrderId! },
    skip: !(isNew && !!changeOrderId),
  });
  const [save] = useSaveBillMutation();
  const [deleteBill] = useDeleteBillMutation();
  const history = useHistory();
  const { showToast } = useToast();

  const mode = isNew ? "create" : edit ? "update" : "read";
  const { sm } = useBreakpoint();

  const bill = query.data?.bill;
  const activeProject = useProjectContext();
  const showClickToPayFlag = activeProject.features.includes(ProjectFeature.ClickToPay);
  const isCredit = isDefined(bill) ? bill.isTradePartnerCredit : !!credit;
  const label = getBillPrefix(bill, isCredit); // -> "Credit" / "Deposit" / "Bill"
  const commitmentChangeOrder = changeOrderQuery.data?.commitmentChangeOrder;
  const commitmentQueryData = commitmentQuery.data?.commitment;
  const pageTitle = bill
    ? `${label} #${bill.tradePartnerNumber} - ${getBillParentsLabel(bill.parents)}`
    : commitmentChangeOrder
      ? `Create ${label} for ${commitmentChangeOrder.commitment.name} ${commitmentChangeOrder.name} - PO ${commitmentChangeOrder.accountingNumber}`
      : commitmentQueryData
        ? `Create ${label} for ${commitmentQueryData.name} - PO ${commitmentQueryData.accountingNumber}`
        : undefined;

  if (!isNew && !hasData(query)) {
    return renderLoadingOrError(query);
  } else if (isNew && commitmentId && !hasData(commitmentQuery)) {
    return renderLoadingOrError(commitmentQuery);
  } else if (isNew && changeOrderId && !hasData(changeOrderQuery)) {
    return renderLoadingOrError(changeOrderQuery);
  }

  const parentEntity = isNew
    ? commitmentId
      ? commitmentQuery.data!.commitment
      : changeOrderQuery.data!.commitmentChangeOrder
    : query.data!.bill.parents;

  const billParentLineItems = isArray(parentEntity)
    ? parentEntity.flatMap((parent) => parent.lineItems)
    : parentEntity.lineItems;

  // If this is a new bill, then grab the commitment object either from the commitment query or the change order query.
  const commitment = isNew
    ? commitmentId
      ? commitmentQuery.data!.commitment
      : changeOrderQuery.data!.commitmentChangeOrder.commitment
    : undefined;

  const tradePartnerId = !isNew ? query.data!.bill.tradePartner.id : commitment!.tradePartner!.id;
  const paymentTerm = !isNew ? query.data!.bill.tradePartner.paymentTerm : commitment!.tradePartner!.paymentTerm;
  const project = !isNew ? bill?.projectStage.project : undefined;
  const projectStageId = isNew ? commitment!.projectStage!.id : query.data!.bill.projectStage!.id;
  const parentInternalNote = commitmentQuery.data
    ? commitmentQuery.data.commitment.internalNote
    : changeOrderQuery.data?.commitmentChangeOrder.internalNote;

  function onCancel() {
    if (mode === "update") {
      setQueryParams({}, "push");
    } else {
      history.push(createCommitmentsUrl(projectId));
    }
  }

  async function onDelete() {
    await deleteBill({ variables: { input: { id: billId || fail("Missing bill id") } } });
    showToast({ message: `Bill #${bill?.tradePartnerNumber} successfully deleted`, type: "success" });
    history.push(createCommitmentsUrl(projectId));
  }

  async function onReverse() {
    await save({ variables: { input: { id: billId || fail("Missing bill id"), status: BillStatus.Reversed } } });
    // Refetch the bill to get the updated status
    await query.refetch();
    showToast({
      message: "The Bill Reversal process has been initiated. Please refresh the page to check the current status.",
      type: "info",
    });
  }

  // If the user changes the URL to have a different project ID than the one the bill is attached to
  // we want to redirect them back to the commitments page for that project.
  if (project && projectId !== project.id) {
    history.replace(createCommitmentsUrl(projectId));
    return null;
  }

  function renderCommentFeed() {
    return queryResult(query, {
      data: (data) => {
        return (
          <div>
            <Divider xss={Css.mt5.$} />
            <CommentFeed commentable={data.bill} />
          </div>
        );
      },
    });
  }

  const [type, text] = (isDefined(bill) && billStatusTagMapper(bill)) || [];

  return (
    <>
      <PageHeader
        title={pageTitle}
        breadcrumb={{ href: createCommitmentsUrl(projectId), label: "Commitments" }}
        // Hide bill status tag in header, for mobile devices. (status tag is included in detail section)
        left={sm ? undefined : <Tag type={type} text={text || ""} />}
      />
      {showClickToPayFlag ? (
        <RightPaneLayout>
          <BillEditorV2
            bill={bill}
            tradePartnerId={tradePartnerId}
            paymentTerm={paymentTerm}
            currentUserId={query.data?.currentInternalUser?.id || ""}
            billParentLineItems={billParentLineItems}
            mode={mode}
            project={project}
            projectId={projectId}
            onCancel={onCancel}
            projectStageId={projectStageId}
            parentInternalNote={parentInternalNote ?? undefined}
            renderCommentFeed={isNew ? undefined : renderCommentFeed}
            onSave={async (input, triggerRedirect) => {
              let billSaveData: FetchResult = {};
              if (input) {
                billSaveData = await save({
                  variables: { input },
                });
              }
              if (triggerRedirect) {
                const billUrl = createBillUrl(billSaveData && billSaveData?.data?.saveBill?.bill.id);
                pushInNextLoop(history, billUrl);
                return;
              }
              await query.refetch();
            }}
            onDelete={onDelete}
            onReverse={onReverse}
            onEdit={() => setQueryParams({ edit: true })}
            isCredit={isCredit}
          />
        </RightPaneLayout>
      ) : (
        <BillEditor
          bill={bill}
          tradePartnerId={tradePartnerId}
          paymentTerm={paymentTerm}
          currentUserId={query.data?.currentInternalUser?.id || ""}
          billParentLineItems={billParentLineItems}
          mode={mode}
          project={project}
          projectId={projectId}
          onCancel={onCancel}
          projectStageId={projectStageId}
          parentInternalNote={parentInternalNote ?? undefined}
          renderCommentFeed={isNew ? undefined : renderCommentFeed}
          onSave={async (input, triggerRedirect) => {
            let billSaveData: FetchResult = {};
            if (input) {
              billSaveData = await save({ variables: { input } });
            }
            if (triggerRedirect) {
              const billUrl = createBillUrl(billSaveData && billSaveData?.data?.saveBill?.bill.id);
              // Clear out location state so that we don't redirect to the same bill again.
              doInNextLoop(() => history.push(billUrl, null));
              return;
            }
            await query.refetch();
          }}
          onDelete={onDelete}
          onReverse={onReverse}
          onEdit={() => setQueryParams({ edit: true })}
          isCredit={isCredit}
        />
      )}
    </>
  );
}
