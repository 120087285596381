import { GridDataRow, simpleHeader } from "@homebound/beam";
import { Maybe, ProjectItem, SpecsAndSelections_ProductFragment } from "src/generated/graphql-types";
import { ProjectItemStore, SpecsAndSelectionsRow } from "src/routes/projects/models/ProjectItemStore";
import { DeepPartial } from "src/utils";

export function createSpecsAndSelectionRows(
  piStore: ProjectItemStore,
  showPlanSource: boolean,
): GridDataRow<SpecsAndSelectionsRow>[] {
  return [
    { kind: "totals" as const, id: "totals", data: piStore },
    simpleHeader,
    ...piStore.groupedBy.map((group) => ({
      kind: "group" as const,
      id: group.id,
      data: group,
      children: group.children.map((pi) => ({
        kind: "projectItem" as const,
        id: pi.id,
        data: pi,
        children: showPlanSource
          ? [pi.fragment.itemTemplateItem, ...pi.fragment.modifyingTemplateItems].compact().map((li, i) => ({
              kind: "lineItem" as const,
              id: `${pi.id}-${li.id}-${i}`,
              data: li,
            }))
          : [],
      })),
      // sortValue defined for each ObservableProjectItemGroup in ProjectItemStore
      sortValue: group.sortValue,
    })),
  ];
}

export function getFinish(product: Maybe<SpecsAndSelections_ProductFragment>): string | undefined {
  // the length check suppresses a `[mobx] Out of bounds read: 0` error in tests, which still shows even with optional chaining
  // this will either contain one finish attribute or be undefined via filtering (see SpecsAndSelectionsPage.graphql)
  return product?.attributes.length ? product.attributes.map((a) => a.value).join(", ") : undefined;
}

export function isSelection(projectItem: DeepPartial<ProjectItem>): boolean {
  return projectItem.homeownerSelection?.id !== undefined;
}

export function isUnallocatedCost(projectItem: DeepPartial<ProjectItem>): boolean {
  return !projectItem.task?.id && projectItem.totalCostInCents !== 0;
}
