import React from "react";
import { AvailableGlobalPhasesQuery, GlobalPhaseDetailsFragment } from "src/generated/graphql-types";
import { keyBy, sortBy, unique } from "src/utils";

type GlobalPhaseMetaData = {
  globalPhase: GlobalPhaseDetailsFragment;
  schedulePhaseId?: string | undefined;
};

/**
 * NOTE: For now, label and value will be the same. We need an object for the sake
 * of the Create New SubPhase feature to work correctly.
 *
 * Also, eventually we may want to store additional information in here, so nice to have the object ready.
 */
export type GlobalSubPhaseMetaData = {
  label: string;
  value: string;
};

/**
 * globalPhases: List of all GlobalPhases for the current stage, keyed by GlobalPhase.id, and the ID of the associated SchedulePhase, if one exists
 * globalSubPhases: List of unique GlobalSubPhases names in the current stage
 */
export type SchedulePhaseContextProps = {
  globalPhases: Record<string, GlobalPhaseMetaData>;
  globalSubPhases: GlobalSubPhaseMetaData[];
};

export const SchedulePhaseContext = React.createContext<SchedulePhaseContextProps>(
  queryResultToSchedulePhaseContextProps(),
);

export function queryResultToSchedulePhaseContextProps(data?: AvailableGlobalPhasesQuery): SchedulePhaseContextProps {
  if (!data) {
    return {
      globalPhases: {},
      globalSubPhases: [],
    };
  }

  const { globalPhases: globalPhasesFromQuery, schedulePhases } = data;

  const globalPhases = keyBy(
    globalPhasesFromQuery,
    (p) => p.id,
    (globalPhase) => ({ globalPhase, schedulePhaseId: undefined, scheduleSubPhases: [] }) as GlobalPhaseMetaData,
  );
  schedulePhases.forEach((sp) => {
    globalPhases[sp.globalPhase.id].schedulePhaseId = sp.id;
  });
  const globalSubPhases = sortBy(
    unique(schedulePhases.flatMap((sp) => sp.scheduleSubPhases.map((ssp) => ssp.globalSubPhase.name))),
    (gsp) => gsp,
  ).map((name) => ({ label: name, value: name }));

  return { globalPhases, globalSubPhases };
}
