import { QueryResult } from "@apollo/client";
import { useEffect } from "react";
import { usePageVisibility } from "./usePageVisibility";

/** Listens for page visibility and continue polling while the page is visible */
export function usePollIfVisible<T>(query: QueryResult<T, any>, interval: number) {
  const isVisible = usePageVisibility();

  useEffect(() => {
    if (isVisible) {
      query.startPolling(interval);
    } else {
      query.stopPolling();
    }
  }, [interval, isVisible, query]);
}
