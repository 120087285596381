import { datadogLogs, LogsInitConfiguration } from "@datadog/browser-logs";
import { datadogRum, DefaultPrivacyLevel, RumInitConfiguration } from "@datadog/browser-rum";
import * as FullStory from "@fullstory/browser";
import { getGraphQLBaseUrl, getStage } from "src/context";

/* Since Stage is meaningful in the GraphQL context as well, grab off the ReturnType vs. exporting from context.ts */
type Stage = ReturnType<typeof getStage>;
type FsWindow = { FS?: { getCurrentSession?: () => string | null }; location: Pick<Location, "pathname"> };

/** Enable third-party analytics tools */
export async function enableAnalytics(stage = getStage(), _fsWindow: FsWindow = window as FsWindow) {
  // This method is somewhat complex because of this issue with Datadog:
  // https://github.com/DataDog/browser-sdk/issues/1397
  // To work around that problem, we wait for FullStory to be fully set up before we start Datadog session recording.

  // Since datadog also tracks JS errors, we want to enable it (without starting session replay recording) ASAP, even
  // before FullStory is fully ready. Then, once it's ready (or times out), we start Datadog recording.
  const waitForFullstoryPromise = enableFullStory(stage, _fsWindow);
  const datadogEnabled = enableDatadog(stage);
  await waitForFullstoryPromise;

  if (datadogEnabled) {
    datadogRum.startSessionReplayRecording();
  }
}

export function setAnalyticsUser(user: { id?: string; email?: string; name?: string }): void {
  const { id, email, name } = user;
  // Attach user identity to FullStory; should be run 1x on every full page load, per:
  // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
  const { FS } = window as any;
  if (id && FS) {
    FS.identify(`internal_users_${id}`, { displayName: name, email });
  }

  // Identify user with Datadog Real User Monitoring
  // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-sessions
  if (id) {
    datadogRum.setUser({ id, name, email });
    datadogLogs.setUser({ id, name, email });
  } else {
    datadogRum.removeUser();
    // There isn't a datadogLogs.removeUser, so remove each key
    ["id", "name", "email"].forEach((key) => datadogLogs.removeUserProperty(key));
  }
}

/**
 * This function enables FullStory via their @fullstory/browser package. The `init` call pulls the script at runtime,
 * so it's not deterministic as to when FullStory is actually up and running. We have a helper method that polls
 * the FS global to determine if it's fully initialized.
 */
async function enableFullStory(stage: Stage, _fsWindow: FsWindow): Promise<boolean> {
  if (stage !== "prod") {
    return false;
  }

  // If the _landing page on load_ is to a disabled route, don't initialize FullStory.
  // Note that this disables FullStory for the entire session. The case of the PDF route, as an example, this is loaded
  // by headless Chrome, so we don't want to initialize FullStory for that.
  const disabledRoutes = ["/pdf"];
  if (disabledRoutes.some((route) => _fsWindow.location.pathname.startsWith(route))) {
    return false;
  }

  FullStory.init({ orgId: "P2TFT" });
  return waitForFullStoryToInit({ interval: 200, maxTimeToWait: 10_000 }, _fsWindow);
}

/**
 * This method waits for FullStory to fully initialize. The main reason we're doing this is because of the Datadog
 * issue mentioned in `enableAnalytics`. If that upstream issue is resolved, this all gets much much simpler :)
 */
async function waitForFullStoryToInit(
  { interval, maxTimeToWait }: { interval: number; maxTimeToWait: number },
  _fsWindow: FsWindow,
): Promise<boolean> {
  return new Promise(async (resolve) => {
    const maxIntervals = maxTimeToWait / interval;

    for (let i = 0; i < maxIntervals; i++) {
      // We're using the global FS here instead of the @fullstory/browser `getCurrentSessionURL` method because it prints
      // a console.warn statement each time we poll and the package isn't ready. This creates a lot of noise in the console
      // so we don't use it.
      if (_fsWindow.FS && _fsWindow.FS.getCurrentSession && _fsWindow.FS.getCurrentSession() !== null) {
        resolve(true);
        return;
      } else {
        await wait(interval);
      }
    }

    console.info(
      `FullStory did not initialize in ${maxTimeToWait / 1000} seconds. An ad-blocker likely blocked the request.`,
    );
    resolve(false);
  });
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const DATADOG_IGNORED_ERROR_MESSAGES = /ResizeObserver loop/;
function enableDatadog(stage: Stage): boolean {
  if (stage === "local") {
    return false;
  }

  const commonOps: Omit<RumInitConfiguration, "applicationId"> & LogsInitConfiguration = {
    clientToken: "pubdc77de404dd21254bc21540990ce1e3c",
    site: "datadoghq.com",
    service: "internal-frontend",
    env: stage,
    version: VITE_GIT_COMMIT ?? "local",
    // Our tests call init multiple times to test our setup logic
    silentMultipleInit: true,
  };

  // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    ...commonOps,
    applicationId: "75b6767e-587c-4c6f-9354-8157c2d9173d",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    allowedTracingUrls: [getGraphQLBaseUrl()],
    beforeSend: (event) => {
      // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#enrich-and-control-rum-data
      if (event.type === "error" && DATADOG_IGNORED_ERROR_MESSAGES.test(event.error.message)) {
        return false;
      }
    },
    defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
    enableExperimentalFeatures: [
      "clickmap", // enable heatmap (https://docs.datadoghq.com/real_user_monitoring/heatmaps/#prerequisites)
    ],
  });

  datadogLogs.init({
    ...commonOps,
    forwardConsoleLogs: [
      // Remember that we pay per-log ingested. You should likely avoid reporting debug-level log messages
      // unless you're actively debugging a major, global issue.
      // "debug",
      "info",
      "warn",
      "error",
    ],
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: (event) => {
      // Abort errors are not necessarily error in the app. They are typically triggered by the user navigating away from
      // a page and causing an in-flight request to be canceled. We don't want to report these as errors.
      if (event.error && event.error.stack?.startsWith("AbortError:")) {
        return false;
      }
      return !DATADOG_IGNORED_ERROR_MESSAGES.test(event.message);
    },
  });

  return true;
}
