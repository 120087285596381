import { Css, Tooltip, useResponsiveGridItem } from "@homebound/beam";
import { useParams } from "react-router";
import { ProgressPill } from "src/components";
import {
  ProgressSummaryStatus,
  RecentActivityRail_PhaseFragment,
  RecentActivityRail_SubPhaseFragment,
  useRecentActivityRailQuery,
} from "src/generated/graphql-types";
import { EmptyTile } from "src/routes/projects/dashboard/components/EmptyTile";
import { ProjectParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { formatMonthDayYear } from "src/utils/dates";

export function RecentActivityRail() {
  const { projectId } = useParams<ProjectParams>();
  // Using `cache-and-network` to avoid reverting to the loading state as this rail is used on multiple tabs in the Project Dashboard.
  const query = useRecentActivityRailQuery({ variables: { projectId }, fetchPolicy: "cache-and-network" });
  const { gridItemProps } = useResponsiveGridItem({ colSpan: 1 });

  return (
    <div css={Css.bgWhite.px2.py3.br8.oa.bshBasic.$} {...gridItemProps}>
      <h2 css={Css.base.mb1.$}>Recent Activity</h2>
      {queryResult(query, ({ project }) => {
        const inProgressPhases = project?.schedules
          .flatMap((s) => s.phases)
          .filter((p) => p?.progress.taskProgress.status === ProgressSummaryStatus.InProgress);

        if (inProgressPhases?.isEmpty) {
          return (
            <div css={Css.mhPx(160).df.fdc.$}>
              <EmptyTile>No recent activity</EmptyTile>
            </div>
          );
        }

        return <div css={Css.addIn("> div + div", Css.bt.bcGray400.$).$}>{inProgressPhases?.map(phaseRow)}</div>;
      })}
    </div>
  );
}

function phaseRow(phase: RecentActivityRail_PhaseFragment) {
  const { total, completed } = phase.progress.taskProgress;
  const percentCompleted = Math.round((completed / total) * 100);
  return (
    <div key={phase.id} css={Css.py2.df.fdc.gap2.$}>
      <div css={Css.df.gap1.jcsb.$}>
        <div css={Css.sm.gray900.$} data-testid="phase">
          {phase.name}
        </div>
        <div css={Css.df.gapPx(4).aic.$}>
          <div css={Css.tinyMd.gray900.$} data-testid="phase_percentCompleted">{`${percentCompleted}%`}</div>
          <ProgressPill progress={percentCompleted} hideProgress />
        </div>
      </div>
      {phase.scheduleSubPhases
        .filter((sp) => sp.progress.taskProgress.status === ProgressSummaryStatus.InProgress)
        .map(subPhaseRow)}
    </div>
  );
}

function subPhaseRow(subPhase: RecentActivityRail_SubPhaseFragment) {
  const { total, completed } = subPhase.progress.taskProgress;
  const { endDate } = subPhase.interval ?? {};
  const completedOverTotalEl = <div css={Css.fs0.$} data-testid="subPhase_progress">{`${completed} / ${total}`}</div>;
  return (
    <div key={subPhase.id} css={Css.df.jcsb.ais.gap1.sm.gray700.$}>
      <span data-testid="subPhase">{subPhase.name}</span>
      {endDate ? (
        <Tooltip title={`Expected completion ${formatMonthDayYear(endDate)}`} placement="left">
          {completedOverTotalEl}
        </Tooltip>
      ) : (
        completedOverTotalEl
      )}
    </div>
  );
}
