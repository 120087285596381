import {
  BoundDateField,
  BoundNumberField,
  BoundSelectField,
  ButtonMenu,
  CollapseToggle,
  Css,
  GridDataRow,
  GridRowLookup,
  GridTable,
  Icon,
  IconButton,
  Loader,
  Palette,
  PresentationProvider,
  RightPaneLayout,
  RowStyles,
  ScrollableContent,
  Tooltip,
  actionColumn,
  collapseColumn,
  column,
  dateColumn,
  emptyCell,
  getTableStyles,
  maybeTooltip,
  useComputed,
  useGridTableApi,
  useModal,
  useRightPane,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { addWeeks, isSameDay, isWithinInterval } from "date-fns";
import debounce from "lodash/debounce";
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { createMilestoneCatalogFormUrl, createMilestoneDetailsPageUrl } from "src/RouteUrls";
import { CommentCountBubble, Icon as LegacyBpIcon, ProgressPill, emptyCellDash, formatDate } from "src/components";
import { StatusIndicator } from "src/components/StatusIndicator";
import { useFeatureFlag } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  DayOfWeek,
  DraftModeEnumDetails_EnumDetailsFragment,
  DraftPlanTaskInput,
  FeatureFlagType,
  InputMaybe,
  Maybe,
  PlanTasksFilter,
  ScheduleDraftMode_PlanMilestoneFragment,
  ScheduleDraftMode_PlanTaskFragment,
  SchedulingExclusionDatesFragment,
  TaskStatus,
  TradePartnerAvailabilityRequestStatus,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { DynamicScheduleSidePane } from "src/routes/projects/dynamic-schedules/components/DynamicScheduleSidePane";
import { SendTradePartnerAvailabilityRequestEmailModal } from "src/routes/projects/dynamic-schedules/components/SendTradePartnerAvailabilityRequestEmailModal";
import { ScheduleDraftModePlanTaskSetDaysModal } from "src/routes/projects/dynamic-schedules/draft-mode/ScheduleDraftModePlanTaskSetDaysModal";
import { TaskDetailCardType } from "src/routes/projects/dynamic-schedules/task-details/TaskDetailCard";
import { assertNever, isDefined, newComparator, noop, pluralize } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { TaskStatusSelect } from "../../schedule-v2/components/TaskStatusSelect";
import { CustomDynamicSchedulesFilter, mapToFilter } from "../components/DynamicSchedulesFilterModal";
import { TaskGroupBy } from "../components/DynamicSchedulesGroupBy";
import {
  DayOfWeekDayPicker,
  DynamicSchedulesMilestoneRow,
  DynamicSchedulesRow,
  DynamicSchedulesTaskRow,
  ScheduleContainer,
  createCostCodeRows,
  createStageRows,
  createTaskAndMilestoneRows,
} from "../utils";
import { DebugTooltip } from "./DebugTooltip";
import { ScheduleDraftModeDelayFlagModal } from "./ScheduleDraftModeDelayFlagModal";
import { ScheduleDraftModeDeleteFlagModal } from "./ScheduleDraftModeDeleteFlagModal";
import { useDraftScheduleStore } from "./scheduleDraftStore";

type DraftScheduleTableProps = {
  planTasks: ScheduleDraftMode_PlanTaskFragment[];
  planMilestones: ScheduleDraftMode_PlanMilestoneFragment[];
  enumDetails: DraftModeEnumDetails_EnumDetailsFragment;
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
  loading: boolean;
  isLookaheadView: boolean;
  groupBy: TaskGroupBy;
  scheduleParentId: string;
  hasDraftChanges: boolean;
};

// TODO: Still need
//  - separate comment bubble icon to own column and rewrite the logic (should be simpler)
//  - add right border to name column when pane is opened for a better UX
//    - will require lots of work to do this on our side. Better to add a prop to column definitions, like `cellStyleWhenSticky`
//    or w/e
export function DraftScheduleTable(props: DraftScheduleTableProps) {
  const {
    planTasks,
    planMilestones,
    enumDetails,
    schedulingExclusionDates,
    loading,
    isLookaheadView,
    groupBy = TaskGroupBy.None,
    scheduleParentId,
    hasDraftChanges,
  } = props;
  const { openRightPane } = useRightPane();
  // TODO: add the taskId as a param so we can deep-link to a schedule with the task sidebar open (and persist sidebar
  //  state between a refresh)? (this is how the old schedule worked, especially since there was no full task detail
  //  page)
  const [, setQueryParam] = useQueryParams({
    scrollIntoView: StringParam,
  });
  const isTradeCommsEnabled = useFeatureFlag(FeatureFlagType.DynamicSchedulesTradeComms);
  const [{ debug }] = useQueryParams({ debug: BooleanParam });
  const lastUpdatedTaskId = useDraftScheduleStore((state) => state.lastUpdatedTaskId);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const history = useHistory();

  const [paneTaskId, setPaneTaskId] = useState<string>();

  const onTaskDetailLink = useCallback(
    (taskData: ScheduleDraftMode_PlanTaskFragment, scrollIntoView?: TaskDetailCardType) => {
      setPaneTaskId(taskData.id);
      scrollIntoView && setQueryParam({ scrollIntoView });
      openRightPane({
        content: (
          <DynamicScheduleSidePane
            onClose={() => {
              setQueryParam({ scrollIntoView: undefined });
              setPaneTaskId(undefined);
            }}
            draftTask={taskData}
          />
        ),
      });
    },
    [openRightPane, setQueryParam],
  );

  const api = useGridTableApi<DynamicSchedulesRow>();

  const { getFormState } = useFormStates<FormInput, ScheduleDraftMode_PlanTaskFragment>({
    config: formConfig,
    map: (task) => mapToForm(task),
    getId: (t) => t.id,
    autoSave: async (fs) => {
      // Though we have implemented our own draft-mode specific autoSave logic, we still want the formState to reset the "dirty"/"touched" states
      // as if we were using the default autoSave behavior. This ensures we don't get caught will stale input values when user updates an input which later
      // gets overridden by a subsequent change/server response.
      fs.commitChanges();
    },
  });

  const getTaskFormState = useCallback(
    (task: ScheduleDraftMode_PlanTaskFragment) =>
      getFormState(task, {
        readOnly: task.status.code === TaskStatus.Complete,
      }),
    [getFormState],
  );

  const styles = getTableStyles({ allWhite: true });
  const activeRowId = useMemo(
    () => (lastUpdatedTaskId ? `task_${lastUpdatedTaskId}` : paneTaskId ? `task_${paneTaskId}` : undefined),
    [lastUpdatedTaskId, paneTaskId],
  );
  const lookup = useRef<GridRowLookup<DynamicSchedulesRow>>();

  const rowStyles: RowStyles<DynamicSchedulesRow> = useMemo(
    () => ({
      lookahead: {
        cellCss: Css.bgGray100.$,
      },
      milestone: {
        onClick: (row: DynamicSchedulesMilestoneRow) =>
          history.push(createMilestoneDetailsPageUrl(scheduleParentId, row.data.id)),
        cellCss: (milestone: DynamicSchedulesMilestoneRow) => Css.if(milestone.data.progress === 100).bgGray200.$,
      },
      task: {
        onClick: (row: GridDataRow<DynamicSchedulesTaskRow>) => onTaskDetailLink(row.data),
        cellCss: (task: DynamicSchedulesTaskRow) => Css.if(task.data.status.code === TaskStatus.Complete).bgGray200.$,
      },
    }),
    [history, onTaskDetailLink, scheduleParentId],
  );

  const rows = useMemo(
    () => createRows(planTasks, planMilestones, isLookaheadView, groupBy),
    [planTasks, planMilestones, isLookaheadView, groupBy],
  );

  const columns = useMemo(
    () =>
      createColumns(
        getTaskFormState,
        debug,
        loading,
        enumDetails,
        schedulingExclusionDates,
        planTasks,
        groupBy,
        isTradeCommsEnabled,
        onTaskDetailLink,
        hasDraftChanges,
        setDraftTaskChanges,
      ),
    [
      getTaskFormState,
      debug,
      loading,
      enumDetails,
      schedulingExclusionDates,
      planTasks,
      groupBy,
      isTradeCommsEnabled,
      onTaskDetailLink,
      hasDraftChanges,
      setDraftTaskChanges,
    ],
  );

  useEffect(() => {
    // Scroll to the first active task or milestone
    if (lookup.current) {
      const currentList = lookup.current.currentList();
      const firstActive = currentList.find(
        (r) =>
          (r.kind === "task" && r.data.status.code !== TaskStatus.Complete) ||
          (r.kind === "milestone" && (!r.data.progress || r.data.progress < 100)),
      );
      if (firstActive) api.scrollToIndex(currentList.indexOf(firstActive));
    }
  }, [lookup, api]);

  useEffect(() => {
    // Scroll to the last updated task
    if (lastUpdatedTaskId) {
      const index = rows.findIndex((r) => r.id === lastUpdatedTaskId);
      if (index !== -1) {
        api.scrollToIndex(index);
      }
    }
  }, [lastUpdatedTaskId, rows, api]);

  return (
    <ScrollableContent virtualized>
      <ScheduleContainer>
        <RightPaneLayout>
          <GridTable
            rowStyles={rowStyles}
            as="virtual"
            api={api}
            columns={columns}
            rows={rows}
            stickyHeader={true}
            activeRowId={activeRowId}
            style={{
              ...styles,
              rootCss: {
                ...styles.rootCss,
                ...Css.pbPx(50).$,
              },
            }}
            rowLookup={lookup}
          />
        </RightPaneLayout>
      </ScheduleContainer>
    </ScrollableContent>
  );
}

function createColumns(
  getFormState: GetFormState,
  debugMode: Maybe<boolean>,
  loading: boolean,
  enumDetails: DraftModeEnumDetails_EnumDetailsFragment,
  schedulingExclusionDates: SchedulingExclusionDatesFragment[],
  tasks: ScheduleDraftMode_PlanTaskFragment[],
  groupBy: TaskGroupBy,
  isTradeCommsEnabled: boolean,
  onTaskDetailLink: (taskId: ScheduleDraftMode_PlanTaskFragment, scrollIntoView?: TaskDetailCardType) => void,
  hasDraftChanges: boolean,
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void,
) {
  const holidayExcludedDates = schedulingExclusionDates.map((exclusionDate) => exclusionDate.date);

  return [
    ...(groupBy !== TaskGroupBy.None
      ? [
          collapseColumn<DynamicSchedulesRow>({
            header: emptyCell,
            group: (_, { row }) => <CollapseToggle row={row} />,
            task: emptyCell,
            w: "40px",
          }),
        ]
      : []),
    actionColumn<DynamicSchedulesRow>({
      header: emptyCell,
      lookahead: emptyCell,
      group: emptyCell,
      milestone: () => ({
        content: () =>
          loading ? (
            <Loader size="xs" />
          ) : (
            <Tooltip title="Milestone">
              <LegacyBpIcon icon="signpost" color={Palette.Gray900} />
            </Tooltip>
          ),
      }),
      task: (task) => <IconCell task={task} loading={loading} />,
      w: "48px",
      sticky: "left",
    }),
    column<DynamicSchedulesRow>({
      header: "Name",
      lookahead: ({ name, startDate, endDate }) => ({
        content: <span css={Css.lgSb.gray900.$}>{name}</span>,
        tooltip:
          name === "6 Weeks"
            ? `Tasks set to start on or after ${formatDate(startDate, "weekDayMonthShort")}`
            : `Tasks set to start between ${formatDate(startDate, "weekDayMonthShort")} - ${formatDate(endDate, "weekDayMonthShort")}`,
      }),
      group: ({ name }) => ({
        content: <span css={Css.smSb.w100.$}>{name}</span>,
        colspan: 4,
      }),
      milestone: ({ name }) => ({
        content: () => <span css={Css.xsSb.$}>{name}</span>,
        value: name,
      }),
      task: ({ name }) => <span css={Css.xs.maxwPx(300).$}>{name}</span>,
      mw: "200px",
      w: 3,
      sticky: "left",
    }),
    column<DynamicSchedulesRow>({
      header: "Trade",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: (milestone) => ({
        colspan: 2,
        revealOnRowHover: true,
        content: () => (
          <div css={Css.df.gap3.w100.$}>
            <div css={Css.df.$}>Milestone Completion:</div>
            <div>
              <ProgressPill fixedWidthPx={150} changeColorOnCompleted progress={milestone.progress} />
            </div>
          </div>
        ),
        value: undefined,
      }),
      task: (task) => ({
        content: () => <span css={Css.xs.lineClamp2.pr1.$}>{task.tradePartner?.name ?? "-"}</span>,
      }),
      mw: "125px",
      w: 2,
    }),
    column<DynamicSchedulesRow>({
      header: "Trade Status",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: emptyCell,
      task: (task) => ({
        content: () => (
          <TradeCell
            stopEventPropagation={stopEventPropagation}
            task={task}
            getFormState={getFormState}
            enumDetails={enumDetails}
          />
        ),
      }),
      clientSideSort: false,
      // Note: 205 is the sweet spot for the hacked select field
      mw: "205px",
    }),
    column<DynamicSchedulesRow>({
      header: "Status",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: emptyCell,
      task: (task) => (
        // Todo figure out the height issue
        <div css={Css.maxhPx(32).$}>
          <StatusInput
            stopEventPropagation={stopEventPropagation}
            task={task}
            getFormState={getFormState}
            loading={loading}
            enumDetails={enumDetails}
          />
        </div>
      ),
      w: "150px",
    }),
    column<DynamicSchedulesRow>({
      header: "Start",
      group: emptyCell,
      lookahead: emptyCell,
      milestone: ({ estStartDate, id }) => ({
        content: () =>
          estStartDate?.date ? (
            <Tooltip title={formatDate(estStartDate?.date, "long")}>
              <span>{formatDate(estStartDate?.date, "weekDayMonthShort")}</span>
            </Tooltip>
          ) : (
            emptyCellDash
          ),
        value: estStartDate?.date,
      }),
      task: (task) => (
        <StartDateInput
          stopEventPropagation={stopEventPropagation}
          task={task}
          getFormState={getFormState}
          loading={loading}
          holidayExcludedDates={holidayExcludedDates}
          setDraftTaskChanges={setDraftTaskChanges}
          tasks={tasks}
        />
      ),
      w: "180px",
    }),
    dateColumn<DynamicSchedulesRow>({
      header: "End",
      group: emptyCell,
      lookahead: emptyCell,
      milestone: ({ estEndDate, id }) => ({
        content: () => (
          <>
            <Tooltip title={formatDate(estEndDate?.date, "long")}>{formatDate(estEndDate?.date, "monthShort")}</Tooltip>
          </>
        ),
        value: estEndDate?.date,
      }),
      task: (task) => (
        <EndDateInput
          stopEventPropagation={stopEventPropagation}
          task={task}
          getFormState={getFormState}
          loading={loading}
          holidayExcludedDates={holidayExcludedDates}
        />
      ),
      w: "120px",
    }),
    column<DynamicSchedulesRow>({
      header: "Duration",
      lookahead: emptyCell,
      group: emptyCell,
      milestone: ({ durationInDays }) => ({
        content: () => (
          <span>
            {durationInDays} {pluralize(durationInDays, "day", "days")}
          </span>
        ),
        value: durationInDays,
      }),
      task: (task) => (
        <DurationInput
          stopEventPropagation={stopEventPropagation}
          task={task}
          getFormState={getFormState}
          loading={loading}
        />
      ),
      w: "150px",
    }),
    actionColumn<DynamicSchedulesRow>({
      header: emptyCell,
      group: emptyCell,
      lookahead: emptyCell,
      milestone: (milestone) => <MilestoneActionCell milestone={milestone} hasDraftChanges={hasDraftChanges} />,
      task: (task) => (
        <>
          <DebugTooltip debugMode={debugMode ?? false} task={task} />
          <TaskIconCell
            task={task}
            isTradeCommsEnabled={isTradeCommsEnabled}
            onTaskDetailLink={onTaskDetailLink}
            hasDraftChanges={hasDraftChanges}
            getFormState={getFormState}
          />
        </>
      ),
      align: "center",
      w: "80px",
    }),
  ];
}

export function MilestoneActionCell({
  milestone,
  hasDraftChanges,
}: {
  milestone: ScheduleDraftMode_PlanMilestoneFragment;
  hasDraftChanges: boolean;
}) {
  const { openModal } = useModal();
  const history = useHistory();

  return (
    <IconButton
      icon="linkExternal"
      inc={2.3}
      disabled={hasDraftChanges && "Save schedule changes first before editing global milestones"}
      onClick={() => {
        openModal({
          content: (
            <ConfirmationModal
              confirmationMessage={
                <div css={Css.sm.$}>
                  This milestone is used in all other projects. Editing this milestone will update it everywhere. Would
                  you like to proceed with editing the milestone?
                </div>
              }
              label="Yes, Edit Milestone"
              onConfirmAction={() => history.push(createMilestoneCatalogFormUrl(milestone.id))}
              title="Edit Milestone"
            />
          ),
        });
      }}
      tooltip="Edit Global Milestone"
    />
  );
}

function TaskIconCell({
  task,
  isTradeCommsEnabled,
  onTaskDetailLink,
  hasDraftChanges,
  getFormState,
}: {
  task: ScheduleDraftMode_PlanTaskFragment;
  isTradeCommsEnabled: boolean;
  onTaskDetailLink: (task: ScheduleDraftMode_PlanTaskFragment, scrollIntoView?: TaskDetailCardType) => void;
  hasDraftChanges: boolean;
  getFormState: GetFormState;
}) {
  const { streams, id } = task;
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setUserAddedDelayFlags = useDraftScheduleStore((state) => state.setUserAddedScheduleFlags);

  const onTaskDelete = useCallback(
    (task: ScheduleDraftMode_PlanTaskFragment) => {
      const doDelete = () => {
        setDraftTaskChanges([{ id: task.id, delete: true }]);
        triggerNotice({ message: "1 task deleted" });
      };

      if (task.canDeleteWithWarning.allowed) {
        return doDelete();
      }

      const warning = task.canDeleteWithWarning.disabledReasons.last?.message;

      openModal({
        content: (
          <ConfirmationModal
            title="Warning"
            confirmationMessage={`${warning} Are you sure you want to delete this task?`}
            label="Yes, Delete"
            onConfirmAction={doDelete}
          />
        ),
      });
    },
    [openModal, setDraftTaskChanges, triggerNotice],
  );

  return (
    <div css={Css.df.jcsb.aic.$}>
      {/* TODO: Wrapping `CommentCountBubble` in a tooltip prevents it from working in any way. Need to find out why our
          internal beam components do not have this issue. Bonus points if we can apply that fix to FullCalendar events.
       */}
      <CommentCountBubble
        // Comment bubble is ancient and doesn't increment the same way as our standard icons so we add some padding
        xss={Css.pxPx(5).$}
        streams={streams}
        // Note: we don't really care about scrolling comments into view since their at the top already
        onClick={() => onTaskDetailLink(task)}
        size={2.3}
      />
      {isTradeCommsEnabled && (
        <Tooltip
          disabled={!hasDraftChanges}
          title={hasDraftChanges && "Save schedule changes first before communicating with trades"}
        >
          <IconButton
            icon="email"
            onClick={() =>
              openModal({
                content: <SendTradePartnerAvailabilityRequestEmailModal planTasks={[task]} />,
                size: "xxl",
              })
            }
            disabled={hasDraftChanges || !task.tradePartner || task.status.code === TaskStatus.Complete}
            inc={2.3}
          />
        </Tooltip>
      )}
      <ButtonMenu
        data-testid="draftTaskActions"
        trigger={{ icon: "verticalDots", inc: 2.3 }}
        items={[
          {
            label: "Scheduling Settings",
            onClick: () => {
              openModal({
                content: (
                  <ScheduleDraftModePlanTaskSetDaysModal planTask={task} setDraftTaskChanges={setDraftTaskChanges} />
                ),
              });
            },
          },
          {
            label: "Delete",
            disabled: disableBasedOnPotentialOperation(task.canDelete),
            onClick: () => onTaskDelete(task),
          },
          {
            label: "Add Delay Flag",
            onClick: () =>
              openModal({
                content: (
                  <ScheduleDraftModeDelayFlagModal
                    planTask={task}
                    getFormState={getFormState}
                    setUserAddedScheduleFlags={setUserAddedDelayFlags}
                  />
                ),
              }),
            disabled: task.status.code === TaskStatus.Complete && "Task is marked as complete",
          },
        ]}
      />
    </div>
  );
}

function createRows(
  tasks: ScheduleDraftMode_PlanTaskFragment[],
  milestones: ScheduleDraftMode_PlanMilestoneFragment[],
  isLookaheadView: boolean,
  groupBy: TaskGroupBy,
): GridDataRow<DynamicSchedulesRow>[] {
  switch (groupBy) {
    case TaskGroupBy.Stage:
      return createStageRows(sortTasks(tasks));
    case TaskGroupBy.None:
      return createTaskAndMilestoneRows(tasks, milestones, isLookaheadView);
    case TaskGroupBy.CostCode:
      return createCostCodeRows(sortTasks(tasks));
    default:
      assertNever(groupBy);
  }
}

export type FormInput = Pick<
  DraftPlanTaskInput,
  "id" | "knownDurationInDays" | "status" | "customWorkableDaysOfWeek" | "tradePartnerStatus"
> & {
  isManuallyScheduled: boolean;
  earliestStartDate: Maybe<Date>;
  desiredEndDate: Maybe<Date>;
  flagReasonId: InputMaybe<string>;
};

export type GetFormState = (row: ScheduleDraftMode_PlanTaskFragment) => ObjectState<FormInput>;
type InputFieldProps = {
  getFormState: GetFormState;
  task: ScheduleDraftMode_PlanTaskFragment;
  loading: boolean;
  enumDetails?: DraftModeEnumDetails_EnumDetailsFragment;
  //  Need to workaround Gridtables onRowClick from firing when the `<TextArea>` portion of our input fields are clicked
  stopEventPropagation: (event: React.MouseEvent) => void;
  holidayExcludedDates: DateOnly[];
};

export const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  isManuallyScheduled: { type: "value" },
  earliestStartDate: { type: "value" },
  knownDurationInDays: {
    type: "value",
    rules: [({ value }) => (value && value > 0 ? undefined : "Task duration must be greater than 0")],
  },
  desiredEndDate: { type: "value" },
  flagReasonId: { type: "value" },
  tradePartnerStatus: { type: "value" },
  status: { type: "value" },
  customWorkableDaysOfWeek: { type: "value", strictOrder: false },
};

function IconCell({ task, loading }: Pick<InputFieldProps, "task" | "loading">) {
  const { openModal } = useModal();
  const userAddedDelayFlags = useDraftScheduleStore((state) => state.userAddedScheduleFlags);
  const removeUserAddedScheduleFlags = useDraftScheduleStore((state) => state.removeUserAddedScheduleFlags);

  if (loading) return <Loader size="xs" />;

  const hasUserAddedDelayFlags = userAddedDelayFlags.some((flag) => flag.taskId === task.id);

  if (!hasUserAddedDelayFlags)
    return (
      task.isCriticalPath && (
        <Tooltip title="Critical Path Task">
          <div css={Css.pbPx(2).$}>
            <Icon icon="criticalPath" color={Palette.Gray900} inc={2.5} />
          </div>
        </Tooltip>
      )
    );

  return (
    <IconButton
      tooltip={task.isCriticalPath && "Critical Path Task has delay flag(s)"}
      icon={task.isCriticalPath ? "criticalPath" : "flag"}
      color={Palette.Red700}
      onClick={() =>
        openModal({
          content: (
            <ScheduleDraftModeDeleteFlagModal
              removeUserAddedScheduleFlags={removeUserAddedScheduleFlags}
              userAddedDelayFlags={userAddedDelayFlags.filter((flag) => flag.taskId === task.id)}
            />
          ),
        })
      }
    />
  );
}

type StartDateInputProps = InputFieldProps & {
  tasks: ScheduleDraftMode_PlanTaskFragment[];
  // We have to pass this in because the change can trigger a modal that also uses StartDateInput and relies on the store
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void;
};

/* Note: It is known that this component triggers a react warning for a bad setState action. This is a TODO FIXME but it does not cause issues on the page */
function StartDateInput({
  getFormState,
  task,
  loading,
  holidayExcludedDates,
  tasks,
  setDraftTaskChanges,
  stopEventPropagation,
}: StartDateInputProps) {
  const [isHovered, setIsHovered] = useState(false);
  const onPointerEnter = useCallback(() => setIsHovered(true), []);
  const onPointerLeave = useCallback(() => setIsHovered(false), []);
  const { openModal } = useModal();

  const disabledDays = useMemo(() => {
    return [{ dayOfWeek: getCustomDisabledWorkingDays(task.customWorkableDays) }, ...holidayExcludedDates];
  }, [holidayExcludedDates, task.customWorkableDays]);
  const os = useMemo(() => getFormState(task), [getFormState, task]);

  // Use the value from formState within the component to ensure the UI updates immediately on click
  const isManuallyScheduled = useComputed(() => os.isManuallyScheduled.value, [os.isManuallyScheduled]);

  const { triggerNotice } = useSnackbar();
  const tids = useTestIds({}, "startDateInput");

  const { iconTooltip, showIcon, iconColor } = useMemo(() => {
    return {
      iconTooltip: isManuallyScheduled
        ? "Unpinning the task date will allow it to auto-move when other tasks are moved. If predecessors have already moved, the task may move immediately when unpinned."
        : "Pin Current Start Date",
      showIcon: task.status.code !== TaskStatus.Complete && (isManuallyScheduled || isHovered),
      iconColor: isManuallyScheduled ? Palette.Blue600 : Palette.Gray700,
    };
  }, [isManuallyScheduled, isHovered, task.status.code]);

  const onDateChange = useCallback(
    (newValue?: Date) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value
      if (isSameDay(newValue, task.startDate)) return;

      // Update the form state so we show the change to the user immediately
      os.earliestStartDate.set(newValue);

      setDraftTaskChanges([
        {
          id: task.id,
          earliestStartDate: new DateOnly(newValue),
          // Current ask is to auto "pin" the task whenever a date change occurs, we may in the future bring back the more
          // nuanced behavior that was originally spec'd that allows a user to set "earliestStart" without fixing that date
          isManuallyScheduled: true,
        },
      ]);

      const followingTwoWeekPinnedTasks = (tasks ?? []).filter(
        (t) =>
          isWithinInterval(t.startDate.date, { start: t.startDate.date, end: addWeeks(t.startDate.date, 2) }) &&
          t.isManuallyScheduled &&
          t.status.code !== TaskStatus.Complete,
      );

      if (followingTwoWeekPinnedTasks.nonEmpty) {
        openModal({
          size: "lg",
          content: (
            <UnpinDependentTasksModal
              stopEventPropagation={stopEventPropagation}
              getFormState={getFormState}
              task={task}
              loading={loading}
              holidayExcludedDates={holidayExcludedDates}
              // TODO: Decouple this modal from the input so we dont have to pass setDraftTaskChanges all the way down
              //   from `createColumns()`
              setDraftTaskChanges={setDraftTaskChanges}
              followingPinnedTasks={followingTwoWeekPinnedTasks}
            />
          ),
        });
      }
    },
    [
      task,
      os.earliestStartDate,
      setDraftTaskChanges,
      tasks,
      openModal,
      stopEventPropagation,
      getFormState,
      loading,
      holidayExcludedDates,
    ],
  );

  /** Toggle between "pinning" the current start date and unsetting an existing pinned date */
  const onPinClick = useCallback(() => {
    os.isManuallyScheduled.set(!task.isManuallyScheduled);
    setDraftTaskChanges([
      {
        id: task.id,
        earliestStartDate: task.isManuallyScheduled ? null : task.startDate,
        isManuallyScheduled: !task.isManuallyScheduled,
      },
    ]);
    triggerNotice({
      message: `Task '${task.name}' has been ${task.isManuallyScheduled ? "unpinned" : "pinned"} `,
    });
  }, [task, setDraftTaskChanges, triggerNotice, os]);

  return (
    <div
      css={Css.wPx(120).relative.$}
      onClick={stopEventPropagation}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      {...tids}
    >
      <BoundDateField
        field={os.earliestStartDate}
        onBlur={noop}
        hideCalendarIcon
        disabledDays={disabledDays}
        onChange={onDateChange}
        borderless
        disabled={loading}
      />
      <div css={Css.absolute.df.aic.jcc.right0.top0.h100.$}>
        {showIcon && (
          <IconButton
            icon="pin"
            onClick={onPinClick}
            color={iconColor}
            tooltip={iconTooltip}
            disabled={loading}
            {...tids.pinButton}
          />
        )}
      </div>
    </div>
  );
}

type UnpinDependentTasksModalProps = Omit<StartDateInputProps, "tasks"> & {
  followingPinnedTasks: ScheduleDraftMode_PlanTaskFragment[];
};

function UnpinDependentTasksModal({ task, followingPinnedTasks, ...otherProps }: UnpinDependentTasksModalProps) {
  return (
    <PresentationProvider fieldProps={{ labelStyle: "hidden" }}>
      <ConfirmationModal
        title="Unpin Dependent Tasks?"
        confirmationMessage={
          <div>
            <span>
              These are all of the pinned tasks in the next 2 weeks following this task <b>{task.name}</b>. Do you want
              to unpin those tasks, or cancel and keep those tasks on their pinned days?
            </span>
            <div css={Css.dg.gap2.gtc("auto auto").mt3.$}>
              <span css={Css.smSb.$}>Dependent Tasks</span>
              <span css={Css.smSb.$}>Start Date</span>
              {followingPinnedTasks.map((task) => (
                <Fragment key={task.id}>
                  <span css={Css.smMd.$}>{task.name}</span>
                  <StartDateInput task={task} {...otherProps} tasks={[]} />
                </Fragment>
              ))}
            </div>
          </div>
        }
        label="Done"
        onConfirmAction={() => {}}
      />
    </PresentationProvider>
  );
}

function DurationInput({
  getFormState,
  task,
  loading,
  stopEventPropagation,
}: Omit<InputFieldProps, "holidayExcludedDates">) {
  const { id, knownDurationInDays } = task;
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: number) => {
        setDraftTaskChanges([{ id, knownDurationInDays: newValue }]);
      }, 500),
    [id, setDraftTaskChanges],
  );

  const onChange = useCallback(
    (newValue?: number) => {
      if (!isDefined(newValue) || newValue < 1) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === knownDurationInDays) return;

      // Keep the form state updated immediately
      os.knownDurationInDays.set(newValue);
      // Debounce the change so multi-digit duration entries don't trigger multiple updates
      debouncedOnChange(newValue);
    },
    [knownDurationInDays, os.knownDurationInDays, debouncedOnChange],
  );

  return (
    <div css={Css.wPx(100).$} onClick={stopEventPropagation}>
      <BoundNumberField field={os.knownDurationInDays} onChange={onChange} type="days" disabled={loading} />
    </div>
  );
}

/* Note: It is known that this component triggers a react warning for a bad setState action. This is a TODO FIXME but it does not cause issues on the page */
function EndDateInput({ getFormState, task, loading, holidayExcludedDates, stopEventPropagation }: InputFieldProps) {
  const disabledDays = useMemo(() => {
    return [
      { dayOfWeek: getCustomDisabledWorkingDays(task.customWorkableDays) },
      { before: task.startDate.date },
      ...holidayExcludedDates,
    ];
  }, [holidayExcludedDates, task.customWorkableDays, task.startDate.date]);
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);

  const onDateChange = useCallback(
    (newValue?: Date) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value
      if (isSameDay(newValue, task.endDate)) return;

      // Update the form state so we show the change to the user immediately
      os.desiredEndDate.set(newValue);

      setDraftTaskChanges([{ id: task.id, desiredEndDate: new DateOnly(newValue) }]);
    },
    [setDraftTaskChanges, task.id, task.endDate, os],
  );

  return (
    <div css={Css.pr1.$} onClick={stopEventPropagation}>
      <BoundDateField
        field={os.desiredEndDate}
        onBlur={noop}
        hideCalendarIcon
        disabledDays={disabledDays}
        onChange={onDateChange}
        borderless
        disabled={loading}
      />
    </div>
  );
}

function StatusInput({
  getFormState,
  task,
  loading,
  enumDetails,
  stopEventPropagation,
}: Omit<InputFieldProps, "holidayExcludedDates">) {
  const { id, status } = task;
  const os = useMemo(() => getFormState(task), [getFormState, task]);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const onSelect = useCallback(
    (newValue?: TaskStatus) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === status.code) return;

      // Keep the form state updated immediately
      os.status.set(newValue);
      setDraftTaskChanges([{ id, status: newValue }]);
    },
    [status.code, os.status, setDraftTaskChanges, id],
  );

  return (
    <div onClick={stopEventPropagation}>
      <TaskStatusSelect
        hideLabel
        options={enumDetails?.taskStatus ?? []}
        canComplete={task.canComplete}
        canStart={task.canStart}
        statusField={os.status}
        onSelect={onSelect}
        disabled={loading}
      />
    </div>
  );
}

function mapToForm(task: ScheduleDraftMode_PlanTaskFragment): FormInput {
  const {
    id,
    tradePartnerStatus,
    isManuallyScheduled,
    startDate,
    endDate,
    knownDurationInDays,
    status,
    customWorkableDays,
  } = task;
  return {
    id,
    isManuallyScheduled: isManuallyScheduled ?? false,
    earliestStartDate: new DateOnly(startDate),
    desiredEndDate: new DateOnly(endDate),
    knownDurationInDays: knownDurationInDays ?? task.durationInDays,
    flagReasonId: null,
    tradePartnerStatus: tradePartnerStatus.code,
    status: status.code,
    customWorkableDaysOfWeek: customWorkableDays,
  };
}

// Tasks are sorted by start date, however we may have multiple tasks starting on the same date
// So we then introduce a secondary sort by task name so the ordering is stable
function sortTasks(tasks: ScheduleDraftMode_PlanTaskFragment[]) {
  return [...tasks].sort((a, b) => {
    const compareKey = isSameDay(a.startDate, b.startDate) ? "name" : "startDate";
    const compareFunc = newComparator<ScheduleDraftMode_PlanTaskFragment>((t) => t[compareKey]);

    return compareFunc(a, b);
  });
}

/* NOTE: `planTask.customWorkableDays` resolves to default array of Mon-Fri when stored value in db is null */
function getCustomDisabledWorkingDays(planTaskCustomWorkingDays: DayOfWeek[]) {
  return DayOfWeek.toValues()
    .filter((day) => !planTaskCustomWorkingDays.includes(day))
    .map((day) => DayOfWeekDayPicker[day]);
}

// helper function to filter the tasks manually for draft schedule mode
export function filterTasks(
  tasks: ScheduleDraftMode_PlanTaskFragment[],
  filter: CustomDynamicSchedulesFilter,
  search: string | null | undefined,
) {
  const mappedFilter = mapToFilter(filter);
  return tasks.filter(
    (task) =>
      task.name.toLowerCase().includes(search?.toLowerCase() ?? "") &&
      filterByStartDateRange(task, mappedFilter) &&
      filterByEndDateRange(task, mappedFilter) &&
      filterByTradePartnerStatus(task, mappedFilter),
  );
}

// Filter by start date range
function filterByStartDateRange(
  { startDate }: { startDate: Maybe<DateOnly> },
  { startDateRange }: PlanTasksFilter,
): boolean {
  if (!startDateRange || !startDateRange.value2 || !startDateRange.value) return true;
  if (!startDate) return false;
  return startDate >= startDateRange.value && startDate <= startDateRange.value2;
}

// Filter by end date range
function filterByEndDateRange({ endDate }: { endDate: Maybe<DateOnly> }, { endDateRange }: PlanTasksFilter): boolean {
  if (!endDateRange || !endDateRange.value || !endDateRange.value2) return true;
  if (!endDate) return false;
  return endDate >= endDateRange.value && endDate <= endDateRange.value2;
}

// Filter by trade partner status
function filterByTradePartnerStatus(
  task: ScheduleDraftMode_PlanTaskFragment,
  { tradePartnerStatus }: PlanTasksFilter,
): boolean {
  if (!tradePartnerStatus) return true;
  return tradePartnerStatus.includes(task.tradePartnerStatus.code);
}

function TradeCell({
  task,
  getFormState,
  enumDetails,
  stopEventPropagation,
}: Omit<InputFieldProps, "loading" | "holidayExcludedDates">) {
  const uniqueCommittedTrades = task.committedTradePartners.uniqueBy((tp) => tp.id);
  if (!task.tradePartner) return null;

  return (
    <>
      <TradeStatusCell
        stopEventPropagation={stopEventPropagation}
        task={task}
        enumDetails={enumDetails}
        getFormState={getFormState}
      />
      <div css={Css.df.gap1.aic.jcc.$}>
        {uniqueCommittedTrades.length > 1 && (
          <Icon
            icon="error"
            color={Palette.Red600}
            tooltip="Only 1 trade partner per task is recommended. The trade partner schedule status only applies to the first trade partner."
            inc={2.3}
          />
        )}
      </div>
    </>
  );
}

function TradeStatusCell({
  task,
  getFormState,
  enumDetails,
  stopEventPropagation,
}: Omit<InputFieldProps, "loading" | "holidayExcludedDates">) {
  const { id, tradePartnerStatus } = task;
  const os = getFormState(task);
  const setDraftTaskChanges = useDraftScheduleStore((state) => state.addDraftTaskChanges);
  const setValidationErrors = useDraftScheduleStore((state) => state.setValidationErrors);

  useEffect(() => {
    setValidationErrors({ [task.id]: os.errors });
  }, [task.id, os.errors, setValidationErrors]);

  const onSelect = useCallback(
    (newValue?: TradePartnerTaskStatus) => {
      if (!isDefined(newValue)) return;
      // Avoid duplicate entries for the same value (can happen when the user hits enter then blurs)
      if (newValue === tradePartnerStatus.code) return;

      // Keep the form state updated immediately
      os.tradePartnerStatus.set(newValue);
      setDraftTaskChanges([{ id, tradePartnerStatus: newValue }]);
    },
    [tradePartnerStatus.code, os.tradePartnerStatus, setDraftTaskChanges, id],
  );

  // Don't show the trade status option if there is no trade
  if (!task.tradePartner) return null;

  const { bgColor } = getTradePartnerTaskStyle(os.tradePartnerStatus.value!);

  const requests = task.tradePartnerAvailabilityRequests;
  /**
   * This is a hack to determine if the task was confirmed by the trade partner via the trade portal.
   *
   * This works because our internal fronteend doesn't have a way to approve trade partner requests right now,
   * but this might change in the future.
   *
   * TODO: SC-61084 - Add a `confirmed_by` field to the `TradePartnerAvailabilityRequest` type
   */
  const wasConfirmedByTradePartner = requests?.some(
    (r) => r.status.code === TradePartnerAvailabilityRequestStatus.Confirmed && r.tradePartnerConfirmationDate,
  );
  const isConfirmed = tradePartnerStatus.code === TradePartnerTaskStatus.Confirmed;

  return (
    <ColoredSelectFieldWrapper
      isReadOnly={os.tradePartnerStatus.readOnly}
      fieldBgColor={bgColor}
      stopEventPropagation={stopEventPropagation}
    >
      {maybeTooltip({
        title: isConfirmed
          ? wasConfirmedByTradePartner
            ? "Confirmed by Trade via Trade Portal"
            : "Confirmed manually"
          : "",
        placement: "top",
        children: (
          <BoundSelectField
            compact
            getOptionMenuLabel={(tps) => (
              <div css={Css.df.aic.$}>
                <StatusIndicator status={tps.code} />
                <span css={Css.ml1.$}>{tps.name}</span>
              </div>
            )}
            fieldDecoration={(tps) => <StatusIndicator status={tps.code} />}
            getOptionValue={(o) => o.code}
            getOptionLabel={(o) => o.name}
            options={enumDetails?.tradePartnerTaskStatus.sortBy((tps) => tradePartnerTaskDropdownOrder[tps.code]) ?? []}
            onSelect={onSelect}
            field={os.tradePartnerStatus}
          />
        ),
      })}
    </ColoredSelectFieldWrapper>
  );
}

// Common fn to workaround Gridtables onRowClick from firing when the `<TextArea>` portion of our input fields are
// clicked
function stopEventPropagation(event: React.MouseEvent) {
  event.stopPropagation();
}

/**
 * Force field to be smaller and possibly fill bg of select field with unique color
 *
 **/
export const ColoredSelectFieldWrapper = ({
  children,
  fieldBgColor,
  isReadOnly,
  stopEventPropagation,
}: {
  children: ReactNode;
  fieldBgColor?: Palette;
  isReadOnly?: boolean;
  stopEventPropagation: (event: React.MouseEvent) => void;
}) =>
  isReadOnly ? (
    <>{children}</>
  ) : (
    <span
      onClick={stopEventPropagation}
      css={
        Css.addIn("> div > div > div", Css.if(!!fieldBgColor).transition.bgColor(fieldBgColor).$)
          .addIn(
            "> div > div > div > textarea:first-of-type",
            Css.if(!!fieldBgColor).transition.bgColor(fieldBgColor).$,
          )
          // In case an inner tooltip is wrapped we need to go through 1 span level
          .addIn("span > div > div > div", Css.if(!!fieldBgColor).transition.bgColor(fieldBgColor).$)
          .addIn(
            "span > div > div > div > textarea:first-of-type",
            Css.if(!!fieldBgColor).transition.bgColor(fieldBgColor).$,
          ).$
      }
    >
      {children}
    </span>
  );

type IconConfig = {
  color: Palette;
  bgColor: Palette;
  icon: "checkCircle" | "infoCircle";
};

function getTradePartnerTaskStyle(code: TradePartnerTaskStatus): IconConfig {
  switch (code) {
    case TradePartnerTaskStatus.CompletedJob:
      return { color: Palette.Gray600, bgColor: Palette.Gray100, icon: "checkCircle" };
    case TradePartnerTaskStatus.Confirmed:
      return { color: Palette.Green500, bgColor: Palette.Green50, icon: "checkCircle" };
    case TradePartnerTaskStatus.NeedsConfirmation:
    case TradePartnerTaskStatus.NeedsReconfirmation:
      return { color: Palette.Yellow500, bgColor: Palette.Yellow50, icon: "checkCircle" };
    case TradePartnerTaskStatus.Unavailable:
      return { color: Palette.Red600, bgColor: Palette.Red100, icon: "checkCircle" };
    default:
      return { color: Palette.Orange500, bgColor: Palette.Orange100, icon: "infoCircle" };
  }
}

const tradePartnerTaskDropdownOrder = {
  [TradePartnerTaskStatus.NeedsConfirmation]: 0,
  [TradePartnerTaskStatus.NeedsReconfirmation]: 1,
  [TradePartnerTaskStatus.Unavailable]: 2,
  [TradePartnerTaskStatus.Confirmed]: 3,
  [TradePartnerTaskStatus.CompletedJob]: 4,
};
