import { useCallback } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import type { IdOrAddParams } from "src/routes/routesDef";
import { addEntityParam } from "src/RouteUrls";
import { doInNextLoop, pushInNextLoop } from "src/utils/history";
import { BooleanParam, useQueryParams } from "use-query-params";

// Make an ADT for our three read/create/update states.
type Read = { mode: "read"; isNew: false; id: string };
type Create = { mode: "create"; isNew: true; id: undefined };
type Update = { mode: "update"; isNew: false; id: string };
type Callbacks = {
  onEdit: () => void;
  onCancel: () => void;
  onSave: (options?: { url: string }) => void;
};
type Mode = (Read | Create | Update) & Callbacks;

export const stayInPlace = "stay-in-place" as const;

type ModeOpts = {
  // If stayInPlace, the onSave will stay put/effectively "unedit" instead of navigating away
  listPageUrl: string | typeof stayInPlace;
};
/**
 * Establishes the CRUD mode of a page.
 *
 * We first look for an `:idOrAdd` route param, and then after that Read/write
 * the `?edit=1` query param.
 *
 * This should play nice with other query params, i.e. not stomp on them.
 */
export function useModeParam(opts: ModeOpts): Mode {
  const history = useHistory();
  const { idOrAdd } = useParams<IdOrAddParams>();
  const [{ edit = false }, setFlags] = useQueryParams({ edit: BooleanParam });
  const { listPageUrl } = opts;

  // onEdit should only be invoked if we're in `read` mode; we defer to FormActions for this
  const onEdit = useCallback(() => {
    setFlags({ edit: true });
  }, [setFlags]);

  // onCancel can be invoked in either create or update mode; if update, we just go back
  // to read mode, if create, we redirect back to the entity's list page.
  const onCancel = useCallback(() => {
    if (idOrAdd === addEntityParam && listPageUrl !== stayInPlace) {
      history.push(listPageUrl);
    } else {
      setFlags({ edit: undefined }, "pushIn");
    }
  }, [setFlags, history, idOrAdd, listPageUrl]);

  // onSave can be invoked in either create or update mode; for either we go back
  // to the entity's list page
  const onSave = useCallback(
    (options?: { url: string }) => {
      const target = options ? options.url : listPageUrl;
      // We pushInNextLoop so that FormAction's onSave promise has a chance to return
      // and mark the formState as clean before we invoke this, so that the "are you sure?"
      // dialog doesn't pop up.
      if (target === stayInPlace) {
        doInNextLoop(() => setFlags({ edit: undefined }, "pushIn"));
      } else {
        pushInNextLoop(history, target);
      }
    },
    [history, listPageUrl, setFlags],
  );

  if (idOrAdd === addEntityParam) {
    return { mode: "create", isNew: true, id: undefined, onEdit, onCancel, onSave };
  } else if (edit) {
    return { mode: "update", isNew: false, id: idOrAdd!, onEdit, onCancel, onSave };
  } else {
    return { mode: "read", isNew: false, id: idOrAdd!, onEdit, onCancel, onSave };
  }
}
