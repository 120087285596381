import { useSnackbar } from "@homebound/beam";
import { useMemo } from "react";
import { useDuplicateItemsConfirmationModal_GetItivsQuery } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { pluralize } from "src/utils";
import { StoreApi } from "zustand";
import { TakeoffsStoreState } from "../TakeoffsManagerContext";

export function DuplicateItemsConfirmationModal(props: { store: StoreApi<TakeoffsStoreState> }) {
  const { store } = props;
  const { triggerNotice } = useSnackbar();
  const { itemTemplate, copyFromTemplate, getSelectedItivIds } = store.getState();
  const selectedItivIds = getSelectedItivIds();
  const itivsQuery = useDuplicateItemsConfirmationModal_GetItivsQuery({ variables: { itivIds: selectedItivIds } });
  const { allowed, numAllowed, numNotAllowed, uniqueNotAllowedMessages } = useMemo(() => {
    const [allowed, notAllowed] =
      itivsQuery.data?.itemTemplateItemVersions.partition((itiv) => itiv.canDuplicate.allowed) ?? [];
    return {
      allowed,
      numAllowed: allowed?.length ?? 0,
      numNotAllowed: notAllowed?.length ?? 0,
      uniqueNotAllowedMessages: Object.entries(
        notAllowed
          ?.flatMap((itiv) => itiv.canDuplicate.disabledReasons.map((reason) => [itiv, reason.message] as const))
          ?.groupBy(([, reason]) => reason) ?? {},
      ),
    };
  }, [itivsQuery.data]);

  const onDuplicate = async () => {
    const { data } = await copyFromTemplate({
      targetTemplateId: itemTemplate.id,
      sourceItemIds: allowed?.map((itiv) => itiv.id),
    });
    triggerNotice({
      message: `${
        data?.copyItemTemplateItems.copiedItemVersions.length === 1
          ? "Item has"
          : `${data?.copyItemTemplateItems.copiedItemVersions.length} items have`
      } been duplicated`,
      icon: "success",
    });
  };

  return (
    <ConfirmationModal
      title="Duplicate Items"
      label="Duplicate"
      onConfirmAction={onDuplicate}
      disabled={itivsQuery.loading || numAllowed === 0}
      confirmationMessage={
        <>
          {numAllowed > 0 && (
            <p>
              Are you sure you wish to duplicate {numAllowed} {pluralize(numAllowed, "item")}?
            </p>
          )}
          {numNotAllowed > 0 && (
            <>
              <p>
                {numNotAllowed} {pluralize(numNotAllowed, "item")} cannot be duplicated for the following{" "}
                {pluralize(uniqueNotAllowedMessages.length, "reason")}:
              </p>
              <ul>
                {uniqueNotAllowedMessages.map(([message, itivs]) => (
                  <li key={message}>
                    [{itivs.length}] - {message}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
    />
  );
}
