import { IObservableArray, observable } from "mobx";
import { DevelopmentCommitmentV2ProjectsTabProjectFragment } from "src/generated/graphql-types";
import { Selectable } from "src/models";
import { DevelopmentCommitmentProject } from "src/routes/development-commitments/models/DevelopmentCommitmentProject";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

export class DevelopmentCommitmentProjectStore extends Selectable {
  readonly projects: IObservableArray<DevelopmentCommitmentProject>;

  constructor(dcProjects: DevelopmentCommitmentV2ProjectsTabProjectFragment[]) {
    const projects = observable(dcProjects.map((p) => new DevelopmentCommitmentProject(p)));
    // passing in projects to allow for bulk select
    super("all", false, projects);
    makeSimpleAutoObservable(this);
    this.projects = projects;
  }

  get selectedProjects() {
    return this.projects.filter((p) => p.selected);
  }
}
