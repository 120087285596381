import { MaterialType, MaterialTypeDetail, useMaterialTypesQuery } from "../../generated/graphql-types";

export function useMaterialTypes(): MaterialTypeDetail[] {
  const typesQuery = useMaterialTypesQuery({ fetchPolicy: "cache-first" });

  return (
    typesQuery.data?.materialTypes ??
    Object.values(MaterialType).map((code, sortOrder) => ({ code, name: code.replace(/_/g, " "), sortOrder }))
  );
}
