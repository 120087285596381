import { Chip, Css, GridDataRow, simpleHeader } from "@homebound/beam";
import { addDays, addWeeks, isAfter, isBefore, isSameDay, startOfToday, startOfWeek, subDays } from "date-fns";
import { Maybe } from "graphql/jsutils/Maybe";
import { useRouteMatch } from "react-router";
import {
  PlanTask,
  ScheduleDraftMode_PlanMilestoneFragment,
  ScheduleDraftMode_PlanTaskFragment,
  ScheduleDraftMode_TradePartnerContactFragment,
  ScheduleDraftMode_TradePartnerFragment,
  TaskDetailsPage_ProjectItemFragment,
  TaskStatus,
  TradePartnerMarketRole,
  TradePartnerTaskStatus,
} from "src/generated/graphql-types";
import { dynamicSchedulesPath } from "src/routes/routesDef";
import { findIndexFrom, isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";

// Shared type for task detail sections that display project items
export type ProjectItemsSection = {
  projectItems: TaskDetailsPage_ProjectItemFragment[];
  // Don't render border and extra padding when reusing section in side pane
  inSidePane?: boolean;
};

// shared utility function to map market specific contacts to their TradePartnerMarketRoles
export function getMarketsContacts(
  tradePartner: ScheduleDraftMode_TradePartnerFragment | undefined | null,
  // Must be in the same order as what is passed to `TradePartner.contactsForProjectAndRoles`
  marketRoles: TradePartnerMarketRole[] = [
    TradePartnerMarketRole.Scheduling,
    TradePartnerMarketRole.SchedulingRecipient_2,
  ],
): MarketContactWithRoles[] {
  if (!tradePartner || !tradePartner.contactsForMarket) return [];

  return getUniqueContactsWithRoles(
    tradePartner.contactsForMarket.compact(),
    tradePartner.contactsForProjectAndRoles,
    marketRoles,
  );
}

export type MarketContactWithRoles = {
  id: string;
  name: string;
  email?: string | null;
  schedulingRoles: TradePartnerMarketRole[];
};

function getUniqueContactsWithRoles(
  contactsForMarket: ScheduleDraftMode_TradePartnerContactFragment[],
  contactsForProjectAndRoles: Maybe<ScheduleDraftMode_TradePartnerContactFragment>[],
  marketRoles: TradePartnerMarketRole[],
): MarketContactWithRoles[] {
  const contactMap: Record<string, MarketContactWithRoles> = {};

  // map the other market contacts that don't have a role
  contactsForMarket
    .unique()
    .forEach(({ id, email, name }) => (contactMap[id] = { id, name, email, schedulingRoles: [] }));

  marketRoles.forEach((role, idx) => {
    // contactsForProjectAndRoles strictly returns null if no contact is found for the role
    if (isDefined(contactsForProjectAndRoles[idx])) {
      const { id } = contactsForProjectAndRoles[idx]!;
      // edge case where the role contact for a project isn't in the market contacts
      if (!contactMap[id]) {
        contactMap[id] = {
          id,
          name: contactsForProjectAndRoles[idx]!.name,
          email: contactsForProjectAndRoles[idx]!.email,
          schedulingRoles: [],
        };
      }
      contactMap[id].schedulingRoles.push(role);
    }
  });

  return Object.values(contactMap);
}

export function MarketContactMenuLabel({ name, email, schedulingRoles }: MarketContactWithRoles) {
  return (
    <div css={Css.df.fdc.gap1.$}>
      <div>
        {name}, {"<"}
        {email}
        {">"}
      </div>
      <div>
        {schedulingRoles.map((role) => (
          <Chip compact type="info" icon="email" text={role} key={role} />
        ))}
      </div>
    </div>
  );
}

export function ScheduleContainer({ children }: { children: React.ReactNode }) {
  return <div css={Css.h100.w100.pr3.ma.$}>{children}</div>;
}

export enum DynamicScheduleView {
  Calendar = "Calendar",
  Gantt = "Gantt",
  List = "List",
  Lookahead = "Lookahead",
  Milestone = "Milestone",
}

export function useScheduleRouteMatch() {
  const isGanttView = !!useRouteMatch(dynamicSchedulesPath.draftGantt);
  const isCalendarView = !!useRouteMatch(dynamicSchedulesPath.draftCalendar);
  const isListView = !!useRouteMatch(dynamicSchedulesPath.draftMode)?.isExact;
  const isMilestoneView = !!useRouteMatch(dynamicSchedulesPath.draftMilestone);
  const isLookaheadView = !!useRouteMatch(dynamicSchedulesPath.draftLookahead);

  if (isGanttView) return DynamicScheduleView.Gantt;
  if (isCalendarView) return DynamicScheduleView.Calendar;
  if (isMilestoneView) return DynamicScheduleView.Milestone;
  if (isListView) return DynamicScheduleView.List;
  if (isLookaheadView) return DynamicScheduleView.Lookahead;

  throw new Error("Unknown schedule view");
}

/**
 * Inserts lookahead divider rows into the task rows when a dynamic schedule list displays a lookahead view.
 */
export function insertLookaheadDividerRows(taskRows: DynamicSchedulesRow[]): DynamicSchedulesRow[] {
  const mondayOfThisWeek = startOfWeek(startOfToday(), { weekStartsOn: 1 });

  const lookaheadRows: GridDataRow<LookaheadRow>[] = [
    {
      kind: "lookahead" as const,
      id: "2 Weeks Divider",
      data: { name: "2 Weeks", startDate: mondayOfThisWeek, endDate: subDays(addWeeks(mondayOfThisWeek, 2), 1) },
    },
    {
      kind: "lookahead" as const,
      id: "3 Weeks Divider",
      data: {
        name: "3 Weeks",
        startDate: addWeeks(mondayOfThisWeek, 2),
        endDate: subDays(addWeeks(mondayOfThisWeek, 3), 1),
      },
    },
    {
      kind: "lookahead" as const,
      id: "4 Weeks Divider",
      data: {
        name: "4 Weeks",
        startDate: addWeeks(mondayOfThisWeek, 3),
        endDate: subDays(addWeeks(mondayOfThisWeek, 4), 1),
      },
    },
    {
      kind: "lookahead" as const,
      id: "6 Weeks Divider",
      data: {
        name: "6 Weeks",
        startDate: addWeeks(mondayOfThisWeek, 4),
        endDate: subDays(addWeeks(mondayOfThisWeek, 6), 1),
      },
    },
  ];

  lookaheadRows.forEach((lar) => {
    // Find the first task that starts between the lookahead rows start and end date and insert lar right before it.
    const firstTaskIndex = findIndexFrom(taskRows, (row) => {
      const { kind, data } = row;
      let starter: Date;
      switch (kind) {
        case "task":
        case "lookahead":
          starter = data.startDate;
          break;
        // Milestones can technically not have an estStartDate
        case "milestone":
          starter = data?.estStartDate ?? subDays(mondayOfThisWeek, 1);
          break;
        case "header":
        default:
          starter = subDays(mondayOfThisWeek, 1);
          break;
      }

      return (
        (isSameDay(starter, lar.data.startDate) || isAfter(starter, lar.data.startDate)) &&
        isBefore(starter, lar.data.endDate)
      );
    });
    if (firstTaskIndex !== -1) {
      // @ts-ignore
      taskRows.splice(firstTaskIndex, 0, lar);
    }
  });

  return taskRows;
}

/* Maps our DayOfWeek enum to numeric day */
export enum DayOfWeekDayPicker {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

type IsDisableDayInput = {
  date: DateOnly | Date;
  customWorkableDays: PlanTask["customWorkableDays"];
  scheduleExcludedDates?: DateOnly[];
};

export function isDisabledDay({ date, customWorkableDays, scheduleExcludedDates }: IsDisableDayInput) {
  const totalWorkingDays = customWorkableDays?.map((d) => DayOfWeekDayPicker[d]);

  const isWorkingDayForTask = totalWorkingDays.includes(date.getDay());
  const isHoliday = scheduleExcludedDates?.some((d) => isSameDay(d, date));

  return !isWorkingDayForTask || isHoliday;
}

/** Shared util to check if a task is in the "2 week window" and has not confirmed the trade */
export function taskRequiresTradeConfirmation(
  taskStatus: TaskStatus,
  taskStartDate: DateOnly,
  tradeStatus: TradePartnerTaskStatus,
) {
  const confirmedStatuses = [TradePartnerTaskStatus.Confirmed, TradePartnerTaskStatus.CompletedJob];
  return (
    taskStatus !== TaskStatus.Complete &&
    isBefore(taskStartDate.date, addDays(new Date(), 14)) &&
    !confirmedStatuses.includes(tradeStatus)
  );
}
export type LookaheadRow = { kind: "lookahead"; id: string; data: { name: string; startDate: Date; endDate: Date } };
export type DynamicSchedulesRow =
  | DynamicSchedulesHeaderRow
  | DynamicSchedulesGroupRow
  | LookaheadRow
  | DynamicSchedulesTaskRow
  | DynamicSchedulesMilestoneRow;

type DynamicSchedulesHeaderRow = { kind: "header"; id: string; data?: null };
type DynamicSchedulesGroupRow = { kind: "group"; id: string; data: { name: string } };
export type DynamicSchedulesTaskRow = {
  kind: "task";
  id: string;
  data: ScheduleDraftMode_PlanTaskFragment;
};

export type DynamicSchedulesMilestoneRow = {
  kind: "milestone";
  id: string;
  data: ScheduleDraftMode_PlanMilestoneFragment;
};

export function createTaskAndMilestoneRows(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
  planMilestones: ScheduleDraftMode_PlanMilestoneFragment[],
  isLookaheadView: boolean,
): GridDataRow<DynamicSchedulesRow>[] {
  const taskRows: GridDataRow<DynamicSchedulesTaskRow>[] = planTasks.map((task) => ({
    kind: "task" as const,
    id: task.id,
    data: task,
  }));

  const planMilestoneRows: GridDataRow<DynamicSchedulesMilestoneRow>[] = (
    isLookaheadView ? filterMilestonesForLookahead(planMilestones) : planMilestones
  ).map((pm) => ({
    id: pm.id,
    kind: "milestone" as const,
    data: pm,
  }));

  const sortedTaskAndMilestones = [...planMilestoneRows, ...taskRows].sort(sortTaskAndMilestoneRows);

  return [
    simpleHeader,
    ...(isLookaheadView ? insertLookaheadDividerRows(sortedTaskAndMilestones) : sortedTaskAndMilestones),
  ];
}

export function createStageRows(planTasks: ScheduleDraftMode_PlanTaskFragment[]): GridDataRow<DynamicSchedulesRow>[] {
  const stageGroups = planTasks.groupBy((task) => task.stage?.id ?? "no-stage");
  return [
    simpleHeader,
    ...Object.entries(stageGroups)
      .sortBy(([_, tasks]) => {
        const earliestDate = tasks.min((t) => t.startDate);
        return earliestDate?.getTime() ?? 0;
      })
      .flatMap(([stageId, tasks]) => [
        {
          kind: "group" as const,
          id: stageId,
          data: {
            name: tasks.first?.stage?.name ?? "No Stage",
          },
          children: tasks.map((task) => ({ kind: "task" as const, id: task.id, data: task })),
        },
      ]),
  ];
}

export function createCostCodeRows(
  planTasks: ScheduleDraftMode_PlanTaskFragment[],
): GridDataRow<DynamicSchedulesRow>[] {
  const costCodeGroups = planTasks.groupByObject((task) => task.globalPlanTask.budgetItem.costCode);
  return [
    simpleHeader,
    ...costCodeGroups
      .sortBy(([_, tasks]) => {
        const earliestDate = tasks.min((t) => t.startDate);
        return earliestDate?.getTime() ?? 0;
      })
      .flatMap(([costCode, tasks]) => [
        {
          kind: "group" as const,
          id: costCode.id,
          data: {
            name: costCode.name,
          },
          children: tasks.map((task) => ({ kind: "task" as const, id: task.id, data: task })),
        },
      ]),
  ];
}

export function sortTaskAndMilestoneRows(
  a: GridDataRow<DynamicSchedulesTaskRow | DynamicSchedulesMilestoneRow>,
  b: GridDataRow<DynamicSchedulesTaskRow | DynamicSchedulesMilestoneRow>,
) {
  const getCompareValues = (row: GridDataRow<DynamicSchedulesTaskRow | DynamicSchedulesMilestoneRow>) => ({
    completed:
      row.kind === "task"
        ? row.data.status.code === TaskStatus.Complete
        : (row.data as ScheduleDraftMode_PlanMilestoneFragment).progress === 100,
    startDate:
      row.kind === "task"
        ? row.data.startDate
        : ((row.data as ScheduleDraftMode_PlanMilestoneFragment)?.estStartDate ?? new Date()),
    name: row.data.name.toLowerCase(),
  });

  const aCompare = getCompareValues(a);
  const bCompare = getCompareValues(b);

  // Completed always first
  if (aCompare.completed !== bCompare.completed) return aCompare.completed ? -1 : 1;
  // Then by date start
  if (aCompare.startDate !== bCompare.startDate) return aCompare.startDate < bCompare.startDate ? -1 : 1;

  // Finally by name
  return aCompare.name.localeCompare(bCompare.name);
}

// FIXME: This logic isn't ideal but while we're inbetween combining draft and standard views, and still
//  refining what is "normal" for these tables we will carry this debt
export function filterMilestonesForLookahead<T extends { estStartDate?: DateOnly | null | undefined }>(
  planMilestones: T[],
) {
  const mondayOfThisWeek = startOfWeek(startOfToday(), { weekStartsOn: 1 });

  return planMilestones.filter(
    ({ estStartDate }) =>
      !!estStartDate && (isSameDay(estStartDate, mondayOfThisWeek) || isAfter(estStartDate, mondayOfThisWeek)),
  );
}
