import { ObjectState } from "@homebound/form-state";
import { parseISO } from "date-fns";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  BillEditor_CommitmentLikeFragment,
  BillOcr,
  BillType,
  ProjectAutocomplete_ProjectFragment,
  useBillEditor_CreateBillOcrMutation,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { BillFormInput } from "../BillEditorV3";

type Props = {
  formState: ObjectState<BillFormInput>;
  allowOcr: boolean;
  setAllowOcr: Dispatch<SetStateAction<boolean>>;
  setSelectedCommitments: Dispatch<SetStateAction<BillEditor_CommitmentLikeFragment[]>>;
  setIsLoading: (loading: boolean) => void;
  setUserHasEditedTotal: Dispatch<SetStateAction<boolean>>;
};

export function useBillOcr({
  formState,
  allowOcr,
  setAllowOcr,
  setSelectedCommitments,
  setIsLoading,
  setUserHasEditedTotal,
}: Readonly<Props>) {
  const [createBillOcr] = useBillEditor_CreateBillOcrMutation();

  // When the assets gets uploaded
  // we should call the mutation createBillOcrResult to get the extracted data from the PDF
  return useCallback(() => {
    if (!allowOcr) return;
    const isAssetUploaded =
      formState.documents.value?.some(({ asset }) => asset.id) || formState.assets?.value?.first?.id;
    if (!isAssetUploaded) return;

    setIsLoading(true);
    return createBillOcr({
      variables: {
        input: {
          assetId: (formState.documents.value?.[0]?.asset.id || formState.assets?.value?.first?.id) as string,
          projectId: formState.project.value?.id,
        },
      },
    })
      .then(({ data }) => {
        const { tradePartner, commitmentLikes, project, ...extractData } =
          data?.createBillOcr?.billOcr ?? ({} as BillOcr);
        if (!extractData) return;

        formState.type.set(BillType.Standard);

        if (extractData.invoiceNumber) {
          formState.tradePartnerNumber.set(extractData.invoiceNumber);
        }
        if (extractData.billDate) {
          formState.billDate.set(
            // There is a known issue with mutations returning a string when they should return a DateOnly, so convert to date if we get a string at runtime
            typeof extractData.billDate === "string"
              ? new DateOnly(new Date(`${parseISO(extractData.billDate)}`))
              : extractData.billDate,
          );
        }
        if (extractData.dueDate) {
          formState.dueDate.set(
            // There is a known issue with mutations returning a string when they should return a DateOnly, so convert to date if we get a string at runtime
            typeof extractData.dueDate === "string"
              ? new DateOnly(new Date(`${parseISO(extractData.dueDate)}`))
              : extractData.dueDate,
          );
        }
        if (extractData.totalInCents) {
          formState.proposedAmountInCents.set(extractData.totalInCents);
          setUserHasEditedTotal(true);
        }
        if (tradePartner) {
          formState.tradePartner.set(tradePartner);
        }
        if (project) {
          formState.project.set(project as ProjectAutocomplete_ProjectFragment);
        }
        if (commitmentLikes) {
          setSelectedCommitments(commitmentLikes as BillEditor_CommitmentLikeFragment[]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        setAllowOcr(false);
      });
  }, [
    allowOcr,
    createBillOcr,
    formState.assets?.value?.first?.id,
    formState.billDate,
    formState.documents.value,
    formState.dueDate,
    formState.project,
    formState.proposedAmountInCents,
    formState.tradePartner,
    formState.tradePartnerNumber,
    formState.type,
    setAllowOcr,
    setIsLoading,
    setSelectedCommitments,
  ]);
}
