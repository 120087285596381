import { Css, RightPaneLayout, ScrollableContent } from "@homebound/beam";
import { useState } from "react";
import { SearchBox } from "src/components";
import { PageBidPackageDetailFragment } from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { PlanBasedBidsTable } from "./PlanBasedBidsTable";
import { UnitBasedBidsTable } from "./UnitBasedBidsTable";

type BidsTabProps = {
  bidPackage: PageBidPackageDetailFragment;
};

export function BidsTab({ bidPackage }: BidsTabProps) {
  const [searchFilter, setSearchFilter] = useState<string | undefined>("");

  return (
    <>
      <TableActions>
        <SearchBox onSearch={setSearchFilter} />
      </TableActions>
      <div css={Css.h100.$}>
        <ScrollableContent virtualized>
          <RightPaneLayout>
            <div css={Css.h100.$}>
              {bidPackage.isUnitBased ? (
                <UnitBasedBidsTable bidPackage={bidPackage} searchFilter={searchFilter} />
              ) : (
                <PlanBasedBidsTable bidPackage={bidPackage} searchFilter={searchFilter} />
              )}
            </div>
          </RightPaneLayout>
        </ScrollableContent>
      </div>
    </>
  );
}

export function getOptionsKey(
  opt: {
    __typename?: "ReadyPlanOption" | undefined;
    id: string;
  }[],
) {
  return opt
    .sortByKey("id")
    .map((o) => o.id)
    .join(",");
}
