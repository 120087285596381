import { Css } from "@homebound/beam";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";

type TaskNumberCellProps = {
  id: string;
};

export function TaskNumberCell({ id }: TaskNumberCellProps) {
  const {
    scheduleState: { taskNumberMap },
  } = useScheduleStore();
  return <div css={Css.jcc.df.fg1.tiny.gray700.$}>{taskNumberMap[id]}</div>;
}
