import { Css, useTestIds } from "@homebound/beam";
import { emptyCellDash, formatDate } from "src/components";
import { CutoffDetailsFragment } from "src/generated/graphql-types";

type CutoffDetailsProps = {
  cutoff: CutoffDetailsFragment | null | undefined;
};

export function CutoffDetails({ cutoff }: CutoffDetailsProps) {
  const tid = useTestIds({}, "cutoffDetails");
  if (!cutoff) return null;
  return (
    <div css={Css.df.jcfs.aiStretch.gap8.my4.$}>
      <LabeledText {...tid.name} label="Cutoff" text={cutoff.name} />
      <LabeledText {...tid.date} label="Cutoff Date" text={cutoff.date ? formatDate(cutoff.date) : undefined} />
      <LabeledText {...tid.status} label="Cutoff Status" text={cutoff.canChangeScope ? "Unlocked" : "Locked"} />
    </div>
  );
}

type LabeledTextProps = React.HTMLProps<HTMLLabelElement> & {
  label: string;
  text: string | undefined;
};

const LabeledText = ({ label, text, ...rest }: LabeledTextProps) => (
  <label css={Css.gray700.tiny.$} {...rest}>
    {label}
    <div css={Css.mtPx(4).gray800.baseMd.$}>{text || emptyCellDash}</div>
  </label>
);
