import { Rule } from "@homebound/form-state";
import { differenceInBusinessDays, differenceInCalendarDays, getWeek, isThisWeek } from "date-fns";
import {
  HomeownerSelectionStatus,
  InputMaybe,
  OverviewTabProjectCutoffFragment,
  PotentialOperation2,
  PotentialOperationDetailsFragment,
} from "src/generated/graphql-types";
import { newComparator } from "src/utils/arrays";
import { z } from "zod";

export function fail(message?: string): never {
  throw new Error(message || "Failed");
}

export const sum = (a: number, b: number) => a + b;
export const subtract = (a: number, b: number) => a - b;

// Even though we use Math.max, this declaration is more reduce-friendly.
export const max = (a: number, b: number) => Math.max(a, b);

/** Returns a `T` that actually has no keys defined; very unsafe but nice to have for default form inputs. */
export function empty<T>(): T {
  return {} as any as T;
}

export function isEmptyObject(obj: Record<string, any>): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

// A nice type alias for hooks instead of the [T, Dispatch<...>] nonsense.
export type StateHook<T> = [T, (value: T) => void];

export function groupBy<T, Y = T>(
  list: T[] | ReadonlyArray<T>,
  fn: (x: T) => string,
  valueFn?: (x: T) => Y,
  sortFn?: (x: Y) => number | string,
): Record<string, Y[]> {
  const result: Record<string, Y[]> = {};
  list.forEach((o) => {
    const group = fn(o);
    if (result[group] === undefined) {
      result[group] = [];
    }
    result[group].push(valueFn === undefined ? (o as any as Y) : valueFn(o));
  });
  if (sortFn) {
    Object.keys(result).forEach((key) => {
      result[key].sort(newComparator(sortFn));
    });
  }
  return result;
}

type Builtin = Date | Function | Uint8Array | string | number | undefined | boolean;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : T extends Record<string, any>
        ? { [K in keyof T]?: DeepPartial<T[K]> }
        : Partial<T>;

export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export function zeroTo(n: number): number[] {
  return [...Array(n).keys()];
}

// Examples: pluralize(tacoCount, "taco"); pluralize(boxCount, "box", "boxes");
// Automatic "s" is for convenience in simple cases; when in doubt, supply the full plural noun
export function pluralize(count: number | unknown[], noun: string, pluralNoun?: string): string {
  if ((Array.isArray(count) ? count.length : count) === 1) return noun;
  return pluralNoun || `${noun}s`;
}

export function count(count: number, noun: string, pluralNoun?: string): string;
export function count(array: unknown[], noun: string, pluralNoun?: string): string;
export function count(countOrArray: unknown[] | number, noun: string, pluralNoun?: string): string {
  const count = Array.isArray(countOrArray) ? countOrArray.length : countOrArray;
  return `${count} ${pluralize(count, noun, pluralNoun)}`;
}

/** Casts `Object.keys` to "what it should be", as long as your instance doesn't have keys it shouldn't. */
export function safeKeys<T>(instance: T): (keyof T)[] {
  return Object.getOwnPropertyNames(instance) as any;
}

/** Casts `Object.entries` to "what it should be", as long as your record doesn't have keys it shouldn't. */
export function safeEntries<K extends keyof any, V>(record: Record<K, V>): [K, V][] {
  return Object.entries(record) as [K, V][];
}

/** used to debug re-rendering issues */
export const objectId = (() => {
  let currentId = 0;
  const map = new WeakMap();
  return (object: object): number => {
    if (!map.has(object)) {
      map.set(object, ++currentId);
    }
    return map.get(object)!;
  };
})();

/** Returns the number suffix of a tagged Id ("p:1" -> 1) */
export function removeTag(id: string) {
  return Number(id.split(":").pop());
}

/** No operation function, can be used to default react props */
export function noop() {}

export function partition<T>(array: ReadonlyArray<T>, f: (el: T) => boolean): [T[], T[]] {
  const trueElements: T[] = [];
  const falseElements: T[] = [];

  array.forEach((el) => {
    if (f(el)) {
      trueElements.push(el);
    } else {
      falseElements.push(el);
    }
  });

  return [trueElements, falseElements];
}

export function unique<T>(array: ReadonlyArray<T>) {
  return [...new Set(array)];
}

export function nonEmpty<T>(array: ReadonlyArray<T>): boolean {
  return array.length > 0;
}

export function isEmpty<T>(array: ReadonlyArray<T>): boolean {
  return array.length === 0;
}
/**
 * Dedupe an array of objects by a given object key
 * @param array An array of objects
 * @param key Object key used to find duplicates
 */
export function uniqueByKey<T extends object>(array: ReadonlyArray<T>, key: keyof T) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

/** Smartly de tag ids only when its in a id form i:12 */
export function maybeDeTagId(id: string) {
  const untaggedId = id.match(/[^\d\W]+:(?<id>\d+)/)?.groups?.id;
  return untaggedId ?? id;
}

/**
 * Effectively a switch-statement on Enums. Identifies an unknown Enum value and
 * maps it down to a given value. Also works on string-union types.
 */
export function foldEnum<T extends string | number, F extends (() => unknown) | unknown>(
  value: T,
  map: { [key in T]: F } | ({ [key in T]?: F } & { else: F }),
): F extends () => unknown ? ReturnType<F> : F {
  const item = map[value] ?? (map as any).else;
  return typeof item === "function" ? item() : item;
}

/** Some object that has a `__typename` field */
type GqlShape<T = unknown> = { __typename?: T };
// Omitting 'Val' side of this for now because it's overlapping with Fn and any'ing the Type
type ValOrFn<N, V = unknown> = (narrowed: N) => V; /* | V */
/** Maps each possible value of `__typename` to a ValOrFn of the narrowed type */
type FoldMap<T extends GqlShape> = {
  [K in Extract<T["__typename"], string>]: ValOrFn<Extract<T, GqlShape<K>>>;
};
type ElsableFoldMap<T extends GqlShape> =
  | Required<FoldMap<T>> // either all keys are present
  | (Partial<FoldMap<T>> & { else: ValOrFn<void> }); // or an `else` may be used as a fallback

/**
 * Narrows down and maps any object of the shape `{ __typename: string }` to a given value
 * based on the map. All possible __typenames must be accounted for, or an `else` may be used.
 *
 * Example usage:
 *
 * ```ts
 * const projectUrl = foldGqlUnion({} as ApprovalSubject, {
 *   ChangeEvent: (ce) => ce.projectStage.blueprintUrl.path, // `ce` is fully-typed
 *   Bill: (b) => b.project.blueprintUrl.path, // as is `b`
 *   Invoice: (i) => i.commitment.projectStage.project.blueprintUrl.path, // and `i`
 * })
 * ```
 *
 * TODO: This returns `any` right now. See https://github.com/homebound-team/internal-frontend/pull/4404/files#r1373994171
 */
export function foldGqlUnion<T extends GqlShape>(foldable: T, foldMap: ElsableFoldMap<T>) {
  const valOrFn = foldMap[foldable?.__typename as keyof typeof foldMap] ?? (foldMap as any).else;
  if (!valOrFn) return;
  return typeof valOrFn === "function" ? valOrFn(foldable as unknown) : valOrFn;
}

export function extractPoMessages(po: PotentialOperationDetailsFragment | undefined, delimiter = "\r\n") {
  if (!po || po.allowed) return;
  return po.disabledReasons.map((dr) => dr.message).join(delimiter);
}

export function capitalize(s: string): string {
  const lc = s.toLowerCase();
  const sArray = lc.split("");
  sArray[0] = sArray[0].toUpperCase();
  return sArray.join("");
}

export function mostFrequentNumber(arr: number[]): number {
  const initValue: Record<number, number> = {};
  const hashmap = arr.reduce((elements, value) => {
    elements[value] = (elements[value] || 0) + 1;
    return elements;
  }, initValue);
  return parseFloat(Object.keys(hashmap).reduce((a, b) => (hashmap[parseFloat(a)] > hashmap[parseFloat(b)] ? a : b)));
}

/**  Format an array [A, B, C] into a string: "A, B and C" */
export function formatList(elements: string[] | number[]): string {
  const result = elements.map((e, i, arr) => {
    return i < arr.length - 2 ? `${e}, ` : i < arr.length - 1 ? `${e} and ` : e;
  });
  return result.join("");
}

export function dollarStringToCents(dollars: string | number): number {
  if (typeof dollars === "number") {
    dollars = String(dollars);
  }

  if (dollars === "") {
    throw new Error("input is empty or blank");
  }

  const isNegative = dollars.startsWith("-");

  const NotNumOrDecimal = new RegExp(/[^\d\.]/gi);
  const sanitizedString = dollars.replaceAll(NotNumOrDecimal, ""); // "$1,234 567.89" --> "1234567.89"

  /** Rejects `1.234` for having too many decimal places */
  const MoreThanTwoDecimalPlaces = new RegExp(/\.\d{3,}$/);
  if (MoreThanTwoDecimalPlaces.test(sanitizedString)) {
    throw new Error(`Bad number provided: \`${dollars}\`. Too many digits after the decimal.`);
  }

  /** Rejects `1.234.567.89` for having too many periods. We're not expecting EU formatting */
  const decimalCount = sanitizedString.split("").reduce((count, char) => (char === "." ? count + 1 : count), 0);
  if (decimalCount > 1) {
    throw new Error(`Too many decimal points provided for number \`${dollars}\``);
  }

  /** Rejects `1,` and `1,2` and `1,23` but `1,234` is valid */
  const EndedWithCommas = new RegExp(/,\d{0,2}$/);
  if (EndedWithCommas.test(dollars)) {
    throw new Error(`Number \`${dollars}\` is invalid. Typo or possible EU Formatting rejected?`);
  }

  return (
    Math.round(
      Number.parseFloat(
        // Expand 100 -> "100.00", or 123.4 --> "123.40", etc
        Number.parseFloat(sanitizedString).toFixed(2),
      ) * 100, // To Cents
    ) * (isNegative ? -1 : 1) // handle Negative
  );
}

export const USStates =
  `AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI WY`
    .trim()
    .split(" ")
    .map((name) => ({ name }));

export const tableHeightWithPagination = "62vh";

/**
 * Detects if our JS build, either locally or in a preview branch, is running in prod qa,
 * or a feature branch qa.
 *
 * Unlike the backend, the frontend will auto-detect "I'm on a qa/feature branch" and use the
 * appropriate backend, based on the branch naming convention, without any actual deployment/infra
 * changes i.e. in the `stacks` repo.
 */
export function qaServerEnvironment(windowLocation = window.location) {
  if (VITE_GIT_BRANCH?.startsWith("qa-")) {
    // Support FE branch names the 'main' qa branch, `qa-1234`, as well as `qa-1234@sub-ticket` WIP branches
    const qaCustomBeBranch =
      VITE_GIT_BRANCH.split("@").first ??
      fail("Invalid BE Feature Branch - please ensure branch name matches pattern 'qa-[story|epic]@[sub-story-branch]");
    // Feature branch qa uses `qa-1234.graphql.dev-homebound.com` subdomains within the dev infra
    return { subDomain: `${qaCustomBeBranch}.graphql`, qaCustomBeBranch };
  } else if (isStableQaEnvironment(windowLocation)) {
    return { subDomain: "stable.graphql", qaCustomBeBranch: undefined };
  }
  // Prod qa uses the top-level `graphql.qa-homebound.com` domain, so doesn't need a subdomain
  return { subDomain: "graphql", qaCustomBeBranch: undefined };
}

export function isStableQaEnvironment(windowLocation = window.location) {
  return windowLocation.hostname === "stable.blueprint.qa-homebound.com";
}

export function percentOfSelectionsFinalized(pc: OverviewTabProjectCutoffFragment): undefined | number {
  const totalHoSelections = pc.homeownerSelections.length;
  if (!totalHoSelections) return undefined;
  const totalHoSelectionsFinalized = pc.homeownerSelections.filter(
    (hos) => hos.status.code === HomeownerSelectionStatus.Finalized,
  ).length;
  return (totalHoSelectionsFinalized / totalHoSelections) * 100;
}

/**
 * Util to print `3 days ago` or `in 1 day` by passing in a target date and
 * comparing to right now. Defaults to only counting business days.
 */
export function daysAgo(targetDate: Date, opts?: { businessDays: boolean }): string {
  const dateFn = opts?.businessDays === false ? differenceInCalendarDays : differenceInBusinessDays;
  const rtfl = new Intl.RelativeTimeFormat();
  const diff = dateFn(targetDate, new Date());
  if (diff === 0) return "today";
  return rtfl.format(diff, "day");
}

/**
 * Similar to `daysAgo` but returns depending on the current date.
 * For this week:
 *  - Today
 *  - Tomorrow
 *  - Wednesday
 *
 * For next week:
 *  - Mon, Oct 18
 *  - Tue, Oct 19
 *
 * After 2 weeks (no day of the week):
 * - Oct 25
 */
export function relativeDate(targetDate: Date, opts?: { businessDays: boolean }): string {
  const dateFn = opts?.businessDays === false ? differenceInCalendarDays : differenceInBusinessDays;
  const diff = dateFn(targetDate, new Date());

  // For past dates
  if (diff < 0) return daysAgo(targetDate, opts);

  // For today, tomorrow, and this week
  if (diff === 0) return "Today";
  if (diff === 1) return "Tomorrow";

  // For this week we need to know if the target date is in THIS week
  if (isThisWeek(targetDate)) return targetDate.toLocaleString("en-US", { weekday: "long" });

  // For next week and beyond
  if (getWeek(targetDate) === getWeek(new Date()) + 1)
    return targetDate.toLocaleString("en-US", { weekday: "short", month: "short", day: "numeric" });
  return targetDate.toLocaleString("en-US", { month: "short", day: "numeric" });
}

type InfiniteScrollOptions = {
  // In case the dataKey is not `entities` you can specify it here
  dataKey: string;
};

export function infiniteScroll({ dataKey = "entities" }: InfiniteScrollOptions | undefined = { dataKey: "entities" }) {
  return {
    keyArgs: (args: Record<string, any> | null) => {
      // Return all args keys except `page`
      const { page, ...rest } = args ?? {};
      return Object.keys(rest).sort();
    },
    merge(existing: any, incoming: any) {
      // In the event the incoming doesn't include the dataKey, we don't need to merge anything and can return the incoming
      if (!incoming[dataKey]) {
        return incoming;
      }

      const merged = existing ?? {
        [dataKey]: [],
        pageInfo: { hasNextPage: true },
      };

      const uniqueEntities = mergeEntities(merged[dataKey], incoming[dataKey]);
      return {
        ...incoming,
        [dataKey]: uniqueEntities,
      };
    },
  };
}

export function mergeEntities(existing: any[], incoming: any[]) {
  // Merge the new entities into the existing entities
  // And deduplicate the results
  const mergedEntities = [...existing, ...incoming];
  return mergedEntities.reduce((acc: any, entity: any) => {
    if (!acc.some((e: any) => e.__ref === entity.__ref)) {
      acc.push(entity);
    }
    return acc;
  }, []);
}

/**
 * Takes
 *   [
 *      [a1, a2],
 *      [b1, b2, b3],
 *   ]
 *
 * and reorganizes it by index to
 *   [
 *      [a1, b1],
 *      [a2, b2],
 *      [undefined, b3],
 *   ]
 */
export function transpose<T>(input: T[][]): (T | undefined)[][] {
  const maxLen = input.reduce((max, arr) => Math.max(arr.length, max), 0);
  const result = [];
  for (let i = 0; i < maxLen; i++) result.push(input.map((subArray) => subArray.at(i)));
  return result as any;
}

/**
 * This is a schema parser for the `search` query param.
 */
export const searchSchema = z.object({
  search: z.coerce.string().default(""),
});

/**
 * This is a schema parser for the `page` query param.
 */
export const pageSchema = z.object({
  offset: z.coerce.number().int().default(0),
  limit: z.coerce.number().int().positive().default(100),
});

/**
 * Returns a joined list of diabled reasons from a PotentialOperation2
 */
export function getDisabledReasons(operation: PotentialOperation2): string {
  return operation.disabledReasons.map((reason) => reason.message).join(", ");
}

export const cannotBeEmpty: Rule<InputMaybe<any[]>> = ({ value, key }) => {
  if (value?.isEmpty) {
    const name = key.replace(/s$/, "");
    return `At least one ${name} is required`;
  }
};

export function truncateString(str: string, num: number) {
  return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + "..." : str;
}

export function sortObjectKeys(obj: Record<string, any>) {
  return Object.entries(obj)
    .sortBy(([key]) => key)
    .toObject();
}

/** Returns a number as a string with the +/- sign for non-zero numbers */
export function numToStringWithSign(num: number) {
  return new Intl.NumberFormat("en-US", {
    signDisplay: "exceptZero",
  }).format(num);
}

/**
 * Returns tuple of name and 3 digit room number when present
 *
 * V2 locations of type ROOM have there room number stored as part of the name and not w/in its own field yet.
 **/
export function maybeSplitRoomNumber(name: string): [string, number | undefined] {
  const match = name.match(/^(.*?)\s*(\d{3})$/);
  if (match) {
    return [match[1].trim(), parseInt(match[2])];
  }
  return [name.trim(), undefined];
}
