import { Button, Css, ModalBody, ModalFooter, ModalHeader, Palette, useModal, useTestIds, Xss } from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { useEffect, useState } from "react";
import { Icon, UppyUploader } from "src/components";

export type PendingAsset = {
  s3Key: string;
  contentType: string | undefined;
  fileName: string;
  downloadUrl: string;
};

type ImageUploaderProps = {
  open?: boolean; // using to show modal in story
  imageUrl?: string;
  thumbnailUrl?: string;
  onChange: Function;
  viewOnly?: boolean; // show image but do not allow upload
  xss?: Xss<"width" | "height" | "borderRadius" | "objectFit">;
};

/** Project navigation button to upload, view and delete an image */
export function ImageUploader(props: ImageUploaderProps) {
  const { open, imageUrl, onChange, viewOnly, xss, thumbnailUrl = imageUrl } = props;
  const testIds = useTestIds({}, "imageUploader");
  const { openModal } = useModal();

  function openPhotoModal() {
    openModal({
      content: <PhotoModal imageUrl={imageUrl} onChange={onChange} viewOnly={!!viewOnly} />,
      size: "lg",
    });
  }

  // Used for storybook purposes to open the PhotoModal
  useEffect(
    () => {
      open && openPhotoModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, imageUrl, onChange, viewOnly],
  );

  return (
    <div css={{ ...Css.df.jcc.relative.$, ...xss }} {...testIds}>
      {/* Adding aria-hidden for this image as the alt attribute doesn't provide any context regarding the actual image.
            We should include a `description` field for images that can provide this alt text at some point. */}
      {imageUrl && (
        <img
          src={thumbnailUrl}
          loading="lazy"
          alt="Uploaded Image"
          aria-hidden="true"
          {...testIds.image}
          css={{ ...Css.objectCover.$, ...xss }}
        />
      )}
      <button
        {...testIds.button}
        type="button"
        css={{
          ...Css.p3.cursorPointer.outline0.absolute.add("transition", "background-color 0.2s, color 0.2s").$,
          ...(imageUrl ? { svg: { opacity: 0 } } : Css.bgGray300.$),
          ":hover": {
            ...Css.add("backgroundColor", "rgba(36,36,36,0.5)").$,
            ...(imageUrl ? { svg: { opacity: 1 } } : {}),
          },
          ...xss,
        }}
        onClick={() => openPhotoModal()}
      >
        <Icon icon="camera" color={Palette.White} xss={Css.add("margin", "auto").$} />
      </button>
    </div>
  );
}

type PhotoModalProps = {
  imageUrl: string | undefined;
  onChange: Function;
  viewOnly: boolean;
};

function PhotoModal(props: PhotoModalProps) {
  const { imageUrl, onChange, viewOnly } = props;
  const { closeModal } = useModal();
  const [modalMode, setModalMode] = useState<"view" | "upload">(imageUrl ? "view" : "upload");
  const [pendingImage, setPendingImage] = useState<PendingAsset | undefined>();

  async function handleFinish(file: UppyFile) {
    const { name, type, meta } = file;
    const { s3Key, downloadUrl } = meta;
    // Save asset information waiting to associate it with an image
    setPendingImage({
      s3Key: s3Key as string,
      contentType: type,
      fileName: name,
      downloadUrl: downloadUrl as string,
    });
    return Promise.resolve();
  }

  async function saveAssetImage() {
    const { s3Key, contentType, fileName, downloadUrl } = pendingImage!;
    onChange({ contentType, fileName, s3Key, downloadUrl });
  }

  useEffect(() => {
    setModalMode(imageUrl ? "view" : "upload");
  }, [imageUrl, setModalMode]);

  return (
    <>
      <ModalHeader>Photo</ModalHeader>
      <ModalBody>
        {modalMode === "view" ? (
          <img src={imageUrl} alt="uploader preview" css={Css.pt1.w100.maxwPx(600).$} />
        ) : (
          <>
            <p>Upload a jpg, gif or png.</p>
            {!pendingImage ? (
              <UppyUploader
                allowedFileTypes={["image/gif", "image/jpeg", "image/png"]}
                maxNumberOfFiles={1}
                onFinish={handleFinish}
                type="Dashboard"
              />
            ) : (
              <img src={pendingImage.downloadUrl} alt="uploader preview" css={Css.pt1.w100.$} />
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {modalMode === "view" ? (
          <>
            {!viewOnly && <Button variant="tertiary" label="Upload New Photo" onClick={() => setModalMode("upload")} />}
            <Button label="Close" onClick={closeModal} />
          </>
        ) : (
          <>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} />
            <Button
              label="Save"
              disabled={!pendingImage}
              onClick={async () => {
                await saveAssetImage();
                closeModal();
              }}
            />
          </>
        )}
      </ModalFooter>
    </>
  );
}
