import { SuperDrawerContent, SuperDrawerHeader } from "@homebound/beam";
import { CommentFeed } from "src/components";
import { useCommitmentOverviewCommentDrawerQuery } from "src/generated/graphql-types";

type OverviewCommentDrawerProps = {
  // TODO: make the compponent more generic, to take in any collaboration/parent ID
  commitmentId: string;
};

export function CommitmentOverviewCommentDrawer({ commitmentId }: OverviewCommentDrawerProps) {
  const query = useCommitmentOverviewCommentDrawerQuery({ variables: { commitmentId: commitmentId } });
  const commitment = query.data?.commitment;
  return (
    <SuperDrawerContent>
      <SuperDrawerHeader hideControls title="Comments" />
      {commitment && <CommentFeed commentable={commitment} showFollowers={false} showCommentTitle={false} />}
    </SuperDrawerContent>
  );
}
