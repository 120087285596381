import { Css, IconButton, navLink, useTestIds } from "@homebound/beam";
import React from "react";
import { Link } from "react-router-dom";

type TileTitleProps = {
  title: string;
  edit?: boolean;
  href?: string;
  actions?: React.ReactNode;
  adornment?: React.ReactNode;
};

export function TileTitle({ title, href, actions, adornment, edit = false }: TileTitleProps) {
  const tid = useTestIds({});
  const titleStyles = Css.xl2Bd.lineClamp1.wbbw.$;
  const linkable = href && !edit;
  return (
    <div css={Css.df.aic.jcsb.gap1.pPx(20).pb0.bgWhite.$}>
      <div css={Css.df.aic.gapPx(12).$}>
        {linkable ? (
          <h2>
            <Link to={href} css={{ ...titleStyles, ":hover": Css.tdu.$ }} className={navLink} {...tid.title}>
              {title}
            </Link>
          </h2>
        ) : (
          <h2 css={titleStyles} {...tid.title}>
            {title}
          </h2>
        )}
        {adornment && <div {...tid.titleAdornment}>{adornment}</div>}
      </div>
      <div css={Css.df.aic.gap1.$}>
        {actions && <div {...tid.actions}>{actions}</div>}
        {linkable && <IconButton icon="chevronRight" onClick={href} />}
      </div>
    </div>
  );
}
