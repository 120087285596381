import { useEffect } from "react";
import { useSideNavContext } from "src/components/layout/SideNavContext";

/** Used to hide the project "side nav" whenever a given component is mounted, then re-expands on cleanup */
export function useHiddenProjectSideNav() {
  const { setSideNavState } = useSideNavContext();
  useEffect(() => {
    setSideNavState("hidden");
    return () => setSideNavState("expanded");
  }, [setSideNavState]);
}
