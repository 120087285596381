import { Button, useModal } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useSaveReadyPlanOptionsMutation } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { AddReadyPlanOption } from "./utils";

type ArchiveOptionModalButtonProps = {
  optionId: string;
  formState?: ObjectState<AddReadyPlanOption>;
};

export function ArchiveOptionModalButton({ optionId, formState }: ArchiveOptionModalButtonProps) {
  const { openModal, closeModal } = useModal();
  const [save] = useSaveReadyPlanOptionsMutation();
  return (
    <Button
      variant="tertiaryDanger"
      label="Archive option"
      onClick={() =>
        openModal({
          content: (
            <ConfirmationModal
              confirmationMessage="Archiving an option will prompt the Procurement team to archive the corresponding scope template."
              onConfirmAction={async () => {
                await save({
                  variables: { input: { readyPlanOptions: [{ id: optionId, active: false }] } },
                });
                formState?.active.set(false);
                formState?.commitChanges();
                closeModal();
              }}
              title="Are you sure you want to archive this option?"
              label="Archive option"
              danger
            />
          ),
        })
      }
    />
  );
}
