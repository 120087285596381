import { useEffect } from "react";

export function useDocumentTitle(initialTitle?: string) {
  const suffix = " | Blueprint | Homebound";
  useEffect(() => {
    if (initialTitle) {
      document.title = `${initialTitle} ${suffix}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (newTitle: string) => (document.title = `${newTitle} ${suffix}`);
}
