import {
  BoundCheckboxField,
  BoundNumberField,
  BoundSelectField,
  BoundTextAreaField,
  Button,
  Css,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { ConstructionType, FloodPlain, FloodZone, FoundationType } from "src/generated/graphql-types";
import { LotDetailDrawerMetadata, LotFormInput, StatusChip } from "../utils";
import { RowField } from "./PlanInformationTab";

type LotInformationTabProps = {
  formState: ObjectState<LotFormInput>;
  metadata: LotDetailDrawerMetadata;
  readOnly: boolean;
  activeEditMode: () => void;
  disableEdit: boolean;
};

export function LotInformationTab({
  formState,
  metadata,
  readOnly,
  activeEditMode,
  disableEdit,
}: LotInformationTabProps) {
  return (
    <>
      <div css={Css.df.aic.jcsb.mb2.$}>
        <div css={Css.gray900.baseBd.$}>Lot Information</div>
        {!disableEdit && (
          <div css={Css.df.h4.$}>
            {readOnly ? (
              <Button variant="tertiary" label="Edit Configuration" onClick={() => activeEditMode()} />
            ) : undefined}
          </div>
        )}
      </div>
      <div css={Css.dg.gap2.gtc("repeat(1, 60% auto)").aic.if(!readOnly).mt3.$}>
        <RowField
          labelOnTop={!readOnly}
          label={
            <div>
              <span css={Css.df.gap1.$}>Lot Type</span>
            </div>
          }
        >
          <div>{<span css={Css.df.gap1.$}>{formState.lotType?.value}</span>}</div>
        </RowField>

        <RowField
          labelOnTop={!readOnly}
          label={
            <div>
              <span css={Css.df.gap1.$}>Client Name (or QMI)</span>
            </div>
          }
        >
          <div>
            {formState.clients.value?.map((client) => (
              <span key={client?.id} css={Css.df.gap1.$}>
                {client?.fullName}
              </span>
            ))}
          </div>
        </RowField>

        <RowField
          labelOnTop={!readOnly}
          label={<>Lot #{readOnly && <StatusChip updatedAt={metadata.updatedFields.lotNumber} />}</>}
        >
          <BoundNumberField compact label="Lot #" labelStyle="hidden" readOnly={readOnly} field={formState.lotNumber} />
        </RowField>
        <RowField
          labelOnTop={!readOnly}
          label={
            <>
              Block
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.block} />}
            </>
          }
        >
          <BoundNumberField compact label="Block" labelStyle="hidden" readOnly={readOnly} field={formState.block} />
        </RowField>
        <RowField
          labelOnTop={!readOnly}
          label={
            <>
              Section
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.section} />}
            </>
          }
        >
          <BoundNumberField compact label="Section" labelStyle="hidden" readOnly={readOnly} field={formState.section} />
        </RowField>
        <RowField
          label={
            <>
              Lot Square Footage
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.lotSquareFootage} />}
            </>
          }
        >
          <BoundNumberField
            compact
            label="Lot Square Footage"
            labelStyle="hidden"
            readOnly={readOnly}
            field={formState.lotSquareFootage}
          />
        </RowField>
        <RowField
          label={
            <>
              Flood Plain
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.floodPlain} />}
            </>
          }
        >
          <BoundSelectField
            required
            compact
            label="Flood Plain"
            labelStyle="hidden"
            field={formState.floodPlain}
            options={[
              { value: FloodPlain.Fp_0, label: "No" },
              { value: FloodPlain.Fp_100, label: "100 Years" },
              { value: FloodPlain.Fp_500, label: "500 Years" },
            ]}
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
          />
        </RowField>
        <RowField
          label={
            <>
              Flood Zone
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.floodZone} />}
            </>
          }
        >
          <BoundSelectField
            required
            compact
            label="Flood Zone"
            labelStyle="hidden"
            field={formState.floodZone}
            options={[
              { value: FloodZone.FzNone, label: "None" },
              { value: FloodZone.FzAe, label: "AE" },
              { value: FloodZone.FzCoastalA, label: "Coastal A" },
              { value: FloodZone.FzVe, label: "VE" },
            ]}
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
          />
        </RowField>
        <RowField
          label={
            <>
              Setback: Front
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.setBackFrontInFeet} />}
            </>
          }
        >
          <BoundNumberField
            compact
            label="Setback: Front"
            labelStyle="hidden"
            readOnly={readOnly}
            field={formState.setBackFrontInFeet}
            numFractionDigits={3}
          />
        </RowField>
        <RowField
          label={
            <>
              Setback: Right Side
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.setBackRightInFeet} />}
            </>
          }
        >
          <BoundNumberField
            compact
            label="Setback: Right Side"
            labelStyle="hidden"
            readOnly={readOnly}
            field={formState.setBackRightInFeet}
            numFractionDigits={3}
          />
        </RowField>
        <RowField
          label={
            <>Setback: Left Side {readOnly && <StatusChip updatedAt={metadata.updatedFields.setBackLeftInFeet} />}</>
          }
        >
          <BoundNumberField
            compact
            label="Setback: Left Side"
            labelStyle="hidden"
            readOnly={readOnly}
            field={formState.setBackLeftInFeet}
            numFractionDigits={3}
          />
        </RowField>
        <RowField
          label={
            <>Setback: Rear Side {readOnly && <StatusChip updatedAt={metadata.updatedFields.setBackRearInFeet} />}</>
          }
        >
          <BoundNumberField
            compact
            label="Setback: Rear Side"
            labelStyle="hidden"
            readOnly={readOnly}
            field={formState.setBackRearInFeet}
            numFractionDigits={3}
          />
        </RowField>
        <RowField label={<>HOA {readOnly && <StatusChip updatedAt={metadata.updatedFields.hoa} />}</>}>
          {readOnly ? (
            <div css={Css.sm.$}>{formState.hoa.value ? "Yes" : "No"}</div>
          ) : (
            <BoundCheckboxField field={formState.hoa} checkboxOnly={true} />
          )}
        </RowField>
        <RowField
          label={
            <>
              HOA Specific Modifications
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.hoaSpecificModifications} />}
            </>
          }
        >
          {readOnly && !formState.hoaSpecificModifications.value ? (
            <div css={Css.sm.gray400.$}>None</div>
          ) : (
            <BoundTextAreaField
              compact
              label="HOA Specific Modifications"
              labelStyle="hidden"
              readOnly={readOnly}
              field={formState.hoaSpecificModifications}
            />
          )}
        </RowField>
        <RowField
          label={
            <>
              Site Specific Modifications
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.siteSpecificModifications} />}
            </>
          }
        >
          {readOnly && !formState.siteSpecificModifications.value ? (
            <div css={Css.sm.gray400.$}>None</div>
          ) : (
            <BoundTextAreaField
              compact
              label="Site Specific Modifications"
              labelStyle="hidden"
              readOnly={readOnly}
              field={formState.siteSpecificModifications}
            />
          )}
        </RowField>
        <RowField
          label={
            <>Construction Type {readOnly && <StatusChip updatedAt={metadata.updatedFields.constructionType} />}</>
          }
        >
          <BoundSelectField
            required
            compact
            label="Construction Type"
            labelStyle="hidden"
            field={formState.constructionType}
            options={[
              { value: ConstructionType.TypeIii, label: "Type III" },
              { value: ConstructionType.TypeIv, label: "Type IV" },
              { value: ConstructionType.TypeV, label: "Type V" },
            ]}
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
          />
        </RowField>
        <RowField
          label={<>Foundation Type {readOnly && <StatusChip updatedAt={metadata.updatedFields.foundationType} />}</>}
        >
          <BoundSelectField
            required
            compact
            label="Foundation Type"
            labelStyle="hidden"
            field={formState.foundationType}
            options={[
              { value: FoundationType.Slab, label: "Slab" },
              { value: FoundationType.Elevated, label: "Elevated" },
              { value: FoundationType.Piers, label: "Piers" },
            ]}
            getOptionLabel={(o) => o.label}
            getOptionValue={(o) => o.value}
          />
        </RowField>
        <RowField
          label={
            <>
              Fire Sprinklers Required
              {readOnly && <StatusChip updatedAt={metadata.updatedFields.fireSprinklersRequired} />}
            </>
          }
        >
          {readOnly ? (
            <div css={Css.sm.$}>{formState.fireSprinklersRequired.value ? "Yes" : "No"}</div>
          ) : (
            <BoundCheckboxField field={formState.fireSprinklersRequired} checkboxOnly={true} />
          )}
        </RowField>
      </div>
    </>
  );
}
