import {
  actionColumn,
  AvatarGroup,
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  SuperDrawerWidth,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { useCallback, useMemo } from "react";
import { useRemoveUserGroupMutation, UserGroupFragment, useUserGroupsQuery } from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils";
import { UserGroupSuperDrawer } from "./UserGroupSuperDrawer";

export function UserGroupsPage() {
  const query = useUserGroupsQuery();
  return queryResult(query, ({ userGroups }) => <UserGroupsView userGroups={userGroups} />);
}

function UserGroupsView({ userGroups }: { userGroups: UserGroupFragment[] }) {
  const { openInDrawer } = useSuperDrawer();
  const { openModal } = useModal();
  const [removeUserGroup] = useRemoveUserGroupMutation();

  const handleRemove = useCallback(
    (id: string) => {
      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage="Are you sure you want to delete this user group?"
            onConfirmAction={() => removeUserGroup({ variables: { id }, refetchQueries: ["UserGroups"] })}
            title={"Remove user group"}
            label={"Remove"}
          />
        ),
      });
    },
    [openModal, removeUserGroup],
  );

  const rows = useMemo(() => createRows(userGroups ?? []), [userGroups]);
  const columns = useMemo(() => createColumns(handleRemove), [handleRemove]);

  const handleCreateUserGroup = useCallback(() => {
    openInDrawer({
      content: <UserGroupSuperDrawer />,
      width: SuperDrawerWidth.Small,
    });
  }, [openInDrawer]);

  const handleEditUserGroup = useCallback(
    (userGroup: UserGroupFragment) => {
      openInDrawer({
        content: <UserGroupSuperDrawer userGroup={userGroup} />,
        width: SuperDrawerWidth.Small,
      });
    },
    [openInDrawer],
  );

  return (
    <>
      <PageHeader
        title="User Groups &#x1F465;"
        right={<Button label="Create User Group" onClick={handleCreateUserGroup} />}
      />
      <ScrollableContent virtualized>
        <GridTable
          rows={rows}
          columns={columns}
          style={{}}
          as="virtual"
          stickyHeader
          rowStyles={{ data: { onClick: ({ data: ff }) => handleEditUserGroup(ff) } }}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<UserGroupFragment>;

function createRows(userGroups: UserGroupFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(userGroups);
}

function createColumns(handleRemove: (id: string) => void): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Name",
      data: ({ name }) => name,
    }),
    column<Row>({
      header: "Users",
      data: ({ users }) => <AvatarGroup avatars={users.map((u) => ({ src: u.internalUser?.avatar, name: u.name }))} />,
    }),
    actionColumn<Row>({
      header: "Actions",
      data: ({ id }) => (
        <IconButton
          icon="trash"
          onClick={() => {
            handleRemove(id);
          }}
        />
      ),
    }),
  ];
}
