import { column, Css, GridDataRow, GridTable, simpleHeader, useSuperDrawer } from "@homebound/beam";
import { History } from "history";
import { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";
import {
  CostType,
  TaskMaterialsPage_PlanTaskFragment,
  TaskMaterialsPage_ProjectItemFragment,
  usePlanTaskMaterialsPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { MaterialSuperDrawer } from "src/routes/libraries/material-catalog/components/material-super-drawer/MaterialSuperDrawer";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { dynamicSchedulesPath, projectsPath } from "src/routes/routesDef";
import {
  createDraftScheduleUrl,
  createTaskDetailsPageUrl,
  createTaskMaterialsPageUrl,
  createTaskMaterialsPageVariantDetailsUrl,
} from "src/RouteUrls";
import { queryResult } from "src/utils";

export function TaskMaterialsPage() {
  const { planTaskId, projectId } = useParams<{ planTaskId: string; projectId: string }>();
  const query = usePlanTaskMaterialsPageQuery({ variables: { taskId: planTaskId } });
  return queryResult(query, ({ planTask }) => {
    return <TaskMaterialsPageView planTask={planTask} projectId={projectId} />;
  });
}

type TaskMaterialsPageViewProps = {
  planTask: TaskMaterialsPage_PlanTaskFragment;
  projectId: string;
};

export function TaskMaterialsPageView({ planTask, projectId }: TaskMaterialsPageViewProps) {
  const { schedule, name, id, projectItems, globalPlanTask } = planTask;
  const superDrawerMatch = useRouteMatch<{ variantId: string }>([dynamicSchedulesPath.materialsDetails]);
  const variantId = superDrawerMatch?.params?.variantId;
  const { isDrawerOpen, openInDrawer, closeDrawer } = useSuperDrawer();
  const history = useHistory();

  const materialProjectItems = useMemo(
    () => projectItems.filter((pi) => pi.costType === CostType.Materials),
    [projectItems],
  );

  const pageTitle = useMemo(() => {
    const maybeDropName = globalPlanTask.developmentDrops.first?.name;
    return `Materials ${maybeDropName ? `- ${maybeDropName}` : ""}`;
  }, [globalPlanTask]);

  useEffect(() => {
    if (variantId && !isDrawerOpen) {
      openInDrawer({
        content: <MaterialSuperDrawer variantId={variantId} />,
        onClose: () => history.push(createTaskMaterialsPageUrl(projectId, id)),
      });
    }
    if (!variantId && isDrawerOpen) {
      closeDrawer();
    }
  }, [isDrawerOpen, variantId, openInDrawer, closeDrawer, history, id, projectId]);
  const rows = useMemo(() => createRows(materialProjectItems), [materialProjectItems]);
  const columns = useMemo(() => createColumns(projectId, id, history), [projectId, id, history]);
  return (
    <div data-testid="taskMaterialsPage">
      <PageHeader
        title={pageTitle}
        breadcrumb={[
          { label: "Projects", href: projectsPath },
          { label: schedule.parent.name, href: createDraftScheduleUrl(schedule.parent.id) },
          { label: name, href: createTaskDetailsPageUrl(projectId, id) },
        ]}
      />
      <GridTable
        columns={columns}
        rows={rows}
        fallbackMessage={`No Materials on Task ${name}`}
        rowStyles={{
          material: {
            cellCss: Css.smMd.$,
            rowLink: (row) =>
              row.data.itemTemplateItem?.materialVariant?.id
                ? createTaskMaterialsPageVariantDetailsUrl(projectId, id, row.data.itemTemplateItem.materialVariant.id)
                : "",
          },
        }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type MaterialRow = { kind: "material"; data: TaskMaterialsPage_ProjectItemFragment };
type Row = HeaderRow | MaterialRow;

function createRows(projectItems: TaskMaterialsPage_ProjectItemFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...projectItems.map((item) => ({
      id: item.id,
      kind: "material" as const,
      data: item,
    })),
  ];
}

function createColumns(projectId: string, taskId: string, history: History) {
  return [
    column<Row>({
      header: "Image",
      material: ({ itemTemplateItem }) => <MaterialImage itemTemplateItem={itemTemplateItem} />,
      w: "64px",
    }),
    column<Row>({
      header: "Material Name",
      material: (projectItem) => <div>{projectItem.name}</div>,
      w: 2,
    }),
    column<Row>({
      header: "Division",
      material: (projectItem) => <div>{projectItem.item.costCode.division.name}</div>,
    }),
    column<Row>({
      header: "Material Code",
      material: ({ itemTemplateItem }) => <div>{itemTemplateItem?.materialVariant?.code}</div>,
      mw: "260px",
    }),
    column<Row>({
      header: "Quantity",
      material: (projectItem) => <div>{projectItem.quantity}</div>,
    }),
    column<Row>({
      header: "SKU",
      material: ({ itemTemplateItem }) => <div>{itemTemplateItem?.materialVariant?.modelNumber}</div>,
    }),
    column<Row>({
      header: "Vendor",
      material: (projectItem) => <div>{projectItem.currentTradePartner?.name}</div>,
    }),
  ];
}

function MaterialImage({ itemTemplateItem }: Pick<TaskMaterialsPage_ProjectItemFragment, "itemTemplateItem">) {
  if (!itemTemplateItem?.materialVariant) return <div />;
  const { materialVariant } = itemTemplateItem;
  return (
    <div css={Css.df.aic.wPx(48).hPx(48).$}>
      <img
        src={materialVariant.featuredImage?.asset?.downloadUrl ?? PRODUCT_FALLBACK_IMG_URL}
        alt={materialVariant.code}
      />
    </div>
  );
}
