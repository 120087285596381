import { Maybe } from "src/generated/graphql-types";
import { isNumber } from "src/utils";
export type CostData = {
  actuals?: Maybe<number>;
  original?: Maybe<number>;
  awarded?: Maybe<number>;
  changeEvents?: Maybe<number>;
  nonChargeableChangeEvents?: Maybe<number>;
  chargeableChangeEvents?: Maybe<number>;
  proposedChangeEvents?: Maybe<number>;
  reallocations?: Maybe<number>;
  revisedInternalBudget?: Maybe<number>;
  revisedExternalBudget?: Maybe<number>;
  committed?: Maybe<number>;
  uncommitted?: Maybe<number>;
  tradeBilled?: Maybe<number>;
  tradePaidToDate?: Maybe<number>;
  unpaidCommitments?: Maybe<number>;
  projected?: Maybe<number>;
};

const costDataKeys: ReadonlyArray<keyof CostData> = [
  "original",
  "awarded",
  "changeEvents",
  "nonChargeableChangeEvents",
  "chargeableChangeEvents",
  "proposedChangeEvents",
  "reallocations",
  "revisedInternalBudget",
  "revisedExternalBudget",
  "committed",
  "uncommitted",
  "tradeBilled",
  "tradePaidToDate",
  "unpaidCommitments",
  "projected",
];

/** Combines two `CostData` (pre-derived values, i.e. we ignore revised and difference). */
export function sumCostData(a: CostData, b: CostData): CostData {
  return Object.fromEntries(
    costDataKeys.map((key) => {
      const keepUndefined = !isNumber(a[key]) && !isNumber(b[key]);
      if (keepUndefined) {
        return [key, undefined];
      }
      return [key, (a[key] || 0) + (b[key] || 0)];
    }),
  );
}
