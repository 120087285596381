import { BoundSelectField, Css } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { StatusIndicator } from "src/components/StatusIndicator";
import { WarrantyTicketItemStatus, WarrantyTicketItemStatusesFragment } from "src/generated/graphql-types";

export type WarrantyTicketItemStatusSelectProps = {
  options: WarrantyTicketItemStatusesFragment[];
  statusField: FieldState<WarrantyTicketItemStatus | null | undefined>;
  compact?: boolean;
};

export function WarrantyTicketItemStatusSelect(props: WarrantyTicketItemStatusSelectProps) {
  const { options, statusField, compact = true } = props;
  return (
    <BoundSelectField
      options={options}
      field={statusField}
      getOptionValue={(ts) => ts.code}
      getOptionLabel={(ts) => ts.name}
      fieldDecoration={(ts) => <StatusIndicator status={ts.code} />}
      getOptionMenuLabel={(ts) => (
        <div css={Css.df.aic.$}>
          <StatusIndicator status={ts.code} />
          <span css={Css.ml1.$}>{ts.name}</span>
        </div>
      )}
      compact={compact}
      labelStyle={compact ? "hidden" : undefined}
      borderless={compact}
    />
  );
}
