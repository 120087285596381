import { BoundSelectField } from "@homebound/beam";
import { useMemo } from "react";
import { TradePartnerOnboardingStatus, useTradePartnersQuery } from "src/generated/graphql-types";
import { FieldState } from "src/utils/formState";

type TradePartnerSelectFieldProps = {
  /** A useFormState() field such as `formState.tradePartner` */
  field: FieldState<string | null | undefined>;
  /** IDs of Trade Partners to disable */
  disabledOptions?: string[];
};

export function TradePartnerSelectField({ field, disabledOptions }: TradePartnerSelectFieldProps) {
  const { data, loading } = useTradePartnersQuery({ fetchPolicy: "cache-first" });

  const tradePartners = useMemo(() => {
    return (
      data?.tradePartners.filter(
        (tp) =>
          tp.onboardingStatus.code !== TradePartnerOnboardingStatus.Inactive &&
          tp.onboardingStatus.code !== TradePartnerOnboardingStatus.DoNotUse,
      ) ?? []
    );
  }, [data]);

  return (
    <BoundSelectField
      field={field}
      disabled={loading}
      label="Trade Partner"
      placeholder={loading ? "Loading..." : "Select a trade partner"}
      options={tradePartners}
      disabledOptions={disabledOptions}
    />
  );
}
