import { Css, useTestIds } from "@homebound/beam";

export function DefaultSpecsText() {
  const tid = useTestIds({});

  return (
    <div {...tid.defaultSpecsText} css={Css.mbPx(10).$}>
      For specifications that are not listed, please refer to authorized MSA, TSA, and current Construction Documents.
      Any specification/scope information clarification, please refer to Procurement.
    </div>
  );
}
