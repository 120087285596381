import { Css, useTestIds } from "@homebound/beam";
import { useParams } from "react-router";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { usePhotosTileQuery } from "src/generated/graphql-types";
import { EmptyTile } from "src/routes/projects/dashboard/components/EmptyTile";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { ProjectParams } from "src/routes/routesDef";
import { createProjectJobLogsGalleryUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function PhotosTile(props: TileBaseProps) {
  const { projectId } = useParams<ProjectParams>();
  const query = usePhotosTileQuery({ variables: { filter: { project: [projectId] }, limit: 5 } });
  const tid = useTestIds({}, "photosTile");
  return (
    <Tile {...props} xss={Css.pPx(12).$} href={createProjectJobLogsGalleryUrl(projectId)}>
      {queryResult(query, ({ jobLogImages }) => {
        const assets = jobLogImages.map((img) => img.asset);

        if (assets.length === 0) {
          return (
            <EmptyTile>
              <div css={Css.maxwPx(150).$}>No Job Log photos have been added</div>
            </EmptyTile>
          );
        }

        return (
          <AssetGallery assets={assets}>
            {(openGallery) => (
              <div css={Css.dg.gap1.w100.gtc("repeat(4, 1fr)").$}>
                {assets.map((asset, idx) => (
                  <button
                    onClick={() => openGallery(asset)}
                    key={asset.id}
                    css={Css.cursorPointer.oh.br8.if(idx === 0).gc("span 4").$}
                    aria-label="View Job Log photo"
                    {...tid.button}
                  >
                    <img
                      src={asset.previewUrl!}
                      loading="lazy"
                      alt="Job Log"
                      aria-hidden="true"
                      css={Css.objectCover.w100.hPx(idx === 0 ? 164 : 58).$}
                      {...tid.image}
                    />
                  </button>
                ))}
              </div>
            )}
          </AssetGallery>
        );
      })}
    </Tile>
  );
}
