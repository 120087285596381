import { FilterDefs, useSessionStorage } from "@homebound/beam";
import { useEffect, useMemo } from "react";
import { safeKeys } from "src/utils";
import { JsonParam, useQueryParams } from "use-query-params";

type PersistedFilterHook<F> = {
  filter: F;
  setFilter: (filter: F) => void;
};

type UsePersistedFilterProps<F> = {
  filterDefs: FilterDefs<F>;
  storageKey: string;
};

// Build a custom persisted filter to be able to create a unqiue query param key
// This allows us to avoid using the query param key: "filter" (beam's default param key name when usePersistedFilter is called)
// And prevents the budgetSupeDrawer activity tab filter, which also has a param key named "filter"
// from overriding the budget page's filter and blowing away the page's filter values by uniquing the keys

// TODO: create a persisted filter for beam that allows dynamic creation of param key names
export function useStorageFilter<F>({ storageKey, filterDefs }: UsePersistedFilterProps<F>): PersistedFilterHook<F> {
  const filterKeys = Object.keys(filterDefs);
  const defaultFilter = useMemo(
    () =>
      Object.fromEntries(
        safeEntries(filterDefs)
          .filter(([key, def]) => def(key as string).defaultValue !== undefined)
          .map(([key, def]) => [key, def(key as string).defaultValue]),
      ),
    [filterDefs],
  );
  const [params, setQueryParams] = useQueryParams({ [storageKey]: JsonParam });
  const queryParams = params[storageKey];
  const [storedFilter, setStoredFilter] = useSessionStorage<F>(storageKey, queryParams ?? defaultFilter);
  const isQueryParamFilterValid = hasValidFilterKeys(queryParams, filterKeys);
  const filter: F = isQueryParamFilterValid ? queryParams : (storedFilter ?? defaultFilter);

  const setFilter = (filter: F) => setQueryParams({ [storageKey]: filter });

  useEffect(
    () => {
      if (queryParams === undefined) {
        // if there is no filter in the query params, use stored filter
        // "replaceIn" replaces the url in history instead of creating a new history item
        // back button will go to previous url
        setQueryParams({ [storageKey]: storedFilter }, "replaceIn");
      } else if (!isQueryParamFilterValid) {
        // if there are invalid query params, fallback to the default filters
        setQueryParams({ [storageKey]: defaultFilter }, "replaceIn");
      } else if (JSON.stringify(queryParams) !== JSON.stringify(storedFilter)) {
        // if there is a valid filter in query params and its different from the
        // current storedFilter, use query params filter
        setStoredFilter(queryParams);
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedFilter, setStoredFilter, setQueryParams, queryParams],
  );

  return { setFilter, filter };
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

function safeEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}

// check for valid filter keys in the query params
function hasValidFilterKeys<F>(queryParamsFilter: F, definedKeys: (keyof F)[]): queryParamsFilter is F {
  return queryParamsFilter && safeKeys(queryParamsFilter).every((key) => definedKeys.includes(key as keyof F));
}
