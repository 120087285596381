import { Css, Palette, useTestIds } from "@homebound/beam";
import { Icon } from "src/components/Icon";
import { ChangeRequestStatus, ChangeRequestStatusDetail } from "src/generated/graphql-types";

const changeRequestStatusToColorMapper: Record<ChangeRequestStatus, string> = {
  [ChangeRequestStatus.NeedsReview]: Palette.Yellow200,
  [ChangeRequestStatus.ReadyForReview]: Palette.Yellow200,
  [ChangeRequestStatus.ReadyForApproval]: Palette.Yellow200,
  [ChangeRequestStatus.Approved]: Palette.Gray200,
  [ChangeRequestStatus.Denied]: Palette.Red100,
  [ChangeRequestStatus.Completed]: Palette.Green100,
  [ChangeRequestStatus.Reversed]: Palette.Yellow200,
  [ChangeRequestStatus.Blocked]: Palette.Red600,
};

export function ChangeRequestStatusPill({ status }: { status: ChangeRequestStatusDetail }) {
  const tid = useTestIds({}, "changeRequestStatus");
  return (
    <div css={Css.df.gap1.aic.$}>
      <Icon icon="status" inc={2} color={changeRequestStatusToColorMapper[status.code]} />
      <span css={Css.sm.$} {...tid.name}>
        {status.name}
      </span>
    </div>
  );
}
