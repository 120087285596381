import { Css, increment, Margin, Only, Padding, Palette, Properties } from "@homebound/beam";
import { CommentStreamCountDetailFragment } from "src/generated/graphql-types";
import { sum } from "src/utils/utils";

type CommentCountBubbleXStyles = Pick<Properties, Padding | Margin>;

type CommentCountBubbleProps<X> = {
  streams: CommentStreamCountDetailFragment[];
  onClick?: () => void;
  size?: number;
  xss?: X;
};

export function CommentCountBubble<X extends Only<CommentCountBubbleXStyles, X>>(props: CommentCountBubbleProps<X>) {
  const { streams, onClick, xss, size = 3 } = props;
  const commentCount = streams.map(({ commentCount }) => commentCount).reduce(sum, 0);
  const unreadCount = streams.map(({ unreadCount }) => unreadCount).reduce(sum, 0);
  const dimension = increment(size);
  const unread = unreadCount > 0;
  const count = unread ? unreadCount : commentCount;
  const hasComments = count > 0;
  const color = hasComments ? Palette.Gray800 : Palette.Gray500;

  return (
    <div
      css={{ ...Css.df.aic.if(!!onClick).cursorPointer.$, ...xss }}
      data-testid="commentBubble"
      onClick={(e) => {
        if (onClick) {
          // Allow the comment bubble to sit on top of other clickable elements (such as a table row) without triggering them.
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <svg
        aria-hidden={true}
        width={dimension}
        height={dimension}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          d="M12 2C6.486 2 2 5.589 2 10C2 12.908 3.898 15.516 7 16.934V22L12.34 17.995C17.697 17.852 22 14.32 22 10C22 5.589 17.514 2 12 2ZM12 16H11.667L9 18V15.583L8.359 15.336C5.67 14.301 4 12.256 4 10C4 6.691 7.589 4 12 4C16.411 4 20 6.691 20 10C20 13.309 16.411 16 12 16Z"
        />

        {unread && (
          <path
            d="M7.02564 4.15385C7.02564 5.73989 5.73989 7.02564 4.15385 7.02564C2.5678 7.02564 1.28205 5.73989 1.28205 4.15385C1.28205 2.5678 2.5678 1.28205 4.15385 1.28205C5.73989 1.28205 7.02564 2.5678 7.02564 4.15385Z"
            fill={Palette.Red600}
            stroke={Palette.White}
            stroke-width="2.5641"
          />
        )}
      </svg>
    </div>
  );
}
