import { FetchResult } from "@apollo/client/link/core";
import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { useState } from "react";
import { CsvRow, CsvUploader } from "src/components/CsvUploader";
import { isCsv } from "src/components/CsvUtils";
import { Loading } from "src/components/Loading";
import {
  CostConfidence,
  CostType,
  ImportProjectItemsModalMetadata_ItemFragment,
  ImportProjectItemsModalMetadata_UnitOfMeasureFragment,
  Named,
  ProjectItemInput,
  SaveProjectItemsMutation,
  useImportProjectItemsModalMetadataQuery,
  useSaveProjectItemsMutation,
} from "src/generated/graphql-types";
import { hasData } from "src/utils";

type ImportItemsModalProps = {
  onImport: (inputs: FetchResult<SaveProjectItemsMutation>) => Promise<void>;
  projectStageId: string;
  storybookProjectItems?: Partial<ProjectItemInput>[];
  storybookError?: string;
};

type ProjectItemRow = ProjectItemInput & { itemCode: string };

export function ImportProjectItemsModal(props: ImportItemsModalProps) {
  const { onImport, projectStageId, storybookProjectItems, storybookError } = props;
  const { closeModal } = useModal();
  const queryDataNeededForImport = useImportProjectItemsModalMetadataQuery();
  const [projectItems, setProjectItems] = useState<Partial<ProjectItemInput>[] | null>(storybookProjectItems ?? null);
  // We use null so that errors can be falsey
  const [errors, setErrors] = useState<string[] | null>(storybookError ? [storybookError] : null);
  const [saveProjectItems] = useSaveProjectItemsMutation();
  const { locations, unitsOfMeasure, items } = queryDataNeededForImport.data || {};

  function handleError(reason: string[]) {
    setErrors([...reason]);
    setProjectItems(null);
  }

  async function handlePrimaryClick() {
    try {
      const result = await saveProjectItems({ variables: { input: { projectItems: projectItems ?? [] } } });
      await onImport(result);
      closeModal();
    } catch (e: any) {
      handleError([e.message]);
    }
  }

  function handleOnDrop(data: CsvRow[], file?: { type: string; name: string }) {
    // Handle when an incorrect file is dropped. Note that we cannot detect the
    // dropped filetype until after processing.
    if (!isCsv(file)) {
      return handleError(["Incorrect file type. Please make sure the file is a .csv file"]);
    }
    // Clear error and projectItem otherwise
    setErrors(null);
    setProjectItems(null);

    const { headers, indexOfHeaders } = findHeaders(data);
    if (headers === undefined) {
      return handleError(["No headers founds; please check format of CSV and upload again"]);
    }
    const processedCsvRows = data.slice(indexOfHeaders + 1, data.length).map((row) =>
      processToProjectItemInput(row, {
        headers,
        unitsOfMeasure: unitsOfMeasure!,
        locations: locations!,
        items: items!,
        projectStageId,
      }),
    );
    const processingErrors = processedCsvRows.flatMap((pcr) => pcr.errors);
    const validProjectItemInputs = processedCsvRows
      .filter((pcr) => pcr.errors.length === 0)
      .map((pcr) => pcr.projectItemInput);
    if (validProjectItemInputs.length === 0) {
      processingErrors.push("No valid project items were found");
    }
    if (processingErrors.length) {
      return handleError(processingErrors);
    }
    setProjectItems(validProjectItemInputs);
  }

  const dataReady = hasData(queryDataNeededForImport);
  return (
    <>
      <ModalHeader>Import Specs & Selections</ModalHeader>
      <ModalBody>
        <div>Easily import specs and selections via a .csv file.</div>
        {!dataReady ? (
          <Loading />
        ) : !projectItems ? (
          <CsvUploader errors={errors || []} onError={(error) => handleError([error])} onDrop={handleOnDrop} />
        ) : (
          <div css={Css.mt3.$}>
            We have found {projectItems.length} project items to import! Click on `import` when you are ready!
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label="Import" disabled={!projectItems || projectItems?.length === 0} onClick={handlePrimaryClick} />
      </ModalFooter>
    </>
  );
}

/**
 * A fuzzy mapper to get and send our costType enum over the wire
 *
 * Exported for testing purposes
 * */
export function fuzzilyMapCostTypes(costType: string) {
  const ct = costType.toUpperCase();
  return Object.values(CostType).find((e) => e === ct || e.includes(ct) || ct.includes(e)) ?? CostType.Other;
}

export function findHeaders(rowData: CsvRow[]): {
  headers: CsvRow | undefined;
  indexOfHeaders: number;
} {
  const requiredHeaders = [
    "Item Name",
    "Item Code",
    "Cost Type",
    "Unit Cost",
    "Unit of Measure",
    "Units",
    "Cost",
    "Price",
    "Location",
  ];
  const indexOfHeaders = rowData.findIndex((row) =>
    requiredHeaders.every((requiredHeader) => row.data.map((v) => v?.trim()).includes(requiredHeader)),
  );
  return { headers: rowData[indexOfHeaders], indexOfHeaders };
}

type ProcessToProjectItemInputState = {
  headers: CsvRow;
  items: ImportProjectItemsModalMetadata_ItemFragment[];
  unitsOfMeasure: ImportProjectItemsModalMetadata_UnitOfMeasureFragment[];
  locations: Named[];
  projectStageId: string;
};

export function processToProjectItemInput(
  row: CsvRow,
  state: ProcessToProjectItemInputState,
): { projectItemInput: ProjectItemInput; errors: string[] } {
  const { headers, items, unitsOfMeasure, locations, projectStageId } = state;
  const errors: string[] = [...row.errors.map((e) => e.message), ...headers.errors.map((e) => e.message)];
  const dirtyInput = row.data.reduce((acc, cell, index) => {
    // Bail when the cell has no data.
    if (!cell.length) return acc;
    const key = csvKeyToProjectItemKey(headers.data[index]);
    const value = csvValueToProjectItemValue(key, cell, unitsOfMeasure, locations);
    // Check if the key is valid and the value is not undefined
    if (key?.length && value !== undefined) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
  return { projectItemInput: cleanProjectItemInput(dirtyInput, projectStageId, items, errors), errors };
}

/** Processes input based on whether project item will be a spec or selection */
function cleanProjectItemInput(
  projectItem: Partial<ProjectItemRow>,
  projectStageId: string,
  items: ImportProjectItemsModalMetadata_ItemFragment[],
  errors: string[],
): ProjectItemInput {
  const {
    name,
    costType,
    itemCode,
    quantity,
    unitCostInCents,
    totalCostInCents: totalCostInCentsFromCsv,
    totalPriceInCents: totalPriceInCentsFromCsv,
    unitOfMeasureId,
    ...others
  } = projectItem;
  if (unitOfMeasureId === undefined) {
    errors.push("Unit of Measure is a required field");
  }
  // If we have a quantity/unit cost, we will compute a total cost in cents, to use in the event totalCostInCents
  // was not specified in the CSV file. But we will generally prefer to use the totalCostInCents from the CSV when available
  const computedTotalCostInCents =
    typeof quantity === "number" && typeof unitCostInCents === "number" ? quantity * unitCostInCents : undefined;
  const totalCostInCents = totalCostInCentsFromCsv ?? computedTotalCostInCents;
  // When price is not specified in the CSV file, we will fallback to setting it to the cost
  const totalPriceInCents = totalPriceInCentsFromCsv ?? totalCostInCents;

  const item = items.find((i) => i.fullCode === itemCode);
  if (!item) {
    errors.push(`Item with code ${itemCode} could not be found`);
  }

  const baseProjectItem: ProjectItemInput = {
    name,
    costType,
    itemId: item?.id,
    unitOfMeasureId,
    quantity,
    projectStageId,
    costConfidence: CostConfidence.High, // NOTE: CostConfidence.High => Estimated
  };

  const isSelection = costType === "MATERIALS" && item?.isSelection;
  if (isSelection && item?.unitOfMeasure?.id !== unitOfMeasureId) {
    errors.push(`Selection with item ${itemCode} must use default unit of measure, ${item?.unitOfMeasure?.name};`);
  }

  return {
    ...baseProjectItem,
    totalCostInCents,
    totalPriceInCents,
    ...others,
  };
}

function csvKeyToProjectItemKey(key: string) {
  switch (key) {
    case "Item Code":
      return "itemCode";
    case "Item Name":
      return "name";
    case "Units":
      return "quantity";
    case "Unit of Measure":
      return "unitOfMeasureId";
    case "Cost Type":
      return "costType";
    case "Unit Cost":
      return "unitCostInCents";
    case "Cost":
      return "totalCostInCents";
    case "Price":
      return "totalPriceInCents";
    case "Location":
      return "locationId";
    default:
      return undefined;
  }
}

function csvValueToProjectItemValue(
  key: string | undefined,
  value: string,
  unitsOfMeasure: ImportProjectItemsModalMetadata_UnitOfMeasureFragment[],
  locations: Named[],
) {
  switch (key) {
    case undefined:
      return undefined;
    case "unitOfMeasureId":
      // Find the id of a unit that matches the name coming in from sage, otherwise ignore it
      const ciName = value.toLocaleLowerCase();
      const uom = unitsOfMeasure.find(
        (u) =>
          u.sageName?.toLocaleLowerCase() === ciName ||
          u.name.toLocaleLowerCase() === ciName ||
          u.abbreviation.toLocaleLowerCase() === ciName,
      );
      return uom?.id;
    case "costType":
      return fuzzilyMapCostTypes(value);
    case "locationId":
      // Find location with a name that matches the location name from sage, otherwise ignore the field
      const ciLocationName = value.toLocaleLowerCase();
      const location = locations.find((l) => l.name.toLocaleLowerCase() === ciLocationName);
      return location?.id || undefined;
    case "totalCostInCents":
    case "totalPriceInCents":
    case "unitCostInCents":
      return Math.round(removeCommas(value) * 100);
    case "quantity":
      return Math.round(removeCommas(value));
    default:
      return value;
  }
}

function removeCommas(toParse: string) {
  return parseFloat(toParse.replace(/[$,]/g, ""));
}
