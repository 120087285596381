import { Css, useTestIds } from "@homebound/beam";
import { DocumentEditorDetailFragment } from "src/generated/graphql-types";
import { bytesToSize, getIconKeyByFileName } from "src/utils/files";
import { Icon } from ".";

type FileListProps = {
  id?: string;
  files: Partial<DocumentEditorDetailFragment>[];
};

export function FileList({ id = "fileList", files }: FileListProps) {
  const testIds = useTestIds({}, id);
  return (
    <ul css={Css.df.fdc.gap2.listReset.$} {...testIds}>
      {files.map((file) => (
        // Using `url` for the key, as that should be unique across all files.
        <li css={Css.df.$} key={file.id}>
          <Icon icon={getIconKeyByFileName(file.name || "")} />
          <div css={Css.ml1.w("inherit").$}>
            <a href={file.asset?.downloadUrl || "#"} target="_blank" rel="noreferrer" {...testIds.link}>
              {file.name}
            </a>
            {file.sizeInBytes && (
              <div css={Css.tinySb.gray700.$} {...testIds.size}>
                {bytesToSize(file.sizeInBytes)}
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
