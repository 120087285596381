import {
  BoundSelectField,
  Button,
  Checkbox,
  column,
  Css,
  dateColumn,
  dateFilter,
  DateFilterValue,
  Filters,
  FullBleed,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  LoadingSkeleton,
  multiFilter,
  selectColumn,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Location } from "history";
import { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { dateCell, emptyCellDash } from "src/components";
import { StepActions } from "src/components/stepper";
import {
  ApplyStep_ProjectToUpdateFragment,
  DateOperation,
  ItemTemplateStatus,
  Maybe,
  ProjectReadyPlanConfigVersion,
  ScopeTemplatesApplyStepQuery,
  useScopeTemplateApplyStepApplyToProjectMutation,
  useScopeTemplatesApplyStepMetadataQuery,
  useScopeTemplatesApplyStepQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { createDevelopmentScopeTemplatesUrl, createProjectDashboardUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";
import { openNewTab } from "src/utils/window";
import { Header } from "./Header";

export function ApplyToProjectsStep() {
  const tid = useTestIds({}, "publishStep");
  const history = useHistory();
  const { triggerNotice } = useSnackbar();
  const { developmentId } = useParams<{ developmentId: string }>();
  const { state } = useLocation<{ itemTemplatesIds?: string[]; from?: Location<unknown> }>();
  const { id, from } = useMemo(() => ({ id: state?.itemTemplatesIds ?? [], from: state?.from }), [state]);
  const [applyToProjects] = useScopeTemplateApplyStepApplyToProjectMutation();
  const { data: milestones } = useScopeTemplatesApplyStepMetadataQuery({
    variables: { input: { templates: id } },
  });
  const groupedMilestones = useMemo(
    () => (milestones?.milestoneTasksForTemplates ?? []).groupBy(({ name }) => name),
    [milestones],
  );

  const [projectsFilter, setProjectsFilter] = useState<ApplyPublishedTemplatesFilter>({});
  const projectsFilterDefs = useMemo(
    () => ({
      projectMilestone: multiFilter({
        options: Object.keys(groupedMilestones).map((name) => ({
          name,
          id: name,
        })),
        label: "Project Milestone",
        getOptionLabel: (c) => c.name,
        getOptionValue: (c) => c.id,
      }),
      startingOn: dateFilter({
        operations: [
          { label: "On", value: DateOperation.On },
          { label: "After", value: DateOperation.After },
        ],
        label: "Starting on/after",
        getOperationLabel: (o) => o.label,
        getOperationValue: (o) => o.value,
        // Providing a default value, otherwise the default date in the DateField will be today's date, which will cause storybook diffs every day.
        defaultValue: { op: DateOperation.After, value: new Date() },
      }),
    }),
    [groupedMilestones],
  );

  const projectsQuery = useScopeTemplatesApplyStepQuery({
    variables: {
      input: {
        templates: id,
        milestones: projectsFilter.projectMilestone?.flatMap((key) => groupedMilestones[key].map(({ id }) => id)),
        milestoneStartDate: projectsFilter.startingOn
          ? {
              op: projectsFilter.startingOn.op as DateOperation,
              value: new DateOnly(projectsFilter.startingOn.value),
            }
          : undefined,
      },
    },
  });
  const { data, loading } = projectsQuery;
  const formState = useFormState({
    config: formConfig,
    init: {
      query: projectsQuery,
      map: ({ projectsToUpdateByTemplates }) => ({
        projects: projectsToUpdateByTemplates.projectsToUpdate.map((pToUpdate) => ({
          id: pToUpdate.project.id,
          onlyChangedInVersion: true,
        })),
      }),
      ifUndefined: { projects: [] },
    },
  });

  const [autoAcceptChangeEvents, setAutoAcceptChangeEvents] = useState(false);

  const tableApi = useGridTableApi<Row>();
  const selectedRows = useComputed(() => tableApi.getSelectedRows("project") ?? [], [tableApi]);
  const columns = useMemo(() => createColumns(), []);
  const formRows = useComputed(() => formState.projects.rows, [formState]);
  const rows = useMemo(() => createRows(data, formRows), [data, formRows]);

  const allProposedTemplatesActive = useMemo(
    () =>
      data &&
      data.projectsToUpdateByTemplates.projectsToUpdate.every(
        (ptu) => ptu.proposedTemplate.status === ItemTemplateStatus.Active,
      ),
    [data],
  );

  function exit() {
    history.push(from ? `${from.pathname}${from.search}` : createDevelopmentScopeTemplatesUrl(developmentId));
  }

  async function apply() {
    const toUpdate: ProjectReadyPlanConfigVersion[] =
      selectedRows.map(({ data: { project, proposedTemplate, objectState } }) => ({
        itemTemplateId: proposedTemplate.id,
        prpcId: project.readyPlanConfig!.id,
        onlyChangedInVersion: objectState?.onlyChangedInVersion.value ?? true,
      })) ?? [];
    const result = await applyToProjects({
      variables: { input: { prpcAtVersions: toUpdate, autoAcceptChangeEvents } },
    });
    triggerNotice({
      message: `Templates successfully applied to ${
        result.data?.applyProjectReadyPlanConfigs.projectReadyPlanConfigs.length ?? ""
      } projects`,
      icon: "success",
    });
    exit();
  }

  return (
    <div>
      <Header
        preHeader="Apply new template versions"
        header="Apply to Projects"
        postHeader="Select the projects below you wish to apply the new scope template versions to."
      />
      <FullBleed>
        <div css={Css.df.aifs.gap5.bgGray100.mh("61vh").p3.pbPx(100).$}>
          <div css={Css.fg1.mwPx(880).bgWhite.br8.$} {...tid.apply}>
            <div css={Css.px3.py2.$}>
              <TableActions>
                <Filters<ApplyPublishedTemplatesFilter>
                  filterDefs={projectsFilterDefs}
                  filter={projectsFilter}
                  onChange={setProjectsFilter}
                />
                <div css={Css.gray900.smMd.$} {...tid.selectedRows}>
                  {selectedRows.length} selected
                </div>
              </TableActions>
              {loading ? (
                <>
                  <LoadingSkeleton rows={1} columns={1} />
                  <LoadingSkeleton rows={5} columns={3} />
                </>
              ) : (
                <GridTable
                  api={tableApi}
                  columns={columns}
                  rows={rows}
                  stickyHeader
                  style={{ vAlign: "top", rowHeight: "flexible" }}
                  fallbackMessage="All projects are up to date."
                />
              )}
            </div>
          </div>
        </div>
      </FullBleed>
      <StepActions>
        <>
          <Button size="lg" variant="secondary" label="Exit" onClick={exit} {...tid.exit} />
          <Checkbox
            selected={autoAcceptChangeEvents}
            label="Auto Accept Change Events"
            onChange={setAutoAcceptChangeEvents}
          />
          <Button
            size="lg"
            variant="primary"
            label="Apply"
            disabled={!selectedRows.length || !allProposedTemplatesActive}
            onClick={apply}
            {...tid.applyButton}
            tooltip={
              !allProposedTemplatesActive
                ? "All templates must be active to apply. Go back to the Review and Publish step and press Publish and Continue."
                : selectedRows.isEmpty
                  ? "To apply choose one or more project."
                  : undefined
            }
          />
        </>
      </StepActions>
    </div>
  );
}

type ApplyPublishedTemplatesFilter = {
  projectMilestone?: string[];
  startingOn?: DateFilterValue<string>;
};

type HeaderRow = { kind: "header"; id: string };
// TODO: Add proper type when the query is made
type ProjectRow = {
  kind: "project";
  data: ApplyStep_ProjectToUpdateFragment & { objectState?: ObjectState<ProjectFields> };
};
type Row = HeaderRow | ProjectRow;

function createColumns(): GridColumn<Row>[] {
  return [
    selectColumn<Row>({}),
    column<Row>({
      header: "Project",
      project: ({ project }) => ({
        content: project.name,
        onClick: () => openNewTab(createProjectDashboardUrl(project.id)),
      }),
      w: "200px",
    }),
    dateColumn<Row>({
      header: "Construction Start",
      project: ({ project }) =>
        project.readyPlanConfig
          ? dateCell(project.readyPlanConfig?.constructionStartDate, { xss: Css.gray900.$ })
          : emptyCellDash,
      w: "140px",
    }),
    column<Row>({
      header: {
        content: () => (
          <>
            Apply changes from{"  "}
            <Icon
              icon={"infoCircle"}
              tooltip="Choose to apply line item changes from the latest major version or cumulative changes from ALL in-between versions."
            />
          </>
        ),
      },
      project: ({ objectState }) => ({
        content: objectState ? (
          <BoundSelectField
            field={objectState.onlyChangedInVersion}
            options={[
              { name: "Changes Only", value: true },
              { name: "All Lines", value: false },
            ]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            label={"Apply Changes From"}
            labelStyle="hidden"
            data-testid="applyChangesFrom"
          />
        ) : (
          "-"
        ),
      }),
      w: "165px",
    }),
  ];
}

function createRows(
  data: ScopeTemplatesApplyStepQuery | undefined,
  formRows: readonly ObjectState<ProjectFields>[],
): GridDataRow<Row>[] {
  const stateById = formRows.keyBy((row) => row.id.value);
  const rows: GridDataRow<Row>[] =
    data?.projectsToUpdateByTemplates.projectsToUpdate.map((project) => ({
      id: project.project.id,
      kind: "project",
      data: {
        ...project,
        objectState: stateById[project.project.id],
      },
    })) ?? [];
  return [simpleHeader, ...rows];
}

type ProjectFields = {
  id: string;
  onlyChangedInVersion: Maybe<boolean>;
};

// A small form just to hold the `onlyChangedInVersion` toggle.
const formConfig: ObjectConfig<{ projects: ProjectFields[] }> = {
  projects: {
    type: "list",
    config: {
      id: { type: "value" },
      onlyChangedInVersion: { type: "value" },
    },
  },
};
