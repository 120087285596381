import {
  Button,
  column,
  Css,
  defaultStyle,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  SelectField,
  simpleHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { dateCell, percentageCell } from "src/components/gridTableCells";
import {
  OverviewTabCutoffsQuery,
  OverviewTabProjectCutoffFragment,
  TaskStatus,
  useOverviewTabCutoffsQuery,
  useSetupModalSaveProjectCutoffMutation,
} from "src/generated/graphql-types";
import { percentOfSelectionsFinalized, queryResult } from "src/utils";
import { DraftChangeEventDrawer } from "./DraftChangeEventDrawer";

export function OverviewTab({ projectId, projectStageId }: { projectId: string; projectStageId: string }) {
  const query = useOverviewTabCutoffsQuery({ variables: { projectId } });

  return queryResult(query, {
    data: (result) => {
      return <OverviewTabTableDataView data={result} projectId={projectId} projectStageId={projectStageId} />;
    },
  });
}

type OverviewTabTableDataViewProps = {
  data: OverviewTabCutoffsQuery;
  projectId: string;
  projectStageId: string;
};

function OverviewTabTableDataView({ data, projectId, projectStageId }: OverviewTabTableDataViewProps) {
  const { project, tasks } = data;
  const { cutoffs: projectCutoffs } = project;
  const { openDrawerDetail } = useSuperDrawer();
  const [saveProjectCutoff] = useSetupModalSaveProjectCutoffMutation();

  // disable option if task status is complete
  const completedTaskIds = tasks.filter((t) => t.status.code === TaskStatus.Complete).map((t) => t.id);

  const columns: GridColumn<Row>[] = [
    column<Row>({
      header: "Cutoff Group",
      projectCutoff: (pc) => pc.name,
    }),
    column<Row>({
      header: "Schedule Task",
      projectCutoff: (pc) => (
        <SelectField
          data-testid="scheduleTask"
          disabledOptions={completedTaskIds}
          compact
          labelStyle="hidden"
          label={pc.name}
          value={pc.task?.id}
          options={tasks}
          onSelect={async (taskId) => {
            await saveProjectCutoff({
              variables: { input: { id: pc.id, taskId } },
            });
          }}
        />
      ),
    }),
    column<Row>({
      header: "Cutoff Date",
      projectCutoff: (pc) => dateCell(pc.task?.interval.endDate),
      align: "right",
      w: "120px",
    }),
    numericColumn<Row>({
      header: "% Finalized",
      projectCutoff: (pc) => percentageCell(percentOfSelectionsFinalized(pc)),
      w: "120px",
    }),
    column<Row>({
      header: "Contract Status",
      projectCutoff: (pc) => {
        const percentFinalized = percentOfSelectionsFinalized(pc);
        return (
          <Button
            disabled={!percentFinalized || (percentFinalized && percentFinalized < 100) || !pc.canChangeScope}
            label="Create Change Event"
            onClick={() => {
              openDrawerDetail({
                content: (
                  <DraftChangeEventDrawer projectCutoff={pc} projectId={projectId} projectStageId={projectStageId} />
                ),
              });
            }}
            variant="tertiary"
          />
        );
      },
      align: "right",
      w: "184px",
    }),
  ];
  const rows: GridDataRow<Row>[] = mapRows(projectCutoffs);

  return (
    <GridTable
      columns={columns}
      fallbackMessage="No cutoffs found."
      rows={rows}
      style={{ ...defaultStyle, ...{ cellCss: { ...defaultStyle.cellCss, ...Css.aic.$ } } }}
    />
  );
}

type Row = { kind: "header" } | { kind: "projectCutoff"; data: OverviewTabProjectCutoffFragment };

function mapRows(projectCutoffs: OverviewTabProjectCutoffFragment[]) {
  return [simpleHeader, ...projectCutoffs.map((pc) => ({ kind: "projectCutoff" as const, id: pc.id, data: pc }))];
}
