import { useBudgetActivityTabMetaQuery, User } from "src/generated/graphql-types";
import { ActivityFeedTable } from "src/routes/my-blueprint/activity-feed/ActivityFeedTable";
import { queryResult } from "src/utils";

export function BudgetActivityTab({ projectId, parentIds }: { projectId: string; parentIds: string[] }) {
  // The current users, internal user, and userEvenParentTypes is fairly static, so use cache-first if the data already exists
  const query = useBudgetActivityTabMetaQuery({ variables: { projectId }, fetchPolicy: "cache-first" });

  return queryResult(query, {
    data: ({ currentUser, internalUsers, userEventParentTypes }) => {
      return (
        <ActivityFeedTable
          currentUser={currentUser as User}
          users={internalUsers}
          projectId={projectId}
          parentIds={parentIds}
          types={userEventParentTypes.filter((t) => ["c", "cco", "b", "ce"].includes(t.id))}
          allowedEntities={["c", "cco", "b", "ce"]}
        />
      );
    },
  });
}
