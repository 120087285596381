import { useComputed, useModal } from "@homebound/beam";
import { useMemo } from "react";
import { AddLineItemButton } from "src/components";
import { InvoiceEditorInvoiceFragment, Maybe, useContractLineItemsQuery } from "src/generated/graphql-types";
import { AddInvoiceItemModal } from "src/routes/projects/invoices/AddInvoiceItemModal";
import { InvoiceFormState } from "src/routes/projects/invoices/InvoiceEditor";
import { buildLineItemsForProjectItem } from "src/routes/projects/invoices/utils";
import { hasData } from "src/utils";

type AddContractLineItemButtonProps = {
  lineItems: InvoiceFormState["lineItems"];
  projectId: string;
  projectStageId: Maybe<string>;
  invoice: InvoiceEditorInvoiceFragment | undefined;
  invoiceV2: boolean;
};

export function AddContractLineItemButton(props: AddContractLineItemButtonProps) {
  const { lineItems, projectId, projectStageId, invoice, invoiceV2 } = props;
  const disabled = !projectStageId;
  const query = useContractLineItemsQuery({
    variables: { projectId, projectStageId: projectStageId! },
    skip: disabled,
  });
  const { openModal } = useModal();

  const lineItemsByProjectItem = query.data?.project.stages[0]?.homeownerContracts[0].lineItemsByProjectItem;

  // Note this is only PIs that have non-draw commitment line items.
  const allProjectItems = useMemo(() => {
    return lineItemsByProjectItem?.flatMap((cpi) => cpi.projectItem);
  }, [lineItemsByProjectItem]);

  // Keep track of project items that have not yet been added to this invoice
  const availableProjectItems = useComputed(() => {
    const set = new Set(
      lineItems.rows.filter((row) => !row.delete.value).map(({ projectItemId }) => projectItemId.value),
    );
    return allProjectItems?.filter((pi) => !set.has(pi.id));
  }, [allProjectItems, lineItems]);

  return (
    <AddLineItemButton
      id="addContractLineItem"
      label={"Contract Line Item"}
      list={lineItems}
      onClick={() =>
        openModal({
          content: (
            <AddInvoiceItemModal
              // `availableProjectItems` should be populated due to the `disabled` logic for the button.
              projectItems={availableProjectItems ?? []}
              onAdd={(projectItemId) => {
                buildLineItemsForProjectItem(projectItemId, lineItemsByProjectItem ?? [], invoice).forEach((li) => {
                  lineItems.add(li);
                });
              }}
            />
          ),
        })
      }
      disabled={
        invoiceV2 || disabled || !hasData(query) || (availableProjectItems && availableProjectItems.length === 0)
      }
    />
  );
}
