import {
  BoundSelectField,
  Button,
  Css,
  GridColumn,
  GridTable,
  RowStyles,
  SimpleHeaderAndData,
  column,
  numericColumn,
  selectColumn,
  simpleDataRows,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { useMemo } from "react";
import { BannerNotice, chipCell, dateCell, linkHeader, priceCell, tagCell } from "src/components";
import { StepActions } from "src/components/stepper";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { WizardHeader } from "src/components/stepper/useStepperWizard/WizardHeader";
import {
  AssignWorkToBidContractInput,
  CommitmentStatus,
  Maybe,
  VoidPOsStep_CommitmentFragment,
  useAssignWorkToBidContractsMutation,
  useVoidPOsStepQuery,
} from "src/generated/graphql-types";
import { commitmentStatusToDevelopmentTagTypeMapper, count, queryResult } from "src/utils";
import { useManagePosWizardContext } from "./useManagePosWizard";

export function VoidPOsStep() {
  const { bcrId } = useManagePosWizardContext();
  const query = useVoidPOsStepQuery({ variables: { id: bcrId }, fetchPolicy: "network-only" });
  return (
    <WizardHeader
      pre="Manage Purchase Orders"
      header="Select POs to void"
      post="Select any existing purchase orders (POs) that you would like to void. Only unpaid POs can be voided."
      uncloseable
    >
      {queryResult(query, (data) => (
        <VoidPOsStepTable
          commitments={data.bidContractRevision.bidContract.revisions.flatMap((bcr) => bcr.commitments)}
        />
      ))}
    </WizardHeader>
  );
}

type VoidPOsStepTableProps = {
  commitments: VoidPOsStep_CommitmentFragment[];
};

function VoidPOsStepTable({ commitments }: VoidPOsStepTableProps) {
  const { setChangeEventIds, bcrId } = useManagePosWizardContext();
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const api = useGridTableApi<Row>();
  const { getFormState } = useFormStates<FormValue, VoidPOsStep_CommitmentFragment>({
    config: formConfig,
    map: (input) => ({
      commitmentId: input.id,
      bcrId: input.preferredBidContractRevision?.bidContractRevision.id || bcrId,
    }),
    getId: (c) => c.id,
  });

  const onlyRelevantCommitments = useMemo(
    () =>
      commitments.filter(
        (c) =>
          c.canBeVoided.allowed &&
          // Not already voided
          c.status !== CommitmentStatus.Voided &&
          // and has a preferred contract
          c.preferredBidContractRevision &&
          // and this contract isn't already set
          c.bidContractRevision?.id !== bcrId &&
          // and it's not already set to be voided by another commitment related to this BCR
          c.eventuallyVoidedBy?.bidContractRevision?.id !== bcrId &&
          // and this BCR is a potential option (assumed), and actually changes the cost
          c.potentialBidContractRevisions.some(
            (pbcr) => pbcr.bidContractRevision.id === bcrId && pbcr.totalCostInCents !== c.costChangeInCents,
          ),
      ),
    [bcrId, commitments],
  );

  const inputs = useComputed(
    () =>
      api
        .getSelectedRows("data")
        // only values that actually changed
        .filter((c) => c.data.bidContractRevision?.id !== getFormState(c.data).bcrId.value)
        .map<AssignWorkToBidContractInput>((c) => ({
          autoRelease: true,
          commitmentId: getFormState(c.data).commitmentId.value,
          bidContractRevisionId: getFormState(c.data).bcrId.value,
        })),
    [onlyRelevantCommitments, getFormState],
  );
  const [assignWork] = useAssignWorkToBidContractsMutation({ variables: { input: inputs } });

  const columns = useMemo(() => createColumns(getFormState), [getFormState]);
  const rows = useMemo(
    () => simpleDataRows(onlyRelevantCommitments).map((r) => ({ ...r, initSelected: true })),
    [onlyRelevantCommitments],
  );

  return (
    <>
      <div css={Css.mt3.$}>
        <BannerNotice
          icon="infoCircle"
          message={
            <>
              POs that don't match budgeted costs will <strong>be automatically voided</strong> after budget updates are
              approved
            </>
          }
        />
      </div>
      {/* TODO: Filters */}
      <GridTable<Row>
        api={api}
        rows={rows}
        columns={columns}
        rowStyles={rowStyles}
        fallbackMessage="No available purchase orders versions to manage"
      />
      {onlyRelevantCommitments.isEmpty ? (
        <StepActions>
          <Button label="Exit" onClick={closeModal} variant="secondary" size="lg" />
        </StepActions>
      ) : (
        <NextStepButton
          label="Continue to Release"
          disabled={inputs.isEmpty}
          disableCurrentStepOnLeaving
          onClick={async () => {
            const { data, errors } = await assignWork();
            // Banner is likely set from this error, so close the modal/wizard to reveal it
            if (errors?.nonEmpty) return closeModal();
            const ces = data?.assignWorkToBidContract ?? [];
            if (ces.isEmpty) {
              triggerNotice({
                message: `${count(inputs, "Commitment")} updated succesfully.`,
                icon: "success",
              });
            } else {
              triggerNotice({
                icon: "success",
                message:
                  ces.length !== inputs.length
                    ? // "3 Commitments updated, 2 require Approval to changes on the budget"
                      `${count(inputs, "Commitment")} updated, ${ces.length} require Approval to changes on the budget`
                    : // "3 Commitments updated and require Approval to budget changes"
                      `${count(inputs, "Commitment")} updated and require Approval to budget changes`,
              });
            }

            setChangeEventIds(data?.assignWorkToBidContract.map((ce) => ce.id) ?? []);
          }}
        />
      )}
    </>
  );
}

type FormValue = { commitmentId: string; bcrId: Maybe<string> };

const formConfig: ObjectConfig<FormValue> = {
  commitmentId: { type: "value" },
  bcrId: { type: "value" },
};

type Row = SimpleHeaderAndData<VoidPOsStep_CommitmentFragment>;
const createColumns: (
  getFormState: (input: VoidPOsStep_CommitmentFragment) => ObjectState<FormValue>,
) => GridColumn<Row>[] = (getFormState) => [
  selectColumn(),
  column<Row>({
    header: "PO#",
    data: (c) => linkHeader(String(c.accountingNumber), c.blueprintUrl.path),
  }),
  column<Row>({
    header: "Address",
    data: (c) => c.project.name,
  }),
  column<Row>({
    header: "Cost Codes",
    data: (c) => chipCell(c.costCodes.map((cc) => cc.number)),
  }),
  column<Row>({
    header: "Release Date",
    data: (c) => dateCell(c.executionDate),
  }),
  column<Row>({
    header: "Status",
    data: (c) => tagCell(commitmentStatusToDevelopmentTagTypeMapper[c.status], c.statusText),
  }),
  // vertical border
  numericColumn<Row>({
    header: "Current Version",
    data: (c) => c.bidContractRevision?.version,
  }),
  numericColumn<Row>({
    header: "Committed Cost",
    data: (c) => priceCell({ valueInCents: c.costChangeInCents }),
  }),
  // vertical border
  column<Row>({
    header: "Proposed Version",
    data: (c) => (
      <BoundSelectField
        label="Contract Version"
        labelStyle="hidden"
        options={c.potentialBidContractRevisions.map((bcr) => ({
          id: bcr.bidContractRevision.id,
          name: bcr.bidContractRevision.version,
        }))}
        field={getFormState(c).bcrId}
        readOnly // They came here from `bcr:4` so don't let them switch away from that because it'd be difficult to support
      />
    ),
  }),
  numericColumn<Row>({
    header: "Proposed Cost",
    data: (c) =>
      priceCell({
        valueInCents:
          c.potentialBidContractRevisions.find((pBcr) => pBcr.bidContractRevision.id === getFormState(c).bcrId.value)
            ?.totalCostInCents ?? 0,
      }),
  }),
];

// Add border to split between previous / proposed values
const cellBorderStyles = Css.addIn("& > div:nth-of-type(7)", Css.bl.bcGray400.$).addIn(
  "& > div:nth-of-type(9)",
  Css.bl.bcGray400.$,
).$;

const rowStyles: RowStyles<Row> = {
  header: { rowCss: cellBorderStyles },
  data: { rowCss: cellBorderStyles },
};
