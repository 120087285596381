import { Css, IconButton } from "@homebound/beam";
import { ReactNode, useState } from "react";

export type AccordionProps = {
  title: ReactNode;
  children?: ReactNode;
  defaultExpanded?: boolean;
};

export function Accordion(props: AccordionProps) {
  const { title, children, defaultExpanded = false } = props;
  const [isCollapsed, setIsCollapsed] = useState(!defaultExpanded);
  const iconKey = isCollapsed ? "chevronDown" : "chevronUp";

  return (
    <span css={Css.dg.w100.gap2.$}>
      <span css={Css.dg.gtc("auto 28px").gap1.$}>
        <span>{title}</span>
        {children && <IconButton onClick={() => setIsCollapsed((prev) => !prev)} icon={iconKey} />}
      </span>
      {!isCollapsed && <span>{children}</span>}
    </span>
  );
}
