import {
  column,
  Css,
  emptyCell,
  getTableStyles,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  numericColumn,
  RowStyles,
  selectColumn,
  SelectToggle,
  simpleHeader,
  useComputed,
} from "@homebound/beam";
import { Link } from "react-router-dom";
import { dateCell, percentageCell, priceCell } from "src/components";
import { HomeownerContractDrawItemFragment, InvoiceV2Fragment, TaskStatus } from "src/generated/graphql-types";
import { DrawAmountItemsFilter } from "src/routes/projects/invoices/invoice-v2/invoice-steps/ConfirmDrawAmount";
import { createHomeownerContractChangeOrderDrawsUrl, createHomeownerContractDrawsUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";

type ConfirmDrawAmountTableProps = {
  invoice: InvoiceV2Fragment;
  draws: HomeownerContractDrawItemFragment[];
  api: GridTableApi<CreateDrawAmountRow>;
  filter: DrawAmountItemsFilter;
};

export function ConfirmDrawAmountTable({ draws, invoice, api, filter }: ConfirmDrawAmountTableProps) {
  const selectedAmount = useComputed(
    () => draws.filter((item) => api.getSelectedRowIds().find((id) => id === item.id)).sum((d) => d.amountInCents),
    [api],
  );
  const tableStyle = getTableStyles({ rowHeight: "fixed" });

  return (
    <GridTable
      style={{
        ...tableStyle,
        ...{ totalsCellCss: { ...tableStyle.totalsCellCss, ...Css.bt.bcGray200.$ } },
      }}
      columns={createColumns(invoice.project.id)}
      rows={createRows(draws, selectedAmount, invoice, filter)}
      rowStyles={rowStyles(api)}
      stickyHeader
      api={api}
    />
  );
}
const rowStyles = (api: GridTableApi<CreateDrawAmountRow>): RowStyles<CreateDrawAmountRow> => {
  const selectedRowsItemIds = api.getSelectedRowIds();
  return {
    drawAmountRow: {
      cellCss(row) {
        return Css.if(!selectedRowsItemIds.includes(row.id)).gray400.$;
      },
      rowCss(row) {
        return Css.addIn("a", !selectedRowsItemIds.includes(row.id) ? Css.blue200.important.$ : {}).$;
      },
    },
  };
};

type DrawData = {
  id: string;
  drawLineItem: string;
  scheduledEnd: DateOnly;
  contract: number;
  drawAmount: number;
  selectable: boolean;
  contractId: string;
};

type DrawAmountRow = {
  kind: "drawAmountRow";
  id: string;
  initSelected: boolean;
  data: DrawData;
};

type TotalRow = {
  kind: "totals";
  id: string;
  data: Omit<
    DrawData,
    "drawLineItem" | "scheduledEnd" | "contract" | "id" | "taskStatus" | "hasInvoice" | "selectable" | "contractId"
  >;
};

type HeaderRow = { kind: "header" };
export type CreateDrawAmountRow = DrawAmountRow | TotalRow | HeaderRow;

function createColumns(projectId: string): GridColumn<CreateDrawAmountRow>[] {
  const selectCol = selectColumn<CreateDrawAmountRow>({
    drawAmountRow: (row) => (row.selectable ? <SelectToggle id={row.id} /> : emptyCell),
    totals: () => ({
      colspan: 2,
      content: <span css={Css.mra.$}>{"Total Draw Amount"}</span>,
    }),
  });
  // "Total Draw Amount"
  const drawLineItem: GridColumn<CreateDrawAmountRow> = {
    header: "Draw Line Item",
    drawAmountRow: ({ drawLineItem, contractId }) => ({
      content: (
        <Link
          to={
            contractId.startsWith("hcco:")
              ? createHomeownerContractChangeOrderDrawsUrl(projectId, contractId)
              : createHomeownerContractDrawsUrl(projectId, contractId)
          }
          target="_blank"
          data-testid="drawLineItemLink"
        >
          {drawLineItem}
        </Link>
      ),
    }),
    totals: () => emptyCell,
    mw: "160px",
  };

  const scheduledEnd = column<CreateDrawAmountRow>({
    header: "Scheduled End",
    drawAmountRow: ({ scheduledEnd }) => dateCell(scheduledEnd),
    totals: () => emptyCell,
    mw: "160px",
  });

  const contract = numericColumn<CreateDrawAmountRow>({
    header: "% of Contract",
    drawAmountRow: ({ contract }) => percentageCell(contract),
    totals: () => emptyCell,
    mw: "160px",
  });

  const drawAmount = numericColumn<CreateDrawAmountRow>({
    header: "Draw Amount",
    drawAmountRow: ({ drawAmount }) => priceCell({ valueInCents: drawAmount }),
    totals: ({ drawAmount }) => priceCell({ valueInCents: drawAmount }),
    mw: "160px",
  });
  return [selectCol, drawLineItem, scheduledEnd, contract, drawAmount];
}

function createRows(
  draws: HomeownerContractDrawItemFragment[],
  selectedAmount: number,
  invoice: InvoiceV2Fragment,
  filter: DrawAmountItemsFilter,
): GridDataRow<CreateDrawAmountRow>[] {
  const savedDraws = draws.filter((item) => invoice.drawLineItems.find(({ draw }) => draw.id === item.id));
  const items = draws
    ? draws.map((draw) => {
        const taskComplete = draw.task.status.code === TaskStatus.Complete;
        const hasInvoice = draw.invoiceDrawLineItem?.invoice.id && draw.invoiceDrawLineItem.invoice.id !== invoice.id;
        const beforeCutoff = draw.task.interval.endDate <= filter.endDate;
        const selectable = taskComplete && !hasInvoice && beforeCutoff;
        const row: DrawAmountRow = {
          kind: "drawAmountRow",
          id: draw.id,
          initSelected: savedDraws.length ? savedDraws.some(({ id }) => id === draw.id) : selectable,
          data: {
            id: draw.id,
            drawLineItem: draw.name,
            scheduledEnd: draw.task.interval.endDate,
            contract: draw.percent,
            drawAmount: draw.amountInCents,
            selectable,
            contractId: draw.parent.id,
          },
        };
        return row;
      })
    : [];
  return [
    simpleHeader,
    ...items,
    {
      kind: "totals",
      id: "totals",
      data: {
        drawAmount: selectedAmount,
      },
    },
  ];
}
