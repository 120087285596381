import { Css, IconButton, Tooltip } from "@homebound/beam";
import { useCallback } from "react";
import { RequiredTaskDocumentDetailFragment } from "src/generated/graphql-types";
import { useScheduleStore } from "src/routes/projects/schedule-v2/contexts/ScheduleStore";
import { setTaskPaneState } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";

type RequiredTaskDocumentFileStatusProps = {
  requiredTaskDocuments: RequiredTaskDocumentDetailFragment[];
  taskId: string;
};

export const RequiredTaskDocumentFileStatus = ({
  requiredTaskDocuments,
  taskId,
}: RequiredTaskDocumentFileStatusProps) => {
  const { dispatch } = useScheduleStore();

  const onFileClick = useCallback(() => {
    dispatch(
      setTaskPaneState({
        taskPaneId: taskId,
        tab: "files",
      }),
    );
  }, [dispatch, taskId]);

  const fulfilled = !requiredTaskDocuments.some((rtd) => rtd.document?.id === undefined);
  const tooltipText = fulfilled ? "Required documents fulfilled" : "Must add required documents to task";
  return (
    <Tooltip title={tooltipText} placement="top">
      <div css={Css.relative.$}>
        <IconButton data-testid="fileDoc" inc={2.5} icon="fileBlank" onClick={onFileClick} />
        {fulfilled && (
          <div data-testid="fulfilledDocs" css={Css.h1.w1.br100.bgGreen600.absolute.bottomPx(3).leftPx(3).$}></div>
        )}
      </div>
    </Tooltip>
  );
};
