import { Button, Css, multiFilter, useModal, usePersistedFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { createDesignCatalogUrl } from "src/RouteUrls";
import { useLocationsTreeFilter } from "src/components/autoPopulateFilters/useLocationsTreeFilter";
import {
  FinishSchedule_DesignPackageFragment,
  ItemTemplateStatus,
  NamedFragment,
  useFinishScheduleDesignPackageMetadataQuery,
  useFinishScheduleDesignPackageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { fail, queryResult } from "src/utils";
import { DesignPackageLocationBadge } from "../designCatalogAtoms";
import { FinishScheduleTable } from "./FinishScheduleTable";
import { ExportFinishScheduleModal } from "./components/ExportFinishScheduleModal";

export function FinishSchedulePage() {
  const { designPackageId } = useParams<{ designPackageId: string }>();
  const query = useFinishScheduleDesignPackageQuery({ variables: { id: designPackageId } });
  return queryResult(query, ({ designPackage }) => <FinishScheduleView designPackage={designPackage} />);
}

function FinishScheduleView({ designPackage }: { designPackage: FinishSchedule_DesignPackageFragment }) {
  const { openModal } = useModal();
  const breadCrumb = [{ href: createDesignCatalogUrl(), label: "Finish Schedules" }];
  if (designPackage.code) breadCrumb.push({ href: "", label: designPackage.code });

  // TODO: Temporary solution until we allow users to select template versions
  // There can only be 1 draft at a time. Find it, or find the latest active template
  const designPackageTemplate =
    designPackage.itemTemplates?.find((it) => it.status === ItemTemplateStatus.Draft) ??
    designPackage.itemTemplates?.find((it) => it.isLatest);

  const { costCodes, itemLocations } =
    useFinishScheduleDesignPackageMetadataQuery({
      variables: { dpItId: designPackageTemplate?.id! },
      skip: !designPackageTemplate?.id,
    }).data?.itemTemplate ?? {};

  const { filter, setFilter, filterDefs } = useFinishScheduleFilters(
    designPackage,
    costCodes ?? [],
    designPackageTemplate?.id,
  );

  return (
    <div>
      <PageHeader
        xss={Css.mb0.$}
        title={designPackage.name}
        breadcrumb={breadCrumb}
        left={<DesignPackageLocationBadge designPackage={designPackage} />}
        right={
          <>
            <div css={Css.df.gap3.$}>
              <Button
                variant="text"
                label="Design Package"
                onClick={createDesignCatalogUrl(designPackage.id)}
                icon="linkExternal"
              />
              <Button
                label="Export"
                onClick={() =>
                  openModal({
                    content: (
                      <ExportFinishScheduleModal
                        exportFilter={filter}
                        designPackage={designPackage}
                        designPackageTemplateId={designPackageTemplate!.id}
                      />
                    ),
                  })
                }
              />
            </div>
          </>
        }
      />
      <FinishScheduleTable
        filter={filter}
        setFilter={setFilter}
        filterDefs={filterDefs}
        costCodes={costCodes}
        locations={itemLocations ?? []}
        designPackageTemplateId={designPackageTemplate!.id}
      />
    </div>
  );
}

export type FinishScheduleFilters = {
  costCodes: string[];
  locationInPath: string[];
  plans: string[];
} & Record<string, string[]>;

function useFinishScheduleFilters(
  designPackage: FinishSchedule_DesignPackageFragment,
  costCodes: NamedFragment[],
  dpItemplateId: string | undefined,
) {
  const { planPackages, options } = designPackage;

  const locationsTreeFilter = useLocationsTreeFilter({ itId: dpItemplateId });

  const filterDefs = useMemo(() => {
    const upgradeOptions = options.filter((o) => o.optionGroup.forDesignUpgrade);
    const designPackageOptionTuples = options
      .filter((rpo) => rpo.optionGroup.forDesignPackages)
      .groupByObject((rpo) => rpo.optionGroup.name);
    const ppTemplates = planPackages.map((planPackage) => {
      const template =
        planPackage.itemTemplates?.find((it) => it.status === ItemTemplateStatus.Draft) ??
        planPackage.itemTemplates?.find((it) => it.isLatest) ??
        fail(`No template found for ${planPackage.name}(${planPackage.id})`);
      return { id: template.id, name: planPackage.name };
    });

    return {
      costCodes: multiFilter({
        label: "Cost Code",
        options: costCodes?.sortByKey("name") ?? [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
        sizeToContent: true,
      }),
      locationInPath: locationsTreeFilter,
      // Will filter down to all the slots that apply to a given plan package
      plans: multiFilter({
        label: "Plans",
        options: ppTemplates?.sortByKey("name") ?? [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
        sizeToContent: true,
      }),
      ...designPackageOptionTuples.mapToObject(([rpogName, rpos]) => [
        rpogName, // name serves as key to Record<string, string[]>
        multiFilter({
          label: rpogName,
          options: rpos,
          getOptionLabel: ({ name }) => name,
          getOptionValue: ({ id }) => id,
          sizeToContent: true,
        }),
      ]),
      ...(upgradeOptions.nonEmpty && {
        upgradeOptions: multiFilter({
          label: "Upgrades",
          options: upgradeOptions,
          getOptionLabel: ({ name }) => name,
          getOptionValue: ({ id }) => id,
          sizeToContent: true,
        }),
      }),
    };
  }, [locationsTreeFilter, options, planPackages, costCodes]);

  const { filter, setFilter } = usePersistedFilter({
    filterDefs,
    storageKey: `finish-schedule-filter-${designPackage?.id}`,
  });

  return { filter, setFilter, filterDefs };
}
