import { HistoryFeed } from "src/components";
import { useCommitmentHistoryQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type CommitmentHistoryTabProps = {
  commitmentId: string;
};

export function CommitmentHistoryTab({ commitmentId }: CommitmentHistoryTabProps) {
  const query = useCommitmentHistoryQuery({ variables: { commitmentId } });
  return queryResult(query, (data) => {
    return <HistoryFeed historyItems={[...(data.commitment.history ?? [])].reverse()} />;
  });
}
