import { Css } from "@homebound/beam";
import { formatLabel, Row } from "./LotSummaryDetailsSection";

export function LotSummaryDetailField({ label, value }: Row) {
  const labelId = formatLabel(label);

  return (
    <div css={Css.db.pyPx(5).prPx(20).$}>
      <p css={Css.wbbw.$}>
        <span css={Css.smBd.mr1.$} data-testid={labelId}>
          {label}:
        </span>
        <span data-testid={`${labelId}-value`}>{value}</span>
      </p>
    </div>
  );
}
