import { Css, SelectField, useTestIds } from "@homebound/beam";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { createBusinessFunctionDetailsUrl } from "src/RouteUrls";
import { BusinessFunction, useBusinessFunctionsQuery } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { changeLogPaths } from "src/routes/routesDef";

type SortKey = keyof Pick<BusinessFunction, "priority" | "name">;

export function BusinessFunctionsPage() {
  const { data } = useBusinessFunctionsQuery();
  const [sortingMethod, setSortingMethod] = useState<SortKey>("priority");
  const sortedBusinessFunctions = useMemo(() => {
    const { businessFunctions } = data ?? {};
    return businessFunctions?.sortByKey(sortingMethod);
  }, [data, sortingMethod]);

  return (
    <div css={Css.df.fdc.fg1.maxh("calc(100% - 48px)").$}>
      <PageHeader
        title="Functions"
        breadcrumb={{ href: changeLogPaths.base, label: "Change Log" }}
        xss={Css.px3.mb0.$}
      />
      <div css={Css.df.jcfe.my2.px3.$}>
        <div css={Css.wPx(180).$}>
          <SelectField
            compact={true}
            value={sortingMethod}
            options={[
              { id: "priority", name: "Priority" },
              { id: "name", name: "Alphabetically" },
            ]}
            label="Sort"
            labelStyle="inline"
            onSelect={(method) => setSortingMethod(method ?? "priority")}
            data-testid="businessFunctionsSort"
          />
        </div>
      </div>
      <div css={Css.dg.gtc("1fr 1fr 1fr 1fr").gap2.px3.$}>
        {sortedBusinessFunctions?.map((bf) => (
          <BusinessFunctionCard key={bf.id} id={bf.id} name={bf.name} priority={bf.priority} />
        ))}
      </div>
    </div>
  );
}

type BusinessFunctionCardProps = {
  name: string;
  id: string;
  priority: number;
};
function BusinessFunctionCard(props: BusinessFunctionCardProps) {
  const { name, priority, id } = props;
  const history = useHistory();
  const tid = useTestIds(props, "businessFunction");
  return (
    <div
      {...tid.card}
      css={Css.hPx(95).bgWhite.br8.bshBasic.df.fdc.jcc.aic.cursorPointer.$}
      onClick={() => history.push(createBusinessFunctionDetailsUrl(id))}
    >
      <div css={Css.lgSb.$}>{name}</div>
      <div>Priority: {priority}</div>
    </div>
  );
}
