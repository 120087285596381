import { Fragment } from "react";
import { partition } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { ProjectItemDetails } from "./components/ProjectItemDetails";
import { usePdfProjectItemsQueryWithQueryParams } from "./hooks/usePdfProjectItemsQueryWithQueryParams";

export function ProjectItemScopeOfWorkPdf() {
  return queryResult(usePdfProjectItemsQueryWithQueryParams(), {
    data: ({ projectItems }) => {
      const [specifications, selections] = partition(
        (projectItems ?? []).filter((pi) => pi.protoCommitmentLineItemToBuyout),
        (pi) => !pi.homeownerSelection,
      );
      return (
        <Fragment>
          <ProjectItemDetails
            id="specificationProjectItems"
            projectItems={specifications}
            title="Scope of Work - Specifications"
          />
          <ProjectItemDetails
            id="selectionProjectItems"
            projectItems={selections}
            title="Scope of Work - Product Selections"
          />
        </Fragment>
      );
    },
  });
}
