import { SuperDrawerContent, SuperDrawerHeader, TabsWithContent, useSessionStorage } from "@homebound/beam";
import { Stage } from "src/generated/graphql-types";
import { ObservableProjectItem } from "src/routes/projects/models/ObservableProjectItem";
import { OverviewTab } from "src/routes/projects/selections/OverviewTab";
import { ProjectItemCommitmentsTable } from "src/routes/projects/selections/ProjectItemCommitmentsTable";
import { ProjectItemHistoryTab } from "src/routes/projects/selections/ProjectItemHistoryTab";
import { SelectionsTab } from "src/routes/projects/selections/SelectionsTab";
import { SpecTab } from "src/routes/projects/selections/SpecTab";

export type SelectionsSuperDrawerProps = {
  projectId: string;
  projectItem: ObservableProjectItem;
  stage: Stage;
};

export function SelectionsSuperDrawer({ projectId, projectItem, stage }: SelectionsSuperDrawerProps) {
  const [selectedTab, setSelectedTab] = useSessionStorage("selectionsSuperDrawerTab", "overview");
  const selection = projectItem.fragment.homeownerSelection;

  const overviewTab = {
    name: "Overview",
    value: "overview",
    render: () => <OverviewTab projectId={projectId} projectItemId={projectItem.id} stage={stage} />,
  };

  const secondaryTab = {
    name: selection ? "Selections" : "Spec",
    value: "specOrSelections",
    render: () =>
      selection ? (
        <SelectionsTab projectItemId={projectItem.id} homeownerSelectionId={selection.id} />
      ) : (
        <SpecTab projectId={projectId} projectItemId={projectItem.id} stage={stage} />
      ),
  };

  const commitmentsTab = {
    name: "Commitments",
    value: "commitments",
    render: () => <ProjectItemCommitmentsTable projectItemId={projectItem.id} />,
  };

  const historyTab = {
    name: "History",
    value: "history",
    render: () => <ProjectItemHistoryTab projectItemId={projectItem.id} />,
  };

  return (
    <SuperDrawerContent>
      <SuperDrawerHeader title={projectItem.fragment.displayName} />
      <TabsWithContent
        tabs={[overviewTab, secondaryTab, commitmentsTab, historyTab]}
        selected={selectedTab}
        onChange={setSelectedTab}
      />
    </SuperDrawerContent>
  );
}
