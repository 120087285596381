import { useSelectionsTabQuery } from "src/generated/graphql-types";
import { ObservableChangeEventLineItem } from "src/routes/projects/change-events/models/ObservableChangeEventLineItem";
import { CurrentSelectionTable } from "src/routes/projects/selections/CurrentSelectionTable";
import { SelectionOptions } from "src/routes/projects/selections/options/SelectionOptions";
import { isDefined, queryResult } from "src/utils";

type SelectionsTabProps = {
  projectItemId: string;
  homeownerSelectionId: string;
  changeEventLineItem?: Pick<ObservableChangeEventLineItem, "id" | "proposedBidItemId">;
};

export function SelectionsTab({ projectItemId, changeEventLineItem, homeownerSelectionId }: SelectionsTabProps) {
  const query = useSelectionsTabQuery({ variables: { projectItemId, homeownerSelectionId } });

  return queryResult(query, ({ homeownerSelection, projectItem, potentialBidItems }) => {
    // If the change event line item is defined, use the proposed bid item id
    const bidItemId = isDefined(changeEventLineItem) ? changeEventLineItem.proposedBidItemId : projectItem.bidItem?.id;
    return (
      <article>
        <CurrentSelectionTable homeownerSelection={homeownerSelection} potentialBidItems={potentialBidItems} />
        <SelectionOptions
          homeownerSelection={homeownerSelection}
          bidItemId={bidItemId}
          potentialBidItems={potentialBidItems}
        />
      </article>
    );
  });
}
