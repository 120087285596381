import {
  BoundNumberField,
  BoundSelectField,
  Button,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { SaveTradeCategoryLeadTimeInput, useSaveTradeCategoryLeadTimesMutation } from "src/generated/graphql-types";
import { pluralize, safeEntries, timeFrequencyToNameMapper } from "src/utils";
import { AllTradeCategoryLeadTimes } from "./TradeCategoryLeadTimesPage";
import { getLeadTimeInDays } from "./utils";

type TradeCategoryLeadTimesModalProps = {
  marketId: string;
  selectedCategory: AllTradeCategoryLeadTimes[];
  setSelectedCategory: React.Dispatch<React.SetStateAction<AllTradeCategoryLeadTimes[]>>;
};

export function TradeCategoryLeadTimesModal({
  marketId,
  selectedCategory,
  setSelectedCategory,
}: TradeCategoryLeadTimesModalProps) {
  const { closeModal } = useModal();
  const [saveTradeCategoryLeadTime] = useSaveTradeCategoryLeadTimesMutation();
  const { triggerNotice } = useSnackbar();
  const form = useFormState({
    config: formConfig,
  });

  const onSubmit = async () => {
    const { leadTimeFrequency, leadTimeInDays } = form.value;
    const result = await saveTradeCategoryLeadTime({
      refetchQueries: ["TradeCategoryLeadTimes"],
      variables: {
        input: {
          tradeCategoryLeadTimes: selectedCategory.map((tclt) => ({
            leadTimeFrequency,
            leadTimeInDays: getLeadTimeInDays(leadTimeInDays, leadTimeFrequency),
            marketId,
            tradeCategoryId: tclt.tradeCategory.id,
            id: tclt.id,
          })),
        },
      },
    });
    if (result.data) {
      triggerNotice({
        icon: "success",
        message: `You have updated ${selectedCategory.length} ${pluralize(
          selectedCategory.length,
          "category",
          "categories",
        )}`,
      });
    }
    setSelectedCategory([]);
    closeModal();
  };

  return (
    <>
      <ModalHeader>Update Selected Lead Times</ModalHeader>
      <ModalBody>
        <div>Update the number and frequency of the selected categories.</div>
        <div css={Css.df.gap4.$}>
          <div css={Css.mt3.wPx(83).$}>
            <BoundNumberField label="Number" field={form.leadTimeInDays} compact required positiveOnly />
          </div>
          <div css={Css.mt3.wPx(190).$}>
            <BoundSelectField
              field={form.leadTimeFrequency}
              getOptionLabel={([_, name]) => name}
              getOptionValue={([code]) => code}
              options={safeEntries(timeFrequencyToNameMapper)}
              label="Frequency"
              compact
              required
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label={`Update Selected ${selectedCategory.length > 0 ? `(${selectedCategory.length})` : ""}`}
              disabled={!form.value.leadTimeInDays || !form.value.leadTimeFrequency}
              onClick={onSubmit}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Pick<SaveTradeCategoryLeadTimeInput, "leadTimeFrequency" | "leadTimeInDays">;

const formConfig: ObjectConfig<FormInput> = {
  leadTimeFrequency: { type: "value", rules: [required] },
  leadTimeInDays: { type: "value", rules: [required] },
};
