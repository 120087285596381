import { Button, Css, useTestIds } from "@homebound/beam";
import { useMemo } from "react";
import { Price } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { ProjectItemsSection } from "src/routes/projects/dynamic-schedules/utils";

export function PurchaseOrders({ projectItems, inSidePane = false }: ProjectItemsSection) {
  const tid = useTestIds({}, "purchaseOrders");
  const { assets, purchaseOrders } = useMemo(() => {
    const commitments = projectItems
      .map((pi) => pi.currentCommitment)
      .unique()
      .compact();
    return { purchaseOrders: commitments, assets: commitments.map((c) => c.document?.asset as AssetInfoFragment) };
  }, [projectItems]);

  return (
    <div css={Css.bcGray300.if(!inSidePane).bb.pb3.$}>
      <div css={Css.if(!inSidePane).my2.else.mb2.$}>
        <span css={Css.df.aic.jcsb.baseSb.gray800.$}>Purchase Order</span>
      </div>
      {purchaseOrders.nonEmpty ? (
        <div css={Css.df.fdc.gap1.mb1.$}>
          <AssetGallery assets={assets} showThumbnails={false}>
            {(openGallery) => (
              <>
                {purchaseOrders.map(
                  ({ id, tradePartner, document, accountingNumber, committedInCents, status, statusText }) => (
                    <div css={Css.df.fdc.w100.mb1.br8.$} key={id}>
                      <div css={Css.dg.gtc("113px 80px 1fr 1fr").w100.cg1.rg1.smSb.aic.$}>
                        <span {...tid.accountNumber}>
                          <Button
                            onClick={() => openGallery(document?.asset as AssetInfoFragment)}
                            label={`#${accountingNumber}`}
                            variant="text"
                          />
                        </span>
                        <span css={Css.smMd.$} {...tid.tradePartner}>
                          {tradePartner?.name}
                        </span>
                        <span css={Css.maxwPx(120).tac.$}>
                          <span css={Css.smMd.$}>{statusText}</span>
                        </span>
                        <span css={Css.smMd.tar.$} {...tid.amount}>
                          <Price valueInCents={committedInCents} />
                        </span>
                      </div>
                    </div>
                  ),
                )}
              </>
            )}
          </AssetGallery>
        </div>
      ) : (
        <div {...tid.noPurchaseOrders} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no linked POs
        </div>
      )}
    </div>
  );
}
