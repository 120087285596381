import { ReadyPlanOption } from "src/generated/graphql-types";

export enum DisabledReason {
  CONFLICT = "conflict",
  PREREQ_MISSING = "prereq-missing",
}

export const REASONS_MAP = {
  [DisabledReason.CONFLICT]: (name: string) => `This option has a conflicting option that has been selected: ${name}`,
  [DisabledReason.PREREQ_MISSING]: (name: string) =>
    `This option has a prerequisite option that must been selected before it can be available: ${name}`,
};

/**
 *
 * @param selectedOptions The options which have been selected
 * @param otherOptions options available for selection that are not selected
 * @returns arrays of options which are disabled and enabled based on the dependencies of the selected options
 */
export function evaluateOptionDependencies(
  selectedOptions: ReadyPlanOption[],
  otherOptions: ReadyPlanOption[],
): {
  disabledOptions: {
    option: ReadyPlanOption;
    reasons: { type: DisabledReason; option: ReadyPlanOption }[];
  }[];
  availableOptions: ReadyPlanOption[];
} {
  // conflicts also needs to include options that are a conflict of a selected option
  const disabledOptions = [
    // options that have a conflicting option selected
    ...otherOptions
      .filter((opt) => !!opt)
      .filter(({ optionConflicts }) =>
        selectedOptions
          .filter((opt) => !!opt)
          .some(({ id }) => optionConflicts.some(({ id: conflictId }) => id === conflictId)),
      )
      .map((option) => ({
        option,
        reasons: option.optionConflicts
          .filter(({ id }) => selectedOptions.filter((opt) => !!opt).some(({ id: conflictId }) => id === conflictId))
          .map((conflictOption) => ({ type: DisabledReason.CONFLICT, option: conflictOption })),
      })),
    // options that are a conflict of a selected option
    ...otherOptions
      .filter((opt) => !!opt)
      .filter(({ id }) =>
        selectedOptions
          .filter((opt) => !!opt)
          .some(({ optionConflicts }) => optionConflicts.some(({ id: conflictId }) => id === conflictId)),
      )
      .map((option) => ({
        option,
        reasons: selectedOptions
          .filter((opt) => opt && opt.optionConflicts.some(({ id }) => id === option.id))
          .map((conflictOption) => ({ type: DisabledReason.CONFLICT, option: conflictOption })),
      })),
    // options that have prerequisite options that have not been selected
    ...otherOptions
      .filter((opt) => !!opt)
      .filter(
        ({ optionPrerequisites }) =>
          optionPrerequisites.length > 0 &&
          !optionPrerequisites.some(({ id: prereqId }) =>
            selectedOptions.filter((opt) => !!opt).some(({ id: selectedId }) => selectedId === prereqId),
          ),
      )
      .map((option) => ({
        option,
        reasons: option.optionPrerequisites
          .filter(({ id }) => !selectedOptions.filter((opt) => !!opt).some(({ id: prereqId }) => id === prereqId))
          .map((prereqOption) => ({ type: DisabledReason.PREREQ_MISSING, option: prereqOption })),
      })),
  ];

  const availableOptions = otherOptions
    .filter((opt) => !!opt)
    .filter(
      ({ id }) =>
        !selectedOptions.filter((opt) => !!opt).some((opt) => opt.id === id) &&
        !disabledOptions.some((o) => o.option.id === id),
    );

  return {
    disabledOptions,
    availableOptions,
  };
}
