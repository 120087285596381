import { IObservableArray, observable, observe, transaction } from "mobx";
import { Selectable } from "src/models/Selectable";
import { isDefined, sortBy } from "src/utils";

/**
 * Observes a list of proxies and creates a grouped list of aggregates.
 *
 * I.e. you can pass a list of items, a `aggregateFn` to group by cost code id,
 * and then the `CostCode` object that you want to contain all items for that
 * cost code, and this function will do all of the bookkeeping for you.
 *
 * A given aggregate (i.e. the `aggregateCstr` instance for a given cost id) will
 * be created only once, and then have newly-added/removed children automatically
 * added to its `Selectable.children` array (as root `children` array changes).
 */
export function observableAggregate<C extends Selectable<any>, A extends Selectable<C>>(
  children: IObservableArray<C>,
  aggregateFn: (child: C) => string,
  aggregateCstr: new (children: C[]) => A,
  opts: {
    sortAggregatesBy?: (aggregate: A) => string | number;
    sortChildrenBy?: (child: C) => string | number;
    initialState?: A[];
    allowEmptyAggregates?: true;
  } = {},
): IObservableArray<A> {
  const { sortChildrenBy, sortAggregatesBy, initialState, allowEmptyAggregates } = opts;
  const map = new Map<string, A>();
  const list = observable([] as A[]);
  // Skip sorting for the initial insert b/c we sort up-front
  let initialInsertion = true;

  function maybeAddInitialStateAggregates() {
    if (isDefined(initialState)) {
      initialState
        .filter((aggregate) => !map.has(aggregate.id))
        .forEach((aggregate) => {
          map.set(aggregate.id, aggregate);
          list.push(aggregate);
        });
    }
  }

  function add(added: C): void {
    const id = aggregateFn(added);
    let aggregate = map.get(id);
    if (!aggregate) {
      aggregate = new aggregateCstr([added]);
      map.set(id, aggregate);
      if (sortAggregatesBy) {
        const i = findInsertPoint(list, aggregate, sortAggregatesBy);
        list.splice(i, 0, aggregate);
      } else {
        list.push(aggregate);
      }
    } else {
      if (sortChildrenBy && !initialInsertion) {
        const i = findInsertPoint(aggregate.children, added, sortChildrenBy);
        aggregate.children.splice(i, 0, added);
      } else {
        aggregate.children.push(added);
      }
    }
  }

  function remove(removed: C): void {
    const id = aggregateFn(removed);
    const aggregate = map.get(id);
    if (aggregate) {
      const i = aggregate.children.indexOf(removed);
      if (i > -1) {
        aggregate.children.splice(i, 1);
        if (!allowEmptyAggregates && aggregate.children.length === 0) {
          map.delete(id);
          list.remove(aggregate);
        }
      }
    }
  }

  if (sortChildrenBy) {
    // On initial creation, we sort `children` up front, so that we pay a single
    // `O(N log N)` sort cost, instead of getting `O(N^2)` by calling `add`
    // directly (w/o initialInsertion set) and letting it re-scan on every `add`.
    sortBy(children, sortChildrenBy).forEach(add);
    initialInsertion = false;
  } else {
    children.forEach(add);
  }

  observe(children, (change) => {
    if ("splice" === change.type) {
      transaction(() => {
        // Technically if we get a big change, this will insert with `O(N^2)` performance
        // because each `add` call is going to sequentially scan for the right spot.
        change.added.forEach(add);
        change.removed.forEach(remove);
      });
    } else {
      throw new Error(`Unhandled change type ${change.type}`);
    }
  });

  maybeAddInitialStateAggregates();

  return list;
}

/** Returns the index to insert `a` in `list` that would keep it ordered by `fn`. */
function findInsertPoint<T>(list: T[], a: T, fn: (v: T) => string | number): number {
  const av = fn(a);
  // Find the 1st child that is "bigger" than `a` and insert at it (to push it down).
  const i = list.findIndex((child) => {
    const cv = fn(child);
    if (typeof av === "number" && typeof cv === "number") {
      return cv > av;
    } else if (typeof av === "string" && typeof cv === "string") {
      return cv.localeCompare(av) > 0;
    } else {
      throw new Error(`Unsupported sortBy values ${av}, ${cv}`);
    }
  });
  // If we didn't find a bigger element (`i === -1`), then put us at the back
  return i === -1 ? list.length : i;
}
