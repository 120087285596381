import { IconButton, useModal } from "@homebound/beam";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";

type ExitIconButtonProps = {
  showModal: boolean;
  onCloseConfirmed: () => void;
};

export function ExitIconButton({ showModal, onCloseConfirmed }: ExitIconButtonProps) {
  const { openModal } = useModal();

  return (
    <IconButton
      inc={5}
      data-testid="exit"
      icon="x"
      onClick={() => {
        if (showModal) {
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage="You have unsaved changes that will be lost. Do you want to discard these changes?"
                onConfirmAction={onCloseConfirmed}
                title="Discard changes?"
                label="Discard changes"
                danger
              />
            ),
          });
        } else {
          onCloseConfirmed();
        }
      }}
    />
  );
}
