import { Chip, Css } from "@homebound/beam";
import { LotConfigurationStatusFragment } from "src/generated/graphql-types";

type LotConfigurationStatusProps = {
  readyPlanConfig: Pick<LotConfigurationStatusFragment, "applied" | "canApply">;
};

export function LotConfigurationStatusChip({ readyPlanConfig }: LotConfigurationStatusProps) {
  return !readyPlanConfig.applied ? (
    <Chip
      type="neutral"
      text="Pending"
      title={
        readyPlanConfig.canApply.disabledReasons.length > 0 ? (
          <p css={Css.tac.$}>{readyPlanConfig.canApply.disabledReasons.map((dr) => dr.message).join(" ")}</p>
        ) : undefined
      }
      compact
    />
  ) : (
    <></>
  );
}
