import { Button, ModalBody, ModalFooter, ModalHeader, SelectField, useModal } from "@homebound/beam";
import { useState } from "react";
import { InvoiceDrawFragment } from "src/generated/graphql-types";
import { fail } from "src/utils";

type AddInvoiceDrawModalProps = {
  /** Available / not-already-on-an-invoice draws for any contract in the current stage. */
  draws: InvoiceDrawFragment[];
  onAdd: (draw: InvoiceDrawFragment) => void;
};

export function AddInvoiceDrawModal(props: AddInvoiceDrawModalProps) {
  const { draws, onAdd } = props;
  const { closeModal } = useModal();
  const [drawId, setDrawId] = useState<string | undefined>("");

  return (
    <>
      <ModalHeader>Add Draw Line Item</ModalHeader>
      <ModalBody>
        <SelectField
          label="Draw"
          value={drawId}
          onSelect={setDrawId}
          options={draws}
          getOptionValue={(opt) => opt.id}
          getOptionLabel={(opt) => opt.description}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Add"
          disabled={!drawId}
          onClick={() => {
            onAdd(draws.find((d) => d.id === drawId) || fail("no draw was selected"));
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
