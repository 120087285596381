import {
  ButtonMenu,
  column,
  Css,
  dateColumn,
  FilterDefs,
  Filters,
  GridColumn,
  GridStyle,
  MenuItem,
  multiFilter,
  RowStyles,
  selectColumn,
  simpleDataRows,
  SimpleHeaderAndData,
  toggleFilter,
  useComputed,
  useGridTableApi,
  useModal,
  usePersistedFilter,
} from "@homebound/beam";
import { max } from "date-fns";
import { useMemo, useState } from "react";
import { dateCell, QueryTable, SearchBox, tagCell } from "src/components";
import {
  Maybe,
  ScheduleTemplatesDetailFragment,
  ScheduleTemplatesFilter,
  ScheduleTemplateStatus,
  useSaveScheduleTemplatesMutation,
  useScheduleTemplatesPageMetadataQuery,
  useScheduleTemplatesQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { CreateOrUpdateScheduleTemplateForm } from "src/routes/libraries/schedules/CreateOrUpdateScheduleTemplateModal";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { createScheduleTemplateUrl } from "src/RouteUrls";
import { safeEntries, scheduleStatusToTagType, stageCodeToNameMapper } from "src/utils";
import { PageHeader } from "../../layout/PageHeader";
import { TableActions } from "../../layout/TableActions";
import { DuplicateScheduleTemplateModalForm } from "./DuplicateScheduleTemplateModalForm";

type ScheduleTemplatesPageProps = {
  documentTitle: string;
};

type ScheduleTemplateFilterWithArchive = ScheduleTemplatesFilter & {
  archived: Maybe<boolean>;
};

export function ScheduleTemplatesPage({ documentTitle }: ScheduleTemplatesPageProps) {
  const { data } = useScheduleTemplatesPageMetadataQuery();
  const [saveScheduleTemplates] = useSaveScheduleTemplatesMutation();
  const { openModal, closeModal } = useModal();
  useDocumentTitle(documentTitle);
  const tableApi = useGridTableApi<Row>();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const selectedTemplates: ScheduleTemplatesDetailFragment[] = useComputed(
    () => tableApi.getSelectedRows("data").map((row) => row.data),
    [tableApi],
  );
  // using mapper here specifically without archive as we don't want to have archived in the dropdown
  const scheduleTemplateStatusToNameMapperWithoutArchive = {
    [ScheduleTemplateStatus.Published]: "Published",
    [ScheduleTemplateStatus.Draft]: "Draft",
  };

  const filterDefs: FilterDefs<ScheduleTemplateFilterWithArchive> = useMemo(
    () => {
      const market = multiFilter({
        label: "Market",
        options: data?.markets || [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
      });

      const stage = multiFilter({
        label: "Schedule Type",
        options: safeEntries(stageCodeToNameMapper),
        getOptionLabel: ([, name]) => name,
        getOptionValue: ([code]) => code,
      });

      const status = multiFilter({
        label: "Status",
        options: safeEntries(scheduleTemplateStatusToNameMapperWithoutArchive),
        getOptionLabel: ([, name]) => name,
        getOptionValue: ([code]) => code,
      });

      const archived = toggleFilter({ label: "Show Archived" });

      return { market, stage, status, archived };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const { filter, setFilter } = usePersistedFilter<ScheduleTemplateFilterWithArchive>({
    storageKey: "scheduleTemplateFilter",
    filterDefs,
  });

  const query = useScheduleTemplatesQuery({ variables: { filter: mapToFilter(filter) } });

  const rowStyles: RowStyles<Row> = {
    header: {
      cellCss: Css.xsMd.gray700.bb.bcGray200.$,
      rowCss: Css.bt.bl.br.bcGray200.bgWhite.borderRadius("8px 8px 0 0").$,
    },
    data: {
      cellCss: Css.sm.gray900.tal.$,
      rowCss: Css.bl.br.bb.bcGray200.bgWhite.$,
      rowLink: (row) => createScheduleTemplateUrl(row.data.id),
    },
  };

  const style: GridStyle = {
    cellCss: Css.aic.pPx(12).$,
    // Apply border radius (bottom-right and bottom-left) to the last row of the list
    rootCss: Css.pb4.bgGray100.addIn("& > div:last-child", Css.borderRadius("0 0 8px 8px").$).$,
  };

  const archivedTemplates = selectedTemplates.filter((st) => st.status === ScheduleTemplateStatus.Archived);

  const scheduleTemplateActionItems: MenuItem[] = useMemo(
    () => {
      const duplicateAction = {
        label: "Duplicate",
        disabled: selectedTemplates.length > 1 || archivedTemplates.length > 0,
        onClick: () =>
          openModal({
            content: (
              <DuplicateScheduleTemplateModalForm
                scheduleTemplateId={selectedTemplates[0].id}
                stage={selectedTemplates[0].stage}
              />
            ),
          }),
      };
      const archiveAction = {
        label: "Archive",
        disabled: archivedTemplates.length > 0,
        onClick: () =>
          openModal({
            content: (
              <ConfirmationModal
                confirmationMessage={
                  selectedTemplates.length === 1
                    ? "Are you sure you want to archive this template?"
                    : "Are you sure you want to archive these templates?"
                }
                onConfirmAction={async () => {
                  await saveScheduleTemplates({
                    variables: {
                      scheduleTemplates: {
                        scheduleTemplates: selectedTemplates.map((st) => ({
                          id: st.id,
                          status: ScheduleTemplateStatus.Archived,
                        })),
                      },
                    },
                  });
                  await query.refetch();
                  closeModal();
                }}
                label={selectedTemplates.length === 1 ? "Archive template" : "Archive templates"}
                title="Confirm template archiving"
                danger={true}
              />
            ),
          }),
      };
      return [...[archiveAction], ...[duplicateAction]];
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplates],
  );

  const createNewMenuItems: MenuItem[] = [
    {
      label: "Project Template",
      onClick: () =>
        openModal({
          content: <CreateOrUpdateScheduleTemplateForm scheduleType={ScheduleType.Project} />,
        }),
    },
    {
      label: "Development Template",
      onClick: () =>
        openModal({
          content: <CreateOrUpdateScheduleTemplateForm scheduleType={ScheduleType.Development} />,
        }),
    },
  ];

  return (
    <div data-testid="scheduleTemplatesPage">
      <PageHeader
        title="Schedule Templates"
        right={<ButtonMenu trigger={{ label: "Create New", variant: "primary" }} items={createNewMenuItems} />}
        xss={Css.bgWhite.$}
      />
      <TableActions>
        <Filters<ScheduleTemplateFilterWithArchive> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <div css={Css.df.aic.gap2.$}>
          <SearchBox onSearch={setSearchFilter} />
          <ButtonMenu
            trigger={{ label: "Actions" }}
            items={scheduleTemplateActionItems}
            disabled={selectedTemplates.length === 0}
          />
        </div>
      </TableActions>
      <QueryTable
        stickyHeader
        sorting={{ on: "client" }}
        query={query}
        columns={columns}
        rowStyles={rowStyles}
        style={style}
        createRows={(data) => simpleDataRows(data?.scheduleTemplates)}
        fallbackMessage="No schedule templates found."
        filter={searchFilter}
        api={tableApi}
      />
    </div>
  );
}

type Row = SimpleHeaderAndData<ScheduleTemplatesDetailFragment>;

const columns: GridColumn<Row>[] = [
  selectColumn<Row>(),
  column<Row>({ header: "Template Name", data: ({ name }) => name }),
  column<Row>({ header: "Stage", data: ({ stage }) => stageCodeToNameMapper[stage] }),
  column<Row>({ header: "Market", data: ({ market }) => market?.name ?? "--" }),
  column<Row>({
    header: "Developments",
    data: ({ developments }) => developments.map((m) => m.name).join(", "),
    clientSideSort: false,
  }),
  column<Row>({
    header: "Status",
    data: ({ statusDetail }) => tagCell(scheduleStatusToTagType[statusDetail.code], statusDetail.name),
  }),
  dateColumn<Row>({ header: "Created", data: ({ createdAt }) => dateCell(createdAt) }),
  dateColumn<Row>({ header: "Last Edited", data: (data) => dateCell(lastEditedDate(data)) }),
];

function lastEditedDate(data: ScheduleTemplatesDetailFragment) {
  const updatedAts = [data.updatedAt, ...data.schedules.map((s) => s.updatedAt)];
  return max(updatedAts);
}

function mapToFilter(filter: ScheduleTemplateFilterWithArchive): ScheduleTemplatesFilter {
  const { archived, status, ...others } = filter;
  return {
    ...others,
    ...(status ? { status } : { status: [ScheduleTemplateStatus.Published, ScheduleTemplateStatus.Draft] }),
    ...(archived && {
      status: [ScheduleTemplateStatus.Archived, ScheduleTemplateStatus.Draft, ScheduleTemplateStatus.Published],
    }),
  };
}
