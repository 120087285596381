import { Css } from "@homebound/beam";
import { useState } from "react";
import { OverviewTab_ProductFragment } from "src/generated/graphql-types";
import { ProductImagesSlider } from "./components/ProductImagesSlider";

export type ProductImageViewerProps = {
  product: OverviewTab_ProductFragment;
};

export function ProductImageViewer(props: ProductImageViewerProps) {
  const { product } = props;
  const [currentImageUrl, setCurrentImageUrl] = useState(product.images.first?.downloadUrl);

  if (product.images?.isEmpty) {
    return <ProductDefaultImage />;
  }

  return (
    <div css={Css.df.$}>
      <ProductImagesSlider product={product} setImageUrl={setCurrentImageUrl} />
      <div css={Css.df.w75.aic.mlPx(40).mrPx(40).$}>
        <img
          data-testid="productMainImage"
          css={Css.maxh100.ma.p1.maxh("500px").wa.$}
          src={currentImageUrl}
          alt={product.name}
          onError={(event) => {
            const target = event.target as HTMLImageElement;
            target.src = PRODUCT_FALLBACK_IMG_URL;
            target.onerror = null;
          }}
        />
      </div>
    </div>
  );
}

export function ProductDefaultImage() {
  return (
    <div css={Css.bcGray100.bgGray100.h100.mr8.df.$}>
      <img
        data-testid="productDefaultMainImage"
        css={Css.maxh100.ma.p2.wa.$}
        src={PRODUCT_FALLBACK_IMG_URL}
        alt="Default Image"
      />
    </div>
  );
}

export const PRODUCT_FALLBACK_IMG_URL = "/images/product-image-fallback.svg";
export const PRODUCT_EMPTY_STATE_IMG_URL = "/images/product-empty-state.svg";
