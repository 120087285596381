import { Css, GridColumn, GridDataRow, GridTable } from "@homebound/beam";
import React from "react";
import { ItemTemplateItemRows } from "../ProductOfferingScopePage";

function ProductOfferingScopeTableComponent({
  columns,
  rows,
}: {
  columns: GridColumn<ItemTemplateItemRows>[];
  rows: GridDataRow<ItemTemplateItemRows>[];
}) {
  return (
    <>
      <GridTable
        as="virtual"
        style={{ grouped: true, allWhite: true, bordered: true, rowHeight: "fixed" }}
        columns={columns}
        rows={rows}
        stickyHeader
        rowStyles={{ header: { cellCss: Css.gray900.$ } }}
        fallbackMessage={"No items match the applied filters. Please adjust and try again"}
      />
    </>
  );
}

export const ProductOfferingScopeTable = React.memo(ProductOfferingScopeTableComponent);
