import {
  BoundSelectField,
  collapseColumn,
  column,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  selectColumn,
  simpleHeader,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useMemo } from "react";
import { ItemTemplateItemVersionFilter, NamedFragment } from "src/generated/graphql-types";
import { capitalize } from "src/utils";
import { DevelopmentItemMappingsStore } from "../models/DevelopmentItemMappingsStore";
import { DevelopmentPotentialItemMapping, MapTemplateItemsFormValue } from "../models/DevelopmentPotentialItemMapping";

type MapTemplateItemFilter = Pick<ItemTemplateItemVersionFilter, "costCode" | "location">;

type MapItemsTableProps = {
  store: DevelopmentItemMappingsStore;
  scheduleTasks: NamedFragment[];
  searchFilter: string | undefined;
  filter: MapTemplateItemFilter;
  getFormState: (input: MapTemplateItemsFormValue) => ObjectState<MapTemplateItemsFormValue>;
  api: GridTableApi<MapTemplateItemsRow>;
  showMappedItems: boolean;
};

export default function MapTemplateItemsTable({
  store,
  scheduleTasks,
  filter,
  searchFilter,
  getFormState,
  api,
  showMappedItems,
}: MapItemsTableProps) {
  const columns = useMemo(() => createColumns(getFormState, scheduleTasks), [getFormState, scheduleTasks]);

  return (
    <div>
      <GridTable
        style={{ rowHeight: "fixed", grouped: true, inlineEditing: true }}
        columns={columns}
        rows={createRows(store, filter, showMappedItems)}
        fallbackMessage="No Template Items found that matched given filters."
        filter={searchFilter}
        stickyHeader
        sorting={{ on: "client", initial: ["item", "ASC"] }}
        api={api}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type CostTypeRow = {
  kind: "costType";
  id: string;
  data: {
    costCode: string;
    scheduleTaskToDo: string;
  };
};

export type MapTemplateItemRow = {
  kind: "item";
  id: string;
  data: DevelopmentPotentialItemMapping;
};

export type MapTemplateItemsRow = MapTemplateItemRow | CostTypeRow | HeaderRow;

function createColumns(
  getFormState: (input: MapTemplateItemsFormValue) => ObjectState<MapTemplateItemsFormValue>,
  scheduleTasks: NamedFragment[],
): GridColumn<MapTemplateItemsRow>[] {
  return [
    collapseColumn<MapTemplateItemsRow>({ w: "32px" }),
    selectColumn<MapTemplateItemsRow>({ w: "32px" }),
    column<MapTemplateItemsRow>({
      id: "item",
      header: "Item",
      costType: (row) => row.costCode,
      item: ({ item }) => item.displayName,
    }),
    column<MapTemplateItemsRow>({
      header: "Location",
      costType: () => emptyCell,
      item: ({ location }) => location.name,
    }),
    column<MapTemplateItemsRow>({
      header: "Cost Type",
      costType: () => emptyCell,
      item: (row) => capitalize(row.costType),
    }),
    column<MapTemplateItemsRow>({
      header: "Schedule task",
      costType: (row) => row.scheduleTaskToDo,
      item: (row) => {
        return {
          content: () => (
            <BoundSelectField
              field={getFormState(row).taskId}
              placeholder={"Select a schedule task"}
              options={scheduleTasks || (row.task ? [row.task] : [])}
            />
          ),
          value: row.task?.name,
        };
      },
    }),
  ];
}

function createRows(
  store: DevelopmentItemMappingsStore,
  filter: MapTemplateItemFilter,
  showMappedItems: boolean,
): GridDataRow<MapTemplateItemsRow>[] {
  const omittedItemMappingIds = new Set();

  if (filter.location || filter.costCode) {
    store.items.forEach(({ item, location, costCode }) => {
      if (
        (filter.location && !filter.location?.includes(location.id)) ||
        (filter.costCode && !filter.costCode?.includes(costCode.id))
      ) {
        omittedItemMappingIds.add(item.id);
      }
    });
  }

  return [
    simpleHeader,
    ...store.children
      .filter((group) => {
        const isNotEveryOmitted = !group.children.every((i) => omittedItemMappingIds.has(i.item.id));

        if (showMappedItems && isNotEveryOmitted) return true;
        if (!showMappedItems && group.isAllMapped && isNotEveryOmitted) return false;

        return isNotEveryOmitted;
      })
      .map((group) => ({
        kind: "costType" as const,
        id: group.id,
        data: {
          costCode: group.costCode.displayName,
          scheduleTaskToDo: group.scheduleTaskToDo,
        },
        children: group.children.reduce<GridDataRow<MapTemplateItemsRow>[]>((acc, im) => {
          if (!omittedItemMappingIds.has(im.item.id) && (showMappedItems || im.isUnmapped)) {
            return [...acc, im.toRow()];
          }
          return acc;
        }, []),
      })),
  ];
}
