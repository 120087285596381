import { Css, useTestIds } from "@homebound/beam";
import { Percentage, Price } from "src/components";
import { useApprovalChangeEventBreakDownTabQuery } from "src/generated/graphql-types";
import { isDefined, queryResult } from "src/utils";
import { basisPointsToPercentage } from "src/utils/basisPoints";
import { ApprovalSummary } from "../ApprovalSummary";
import { useApprovalBreakpoint } from "../approvalAtoms";
import { ApprovalChangeEventLineItemTable } from "./ApprovalChangeEventLineItemTable";

type ApprovalChangeEventBreakDownProps = {
  changeEventId: string | undefined;
};

export function ApprovalChangeEventBreakDown({ changeEventId }: ApprovalChangeEventBreakDownProps) {
  const query = useApprovalChangeEventBreakDownTabQuery({ variables: { changeEventId: changeEventId! } });
  const tid = useTestIds({});

  return queryResult(query, (data) => {
    const ce = data.changeEvent;
    const celiTotalCost = ce.lineItems.sum((li) => li.totalCostInCents);
    const celiTotalPrice = ce.lineItems.sum((li) => li.totalPriceInCents);

    const originalCost = ce.lineItems.sum((li) => li.projectItem.originalBudgetInCents);
    const originalPrice = ce.lineItems.sum((li) => li.projectItem.originalPriceInCents);
    const originalMarkup = originalCost ? (originalPrice - originalCost) / originalCost : 0;
    const originalMarkupTotal = originalCost * originalMarkup;

    const revisedCost = ce.lineItems.sum((li) => li.projectItem.revisedApprovedBudgetInCents) || originalCost;
    const revisedPrice = ce.lineItems.sum((li) => li.projectItem.totalPriceInCents) || originalPrice;
    const revisedMarkup = revisedCost ? (revisedPrice - revisedCost) / revisedCost : originalMarkup;
    const revisedMarkupTotal = revisedCost * revisedMarkup;

    const proposedCost = ce.lineItems.sum((li) => li.proposedTotalCostInCents); // celi.pi.revisedApprovedBudget + celi.totalCost
    const proposedPrice = revisedPrice + celiTotalPrice;
    const proposedMarkup = isDefined(proposedCost) ? (proposedPrice - proposedCost) / proposedCost : 0;
    const proposedMarkupTotal = proposedCost * proposedMarkup;

    return (
      <div css={Css.df.fdc.gap5.mb7.$}>
        <ApprovalSummary subjects={[ce]} />
        <div css={Css.dg.gtc("repeat(3, 1fr)").br8.ba.bcGray200.addIn("& > div + div", Css.bl.bcGray200.$).$}>
          <BudgetImpactComparison
            {...tid.underwritten}
            title="Underwritten"
            totalCost={originalCost}
            markupPercent={originalMarkup * 100}
            totalMarkup={originalMarkupTotal}
            totalPrice={originalPrice}
          />

          <BudgetImpactComparison
            {...tid.revisedInternal}
            title="Revised Internal"
            totalCost={revisedCost}
            markupPercent={revisedMarkup * 100}
            totalMarkup={revisedMarkupTotal}
            totalPrice={revisedPrice}
            delta={{
              cost: revisedCost - originalCost,
              markup: revisedMarkup - originalMarkup,
              markupTotal: revisedMarkupTotal - originalMarkupTotal,
              price: revisedPrice - originalPrice,
            }}
          />

          <BudgetImpactComparison
            {...tid.proposed}
            title="Proposed"
            totalCost={proposedCost}
            markupPercent={proposedMarkup * 100}
            totalMarkup={proposedMarkupTotal}
            totalPrice={proposedPrice}
            delta={{
              cost: celiTotalCost,
              markup: isDefined(proposedCost) ? proposedMarkup - revisedMarkup : 0,
              markupTotal: isDefined(proposedCost) ? proposedMarkupTotal - revisedMarkupTotal : 0,
              price: isDefined(proposedCost) ? proposedPrice - revisedPrice : 0,
            }}
          />
        </div>
        <ApprovalChangeEventLineItemTable changeEvent={ce} />
      </div>
    );
  });
}

type BudgetImpactComparisonProps = {
  title: string;
  totalCost: number;
  markupPercent: number;
  totalMarkup: number;
  totalPrice: number;
  delta?: {
    cost: number;
    markup: number;
    markupTotal: number;
    price: number;
  };
};

function BudgetImpactComparison(props: BudgetImpactComparisonProps) {
  const { title, totalCost, markupPercent, totalMarkup, totalPrice, delta } = props;
  // Display grid with 3 columns if a cost delta col exists
  const cardSectionStyle = Css.dg.gtc("1fr 1fr").if(isDefined(delta)).gtc("repeat(3, minmax(0, 1fr))").cg1.$;
  const tid = useTestIds(props);
  const desktop = useApprovalBreakpoint();

  return (
    <div css={Css.if(desktop).py4.px7.else.p3.$} {...tid.title}>
      <h4 css={Css.baseBd.gray900.mb1.$}>{title}</h4>

      <div css={cardSectionStyle}>
        <div css={Css.xs.gray700.$}>total cost</div>
        <div css={Css.xsSb.tar.jse.$} {...tid.totalCost}>
          <Price valueInCents={totalCost} />
        </div>
        <div css={Css.xs.tar.jse.$}>{delta && <Price valueInCents={delta?.cost} displayDirection invertColors />}</div>
      </div>

      <div css={cardSectionStyle}>
        <div css={Css.xs.gray700.$}>markup %</div>
        <div css={Css.xsSb.tar.jse.$} {...tid.markupPercent}>
          <Percentage percent={basisPointsToPercentage(markupPercent ?? 0)} dropZero />
        </div>
        <div css={Css.xs.tar.jse.$}>{delta && <Percentage percent={delta.markup} />}</div>
      </div>

      <div css={cardSectionStyle}>
        <div css={Css.xs.gray700.$}>total markup</div>
        <div css={Css.xsSb.tar.jse.$} {...tid.totalMarkup}>
          <Price valueInCents={totalMarkup} />
        </div>
        <div css={Css.xs.tar.jse.$}>{delta && <Price valueInCents={delta?.markupTotal} displayDirection />}</div>
      </div>

      <div css={cardSectionStyle}>
        <div css={Css.xs.gray700.$}> total price </div>
        <div css={Css.xsSb.tar.jse.$} {...tid.totalPrice}>
          <Price valueInCents={totalPrice} />
        </div>
        <div css={Css.xs.tar.jse.$}>{delta && <Price valueInCents={delta?.price} displayDirection />}</div>
      </div>
    </div>
  );
}
