import { ApolloQueryResult } from "@apollo/client";
import { Button, ButtonModal, Css, HasIdAndName } from "@homebound/beam";
import { useParams } from "react-router";
import {
  DevelopmentBulkScheduleDevelopmentDetailFragment,
  DevelopmentBulkScheduleQuery,
  Maybe,
  Stage,
  TaskStatusesFragment,
  useDevelopmentBulkScheduleQuery,
} from "src/generated/graphql-types";
import { DevelopmentBulkScheduleTable } from "src/routes/developments/schedule/components/DevelopmentBulkScheduleTable";
import {
  DevelopmentBulkScheduleFilterModal,
  DevelopmentBulkSchedulesGroupBy,
} from "src/routes/developments/schedule/DevelopmentBulkScheduleFilterModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { useQueryStorage } from "src/routes/projects/schedule-v2/table/filterUtils";
import { nonEmpty, queryResult } from "src/utils";

export function DevelopmentBulkSchedulePage() {
  const { developmentId } = useParams<{ developmentId: string }>();
  const query = useDevelopmentBulkScheduleQuery({ variables: { developmentId } });

  return queryResult(query, {
    data: ({ development, taskStatuses, projects }) => {
      return (
        <DevelopmentBulkSchedulePageView
          development={development}
          taskStatuses={taskStatuses}
          projects={projects}
          refetchScheduleQuery={() => query.refetch()}
        />
      );
    },
  });
}

type DevelopmentBulkSchedulePageViewProps = {
  development: DevelopmentBulkScheduleDevelopmentDetailFragment;
  projects: HasIdAndName[];
  taskStatuses: TaskStatusesFragment[];
  refetchScheduleQuery: () => Promise<ApolloQueryResult<DevelopmentBulkScheduleQuery>>;
};

const DevelopmentBulkSchedulePageView = ({
  development,
  taskStatuses,
  refetchScheduleQuery,
}: DevelopmentBulkSchedulePageViewProps) => {
  const { id, name, cohorts } = development;

  // custom hook to allow us to set queryParams to use as a filter instead of using persistedFilter
  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: `${id}_bulkScheduleFilter`,
    initialQueryStorage: bulkScheduleFilterDefault,
  });

  const showClear =
    Object.keys(queryStorage).includes("stage") ||
    nonEmpty(queryStorage.project || []) ||
    nonEmpty(queryStorage.cohort || []) ||
    nonEmpty(queryStorage.latestActiveStage || []) ||
    queryStorage.milestonesOnly ||
    queryStorage.taskStatusView ||
    queryStorage.stale;

  return (
    <>
      <PageHeader
        left={
          <div>
            <h2 css={Css.xsMd.gray700.$}>{name}</h2>
            <h1 css={Css.xl2Sb.gray900.$}>Schedule</h1>
          </div>
        }
        right={
          <div css={Css.dif.fdc.absolute.right0.br8.pl2.pr4.py1.gap1.$}>
            <span css={Css.df.aic.xs.$}>
              <span css={Css.df.w1.h1.br100.bgRed100.mr1.ba.bcGray600.$} /> Past incomplete task
            </span>
            <span css={Css.df.aic.xs.$}>
              <span css={Css.df.w1.h1.br100.bgYellow200.mr1.ba.bcGray600.$} /> Upcoming potential delay
            </span>
          </div>
        }
        hideTitle
        xss={Css.bgWhite.py4.$}
      />
      <TableActions onlyLeft>
        <ButtonModal
          content={
            <DevelopmentBulkScheduleFilterModal
              filter={queryStorage}
              setFilter={setQueryStorage}
              cohorts={cohorts ?? []}
            />
          }
          trigger={{ label: "Filters" }}
        />
        {showClear && (
          <div>
            <Button
              label="Clear"
              variant="tertiary"
              onClick={() => setQueryStorage({} as DevelopmentBulkScheduleFilter)}
            />
          </div>
        )}
      </TableActions>
      <DevelopmentBulkScheduleTable
        development={development}
        filter={queryStorage}
        taskStatuses={taskStatuses}
        refetchScheduleQuery={() => refetchScheduleQuery()}
      />
    </>
  );
};

export type DevelopmentBulkScheduleFilter = {
  project?: string[];
  cohort?: string[];
  milestonesOnly: boolean;
  stage: Maybe<Stage>;
  latestActiveStage: Maybe<Stage[]>;
  taskStatusView: boolean;
  stale: boolean;
  groupBy: DevelopmentBulkSchedulesGroupBy;
};

const bulkScheduleFilterDefault: DevelopmentBulkScheduleFilter = {
  project: undefined,
  cohort: undefined,
  milestonesOnly: false,
  stage: undefined,
  latestActiveStage: undefined,
  taskStatusView: false,
  stale: false,
  groupBy: DevelopmentBulkSchedulesGroupBy.Cohort,
};

export const myProjectsId = "my-projects";
