import {
  BoundCheckboxField,
  BoundMultiSelectField,
  BoundSelectField,
  Button,
  collapseColumn,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  GridTable,
  IconButton,
  Palette,
  simpleHeader,
  Tag,
  Tooltip,
} from "@homebound/beam";
import { ObjectState, useFormStates } from "@homebound/form-state";
import DOMPurify from "dompurify";
import { Maybe } from "graphql/jsutils/Maybe";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { CommentCountBubble } from "src/components/comments/CommentCountBubble";
import { ToDoChecklistStatus } from "src/components/to-dos/ToDoChecklistStatus";
import { ParentOption } from "src/components/to-dos/ToDoModal";
import {
  AvailableTeamMembersFragment,
  SaveToDoInput,
  ToDoCardFragment,
  ToDoStatus,
  ToDoStatusDetail,
  ToDoTypeDetail,
  useSaveFollowersMutation,
  useSaveToDosMutation,
} from "src/generated/graphql-types";
import { createDashboardTodoModalUrl, createProjectToDoModalUrl } from "src/RouteUrls";
import { sortBy } from "src/utils";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";
import { ObjectConfig } from "src/utils/formState";

export type ToDosTableProps = {
  projectId?: string;
  toDos: ToDoCardFragment[];
  parentOptions: ParentOption[];
  statuses: ToDoStatusDetail[];
  teamMembers: AvailableTeamMembersFragment[];
  types: ToDoTypeDetail[];
  fetchMoreCompletedTasks: () => void;
  showLoadMore: boolean;
};

export function ToDosTable({
  projectId,
  toDos,
  parentOptions,
  statuses,
  teamMembers,
  types,
  fetchMoreCompletedTasks,
  showLoadMore,
}: ToDosTableProps) {
  const [saveToDosMutation] = useSaveToDosMutation();
  const [saveFollowersMutation] = useSaveFollowersMutation();
  const { getFormState } = useFormStates<InLineSaveToDo, ToDoCardFragment>({
    config: formConfig,
    map: ({ id, status, parent, description, assignees, dueDate, followers, type, urgent }) => ({
      id,
      dueDate,
      urgent,
      status: status.code,
      parentId: parent?.id,
      description: description,
      assigneeIds: assignees.map(({ internalUser }) => internalUser!.id),
      followerIds: followers.map((f) => f.id),
      type: type.code,
      complete: status.code === ToDoStatus.Complete,
    }),
    getId: (o) => o.id!,
    autoSave: async (os) => saveTodo(os),
  });
  const history = useHistory();

  // helper to toggle status of checkbox field to complete or in progress
  async function toggleStatus(os: ObjectState<InLineSaveToDo>) {
    const { id, complete, ...input } = os.changedValue;
    await saveToDosMutation({
      variables: {
        toDos: [{ id, status: complete ? ToDoStatus.Complete : ToDoStatus.InProgress, ...input }],
      },
    });
  }

  const saveTodo = async (os: ObjectState<InLineSaveToDo>) => {
    const { id, followerIds, complete, ...input } = os.changedValue;
    if (Object.keys(input).nonEmpty) {
      await saveToDosMutation({ variables: { toDos: [{ id, ...input }] } });
    }
    if (followerIds) {
      await saveFollowersMutation({
        variables: { input: { followableId: id!, followerIds, fromTag: false } },
      });
      os.followerIds.commitChanges();
    }
  };

  const openTodoModal = useCallback(
    (id: string, tab?: string) => {
      const maybeTabParam = tab ? { search: `?tab=${tab}` } : {};

      history.push({
        pathname: !!projectId ? createProjectToDoModalUrl(projectId, id) : createDashboardTodoModalUrl(id),
        ...maybeTabParam,
      });
    },
    [projectId, history],
  );

  const groupedTodos = useMemo(() => toDos.groupBy(({ status }) => status.code), [toDos]);

  const sortedParentOption = useMemo(() => sortBy(parentOptions, (p) => p.name), [parentOptions]);

  const columns = useMemo(
    () => [
      collapseColumn<Row>({
        header: emptyCell,
        status: (data, { row }) => <CollapseToggle row={row} />,
        todo: (todo) => {
          const os = getFormState(todo);
          return (
            <BoundCheckboxField
              field={os.complete}
              checkboxOnly
              onChange={async () => {
                os.complete.set(!os.complete.value);
                await toggleStatus(os);
              }}
            />
          );
        },
      }),
      column<Row>({
        header: "Status",
        status: ({ status, count }) => ({
          content: () => (
            <div css={Css.df.aic.gap1.$}>
              <span>{status.name}</span>
              <Tag text={count.toString()} />
            </div>
          ),
          colspan: 8,
        }),
        todo: (todo) => {
          const os = getFormState(todo);
          return (
            <BoundSelectField
              label="Status"
              labelStyle="hidden"
              field={os.status}
              options={statuses}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code}
            />
          );
        },
        loadMore: () => emptyCell,
      }),
      ...(!projectId
        ? [
            column<Row>({
              header: "Project/Cohort",
              status: emptyCell,
              todo: (todo) => {
                const os = getFormState(todo);
                return (
                  <BoundSelectField
                    label="Parent"
                    labelStyle="hidden"
                    field={os.parentId}
                    options={sortedParentOption}
                  />
                );
              },
              loadMore: () => emptyCell,
            }),
          ]
        : []),
      column<Row>({
        header: "Title",
        status: emptyCell,
        todo: ({ id, name }) => (
          <div
            css={Css.xsMd.blue700.cursorPointer.maxhPx(50).oh.add("textOverflow", "ellipsis").$}
            onClick={() => openTodoModal(id)}
          >
            <span data-testid="name">{name}</span>
          </div>
        ),
        loadMore: () => emptyCell,
      }),
      column<Row>({
        header: "Description",
        status: emptyCell,
        todo: ({ id, description }) => (
          <div
            css={Css.xsMd.blue700.cursorPointer.maxhPx(50).oh.add("textOverflow", "ellipsis").$}
            onClick={() => openTodoModal(id)}
          >
            {
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description ?? "", {
                    USE_PROFILES: { html: false },
                  }),
                }}
              ></span>
            }
          </div>
        ),
        loadMore: () => emptyCell,
      }),
      column<Row>({
        header: "Assignees",
        status: emptyCell,
        todo: (todo) => {
          const os = getFormState(todo);
          return (
            <BoundMultiSelectField
              label="Assignees"
              labelStyle="hidden"
              field={os.assigneeIds}
              options={teamMembers}
              fieldDecoration={internalUserAvatar}
              getOptionMenuLabel={internalUserMenuLabel}
            />
          );
        },
        loadMore: () => ({
          content: showLoadMore && (
            <Button variant="secondary" label="Load More" onClick={() => fetchMoreCompletedTasks()}></Button>
          ),
          colspan: 8,
        }),
      }),
      column<Row>({
        header: "Due Date",
        status: emptyCell,
        todo: (todo) => {
          const os = getFormState(todo);
          return <BoundBeamDateField label="Due Date" labelStyle="hidden" field={os.dueDate} />;
        },
        loadMore: emptyCell,
      }),
      column<Row>({
        header: "Watchers",
        status: emptyCell,
        todo: (todo) => {
          const os = getFormState(todo);
          return (
            <BoundMultiSelectField
              label="Watchers"
              labelStyle="hidden"
              field={os.followerIds}
              options={teamMembers}
              fieldDecoration={internalUserAvatar}
              getOptionMenuLabel={internalUserMenuLabel}
            />
          );
        },
        loadMore: emptyCell,
      }),
      column<Row>({
        header: "Type",
        status: emptyCell,
        todo: (todo) => {
          const os = getFormState(todo);
          return (
            <BoundSelectField
              options={types}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.code}
              field={os.type}
            />
          );
        },
        loadMore: emptyCell,
      }),
      column<Row>({
        header: emptyCell,
        w: "140px",
        sticky: "right",
        status: emptyCell,
        todo: (todo) => {
          const { id, streams, checklistProgress } = todo;
          const os = getFormState(todo);
          return (
            <div css={Css.df.aic.gap1.jcfe.$}>
              <Tooltip title={os.urgent.value ? "Urgent" : "Not Urgent"}>
                <div>
                  <IconButton
                    icon={os.urgent.value ? "flag" : "outlineFlag"}
                    color={os.urgent.value ? Palette.Red600 : undefined}
                    onClick={() => os.urgent.set(!os.urgent.value)}
                  />
                </div>
              </Tooltip>
              <CommentCountBubble streams={streams} onClick={() => openTodoModal(id)} />
              <ToDoChecklistStatus checklistProgress={checklistProgress} openTodoModal={openTodoModal} toDoId={id} />
            </div>
          );
        },
        loadMore: emptyCell,
      }),
    ],
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchMoreCompletedTasks, projectId],
  );

  const rows = useMemo(
    () => [
      simpleHeader,
      ...[
        {
          status: statuses.find((x) => x.code === ToDoStatus.InProgress)!,
          todos: groupedTodos[ToDoStatus.InProgress] ?? [],
          count: (groupedTodos[ToDoStatus.InProgress] ?? []).length,
        },
        {
          status: statuses.find((x) => x.code === ToDoStatus.UpNext)!,
          todos: groupedTodos[ToDoStatus.UpNext] ?? [],
          count: (groupedTodos[ToDoStatus.UpNext] ?? []).length,
        },
        {
          status: statuses.find((x) => x.code === ToDoStatus.Backlog)!,
          todos: groupedTodos[ToDoStatus.Backlog] ?? [],
          count: (groupedTodos[ToDoStatus.Backlog] ?? []).length,
        },
        {
          status: statuses.find((x) => x.code === ToDoStatus.Complete)!,
          todos: groupedTodos[ToDoStatus.Complete] ?? [],
          count: (groupedTodos[ToDoStatus.Complete] ?? []).length,
        },
      ]
        .filter(({ todos }) => todos.nonEmpty)
        .map(({ count, status, todos }) => ({
          id: status.code,
          kind: "status" as const,
          data: { status, count },
          children: [
            ...todos.map((toDo) => ({
              id: toDo.id,
              kind: "todo" as const,
              data: toDo,
            })),
            ...(status.name === "Complete" ? [{ id: "loadMore", kind: "loadMore" as const, data: "" }] : []),
          ],
        })),
    ],
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupedTodos],
  );

  return (
    <GridTable
      as="virtual"
      rows={rows}
      columns={columns}
      style={{ grouped: true, inlineEditing: true, allWhite: true, bordered: true }}
      stickyHeader
      rowStyles={{ status: { cellCss: Css.gray700.smMd.$ } }}
    />
  );
}

export type InLineSaveToDo = SaveToDoInput & {
  followerIds: Maybe<string[]>;
  complete: Maybe<boolean>;
};

const formConfig: ObjectConfig<InLineSaveToDo> = {
  id: { type: "value" },
  status: { type: "value" },
  urgent: { type: "value" },
  type: { type: "value" },
  parentId: { type: "value" },
  description: { type: "value" },
  assigneeIds: { type: "value" },
  dueDate: { type: "value" },
  followerIds: { type: "value" },
  complete: { type: "value" },
};

type HeaderRow = { kind: "header"; data: undefined };
type StatusRow = { kind: "status"; data: { status: ToDoStatusDetail; count: number } };
type ToDoRow = { kind: "todo"; data: ToDoCardFragment };
type LoadMoreRow = { kind: "loadMore"; data: string };
type Row = HeaderRow | StatusRow | ToDoRow | LoadMoreRow;
