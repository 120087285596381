import { Button, Css, IconKey } from "@homebound/beam";
import { useEffect, useState } from "react";
import { BannerNotice } from "src/components";
import { useInvoiceIntacctSyncErrorQuery, useSyncInvoiceToIntacctMutation } from "src/generated/graphql-types";

type BannerSyncProps = {
  invoiceId: string | null | undefined;
  hasSyncError: boolean;
};

type InvoiceBannerProps = {
  message?: string;
  icon?: IconKey;
  success?: boolean;
  show: boolean;
};

export function InvoiceBanner({ invoiceId, hasSyncError }: BannerSyncProps) {
  const [syncInvoiceToIntacct] = useSyncInvoiceToIntacctMutation();
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const querySyncError = useInvoiceIntacctSyncErrorQuery({
    variables: { invoiceId: invoiceId! },
    skip: !invoiceId,
  });
  const [bannerProps, setBannerProps] = useState<InvoiceBannerProps | undefined>(
    hasSyncError
      ? {
          message: `Invoice ${invoiceId} has failed to sync with ERP system. Fix related issues and try again.`,
          icon: "xCircle",
          success: false,
          show: true,
        }
      : {
          show: false,
        },
  );

  useEffect(() => {
    if (
      querySyncError?.previousData &&
      querySyncError?.previousData?.invoice?.updatedAt !== querySyncError.data?.invoice?.updatedAt
    ) {
      const successSync = !querySyncError?.data?.invoice?.intacctSyncError;
      querySyncError.stopPolling();
      setIsSyncLoading(false);
      const newBannerProps: InvoiceBannerProps = !successSync
        ? {
            message: `Sync with ERP system failed. Check Details section for the reason.`,
            icon: "xCircle",
            success: false,
            show: true,
          }
        : { message: `Successfully synced with ERP.`, icon: "checkCircle", success: true, show: true };
      setBannerProps(newBannerProps);
      if (successSync) {
        setTimeout(() => {
          // After 3 seconds hide the banner
          setBannerProps({ show: false });
        }, 3000);
      }
    }
  }, [querySyncError]);

  async function onSyncToErp() {
    try {
      await syncInvoiceToIntacct({ variables: { input: { id: invoiceId ?? "" } } });
      querySyncError.startPolling(1000);
      setIsSyncLoading(true);
    } catch {
      setIsSyncLoading(false);
    }
  }

  return (
    <div hidden={!bannerProps?.show} data-testid="divBanner">
      <BannerNotice
        data-testid="bannerNotice"
        icon={bannerProps?.icon}
        message={bannerProps?.message}
        xss={bannerProps?.success ? Css.bgGreen100.gray900.$ : Css.bgRed100.gray900.$}
        right={
          <span hidden={bannerProps?.success} css={Css.lh(0).$}>
            <Button
              disabled={isSyncLoading}
              icon="refresh"
              onClick={onSyncToErp}
              label={isSyncLoading ? "Syncing..." : "Resync"}
              variant="tertiaryDanger"
              data-testid="resyncButton"
            />
          </span>
        }
      />
    </div>
  );
}
