import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";
import {
  NamedFragment,
  Project,
  TradesPunchlistPdfQuery,
  useTradesPunchlistPdfQuery,
} from "src/generated/graphql-types";
import { fail, isDefined, queryResult } from "src/utils";
import { ArrayParam, useQueryParams } from "use-query-params";

export function TradesPunchlistPdf() {
  const [{ punchlistItemIds }] = useQueryParams({
    punchlistItemIds: ArrayParam,
  });

  if (!punchlistItemIds?.nonEmpty) fail("Missing punchlistItemIds");

  const query = useTradesPunchlistPdfQuery({
    variables: { punchlistItemIds: punchlistItemIds.map((id) => id!) },
  });

  return queryResult(query, ({ punchlistItemTodos }) => <PunchlistView punchlistItemTodos={punchlistItemTodos} />);
}

type PunchListViewProps = {
  punchlistItemTodos: TradesPunchlistPdfQuery["punchlistItemTodos"];
};

function PunchlistView({ punchlistItemTodos }: PunchListViewProps) {
  const groupedByTrade = punchlistItemTodos
    .filter((p) => p.tradePartner !== undefined)
    .groupByObject((p) => p.tradePartner);

  const today = new Date();
  return (
    <>
      <Global styles={{ "@page": { size: "letter" } }} />
      <div>
        <div /* Header */ css={Css.w100.df.jcc.$}>
          <div css={Css.df.fdc.aic.$}>
            <div>
              <img src="/wordmark.svg" alt="Homebound" css={Css.hPx(18).$} />
            </div>
            <div css={Css.xl3Bd.pPx(2).$}>Punchlist</div>
            <div>{today.toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })}</div>
          </div>
        </div>

        <div css={Css.pPx(24).tal.$}>
          <p css={Css.pb1.$}>Hello,</p>
          <p>
            We are reaching out to share a list of punchlist items that are assigned to you. Please complete these
            requested changes.
          </p>

          <div /* Punchlist Items List */ css={Css.w100.py4.$}>
            {groupedByTrade.map(([tradePartner, punchlistItems]) => (
              <TradePunchlistTable
                key={tradePartner!.id}
                tradePartner={tradePartner!}
                punchlistItems={punchlistItems}
                today={today}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

type TradePunchlistTableProps = {
  tradePartner: NamedFragment;
  punchlistItems: TradesPunchlistPdfQuery["punchlistItemTodos"];
  today: Date;
};

function TradePunchlistTable(props: TradePunchlistTableProps) {
  const { tradePartner, punchlistItems, today } = props;
  return (
    // The first table should not have a page break before it, but all subsequent tables should.
    <div css={Css.pb2.$}>
      <div css={Css.baseBd.pb1.$}>{tradePartner.name}</div>
      <table css={Css.w100.$}>
        <thead>
          <tr>
            <th css={Css.smMd.pb1.$}>Punchlist Items</th>
            <th css={Css.smMd.pb1.$}>Room</th>
            <th css={Css.smMd.pb1.$}>Project</th>
            <th css={Css.smMd.pb1.$}>Created</th>
            <th css={Css.smMd.pb1.$}>Due</th>
          </tr>
        </thead>
        <tbody>
          {punchlistItems.map((item, index, array) => {
            const created = item.createdAt?.toLocaleDateString() ?? "";
            const due = item.dueDate?.toLocaleDateString() ?? "";
            const overDue = item.dueDate ? today > new Date(item.dueDate) : false;
            const isLastItem = item === array.last;
            return (
              <>
                <tr /* Punchlist Item */ css={Css.bt.bcGray400.$}>
                  <td css={Css.vat.smMd.pt2.$}>{item.name}</td>
                  <td css={Css.vat.pt2.wPx(125).$}>{item.location?.name ?? ""}</td>
                  <td css={Css.vat.pt2.w25.$}>{(item.parent as Project)?.name ?? ""}</td>
                  <td css={Css.vat.pt2.w8.$}>{created}</td>
                  <td css={Css.vat.pt2.w8.if(overDue).red500.$}>{due}</td>
                </tr>
                <tr /* Punchlist Item Description */>
                  <td
                    css={Css.w25.wbbw.vat.py1.add("breakInside", "avoid").$}
                    dangerouslySetInnerHTML={{ __html: item.description ?? "" }}
                  />
                </tr>
                {/* Add border to last row, except for last punchlist item */}
                <tr /* Punchlist Images */ css={Css.add("breakInside", "avoid").if(!isLastItem).bb.bcGray400.$}>
                  {item.attachments.nonEmpty && (
                    <td css={Css.pb2.$} colSpan={5}>
                      <div css={Css.w100.df.gap1.fww.$}>
                        {item.attachments
                          .filter((attachment) => isDefined(attachment.asset.previewUrl))
                          .map((a) => (
                            <div key={a.asset.id} css={Css.hPx(75).wPx(75).df.fdc.aic.jcc.br16.bgGray400.$}>
                              <img css={Css.maxw100.maxh100.$} src={a.asset.previewUrl!} alt="attachment" />
                            </div>
                          ))}
                      </div>
                    </td>
                  )}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
