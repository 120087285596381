import { Icon as BeamIcon, Css, IconProps, Margin, Only, Xss, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";
import { Icon, IconKey } from "./Icon";

type BannerNoticeVariants = "basic" | "form" | "field";
type BannerNoticeProps<X> = {
  icon?: IconKey | IconProps;
  message: ReactNode;
  right?: ReactNode;
  /** Default is basic */
  variant?: BannerNoticeVariants;
  xss?: X;
};

const variantCss: Record<BannerNoticeVariants, any> = {
  basic: Css.bgGray800.white.mb3.$,
  form: Css.bgGray100.xsMd.gray900.br8.mb3.$,
  field: Css.bgGray100.xsMd.gray900.br8
    .gc("1 / 3")
    .pPx(7)
    .mtPx(-10)
    .mxPx(-5)
    .mb0.w("calc(100% + 5px)")
    .dg.gap2.gtc("45% 51%").aic.$,
};

export function BannerNotice<X extends Only<BannerNoticeXss, X>>(props: BannerNoticeProps<X>) {
  const tid = useTestIds(props, "banner");
  const { icon, message, right, xss, variant = "basic" } = props;

  return (
    <div
      {...tid}
      css={{
        ...Css.w100.pPx(12).pl3.df.jcsb.gap2.$,
        ...variantCss[variant],
        ...xss,
      }}
    >
      <div css={Css.df.gap1.aic.w100.$}>
        {typeof icon === "string" ? <Icon icon={icon} /> : icon && <BeamIcon {...icon} />}
        {message}
      </div>
      {right}
    </div>
  );
}

type BannerNoticeXss = Xss<Margin | "backgroundColor" | "color">;
