import { BaseFilter, Css, Filter, Switch, TestIds } from "@homebound/beam";
import { ItemTemplateChangesSettings } from "./useItemTemplateShowChangesSettings";

export type ItemTemplateChangesFilterProps = {
  label?: string;
  defaultValue?: ItemTemplateChangesSettings | undefined;
};

/**
 * This is a custom meta-filter which drives ~3 ITIV filters.
 * They are all put into the filter for convenient URL sharing, but some of them
 * could one day be moved to a user preference, if it is found users always want to highlight changes for instance.
 */
export class ItemTemplateChangesFilter
  extends BaseFilter<ItemTemplateChangesSettings, ItemTemplateChangesFilterProps>
  implements Filter<ItemTemplateChangesSettings>
{
  render(
    value: ItemTemplateChangesSettings | undefined,
    setValue: (value: ItemTemplateChangesSettings | undefined) => void,
    tid: TestIds,
  ): JSX.Element {
    const { defaultValue } = this.props;
    const { showRecentUpdates = false, ...otherFields } = value ||
      defaultValue || { showChangesOnly: false, highlightChanges: false, includeRemoved: false };

    function setValueField(field: keyof ItemTemplateChangesSettings, newValue: boolean) {
      const proposedValue = { showRecentUpdates, ...otherFields, [field]: newValue };
      const { showRecentUpdates: _, ...proposedOtherFields } = proposedValue;
      if (!value && proposedValue.showRecentUpdates) {
        // If we switch the main toggle on when nothing is set, we will turn them all on by default.
        setValue({
          showRecentUpdates: true,
          showChangesOnly: true,
          highlightChanges: true,
          includeRemoved: true,
        });
      } else if (!Object.values(proposedOtherFields).some((on) => on) || (field === "showRecentUpdates" && !newValue)) {
        // If none of the fields are set OR if we turn showRecentUpdates off, clear the value
        setValue(undefined);
      } else if (
        field !== "showRecentUpdates" &&
        !proposedValue.showRecentUpdates &&
        Object.values(proposedOtherFields).some((on) => on)
      ) {
        // If we have showRecentUpdates off, but we check anything else, turn showRecentUpdates on
        setValue({ showRecentUpdates: true, ...proposedOtherFields });
      } else {
        // Otherwise, just set the value
        setValue(proposedValue);
      }
    }

    return (
      <>
        <Switch
          selected={showRecentUpdates}
          label={`${this.label} 🧪`}
          labelStyle={"filter"}
          onChange={(on) => setValueField("showRecentUpdates", on)}
          {...this.testId(tid)}
        />
        <div css={Css.pl2.$}>
          {(
            [
              ["showChangesOnly", "Show Changes Only"],
              ["highlightChanges", "Highlight Changes"],
              ["includeRemoved", "Include Removed"],
            ] as const
          ).map(([field, label]) => (
            <div key={field} css={Css.mt2.$}>
              <Switch
                selected={otherFields[field]}
                label={label}
                labelStyle={"filter"}
                onChange={(on) => setValueField(field, on)}
                {...this.testId(tid)}
              />
            </div>
          ))}
        </div>
      </>
    );
  }

  get hideLabelInModal() {
    return true;
  }
}
