import {
  BoundMultiSelectField,
  BoundTextField,
  Button,
  Chip,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  Tooltip,
  useHover,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { HoverDelete } from "src/components/HoverDelete";
import { Icon, IconProps } from "src/components/Icon";
import {
  ChangeRequestDependency,
  DependencyModalOption_ChangeRequestFragment,
  DependencyModal_ChangeRequestFragment,
  DependencyModal_ChangeRequestGroupFragment,
  IncrementalCollectionOp,
  InputMaybe,
  SaveChangeRequestDependencyBlockersMutationFn,
  SaveChangeRequestDependencyBlockingMutationFn,
  SaveChangeRequestGroupInput,
  SaveChangeRequestRelatedToMutationFn,
  useChangeRequestGroupsForModalQuery,
  useChangeRequestsDependencyModalQuery,
  useSaveChangeRequestDependencyBlockersMutation,
  useSaveChangeRequestDependencyBlockingMutation,
  useSaveChangeRequestRelatedToMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { ChangeRequestGroupModal } from "./ChangeRequestGroupModal";

type DependencyModalProps = {
  id: string;
  kind: ChangeRequestDependency;
};

type ContentByKind = {
  type: string;
  label: string;
  title: string;
  icon?: IconProps<any>["icon"];
  description: string;
  saveLabel: string;
};

const staticContentByKind: Record<ChangeRequestDependency, ContentByKind> = {
  [ChangeRequestDependency.Block]: {
    type: "Blocking",
    label: "Blocking Other Change Requests",
    title: "Block a Change Request",
    icon: "error",
    description:
      "Mark this Change Request as blocking another one. If this one is then rejected, the other is not needed.",
    saveLabel: "Add Blocker",
  },
  [ChangeRequestDependency.Dependent]: {
    type: "Dependency",
    label: "Dependent on Other Change Requests",
    title: "Add a Dependency",
    icon: "error",
    description:
      "Mark this Change Request as dependent on another one. If that one is then rejected, this one is not needed.",
    saveLabel: "Add Dependency",
  },
  [ChangeRequestDependency.Link]: {
    type: "Related To",
    label: "Related To",
    title: "Add a Link",
    description:
      "Link this Change Request to another one without status dependencies. If one is then rejected, the other will not be affected.",
    saveLabel: "Add Link",
  },
};

export function DependencyModal(props: DependencyModalProps) {
  const { id, kind } = props;
  const { openModal } = useModal();
  const query = useChangeRequestsDependencyModalQuery({ variables: { id, kind } });
  const { type, label, title, description, icon } = staticContentByKind[kind];

  const [saveBlocking] = useSaveChangeRequestDependencyBlockingMutation();
  const [saveBlocker] = useSaveChangeRequestDependencyBlockersMutation();
  const [saveLink] = useSaveChangeRequestRelatedToMutation();
  // Hack: We need to use the correct save function based on the kind of dependency. We can't use a single function
  // because then one of the variables will be undefined and the mutation will just take it as an empty array.
  const {
    key,
    saveDependency,
  }: {
    key: "blocking" | "blockers" | "linkedTo";
    saveDependency:
      | SaveChangeRequestDependencyBlockersMutationFn
      | SaveChangeRequestDependencyBlockingMutationFn
      | SaveChangeRequestRelatedToMutationFn;
  } = {
    [ChangeRequestDependency.Block]: {
      key: "blocking" as const,
      saveDependency: saveBlocking,
    },
    [ChangeRequestDependency.Dependent]: {
      key: "blockers" as const,
      saveDependency: saveBlocker,
    },
    [ChangeRequestDependency.Link]: {
      key: "linkedTo" as const,
      saveDependency: saveLink,
    },
  }[kind];

  return queryResult(query, {
    data: (result) => {
      const { changeRequest } = result;
      const relatedThroughGroups =
        kind === ChangeRequestDependency.Link
          ? changeRequest.groups
              .map(({ changeRequests }) => changeRequests)
              .flat()
              // Exclude the current change request
              .filter(({ id }) => id !== changeRequest.id)
          : [];
      const values = [...relatedThroughGroups, ...changeRequest[key]].uniqueBy(({ id }) => id);
      return (
        <div>
          <div css={Css.df.gap1.mb1.aic.$}>
            <span css={Css.sm.gray700.$}>{label}</span>
            <Tooltip title={description} placement="top">
              <Icon icon="infoCircle" inc={2} />
            </Tooltip>
          </div>
          <div css={Css.df.fdc.gap1.mb1.$}>
            {values.map((v) => (
              <DependencyValue
                key={v.id}
                changeRequestId={changeRequest.id}
                kind={kind}
                dependency={v}
                type={type}
                icon={icon}
                groups={changeRequest.groups}
                saveDependency={saveDependency}
              />
            ))}
          </div>
          <Button
            variant="secondary"
            label={title}
            onClick={async () => {
              const {
                data: { availableDependencyChangeRequests },
              } = await query.refetch();
              openModal({
                content: (
                  <DependencyModalContent
                    changeRequestId={id}
                    kind={kind}
                    changeRequest={changeRequest}
                    options={availableDependencyChangeRequests}
                    saveDependency={saveDependency}
                  />
                ),
              });
            }}
          />
        </div>
      );
    },
  });
}

type DependencyValueProps = {
  changeRequestId: string;
  kind: ChangeRequestDependency;
  dependency: DependencyModalOption_ChangeRequestFragment;
  type: string;
  saveDependency:
    | SaveChangeRequestDependencyBlockersMutationFn
    | SaveChangeRequestDependencyBlockingMutationFn
    | SaveChangeRequestRelatedToMutationFn;
  groups: DependencyModal_ChangeRequestGroupFragment[];
  icon?: IconProps<any>["icon"];
};

const kindToKey = {
  [ChangeRequestDependency.Block]: "blocking" as const,
  [ChangeRequestDependency.Dependent]: "blockers" as const,
  [ChangeRequestDependency.Link]: "linkedTo" as const,
};

function DependencyValue({
  changeRequestId,
  kind,
  dependency,
  type,
  saveDependency,
  icon,
  groups,
}: DependencyValueProps) {
  const { isHovered, hoverProps } = useHover({});
  const { openModal } = useModal();

  const handleDelete = useCallback(async () => {
    await saveDependency({
      variables: {
        id: changeRequestId,
        [kindToKey[kind]]: [{ id: dependency.id, op: IncrementalCollectionOp.Remove }],
        groups: [],
      },
      refetchQueries: ["ChangeLogRightPaneContentDetails"],
    });
  }, [saveDependency, changeRequestId, kind, dependency.id]);

  const handleGroupChipClick = useCallback(
    (id: string) => {
      openModal({
        content: <ChangeRequestGroupModal id={id} />,
      });
    },
    [openModal],
  );

  const hasGroupDependency = (group: DependencyModal_ChangeRequestGroupFragment) =>
    group.changeRequests.some((cr) => cr.id === dependency.id);

  return (
    <div css={Css.df.jcsb.aic.p2.xsMd.bgGray100.gap2.br4.$} {...hoverProps}>
      <div css={Css.df.fdc.gap1.$}>
        <div css={Css.df.gapPx(4).$}>
          {icon && <Icon icon={icon} color={Palette.Red600} inc={2} />}
          <span>
            {type} <Link to={dependency.blueprintUrl.path}>{dependency.name}</Link>
          </span>
        </div>
        <div css={Css.df.aifs.gap1.whiteSpace("normal").$}>
          {groups.filter(hasGroupDependency).map((group) => (
            <div key={group.id} onClick={() => handleGroupChipClick(group.id)} css={Css.cursorPointer.$}>
              <Chip text={group.name} compact />
            </div>
          ))}
        </div>
      </div>
      <div css={Css.df.gap2.$}>
        <HoverDelete compact visible={isHovered} onClick={handleDelete} />
      </div>
    </div>
  );
}

type DependencyModalContentProps = {
  changeRequestId: string;
  kind: ChangeRequestDependency;
  changeRequest: DependencyModal_ChangeRequestFragment;
  options: DependencyModalOption_ChangeRequestFragment[];
  saveDependency:
    | SaveChangeRequestDependencyBlockersMutationFn
    | SaveChangeRequestDependencyBlockingMutationFn
    | SaveChangeRequestRelatedToMutationFn;
};

function DependencyModalContent(props: DependencyModalContentProps) {
  const { changeRequestId, kind, options, saveDependency } = props;
  const { label, title, description, saveLabel } = staticContentByKind[kind];
  const { closeModal } = useModal();

  const formState = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { id: changeRequestId, selected: [], groups: [], maybeNewGroup: undefined },
    },
  });

  const handleSubmit = useCallback(async () => {
    const { selected } = formState.changedValue;
    const key = kindToKey[kind];
    const changeRequests = [changeRequestId, ...(selected ?? [])].map((id) => ({
      id,
      op: IncrementalCollectionOp.Include,
    }));
    const addedGroups: SaveChangeRequestGroupInput[] =
      formState.changedValue.groups?.map((id) => ({
        id,
        changeRequests,
      })) ?? [];
    const hasNewGroup = formState.changedValue.maybeNewGroup !== undefined;
    const maybeNewGroup = hasNewGroup ? [{ name: formState.changedValue.maybeNewGroup, changeRequests }] : [];
    await saveDependency({
      variables: {
        id: changeRequestId,
        [key]: [...(selected ?? []).map((id) => ({ id, op: IncrementalCollectionOp.Include }))],
        groups: [...addedGroups, ...maybeNewGroup],
      },
      refetchQueries: ["ChangeLogRightPaneContentDetails"],
    });
    closeModal();
    formState.commitChanges();
  }, [closeModal, formState, changeRequestId, kind, saveDependency]);

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <div>
              <div css={Css.mb3.$}>
                <span css={Css.sm.gray700.$}>{description}</span>
              </div>
              <BoundMultiSelectField field={formState.selected} options={options} label={label} />
              {kind === ChangeRequestDependency.Link && <ChangeRequestGroupFields formState={formState} />}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="text" label="Cancel" onClick={closeModal} />
            <Button variant="primary" label={saveLabel} onClick={handleSubmit} />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type ChangeRequestGroupProps = {
  formState: ObjectState<ChangeRequestDependencyForm>;
};

function ChangeRequestGroupFields({ formState }: ChangeRequestGroupProps) {
  const [createOpen, setCreateOpen] = useState(false);
  const { data } = useChangeRequestGroupsForModalQuery();

  const { changeRequestGroups } = data ?? {};

  return (
    <div css={Css.df.fdc.gap2.mt2.$}>
      <Observer>
        {() => (
          <>
            <BoundMultiSelectField
              field={formState.groups}
              options={changeRequestGroups ?? []}
              label="Add to Existing Group (Optional)"
            />
            {!createOpen ? (
              <Button variant="text" label="Create New Group" onClick={() => setCreateOpen(true)} />
            ) : (
              <BoundTextField field={formState.maybeNewGroup} label="Create New Group (Optional)" />
            )}
          </>
        )}
      </Observer>
    </div>
  );
}

type ChangeRequestDependencyForm = {
  id: string;
  selected: InputMaybe<string[]>;
  groups: InputMaybe<string[]>;
  maybeNewGroup: InputMaybe<string>;
};

const formConfig: ObjectConfig<ChangeRequestDependencyForm> = {
  id: { type: "value", rules: [required] },
  selected: { type: "value", rules: [required] },
  groups: { type: "value" },
  maybeNewGroup: { type: "value" },
};
