import { Css, GridColumn, GridDataRow, RowStyles, simpleDataRows, SimpleHeaderAndData } from "@homebound/beam";
import { camelCase } from "change-case";
import { Fragment } from "react";
import { Price, priceCell, QueryTable } from "src/components";
import {
  HomeownerChangeOrderLineItemFragment,
  HomeownerChangeOrderQueryResult,
  useHomeownerChangeOrderQuery,
} from "src/generated/graphql-types";
import { sum } from "src/utils";
import { queryResult } from "src/utils/queryResult";
import { StringParam, useQueryParam } from "use-query-params";

export function HomeownerChangeOrderPdf() {
  const [id] = useQueryParam("estimateId", StringParam);
  const query = useHomeownerChangeOrderQuery({ variables: { id: id! } });
  return queryResult(query, {
    data: ({
      projectEstimate: {
        totalPriceInCents,
        projectStage: {
          homeownerContracts: [contract],
        },
      },
    }) => {
      const previousTotal = contract
        ? contract.priceChangeInCents + contract?.changeOrders.map((co) => co.priceChangeInCents).reduce(sum, 0)
        : 0;
      return (
        <Fragment>
          <h1 css={Css.gray800.fwb.xl2Sb.m0.mb2.$}>Line Items</h1>
          <div css={Css.bt.bb.br.bcGray400.mb5.$}>
            <QueryTable query={query} createRows={createRows} columns={columns} rowStyles={rowStyles} />
            <div css={Css.fwb.bl.bt.p1.pr3.bcGray400.tar.$}>
              Total: <Price valueInCents={totalPriceInCents} />
            </div>
          </div>
          <h1 css={Css.gray800.fwb.xl2Sb.m0.mb2.$}>Effect on Contract Total</h1>
          <div
            css={
              Css.ba.bcGray400
                .mb("2px")
                .dg.add({ gridTemplateColumns: "300px auto" })
                .addIn("& > div + div > *", Css.bt.bcGray400.$).$
            }
            data-testid={id}
          >
            {(
              [
                ["Previous Contract Total", previousTotal],
                ["This Change Order", totalPriceInCents],
                ["New Contract Total*", previousTotal + totalPriceInCents],
              ] as const
            ).map(([label, valueInCents], i) => (
              <div key={i} css={Css.display("contents").$}>
                <div css={Css.df.fwb.p2.$}>{label}</div>
                <div css={Css.df.p2.bl.bcGray400.$} data-testid={camelCase(label)}>
                  <Price valueInCents={valueInCents} />
                </div>
              </div>
            ))}
          </div>
          <div>* May not include other change orders that have been approved since this change order was created </div>
        </Fragment>
      );
    },
  });
}

type Row = SimpleHeaderAndData<HomeownerChangeOrderLineItemFragment>;

// Headers need to be wrapped in a span to avoid default styling applied to simple strings in `maybeAddHeaderStyling`
const h = (header: string) => () => <span>{header}</span>;
const columns: GridColumn<Row>[] = [
  { header: h("Category"), data: (lineItem) => lineItem.projectItem.item.costCode.division.name },
  {
    header: h("Item"),
    data: ({ projectItem: { displayName, homeownerSelection } }) => [
      displayName,
      ...(homeownerSelection ? [<br />, homeownerSelection.selectedOption?.name ?? "No Selection"] : []),
    ],
  },
  {
    header: h("Item Details"),
    data: ({ projectItem: { specifications } }) => specifications,
  },
  {
    header: h("Quantity"),
    data: ({ quantity, unitOfMeasure }) => {
      return unitOfMeasure?.useQuantity === false
        ? (unitOfMeasure.name ?? "")
        : `${quantity} ${unitOfMeasure?.name ?? ""}`;
    },
  },
  { header: h("Unit Price"), data: ({ unitPriceInCents }) => priceCell({ valueInCents: unitPriceInCents }) },
  { header: h("Total"), data: ({ totalPriceInCents }) => priceCell({ valueInCents: totalPriceInCents }) },
];

const rowStyles: RowStyles<Row> = {
  header: { cellCss: Css.sm.jcfs.bl.bcGray400.gray800.fwb.$ },
  data: { cellCss: Css.bl.bcGray400.gray800.jcfs.$ },
};

function createRows(data?: HomeownerChangeOrderQueryResult["data"]): GridDataRow<Row>[] {
  return simpleDataRows(data?.projectEstimate.lineItems ?? []);
}
