import { Css } from "@homebound/beam";
import { ReactNode } from "react";

/** To be used as the body of the `StepperContext` when using the `newLayout` property */
export function StepLayout({ header, body }: { header: ReactNode; body: ReactNode }) {
  return (
    <>
      {header}
      <div css={Css.df.fdc.fg1.oa.px3.$}>{body}</div>
    </>
  );
}
