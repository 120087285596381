import { Button, Css, Font, Icon, IconKey, Margin, useTestIds, Xss } from "@homebound/beam";
import { useState } from "react";
import { FormattedDate } from "src/components";
import { foldEnum, isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";

/**
 * Displays a list of data prefixed with an associated icon
 *
 * ListStyle:
 * - Detail: splits data into icon column & longer description column with gray text color
 * - Simple: splits data into icon & short label column
 */
type IconsListProps = {
  list: {
    icon: IconKey;
    iconLabel?: string;
    value?: (string | number)[] | string | number | undefined;
    date?: { label: string; value: DateOnly | null | undefined };
    /** Add a text button to view the entire value list or just limit to the first 2 */
    limitList?: boolean;
  }[];
  listStyle: IconListStyle;
};

export function IconsList(props: IconsListProps) {
  const { list, listStyle } = props;
  const { listXss, valueXss } = iconListStyles(listStyle);
  const tid = useTestIds({});

  return (
    <div>
      <div css={Css.df.fdc.jcc.smMd.$}>
        {list.map((l, idx) => (
          <div css={Css.df.fdr.gap1.$} key={l.icon}>
            {l.value && (
              <div css={listXss}>
                <div css={Css.df.fdr.gap1.$}>
                  <Icon icon={l.icon} inc={2} />
                  {l.iconLabel && (
                    <div css={Css.xsMd.$} {...tid.iconLabel}>
                      {l.iconLabel}
                    </div>
                  )}
                </div>

                {Array.isArray(l.value) ? (
                  <div>
                    {isDefined(list[idx].limitList) ? (
                      <LimitValues options={l.value} valueXss={valueXss} />
                    ) : (
                      l.value.map((val) => (
                        <div css={valueXss} key={val} {...tid.iconValue}>
                          {val}
                        </div>
                      ))
                    )}
                  </div>
                ) : (
                  <div css={valueXss} {...tid.iconValue}>
                    {l.value}
                  </div>
                )}
              </div>
            )}

            {!!l.date?.value && (
              <div css={Css.df.fdr.gap1.$}>
                <Icon icon={l.icon} inc={2} />
                <div {...tid.iconDate}>{l.date.label}</div>
                <FormattedDate date={l.date.value} dateFormatStyle="short" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export enum IconListStyle {
  DetailList,
  SimpleList,
}

type IconListXStyle = Xss<"color" | "display" | "gridTemplateColumns" | "gap" | "alignItems" | Margin | Font>;

function iconListStyles(listStyle: IconListStyle): { listXss: IconListXStyle; valueXss: IconListXStyle } {
  return foldEnum(listStyle, {
    [IconListStyle.DetailList]: {
      listXss: Css.dg.gtc("115px 430px").gap3.mt1.aifs.$,
      valueXss: Css.dg.gtc("1fr 1fr").gap1.aifs.sm.gray500.$,
    },
    [IconListStyle.SimpleList]: { valueXss: Css.smMd.$, listXss: Css.df.fdr.gap1.$ },
  });
}

/**
 * Limit lists with large value arrays (e.g. Project config plans/options with +100 items)
 * Add a text button to view the entire value list or just limit to the first 2
 */
function LimitValues({ options, valueXss }: { options: (string | number)[]; valueXss: IconListXStyle }) {
  const tid = useTestIds({});
  const [showAll, setShowAll] = useState(false);
  const limitedOrAllOptions = showAll ? options : options.slice(0, 2);
  const fullLength = options.length;
  const label = showAll ? (
    <div css={Css.df.fdr.$}>
      <Icon icon="undoCircle" inc={2} /> &nbsp; limit view
    </div>
  ) : (
    `+${fullLength - limitedOrAllOptions.length} more`
  );

  return (
    <>
      {limitedOrAllOptions.map((val) => (
        <div css={valueXss} key={val} {...tid.iconValue}>
          {val}
        </div>
      ))}
      <Button variant="text" label={label} onClick={() => setShowAll(!showAll)} />
    </>
  );
}
