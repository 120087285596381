import {
  Css,
  GridTable,
  Icon,
  Palette,
  RowStyles,
  ScrollableContent,
  column,
  emptyCell,
  selectColumn,
  simpleHeader,
} from "@homebound/beam";
import { useMemo } from "react";
import { createMilestoneCatalogFormUrl } from "src/RouteUrls";
import {
  MilestoneCatalogPage_GlobalPlanMilestoneFragment,
  MilestoneCatalogPage_GlobalPlanMilestoneGroupFragment,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils";

type MilestoneCatalogTableProps = {
  globalPlanMilestones: MilestoneCatalogPage_GlobalPlanMilestoneFragment[];
  maybeFetchMore: () => void;
};

export function MilestoneCatalogTable({ globalPlanMilestones, maybeFetchMore }: MilestoneCatalogTableProps) {
  const rows = useMemo(() => createRows(globalPlanMilestones ?? []), [globalPlanMilestones]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <ScrollableContent virtualized>
      <GridTable
        columns={columns}
        rows={rows}
        as="virtual"
        infiniteScroll={{ onEndReached: maybeFetchMore }}
        rowStyles={rowStyles}
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: MilestoneCatalogPage_GlobalPlanMilestoneFragment };

export type Row = HeaderRow | DataRow;

const rowStyles: RowStyles<Row> = {
  data: {
    rowLink: (row) => createMilestoneCatalogFormUrl(row.data.id),
    cellCss: Css.sm.$,
  },
};

function createColumns() {
  return [
    selectColumn<Row>({
      header: emptyCell,
    }),
    column<Row>({
      header: "Milestone Name",
      data: ({ name }) => <span>{name}</span>,
    }),
    column<Row>({
      header: "Constraints",
      data: ({ constraints }) => <span>{constraints.length}</span>,
    }),
    column<Row>({
      header: "Team",
      data: ({ groups }) => <span>{formatTeamColumn(groups)}</span>,
    }),
    column<Row>({
      header: "Default",
      data: ({ isDefault }) => (isDefault ? <Icon icon={"checkCircle"} color={Palette.Gray600} /> : emptyCell),
    }),
    column<Row>({
      header: () => (
        <div css={Css.df.jcsb.gap1.aic.$}>
          <div>System</div>
          <Icon
            color={Palette.Gray600}
            inc={2}
            icon="infoCircle"
            tooltip="These milestones power reporting pages and other metrics, and therefore cannot be deleted."
          />
        </div>
      ),
      data: ({ systemPlanMilestone }) =>
        isDefined(systemPlanMilestone) ? <Icon icon={"checkCircle"} color={Palette.Gray600} /> : emptyCell,
    }),
  ];
}

function createRows(data: MilestoneCatalogPage_GlobalPlanMilestoneFragment[]) {
  return [
    simpleHeader,
    ...data.map((globalPlanMilestone) => ({
      id: globalPlanMilestone.id,
      kind: "data" as const,
      data: globalPlanMilestone,
    })),
  ];
}

// helper function to grab all the milestone group's business functions
function formatTeamColumn(groups: MilestoneCatalogPage_GlobalPlanMilestoneGroupFragment[]) {
  const groupByBusinessFunction = groups.groupBy((group) => group.businessFunctionType.name);
  return Object.keys(groupByBusinessFunction)
    .map((businessFunction) => businessFunction)
    .join(", ");
}
