import { Css, dateFilter, FilterDefs, Filters, multiFilter } from "@homebound/beam";
import { subWeeks } from "date-fns";
import { useMemo } from "react";
import {
  InternalUserDetailFragment,
  Named,
  ProjectFeature,
  User,
  UserEventDetailProjectFragment,
  UserEventDetailUserEventParentTypeFragment,
} from "src/generated/graphql-types";
import { myProjectsId } from "src/routes/developments/schedule/DevelopmentBulkSchedulePage";
import { useStorageFilter } from "src/routes/projects/budget/components/useStorageFilter";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { DateFilterOperation, dateFilterOperations, sortBy } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { UserEventFilterWithDateFilterValue, UserEventsTable } from "./UserEventsTable";

export type ActivityFeedTableProps = {
  projects?: UserEventDetailProjectFragment[];
  cohorts?: Named[];
  developments?: Named[];
  /** Used for the parent type filter options */
  types?: UserEventDetailUserEventParentTypeFragment[];
  users: InternalUserDetailFragment[];
  currentUser: User;
  projectId?: string;
  allowedEntities?: string[];
  /** Used to view the activity only for requested parent ids */
  parentIds?: string[] | undefined;
  allEvents?: boolean;
};

export function ActivityFeedTable({
  projects,
  cohorts,
  developments,
  types,
  users,
  currentUser,
  projectId,
  allowedEntities,
  parentIds,
  allEvents = false,
}: ActivityFeedTableProps) {
  const { features } = useProjectContext();
  // add conditional check bc we dont have project context on dashboard
  const delayFeatureFlagIsOn = features.includes(ProjectFeature.DelayFlags);

  // "Flags" is referring to the "Task" event parent; non-Flagged events will be filtered out on the client side
  const typesToSort = delayFeatureFlagIsOn ? [...(types || []), { id: "t", name: "Flag" }] : (types ?? []);
  const sortedTypes = sortBy(typesToSort, (type) => type.name);
  const projectsWithMyProjects = useMemo(() => {
    return projects ? [{ id: myProjectsId, name: "My Projects" }, ...projects] : [];
  }, [projects]);
  const isProjectPage = !!projectId;
  const isBudgetSuperDrawer = !!parentIds;
  const filterDefs: FilterDefs<UserEventFilterWithDateFilterValue> = useMemo(() => {
    return {
      ...(!isProjectPage
        ? {
            project: multiFilter({
              label: "Projects",
              options: projectsWithMyProjects,
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
              defaultValue: projectsWithMyProjects.nonEmpty ? [myProjectsId] : [],
            }),
            cohort: multiFilter({
              label: "Cohorts",
              options: cohorts ?? [],
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
            development: multiFilter({
              options: developments ?? [],
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
          }
        : {}),
      createdAt: dateFilter({
        label: "Created at",
        operations: dateFilterOperations,
        getOperationLabel: ({ label }) => label,
        getOperationValue: ({ value }) => value,
        defaultValue: allEvents
          ? undefined
          : { op: DateFilterOperation.After, value: new DateOnly(subWeeks(new Date(), 1)) },
      }),
      parentType: multiFilter({
        label: "Type",
        options: sortedTypes ?? [],
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
      createdBy: multiFilter({
        label: "Created by",
        options: users,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ name }) => name,
      }),
      ...(!isBudgetSuperDrawer
        ? {
            createdFor: multiFilter({
              label: "Created for",
              options: users,
              getOptionValue: ({ id }) => id,
              getOptionLabel: ({ name }) => name,
            }),
          }
        : {}),
    };
  }, [
    isProjectPage,
    projectsWithMyProjects,
    cohorts,
    developments,
    allEvents,
    sortedTypes,
    users,
    isBudgetSuperDrawer,
  ]);

  const storageKey = isProjectPage ? "projectUserEventsFilter" : "userEventsFilter";
  const { setFilter, filter } = useStorageFilter<UserEventFilterWithDateFilterValue>({
    storageKey,
    filterDefs,
  });

  return (
    <>
      <div css={Css.pb2.mb1.tar.df.jcsb.aife.$}>
        <Filters<UserEventFilterWithDateFilterValue> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
      </div>
      <UserEventsTable
        currentUser={currentUser}
        filter={filter}
        queryFilter={{ projectId, parentIds, parentType: allowedEntities }}
      />
    </>
  );
}
