import { createContext, MutableRefObject, PropsWithChildren, useContext, useMemo, useRef } from "react";

type BlueprintContextProps = {
  headerActionsRef: MutableRefObject<HTMLDivElement | null> | null;
  headerActionsEl: HTMLDivElement | null;
};

export const BlueprintContext = createContext<BlueprintContextProps>({
  headerActionsRef: null,
  headerActionsEl: null,
});

export function BlueprintProvider({ children }: PropsWithChildren<unknown>) {
  const headerActionsRef = useRef<HTMLDivElement | null>(null);
  const headerActionsEl = useMemo(() => document.createElement("div"), []);
  const context: BlueprintContextProps = { headerActionsRef, headerActionsEl };

  return <BlueprintContext.Provider value={context}>{children}</BlueprintContext.Provider>;
}

export function useBlueprintContext() {
  return useContext(BlueprintContext);
}
