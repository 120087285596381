import {
  BoundSwitchField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  StaticField,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  ReadyPlanOptionDetailFragment,
  SaveReadyPlanOptionInput,
  useSaveReadyPlanOptionsModalMutation,
} from "src/generated/graphql-types";
import { ArchiveOptionModalButton } from "src/routes/developments/plan-and-options/components/ArchiveOptionModalButton";
import { AddReadyPlanOption } from "./utils";

type EditOtherOptionModalProps = {
  readyPlanOption: Pick<
    ReadyPlanOptionDetailFragment,
    "id" | "active" | "name" | "description" | "location" | "homeownerChoice" | "globalOption"
  >;
  fState?: ObjectState<AddReadyPlanOption>;
};

export function EditOtherOptionModal({ readyPlanOption, fState }: EditOtherOptionModalProps) {
  const { id, active, name, location, globalOption } = readyPlanOption;
  const { closeModal } = useModal();
  const formState = useFormState({
    config,
    init: {
      input: readyPlanOption,
      map: ({ id, name, description, homeownerChoice }) => ({ id, name, description, homeownerChoice }),
    },
  });
  const [save] = useSaveReadyPlanOptionsModalMutation();
  const onSave = useCallback(async () => {
    await save({ variables: { input: { readyPlanOptions: [{ active: true, ...formState.changedValue }] } } });
    fState?.name?.set(formState.name.value);
    fState?.active?.set(true);
    fState?.globalOptionDescription?.set(formState.description.value);
    fState?.commitChanges();
    closeModal();
  }, [closeModal, formState, fState, save]);

  return (
    <>
      <ModalHeader>
        <div css={Css.xs.gray700.$}>{globalOption.code}</div>
        {name}
      </ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundTextField
            field={formState.name}
            label="Option Name"
            helperText="Changing the option name will only change it for this development"
          />
        </FormLines>
        <FormLines>
          <StaticField label="Location" value={location ? location.name : "N/A"} />
        </FormLines>
        <FormLines>
          <BoundTextAreaField field={formState.description} />
        </FormLines>
        <FormLines>
          <BoundSwitchField label="Homeowner Choice" labelStyle="filter" field={formState.homeownerChoice} />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        {active && (
          <div css={Css.mra.$}>
            <ArchiveOptionModalButton optionId={id} formState={fState} />
          </div>
        )}
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label={active ? "Save" : "Activate"}
              disabled={active ? !formState.dirty : false}
              onClick={onSave}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

const config: ObjectConfig<SaveReadyPlanOptionInput> = {
  id: { type: "value" },
  name: { type: "value" },
  description: { type: "value" },
  homeownerChoice: { type: "value" },
};
