import { Button, Css } from "@homebound/beam";
import { useEffect } from "react";
import { StepActions, useStepperContext } from "src/components/stepper";
import { ScheduleTemplateWithMappingInfo } from "src/routes/developments/cost-mapping/DevelopmentScheduleCostMappingPage";
import ScheduleTemplateSelector from "../components/ScheduleTemplateSelector";

type TemplateSelectStepViewProps = {
  scheduleTemplates: ScheduleTemplateWithMappingInfo[];
  selectedTemplate?: ScheduleTemplateWithMappingInfo;
  onTemplateSelect: (mstf: ScheduleTemplateWithMappingInfo) => void;
};

export function TemplateSelectStep({
  scheduleTemplates,
  selectedTemplate,
  onTemplateSelect,
}: TemplateSelectStepViewProps) {
  const { nextStep, setSteps } = useStepperContext();

  function handleSelection(template: ScheduleTemplateWithMappingInfo) {
    if (template.id !== selectedTemplate?.id) {
      onTemplateSelect(template);
    }
  }

  const enableNextStep = selectedTemplate !== undefined;

  useEffect(() => {
    setSteps((prevState) => {
      return [
        { ...prevState[0], state: prevState[0].state === "complete" || enableNextStep ? "incomplete" : "complete" },
        { ...prevState[1], disabled: !enableNextStep },
        { ...prevState[2] },
      ];
    });
  }, [enableNextStep, setSteps]);

  return (
    <>
      <header css={Css.mtPx(2).mb2.$} data-testid="templateSelectStepTitle">
        <h1 css={Css.xl3Sb.mb2.gray900.$}>Start by selecting a schedule template</h1>
      </header>
      <div css={Css.p3.bgGray100.br8.mh("75vh").$} data-testid="templateSelectStep">
        <ScheduleTemplateSelector
          handleSelection={handleSelection}
          scheduleTemplates={scheduleTemplates}
          selectedTemplate={selectedTemplate}
        />
        <StepActions>
          <div>
            <Button
              label="Continue"
              disabled={!enableNextStep}
              onClick={() => {
                nextStep();
              }}
            />
          </div>
        </StepActions>
      </div>
    </>
  );
}
