import { Css, SelectField } from "@homebound/beam";
import { CustomDynamicSchedulesFilter } from "./DynamicSchedulesFilterModal";

export enum TaskGroupBy {
  None = "None",
  CostCode = "CostCode",
  Stage = "Stage",
}

type DynamicSchedulesGroupByProps = {
  queryStorage: CustomDynamicSchedulesFilter;
  setQueryStorage: (filter: CustomDynamicSchedulesFilter) => void;
};

export function DynamicSchedulesGroupBy({ queryStorage, setQueryStorage }: DynamicSchedulesGroupByProps) {
  return (
    <div css={Css.maxwPx(200).$}>
      <SelectField
        compact
        value={queryStorage.groupBy}
        onSelect={(val) => setQueryStorage({ ...queryStorage, groupBy: val! })}
        label="Group By"
        options={[
          { value: TaskGroupBy.None, label: "None" },
          { value: TaskGroupBy.CostCode, label: "Cost Code" },
          { value: TaskGroupBy.Stage, label: "Stage" },
        ]}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        labelStyle="inline"
      />
    </div>
  );
}
