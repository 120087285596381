import { ApolloError } from "@apollo/client";
import { Button, Css, px, useTestIds } from "@homebound/beam";
import { generatePath } from "react-router-dom";
import { useFuzzyRouteMatch } from "src/hooks/useFuzzyRouteMatch";

export function SuggestedPaths({ error }: { error?: ApolloError | Error }) {
  const [suggestedPaths, matchedParams] = useFuzzyRouteMatch(error);
  const tid = useTestIds({}, "suggestedPaths");
  return (
    <>
      {suggestedPaths && suggestedPaths.length > 0 && (
        <div css={Css.tac.$}>
          <h2 css={Css.baseMd.mt4.$} {...tid.title}>
            Let's get you headed in the right direction!
          </h2>
          <p {...tid.subtitle}>Maybe one of these links will help you get back on track</p>
          <ul css={Css.listReset.df.mxa.gap2.mt2.maxw(px(600)).add("flexWrap", "wrap").jcc.$}>
            {suggestedPaths.map((m) => {
              const path = generatePath(m, matchedParams);
              return (
                <li key={path} {...tid}>
                  <Button onClick={path} label={path} variant="secondary" />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
