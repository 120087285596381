import { column, Css, GridColumn, GridDataRow, useResponsiveGrid, useResponsiveGridItem } from "@homebound/beam";
import { ReactNode } from "react";
import { useParams } from "react-router";
import { FormattedDate, Percentage, Price, QueryTable } from "src/components";
import {
  PreConExpectationsTabQueryResult,
  ProjectStageStatus,
  usePreConExpectationsTabQuery,
} from "src/generated/graphql-types";
import { RecentActivityRail } from "src/routes/projects/dashboard/components/RecentActivityRail/RecentActivityRail";
import { ProjectParams } from "src/routes/routesDef";

export function PreConExpectationsTab() {
  const { projectId } = useParams<ProjectParams>();
  const query = usePreConExpectationsTabQuery({ variables: { projectId } });
  const { gridStyles } = useResponsiveGrid({ minColumnWidth: 276, columns: 4, gap: 24 });
  const { gridItemProps } = useResponsiveGridItem({ colSpan: 3 });
  return (
    <div css={{ ...gridStyles, ...Css.aifs.$ }}>
      <div css={Css.bgWhite.p3.br8.bshBasic.$} {...gridItemProps}>
        <h2 css={Css.xlBd.mb4.$}>Pre-Con Expectations</h2>
        <QueryTable
          query={query}
          createRows={createRows}
          columns={createColumns()}
          style={{ allWhite: true, cellTypography: "sm" }}
        />
      </div>

      <RecentActivityRail />
    </div>
  );
}

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({ mw: "240px", data: ({ label }) => label }),
    column<Row>({ w: "160px", align: "right", data: ({ value }) => value }),
  ];
}

function createRows(data: PreConExpectationsTabQueryResult["data"]): GridDataRow<Row>[] {
  if (!data) return [];

  const { constructionConfidenceFactor, stage } = data.project;
  const { interval, status, hocFinancials, homeownerContracts } = stage;

  const estimatedTotalInCents = homeownerContracts
    .flatMap((hc) => hc.lineItems)
    .reduce((total, li) => total + (li?.budgetChangeInCents || 0), 0);
  const changeOrderTotalInCents = homeownerContracts
    .flatMap((hc) => hc.changeOrders)
    .flatMap((co) => co.lineItems)
    .reduce((total, li) => total + (li?.budgetChangeInCents || 0), 0);
  const actualTotalInCents = estimatedTotalInCents + changeOrderTotalInCents;
  const isPreconCompleted = status.code === ProjectStageStatus.Completed;

  return [
    {
      kind: "data",
      id: "ocv",
      data: {
        label: "Original Contract Value",
        value: <Price valueInCents={hocFinancials.originalContractValueInCents ?? 0} dropZero />,
      },
    },
    {
      kind: "data",
      id: "at",
      data: { label: "Actual Total", value: <Price valueInCents={actualTotalInCents} dropZero /> },
    },
    {
      kind: "data",
      id: "as",
      data: {
        label: "Actual Start",
        value: <FormattedDate date={interval.startDate} dateFormatStyle="medium" mdash />,
      },
    },
    {
      kind: "data",
      id: "ac",
      data: {
        label: "Actual Completion",
        value: <FormattedDate date={isPreconCompleted ? interval.endDate : undefined} dateFormatStyle="medium" mdash />,
      },
    },
    {
      kind: "data",
      id: "ccf",
      data: {
        label: "Construction Confidence Factor",
        value: <Percentage percent={constructionConfidenceFactor} dropZero />,
      },
    },
  ];
}

type Row = { kind: "data"; id: string; data: { label: string; value: ReactNode } };
