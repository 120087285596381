import { Route, Switch } from "react-router-dom";
import { BidPackagesPage } from "src/routes/bids/BidPackagesPage";
import { PurchaseOrdersPage } from "src/routes/developments/purchase-orders/PurchaseOrdersPage";
import { bidPackagePath, tradesPurchaseOrdersPath, tradesTradePartnersPath } from "src/routes/routesDef";
import { TradePartnersPage } from "src/routes/trade-partners/TradePartnersPage";

export function TradesLayout() {
  return (
    <Switch>
      <Route path={tradesTradePartnersPath} component={TradePartnersPage} />
      <Route path={tradesPurchaseOrdersPath} render={() => <PurchaseOrdersPage isGlobal={true} isTrades={true} />} />
      <Route path={bidPackagePath} component={BidPackagesPage} />
    </Switch>
  );
}
