import {
  Avatar,
  Button,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  Palette,
  simpleHeader,
  SimpleHeaderAndData,
  Tooltip,
} from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { formatDate, Icon } from "src/components";
import {
  DashboardTileSize,
  ScheduleTile_ScheduleTaskFragment,
  Stage,
  useProjectScheduleTileQuery,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { EmptyTile } from "src/routes/projects/dashboard/components/EmptyTile";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { TileSpansAndSize, useTileSize } from "src/routes/projects/dashboard/hooks/useTileSize";
import { DurationCellReadOnly } from "src/routes/projects/schedule-v2/table/DurationCellReadOnly";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";
import { openNewTab } from "src/utils/window";

export function ScheduleTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const { latestActiveStage } = useProjectContext();
  const tileSpansAndSize = useTileSize(props.userDashboardConfig.size);

  const query = useProjectScheduleTileQuery({
    variables: { projectId, stage: latestActiveStage },
  });
  const flagCount = query.data?.schedules?.first?.flagCount ?? 0;

  return (
    <Tile
      {...props}
      href={createProjectScheduleUrl(projectId)}
      titleAdornment={
        flagCount > 0 && (
          <div css={Css.xsMd.df.gap1.aic.$}>
            <Icon icon="flag" color={Palette.Red500} pxSize={20} /> {flagCount}
          </div>
        )
      }
    >
      {queryResult(query, ({ scheduleTasks }) => {
        return scheduleTasks.nonEmpty ? (
          <div>
            <ScheduleTileTable
              scheduleTasks={scheduleTasks}
              tileSpansAndSize={tileSpansAndSize}
              projectId={projectId}
              latestActiveStage={latestActiveStage}
            />
          </div>
        ) : (
          <EmptyScheduleTileWrapper projectId={projectId} />
        );
      })}
    </Tile>
  );
}

type ScheduleTileTableProps = {
  scheduleTasks: ScheduleTile_ScheduleTaskFragment[];
  tileSpansAndSize: TileSpansAndSize;
  projectId: string;
  latestActiveStage: Stage;
};

function ScheduleTileTable({ scheduleTasks, tileSpansAndSize, projectId, latestActiveStage }: ScheduleTileTableProps) {
  const size = tileSpansAndSize.size;
  const rows = useMemo(() => createRows(scheduleTasks ?? []), [scheduleTasks]);
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => createColumns(size, projectId, latestActiveStage), [size]);

  return (
    <div css={Css.mt2.$}>
      <GridTable
        style={{
          rowHeight: "fixed",
          allWhite: true,
          rowHover: false,
        }}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}

type Row = SimpleHeaderAndData<ScheduleTile_ScheduleTaskFragment>;

function createRows(tasks: ScheduleTile_ScheduleTaskFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...tasks.map((task) => ({ kind: "data" as const, id: task.id, data: task }))];
}

function createColumns(size: DashboardTileSize, projectId: string, latestActiveStage: Stage): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => ({
        content: () => (
          <Tooltip title={"Next 30 tasks in the schedule"} placement="top">
            Task name
          </Tooltip>
        ),
      }),
      data: ({ name, id }) => ({
        content: () => (
          <Tooltip title={name} placement="top">
            <Link to={createProjectScheduleUrl(projectId, latestActiveStage, id)} css={Css.truncate.$}>
              {name}
            </Link>
          </Tooltip>
        ),
      }),
      mw: "150px",
    }),
    ...(sizeToShowDateColumns[size]
      ? [
          column<Row>({
            header: "Start",
            data: ({ interval }) => formatDate(interval.startDate),
            w: "80px",
          }),
          column<Row>({
            header: "End",
            data: ({ interval }) => formatDate(interval.endDate),
            w: "80px",
          }),
        ]
      : []),
    column<Row>({
      header: "Duration",
      data: ({ interval }) => <DurationCellReadOnly interval={interval} />,
      w: "80px",
    }),
    column<Row>({
      header: "Status",
      data: ({ statusDetail }) => statusDetail.name,
      w: "80px",
    }),
    column<Row>({
      header: emptyCell,
      data: ({ assignee }) => {
        return assignee ? <Avatar name={assignee.name} src={assignee.avatar} size="sm" /> : emptyCell;
      },
      w: "48px",
    }),
  ];
}

const sizeToShowDateColumns: Record<DashboardTileSize, boolean> = {
  SMALL: false,
  MEDIUM: true,
  LARGE: true,
};

// wrapper to show empty state if there is no schedule for the project
function EmptyScheduleTileWrapper({ projectId }: { projectId: string }) {
  return (
    <EmptyTile data-testid="scheduleTileEmptyState">
      <p css={Css.mb2.$}>
        There is no schedule <br /> yet for this project.
      </p>
      <Button label="Go to the Schedule" onClick={() => openNewTab(createProjectScheduleUrl(projectId))} />
    </EmptyTile>
  );
}
