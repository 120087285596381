import { Css } from "@homebound/beam";
import React from "react";
import { SearchBox } from "src/components";
import { TableActions } from "src/routes/layout/TableActions";
import { useTakeoffsStore } from "src/routes/libraries/plan-package/takeoffs/TakeoffsManagerContext";
import { GroupBySelectField } from "src/routes/libraries/plan-package/takeoffs/components/GroupBySelectField";
import { SortBySelectField } from "src/routes/libraries/plan-package/takeoffs/components/SortBySelectField";
import { TakeoffsItemsTableBulkActionsMenu } from "src/routes/libraries/plan-package/takeoffs/components/TakeoffsItemsTableBulkActionsMenu";

export function TakeoffsItemsTableActionsImpl() {
  const setSearch = useTakeoffsStore((state) => state.setItemTableSearch);

  return (
    <TableActions>
      <div css={Css.fg1.df.aic.gap1.$}>
        <div css={Css.mwPx(140).$}>
          <SearchBox onSearch={setSearch} debounceDelayInMs={200} />
        </div>
        <div>
          <GroupBySelectField />
        </div>
        <div>
          <SortBySelectField />
        </div>
      </div>

      <TakeoffsItemsTableBulkActionsMenu />
    </TableActions>
  );
}

export const TakeoffsItemsTableActions = React.memo(TakeoffsItemsTableActionsImpl);
