import { HistoryFeed } from "src/components";
import { useScheduleHistoryTabQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export type HistoryTabProps = {
  taskId: string;
};

export function HistoryTab({ taskId }: HistoryTabProps) {
  const query = useScheduleHistoryTabQuery({
    variables: { taskId },
  });

  return queryResult(query, {
    data: (data) => <HistoryFeed historyItems={[...data.scheduleTask.history].reverse()} renderAsCard={false} />,
  });
}
