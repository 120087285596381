import { Button, useModal } from "@homebound/beam";
import { differenceInCalendarDays } from "date-fns";
import { useEffect, useState } from "react";
import { BannerNotice } from "src/components";
import {
  ItemTemplateStatus,
  Order,
  ScopeChangeType,
  useRecentlyActivatedItemTemplatesQuery,
} from "src/generated/graphql-types";
import { PlanBasedBidRequest } from "../DevelopmentProcurementPage";
import { DevelopmentTemplateItemsModalSize } from "./DevelopmentTemplateItemsModal";

type GenerateNewBidRequestNoticeProps = {
  developmentId: string;
};

export function GenerateNewBidRequestNotice({ developmentId }: GenerateNewBidRequestNoticeProps) {
  const { openModal } = useModal();
  const { data, loading } = useRecentlyActivatedItemTemplatesQuery({
    variables: {
      filter: {
        development: [developmentId],
        status: [ItemTemplateStatus.Active],
        scopeChangeType: [ScopeChangeType.Major],
      },
      order: { activatedAt: Order.Desc },
      limit: 1,
    },
  });
  const limitDaysToShowBanner = 7;
  const [daysDiff, setDaysDiff] = useState(false);

  useEffect(() => {
    if (!loading && data) {
      // The first item template is because default query have limit 1
      const activatedAt = data?.itemTemplates[0]?.activatedAt;
      if (activatedAt) {
        const daysDiff = differenceInCalendarDays(activatedAt, new Date()) <= limitDaysToShowBanner;
        setDaysDiff(daysDiff);
      }
    }
  }, [data, loading]);

  return (
    <>
      {
        // This banner is displayed for 7 days after a new major version of an ItemTemplate were published
        daysDiff && (
          <BannerNotice
            icon="infoCircle"
            message="New scope template versions have been created"
            right={
              <Button
                label="Create New Bid Request"
                variant="tertiary"
                contrast
                onClick={() =>
                  openModal({
                    content: <PlanBasedBidRequest developmentId={developmentId} />,
                    size: DevelopmentTemplateItemsModalSize,
                  })
                }
              />
            }
          />
        )
      }
    </>
  );
}
