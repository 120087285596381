import {
  Avatar,
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  simpleHeader,
  SimpleHeaderAndData,
  Tooltip,
  useTestIds,
} from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import { formatDate } from "src/components";
import { HomeownerNoteTileFragment, useHomeownerNotesTileQuery } from "src/generated/graphql-types";
import { EmptyTile } from "src/routes/projects/dashboard/components/EmptyTile";
import { Tile, TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { createProjectHomeownerNotesUrl } from "src/RouteUrls";
import { queryResult } from "src/utils";

export function HomeownerNotesTile(props: TileBaseProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const tid = useTestIds({});
  const query = useHomeownerNotesTileQuery({ variables: { projectId } });
  const rows = useMemo(() => createRows(query.data?.homeownerNotes ?? []), [query.data?.homeownerNotes]);
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => createColumns(projectId), []);

  return (
    <Tile href={createProjectHomeownerNotesUrl(projectId)} {...props}>
      {queryResult(query, ({ homeownerNotes }) => {
        return homeownerNotes.nonEmpty ? (
          <GridTable
            rows={rows}
            columns={columns}
            style={{
              rowHeight: "fixed",
              allWhite: true,
              rowHover: false,
              cellTypography: "xsMd",
            }}
          />
        ) : (
          <EmptyHomeownerNoteTileWrapper />
        );
      })}
    </Tile>
  );
}

type Row = SimpleHeaderAndData<HomeownerNoteTileFragment>;

function createRows(homeownerNotes: HomeownerNoteTileFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...homeownerNotes.map((hn) => ({ kind: "data" as const, id: hn.id, data: hn }))];
}

function createColumns(projectId: string): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Title",
      data: ({ title, id }) => ({
        content: () => (
          <Tooltip title={title} placement="top">
            <Button size="sm" variant="text" onClick={createProjectHomeownerNotesUrl(projectId, id)} label={title} />
          </Tooltip>
        ),
      }),
      mw: "140px",
    }),
    column<Row>({
      header: "Added",
      data: ({ createdAt }) => ({
        content: () => formatDate(createdAt),
      }),
      w: "88px",
    }),
    column<Row>({
      header: "By",
      data: ({ createdBy }) => ({
        content: () => <Avatar size="sm" src={createdBy.avatarUrl} name={createdBy.name} />,
      }),
      w: "48px",
      align: "center",
    }),
  ];
}

function EmptyHomeownerNoteTileWrapper() {
  return (
    <EmptyTile data-testid="homeownerNotesTileEmptyState">
      No Homeowner Notes have
      <br />
      been created.
    </EmptyTile>
  );
}
