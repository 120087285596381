import { Css, FilterDefs, Filters, booleanFilter, multiFilter, treeFilter, usePersistedFilter } from "@homebound/beam";
import { useCallback, useMemo } from "react";
import {
  InboxPageMetaQuery,
  UserRelevantCommentStreamsFilter,
  useInboxPageMetaQuery,
  useUserRelevantCommentStreamsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { StringParam, useQueryParam } from "use-query-params";
import { PageHeader } from "../layout/PageHeader";
import { Inbox } from "./components/Inbox";

export function InboxPage() {
  const query = useInboxPageMetaQuery();

  return queryResult(query, (data) => <InboxPageView data={data} />);
}

function InboxPageView({ data }: { data: InboxPageMetaQuery }) {
  const [commentStreamId] = useQueryParam("streamId", StringParam);

  const { currentInternalUser, internalUsers, developments } = data ?? {
    internalUsers: [],
    developments: [],
    currentInternalUser: {},
  };

  // mapping our data to fit the dropdown-tree-select component structure
  const dropdownCohortData = useMemo(
    () =>
      developments
        .filter((d) => d.cohorts?.nonEmpty)
        .map((d) => ({
          id: d.id,
          name: d.name,
          children: d.cohorts
            ?.filter((c) => c.projects.nonEmpty)
            .map((c) => ({
              id: c.id,
              name: c.name,
              children: c.projects?.map((p) => ({
                id: p.id,
                name: p.name,
              })),
            })),
        })),
    [developments],
  );

  const filterDefs: FilterDefs<UserRelevantCommentStreamsFilter> = useMemo(() => {
    const projectIds = treeFilter({
      defaultCollapsed: true,
      label: "Projects",
      filterBy: "leaf",
      getOptionLabel: (o) => o.name,
      getOptionValue: (o) => o.id,
      options: dropdownCohortData,
      sizeToContent: true,
    });

    const internalUser = multiFilter({
      label: "Person",
      options: internalUsers,
      required: true,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
      defaultValue: [currentInternalUser!.id],
    });

    const hasUnreads = booleanFilter({
      label: "Read Status",
      options: [
        [undefined, "All"],
        [false, "Read"],
        [true, "Unread"],
      ],
      defaultValue: undefined,
    });

    return { projectIds, internalUser, hasUnreads };
  }, [currentInternalUser, dropdownCohortData, internalUsers]);

  const { setFilter, filter } = usePersistedFilter<UserRelevantCommentStreamsFilter>({
    storageKey: "inboxFilter",
    filterDefs,
  });

  const query = useUserRelevantCommentStreamsQuery({
    variables: {
      filter: {
        ...filter,
        parentTypeNames: ["TradePartnerAvailabilityRequest", "BidPackageRequest"],
      },
      page: { limit: 10, offset: 0 },
    },
    skip: !filter.internalUser,
  });

  const maybeFetchMore = useCallback(async () => {
    if (query.data?.userRelevantCommentStreamsPaged.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: {
          page: {
            offset: query.data.userRelevantCommentStreamsPaged.entities.length,
            limit: 10,
          },
        },
      });
    }
  }, [query]);

  return (
    <>
      <PageHeader title="Inbox" />
      <div css={Css.df.fdc.h("calc(100% - 110px)").gap2.$}>
        <div css={Css.df.fdc.h100.gap0.bgWhite.br8.$}>
          <div css={Css.df.jcsb.mhPx(50).df.aic.p2.$}>
            <span css={Css.lgSb.$}>All Conversations</span>
            <div css={Css.df.gap1.$}>
              <Filters<UserRelevantCommentStreamsFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
            </div>
          </div>
          <div css={Css.fg1.$}>
            {!filter.internalUser && (
              <div css={Css.w100.bgGray100.h100.df.jcsa.aic.br8.$}>
                <div css={Css.tac.maxwPx(320).$}>
                  <p css={Css.smBd.pb1.$}>Nothing Selected</p>
                  <p css={Css.base.$}>Person filter is requited</p>
                </div>
              </div>
            )}
            {queryResult(query, ({ userRelevantCommentStreamsPaged }) => (
              <Inbox
                commentStreamId={commentStreamId}
                streams={userRelevantCommentStreamsPaged.entities}
                displayCommentableNames={{ closeStream: true, openStream: false }}
                maybeFetchMore={maybeFetchMore}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
