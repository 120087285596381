import { Button, Css, useModal } from "@homebound/beam";
import { useState } from "react";
import { HoverDelete } from "src/components/HoverDelete";
import { DocumentEditorDetailFragment, SaveDocumentInput, ScheduleTaskInfoFragment } from "src/generated/graphql-types";
import { DocumentScheduleTasksModal } from "src/routes/projects/documents/DocumentScheduleTasksModal";
import { isDefined, isEmpty, nonEmpty, sortBy } from "src/utils";

export type DocumentScheduleTasksProps = {
  document: DocumentEditorDetailFragment;
  scheduleTasks: ScheduleTaskInfoFragment[];
  onSave: (input: SaveDocumentInput) => void;
};

export function DocumentScheduleTasks({ document, scheduleTasks, onSave }: DocumentScheduleTasksProps) {
  const [hoveredId, setHoveredId] = useState("");
  const { openModal } = useModal();
  const sortedTasks = sortBy(
    document.tasks.map(({ id }) => scheduleTasks.find((t) => t.id === id)!).filter(isDefined),
    ({ name }) => name,
  );

  return (
    <>
      <div css={Css.smMd.$}>
        <span css={Css.df.aic.jcsb.sm.gray700.$}>
          Related Schedule Tasks{nonEmpty(document.tasks) ? ` (${document.tasks.length})` : ""}
          <Button
            label="Add Link to Schedule"
            variant="tertiary"
            disabled={isEmpty(scheduleTasks)}
            onClick={() =>
              openModal({
                content: (
                  <DocumentScheduleTasksModal
                    document={document}
                    scheduleTasks={scheduleTasks}
                    onSave={(taskIds) => onSave({ id: document.id, taskIds })}
                  />
                ),
                size: "md",
              })
            }
          />
        </span>
      </div>
      {nonEmpty(sortedTasks) ? (
        <div css={Css.df.fdc.gap1.mb1.maxhPx(200).oya.$}>
          {sortedTasks.map(({ id, name }) => {
            return (
              <div css={Css.df.fdc.br8.bgGray100.$} key={id}>
                <div
                  onPointerEnter={() => setHoveredId(id)}
                  onPointerLeave={() => setHoveredId("")}
                  css={Css.df.jcfs.gap1.aic.xs.p2.pr1.br8.$}
                  key={id}
                  data-testid={id}
                >
                  <div css={Css.w100.$}>{name}</div>
                  <HoverDelete
                    visible={hoveredId === id}
                    onClick={() =>
                      onSave({
                        id: document.id,
                        taskIds: document.tasks.filter((t) => t.id !== id).map((t) => t.id),
                      })
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>There are no linked schedule tasks</div>
      )}
    </>
  );
}
