import {
  BoundMultiSelectField,
  BoundRichTextField,
  BoundSelectField,
  BoundTextField,
  Button,
  ButtonGroup,
  ButtonGroupButton,
  Css,
  IconButton,
  ModalBody,
  ModalHeader,
  ModalSize,
  Palette,
  TabsWithContent,
  TabWithContent,
  useComputed,
  useModal,
} from "@homebound/beam";
import { Maybe } from "graphql/jsutils/Maybe";
import { Observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { CommentFeed, formatDate } from "src/components";
import {
  AssetInput,
  IncrementalCollectionOp,
  InternalUserDetailFragment,
  SaveToDoAttachmentInput,
  SaveToDoInput,
  ToDo,
  ToDoChecklistProgress,
  ToDoModal_CohortFragment,
  ToDoModal_DevelopmentFragment,
  ToDoModal_ProjectFragment,
  ToDoStatus,
  useCurrentInternalUserQuery,
  useDeleteToDoMutation,
  useSaveFollowersMutation,
  useSaveToDosMutation,
  useToDoModalDataQuery,
  useToDoModalQuery,
} from "src/generated/graphql-types";
import { useToDoStatuses } from "src/hooks/enums/useToDoStatuses";
import { useToDoTypes } from "src/hooks/enums/useToDoTypes";
import { useInternalUsers } from "src/hooks/useInternalUsers";
import { useTabParam } from "src/hooks/useTabParam";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { sortBy } from "src/utils";
import { DateTime } from "src/utils/dates";
import { internalUserAvatar, internalUserMenuLabel } from "src/utils/decorators/internalUserDecorators";
import { ObjectConfig, required, useFormState } from "src/utils/formState";
import { BoundBeamDateField } from "../BoundBeamDateField";
import { ToDoAttachments } from "./ToDoAttachments";
import { ToDoChecklist } from "./ToDoChecklist";
import { checklistProgressSummary } from "./ToDoChecklistStatus";
import { ToDoLinks } from "./ToDoLinks";

export enum ToDoTabs {
  details = "details",
  checklist = "checklist",
}

type ToDoModalProps = {
  toDoId: "new" | string;
  parentId?: string;
  returnUrl?: string | undefined;
  internalUsers: InternalUserDetailFragment[];
  parentOptions: ParentOption[];
  isProjectPage: boolean;
  refreshQueries?: () => void;
  currentUser: { id: string } | undefined | null;
};

export type useToDoModalProps = {
  defaultParentId?: string;
  isProjectPage?: boolean;
  returnUrl?: string | undefined;
  refreshQueries?: () => void;
};

export function useToDoModal({ defaultParentId, isProjectPage, returnUrl, refreshQueries }: useToDoModalProps) {
  const { openModal, closeModal } = useModal();

  const { data: currentUserData } = useCurrentInternalUserQuery({ fetchPolicy: "cache-first" });

  const { data } = useToDoModalDataQuery();

  const parentOptions = useMemo(
    () => sortBy([...(data?.projects ?? []), ...(data?.cohorts ?? []), ...(data?.developments ?? [])], (p) => p.name),
    [data?.cohorts, data?.developments, data?.projects],
  );

  const { internalUsers } = useInternalUsers();

  const orderedInternalUsers = useMemo(() => sortBy(internalUsers, (p) => p.name), [internalUsers]);

  const open = useCallback(
    (toDoId: string, parentId?: string) => {
      const parent = parentId ?? defaultParentId;
      openModal({
        content: (
          <ToDoModal
            toDoId={toDoId}
            parentId={parent}
            returnUrl={returnUrl}
            isProjectPage={isProjectPage ?? false}
            internalUsers={orderedInternalUsers}
            parentOptions={parentOptions}
            refreshQueries={refreshQueries}
            currentUser={currentUserData?.currentInternalUser}
          />
        ),
        size: ToDoModalSize,
        drawHeaderBorder: true,
      });
    },
    [
      currentUserData?.currentInternalUser,
      defaultParentId,
      isProjectPage,
      openModal,
      orderedInternalUsers,
      parentOptions,
      refreshQueries,
      returnUrl,
    ],
  );

  return { open, close: closeModal };
}

export function ToDoModal({
  toDoId,
  parentId,
  returnUrl,
  internalUsers,
  parentOptions,
  isProjectPage,
  refreshQueries,
  currentUser,
}: ToDoModalProps) {
  const isNewToDo = toDoId === "new";
  const query = useToDoModalQuery({
    variables: { toDoId: toDoId! },
    skip: isNewToDo || !toDoId,
    // For a faster UX
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const [saveToDosMutation, { loading: saveToDosLoading }] = useSaveToDosMutation();
  const [saveFollowersMutation] = useSaveFollowersMutation();
  const [deleteToDoMutation] = useDeleteToDoMutation();
  const { openModal, closeModal, addCanClose } = useModal();
  const history = useHistory();

  const { data } = query;
  const toDo = data?.toDos[0];
  const isComplete = toDo && toDo.status.code === ToDoStatus.Complete;
  const modalProps = isNewToDo
    ? { primaryLabel: "Create", name: "Add New To-Do" }
    : { primaryLabel: "Save", name: toDo?.name ?? "Loading..." };

  const statuses = useToDoStatuses();
  const types = useToDoTypes();

  const formConfig = useMemo(() => buildFormConfig(isProjectPage), [isProjectPage]);

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ toDos: [toDo] }) => ({
        ...toDo,
        status: toDo.status.code,
        urgent: toDo.urgent,
        type: toDo.type.code,
        assigneeIds: toDo.assignees?.map(({ internalUser }) => internalUser!.id),
        parentId: toDo.parent?.id,
        followerIds: toDo.followers.map((f) => f.id),
        attachments: toDo.attachments.map((a) => ({ ...a, op: "existing" })),
        checklistItems: toDo.checklistItems.map((tdci) => ({
          ...tdci,
          op: IncrementalCollectionOp.Include,
        })),
      }),
      ifUndefined: {
        parentId,
        attachments: [],
        checklistItems: [],
        followerIds: [...(currentUser ? [currentUser.id] : [])],
      },
    },
    autoSave: maybeAutoSave,
  });

  async function maybeAutoSave() {
    if (!isNewToDo) {
      await saveToDo();
    }
  }

  addCanClose(() => {
    void maybeAutoSave();
    if (!isNewToDo && returnUrl) {
      history.push(returnUrl);
    }
    return true;
  });

  async function saveToDo() {
    // Because saveToDo is called on modal close, ensure a save is not already in progress
    if (formState.canSave() && !saveToDosLoading) {
      let savedToDo = toDo as ToDo;
      // Remove followers since it will be handle by another mutation
      const { id, attachments, followerIds, ...input } = formState.changedValue;
      const actualAttachmentChanges = (attachments || []).filter((a) => a.op === "create" || a.op === "delete");
      if (Object.keys(input).length > 0 || actualAttachmentChanges.length > 0) {
        const { data } = await saveToDosMutation({
          variables: {
            toDos: [
              {
                id,
                ...input,
                attachments: formState.attachments.value
                  .filter((a) => a.op === "create" || a.op === "delete")
                  .map(({ asset: { id, fileName, contentType, s3Key, sizeInBytes }, ...attachment }) => ({
                    ...attachment,
                    asset: { id, fileName, contentType, s3Key, sizeInBytes },
                  })),
              },
            ],
          },
        });
        savedToDo = data?.saveToDos.toDos[0] as ToDo;
      }

      if (savedToDo && followerIds) {
        await saveFollowersMutation({
          variables: { input: { followableId: savedToDo.id, followerIds, fromTag: false } },
        });
        formState.followerIds.commitChanges();
      }

      refreshQueries && refreshQueries();
    }
  }

  async function onPrimary() {
    await saveToDo();
    closeModal();
  }

  async function onDelete() {
    await deleteToDoMutation({
      variables: { input: { id: toDoId } },
    });
    refreshQueries && refreshQueries();
    closeModal();
  }

  function completeButton(): ButtonGroupButton {
    return {
      text: "Mark Complete",
      onClick: async () => {
        // Focus to prevent auto save on setter, since close modal will also call save mutation
        // This is to avoid  Oplock failure
        formState.status.focus();
        formState.status.value = ToDoStatus.Complete;
        closeModal();
      },
    };
  }

  function deleteButton(): ButtonGroupButton {
    return {
      text: "Delete",
      onClick: openDeleteModal,
    };
  }

  function actionButtons() {
    if (isComplete) {
      return <Button data-testid="deleteButton" variant="secondary" label="Delete" onClick={openDeleteModal} />;
    } else {
      return <ButtonGroup data-testid="actions" buttons={[completeButton(), deleteButton()]} />;
    }
  }

  function openDeleteModal() {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage="Are you sure you want to delete this To-Do?"
          title="Delete To-Do"
          onConfirmAction={onDelete}
          label="Delete"
          danger
        />
      ),
    });
  }

  function onAssigneesChange(assigneeIds: string[]) {
    if (isNewToDo) {
      const currentFollowers = formState.followerIds.value ?? [];
      const currentAssignees = formState.assigneeIds.value ?? [];
      const newAssignee = assigneeIds.find((id) => !currentAssignees.includes(id))!;
      formState.followerIds.set([...currentFollowers, newAssignee]);
    }
    formState.assigneeIds.set(assigneeIds);
  }

  const toggleUrgent = (value?: boolean) => formState.urgent.set(value !== undefined ? value : !formState.urgent.value);

  /** Compute checklist progress from the form rather than using toDo.checklistItemProgress
    to ensure these numbers are reactive during the `isNewToDo` (non-autosave) state */
  const checklistProgress: ToDoChecklistProgress = useComputed(() => {
    const checkListItems = formState.checklistItems.value;
    const completed = checkListItems.filter((item) => item.isComplete).length;

    return {
      total: checkListItems.length,
      completed,
    };
  }, [formState]);

  const tabs: TabWithContent[] = [
    {
      name: "Details",
      value: ToDoTabs.details,
      render: () => (
        <div css={Css.df.fdc.gap2.$}>
          <BoundRichTextField field={formState.description} placeholder="Add description" />
          <ToDoAttachments field={formState.attachments} />
          {toDo && <ToDoLinks toDo={toDo} />}
          <div css={Css.f1.bt.bcGray200.pt3.mhPx(100).$}>
            {toDo && <CommentFeed commentable={toDo} showFollowers={false} />}
          </div>
        </div>
      ),
    },
    {
      name: `Checklist ${checklistProgressSummary(checklistProgress)}`,
      value: ToDoTabs.checklist,
      render: () => <ToDoChecklist field={formState.checklistItems} isComplete={isComplete} isNewToDo={isNewToDo} />,
    },
  ];

  const [tab, setTab] = useTabParam(ToDoTabs.details);

  return (
    <>
      <ModalHeader>
        <div css={Css.df.aic.$}>
          <BoundTextField
            borderless
            labelStyle="hidden"
            field={formState.name}
            placeholder="Add title"
            xss={Css.xl.$}
          />
          <div css={Css.df.aic.jcc.wPx(56).pl2.if(isNewToDo).mla.$}>
            <Observer>
              {() => (
                <IconButton
                  color={formState.urgent.value ? Palette.Red600 : Palette.Gray900}
                  icon={formState.urgent.value ? "flag" : "outlineFlag"}
                  onClick={() => toggleUrgent()}
                />
              )}
            </Observer>
          </div>
          <div css={Css.df.jcfe.if(!isNewToDo).wPx(372).$}>{!isNewToDo && actionButtons()}</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div css={Css.df.mh100.mxPx(-24).$}>
          <div css={Css.df.fg1.fdc.br.bcGray200.p3.$}>
            <TabsWithContent selected={tab} tabs={tabs} onChange={setTab} includeBottomBorder />
            <Observer>
              {() =>
                isNewToDo ? (
                  // Negative margin bottom, position sticky and margin-top: auto allows the button bar to stay pinned at the bottom
                  // of the left scrollable pane while accounting for parent container padding
                  <div css={Css.sticky.bottom0.mta.df.fdrr.bt.bcGray200.py2.mbPx(-24).bgWhite.$}>
                    <Button
                      disabled={!formState.valid || !formState.dirty}
                      label={modalProps.primaryLabel}
                      onClick={onPrimary}
                    />
                    <Button variant="tertiary" label="Cancel" onClick={closeModal} />
                  </div>
                ) : null
              }
            </Observer>
          </div>
          <div css={Css.df.wPx(250).fs0.fdc.p2.bgGray100.$}>
            <div css={Css.df.fdc.gap3.$}>
              {!isProjectPage && (
                <BoundSelectField
                  label="Project, Cohort, or Development"
                  options={sortBy(parentOptions, (p) => p.name)}
                  field={formState.parentId}
                />
              )}
              <BoundSelectField
                options={statuses ?? []}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.code}
                field={formState.status}
              />
              <BoundSelectField
                options={types ?? []}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.code}
                field={formState.type}
              />
              <BoundBeamDateField field={formState.dueDate} label="Due Date" />
              <BoundMultiSelectField
                label="Assignees"
                field={formState.assigneeIds}
                options={internalUsers ?? []}
                fieldDecoration={internalUserAvatar}
                onSelect={onAssigneesChange}
                getOptionMenuLabel={internalUserMenuLabel}
              />
              <BoundSelectField
                label="Urgency"
                options={urgentOptions}
                getOptionLabel={(o) => o.label}
                getOptionValue={(o) => o.value}
                field={formState.urgent}
                onSelect={(_, opt) => toggleUrgent(opt?.value)}
              />
              <BoundMultiSelectField
                label="Watchers"
                field={formState.followerIds}
                options={internalUsers}
                fieldDecoration={internalUserAvatar}
                getOptionMenuLabel={internalUserMenuLabel}
              />
              <Observer>
                {() => (
                  <>
                    {currentUser?.id && !formState.followerIds?.value?.includes(currentUser.id) ? (
                      <div css={Css.fw5.mt(-2).ml1.important.$}>
                        <Button
                          variant="text"
                          label="Follow"
                          data-testid="follow"
                          onClick={() =>
                            formState.followerIds.set([currentUser.id, ...(formState.followerIds?.value ?? [])])
                          }
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </Observer>
              <div css={Css.gray600.sm.$}>
                <div>{toDo?.createdAt && `Created on ${formatDate(toDo.createdAt, "medium")}`}</div>
                <div>{toDo?.createdBy?.name && `by ${toDo.createdBy?.name}`}</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </>
  );
}

const urgentOptions = [
  { label: "Not Urgent", value: false },
  { label: "Urgent", value: true },
];

export type ParentOption = ToDoModal_ProjectFragment | ToDoModal_CohortFragment | ToDoModal_DevelopmentFragment;

export const ToDoModalSize: {
  width: ModalSize;
  height: number;
} = { width: "xl", height: 799 };

type SaveAssetModel = AssetInput & {
  downloadUrl: string;
  attachmentUrl: string;
  createdAt: DateTime;
};

export type SaveToDoAttachmentModel = SaveToDoAttachmentInput & {
  asset: SaveAssetModel;
};

export type SaveToDoModel = SaveToDoInput & {
  followerIds: Maybe<string[]>;
  attachments: SaveToDoAttachmentModel[];
};

const buildFormConfig = (isProjectPage: boolean): ObjectConfig<SaveToDoModel> => ({
  id: { type: "value" },
  name: { type: "value", rules: [required] },
  status: { type: "value", rules: [required] },
  urgent: { type: "value" },
  type: { type: "value" },
  parentId: { type: "value", rules: isProjectPage ? [required] : [] },
  description: { type: "value" },
  assigneeIds: { type: "value" },
  dueDate: { type: "value" },
  followerIds: { type: "value" },
  attachments: {
    type: "list",
    config: {
      id: { type: "value" },
      asset: {
        type: "object",
        config: {
          id: { type: "value" },
          s3Key: { type: "value" },
          fileName: { type: "value" },
          contentType: { type: "value" },
          sizeInBytes: { type: "value" },
          downloadUrl: { type: "value" },
          attachmentUrl: { type: "value" },
          createdAt: { type: "value" },
        },
      },
      op: { type: "value" },
    },
  },
  checklistItems: {
    type: "list",
    update: "incremental",
    config: {
      id: { type: "value" },
      description: { type: "value", rules: [required] },
      isComplete: { type: "value" },
      op: { type: "value" },
    },
  },
});
