import { Css, ScrollShadows } from "@homebound/beam";
import { ReactNode } from "react";
import { useDashboardFilterContext } from "src/routes/personal-dashboard/DashboardFilterContext";
import { ActionItemTypes } from "src/routes/personal-dashboard/enums";
import { EmptyStateWrapper } from "../../components/EmptyStateWrapper";
import { PersonalDashboardTitle } from "../../components/PersonalDashboardTitle";
import { ActionItemsScheduling } from "./ActionItemScheduling";
import { ActionItemsApprovals } from "./ActionItemsApprovals";
import { ActionItemsBillsPendingReview } from "./ActionItemsBillsPendingReview";
import { ActionItemsDeferredBills } from "./ActionItemsDeferredBills";
import { ActionItemsDelayFlags } from "./ActionItemsDelayFlags";
import { ActionItemsStaleTasks } from "./ActionItemsStaleTasks";

const accordions: Record<string, ReactNode> = {
  [ActionItemTypes.DELAYS]: <ActionItemsDelayFlags />,
  [ActionItemTypes.APPROVALS]: <ActionItemsApprovals />,
  [ActionItemTypes.DEFERRED_BILLS]: <ActionItemsDeferredBills />,
  [ActionItemTypes.BILLS_PENDING_REVIEW]: <ActionItemsBillsPendingReview />,
  [ActionItemTypes.SCHEDULING]: <ActionItemsScheduling />,
  [ActionItemTypes.STALE_TASKS]: <ActionItemsStaleTasks />,
};

export function ActionItemsPage() {
  const { filter } = useDashboardFilterContext();

  const accordionKeys =
    filter.actionItemTypes === undefined ? Object.values(ActionItemTypes) : filter.actionItemTypes || [];

  return (
    <div css={Css.df.fdc.h100.$}>
      <PersonalDashboardTitle title="Action Items" />
      <EmptyStateWrapper title="Action Items">
        <ScrollShadows xss={Css.h100.oys.mt1.$}>
          {accordionKeys.map((key) => (
            <div key={key}>{accordions[key]}</div>
          ))}
        </ScrollShadows>
      </EmptyStateWrapper>
    </div>
  );
}

export const EmptyActionItems = ({ title }: { title: string }) => (
  <div css={Css.w100.bgGray100.df.jcsa.aic.p2.$}>
    <span>{title}</span>
  </div>
);

export const ActionItemsContainer = ({ children, count }: { children: ReactNode; count: number }) => (
  <ScrollShadows xss={Css.if(count >= 4).maxh("400px").oys.pr1.$}>{children}</ScrollShadows>
);

export const LINK_CSS = Css.blue700.cursorPointer.onHover.tdu.$;
