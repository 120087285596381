import { Chips } from "@homebound/beam";
import { ApprovalSubject_ChangeEventFragment } from "src/generated/graphql-types";
import { fail } from "src/utils";
import { useApprovalContext } from "../ApprovalSuperDrawer";
import { ApprovalOverviewTradesList, OverviewFormLabel } from "../approvalAtoms";

export function ApprovalOverviewChangeEventExtension() {
  const { approvals } = useApprovalContext();
  const [firstCe] =
    (approvals
      ?.filter((approval) => approval.subject.__typename === "ChangeEvent")
      .map((approval) => approval.subject) as ApprovalSubject_ChangeEventFragment[]) ??
    fail("Superdrawer should've loaded an Approval, but didn't.");

  return (
    <>
      <ApprovalOverviewTradesList
        changeEvents={(approvals?.flatMap((a) => a.subject) as ApprovalSubject_ChangeEventFragment[]) ?? []}
      />
      <OverviewFormLabel>Impacted CCs</OverviewFormLabel>
      <Chips values={firstCe.lineItems.map((li) => li.projectItem.item.fullCode).unique() || []} compact />
      <OverviewFormLabel>Reason Code</OverviewFormLabel>
      <span>{firstCe.reason.name}</span>
      <OverviewFormLabel>Note</OverviewFormLabel>
      <span>{firstCe.internalNote ?? ""}</span>
    </>
  );
}
