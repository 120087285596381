import { useCallback, useMemo } from "react";
import {
  DynamicSchedulesMilestoneSnapshot_PlanMilestoneSnapshotFragment,
  DynamicSchedulesTaskSnapshot_TaskSnapshotFragment,
  useDynamicSchedulesSnapshotsQuery,
} from "src/generated/graphql-types";

export type ScheduleSnapshotsHookResult = ReturnType<typeof useScheduleSnapshots>;
type PartialTaskSnapshotMap = Record<string, DynamicSchedulesTaskSnapshot_TaskSnapshotFragment | undefined>;
type PartialPlanMilestoneSnapshotMap = Record<
  string,
  DynamicSchedulesMilestoneSnapshot_PlanMilestoneSnapshotFragment | undefined
>;

/** This custom hook loads schedule date history data to make it easier to stitch the data together.
 * Getting functions are provided with undefined/loading defaults so callers can access nested properties such as `getTaskSnapshot(task.id)` synchronously. */
export function useScheduleSnapshots(scheduleParentId: string) {
  const { data } = useDynamicSchedulesSnapshotsQuery({
    variables: { input: { scheduleParentId } },
    // The historical date snapshots will be fairly stable so we can lean into the cached values where possible
    fetchPolicy: "cache-first",
  });

  const { taskSnapshots, taskSnapShotsById, planMilestoneSnapshots, planMilestoneSnapshotsById } = useMemo(() => {
    const { taskSnapshots = [], planMilestoneSnapshots = [] } = data?.planScheduleSnapshots ?? {};
    // Define the types on these keyBys so typescript knows that not all string keys will be present and will return `| undefined`
    // Additional uniqueBys are added just in case the query returns duplicates we don't want to bomb on the keyBy
    const taskSnapShotsById: PartialTaskSnapshotMap = taskSnapshots
      .uniqueBy((ts) => ts.task.id)
      .keyBy((ts) => ts.task.id);
    const planMilestoneSnapshotsById: PartialPlanMilestoneSnapshotMap = planMilestoneSnapshots
      .uniqueBy((pms) => pms.planMilestone.id)
      .keyBy((pms) => pms.planMilestone.id);

    return { taskSnapshots, taskSnapShotsById, planMilestoneSnapshots, planMilestoneSnapshotsById };
  }, [data]);

  const getTaskSnapshot = useCallback((taskId: string) => taskSnapShotsById[taskId], [taskSnapShotsById]);
  const getPlanMilestoneSnapshot = useCallback(
    (planMilestoneId: string) => planMilestoneSnapshotsById[planMilestoneId],
    [planMilestoneSnapshotsById],
  );

  return { taskSnapshots, planMilestoneSnapshots, getPlanMilestoneSnapshot, getTaskSnapshot };
}
