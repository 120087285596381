import { RouteTabWithContent, TabContent, Tabs } from "@homebound/beam";
import { Route } from "react-router-dom";
import { CreateOrUpdateDevelopmentModal } from "src/routes/developments/components/CreateOrUpdateDevelopmentModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectsPage } from "src/routes/projects/ProjectsPage";
import { developmentsPath, projectsPath } from "src/routes/routesDef";
import { DevelopmentsPage } from "../developments/DevelopmentsPage";

export function ProjectsDevelopmentsPage() {
  const tabs: RouteTabWithContent[] = [
    {
      name: "All Projects",
      href: projectsPath,
      path: projectsPath,
      render: () => <ProjectsPage />,
    },
    {
      name: "Developments",
      href: developmentsPath,
      path: [developmentsPath],
      render: () => <DevelopmentsPage />,
    },
  ];
  return (
    <>
      <PageHeader
        left={<Tabs tabs={tabs} />}
        right={<Route path={developmentsPath} component={CreateOrUpdateDevelopmentModal} />}
      />
      <TabContent tabs={tabs} />
    </>
  );
}
