import { useParams } from "react-router";
import { ProjectRouteProps } from "src/Routes";
import { ChangeLog, ChangeLogView } from "src/components/change-log/ChangeLog";

export function ProjectChangeLogPage() {
  // Then get the project id
  const { projectId } = useParams<ProjectRouteProps>();

  return <ChangeLog view={ChangeLogView.Project} projectId={projectId} />;
}
