import { Css, Icon, Palette, useTestIds } from "@homebound/beam";
import { Link, useParams } from "react-router-dom";
import { DashboardTile } from "src/generated/graphql-types";
import { baseTileStyles, tileContentStyles } from "src/routes/projects/dashboard/components/Tile";
import { TileTitle } from "src/routes/projects/dashboard/components/TileTitle";
import { TileComponentConfig } from "src/routes/projects/dashboard/config";

type OtherPagesTileProps = {
  tiles: [DashboardTile, TileComponentConfig][];
};

export function OtherPagesTile({ tiles }: OtherPagesTileProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const tileComponentConfigs = tiles.map(([t, config]) => config);
  const tid = useTestIds({}, "otherPages");
  return (
    <div css={baseTileStyles}>
      <TileTitle title="Other Pages" />
      <ul css={{ ...Css.listReset.$, ...tileContentStyles }}>
        {tileComponentConfigs
          .filter((t) => typeof t.createUrl === "function")
          .map((t) => (
            <li key={t.title}>
              {/* Using `t.createUrl!` as the above filter ensures we can call this */}
              <Link
                to={t.createUrl!(projectId)}
                css={
                  Css.df.aic.jcsb.smSb.py1.pxPx(4).w100.gap1.onHover.bgBlue50.boxShadow(`0 0 4px ${Palette.Blue50})`).$
                }
                className="navLink"
                {...tid.link}
              >
                {t.title}
                <span css={Css.fs0.$}>
                  <Icon icon="chevronRight" />
                </span>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}
