import { BoundSelectField, BoundTextField, RadioGroupField, StaticField } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { FormMode } from "src/components/formFields/FormActions";
import { InputMaybe, Stage, usePotentialInvoicesToCreditQuery } from "src/generated/graphql-types";
import { useReaction } from "src/hooks";
import { invoiceNumberRule } from "src/routes/projects/invoices/utils";
import { ProjectParams } from "src/routes/routesDef";
import { isDefined } from "src/utils";
import { FieldState, required } from "src/utils/formState";

// `enum`s need to be defined before being referenced.
enum Source {
  External = "External",
  Blueprint = "Blueprint",
  CreditMemo = "CreditMemo",
}

export type InvoiceNumberProps = {
  formState: {
    invoiceNumber: FieldState<InputMaybe<string>>;
    creditForInvoiceId: FieldState<InputMaybe<string>>;
  };
  isHistoricalInvoiceNumber: boolean | undefined;
  mode: FormMode;
  stage: Stage;
};

export function InvoiceNumber({ formState, isHistoricalInvoiceNumber, mode, stage }: InvoiceNumberProps) {
  const { projectId } = useParams<ProjectParams>();
  // Default to Blueprint as the source - `source` is only used in "create" mode, so we do not need to re-initalize via a useEffect or similar.
  const [source, setSource] = useState<Source>(isHistoricalInvoiceNumber ? Source.External : Source.Blueprint);

  const pontentialInvoicesQuery = usePotentialInvoicesToCreditQuery({
    variables: { projectId, stage },
    fetchPolicy: "cache-first",
    skip: mode === "read" || source !== Source.CreditMemo,
  });

  const creditMemoOptions = useMemo(
    () => pontentialInvoicesQuery.data?.invoices.filter((inv) => !isDefined(inv.creditForInvoice)) ?? [],
    [pontentialInvoicesQuery.data],
  );

  useReaction(
    () => source,
    () => {
      const rulesBySource: Record<Source, Function> = {
        [Source.External]: () => formState.invoiceNumber.rules.push(invoiceNumberRule),
        [Source.Blueprint]: () => formState.invoiceNumber.rules.push(invoiceNumberRule),
        [Source.CreditMemo]: () => formState.creditForInvoiceId.rules.push(required),
      };

      // Clear all existing rules
      formState.invoiceNumber.rules = [];
      formState.creditForInvoiceId.rules = [];

      rulesBySource[source]();
    },
    [formState, source],
    { fireImmediately: true },
  );

  return (
    <Observer>
      {() =>
        mode === "read" || isHistoricalInvoiceNumber === false ? (
          <StaticField
            label="Invoice Number"
            value={`${formState.invoiceNumber.value ?? "No number selected"}${
              isHistoricalInvoiceNumber ? " (External)" : ""
            }`}
          />
        ) : (
          <div>
            {mode === "create" && (
              <RadioGroupField
                label="Invoice Number"
                value={source}
                options={[
                  { label: "Use Blueprint Number", value: Source.Blueprint },
                  { label: "Credit Memo", value: Source.CreditMemo },
                  { label: "Use External Number", value: Source.External },
                ]}
                onChange={(v) => {
                  setSource(v);
                  // Default external source to be "0" as kind of a hack for our validation logic. Cannot set to be `""`, as formState will massage that back to be `undefined`, which we do not want.
                  if ([Source.External, Source.Blueprint].includes(v)) {
                    formState.creditForInvoiceId.set(undefined);
                    formState.invoiceNumber.set(v === Source.Blueprint ? undefined : "0");
                  }
                  if (v === Source.CreditMemo) {
                    // Clear out the invoiceNumber if we are using a credit memo
                    formState.invoiceNumber.set(undefined);
                  }
                }}
              />
            )}
            {source === Source.CreditMemo && mode === "create" && (
              <BoundSelectField
                label="Invoice Credit for"
                options={creditMemoOptions}
                field={formState.creditForInvoiceId}
              />
            )}
            {(source === Source.External || (mode === "create" && source !== Source.CreditMemo)) && (
              <BoundTextField
                field={formState.invoiceNumber}
                placeholder="External Invoice number"
                labelStyle={mode === "create" ? "hidden" : "above"}
                disabled={source !== Source.External}
              />
            )}
          </div>
        )
      }
    </Observer>
  );
}
