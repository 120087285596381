import { Css } from "@homebound/beam";
import {
  BudgetSuperDrawer_CommitmentChangeOrderFragment,
  BudgetSuperDrawer_CommitmentFragment,
  BudgetSuperDrawer_ProjectItemFragment,
} from "src/generated/graphql-types";
import { centsToDollars, formatNumberToString, partition } from "src/utils";
import { BudgetBillsTable } from "../BudgetBillsTable";
import { BudgetChangeEventsTable } from "../BudgetChangeEventsTable";
import { BudgetCommitmentsTable } from "../BudgetCommitmentsTable";

export type BudgetDetailsTabProps = {
  projectItems: BudgetSuperDrawer_ProjectItemFragment[];
  projectId: string;
};

export function BudgetDetailsTab(props: BudgetDetailsTabProps) {
  const { projectItems, projectId } = props;
  const bills = projectItems.flatMap((pi) => pi.bills).unique();
  const expenses = projectItems
    .flatMap((pi) => pi.expenseAllocations)
    .map((a) => a.expense)
    .unique();
  const changeEvents = projectItems.flatMap((pi) => pi.changeEventLineItems.map((celi) => celi.changeEvent)).unique();
  const projectItemIds = projectItems.map((pi) => pi.id);
  const changeEventsTotalCosts =
    // Only sum the CE line items if its project item is selected for the super drawer
    changeEvents.sum((ce) =>
      ce.lineItems.sum((li) => (projectItemIds.includes(li.projectItem.id) ? li.totalCostInCents : 0)),
    );
  const commitmentLikes = projectItems
    .flatMap((pi) => pi.commitmentLineItems)
    .map((li) => li.owner)
    .unique();
  const [commitments, changeOrders] = partition(commitmentLikes, (c) => c.__typename === "Commitment");

  return (
    <div css={Css.df.fdc.gap3.$}>
      <BudgetChangeEventsTable
        changeEvents={changeEvents}
        projectId={projectId}
        projectItemIds={projectItemIds}
        title="Change Events"
      />

      <div css={Css.df.fdr.mb5.baseSb.$}>
        <div>Total Change Events</div>
        <div data-testid="totalChangeEvents" css={Css.mlPx(550).$}>
          ${formatNumberToString(centsToDollars(changeEventsTotalCosts))}
        </div>
      </div>

      <div css={Css.hPx(1).mb3.bgGray200.$} />

      <BudgetCommitmentsTable
        commitments={commitments as BudgetSuperDrawer_CommitmentFragment[]}
        commitmentChangeOrders={changeOrders as BudgetSuperDrawer_CommitmentChangeOrderFragment[]}
        projectItemIds={projectItemIds}
        projectId={projectId}
      />

      <div css={Css.hPx(1).my3.bgGray200.$} />

      <BudgetBillsTable bills={bills} expenses={expenses} projectId={projectId} projectItemIds={projectItemIds} />
    </div>
  );
}
