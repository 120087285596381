import { Css, useTestIds } from "@homebound/beam";
import { isDefined } from "src/utils";

// TODO: Beam-ify
type ProgressPillProps = {
  progress: number;
  /** Enabling this will render the `% Released` text on top of the bar instead of inline/after */
  stacked?: boolean;
  text?: string;
  textWhiteSpace?: "normal" | "nowrap" | "pre" | "pre-wrap" | "pre-line" | "break-spaces";
  fullWidth?: boolean;
  bottomLabel?: boolean;
  changeColorOnCompleted?: boolean;
  hideProgress?: boolean;
  /** Sets a known width for the progress bar unless `fullWidth` is specified */
  fixedWidthPx?: number;
};

export const ProgressPill = ({
  progress,
  stacked,
  text,
  changeColorOnCompleted,
  fullWidth = false,
  bottomLabel = false,
  hideProgress = false,
  textWhiteSpace = "normal",
  fixedWidthPx,
  ...others
}: ProgressPillProps) => {
  const tid = useTestIds(others, "progressPill");
  const color = changeColorOnCompleted && progress === 100 ? Css.bgGreen400.$ : Css.bgBlue700.$;
  const barWidth = fullWidth ? Css.w100.$ : Css.wPx(fixedWidthPx ?? 100).$;

  const progressFillCss =
    fullWidth || isDefined(fixedWidthPx)
      ? Css.w(`${Math.floor(Math.min(Math.max(0, progress), 100))}%`).$
      : Css.wPx(Math.floor(Math.min(Math.max(0, progress), 100))).$;

  return (
    <div
      {...tid}
      css={stacked ? Css.df.fdcr.gap1.if(bottomLabel).fdc.if(fullWidth).w100.$ : Css.df.gap1.aic.if(fullWidth).w100.$}
    >
      <div css={Css.relative.h1.borderRadius("2em").oh.add(barWidth).$}>
        <span css={Css.absolute.z2.h1.bgGray300.add(barWidth).$} />
        <span css={Css.absolute.z2.h1.add(color).add(progressFillCss).add("transition", "width 200ms").$} />
      </div>
      <div css={Css.tiny.fg1.whiteSpace(textWhiteSpace).$}>
        {hideProgress ? "" : `${progress ?? 0}% `}
        {text}
      </div>
    </div>
  );
};
