import { IObservableArray, observable } from "mobx";
import {
  ChangeEventLineItemsTabChangeEventLineItemFragment,
  ProjectItemInput,
  SaveChangeEventLineItemInput,
} from "src/generated/graphql-types";
import { Selectable } from "src/models";
import { ObservableChangeEventLineItem } from "src/routes/projects/change-events/models/ObservableChangeEventLineItem";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";

/**
 * We create a new store specifically for ChangeEvents, b/c the ProjectItemStore is too specific to Specs & Selections.
 */
export class ChangeEventLineItemStore extends Selectable {
  // Our base class children is divisions, but keep a list of our leaf items as well
  private readonly lineItems: IObservableArray<ObservableChangeEventLineItem>;
  private readonly onSave?: (input: ProjectItemInput) => void;

  constructor(
    changeEventLineItems: ChangeEventLineItemsTabChangeEventLineItemFragment[],
    public isInternal: boolean,
    onSave?: (input: SaveChangeEventLineItemInput) => void,
  ) {
    const lineItems = observable(
      changeEventLineItems.map((celi) => new ObservableChangeEventLineItem(celi, isInternal, onSave)),
    );
    super("all", false, lineItems);
    makeSimpleAutoObservable(this);
    this.lineItems = lineItems;
    this.onSave = onSave;
  }

  /**
   * Called with new data from the apollo cache.
   *
   * In theory all of the fields on the LI tab/table directly update the store already, so
   * we wouldn't need to update from the cache; but changes made in the superdrawer don't go
   * through the store, and so we only see/refresh from those via apollo cache refreshes.
   */
  upsertLineItems(celis: ChangeEventLineItemsTabChangeEventLineItemFragment[]) {
    celis.forEach((celi) => {
      const existing = this.lineItems.find((o) => o.id === celi.id);
      if (existing) {
        existing.update(celi);
      } else {
        this.lineItems.push(new ObservableChangeEventLineItem(celi, this.isInternal, this.onSave));
      }
    });
  }

  removeLineItems(lineItemIds: string[]) {
    this.lineItems.filter((i) => lineItemIds.includes(i.id)).forEach((i) => this.lineItems.remove(i));
  }

  clearSelections() {
    this.unselect();
  }

  get items() {
    return this.lineItems;
  }

  get selectedLineItems() {
    return this.lineItems.filter((item) => item.selected);
  }

  get disableProjectMarkup() {
    return this.lineItems.some((celi) => celi.disableMarkup);
  }
}
