import { Css } from "@homebound/beam";
import { AssetInfoFragment, CommentFragment } from "src/generated/graphql-types";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { AssetGallery } from "../assetGallery/AssetGallery";

export type CommentAttachmentsRowProps = {
  comment: CommentFragment;
};

export function CommentAttachmentsRow({ comment }: CommentAttachmentsRowProps) {
  const { attachments = [] } = comment;

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.gray700.sm.my1.$}>Attachments ({attachments.length})</div>
      <div>
        <AssetGallery assets={attachments.map((i) => i.asset as AssetInfoFragment)} display="vertical">
          {(openGallery) => (
            <>
              {attachments.map((attachment) => {
                const asset = attachment.asset as AssetInfoFragment;
                return (
                  <div key={attachment.id} data-testid={"attachmentRow"} css={Css.df.relative.mr1.mt1.$}>
                    <AssetPreview
                      asset={asset}
                      dimensions={{ width: 100, height: 100 }}
                      onClick={() => openGallery(asset)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </AssetGallery>
      </div>
    </div>
  );
}
