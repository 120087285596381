import { Css } from "@homebound/beam";
import { useEffect } from "react";
import { Icon } from "src/components";
import { UserCommunicationPreferences } from "src/components/communication-preferences/UserCommunicationPreferences";
import {
  ContactsTabTradePartnerContactFragment,
  useSaveTradePartnerContactUserMutation,
} from "src/generated/graphql-types";
import { StringParam, useQueryParams } from "use-query-params";

export type TradePartnerContactCommunicationPreferencesProps = {
  tpContact: ContactsTabTradePartnerContactFragment;
};

export function TradePartnerContactCommunicationPreferences({
  tpContact,
}: TradePartnerContactCommunicationPreferencesProps) {
  const [, setQueryParams] = useQueryParams({ tpcId: StringParam });
  const [saveTPContactUser] = useSaveTradePartnerContactUserMutation();

  // Creates the TP Contact user
  useEffect(
    () => {
      if (!tpContact.tradePartnerUser) {
        void saveTPContactUser({ variables: { input: { id: tpContact.id, canLoginToPortal: true } } });
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tpContact.tradePartnerUser],
  );

  return (
    <div css={Css.df.relative.p2.bgGray100.$}>
      <div css={Css.cursorPointer.df.gap2.absolute.top3.left3.$} onClick={() => setQueryParams({ tpcId: undefined })}>
        <Icon icon="arrowBack" />
        <span>Back</span>
      </div>
      {tpContact.tradePartnerUser && <UserCommunicationPreferences userId={tpContact.tradePartnerUser.id} />}
    </div>
  );
}
