import { SelectField, SelectFieldProps, useComputed } from "@homebound/beam";
import { Observer } from "mobx-react";
import {
  BidContractRevisionStatus,
  ChangeEventLineItemTradePartnerSelect_BidItemBidContractLineItemsFragment,
  useChangeEventLineItemTradePartnerSelectLazyQuery,
} from "src/generated/graphql-types";
import { ObservableChangeEventLineItem } from "../models/ObservableChangeEventLineItem";

type TradePartnerOption = {
  id: string;
  name: string;
};

type ChangeEventLineItemTradePartnerSelectFieldProps = Omit<
  SelectFieldProps<TradePartnerOption, string>,
  "options" | "getOptionLabel" | "getOptionValue" | "value" | "onSelect" | "label"
> & { row: ObservableChangeEventLineItem };

/** Provides BCLIs for a given CELI w/a bid item. */
export function ChangeEventLineItemTradePartnerSelectField(props: ChangeEventLineItemTradePartnerSelectFieldProps) {
  const { row, readOnly } = props;
  // Default to original bid item if there's not a proposed set
  const bidItemIds = useComputed(() => [row.proposedBidItemId ?? row.originalBidItem?.id].compact(), [row]);

  const [load, { data }] = useChangeEventLineItemTradePartnerSelectLazyQuery({
    variables: {
      filter: {
        bidItemIds,
        itemTemplateItemId: row.bidItemTemplateItemId,
        developmentId: row.developmentId,
        status: [BidContractRevisionStatus.Signed],
      },
    },
    fetchPolicy: "cache-first",
  });

  // Filter out BCLIs from internal estimates
  const options = data?.bidContractLineItems.filter((bcli) => !!bcli.revision.bidContract.tradePartner);

  return (
    <Observer>
      {() => (
        <SelectField
          label="proposed trade"
          readOnly={readOnly}
          // We use `null` to unset b/c the form is not form-state backed yet
          value={row.proposedBidContractLineItemId ?? undefined}
          // Unlike the CELI.proposedBidItem and CELI.proposedProduct, we pre-fill the CELI.proposedBCLI
          // on the backend to the PI.primaryBCLI, and then use it as the "cost source manual/dev contract"
          // knob in the FE, so we don't actually `original || proposed` or use the unsetLabel.
          options={{ current: row.proposedBidContractLineItem, load, options }}
          onSelect={(_id, bcli) => row.updateProposedBidContractLineItem(bcli)}
          getOptionValue={(bcli) => bcli.id}
          getOptionLabel={(bcli) => tradeNameWithContractVersion(bcli)}
          // Abuse the unsetLabel as a kind of "no options error"
          unsetLabel={options && options.isEmpty ? "No Trades Available" : undefined}
          data-testid="proposedBCLI"
        />
      )}
    </Observer>
  );
}

export function tradeNameWithContractVersion(
  bcli: ChangeEventLineItemTradePartnerSelect_BidItemBidContractLineItemsFragment,
) {
  return `${bcli.revision.bidContract.displayName} (Revision ${bcli.revision.version}) - ${
    bcli.ofIti ? "Plan Based" : "Item Based"
  }`;
}
