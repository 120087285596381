import { Css } from "@homebound/beam";
import { InternalUser, JobLogDetailFragment } from "src/generated/graphql-types";
import { JobLogHeader } from "./JobLogHeader";
import { JobLogNotes } from "./JobLogNotes";

type JobLogProps = {
  jobLog: JobLogDetailFragment;
  currentUser: InternalUser;
  refetchJobLogs: () => void;
};

export function JobLog({ jobLog, currentUser, refetchJobLogs }: JobLogProps) {
  return (
    <div data-testid="jobLog" css={Css.w100.py2.$}>
      <div css={Css.df.fdc.maxw100.mb0.$}>
        <JobLogHeader
          jobLog={jobLog}
          currentUser={currentUser}
          refetchJobLogs={refetchJobLogs}
          canEdit={jobLog.project.canEditJobLogs}
        />
        <div css={Css.w100.$}>
          <JobLogNotes notes={jobLog.notes} jobLog={jobLog} />
        </div>
      </div>
    </div>
  );
}
