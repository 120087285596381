import React, { useCallback, useMemo, useState } from "react";
import { SubmitChangeEventApprovalStepView } from "src/components/approvals/SubmitChangeEventApprovalStepView";
import { WizardStep, useStepperWizard } from "src/components/stepper/useStepperWizard/useStepperWizard";
import { ReassignPurchaseOrdersStep } from "./reassign-purchase-orders-approvals/ReassignPurchaseOrdersStep";

type ReassignPurchaseOrdersContextType = {
  selectedCommitmentIds: string[];
  mode: ReassignOption;
  changeEventIds: string[];
  setChangeEventIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ReassignPurchaseOrdersContext = React.createContext<ReassignPurchaseOrdersContextType>({
  selectedCommitmentIds: [],
  mode: "trade partner",
  changeEventIds: [],
  setChangeEventIds: () => {},
});

export function ReassignPurchaseOrdersProvider({
  mode,
  selectedCommitmentIds,
  children,
}: React.PropsWithChildren<{ selectedCommitmentIds: string[]; mode: ReassignOption }>) {
  const [changeEventIds, setChangeEventIds] = useState<string[]>([]);
  return (
    <ReassignPurchaseOrdersContext.Provider value={{ mode, selectedCommitmentIds, changeEventIds, setChangeEventIds }}>
      {children}
    </ReassignPurchaseOrdersContext.Provider>
  );
}

export type ReassignOption = "trade partner" | "version";

export function useReassignPurchaseOrdersWizard(mode: ReassignOption, selectedCommitmentIds: string[]) {
  const steps = useMemo<WizardStep[]>(
    () => [
      {
        label: mode === "trade partner" ? "Select Trade Partners" : "Select Versions",
        render: ReassignPurchaseOrdersStep,
      },
      {
        label: "Submit for Approval",
        render: () => (
          <ReassignPurchaseOrdersContext.Consumer>
            {({ changeEventIds }) => <SubmitChangeEventApprovalStepView changeEventIds={changeEventIds} />}
          </ReassignPurchaseOrdersContext.Consumer>
        ),
      },
    ],
    [mode],
  );

  const { openWizard } = useStepperWizard({
    steps,
    Provider: ({ children }) => (
      <ReassignPurchaseOrdersProvider selectedCommitmentIds={selectedCommitmentIds} mode={mode}>
        {children}
      </ReassignPurchaseOrdersProvider>
    ),
  });

  return useCallback(() => openWizard(), [openWizard]);
}
