import {
  Css,
  GridColumn,
  GridTable,
  IconButton,
  Palette,
  column,
  emptyCell,
  simpleDataRows,
  useModal,
} from "@homebound/beam";
import { useMemo } from "react";
import { Icon } from "src/components/Icon";
import { BusinessFunctionRolesModal } from "src/components/change-log/components/BusinessFunctionRolesModal";
import { BusinessFunctionRoles_BusinessFunctionFragment } from "src/generated/graphql-types";
import { formatCentsToPrice, pluralize } from "src/utils";

type BusinessFunctionRolesProps = {
  configurationData: BusinessFunctionRoles_BusinessFunctionFragment;
};

export function BusinessFunctionRoles({ configurationData }: BusinessFunctionRolesProps) {
  const { openModal } = useModal();
  const rows = useMemo(() => createRows(configurationData), [configurationData]);
  const columns = useMemo(
    () => createColumns(() => openModal({ content: <BusinessFunctionRolesModal {...configurationData} /> })),
    [configurationData, openModal],
  );
  return (
    <div css={Css.br8.bgWhite.bshBasic.p3.m3.$}>
      <div css={Css.$}>
        <h2 css={Css.baseMd.mb2.$}>Roles</h2>
        <GridTable
          rows={rows}
          columns={columns}
          style={{
            cellCss: Css.bgWhite.smMd.mt1.$,
            rowHoverColor: Palette.White,
            headerCellCss: Css.sm.gray700.$,
            emptyCell: "--",
          }}
        />
      </div>
      <ChangeTypesForFunction businessFunction={configurationData} />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: BusinessFunctionRoles_BusinessFunctionFragment };

type Row = HeaderRow | DataRow;

function createRows(data: BusinessFunctionRoles_BusinessFunctionFragment) {
  return simpleDataRows([data]);
}

function createColumns(onEdit: () => void): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Initial Reviewer",
      data: ({ initialReviewer }) => initialReviewer?.name,
    }),
    column<Row>({
      header: "Impact Reviewer",
      data: ({ impactReviewer }) => impactReviewer?.name,
    }),
    column<Row>({
      header: "Approver",
      data: ({ approverProjectRole }) => approverProjectRole?.name,
    }),
    column<Row>({
      header: "Auto Approval Amount",
      data: ({ autoApprovalAmountInCents }) =>
        typeof autoApprovalAmountInCents === "number" ? formatCentsToPrice(autoApprovalAmountInCents) : "",
    }),
    column<Row>({
      header: "Priority of this Function",
      data: ({ priority }) => priority,
    }),
    column<Row>({
      header: emptyCell,
      data: () => (
        <div css={Css.df.jcfe.w100.mr3.$}>
          <IconButton icon="pencil" onClick={onEdit} data-testid="editRoles" />
        </div>
      ),
    }),
  ];
}

function ChangeTypesForFunction({
  businessFunction,
}: {
  businessFunction: BusinessFunctionRoles_BusinessFunctionFragment;
}) {
  const { applicableChangeTypes, initialReviewer, impactReviewer } = businessFunction;
  const maybeReviewerString = getReviewerString({ initialReviewer, impactReviewer });
  return (
    maybeReviewerString && (
      <div>
        <div css={Css.mt3.df.aic.$}>
          <Icon icon="infoCircle" inc={2} xss={Css.mr1.$} />
          <span>{maybeReviewerString}</span>
        </div>
        <ul css={Css.mt1.$}>
          {applicableChangeTypes.map((changeType) => (
            <li key={`initialReviewer-${changeType.code}`} css={Css.xs.$}>
              {changeType.name}
            </li>
          ))}
        </ul>
      </div>
    )
  );
}

function getReviewerString({
  initialReviewer,
  impactReviewer,
}: Pick<BusinessFunctionRoles_BusinessFunctionFragment, "initialReviewer" | "impactReviewer">) {
  if (!initialReviewer && !impactReviewer) return null;
  const reviewerStrings = [
    ...(initialReviewer ? ["Initial Reviewer"] : []),
    ...(impactReviewer ? ["Impact Reviewer"] : []),
  ];

  return `The ${reviewerStrings.join(" and ")} ${pluralize(
    reviewerStrings.length,
    "receives",
    "receive",
  )} To-Dos related to these change types:`;
}
