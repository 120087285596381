import { MutableRefObject, useMemo } from "react";

/** Returns a div+ref combination where `div` can be passed to `createPortal`, and it will end in `ref`. */
export function usePortalDiv(): [HTMLDivElement, MutableRefObject<HTMLDivElement | null>] {
  const div = useMemo(() => document.createElement("div"), []);
  const ref: MutableRefObject<HTMLDivElement | null> = {
    get current() {
      return null;
    },
    set current(current: HTMLDivElement | null) {
      if (current) {
        current.appendChild(div);
      }
    },
  };
  return [div, ref];
}

/** A component testing/storybook that provides a portal div and also prepends it in the DOM. */
export function WithPortal(props: { children: (div: HTMLDivElement) => JSX.Element }): JSX.Element {
  const [div, ref] = usePortalDiv();
  return (
    <div>
      <div ref={ref} />
      {props.children(div)}
    </div>
  );
}
