import React, { useContext, useState } from "react";
import { SubmitChangeEventApprovalStepView } from "src/components/approvals/SubmitChangeEventApprovalStepView";
import { WizardStep, useStepperWizard } from "src/components/stepper/useStepperWizard/useStepperWizard";
import { ReleasePOsStep } from "./ReleasePOsStep";
import { VoidPOsStep } from "./VoidPOsStep";

export function useManagePosWizard(bidContractRevisionId: string) {
  return useStepperWizard({
    steps,
    Provider: ({ children }) => (
      <ManagePOsWizardProvider bcrId={bidContractRevisionId}>{children}</ManagePOsWizardProvider>
    ),
  }).openWizard;
}

function ManagePOsWizardProvider({ bcrId, children }: React.PropsWithChildren<{ bcrId: string }>) {
  const [changeEventIds, setChangeEventIds] = useState<string[]>([]);
  return (
    <ManagePosWizardContext.Provider value={{ bcrId, changeEventIds, setChangeEventIds }}>
      {children}
    </ManagePosWizardContext.Provider>
  );
}

const steps: WizardStep[] = [
  { label: "Void POs", render: VoidPOsStep },
  { label: "Release new POs", render: ReleasePOsStep },
  {
    label: "Submit for Approval",
    render: () => (
      <ManagePosWizardContext.Consumer>
        {({ changeEventIds }) => <SubmitChangeEventApprovalStepView changeEventIds={changeEventIds} />}
      </ManagePosWizardContext.Consumer>
    ),
  },
];

type ManagePosWizardContextType = {
  bcrId: string;
  changeEventIds: string[];
  setChangeEventIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ManagePosWizardContext = React.createContext<ManagePosWizardContextType>({
  bcrId: "",
  changeEventIds: [],
  setChangeEventIds: () => {},
});

export const useManagePosWizardContext = () => useContext(ManagePosWizardContext);
