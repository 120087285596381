import { ApolloClient } from "@apollo/client";
import {
  BoundChipSelectField,
  Chip,
  Css,
  GridColumn,
  GridRowKind,
  IconButton,
  Palette,
  actionColumn,
  column,
} from "@homebound/beam";
import { ObjectConfig, ObjectState } from "@homebound/form-state";
import { addBusinessDays, differenceInBusinessDays, subBusinessDays } from "date-fns";
import { Dispatch } from "react";
import { CommentCountBubble, ProgressPill } from "src/components";
import { RequiredTaskDocumentFileStatus } from "src/components/RequiredTaskDocumentFileStatus";
import { TaskChecklistItemsStatus } from "src/components/TaskChecklistItemsStatus";
import { TaskCostAllocationIcon } from "src/components/TaskCostAllocationIcon";
import {
  BaselineIntervalDetailsFragment,
  CalendarInterval,
  DayOfWeek,
  Maybe,
  ProgressSummaryStatus,
  ProjectRoleDetailsFragment,
  RequiredTaskDocumentDetailFragment,
  SaveScheduleTaskChecklistItemInput,
  SaveScheduleTaskInput,
  Scalars,
  ScheduleDetailsFragment,
  ScheduleIntervalDetailsFragment,
  SchedulePhaseProgressDetailsFragment,
  ScheduleSettingDetailsFragment,
  ScheduleStatus,
  ScheduleSubPhaseProgressDetailsFragment,
  TaskAssigneeFragment,
  TaskDateEditabilityFragment,
  TaskDateEditabilityFragmentDoc,
  TaskDependencyInput,
  TaskDetailPaneFragment,
  TaskFilterOptionsFragment,
  TaskScheduleDetailsFragment,
  TaskStatusesFragment,
} from "src/generated/graphql-types";
import { BaselineChip } from "src/routes/components/BaselineChip";
import { SchedulePhaseIndicatorFormInput } from "src/routes/developments/components/SchedulePhaseIndicator";
import { TaskStatusSelect } from "src/routes/projects/schedule-v2/components/TaskStatusSelect";
import { Actions, setTaskPaneState } from "src/routes/projects/schedule-v2/contexts/scheduleStoreReducer";
import { OperationInput } from "src/routes/projects/schedule-v2/scheduleMutationManager";
import { CollapseCell } from "src/routes/projects/schedule-v2/table/CollapseCell";
import { DateCellField } from "src/routes/projects/schedule-v2/table/DateCellField";
import { DateCellReadOnly } from "src/routes/projects/schedule-v2/table/DateCellReadOnly";
import { DurationCellField } from "src/routes/projects/schedule-v2/table/DurationCellField";
import { DurationCellReadOnly } from "src/routes/projects/schedule-v2/table/DurationCellReadOnly";
import { MoreOptionsMenuCell } from "src/routes/projects/schedule-v2/table/MoreOptionsMenuCell";
import { PhaseCell } from "src/routes/projects/schedule-v2/table/PhaseCell";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { SubPhaseCell } from "src/routes/projects/schedule-v2/table/SubPhaseCell";
import { TaskIconCell } from "src/routes/projects/schedule-v2/table/TaskIconCell";
import { calcPercent } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { TaskTradePartnerStatusIcon } from "../components/TaskTradePartnerStatusIcon";
import { DependencyCell } from "./DependencyCell";
import { TaskNumberCell } from "./TaskNumberCell";
import { TableSortableCols } from "./sortUtils";

export type HeaderRow = { kind: "header"; data: undefined };
export type PhaseRow = {
  kind: "phase";
  id: string;
  data: {
    phaseId: string;
    name: string;
    interval: ScheduleIntervalDetailsFragment | null;
    tasks?: TaskScheduleDetailsFragment[];
    progress?: SchedulePhaseProgressDetailsFragment;
    baselineInterval?: ScheduleIntervalDetailsFragment | null;
    status?: ProgressSummaryStatus;
  };
};
export type SubPhaseRow = {
  kind: "subPhase";
  id: string;
  data: {
    subPhaseId: string;
    name: string;
    interval: ScheduleIntervalDetailsFragment | null;
    tasks?: TaskScheduleDetailsFragment[];
    progress?: ScheduleSubPhaseProgressDetailsFragment;
    baselineInterval?: ScheduleIntervalDetailsFragment | null;
    status?: ProgressSummaryStatus;
  };
};
export type TaskRow = {
  kind: "task";
  id: string;
  data: Omit<TaskScheduleDetailsFragment, "kind"> & { kind: "task" };
};

export type ScheduleRow = HeaderRow | PhaseRow | SubPhaseRow | TaskRow;

// We tend to use PascalCase for these enums, however these groupBy keys are already in use as query params
// keeping the casing the same to avoid dealing with a breaking route change
export enum ScheduleListGroupBy {
  phase = "phase",
  upcoming = "upcoming",
  none = "none",
}

type createScheduleColumnArgs = {
  schedule: ScheduleDetailsFragment;
  onTaskSave: (input: OperationInput) => void;
  onRowClick: (taskId: string, scrollIntoViewType?: "comments" | "predecessor" | "successor" | undefined) => void;
  taskStatuses: TaskStatusesFragment[];
  teamMembers: TaskAssigneeFragment[];
  projectRoleDetails: ProjectRoleDetailsFragment[];
  lazyTaskObjectStateFactory: (task: TaskScheduleDetailsFragment) => ObjectState<Partial<TaskDetailsFormInput>>;
  scheduleIsLocked: boolean;
  listGroupByValue: ScheduleListGroupBy;
  scheduleType: ScheduleType;
  tasks: TaskFilterOptionsFragment[];
  dispatch: Dispatch<Actions>;
  scrollToSubPhase: (subPhaseId: string) => void;
  setPaneId: (taskId: string) => void;
};

export function createScheduleColumns(args: createScheduleColumnArgs): GridColumn<ScheduleRow>[] {
  const {
    schedule,
    onTaskSave,
    onRowClick, // TODO: Unused -- But a bunch of things pass this in and I don't want to deal with it this commit
    taskStatuses,
    teamMembers,
    projectRoleDetails,
    lazyTaskObjectStateFactory,
    scheduleIsLocked,
    listGroupByValue,
    scheduleType,
    tasks,
    dispatch,
    scrollToSubPhase,
    setPaneId,
  } = args;
  const { scheduleSetting } = schedule;
  const isTemplate = scheduleType === ScheduleType.Template;
  const renderMoreOptions = !scheduleIsLocked && listGroupByValue === ScheduleListGroupBy.phase;
  const arrowColumn = actionColumn<ScheduleRow>({
    header: CollapseCell,
    phase: CollapseCell,
    subPhase: CollapseCell,
    task: TaskIconCell,
    w: "48px",
    name: "Arrow",
    id: "Arrow",
    sticky: "left",
  });

  const taskNameColumn = column<ScheduleRow>({
    header: "Task",
    phase: ({ name }) => ({ content: <div css={Css.baseMd.$}>{name}</div>, css: Css.mw("185px").$ }),
    subPhase: ({ name }) => ({ content: <div css={Css.smMd.$}>{name}</div>, css: Css.mw("185px").$ }),
    task: (task) => ({
      typeScale: "xs",
      onClick: () => {
        dispatch(
          setTaskPaneState({
            taskPaneId: task.id,
            tab: "details",
          }),
        );
      },
      content: () => (
        <div css={Css.df.gap2.$}>
          <TaskNumberCell id={task.id} />
          <div data-testid="name" css={Css.maxw("300px").xsMd.$}>
            {task.name}
          </div>
        </div>
      ),
      alignment: "left",
    }),
    w: 1.5,
    mw: "300px",
    serverSideSortKey: TableSortableCols.TaskName,
    name: "Name",
    id: "Name",
    sticky: "left",
  });

  // Todo: Add tooltip and disabled reasons in SC-9835
  const startColumn = column<ScheduleRow>({
    header: "Start",
    w: "100px",
    phase: ({ interval }) => <DateCellReadOnly interval={interval} field={"startDate"} />,
    subPhase: ({ interval }) => <DateCellReadOnly interval={interval} field={"startDate"} />,
    task: (task) => ({
      content: (
        <DateCellField
          field={"startDate"}
          objectState={lazyTaskObjectStateFactory(task)}
          editability={task}
          scheduleIsLocked={scheduleIsLocked}
          scheduleSetting={scheduleSetting}
          hideCalendarIcon
          label="Start"
        />
      ),
    }),
    serverSideSortKey: TableSortableCols.TaskStart,
    name: "Start",
    id: "Start",
  });

  const plannedStartColumn = column<ScheduleRow>({
    header: "Planned Start",
    w: "100px",
    phase: ({ baselineInterval }) => <DateCellReadOnly interval={baselineInterval} field={"startDate"} />,
    subPhase: ({ baselineInterval }) => <DateCellReadOnly interval={baselineInterval} field={"startDate"} />,
    task: ({ baselineInterval2 }) => (
      <DateCellReadOnly interval={baselineInterval2} field={"startDate"} testId="plannedStart" />
    ),
    serverSideSortKey: TableSortableCols.TaskStartBaseline,
    name: "Planned Start",
    id: "plannedStart",
    canHide: true,
  });

  const startVarianceColumn = column<ScheduleRow>({
    header: "Start Variance",
    w: "80px",
    phase: ({ interval, baselineInterval, status }) => (
      <BaselineChip
        baselineDelta={getBaselineDelta(baselineInterval, interval, "startDate")}
        status={status}
        scheduleSetting={scheduleSetting}
      />
    ),
    subPhase: ({ interval, baselineInterval, status }) => (
      <BaselineChip
        baselineDelta={getBaselineDelta(baselineInterval, interval, "startDate")}
        status={status}
        scheduleSetting={scheduleSetting}
      />
    ),
    task: ({ interval, baselineInterval2, status }) => (
      <div data-testid="startVariance">
        <BaselineChip
          baselineDelta={getBaselineDelta(baselineInterval2, interval, "startDate")}
          status={status}
          scheduleSetting={scheduleSetting}
        />
      </div>
    ),
    name: "Start Variance",
    id: "StartVariance",
    canHide: true,
  });

  // Todo: Add tooltip and disabled reasons in SC-9835
  const endColumn = column<ScheduleRow>({
    header: "End",
    w: "100px",
    phase: ({ interval }) => <DateCellReadOnly interval={interval} field={"endDate"} />,
    subPhase: ({ interval }) => <DateCellReadOnly interval={interval} field={"endDate"} />,
    task: (task) => ({
      content: (
        <DateCellField
          field={"endDate"}
          objectState={lazyTaskObjectStateFactory(task)}
          editability={task}
          scheduleIsLocked={scheduleIsLocked}
          scheduleSetting={scheduleSetting}
          hideCalendarIcon
          label="End"
        />
      ),
    }),
    serverSideSortKey: TableSortableCols.TaskEnd,
    name: "End",
    id: "End",
  });

  const plannedEndColumn = column<ScheduleRow>({
    header: "Planned End",
    w: "100px",
    phase: ({ baselineInterval }) => <DateCellReadOnly interval={baselineInterval} field={"endDate"} />,
    subPhase: ({ baselineInterval }) => <DateCellReadOnly interval={baselineInterval} field={"endDate"} />,
    task: ({ baselineInterval2 }) => (
      <DateCellReadOnly interval={baselineInterval2} field={"endDate"} testId="plannedEnd" />
    ),
    serverSideSortKey: TableSortableCols.TaskEndBaseline,
    name: "Planned End",
    id: "PlannedEnd",
    canHide: true,
  });

  const endVarianceColumn = column<ScheduleRow>({
    header: "End Variance",
    w: "80px",
    phase: ({ interval, baselineInterval, status }) => (
      <BaselineChip
        baselineDelta={getBaselineDelta(baselineInterval, interval, "endDate")}
        status={status}
        scheduleSetting={scheduleSetting}
      />
    ),
    subPhase: ({ interval, baselineInterval, status }) => (
      <BaselineChip
        baselineDelta={getBaselineDelta(baselineInterval, interval, "endDate")}
        status={status}
        scheduleSetting={scheduleSetting}
      />
    ),
    task: ({ interval, baselineInterval2, status }) => (
      <div data-testid="endVariance">
        <BaselineChip
          baselineDelta={getBaselineDelta(baselineInterval2, interval, "endDate")}
          status={status}
          scheduleSetting={scheduleSetting}
        />
      </div>
    ),
    name: "End Variance",
    id: "EndVariance",
    canHide: true,
  });

  // Todo: Add tooltip and disabled reasons in SC-9835
  const durationColumn = column<ScheduleRow>({
    header: "Duration",
    w: "100px",
    phase: ({ interval }) => <DurationCellReadOnly interval={interval} />,
    subPhase: ({ interval }) => <DurationCellReadOnly interval={interval} />,
    task: (task) => ({
      content: (
        <DurationCellField
          objectState={lazyTaskObjectStateFactory(task)}
          editability={task}
          scheduleIsLocked={scheduleIsLocked}
        />
      ),
    }),
    serverSideSortKey: TableSortableCols.TaskDuration,
    name: "Duration",
    id: "Duration",
    canHide: true,
    initVisible: true,
  });

  const plannedDurationColumn = column<ScheduleRow>({
    header: "Planned Duration",
    w: "100px",
    phase: ({ baselineInterval }) => <DurationCellReadOnly interval={baselineInterval} />,
    subPhase: ({ baselineInterval }) => <DurationCellReadOnly interval={baselineInterval} />,
    task: ({ baselineInterval2 }) =>
      baselineInterval2?.startDate &&
      baselineInterval2?.endDate && <DurationCellReadOnly interval={baselineInterval2} testId="plannedDuration" />,
    serverSideSortKey: TableSortableCols.TaskDurationBaseline,
    name: "Planned Duration",
    id: "PlannedDuration",
    canHide: true,
  });

  const durationVarianceColumn = column<ScheduleRow>({
    header: "Duration Variance",
    w: "80px",
    phase: ({ interval, baselineInterval, status }) => (
      <BaselineChip
        baselineDelta={getBaselineDelta(baselineInterval, interval, "durationInDays")}
        status={status}
        scheduleSetting={scheduleSetting}
      />
    ),
    subPhase: ({ interval, baselineInterval, status }) => (
      <BaselineChip
        baselineDelta={getBaselineDelta(baselineInterval, interval, "durationInDays")}
        status={status}
        scheduleSetting={scheduleSetting}
      />
    ),
    task: ({ interval, baselineInterval2, status }) => (
      <div data-testid="durationVariance">
        <BaselineChip
          baselineDelta={getBaselineDelta(baselineInterval2, interval, "durationInDays")}
          status={status}
          scheduleSetting={scheduleSetting}
        />
      </div>
    ),
    name: "Duration Variance",
    id: "DurationVariance",
    canHide: true,
    initVisible: true,
  });

  const predecessorColumn = column<ScheduleRow>({
    w: "210px",
    header: "Predecessor",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: (
        <DependencyCell
          task={task}
          dependencyType="predecessor"
          tasks={tasks}
          onSave={(changedValue) => {
            onTaskSave({
              ...changedValue,
            });
          }}
          scheduleIsLocked={scheduleIsLocked}
          formState={lazyTaskObjectStateFactory(task)}
          setPaneId={setPaneId}
        />
      ),
      css: Css.wPx(180).$,
    }),
    name: "Predecessor",
    id: "Predecessor",
    canHide: true,
    initVisible: true,
  });

  const successorColumn = column<ScheduleRow>({
    w: "210px",
    header: "Successor",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: (
        <DependencyCell
          task={task}
          dependencyType="successor"
          tasks={tasks}
          onSave={(changedValue) => {
            onTaskSave({
              ...changedValue,
            });
          }}
          scheduleIsLocked={scheduleIsLocked}
          formState={lazyTaskObjectStateFactory(task)}
          setPaneId={setPaneId}
        />
      ),
      css: Css.wPx(180).$,
    }),
    name: "Successor",
    id: "Successor",
    canHide: true,
    initVisible: true,
  });

  const phaseColumn = column<ScheduleRow>({
    w: "130px",
    header: "Phase",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: (
        <PhaseCell
          objectState={lazyTaskObjectStateFactory(task)}
          scheduleIsLocked={scheduleIsLocked}
          hasGlobalTask={!!task.globalTask}
          globalPhaseName={task.globalPhase?.name}
        />
      ),
    }),
    name: "Phase",
    id: "Phase",
    canHide: true,
    initVisible: true,
  });

  // Todo: add disabled prop with tooltip reason
  const subPhaseColumn = column<ScheduleRow>({
    w: "130px",
    header: "SubPhase",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: <SubPhaseCell objectState={lazyTaskObjectStateFactory(task)} scheduleIsLocked={scheduleIsLocked} />,
    }),
    name: "SubPhase",
    id: "SubPhase",
    canHide: true,
    initVisible: true,
  });

  const filesColumn = column<ScheduleRow>({
    w: "61px",
    header: "Files",
    name: "Files",
    id: "Files",
    phase: "",
    subPhase: "",
    task: (task) => {
      if (task.requiredTaskDocuments.isEmpty) return null;
      return (
        <RequiredTaskDocumentFileStatus
          requiredTaskDocuments={task.requiredTaskDocuments as RequiredTaskDocumentDetailFragment[]}
          taskId={task.id}
        />
      );
    },
    align: "center",
    canHide: true,
    initVisible: true,
  });

  const checklistColumn = column<ScheduleRow>({
    w: "88px",
    header: "Checklist",
    name: "Checklist",
    id: "Checklist",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: () => {
        if (task.checklistComplete === null) return null;
        return <TaskChecklistItemsStatus taskId={task.id} checklistComplete={!!task.checklistComplete} />;
      },
    }),
    align: "center",
    canHide: true,
    initVisible: true,
  });

  const costAllocationColumn = column<ScheduleRow>({
    w: "88px",
    header: "Cost Allocation",
    name: "Cost Allocation",
    id: "CostAllocation",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: () => {
        if (task.projectItems.isEmpty) return null;
        return <TaskCostAllocationIcon taskId={task.id} />;
      },
    }),
    align: "center",
    canHide: true,
    initVisible: true,
  });

  const statusColumn = column<ScheduleRow>({
    header: "Status",
    phase: (phase) => {
      return <ProgressPill progress={getProgress(phase.progress)} changeColorOnCompleted />;
    },
    subPhase: (subPhase) => {
      return <ProgressPill progress={getProgress(subPhase.progress)} changeColorOnCompleted />;
    },
    task: (task) => {
      // `deadlineType` (key phase/cutoff) type tasks do not have a user-facing status
      if (!!task.deadlineType) return null;
      const os = lazyTaskObjectStateFactory(task);
      // Tasks statuses may not be updated when the schedule is in draft
      const readOnlyInDraft = schedule.status === ScheduleStatus.Draft;

      return (
        <TaskStatusSelect
          hideLabel
          label="Task Status"
          options={taskStatuses}
          statusField={os.status}
          canComplete={task.canComplete}
          canStart={task.canStart}
          disabled={scheduleIsLocked || readOnlyInDraft}
        />
      );
    },
    w: "155px",
    serverSideSortKey: TableSortableCols.TaskStatus,
    id: "Status",
    name: "Status",
    canHide: true,
    initVisible: true,
  });

  const assigneeColumn = column<ScheduleRow>({
    w: "130px",
    header: "Assignee",
    phase: "",
    subPhase: "",
    task: (task) => {
      const os = lazyTaskObjectStateFactory(task);
      const options = [...(task.assignee ? [task.assignee] : []), ...teamMembers].uniqueByKey("id");
      return (
        <BoundChipSelectField
          options={options}
          field={os.internalUserId}
          label="Assignee"
          placeholder="+ Assignee"
          disabled={options.isEmpty}
        />
      );
    },
    name: "Assignee",
    id: "Assignee",
    canHide: true,
    initVisible: true,
  });

  const roleColumn = column<ScheduleRow>({
    w: "130px",
    header: "Role",
    phase: "",
    subPhase: "",
    task: (task) => {
      const os = lazyTaskObjectStateFactory(task);
      return (
        <BoundChipSelectField
          getOptionValue={({ code }) => code}
          getOptionLabel={({ name }) => name}
          options={projectRoleDetails.sortByKey("name") ?? []}
          field={os.templateProjectRole}
          label="Role"
          placeholder="+ Role"
          disabled={scheduleIsLocked}
        />
      );
    },
    canHide: true,
    initVisible: true,
    name: "Role",
    id: "Role",
  });

  const tradePartnerColumn = column<ScheduleRow>({
    w: "130px",
    header: "Trade Partner",
    phase: "",
    subPhase: "",
    task: (task) => {
      return task.tradePartner ? (
        <div data-testid="tradePartner">
          <Chip text={task.tradePartner.name} title={task.tradePartner.name} />
        </div>
      ) : (
        ""
      );
    },
    name: "Trade Partner",
    id: "TradePartner",
    canHide: true,
    initVisible: true,
  });

  const scheduledColumn = column<ScheduleRow>({
    header: "Scheduled",
    phase: "",
    subPhase: "",
    task: (task) => ({
      content: task.tradePartnerStatus && <TaskTradePartnerStatusIcon status={task.tradePartnerStatus} />,
    }),
    w: "180px",
    id: "Scheduled",
    name: "Scheduled",
    align: "center",
    canHide: true,
    initVisible: true,
  });

  const buttonColumn = actionColumn<ScheduleRow>({
    header: "Actions",
    id: "Actions",
    name: "Actions",
    w: args.scheduleType !== ScheduleType.Template ? "120px" : "90px",
    sticky: "right",
    phase: (data, { row }) => ({
      css: Css.bl.bcGray200.$,
      alignment: "right",
      content: renderMoreOptions && (
        <MoreOptionsMenuCell
          row={row as PhaseRow}
          tasks={tasks}
          schedule={schedule}
          onTaskSave={onTaskSave}
          scrollToSubPhase={scrollToSubPhase}
          scheduleIsLocked={scheduleIsLocked}
        />
      ),
    }),
    subPhase: (data, { row }) => ({
      css: Css.bl.bcGray200.$,
      alignment: "right",
      content: renderMoreOptions && (
        <MoreOptionsMenuCell
          row={row as SubPhaseRow}
          tasks={tasks}
          schedule={schedule}
          onTaskSave={onTaskSave}
          scrollToSubPhase={scrollToSubPhase}
          scheduleIsLocked={scheduleIsLocked}
        />
      ),
    }),
    task: (task, { row }) => ({
      css: Css.bl.bcGray200.$,
      alignment: "right",
      content: renderTaskIconButtons(task, row, args),
    }),
  });

  return [
    arrowColumn,
    // selectColumn, // TODO: Functionality awaiting [SC-7405]
    taskNameColumn,
    startColumn,
    ...(!isTemplate ? [plannedStartColumn] : []),
    ...(!isTemplate ? [startVarianceColumn] : []),
    endColumn,
    ...(!isTemplate ? [plannedEndColumn] : []),
    ...(!isTemplate ? [endVarianceColumn] : []),
    durationColumn,
    ...(!isTemplate ? [plannedDurationColumn] : []),
    ...(!isTemplate ? [durationVarianceColumn] : []),
    predecessorColumn,
    successorColumn,
    ...(isTemplate ? [roleColumn] : []),
    ...(!isTemplate ? [statusColumn, assigneeColumn, tradePartnerColumn, scheduledColumn] : []),
    phaseColumn,
    subPhaseColumn,
    filesColumn,
    checklistColumn,
    costAllocationColumn,
    buttonColumn,
  ];
}

export function getProgress(
  progress: SchedulePhaseProgressDetailsFragment | ScheduleSubPhaseProgressDetailsFragment | undefined,
): number {
  const total = progress?.taskProgress?.total ?? 0;
  const completed = progress?.taskProgress?.completed ?? 0;
  return Math.round(calcPercent(completed, total));
}

function getBaselineDelta(
  baselineInterval: ScheduleIntervalDetailsFragment | BaselineIntervalDetailsFragment | null | undefined,
  interval: ScheduleIntervalDetailsFragment | null | undefined,
  type: "startDate" | "endDate" | "durationInDays",
) {
  if (type === "durationInDays") {
    return {
      baselineDate: baselineInterval ? baselineInterval?.endDate : undefined,
      actualDuration: interval ? interval[type] : undefined,
      baselineDuration: baselineInterval ? baselineInterval[type] : undefined,
    };
  } else {
    return {
      actualDate: interval ? interval[type] : undefined,
      baselineDate: baselineInterval ? baselineInterval[type] : undefined,
    };
  }
}

export type FormInput = SaveScheduleTaskInput & {
  endDate?: DateOnly | null | undefined;
};

export type TaskDetailsFormInput = Omit<FormInput, "predecessorDependencies" | "successorDependencies"> & {
  predecessorDependencies: DependencyValue[];
  successorDependencies: DependencyValue[];
  scheduleTaskChecklistItems?: SaveScheduleTaskChecklistItemInput[];
};

export type DependencyValue = Omit<TaskDependencyInput, "successorId" | "predecessorId"> & {
  id: Scalars["ID"];
  name: string;
};

export const dependencyConfig: ObjectConfig<DependencyValue> = {
  type: { type: "value" },
  lagInDays: { type: "value" },
  delete: { type: "value" },
  id: { type: "value" },
  name: { type: "value" },
};

export const formConfig: ObjectConfig<Partial<TaskDetailsFormInput>> = {
  id: { type: "value" },
  name: { type: "value" },
  startDate: { type: "value" },
  endDate: { type: "value" },
  durationInDays: { type: "value" },
  status: { type: "value" },
  schedulePhase: { type: "value" },
  scheduleSubPhase: { type: "value" },
  internalUserId: { type: "value" },
  templateProjectRole: { type: "value" },
  successorDependencies: {
    type: "list",
    config: dependencyConfig,
  },
  predecessorDependencies: {
    type: "list",
    config: dependencyConfig,
  },
  tradePartnerStatus: { type: "value" },
};

export function mapToForm(task: TaskScheduleDetailsFragment): TaskDetailsFormInput {
  return {
    id: task.id,
    name: task.name,
    startDate: task.interval.startDate,
    durationInDays: task.interval.durationInDays,
    endDate: task.interval.endDate,
    internalUserId: task.assignee?.id,
    templateProjectRole: task.templateProjectRole,
    status: task.status,
    schedulePhase: task.globalPhase?.id,
    scheduleSubPhase: task.scheduleSubPhase?.name,
    predecessorDependencies: mapPredecessorsToFormState(task),
    successorDependencies: mapSuccessorsToFormState(task),
    tradePartnerStatus: task.tradePartnerStatus.code,
  };
}

export function mapPredecessorsToFormState(task: TaskScheduleDetailsFragment | TaskDetailPaneFragment) {
  return task.predecessorDependencies.map((dep) => ({
    type: dep.type,
    lagInDays: dep.lagInDays,
    id: dep.predecessor.id,
    name: dep.predecessor.name,
  }));
}

export function mapSuccessorsToFormState(task: TaskScheduleDetailsFragment | TaskDetailPaneFragment) {
  return task.successorDependencies.map((dep) => ({
    type: dep.type,
    lagInDays: dep.lagInDays,
    id: dep.successor.id,
    name: dep.successor.name,
  }));
}

export function mapToInput(
  changedValue: Partial<TaskDetailsFormInput>,
  requiredTaskDocumentDocuments: string[] = [],
): OperationInput {
  const { predecessorDependencies, successorDependencies, documents, ...others } = changedValue;

  return {
    ...others,
    documents: documents ? [...requiredTaskDocumentDocuments, ...documents] : undefined,
    ...(predecessorDependencies
      ? {
          predecessorDependencies: predecessorDependencies.map(({ id, name, ...others }) => ({
            predecessorId: id,
            ...others,
          })),
        }
      : {}),
    ...(successorDependencies
      ? {
          successorDependencies: successorDependencies.map(({ id, name, ...others }) => ({
            successorId: id,
            ...others,
          })),
        }
      : {}),
  };
}

// TODO: Codegen this? and move it elsewhere
const tagsToTypes: Record<string, string> = {
  u: "SchedulePhase",
  a: "ScheduleSubPhase",
  t: "ScheduleTask",
};

export function cacheKeyFromId(id: string): string {
  const [tag] = id.split(":");
  return `${tagsToTypes[tag]}:${id}`;
}

export type TaskDateField = keyof Pick<CalendarInterval, "startDate" | "endDate" | "durationInDays">;
type TaskIntervalFieldEditability = Record<TaskDateField, boolean>;

export function determineTaskDateEditability(task: Maybe<TaskDateEditabilityFragment>): TaskIntervalFieldEditability {
  // TODO - In another ticket to add hover state?
  return {
    startDate: task?.canEditStartDate?.allowed ?? true,
    endDate: task?.canEditEndDate?.allowed ?? true,
    durationInDays: task?.canEditDuration?.allowed ?? true,
  };
}

export function determineTaskDateEditabilityById(id: string, client: ApolloClient<any>): TaskIntervalFieldEditability {
  const task: TaskDateEditabilityFragment | null = client.cache.readFragment({
    id: cacheKeyFromId(id),
    fragment: TaskDateEditabilityFragmentDoc,
    fragmentName: "TaskDateEditability",
  });

  return determineTaskDateEditability(task);
}

// helper function to get values for optimistically updating task schedule dates
export function getDateValueToUpdate(
  os:
    | ObjectState<FormInput>
    | ObjectState<SchedulePhaseIndicatorFormInput>
    | ObjectState<Partial<TaskDetailsFormInput>>,
  changedValue: Partial<TaskDetailsFormInput>,
  canEdit: TaskIntervalFieldEditability,
  scheduleSetting: ScheduleSettingDetailsFragment | null | undefined,
): {
  input: Partial<TaskDetailsFormInput>;
  attributeToSet?: "startDate" | "endDate" | "durationInDays" | undefined;
  newValue?: number | DateOnly | undefined;
} {
  const {
    startDate: changedStartDate,
    endDate: changedEndDate,
    durationInDays: changedDurationInDays,
    ...others
  } = changedValue;

  const durationChanged = typeof changedDurationInDays === "number";

  if (!durationChanged && !changedEndDate && !changedStartDate) {
    return { input: { ...others } };
  }

  const businessDays = getBusinessDays(scheduleSetting?.workingDays ?? []);
  const exceptions = scheduleSetting?.exceptions;
  const formStartDate = os.startDate.value!;
  const formEndDate = os.endDate.value!;
  const formDuration = os.durationInDays.value!;
  let attributeToSet: "startDate" | "endDate" | "durationInDays" | undefined;
  let newValue;
  let durationInDays = changedDurationInDays ?? formDuration;
  let startDate = changedStartDate ?? formStartDate;
  let endDate = changedEndDate ?? formEndDate;

  if (changedEndDate) {
    attributeToSet = "durationInDays";
    durationInDays = differenceInBusinessDays(changedEndDate, formStartDate, { businessDays, exceptions });
    newValue = durationInDays;
  } else if (changedStartDate && canEdit.endDate) {
    attributeToSet = "endDate";
    endDate = new DateOnly(addBusinessDays(changedStartDate, formDuration, { businessDays, exceptions }));
    newValue = endDate;
  } else if (changedStartDate && !canEdit.endDate) {
    attributeToSet = "durationInDays";
    durationInDays = differenceInBusinessDays(formEndDate, changedStartDate, { businessDays, exceptions });
    newValue = durationInDays;
  } else if (durationChanged && canEdit.endDate) {
    attributeToSet = "endDate";
    endDate = new DateOnly(addBusinessDays(formStartDate, changedDurationInDays, { businessDays, exceptions }));
    newValue = endDate;
  } else if (durationChanged && !canEdit.endDate) {
    attributeToSet = "startDate";
    startDate = new DateOnly(subBusinessDays(formEndDate, changedDurationInDays, { businessDays, exceptions }));
    newValue = startDate;
  }

  const input = {
    startDate,
    durationInDays,
    endDate,
    ...others,
  };
  return { input, attributeToSet, newValue };
}

// Given a `scheduleSetting` object, returns business day functions mapped for those working days and exceptions
export function getBusinessDayHelpersForSchedule(scheduleSetting?: ScheduleSettingDetailsFragment) {
  const businessDays = getBusinessDays(scheduleSetting?.workingDays ?? []);
  const exceptions = scheduleSetting?.exceptions;

  return {
    businessDays,
    addBusinessDays: (date: number | Date, amount: number) =>
      addBusinessDays(date, amount, { businessDays, exceptions }),
    differenceInBusinessDays: (dateLeft: number | Date, dateRight: number | Date) =>
      differenceInBusinessDays(dateLeft, dateRight, { businessDays, exceptions }),
  };
}

export function getBusinessDays(workingDays: DayOfWeek[]) {
  const totalWorkingDays = [1, 2, 3, 4, 5];
  workingDays.includes(DayOfWeek.Sunday) && totalWorkingDays.unshift(0);
  workingDays.includes(DayOfWeek.Saturday) && totalWorkingDays.push(6);
  return totalWorkingDays;
}

function renderTaskIconButtons(
  task: Omit<TaskScheduleDetailsFragment, "kind"> & { kind: "task" },
  row: GridRowKind<ScheduleRow, "task">,
  {
    scheduleIsLocked,
    scheduleType,
    onTaskSave,
    schedule,
    dispatch,
    scrollToSubPhase,
    tasks,
  }: Omit<createScheduleColumnArgs, "onRowClick" | "listGroupByValue">,
) {
  const isTemplate = scheduleType === ScheduleType.Template;
  return (
    <div css={Css.df.aic.gap1.$}>
      {scheduleType === ScheduleType.Project && (
        <IconButton
          icon={task.scheduleFlags.length > 0 ? "flag" : "outlineFlag"}
          color={task.scheduleFlags.length > 0 ? Palette.Red600 : Palette.Gray900}
          onClick={() => {
            dispatch(
              setTaskPaneState({
                taskPaneId: task.id,
                tab: "details",
                scrollIntoViewType: "flags",
              }),
            );
          }}
        />
      )}
      {!isTemplate && (
        <CommentCountBubble
          streams={task.streams}
          onClick={() => {
            dispatch(
              setTaskPaneState({
                taskPaneId: task.id,
                scrollIntoViewType: "comments",
                tab: "comments",
              }),
            );
          }}
          size={3}
        />
      )}
      {scheduleType !== ScheduleType.Project && (
        <IconButton
          icon="infoCircle"
          onClick={() => {
            dispatch(
              setTaskPaneState({
                taskPaneId: task.id,
                tab: "details",
              }),
            );
          }}
        />
      )}
      {!scheduleIsLocked && (
        <MoreOptionsMenuCell
          row={row as TaskRow}
          tasks={tasks}
          schedule={schedule}
          onTaskSave={onTaskSave}
          scrollToSubPhase={scrollToSubPhase}
          scheduleIsLocked={scheduleIsLocked}
        />
      )}
    </div>
  );
}
