import { Icon, IconKey, Palette } from "@homebound/beam";
import { DOMProps } from "@react-types/shared";
import { UserEventParent, UserEventType } from "src/generated/graphql-types";

export type UserEventImageProps = DOMProps & {
  entity: string | undefined;
  eventType?: UserEventType;
};

export function UserEventImage(props: UserEventImageProps) {
  const mappedEntity = mapEntity(props);

  // Search for icon definition based on entity
  // If not found, search for icon definition based on entity otherwise use ChangeEvent icon
  const iconData = userEventTypeToImageMap[props.eventType ?? ""] ?? entityToImageMap[mappedEntity ?? "ChangeEvent"];

  return <Icon inc={2.5} tooltip={mappedEntity} icon={iconData.iconKey} color={iconData.iconColor} />;
}

function mapEntity({ entity, eventType }: UserEventImageProps) {
  if (UserEventType.Attached === eventType) {
    return "Attachment";
  }
  if (UserEventType.LotRelease === eventType) {
    return "LotRelease";
  }
  if (UserEventType.Flagged === eventType) {
    return "ScheduleFlag";
  }
  return entity;
}

export type IconData = {
  iconKey: IconKey;
  iconColor?: Palette;
};

const userEventParentEntityToImageMap: Record<NonNullable<UserEventParent["__typename"]>, IconData> = {
  Approval: { iconKey: "changeEvent" },
  Bill: { iconKey: "bill" },
  ChangeEvent: { iconKey: "changeEvent" },
  ChangeRequestToDo: { iconKey: "todo" },
  PunchlistItemTodo: { iconKey: "todo" },
  Commitment: { iconKey: "commitment" },
  CommitmentChangeOrder: { iconKey: "changeEvent" },
  Document: { iconKey: "document" },
  Estimate: { iconKey: "estimate" },
  HomeownerContractChangeOrder: { iconKey: "changeEvent" },
  Invoice: { iconKey: "bill" },
  JobLog: { iconKey: "task" },
  JobLogNote: { iconKey: "task" },
  JobLogImage: { iconKey: "task" },
  Project: { iconKey: "projectItem" },
  ProjectReadyPlanConfig: { iconKey: "abacus" },
  ProjectItem: { iconKey: "projectItem" },
  Task: { iconKey: "task" },
  ScheduleTask: { iconKey: "task" },
  PlanTask: { iconKey: "task" },
  ToDo: { iconKey: "todo" },
  ProjectLotDetail: { iconKey: "abacus" },
  ReadyPlan: { iconKey: "house" },
  ReadyPlanOption: { iconKey: "house" },
  PlanPackage: { iconKey: "house" },
  DesignPackage: { iconKey: "house" },
  ProductOffering: { iconKey: "house" },
  WarrantyTicket: { iconKey: "house" },
  ItemTemplate: { iconKey: "house" },
};

const entityToImageMap: Record<string, IconData> = {
  ...userEventParentEntityToImageMap,
  Comment: { iconKey: "comment" },
  Attachment: { iconKey: "attachment" },
  LotRelease: { iconKey: "hardHat" },
  // TODO: Gather the correct entities that are going to map to the following icons
  ScheduleFlag: { iconKey: "flag", iconColor: Palette.Red700 },
};

const userEventTypeToImageMap: Record<string, IconData> = {
  [UserEventType.RequestedChanges]: { iconKey: "undoCircle" },
  [UserEventType.Rejected]: { iconKey: "xCircle" },
  [UserEventType.Approved]: { iconKey: "checkCircle" },
  [UserEventType.Commented]: { iconKey: "comment" },
};
