import { Css, useTestIds } from "@homebound/beam";
import { ReactNode } from "react";

type EmptyTileProps = {
  children: ReactNode;
};
export function EmptyTile({ children, ...others }: EmptyTileProps) {
  const tid = useTestIds(others, "emptyTile");
  return (
    <div css={Css.df.fdc.fg1.aic.jcc.w100.h100.bgGray100.gray900.br8.xs.tac.p2.$} {...tid}>
      {children}
    </div>
  );
}
