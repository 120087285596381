import { ApolloError } from "@apollo/client";
import { Css, useTestIds } from "@homebound/beam";
import { ErrorMessages, SuggestedPaths } from "src/components";

export function Error({ error }: { error: ApolloError | Error }) {
  const tid = useTestIds({});
  return (
    <div css={Css.tac.mt4.wPx(600).mxa.$} {...tid.errorPage}>
      <img src="/images/error.jpg" css={Css.bshHover.my2.wPx(400).mxa.$} alt="" />
      <h1 css={Css.xl2Sb.mb4.$} {...tid.title}>
        &#x1F914; Something doesn't seem quite right here&hellip;
      </h1>
      <ErrorMessages error={error} />
      <SuggestedPaths error={error} />
    </div>
  );
}
