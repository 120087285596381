import { Css, Margin, Only, Properties } from "@homebound/beam";

type DividerXStyles = Pick<Properties, Margin>;

type DividerProps<X> = {
  xss?: X;
};

export function Divider<X extends Only<DividerXStyles, X>>({ xss }: DividerProps<X>) {
  return <div css={{ ...Css.mt3.w100.$, ...xss }} />;
}
