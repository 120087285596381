import {
  Button,
  Css,
  GridColumn,
  GridTable,
  IconButton,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
} from "@homebound/beam";
import { useParams } from "react-router-dom";

import {
  DevelopmentDropDetailsFragment,
  useDeleteDevelopmentDropMutation,
  useDevelopmentDropsPageQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { EmptyState } from "src/routes/components/EmptyState";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DevelopmentParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { EmptyPlanAndOptionImage } from "../plan-and-options/components/EmptyStateSvgs";
import { DevelopmentDropModal } from "./components/DevelopmentDropModal";

export function DevelopmentDropsPage() {
  const { developmentId } = useParams<DevelopmentParams>();
  const query = useDevelopmentDropsPageQuery({ variables: { developmentId } });
  useDocumentTitle("Development Drops");
  return queryResult(query, {
    data: ({ developmentDrops }) => (
      <DevelopmentDropsView developmentDrops={developmentDrops} developmentId={developmentId} />
    ),
  });
}

export type DevelopmentDropsViewProps = {
  developmentId: string;
  developmentDrops: DevelopmentDropDetailsFragment[];
};

type Row = SimpleHeaderAndData<DevelopmentDropDetailsFragment>;

export function DevelopmentDropsView({ developmentId, developmentDrops }: DevelopmentDropsViewProps) {
  const { openModal } = useModal();
  const [deleteDevelopmentDrop] = useDeleteDevelopmentDropMutation({
    refetchQueries: ["DevelopmentDropsPage"],
  });
  const hasNoDrops = developmentDrops.length === 0;

  function createDrop() {
    openModal({
      content: <DevelopmentDropModal developmentId={developmentId} />,
    });
  }

  function editDrop(drop: DevelopmentDropDetailsFragment) {
    openModal({
      content: <DevelopmentDropModal developmentId={developmentId} existingDrop={drop} />,
    });
  }

  function deleteDrop(drop: DevelopmentDropDetailsFragment) {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={
            <>
              Are you sure you want to delete this Drop{" "}
              <span css={Css.smBd.$}>
                {drop.code} {drop.name}
              </span>
              ? It will not be archived and cannot be undone.
            </>
          }
          onConfirmAction={async () => deleteDevelopmentDrop({ variables: { id: drop.id } })}
          title="Delete Drop"
          label="Yes, Delete"
          danger={true}
        />
      ),
    });
  }

  return (
    <div>
      <PageHeader title="Drops" right={hasNoDrops ? null : <Button label="Add another Drop" onClick={createDrop} />} />
      {hasNoDrops ? (
        <EmptyState
          message="There are no Drops for this development yet"
          svg={<EmptyPlanAndOptionImage />}
          button={<Button label="Create New Drop" onClick={createDrop} />}
        />
      ) : (
        <ScrollableContent>
          <GridTable columns={createColumns(editDrop, deleteDrop)} rows={simpleDataRows(developmentDrops)} />
        </ScrollableContent>
      )}
    </div>
  );
}

function createColumns(
  editDrop: (drop: DevelopmentDropDetailsFragment) => void,
  deleteDrop: (drop: DevelopmentDropDetailsFragment) => void,
): GridColumn<Row>[] {
  return [
    { header: "Drop Code", data: ({ code }) => code },
    { header: "Drop Name", data: ({ name }) => name },
    { header: "Cost Code", data: ({ costCode }) => costCode.displayName },
    { header: "Drop Task", data: ({ globalPlanTask }) => globalPlanTask.name },
    {
      header: "Locations",
      data: ({ locations }) => (
        <div css={Css.oh.add("textOverflow", "ellipsis").onHover.ov.$}>{locations.map((l) => l.name).join(", ")}</div>
      ),
    },
    {
      header: "Edit",
      data: (drop) => (
        <div css={Css.df.gap1.$}>
          <IconButton icon="pencil" onClick={() => editDrop(drop)} />
          <IconButton icon="trash" onClick={() => deleteDrop(drop)} />
        </div>
      ),
      w: "100px",
    },
  ];
}
