import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSideNavContext } from "src/components/layout/SideNavContext";
import {
  ProjectFeature,
  ProjectStageProjectItemDetailFragment,
  ScheduleDetailsFragment,
  TaskAssigneeFragment,
  TaskFilterOptionsFragment,
  TaskStatusesFragment,
  TaskTradePartnerFragment,
  useProjectSchedulePageV2Query,
  useTaskStatusesQuery,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { CondensedScheduleHeaderData } from "src/routes/projects/schedule-v2/components/CondensedScheduleHeaderData";
import { ScheduleOutOfSyncToast } from "src/routes/projects/schedule-v2/components/ScheduleOutOfSyncToast";
import { ScheduleType } from "src/routes/projects/schedule-v2/table/ScheduleType";
import { useQueryStorage } from "src/routes/projects/schedule-v2/table/filterUtils";
import { StageUrlSlug, getStageFromSlug, queryResult } from "src/utils";
import { Schedule } from "./Schedule";
import { ScheduleStoreProvider } from "./contexts/ScheduleStore";
import { TaskBillModalProvider } from "./contexts/TaskBillModalContext";
import { prepareFilterOptions } from "./scheduleUtils";

export type ProjectSchedulePageRouteParams = {
  projectId: string;
  stageSlug: StageUrlSlug;
};

export function ProjectSchedulePageV2() {
  const { features } = useProjectContext();
  // Loading project schedule data to initialize the page
  const { projectId, stageSlug: stageSlugCode } = useParams<ProjectSchedulePageRouteParams>();
  const stage = getStageFromSlug(stageSlugCode as StageUrlSlug);
  const query = useProjectSchedulePageV2Query({
    variables: { projectId, stage },
    nextFetchPolicy: "cache-first",
  });
  const taskStatusQuery = useTaskStatusesQuery();

  return queryResult(query, {
    data: ({ schedules, project, scheduleTasks: tasks }) => {
      const { assignees, tradePartners } = prepareFilterOptions(tasks);

      return (
        <ScheduleStoreProvider
          scheduleType={ScheduleType.Project}
          stage={stage}
          delayFeatureFlagIsOn={features.includes(ProjectFeature.DelayFlags)}
        >
          <TaskBillModalProvider>
            <ProjectSchedulePageV2Data
              schedules={schedules}
              projectAddress={project.buildAddress.street1}
              projectItems={project.stage.projectItems}
              tasks={tasks}
              marketId={project.market.id}
              filterOptions={{ taskStatuses: taskStatusQuery.data?.taskStatuses ?? [], assignees, tradePartners }}
            />
          </TaskBillModalProvider>
        </ScheduleStoreProvider>
      );
    },
  });
}

export type ProjectSchedulePageV2DataProps = {
  // TODO: Probably want to think more about these props...
  schedules: ScheduleDetailsFragment[];
  projectItems: ProjectStageProjectItemDetailFragment[];
  tasks: TaskFilterOptionsFragment[];
  filterOptions: {
    taskStatuses: TaskStatusesFragment[];
    assignees: TaskAssigneeFragment[];
    tradePartners: TaskTradePartnerFragment[];
  };
  marketId: string;
  projectAddress: string;
};

export function ProjectSchedulePageV2Data(props: ProjectSchedulePageV2DataProps) {
  const { schedules, projectItems, filterOptions, tasks, projectAddress } = props;
  const { projectId } = useParams<ProjectSchedulePageRouteParams>();
  // TODO: What happens if there is no schedule? We probably blow up?
  const schedule = schedules[0];
  const { stage } = schedule;

  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: "scheduleFullScreen",
    initialQueryStorage: { fullScreen: false },
  });
  const { setSideNavState } = useSideNavContext();

  const { fullScreen } = queryStorage;

  useEffect(() => setSideNavState(fullScreen ? "collapse" : "expanded"), [fullScreen, setSideNavState]);

  return (
    <div data-testid="schedulesPageV2">
      <ScheduleOutOfSyncToast
        scheduleParentId={schedule.parent.id}
        scheduleStatus={schedule.status}
        stage={schedule.stage}
      />
      <div>
        {!fullScreen && (
          <CondensedScheduleHeaderData
            projectAddress={projectAddress}
            tradePartners={filterOptions.tradePartners}
            projectId={projectId}
            schedule={schedule}
          />
        )}

        <Schedule
          schedule={schedule}
          tasks={tasks}
          projectItems={projectItems}
          filterOptions={filterOptions}
          fullScreen={fullScreen}
          toggleFullScreen={() => setQueryStorage({ fullScreen: !fullScreen })}
        />
      </div>
    </div>
  );
}
