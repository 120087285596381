import {
  ButtonMenu,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  ScrollableContent,
  selectColumn,
  simpleDataRows,
  SimpleHeaderAndData,
  useComputed,
  useGridTableApi,
} from "@homebound/beam";
import { useState } from "react";
import { SearchBox, tagCell } from "src/components";
import {
  useActivateUsersMutation,
  useDeactivateUsersMutation,
  UsersPageQuery,
  useUsersPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { queryResult } from "src/utils";

/** Lists all internal users and allows deactivating/activating. */
export function UsersPage() {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const query = useUsersPageQuery();
  const [activateUsers] = useActivateUsersMutation({
    refetchQueries: ["UsersPage"],
  });
  const [deactivateUsers] = useDeactivateUsersMutation({
    refetchQueries: ["UsersPage"],
  });
  const tableApi = useGridTableApi<Row>();

  const selectedRows = useComputed(() => tableApi.getSelectedRows("data"), [tableApi]);

  return queryResult(query, {
    data: ({ internalUsers }) => {
      return (
        <>
          <PageHeader title="Users" />
          <TableActions onlyRight>
            <SearchBox onSearch={setSearchFilter} />
            <ButtonMenu
              items={[
                {
                  label: `Activate Selected Users (${selectedRows.length})`,
                  disabled: selectedRows.length === 0,
                  onClick: async () => {
                    await activateUsers({ variables: { input: { id: selectedRows.map((r) => r.data.user.id!) } } });
                    tableApi.clearSelections();
                  },
                },
                {
                  label: `Deactivate Selected Users (${selectedRows.length})`,
                  disabled: selectedRows.length === 0,
                  onClick: async () => {
                    await deactivateUsers({ variables: { input: { id: selectedRows.map((r) => r.data.user.id!) } } });
                    tableApi.clearSelections();
                  },
                },
              ]}
              trigger={{ label: "Actions" }}
            />
          </TableActions>
          <ScrollableContent>
            <GridTable
              api={tableApi}
              style={{ rowHeight: "fixed" }}
              columns={columns}
              rows={createRows(internalUsers)}
              filter={searchFilter}
              stickyHeader
            />
          </ScrollableContent>
        </>
      );
    },
  });
}

type Row = SimpleHeaderAndData<UsersPageQuery["internalUsers"][0]>;

const columns: GridColumn<Row>[] = [
  selectColumn<Row>({ w: "32px" }),
  column<Row>({ header: "Name", data: (d) => d.name }),
  column<Row>({ header: "Email", data: (d) => d.email }),
  column<Row>({
    header: "Status",
    data: (d) => tagCell(d.user?.isActive ? "success" : "neutral", d.user?.isActive ? "Active" : "Inactive"),
    w: "108px",
  }),
];

function createRows(internalUsers: UsersPageQuery["internalUsers"]): GridDataRow<Row>[] {
  return simpleDataRows(internalUsers);
}
