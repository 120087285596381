import { Css, useRightPane, useTestIds } from "@homebound/beam";
import React, { useCallback } from "react";
import { WeekInterval } from "../useWeekRangeFilter";
import { ProjectTaskByWeekRightPane } from "./ProjectTaskByWeekRightPane";
import type { CellSelectionState, ProjectRow } from "./TaskLookaheadTable";

type ProjectTasksCellProps = Pick<ProjectRow["data"], "weekToTaskMapping" | "loading" | "id"> & {
  weekInterval: WeekInterval;
  cellSelectionState: [CellSelectionState, React.Dispatch<CellSelectionState>];
};

export function ProjectTasksCell({
  weekToTaskMapping,
  loading,
  weekInterval,
  id,
  cellSelectionState,
}: ProjectTasksCellProps) {
  const tid = useTestIds({}, "projectTaskCell");
  const { openRightPane } = useRightPane();

  const taskCountForWeek = weekToTaskMapping[weekInterval.weekID].size;
  const isSelectable = taskCountForWeek > 0;
  const [selectedCell, setSelectedCell] = cellSelectionState;
  const isSelected = id === selectedCell?.projectId && weekInterval.weekID === selectedCell?.weekId;

  const handleClick = useCallback(() => {
    if (!isSelectable) return;

    setSelectedCell({ projectId: id, weekId: weekInterval.weekID });
    openRightPane({
      content: (
        <ProjectTaskByWeekRightPane weekInterval={weekInterval} projectId={id} setSelectedCell={setSelectedCell} />
      ),
    });
  }, [isSelectable, openRightPane, weekInterval, id, setSelectedCell]);

  const styles = {
    ...Css.pPx(10).w100.h100.$,
    // `important` overrides the hover style such that selected will stay darker while hovering
    ...(isSelected ? Css.bgBlue200.important.$ : {}),
    ...(isSelectable ? Css.cursorPointer.onHover.bgBlue100.$ : {}),
  };

  return (
    <div {...tid} css={styles} onClick={handleClick}>
      {loading ? "-" : taskCountForWeek}
    </div>
  );
}
