import { BoundSelectField, BoundSelectFieldProps } from "@homebound/beam";
import { useMemo, useState } from "react";
import {
  AddEditItemModal_MvFragment,
  MaterialVariantsFilter,
  useAddEditItemModal_GetMaterialVariantsQuery,
} from "src/generated/graphql-types";

export type MaterialVariantSelectFieldProps = Omit<
  BoundSelectFieldProps<AddEditItemModal_MvFragment, string>,
  "options"
> & {
  //* * Default filter to be used when pulling variants */
  filter: MaterialVariantsFilter;
  initialOptions: AddEditItemModal_MvFragment[];
};

/** Material Variant select field that load options based on user's search
 * This component loads Material Variants and it's MAVs
 */
export function MaterialVariantSelectField({ filter, initialOptions, ...props }: MaterialVariantSelectFieldProps) {
  const [search, setSearch] = useState<string>();
  const { data } = useAddEditItemModal_GetMaterialVariantsQuery({
    variables: { filter: { ...filter, search } },
    fetchPolicy: "cache-first",
  });

  const options = useMemo(
    () => [...initialOptions, ...(data?.materialVariants.entities ?? [])].uniqueByKey("id"),
    [data, initialOptions],
  );

  return <BoundSelectField {...props} options={options} onSearch={setSearch} />;
}
