import { Css, RouteTabWithContent, TabContent } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router";
import {
  createPlanPackageDetailsActivityUrl,
  createPlanPackageDetailsOptionDataUrl,
  createPlanPackageDetailsOverviewUrl,
} from "src/RouteUrls";
import { PlanPackagePageMetadataQuery, usePlanPackagePageMetadataQuery } from "src/generated/graphql-types";
import { PlanPackageHeader } from "src/routes/libraries/plan-package/details/components/PlanPackageHeader";
import { OverviewTab } from "src/routes/libraries/plan-package/details/plan-overview/OverviewTab";
import {
  PlanPackageDetailsParams,
  planPackageDetailsActivity,
  planPackageDetailsOptionData,
  planPackageDetailsOverview,
  planPackagePath,
} from "src/routes/routesDef";
import { queryResult } from "src/utils";
import { ActivityTab } from "./ActivityTab";
import { OptionDataTab } from "./OptionDataTab";

export function PlanPackagePage() {
  const { planPackageId } = useParams<PlanPackageDetailsParams>();
  const planPageMetadataQuery = usePlanPackagePageMetadataQuery({ variables: { id: planPackageId } });

  return queryResult(planPageMetadataQuery, {
    data: (data) => <DataView planPackageId={planPackageId} metadata={{ ...data }} />,
  });
}

type DataViewProps = {
  planPackageId: string;
  metadata: PlanPackagePageMetadataQuery;
};

function DataView({ planPackageId, metadata }: DataViewProps) {
  const { planPackage, currentUser } = metadata;
  const tabs: RouteTabWithContent[] = useMemo(
    () => [
      {
        name: "Plan Overview",
        href: createPlanPackageDetailsOverviewUrl(planPackageId),
        path: [planPackagePath, planPackageDetailsOverview],
        render: () => (
          <OverviewTab planPackageId={planPackageId} takeoffsId={planPackage.takeoff.id} currentUser={currentUser} />
        ),
      },
      {
        name: "Option Data",
        href: createPlanPackageDetailsOptionDataUrl(planPackageId),
        path: planPackageDetailsOptionData,
        render: () => <OptionDataTab metadata={metadata} />,
      },
      {
        name: "Activity",
        href: createPlanPackageDetailsActivityUrl(planPackageId),
        path: planPackageDetailsActivity,
        render: () => <ActivityTab metadata={metadata} />,
      },
      {
        name: "Documents",
        href: "#",
        path: "",
        render: () => null,
        disabled: "Documents tab coming soon...",
      },
    ],
    [currentUser, metadata, planPackage.takeoff.id, planPackageId],
  );

  return (
    <>
      <PlanPackageHeader planPackage={planPackage} tabs={tabs} />
      <TabContent tabs={tabs} contentXss={Css.pt3.$} />
    </>
  );
}
