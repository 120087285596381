import { Css, StaticField, Tag, useBreakpoint, useTestIds } from "@homebound/beam";
import { FormattedDate } from "src/components";
import { BillPage_BillFragment } from "src/generated/graphql-types";
import { useApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { approvalStatusToTagTypeMapper } from "src/utils";

export function BillDetailsTab({ bill }: { bill: BillPage_BillFragment }) {
  const tid = useTestIds({});
  const { sm } = useBreakpoint();
  const openApproval = useApprovalSuperDrawer();

  return (
    <div css={Css.df.fdc.w100.$}>
      <div css={Css.df.jcsb.w100.sm.gray700.my2.if(sm).fdc.gap1.else.fdr.$}>
        <div css={Css.df.fdc.$} {...tid.createdDate}>
          Bill Date
          <div css={Css.smSb.$}>
            <FormattedDate date={bill.billDate} dateFormatStyle="short" mdash />
          </div>
        </div>

        <div css={Css.df.fdc.$} {...tid.dueDate}>
          Due
          <div css={Css.smSb.$}>
            <FormattedDate date={bill.dueDate || undefined} dateFormatStyle="short" mdash />
          </div>
        </div>

        <div css={Css.df.fdc.$} {...tid.approvedDate}>
          Approved
          <div css={Css.smSb.$}>
            <FormattedDate date={bill.postedDate || undefined} dateFormatStyle="short" mdash />
          </div>
        </div>

        <div css={Css.df.fdc.$} {...tid.processedDate}>
          Processed
          <div css={Css.smSb.$}>
            <FormattedDate date={bill.paidDate || undefined} dateFormatStyle="short" mdash />
          </div>
        </div>
      </div>

      {bill.internalNote && <StaticField label="internal note" value={bill.internalNote || ""} labelStyle="above" />}

      {!!bill?.approval && (
        <div css={Css.mt3.$}>
          <div css={Css.sm.gray700.mb1.$}>Approvals</div>

          <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={bill.approval.id}>
            <div css={Css.dg.cg1.rg1.tiny.bgGray100.p2.aic.br8.if(sm).gtc("1fr").else.gtc("120px 150px 80px").$}>
              <span
                {...tid.billApproval}
                css={Css.blue700.cursor("pointer").$}
                onClick={() => openApproval(bill.approval?.id)}
              >
                {bill.name}
              </span>

              <div css={Css.df.fdr.$} {...tid.approver}>
                Approver &nbsp; <span css={Css.wbbw.$}>{bill.approval.approvers.first?.user.name}</span>
              </div>

              <Tag
                type={approvalStatusToTagTypeMapper(bill.approval)[0]}
                text={approvalStatusToTagTypeMapper(bill.approval)[1]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
