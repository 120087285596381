import {
  collapseColumn,
  CollapseToggle,
  column,
  Css,
  emptyCell,
  GridDataRow,
  GridSortConfig,
  GridTable,
  Palette,
  RightPaneLayout,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  Tag,
  Tooltip,
  useComputed,
  useGridTableApi,
  useRightPane,
} from "@homebound/beam";
import { format, isFuture, isPast, parseISO } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ProgressPill } from "src/components";
import {
  CompletedMilestoneFragment,
  DevelopmentBulkScheduleCohortDetailFragment,
  DevelopmentBulkScheduleDevelopmentDetailFragment,
  DevelopmentBulkSchedulePhasesDetailFragment,
  DevelopmentBulkScheduleProjectDetailFragment,
  DevelopmentBulkScheduleScheduleDetailFragment,
  DevelopmentBulkScheduleTaskDetailFragment,
  DevelopmentGlobalPhasesDetailFragment,
  Maybe,
  Order,
  SaveScheduleTaskInput,
  ScheduleStatus,
  Stage,
  TaskStatus,
  TaskStatusesFragment,
  useCompletedMilestonesByProjectQuery,
  useDevelopmentBulkScheduleProjectsQuery,
  useDevelopmentBulkScheduleSaveScheduleTaskMutation,
  useScheduleTasksByPhasesQuery,
} from "src/generated/graphql-types";
import { useDelayFlagModal } from "src/hooks/useDelayFlagModal";
import { SchedulePhaseIndicator } from "src/routes/developments/components/SchedulePhaseIndicator";
import { PhaseTaskListPane } from "src/routes/developments/schedule/components/PhaseTaskListPane";
import { StandaloneTaskDetailPane } from "src/routes/developments/schedule/components/StandaloneTaskDetailPane";
import { DevelopmentBulkScheduleFilter } from "src/routes/developments/schedule/DevelopmentBulkSchedulePage";
import { createProjectScheduleUrl } from "src/RouteUrls";
import { groupBy, mostFrequentNumber, queryResult, sortBy, unique } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { StringParam, useQueryParams } from "use-query-params";
import { DevelopmentBulkSchedulesGroupBy } from "../DevelopmentBulkScheduleFilterModal";

type DevelopmentBulkScheduleTableProps = {
  filter: DevelopmentBulkScheduleFilter;
  development: DevelopmentBulkScheduleDevelopmentDetailFragment;
  taskStatuses: TaskStatusesFragment[];
  refetchScheduleQuery: () => void;
};

export function DevelopmentBulkScheduleTable({
  development,
  filter,
  taskStatuses,
  refetchScheduleQuery,
}: DevelopmentBulkScheduleTableProps) {
  const query = useDevelopmentBulkScheduleProjectsQuery({
    variables: {
      projectFilter: {
        id: filter.project,
        development: [development.id],
        latestActiveStage: filter.latestActiveStage,
        cohort: filter.cohort,
      },
      stage: filter.latestActiveStage || [Stage.PreConPlanning, Stage.PreConExecution, Stage.Construction],
    },
  });

  return queryResult(query, {
    data: ({ projects, globalPhases }) => {
      return (
        <DevelopmentBulkScheduleTableView
          filter={filter}
          development={development}
          globalPhases={globalPhases}
          taskStatuses={taskStatuses}
          projects={projects}
          refetchScheduleQuery={refetchScheduleQuery}
        />
      );
    },
  });
}

type DevelopmentBulkScheduleTableViewProps = DevelopmentBulkScheduleTableProps & {
  projects: DevelopmentBulkScheduleProjectDetailFragment[];
  globalPhases: DevelopmentGlobalPhasesDetailFragment[];
};

function DevelopmentBulkScheduleTableView({
  development,
  filter,
  globalPhases,
  projects,
  taskStatuses,
  refetchScheduleQuery,
}: DevelopmentBulkScheduleTableViewProps) {
  const [{ cohortId, projectId, scheduleId, phaseId, taskId }, setQueryParams] = useQueryParams({
    cohortId: StringParam,
    projectId: StringParam,
    scheduleId: StringParam,
    phaseId: StringParam,
    taskId: StringParam,
  });

  const [sidePaneData, setSidePaneData] = useState<SidePaneData>();
  const [selectedGlobalPhases, setSelectedGlobalPhases] = useState<Map<string, ExpandedGlobalPhase>>(new Map());
  const { refetch: scheduleTaskByPhasesQuery } = useScheduleTasksByPhasesQuery({ skip: true });
  const { onCompleted } = useDelayFlagModal();
  const [saveScheduleTask] = useDevelopmentBulkScheduleSaveScheduleTaskMutation({ onCompleted });
  const tableApi = useGridTableApi<BulkScheduleRow>();
  const projectsByCohort = useMemo(() => groupBy(projects, ({ cohort }) => cohort!.id), [projects]);
  // Completed milestones
  const projectIds = useMemo(() => projects.map(({ id }) => id), [projects]);
  const { data: CompletedMilestonesData, refetch: refetchCompletedMilestonesQuery } =
    useCompletedMilestonesByProjectQuery({
      skip: !filter.taskStatusView,
      variables: { projects: projectIds },
    });

  const { openRightPane, closeRightPane } = useRightPane();

  const completedMilestones = useMemo(() => {
    if (CompletedMilestonesData?.scheduleTasks === undefined) return;

    const tasksByPhase = groupBy(CompletedMilestonesData?.scheduleTasks, ({ globalPhase }) => globalPhase?.name || "");
    // map and sort the phases to align with page layout
    return sortBy(
      Object.entries(tasksByPhase).map(([name, tasks]) => ({
        name,
        tasks,
        displayOrder: tasks[0].globalPhase?.displayOrder,
      })),
      ({ displayOrder }) => displayOrder,
      Order.Desc,
    ).flatMap(({ tasks }) => tasks);
  }, [CompletedMilestonesData]);

  const onTaskSave = useCallback(
    async (input: SaveScheduleTaskInput) => {
      const { data } = await saveScheduleTask({
        variables: { input: { tasks: [input] } },
      });
      // If dates changes
      if (data && (input.startDate || input.durationInDays || input.status || input.predecessorDependencies)) {
        // The apollo client should trigger a re-render after running the mutation, this logic will be re-visit on tech debt ticket
        for (const impacted of data.saveScheduleTasks.impactedScheduleTasks) {
          const impactedColumnValues = Array.from(selectedGlobalPhases.values());
          const impactedColumn = impactedColumnValues
            .flatMap(({ groupedTasks }) => groupedTasks)
            .find(({ name }) => impacted.name === name);
          if (impactedColumn) {
            // Find the impacted task and update the interval
            // The object result from the mutation does not run throughout our dateTypePolicy so we have to manually parse the string into a DateOnly
            impactedColumn.tasks = impactedColumn.tasks.map((t) =>
              t.id === impacted.id
                ? {
                    ...t,
                    status: impacted.status,
                    interval: {
                      startDate: new DateOnly(parseISO(impacted.interval.startDate as any as string)),
                      endDate: new DateOnly(parseISO(impacted.interval.endDate as any as string)),
                      durationInDays: impacted.interval.durationInDays,
                    },
                  }
                : t,
            );
          }
        }
        setSelectedGlobalPhases(new Map(Array.from(selectedGlobalPhases)));
      }

      // refresh the development with cohorts data to get progress and task status roll up.
      refetchScheduleQuery();
      await refetchCompletedMilestonesQuery();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetchCompletedMilestonesQuery, saveScheduleTask, selectedGlobalPhases],
  );

  const allPhases = useMemo(
    () => projects.flatMap(({ schedules }) => schedules).flatMap(({ phases }) => phases),
    [projects],
  );

  // fetch the global phase tasks and set local state
  const globalPhaseTasks = useCallback(
    async (gp: DevelopmentGlobalPhasesDetailFragment) => {
      const phases = allPhases.filter((ph) => ph.globalPhase.id === gp.id).map((ph) => ph.id);
      if (phases.nonEmpty) {
        // Get Tasks from selected globalPhase across projects
        const { data } = await scheduleTaskByPhasesQuery({ phases: unique(phases) });

        const tasksByName = groupBy(data.scheduleTasks, ({ name }) => name);
        const sortedTasks = sortBy(
          Object.entries(tasksByName).map(([name, tasks]) => ({
            name,
            tasks,
            sortOrder: mostFrequentNumber(tasks.map((t) => t.sortOrder)),
          })),
          ({ sortOrder }) => sortOrder,
        );
        if (sortedTasks.nonEmpty) {
          setSelectedGlobalPhases(
            selectedGlobalPhases.set(gp.id, {
              globalPhase: gp,
              groupedTasks: sortedTasks,
            }),
          );
        }
      }
    },
    [allPhases, scheduleTaskByPhasesQuery, selectedGlobalPhases],
  );

  // Update query params for opening side panes
  useEffect(
    () => {
      if (sidePaneData) {
        const cohort = development.cohorts?.find(({ id }) => {
          const cohortProjects = projectsByCohort[id];
          return cohortProjects?.some((p) => p.id === sidePaneData?.projectId);
        });
        setQueryParams(
          {
            cohortId: cohort?.id ?? cohortId,
            projectId: sidePaneData?.projectId,
            scheduleId: sidePaneData?.schedule?.id,
            phaseId: sidePaneData?.phase?.id,
            taskId: sidePaneData?.taskId,
          },
          "pushIn",
        );
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidePaneData],
  );

  // Open phase or task detail pane from query params
  useEffect(
    () => {
      if (cohortId && projectId && scheduleId && (phaseId || taskId)) {
        const cohortProjects = projectsByCohort[cohortId];
        const project = cohortProjects.find(({ id }) => id === projectId);
        const schedule = project?.schedules.find(({ id }) => id === scheduleId);
        const phase = schedule?.phases.find(({ id }) => id === phaseId);

        if (phase && taskId) {
          setAndOpenTaskPane({ taskId, phase, projectId, schedule });
        } else if (phase) {
          setAndOpenPhasePane(projectId, phase, schedule);
        }
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cohortId, projectId, scheduleId, phaseId, taskId, selectedGlobalPhases],
  );

  function setAndOpenPhasePane(
    projectId: string,
    phase: DevelopmentBulkSchedulePhasesDetailFragment,
    schedule?: DevelopmentBulkScheduleScheduleDetailFragment,
  ) {
    setSidePaneData({ projectId, phase, schedule });
  }

  function setAndOpenTaskPane(data: SidePaneData) {
    setSidePaneData(data);
  }

  const closeSidePane = useCallback(() => {
    setSidePaneData((prev) =>
      prev ? { ...prev, phase: undefined, taskId: undefined, schedule: undefined } : undefined,
    );
    closeRightPane();
  }, [closeRightPane]);

  const clientSideSort = false;

  const arrowColumn = useMemo(
    () =>
      collapseColumn<BulkScheduleRow>({
        header: (data, { row }) => !isTaskRow(data) && <CollapseToggle row={row} />,
        cohort: (data, { row }) => <CollapseToggle row={row} />,
        project: emptyCell,
        plan: () => <></>,
        taskStatus: () => <></>,
        w: "60px",
        sticky: "left",
      }),
    [],
  );

  const cohortNameColumn = useMemo(
    () =>
      column<BulkScheduleRow>({
        clientSideSort,
        expandableHeader: emptyCell,
        header: (data) =>
          !isTaskRow(data) && (
            <span css={Css.z1.$}>
              {filter.groupBy === DevelopmentBulkSchedulesGroupBy.Cohort ? "Cohort Name" : "Project Name"}
            </span>
          ),
        cohort: ({ id, name }) => ({
          content: () => {
            const cohortProjects = projectsByCohort[id];
            const length = cohortProjects.length;
            return (
              <>
                <div css={Css.gray900.smMd.mr1.$}>{name}</div>
                {length > 0 && <Tag text={length.toString()} />}
              </>
            );
          },
          value: "name",
        }),
        project: (data, { row }) => {
          const phases = data.schedules.flatMap(({ phases }) => phases);
          const completedPhases = phases.filter(
            ({ taskStatusRollup }) => taskStatusRollup === TaskStatus.Complete,
          ).length;
          return (
            <>
              <CollapseToggle row={row} compact />
              <Link css={Css.mx2.$} to={createProjectScheduleUrl(data.id)}>
                <Tooltip title={"View Project Schedule"} data-testid="projectScheduleTooltip" delay={500}>
                  {data.name}
                </Tooltip>
              </Link>
              {filter.taskStatusView && (
                <div css={Css.mla.$}>
                  <ProgressPill
                    changeColorOnCompleted
                    progress={(completedPhases / phases.length) * 100}
                    hideProgress
                  />
                </div>
              )}
            </>
          );
        },
        plan: () => <div css={Css.plPx(34).gray700.fw4.xs.$}>Plan</div>,
        taskStatus: ({ id, phases, completedMilestones }) => {
          // Getting CompletedMilestonesByProject ordered by sort order DESC then filtering by project id to get the most recent milestone completed

          const latestMilestoneCompleted = completedMilestones?.filter(
            (s) => s?.project?.id === id && s?.status === TaskStatus.Complete,
          )[0];
          return <div css={Css.plPx(34).truncate.fw4.$}>{latestMilestoneCompleted?.name}</div>;
        },
        sticky: "left",
        w: filter.taskStatusView ? "330px" : "240px",
      }),
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectsByCohort, filter.taskStatusView],
  );

  const taskColumnWidth = 130;

  const sortedGlobalPhases = useMemo(
    () =>
      stageOrder
        .filter((stage) => (filter.stage ? stage === filter.stage : true))
        .flatMap((s) => globalPhases.filter((gp) => gp.stage === s).sortBy((gp) => gp.displayOrder || Infinity)),
    [filter.stage, globalPhases],
  );

  const taskStatusCell = useCallback(
    (status?: TaskStatus | null | undefined) => {
      const taskStatus = taskStatuses.find((f) => f.code === status);
      const statusColor = taskStatus?.code ? taskStatusCodeColors[taskStatus?.code] : "transparent";

      return {
        content: () => taskStatus?.name,
        css: Css.bw("4px").bt.bc(statusColor).$,
      };
    },
    [taskStatuses],
  );

  // We will use this to only query the global phases once and then use the cached result when the filter changes
  const runOnceAsync = useRunOnceAsync<DevelopmentGlobalPhasesDetailFragment>();

  const columns = useMemo(
    () => {
      return [
        arrowColumn,
        cohortNameColumn,
        ...sortedGlobalPhases.flatMap((gp) => {
          return column<BulkScheduleRow>({
            id: gp.id,
            expandableHeader: gp.name,
            hideOnExpand: true,
            header: emptyCell,
            cohort: emptyCell,
            project: ({ id: projectId, schedules }, { expanded }) => {
              const schedule = schedules.find((s) => s.phases.find((sp) => sp.globalPhase.id === gp.id));
              const phase = schedule?.phases.find((sp) => sp.globalPhase.id === gp.id);
              const endDate = phase?.interval?.endDate.date;
              const isInPast = !!(endDate && isPast(endDate));
              return {
                content: () => {
                  return (
                    <SchedulePhaseIndicator
                      endDate={endDate}
                      baseline={phase?.baselineInterval?.endDate.date}
                      status={phase?.taskStatusRollup as TaskStatus}
                      scheduleSetting={schedule?.scheduleSetting}
                      onClick={() => phase?.id && setAndOpenPhasePane(projectId, phase, schedule)}
                    />
                  );
                },
                // Give undefined endDates the year of 2500 in order to put them last
                sortValue: endDate || farFuture,
                css: Css.if(isInPast).bgGray100.gray600.$,
              };
            },
            plan: ({ phases }) => {
              const date = phases.find((ph) => ph.globalPhase.id === gp.id)?.baselineInterval?.endDate;
              const formattedDate = formatDate(date);
              return {
                content: () => <span css={Css.fw5.$}>{formattedDate}</span>,
                value: formattedDate,
              };
            },
            taskStatus: ({ phases }) => {
              const phase = phases.find((ph) => ph.globalPhase.id === gp.id);
              return taskStatusCell(phase?.taskStatusRollup);
            },
            expandColumns: async () => {
              // need to keep this async fetch call inside expanded columns in order for Beam implementation to work
              await runOnceAsync(gp.id, globalPhaseTasks, gp);
              const groupedTasksByName = selectedGlobalPhases.get(gp.id)?.groupedTasks ?? [];
              const groupedTasksWithMilestones = groupedTasksByName.map((phase) => ({
                ...phase,
                isMilestone: phase.tasks.some((t) => t.isGlobalMilestoneTask),
              }));
              const phaseHasMilestones = groupedTasksWithMilestones.some(({ isMilestone }) => isMilestone);

              if (filter.milestonesOnly && !phaseHasMilestones) {
                return [
                  column<BulkScheduleRow>({
                    expandableHeader: emptyCell,
                    id: `${gp.id}_empty`,
                    w: `200px`,
                    header: emptyCell,
                    cohort: emptyCell,
                    project: () => ({
                      content: () => <span>There aren't any milestone tasks</span>,
                      sortValue: farFuture,
                    }),
                    plan: emptyCell,
                    taskStatus: emptyCell,
                  }),
                ];
              }

              if (groupedTasksByName.isEmpty) {
                return [
                  column<BulkScheduleRow>({
                    expandableHeader: emptyCell,
                    id: `${gp.id}_empty`,
                    w: `200px`,
                    header: emptyCell,
                    cohort: emptyCell,
                    project: () => ({
                      content: () => <span>There aren't any tasks</span>,
                      sortValue: farFuture,
                    }),
                    plan: emptyCell,
                    taskStatus: emptyCell,
                  }),
                ];
              }

              return groupedTasksWithMilestones
                .filter(({ isMilestone }) => (filter.milestonesOnly ? isMilestone : true))
                .map(({ tasks, name, isMilestone }) =>
                  column<BulkScheduleRow>({
                    expandableHeader: emptyCell,
                    id: `${gp.id}_${name}`,
                    w: `200px`,
                    header: () => ({
                      content: () => name,
                      tooltip: name,
                      value: name,
                      css: Css.if(isMilestone).gray900.fwb.$,
                    }),
                    cohort: emptyCell,
                    project: ({ id, schedules }) => {
                      const schedule = schedules.find((s) => s.phases.find((sp) => sp.globalPhase.id === gp.id));
                      const phase = schedule?.phases.find(({ globalPhase }) => globalPhase.id === gp.id);
                      const projectTasksByName = groupedTasksByName.find((t) => t.name === name);
                      const projectTask = projectTasksByName?.tasks.find((task) => task.project?.id === id);
                      // We want the order to be the last phase's end date, so we force it here.
                      const sortValue: Date = projectTask?.interval.endDate.date || farFuture;
                      // Adding a helper to filter the stale tasks, this logic will be revisited in ticket SC-29656
                      const staleTask = projectTasksByName?.tasks
                        .filter((t) => t.interval.endDate < new Date() && t.status !== TaskStatus.Complete)
                        .find((task) => task.project?.id === id);

                      const endDate = projectTask?.interval.endDate.date;
                      const isInPast = !!(endDate && isPast(endDate));

                      return {
                        content: () => {
                          // if we have a stale filter, hide the non-stale tasks
                          if (filter.stale && !staleTask) {
                            return " ";
                          }
                          return (
                            <div key={projectTask?.id} css={Css.wPx(taskColumnWidth).$}>
                              {projectTask ? (
                                <SchedulePhaseIndicator
                                  scheduleTask={projectTask}
                                  endDate={projectTask?.interval.endDate.date}
                                  baseline={projectTask?.baselineInterval.endDate.date}
                                  scheduleIsLocked={schedule?.status === ScheduleStatus.Completed}
                                  status={projectTask?.status}
                                  scheduleSetting={schedule?.scheduleSetting}
                                  onClick={() =>
                                    setAndOpenTaskPane({ projectId: id, taskId: projectTask?.id, phase, schedule })
                                  }
                                  onSave={onTaskSave}
                                />
                              ) : (
                                "--"
                              )}
                            </div>
                          );
                        },
                        sortValue,
                        css: Css.if(isInPast).bgGray100.gray600.$,
                      };
                    },
                    plan: ({ id }) => {
                      const projectTask = tasks.find(({ project }) => project?.id === id);
                      const date = projectTask?.baselineInterval?.endDate;
                      const isInFuture = !!(date && isFuture(date));
                      const formattedDate = formatDate(date);
                      return {
                        content: () => (
                          <span css={Css.wPx(taskColumnWidth).if(isInFuture).gray900.fw5.$}>{formattedDate}</span>
                        ),
                        value: formattedDate,
                      };
                    },
                    taskStatus: ({ id }) => {
                      const projectTask = tasks.find(({ project }) => project?.id === id);
                      return taskStatusCell(projectTask?.status);
                    },
                  }),
                );
            },
            w: "136px",
          });
        }),
      ];
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      arrowColumn,
      cohortNameColumn,
      sortedGlobalPhases,
      taskStatusCell,
      globalPhaseTasks,
      selectedGlobalPhases,
      filter.milestonesOnly,
      filter.stale,
      onTaskSave,
    ],
  );

  const lastColumn = columns[columns.length - 1];

  const sortingConfig: GridSortConfig = {
    on: "client",
    initial: [lastColumn.id!, "ASC"],
  };

  const rows = useMemo(() => {
    return createBulkScheduleRows(development, projectsByCohort, cohortId, filter, completedMilestones);
  }, [development, projectsByCohort, cohortId, filter, completedMilestones]);

  // Calculate activeCellId based on query params
  const cellId = useComputed(() => {
    const phases = projects.flatMap(({ schedules }) => schedules).flatMap(({ phases }) => phases);

    const phase = phases.find(({ id }) => id === phaseId);

    if (taskId) {
      const phaseGroupings = Array.from(selectedGlobalPhases.values());
      const task = phaseGroupings
        .flatMap(({ groupedTasks }) => groupedTasks)
        .find(({ tasks }) => tasks.some(({ id }) => id === taskId));
      return `project_${projectId}_${phase?.globalPhase.id}_${task?.name}`;
    }

    if (phase) {
      return `project_${projectId}_${phase?.globalPhase.id}`;
    }
  }, [projectId, phaseId, taskId, selectedGlobalPhases]);

  useEffect(
    () => {
      if (sidePaneData?.phase && !sidePaneData?.taskId) {
        openRightPane({
          content: (
            <PhaseTaskListPane
              onClose={closeSidePane}
              phase={sidePaneData.phase}
              openTaskPane={(taskId) =>
                setAndOpenTaskPane({
                  projectId: sidePaneData.projectId,
                  phase: sidePaneData.phase,
                  taskId: taskId,
                  schedule: sidePaneData.schedule,
                })
              }
              stale={filter.stale}
            />
          ),
        });
      }

      if (sidePaneData?.taskId && sidePaneData.schedule) {
        openRightPane({
          content: (
            <StandaloneTaskDetailPane
              taskId={sidePaneData.taskId}
              scheduleId={sidePaneData.schedule.id}
              stage={sidePaneData.schedule.stage}
              onClose={closeSidePane}
              onSave={(changedValue) => onTaskSave({ id: sidePaneData.taskId, ...changedValue })}
              taskPaneState={{
                scrollIntoViewType: undefined,
                tab: "details",
                taskPaneId: sidePaneData.taskId,
              }}
            />
          ),
        });
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidePaneData],
  );

  return (
    <ScrollableContent virtualized>
      <div css={Css.mr3.h100.$}>
        <RightPaneLayout>
          <GridTable
            as="virtual"
            activeCellId={cellId}
            columns={columns}
            rows={rows}
            rowStyles={rowStyles}
            sorting={sortingConfig}
            style={{ cellHighlight: true, allWhite: true, grouped: true }}
            stickyHeader
            persistCollapse={"test"}
            api={tableApi}
          />
        </RightPaneLayout>
      </div>
    </ScrollableContent>
  );
}

function createBulkScheduleRows(
  development: DevelopmentBulkScheduleDevelopmentDetailFragment,
  projectsByCohort: Record<string, DevelopmentBulkScheduleProjectDetailFragment[]>,
  cohortId: string | null | undefined,
  filter: DevelopmentBulkScheduleFilter,
  completedMilestones: Maybe<CompletedMilestoneFragment[]>,
): GridDataRow<BulkScheduleRow>[] {
  const cohorts = development.cohorts || [];

  const bulkScheduleRows: CohortRow[] = sortBy(
    cohorts.filter(({ id }) => {
      const projects = projectsByCohort[id];
      return (
        projects &&
        // return projects/cohorts that are filtered
        projects.some((project: DevelopmentBulkScheduleProjectDetailFragment) => {
          if (!filter.cohort && !filter.project) return true;
          return filter.project?.includes(project.id) || (project.cohort && filter.cohort?.includes(project.cohort.id));
        })
      );
    }),
    ({ name }) => name,
  ).map((c) => ({
    kind: "cohort",
    id: c.id,
    data: c,
    initCollapsed: c.id !== cohortId,
    children: [
      ...projectsByCohort[c.id].map((p) => ({
        kind: "project" as const,
        id: p.id,
        data: p,
        initCollapsed: true,
        children: [
          filter.taskStatusView
            ? {
                kind: "taskStatus",
                id: `${p.id}-taskStatus`,
                data: { id: p.id, phases: p.schedules.reduce(phasesFromSchedules, []), completedMilestones },
              }
            : {
                kind: "plan",
                id: `${p.id}-plan`,
                data: { id: p.id, phases: p.schedules.reduce(phasesFromSchedules, []) },
              },
        ],
      })),
    ],
  }));
  return [
    { kind: "expandableHeader" as const, id: "expandableHeader", data: undefined },
    simpleHeader,
    ...(filter.groupBy === DevelopmentBulkSchedulesGroupBy.Cohort
      ? bulkScheduleRows
      : bulkScheduleRows.flatMap((c) => c.children)),
  ];
}

type SidePaneData = {
  taskId?: string;
  phase?: DevelopmentBulkSchedulePhasesDetailFragment | undefined;
  projectId: string;
  schedule?: DevelopmentBulkScheduleScheduleDetailFragment;
};

// Beam does not currently fully support multiple header rows, so this is an attempt to make the current
// sticky functionality work with what's currently available. Support will be added in https://app.shortcut.com/homebound-team/story/22151/allow-for-multiple-header-rows
type HeaderRowData = ScheduleTaskDetailRow | undefined;

type GroupedScheduleTask = { name: string; tasks: DevelopmentBulkScheduleTaskDetailFragment[] };
type ExpandedGlobalPhase = {
  globalPhase: DevelopmentGlobalPhasesDetailFragment;
  groupedTasks: GroupedScheduleTask[];
};
type ScheduleTaskDetailRow = { taskRow: boolean };
type ExpandableHeaderRow = { id: string; kind: "expandableHeader"; data: HeaderRowData };
type HeaderRow = { id: string; kind: "header"; data: HeaderRowData };
type CohortRow = {
  kind: "cohort";
  id: string;
  data: DevelopmentBulkScheduleCohortDetailFragment;
  children: ProjectRow[];
};
type ProjectRow = {
  kind: "project";
  id: string;
  data: DevelopmentBulkScheduleProjectDetailFragment;
};
type BaselineRow = {
  kind: "plan";
  id: string;
  data: { id: string; phases: DevelopmentBulkSchedulePhasesDetailFragment[] };
};
type TaskStatusRow = {
  kind: "taskStatus";
  id: string;
  data: {
    id: string;
    phases: DevelopmentBulkSchedulePhasesDetailFragment[];
    completedMilestones: CompletedMilestoneFragment[];
  };
};

type BulkScheduleRow = ExpandableHeaderRow | HeaderRow | CohortRow | ProjectRow | BaselineRow | TaskStatusRow;

const rowStyles: RowStyles<BulkScheduleRow> = {
  // TODO: Remove when Beam supports maximum lines in header rows.
  cohort: { cellCss: Css.aic.bgWhite.smMd.gray900.px1.py1.fw7.$ },
  project: { cellCss: Css.aic.xs.gray600.px1.py1.mhPx(40).$ },
  plan: { cellCss: Css.fw4.px1.py1.$ },
  taskStatus: { cellCss: Css.fw4.px1.py1.$ },
};

const formatDate = (date: DateOnly | undefined) => {
  if (date && date.date) {
    return date ? format(date.date, "MMM dd") : "--";
  }
};

function isTaskRow(data: HeaderRowData) {
  return data?.taskRow;
}

// Reducers to be used like development.cohorts?.reduce(projectFromCohorts, [])
const phasesFromSchedules: (
  a: DevelopmentBulkSchedulePhasesDetailFragment[],
  b: DevelopmentBulkScheduleScheduleDetailFragment,
) => DevelopmentBulkSchedulePhasesDetailFragment[] = (a, b) => {
  return [...a, ...b.phases];
};

const stageOrder = [Stage.PreConPlanning, Stage.PreConExecution, Stage.Construction];
const farFuture = new Date(2500, 0);

// TODO - consolidate all color mappings for schedules.
const taskStatusCodeColors = {
  [TaskStatus.NotStarted]: Palette.Gray700,
  [TaskStatus.InProgress]: Palette.Blue600,
  [TaskStatus.Complete]: Palette.Green500,
  [TaskStatus.Delayed]: Palette.Red600,
  [TaskStatus.OnHold]: "transparent",
};

// Hook to run a callback just once based on the argument using a cache to track if it has run.
function useRunOnceAsync<T>() {
  const cache = useRef<string[]>([]);
  return async (key: string, callback: (args: T) => Promise<void>, args: T) => {
    if (!cache.current.includes(key)) {
      cache.current.push(key);
      await callback(args);
    }
  };
}
