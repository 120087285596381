import { Css, useComputed } from "@homebound/beam";
import { OptionSection } from "./OptionSection";
import { GroupedOptionsProps } from "./OptionsByTypeSection";

export function OptionsByOptionGroupSection(props: GroupedOptionsProps) {
  const { groupedRpos: rposGroupedByOptionGroup, formState, recomputeCosts } = props;
  const selectedRpos = useComputed(
    () => formState.value.readyPlanOptions.map((os) => os.readyPlanOptionId).compact(),
    [formState],
  );
  return (
    <div css={Css.p4.bgWhite.br12.aic.bshBasic.addIn("& > div:last-child", Css.bsn.$).$}>
      {Object.entries(rposGroupedByOptionGroup).map(([OptGroupName, optGroupRpos]) => (
        <div key={[OptGroupName, ...optGroupRpos.map((delta) => delta.rpo.id)].join("-")}>
          <div css={Css.base.gray700.mt2.$}>{OptGroupName}</div>
          {optGroupRpos
            .sortBy((data) => data.rpo.order ?? Number.POSITIVE_INFINITY)
            .map((data) => {
              const conflicts = [
                ...data.rpo.optionConflicts.filter((opt) => selectedRpos.includes(opt.id)),
                ...data.rpo.optionConflictChildren.filter((opt) => selectedRpos.includes(opt.id)),
              ].map((opt) => opt.displayName);
              // Cannot select until these items are selected
              const missingPreReqs = [
                ...data.rpo.optionPrerequisites.filter(
                  (preReqOpt) =>
                    // Find any prereq not yet selected,
                    !selectedRpos.includes(preReqOpt.id) &&
                    !preReqOpt.optionGroup.options.some(
                      (preReqGroupRpo) =>
                        // And the prereq's optionGroup
                        // does not yet have a selected opt that already
                        // satisfies this RPO's prereqs
                        selectedRpos.includes(preReqGroupRpo.id) &&
                        data.rpo.optionPrerequisites.map((requiredRpo) => requiredRpo.id).includes(preReqGroupRpo.id),
                    ),
                ),
              ].map((opt) => opt.displayName);

              return (
                <OptionSection
                  key={data.rpo.id}
                  configData={data}
                  conflicts={conflicts}
                  missingPreReqs={missingPreReqs}
                  formState={formState}
                  recomputeCosts={recomputeCosts}
                  disabled={missingPreReqs.nonEmpty || conflicts.nonEmpty}
                />
              );
            })}
        </div>
      ))}
    </div>
  );
}
