import {
  Avatar,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridStyle,
  GridTable,
  Palette,
  useTestIds,
} from "@homebound/beam";
import { Fragment } from "react";
import { formatDate } from "src/components";
import { Card } from "src/components/Card";
import { History, HistoryFeedInfoFragment } from "src/generated/graphql-types";
import { sanitizeHtml, sortBy } from "src/utils";

type HistoryProps = {
  historyItems: History[];
  // defaults to true
  renderAsCard?: boolean;
};

export function HistoryFeed({ historyItems, renderAsCard = true }: HistoryProps) {
  const testIds = useTestIds({}, "historyFeed");

  const Render = renderAsCard ? Card : Fragment;

  return (
    <Render>
      {historyItems.isEmpty ? (
        <div {...testIds.empty} css={Css.mt1.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          No history available
        </div>
      ) : (
        <div {...testIds}>
          <GridTable
            rows={createHistoryRows(sortBy(historyItems, (historyItem) => -historyItem.recordedAt))}
            columns={historyColumns}
            style={compact}
          />
        </div>
      )}
    </Render>
  );
}

const compact: GridStyle = {
  rootCss: Css.dig.xs.gray900.w100.$,
  cellCss: Css.py(0.5).sm.$,
  rowHoverColor: Palette.Gray200,
  betweenRowsCss: Css.bb.bcGray200.mb1.$,
};

type HistoryRow = { kind: "data"; id: string; data: HistoryFeedInfoFragment };

export const historyColumns: GridColumn<HistoryRow>[] = [
  column<HistoryRow>({
    data: (data) => {
      const titleSplit = data.title.split(" ");
      const name = titleSplit[0] === "Blueprint" ? titleSplit[0] : [titleSplit[0], titleSplit[1]].join(" ");
      return <Avatar name={name} src={data.avatar} />;
    },
    w: "50px",
  }),
  column<HistoryRow>({
    data: (data) => {
      const hasHTMLContent = /<\/?[a-z][\s\S]*>/i.test(data.subtitle || "");
      return (
        <div css={Css.gray700.mb1.$}>
          <div css={Css.absolute.right0.topPx(4).gray700.xsSb.$}>{formatDate(data.recordedAt, "short", "short")}</div>
          {data.userName && (
            <div css={Css.xs.mb1.$}>
              <span>{data.userName}</span>
            </div>
          )}
          <div css={Css.mb1.xsMd.$}>{data.title}</div>
          {hasHTMLContent ? (
            <div
              css={Css.xs.wbbw.pb2.df.fdc.aifs.$}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.subtitle || "") }}
            />
          ) : (
            <div css={Css.xs.wbbw.$}>{data.subtitle}</div>
          )}
        </div>
      );
    },
  }),
];

function createHistoryRows(historyItems: History[]): GridDataRow<HistoryRow>[] {
  return historyItems.map((history, index) => ({ kind: "data", id: index.toString(), data: history }));
}
