import { Css, Palette, px } from "@homebound/beam";
import { RouteProps } from "react-router-dom";
import { Footer } from "src/components/Footer";
import { HomeboundLogo } from "src/components/HomeboundLogo";
import { Icon } from "src/components/Icon";
import { getStage, isContentPreview } from "src/context";
import { getQueryStringValue } from "src/utils/queryString";
import { LoginButton } from "./LoginButton";
import { UnauthedHeader } from "./UnauthedHeader";

export type SignInProps = RouteProps;

export function SignIn(props: SignInProps) {
  const errorMessage = getQueryStringValue(props.location, "signInError");

  const backgroundImageCss = {
    background: 'url("/images/sign-in.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const stage = getStage();
  let environmentName = stageNameMap[stage];

  if (isContentPreview()) {
    environmentName = window.location.host.split(".")[0];
  }

  function getErrorMessage() {
    return (
      <div css={Css.bgRed100.fs0.w100.sticky.z999.h(px(48)).df.aic.mt2.$}>
        <div css={Css.mx3.df.$}>
          <div css={Css.mr1.mla.$}>
            <Icon icon="alertError" inc={3} color={Palette.Gray800} />{" "}
          </div>
          <div css={Css.gray800.$}>{errorMessage}</div>
        </div>
      </div>
    );
  }

  return (
    <div css={{ ...Css.df.fdc.pt4.px4.mvh100.$, ...backgroundImageCss }}>
      <div css={Css.h("30vh").$}>
        <UnauthedHeader />
        {errorMessage && getErrorMessage()}
      </div>
      <div css={Css.fg1.asc.h("60vh").$}>
        <div
          css={
            Css.df.fdc.aic.jcsb
              .h("280px")
              .bgColor("rgba(254,254,254,0.8)")
              .boxShadow("0px 4px 30px rgba(0, 0, 0, 0.35)")
              .wPx(580)
              .ifSm.wPx(360).$
          }
        >
          <div css={Css.w100.df.fdr.jcfs.p2.mb1.$}>
            <HomeboundLogo width={120} />
          </div>
          <div>
            <div css={Css.py2.xl3.tac.$}>
              <div>Start building.</div>
            </div>
            <LoginButton />
          </div>
          <div css={Css.w100.df.fdr.jcsb.p2.mt5.xs.$}>
            {environmentName ? <span>{environmentName}</span> : <span />}
            <a href="mailto:product-support@homebound.com">
              <span css={Css.gray800.$}>Contact Product Ops</span>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const stageNameMap: Record<string, string> = {
  local: "Localhost",
  dev: "Development",
  qa: "QA",
};
