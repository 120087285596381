import { BoundNumberField, BoundSelectField, Button, Css, IconButton, useComputed } from "@homebound/beam";
import { ListFieldState, ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  IncrementalCollectionOp,
  IncrementalSaveItemPlanTaskMappingInput,
  Named,
  useTaskCatalogFormMaterialsQuery,
} from "src/generated/graphql-types";

type MaterialsAndCostFormDetailsProps = {
  formState: ListFieldState<IncrementalSaveItemPlanTaskMappingInput>;
};

export function MaterialsAndCostFormDetails({ formState }: MaterialsAndCostFormDetailsProps) {
  const { data } = useTaskCatalogFormMaterialsQuery();
  const materialsInputs = useComputed(() => {
    return formState.rows
      .filter((row) => row.op.value === IncrementalCollectionOp.Include)
      .map((item, i, rows) => (
        <MaterialInput
          key={item.value.id ?? `newItem:${i}`}
          formState={item}
          items={data?.items}
          unitsOfMeasure={data?.unitsOfMeasure}
          isLast={i === rows.length - 1}
        />
      ));
  }, [formState.rows, data?.items]);
  return (
    <Observer>
      {() => (
        <div>
          {materialsInputs}
          <Button
            label="Add another material"
            onClick={() => formState.add({ op: IncrementalCollectionOp.Include })}
            variant="tertiary"
            icon="plus"
          />
        </div>
      )}
    </Observer>
  );
}

type MaterialInputProps = {
  formState: ObjectState<IncrementalSaveItemPlanTaskMappingInput>;
  items?: Named[];
  unitsOfMeasure?: Named[];
  isLast?: boolean;
};

function MaterialInput({ formState, items, unitsOfMeasure, isLast }: MaterialInputProps) {
  return (
    <div data-testid="materialInput" css={Css.pb4.if(!isLast).bb.bcGray300.mb4.$}>
      <div css={Css.df.aife.$}>
        <BoundSelectField
          label="Item Code (Material)"
          labelSuffix={{ required: "*" }}
          field={formState.itemId}
          options={items ?? []}
          required
          hideErrorMessage
        />
        <span css={Css.p1.pbPx(4).$}>
          <IconButton
            icon="trash"
            onClick={() => formState.op.set(IncrementalCollectionOp.Delete)}
            label="Remove material from task"
          />
        </span>
      </div>
      <div css={Css.mt3.df.jcsb.aife.$}>
        <span css={Css.f3.mr1.$}>
          <BoundNumberField label="Expected Labor Cost" field={formState.estimatedLaborCostInCents} type="cents" />
        </span>
        <span css={Css.f2.ml1.$}>
          <BoundSelectField
            label="Unit"
            labelStyle="hidden"
            field={formState.unitOfMeasureId}
            options={unitsOfMeasure ?? []}
            getOptionLabel={getOptionLabel}
            placeholder="Unit of Measure"
          />
        </span>
      </div>
    </div>
  );
}

function getOptionLabel({ name }: Named) {
  const nonPluralizedLabels: Record<string, string> = {
    Hours: "Hour",
    Days: "Day",
    Weeks: "Week",
    Months: "Month",
    Years: "Year",
  };
  if (nonPluralizedLabels[name]) {
    return nonPluralizedLabels[name];
  } else {
    return name;
  }
}
