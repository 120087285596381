import {
  Button,
  Css,
  FormLines,
  StaticField,
  SuperDrawerContent,
  SuperDrawerHeader,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { RoomTypeBoundMultiSelectField } from "src/components/autoPopulateSelects/RoomTypeBoundMultiSelectField";
import {
  AdminLocationDrawer_AssociatedLocationFragment,
  AdminLocationDrawer_LocationFragment,
  LocationInput,
  useAdminLocationDrawerQuery,
  useSaveAdminLocationDrawerMutation,
} from "src/generated/graphql-types";
import { NotFound } from "src/routes/NotFound";
import { queryResult } from "src/utils";

export function LocationDrawer({ id }: { id: string }) {
  const query = useAdminLocationDrawerQuery({ variables: { id } });
  return (
    <>
      <SuperDrawerHeader title={query.data?.location.name ?? ""} />
      <SuperDrawerContent>
        {queryResult(query, ({ location }) => {
          if (!location) return <NotFound error={{ message: "Unable to find location ID " + id, name: "Not Found" }} />;
          return <LocationDrawerContent location={location} />;
        })}
      </SuperDrawerContent>
    </>
  );
}

function LocationDrawerContent({ location }: { location: AdminLocationDrawer_LocationFragment }) {
  const [save] = useSaveAdminLocationDrawerMutation();
  const { openDrawerDetail } = useSuperDrawer();
  const formState = useFormState({
    config: formConfig,
    init: {
      input: location,
      map: (l) => ({ roomTypes: l.roomTypes.map((rt) => rt.id) }),
    },
    autoSave: async (fs) => {
      await save({ variables: { input: { locations: [{ id: location.id, ...fs.changedValue }] } } });
      fs.commitChanges();
    },
  });

  return (
    <>
      <FormLines width="full">
        <h2 css={Css.xlMd.$}>Properties</h2>
        <StaticField label={"Code"} value={location.effectiveCode} />
        <StaticField label="Type" value={location.type.name} />
        <RoomTypeBoundMultiSelectField field={formState.roomTypes} current={location.roomTypes} />
      </FormLines>
      <h2 css={Css.xlMd.mt4.mb2.$}>Hierarchy</h2>
      <ul
        css={
          Css.listReset.df.aic.gap1.fww.smMd.addIn(
            "> li + li",
            Css.addIn(":before", Css.add("content", '" > "').db.$).$,
          ).$
        }
      >
        {[...location.parents].reverse().map((l, idx) => (
          <li key={l.id} css={Css.df.aic.gap1.$}>
            <AssociatedLocation
              location={l}
              onClick={() => openDrawerDetail({ content: <LocationDrawer id={l.id} /> })}
            />
          </li>
        ))}
        <li css={Css.smMd.df.aic.gap1.$}>{location.name} (This location)</li>
      </ul>

      <h2 css={Css.xlMd.mt4.mb2.$}>Locations Within</h2>
      {location.locations.isEmpty ? (
        <p css={Css.gray700.$}>No locations within</p>
      ) : (
        <ul css={Css.listReset.gap1.smMd.$}>
          {location.locations.map((l, idx) => (
            <li key={l.id} css={Css.df.gap1.$}>
              <AssociatedLocation
                location={l}
                onClick={() => openDrawerDetail({ content: <LocationDrawer id={l.id} /> })}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

type AssociatedLocationProps = {
  location: AdminLocationDrawer_AssociatedLocationFragment;
  onClick: VoidFunction;
};

function AssociatedLocation({ location, onClick }: AssociatedLocationProps) {
  return (
    <span css={Css.df.aic.gapPx(4).$}>
      <Button variant="text" label={location.name} onClick={onClick} />
      <span css={Css.gray700.sm.$}>({location.type.name})</span>
    </span>
  );
}

const formConfig: ObjectConfig<LocationInput> = {
  roomTypes: { type: "value" },
};
