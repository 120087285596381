import { DateOperation } from "src/generated/graphql-types";

/** Creates common date filter values for the "single" date filter.
 * Removes Between as that requires a bespoke "DateRangeFilter" */
const { Between, ...supportedDateFilterOperations } = DateOperation;
// Create an enum-esque object that does not include the Between operations.
export const DateFilterOperation = supportedDateFilterOperations;
const dateOperationToName: Record<Exclude<DateOperation, DateOperation.Between>, string> = {
  [DateOperation.Before]: "Before",
  [DateOperation.On]: "On",
  [DateOperation.After]: "After",
  [DateOperation.OnOrBefore]: "On or Before",
  [DateOperation.OnOrAfter]: "On or After",
};
// Create an array of operations that can be used for Beam's `dateFilter` helper.
export const dateFilterOperations = Object.keys(dateOperationToName).map((o) => ({
  // @ts-ignore - we know this is a valid DateOperation, but `Object.keys` doesn't know that. Tried using `safeKeys`, but there are "order of import" issues causing test failures
  label: dateOperationToName[o],
  value: o,
}));
