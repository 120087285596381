import { Chip } from "@homebound/beam";
import { differenceInBusinessDays } from "date-fns";
import { ProgressSummaryStatus, ScheduleSettingDetailsFragment, TaskStatus } from "src/generated/graphql-types";
import { getBusinessDays } from "src/routes/projects/schedule-v2/table/utils";

type BaselineChipProps = {
  baselineDelta:
    | { actualDate: Date | undefined | null; baselineDate: Date | undefined | null }
    | {
        actualDuration: number | undefined | null;
        baselineDuration: number | null | undefined;
        baselineDate: Date | undefined | null;
      };
  status?: TaskStatus | ProgressSummaryStatus;
  scheduleSetting?: ScheduleSettingDetailsFragment | null | undefined;
};

export function BaselineChip({ baselineDelta, status, scheduleSetting }: BaselineChipProps) {
  const today = new Date();
  const completed = status === TaskStatus.Complete;
  const businessDays = getBusinessDays(scheduleSetting?.workingDays ?? []);
  const exceptions = scheduleSetting?.exceptions;
  let daysDiff = 0;

  if ("actualDate" in baselineDelta) {
    if (baselineDelta?.actualDate && baselineDelta?.baselineDate)
      daysDiff = differenceInBusinessDays(baselineDelta.actualDate, baselineDelta.baselineDate, {
        businessDays,
        exceptions,
      });
  } else {
    if (baselineDelta?.actualDuration && baselineDelta?.baselineDuration)
      daysDiff = baselineDelta.actualDuration - baselineDelta.baselineDuration;
  }

  const delay = baselineDelta?.baselineDate && baselineDelta?.baselineDate <= today && !completed && daysDiff > 0;
  const futureDelay = baselineDelta?.baselineDate && baselineDelta?.baselineDate > today && !completed && daysDiff > 0;

  return (
    <>{(delay || futureDelay) && <Chip type={futureDelay ? "caution" : "warning"} text={`${daysDiff}d`}></Chip>}</>
  );
}
