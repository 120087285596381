import { Css, Tag, Tooltip, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { Icon, Price } from "src/components";
import { billStatusTagMapper } from "src/components/detailPane/bill/utils";
import { FormGap } from "src/components/FormGap";
import { TaskDetailPane_BillFragment } from "src/generated/graphql-types";

export function TaskBills({ bills }: { bills: TaskDetailPane_BillFragment[] }) {
  const tid = useTestIds({});

  return (
    <>
      <span css={Css.df.aic.fdr.gap1.sm.gray700.my2.$}>
        Bills
        <Tooltip title="Bills are autogenerated once a task is marked complete.">
          <Icon icon="alertInfo" pxSize={13} />
        </Tooltip>
      </span>
      {bills.nonEmpty ? (
        bills.map((b) => {
          const [type, text] = billStatusTagMapper(b);
          return (
            <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={b.id}>
              <div css={Css.dg.gtc("55px 65px 13px 60px 120px").cg1.rg1.tiny.bgGray100.p2.aic.br8.$}>
                <Link target="_blank" to={b.blueprintUrl.path} {...tid.billNumber}>
                  #{b.tradePartnerNumber}
                </Link>
                <div css={Css.wbbw.$} {...tid.tradePartner}>
                  {b.tradePartner.name}
                </div>
                <FormGap />
                <div css={Css.tinySb.$} {...tid.billTotal}>
                  <Price valueInCents={b.billedInCents} />
                </div>
                <div css={Css.maxwPx(120).tac.$}>
                  <Tag type={type} text={text} />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div {...tid.noBills} css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
          There are no bills associated to this task
        </div>
      )}
    </>
  );
}
