import { ApolloError } from "@apollo/client";
import { Css, useTestIds } from "@homebound/beam";
import { ErrorMessages, SuggestedPaths } from "src/components";

export function NotFound({ error }: { error?: ApolloError | Error }) {
  const tid = useTestIds({});
  return (
    <div css={Css.tac.mt4.wPx(600).mxa.$} {...tid.notFoundPage}>
      <img src="/images/not-found.jpg" css={Css.bshHover.my2.wPx(400).mxa.$} alt="" />
      <h1 css={Css.xl2Sb.mb4.$} {...tid.title}>
        Hmmm&hellip; Wrong address? &#x1F914;
      </h1>
      {error && <ErrorMessages error={error} />}
      <SuggestedPaths error={error} />
    </div>
  );
}
