import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ReadyPlanDetailsFragment } from "src/generated/graphql-types";

type WelcomeReadyPlanModalProps = {
  readyPlans: ReadyPlanDetailsFragment[];
};
export function WelcomeReadyPlanModal(props: WelcomeReadyPlanModalProps) {
  const { readyPlans } = props;
  const { closeModal } = useModal();
  const readyPlanNames = readyPlans.map((rp) => rp.name).join(", ");
  const development = readyPlans[0].development?.name!;

  return (
    <>
      <ModalHeader>Welcome to the ReadyPlan Workflow!</ModalHeader>
      <ModalBody>
        <div>
          <div css={Css.mb2.$}>
            <h2 css={Css.baseSb.blue700.$}>What is a ReadyPlan?</h2>
            <p css={Css.base.$}>
              ReadyPlans are <span css={Css.baseBd.$}>development-level architectural plan templates </span>
              with options that create a custom look and feel to a home. ReadyPlans can later be configured on projects
              on the Lot Summary sheet, and set the framework to build scope for Development Templates.
            </p>
          </div>

          <div css={Css.mb2.$}>
            <h2 css={Css.baseSb.blue700.$}>Next Steps</h2>
            <p css={Css.base.mb2.$}>
              This ReadyPlan, <span css={Css.baseBd.$}>{readyPlanNames}</span>, will be available for the all lots in
              development <span css={Css.baseBd.$}>{development}</span>.
            </p>
            <p css={Css.base.$}>Follow the steps in the tabs at the bottom of the screen.</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Continue" onClick={closeModal} />
      </ModalFooter>
    </>
  );
}
