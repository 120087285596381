import { useApolloClient } from "@apollo/client";
import { BoundTextAreaField, Button, Checkbox, Css, IconButton, useModal } from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormStates } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useRef, useState } from "react";
import {
  PotentialOperationDetailsFragment,
  SaveScheduleTaskChecklistItemInput,
  TaskDetailPaneDocument,
  TaskDetailPaneFragment,
  TaskStatus,
  useDeleteScheduleTaskChecklistItemMutation,
  useSaveScheduleTaskChecklistItemMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { FormValue } from "src/routes/projects/schedule-v2/detail-pane/TaskDetailPane";
import { noop } from "src/utils";
import { useFormState } from "src/utils/formState";

type TaskChecklistItemsProps = {
  task?: TaskDetailPaneFragment;
  scheduleIsLocked: boolean;
  isTemplate: boolean;
  formState?: FormValue;
  newTask?: boolean;
  ScheduleTaskChecklistItemFormState?: ObjectState<SaveScheduleTaskChecklistItemInput>;
};

export function TaskChecklistItems(props: TaskChecklistItemsProps) {
  const addItemRef = useRef<HTMLDivElement>(null);
  const { task, scheduleIsLocked, isTemplate, formState, newTask } = props;
  const { scheduleTaskChecklistItems, status: taskStatus, id: taskId } = task || {};

  const [deleteChecklistItem] = useDeleteScheduleTaskChecklistItemMutation();
  const [saveItem] = useSaveScheduleTaskChecklistItemMutation();
  const [addChecklistItem, setAddChecklistItem] = useState<boolean>(false);
  const { openModal } = useModal();
  const client = useApolloClient();

  const deleteItemModal = (item: ScheduleTaskChecklistItemFormState, index: number) => {
    openModal({
      content: (
        <ConfirmationModal
          confirmationMessage={<span>Are you sure you want to delete this checklist item?</span>}
          title="Delete Item"
          onConfirmAction={async () => {
            if (newTask) {
              formState?.scheduleTaskChecklistItems.remove(index!);
              return;
            }
            await deleteChecklistItem({
              variables: { input: { id: item.id.value! } },
              refetchQueries: ["TaskDetailPane"],
            });
          }}
          label="Delete"
        />
      ),
    });
  };

  const { getFormState } = useFormStates<SaveScheduleTaskChecklistItemInput>({
    config: checklistConfig,
    getId: (v) => v.id!,
    autoSave: async (os) => {
      // description is required when saving a checklist item, if it's null, revert changes without saving
      if (os.changedValue.description === null) return os.revertChanges();
      await saveItem({ variables: { input: os.value }, refetchQueries: [TaskDetailPaneDocument] });
      os.commitChanges();
    },
  });

  return (
    <div>
      <span css={Css.df.aic.jcsb.sm.gray700.mb1.$}>
        Checklist
        <Button
          label="Add an item"
          variant="tertiary"
          data-testid="addChecklistItem"
          onClick={() => {
            setAddChecklistItem(true);
            addItemRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          disabled={
            scheduleIsLocked || (taskStatus === TaskStatus.Complete && "Cannot add because task is marked as Complete")
          }
        />
      </span>

      <div>
        {newTask
          ? formState?.scheduleTaskChecklistItems.rows.map((item, index) => (
              <Observer key={index}>
                {() => (
                  <ChecklistItem
                    os={item}
                    isCompleted={false}
                    isTemplate={isTemplate}
                    deleteItemModal={deleteItemModal}
                    scheduleIsLocked={scheduleIsLocked}
                    newTask={newTask}
                    index={index}
                  />
                )}
              </Observer>
            ))
          : scheduleTaskChecklistItems?.map((item, index) => {
              const os = getFormState(item);
              return (
                <ChecklistItem
                  os={os}
                  isCompleted={item.isCompleted}
                  isTemplate={isTemplate}
                  deleteItemModal={deleteItemModal}
                  scheduleIsLocked={scheduleIsLocked}
                  taskStatus={taskStatus}
                  index={index}
                  canDelete={item.canDelete}
                  key={os.id.value}
                />
              );
            })}

        {addChecklistItem && (
          <div ref={addItemRef}>
            <AddChecklistItem
              setAddChecklistItem={setAddChecklistItem}
              taskId={taskId}
              isTemplate={isTemplate}
              newTask={newTask}
              formState={formState}
            />
          </div>
        )}

        {!addChecklistItem &&
          (scheduleTaskChecklistItems?.isEmpty ?? true) &&
          (formState?.scheduleTaskChecklistItems.rows.isEmpty ?? true) && (
            <div data-testid="noChecklistItems" css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.df.jcc.$}>
              There are no checklist items added to this task
            </div>
          )}
      </div>
    </div>
  );
}

type ScheduleTaskChecklistItemFormState = ObjectState<SaveScheduleTaskChecklistItemInput>;

export const checklistConfig: ObjectConfig<SaveScheduleTaskChecklistItemInput> = {
  id: { type: "value" },
  description: { type: "value" },
  completedAt: { type: "value" },
};

type AddChecklistItemsProps = {
  setAddChecklistItem: (value: boolean) => void;
  taskId?: string;
  isTemplate: boolean;
  newTask?: boolean;
  formState?: FormValue;
};

function AddChecklistItem(props: AddChecklistItemsProps) {
  const { setAddChecklistItem, taskId, isTemplate, newTask, formState: newTaskFormState } = props;
  const [saveItem] = useSaveScheduleTaskChecklistItemMutation();
  const formState = useFormState({ config: checklistConfig });
  const client = useApolloClient();

  return (
    <div css={Css.df.aic.gap2.mb1.$}>
      {!isTemplate && <Checkbox label="checklist completed " selected={false} checkboxOnly disabled onChange={noop} />}
      <BoundTextAreaField
        field={formState.description}
        labelStyle="hidden"
        preventNewLines
        onBlur={async () => {
          if (!formState.description.value) return setAddChecklistItem(false);
          if (newTask) {
            newTaskFormState?.scheduleTaskChecklistItems.add({ description: formState.description.value }, 0);
            setAddChecklistItem(false);
            return;
          }
          await saveItem({ variables: { input: { description: formState.description.value, taskId } } });
          setAddChecklistItem(false);
          // using Apollo Client directly instead of prop drilling refetch to update cache
          await client.refetchQueries({ include: [TaskDetailPaneDocument] });
        }}
        autoFocus
      />
      <IconButton compact icon="trash" onClick={noop} disabled />
    </div>
  );
}

type ChecklistItemProps = {
  os: ObjectState<SaveScheduleTaskChecklistItemInput>;
  isCompleted: boolean;
  isTemplate: boolean;
  taskStatus?: TaskStatus;
  deleteItemModal: (item: ScheduleTaskChecklistItemFormState, index: number) => void;
  scheduleIsLocked: boolean;
  canDelete?: PotentialOperationDetailsFragment;
  newTask?: boolean;
  index: number;
};

function ChecklistItem(props: ChecklistItemProps) {
  const { os, isCompleted, isTemplate, taskStatus, deleteItemModal, scheduleIsLocked, canDelete, newTask, index } =
    props;

  const cannotDelete = !canDelete?.allowed && canDelete?.disabledReasons.map(({ message }) => message).join(" ");

  return (
    <div data-testid="checklistItem" css={Css.df.aic.gap2.mb1.$} key={index}>
      {!isTemplate && (
        <Checkbox
          label="completedAt"
          disabled={taskStatus === TaskStatus.Complete || scheduleIsLocked || newTask}
          selected={newTask ? !!os.completedAt.value : isCompleted}
          onChange={(value) => os.completedAt.set(value ? new Date() : undefined)}
          checkboxOnly
        />
      )}
      <BoundTextAreaField
        field={os.description}
        labelStyle="hidden"
        preventNewLines
        disabled={
          scheduleIsLocked ||
          isCompleted ||
          (taskStatus === TaskStatus.Complete && "Cannot edit because task is marked as Complete") ||
          cannotDelete
        }
      />
      <IconButton
        data-testid="delete_checklist_item"
        compact
        icon="trash"
        disabled={taskStatus === TaskStatus.Complete || scheduleIsLocked || cannotDelete}
        onClick={() => deleteItemModal(os, index)}
      />
    </div>
  );
}
