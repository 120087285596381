import { useRightPane } from "@homebound/beam";
import { Menu, WidgetColumnConfig } from "@homebound/schedules-v2-gantt";
import { useCallback } from "react";
import { ScheduleGanttQuery } from "src/generated/graphql-types";
import { assertNever } from "src/utils";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { setTaskPaneState } from "../contexts/scheduleStoreReducer";
import { AddScheduleTaskPane, AddScheduleTaskPaneProps, getAddTaskFormValues } from "../table/AddScheduleTaskPane";
import { AddSubPhasePane } from "../table/AddSubPhasePane";
import { GanttTask, OriginalTaskDataById, RowType } from "./ganttUtils";

type MenuAction =
  | "editTask"
  | "addTaskAsSuccessor"
  | "addTaskAsPredecessor"
  | "addSubPhase"
  | "addTaskToPhase"
  | "addTaskToSubPhase";

type OnOptionButtonClickEvent = {
  source: {
    cellInfo: {
      record: {
        originalData: GanttTask;
      };
    };
  };
};

type OnMenuItemClickEvent = {
  item: {
    action: MenuAction;
  };
};

type GanttMoreOptionsActionsAttrs = {
  buttonColumnWidth: number;
  scheduleIsLocked: boolean;
  originalTaskDataById: OriginalTaskDataById;
  scheduleGanttQuery?: ScheduleGanttQuery;
  scrollGanttTaskIntoViewById: (taskId: string) => Promise<any> | undefined;
};

/** Because the Bryntum gantt chart doesn't use React under the hood, we can't easily pass in existing schedule components like the `MoreOptionsMenuCell`
 * This hook helps bridge the gap between our react components and the vanilla JS Bryntum Menu component
 */
export function useGanttMoreOptionsActions(attrs: GanttMoreOptionsActionsAttrs) {
  const { buttonColumnWidth, scheduleIsLocked, originalTaskDataById, scheduleGanttQuery, scrollGanttTaskIntoViewById } =
    attrs;

  const { dispatch: scheduleStoreDispatch } = useScheduleStore();
  const { openRightPane } = useRightPane();

  // The add task flow needs global[Sub]Phase ids rather than schedule[Sub]Phase ids to wire up tasks to the correct parent
  const globalPhaseIdMap = scheduleGanttQuery
    ? scheduleGanttQuery.schedulePhases.keyBy(
        (sp) => sp.id,
        (sp) => sp.globalPhase.id,
      )
    : undefined;
  const globalSubPhaseIdMap = scheduleGanttQuery
    ? scheduleGanttQuery.schedulePhases
        .flatMap((sp) => sp.scheduleSubPhases)
        .keyBy(
          (ssp) => ssp.id,
          (ssp) => ssp.globalSubPhase.id,
        )
    : undefined;

  const getOnMenuClickFunc = useCallback(
    (rowId: string) => {
      return ({ item }: OnMenuItemClickEvent) => {
        if (!scheduleGanttQuery || !globalSubPhaseIdMap || !globalPhaseIdMap) return null;

        const addScheduleTaskPaneSharedProps: Pick<
          AddScheduleTaskPaneProps,
          "tasks" | "schedule" | "refetchQueries" | "afterSave"
        > = {
          tasks: scheduleGanttQuery.scheduleTasks,
          schedule: scheduleGanttQuery.schedule,
          refetchQueries: ["ScheduleGantt"],
          afterSave: (taskId: string) => retryScrollTo({ scrollFunction: () => scrollGanttTaskIntoViewById(taskId) }),
        };

        switch (item.action) {
          case "editTask":
            return scheduleStoreDispatch(setTaskPaneState({ taskPaneId: rowId, tab: "details" }));

          case "addTaskToSubPhase":
            return openRightPane({
              content: (
                <AddScheduleTaskPane
                  {...addScheduleTaskPaneSharedProps}
                  type="task"
                  formValues={getAddTaskFormValues({
                    kind: "subPhase",
                    id: rowId,
                    data: { subPhaseId: globalSubPhaseIdMap[rowId] },
                  })}
                />
              ),
            });

          case "addTaskToPhase":
            return openRightPane({
              content: (
                <AddScheduleTaskPane
                  {...addScheduleTaskPaneSharedProps}
                  type="task"
                  formValues={getAddTaskFormValues({
                    kind: "phase",
                    id: rowId,
                    data: { phaseId: globalPhaseIdMap[rowId] },
                  })}
                />
              ),
            });

          case "addTaskAsSuccessor":
            return openRightPane({
              content: (
                <AddScheduleTaskPane
                  {...addScheduleTaskPaneSharedProps}
                  type="successor"
                  formValues={getAddTaskFormValues(
                    {
                      kind: "task",
                      id: rowId,
                      data: originalTaskDataById[rowId],
                    },
                    "successor",
                  )}
                />
              ),
            });

          case "addTaskAsPredecessor":
            return openRightPane({
              content: (
                <AddScheduleTaskPane
                  {...addScheduleTaskPaneSharedProps}
                  type="predecessor"
                  formValues={getAddTaskFormValues(
                    {
                      kind: "task",
                      id: rowId,
                      data: originalTaskDataById[rowId],
                    },
                    "predecessor",
                  )}
                />
              ),
            });

          case "addSubPhase":
            return openRightPane({
              content: (
                <AddSubPhasePane
                  scheduleId={scheduleGanttQuery.schedule.id}
                  phaseId={rowId}
                  refetchQueries={["ScheduleGantt"]}
                />
              ),
            });

          default:
            return assertNever(item.action);
        }
      };
    },
    [
      scheduleStoreDispatch,
      openRightPane,
      scheduleGanttQuery,
      originalTaskDataById,
      globalSubPhaseIdMap,
      globalPhaseIdMap,
      scrollGanttTaskIntoViewById,
    ],
  );

  const moreOptionsButtonColumnConfig: Partial<WidgetColumnConfig> & { type: "widget" } = {
    type: "widget",
    width: buttonColumnWidth,

    widgets: [
      {
        type: "button",
        icon: "b-fw-icon b-fa-ellipsis-vertical",
        disabled: scheduleIsLocked,
        onClick: ({ source }: OnOptionButtonClickEvent) => {
          const { type, id } = source.cellInfo.record.originalData;

          return new Menu({
            items: menuItemsByRowType[type],
            onItem: getOnMenuClickFunc(id),
          }).showBy({ target: source });
        },
      },
    ],
  };

  return { moreOptionsButtonColumnConfig };
}

/** Since we don't have any callback to know if the newly created task has been drawn to the Gantt chart yet, the best we can do is keep retrying with a delay */
async function retryScrollTo({
  scrollFunction,
  retryCount = 0,
  lastError = null,
}: {
  scrollFunction: () => Promise<any> | undefined;
  retryCount?: number;
  lastError?: Error | null | unknown;
}): Promise<void> {
  if (retryCount > 5) return console.error(lastError);

  try {
    return scrollFunction();
  } catch (e) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return retryScrollTo({ scrollFunction, retryCount: retryCount + 1, lastError: e });
  }
}

const menuItemsByRowType: Record<RowType, { icon: string; text: string; action: MenuAction }[]> = {
  Task: [
    {
      icon: "b-fw-icon b-icon-edit",
      text: "Edit Task",
      action: "editTask",
    },
    {
      icon: "b-fw-icon b-icon-add",
      text: "Add Task As Successor",
      action: "addTaskAsSuccessor",
    },
    {
      icon: "b-fw-icon b-icon-add",
      text: "Add Task As Predecessor",
      action: "addTaskAsPredecessor",
    },
  ],
  SchedulePhase: [
    {
      icon: "b-fw-icon b-icon-add",
      text: "Add Task To Phase",
      action: "addTaskToPhase",
    },
    {
      icon: "b-fw-icon b-icon-add",
      text: "Add SubPhase",
      action: "addSubPhase",
    },
  ],
  ScheduleSubPhase: [
    {
      icon: "b-fw-icon b-icon-add",
      text: "Add Task To SubPhase",
      action: "addTaskToSubPhase",
    },
  ],
};
