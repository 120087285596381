import { Css, Icon, IconKey, useTestIds } from "@homebound/beam";
import { camelCase } from "change-case";
import { LotConfigurationDetailsReadyPlanConfigDetailsOptionsFragment } from "src/generated/graphql-types";
type LotConfigurationSectionProps = {
  data: LotConfigurationDetailsReadyPlanConfigDetailsOptionsFragment[];
  optionType: string;
  icon: IconKey;
  title: string;
  compact?: boolean;
};

export function LotConfigurationSection(props: LotConfigurationSectionProps) {
  const { data, optionType, icon, title, compact } = props;
  const filterOption = camelCase(optionType);
  const tid = useTestIds(props, "lotConfigSection");

  return (
    <div css={Css.df.$} {...tid}>
      <div css={Css.df.gap1.w("230px").$}>
        <div css={Css.bgGray100.br100.w2.h2.gray900.$}>
          <Icon icon={icon} inc={2} data-testid="icon" />
        </div>
        <p css={Css.xsSb.$} data-testid="title">
          {title}
        </p>
      </div>
      <div css={Css.xs.df.f1.fdc.if(!compact).gap1.$}>
        {data?.length > 0 ? (
          data.map((rpo, i) => (
            <span key={`${filterOption}-${i}`} data-testid={`${filterOption}_option`}>
              {rpo.readyPlanOption.globalOption.code} - {rpo.readyPlanOption.name}
            </span>
          ))
        ) : (
          <span css={Css.xs.gray400.$}>Not configured</span>
        )}
      </div>
    </div>
  );
}
