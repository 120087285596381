import { fail } from "src/utils";
import { useApprovalContext } from "../ApprovalSuperDrawer";

export function ApprovalOverviewChangeRequestExtension() {
  const { approvals } = useApprovalContext();
  const changeRequestApprovals =
    approvals?.filter((a) => a.subject.__typename === "ChangeRequest") ??
    fail("Superdrawer should've loaded an Approval, but didn't");

  return null;
}
