import { QueryResult } from "@apollo/client";
import { Global } from "@emotion/react";
import { Css, emptyCell, GridColumn, GridDataRow, GridTable, RowStyles, simpleHeader } from "@homebound/beam";
import { Fragment, ReactNode } from "react";
import { formatDate, Icon } from "src/components";
import {
  Maybe,
  ToDoCardFragment,
  ToDoModal_ToDoChecklistItemFragment,
  ToDosPdfQuery,
  ToDoStatusDetail,
  useToDosPdfQuery,
} from "src/generated/graphql-types";
import { useToDoStatuses } from "src/hooks/enums/useToDoStatuses";
import { groupBy, renderLoadingOrError, sortBy } from "src/utils";
import { ArrayParam, useQueryParam } from "use-query-params";

export function ToDosPdf() {
  const [toDoIds] = useQueryParam("toDoIds", ArrayParam);
  const filter = {
    id: toDoIds as Maybe<string[]>,
  };
  const { data, loading, error } = useToDosPdfQuery({
    variables: { filter },
  });
  const toDoStatuses = useToDoStatuses();
  // Handle loading state
  if (loading || error) {
    return renderLoadingOrError({ loading, error } as QueryResult);
  }
  return <ToDosPdfDataComponent data={data} toDoStatuses={toDoStatuses} />;
}

export function ToDosPdfDataComponent(props: { data?: ToDosPdfQuery; toDoStatuses: ToDoStatusDetail[] }) {
  const { data, toDoStatuses } = props;
  return (
    <Fragment>
      <Global styles={{ "@page": { size: "letter landscape" } }} />
      <GridTable
        as="table"
        columns={createColumns()}
        rows={createRows(data, toDoStatuses)}
        rowStyles={createRowStyles()}
      />
    </Fragment>
  );
}

type HeaderRow = { kind: "header" };
type StatusRow = { kind: "status"; data: ToDoCardFragment };
type ToDoRow = { kind: "toDo"; data: ToDoCardFragment };
type ChecklistRow = { kind: "checklist"; data: ToDoModal_ToDoChecklistItemFragment };
type Row = HeaderRow | StatusRow | ToDoRow | ChecklistRow;

function createRowStyles(): RowStyles<Row> {
  return {
    header: { cellCss: Css.p1.$ },
    status: { cellCss: Css.bgColor("rgba(245, 228, 205)").p1.sm.$ },
    toDo: { cellCss: Css.p1.sm.$ },
  };
}

function convertToPlain(html: string): string {
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

function HeaderText({ children }: { children: ReactNode }) {
  return <div css={Css.sm.$}>{children}</div>;
}

function createColumns(): GridColumn<Row>[] {
  const idColumn: GridColumn<Row> = {
    header: () => <HeaderText>Status</HeaderText>,
    status: (row) => row.id,
    toDo: emptyCell,
    checklist: emptyCell,
  };
  const nameColumn: GridColumn<Row> = {
    header: () => <HeaderText>Title</HeaderText>,
    status: emptyCell,
    toDo: (row) => row.name,
    checklist: emptyCell,
  };
  const parentColumn: GridColumn<Row> = {
    header: () => <HeaderText>Project/Cohort</HeaderText>,
    status: emptyCell,
    toDo: (row) => row.parent?.name,
    checklist: emptyCell,
  };
  const descriptionColumn: GridColumn<Row> = {
    header: () => <HeaderText>Description</HeaderText>,
    status: emptyCell,
    toDo: (row) => convertToPlain(row.description as string),
    checklist: emptyCell,
  };
  const assigneeColumn: GridColumn<Row> = {
    header: () => <HeaderText>Assignee</HeaderText>,
    status: emptyCell,
    toDo: (row) => row.assignees.map(({ name }) => name).join(),
    checklist: emptyCell,
  };
  const dueDateColumn: GridColumn<Row> = {
    header: () => <HeaderText>Due Date</HeaderText>,
    status: emptyCell,
    toDo: (row) => formatDate(row.dueDate as Date, "medium"),
    checklist: emptyCell,
    w: "115px",
  };
  const checklistItemColumn: GridColumn<Row> = {
    header: () => <HeaderText>Checklist Item</HeaderText>,
    status: emptyCell,
    toDo: emptyCell,
    checklist: (row) => row.description,
  };
  const checklistCompleteColumn: GridColumn<Row> = {
    header: () => <HeaderText>Checklist Complete?</HeaderText>,
    status: emptyCell,
    toDo: emptyCell,
    checklist: (checklistItem) => <Icon icon={checklistItem.isComplete ? "checkboxOn" : "checkboxOff"} />,
  };

  return [
    idColumn,
    parentColumn,
    nameColumn,
    descriptionColumn,
    assigneeColumn,
    dueDateColumn,
    checklistItemColumn,
    checklistCompleteColumn,
  ];
}

function createRows(data?: ToDosPdfQuery, toDoStatuses: ToDoStatusDetail[] = []): GridDataRow<Row>[] {
  const sortedStatuses = sortBy(toDoStatuses || [], (p) => p.sortOrder).map((status) => status.name);
  const sortedToDos = sortBy(data?.toDos || [], (p) => sortedStatuses.indexOf(p.status.name));
  const toDosGroupedByStatus = groupBy(sortedToDos ?? [], (t) => t.status.name);
  const checklistsGroupedByToDo = sortedToDos.reduce<Record<string, GridDataRow<Row>[]>>(
    (acc, todo) => {
      acc[todo.id] = todo.checklistItems.map((item) => ({ kind: "checklist", id: item.id, data: item }));
      return acc;
    },
    {} as Record<string, GridDataRow<Row>[]>,
  );
  return [
    simpleHeader,
    ...Object.keys(toDosGroupedByStatus).reduce(
      (accumulator, status) =>
        [
          ...accumulator,
          {
            kind: "status",
            id: status,
            data: { id: status, description: "" },
          },
          ...toDosGroupedByStatus[status]
            .sort((a, b) => b.order.localeCompare(a.order))
            .reduce((accumlator, toDo) => {
              const rows: GridDataRow<Row>[] = [
                {
                  kind: "toDo",
                  id: toDo.id,
                  data: toDo,
                },
                ...(checklistsGroupedByToDo[toDo.id] || []),
              ];

              return accumlator.concat(rows);
            }, [] as GridDataRow<Row>[]),
        ] as GridDataRow<Row>[],
      [] as GridDataRow<Row>[],
    ),
  ];
}
