import { fail } from "src/utils";
import { useApprovalContext } from "../ApprovalSuperDrawer";

export function ApprovalOverviewInvoiceExtension() {
  const { approvals } = useApprovalContext();
  const invoiceApprovals =
    approvals?.filter((a) => a.subject.__typename === "Invoice") ??
    fail("Superdrawer should've loaded an Approval, but didn't");

  return null;
}
