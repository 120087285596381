import {
  Chip,
  dateRangeFilter,
  DateRangeFilterValue,
  FilterDefs,
  MenuItem,
  ModalProps,
  multiFilter,
  useComputed,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import { camelCase } from "change-case";
import { compareDesc, differenceInDays } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Client,
  CohortDevelopmentDetailsFragment,
  DateOperation,
  DevelopmentProjectDetailFragment,
  GlobalOptionTypeDrawerFragment,
  InputMaybe,
  InternalUserDetailFragment,
  LotDetailDrawerEnums_AllEnumDetailFragment,
  LotDetailDrawerOptionFragment,
  LotDetailDrawerReadyPlanFragment,
  LotSequenceSheetTableMetadataQuery,
  LotType,
  Maybe,
  NamedFragment,
  PotentialOperationDetailsFragment,
  ProgramData,
  ProgramDataForLotSequenceFragment,
  ProjectLotDetail,
  ProjectReadyPlanConfig,
  ProjectReadyPlanConfigFilter,
  ProjectsForLotSequenceFragment,
  SaveProgramDataInput,
  SaveProjectLotDetailInput,
  SaveProjectReadyPlanConfigInput,
  UserEventsQuery,
} from "src/generated/graphql-types";
import { isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { MoveProjectModal } from "./move-project-modal/MoveProjectModal";

/**
 * Union together the input keys & the PD fragment keys, b/c the PD fragment has the computed data.
 *
 * (And if we don't do this, any new keys added to the `SaveProgramDataInput` on the backend/codegen
 * will insta-break code below that combines the being-typed-in input & computed-by-the-BE fragment.)
 */
export type ProgramDataKey = keyof SaveProgramDataInput & keyof ProgramDataForLotSequenceFragment;

export type ProgramDataDerivedKey = keyof Omit<
  ProgramDataForLotSequenceFragment,
  ProgramDataKey | "__typename" | "updatedAt"
>;

export type FormInput = Omit<SaveProjectReadyPlanConfigInput, "options"> & {
  elevationOptionId?: Maybe<string>;
  exteriorPaletteOptionId?: Maybe<string>;
  specOptionId?: Maybe<string>;
  options: {
    id?: string;
    updatedAt: Date;
    autoAdded?: Date;
  }[];
  computedProgramData: ProgramDataForLotSequenceFragment;
  // Add derived fields like `unfinishedBelowGroundSqft` to `programData` b/c
  // they're not on the input, but we want to show the read-only values in the form
  programData?: Omit<ProgramDataForLotSequenceFragment, ProgramDataKey | "__typename" | "updatedAt">;
};

export type LotFormInput = SaveProjectLotDetailInput & {
  lotType?: Maybe<LotType>;
  clients?: Maybe<Pick<Client, "id" | "fullName">>[];
};

type PRPCUpdatedFields = {
  [attributeName in keyof Pick<
    ProjectReadyPlanConfig,
    "elevationOption" | "exteriorPaletteOption" | "internalNote" | "readyPlan" | "specOption" | "orientation"
  >]?: Date;
};

type PDUpdatedFields = {
  [attributeName in keyof Omit<
    ProgramData,
    | "id"
    | "hasManualDeviations"
    | "confirmedDeviations"
    | "computed"
    | "computedProgramData"
    | "projectReadyPlanConfig"
    | "readyPlan"
    | "readyPlanOption"
    | "maxOfReadyPlan"
    | "minOfReadyPlan"
    | "createdAt"
    | "updatedAt"
  >]?: Date;
};

type LotDetailUpdatedFields = {
  [attributeName in keyof Pick<
    ProjectLotDetail,
    | "lotNumber"
    | "block"
    | "section"
    | "lotSquareFootage"
    | "floodPlain"
    | "floodZone"
    | "setBackFrontInFeet"
    | "setBackRightInFeet"
    | "setBackLeftInFeet"
    | "setBackRearInFeet"
    | "hoa"
    | "hoaSpecificModifications"
    | "siteSpecificModifications"
    | "constructionType"
    | "foundationType"
    | "fireSprinklersRequired"
  >]?: Date;
};

export type UpdatedFields = PRPCUpdatedFields & LotDetailUpdatedFields & PDUpdatedFields;

export type LotDetailDrawerMetadata = {
  readyPlans: Omit<LotDetailDrawerReadyPlanFragment, "options">[];
  elevationOptions: Omit<LotDetailDrawerOptionFragment, "updatedAt">[];
  exteriorSchemeOptions: Omit<LotDetailDrawerOptionFragment, "updatedAt">[];
  otherOptions: Omit<LotDetailDrawerOptionFragment, "updatedAt">[];
  specOptions: Omit<LotDetailDrawerOptionFragment, "updatedAt">[];
  currentUserId: Maybe<string>;
  internalUsers: InternalUserDetailFragment[];
  updatedFields: UpdatedFields;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
  enumDetails: LotDetailDrawerEnums_AllEnumDetailFragment | undefined;
  canEdit: PotentialOperationDetailsFragment | undefined;
};

export const readyPlanFormConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  projectId: { type: "value", readOnly: true },
  readyPlanId: { type: "value", rules: [required] },
  orientation: { type: "value" },
  specOptionId: { type: "value", rules: [required] },
  elevationOptionId: { type: "value", rules: [required] },
  exteriorPaletteOptionId: { type: "value", rules: [required] },
  internalNote: { type: "value" },
  options: {
    type: "list",
    config: {
      autoAdded: { type: "value" },
      updatedAt: { type: "value" },
      id: { type: "value" },
    },
  },
  programData: {
    type: "object",
    config: {
      sellableSqft: { type: "value" },
      netBuildableSqft: { type: "value" },
      permittableSqft: { type: "value" },
      grossBuildableSqft: { type: "value" },
      sellableAboveGroundSqft: { type: "value" },
      sellableBelowGroundSqft: { type: "value" },
      grossBelowGroundSqft: { type: "value" },
      unfinishedBelowGroundSqft: { type: "value" },
      imperviousSqft: { type: "value" },
      stories: { type: "value" },
      bedrooms: { type: "value" },
      fullBaths: { type: "value" },
      halfBaths: { type: "value" },
      basementConfig: { type: "value" },
      garageAttached: { type: "value" },
      garageDetached: { type: "value" },
      garagePort: { type: "value" },
      garageConfiguration: { type: "value" },
      windowColor: { type: "value" },
      closetsInPrimarySuite: { type: "value" },
      firstFloorBedrooms: { type: "value" },
      diningRoom: { type: "value" },
      casualDining: { type: "value" },
      mediaRooms: { type: "value" },
      loftGameFlexRooms: { type: "value" },
      offices: { type: "value" },
      workspaces: { type: "value" },
      primaryBedroom: { type: "value" },
      minLotSizeInSqft: { type: "value" },
      depthInFeet: { type: "value" },
      widthInFeet: { type: "value" },
      minLotDepthInFeet: { type: "value" },
      minLotWidthInFeet: { type: "value" },
    },
  },
  computedProgramData: {
    type: "object",
    config: {
      updatedAt: { type: "value" },
      sellableSqft: { type: "value" },
      netBuildableSqft: { type: "value" },
      permittableSqft: { type: "value" },
      grossBuildableSqft: { type: "value" },
      sellableAboveGroundSqft: { type: "value" },
      sellableBelowGroundSqft: { type: "value" },
      grossBelowGroundSqft: { type: "value" },
      unfinishedBelowGroundSqft: { type: "value" },
      imperviousSqft: { type: "value" },
      stories: { type: "value" },
      bedrooms: { type: "value" },
      fullBaths: { type: "value" },
      halfBaths: { type: "value" },
      basementConfig: { type: "value" },
      garageAttached: { type: "value" },
      garageDetached: { type: "value" },
      garagePort: { type: "value" },
      garageConfiguration: { type: "value" },
      windowColor: { type: "value" },
      closetsInPrimarySuite: { type: "value" },
      firstFloorBedrooms: { type: "value" },
      diningRoom: { type: "value" },
      casualDining: { type: "value" },
      mediaRooms: { type: "value" },
      loftGameFlexRooms: { type: "value" },
      offices: { type: "value" },
      workspaces: { type: "value" },
      primaryBedroom: { type: "value" },
      minLotSizeInSqft: { type: "value" },
      depthInFeet: { type: "value" },
      widthInFeet: { type: "value" },
      minLotDepthInFeet: { type: "value" },
      minLotWidthInFeet: { type: "value" },
    },
  },
};

export const hblReadyPlanFormConfig: ObjectConfig<FormInput> = {
  ...readyPlanFormConfig,
  readyPlanId: { type: "value" },
  specOptionId: { type: "value" },
  elevationOptionId: { type: "value" },
  exteriorPaletteOptionId: { type: "value" },
};

export const lotConfig: ObjectConfig<LotFormInput> = {
  id: { type: "value" },
  projectId: { type: "value", readOnly: true },
  lotType: { type: "value" },
  clients: {
    type: "list",
    config: {
      fullName: { type: "value" },
      id: { type: "value" },
    },
  },
  lotNumber: { type: "value" },
  block: { type: "value" },
  section: { type: "value" },
  lotSquareFootage: { type: "value" },
  floodPlain: { type: "value" },
  floodZone: { type: "value" },
  setBackFrontInFeet: { type: "value" },
  setBackRightInFeet: { type: "value" },
  setBackLeftInFeet: { type: "value" },
  setBackRearInFeet: { type: "value" },
  hoa: { type: "value" },
  hoaSpecificModifications: { type: "value" },
  siteSpecificModifications: { type: "value" },
  constructionType: { type: "value" },
  foundationType: { type: "value" },
  fireSprinklersRequired: { type: "value" },
};

export enum OptionTypes {
  Elevation = "Elevation",
  ExteriorScheme = "Exterior Scheme",
  AddOn = "Add-on",
  FloorPlan = "Floor Plan",
  Interior = "Interior",
  Custom = "Custom",
  SpecLevel = "Spec Level",
}

/** This will tell for the complete lot sequence sheet the amount of days to show:
 * * New tags
 * * Updated tags
 *
 * Any date older than this amount of days wont display any tag
 */
export const LOT_SEQUENCE_MAX_DAYS_TO_SHOW_TAGS = 7;

export function dateCanShowStatusTags(date: Date) {
  const difference = differenceInDays(new Date(), date);
  return difference <= LOT_SEQUENCE_MAX_DAYS_TO_SHOW_TAGS;
}

export function StatusChip({ updatedAt }: { updatedAt?: Date }) {
  if (!updatedAt) return <></>;
  const isUpdated = dateCanShowStatusTags(updatedAt);
  return isUpdated ? <Chip compact type="caution" text="Updated" /> : <></>;
}
/** This function grabs a list of user events, thinking on the `ProjectReadyPlanConfig` events payload
 * and builds a map of type `UpdatedFields` that indicates a field from the `ProjectReadyPlanConfig` was
 * updated and on which date it was updated, so we can use it for displaying updated statuses
 *
 * Note: This makes no distintion between record ids, make sure all of the events are already filtered
 * and are relevant to the context you are expecting to use the data
 */
export function mapUpdatedFields(events: UserEventsQuery["userEvents"]) {
  const updatedFields: UpdatedFields = {};

  for (const event of events ?? []) {
    const { __typename, __originalValues, programData, ...fields } = event.payload;
    const { __originalValues: PdOriginalValues, __typename: pdTN, ...pdFields } = programData?.__payload ?? {};
    const allFields = { ...fields, ...pdFields };
    for (const field of Object.keys(allFields)) {
      switch (field) {
        case "options":
          const options = [...(allFields[field].added ?? []), ...(allFields[field].deleted ?? [])].groupBy(
            (opt: any) => opt.__payload?.__meta?.type?.name,
          );
          if (options[OptionTypes.ExteriorScheme]) updatedFields.exteriorPaletteOption = event.createdAt;
          if (options[OptionTypes.Elevation]) updatedFields.elevationOption = event.createdAt;
          break;

        default:
          updatedFields[field as keyof UpdatedFields] = event.createdAt;
      }
    }
  }

  return updatedFields;
}

// This function creates our button menu items used to move a project to another cohort. Currently used in `LotSequenceSheet.tsx` and `DevelopmentAllLots.tsx`
export function createCohortButtonMenuItems(
  cohortDevelopments: CohortDevelopmentDetailsFragment[],
  project: ProjectsForLotSequenceFragment | DevelopmentProjectDetailFragment,
  openModal: (modal: ModalProps) => void,
): MenuItem[] {
  // Gets our cohorts that are in the same market as the project and removes our current cohort from the list
  const cohortsForProject = cohortDevelopments.filter(
    ({ id, market, development }) =>
      market.id === project.market.id && id !== project?.cohort?.id && isDefined(development),
  );

  // Sort our cohorts by development created date
  cohortsForProject.sort((a, b) => {
    if (a.development?.createdAt && b.development?.createdAt) {
      return compareDesc(a.development.createdAt, b.development.createdAt);
    }
    return 0;
  });

  return cohortsForProject.map((cohort) => {
    return {
      label: `${cohort.development?.name} - ${cohort.name}`,
      onClick: () =>
        openModal({
          content: <MoveProjectModal cohortDevelopment={cohort} project={project} />,
        }),
    };
  });
}

function getFilterOptions(data?: LotSequenceSheetTableMetadataQuery) {
  const optionsByType = data?.globalOptionTypes.sortBy((go) => go.order);
  return {
    readyPlans: data?.readyPlans ?? [],
    cohorts: data?.cohorts ?? [],
    options: optionsByType,
  };
}

export type LotSequenceSheetTableFilter = Omit<ProjectReadyPlanConfigFilter, "options" | "constructionStartDate"> & {
  constructionStartDate: DateRangeFilterValue<string>;
};

export function getFilterDef(data?: LotSequenceSheetTableMetadataQuery): FilterDefs<LotSequenceSheetTableFilter> {
  const { readyPlans, cohorts, options } = getFilterOptions(data);

  const readyPlan = multiFilter({
    label: "Plan",
    options: readyPlans,
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
  });

  const cohort = multiFilter({
    label: "Cohort",
    options: cohorts,
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
  });

  const constructionStartDate = dateRangeFilter({ label: "Construction Start Date" });

  const optionsByType: Record<string, any> = {};
  options?.forEach(
    (option) =>
      (optionsByType[`${camelCase(option.name)}`] = multiFilter({
        label:
          option.name === OptionTypes.AddOn
            ? "Add-ons"
            : !option.isElevation && !option.isExteriorPalette && !option.isSpecLevel
              ? `${option.name} Options`
              : `${option.name}`,
        options: getOptionsByType(data, option.name) || [],
        getOptionLabel: ({ name }) => name,
        getOptionValue: ({ id }) => id,
      })),
  );

  return {
    cohort,
    readyPlan,
    constructionStartDate,
    ...optionsByType,
  };
}

export enum LotSummaryGroupBy {
  cohort = "cohort",
  none = "none",
}

function getOptionsByType(data: LotSequenceSheetTableMetadataQuery | undefined, name: string) {
  return data?.readyPlans
    .flatMap(({ options }) => options)
    .map(({ globalOption }) => globalOption)
    .uniqueByKey("id")
    .sortBy((go) => go.type.order)
    .filter((go) => go.type.name === name);
}

export function mapToFilter(
  filter: LotSequenceSheetTableFilter,
  metadata?: LotSequenceSheetTableMetadataQuery,
): ProjectReadyPlanConfigFilter {
  const { constructionStartDate, ...moreFilters } = filter;
  const options: InputMaybe<string[]> = [];
  const optionsFilter: Record<string, any> = { ...filter };
  const baseFilter: Record<string, any> = { ...moreFilters };

  metadata?.globalOptionTypes
    .sortBy((go) => go.order)
    .forEach((option) => {
      const name = camelCase(option.name);
      const optionValue = optionsFilter[name];
      delete baseFilter[name];
      if (optionValue && optionValue.length) {
        optionValue.forEach((filter: string) => options.push(filter));
      }
    });

  return {
    ...baseFilter,
    globalOption: options.isEmpty ? undefined : options,
    constructionStartDate: constructionStartDate?.value
      ? {
          op: DateOperation.Between,
          value: new DateOnly(new Date(constructionStartDate.value.from!)),
          value2: new DateOnly(new Date(constructionStartDate.value.to!)),
        }
      : undefined,
  };
}

export type LotSummaryPageFiltersProps = {
  queryFilter: ProjectReadyPlanConfigFilter;
  searchFilter?: string | undefined;
  groupBy: LotSummaryGroupBy;
  setSummaryProjectIds?: React.Dispatch<React.SetStateAction<string[]>>;
};

export const lotSequenceDefaultFilter = {
  constructionStartDate: undefined,
  globalOption: undefined,
};

function getRposFromFormState(formState: ObjectState<FormInput>) {
  return [
    ...formState.options.value,
    ...(formState.elevationOptionId.value ? [{ id: formState.elevationOptionId.value, updatedAt: new Date() }] : []),
    ...(formState.exteriorPaletteOptionId.value
      ? [{ id: formState.exteriorPaletteOptionId.value, updatedAt: new Date() }]
      : []),
    ...(formState.specOptionId.value ? [{ id: formState.specOptionId.value, updatedAt: new Date() }] : []),
  ];
}

/** This hook will handle the auto addition of default rpos when user adds any rpo to the lot */
export function useOptionNotifications(props: {
  formState: ObjectState<FormInput>;
  metadata: LotDetailDrawerMetadata;
  isBoyl: boolean;
}) {
  // because the form state is mapped to expose the RPOs and not the PRPCOs to simplify implementation, all references are to RPOs (added and to be added)
  const { formState, metadata: md, isBoyl } = props;
  // cachedRpos will be used to keep the previous state of the options configured on the lot, new options will be missing from the cache
  const [cachedRpos, setRposCache] = useState(getRposFromFormState(formState));
  // this will be the actual rpos on the form state
  const actualRpos = useComputed(() => getRposFromFormState(formState), [formState]);
  // we will keep track of the auto added options so the UI can display the banner and highlight the options
  const [autoAddedRpos, setAutoAddedRpos] = useState<typeof actualRpos>([]);
  // utility to have conflicts, defaults and prerequisites for the RPOs at hand
  const allRpos = useMemo(
    () =>
      [...md.otherOptions, ...md.elevationOptions, ...md.exteriorSchemeOptions, ...md.specOptions].keyBy(
        ({ id }) => id,
      ),
    [md],
  );

  // Used to clear out the autoAddedRpos array, so the banner goes away and the options are not highlighted anymore (it does not remove configured RPOs from the form state)
  const clearAutoAddedRpos = useCallback(() => {
    setAutoAddedRpos([]);
    for (const rpo of formState.options.rows) {
      !formState.readOnly && rpo.autoAdded?.set(undefined);
    }
  }, [setAutoAddedRpos, formState.options, formState.readOnly]);

  // The main reaction of the hook, should trigger when the form state options changes
  useEffect(() => {
    // the new rpos will be the ones on the actual list of rpos that are not on the cached list, new to this evaluation
    const newRpos = actualRpos.filter((o) => !cachedRpos.find((co) => co.id === o.id));
    // the result
    const defaultRposToAdd: typeof cachedRpos = [];

    // if no new rpos, we return (this will happen when this hooks is fired after adding the default rpos, but because at the end we update the cache, there will be no new rpos)
    if (newRpos.isEmpty) return;

    for (const rpo of newRpos) {
      // we get the populated option from the metadata
      const populatedRpo = rpo.id ? allRpos[rpo.id] : undefined;
      // only if there is any default to evaluate at all and it's not a BOYL lot
      if (populatedRpo?.optionDefaultChildren?.nonEmpty && !isBoyl) {
        // we get the defaults to add, maybe there are none due to conflicts or prerequisites not satified
        const toAdd = maybeAddDefaults({
          defaultRpos: populatedRpo.optionDefaultChildren,
          allRpos,
          actualRpos,
          formState,
        });
        // we add to the result variable
        defaultRposToAdd.push(...toAdd.map(({ id }) => ({ id, updatedAt: new Date(), autoAdded: new Date() })));
      }
    }

    // only if there are any defaults to add, we update the form state, only once to reduce multiple reruns of this hook
    if (defaultRposToAdd.nonEmpty) {
      formState.options.set([...formState.options.value, ...defaultRposToAdd].uniqueByKey("id"));
    }

    // we update the cache including the defaults we just added so the second run, will not find any "new" rpos
    setRposCache(getRposFromFormState(formState));
    // we update the auto added rpos so the UI can display the banner and highlight the options
    setAutoAddedRpos((prev) => [...prev, ...defaultRposToAdd]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualRpos]);

  return {
    autoAddedRpos,
    /** Used to clear the autoAddedRpos array (to dismiss the auto added notification, but keeps the options on the form state)*/
    clearAutoAddedRpos,
  };
}

export function ClearAutoAddedOptionsComp({ clearAutoAddedRpos }: { clearAutoAddedRpos: () => void }) {
  // We clear on unmount and not on mount to make sure the users can see the auto added options higlighted and the banner when they are on the options tab
  useEffect(() => () => clearAutoAddedRpos(), [clearAutoAddedRpos]);
  return <></>;
}

function maybeAddDefaults(props: {
  defaultRpos: NamedFragment[];
  allRpos: Record<string, Omit<LotDetailDrawerOptionFragment, "updatedAt">>;
  actualRpos: { id?: string }[];
  formState: ObjectState<FormInput>;
}) {
  const { defaultRpos, allRpos, actualRpos, formState } = props;
  const populatedDefaultRpos = defaultRpos.map((dOpt) => allRpos[dOpt.id]);
  const nestedRpoDefaults: NamedFragment[] = [];
  const addedRpos: NamedFragment[] = [];
  let specLevel = formState.specOptionId.value;
  let elevation = formState.elevationOptionId.value;
  let exteriorScheme = formState.exteriorPaletteOptionId.value;
  let designPackage = actualRpos.find(
    (rpo) => rpo.id && allRpos[rpo.id]?.type.forDesignInterior && !allRpos[rpo.id]?.type.isSpecLevel,
  )?.id;

  // for each default
  for (const defaultRpo of populatedDefaultRpos) {
    const { optionConflicts: conflicts, optionPrerequisites: prereqs, type } = defaultRpo;

    if (type.isSpecLevel && specLevel) continue;
    if (type.isElevation && elevation) continue;
    if (type.isExteriorPalette && exteriorScheme) continue;
    if (type.forDesignInterior && designPackage) continue;
    // we check if some prerequisites are not satisfied, if so, we skip
    if (prereqs.some((prereq) => !actualRpos.some((o) => o.id === prereq.id))) continue;
    // if not prerequisites or all are satisfied, we check for the conflicts opts not to be added
    if (conflicts.some((conf) => actualRpos.some((o) => o.id === conf.id))) continue;

    if (type.isSpecLevel) {
      specLevel = defaultRpo.id;
      formState.specOptionId.set(defaultRpo.id);
    } else if (type.isElevation) {
      elevation = defaultRpo.id;
      formState.elevationOptionId.set(defaultRpo.id);
    } else if (type.isExteriorPalette) {
      exteriorScheme = defaultRpo.id;
      formState.exteriorPaletteOptionId.set(defaultRpo.id);
    } else {
      // if we made it here, this option can be added, the others above will be handled by the specific fields
      addedRpos.push(defaultRpo);
      if (type.forDesignInterior) {
        designPackage = defaultRpo.id;
      }
    }

    nestedRpoDefaults.push(...defaultRpo.optionDefaultChildren);
  }

  // now we check for the defaults of the defaults resursively
  if (nestedRpoDefaults.nonEmpty)
    addedRpos.push(...maybeAddDefaults({ defaultRpos: nestedRpoDefaults, allRpos, actualRpos, formState }));

  return addedRpos;
}
