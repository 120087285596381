import { BoundNumberField, BoundRadioGroupField, RadioFieldOption, StaticField } from "@homebound/beam";
import { Observer } from "mobx-react";
import { ContractType, Maybe } from "src/generated/graphql-types";
import { basisPointsToPercentage } from "src/utils/basisPoints";
import { FieldState } from "src/utils/formState";

type ContractTypeFieldProps = {
  contractTypeField: FieldState<Maybe<ContractType>>;
  costPlusMarkupBasisPointsField: FieldState<number | null | undefined>;
};

export function ContractTypeField(props: ContractTypeFieldProps) {
  const { contractTypeField, costPlusMarkupBasisPointsField } = props;

  return (
    <Observer>
      {() =>
        contractTypeField.readOnly ? (
          <StaticField
            label="Contract Type"
            value={`${
              contractTypeField.value ? getFriendlyContractType(contractTypeField.value) : "Contract Type not defined"
            }${
              contractTypeField.value === ContractType.CostPlus
                ? `: ${basisPointsToPercentage(costPlusMarkupBasisPointsField.value ?? 0)}%`
                : ""
            }`}
          />
        ) : (
          <>
            <BoundRadioGroupField field={contractTypeField} options={contractTypeOptions} />
            <BoundNumberField
              label="Cost Plus Markup"
              field={costPlusMarkupBasisPointsField}
              type="basisPoints"
              disabled={contractTypeField.value !== ContractType.CostPlus}
            />
          </>
        )
      }
    </Observer>
  );
}

function getFriendlyContractType(contractType: ContractType) {
  const friendlyNames: Record<ContractType, string> = {
    [ContractType.Fixed]: "Fixed",
    [ContractType.CostPlus]: "Cost Plus",
    [ContractType.BudgetReallocation]: "Budget Reallocation",
  };
  return friendlyNames[contractType];
}

const contractTypeOptions: RadioFieldOption<ContractType>[] = [
  { value: ContractType.Fixed, label: "Fixed" },
  { value: ContractType.CostPlus, label: "Cost Plus" },
];
