import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import {
  Stage,
  useAddCommitmentPotentialLineItemsQuery,
  useUpdateDevelopmentCommitmentMutation,
} from "src/generated/graphql-types";
import { DevelopmentCommitmentPotentialLineItemStore } from "src/routes/development-commitments/models/DevelopmentCommitmentPotentialLineItemStore";
import { DevelopmentCommitmentPotentialLineItemTable } from "./DevelopmentCommitmentPotentialLineItemTable";

type AddItemsModalProps = {
  developmentCommitmentId: string;
  onAddItems: () => void;
  stage: Stage;
};

export function AddItemsModal({ developmentCommitmentId, onAddItems, stage }: AddItemsModalProps) {
  const { data: potentialLineItems } = useAddCommitmentPotentialLineItemsQuery({
    variables: { developmentCommitmentId },
  });
  const [updateDevelopmentCommitment] = useUpdateDevelopmentCommitmentMutation();
  const { closeModal } = useModal();

  // Create line items store.
  const store = useMemo(
    () =>
      new DevelopmentCommitmentPotentialLineItemStore(
        potentialLineItems?.developmentCommitmentPotentialLineItems || [],
      ),
    [potentialLineItems],
  );

  return (
    <>
      <ModalHeader>Add Items</ModalHeader>
      <ModalBody>
        <DevelopmentCommitmentPotentialLineItemTable stage={stage} store={store} />
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button variant="tertiary" label="Cancel" onClick={closeModal} />
              <Button
                disabled={!store.selectedLineItems?.length}
                label="Add to Commitment"
                onClick={async () => {
                  if (store.selectedLineItems?.length) {
                    await updateDevelopmentCommitment({
                      variables: {
                        input: {
                          id: developmentCommitmentId,
                          potentialLineItems: store.selectedLineItems.map(({ itemId, costType, locationObj }) => ({
                            itemId,
                            costType,
                            locationId: locationObj?.id,
                          })),
                        },
                      },
                    });
                    onAddItems();
                    closeModal();
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}
