import {
  Button,
  ButtonMenu,
  ButtonModal,
  SelectField,
  useComputed,
  useGridTableApi,
  useGroupBy,
  useModal,
} from "@homebound/beam";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { createConstraintItemCatalogUrl, createTaskCatalogFormUrl } from "src/RouteUrls";
import { SearchBox } from "src/components";
import { GlobalPlanTaskFilter, useTaskCatalogPageMetadataQuery } from "src/generated/graphql-types";
import { HeaderBreadcrumb, PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { definedFilterValues, useQueryStorage } from "src/routes/projects/schedule-v2/table/filterUtils";
import { catalogTaskFolderPath, catalogTasksPath } from "src/routes/routesDef";
import { Row, TaskCatalogTable } from "./TaskCatalogTable";
import { AddGlobalPlanTagModal } from "./components/AddGlobalPlanTagModal";
import { BulkGlobalPlanTagsModal } from "./components/BulkGlobalPlanTagsModal";
import { MermaidLinkModal } from "./components/MermaidLinkModal";
import { TaskCatalogFilterModal } from "./components/TaskCatalogFilterModal";

export enum TaskCatalogGroupBy {
  Team = "Team",
  Tag = "Tag",
  None = "None",
}

type TaskCatalogPageConfig = {
  pageTitle: string;
  breadcrumbs: HeaderBreadcrumb[];
  folderFilter: Partial<GlobalPlanTaskFilter>;
};

export type CustomTaskCatalogPageFilter = GlobalPlanTaskFilter & {
  groupBy?: TaskCatalogGroupBy | undefined;
};

// set local filters to undefined as a default
export const customTaskCatalogPageFilterDefault: CustomTaskCatalogPageFilter = {
  archived: undefined,
  id: undefined,
  name: undefined,
  tags: undefined,
  team: undefined,
  groupBy: undefined,
  market: undefined,
  applyToAllSchedules: undefined,
  development: undefined,
};

export function TaskCatalogPage() {
  const { data } = useTaskCatalogPageMetadataQuery();
  const { markets = [], developments = [], globalPlanTags = [], enumDetails } = data ?? {};

  const businessFunctionTypes = useMemo(() => enumDetails?.businessFunctionType ?? [], [enumDetails]);

  // Determine if we are on a folder page
  const match = useRouteMatch<{ folderId: string }>(catalogTaskFolderPath);
  const isFolderPage = match?.isExact;

  // Get page title, breadcrumbs and folder-level task filter, if necessary
  const { breadcrumbs, folderFilter, pageTitle } = useMemo((): TaskCatalogPageConfig => {
    const breadcrumbs = [{ href: catalogTasksPath, label: "Task Catalog" }];

    if (!isFolderPage) return { pageTitle: "Task Catalog", breadcrumbs, folderFilter: {} };

    const { folderId } = match.params;
    if (folderId.includes("globalPlanTag")) {
      const tag = globalPlanTags.find((tag) => tag.id === folderId);
      const pageTitle = `${tag?.name ?? "Tag"} Tasks`;
      return {
        pageTitle,
        breadcrumbs: [...breadcrumbs, { href: match.url, label: pageTitle }],
        folderFilter: { tags: [folderId] },
      };
    } else {
      const team = businessFunctionTypes.find((team) => team.code === folderId);
      const pageTitle = `${team?.name ?? "Team"} Tasks`;
      return {
        pageTitle,
        breadcrumbs: [...breadcrumbs, { href: match.url, label: pageTitle }],
        folderFilter: { team: team ? [team.code] : [] },
      };
    }
  }, [match, isFolderPage, globalPlanTags, businessFunctionTypes]);

  const [search, setSearch] = useState<string | undefined>();

  // custom hook that allows us to set queryParams to use as a filter (so groupBy will persist)
  const { queryStorage, setQueryStorage } = useQueryStorage({
    storageKey: "taskCatalogPageFilter",
    initialQueryStorage: customTaskCatalogPageFilterDefault,
  });

  // remove query param filters that are not hooked up to the backend
  const catalogTaskFilter = useMemo(() => mapToFilter(queryStorage), [queryStorage]);

  const groupBy = useGroupBy<TaskCatalogGroupBy>({
    None: "None",
    Team: "Team",
    Tag: "Tag",
  });

  // Sync groupBy with queryStorage so it persists
  useEffect(() => {
    if (queryStorage.groupBy) {
      groupBy.setValue(queryStorage.groupBy);
    }
    // Intentionally not including "groupBy" as this causes a circular dependency error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStorage.groupBy]);

  const showFilterModal = useCallback(
    (close: VoidFunction) => {
      return (
        <TaskCatalogFilterModal
          businessFunctionTypes={businessFunctionTypes}
          markets={markets}
          developments={developments}
          setFilter={setQueryStorage}
          globalPlanTags={globalPlanTags}
          filter={catalogTaskFilter}
        />
      );
    },
    [businessFunctionTypes, globalPlanTags, markets, setQueryStorage, catalogTaskFilter, developments],
  );

  const api = useGridTableApi<Row>();
  const { openModal } = useModal();

  const selectedRows = useComputed(() => api.getSelectedRowIds("data"), [api]);

  // The selected rows will have `TagName`-`TaskId` or `TeamName`-`TaskId` ot just `TaskId` as id and we need to split them to get the task ids
  const selectedTaskIds = useMemo(
    () => selectedRows.map((id) => (id.includes("-") ? id.split("-")[1] : id)),
    [selectedRows],
  );

  return (
    <div>
      <PageHeader
        breadcrumb={breadcrumbs}
        title={pageTitle}
        right={<Button label="Create New" onClick={createTaskCatalogFormUrl()} />}
      />
      <TableActions onlyRight>
        {!isFolderPage && (
          <div>
            <SelectField
              label="Group By"
              value={groupBy.value}
              onSelect={(value) => {
                setQueryStorage({ ...catalogTaskFilter, groupBy: value });
              }}
              options={groupBy.options}
              compact
              labelStyle="inline"
              sizeToContent
            />
          </div>
        )}
        <ButtonModal content={showFilterModal} trigger={{ label: "Filter" }} />
        <SearchBox onSearch={setSearch} />
        <ButtonMenu
          trigger={{ icon: "verticalDots" }}
          items={[
            {
              label: "Add to an Existing Tag",
              onClick: () => openModal({ content: <BulkGlobalPlanTagsModal taskIds={selectedTaskIds} /> }),
              disabled: selectedTaskIds.isEmpty,
            },
            {
              label: "Add to a New Tag",
              onClick: () => openModal({ content: <AddGlobalPlanTagModal taskIds={selectedTaskIds} /> }),
              disabled: selectedTaskIds.isEmpty,
            },
            // {
            //   label: "Audit Tasks",
            //   // TODO: implement
            //   onClick: noop,
            //   disabled: true,
            // },
            {
              label: "Manage Constraints",
              onClick: createConstraintItemCatalogUrl(),
            },
            {
              label: "Visualize Task Dependencies",
              onClick: () => openModal({ content: <MermaidLinkModal /> }),
            },
          ]}
        />
      </TableActions>
      <TaskCatalogTable
        groupBy={groupBy.value}
        filter={{ name: search, ...folderFilter, ...definedFilterValues(catalogTaskFilter) }}
        api={api}
      />
    </div>
  );
}

function mapToFilter(filter: CustomTaskCatalogPageFilter): GlobalPlanTaskFilter {
  // remove groupBy so we don't send it to the backend
  const { groupBy, ...others } = filter;
  return {
    ...others,
  };
}
