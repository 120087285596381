import { CalendarOptions, EventContentArg, EventInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Css, Palette } from "@homebound/beam";
import { isSameDay } from "date-fns";
import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { SchedulingExclusionDatesFragment } from "src/generated/graphql-types";
import { DynamicTaskCalendarColorLegend } from "../../schedule-v2/components/TaskColorLegend";
import { HolidayEventContent, createHolidayCalendarEvents } from "./dynamicSchedulesCalendarUtils";

type DynamicSchedulesCalendarWrapperProps = Pick<
  CalendarOptions,
  "eventResize" | "eventDrop" | "editable" | "eventClick"
> & {
  events: EventInput[];
  eventContent?: (hookProps: EventContentArg) => ReactNode;
  calendarRef: RefObject<FullCalendar>;
  /* Blocks user interaction while true */
  loading?: boolean;
  schedulingExclusionDates?: SchedulingExclusionDatesFragment[];
};
// Copied from Beam to make FullCalendar's button groups match Beam's button styles
// TODO: Maybe on a hackday, create a fully custom view to allow using Beam components in lieu of FullCalendar defaults
const buttonStyles = {
  ...Css.z1.px2.bgWhite.bcGray300.bw1.ba.gray900.br0.$,
  "&:disabled": Css.gray400.cursorNotAllowed.bcGray300.$,
  // Our first button should have a rounded left border
  "&:first-of-type": Css.add("borderRadius", "4px 0 0 4px").$,
  // Our last button should have a rounded right border
  "&:last-of-type": Css.add("borderRadius", "0 4px 4px 0").$,
  // Nudge buttons one pixel to the left so they visually share a border
  "&:not(:first-of-type)": Css.mlPx(-1).$,
};

const baseStyles = {
  ...Css.h100
    // Only styling the top border radius. Our legend completes the card look
    .addIn(".fc-theme-standard", Css.bgWhite.bcGray100.borderRadius("8px 8px 0 0").$)
    .addIn(
      ".fc-daygrid-event, .fc-daygrid-block-event .fc-h-event fc-event .fc-event-start .fc-event-end .fc-event-past",
      Css.bn.$,
    )
    // Define width of inner calendar header cells to 100%
    .addIn(".fc .fc-col-header-cell-cushion", Css.w100.$)
    .addIn(".fc .fc-daygrid-day.fc-day-today", Css.bgBlue50.bcGray300.$)
    .addIn(".fc-highlight", Css.bgWhite.outline(`2px solid ${Palette.Blue700}`).add("outlineOffset", "-1px").$)
    .addIn(".fc", Css.p3.br8.$)
    .addIn("table.fc-scrollgrid, .fc .fc-scrollgrid-section-liquid > td", Css.br8.$)
    .addIn("h2.fc-toolbar-title", Css.xl.$)
    .addIn("div.fc-toolbar-chunk > div", Css.df.aic.$)
    .addIn("div.fc-toolbar-chunk > button.fc-today-button", Css.smMd.bgWhite.bcGray300.gray900.br4.$)
    .addIn("div.fc-toolbar-chunk > button.fc-today-button:disabled", Css.bgWhite.gray400.cursorNotAllowed.$)
    .addIn("div.fc div.fc-button-group > button, .fc .fc-button-primary:not(:disabled).fc-button-active", {
      ...Css.smMd.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-prev-button", {
      ...Css.bgWhite.bcGray300.gray900.mlPx(12).$,
      ...buttonStyles,
    })
    .addIn("button.fc-next-button", {
      ...Css.bgWhite.bcGray300.gray900.$,
      ...buttonStyles,
    })
    .addIn("button.fc-button:active:not(.fc-today-button:disabled)", Css.bgGray300.bcGray300.gray900.important.$)
    .addIn("button.fc-button:focus, button.fc-button:active", Css.important.bsh0.$)
    .addIn(".fc-timeline-event-harness", Css.top0.important.$)
    .addIn(".fc-resource-timeline table tbody tr .fc-datagrid-cell div", Css.hPx(33).important.$)
    .addIn(".fc-event-draggable", Css.add("cursor", "grab").important.$)
    // Add large visual indicator for the resize handler, hiding it until even hover or on drag
    .addIn(".fc-event-resizer-end:after", Css.vh.$)
    .addIn(".fc-event-draggable:hover > .fc-event-resizer-end:after", Css.vv.$)
    .addIn(".fc-event-dragging:hover > .fc-event-resizer-end:after", Css.vh.$)
    .addIn(".fc-event-resizer-end", Css.add("cursor", "ew-resize").important.$)
    .addIn(
      ".fc-event-resizer-end:after",
      Css.add({
        content: '"‖"',
        fontSize: "20px",
      })
        .bgBlue600.white.absolute.right("10px")
        .pxPx(5)
        .topPx(5)
        .ptPx(4)
        .borderRadius("0 2px 2px 0")
        .myPx(-5).h100.w100.z9999.$,
    )
    // ---- HB custom classes ----
    // Holiday day cells need to be grayed out UNLESS it is today
    .addIn(".hb-fc-holiday-cell, .fc-daygrid-day.hb-fc-holiday-cell", Css.bgGray100.$).$,
  "--fc-event-resizer-thickness": "18px",
};

export function DynamicSchedulesCalendarWrapper(props: DynamicSchedulesCalendarWrapperProps) {
  const {
    calendarRef,
    eventContent,
    events,
    eventDrop,
    eventResize,
    eventClick,
    editable,
    loading,
    schedulingExclusionDates = [],
  } = props;
  const [taskEventScrollTop, setTaskEventScrollTop] = useState<number>();
  const divRef = useRef<HTMLDivElement>(null);

  // Grab scrollPosition of FullCalendar scroller on `eventResize` and `eventDrop` to restore it after re-render
  function setCurrentScrollPosition() {
    const fullCalendarScroller = divRef?.current?.getElementsByClassName("fc-scroller-liquid-absolute")?.[0];
    setTaskEventScrollTop(fullCalendarScroller?.scrollTop);
  }

  useEffect(() => {
    const fullCalendarScroller = divRef?.current?.getElementsByClassName("fc-scroller-liquid-absolute")?.[0];
    if (fullCalendarScroller && taskEventScrollTop) {
      // use a timeout to push this onto the queue
      setTimeout(() => {
        fullCalendarScroller.scrollTop = taskEventScrollTop;
      }, 0);
    }
  }, [events, taskEventScrollTop]);

  return (
    <div ref={divRef} css={{ ...baseStyles, ...Css.if(loading === true).cursor("wait").$ }}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, ...(editable ? [interactionPlugin] : [])]}
        headerToolbar={{ left: "title,prev,next", center: "today,dayGridWeek,dayGridMonth", end: "" }}
        events={[...createHolidayCalendarEvents(schedulingExclusionDates), ...events]}
        eventClick={eventClick}
        // 100% of height - (pb2 of sibling + 40px max height from inner DynamicTaskColorLegend)
        height="calc(100% - 56px)"
        // Holidays always on top
        eventOrder={(a, b) => ((a as EventInput)?.extendedProps?.type === "holiday" ? -1 : 1)}
        eventOrderStrict={true}
        dayHeaderContent={({ text }) => <div css={Css.smMd.gray900.mb1.$}>{text}</div>}
        dayCellClassNames={({ date }) =>
          schedulingExclusionDates.some(({ date: hDate }) => isSameDay(hDate, date)) ? ["hb-fc-holiday-cell"] : []
        }
        dayCellContent={({ dayNumberText }) => <div css={Css.xs.blue700.$}>{dayNumberText}</div>}
        eventContent={eventContent}
        editable={editable && !loading}
        droppable={editable}
        eventResize={(args) => {
          setCurrentScrollPosition();
          eventResize?.(args);
        }}
        eventDrop={(args) => {
          setCurrentScrollPosition();
          eventDrop?.(args);
        }}
        // Note: `view` prop overrides render methods for month and week views
        views={{
          dayGridWeek: {
            // Mimic how google calendar only shows holidays within header for week view
            eventContent: (args) => args.event.extendedProps.type === "planTask" && eventContent?.(args),
            dayHeaderContent: ({ text, date }) => {
              const maybeHoliday = schedulingExclusionDates?.find(({ date: hDate }) => isSameDay(hDate, date));
              if (maybeHoliday) {
                return (
                  <div css={Css.df.fdc.gap1.aic.$}>
                    <div css={Css.smMd.gray900.$}>{text}</div>
                    <HolidayEventContent title={maybeHoliday.name} />
                  </div>
                );
              } else return <div css={Css.smMd.gray900.mb1.$}>{text}</div>;
            },
          },
        }}
      />
      <div
        // Match bgWhite of table and complete border radius to complete card appearance
        css={Css.bgWhite.pb2.borderRadius("0 0 8px 8px").$}
      >
        <DynamicTaskCalendarColorLegend
          title="Color Legend:"
          items={[
            { color: Palette.Blue500, label: "A day that this task was worked on" },
            { color: Palette.Blue200, label: "Estimated work days / No work done" },
            { color: Palette.Red200, label: "Trade - Not Sent" },
            { color: Palette.Yellow200, label: "Trade - Sent and Unconfirmed" },
            { color: Palette.Gray800, icon: "truck", label: "Material Drop", sizeOverride: 2 },
            { color: Palette.Red600, icon: "flag", label: "Delay Flag", sizeOverride: 2 },
            { color: Palette.Gray800, icon: "hardHatInverse", label: "Trade - Worked", sizeOverride: 2 },
            { color: Palette.Gray800, icon: "hardHat", label: "Trade - Scheduled", sizeOverride: 2 },
          ]}
        />
      </div>
    </div>
  );
}
