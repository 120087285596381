import {
  BoundTextField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  AssignElevationListDetailFragment,
  SaveDevelopmentOptionInput,
  useAssignElevationListQuery,
  useSaveElevationAssignmentMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";

type AssignElevationsModalProps = {
  onSave?: () => void;
};

export function AssignElevationsModalButton({ onSave }: AssignElevationsModalProps) {
  const { openModal } = useModal();
  const { developmentId } = useParams<{ developmentId: string }>();
  return (
    <Button
      label="Assign elevations"
      variant="secondary"
      onClick={() =>
        openModal({
          content: <AssignElevationModal developmentId={developmentId} onSave={onSave} />,
        })
      }
    />
  );
}

type AssignElevationFormProps = {
  developmentId: string;
  onSave?: () => void;
};

export function AssignElevationModal({ developmentId, onSave }: AssignElevationFormProps) {
  const query = useAssignElevationListQuery({ variables: { developmentId } });
  return queryResult(query, {
    data: ({ development }) => <FieldsListView development={development} onSave={onSave} />,
  });
}

type FieldsViewListProps = {
  development: AssignElevationListDetailFragment;
  onSave?: () => void;
};

function FieldsListView({ development, onSave }: FieldsViewListProps) {
  const { closeModal } = useModal();
  const [save] = useSaveElevationAssignmentMutation();
  const deliminator = "|||";

  const formState = useFormState({
    init: {
      input: development,
      map: (development) => ({
        elevationDevOps: (development.options || [])
          .map((devOption) => {
            return {
              id: devOption.id,
              name: devOption.globalOption.name,
              shortName: devOption.shortName,
              developmentId: development.id,
              globalOptionId: devOption.globalOption.id,
            };
          })
          // removed if had duplicate globalOptionId (...why would this ever happen?)
          .uniqueBy((o) => o.globalOptionId),
      }),
    },
    config: formConfig,
    addRules(state) {
      state.elevationDevOps.rules.push(() => {
        const getFormNames = formState.elevationDevOps.rows.map((row) => row.shortName.value).compact();
        const duplicate = getFormNames.filter((name, i) => getFormNames.indexOf(name) !== i);
        if (duplicate.length > 0) {
          return duplicate.join(deliminator);
        }
        return undefined;
      });
    },
  });

  const onSubmit = useCallback(
    async (formState: ObjectState<FormInput>) => {
      await save({
        variables: {
          input: {
            saveDevelopmentOptions: formState.elevationDevOps.rows.map((row) => ({
              id: row.id.value,
              shortName: row.shortName.value,
            })),
          },
        },
      });
      onSave?.();
      closeModal();
    },
    [save, onSave, closeModal],
  );

  const rows = useComputed(() => formState.elevationDevOps.rows, [formState]);

  return (
    <>
      <ModalHeader>Assign elevations</ModalHeader>
      <ModalBody>
        {rows.length > 0 ? (
          rows.map((item) => {
            return (
              <FormLines key={item.id.value}>
                <Observer>
                  {() => (
                    <BoundTextField
                      field={item.shortName}
                      labelStyle="left"
                      label={item.name.value}
                      compact
                      errorMsg={
                        (item.shortName.touched ? item.shortName.errors.join(" ") : undefined) ||
                        (item.shortName.value &&
                        formState.elevationDevOps.errors.join().split(deliminator).includes(item.shortName.value)
                          ? "Duplicate"
                          : undefined)
                      }
                    />
                  )}
                </Observer>
              </FormLines>
            );
          })
        ) : (
          <div>No Elevations</div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => <Button disabled={!formState.valid} label={"Save"} onClick={() => onSubmit(formState)} />}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = {
  elevationDevOps: AssignElevationInput[];
};

type AssignElevationInput = SaveDevelopmentOptionInput & { name: string };

const formConfig: ObjectConfig<FormInput> = {
  elevationDevOps: {
    type: "list",
    config: {
      id: { type: "value" },
      name: { type: "value" },
      shortName: { type: "value" },
      developmentId: { type: "value", rules: [required] },
      globalOptionId: { type: "value", rules: [required] },
    },
  },
};
