import { Global } from "@emotion/react";
import { Css } from "@homebound/beam";

// Applying some Blueprint global styles that differ from Beam.
export function LocalCssReset() {
  return (
    <Global
      styles={[
        // Adding in `important` to font size styling for Storybook.
        // `<CssReset />` adds in `body { line-height: inherit; }` which we override by defining `LocalCssReset` _after_ `<CssReset />`.
        // We're applying the same styles in the same order in Storybook's `preview.tsx`, but it seems to not be respecting that order
        Css.addIn("body", { ...Css.sm.important.$, ...Css.bgGray100.m0.gray800.$ }).addIn(
          "html",
          Css.add("WebkitFontSmoothing", "antialiased").$,
        ).$,
      ]}
    />
  );
}
