import { column, Css, dateColumn, GridColumn, GridDataRow, numericColumn, simpleHeader } from "@homebound/beam";
import { Link } from "react-router-dom";
import { dateCell, priceCell, QueryTable } from "src/components";
import { SectionTitle } from "src/components/contracts";
import {
  ChangeEventAssociationsQuery,
  ChangeEventChangeOrderFragment,
  ChangeEventEstimateFragment,
  Stage,
  useChangeEventAssociationsQuery,
} from "src/generated/graphql-types";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { createEstimateUrl, createHomeownerContractChangeOrderUrl } from "src/RouteUrls";
import { sortBy } from "src/utils";

type ChangeEventAssociationsTableProps = {
  changeEventId: string;
  projectId: string;
  stage: Stage;
};

export function ChangeEventAssociationsTable({ changeEventId, projectId, stage }: ChangeEventAssociationsTableProps) {
  const { clientNoun } = useProjectContext();
  const query = useChangeEventAssociationsQuery({
    variables: { filter: { id: [changeEventId], projectId: [projectId] } },
  });

  return (
    <>
      <div css={Css.mb1.$}>
        <SectionTitle title="Associated Estimates & Change Order" />
      </div>
      <QueryTable query={query} createRows={createRows} columns={createColumns(projectId, clientNoun)} />
    </>
  );
}

type HeaderRow = { kind: "header" };
type HOCORow = { kind: "hoco"; data: ChangeEventChangeOrderFragment };
type EstimateRow = { kind: "estimate"; data: ChangeEventEstimateFragment };
type Row = HeaderRow | HOCORow | EstimateRow;

function createRows(data: ChangeEventAssociationsQuery | undefined): GridDataRow<Row>[] {
  const changeEvent = data?.changeEvents[0] || undefined;
  const hoco = changeEvent?.homeownerContractChangeOrder;
  const estimates = changeEvent?.estimates;
  return [
    simpleHeader,
    ...(hoco ? [{ kind: "hoco" as const, id: hoco.id, data: hoco }] : []),
    ...(estimates
      ? sortBy(estimates, (e) => e.createdAt).map((estimate) => ({
          kind: "estimate" as const,
          id: estimate.id,
          data: estimate,
        }))
      : []),
  ];
}

function createColumns(projectId: string, clientNoun: string): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => "Name",
      hoco: ({ id, title }) => <Link to={createHomeownerContractChangeOrderUrl(projectId, id)}>{title}</Link>,
      estimate: ({ id, title }) => <Link to={createEstimateUrl(projectId, id)}>{title}</Link>,
    }),
    column<Row>({
      header: () => "Type",
      hoco: `${clientNoun} CO`,
      estimate: ({ type }) => `${type.name} Estimate`,
      w: "186px",
    }),
    dateColumn<Row>({
      header: () => "Created On",
      hoco: ({ executionDate }) => dateCell(executionDate),
      estimate: ({ createdAt }) => dateCell(createdAt),
      w: "124px",
    }),
    column<Row>({
      header: () => "Status",
      hoco: "Executed",
      estimate: ({ status }) => status.name,
      w: "112px",
    }),
    numericColumn<Row>({
      header: () => `${clientNoun} Price`,
      hoco: ({ priceChangeInCents }) => priceCell({ valueInCents: priceChangeInCents }),
      estimate: ({ totalPriceInCents }) => priceCell({ valueInCents: totalPriceInCents }),
      align: "right",
      w: "168px",
    }),
  ];
}
