import {
  Button,
  ButtonMenu,
  Css,
  FilterDefs,
  Filters,
  IconButton,
  RightPaneLayout,
  ScrollableContent,
  Switch,
  useRightPane,
  useSnackbar,
} from "@homebound/beam";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import { AssetInfoFragment, JobLogDetailFragment, JobLogImageDetailFragment } from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { AssetPreview } from "src/routes/projects/assets/AssetPreview";
import { HomeownerNoteRightPane } from "src/routes/projects/job-logs/components/HomeownerNoteRightPane";
import { downloadFilesToZip } from "src/utils/downloader";
import { JobLogFilterType } from "../JobLogsPage";
import { JobLogNoRowsImage } from "./JobLogsNoRowsImage";

type JobLogsGalleryViewProps = {
  jobLogs: JobLogDetailFragment[];
  setGallery: (gallery: boolean) => void;
  filterDefs: FilterDefs<JobLogFilterType>;
  filter: JobLogFilterType;
  numberOfInlineFilters: number;
  setFilter: (filter: JobLogFilterType) => void;
  zipName?: string;
  maybeFetchNextPage: () => Promise<void>;
  hasNextPage: boolean;
};

export function JobLogsGalleryView({
  jobLogs,
  setGallery,
  filterDefs,
  filter,
  numberOfInlineFilters,
  setFilter,
  zipName,
  maybeFetchNextPage,
  hasNextPage,
}: JobLogsGalleryViewProps) {
  const { closeRightPane, openRightPane } = useRightPane();
  const [paneOpen, setPaneOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<CustomAsset[]>([]);
  const { triggerNotice } = useSnackbar();
  const projectImages = jobLogs.flatMap((jobLog) => [
    ...jobLog.images.map(mapImage(jobLog)),
    ...jobLog.notes.flatMap((note) => [...(note.jobLogImages?.map(mapImage(jobLog, note.content)) || [])]),
  ]);

  // wrapping openRightPane in callback and useEffect to allow continuous image selection w/ open pane
  const openRightPaneWrapper = useCallback(() => {
    setPaneOpen(true);
    openRightPane({
      content: (
        <HomeownerNoteRightPane
          selectedImages={selectedImages}
          onClose={() => {
            closeRightPane();
            setPaneOpen(false);
          }}
          onSuccess={() => {
            closeRightPane();
            setPaneOpen(false);
            setSelectedImages([]);
          }}
        />
      ),
    });
  }, [closeRightPane, openRightPane, selectedImages]);
  useEffect(() => {
    if (paneOpen) {
      openRightPaneWrapper();
    }
  }, [paneOpen, openRightPaneWrapper]);

  return (
    <div data-testid="jobLogsGalleryView">
      <PageHeader
        title="Job Logs"
        left={
          <>
            <Filters<JobLogFilterType>
              filter={filter}
              onChange={setFilter}
              filterDefs={filterDefs}
              numberOfInlineFilters={numberOfInlineFilters}
            />
            <Switch label="Gallery" selected onChange={setGallery} labelStyle="inline" />
          </>
        }
        right={
          <ButtonMenu
            key="actions"
            items={[
              {
                label: "Create H/O Note",
                onClick: openRightPaneWrapper,
              },
              {
                label: "Download",
                disabled: selectedImages.length === 0,
                onClick: async () => {
                  await downloadFilesToZip(
                    `${zipName} Images.zip`,
                    selectedImages.map((image) => ({
                      filename: image.fileName!,
                      url: image.downloadUrl,
                    })),
                  );
                  triggerNotice({ message: "Selected item(s) have been downloaded.", icon: "success" });
                  setSelectedImages([]);
                },
              },
              {
                label: "Download All",
                disabled: projectImages.length === 0,
                onClick: async () => {
                  await downloadFilesToZip(
                    `${zipName} Images.zip`,
                    projectImages.map((image) => ({
                      filename: image.fileName!,
                      url: image.downloadUrl,
                    })),
                  );
                },
              },
            ]}
            trigger={{ label: "Actions" }}
          />
        }
      />
      <div css={Css.mt(-3).h100.$} data-testid="jobLogsPageGalleryWrapper">
        <ScrollableContent virtualized>
          {projectImages.nonEmpty ? (
            <RightPaneLayout>
              <div
                css={
                  Css.addIn("> div:first-of-type > div:first-of-type", Css.dg.gtc("repeat(auto-fill, 200px)").jcc.$).$
                }
              >
                <AssetGallery
                  assets={projectImages}
                  caption={({ createdAt, projectName, content = "" }) =>
                    `Uploaded for ${projectName} on ${createdAt && format(createdAt, "EEEE, MMMM d")} ${content}`
                  }
                >
                  {(openGallery) => (
                    <>
                      {projectImages.map((asset, idx) => (
                        <div
                          key={asset.id}
                          css={
                            Css.relative.oh
                              .mPx(2)
                              .pPx(2)
                              .br4.bss.bcWhite.bw2.addIn("> img ", Css.objectCover.$)
                              .addIn("> .galleryButton", Css.vh.$)
                              .addIn(":hover > .galleryButton", Css.vv.$)
                              .if(selectedImages.some(({ id }) => id === asset.id)).bcBlue600.$
                          }
                          data-testid="assetGalleryItem"
                        >
                          <div css={Css.absolute.m1.right0.$} className="galleryButton">
                            <IconButton contrast icon="expand" onClick={() => openGallery(asset)} />
                          </div>
                          <AssetPreview
                            asset={asset}
                            dimensions={{ width: 0, height: 200 }}
                            onClick={() =>
                              setSelectedImages(
                                selectedImages.some(({ id }) => id === asset.id)
                                  ? selectedImages.filter(({ id }) => id !== asset.id)
                                  : [...selectedImages, asset],
                              )
                            }
                          />
                        </div>
                      ))}
                    </>
                  )}
                </AssetGallery>
                {hasNextPage && (
                  <div css={Css.df.jcc.mt1.mb1.p2.$}>
                    <Button label="Load More" data-testid="jobLogsGalleryLoadMore" onClick={maybeFetchNextPage} />
                  </div>
                )}
              </div>
            </RightPaneLayout>
          ) : (
            <div css={Css.df.fdc.aic.$}>
              <div css={Css.ptPx(36).pb5.$}>
                <JobLogNoRowsImage />
              </div>
              <div css={Css.base.pb2.$}>No pictures found for this project.</div>
            </div>
          )}
        </ScrollableContent>
      </div>
    </div>
  );
}

// Override the asset created date to use the job log date on the asset gallery caption
function mapImage(jobLog: JobLogDetailFragment, content?: string | undefined) {
  return (image: JobLogImageDetailFragment): CustomAsset => ({
    ...image.asset,
    id: image.id,
    createdAt: image.createdAt,
    projectName: jobLog.project.name,
    content: content,
  });
}

export type CustomAsset = AssetInfoFragment & { projectName: string; content: string | undefined };
