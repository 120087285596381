import { Css, FilterDefs, Filters, RouteTabWithContent, Tabs, useBreakpoint, useTestIds } from "@homebound/beam";
import { useRouteMatch } from "react-router";
import { personalDashboardPaths } from "src/routes/routesDef";
import { useDashboardFilterContext } from "../DashboardFilterContext";
import { PersonalDashboardFilter } from "../useHeaderFilterDefs";
import { HeaderOverview } from "./HeaderOverview";
import { HeaderSummary } from "./HeaderSummary";

type PersonalDashboardHeaderProps = {
  filterDefs: FilterDefs<PersonalDashboardFilter>;
  tabs: RouteTabWithContent<string>[];
  section?: string;
};

export const PersonalDashboardHeader = ({ filterDefs, tabs, section }: PersonalDashboardHeaderProps) => {
  const { filter, setFilter, fullScreen } = useDashboardFilterContext();
  const { sm } = useBreakpoint();
  const isDashboardActivityPage = !!useRouteMatch([personalDashboardPaths.activity]);
  const tids = useTestIds({}, `personalDashboardHeader_${section}`);
  return (
    <>
      <header css={Css.bgWhite.pt3.bw1.px3.bcGray200.if(fullScreen).dn.if(sm).p2.pb0.$} data-testid="dashboardHeader">
        <section css={Css.df.jcsb.mb2.gap2.ifMdAndDown.fdc.mb1.gap1.$}>
          <HeaderOverview />
          <div css={Css.df.aic.if(sm).oa.wsnw.$}>
            <HeaderSummary />
          </div>
        </section>
        <section css={Css.df.jcsb.gap2.ifMdAndDown.fdc.fdcr.$}>
          <Tabs tabs={tabs} includeBottomBorder contentXss={Css.bgRed100.$} />
          <div css={Css.p1.$}>
            <Filters
              filter={filter}
              onChange={setFilter}
              filterDefs={filterDefs}
              numberOfInlineFilters={isDashboardActivityPage ? 3 : sm ? 1 : 4}
              {...tids.filter}
            />
          </div>
        </section>
      </header>
    </>
  );
};
