import { IObservableArray, observable } from "mobx";
import { observableAggregate } from "src/models";
import { makeSimpleAutoObservable } from "src/utils/makeSimpleAutoObservable";
import { LineItemRollup } from "./LineItemRollup";
import { LineItemWithCommitmentId, ObservableLineItem } from "./ObservableLineItem";

export class LineItemStore extends LineItemRollup<ObservableLineItemGroup> {
  readonly lineItems: IObservableArray<ObservableLineItem>;
  readonly groupBy: "costCode" | "project";

  constructor(clis: LineItemWithCommitmentId[], groupBy: "costCode" | "project") {
    const lineItems = observable(clis.map((cli) => new ObservableLineItem(cli)));
    let groups: ObservableLineItemGroup[];
    if (groupBy === "costCode") {
      groups = observableAggregate(lineItems, (cli) => cli.costCode, ObservableLineItemCostCode);
    } else {
      groups = observableAggregate(lineItems, (cli) => cli.projectIds[0], ObservableLineItemProject);
    }
    super("total", "Total", groups);
    makeSimpleAutoObservable(this);
    this.lineItems = lineItems;
    this.groupBy = groupBy;
  }

  get groupedBy() {
    return this.children;
  }

  get selectedLineItems() {
    return this.lineItems.filter((item) => item.selected);
  }
}

export type ObservableLineItemGroup = ObservableLineItemProject | ObservableLineItemCostCode;

class ObservableLineItemProject extends LineItemRollup<ObservableLineItem> {
  constructor(clis: ObservableLineItem[]) {
    const { id, projectName } = clis[0];
    super(id, projectName, clis);
    makeSimpleAutoObservable(this);
  }
}

class ObservableLineItemCostCode extends LineItemRollup<ObservableLineItem> {
  constructor(clis: ObservableLineItem[]) {
    const { id, costCode } = clis[0];
    super(id, costCode, clis);
    makeSimpleAutoObservable(this);
  }
}
