import {
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  SimpleHeaderAndData,
  column,
  simpleDataRows,
} from "@homebound/beam";
import { useMemo } from "react";
import { createInvoiceTermUrl } from "src/RouteUrls";
import { dateCell } from "src/components";
import { InvoiceTerms_InvoiceScheduleTemplatesFragment, useInvoiceTermsQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export function InvoiceTermsTable() {
  const query = useInvoiceTermsQuery();

  return queryResult(query, {
    data: (data) => <InvoiceTermsTableView invoiceScheduleTemplates={data.invoiceScheduleTemplates} />,
  });
}

type InvoiceTermsTableViewProps = {
  invoiceScheduleTemplates: InvoiceTerms_InvoiceScheduleTemplatesFragment[];
};

export function InvoiceTermsTableView({ invoiceScheduleTemplates }: InvoiceTermsTableViewProps) {
  const rows = useMemo(() => createRows(invoiceScheduleTemplates), [invoiceScheduleTemplates]);
  const columns = useMemo(() => createColumns(), []);
  return <GridTable rows={rows} columns={columns} style={{ allWhite: true }} rowStyles={createRowStyles()} />;
}

type Row = SimpleHeaderAndData<InvoiceTerms_InvoiceScheduleTemplatesFragment>;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({ header: "Term name", data: (row) => row.name }),
    column<Row>({ header: "Product Type", data: (row) => row.lotType.code }),
    column<Row>({ header: "Customer", data: (row) => row.customer?.name }),
    column<Row>({ header: "Created On", data: (row) => dateCell(row.createdAt) }),
  ];
}

function createRows(data: InvoiceTerms_InvoiceScheduleTemplatesFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(data);
}

function createRowStyles(): RowStyles<Row> {
  return { header: {}, data: { rowLink: ({ id }) => createInvoiceTermUrl(id) } };
}
