import { BaselineIntervalDetailsFragment, Maybe, ScheduleIntervalDetailsFragment } from "src/generated/graphql-types";
import { formatWithShortYear } from "src/utils/dates";

type DateCellReadOnlyProps = {
  interval: Maybe<ScheduleIntervalDetailsFragment> | Maybe<BaselineIntervalDetailsFragment>;
  field: "startDate" | "endDate";
  testId?: string;
};

export function DateCellReadOnly({ interval, field, testId }: DateCellReadOnlyProps) {
  const intervalField = interval ? interval[field] : undefined;
  return intervalField === undefined ? null : (
    <div data-testid={testId}>{intervalField && formatWithShortYear(intervalField)}</div>
  );
}
