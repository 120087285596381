import {
  Button,
  Css,
  FilterDefs,
  Filters,
  multiFilter,
  ScrollableContent,
  singleFilter,
  useSuperDrawer,
} from "@homebound/beam";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SearchBox } from "src/components";
import { baseDownloadUrl } from "src/context";
import {
  BudgetPage_ProjectItemFragment,
  BudgetSummaryQuery,
  CostClassificationType,
  ProjectItemFilter,
  Stage,
  useBudgetSummaryQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { BudgetTable } from "src/routes/projects/budget/BudgetPageTable";
import { hasData, queryResult, renderLoadingOrError } from "src/utils/queryResult";
import { NoSignedContractScreen } from "../components/NoSignedContractScreen";
import { BudgetHeroMetrics } from "./components/BudgetHeroMetrics";
import { useStorageFilter } from "./components/useStorageFilter";

type RouteParams = {
  projectId: string;
};

/**
 * Provides a summary of a project's budget.
 *
 * In theory this should be per-stage, but currently we only do budgets in PreCon, so we're
 * punting on per-stage filtering. Also construction budget summaries will probably be
 * calculated server-side anyway.
 */
export function BudgetPage() {
  const { projectId } = useParams<RouteParams>();
  const [filteredPIs, setFilteredPIs] = useState<BudgetPage_ProjectItemFragment[]>([]);
  const query = useBudgetSummaryQuery({
    variables: { projectId },
  });
  const { data } = query;
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const { closeDrawer, isDrawerOpen } = useSuperDrawer();
  const filterDefs = useMemo(() => createFilterDefs(data?.project), [data?.project]);
  const { setFilter, filter } = useStorageFilter<BudgetPageFilter>({
    storageKey: "budgetPageFilter",
    filterDefs,
  });

  /**
   * When un-mounting this page, close the budget Superdrawer.
   * This is to prevent having a SuperDrawer stay open when navigating multiple
   * pages back/forward as the SuperDrawer is outside the ReactRouter scope.
   */
  useEffect(() => {
    return function unmountBudgetPage() {
      isDrawerOpen && closeDrawer();
    };
  }, [closeDrawer, isDrawerOpen]);

  return queryResult(query, (data) => (
    <>
      <PageHeader title="Budget" />
      {data?.project.stages.some((s) => s.hasSignedContract) && (
        <BudgetHeroMetrics projectItems={filteredPIs} lotType={data.project.lotType?.code} />
      )}
      <TableActions>
        {/* Wrapping in a div because `TableActions` uses flex layout, which stretches the children by default and we don't want the SelectField to stretch*/}
        <div css={Css.df.fdr.cg1.$}>
          <Filters<BudgetPageFilter> filter={filter} filterDefs={filterDefs} onChange={setFilter} />
        </div>
        <div css={Css.df.gap1.$}>
          <SearchBox onSearch={setSearchFilter} />
          <Button
            download
            label="Download"
            onClick={`${baseDownloadUrl()}/csv?type=budget&${data?.project.stages
              // download the filtered stages
              .filter((s) => !filter?.stage || filter?.stage === s.stage.code)
              .map((s) => `projectStageId=${s.id}`)
              .join("&")}`} // --> `csv?type=budget&projectStageId=ps:1&projectStageId=ps:2`
            variant="tertiary"
          />
        </div>
      </TableActions>
      {!hasData(query) ? (
        renderLoadingOrError(query)
      ) : data?.project.stages
          // If there is a stage filter make sure theres a signed contract
          // Else render a no signed contract state
          .filter((s) => !filter.stage || s.stage.code === filter.stage)
          .some((s) => s.hasSignedContract) ? (
        <ScrollableContent>
          <BudgetTable data={data} searchFilter={searchFilter} filter={filter} setFilteredPIs={setFilteredPIs} />
        </ScrollableContent>
      ) : (
        <NoSignedContractScreen {...{ projectId }} />
      )}
    </>
  ));
}

function createFilterDefs(project: BudgetSummaryQuery["project"] | undefined): FilterDefs<BudgetPageFilter> {
  const stages = project?.stages.map((s) => ({ id: s.stage.code, name: s.stage.name })) || [];
  const costCodes = project?.stages.flatMap((s) => s.projectItems.map((pi) => pi.item.costCode)).unique() || [];
  const costClasses = costCodes.map((cc) => cc?.costClassification).unique();

  const costClassification = multiFilter({
    options: costClasses,
    label: "Classifications",
    getOptionValue: (type) => type?.code as CostClassificationType,
    getOptionLabel: (type) => type?.name || "",
    disabled: costClasses.compact().isEmpty && <div>No cost classifications to select</div>,
  });
  const stage = singleFilter({
    options: stages,
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.name,
  });
  const costCode = multiFilter({
    options: costCodes,
    label: "Cost Code",
    getOptionValue: (costCode) => costCode.id,
    getOptionLabel: (costCode) => costCode.displayName,
  });
  return {
    costClassification,
    stage,
    costCode,
  };
}

export type BudgetPageFilter = ProjectItemFilter & { stage: Stage };
