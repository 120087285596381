import {
  Button,
  Checkbox,
  checkboxFilter,
  Css,
  FilterDefs,
  Filters,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSize,
  multiFilter,
  Palette,
  TextField,
  ToggleChips,
  Tooltip,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useEffect, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import {
  BidContractItemsFilter,
  BidContractPotentialItem,
  Development,
  DevelopmentTemplateItemsModalBidContractItemFragment,
  newBidContractPotentialItem,
  useDevelopmentTemplateItemsModalMetadataQuery,
  useDevelopmentTemplateItemsModalQuery,
} from "src/generated/graphql-types";
import { isDefined, sortBy } from "src/utils";

/** We send back both the low-level ITIs, plus the higher-level Items which are what the user actually selected. */
export type DevelopmentTemplateItemsModalOnSave = (
  potentialItem: DevelopmentTemplateItemsModalBidContractItemFragment[],
  filter: BidContractItemsFilter,
  includeCost: boolean,
  fileName?: string,
) => void;

type DevelopmentTemplateItemsModalProps = {
  confirmationButtonText: string;
  excludedItiIds?: string[];
  developmentId: string;
  onSave: DevelopmentTemplateItemsModalOnSave;
  headerText: string;
  selectAllText: string;
  subHeader?: React.ReactNode;
};

/** Provides a modal to select the items/ITIs available for bid in a development. */
export function DevelopmentTemplateItemsModal(props: DevelopmentTemplateItemsModalProps) {
  const { confirmationButtonText, developmentId, excludedItiIds, onSave, headerText, selectAllText, subHeader } = props;
  const [filter, setFilter] = useState<BidContractItemsFilter>({});
  const metadata = useDevelopmentTemplateItemsModalMetadataQuery({ variables: { developmentId } });
  const query = useDevelopmentTemplateItemsModalQuery({
    variables: { developmentId, filter: { excludeItemTemplateItemId: excludedItiIds, ...filter } },
  });
  const [selected, setSelected] = useState<DevelopmentTemplateItemsModalBidContractItemFragment[]>([]);
  const [filterTerm, setFilterTerm] = useState("");
  const [customFileName, setCustomFileName] = useState<string>();
  const [includeCost, setIncludeCost] = useState(false);
  const { closeModal } = useModal();
  const tid = useTestIds({}, "DevelopmentTemplateItemsModal");

  const uniqueItems = useMemo(() => query.data?.bidContractItems.filter((i) => isDefined(i.item)) ?? [], [query]);

  const handleToggle = (item: DevelopmentTemplateItemsModalBidContractItemFragment): void => {
    setSelected((current) => (current.includes(item) ? current.filter((c) => c !== item) : current.concat(item)));
  };

  const filteredSortedItems = sortBy(
    uniqueItems.filter(({ item }) => {
      return item?.fullCode.includes(filterTerm) || item?.name.toLowerCase().includes(filterTerm);
    }),
    ({ item }) => item?.fullCode,
  );

  const allSelected = selected.length === uniqueItems.length;

  const hasAnyUpdated = uniqueItems.some((i) => i.needsBid);
  const costCodes = uniqueItems
    .map((i) => i.item!.costCode)
    .filter(isDefined)
    .unique();

  const readyPlans = useMemo(() => metadata.data?.readyPlans ?? [], [metadata.data?.readyPlans]);
  const readyPlanOptions = useMemo(
    () => metadata.data?.readyPlans.flatMap((p) => p.options) ?? [],
    [metadata.data?.readyPlans],
  );

  const filterDefs: FilterDefs<BidContractItemsFilter> = useMemo(
    () => ({
      costCodeId: multiFilter({
        label: "Cost Code",
        options: costCodes,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ displayName }) => displayName,
      }),
      readyPlanId: multiFilter({
        label: "Plans",
        options: readyPlans,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ displayName }) => displayName,
      }),
      readyPlanOptionId: multiFilter({
        label: "Options",
        options: readyPlanOptions,
        getOptionValue: ({ id }) => id,
        getOptionLabel: ({ displayName }) => displayName,
      }),
      updatedOnly: checkboxFilter({
        label: "Updated items only",
      }),
    }),
    [costCodes, readyPlanOptions, readyPlans],
  );

  // Rematch the selected items to the latest data
  useEffect(() => {
    if (!query.loading)
      setSelected((selected) =>
        selected.map((s) => uniqueItems.find((i) => i.item.id === s.item.id) ?? undefined).compact(),
      );
  }, [query.loading, uniqueItems]);

  const chips = useMemo(() => sortBy(selected, ({ item }) => item?.fullCode), [selected]);

  return (
    <>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalBody virtualized>
        <div css={Css.pr3.$}>
          {subHeader}
          <Checkbox
            label={selectAllText}
            selected={selected.length > 0 && allSelected}
            disabled={selected.length !== 0 && !allSelected} // Disable if some items selected, enable if 0 or All items selected
            onChange={(isSelected) => setSelected(isSelected ? sortBy(uniqueItems, ({ item }) => item!.fullCode) : [])}
          />
          <div css={Css.mt2.$}>
            <Checkbox
              data-testid="includeCost"
              label="Include current ReadyPlan template costs"
              selected={includeCost}
              onChange={() => setIncludeCost((val) => !val)}
            />
          </div>
          <div css={Css.mt2.w50.$}>
            <TextField
              data-testid="customFileName"
              label="File Name"
              onChange={setCustomFileName}
              value={customFileName}
              compact
            />
          </div>
          <ToggleChips
            values={chips}
            getLabel={({ item }) => item!.fullCode}
            onRemove={(item) => handleToggle(item)}
            xss={Css.my2.$}
          />
          <div css={Css.pb2.$}>
            <Filters filterDefs={filterDefs} filter={filter} onChange={setFilter} />
          </div>
          <div css={Css.pb2.$}>
            <SearchBox
              onSearch={(term) => setFilterTerm(term.toLowerCase())}
              placeholder="Search line items"
              fullWidth
              clearable
            />
          </div>
          {query.loading ? (
            <div css={Css.tac.baseMd.$}>Loading...</div>
          ) : (
            <div css={Css.maxhPx(310).oya.$}>
              {filteredSortedItems.map((pItem) => (
                <div
                  key={pItem.item?.id}
                  onClick={() => handleToggle(pItem!)}
                  data-testid="itemRow"
                  css={
                    Css.df.fdr.gap1.cursorPointer.ba.br4.p1.mb1.usn
                      .bc(selected.includes(pItem) ? Palette.Blue700 : "lightgray")
                      .bw(selected.includes(pItem) ? "2px" : "1px").$
                  }
                >
                  <div css={Css.df.aic.gap1.$}>
                    {hasAnyUpdated &&
                      (pItem.needsBid ? (
                        <Tooltip title="This line item has been updated on one or more templates">
                          <Icon icon="errorCircle" inc={2} color={Palette.Gray900} />
                        </Tooltip>
                      ) : (
                        <div css={Css.w2.h2.$} />
                      ))}
                    <div css={Css.smMd.gray900.$}>{pItem.item!.fullCode}</div>
                  </div>
                  <div css={Css.gray700.$}>{pItem.item!.name}</div>
                </div>
              ))}
              {!filteredSortedItems.length && <div css={Css.tac.baseMd.$}>No matches</div>}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={() => closeModal()} />
        <Button
          {...tid.confirm}
          label={confirmationButtonText}
          disabled={!selected.length}
          onClick={() => {
            // Has a fullCode matching any selection
            onSave(selected, filter, includeCost, customFileName);
          }}
        />
      </ModalFooter>
    </>
  );
}

export const DevelopmentTemplateItemsModalSize = {
  width: "l" as ModalSize,
  height: 700,
};

// Backend mockup for tests
export function getUniqueBidContractPotentialItems(development: Development): BidContractPotentialItem[] {
  const itemTemplates = development.itemTemplates;
  const itemVersions = itemTemplates.flatMap((itemTemplate) => itemTemplate.itemVersions);
  const items = itemVersions.map((itemVersion) => itemVersion.item);
  const uniqueItems = items.uniqueByKey("fullCode");
  return uniqueItems.map((item) =>
    newBidContractPotentialItem({
      item,
      templateItemIds: itemVersions.filter((iv) => iv.item === item).map((iv) => iv.scope.id),
      needsBid: false,
    }),
  );
}
