import {
  BoundSelectField,
  BoundTextAreaField,
  BoundTextField,
  Css,
  FormLines,
  ScrollableContent,
  StaticField,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { FormActions } from "src/components";
import { FormMode } from "src/components/formFields";
import {
  ItemTemplateStatus,
  Named,
  SaveItemTemplateInput,
  SettingsItemTemplateFragment,
  Stage,
  useSaveItemTemplateMutation,
} from "src/generated/graphql-types";
import { useToggle } from "src/hooks";
import { createItemTemplateUrl } from "src/RouteUrls";
import { pushInNextLoop, safeEntries, stageCodeToNameMapper } from "src/utils";
import { ObjectConfig, required, useFormState } from "src/utils/formState";

export type ItemTemplateDetailsProps = {
  developments?: Named[] | null;
  itemTemplate: SettingsItemTemplateFragment | undefined;
};

export function ItemTemplateDetailsTab(props: ItemTemplateDetailsProps) {
  const { developments, itemTemplate } = props;

  // Cancel when creating closing the drawer, or maybe eventually a modal?
  const [saveItemTemplate] = useSaveItemTemplateMutation({ refetchQueries: ["ItemTemplates"] });
  // Locally control edit flag; eventually update mode should auto-save?...
  const [edit, toggleEdit] = useToggle(false);
  const history = useHistory();

  const isNew = !itemTemplate;
  const mode: FormMode = isNew ? "create" : edit ? "update" : "read";
  const readOnly = mode === "read";

  const formState = useFormState({
    config: formConfig,
    init: {
      input: itemTemplate,
      map: (itemTemplate) => ({
        id: itemTemplate.id,
        description: itemTemplate.description,
        developmentId: itemTemplate.development?.id,
        name: itemTemplate.name,
        stage: itemTemplate.stage,
        status: itemTemplate.status,
      }),
    },
    readOnly,
  });

  return (
    <ScrollableContent>
      <div css={Css.df.aifs.gap2.bgWhite.p2.bshBasic.br8.w("fit-content").$}>
        <FormLines width="md">
          <BoundTextField field={formState.name} />
          {!isNew && formState.value.stage === Stage.PreConExecution && formState.value.developmentId ? (
            <StaticField label="Stage" value={stageCodeToNameMapper[formState.value.stage!]} />
          ) : (
            <BoundSelectField
              field={formState.stage}
              options={[{ code: Stage.PreConExecution }, { code: Stage.Construction }]}
              getOptionLabel={({ code }) => stageCodeToNameMapper[code]}
              getOptionValue={({ code }) => code}
              onSelect={(value) => {
                formState.stage.set(value);
                value === Stage.Construction && formState.developmentId.set(undefined);
              }}
            />
          )}
          {!isNew ? (
            <StaticField
              label="Development"
              value={formState.value.developmentId ? itemTemplate?.development?.name : "None"}
            />
          ) : (
            <Observer>
              {() => (
                <BoundSelectField
                  disabled={
                    formState.value.stage !== Stage.PreConExecution
                      ? "Only Pre-Con templates may be associated to a development from Libraries."
                      : undefined
                  }
                  helperText={"Once a template has been associated to a development it cannot be changed."}
                  field={formState.developmentId}
                  // allow unset when creating template if development chosen mistakenly
                  options={developments ?? []}
                  readOnly={readOnly}
                  unsetLabel="None"
                />
              )}
            </Observer>
          )}
          <BoundSelectField
            field={formState.status}
            // Disable system draft as assignable template status
            options={safeEntries(ItemTemplateStatus).filter(([_, v]) => v !== ItemTemplateStatus.SystemDraft)}
            getOptionLabel={([name]) => name}
            getOptionValue={([_, status]) => status}
          />
          <BoundTextAreaField field={formState.description} />
        </FormLines>
        <FormActions
          mode={mode}
          formState={formState}
          onEdit={toggleEdit}
          onSave={async () => {
            const result = await saveItemTemplate({ variables: { input: { ...formState.changedValue } } });
            if (isNew) {
              pushInNextLoop(history, createItemTemplateUrl(result.data?.saveItemTemplate.itemTemplate.id));
            } else {
              toggleEdit();
            }
          }}
          onCancel={() => {
            if (isNew) {
              history.goBack();
            } else {
              toggleEdit();
            }
          }}
        />
      </div>
    </ScrollableContent>
  );
}

const formConfig: ObjectConfig<SaveItemTemplateInput> = {
  id: { type: "value" },
  description: { type: "value" },
  name: { type: "value", rules: [required] },
  developmentId: { type: "value" },
  stage: { type: "value", rules: [required] },
  status: { type: "value", rules: [required] },
};
