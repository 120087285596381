import { Css, RadioGroupField, StaticField, TextField } from "@homebound/beam";
import { Observer } from "mobx-react";
import { Fragment } from "react";
import { Maybe } from "src/generated/graphql-types";
import { maxExternalAccountingNumber } from "src/routes/projects/commitments/utils";
import { FieldState } from "src/utils/formState";

type AccountingNumberProps = {
  field: FieldState<number | null | undefined>;
};

enum Source {
  External = "External",
  Blueprint = "Blueprint",
}

export function AccountingNumber(props: AccountingNumberProps) {
  const { field } = props;

  return (
    <Observer>
      {() => {
        const source = getSource(field.value);
        return (
          <div>
            {field.readOnly ? (
              <StaticField label="Accounting PO&#35;">
                {field.value ? (
                  <Fragment>
                    {field.value} {source === Source.External && <span css={Css.gray700.$}>(External)</span>}
                  </Fragment>
                ) : (
                  <em>No number selected</em>
                )}
              </StaticField>
            ) : (
              <>
                <RadioGroupField
                  label="Accounting PO&#35;"
                  options={[
                    { label: "Use Blueprint Number", value: Source.Blueprint },
                    { label: "Use External Number", value: Source.External },
                  ]}
                  value={source}
                  onBlur={() => field.blur()}
                  onFocus={() => field.focus()}
                  onChange={(v) => {
                    // If we changed the source back to the original source, then reset to the original value.
                    // If source is now blueprint, then set the value to null. This will tell the backend we need a new accounting number.
                    // Otherwise set it to be 0. This is a bit of a hack since both null and undefined are reserved for representing a Blueprint accounting number.
                    // We enforce 0 is not a valid accountingNumber in the `accountingNumberRule`
                    field.set(
                      v === getSource(field.originalValue) ? field.originalValue : v === Source.Blueprint ? null : 0,
                    );

                    field.maybeAutoSave();
                  }}
                />

                {source === Source.External && (
                  <TextField
                    label="External Accounting PO#"
                    labelStyle="hidden"
                    placeholder="External Accounting PO#"
                    // If value is 0, null or undefined, then show an empty string.
                    value={field.value ? field.value.toString() : ""}
                    onChange={(value) => value && field.set(Number(value.replace(/\D/g, "")))}
                    errorMsg={field.touched ? field.errors.join(" ") : undefined}
                    onBlur={() => field.blur()}
                    onFocus={() => field.focus()}
                  />
                )}
              </>
            )}
          </div>
        );
      }}
    </Observer>
  );
}

function getSource(value: Maybe<number>): Source {
  // Specifying `undefined` and `null` here to allow the case where value is `0` to be External.
  return value !== undefined && value !== null && value < maxExternalAccountingNumber
    ? Source.External
    : Source.Blueprint;
}
