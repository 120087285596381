import {
  BoundDateField,
  BoundTextField,
  Button,
  Css,
  IconButton,
  Palette,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import {
  IncrementalCollectionOp,
  Maybe,
  ProjectFeature,
  SchedulingExclusionDatesFragment,
  useDevelopmentHolidayCard_AllProjectsQuery,
  useSaveDevelopmentsMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { DateOnly, ensureDateOnly } from "src/utils/dates";

type DevelopmentHolidayCardProps = {
  developmentId: string;
  schedulingExclusionDates: SchedulingExclusionDatesFragment[];
};

type FormConfigProps = {
  holidays: {
    id?: string;
    name?: Maybe<string>;
    date?: Maybe<Date>;
    delete?: boolean;
  }[];
};

const devHolidayFormConfig: ObjectConfig<FormConfigProps> = {
  holidays: {
    type: "list",
    config: {
      id: { type: "value" },
      delete: { type: "value" },
      date: { type: "value", rules: [required] },
      name: { type: "value", rules: [required] },
    },
  },
};

export function DevelopmentHolidayCard({ developmentId, schedulingExclusionDates }: DevelopmentHolidayCardProps) {
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();

  const [saveDevelopments, { data }] = useSaveDevelopmentsMutation();

  const { data: projectData } = useDevelopmentHolidayCard_AllProjectsQuery({
    variables: {
      filter: { development: [developmentId] },
    },
  });

  const [saveErrors, setSaveErrors] = useState<string[]>();

  const devHolidayFormState = useFormState({
    config: devHolidayFormConfig,
    init: {
      input: data?.saveDevelopments?.developments[0].schedulingExclusionDates ?? schedulingExclusionDates,
      map: (input) => ({
        holidays: input.sortByKey("date").map((holiday) => ({
          id: holiday.id,
          date: ensureDateOnly(holiday.date),
          name: holiday.name,
        })),
      }),
    },
  });

  async function saveHolidays() {
    // Commit changes to formState to get rid of render warnings when `DevelopmentDetail` is re-fetched
    const devHolidays = devHolidayFormState.holidays.value.map((holiday) => ({
      id: holiday?.id,
      date: new DateOnly(holiday.date!),
      name: holiday.name!,
      op: holiday.delete ? IncrementalCollectionOp.Delete : IncrementalCollectionOp.Include,
    }));
    devHolidayFormState.commitChanges();
    setSaveErrors(undefined);
    await saveDevelopments({
      variables: {
        developments: {
          developments: [
            {
              id: developmentId,
              schedulingExclusionDates: devHolidays,
            },
          ],
        },
      },
      // Capture errors to display them in a more readable list format since one bad date on a holiday can trigger up
      // to 7 making them unreadable in our default banner.
      onError: (e) => {
        const uniqueGqlErrors = [...new Set(e.graphQLErrors.map((error) => error.message))];
        setSaveErrors(uniqueGqlErrors);
      },
      onCompleted: () => triggerNotice({ message: "Development wide holidays saved" }),
    });
  }

  return (
    <div css={Css.bgWhite.boxShadow("0px 0px 13px 0px rgba(0, 0, 0, 0.05)").br8.bw1.w100.maxwPx(680).py2.px3.$}>
      <Observer>
        {() => (
          <div css={Css.df.fdc.gap3.$}>
            <div css={Css.df.jcsb.$}>
              <div css={Css.gray900.lg.fw6.$}>Holidays</div>
              <div css={Css.df.gap1.$}>
                <Button
                  variant="text"
                  label="Cancel"
                  onClick={() => {
                    setSaveErrors(undefined);
                    devHolidayFormState.revertChanges();
                  }}
                />
                <Button
                  label="Save"
                  disabled={!devHolidayFormState.valid || !devHolidayFormState.dirty}
                  onClick={() => {
                    openModal({
                      content: (
                        <ConfirmationModal
                          confirmationMessage={
                            <div css={Css.df.fdc.gap3.$}>
                              <div>
                                Making a change to this holiday(s) will immediately affect the following schedules:
                              </div>
                              <div css={Css.fdc.$}>
                                {projectData?.projectsPage.entities
                                  .filter((p) => p.features.includes(ProjectFeature.ProductConfigPlan))
                                  .map((project) => <div key={project.id}>{project.buildAddress.street1}</div>)}
                              </div>
                            </div>
                          }
                          onConfirmAction={saveHolidays}
                          title="Schedules Affected"
                          label="Save"
                        />
                      ),
                    });
                  }}
                />
              </div>
            </div>
            <div css={Css.sm.$}>
              Define the holidays across all projects in this development. These holidays specify the days when schedule
              tasks cannot be worked on.
            </div>
            {devHolidayFormState.holidays.rows.map((holiday, idx) => (
              <div key={`holiday-${idx}`} css={Css.dg.gtc("1fr auto auto").aic.gap1.$}>
                <BoundTextField
                  compact
                  hideErrorMessage
                  disabled={holiday.delete.value && `${holiday.name.value} marked for deletion`}
                  label="Holiday Name"
                  labelStyle="hidden"
                  field={holiday.name}
                />
                <BoundDateField
                  compact
                  useYearPicker
                  disabled={holiday.delete.value && `${holiday.name.value} marked for deletion`}
                  label="Date"
                  labelStyle="hidden"
                  field={holiday.date}
                />
                <IconButton
                  color={holiday.delete.value ? Palette.Red500 : undefined}
                  icon="trash"
                  onClick={() => {
                    if (holiday.id.value) {
                      holiday.delete.set(!holiday.delete.value);
                    } else {
                      devHolidayFormState.holidays.remove(idx);
                    }
                  }}
                />
              </div>
            ))}
            <div>
              <Button variant="text" label="Add a holiday" onClick={() => devHolidayFormState.holidays.add({})} />
            </div>
            {saveErrors && (
              <div css={Css.red600.$}>
                Unable to save holidays due to the following errors:
                {saveErrors?.map((error, idx) => <ol key={error}>{error}</ol>)}
              </div>
            )}
          </div>
        )}
      </Observer>
    </div>
  );
}
