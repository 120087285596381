import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import {
  ChangeEventPageCommitmentFragment,
  CommitmentStatus,
  useSavePandaDocMutation,
} from "src/generated/graphql-types";

type CommitmentBulkUploadModalProps = {
  commitments: ChangeEventPageCommitmentFragment[];
};

/** Auto-create PandaDocs for each Commitment in the ChangeEvent. */
export function CommitmentBulkUploadModal({ commitments }: CommitmentBulkUploadModalProps) {
  const { closeModal, openModal } = useModal();
  const unsigned = commitments.filter((c) => c.status !== CommitmentStatus.Signed);
  const [savePandaDoc] = useSavePandaDocMutation();
  return (
    <>
      <ModalHeader>Upload to PandaDoc</ModalHeader>
      <ModalBody>
        <div>Are you sure you want to upload to PandaDoc?</div>
        <div>
          <div css={Css.my2.$}>The following commitments will be uploaded:</div>
          {unsigned.map((c) => (
            <div key={c.id} css={Css.smMd.$}>
              PO# {c.accountingNumber}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Upload"
          onClick={async () => {
            await Promise.all(
              unsigned.map((c) => {
                const variables = c.id.startsWith("cco") ? { commitmentChangeOrderId: c.id } : { commitmentId: c.id };
                return savePandaDoc({ variables: { input: variables } });
              }),
            );
            openModal({ content: <UploadConfirmationModal /> });
          }}
        />
      </ModalFooter>
    </>
  );
}

function UploadConfirmationModal() {
  const { closeModal } = useModal();
  return (
    <>
      <ModalHeader>Upload to PandaDoc</ModalHeader>
      <ModalBody>
        <div css={Css.baseMd.mb1.$}>Commitments uploading!</div>
        <div>Check back in a few minutes to view this commitment in PandaDoc.</div>
      </ModalBody>
      <ModalFooter>
        <Button label="Close" variant="tertiary" onClick={() => closeModal()} />
      </ModalFooter>
    </>
  );
}
