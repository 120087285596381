import { TriggerNoticeProps } from "@homebound/beam";
import { BryntumGanttProps } from "@homebound/schedules-v2-gantt";
import React from "react";
import { formatDate } from "src/components";
import {
  SaveGanttScheduleTasksMutationHookResult,
  ScheduleSettingDetailsFragment,
  TaskDependencyType,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";
import { calcDependencyLag } from "../calcDependencyLag";
import { Actions as ScheduleStoreActions, setTaskPaneState } from "../contexts/scheduleStoreReducer";
import { getDisabledDaysByScheduleSetting } from "../table/DateCellField";
import { GanttTask, OriginalTaskDataById } from "./ganttUtils";

type OnBeforeTaskEditEvent = {
  taskRecord: {
    originalData: GanttTask;
  };
};

/** Used to override the default "Edit" flow, allowing us to open our own task sidebar instead of the Bryntum modal */
function onBeforeTaskEdit(event: OnBeforeTaskEditEvent, eventHelpers: EventHelpers) {
  const { scheduleStoreDispatch } = eventHelpers;
  const {
    taskRecord: {
      originalData: { id, type },
    },
  } = event;
  // For now, SchedulePhases and ScheduleSubPhases are not editable
  if (type !== "Task") return false;

  scheduleStoreDispatch(setTaskPaneState({ taskPaneId: id?.toString(), tab: "details" }));

  // Returning false prevents the Bryntum modal from opening
  return false;
}

type OnBeforeTaskResizeFinalizeEvent = {
  context: {
    async: boolean;
    finalize: (shouldFinalize: boolean) => void;
    duration: number;
    resizedRecord: {
      originalData: {
        id: string;
      };
    };
  };
};

/**
 * Called when dragging the right edge of a task to modify the task duration
 * https://www.bryntum.com/docs/gantt/api/Gantt/feature/TaskResize#event-beforeTaskResizeFinalize
 */
async function onBeforeTaskResizeFinalize(event: OnBeforeTaskResizeFinalizeEvent, eventHelpers: EventHelpers) {
  const { originalTaskDataById, triggerNotice, saveScheduleTasks } = eventHelpers;
  const { context } = event;
  const id = context.resizedRecord.originalData.id;
  // Set the event context to `async` to allow us to back out the change via `context.finalize(false)` if a task duration cannot be modified
  context.async = true;

  const { canEditDuration } = originalTaskDataById[id];
  if (!canEditDuration.allowed) {
    context.finalize(false);
    return triggerNotice({
      message: canEditDuration.disabledReasons.map((dr) => dr.message).join(", "),
      icon: "error",
    });
  }

  // If the duration can be set for this task, allow the change to stick
  context.finalize(true);

  // The Gantt chart has finer than "day" level time precision, round to the nearest full day in the event a decimal is returned
  const roundedDuration = Math.round(context.duration);
  await saveScheduleTasks({
    variables: {
      input: { tasks: [{ id, durationInDays: roundedDuration }] },
    },
  });
}

type OnBeforeTaskDropFinalizeEvent = {
  context: {
    valid: boolean;
    startDate: Date;
    finalize: (shouldFinalize: boolean) => void;
    record: {
      id: string;
    };
  };
};

/** Called while dragging a task horizontally to change the `startDate` either directly or via modifying predecessor lag */
function onBeforeTaskDropFinalize(event: OnBeforeTaskDropFinalizeEvent, eventHelpers: EventHelpers) {
  const { triggerNotice, originalTaskDataById, saveScheduleTasks, scheduleSetting } = eventHelpers;
  const {
    context: {
      record: { id },
    },
    context,
  } = event;
  const newStartDate = new DateOnly(context.startDate);
  const isDisabledDay = getDisabledDaysByScheduleSetting(scheduleSetting);

  // We can't prevent a user from dropping over a non-working day, rather than guessing the closet working day, revert the change and pop a warning
  if (isDisabledDay(context.startDate)) {
    // The new startDate is not valid working day, setting `context.valid` tells the chart to gracefully back out the change
    // https://bryntum.com/docs/gantt/api/Gantt/feature/TaskDrag#validating-a-drag-drop-operation
    context.valid = false;

    return triggerNotice({
      message: `Start Date of ${formatDate(newStartDate)} is not a working day for this schedule`,
      icon: "error",
    });
  }

  const originalTaskData = originalTaskDataById[id];
  const { canEditStartDate, canEditEndDate } = originalTaskData;

  // If a task startDate and endDate are editable (no predecessors), then we can simply save the new startDate
  if (canEditStartDate.allowed && canEditEndDate.allowed) {
    return saveScheduleTasks({
      variables: { input: { tasks: [{ id, startDate: newStartDate }] } },
    });
  }

  // Otherwise, when there are predecessors, calculate the updated lag for each dependency to produce the desired start date
  // This matches the chart UI for the user as the dependency lines visually stretch or compress during drag
  const { mutationInput } = calcDependencyLag({ newStartDate, originalTaskData, scheduleSetting });

  return saveScheduleTasks({
    variables: { input: { tasks: [{ id, predecessorDependencies: mutationInput }] } },
  });
}

type DependencyLineSides = "end" | "start";

type OnBeforeDependencyCreateFinalizeEvent = {
  valid: boolean;
  source: {
    originalData: GanttTask;
  };
  target: {
    originalData: GanttTask;
  };
  fromSide: DependencyLineSides;
  toSide: DependencyLineSides;
};

/**
 * Called once a dependency line has been dragged, then dropped by the user. Bryntum has some built in validations that
 * attempts to block circular dependencies and if a dependency already exists between tasks, however we must layer in our own
 * validations and allow the change to stick (context.valid) only if the backend accepts the new dependency.
 */
async function onBeforeDependencyCreateFinalize(
  event: OnBeforeDependencyCreateFinalizeEvent,
  eventHelpers: EventHelpers,
) {
  const { triggerNotice, saveScheduleTasks } = eventHelpers;
  const {
    source: { originalData: sourceTask },
    target: { originalData: targetTask },
    fromSide,
    toSide,
  } = event;

  if (sourceTask.type !== "Task" || targetTask.type !== "Task") {
    triggerNotice({
      message: "Task dependencies can only be drawn between Tasks",
      icon: "error",
    });

    event.valid = false;
    return false;
  }

  return await saveScheduleTasks({
    variables: {
      input: {
        tasks: [
          {
            id: sourceTask.id,
            successorDependencies: [
              {
                lagInDays: 0,
                type: getDependencyTypeFromEvent(fromSide, toSide),
                successorId: targetTask.id,
              },
            ],
          },
        ],
      },
    },
    onCompleted: () => {
      event.valid = true;
      return true;
    },
    onError: () => {
      // If a circular dependency is caught by the backend that slipped through Bryntum's check, back out the change
      event.valid = false;
      return false;
    },
  });
}

/** Because the onBeforeDependencyCreateFinalize callback is before the new dependency is "finalized", we must infer the dependency type ourselves */
function getDependencyTypeFromEvent(fromSide: DependencyLineSides, toSide: DependencyLineSides) {
  if (fromSide === "start" && toSide === "start") {
    return TaskDependencyType.StartStart;
  } else if (fromSide === "start" && toSide === "end") {
    return TaskDependencyType.StartFinish;
  } else if (fromSide === "end" && toSide === "end") {
    return TaskDependencyType.FinishFinish;
  } else {
    return TaskDependencyType.FinishStart;
  }
}

type EventHelpers = {
  saveScheduleTasks: SaveGanttScheduleTasksMutationHookResult[0];
  scheduleStoreDispatch: React.Dispatch<ScheduleStoreActions>;
  originalTaskDataById: OriginalTaskDataById;
  triggerNotice: (props: TriggerNoticeProps) => void;
  scheduleSetting?: ScheduleSettingDetailsFragment;
};

/** 
 * This function attempts to clean up the following:
 1. Bryntum does not provide types on event objects, cast the `unknown` events to our own types
 2. Pass in our `eventHelpers` here to avoid inlining lambda funcs on each `BryntumGantt` component handler prop
 * */
export function ganttEventHandlers(
  eventHelpers: EventHelpers,
): Pick<
  BryntumGanttProps,
  "onBeforeTaskEdit" | "onBeforeTaskResizeFinalize" | "onBeforeTaskDropFinalize" | "onBeforeDependencyCreateFinalize"
> {
  return {
    onBeforeTaskEdit: (event: unknown) => onBeforeTaskEdit(event as OnBeforeTaskEditEvent, eventHelpers),
    onBeforeTaskResizeFinalize: (event: unknown) =>
      onBeforeTaskResizeFinalize(event as OnBeforeTaskResizeFinalizeEvent, eventHelpers),
    onBeforeTaskDropFinalize: (event: unknown) =>
      onBeforeTaskDropFinalize(event as OnBeforeTaskDropFinalizeEvent, eventHelpers),
    onBeforeDependencyCreateFinalize: (event: unknown) =>
      onBeforeDependencyCreateFinalize(event as OnBeforeDependencyCreateFinalizeEvent, eventHelpers),
  };
}
