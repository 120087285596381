import { Css, Tag, Tooltip } from "@homebound/beam";
import { pluralize } from "src/utils";

export function ConflictTag(props: { conflicts: string[] }) {
  const { conflicts } = props;

  return (
    <div css={Css.h2.wPx(100).$}>
      <Tooltip
        title={`Option conflict: This option cannot be selected with ${conflicts.join(
          ", ",
        )} option(s). Please deselect the other(s) to enable this option.`}
      >
        <Tag text={`${conflicts.length} ${pluralize(conflicts.length, "conflict")}`} type="warning" />
      </Tooltip>
    </div>
  );
}
