import { BoundTextField, Button, Css, Icon, SuperDrawerContent, SuperDrawerHeader, useComputed } from "@homebound/beam";
import { Observer } from "mobx-react";
import {
  MaterialCatalog_ItemFragment,
  MaterialCatalog_MaterialBrandFragment,
  useMaterialCatalogMetadataQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { MaterialListingForm } from "../material-listing-form/MaterialListingForm";
import { MaterialSuperDrawerContextProvider, useMaterialSuperDrawerContext } from "./MaterialSuperDrawerContext";
import { MaterialSuperDrawerView } from "./MaterialSuperDrawerView";

export type MaterialSuperDrawerProps = {
  variantId: string;
  readOnly?: boolean;
};

export function MaterialSuperDrawer({ variantId, readOnly = true }: MaterialSuperDrawerProps) {
  const query = useMaterialCatalogMetadataQuery();

  return queryResult(query, ({ materialBrands, items }) => (
    <MaterialSuperDrawerContextProvider variantId={variantId} readOnly={readOnly}>
      <MaterialSuperDrawerContent items={items} brands={materialBrands} />
    </MaterialSuperDrawerContextProvider>
  ));
}

type MaterialSuperDrawerContentProps = {
  items: MaterialCatalog_ItemFragment[];
  brands: MaterialCatalog_MaterialBrandFragment[];
};

function MaterialSuperDrawerContent({ items, brands }: MaterialSuperDrawerContentProps) {
  const { listing, formState, save, cancel, readOnly, toggleReadOnly } = useMaterialSuperDrawerContext();

  const website = useComputed(() => {
    const url = formState.manufacturerUrl.value;
    if (!url) return undefined;
    return url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
  }, [formState]);

  return (
    <SuperDrawerContent>
      <SuperDrawerHeader
        title={
          readOnly ? (
            <div css={Css.df.fdc.gap1.$}>
              <h1 css={Css.lgMd.$}>{listing?.name}</h1>
              <div css={Css.df.gapPx(4).base.$}>
                <span>{listing?.item.name}</span>
                {listing?.brand && (
                  <>
                    By{" "}
                    {website ? (
                      <a css={Css.blue700.tdu.df.aic.gapPx(4).$} href={website} target="_blank" rel="noreferrer">
                        {listing.brand.name} <Icon icon="linkExternal" inc={2} />
                      </a>
                    ) : (
                      listing.brand.name
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div css={Css.w100.df.aic.gap3.$}>
              <div css={Css.fg1.$}>
                <BoundTextField field={formState.name} label="Name" labelStyle="hidden" fullWidth />
              </div>
              <Observer>
                {() => (
                  <div css={Css.df.gap1.pr1.$}>
                    <Button label="Cancel" variant="secondary" onClick={cancel} />
                    <Button label="Save" disabled={!formState.dirty} variant="primary" onClick={save} />
                  </div>
                )}
              </Observer>
            </div>
          )
        }
        hideControls
        right={
          <div css={Css.df.aic.gap1.$}>
            {readOnly && <Button label="Edit" variant="secondary" onClick={toggleReadOnly} />}
          </div>
        }
      />
      {readOnly ? (
        <MaterialSuperDrawerView items={items} brands={brands} />
      ) : (
        <MaterialListingForm
          isNew={false}
          formState={formState}
          canEditItem={listing?.canEditItem}
          items={items}
          brands={brands}
        />
      )}
    </SuperDrawerContent>
  );
}
