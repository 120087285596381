import { Button, Css, Icon, Palette } from "@homebound/beam";
import { Link } from "react-router-dom";
import { ProgressPill } from "src/components";
import { createDevelopmentBidPackagesUrl } from "src/RouteUrls";

export function BidOutCell({ bidOutPercentage, developmentId }: { bidOutPercentage: number; developmentId: string }) {
  if (bidOutPercentage === 0)
    return (
      <div css={Css.df.gap1.aic.$}>
        <Button label="Get Estimate" onClick={createDevelopmentBidPackagesUrl(developmentId)} variant="text" />
        <Icon icon="dollar" color={Palette.Green500} inc={2} />
      </div>
    );

  if (bidOutPercentage === 100)
    return (
      <div css={Css.df.gap1.aic.$}>
        <Button label="Awarded" onClick={createDevelopmentBidPackagesUrl(developmentId)} variant="text" />
        <Icon icon="checkCircle" color={Palette.Green500} inc={2} />
      </div>
    );

  return (
    <Link href={createDevelopmentBidPackagesUrl(developmentId)} to={createDevelopmentBidPackagesUrl(developmentId)}>
      <ProgressPill progress={bidOutPercentage} changeColorOnCompleted text="bid" />
    </Link>
  );
}
