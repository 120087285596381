import { Css, px, SelectField } from "@homebound/beam";
import { TaskCalendarDetailsFragment } from "src/generated/graphql-types";

type SearchScheduleCalendarProps = {
  scheduleTasks: TaskCalendarDetailsFragment[];
  onSearch: (task: TaskCalendarDetailsFragment) => void;
};

export function SearchScheduleCalendar({ scheduleTasks, onSearch }: SearchScheduleCalendarProps) {
  const sortedTasksByName = scheduleTasks.sortBy((task) => task.name);
  const inputIsDisabled = !sortedTasksByName.length;

  return (
    <div css={Css.fb(px(250)).fs0.$}>
      <SelectField
        label="Search Tasks"
        placeholder="Search Tasks"
        options={sortedTasksByName}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        value={undefined}
        onSelect={(_taskId: string | undefined, selectedTask) => {
          selectedTask && onSearch(selectedTask);
        }}
        disabled={inputIsDisabled}
        labelStyle="hidden"
        compact
      />
    </div>
  );
}
