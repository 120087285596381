import { Checkbox, Css, Tooltip, useComputed, useTestIds } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { addBusinessDays, subBusinessDays } from "date-fns";
import { Maybe } from "graphql/jsutils/Maybe";
import { Observer } from "mobx-react";
import { Icon } from "src/components";
import {
  DateOperation,
  JobLogDetailFragment,
  JobLogScheduleTaskFragment,
  useScheduleTasksForJobLogsQuery,
  useScheduleTasksOnJobLogNoteQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { DateOnly, formatMonthDay } from "src/utils/dates";

type JobLogNoteScheduleTasksProps = {
  field: FieldState<Maybe<string[]>>;
  // Any is passed below due to this form-state issue: https://github.com/homebound-team/form-state/issues/78
  jobLog: JobLogDetailFragment;
  isReadOnly: boolean;
};

export function JobLogNoteScheduleTasks(props: JobLogNoteScheduleTasksProps) {
  const { field, jobLog, isReadOnly } = props;
  const tasksByDateQuery = useScheduleTasksForJobLogsQuery({
    variables: {
      scheduleParent: jobLog.project.id,
      startDateRange: { op: DateOperation.OnOrBefore, value: new DateOnly(addBusinessDays(jobLog.logDate.date, 1)) },
      endDateRange: { op: DateOperation.OnOrAfter, value: new DateOnly(subBusinessDays(jobLog.logDate.date, 1)) },
    },
  });
  const tasksOnNoteQuery = useScheduleTasksOnJobLogNoteQuery({ variables: { taskIds: field.value ?? [] } });
  return queryResult(tasksByDateQuery, {
    data: ({ scheduleTasks: tasksByDate }) => {
      const tasksByIds = tasksOnNoteQuery.data?.scheduleTasks ?? [];
      const dedupedScheduleTasks = tasksByDate.concat(tasksByIds).uniqueByKey("id");
      return <JobLogNoteScheduleTasksData scheduleTasks={dedupedScheduleTasks} field={field} isReadOnly={isReadOnly} />;
    },
  });
}

type JobLogNoteScheduleTasksDataProps = Omit<JobLogNoteScheduleTasksProps, "jobLog"> & {
  scheduleTasks: JobLogScheduleTaskFragment[];
};

export function JobLogNoteScheduleTasksData(props: JobLogNoteScheduleTasksDataProps) {
  const { scheduleTasks, field, isReadOnly } = props;

  const scheduleTaskCards = scheduleTasks.map((scheduleTask) => {
    return <ScheduleTaskCard scheduleTask={scheduleTask} field={field} key={scheduleTask.id} isReadOnly={isReadOnly} />;
  });

  return (
    <Observer>
      {() => (
        <div>
          <div css={Css.df.aic.mb2.$}>
            <h2 css={Css.xlBd.$}>Attach to Schedule Tasks</h2>
            <Tooltip title="You may attach upcoming tasks to this note for additional context and visiblity.">
              <span css={Css.ml1.$}>
                <Icon icon="infoCircle" />
              </span>
            </Tooltip>
          </div>
          <div css={Css.mb2.$}>
            {scheduleTasks.nonEmpty ? (
              <h3 css={Css.smBd.mbPx(4).$}>Attach to upcoming tasks</h3>
            ) : (
              <h3 css={Css.br8.bsDashed.bcGray200.gray700.bw("3px").py2.px3.df.jcc.$}>
                There are no tasks occuring within one day of this note's date.
              </h3>
            )}
          </div>
          {scheduleTaskCards}
        </div>
      )}
    </Observer>
  );
}

type ScheduleTaskCardProps = {
  scheduleTask: JobLogScheduleTaskFragment;
} & Omit<JobLogNoteScheduleTasksDataProps, "scheduleTasks" | "shouldShiftScheduleTasks">;

function ScheduleTaskCard(props: ScheduleTaskCardProps) {
  const tid = useTestIds(props, "scheduleTaskCard");
  const { scheduleTask, field, isReadOnly } = props;
  const isChecked = useComputed(
    () => (field.value ?? []).includes(scheduleTask.id) ?? false,
    [field.value, scheduleTask.id],
  );

  const onChange = () => {
    const selectedTasks = [...(field.value ?? [])];
    // When adding a task to a JLN, the task id is added to the form state's list of tasks
    if (!isChecked) {
      selectedTasks?.push(scheduleTask.id);
      field.set(selectedTasks);
      // If the task is being unchecked or removed from the JLN, splice it from the form state's list of tasks
    } else {
      const i = selectedTasks?.findIndex((id) => id === scheduleTask.id);
      if (i !== undefined && i >= 0) {
        selectedTasks?.splice(i, 1);
        field.set(selectedTasks);
      }
    }
  };
  return (
    <Observer>
      {() => (
        <div css={Css.w100.df.aic.mb1.$} key={scheduleTask.id}>
          <div css={Css.fb("24px").asfs.pt3.fs0.fg0.$}>
            <Checkbox
              {...tid.checkbox}
              label={`Schedule Task ${scheduleTask.id} Checkbox`}
              checkboxOnly
              selected={isChecked}
              onChange={onChange}
              disabled={isReadOnly}
            />
          </div>
          <div css={Css.xs.mwPx(359).maxwPx(359).mhPx(64).px1.df.fdc.jcc.ba.bcGray300.br8.$} {...tid.taskCard}>
            <div css={Css.fb(0).df.aic.jcsb.gap1.w100.$}>
              <div {...tid.name} css={Css.df.mwPx(100).aic.$}>
                {scheduleTask.isCriticalPath && (
                  <div css={Css.fb("24px").h3.$}>
                    <Icon icon="criticalPath" xss={Css.mr1.$} {...tid.icon}></Icon>
                  </div>
                )}
                <Tooltip title={scheduleTask.name}>
                  <span css={Css.truncate.if(!scheduleTask.isCriticalPath).ml4.$}>{scheduleTask.name}</span>
                </Tooltip>
              </div>
              <div css={Css.df.fdr.aic.$}>
                <div css={Css.mwPx(90).jse.$}>
                  {formatMonthDay(scheduleTask.interval.startDate)} - {formatMonthDay(scheduleTask.interval.endDate)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
}
