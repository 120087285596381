import { HbLoadingSpinner } from "@homebound/beam";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  BillDetailPane_BillFragment,
  BillDetailPane_CommitmentChangeOrderFragment,
  BillDetailPane_CommitmentFragment,
  useBillDetailPaneQuery,
  useBillDetailPane_NewBillCommitmentLikeDataQuery,
} from "src/generated/graphql-types";
import { foldEnum, isDefined } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";
import { BillDetailForm } from "./BillDetailForm";
import { BillDetailReview } from "./BillDetailReview";

export enum BillPaneViews {
  Create,
  Edit,
  View,
}

export type BillPaneContextType = {
  /** view types "Create / Edit / View " drive which jsx components are rendered in the billDetailPane  */
  view: BillPaneViews;
  setView: React.Dispatch<React.SetStateAction<BillPaneViews>>;
  bill: BillDetailPane_BillFragment | undefined;
  billId: string | undefined;
  setBillId: React.Dispatch<React.SetStateAction<string | undefined>>;
  commitment: BillDetailPane_CommitmentFragment | undefined;
  changeOrder: BillDetailPane_CommitmentChangeOrderFragment | undefined;
  onPrevOrNext: ((billId: string, direction: "prev" | "next") => void) | undefined;
  setBillPaneId: React.Dispatch<React.SetStateAction<string | undefined>> | undefined;
};

export const BillPaneContext = createContext<BillPaneContextType>({
  view: BillPaneViews.Create,
  setView() {},
  bill: undefined,
  billId: undefined,
  setBillId() {},
  commitment: undefined,
  changeOrder: undefined,
  onPrevOrNext() {},
  setBillPaneId() {},
});

type BillPaneProps = {
  billId?: string | undefined;
  commitmentId?: string | undefined;
  changeOrderId?: string | undefined;
  onPrevOrNext?: ((billId: string, direction: "prev" | "next") => void) | undefined;
  setBillPaneId?: React.Dispatch<React.SetStateAction<string | undefined>> | undefined;
};

export function BillDetailPane({
  billId: givenBillId,
  commitmentId,
  changeOrderId,
  onPrevOrNext,
  setBillPaneId,
}: BillPaneProps) {
  // Add billId to context state in order to set & rehydrate our context once a new bill is created
  const [billId, setBillId] = useState<string | undefined>(givenBillId);
  const isCo = isDefined(changeOrderId);
  const [_, setQueryParam] = useQueryParams({
    billId: StringParam,
  });

  useEffect(() => {
    // Watch for changes to the givenBillId and rehydrate the billContext if it changes
    setBillId(givenBillId);
    // allows us to deep link billPane to any page
    givenBillId && setQueryParam({ billId: givenBillId });
    // cleanup query params on dismount
    return () => {
      setQueryParam({ billId: undefined });
    };
  }, [givenBillId, setQueryParam]);

  const { loading: loadingBill, data: billData } = useBillDetailPaneQuery({
    variables: { billId: billId ?? "" },
    skip: !billId,
  });
  const { loading: loadingCommitment, data: commitmentLikeData } = useBillDetailPane_NewBillCommitmentLikeDataQuery({
    variables: {
      commitmentId: commitmentId ?? "",
      changeOrderId: changeOrderId ?? "",
      // Use a skip directive inline to only query a commitment/cco depending on which page we've requested
      // Allows us to also handle the request for both in the same query
      skipCommitment: isCo,
      skipChangeOrder: !isCo,
    },
    // skip the query entirely, if neither is passed
    skip: !commitmentId && !changeOrderId,
  });

  // Note: Manual draft bills are editable up until the bill's submission for approval.
  // For all bill types, if approval status becomes `changes requested`, BE reverts the bill back to `draft` status.
  const bill = useMemo(() => billData?.bill, [billData?.bill]);
  const [view, setView] = useState<BillPaneViews>(
    !billId ? BillPaneViews.Create : bill?.canEdit.allowed ? BillPaneViews.Edit : BillPaneViews.View,
  );

  useEffect(() => {
    bill?.canEdit.allowed && setView(BillPaneViews.Edit);
  }, [bill?.status.code, bill?.type.code, bill?.canEdit.allowed]);

  const value = useMemo<BillPaneContextType>(
    () => ({
      view,
      setView,
      bill,
      billId,
      setBillId,
      commitment: commitmentLikeData?.commitment,
      changeOrder: commitmentLikeData?.commitmentChangeOrder,
      onPrevOrNext,
      setBillPaneId,
    }),
    [
      view,
      bill,
      billId,
      commitmentLikeData?.commitment,
      commitmentLikeData?.commitmentChangeOrder,
      onPrevOrNext,
      setBillPaneId,
    ],
  );

  if (loadingBill || loadingCommitment) return <HbLoadingSpinner />;

  return (
    <BillPaneContext.Provider value={value}>
      {foldEnum(view, {
        [BillPaneViews.Create]: () => <BillDetailForm />,
        [BillPaneViews.Edit]: () => <BillDetailForm />,
        [BillPaneViews.View]: () => <BillDetailReview />,
      })}
    </BillPaneContext.Provider>
  );
}

export const useBillPaneContext = () => useContext(BillPaneContext);
