import { useHistory } from "react-router";
import { FormMode } from "src/components";
import {
  useDeleteTradePartnerMutation,
  useSaveTradePartnerMutation,
  useTradePartnerDetailsTabQuery,
} from "src/generated/graphql-types";
import { TradePartnerEditor } from "src/routes/trade-partners/TradePartnerEditor";
import { addEntityParam, createTradePartnersUrl, createTradePartnerUrl } from "src/RouteUrls";
import { pushInNextLoop, queryResult } from "src/utils";

type TradePartnerDetailsTabProps = {
  idOrAdd: string;
};

export function TradePartnerDetailsTab(props: TradePartnerDetailsTabProps) {
  const { idOrAdd } = props;
  const isNew = idOrAdd === addEntityParam;
  const query = useTradePartnerDetailsTabQuery({
    variables: { id: idOrAdd, isNew },
  });
  const [saveTradePartner] = useSaveTradePartnerMutation();
  const [deleteTradePartner] = useDeleteTradePartnerMutation();
  // We're on the way to superdraw-ing, and so are skipping "read" mode for now
  const mode: FormMode = isNew ? "create" : "update";
  const history = useHistory();

  return queryResult(query, {
    data: ({ tradePartner, markets, internalUsers, costCodes, paymentTerms, tradeCategories }) => {
      return (
        <TradePartnerEditor
          {...{ mode, tradePartner }}
          markets={markets}
          internalUsers={internalUsers}
          costCodes={costCodes}
          tradeCategories={tradeCategories}
          paymentTerms={paymentTerms}
          onEdit={() => {}}
          onSave={async (input) => {
            const result = await saveTradePartner({ variables: { input } });
            if (isNew && result.data) {
              pushInNextLoop(history, createTradePartnerUrl(result.data.saveTradePartner.tradePartner.id));
            }
          }}
          onDelete={async () => {
            await deleteTradePartner({ variables: { input: { id: idOrAdd } } });
            history.push(createTradePartnersUrl());
          }}
        />
      );
    },
  });
}
