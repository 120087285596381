import { FetchMoreQueryOptions } from "@apollo/client";
import {
  column,
  Css,
  dateRangeFilter,
  DateRangeFilterValue,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  multiFilter,
  numericColumn,
  OffsetAndLimit,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  singleFilter,
  usePersistedFilter,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { chipCell, dateCell, emptyCellDash, priceCell, SearchBox, tagCell } from "src/components";
import {
  CommitmentOrder,
  CommitmentsFilter,
  CommitmentStatus,
  DateOperation,
  Order,
  TradePartner_CommitmentChangeOrderFragment,
  TradePartner_CommitmentFragment,
  useTradePartnerCommitmentsMetadataQuery,
  useTradePartnerCommitmentsQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { commitmentStatusToDevelopmentTagTypeMapper, formatList, searchSchema, StateHook } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { toOrder } from "src/utils/ordering";
import { queryResult } from "src/utils/queryResult";
import { TableActions } from "../layout/TableActions";

type TradePartnerCommitmentsTabProps = {
  tradePartnerId: string;
};

const defaultPageOptions: OffsetAndLimit = { limit: 50, offset: 0 };

export function TradePartnerCommitmentsTab(props: TradePartnerCommitmentsTabProps) {
  const { tradePartnerId } = props;
  const [orderBy, setOrderBy] = useState<CommitmentOrder>({ accountingNumber: Order.Desc });
  const [{ search }, setSearch] = useZodQueryString(searchSchema);
  const { data: metadata } = useTradePartnerCommitmentsMetadataQuery({
    variables: { tradePartnerId },
    fetchPolicy: "cache-first",
  });

  /** A callback function to avoid re-render the component when use setSearch directly */
  const searchCallback = useCallback((text: string) => setSearch({ search: text }), [setSearch]);

  const filterDefs = useMemo(
    () => ({
      projectId: singleFilter({
        options: metadata?.projects ?? [],
        getOptionLabel: (cc) => cc.name,
        getOptionValue: (cc) => cc.id,
        label: "Project",
      }),
      developmentId: singleFilter({
        options:
          metadata?.projects
            .flatMap((p) => p.cohort?.development)
            .compact()
            .unique() ?? [],
        getOptionLabel: (cc) => cc.name,
        getOptionValue: (cc) => cc.id,
        label: "Development",
      }),
      status: multiFilter({
        options: metadata?.commitmentStatuses ?? [],
        getOptionValue: ({ code }) => code,
        getOptionLabel: ({ name }) => name,
      }),
      releasedAt: dateRangeFilter<string>({
        label: "Released",
        testFieldLabel: "Released",
      }),
      costCode: singleFilter({
        options: metadata?.costCodes ?? [],
        getOptionLabel: (cc) => cc.number,
        getOptionValue: (cc) => cc.id,
        label: "Cost codes",
      }),
    }),
    [metadata],
  );

  const { setFilter, filter } = usePersistedFilter<FilterOptions>({
    storageKey: "tradeCommitmentsFilter",
    filterDefs,
  });

  const query = useTradePartnerCommitmentsQuery({
    variables: {
      filter: applyFilters(tradePartnerId, filter, search),
      page: defaultPageOptions,
      orderBy,
    },
  });

  return queryResult(query, ({ commitmentsPage }) => (
    <TradePartnerCommitmentsView
      commitments={commitmentsPage.commitments}
      hasNextPage={commitmentsPage.pageInfo.hasNextPage}
      filterDefs={filterDefs}
      setSearch={searchCallback}
      setOrderBy={setOrderBy}
      filterSetting={[filter, setFilter]}
      fetchMore={query.fetchMore}
    />
  ));
}

type TradePartnerCommitmentsViewProps = {
  commitments: TradePartner_CommitmentFragment[];
  filterDefs: FilterDefs<FilterOptions>;
  setSearch: (search: string) => void;
  setOrderBy: (orderBy: CommitmentOrder) => void;
  hasNextPage: boolean;
  filterSetting: StateHook<FilterOptions>;
  fetchMore: (options: FetchMoreQueryOptions<{ page: OffsetAndLimit }>) => void;
};

function TradePartnerCommitmentsView(props: TradePartnerCommitmentsViewProps) {
  const {
    commitments,
    filterDefs,
    filterSetting: [filter, setFilter],
    setSearch,
    setOrderBy,
    fetchMore,
    hasNextPage,
  } = props;

  const rows = useMemo(() => createRows(commitments), [commitments]);
  const columns = useMemo(() => createColumns(), []);

  return (
    <>
      <TableActions>
        <Filters<FilterOptions> filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <SearchBox onSearch={setSearch} />
      </TableActions>
      <ScrollableContent virtualized>
        <GridTable
          columns={columns}
          rows={rows}
          rowStyles={createRowStyles()}
          style={{ rowHeight: "flexible", allWhite: true, bordered: true }}
          sorting={{
            on: "server",
            onSort: (key, direction) => setOrderBy(toOrder(key, direction)),
          }}
          stickyHeader
          as="virtual"
          fallbackMessage="No Commitments or Change Orders for this Trade Partner"
          infiniteScroll={{
            onEndReached() {
              if (hasNextPage) {
                fetchMore({
                  variables: { page: { ...defaultPageOptions, limit: commitments.length + defaultPageOptions.limit } },
                });
              }
            },
          }}
        />
      </ScrollableContent>
    </>
  );
}

function createRowStyles(): RowStyles<Row> {
  return {
    commitment: { rowLink: (row) => row.data.blueprintUrl.path },
    changeOrder: { rowLink: (row) => row.data.blueprintUrl.path },
  };
}

type CommitmentTotals = {
  totalBilledInCents: number;
  totalPaidInCents: number;
  totalBalanceInCents: number;
  totalCommittedInCents: number;
  totalRemainingInCents: number;
};

type CommitmentLike = TradePartner_CommitmentFragment | TradePartner_CommitmentChangeOrderFragment;
type HeaderRow = { kind: "header" };
type CommitmentRow = { kind: "commitment"; data: TradePartner_CommitmentFragment };
type ChangeOrderRow = { kind: "changeOrder"; data: TradePartner_CommitmentChangeOrderFragment };
type TotalsRow = { kind: "totals"; id: string; data: CommitmentTotals };
type Row = HeaderRow | TotalsRow | ChangeOrderRow | CommitmentRow;

function createColumns(): GridColumn<Row>[] {
  const commitmentLikeColumns = {
    accountingNumber: (row: CommitmentLike) => `#${row.accountingNumber}`,
    costCodes: (row: CommitmentLike) =>
      chipCell(row.costCodes.map((cc) => ({ text: cc.number, title: formatList(cc.items.map((i) => i.displayName)) }))),
    releasedAt: (row: CommitmentLike) => dateCell(row.executionDate),
    committedCost: (row: CommitmentLike) => priceCell({ valueInCents: row.committedInCents }),
    billed: (row: CommitmentLike) => priceCell({ valueInCents: row.billedInCents }),
    remaining: (row: CommitmentLike) => priceCell({ valueInCents: row.pendingRemainingInCents }),
    paidToDate: (row: CommitmentLike) => priceCell({ valueInCents: row.paidInCents }),
    balanceDue: (row: CommitmentLike) => priceCell({ valueInCents: row.balanceInCents }),
    status: (row: CommitmentLike) => tagCell(commitmentStatusToDevelopmentTagTypeMapper[row.status], row.statusText),
  };

  return [
    column<Row>({
      totals: emptyCell,
      header: "PO #",
      commitment: commitmentLikeColumns.accountingNumber,
      changeOrder: commitmentLikeColumns.accountingNumber,
      serverSideSortKey: "accountingNumber",
    }),
    column<Row>({
      totals: emptyCell,
      header: "PO Type",
      commitment: "PO",
      changeOrder: ({ name }) => <div css={Css.pl2.$}>{name}</div>,
    }),
    column<Row>({
      totals: emptyCell,
      header: "Development",
      commitment: ({ project }) => project.cohort?.development?.name ?? emptyCellDash,
      changeOrder: emptyCell,
      serverSideSortKey: "development",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Address",
      commitment: ({ project }) => project.buildAddress.street1,
      changeOrder: emptyCell,
      serverSideSortKey: "projectAddress",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Cost Codes",
      commitment: commitmentLikeColumns.costCodes,
      changeOrder: commitmentLikeColumns.costCodes,
    }),
    column<Row>({
      totals: emptyCell,
      header: "Released on",
      commitment: commitmentLikeColumns.releasedAt,
      changeOrder: commitmentLikeColumns.releasedAt,
      serverSideSortKey: "executionDate",
      align: "right",
    }),
    numericColumn<Row>({
      totals: emptyCell,
      header: "Version",
      commitment: (row) => row.bidContractRevision?.version,
      changeOrder: emptyCell,
      serverSideSortKey: "bidContractRevision",
    }),
    numericColumn<Row>({
      totals: ({ totalCommittedInCents }) => priceCell({ valueInCents: totalCommittedInCents }),
      header: "Committed Cost",
      commitment: commitmentLikeColumns.committedCost,
      changeOrder: commitmentLikeColumns.committedCost,
      serverSideSortKey: "committedInCents",
    }),
    numericColumn<Row>({
      totals: ({ totalBilledInCents }) => priceCell({ valueInCents: totalBilledInCents }),
      header: "Billed",
      commitment: commitmentLikeColumns.billed,
      changeOrder: commitmentLikeColumns.billed,
      serverSideSortKey: "billedInCents",
    }),
    numericColumn<Row>({
      totals: ({ totalRemainingInCents }) => priceCell({ valueInCents: totalRemainingInCents }),
      header: "Remaining",
      commitment: commitmentLikeColumns.remaining,
      changeOrder: commitmentLikeColumns.remaining,
      serverSideSortKey: "pendingRemainingInCents",
    }),
    numericColumn<Row>({
      totals: ({ totalPaidInCents }) => priceCell({ valueInCents: totalPaidInCents }),
      header: "Paid to date",
      commitment: commitmentLikeColumns.paidToDate,
      changeOrder: commitmentLikeColumns.paidToDate,
      serverSideSortKey: "paidInCents",
    }),
    numericColumn<Row>({
      totals: ({ totalBalanceInCents }) => priceCell({ valueInCents: totalBalanceInCents }),
      header: "Balance due",
      commitment: commitmentLikeColumns.balanceDue,
      changeOrder: commitmentLikeColumns.balanceDue,
      serverSideSortKey: "balanceInCents",
    }),
    column<Row>({
      totals: emptyCell,
      header: "Status",
      commitment: commitmentLikeColumns.status,
      changeOrder: commitmentLikeColumns.status,
      serverSideSortKey: "status",
    }),
  ];
}

function createRows(commitments: TradePartner_CommitmentFragment[]): GridDataRow<Row>[] {
  return [
    { kind: "totals", id: "totals", data: totalsRow(commitments) },
    simpleHeader,
    ...commitments.map((c) => ({
      kind: "commitment" as const,
      id: c.id,
      data: c,
      children: c.changeOrders.map((co) => ({
        kind: "changeOrder" as const,
        id: co.id,
        data: co,
      })),
    })),
  ];
}

type FilterOptions = Partial<{
  releasedAt: DateRangeFilterValue<string>;
  costCode: string;
  projectId: string;
  developmentId: string;
  status: CommitmentStatus[];
}>;

function totalsRow(commitments: TradePartner_CommitmentFragment[]): CommitmentTotals {
  const allCommitments = commitments.flatMap(({ changeOrders, ...contract }) => [contract, ...changeOrders]);

  return allCommitments.reduce(
    (acc, commitment) => ({
      totalRemainingInCents: acc.totalRemainingInCents + (commitment.pendingRemainingInCents || 0),
      totalBilledInCents: acc.totalBilledInCents + (commitment.billedInCents || 0),
      totalPaidInCents: acc.totalPaidInCents + (commitment.paidInCents || 0),
      totalBalanceInCents: acc.totalBalanceInCents + (commitment.balanceInCents || 0),
      totalCommittedInCents: acc.totalCommittedInCents + (commitment.committedInCents || 0),
    }),
    {
      totalRemainingInCents: 0,
      totalBilledInCents: 0,
      totalPaidInCents: 0,
      totalBalanceInCents: 0,
      totalCommittedInCents: 0,
    },
  );
}

function applyFilters(tradePartnerId: string, filter: FilterOptions, search: string): CommitmentsFilter {
  return {
    tradePartners: [tradePartnerId],
    status: filter.status?.nonEmpty ? filter.status : undefined,
    projectId: filter.projectId ? filter.projectId : undefined,
    developmentId: filter.developmentId ? filter.developmentId : undefined,
    executionDate: filter.releasedAt?.value
      ? {
          op: DateOperation.Between,
          value: new DateOnly(new Date(filter.releasedAt.value.from!)),
          value2: new DateOnly(new Date(filter.releasedAt.value.to!)),
        }
      : undefined,
    costCodes: filter.costCode ? [filter.costCode] : undefined,
    search,
  };
}
