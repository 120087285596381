import {
  Button,
  column,
  Css,
  GridDataRow,
  GridTable,
  Icon,
  numericColumn,
  simpleHeader,
  Tooltip,
  useComputed,
  useModal,
  useTestIds,
  useToast,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { addDays, startOfDay } from "date-fns";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Price, priceCell } from "src/components";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import { useSideNavContext } from "src/components/layout/SideNavContext";
import { StepActions } from "src/components/stepper";
import {
  ContractType,
  InvoiceV2Fragment,
  ProjectRole,
  SaveInvoiceInput,
  Stage,
  useDeleteInvoiceV2Mutation,
  useSaveInvoiceV2Mutation,
  useSubmitApprovalMutation,
} from "src/generated/graphql-types";
import { ConfirmationModal } from "src/routes/components/ConfirmationModal";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ProjectParams } from "src/routes/routesDef";
import { createInvoicesUrl } from "src/RouteUrls";
import { DateOnly, formatWithShortYear } from "src/utils/dates";
import { isCostPlusWithoutCost } from "../../utils";
type ReviewInvoiceProps = {
  draftInvoice: InvoiceV2Fragment;
  lineItemStartDate: DateOnly;
  onExit: () => void;
};

export function ReviewInvoice({ draftInvoice, onExit, lineItemStartDate }: ReviewInvoiceProps) {
  const { setSideNavState } = useSideNavContext();
  const { openModal } = useModal();
  const { projectId } = useParams<ProjectParams>();
  const history = useHistory();
  const [saveInvoice] = useSaveInvoiceV2Mutation();
  const [deleteInvoice] = useDeleteInvoiceV2Mutation();
  const [saveApproval] = useSubmitApprovalMutation();
  const { showToast } = useToast();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: draftInvoice,
      map: (invoice) => {
        const today = startOfDay(new Date());
        const { status, ...otherFields } = invoice;
        return {
          ...otherFields,
          invoiceDate: new DateOnly(today),
          dueDate: new DateOnly(addDays(today, 30)),
        };
      },
    },
  });

  const pageHeaderTitle = useComputed(
    () =>
      `Review your invoice for ${formatWithShortYear(formState.invoiceDate.value!)} – ${formatWithShortYear(
        formState.dueDate.value!,
      )}`,
    [formState.dueDate.value, formState.invoiceDate.value],
  );

  const managerName = useMemo(() => {
    const team = draftInvoice.project.teamMembers ?? [];
    const ps = draftInvoice.projectStage.stage.code;
    const pm =
      ps === Stage.Construction
        ? team.find((m) => m.role.code === ProjectRole.ConOpsProjectManager)
        : team.find((m) => m.role.code === ProjectRole.PreConProjectManager);
    return pm?.user?.name;
  }, [draftInvoice]);

  function openDeleteModal() {
    openModal({
      content: (
        <ConfirmationModal
          danger
          label="Delete"
          title="Delete Invoice"
          confirmationMessage="Are you sure you want to delete this draft Invoice?"
          onConfirmAction={async () => {
            await deleteInvoice({ variables: { invoiceId: draftInvoice.id } });
            history.push(createInvoicesUrl(projectId));
          }}
        />
      ),
    });
  }

  return (
    <>
      <PageHeader
        title={pageHeaderTitle}
        breadcrumb={{
          label: "NEW INVOICE",
          href: "#",
        }}
        right={
          <div css={Css.df.jcsb.gap4.$}>
            <ExitIconButton showModal={false} onCloseConfirmed={onExit} />
          </div>
        }
      />
      <div css={Css.df.fdr.$}>
        {/* Left side */}
        <InvoiceCreationSummary invoice={draftInvoice} managerName={managerName} />
        {/* Right side */}
        <DateSettings formState={formState} />
      </div>
      <StepActions>
        <>
          <Button label="Delete" variant="danger" onClick={openDeleteModal} />
          <Button
            label="Submit Invoice"
            onClick={async () => {
              const { data } = await saveInvoice({ variables: { input: { ...formState.value, lineItemStartDate } } });

              // Add an approval for the invoice
              await saveApproval({ variables: { input: { subjectId: data?.saveInvoice.invoice.id } } });

              // Show a success message to the user
              const message = managerName
                ? `Invoice successfully completed and sent to ${managerName}. 👍`
                : `Invoice successfully completed. 👍`;
              showToast({ type: "success", message });

              // Redirect
              history.push(createInvoicesUrl(projectId));
              // show project side nav again
              setSideNavState("expanded");
            }}
          />
        </>
      </StepActions>
    </>
  );
}

type InvoiceCreationSummaryProps = {
  invoice: InvoiceV2Fragment;
  managerName?: string;
};

function InvoiceCreationSummary({ invoice, managerName }: InvoiceCreationSummaryProps) {
  const tid = useTestIds({}, "invoiceCreationSummary");

  // group invoice line items by fixed and cost plus
  const lineItemsByContractType = useMemo(
    () =>
      invoice.lineItemsByCostCode.map((licc) => {
        const { lineItems, ...otherFields } = licc;
        const [fixedItems, costPlusItems] = lineItems.partition(
          (li) =>
            li.homeownerContractLineItem.owner.contractType === ContractType.Fixed ||
            isCostPlusWithoutCost(li.homeownerContractLineItem),
        );
        return { ...otherFields, fixedItems, costPlusItems };
      }),
    [invoice.lineItemsByCostCode],
  );

  const fixedTotal = lineItemsByContractType.sum((licc) => licc.fixedItems.sum((li) => li.amountInCents));
  const costPlusTotal = lineItemsByContractType.sum((licc) => licc.costPlusItems.sum((li) => li.amountInCents));
  const drawTotal = invoice.drawLineItems.sum((li) => li.draw.amountInCents);

  return (
    <div css={Css.py3.pr3.f(0.6).$}>
      <h2 css={Css.lgSb.mb2.$} {...tid.title}>
        Invoice “{invoice.invoiceNumber} - {invoice.title}”
      </h2>
      <span css={Css.sm.$} {...tid.description}>
        Please review the totals below and invoice dates to the side. To make changes, go back to “Confirm amounts." If
        you are done, select “Submit Invoice” to submit this invoice for approval. Once submitted, this project’s
        Project Manager (<b>{managerName}</b>) will receive an alert to approve this invoice.
      </span>

      <RenderInvoiceItemsTable
        id="costPlus"
        title={"Cost-Plus Items"}
        rows={[
          ...lineItemsByContractType
            .filter((li) => !li.costPlusItems.isEmpty)
            .map((li) => ({
              id: li.costCode.id,
              kind: "lineItem" as const,
              data: {
                title: li.costCode.displayName,
                toInvoiceInCents: li.costPlusItems.sum((cpi) => cpi.amountInCents),
              },
            })),
          {
            id: "total",
            kind: "total",
            data: { totalInCents: costPlusTotal },
          },
        ]}
      />

      <RenderInvoiceItemsTable
        id="fixedPrice"
        title={"Fixed Price Items"}
        rows={[
          ...lineItemsByContractType
            .filter((li) => !li.fixedItems.isEmpty)
            .map((li) => ({
              id: li.costCode.id,
              kind: "lineItem" as const,
              data: {
                title: li.costCode.displayName,
                toInvoiceInCents: li.fixedItems.sum((cpi) => cpi.amountInCents),
              },
            })),
          {
            id: "total",
            kind: "total",
            data: { totalInCents: fixedTotal },
          },
        ]}
      />

      <RenderInvoiceItemsTable
        id="draws"
        title={"Draws Items"}
        rows={[
          ...invoice.drawLineItems.map((li) => ({
            id: li.draw.id,
            kind: "lineItem" as const,
            data: {
              title: li.draw.name,
              toInvoiceInCents: li.draw.amountInCents,
            },
          })),
          {
            id: "total",
            kind: "total",
            data: { totalInCents: drawTotal },
          },
        ]}
      />

      <div css={Css.df.fdr.jcsb.pb1.pt4.baseBd.bb.bcGray300.$} {...tid.grand_total}>
        <span>Grand Total</span>
        <Price valueInCents={drawTotal + fixedTotal + costPlusTotal} />
      </div>
    </div>
  );
}

type RenderInvoiceItemsTableProps = {
  id: string;
  title: string;
  rows: GridDataRow<SummaryRow>[];
};

function RenderInvoiceItemsTable({ id, rows, title }: RenderInvoiceItemsTableProps) {
  return (
    <div css={Css.mt4.$}>
      <div css={Css.lgSb.mb2.$}>{title}</div>
      <GridTable<SummaryRow>
        id={id}
        rows={[simpleHeader, ...rows]}
        columns={summaryColumns()}
        style={{}}
        rowStyles={{ total: { cellCss: Css.fwb.$ } }}
      />
    </div>
  );
}

type DateSettingsProps = {
  formState: ObjectState<FormInput>;
};

function DateSettings({ formState }: DateSettingsProps) {
  const tid = useTestIds({}, "dateSettings");
  return (
    <div css={Css.ba.br8.bcGray100.p3.f(0.4).bgGray100.h100.$}>
      <h2 css={Css.lgSb.mbPx(12).$} {...tid.title}>
        Invoice Dates
      </h2>
      <div css={Css.mbPx(20).$}>
        <BoundBeamDateField
          field={formState.invoiceDate}
          helperText={
            <div css={Css.df.gap(0.5).fdr.$}>
              <span css={Css.$}>Defaults to today. Backdating may cause an error.</span>
              <Tooltip title={"If the General Ledger period is closed for the selected date, an error will occur."}>
                <Icon icon="infoCircle" inc={2} />
              </Tooltip>
            </div>
          }
          {...tid.invoiceDate}
        />
      </div>
      <div css={Css.mbPx(20).$}>
        <BoundBeamDateField
          field={formState.dueDate}
          disabledDays={{ before: formState.invoiceDate.value! }}
          helperText={`Due Date defaults to 30 days after the Invoice Date.`}
          {...tid.dueDate}
        />
      </div>
      {formState.paidDate?.value ? (
        <div css={Css.mbPx(20).$}>
          <BoundBeamDateField field={formState.dueDate} helperText={`Invoice Paid Date.`} readOnly {...tid.paidDate} />
        </div>
      ) : null}
    </div>
  );
}

type FormInput = Omit<SaveInvoiceInput, "lineItems" | "drawLineItems">;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  invoiceDate: { type: "value", rules: [required] },
  dueDate: { type: "value", rules: [required] },
  paidDate: { type: "value" },
};

type TotalRow = { kind: "total"; data: { totalInCents: number } };
type LineItemRow = { kind: "lineItem"; data: { title: string; toInvoiceInCents: number } };
type HeaderRow = { kind: "header" };
type SummaryRow = HeaderRow | LineItemRow | TotalRow;

const summaryColumns = () => [
  column<SummaryRow>({
    header: "Cost Code",
    lineItem: ({ title }) => title,
    total: "Total",
  }),
  numericColumn<SummaryRow>({
    header: "To Invoice",
    lineItem: ({ toInvoiceInCents }) => priceCell({ valueInCents: toInvoiceInCents }),
    total: ({ totalInCents }) => priceCell({ valueInCents: totalInCents }),
  }),
];
