import { Button, Css, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import {
  CreateCommitmentModal_ProjectItemFragment,
  SaveCommitmentLineItemInput,
  useCreateCommitmentModalProjectItemLazyQuery,
  useSaveNewCommitmentMutation,
} from "src/generated/graphql-types";
import { CommitmentCreatedModal } from "src/routes/projects/selections/bulkActions/CommitmentCreatedModal";

type CreateCommitmentModalProps = {
  projectId: string;
  projectStageId: string;
  selectedProjectItemIds: string[];
};

export function CreateCommitmentModal(props: CreateCommitmentModalProps) {
  const { projectId, projectStageId, selectedProjectItemIds } = props;
  const [selectedItemsQuery] = useCreateCommitmentModalProjectItemLazyQuery();
  const [saveCommitment] = useSaveNewCommitmentMutation();
  const { closeModal, openModal } = useModal();

  return (
    <>
      <ModalHeader>Create Commitment</ModalHeader>
      <ModalBody>
        <p css={Css.smMd.mb2.$}>
          {selectedProjectItemIds.length > 1
            ? `${selectedProjectItemIds.length} items selected`
            : `${selectedProjectItemIds.length} item selected`}
        </p>
        <p>{`Are you sure you want to create a draft commitment with the ${
          selectedProjectItemIds.length > 1 ? "items" : "item"
        } selected?`}</p>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Create"
          onClick={async () => {
            const queryResult = await selectedItemsQuery({ variables: { filter: { id: selectedProjectItemIds } } });
            if (queryResult && queryResult.data) {
              const mutationResult = await saveCommitment({
                variables: {
                  input: {
                    lineItems: queryResult.data.projectItems.map((sPi) => mapToSaveCommitmentLineItemInput(sPi)),
                    projectStageId,
                  },
                },
              });

              const newCommitmentId = mutationResult.data?.saveCommitment.commitment.id;
              if (newCommitmentId) {
                openModal({
                  content: <CommitmentCreatedModal projectId={projectId} commitmentId={newCommitmentId} />,
                });
              }
            }
          }}
        />
      </ModalFooter>
    </>
  );
}

function mapToSaveCommitmentLineItemInput(pi: CreateCommitmentModal_ProjectItemFragment): SaveCommitmentLineItemInput {
  return {
    costChangeInCents: pi.protoCommitmentLineItemToBuyout?.costChangeInCents || 0,
    quantity: pi.protoCommitmentLineItemToBuyout?.quantity,
    projectItemId: pi.id,
  };
}
