import { Css, FilterDefs, Filters, multiFilter, numberRangeFilter, useTestIds } from "@homebound/beam";
import { camelCase } from "change-case";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ProductAttribute,
  ProductStatus,
  ProductStatusDetail,
  useProductAttributesQuery,
} from "src/generated/graphql-types";
import { groupBy, queryResult } from "src/utils";
import { ProductCatalogQueryParams } from "../hooks/useProductCatalog";

type ProductCatalogFilterProps = {
  settings: ProductCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<ProductCatalogQueryParams>>;
};

export function ProductCatalogFilter({ settings, setSettings }: ProductCatalogFilterProps) {
  const result = useProductAttributesQuery();
  return queryResult(result, (data) => (
    <ProductsFilter
      productAttributes={data.attributes}
      productStatuses={data.productStatuses}
      settings={settings}
      setSettings={setSettings}
    />
  ));
}

type FilterProps = {
  productAttributes: ProductAttribute[];
  productStatuses: ProductStatusDetail[];
  settings: ProductCatalogQueryParams;
  setSettings: Dispatch<SetStateAction<ProductCatalogQueryParams>>;
};

type LocalFilters = {
  market?: string[];
  brand?: string[];
  finish?: string[];
  designPackage?: string[];
  price?: {
    min?: number | undefined | null;
    max?: number | undefined | null;
  };
  status?: ProductStatus[] | null;
};

function ProductsFilter({ productAttributes, productStatuses, settings, setSettings }: FilterProps) {
  const groupedAttributes = groupBy(productAttributes, (attribute) => attribute.type);
  const testIds = useTestIds({});
  const [localFilters, setLocalFilters] = useState<LocalFilters>(mapToLocalFilters(settings, productAttributes));

  useEffect(
    () => {
      // Reset the current page to 1 if the filters change
      setSettings(mapToProviderFilters(localFilters, settings.perPage));
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localFilters, setLocalFilters],
  );

  return (
    <div css={Css.wPx(272).h100.bgGray100.p2.$} {...testIds}>
      <h1 css={Css.xlMd.pb2.$}>Filter</h1>
      <Filters
        filterDefs={createFilterDefs(groupedAttributes, productStatuses)}
        filter={localFilters}
        onChange={setLocalFilters}
        vertical
      />
    </div>
  );
}

function createFilterDefs(
  groupedAttributes: Record<string, ProductAttribute[]>,
  productStatuses: ProductStatusDetail[],
): FilterDefs<LocalFilters> {
  const market = multiFilter({
    label: "Market",
    options: groupedAttributes["MARKET"],
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.value,
  });
  const brand = multiFilter({
    label: "Brand",
    options: groupedAttributes["BRAND"],
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.value,
  });
  const finish = multiFilter({
    label: "Finish",
    options: groupedAttributes["FINISH"],
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.value,
  });
  const designPackage = multiFilter({
    label: "Design Package",
    options: groupedAttributes["DESIGN_PACKAGE"],
    getOptionValue: (o) => o.id,
    getOptionLabel: (o) => o.value,
  });
  const price = numberRangeFilter({
    label: "Price",
    numberFieldType: "cents",
  });
  const status = multiFilter({
    label: "Status",
    options: productStatuses,
    getOptionValue: (o) => o.code,
    getOptionLabel: (o) => o.name,
  });

  return {
    market,
    brand,
    finish,
    designPackage,
    price,
    status,
  };
}

const attributeKeys = ["market", "brand", "finish", "designPackage"] as const;

function mapToProviderFilters(localFilters: LocalFilters, perPage: number): ProductCatalogQueryParams {
  // map `localFilters` to the ProductCatalogProvider `filters` state object
  const attributes = Object.entries(localFilters)
    .filter(([key, value]) => attributeKeys.includes(key as (typeof attributeKeys)[number]))
    .flatMap(([key, value]) => value as string) as string[];
  return {
    attributes: attributes.nonEmpty ? attributes : undefined,
    status: localFilters.status?.nonEmpty ? localFilters.status : undefined,
    minPrice: localFilters.price?.min,
    maxPrice: localFilters.price?.max,
    page: 1,
    perPage,
  };
}

function mapToLocalFilters(settings: ProductCatalogQueryParams, productAttributes: ProductAttribute[]): LocalFilters {
  // map the incoming provider filters to the local filters state
  const attributes = {} as LocalFilters;
  attributeKeys.forEach((key) => (attributes[key] = []));
  settings?.attributes?.forEach((attributeId) => {
    const attribute = productAttributes.find(({ id }) => id === attributeId);
    const filterKey = camelCase(attribute!.type);
    (attributes[filterKey as keyof LocalFilters] as string[]).push(attributeId);
  });

  return {
    ...attributes,
    status: settings.status,
    price: {
      min: settings.minPrice,
      max: settings.maxPrice,
    },
  };
}
