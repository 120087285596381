import { ButtonMenu, useModal } from "@homebound/beam";
import {
  DevelopmentProjectDetailFragment,
  ProjectsForLotSequenceFragment,
  useCohortDevelopmentsQuery,
} from "src/generated/graphql-types";
import { createCohortButtonMenuItems } from "../utils";

type MoveProjectButtonMenuProps = {
  project: ProjectsForLotSequenceFragment | DevelopmentProjectDetailFragment;
};

export function MoveProjectButtonMenu({ project }: MoveProjectButtonMenuProps) {
  const { openModal } = useModal();
  const { data } = useCohortDevelopmentsQuery({ fetchPolicy: "cache-first" });
  const { cohorts } = data || { cohorts: [] };

  return (
    <ButtonMenu trigger={{ icon: "arrowFromLeft" }} items={createCohortButtonMenuItems(cohorts, project, openModal)} />
  );
}
