import {
  actionColumn,
  ButtonMenu,
  column,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridSortConfig,
  multiFilter,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
  Tag,
  toggleFilter,
  useModal,
  usePersistedFilter,
  useSnackbar,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { QueryTable, SearchBox } from "src/components";
import {
  AdminProjectsFilterDataQuery,
  LotType,
  Order,
  ProjectAdminFragment,
  ProjectFeature,
  ProjectFeatureDetail,
  ProjectFilter,
  ProjectOrder,
  ProjectsQuery,
  useAdminProjectsFilterDataQuery,
  useProjectsQuery,
  useSetProjectIsTestMutation,
} from "src/generated/graphql-types";
import { ProjectFeaturesCell } from "src/routes/admin/components/ProjectFeaturesCell";
import { ProjectStatusCell } from "src/routes/admin/components/ProjectStatusCell";
import { QbIdModal } from "src/routes/admin/components/QbIdModal";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import {
  keyBy,
  projectStatusDetails,
  queryResult,
  removeTag,
  safeEntries,
  sortBy,
  stageCodeToNameMapper,
} from "src/utils";
import { parseOrder, toOrder } from "../../../utils/ordering";

export function AdminProjects() {
  const query = useAdminProjectsFilterDataQuery({ fetchPolicy: "cache-first" });
  return queryResult(query, (data) => <AdminProjectsView metadata={data} />);
}

function AdminProjectsView({ metadata }: { metadata: AdminProjectsFilterDataQuery }) {
  const { cohorts, developments, markets, projectFeatures } = metadata;
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [orderBy, setOrderBy] = useState<ProjectOrder>({ id: Order.Asc });
  const filterDefs: FilterDefs<ProjectFilter> = useMemo(() => {
    const latestActiveStage = multiFilter({
      options: safeEntries(stageCodeToNameMapper),
      getOptionValue: ([code]) => code,
      getOptionLabel: ([, name]) => name,
    });

    const market = multiFilter({
      options: markets,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const status = multiFilter({
      options: safeEntries(projectStatusDetails),
      getOptionValue: ([code]) => code,
      getOptionLabel: ([_code, name]) => name,
    });
    const development = multiFilter({
      options: developments,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const cohort = multiFilter({
      options: sortBy(cohorts, ({ name }) => name),
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    });

    const lotType = multiFilter({
      options: [LotType.Bool, LotType.Boyl, LotType.Hbl, LotType.Btr, LotType.Fee],
      getOptionValue: (v) => v,
      getOptionLabel: (v) => v,
    });

    const isTest = toggleFilter({ label: "Only show test projects" });

    return { latestActiveStage, market, status, development, cohort, lotType, isTest };
  }, [markets, developments, cohorts]);

  const { setFilter, filter } = usePersistedFilter<ProjectFilter>({
    storageKey: "projectFilter",
    filterDefs,
  });

  const query = useProjectsQuery({
    variables: {
      filter: { ...filter, search: searchFilter },
      page: { limit: 100, offset: 0 },
      orderBy,
    },
  });

  const maybeFetchNextPage = useCallback(async () => {
    if (query.data?.projectsPage.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: { page: { offset: query.data?.projectsPage.entities?.length, limit: 100 } },
      });
    }
  }, [query]);

  const initSortState: GridSortConfig = useMemo(
    () => ({
      on: "server",
      onSort: (key, direction) => setOrderBy(toOrder(key, direction)),
      value: parseOrder(orderBy),
    }),
    [orderBy, setOrderBy],
  );

  const featureCodeToName = keyBy(
    projectFeatures,
    (f) => f.code,
    (f) => f.name,
  );

  const columns = useMemo(
    () => createColumns(featureCodeToName, projectFeatures),
    [featureCodeToName, projectFeatures],
  );

  return (
    <>
      <PageHeader title="Mission Control &#x1F680;" />
      <TableActions>
        <Filters<ProjectFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox onSearch={setSearchFilter} clearable debounceDelayInMs={500} />
      </TableActions>
      <ScrollableContent virtualized>
        <QueryTable
          stickyHeader
          keepHeaderWhenLoading
          sorting={initSortState}
          emptyFallback="No projects found that match the given filters."
          query={query}
          columns={columns}
          createRows={createRows}
          style={{ bordered: true }}
          as="virtual"
          infiniteScroll={{ onEndReached: maybeFetchNextPage }}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<ProjectAdminFragment>;

function createRows(data: ProjectsQuery | undefined): GridDataRow<Row>[] {
  const projects = data?.projectsPage.entities ?? [];
  return [simpleHeader, ...projects.map((p) => ({ kind: "data" as const, id: p.id, data: p }))];
}

function createColumns(
  featureCodeToName: Record<ProjectFeature, string>,
  features: ProjectFeatureDetail[],
): GridColumn<Row>[] {
  return [
    column<Row>({
      header: () => "ID",
      data: ({ id }) => ({ content: () => id, value: removeTag(id) }),
      w: "80px",
      serverSideSortKey: "id",
    }),
    column<Row>({
      header: () => "Address",
      data: ({ buildAddress, isTest }) => ({
        content: () => {
          return (
            <div css={Css.df.aic.gap1.$}>
              <span>{buildAddress.street1}</span>
              {isTest && <Tag text="Test" type="info" />}
            </div>
          );
        },
        value: `${buildAddress.street1} ${isTest ? "Test" : ""}`,
      }),
      mw: "360px",
      serverSideSortKey: "buildAddress",
    }),
    column<Row>({
      header: () => "Status",
      data: (project) => ({
        content: () => <ProjectStatusCell project={project} />,
        value: project.status.name,
      }),
      w: "160px",
      serverSideSortKey: "status",
    }),
    column<Row>({
      header: () => "Stage",
      data: ({ latestActiveStage }) => latestActiveStage?.stage.name,
      w: "140px",
      serverSideSortKey: "latestActiveStage",
    }),
    column<Row>({
      header: () => "Market",
      data: ({ market }) => market.name,
      w: "160px",
      serverSideSortKey: "market",
    }),
    column<Row>({
      header: () => "Homeowner",
      data: ({ primaryHomeowner }) => primaryHomeowner?.shortName,
      w: "180px",
      serverSideSortKey: "homeowner",
    }),
    column<Row>({
      header: () => "Features",
      data: (project) => ({
        content: () => <ProjectFeaturesCell project={project} features={features} />,
        value: project.features.map((f) => featureCodeToName[f]).join(" "),
      }),
      w: "360px",
    }),
    actionColumn<Row>({
      header: () => "Actions",
      data: (project) => <ProjectActionsMenu project={project} />,
      w: "80px",
      clientSideSort: false,
    }),
  ];
}

type ProjectActionsMenuProps = {
  project: ProjectAdminFragment;
};

function ProjectActionsMenu({ project }: ProjectActionsMenuProps) {
  const { openModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [projectIsTestMutation] = useSetProjectIsTestMutation();

  const toggleTest = useCallback(async () => {
    await projectIsTestMutation({ variables: { projectId: project.id, isTest: !project.isTest } });

    triggerNotice({
      icon: "success",
      message: `${project.buildAddress.street1} (${project.id}) ${
        !project.isTest ? "marked" : "unmarked"
      } as Test Project`,
    });
  }, [triggerNotice, projectIsTestMutation, project]);

  return (
    <ButtonMenu
      trigger={{ icon: "verticalDots" }}
      placement="right"
      items={[
        { label: project.isTest ? "Unmark as test" : "Mark as test", onClick: toggleTest },
        {
          label: "Edit QBO ID",
          onClick: () => openModal({ content: <QbIdModal project={project} /> }),
        },
      ]}
    />
  );
}
