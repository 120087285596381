import { BoundSelectField } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { useProjectRoleDetailsQuery } from "src/generated/graphql-types";
import { FormInput, TaskDetailsFormInput } from "../table/utils";

type RoleSelectFieldProps = {
  formState: ObjectState<FormInput> | ObjectState<Partial<TaskDetailsFormInput>>;
  scheduleIsLocked: boolean;
};

export function RoleSelectField({ formState, scheduleIsLocked }: RoleSelectFieldProps) {
  const { data: projectRoleDetailsData } = useProjectRoleDetailsQuery({ fetchPolicy: "cache-first" });
  return (
    <BoundSelectField
      label="Role"
      compact
      field={formState.templateProjectRole}
      options={projectRoleDetailsData?.projectRoleDetails ?? []}
      getOptionLabel={(pr) => pr.name}
      placeholder={"No Role selected"}
      getOptionValue={(pr) => pr.code}
      disabled={scheduleIsLocked}
    />
  );
}
