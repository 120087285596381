import { HistoryFeed } from "src/components";
import { useDevelopmentCommitmentHistoryTabQuery } from "src/generated/graphql-types";
import { queryResult } from "src/utils";

export type HistoryTabProps = {
  developmentCommitmentId: string;
};

export function HistoryTab({ developmentCommitmentId }: HistoryTabProps) {
  const query = useDevelopmentCommitmentHistoryTabQuery({
    variables: { id: developmentCommitmentId },
  });

  return queryResult(query, {
    data: (data) => <HistoryFeed historyItems={[...data.developmentCommitment.history].reverse()} />,
  });
}
