import { Css } from "@homebound/beam";

type FixedFooterLayoutProps = {
  children: React.ReactNode;
  footer: React.ReactNode;
};

const FOOTER_HEIGHT = 100;

export function FixedFooterLayout({ children, footer }: FixedFooterLayoutProps) {
  return (
    <div css={Css.df.fdc.fg1.oh.$}>
      <div css={Css.fg1.oa.pb4.mbPx(FOOTER_HEIGHT / 2).$}>{children}</div>
      <div
        css={
          Css.df.aic.fs0.fixed.z999.bottom0.left0.right0.bgGray100.py2.px4
            .hPx(FOOTER_HEIGHT)
            .oxa.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$
        }
      >
        {footer}
      </div>
    </div>
  );
}
