import {
  actionColumn,
  BoundNumberField,
  BoundSelectField,
  column,
  emptyCell,
  GridColumn,
  numericColumn,
  SuperDrawerWidth,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { runInAction } from "mobx";
import { Link } from "react-router-dom";
import { CommentCountBubble } from "src/components";
import { BidItemSelectField } from "src/components/autoPopulateSelects/BidItemSelectField";
import { CostSourceSelectField } from "src/components/autoPopulateSelects/CostSourceSelectField";
import { ProductSelectField } from "src/components/autoPopulateSelects/ProductSelectField";
import { CommentsSidebar } from "src/components/comments/CommentsSidebar";
import {
  BidContractRevisionStatus,
  ReadyPlanOption,
  SpecsAndSelections_UnitOfMeasureFragment,
} from "src/generated/graphql-types";
import { SpecsAndSelectionsMode } from "src/routes/projects/components/SpecsAndSelectionsTable";
import { ObservableProjectItem, ProjectItemFormInput } from "src/routes/projects/models/ObservableProjectItem";
import { sum } from "src/utils";
import { SpecsAndSelectionsRow } from "../models/ProjectItemStore";

type ProjectItemColumnProps = {
  createProjectItemUrl: ((projectItemId: string) => string) | undefined;
  showSelections: boolean;
};

export function getProjectItemColumn({
  createProjectItemUrl,
  showSelections,
}: ProjectItemColumnProps): GridColumn<SpecsAndSelectionsRow> {
  return column<SpecsAndSelectionsRow>({
    id: "projectItem",
    header: () => "Item",
    totals: () => "Totals",
    group: (data, { row }) => ({
      content: () => data.name,
      sortValue: row.sortValue,
      typeScale: "smMd",
    }),
    projectItem: (row) => ({
      content: () =>
        createProjectItemUrl ? (
          <Link
            to={{
              pathname: createProjectItemUrl(row.id),
              // Pass through the search query to keep state for SuperDrawer feature
              // since this feature redirects back to the same page.
              search: window.location.search,
              state: { showSelections },
            }}
            data-testid={`lineItem_${row.id}`}
            id={`lineItem_${row.id}`}
            title={row.fragment.displayName}
          >
            {row.fragment.displayName}
          </Link>
        ) : (
          <span id={`lineItem_${row.id}`} title={row.fragment.displayName}>
            {row.fragment.displayName}
          </span>
        ),
      value: row.fragment.displayName,
    }),
    lineItem: (row) => {
      // We can't just use `row.type` to know "is this base or not", b/c some
      const rpos = [row.elevationIds, row.specOptionIds, row.otherOptionIds]
        .flatMap((ids) => ids.map((id) => row.options.find((rpo) => rpo.id === id)))
        .compact();
      return `${itiOptionsDisplayValue(rpos)} @ ${row.first.displayVersion}`;
    },
    w: "260px",
    sticky: "left",
  });
}

export function itiOptionsDisplayValue(rpos: Pick<ReadyPlanOption, "name" | "code" | "shortName">[]) {
  // Adds are only within an option. So instead infer 'Base' as "no options are set".
  return rpos.isEmpty
    ? "Base"
    : rpos.map((x) => `${x.code} - ${x.name}${x.shortName ? ` (${x.shortName})` : ""}`).join(", ");
}

export const quantityColumn = (
  readOnly: boolean,
  getFormState: (input: ObservableProjectItem) => ObjectState<ProjectItemFormInput>,
): GridColumn<SpecsAndSelectionsRow> => {
  return numericColumn<SpecsAndSelectionsRow>({
    header: () => "Qty",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () =>
          row.fragment.unitOfMeasure.useQuantity === false ? (
            <span data-testid="quantity" data-readonly="true">
              N/A
            </span>
          ) : (
            <BoundNumberField field={os.quantity} readOnly={readOnly} />
          ),
        value: () => (row.fragment.unitOfMeasure.useQuantity ? "N/A" : (row.quantity ?? "")),
      };
    },
    lineItem: (row) => row.quantity ?? "",
    w: "70px",
  });
};

function bidItemColumn(readOnly: boolean) {
  return column<SpecsAndSelectionsRow>({
    header: () => "Bid Item",
    totals: emptyCell,
    group: emptyCell,
    projectItem: (row) => {
      const maybeLumpSum = row.fragment.unitOfMeasure.name === "Lump Sum";
      if (maybeLumpSum) return "N/A";
      if (readOnly && !row.fragment.bidItem) return "Not selected";
      return {
        content: () => (
          <BidItemSelectField
            label="Bid Item"
            value={row.bidItem}
            filter={{ projectItemId: row.id }}
            readOnly={readOnly}
            onSelect={(id, selectedBidItem) =>
              runInAction(() => {
                row.bidItemId = id;
                row.bidItem = selectedBidItem;
                // Unset the cost source if the bid item is changed
                row.bidContractLineItemId = undefined;
                row.bidContractLineItem = undefined;
                row.productId = undefined;
                row.product = undefined;
              })
            }
          />
        ),
        value: row.bidItem?.displayName,
      };
    },
    lineItem: () => emptyCell,
    w: "200px",
  });
}

function productColumn(readOnly: boolean) {
  return column<SpecsAndSelectionsRow>({
    header: () => "Product",
    totals: emptyCell,
    group: emptyCell,
    projectItem: (row) => {
      const hs = row.fragment.homeownerSelection;
      if (hs) {
        // Selections cannot directly edit the product, so just show the name
        return hs.selectedOption?.product.name ?? "Not selected";
      } else if (!row.bidItemId) {
        return emptyCell;
      } else {
        return (
          <ProductSelectField
            filter={{ bidItems: [row.bidItemId].compact() }}
            disabled={row.bidItemId === undefined}
            value={row.product}
            readOnly={readOnly}
            onSelect={(product) => {
              runInAction(() => {
                row.productId = product?.id;
                row.product = product;
              });
            }}
          />
        );
      }
    },
    lineItem: () => emptyCell,
    clientSideSort: false,
    w: "200px",
  });
}

/** We only display the bid item, product code and cost source columns in the S&S tables */
export function maybeBidItemColumn(mode: SpecsAndSelectionsMode, readOnly: boolean) {
  // mode can be one of the following: estimates | S&S | S&S-PartialReadOnly
  return mode !== "estimate" ? [bidItemColumn(readOnly), productColumn(readOnly), costSourceColumn(readOnly)] : [];
}

function costSourceColumn(readOnly: boolean) {
  return column<SpecsAndSelectionsRow>({
    header: () => "Cost Source",
    totals: emptyCell,
    group: emptyCell,
    projectItem: (row) => ({
      content: () => {
        const bidItemId = row.bidItemId;
        if (!bidItemId) {
          return <>Manual</>;
        }
        return (
          <CostSourceSelectField
            filter={{
              status: [BidContractRevisionStatus.Signed],
              bidItemIds: [bidItemId],
              developmentId: row.fragment.project.cohort?.development?.id,
            }}
            value={row.bidContractLineItem}
            readOnly={readOnly || !row.fragment.canEditCost.allowed}
            onSelect={(bcliId, selectedCostSource) => {
              runInAction(() => {
                row.bidContractLineItem = selectedCostSource;
                row.bidContractLineItemId = bcliId;
                if (bcliId && selectedCostSource) {
                  // Setting the qty to 1 to not lose the applied bcli costs
                  row.quantity = row.quantity || 1;
                  row.totalCostInCents = (selectedCostSource?.unitCostInCents || 0) * (row.quantity || 1);
                }
              });
            }}
          />
        );
      },
      value: row.fragment.unitOfMeasure.name,
    }),
    lineItem: () => emptyCell,
    w: "200px",
  });
}

export function commentColumn(): GridColumn<SpecsAndSelectionsRow> {
  return actionColumn<SpecsAndSelectionsRow>({
    header: () => "Comments",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => ({
      content: () => <CommentCell data={row} />,
      value: () => row.fragment.streams.map(({ commentCount }) => commentCount).reduce(sum, 0),
    }),
    lineItem: () => emptyCell,
    w: "90px",
  });
}

export function idColumn(): GridColumn<SpecsAndSelectionsRow> {
  return column<SpecsAndSelectionsRow>({
    header: () => "ID",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => row.id,
    lineItem: () => emptyCell,
    w: "90px",
    sticky: "left",
  });
}

function CommentCell({ data }: { data: ObservableProjectItem }) {
  const { openInDrawer } = useSuperDrawer();
  return (
    <CommentCountBubble
      streams={data.fragment.streams}
      onClick={() =>
        openInDrawer({ content: <CommentsSidebar commentableId={data.id} />, width: SuperDrawerWidth.Small })
      }
    />
  );
}

export function unitColumn(
  readOnly: boolean,
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[] | undefined,
  getFormState: (input: ObservableProjectItem) => ObjectState<ProjectItemFormInput>,
): GridColumn<SpecsAndSelectionsRow> {
  return column<SpecsAndSelectionsRow>({
    header: () => "Unit",
    totals: () => emptyCell,
    group: () => emptyCell,
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () => (
          // If we do not have `unitsOfMeasure` then default the options to this row's unitOfMeasure so `SelectField` can show the value
          <BoundSelectField
            field={os.unitOfMeasureId}
            readOnly={readOnly || !!row.fragment.homeownerSelection}
            options={unitsOfMeasure || (row.fragment.unitOfMeasure ? [row.fragment.unitOfMeasure] : [])}
          />
        ),
        value: row.fragment.unitOfMeasure.name,
      };
    },
    lineItem: () => emptyCell,
    w: "140px",
  });
}
