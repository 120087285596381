import {
  Button,
  Chip,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelectField,
  SelectField,
  Tooltip,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import {
  AddItemsFromExistingTemplateQuery,
  AddItemsFromExistingTemplateRpoFragment,
  ItemTemplateDetailFragment,
  useAddItemsFromExistingTemplateQuery,
  useItemTemplatesQuery,
} from "src/generated/graphql-types";
import { NotFound } from "src/routes/NotFound";
import { itemTemplateStatusToTagType, queryResult, safeEntries } from "src/utils";
import { ItemTemplateApi } from "./api/ItemTemplateApi";

export type AddItemsFromExistingItemTemplateModalProps = {
  itApi: ItemTemplateApi;
  // TODO: `isReadyHomeTemplate` could/should move into the itApi
  template: Pick<ItemTemplateDetailFragment, "id" | "isReadyHomeTemplate">;
  isReadyHomeTemplate?: boolean;
};

export function AddItemsFromExistingItemTemplateModal(props: AddItemsFromExistingItemTemplateModalProps) {
  const query = useAddItemsFromExistingTemplateQuery({
    variables: { templateId: props.itApi.templateId },
    fetchPolicy: "cache-first",
  });
  return queryResult(query, {
    data: ({ itemTemplate }) => {
      if (!itemTemplate) return <NotFound />;
      return <AddItemsFromExistingItemTemplateModalContent itemTemplate={itemTemplate} {...props} />;
    },
  });
}

type AddItemsFromExistingItemTemplateModalContentProps = AddItemsFromExistingItemTemplateModalProps & {
  itemTemplate: AddItemsFromExistingTemplateQuery["itemTemplate"];
};

function AddItemsFromExistingItemTemplateModalContent(props: AddItemsFromExistingItemTemplateModalContentProps) {
  const { isReadyHomeTemplate = false, template, itemTemplate, itApi } = props;
  const { id } = template;
  const { closeModal } = useModal();
  const testid = useTestIds(props);
  const { triggerNotice } = useSnackbar();
  const [sourceTemplateId, setSourceTemplateId] = useState<string>();
  const [destinationOptionId, setDestinationOptionId] = useState<string[]>([baseHouseOptionId]);
  const [destinationElevationId, setDestinationElevationId] = useState<string | undefined>(allElevationsOptionId);
  const query = useItemTemplatesQuery({
    variables: { filter: { isReadyHomeTemplate, canCopyItemsTo: id } },
  });

  const [elevationOptions, nonElevationOptions] = useMemo(() => {
    // Using `as any` because AddItemsOption requires a string for the id field, but we're using null.
    const baseOption: AddItemsOption = { id: baseHouseOptionId, name: "Base House" };
    const allOption: AddItemsOption = { id: allElevationsOptionId, name: "All Elevations" };
    const defaultOptions: [AddItemsFromExistingTemplateRpoFragment[], AddItemsFromExistingTemplateRpoFragment[]] = [
      [],
      [],
    ];

    const [elevationOptions, nonElevationOptions] =
      itemTemplate.readyPlan?.options
        .filter((o) => o.active)
        .map((o) => ({
          ...o,
          name: o.shortName ? `${o.displayName} (${o.shortName})` : o.displayName,
        }))
        .partition((o) => o.type.isElevation) ?? defaultOptions;
    const [exteriorSchemeOptions, nonElevationNonExteriorSchemeOptions] = nonElevationOptions.partition(
      (rpo) => rpo.type.isExteriorPalette,
    );
    const otherOptions = nonElevationNonExteriorSchemeOptions.filter((rpo) => !rpo.type.isSpecLevel);
    // Create a sort of,..
    // { id: globalOption.id , name: rpo.name, rpoIds: [rpo.id,...] }
    const groupedExteriorSchemeOptions = safeEntries(exteriorSchemeOptions.groupBy((rpo) => rpo.globalOption.id));

    return [
      [allOption, ...elevationOptions.sortByKey("name")],
      [
        baseOption,
        ...[
          ...groupedExteriorSchemeOptions.map(([id, options]) => ({
            id,
            name: options.first!.displayName,
            rpoIds: options.map((rpo) => rpo.id),
          })),
          ...otherOptions.map((rpo) => ({
            id: rpo.id,
            name: rpo.displayName,
            rpoIds: [rpo.id],
          })),
        ].sortByKey("name"),
      ],
    ];
  }, [itemTemplate]);

  const maybeTriggerNotice = useCallback(
    (addedCount: number) => {
      const [sourceTemplate] = (query.data?.itemTemplates ?? []).filter((item) => item.id === sourceTemplateId);
      if (sourceTemplate) {
        triggerNotice({
          message: `${addedCount} items were successfully added from ${sourceTemplate.name}`,
        });
      }
    },
    [query.data?.itemTemplates, sourceTemplateId, triggerNotice],
  );

  const onClickHandler = useCallback(async () => {
    const result = await itApi.copyFromTemplate({
      targetTemplateId: id,
      sourceTemplateId: sourceTemplateId!,
      ...(!isReadyHomeTemplate && {
        targetOptionIds: destinationOptionId.flatMap(
          (id) =>
            nonElevationOptions
              .find((option) => option.id === id)
              ?.rpoIds?.map((rpoId) => (rpoId === baseHouseOptionId ? null : rpoId)) ?? [
              id === baseHouseOptionId ? null : id,
            ],
        ),
        targetElevationIds: [destinationElevationId === allElevationsOptionId ? null : destinationElevationId],
      }),
    });
    const items = result.data?.copyItemTemplateItems?.copiedItemVersions ?? [];
    maybeTriggerNotice(items.length);
    closeModal();
  }, [
    id,
    sourceTemplateId,
    destinationOptionId,
    destinationElevationId,
    itApi,
    maybeTriggerNotice,
    closeModal,
    nonElevationOptions,
    isReadyHomeTemplate,
  ]);

  return (
    <>
      <ModalHeader>
        {isReadyHomeTemplate ? "Add items from an existing ready plan template" : "Add items from a template"}
      </ModalHeader>
      <ModalBody>
        <div css={{ ...Css.mt2.mb1.$, "& > label": Css.smMd.$ }}>
          <>
            {isReadyHomeTemplate && (
              <div css={Css.sm.gray800.$}>
                Only ready plan templates from developments with the same specification level configuration are
                available to select.
              </div>
            )}
            <div css={{ ...Css.mt2.mb1.df.fdc.gap2.$, "& > label": Css.smMd.$ }}>
              {!isReadyHomeTemplate && template.isReadyHomeTemplate && (
                <div css={Css.df.gap1.$}>
                  <SelectField
                    {...testid.elevationSelect}
                    label="Add items to Elevation"
                    options={elevationOptions}
                    onSelect={setDestinationElevationId}
                    value={destinationElevationId}
                    nothingSelectedText="Select an Elevation"
                  />
                  <MultiSelectField
                    {...testid.optionSelect}
                    label="and options"
                    options={nonElevationOptions}
                    onSelect={setDestinationOptionId}
                    values={destinationOptionId}
                    nothingSelectedText="Select Base House or Options"
                  />
                </div>
              )}
              <SelectField
                {...testid.selectTemplate}
                label={isReadyHomeTemplate ? "Ready plan template" : "From template"}
                value={sourceTemplateId}
                onSelect={setSourceTemplateId}
                options={query.data?.itemTemplates || []}
                getOptionLabel={(template) =>
                  isReadyHomeTemplate ? `${template.displayName} - ${template.development?.name}` : `${template.name}`
                }
                getOptionMenuLabel={(template) => (
                  <div css={Css.df.jcc.aic.$}>
                    <span css={Css.wPx(310).mrPx(20).$}>
                      {isReadyHomeTemplate
                        ? `${template.displayName} - ${template.development?.name}`
                        : `${template.displayName}`}
                    </span>
                    {template.isVersioned && (
                      <span>
                        <Tooltip title={template.status} placement="right">
                          <Chip
                            text={`v${template.version}`}
                            type={itemTemplateStatusToTagType(template.status)}
                            compact
                          />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                )}
                getOptionValue={(template) => template.id}
              />
            </div>
          </>
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap1.$}>
          <Button label="Cancel" variant="tertiary" onClick={closeModal} />
          <Button
            label="Add Items"
            variant="primary"
            disabled={!sourceTemplateId}
            onClick={onClickHandler}
            data-testid="addItemsFromExistingItemTemplate"
          />
        </div>
      </ModalFooter>
    </>
  );
}

type AddItemsOption = {
  id: string;
  name: string;
  rpoIds?: string[];
};

const baseHouseOptionId = "baseHouse";
const allElevationsOptionId = "allElevations";
