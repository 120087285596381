import {
  BoundSelectField,
  BoundTextField,
  Button,
  Css,
  FormDivider,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { FileUploadProgress, UppyUploader } from "src/components";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  DocumentDetailDocumentTypeFragment,
  DocumentType,
  InputMaybe,
  Maybe,
  RequiredTaskDocumentDetailFragment,
  SaveDocumentInput,
  Scalars,
  useDocumentsForParentQuery,
  useSaveRequiredTaskDocumentMutation,
  useSaveRequiredTaskDocumentOnPlanTaskMutation,
  useSaveTaskDocumentsMutation,
} from "src/generated/graphql-types";
import { sortBy } from "src/utils";
import { ObjectConfig, useFormState } from "src/utils/formState";

type LinkDocumentRequirementModalProps = {
  documentTypes: DocumentDetailDocumentTypeFragment[];
  parentId: string;
  requiredTaskDocument: RequiredTaskDocumentDetailFragment;
  onPlanTask?: boolean;
};

export function LinkDocumentRequirementModal(props: LinkDocumentRequirementModalProps) {
  const { documentTypes, parentId, requiredTaskDocument, onPlanTask } = props;
  const [saveDocuments] = useSaveTaskDocumentsMutation({ refetchQueries: ["DocumentsForParent"] });
  const [saveRequiredTaskDocument] = useSaveRequiredTaskDocumentMutation();
  const [saveRequiredTaskDocumentOnPlanTask] = useSaveRequiredTaskDocumentOnPlanTaskMutation();
  const { closeModal } = useModal();

  const { data } = useDocumentsForParentQuery({
    variables: { parentId },
  });

  const sortedDocuments = sortBy(data?.documents ?? [], ({ name }) => name);
  const documentTypeOptions = documentTypes.filter((docType) => !docType.systemControlled);

  const modalForm = useFormState({
    config: formConfig,
    init: {
      onlyOnce: true,
      input: { document: "", documentType: "", type: requiredTaskDocument.documentType.code },
    },
  });

  const onFinish = async (file: UppyFile) => {
    const saveDocumentsInput: [SaveDocumentInput] = [
      {
        documentType: modalForm.type.value,
        name: file.name,
        sizeInBytes: file.size,
        parentId,
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
        },
      },
    ];
    const { data } = await saveDocuments({ variables: { input: saveDocumentsInput } });
    const newDocument = data?.saveDocuments.documents[0];
    if (newDocument) {
      modalForm.document.set(newDocument.id);
      modalForm.documentType.set(newDocument.documentType.name ?? undefined);
    }
  };

  const linkToRequiredTaskDocument = async () => {
    // The first mutation can be removed once #SC-49625 (canComplete) has been implemented for PlanTask
    const mutation = onPlanTask ? saveRequiredTaskDocumentOnPlanTask : saveRequiredTaskDocument;
    await mutation({ variables: { input: { id: requiredTaskDocument.id, documentId: modalForm.document.value } } });
  };

  return (
    <Observer>
      {() => (
        <>
          <ModalHeader>Link Required Document</ModalHeader>
          <ModalBody>
            <p css={Css.mb3.$}>
              Add an existing document or upload a new one to <span css={Css.fwb.$}>{requiredTaskDocument.name}</span>.
            </p>
            <p css={Css.mb3.lgMd.gray900.$}>Link an Existing Document</p>
            <div css={Css.mb2.$}>
              <BoundSelectField
                label="Document"
                placeholder="-"
                aria-label="Link document to required task document"
                nothingSelectedText="Select a document"
                options={sortedDocuments}
                field={modalForm.document}
                onSelect={(val) => {
                  modalForm.document.set(val);
                  modalForm.documentType.set(
                    data?.documents.find((doc) => doc.id === val)?.documentType.name ?? undefined,
                  );
                }}
              />
            </div>
            <h1 css={Css.gray700.$}>Type</h1>
            <div css={Css.mb2.$}>
              {/* This fits the design to display the type but is not part of the form */}
              <BoundTextField
                label="documentType"
                placeholder="-"
                aria-label=""
                labelStyle="hidden"
                field={modalForm.documentType}
                disabled
              />
            </div>
            <div css={Css.mb3.mt3.$}>
              <FormDivider />
            </div>
            <h1 css={Css.mb2.lgMd.gray900.$}>Upload new document</h1>
            <UploaderProvider>
              <div data-testid="fileUploader" css={Css.mb2.$}>
                <UppyUploader
                  onFinish={onFinish}
                  dragDropWidth={432}
                  dragDropText="Drag & drop or click to add file"
                  maxNumberOfFiles={1}
                />
              </div>
              <FileUploadProgress hideOnComplete />
            </UploaderProvider>
            <h1 css={Css.gray700.$}>Type</h1>
            <div css={Css.mb2.$}>
              <BoundSelectField
                placeholder="-"
                nothingSelectedText="Select a type"
                labelStyle="hidden"
                options={documentTypeOptions}
                field={modalForm.type}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ code }) => code}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" label="Cancel" onClick={closeModal} />
            <Button
              label="Link Document"
              disabled={!modalForm.document.value}
              onClick={async () => {
                await linkToRequiredTaskDocument();
                closeModal();
              }}
            />
          </ModalFooter>
        </>
      )}
    </Observer>
  );
}

type TaskDocumentsFormValue = {
  document: Maybe<Scalars["ID"]>;
  documentType: Maybe<Scalars["String"]>;
  type: InputMaybe<DocumentType>;
};

const formConfig: ObjectConfig<TaskDocumentsFormValue> = {
  document: { type: "value" },
  documentType: { type: "value" },
  type: { type: "value" },
};
