import {
  Button,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  selectColumn,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useSnackbar,
} from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { chipCell, emptyCellDash, linkHeader, priceCell, tagCell } from "src/components";
import { AssetGallery } from "src/components/assetGallery/AssetGallery";
import {
  CommitmentStatus,
  ProjectFeature,
  ReviewOrdersStepCommitmentLikeFragment,
  ReviewOrdersStepQuery,
  Stage,
  useReviewOrdersStepQuery,
  useReviewOrdersStepReleaseCommitmentsMutation,
} from "src/generated/graphql-types";
import { useMaybeAllocateItemTaskModal } from "src/hooks/useMaybeAllocateItemTaskModal";
import { LotSummary } from "src/routes/developments/lot-summary/lot-release/components/LotSummary";
import { DevelopmentParams, ProjectParams } from "src/routes/routesDef";
import { createDevelopmentLotSummaryReleaseUrl } from "src/RouteUrls";
import { commitmentStatusTagTypeMapper, count, queryResult } from "src/utils";

type DevelopmentProjectParams = DevelopmentParams & ProjectParams;

export function ReviewOrdersStep() {
  const { developmentId, projectId } = useParams<DevelopmentProjectParams>();
  const query = useReviewOrdersStepQuery({ variables: { id: projectId } });

  return queryResult(query, ({ project }) => (
    <>
      <div css={Css.bgGray100.h100.w100.px8.py3.$}>
        <div css={Css.df.gap5.$}>
          <div css={Css.fg1.$}>
            <ReviewOrdersStepView
              commitments={project.stage.commitmentLikes}
              developmentId={developmentId}
              project={project}
            />
          </div>
          <div css={Css.mw("330px").$}>
            <LotSummary border />
          </div>
        </div>
      </div>
    </>
  ));
}

type ReviewOrdersStepViewProps = {
  commitments: ReviewOrdersStepCommitmentLikeFragment[];
  developmentId: string;
  project: ReviewOrdersStepQuery["project"];
};

function ReviewOrdersStepView({ commitments, developmentId, project }: ReviewOrdersStepViewProps) {
  const columns = useMemo(() => createColumns(), []);
  const rows = useMemo(() => createRows(commitments), [commitments]);
  const tableApi = useGridTableApi<Row>();
  const selectedLineItems = useComputed(() => tableApi.getSelectedRowIds("data"), [tableApi]);
  const selectedLineItemsData = useComputed(() => tableApi.getSelectedRows("data"), [tableApi]);
  const [releaseCommitments] = useReviewOrdersStepReleaseCommitmentsMutation();
  const { triggerNotice } = useSnackbar();
  const maybeOpenAllocateModal = useMaybeAllocateItemTaskModal();
  const handleMaybeOpenAllocationModal = (otherwise: VoidFunction) => {
    maybeOpenAllocateModal({
      projectItems: selectedLineItemsData.flatMap((os) => os.data.lineItems).map((li) => li.projectItem),
      // original op to perform if item allocation not allowed
      otherwise,
      projectId: project.id,
      stage: Stage.Construction,
      showClickToPayFlag: project.features.includes(ProjectFeature.ClickToPay),
      lotType: project.lotType?.code,
    });
  };

  return (
    <>
      <div css={Css.pb8.$}>
        <GridTable
          api={tableApi}
          columns={columns}
          fallbackMessage="No Line Items found."
          rows={rows}
          sorting={{ on: "client" }}
          style={{ allWhite: true, bordered: true }}
        />
      </div>
      <div
        css={
          Css.df.jcfe.gap1.aic.fixed.z999.bottom0.left0.right0.bgGray100.px4
            .hPx(100)
            .oxa.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$
        }
      >
        <div css={Css.df.gap1.$}>
          <Button
            size="lg"
            label="Exit"
            onClick={createDevelopmentLotSummaryReleaseUrl(developmentId)}
            variant="secondary"
          />
          <Button
            disabled={selectedLineItems.length === 0}
            label="Release"
            size="lg"
            onClick={() =>
              handleMaybeOpenAllocationModal(async () => {
                const selectedItemsNum = selectedLineItems.length;
                await releaseCommitments({ variables: { commitmentLikeIds: selectedLineItems } });
                // clear table selections
                tableApi.clearSelections();
                // show snackbar
                triggerNotice({
                  icon: "success",
                  message: `${count(selectedItemsNum, "Purchase Order")} successfully sent`,
                });
              })
            }
          />
        </div>
      </div>
    </>
  );
}

function createColumns(): GridColumn<Row>[] {
  const selectCol = selectColumn<Row>({ w: "48px" });

  const poNumCol = column<Row>({
    header: "PO #",
    data: ({ accountingNumber, blueprintUrl }) => linkHeader(String(accountingNumber), blueprintUrl.path),
    mw: "125px",
  });

  const tradePartnerCol = column<Row>({
    header: "Trade Partner",
    data: ({ tradePartner }) => tradePartner?.name,
    mw: "125px",
    clientSideSort: false,
  });

  const costCodesCol = column<Row>({
    header: "Cost Codes",
    data: ({ lineItems }) => {
      const costCodes = lineItems.map((li) => li.costCode.number);
      // chip cell uniques passed values
      return chipCell(costCodes);
    },
    mw: "125px",
    clientSideSort: false,
  });

  const numLineItemsCol = column<Row>({
    header: "# of Line Items",
    data: ({ lineItems }) => lineItems.length,
    mw: "125px",
  });

  const totalCommittedCol = column<Row>({
    header: "Total Committed",
    data: ({ committedInCents }) => priceCell({ valueInCents: committedInCents }),
    mw: "125px",
  });

  const statusCol = column<Row>({
    header: "Status",
    data: ({ status, statusText }) => tagCell(commitmentStatusTagTypeMapper(status, statusText), statusText),
    mw: "125px",
  });

  const pdfCol = column<Row>({
    header: "PDF",
    data: ({ document }) => {
      if (!document?.asset) return emptyCellDash;
      const { asset } = document;
      return (
        <AssetGallery assets={[asset]}>
          {(openGallery) => <IconButton icon="file" onClick={() => openGallery(asset)} />}
        </AssetGallery>
      );
    },
    mw: "98px",
    clientSideSort: false,
  });

  return [selectCol, poNumCol, tradePartnerCol, costCodesCol, numLineItemsCol, totalCommittedCol, statusCol, pdfCol];
}

function createRows(commitments: ReviewOrdersStepCommitmentLikeFragment[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...commitments.map((c) => ({
      kind: "data" as const,
      id: c.id,
      data: c,
      selectable: isSelectable(c),
    })),
  ];
}

function isSelectable(commitment: ReviewOrdersStepCommitmentLikeFragment): false | undefined {
  return commitment.status === CommitmentStatus.Signed || commitment.statusText === "Pending Approval"
    ? false
    : undefined;
}

type HeaderRow = { kind: "header" };
type CommitmentRow = { kind: "data"; data: ReviewOrdersStepCommitmentLikeFragment };
type Row = HeaderRow | CommitmentRow;
