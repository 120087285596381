import {
  BoundDateField,
  BoundMultiSelectField,
  BoundSelectField,
  BoundSwitchField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  IconButton,
  StaticField,
  useComputed,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { BidPackageDetailsStepQuery, CostType, useBidPackageDetailsStepQuery } from "src/generated/graphql-types";
import { isDefined, queryResult } from "src/utils";
import { BidPackageFormState } from "../AddBidPackagePage";

export function BidPackageDetailsStep({ formState }: { formState: ObjectState<BidPackageFormState> }) {
  const query = useBidPackageDetailsStepQuery();
  return queryResult(query, (data) => <BidPackageDetailsView {...data} formState={formState} />);
}

function BidPackageDetailsView(props: BidPackageDetailsStepQuery & { formState: ObjectState<BidPackageFormState> }) {
  const { formState, developments, productOfferings, costCodes } = props;
  const isHistoricalBid = formState.isHistoricalBid.value;

  const availableProductOfferings = useComputed(() => {
    if (!formState.development.value) return productOfferings.entities;
    // If developments have been selected filter the available product offerings by them
    return productOfferings.entities.filter(
      (po) =>
        formState.development.value!.includes(po.development!.id) || formState.productOfferings.value?.includes(po.id),
    );
  }, [formState, productOfferings.entities]);

  return (
    <>
      <div css={Css.df.fdc.aic.$}>
        <div css={Css.df.fdc.aic.my6.$}>
          <div css={Css.xl3Sb.$}>Create a bid package</div>
          <div css={Css.base.pt2.$}>
            Get started by bidding out as many product offerings and bid categories as you need to.
          </div>
        </div>

        <div css={Css.w50.p4.bgWhite.bshBasic.br12.mb3.$}>
          <div css={Css.lgSb.mb2.$}>Create Package Overview</div>
          <div css={Css.sm.mb3.$}>
            The trade categories and product offerings here will determine which lines of scope are auto filled into
            your packages for bidding.
          </div>
          <FormLines labelSuffix={{ required: "*" }} labelStyle="left" compact width="full">
            <BoundSelectField
              label="Development"
              options={developments}
              field={formState.development}
              getOptionLabel={(d) => d.name ?? ""}
              getOptionValue={(d) => d.id}
            />
            <div css={Css.df.fdr.aic.$}>
              <span css={Css.w50.gray700.$}>Is Unit Based Bid</span>
              <BoundSwitchField
                field={formState.isUnitBased}
                onChange={(val) => {
                  if (!!val) {
                    formState.productOfferings.set([]);
                  }
                  formState.isUnitBased.set(val);
                }}
                label="Is Unit Based Bid"
                labelStyle="hidden"
              />
            </div>
            <Observer>
              {() => (
                <BoundMultiSelectField
                  label="Product Offerings"
                  field={formState.productOfferings}
                  options={availableProductOfferings}
                  disabled={
                    !!formState.isUnitBased?.value && "Unit bids will apply to all product offerings in the market"
                  }
                />
              )}
            </Observer>
            <Observer>
              {() => (
                <StaticField label="Cost Codes *">
                  <div css={Css.w100.df.fdc.gap1.$}>
                    {formState.bidPackages.rows.compact().map((bp, i) => {
                      // Filter out cost codes that are in other bid packages
                      const availableCostCodes = costCodes.filter(
                        (cc) =>
                          !formState.bidPackages.rows.some((bp, j) => j !== i && bp.costCodes.value?.includes(cc.id)),
                      );
                      // Check if this bid package should be deleted (removing from the formstate does not delete in the DB)
                      if (bp.delete.value === true) return null;
                      return (
                        <div css={Css.df.gapPx(4).$} key={i}>
                          <BoundMultiSelectField
                            field={bp.costCodes}
                            options={availableCostCodes}
                            labelStyle="hidden"
                            multiline
                          />

                          {formState.bidPackages.rows.length > 1 && (
                            <div css={Css.ptPx(2).$}>
                              <IconButton
                                icon="trash"
                                // we need to remove the bid package from the DB using this boolean, otherwise it will only be removed from the UI
                                onClick={() => {
                                  if (isDefined(bp.id.value)) {
                                    bp.delete.set(true);
                                  } else {
                                    formState.bidPackages.remove(i);
                                  }
                                }}
                                label="Remove bid package"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div css={Css.mb2.$}>
                      <Button
                        variant="text"
                        icon="plus"
                        label="Add another bid package"
                        onClick={() => formState.bidPackages.add({})}
                      />
                    </div>
                  </div>
                </StaticField>
              )}
            </Observer>
            <BoundSelectField
              field={formState.costType}
              getOptionValue={(ct) => ct.code}
              getOptionLabel={(ct) => ct.name}
              options={[
                { code: CostType.Labor, name: "Labor" },
                { code: CostType.Materials, name: "Material" },
                { code: CostType.Subcontractor, name: "Subcontractor" },
              ]}
            />
            {!isHistoricalBid && (
              <>
                <BoundTextField field={formState.planRoomLink} />
                <BoundTextAreaField label="Invitation Text" field={formState.invitationText} />
                <BoundTextAreaField label="Bid Instruction" field={formState.bidInstructionsText} />
              </>
            )}
          </FormLines>
        </div>

        {!isHistoricalBid && (
          <div css={Css.w50.p4.bgWhite.bshBasic.br12.$}>
            <div css={Css.lgSb.$}>Set the Bid Timeline</div>
            <div css={Css.base.pt2.mb3.$}>
              This will apply to all bid packages you create unless you modify them individually in the review step
              before sending.
            </div>
            <FormLines labelSuffix={{ required: "*" }} labelStyle="left" compact width="full">
              <div css={Css.df.fdc.gap1.my1.$}>
                <BoundDateField field={formState.dueDate} label="Bids Due" />
                <BoundDateField field={formState.estimatedAwardDate} label="Bids Awarded" />
                <BoundDateField field={formState.estimatedConstructionDate} label="Est. Construction Start" />
                <BoundDateField field={formState.sendReminderDate} label="Send reminder" />
              </div>
            </FormLines>
          </div>
        )}
      </div>
    </>
  );
}
