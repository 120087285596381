import {
  actionColumn,
  Button,
  column,
  Css,
  dateColumn,
  emptyCell,
  GridColumn,
  GridTable,
  IconButton,
  ModalProps,
  numericColumn,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { useMemo } from "react";
import { preserveLinesCell } from "src/components";
import { formatDate } from "src/components/FormattedDate";
import {
  ReviewTabReviewFragment,
  TradePartnerReviewsQuery,
  useTradePartnerReviewsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils/queryResult";
import { HasIdAndName } from "src/utils/types";
import { sum } from "src/utils/utils";
import { TradePartnerReviewModalContent } from "./TradePartnerReviewModalContent";

type TradePartnerReviewsProps = {
  tradePartnerId: string;
};

export function TradePartnerReviews({ tradePartnerId }: TradePartnerReviewsProps) {
  const query = useTradePartnerReviewsQuery({ variables: { tradePartnerId } });
  return queryResult(query, (data) => <DataView data={data} tradePartnerId={tradePartnerId} />);
}

function DataView(props: { data: TradePartnerReviewsQuery; tradePartnerId: string }) {
  const { data, tradePartnerId } = props;
  const {
    tradePartner: { reviews },
  } = data;
  const { openModal } = useModal();
  const testIds = useTestIds({}, "tpr");
  const columns = useMemo(
    () => createColumns(tradePartnerId, openModal, data.projects, data.internalUsers),
    [tradePartnerId, openModal, data],
  );
  return (
    <div>
      <div css={Css.df.aic.jcsb.mb2.$}>
        <h2 css={Css.db.gray800.base.$}>
          Reviews
          {reviews && reviews.length > 0 && (
            <span css={Css.tinySb.$} {...testIds.avgScore}>
              {" "}
              ({averageScore(reviews)} avg - {reviews.length}
              {reviews.length > 1 ? " reviews" : " review"})
            </span>
          )}
        </h2>
        <Button
          icon="plus"
          label="Create Review"
          onClick={() => {
            openModal({
              content: (
                <TradePartnerReviewModalContent
                  review={undefined}
                  tradePartnerId={tradePartnerId}
                  internalUsers={data.internalUsers}
                  projects={data.projects}
                />
              ),
            });
          }}
          variant="secondary"
        />
      </div>
      <ScrollableContent>
        <GridTable
          stickyHeader
          columns={columns}
          fallbackMessage="No reviews found."
          rows={simpleDataRows(reviews)}
          rowStyles={{
            header: { cellCss: Css.tinySb.$ },
            data: {},
          }}
          sorting={{ on: "client", initial: ["date", "DESC"] }}
        />
      </ScrollableContent>
    </div>
  );
}

type TradePartnerRow = SimpleHeaderAndData<ReviewTabReviewFragment>;

function createColumns(
  tradePartnerId: string,
  openModal: (props: ModalProps) => void,
  projects: HasIdAndName[],
  internalUsers: HasIdAndName[],
): GridColumn<TradePartnerRow>[] {
  return [
    column<TradePartnerRow>({ header: "Project", data: ({ project }) => project.name }),
    numericColumn<TradePartnerRow>({ header: "Score", data: ({ score }) => score }),
    column<TradePartnerRow>({
      header: "Recommended",
      data: ({ recommended }) => (recommended ? "Yes" : typeof recommended === "boolean" && !recommended ? "No" : ""),
    }),
    column<TradePartnerRow>({
      header: "Note",
      data: ({ internalNote }) => preserveLinesCell(internalNote || ""),
      w: 2,
    }),
    column<TradePartnerRow>({ header: "Reviewer", data: ({ internalUser }) => internalUser.name }),
    dateColumn<TradePartnerRow>({ id: "date", header: "Date", data: ({ createdAt }) => formatDate(createdAt) }),
    actionColumn<TradePartnerRow>({
      header: emptyCell,
      data: (tpr) => (
        <IconButton
          icon="pencil"
          onClick={() => {
            openModal({
              content: (
                <TradePartnerReviewModalContent
                  review={tpr}
                  tradePartnerId={tradePartnerId}
                  internalUsers={internalUsers}
                  projects={projects}
                />
              ),
            });
          }}
        />
      ),
    }),
  ];
}

function averageScore(reviews: ReviewTabReviewFragment[]): number {
  if (reviews.length > 0) {
    const totalScore = reviews.map((tpr) => tpr.score).reduce(sum, 0);
    return Number((totalScore / reviews.length).toFixed(2));
  }
  return 0;
}
