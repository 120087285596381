import { FieldState } from "@homebound/form-state";
import equal from "fast-deep-equal";
import { comparer, reaction } from "mobx";
import { useEffect } from "react";
import { Maybe } from "src/generated/graphql-types";
import { isDefined, roundToDigits, safeKeys } from "src/utils";

type RecalcInput = {
  // To be as generic as possible, we let all of our fields be optional keys
  totalPriceInCents?: Maybe<number>;
  totalCostInCents?: Maybe<number>;
  quantity?: Maybe<number>;
  unitCostInCents?: Maybe<number>;
  unitPriceInCents?: Maybe<number>;
  totalMarkupInCents?: Maybe<number>;
  markupPercentage?: Maybe<number>;
  markupBasisPoints?: Maybe<number>;
};

type CalculatePriceFieldOpts = {
  canEditPrice: boolean;
  unitFieldDecimalPlaces?: number;
};
type RecalcFn = (input: RecalcInput, newValue: number, opts: CalculatePriceFieldOpts) => Partial<RecalcInput>;

type ChangeKey = keyof RecalcInput;

// This should eventually be driven by a per-project setting
const defaultMarkup = 20;

export function calculateForm(
  input: RecalcInput,
  changedKey: ChangeKey,
  changedValue: number | undefined,
  opts: CalculatePriceFieldOpts,
): RecalcInput {
  // It is possible that the `changedValue` has been set to null or undefined.
  // Project Items should only show a null state when initially created.
  // Once a line item is touched, all null or undefined values should be treated as `0`.
  changedValue = changedValue || 0;
  // Fill in any extra fields that we can, i.e. a calc might way to use logic like
  // "keep existing markup %, and use it to calc new price", but technically
  // `input.markupPercentage` is not set yet.
  const derived = deriveFromInput(input, opts);
  const newValues = updateFns[changedKey](derived, changedValue, opts);
  return updateMarginsAndUnits(
    {
      ...derived,
      [changedKey]: changedValue,
      ...newValues,
    },
    opts,
  );
}

/**
 * A hook to re-calc cost/markup/price/etc. on change.
 *
 * The caller passes an `observeFn` that should return `.value`s from a mobx/form-state
 * proxy that is being edited.
 *
 * On any change, `updateFn` will be called with the change + any percolated values.
 */
export function useCalculatePriceFields(
  observeFn: () => RecalcInput,
  updateFn: (output: RecalcInput) => void,
  opts: CalculatePriceFieldOpts,
  deps: any[],
) {
  useEffect(() => {
    return reaction(
      observeFn,
      (curr, prev) => {
        const updated = calculateReaction(curr, prev, opts);
        // The user changing (say) price will kick off a real re-calc, and we'll (say) recalc margin,
        // which our observe will then see as "oh and the margin changed". However, this 2nd change
        // isn't a real change, and so `calculateForm` (thankfully) will not change anything, i.e.
        // the new value will be a noop, so we can ignore it.
        if (updated && !equal(curr, updated)) {
          updateFn(updated);
        }
      },
      { equals: comparer.shallow },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

/**
 * Setups up cost/margin/price calculation but w/o a hook.
 *
 * This is very similar to what `useCalculatePriceFields` provides, but handles directly
 * reading/updating each FieldState in a form-state.
 *
 * In particular this is useful for when the form-state names are slightly different from the
 * canonical RecalcInput names (i.e. `undecidedUnitPriceInCents` vs. `unitPriceInCents`), as
 * well as calling `setupPriceFields` from a `useFormStates`'s `addRules`, where we can't
 * have a dedicated per-row hook.
 */
export function setupPriceFields(
  fields: Partial<Record<keyof RecalcInput, FieldState<Maybe<number>>>>,
  opts: CalculatePriceFieldOpts,
): void {
  reaction(
    () => {
      return {
        // Unit-based callers don't always have a quantity, so assume 1
        quantity: 1,
        // Map each `{ unitCostInCents: someField }` to that field's current value
        ...Object.fromEntries(Object.entries(fields).map(([key, field]) => [key, field.value])),
      };
    },
    (curr, prev) => {
      const updated = calculateReaction(curr, prev, opts);
      if (updated && !equal(curr, updated)) {
        // Push each `{ unitCostInCents: number }` back into the field
        Object.entries(fields).forEach(([key, field]) => {
          field.set((updated as any)[key], { autoSave: false });
        });
      }
    },
    { equals: comparer.shallow },
  );
}

// exported for testing
export function calculateReaction(
  curr: RecalcInput,
  prev: RecalcInput,
  opts: CalculatePriceFieldOpts,
): RecalcInput | undefined {
  const changed = changedKeys(curr, prev);
  if (changed.length > 1) {
    // If more than one key changed, assume `.set` was called, and we should just re-derive the values
    return deriveFromInput(curr, opts);
  } else {
    const k = changed[0];
    // If the user has cleared a field, they are probably mid-edit, so just leave
    // everything as-is and wait for them to type in a new value.
    //
    // Note: We should _probably_ do this inside of `calculateForm`, which is currently treating
    // `undefined` as 0, but that might be valid/legacy behavior for unsetting fields (by other code
    // that directly calls calculateForm), e.g. in formState if they want to unset quantity on an
    // existing entity, it will be `quantity=null`.
    if (curr[k] === undefined || curr[k] === null) {
      return;
    }
    return calculateForm(prev, k, curr[k]!, opts);
  }
}

const calcTotalCostChange: RecalcFn = (input, newTotalCost, opts) => {
  if (opts.canEditPrice) {
    // Recalculate: Total Price (and Markup Amount)
    // Fixed: Unit Cost and Markup Percent
    const markupPercentage = input.markupPercentage ?? defaultMarkup;
    const newMarkupAmount = (newTotalCost * markupPercentage) / 100;
    const totalPriceInCents = Math.round(newTotalCost + newMarkupAmount);
    return { totalPriceInCents };
  } else {
    // Recalculate: Markup Percent and Amount
    return {};
  }
};

const calcTotalPriceChange: RecalcFn = () => {
  // Recalculate: Markup Amount / Percentage
  return {};
};

const calcQuantityChange: RecalcFn = (input, newQuantity) => {
  // Recalculate: Total Cost, Total Price, and Markup Amount
  // Fixed: Unit Cost and Markup Percent
  const { unitCostInCents } = input;
  if (!isDefined(unitCostInCents)) {
    return {};
  }
  const totalCostInCents = newQuantity * unitCostInCents;
  const markupPercentage = input.markupPercentage ?? defaultMarkup;
  const newMarkupAmount = (markupPercentage / 100) * totalCostInCents;
  const totalPriceInCents = Math.round(totalCostInCents + newMarkupAmount);
  return { totalPriceInCents, totalCostInCents };
};

const calcMarkupAmountChange: RecalcFn = (input, newMarkupAmount) => {
  // Recalculate: Total Price (and Markup Percent)
  // Fixed: Total Cost, Unit Cost, and Quantity
  const { totalCostInCents } = input;
  const totalPriceInCents = Math.round((totalCostInCents ?? 0) + newMarkupAmount);
  return { totalPriceInCents };
};

const calcUnitCostInCentsChange: RecalcFn = (input, newUnitCost, opts) => {
  const { quantity } = input;
  if (!isDefined(quantity)) {
    return {};
  }

  // When quantity is 0, we assume totalCost and unitCost are the same
  const totalCostInCents = quantity === 0 ? newUnitCost : newUnitCost * quantity;
  if (opts.canEditPrice) {
    // Recalculate: Total Cost, Total Price, and Markup Amount
    // Fixed: Quantity, Markup Percent
    const markupPercentage = input.markupPercentage ?? defaultMarkup;
    const markupInCents = totalCostInCents * (markupPercentage / 100);
    const totalPriceInCents = Math.round(totalCostInCents + markupInCents);
    return { totalPriceInCents, totalCostInCents };
  } else {
    // Recalculate: Markup Amount & Percent
    return { totalCostInCents };
  }
};

const calcUnitPriceChange: RecalcFn = (input, newUnitPrice) => {
  // Recalculate: Markup
  const newTotalPrice = newUnitPrice * (input.quantity ?? 1);
  return { totalPriceInCents: newTotalPrice };
};

const calcMarkupPercentageChange: RecalcFn = (input, newMarkupPercentage) => {
  // Recalculate: Total Price (and Markup Amount)
  // Fixed:  Total Cost, Unit Cost, and Quantity
  const { totalCostInCents } = input;
  if (!isDefined(totalCostInCents)) {
    return {};
  }
  const totalPriceInCents = Math.round(totalCostInCents * (newMarkupPercentage / 100) + totalCostInCents);
  return { totalPriceInCents };
};

const calcMarkupBasisPoints: RecalcFn = (input, newMarkupBasisPoints, opts) => {
  return calcMarkupPercentageChange(input, newMarkupBasisPoints / 100, opts);
};

const updateFns: Record<ChangeKey, RecalcFn> = {
  unitCostInCents: calcUnitCostInCentsChange,
  totalMarkupInCents: calcMarkupAmountChange,
  markupPercentage: calcMarkupPercentageChange,
  markupBasisPoints: calcMarkupBasisPoints,
  quantity: calcQuantityChange,
  totalCostInCents: calcTotalCostChange,
  totalPriceInCents: calcTotalPriceChange,
  unitPriceInCents: calcUnitPriceChange,
};

/**
 * Fills in any potentially-knowable fields from currently-set fields.
 *
 * E.g. if markup amount is not set, but cost and price are, we'll fill in
 * markup amount, so that subsequent logic like "if cost changed, set new price
 * = new cost + markup amount" will have the markup amount available.
 *
 * That said, we respect all existing input, even `undefined`s.
 */
export function deriveFromInput(input: RecalcInput, opts?: CalculatePriceFieldOpts): RecalcInput {
  let {
    quantity,
    totalPriceInCents,
    totalCostInCents,
    totalMarkupInCents,
    markupPercentage,
    markupBasisPoints,
    unitCostInCents,
    unitPriceInCents,
  } = input;

  // Derive unit cost/total cost if needed
  if (!isDefined(unitCostInCents)) {
    if (isDefined(quantity) && isDefined(totalCostInCents) && quantity !== 0) {
      unitCostInCents = roundToDigits(totalCostInCents / quantity, opts?.unitFieldDecimalPlaces ?? 0);
    }
  }
  if (!isDefined(totalCostInCents)) {
    if (isDefined(quantity) && isDefined(unitCostInCents) && quantity !== 0) {
      totalCostInCents = Math.round(unitCostInCents * quantity);
    }
  }

  // Derive unit price/total price if needed
  if (!isDefined(unitPriceInCents)) {
    if (isDefined(quantity) && isDefined(totalPriceInCents) && quantity !== 0) {
      unitPriceInCents = roundToDigits(totalPriceInCents / quantity, opts?.unitFieldDecimalPlaces ?? 0);
    }
  }
  if (!isDefined(totalPriceInCents)) {
    if (isDefined(quantity) && isDefined(unitPriceInCents) && quantity !== 0) {
      totalPriceInCents = Math.round(unitPriceInCents * quantity);
    }
  }

  // Derive total markup/markup percentage
  if (!isDefined(totalMarkupInCents)) {
    if (isDefined(totalPriceInCents) && isDefined(totalCostInCents)) {
      totalMarkupInCents = totalPriceInCents - totalCostInCents;
    }
  }

  // Sneak in markupBasisPoints --> markupPercentage, before the markupPercentage handleing
  if (isDefined(markupBasisPoints) && !isDefined(markupPercentage)) {
    markupPercentage = toPercentWithTwoDecimals(markupBasisPoints / 10000);
  }

  if (!isDefined(markupPercentage)) {
    if (isDefined(totalMarkupInCents) && isDefined(totalCostInCents) && totalCostInCents !== 0) {
      markupPercentage = toPercentWithTwoDecimals(totalMarkupInCents / totalCostInCents);
    } else if (isDefined(unitCostInCents) && isDefined(unitPriceInCents) && unitCostInCents !== 0) {
      const unitMarkupInCents = unitPriceInCents - unitCostInCents;
      markupPercentage = toPercentWithTwoDecimals(unitMarkupInCents / unitCostInCents);
    } else if (totalMarkupInCents === 0) {
      // Respect the $0 markup, not the defaultMarkup
      markupPercentage = 0;
    }
  }

  if (!isDefined(markupBasisPoints) && isDefined(markupPercentage)) {
    markupBasisPoints = Math.round(markupPercentage * 100);
  }

  return {
    quantity,
    totalCostInCents,
    totalPriceInCents,
    totalMarkupInCents,
    markupPercentage,
    markupBasisPoints,
    unitCostInCents,
    unitPriceInCents,
  };
}

/**
 * Assume we've reactively updated the "core" fields of quantity, total cost, total price and derive the others.
 */
export function updateMarginsAndUnits(input: RecalcInput, opts?: CalculatePriceFieldOpts): RecalcInput {
  let {
    quantity,
    totalPriceInCents,
    totalCostInCents,
    totalMarkupInCents,
    markupPercentage,
    markupBasisPoints,
    unitCostInCents,
    unitPriceInCents,
  } = input;

  // These calcs are extremely similar to deriveFromInput, but instead of leaving
  // the existing value (which may be the user's requested changed value), we assume
  // the "core" fields are now correct, and we should "true up"/write over any existing
  // values (which would be a noop for a just-changed value).
  if (isDefined(totalPriceInCents) && isDefined(totalCostInCents)) {
    totalMarkupInCents = totalPriceInCents - totalCostInCents;
  }

  if (isDefined(totalMarkupInCents) && isDefined(totalCostInCents) && totalCostInCents !== 0) {
    markupPercentage = toPercentWithTwoDecimals(totalMarkupInCents / totalCostInCents);
    markupBasisPoints = Math.round(markupPercentage * 100);
  }

  if (!isDefined(quantity) && isDefined(totalCostInCents)) {
    unitCostInCents = totalCostInCents;
  }

  if (isDefined(quantity) && isDefined(totalCostInCents) && quantity !== 0) {
    unitCostInCents = roundToDigits(totalCostInCents / quantity, opts?.unitFieldDecimalPlaces ?? 0);
  }

  if (isDefined(quantity) && isDefined(totalPriceInCents) && quantity !== 0) {
    unitPriceInCents = roundToDigits(totalPriceInCents / quantity, opts?.unitFieldDecimalPlaces ?? 0);
  }

  return {
    quantity,
    totalCostInCents,
    totalPriceInCents,
    totalMarkupInCents,
    markupPercentage,
    markupBasisPoints,
    unitCostInCents,
    unitPriceInCents,
  };
}

function changedKeys<T>(a: T, b: T): Array<keyof T> {
  return safeKeys(a).filter((k) => a[k] !== b[k]);
}

/** E.g. 23 / 100 --> 23 so that it's "23%". */
function toPercentWithTwoDecimals(number: number): number {
  // Times 10k, divide by 100 to ensure 2 decimals
  return Math.round(number * 10_000) / 100;
}
