import {
  EstimateLineItemDetailFragment,
  EstimatePageQuery,
  SpecsAndSelections_ProjectItemFragment,
  useEstimateLineItemTabQuery,
} from "src/generated/graphql-types";
import { SpecsAndSelectionsTable } from "src/routes/projects/components/SpecsAndSelectionsTable";
import { queryResult } from "src/utils/queryResult";
import { ProjectItemsContextProvider } from "../context/ProjectItemsContext";

export type EstimateLineItemsTabProps = {
  estimate: EstimatePageQuery["projectEstimate"];
};

export function EstimateLineItemsTab(props: EstimateLineItemsTabProps) {
  const { estimate } = props;

  const query = useEstimateLineItemTabQuery({ variables: { estimateId: estimate.id } });

  return queryResult(query, {
    data: ({ projectEstimate }) => {
      const pis = projectEstimate.lineItems.map(overlayEstimateLineItemOnProjectItem);
      return (
        <ProjectItemsContextProvider>
          <SpecsAndSelectionsTable
            disableSelectable
            mode="estimate"
            projectItems={pis}
            stage={estimate.projectStage.stage.code}
            filterDefs={{}}
            uiFilter={{}}
            setFilter={() => {}}
          />
        </ProjectItemsContextProvider>
      );
    },
  });
}

/**
 * The SpecsAndSelectionsTable wants "basically project items" so we pass it those, but first
 * layer over our snapshot estimate values, instead of using the latest project item values.
 */
export function overlayEstimateLineItemOnProjectItem(
  eli: EstimateLineItemDetailFragment,
): SpecsAndSelections_ProjectItemFragment {
  return {
    ...eli.projectItem,
    totalCostInCents: eli.totalCostInCents,
    totalPriceInCents: eli.totalPriceInCents,
    quantity: eli.quantity ?? 0,
    displayName: eli.displayName,
  };
}
