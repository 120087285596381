import {
  Button,
  Css,
  FilterDefs,
  Filters,
  GridDataRow,
  ModalBody,
  ModalFooter,
  ModalHeader,
  multiFilter,
  ScrollableContent,
  SelectField,
  Switch,
  useComputed,
  useGridTableApi,
  useModal,
  usePersistedFilter,
  useTestIds,
} from "@homebound/beam";
import { ObjectState, useFormStates } from "@homebound/form-state";
import { useEffect, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  ItemTemplateItemVersionFilter,
  NamedFragment,
  SaveScheduleTemplateItemMappingInput,
  useCostMappingScheduleTasksQuery,
  useSaveMapItemsStepMutation,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { isDefined, queryResult } from "src/utils";
import MapTemplateItemsTable, { MapTemplateItemRow, MapTemplateItemsRow } from "../components/MapTemplateItemsTable";
import { ScheduleTemplateWithMappingInfo } from "../DevelopmentScheduleCostMappingPage";
import { DevelopmentItemMappingsStore } from "../models/DevelopmentItemMappingsStore";
import { mapTemplateItemsFormConfig, MapTemplateItemsFormValue } from "../models/DevelopmentPotentialItemMapping";

type MapItemsStepProps = {
  selectedScopeTemplate: ScheduleTemplateWithMappingInfo;
};

export function MapItemsStep({ selectedScopeTemplate }: MapItemsStepProps) {
  const { costCodesOptionsMap, locationsOptionsMap } = useMemo(
    () =>
      selectedScopeTemplate.stageMappings.reduce(
        (acc, sm) => {
          acc.locationsOptionsMap.set(sm.location.id, sm.location);
          acc.costCodesOptionsMap.set(sm.item.costCode.id, sm.item.costCode);

          return acc;
        },
        { locationsOptionsMap: new Map(), costCodesOptionsMap: new Map() },
      ),
    [selectedScopeTemplate.stageMappings],
  );

  const filterDefs: FilterDefs<ItemTemplateItemVersionFilter> = useMemo(() => {
    const costCode = multiFilter({
      options: Array.from(costCodesOptionsMap.values()),
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ displayName }) => displayName,
    });

    const location = multiFilter({
      options: Array.from(locationsOptionsMap.values()),
      getOptionValue: ({ id }) => id,
      getOptionLabel: ({ name }) => name,
    });

    return { costCode, location };
  }, [costCodesOptionsMap, locationsOptionsMap]);

  const { filter, setFilter } = usePersistedFilter<ItemTemplateItemVersionFilter>({
    storageKey: "itemTemplateItemFilter",
    filterDefs,
  });

  const query = useCostMappingScheduleTasksQuery({
    variables: {
      scheduleTemplateId: selectedScopeTemplate.id,
    },
  });

  return queryResult(query, {
    data: ({ scheduleTasks }) => (
      <MapItemsStepView
        selectedScopeTemplate={selectedScopeTemplate}
        scheduleTasks={scheduleTasks}
        filterDefs={filterDefs}
        filter={filter}
        setFilter={setFilter}
      />
    ),
  });
}

type MapItemsStepViewProps = {
  selectedScopeTemplate: ScheduleTemplateWithMappingInfo;
  scheduleTasks: NamedFragment[];
  filter: ItemTemplateItemVersionFilter;
  setFilter: (filter: ItemTemplateItemVersionFilter) => void;
  filterDefs: FilterDefs<ItemTemplateItemVersionFilter>;
};

function MapItemsStepView({
  selectedScopeTemplate,
  scheduleTasks,
  filter,
  setFilter,
  filterDefs,
}: MapItemsStepViewProps) {
  const [textFilter, setTextFilter] = useState<string>();
  const isAllItemsMapped = useMemo(
    () => selectedScopeTemplate.total === selectedScopeTemplate.mapped,
    [selectedScopeTemplate.mapped, selectedScopeTemplate.total],
  );
  const [showMappedItems, toggleShowMappedItems] = useState<boolean>(isAllItemsMapped); // if all items are mapped then default it to true
  const { nextStep, setSteps, currentStep } = useStepperContext();
  const tid = useTestIds({}, "mapItemsStep");
  const tableApi = useGridTableApi<MapTemplateItemsRow>();
  const [saveMappedItems] = useSaveMapItemsStepMutation();
  const { openModal, closeModal } = useModal();

  useEffect(
    () => {
      // when we complete the current step we also mark the next as no longer disabled which was blocking progressing to the next step
      if (currentStep.state === "complete") {
        nextStep();
      }
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep],
  );

  const store = useMemo(() => {
    return new DevelopmentItemMappingsStore(selectedScopeTemplate.stageMappings || [], selectedScopeTemplate.id);
  }, [selectedScopeTemplate.id, selectedScopeTemplate.stageMappings]);

  const { getFormState } = useFormStates<MapTemplateItemsFormValue>({
    config: mapTemplateItemsFormConfig,
    getId: (v) => v.id!,
  });

  const selectedRows = useComputed(() => tableApi.getSelectedRows("item"), [tableApi]);

  const changedItemsRows = useComputed(
    () =>
      store.items
        .map((item) => getFormState(item))
        .filter(({ taskId }) => taskId.changedValue !== taskId.originalValue),
    [store.items],
  );

  return (
    <>
      <header css={Css.mtPx(2).mb2.$}>
        <TableActions>
          <h1 css={Css.xl3Sb.mb2.gray900.$} {...tid.title}>
            Map items to the correct schedule task
          </h1>
          <TemplateDetails templateStepData={selectedScopeTemplate} />
        </TableActions>
      </header>
      <div css={Css.df.aic.jcsb.mb2.$}>
        <div css={Css.df.gap1.$}>
          <Filters<ItemTemplateItemVersionFilter> filterDefs={filterDefs} filter={filter} onChange={setFilter} />
          <Switch
            label="Show mapped items"
            selected={showMappedItems}
            onChange={() => toggleShowMappedItems(!showMappedItems)}
            labelStyle="inline"
            {...tid.switch}
          />
        </div>
        <div css={Css.df.gap1.$}>
          <SearchBox onSearch={setTextFilter} />
          <Button
            variant="secondary"
            label="Map items"
            disabled={!selectedRows.length}
            onClick={() =>
              openModal({
                content: (
                  <BulkMapItemsModal
                    scheduleTasks={scheduleTasks}
                    closeModal={closeModal}
                    selectedRows={selectedRows}
                    getFormState={getFormState}
                  />
                ),
              })
            }
          />
        </div>
      </div>
      <ScrollableContent>
        <MapTemplateItemsTable
          getFormState={getFormState}
          scheduleTasks={scheduleTasks}
          searchFilter={textFilter}
          filter={filter}
          store={store}
          api={tableApi}
          showMappedItems={showMappedItems}
        />
        <StepActions>
          <div>
            <Button
              disabled={!isAllItemsMapped && !changedItemsRows.length}
              label="Save & Continue"
              onClick={async () => {
                const inputItems: SaveScheduleTemplateItemMappingInput[] = changedItemsRows.map((row) => {
                  const { costType, location, item, taskId, scheduleItemMappingId } = row.value;
                  return {
                    taskId,
                    costType,
                    itemId: item.id,
                    locationId: location.id,
                    scheduleTemplateId: selectedScopeTemplate.id,
                    id: scheduleItemMappingId,
                  };
                });

                await saveMappedItems({
                  variables: { input: { scheduleTemplateItemMappings: inputItems } },
                  refetchQueries: ["DevelopmentScheduleItemMappingPage"],
                });

                setSteps((prevState) => {
                  return [
                    { ...prevState[0], state: "complete" },
                    { ...prevState[1], state: "complete" },
                    { ...prevState[2], disabled: false },
                  ];
                });
              }}
            />
          </div>
        </StepActions>
      </ScrollableContent>
    </>
  );
}

type BulkMapItemsModalProps = {
  selectedRows: GridDataRow<MapTemplateItemRow>[];
  closeModal: () => void;
  scheduleTasks: NamedFragment[];
  getFormState: (input: MapTemplateItemsFormValue) => ObjectState<MapTemplateItemsFormValue>;
};

function BulkMapItemsModal({ selectedRows, closeModal, getFormState, scheduleTasks }: BulkMapItemsModalProps) {
  const [taskId, setTaskId] = useState<string | undefined>();
  return (
    <>
      <ModalHeader>Map Items</ModalHeader>
      <ModalBody>
        <p css={Css.mb3.$}>
          {selectedRows.length} {selectedRows.length === 1 ? "item" : "items"} selected
        </p>
        <SelectField
          label="Select a schedule task"
          sizeToContent
          compact
          options={scheduleTasks}
          value={taskId}
          onSelect={setTaskId}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label="Map Items"
          data-testid="bulkMapItems"
          disabled={!isDefined(taskId)}
          onClick={() => {
            selectedRows.forEach((row) => {
              const rowState = getFormState(row.data);
              rowState.taskId.set(taskId);
            });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}

const TemplateDetails = ({ templateStepData }: { templateStepData: ScheduleTemplateWithMappingInfo }) => {
  const tid = useTestIds({}, "templateDetails");
  return (
    <div css={Css.df.gap7.$}>
      <div css={Css.df.fdc.aifs.$}>
        <span css={titleCss}>Total items</span>
        <span css={valueCss} {...tid.total}>
          {templateStepData.total}
        </span>
      </div>
      <div css={Css.df.fdc.aifs.$}>
        <span css={titleCss}>Unmapped</span>
        <span css={valueCss} {...tid.unmapped}>
          {templateStepData.total - templateStepData.mapped}
        </span>
      </div>
      <div css={Css.df.fdc.aifs.$}>
        <span css={titleCss}>Schedule template</span>
        <span css={valueCss}>{templateStepData.name}</span>
      </div>
    </div>
  );
};

const titleCss = Css.sm.gray700.fw4.$;
const valueCss = Css.smMd.fw5.$;
