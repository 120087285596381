import { HasIdAndName } from "@homebound/beam";
import { ItivOrderField } from "src/generated/graphql-types";

export function offeringItemTableGroupByOptions(): HasIdAndName[] {
  return [
    { id: ItivOrderField.Options, name: "Option" },
    { id: ItivOrderField.CostCode, name: "Cost Code" },
    { id: ItivOrderField.CostDivision, name: "Cost Division" },
    { id: ItivOrderField.LocationLevel, name: "Level" },
    { id: ItivOrderField.LocationRoom, name: "Room" },
    { id: ItivOrderField.LocationFeature, name: "Feature" },
  ];
}

export function offeringItemTableSortByOptions(): HasIdAndName[] {
  return [
    { id: ItivOrderField.Item, name: "Item code" },
    { id: ItivOrderField.MaterialCode, name: "Material Code" },
    { id: ItivOrderField.Location, name: "Location" },
  ];
}
