import { Css, GridTable, ScrollableContent, column, simpleHeader } from "@homebound/beam";
import { createDevelopmentProductOfferingUrl } from "src/RouteUrls";
import { ProgressPill } from "src/components";
import { ReadyPlanVersionHeader } from "src/components/ReadyPlanVersionHeader";
import { ProductOfferingsDetailFragment } from "src/generated/graphql-types";
import { programDataBathRange, programDataRange } from "src/utils/programData";
import { UpdatesAvailableButton } from "./components/UpdatesAvailable";

export function ProductOfferingsTable({ productOfferings }: { productOfferings: ProductOfferingsDetailFragment[] }) {
  const columns = [
    column<Row>({
      header: "Offering Name",
      productOffering: (po) => ({
        content: (
          <div css={Css.df.gap("4px").$}>
            <div css={Css.smSb.$}>{po.name}</div>
            <ReadyPlanVersionHeader readyPlan={po} />
            <UpdatesAvailableButton productOfferingId={po.id} updates={po.availableUpdates} showText={false} />
          </div>
        ),
        value: po.name,
      }),
    }),
    column<Row>({
      header: "Plan Code",
      productOffering: (po) => po.planPackage.code,
    }),
    column<Row>({
      header: "Sqft",
      productOffering: ({ minProgramData, maxProgramData }) =>
        programDataRange(minProgramData, maxProgramData, "sellableSqft"),
    }),
    column<Row>({
      header: "Beds",
      productOffering: ({ minProgramData, maxProgramData }) =>
        programDataRange(minProgramData, maxProgramData, "bedrooms"),
    }),
    column<Row>({
      header: "Baths",
      productOffering: (po) => programDataBathRange(po.minProgramData, po.maxProgramData),
    }),
    column<Row>({
      header: "Elevations",
      productOffering: (po) => po.options.filter((rpo) => rpo.active && rpo.type.isElevation).length,
    }),
    column<Row>({
      header: "Width",
      productOffering: ({ minProgramData, maxProgramData }) =>
        programDataRange(minProgramData, maxProgramData, "widthInFeet"),
    }),
    column<Row>({
      header: "Depth",
      productOffering: ({ minProgramData, maxProgramData }) =>
        programDataRange(minProgramData, maxProgramData, "depthInFeet"),
    }),
    column<Row>({
      header: "Bid Out",
      productOffering: (po) => <ProgressPill progress={po.bidOutPercentage} stacked changeColorOnCompleted />,
    }),
  ];

  const rows = [
    simpleHeader,
    ...productOfferings.map((po) => ({ kind: "productOffering" as const, data: po, id: po.id })),
  ];
  return (
    <>
      <ScrollableContent>
        <GridTable
          rows={rows}
          columns={columns}
          rowStyles={{
            productOffering: {
              rowLink: ({ data }) => createDevelopmentProductOfferingUrl(data.development!.id, data!.id),
            },
          }}
        />
      </ScrollableContent>
    </>
  );
}

type HeaderRow = { kind: "header"; data: undefined };
type ProductOfferingRow = { kind: "productOffering"; data: ProductOfferingsDetailFragment };
type Row = HeaderRow | ProductOfferingRow;
