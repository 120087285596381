import {
  BoundMultiSelectField,
  BoundRichTextField,
  BoundTextField,
  Button,
  Css,
  FormLines,
  IconButton,
  TextField,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";
import { createTradePartnersUrl } from "src/RouteUrls";
import { Icon, formatDate } from "src/components";
import {
  BoundAttachments,
  SaveAttachmentModel,
  attachmentConfig,
  formatAttachments,
} from "src/components/boundAttachments/BoundAttachments";
import {
  PersonalDashboard_ScheduleTaskFragment,
  SaveTradePartnerAvailabilityRequestInput,
  TradePartnerAvailabilityRequestStatus,
  useSaveTradePartnerAvailabilityRequestMutation,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { noop } from "src/utils";
import { openNewTab } from "src/utils/window";
import {
  getTradePartnerAvailabilityRequestEmailMessage,
  getTradePartnerAvailabilityRequestEmailSubject,
} from "../utils";

enum TradePartnerAvailabilityPaneViews {
  Draft,
  Preview,
  Sent,
}

type TradePartnerAvailabilityPaneContextProps = PropsWithChildren<{
  view: TradePartnerAvailabilityPaneViews;
  setView: React.Dispatch<React.SetStateAction<TradePartnerAvailabilityPaneViews>>;
  onClose: VoidFunction;
  scheduleTask: PersonalDashboard_ScheduleTaskFragment | undefined;
}>;

const TradePartnerAvailabilityPaneContext = createContext<TradePartnerAvailabilityPaneContextProps>({
  view: TradePartnerAvailabilityPaneViews.Draft,
  setView() {},
  onClose() {},
  scheduleTask: undefined,
});

type TradePartnerAvailabilityProviderProps = Omit<TradePartnerAvailabilityPaneContextProps, "view" | "setView">;

export function TradePartnerAvailabilityProvider(props: TradePartnerAvailabilityProviderProps) {
  const { children, scheduleTask, ...contextValue } = props;
  // set default view to preview
  const [view, setView] = useState<TradePartnerAvailabilityPaneViews>(TradePartnerAvailabilityPaneViews.Draft);

  return (
    <TradePartnerAvailabilityPaneContext.Provider
      value={{
        ...contextValue,
        view,
        setView,
        scheduleTask,
      }}
    >
      {children}
    </TradePartnerAvailabilityPaneContext.Provider>
  );
}

export function useTradePartnerAvailabilityRequestPaneContext() {
  return useContext(TradePartnerAvailabilityPaneContext);
}

export function TradePartnerAvailabilityRequestDetailPaneForm() {
  const { scheduleTask, setView, view } = useTradePartnerAvailabilityRequestPaneContext();
  const [saveTradePartnerAvailabilityRequest] = useSaveTradePartnerAvailabilityRequestMutation({});
  const tids = useTestIds({}, "tradePartnerAvailabilityRequestDetailPane");
  const { id } = useCurrentUser();

  const formState = useFormState({
    config: tradePartnerAvailabilityFormConfig,
    init: {
      input: scheduleTask,
      map: (t) => mapToForm(t),
    },
  });

  const onSave = useCallback(async () => {
    if (formState.canSave()) {
      await saveTradePartnerAvailabilityRequest({
        variables: {
          input: mapToInput(formState.value, scheduleTask?.id, id),
        },
        refetchQueries: ["SchedulingModal", "ActionItemsScheduling"],
      });
      setView(TradePartnerAvailabilityPaneViews.Sent);
    }
  }, [id, setView, saveTradePartnerAvailabilityRequest, formState, scheduleTask?.id]);

  const previousRequests = useMemo(
    () =>
      scheduleTask?.tradePartnerAvailabilityRequests?.flatMap((r) =>
        r.emails.map((e) => ({
          id: e.id,
          url: r.blueprintUrl,
          createdAt: e.createdAt,
        })),
      ) ?? [],
    [scheduleTask?.tradePartnerAvailabilityRequests],
  );

  return (
    <Observer>
      {() => (
        <div css={Css.bgWhite.h100.os.px4.py3.pb8.$}>
          <TradePartnerAvailabilityRequestDetailPaneHeader />
          {view === TradePartnerAvailabilityPaneViews.Draft && (
            <div {...tids.body}>
              {previousRequests.length > 0 && (
                <div css={Css.mb4.bb.pb2.bcGray300.$}>
                  <h2 css={Css.baseBd.gray900.mb2.$}>Previously sent availability requests</h2>
                  {previousRequests.map(({ id, url, createdAt }) => (
                    <div css={Css.df.jcsb.aic.$} key={id} {...tids.previouslySentRequest}>
                      <span>{formatDate(createdAt, "monthShort", "short")}</span>
                      <Button label="View" onClick={() => openNewTab(`${url.path}`)} variant="tertiary" />
                    </div>
                  ))}
                </div>
              )}
              <h2 css={Css.baseBd.gray900.mb2.$}>To:</h2>
              <FormLines width="full">
                <TextField
                  readOnly
                  label="Trade Partner Company"
                  onChange={noop}
                  value={scheduleTask?.tradePartner?.name}
                />
              </FormLines>
              <FormLines width="full">
                {/* // Once a request has been sent, we should never update tradePartnerContacts on a comment stream, we just show the selected contacts */}
                {scheduleTask?.tradePartnerAvailabilityRequests?.isEmpty ? (
                  <BoundMultiSelectField
                    field={formState.tradePartnerContactIds}
                    options={scheduleTask?.tradePartner?.contacts ?? []}
                    label="Trade Partner Individual Recipients"
                    required
                    errorMsg={formState.tradePartnerContactIds.value?.isEmpty ? "Required" : ""}
                    disabled={scheduleTask?.tradePartner?.contacts.isEmpty}
                  />
                ) : (
                  <div>
                    <div css={Css.mbPx(4).gray700.$}>Trade Partner Individual Recipients</div>
                    {scheduleTask?.tradePartnerAvailabilityRequests?.last?.tradePartnerContacts.map((tpc) => (
                      <ul key={tpc.id} css={Css.m0.pl0.gray900.smMd.$}>
                        {tpc.name}
                      </ul>
                    ))}
                  </div>
                )}
                {scheduleTask?.tradePartner?.contacts.isEmpty && (
                  <Button label="Add New" onClick={createTradePartnersUrl()} variant="secondary" />
                )}
              </FormLines>
              <h2 css={Css.baseBd.gray900.mt4.mb3.$}>Message:</h2>
              <FormLines width="full">
                <BoundTextField
                  field={formState.subject}
                  label="subject"
                  labelStyle="hidden"
                  required
                  errorMsg={!formState.subject.valid ? "Required" : ""}
                />
              </FormLines>
              <FormLines width="full">
                <BoundRichTextField field={formState.message} label="Message" />
                {/* Adding in a manual required field due to Trix (native input element): https://github.com/basecamp/trix/issues/328 */}
                {!formState.message.valid && (
                  <div css={Css.red600.sm.df.mtPx(4).$}>
                    <span css={Css.fs0.$}>
                      <Icon icon="error" />
                    </span>
                    <span id={id} css={Css.ml1.mtPx(2).$}>
                      Required
                    </span>
                  </div>
                )}
              </FormLines>
              <div css={Css.my4.$}>The Trade Partner Portal link will be included in the email.</div>
              <BoundAttachments
                field={formState.attachments}
                uppyUploaderProps={{ dragDropHeight: 48, dragDropWidth: 384, dragDropText: "Attach a file" }}
              />
            </div>
          )}
          {(view === TradePartnerAvailabilityPaneViews.Preview || view === TradePartnerAvailabilityPaneViews.Sent) && (
            <div>
              <h2 css={Css.baseBd.gray900.mt1.$}>{`Title: ${formState.subject.value}`}</h2>
              <h2 css={Css.baseBd.gray900.mb0.mt2.$}>Recipient(s):</h2>
              <li css={Css.listReset.$}>
                <ul css={Css.m0.pl0.$}>{`(${scheduleTask?.tradePartner?.name})`}</ul>
                {scheduleTask?.tradePartner?.contacts
                  ?.filter((tpc) => formState.value.tradePartnerContactIds?.includes(tpc.id))
                  .map((tpc) => (
                    <ul key={tpc.id} css={Css.m0.pl0.$}>
                      {tpc.email}
                    </ul>
                  ))}
              </li>
              <h2 css={Css.baseBd.gray900.mb0.mt3.$}>From:</h2>
              <div css={Css.mt0.$}>Homebound</div>
              <h2 css={Css.baseBd.gray900.mt3.mb2.$}>Message:</h2>
              <BoundRichTextField field={formState.message} label="" readOnly />
              <div css={Css.my3.$}>
                <div>The Trade Partner Portal link will be included in the email.</div>
                <BoundAttachments
                  field={formState.attachments}
                  readonly
                  uppyUploaderProps={{ dragDropHeight: 48, dragDropWidth: 384 }}
                />
              </div>
            </div>
          )}
          <TradePartnerAvailabilityRequestDetailPaneFooter formState={formState} onSave={onSave} />
        </div>
      )}
    </Observer>
  );
}

function TradePartnerAvailabilityRequestDetailPaneHeader() {
  const { onClose, view } = useTradePartnerAvailabilityRequestPaneContext();
  const tids = useTestIds({}, "tradePartnerAvailabilityRequestDetailPaneHeader");

  return (
    <div css={Css.df.jcsb.aic.mb5.$} {...tids}>
      <div css={Css.df.aic.$}>
        <h2 css={Css.lgSb.gray900.mr1.$} {...tids.title}>
          {view === TradePartnerAvailabilityPaneViews.Draft
            ? "Request Availability"
            : view === TradePartnerAvailabilityPaneViews.Preview
              ? "Preview"
              : "Availability Request Created!"}
        </h2>
        <Icon icon="email" />
      </div>
      <IconButton {...tids.closePane} icon="x" onClick={onClose} />
    </div>
  );
}

function TradePartnerAvailabilityRequestDetailPaneFooter({
  formState,
  onSave,
}: {
  formState: ObjectState<FormValue>;
  onSave: () => Promise<void>;
}) {
  const { view, setView, onClose } = useTradePartnerAvailabilityRequestPaneContext();
  const tids = useTestIds({}, "tradePartnerAvailabilityRequesDetailPaneFooter");
  const isPreview = view === TradePartnerAvailabilityPaneViews.Preview;
  const isSent = view === TradePartnerAvailabilityPaneViews.Sent;

  return (
    <div
      {...tids}
      css={
        Css.p1.df.aic.bt.bcGray200
          .mxPx(-32)
          .wPx(450)
          .hPx(70)
          .fixed.bottom0.mt4.bgWhite.if(isPreview)
          .add("justifyContent", "space-between").else.jcfe.$
      }
    >
      {isPreview && (
        <div css={Css.ml4.$}>
          <Button
            variant="text"
            label="Back"
            onClick={() => setView(TradePartnerAvailabilityPaneViews.Draft)}
            {...tids.back}
          />
        </div>
      )}
      <div css={Css.df.gap2.p2.aic.mr2.$}>
        {!isSent && <Button variant="text" label="Cancel" onClick={onClose} {...tids.cancel} />}
        <Observer>
          {() =>
            isPreview ? (
              <Button label="Send" onClick={onSave} {...tids.send} />
            ) : isSent ? (
              <Button label="Ok" onClick={onClose} {...tids.ok} />
            ) : (
              <Button
                label="Preview"
                onClick={() => setView(TradePartnerAvailabilityPaneViews.Preview)}
                disabled={!formState.valid}
                {...tids.preview}
              />
            )
          }
        </Observer>
      </div>
    </div>
  );
}

type FormValue = SaveTradePartnerAvailabilityRequestInput & {
  message: string | null | undefined;
  subject: string | null | undefined;
  tradePartnerContactIds: string[] | null | undefined;
  attachments: SaveAttachmentModel[];
};

const tradePartnerAvailabilityFormConfig: ObjectConfig<FormValue> = {
  message: { type: "value", rules: [required] },
  subject: { type: "value", rules: [required] },
  tradePartnerContactIds: { type: "value", rules: [required] },
  taskId: { type: "value", rules: [required] },
  tradePartnerId: { type: "value", rules: [required] },
  internalUserId: { type: "value" },
  emails: { type: "list", config: { id: { type: "value", rules: [required] } } },
  id: { type: "value" },
  attachments: { type: "list", config: attachmentConfig },
};

// setting form with pre-set copy
function mapToForm(scheduleTask: PersonalDashboard_ScheduleTaskFragment): FormValue {
  const { tradePartner, tradePartnerAvailabilityRequests } = scheduleTask;
  const tpar = tradePartnerAvailabilityRequests?.last;

  return {
    message: getTradePartnerAvailabilityRequestEmailMessage(scheduleTask),
    subject: getTradePartnerAvailabilityRequestEmailSubject(scheduleTask),
    attachments: [],
    tradePartnerContactIds:
      tradePartnerAvailabilityRequests?.last?.tradePartnerContacts.map((tpc) => tpc.id) ??
      tradePartner?.contacts.map((tpc) => tpc.id) ??
      [],
    tradePartnerId: tradePartner?.id,
    taskId: scheduleTask.id,
    emails: tpar?.emails.map((e) => ({ id: e.id })) ?? [],
    id: tpar?.id,
  };
}

function mapToInput(
  input: FormValue,
  scheduleTaskId: string | undefined,
  currentUserId: string,
): SaveTradePartnerAvailabilityRequestInput {
  const { internalUserId, taskId, message, subject, emails, attachments, ...others } = input;
  return {
    ...others,
    internalUserId: currentUserId,
    taskId: scheduleTaskId,
    emails: [
      {
        message,
        subject,
        attachments: formatAttachments(attachments),
      },
    ],
    status: TradePartnerAvailabilityRequestStatus.Waiting,
  };
}
