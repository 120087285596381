import { Css } from "@homebound/beam";
import { CommentFeed } from "src/components";
import { TaskDetailsPage_PlanTaskFragment } from "src/generated/graphql-types";
import { TaskDetailCard, TaskDetailCardType } from "./TaskDetailCard";

type CommentsCardProps = {
  task: TaskDetailsPage_PlanTaskFragment;
};

export function CommentsCard({ task }: CommentsCardProps) {
  return (
    <TaskDetailCard gridColumn={1} gridRow={3} cardType={TaskDetailCardType.Comments} maxHeight={650}>
      <h2 css={Css.xlBd.mb1.$}>Comments</h2>
      <CommentFeed commentable={task} maxHeight={490} />
    </TaskDetailCard>
  );
}
