import { ObjectState } from "@homebound/form-state";
import { isSameDay } from "date-fns";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import {
  DayOfWeek,
  Maybe,
  ScheduleSettingDetailsFragment,
  ScheduleTaskDateEditabilityFragment,
  TaskDateEditabilityFragment,
  TaskStatus,
} from "src/generated/graphql-types";
import {
  FormInput,
  TaskDateField,
  TaskDetailsFormInput,
  determineTaskDateEditability,
} from "src/routes/projects/schedule-v2/table/utils";
import { DateOnly } from "src/utils/dates";
import { SetStartDateViaPredecessorInput } from "./SetDateViaPredecessor";

export type DateCellFieldProps = {
  field: "startDate" | "endDate";
  objectState: ObjectState<FormInput> | ObjectState<Partial<TaskDetailsFormInput>>;
  editability: ScheduleTaskDateEditabilityFragment;
  scheduleIsLocked: boolean;
  scheduleSetting?: ScheduleSettingDetailsFragment | null;
  defaultOpen?: boolean;
  iconLeft?: boolean;
  hideCalendarIcon?: boolean;
  label: string;
  onChange?: (value: Date | undefined) => void;
  disabledField?: boolean;
};

export function DateCellField({
  field,
  objectState,
  editability,
  scheduleIsLocked,
  scheduleSetting,
  defaultOpen,
  iconLeft = true,
  hideCalendarIcon = false,
  label,
  onChange,
  disabledField,
}: DateCellFieldProps) {
  const { disabled, disabledReason, disabledDays } = useMemo(
    () => determineScheduleTaskDateEditability(editability, field, scheduleIsLocked, scheduleSetting),
    [editability, field, scheduleIsLocked, scheduleSetting],
  );

  // If the schedule is NOT locked, but the autoSave-able date field is disabled (the only current valid reason for
  // this is due to a dependency connection), render this special input instead which helps the user achieve
  // a specific start date by calculating & proposing new predecessor lag(s) automatically
  if (field === "startDate" && !scheduleIsLocked && disabled && editability.id) {
    return (
      <Observer>
        {() => (
          <SetStartDateViaPredecessorInput
            taskId={editability.id}
            initialValue={objectState[field].value ?? undefined}
            disabled={
              objectState.status.value === TaskStatus.Complete
                ? "Start date cannot be changed on a completed task"
                : undefined
            }
            disabledDays={disabledDays}
            defaultOpen={defaultOpen}
            iconLeft={iconLeft}
            hideCalendarIcon={hideCalendarIcon}
            label={label}
            scheduleSetting={scheduleSetting}
          />
        )}
      </Observer>
    );
  }

  return (
    <BoundBeamDateField
      label={label}
      iconLeft={iconLeft}
      field={objectState[field]}
      disabled={disabledReason || disabled || disabledField}
      disabledDays={disabledDays}
      defaultOpen={defaultOpen}
      hideCalendarIcon={hideCalendarIcon}
      onChange={onChange}
    />
  );
}

export function determineScheduleTaskDateEditability(
  task: ScheduleTaskDateEditabilityFragment,
  field: TaskDateField,
  scheduleIsLocked: boolean,
  scheduleSetting?: ScheduleSettingDetailsFragment | null,
) {
  if (scheduleIsLocked) {
    return {
      disabled: true,
      // Current product decision to leave off `disabledReason` tooltip when the schedule is locked
      disabledReason: undefined,
      disabledDays: undefined,
    };
  }

  const disabled = !determineTaskDateEditability(task)[field];
  const disabledReason = getDisabledReasons(task)[field].reduce((acc, { message }) => `${message}. ${acc}`, "");
  const disabledDaysByEditability = getDisabledDaysForField(task, field);
  const disabledDays = getDisabledDays(scheduleSetting, disabledDaysByEditability);
  return { disabled, disabledReason, disabledDays };
}

export function getDisabledDays(
  scheduleSetting?: ScheduleSettingDetailsFragment | null,
  disabledDaysByEditability?: { after: DateOnly } | { before: DateOnly } | undefined,
) {
  const disabledDaysByScheduleSetting = getDisabledDaysByScheduleSetting(scheduleSetting);
  return [
    ...(disabledDaysByEditability ? [disabledDaysByEditability] : []),
    ...(disabledDaysByScheduleSetting ? [disabledDaysByScheduleSetting] : []),
  ];
}

function getDisabledReasons(task: Maybe<TaskDateEditabilityFragment>) {
  return {
    startDate: task?.canEditStartDate?.disabledReasons ?? [],
    endDate: task?.canEditEndDate?.disabledReasons ?? [],
    durationInDays: task?.canEditDuration?.disabledReasons ?? [],
  };
}

function getDisabledDaysForField(task: ScheduleTaskDateEditabilityFragment, field: TaskDateField) {
  const disabledDaysByField = {
    // Only disable startDate selections if the corresponding endDate date is locked to prevent negative task durations
    startDate: !task?.canEditEndDate?.allowed ? { after: task?.interval?.endDate } : undefined,
    endDate: { before: task?.interval?.startDate },
    durationInDays: undefined,
  };

  return disabledDaysByField[field];
}

export function getDisabledDaysByScheduleSetting(scheduleSetting?: ScheduleSettingDetailsFragment | null) {
  const scheduleExceptions = scheduleSetting?.exceptions ?? {};
  const scheduleWorkingDays = scheduleSetting?.workingDays ?? [];

  function disableDaysByScheduleSetting(day: Date): boolean {
    const exceptionDays = Object.keys(scheduleExceptions);
    for (let i = 0; i < exceptionDays.length; i++) {
      // if day is an exception
      if (isSameDay(new Date(exceptionDays[i]), day)) {
        // disable if exception is false (i.e., it is not a working day)
        return !scheduleExceptions[exceptionDays[i]];
      }
    }

    // if the day is a Sunday, disable it if working days does not include Sunday
    if (day.getDay() === 0 && !scheduleWorkingDays.includes(DayOfWeek.Sunday)) {
      return true;
    }

    // if the day is a Saturday, disable it if working days does not include Saturday
    if (day.getDay() === 6 && !scheduleWorkingDays.includes(DayOfWeek.Saturday)) {
      return true;
    }

    return false;
  }

  return disableDaysByScheduleSetting;
}
