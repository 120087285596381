import { Css } from "@homebound/beam";
import { Icon } from "src/components";
import { BudgetPage_ProjectItemFragment, LotType } from "src/generated/graphql-types";
import { HeroMetric } from "src/routes/components/HeroMetric";
import { calcPercent, currencyAbbreviation } from "src/utils/numbers";

type BudgetMetricProps = {
  projectItems: BudgetPage_ProjectItemFragment[];
  lotType: LotType | undefined;
};

export function BudgetHeroMetrics({ projectItems, lotType }: BudgetMetricProps) {
  const originalBudgetInCents = projectItems.sum((pi) => pi.budgetFinancials.original);
  const currentBudgetInCents = projectItems.sum((pi) => pi.budgetFinancials.revisedInternalBudget);
  const originalRevenueInCents = projectItems.sum((pi) => pi.originalPriceInCents);
  const currentRevenueInCents = projectItems.sum((pi) => pi.currentPriceInCents);

  const revenueVariance = currentRevenueInCents - originalRevenueInCents;
  const budgetVariance = currentBudgetInCents - originalBudgetInCents;
  // Keep percents rounded to 1 decimal
  const marginCurrentPercent =
    Math.round(calcPercent(currentRevenueInCents - currentBudgetInCents, currentRevenueInCents) * 10) / 10;
  const marginOriginalPercent =
    Math.round(calcPercent(originalRevenueInCents - originalBudgetInCents, originalRevenueInCents) * 10) / 10;
  const marginVariance = marginCurrentPercent - marginOriginalPercent;

  const boylMarginColor = {
    // Boyl margin:
    // If current margin >= original contract margin, display in green
    // If current margin >= 3% below original contract margin, display in yellow
    // If current margin < 3% below the original contract margin, display in red
    ...Css.if(marginVariance >= -3)
      .yellow600.else.if(marginCurrentPercent >= marginOriginalPercent)
      .green800.else.if(marginVariance < -3).red600.$,
  };

  if (lotType === LotType.Bool)
    return (
      <div css={Css.my3.$}>
        <HeroMetric
          value={budgetVariance}
          subTitle1="Budget Variance"
          subTitle2={
            <div css={Css.df.fdr.aic.gap1.tiny.$}>
              {currencyAbbreviation(originalBudgetInCents)} <Icon icon="arrowRight" pxSize={10} />
              {currencyAbbreviation(currentBudgetInCents)}
            </div>
          }
          greenIf="negative"
          toolTipText="Underwritten Budget vs. Current Budget"
          displayDirection
        />
      </div>
    );

  return (
    <div css={Css.my3.df.fdr.cg6.$}>
      {/** Revenue var (if var < 0, display in red) */}
      <HeroMetric
        value={revenueVariance}
        subTitle1="Revenue Variance"
        subTitle2={
          <div css={Css.df.fdr.aic.gap1.tiny.$}>
            {currencyAbbreviation(originalRevenueInCents)} <Icon icon="arrowRight" pxSize={10} />
            {currencyAbbreviation(currentRevenueInCents)}
          </div>
        }
        greenIf="positive"
        toolTipText="Original Revenue vs. Current Revenue"
        displayDirection
      />

      {/** Budget var (if var > 0, display in red) */}
      <HeroMetric
        value={budgetVariance}
        subTitle1="Budget Variance"
        subTitle2={
          <div css={Css.df.fdr.aic.gap1.tiny.$}>
            {currencyAbbreviation(originalBudgetInCents)} <Icon icon="arrowRight" pxSize={10} />
            {currencyAbbreviation(currentBudgetInCents)}
          </div>
        }
        greenIf="negative"
        toolTipText="Original Budget vs. Current Budget"
        displayDirection
      />

      {/** Margin Original Percent */}
      <HeroMetric
        value={marginOriginalPercent}
        subTitle1="Margin"
        subTitle2="as of Original Contract"
        isPercent
        displayDirection
      />

      {/** Margin Current Percent */}
      <HeroMetric
        value={marginCurrentPercent}
        subTitle1="Margin"
        subTitle2="as of Today"
        isPercent
        colorXss={boylMarginColor}
        displayDirection
      />
    </div>
  );
}
