import { Maybe } from "src/generated/graphql-types";
import { Selectable } from "src/models/Selectable";
import { sum } from "src/utils";

type RollupChild = {
  id: string;
  totalCommittedInCents: Maybe<number>;
  totalBilledInCents: Maybe<number>;
  totalUnbilledinCents: Maybe<number>;
  totalPaidToDate: Maybe<number>;
  totalBalanceDue: Maybe<number>;
  projectIds: string[];
} & Selectable;

export class LineItemRollup<C extends RollupChild> extends Selectable<C> {
  constructor(
    id: string,
    public name: string,
    children: C[],
  ) {
    super(id, false, children);
  }

  get totalCommittedInCents(): number {
    return this.children.map((c) => c.totalCommittedInCents || 0).reduce(sum, 0);
  }

  get totalBilledInCents(): number {
    return this.children.map((c) => c.totalBilledInCents || 0).reduce(sum, 0);
  }

  get totalUnbilledinCents(): number {
    return this.children.map((c) => c.totalUnbilledinCents || 0).reduce(sum, 0);
  }

  get totalPaidToDate(): number {
    return this.children.map((c) => c.totalPaidToDate || 0).reduce(sum, 0);
  }

  get totalBalanceDue(): number {
    return this.children.map((c) => c.totalBalanceDue || 0).reduce(sum, 0);
  }

  get projectIds(): string[] {
    return [...new Set(this.children.flatMap((c) => c.projectIds))];
  }
}
