import { Rows } from "./LotSummaryDetailsSection";
import { LotSummaryDetailTableRow } from "./LotSummaryDetailTableRow";

type LotSummaryDetailTableProps = {
  columns: number;
  rows: Rows[];
};

export function LotSummaryDetailTable({ columns, rows }: LotSummaryDetailTableProps) {
  return (
    <>
      {rows.map((props, index) => (
        <LotSummaryDetailTableRow key={`section-${index}`} columns={columns} rows={props} />
      ))}
    </>
  );
}
