import { useToast } from "@homebound/beam";
import { ObjectConfig, required } from "@homebound/form-state";
import { useCallback, useState } from "react";
import {
  ItemTemplateItemVersionFilter,
  ItemTemplateItemVersionOrder,
  ItemTemplateLineItemsTab_GroupByFragment,
  ItivOrderField,
  Maybe,
  SaveItemTemplateItemVersionInput,
  ScopeChangeType,
  useItemTemplateItemVersionsPageQuery,
} from "src/generated/graphql-types";
import { GroupByKeys } from "src/routes/settings/itemTemplates/ItemTemplateLineItemsTab";
import { ItemTemplateChangesSettings } from "src/routes/settings/itemTemplates/changes/useItemTemplateShowChangesSettings";
import { isDefined } from "./arrays";
import { fail } from "./utils";

export const useItemTemplateItemVersionsQuery = (
  filter: ItivFilters,
  sortOrder: ItemTemplateItemVersionOrder[],
  pageSize: number,
  opts?: {
    groupBy?: GroupByKeys;
    itemTemplateId?: string;
    useFetchConfig?: boolean;
  },
) => {
  const { showChanges } = filter;
  const { highlightChanges = false } = showChanges ?? {};
  const { itemTemplateId, groupBy = GroupByKeys.code, useFetchConfig } = opts ?? {};

  // Get the first 25 itemTemplateItemVersions
  const query = useItemTemplateItemVersionsPageQuery({
    variables: {
      filter: toServerFilter(itemTemplateId, filter),
      highlightChanges,
      first: pageSize,
      ...(sortOrder?.length && {
        order: sortOrder,
      }),
    },
    ...(useFetchConfig && {
      // Use "cache-and-network" since data will be getting updated frequently
      fetchPolicy: "cache-and-network",
    }),
    skip: !itemTemplateId && !filter.template?.length,
  });

  // NOTE: Test casees revealed that if the fetchMore query fails, the entire page will crash.
  // So we wrap in a try/catch and note which groupIds failed to fetch, so that we stop attempting to fetch them.
  // TODO: This is a hacky solution, and we should figure out a better way to handle this, but it is also unclear
  //       whether this will be an issue in production, since we don't expect the fetchMore to fail.
  const { showToast } = useToast();
  const [failedGroupIds] = useState(new Set<string>());
  const { fetchMore: fetchMoreRaw } = query;

  const fetchMore = useCallback(
    async (groupTotals: ItemTemplateLineItemsTab_GroupByFragment) => {
      if (failedGroupIds.has(groupTotals.groupId)) return;
      try {
        await fetchMoreRaw({
          variables: {
            first: null,
            subFilter: subFilterByOrder(sortOrder[0].field, groupTotals.groupId),
          },
        });
      } catch (e) {
        console.error(e);
        failedGroupIds.add(groupTotals.groupId);
        showToast({ type: "error", message: `Failed to fetch items for group ${groupTotals.displayName}` });
      }
    },
    [fetchMoreRaw, showToast, sortOrder, failedGroupIds],
  );

  return {
    loading: query.loading,
    items: query.data?.itemTemplateItemVersionsPage.items ?? query.previousData?.itemTemplateItemVersionsPage.items,
    groupTotals:
      query.data?.itemTemplateItemVersionsPage.groupTotals ??
      query.previousData?.itemTemplateItemVersionsPage.groupTotals,
    fetchMore,
  };
};

export type ItivFilters = ItemTemplateItemVersionFilter & {
  showChanges?: Maybe<ItemTemplateChangesSettings>;
};

export function toServerFilter(itemTemplateId: string | undefined, filter: ItivFilters): ItemTemplateItemVersionFilter {
  const { showChanges, ...otherFilters } = filter;
  const { showChangesOnly = false, includeRemoved = false } = showChanges ?? {};
  return {
    ...(showChangesOnly
      ? {
          scopeChangeType: [
            ScopeChangeType.Add,
            ScopeChangeType.Major,
            ScopeChangeType.Minor,
            ScopeChangeType.Replace,
            ...(includeRemoved ? [ScopeChangeType.Removed] : []),
          ],
        }
      : !isDefined(otherFilters.scopeChangeType)
        ? {
            excludeRemoved: !includeRemoved,
          }
        : {}),
    ...(itemTemplateId && {
      template: [itemTemplateId],
    }),
    ...otherFilters,
  };
}

export function subFilterByOrder(order: ItivOrderField, groupId: string): ItemTemplateItemVersionFilter {
  switch (order) {
    case ItivOrderField.Option:
      return { option: [groupId === "rpo:-1" ? null : groupId.split(",")].flat() };
    case ItivOrderField.Options:
      return { options: [groupId === "rpo:-1" ? null : groupId.split(",")].flat() };
    case ItivOrderField.CostCode:
      return { costCode: [groupId] };
    case ItivOrderField.CostDivision:
      return { costDivision: [groupId] };
    case ItivOrderField.LocationLevel:
    case ItivOrderField.LocationRoom:
    case ItivOrderField.LocationFeature:
      return { locationInPath: [groupId] };
    case ItivOrderField.GlobalPlanTask:
      return { globalPlanTask: [groupId] };
    default:
      fail(`Unsupported order by ${order}`);
  }
}

export type ItemTemplateItemFormValue = SaveItemTemplateItemVersionInput & {
  unitCostInCents: Maybe<number>;
};

export const itemTemplateItemFormConfig: ObjectConfig<ItemTemplateItemFormValue> = {
  id: { type: "value" },
  itemId: { type: "value", rules: [required] },
  costType: { type: "value", rules: [required] },
  quantity: { type: "value" }, // don't require b/c it might be lump sum
  locationId: { type: "value", rules: [required] },
  unitOfMeasureId: { type: "value", rules: [required] },
  totalCostInCents: { type: "value", rules: [required] },
  unitCostInCents: { type: "value" }, // Not submitted to the server, just for local editings
  specifications: { type: "value" },
  internalNote: { type: "value" },
  tradePartnerNote: { type: "value" },
  bidItemId: { type: "value" },
  baseProductId: { type: "value" },
  name: { type: "value" },
  isSelection: { type: "value" },
  elevationIds: { type: "value" },
  otherOptionIds: { type: "value" },
  preferredBidContractLineItemId: { type: "value" },
  specOptionIds: { type: "value" },
};
