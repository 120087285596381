import {
  actionColumn,
  column,
  dateColumn,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridSortConfig,
  Icon,
  multiFilter,
  Palette,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  SimpleHeaderAndData,
  TagType,
  toggleFilter,
  usePersistedFilter,
} from "@homebound/beam";
import { useCallback, useMemo, useState } from "react";
import { dateCell, QueryTable, SearchBox, tagCell } from "src/components";
import {
  LotType,
  Order,
  ProjectFilter,
  ProjectOrder,
  ProjectsPageInternalUserFragment,
  ProjectsPageMetadataQuery,
  ProjectsPageProjectFragment,
  ProjectsPageProjectsQuery,
  ProjectStatus,
  useProjectsPageMetadataQuery,
  useProjectsPageProjectsQuery,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { TableActions } from "src/routes/layout/TableActions";
import { createProjectDashboardUrl } from "src/RouteUrls";
import { projectStatusDetails, queryResult, removeTag, safeEntries, sortBy, stageCodeToNameMapper } from "src/utils";
import { createHopProjectUrl } from "src/utils/hopLinks";
import { openNewTab } from "src/utils/window";
import { parseOrder, toOrder } from "../../utils/ordering";

export function ProjectsPage() {
  const currentInternalUserQuery = useProjectsPageMetadataQuery({ fetchPolicy: "cache-first" });
  useDocumentTitle("Projects");
  return queryResult(currentInternalUserQuery, {
    data: ({ currentInternalUser, internalUsers, markets, cohorts, developments }) => {
      return (
        <ProjectsDataView
          currentUser={currentInternalUser!}
          internalUsers={internalUsers}
          markets={markets}
          cohorts={cohorts}
          developments={developments}
        />
      );
    },
  });
}

type ProjectsDataViewProps = {
  currentUser: ProjectsPageInternalUserFragment;
  internalUsers: ProjectsPageMetadataQuery["internalUsers"];
  markets: ProjectsPageMetadataQuery["markets"];
  cohorts: ProjectsPageMetadataQuery["cohorts"];
  developments: ProjectsPageMetadataQuery["developments"];
};

export function ProjectsDataView({
  currentUser,
  markets,
  internalUsers,
  cohorts,
  developments,
}: ProjectsDataViewProps) {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [orderBy, setOrderBy] = useState<ProjectOrder>({ buildAddress: Order.Asc });
  const filterDefs: FilterDefs<ProjectFilter> = useMemo(
    () => {
      const teamMembers = multiFilter({
        options: internalUsers ?? [],
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
        defaultValue: [currentUser.id],
      });

      const latestActiveStage = multiFilter({
        options: safeEntries(stageCodeToNameMapper),
        getOptionValue: ([code]) => code,
        getOptionLabel: ([, name]) => name,
      });

      const market = multiFilter({
        options: markets,
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      });

      const status = multiFilter({
        options: safeEntries(projectStatusDetails),
        getOptionValue: ([code]) => code,
        getOptionLabel: ([_code, name]) => name,
        defaultValue: [ProjectStatus.Active],
      });
      const development = multiFilter({
        options: developments,
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      });

      const cohort = multiFilter({
        options: sortBy(cohorts, ({ name }) => name),
        getOptionValue: (o) => o.id,
        getOptionLabel: (o) => o.name,
      });

      const lotType = multiFilter({
        options: [LotType.Bool, LotType.Boyl, LotType.Hbl, LotType.Btr, LotType.Fee],
        getOptionValue: (v) => v,
        getOptionLabel: (v) => v,
      });

      const isTest = toggleFilter({ label: "Only show test projects" });

      return { teamMembers, latestActiveStage, market, status, development, cohort, lotType, isTest };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, markets, internalUsers],
  );

  const { setFilter, filter } = usePersistedFilter<ProjectFilter>({
    storageKey: "projectFilter",
    filterDefs,
  });

  const query = useProjectsPageProjectsQuery({
    variables: {
      filter: { ...filter, search: searchFilter },
      orderBy,
      page: { offset: 0, limit: 100 },
    },
  });
  const initSortState: GridSortConfig = useMemo(
    () => ({
      on: "server",
      onSort: (key, direction) => setOrderBy(toOrder(key, direction)),
      value: parseOrder(orderBy),
    }),
    [orderBy],
  );

  const maybeFetchNextPage = useCallback(async () => {
    if (query.data?.projectsPage.pageInfo.hasNextPage) {
      await query.fetchMore({
        variables: { page: { offset: query.data?.projectsPage.entities?.length, limit: 100 } },
      });
    }
  }, [query]);

  return (
    <>
      <TableActions>
        <Filters<ProjectFilter> filter={filter} onChange={setFilter} filterDefs={filterDefs} />
        <SearchBox debounceDelayInMs={500} onSearch={setSearchFilter} />
      </TableActions>
      <ScrollableContent virtualized>
        <QueryTable
          stickyHeader
          keepHeaderWhenLoading
          sorting={initSortState}
          emptyFallback="No projects found that match the given filters."
          query={query}
          columns={columns}
          createRows={createRows}
          rowStyles={rowStyles}
          style={{ rowHeight: "fixed", allWhite: true, bordered: true }}
          as="virtual"
          infiniteScroll={{
            onEndReached: maybeFetchNextPage,
          }}
        />
      </ScrollableContent>
    </>
  );
}

type Row = SimpleHeaderAndData<ProjectsPageProjectFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({ header: "ID", data: (data) => removeTag(data.id), w: "72px", serverSideSortKey: "id" }),
  column<Row>({
    id: "address",
    header: "Address",
    data: (data) => data.buildAddress.street1,
    w: 3,
    mw: "250px",
    serverSideSortKey: "buildAddress",
  }),
  column<Row>({
    header: "Homeowner",
    data: ({ primaryHomeowner }) => {
      return primaryHomeowner ? primaryHomeowner.shortName : "";
    },
    w: "188px",
    serverSideSortKey: "homeowner",
  }),
  column<Row>({
    header: "Status",
    data: (data) => tagCell(projectStatusToTagType[data.status.code], projectStatusDetails[data.status.code]),
    w: "160px",
    serverSideSortKey: "status",
  }),
  column<Row>({
    header: "Latest Stage",
    data: (data) => data.latestActiveStage?.stage.name ?? "-",
    w: "120px",
    serverSideSortKey: "latestActiveStage",
  }),
  column<Row>({ header: "Market", data: (data) => data.market.name, w: "140px", serverSideSortKey: "market" }),
  column<Row>({ header: "Cohort", data: (data) => data.cohort?.name, w: "220px", serverSideSortKey: "cohort" }),
  dateColumn<Row>({
    header: "Target End Date",
    data: (data) => {
      return dateCell(data.targetEndDate);
    },
    w: "140px",
    serverSideSortKey: "targetEndDate",
  }),
  column<Row>({
    header: "Project Lead",
    data: (data) => data.projectLead?.user?.name ?? "",
    w: "140px",
    serverSideSortKey: "projectLead",
  }),
  actionColumn<Row>({
    header: "HOP",
    data: (data) => (
      <div
        onClick={(e) => {
          e.preventDefault();
          openNewTab(createHopProjectUrl(data.id));
        }}
        data-testid="hopLink"
      >
        <Icon icon="linkExternal" color={Palette.Gray700} />
      </div>
    ),
    w: "60px",
  }),
];

function createRows(data: ProjectsPageProjectsQuery | undefined): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(data?.projectsPage.entities?.map((p) => ({ kind: "data" as const, id: p.id, data: p })) || []),
  ];
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: { rowLink: ({ id }) => createProjectDashboardUrl(id) },
};

const projectStatusToTagType: Record<ProjectStatus, TagType> = {
  [ProjectStatus.Active]: "info",
  [ProjectStatus.Hold]: "caution",
  [ProjectStatus.Closed]: "neutral",
  [ProjectStatus.Completed]: "success",
  [ProjectStatus.UnderWarranty]: "success",
};
