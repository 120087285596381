import { datadogRum } from "@datadog/browser-rum";
import { Css } from "@homebound/beam";
import { Component, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { ErrorMessages } from "src/components/ErrorMessages";

type ErrorBoundaryClassState = {
  hasError: boolean;
  error?: Error;
  currentPath: string;
};

type ErrorBoundaryClassProps = {
  children: ReactNode;
  currentPath: string;
};

/** ErrorBoundary set up as a class component since there is no hook equivalent for `componentDidCatch` lifecycle method.
 * https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * The react docs do point to a lib: `react-error-boundary` that can be imported, but the implementation is so simple we can skip the extra import.
 * We also have more control over resetting of the error state based on route changes like we're doing below */
class WrappedErrorBoundary extends Component<ErrorBoundaryClassProps, ErrorBoundaryClassState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, currentPath: "" };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  static getDerivedStateFromProps(props: ErrorBoundaryClassProps, state: ErrorBoundaryClassState) {
    // Rather than trying to compare props.children, we'll use a route change as a signal to reset the error boundary
    if (state.currentPath !== props.currentPath) {
      return {
        hasError: false,
        error: undefined,
        currentPath: props.currentPath,
      };
    }

    return state;
  }

  componentDidCatch(error: Error) {
    datadogRum.addError(error);
  }

  render() {
    const { hasError, error } = this.state;

    if (!hasError || !error) {
      return this.props.children;
    }

    return (
      <div css={Css.df.fdc.gap2.wPx(650).br8.bgWhite.p8.ma.$} data-testid="ErrorBoundary">
        <img src="/images/safety-guardrail.svg" css={Css.w50.asc.$} alt="" />
        <h1 css={Css.lgBd.my2.tac.$}>
          Oh no! <br />
          An error has occurred that we couldn't recover from
        </h1>
        <p css={Css.tac.base.$}>
          For immediate help, reach out to the <span css={Css.baseBd.$}>#product-feedback </span> slack channel
        </p>

        <ErrorMessages error={error} />
      </div>
    );
  }
}

/** ErrorBoundary component that will present a fallback UX to the user while also reporting errors to Datadog. */
export function ErrorBoundary({ children }: { children: ReactNode }) {
  // This component also listens for location changes to attempt to reset the error state so the user can navigate back to a working page.
  // Set up as a wrapper component since ErrorBoundary components must be a class component and react router is moving towards only supporting hooks going forward.
  const location = useLocation();

  return <WrappedErrorBoundary currentPath={location.pathname}>{children}</WrappedErrorBoundary>;
}
