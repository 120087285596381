import { column, Css, GridColumn, GridDataRow, GridTable, Palette, simpleHeader, useTestIds } from "@homebound/beam";
import { emptyCellDash, formatDate, HomeboundLogo } from "src/components";
import {
  LotSequenceSheetTableMetadataQuery,
  ProjectReadyPlanConfigOrientation,
  PrpcForLotSequenceExportFragment,
  useLotSequenceSheetPdfQuery,
  useLotSequenceSheetTableMetadataQuery,
} from "src/generated/graphql-types";
import { mapToFilter, OptionTypes } from "src/routes/developments/lot-summary/sequence-sheet/components/utils";
import { Error } from "src/routes/Error";
import { pluralize, queryResult } from "src/utils";
import { StringParam, useQueryParams } from "use-query-params";

export function LotSequenceSheetPdf() {
  const [{ developmentId }] = useQueryParams({ developmentId: StringParam });
  const query = useLotSequenceSheetTableMetadataQuery({ variables: { developmentId: developmentId! } });
  return queryResult(query, {
    data: (data) => <LotSequenceSheetPdfComponent developmentId={developmentId!} metadata={data} />,
  });
}

type LotSequenceSheetPdfComponentProps = {
  developmentId: string;
  metadata: LotSequenceSheetTableMetadataQuery;
};

function LotSequenceSheetPdfComponent({ developmentId, metadata }: LotSequenceSheetPdfComponentProps) {
  const [{ filter }] = useQueryParams({ filter: StringParam });
  const query = useLotSequenceSheetPdfQuery({
    variables: {
      developmentId: developmentId!,
      filter: filter ? { development: [developmentId!], ...mapToFilter(JSON.parse(filter), metadata) } : {},
    },
    skip: !developmentId,
  });
  const tid = useTestIds({});

  if (!developmentId) {
    return <Error error={{ name: "", message: "The required query parameter (developmentId) is missing" }} />;
  }

  return queryResult(query, (data) => {
    const { projectReadyPlanConfigs, development } = data;
    const cohorts =
      projectReadyPlanConfigs
        // Kind of janky, but prefer sorting by constructionStartDate first, then by id
        ?.sortBy((prpc) => (prpc.constructionStartDate ? `a-${prpc.constructionStartDate}` : `b-${prpc.id}`))
        ?.groupByObject(({ project }) => project.cohort) ?? [];

    return (
      <div>
        <div css={Css.df.lg.tac.$}>
          <HomeboundLogo color={Palette.Gray900} />
          {/* To get the title to sit centered in the page, then we add a  -140px margin left (the width of the HomeboundLogo) */}
          <h1 css={Css.df.fdc.fg1.mlPx(-140).$}>
            <span css={Css.lgBd.$} {...tid.developmentName}>
              Lot Summary - {development.name}
            </span>
            <span data-chromatic="ignore" {...tid.date}>
              {formatDate(new Date(), "long")}
            </span>
          </h1>
        </div>

        {cohorts.map(([cohort, prpcs], idx) => (
          <div css={Css.mt2.$} key={cohort?.id ?? idx}>
            <div css={Css.baseBd.$} {...tid.cohortName}>
              {cohort?.name ?? "No Cohort"} - {prpcs.length} {pluralize(prpcs.length, "Lot", "Lots")}
            </div>
            <div css={Css.bl.bt.bcGray300.mt1.$}>
              <GridTable
                as="table"
                columns={createColumns(metadata)}
                rows={createRows(prpcs)}
                style={{
                  headerCellCss: Css.gray600.smBd.$,
                  cellCss: Css.bb.br.bcGray300.p1.$,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  });
}

function createColumns(metadata: LotSequenceSheetTableMetadataQuery): GridColumn<Row>[] {
  const otherOptions = metadata.globalOptionTypes.filter(
    (got) =>
      !got.isElevation &&
      !got.isExteriorPalette &&
      !got.isSpecLevel &&
      got.name !== OptionTypes.FloorPlan &&
      got.name !== OptionTypes.AddOn &&
      got.name !== OptionTypes.Interior,
  );

  return [
    column<Row>({
      header: "Address",
      prpc: ({ project, readyPlan, orientationDetail, specOption }) => (
        <div>
          <div css={Css.fw7.$}>{project.buildAddress.street1}</div>
          <div>
            {readyPlan && specOption
              ? `${readyPlan.displayCode} - ${readyPlan.name} - ${
                  orientationDetail.code === ProjectReadyPlanConfigOrientation.Right ? "RH" : "LH"
                } - ${specOption.readyPlanOption.name}`
              : "N/A"}
          </div>
        </div>
      ),
    }),
    column<Row>({
      header: "Exterior Options",
      prpc: ({ options }) => {
        const elevationOption = options.find((o) => o.readyPlanOption.type.isElevation);
        const exteriorSchemeOption = options.find((o) => o.readyPlanOption.type.isExteriorPalette);
        if (!exteriorSchemeOption && !elevationOption) {
          return emptyCellDash;
        }
        return `${elevationOption?.readyPlanOption.name ?? "No elevation selected"} - ${
          exteriorSchemeOption?.readyPlanOption.name ?? "No exterior scheme selected"
        }`;
      },
    }),
    column<Row>({
      header: "Floor Plan Options",
      prpc: ({ options }) => {
        const floorPlanOptions = options.filter(
          (o) => o.readyPlanOption.globalOption.type.name === OptionTypes.FloorPlan,
        );
        if (floorPlanOptions.length === 0) {
          return emptyCellDash;
        }
        return floorPlanOptions.map((o) => (
          <div key={o.id}>
            {o.readyPlanOption.code} - {o.readyPlanOption.name}
          </div>
        ));
      },
    }),
    column<Row>({
      header: "Add-ons",
      prpc: ({ options }) => {
        const addOnOptions = options.filter((o) => o.readyPlanOption.globalOption.type.name === OptionTypes.AddOn);
        if (addOnOptions.length === 0) {
          return emptyCellDash;
        }
        return addOnOptions.map((o) => (
          <div key={o.id}>
            {o.readyPlanOption.code} - {o.readyPlanOption.name}
          </div>
        ));
      },
    }),
    column<Row>({
      header: "Interior Style",
      prpc: ({ options, specOption }) => {
        const interiorOptions = options.filter(
          (o) => o.readyPlanOption.globalOption.type.name === OptionTypes.Interior,
        );
        if (interiorOptions.length === 0) {
          return emptyCellDash;
        }
        return interiorOptions.map((o) => (
          <div key={o.id}>
            {o.readyPlanOption.name}
            {specOption && ` - ${specOption.readyPlanOption.name}`}
          </div>
        ));
      },
    }),
    ...(!otherOptions.isEmpty
      ? [
          column<Row>({
            header: "Other Options",
            prpc: ({ options, specOption }) => {
              const otherOptions =
                options
                  .filter(
                    (o) =>
                      !o.readyPlanOption.type.isElevation &&
                      !o.readyPlanOption.type.isExteriorPalette &&
                      !o.readyPlanOption.type.isSpecLevel &&
                      o.readyPlanOption.globalOption.type.name !== OptionTypes.FloorPlan &&
                      o.readyPlanOption.globalOption.type.name !== OptionTypes.AddOn &&
                      o.readyPlanOption.globalOption.type.name !== OptionTypes.Interior,
                  )
                  .groupByObject((o) => o.readyPlanOption.globalOption.type) ?? [];

              if (otherOptions.length === 0) {
                return emptyCellDash;
              }
              return otherOptions.map((otherOption) => (
                <div key={otherOption[0].id}>
                  {otherOption[0].name}
                  <ul>
                    {otherOption[1].map((option) => (
                      <li key={option.id}>
                        {option.readyPlanOption.code} - {option.readyPlanOption.name}
                      </li>
                    ))}
                  </ul>
                </div>
              ));
            },
          }),
        ]
      : []),
  ];
}

function createRows(prpcs: PrpcForLotSequenceExportFragment[]): GridDataRow<Row>[] {
  return [simpleHeader, ...prpcs.map((prpc) => ({ kind: "prpc" as const, id: prpc.id, data: prpc }))];
}

type HeaderRow = { kind: "header" };
type ProjectRow = { kind: "prpc"; data: PrpcForLotSequenceExportFragment };
type Row = HeaderRow | ProjectRow;
