import { TabWithContent, TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useExpensesPageMetadataQuery } from "src/generated/graphql-types";
import { EmptyState } from "src/routes/components/EmptyState";
import { EmptyPlansDetailImage } from "src/routes/developments/plan-and-options/components/EmptyStateSvgs";
import { PageHeader } from "src/routes/layout/PageHeader";
import { ExpensesTable } from "src/routes/projects/expenses/components/ExpensesTable";
import { ProjectHeader } from "src/routes/projects/expenses/components/ProjectHeader";
import { ProjectParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export function ExpensesPage() {
  const { projectId } = useParams<ProjectParams>();
  const metadata = useExpensesPageMetadataQuery({ variables: { projectId } });
  return queryResult(metadata, ({ project }) => (
    <>
      <PageHeader title="Trade Expenses" />
      {project.expenses.isEmpty ? (
        <EmptyState
          message={"There are currently no expenses to allocate for this project."}
          svg={<EmptyPlansDetailImage />}
          button={<Link to={"/projects"}>Go to Projects</Link>}
        />
      ) : (
        <>
          <ProjectHeader project={project} allProjects={[project]} />
          <ExpenseProjectView projectId={project.id} refetchHeaderQuery={metadata.refetch} />
        </>
      )}
    </>
  ));
}

type ExpenseProjectViewProps = {
  projectId: string;
  refetchHeaderQuery: VoidFunction;
};

type ExpensesTab = "unallocated" | "allocated";

export function ExpenseProjectView({ projectId, refetchHeaderQuery }: ExpenseProjectViewProps) {
  const [selectedTab, setSelectedTab] = useState<ExpensesTab>("unallocated");

  const tabs: TabWithContent<ExpensesTab>[] = [
    {
      name: "Unallocated",
      value: "unallocated",
      render: () => (
        <ExpensesTable projectId={projectId} unallocatedView={true} refetchHeaderQuery={refetchHeaderQuery} />
      ),
    },
    {
      name: "Allocated",
      value: "allocated",
      render: () => (
        <ExpensesTable projectId={projectId} unallocatedView={false} refetchHeaderQuery={refetchHeaderQuery} />
      ),
    },
  ];

  return <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} includeBottomBorder />;
}
