import {
  actionColumn,
  Button,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  Icon,
  Palette,
  selectColumn,
  simpleHeader,
  Tooltip,
} from "@homebound/beam";
import { useMemo } from "react";
import { dateCell } from "src/components";

import {
  DevelopmentScopeTemplateFragment,
  ItemTemplateStatus,
  ReadyPlanStatus,
  useArchiveItemTemplateVersionsMutation,
  useDeleteItemTemplatesMutation,
} from "src/generated/graphql-types";
import { CopyLineItemsModalButton } from "src/routes/developments/templates/components/CopyLineItemsModalButton";
import { createDevelopmentScopeTemplateUrl } from "src/RouteUrls";

export type DevelopmentScopeTemplatesProp = {
  searchFilter: string | undefined;
  developmentId: string;
  itemTemplates: DevelopmentScopeTemplateFragment[];
  onSave: () => void;
  fallbackMessage: string;
  tableApi: GridTableApi<Row>;
};

export function DevelopmentScopeTemplatesTable(props: DevelopmentScopeTemplatesProp) {
  const { developmentId, searchFilter, itemTemplates, onSave, fallbackMessage, tableApi } = props;
  // Used to control the rendering of the archive column
  const hasArchivableItemTemplates = itemTemplates.some((it) => {
    const { isArchived, readyPlanIsArchived } = getReadyPlanArchiveStatus(it);
    return !isArchived && readyPlanIsArchived;
  });
  const columns = useMemo(
    () => createColumns(developmentId, onSave, hasArchivableItemTemplates),
    [developmentId, onSave, hasArchivableItemTemplates],
  );
  const rows = useMemo(() => createScopeTemplateTableRows(itemTemplates), [itemTemplates]);

  return (
    <GridTable
      columns={columns}
      filter={searchFilter}
      style={{ rowHeight: "fixed", allWhite: true, bordered: true }}
      rows={rows}
      stickyHeader
      fallbackMessage={fallbackMessage}
      api={tableApi}
      sorting={{
        on: "client",
        initial: ["templateName", "ASC"],
      }}
    />
  );
}

type HeaderRow = { kind: "header" };

type ItemTemplateRow = {
  kind: "itemTemplate";
  data: DevelopmentScopeTemplateFragment;
  id: string;
};
type Row = HeaderRow | ItemTemplateRow;
export type DevelopmentScopeTemplatesTableRow = Row;

function createScopeTemplateTableRows(itemTemplates: DevelopmentScopeTemplateFragment[]): GridDataRow<Row>[] {
  const rows: any = [
    simpleHeader,
    ...itemTemplates.map((it) => {
      return {
        id: it.id,
        kind: "itemTemplate" as const,
        data: it,
      };
    }),
  ];

  return rows;
}

function createColumns(developmentId: string, onSave: () => void, showArchiveCol: boolean): GridColumn<Row>[] {
  const nameColumn = column<Row>({
    id: "planName",
    header: () => ({ content: "Plan", w: "350px", css: Css.pl0.pr0.$ }),
    itemTemplate: (row) => getNameCellContent(row, developmentId),
    w: "350px",
  });

  const versionColumn = column<Row>({
    id: "versionName",
    header: "Version",
    itemTemplate: ({ displayVersion, isVersioned }) => (isVersioned ? `${displayVersion}` : "--"),
  });

  const archiveColumn = actionColumn<Row>({
    header: () => ({ ...emptyCell, css: Css.pl0.$ }),
    itemTemplate: (row) => getArchiveCellContent(row),
    clientSideSort: false,
    w: "16px",
  });

  const statusColumn = column<Row>({
    header: "Status",
    itemTemplate: ({ statusDetail }) => statusDetail.name,
  });

  const createdAtColumn = column<Row>({
    header: "Created",
    itemTemplate: ({ createdAt }) => dateCell(createdAt),
  });

  const updatedAtColumn = column<Row>({
    header: "Last updated",
    itemTemplate: ({ updatedAt }) => dateCell(updatedAt),
  });

  const actionsColumn = actionColumn<Row>({
    header: emptyCell,
    itemTemplate: (row) => getActionCellContent(row, developmentId, onSave),
    w: "180px",
  });

  return [
    selectColumn<Row>(),
    ...(showArchiveCol ? [archiveColumn] : []),
    nameColumn,
    versionColumn,
    statusColumn,
    createdAtColumn,
    updatedAtColumn,
    actionsColumn,
  ];
}

function ArchiveOrDeleteTemplateButton({
  itemTemplate,
  onSave,
}: {
  itemTemplate: Pick<DevelopmentScopeTemplateFragment, "id" | "canDelete">;
  onSave: () => void;
}) {
  const [archiveItemTemplate] = useArchiveItemTemplateVersionsMutation();
  const [deleteItemTemplate] = useDeleteItemTemplatesMutation();

  const deleteTemplate = itemTemplate.canDelete.allowed;

  return (
    <Button
      label={deleteTemplate ? "Delete" : "Archive"}
      variant="secondary"
      onClick={async () => {
        if (deleteTemplate) {
          await deleteItemTemplate({ variables: { id: [itemTemplate.id] } });
        } else {
          await archiveItemTemplate({
            variables: { templateId: [itemTemplate.id], archiveAllVersions: true },
          });
        }
        // We requery instead of evict due to the way template versioning works with the `latest` template is hard to predict from the FE
        onSave();
      }}
    />
  );
}

// Renders our action cell content for parent template columns
// Currently there are two possible actions for templates:
// 1. Show a 'Archive`/`Delete` btn depending on the state of the ready plan
// 2. Then 'copy line items' btn as the default state
function getActionCellContent(row: DevelopmentScopeTemplateFragment, developmentId: string, onSave: () => void) {
  const { isArchived, readyPlanIsArchived } = getReadyPlanArchiveStatus(row);
  return {
    content: () => {
      if (!isArchived && readyPlanIsArchived)
        return <ArchiveOrDeleteTemplateButton itemTemplate={row} onSave={onSave} />;

      return row.readyPlan?.id ? (
        <CopyLineItemsModalButton
          templateId={row.id}
          displayName={row.displayName}
          onSave={onSave}
          developmentId={developmentId}
          hasCloneToTargets={row.hasCloneToTargets}
        />
      ) : (
        <></>
      );
    },
    revealOnRowHover: true as const,
  };
}

// Renders our archive cell content for parent template columns.
// If a ReadyPlan has been archived but we have not archived the template,
// we show an Icon letting the user know to archive their template
function getArchiveCellContent(row: DevelopmentScopeTemplateFragment) {
  const { isArchived, readyPlanIsArchived } = getReadyPlanArchiveStatus(row);

  return {
    content: () =>
      !isArchived && readyPlanIsArchived ? (
        <div css={Css.p0.$}>
          <Tooltip title="This ReadyPlan has been archived">
            <Icon icon="error" color={Palette.Red600} inc={2} />
          </Tooltip>
        </div>
      ) : (
        <></>
      ),
    css: Css.pl0.$,
  };
}

// Renders our name cell content for parent/child template columns
function getNameCellContent(row: DevelopmentScopeTemplateFragment, developmentId: string) {
  return {
    content: row.displayName,
    onClick: createDevelopmentScopeTemplateUrl(developmentId, row.id),
    css: Css.pl0.pr0.$,
  };
}

// Returns whether an ItemTemplate isArchived, whether or not its ReadyPlan is archivedts
function getReadyPlanArchiveStatus(it: DevelopmentScopeTemplateFragment) {
  const isArchived = it.statusDetail.code === ItemTemplateStatus.Archived;

  const readyPlanIsArchived = it.readyPlan && it.readyPlan?.status.code === ReadyPlanStatus.Archived;

  return { isArchived, readyPlanIsArchived };
}

export enum Column {
  Select,
  TemplateName,
  Version,
  Status,
  Created,
  LastUpdated,
  Actions,
}
