import { Button, Css, DateField } from "@homebound/beam";
import { useState } from "react";

export type InvoiceDateFilterProps = {
  startDate: Date;
  endDate: Date;
  onChange: (endDate: Date) => void;
};

export function InvoiceDateFilter({ endDate, startDate, onChange }: InvoiceDateFilterProps) {
  const [editDate, setEditDate] = useState(true);
  return (
    <>
      <DateField label="Start date" data-testid="startDate" onChange={() => {}} value={startDate} readOnly />
      <div css={Css.df.aife.w("100%").$}>
        <div css={Css.mr("5px").$}>
          <DateField
            label="End date"
            onChange={(date) => {
              if (date) {
                setEditDate(true);
                onChange(date);
              }
            }}
            value={endDate}
            readOnly={editDate}
            disabledDays={[{ before: startDate }]}
            data-testid="endDate"
          />
        </div>
        {editDate && (
          <div css={Css.addIn("> button", Css.xs.$).$}>
            <Button variant="text" onClick={() => setEditDate(!editDate)} label="Edit" />
          </div>
        )}
      </div>
    </>
  );
}
