import { Css } from "@homebound/beam";
import { camelCase } from "change-case";
import { PdfProjectItemFragment } from "src/generated/graphql-types";
import { HasChildren } from "src/utils";

const productForNoSelection = {
  name: "No selection",
  manufacturerUrl: "N/A",
  description: "N/A",
  sku: "N/A",
};

type PdfProjectItemDetailProps = {
  projectItem: PdfProjectItemFragment;
};

export function ProjectItemDetail(props: PdfProjectItemDetailProps) {
  const { displayName, unitOfMeasure, homeownerSelection, protoCommitmentLineItemToBuyout } = props.projectItem;
  const { quantity, specifications, tradePartnerNote } = { ...props.projectItem, ...protoCommitmentLineItemToBuyout };
  const product = homeownerSelection
    ? (homeownerSelection.selectedOption?.product ?? productForNoSelection)
    : undefined;

  return (
    <div>
      <div css={Css.base.fwb.mt2.$} data-testid="displayName">
        {displayName}
      </div>
      <ul css={Css.listReset.add("listStyleType", "disc").add("padding", "revert").$}>
        {product && <ProjectItemDetailRow title="Product Name">{product.name}</ProjectItemDetailRow>}
        <ProjectItemDetailRow title="Quantity">
          {quantity} {unitOfMeasure?.name}
        </ProjectItemDetailRow>
        <ProjectItemDetailRow title="Specifications">{specifications ?? ""}</ProjectItemDetailRow>
        <ProjectItemDetailRow title="Trade Note">{tradePartnerNote ?? ""}</ProjectItemDetailRow>
        {product && <ProjectItemDetailRow title="Product Description">{product.description}</ProjectItemDetailRow>}
        {product && (
          <ProjectItemDetailRow title="Product Manufacturer URL">
            {product === productForNoSelection ? (
              product.manufacturerUrl
            ) : (
              <a href={product.manufacturerUrl ?? ""}>{product.manufacturerUrl}</a>
            )}
          </ProjectItemDetailRow>
        )}
        {product && <ProjectItemDetailRow title="SKU">{product.sku}</ProjectItemDetailRow>}
      </ul>
    </div>
  );
}

type PdfProjectItemDetailRowProps = HasChildren & {
  title: string;
};

function ProjectItemDetailRow(props: PdfProjectItemDetailRowProps) {
  return (
    <li css={Css.mt("5px").mb("5px").$} data-testid={props["data-testid"] ?? camelCase(props.title)}>
      <span css={Css.tdu.mr1.$}>{props.title}:</span>
      {props.children}
    </li>
  );
}
