import { GridDataRow } from "@homebound/beam";
import {
  ConfirmLineItemsCostLineItemEntityFragment,
  ConfirmLineItemsCostLineItemFragment,
  Maybe,
} from "src/generated/graphql-types";
import { CostLineItemRow } from "../components/InvoiceLineItemsTable";
import { InvoiceHomeownerContractLineItem } from "./InvoiceHomeownerContractLineItem";

export class InvoiceCostLineItem {
  id: string;
  costLineItemId: string;
  homeownerContractAmount: Maybe<number>;
  invoicedToDate: number;
  remainingFromPrior: number;
  periodActuals: number;
  subtotal: number;
  toInvoice: number;
  exceedActuals: boolean = false;
  entity: ConfirmLineItemsCostLineItemEntityFragment;
  parent: InvoiceHomeownerContractLineItem;
  parentHCLIId: string;
  isChangeOrder: boolean;

  constructor(cli: ConfirmLineItemsCostLineItemFragment, parent: InvoiceHomeownerContractLineItem) {
    this.id = `${parent.id}-${cli.id}`;
    this.costLineItemId = cli.id;
    this.entity = cli.entity;
    this.homeownerContractAmount = undefined;
    this.parent = parent;
    this.parentHCLIId = parent.id;
    this.isChangeOrder = parent.isChangeOrder;

    const { markupMultiplier } = parent;
    const unallocatedCostLineItems = parent.unallocatedCostLineItems();

    // these amountInCents have subtracted allocations already
    const periodActualsAmount = parent
      .periodActuals(unallocatedCostLineItems)
      .filter((pCli) => pCli.id === cli.id)
      .map((pCli) => pCli.amountInCents)
      .sum();
    const remainingFromPriorAmount = parent
      .remainingFromPrior(unallocatedCostLineItems)
      .filter((rCli) => rCli.id === cli.id)
      .map((rCli) => rCli.amountInCents)
      .sum();
    const subtotal = periodActualsAmount + remainingFromPriorAmount;

    // current allocations, other cli invoice amounts, and credits should be counted against the homeowner contract comparison
    const hcliInvoicedToDate = parent
      .costLineItemAllocations()
      .map((clia) => clia.invoicedAmountInCents)
      .sum();

    const otherBillLineItemInvoiceAmount = this.costLineItemsBeforeAndCredits
      .map((cli) => cli.amountInCents * markupMultiplier)
      .sum();

    const invoicedToDate = parent
      .costLineItemAllocations()
      .filter((clia) => clia.costLineItemId === cli.id)
      .map((clia) => clia.invoicedAmountInCents)
      .sum();

    let toInvoice = subtotal * markupMultiplier;
    if (toInvoice > 0) {
      // in case of total amount before is enough then we should set zero to turn the row unselectable
      if (hcliInvoicedToDate + otherBillLineItemInvoiceAmount >= parent.homeownerContractAmount) {
        toInvoice = 0;
      } else if (toInvoice + hcliInvoicedToDate + otherBillLineItemInvoiceAmount > parent.homeownerContractAmount) {
        this.exceedActuals = true;
        // remove the amount over the invoice
        toInvoice -= toInvoice + hcliInvoicedToDate + otherBillLineItemInvoiceAmount - parent.homeownerContractAmount;
      }
    }

    this.invoicedToDate = invoicedToDate;
    this.periodActuals = periodActualsAmount;
    this.remainingFromPrior = remainingFromPriorAmount;
    this.subtotal = subtotal;
    this.toInvoice = toInvoice;
  }

  get isSelectable() {
    return this.parent.hasActualsOnCurrentPeriod && this.toInvoice !== 0;
  }

  get costLineItemsBeforeAndCredits() {
    const parentUnallocatedCLIs = this.parent.unallocatedCostLineItems();
    const index = parentUnallocatedCLIs.findIndex((cli) => cli.id === this.costLineItemId);
    const priorBills = parentUnallocatedCLIs.slice(0, index);
    const creditsAfter = parentUnallocatedCLIs.slice(index + 1).filter((cli) => cli.isTradePartnerCredit);
    return [...priorBills, ...creditsAfter];
  }

  toRow(): GridDataRow<CostLineItemRow> {
    return {
      kind: "costLineItem",
      // The same cli can be under multiple HCLIs, so we need a unique id
      id: this.id,
      selectable: this.isSelectable ? undefined : false,
      data: this,
    };
  }
}
