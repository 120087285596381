import { ApolloQueryResult } from "@apollo/client";
import {
  Button,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  IconButton,
  ModalProps,
  emptyCell,
  simpleHeader,
  useModal,
} from "@homebound/beam";
import { createTradePartnersUrl } from "src/RouteUrls";
import {
  PaymentTermDueFromDetail,
  PaymentTermFragment,
  PaymentTermsQuery,
  usePaymentTermsQuery,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils";
import { PageHeader } from "../../layout/PageHeader";
import { CreateOrUpdatePaymentTermModal } from "./CreateOrUpdatePaymentTermModal";

export function PaymentTermsPage() {
  const { openModal } = useModal();
  const query = usePaymentTermsQuery();

  return queryResult(query, (data) => {
    const dueFromDetails = data.paymentTermDueFromDetails ?? [];
    return (
      <div>
        <PageHeader title="Payment Terms" breadcrumb={[{ label: "Trade Partners", href: createTradePartnersUrl() }]} />
        <div css={Css.maxw("1400px").ma.$}>
          <div css={Css.df.fdr.jcsb.mb2.$}>
            <div css={Css.mt1.$}>Payment Terms determine when a specific Trade Partner gets paid.</div>
            <Button
              label="Create Payment Term"
              onClick={() =>
                openModal({
                  content: <CreateOrUpdatePaymentTermModal dueFromDetails={dueFromDetails} onClose={query.refetch} />,
                })
              }
            />
          </div>
          <GridTable
            rows={createRows(data.paymentTerms ?? [])}
            columns={createColumns(dueFromDetails, openModal, query.refetch)}
          />
        </div>
      </div>
    );
  });
}

type HeaderRow = { kind: "header"; data: undefined; id: string };
type LineItemRow = { kind: "lineItem"; data: PaymentTermFragment; id: string };
type Row = HeaderRow | LineItemRow;

function createRows(paymentTerms: PaymentTermFragment[]): GridDataRow<Row>[] {
  const rows = paymentTerms.map((pt) => ({
    kind: "lineItem" as const,
    id: pt.id,
    data: pt,
  }));

  return [simpleHeader, ...rows];
}

function createColumns(
  dueFromDetails: PaymentTermDueFromDetail[],
  openModal: (props: ModalProps) => void,
  onClose: () => Promise<ApolloQueryResult<PaymentTermsQuery>>,
): GridColumn<Row>[] {
  return [
    {
      header: "Payment Term",
      lineItem: ({ name }) => name,
      w: 0.4,
    },
    {
      header: "Description",
      lineItem: ({ description }) => description,
    },
    {
      header: "Status",
      lineItem: ({ active }) => (
        <div css={Css.df.fdr.$}>
          <div css={Css.wPx(8).hPx(8).br100.mt("4px").mr1.if(active).bgGreen400.else.bgRed400.$} />
          <div>{active ? "Active" : "Inactive"}</div>
        </div>
      ),
      align: "left",
      w: 0.1,
    },
    {
      header: emptyCell,
      lineItem: (pt) => (
        <IconButton
          data-testid="editPaymentTerm"
          icon="pencil"
          onClick={() =>
            openModal({
              content: (
                <CreateOrUpdatePaymentTermModal dueFromDetails={dueFromDetails} paymentTerm={pt} onClose={onClose} />
              ),
            })
          }
        />
      ),
      align: "right",
      w: 0.1,
    },
  ];
}
