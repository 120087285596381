import { useSnackbar } from "@homebound/beam";
import { useCallback, useEffect, useState } from "react";
import { CsvRow } from "src/components/CsvUploader";
import { isCsv } from "src/components/CsvUtils";
import { SaveItemTemplateItemVersionInput } from "src/generated/graphql-types";
import { SaveItivResult } from "../TakeoffsManagerContext";

type MappedInputErrors = {
  kind: "errors";
  errors: string[];
};

type MappedInputSuccess = {
  kind: "success";
  inputs: SaveItemTemplateItemVersionInput[];
};

type MappedInputResult = MappedInputErrors | MappedInputSuccess;

/** Maps CSV rows to GQL inputs for item template items. */
export function mapToInputs(rows: CsvRow[]): MappedInputResult {
  const { header, columnIndexes } = findHeaders(rows);
  if (header === undefined) {
    return { kind: "errors", errors: ["No headers founds; please check format of CSV and upload again"] };
  }

  // Get the raw string[] data into an array of JSON objects
  const importRows = rows
    .slice(1, rows.length) // slice drops the header
    .filter((row) => row.data.length > 1) // drop any empty rows / new lines, i.e. `row=[""]`
    .map((row, i) => {
      // Create a JSON version of the data[]; surely react-papaparse could do this for us...
      const importRow = { index: i } as ImportRow;
      header.data.forEach((header, j) => {
        const inputKey = csvHeaderToInputKey[header];
        if (inputKey) importRow[inputKey] = row.data[columnIndexes[j]]?.trim();
      });
      return importRow;
    });

  // Keep a list of errors, granted all we can do client-side is if the lookup failed
  const errors: string[] = [];

  const inputs = importRows.map((row, i) => {
    const input: SaveItemTemplateItemVersionInput = {};
    const isRemove = row.quantity?.toLowerCase() === "remove";
    input.itemCode = row.itemCode;
    input.costTypeName = row.itemType;
    input.locationPath = row.location;
    input.unitOfMeasureName = row.uom || undefined;
    input.taskName = row.taskId || undefined;
    input.quantity = isRemove ? 0 : (row.quantity && quantity(row.quantity)) || 0;
    input.totalCostInCents = 0;
    input.materialVariantCode = row.materialVariantId || undefined;
    // Allow multiple options
    input.optionCodes = (row.optionIds?.split(",") ?? []).map(stripEmptyString).unique().compact();
    if (isRemove) {
      input.remove = isRemove;
    }

    const unsetFields = (
      [
        ["itemCode", "itemCode"],
        ["costTypeName", "itemType"],
        ["locationPath", "location"],
        ["unitOfMeasureName", "uom"],
        ["taskName", "taskId"],
        ["materialVariantCode", "materialVariantId"],
      ] as const
    ).filter(([inputField, csvField]) => !input[inputField] && !row[csvField]);

    // we set the maybe optional fields, so we can do, if task is set, bidItemId is optional and the other way around
    const maybeOptionalFields: { field: keyof ImportRow; optionalIfField: (keyof ImportRow)[] }[] = [
      { field: "taskId", optionalIfField: ["materialVariantId"] },
      { field: "materialVariantId", optionalIfField: ["taskId"] },
    ];
    maybeOptionalFields.forEach(({ field, optionalIfField }) => {
      const unsetField = unsetFields.find(([, csvField]) => csvField === field);
      const isFieldOptional = optionalIfField.some((otherRequiredField) => !!row[otherRequiredField]);
      if (unsetField && isFieldOptional) {
        // we remove the field from the unsetFields to avoid the error message and allow the upload
        unsetFields.remove(unsetField);
      }
    });

    if (unsetFields.nonEmpty) {
      // Use +1 for the header and +1 to be 1-based, so +2
      errors.push(`Row ${i + 2} is missing required fields ${unsetFields.map(([, csvField]) => csvField).join(", ")}`);
    }

    return input;
  });

  if (errors.length > 0) {
    return { kind: "errors", errors };
  }

  return { kind: "success", inputs };
}

function findHeaders(rowData: CsvRow[]): { header: CsvRow | undefined; columnIndexes: number[] } {
  const requiredHeaders = ["Location*", "Quantity*", "Unit of Measure*", "Option Codes*", "Task*", "Material Code*"];
  // Does the first row have all our required headers?
  const headerRow = rowData[0];
  if (!headerRow || !requiredHeaders.every((name) => headerRow.data.includes(name))) {
    return { header: undefined, columnIndexes: [] };
  }
  // Support the headers being at random indexes
  const columnIndexes: number[] = [];
  const validHeaders: string[] = [];
  headerRow.data.forEach((header) => {
    const inputKey = csvHeaderToInputKey[header];
    if (inputKey) {
      validHeaders.push(header);
      columnIndexes.push(headerRow.data.indexOf(header));
    }
  });
  headerRow.data = validHeaders;
  return { header: headerRow, columnIndexes };
}

const csvHeaderToInputKey: Record<string, Exclude<keyof ImportRow, "index">> = {
  "Item Code*": "itemCode",
  "Item Type*": "itemType",
  "Item Name": "name",
  "Location*": "location",
  "Quantity*": "quantity",
  "Unit of Measure*": "uom",
  "Option Codes*": "optionIds",
  "Task*": "taskId",
  "Material Code*": "materialVariantId",
};

/** A step between CsvRow `string[]` and SaveItemTemplateItemVersionInput. */
export type ImportRow = {
  index: number;
  itemCode: string | undefined; // look up entity by cost
  itemType: string | undefined; // look up enum by name
  name: string;
  location: string | undefined; // look up enum by name
  specifications: string | undefined;
  tradePartnerNote: string | undefined;
  internalNote: string | undefined;
  totalCostInDollars: string | undefined;
  unitCostInDollars: string | undefined;
  quantity: string | undefined; // can be empty, will be set to 0 if lump sum uom
  uom: string | undefined; // look up entity by name
  blueprintProductId: string | undefined;
  bidItemId: string | undefined;
  bidItemCode: string | undefined;
  elevationId: string | undefined;
  specOptionName: string | undefined;
  optionId: string | undefined;
  optionIds: string | undefined;
  taskId: string | undefined; // look up entity by name
  materialVariantId: string | undefined; // look up entity by code
  path: string | undefined; // the path to the item in blueprint
};

function quantity(toParse: string): number {
  return Math.round(parseFloat(toParse.replace(/,/g, "")));
}

// Item Code needs to be 3 decimals, so 1.1 -> 1.100, 1.11 -> 1.110
// exported for testing
export function formatItemCode(itemCode: string | undefined) {
  if (itemCode) {
    const itemCodeParts = itemCode.split(".");
    if (itemCodeParts.length === 2) {
      const decimalPart = itemCodeParts[1];
      if (decimalPart.length === 1) {
        return `${itemCode}00`;
      } else if (decimalPart.length === 2) {
        return `${itemCode}0`;
      }
    }
  }
  return itemCode;
}

export function useTakeoffUploader(
  addItiv: (item: SaveItemTemplateItemVersionInput | SaveItemTemplateItemVersionInput[]) => SaveItivResult,
  opts?: { autoImport?: boolean },
) {
  const { autoImport } = opts ?? {};
  const [errors, setErrors] = useState<string[]>([]);
  const [csvRows, setCsvRows] = useState<CsvRow[] | undefined>();
  const [importDataReady, setImportDataReady] = useState(false);
  const [mappedInputResult, setMappedInputResult] = useState<MappedInputResult | undefined>(undefined);
  const { triggerNotice } = useSnackbar();

  const saveItemTemplateMutation = useCallback(
    async (result: MappedInputSuccess) => {
      const { data } = await addItiv(result.inputs);
      triggerNotice({
        message: `${data?.saveItemTemplateItemVersions.itemTemplateItemVersions.length} items were successfully uploaded`,
        icon: "success",
      });
      if (data?.saveItemTemplateItemVersions.deleted.nonEmpty)
        triggerNotice({
          message: `${data?.saveItemTemplateItemVersions.deleted.length} items could not be uploaded due to mismatching Ready Plan Options.`,
          icon: "warning",
          persistent: true,
        });
    },
    [addItiv, triggerNotice],
  );

  useEffect(() => {
    if (csvRows) {
      // Used as a switch to prevent the user importing before the importData is ready
      setImportDataReady(true);
      // We reset the errors array every time we upload a new file
      setErrors([]);
      const result = mapToInputs(csvRows);
      if (result.kind === "errors") {
        setErrors(result.errors);
      }
      // If we're auto importing and no errors have been found, then we can 'autoImport' the items, otherwise we wait for the user to click the import button
      void (autoImport && result.kind === "success" ? saveItemTemplateMutation(result) : setMappedInputResult(result));
    }
  }, [saveItemTemplateMutation, csvRows, autoImport]);

  const addError = useCallback(
    (err: unknown) => {
      setErrors((existingErrors) => existingErrors.concat(String(err)));
    },
    [setErrors],
  );

  const handleOnDrop = useCallback(
    async (rows: CsvRow[], file?: { type: string; name: string }) => {
      if (!isCsv(file)) {
        return setErrors(["Incorrect file type. Please make sure the file is a .csv file"]);
      }
      // lets the user drop the same file again, otherwise nothing happens, with no feedback
      setImportDataReady(false);
      setCsvRows([]);
      setCsvRows(rows);
    },
    [setCsvRows, setImportDataReady],
  );

  const handleOnClick = useCallback(async () => {
    if (mappedInputResult?.kind === "success") {
      await saveItemTemplateMutation(mappedInputResult);
    }
  }, [saveItemTemplateMutation, mappedInputResult]);

  return { errors, addError, handleOnDrop, handleOnClick, importDataReady };
}

function stripEmptyString(value: any): any {
  if (typeof value === "string") {
    value = value.trim();
    return value === "" ? undefined : value;
  }
  return value;
}
