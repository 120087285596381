import { Css, Tag, useBreakpoint, useTestIds } from "@homebound/beam";
import { BillPageV2_BillFragment } from "src/generated/graphql-types";
import { useApprovalSuperDrawer } from "src/routes/components/Approval/ApprovalSuperDrawer";
import { approvalStatusToTagTypeMapper } from "src/utils";

export const BillApproval = ({ bill }: { bill: BillPageV2_BillFragment }) => {
  const openApproval = useApprovalSuperDrawer();

  const { sm } = useBreakpoint();
  const tids = useTestIds({}, "billApproval");

  if (!bill.approval) {
    return null;
  }

  return (
    <div css={Css.df.fdc.w50.$}>
      <div css={Css.sm.gray700.mb1.$}>Approvals</div>

      <div css={Css.df.fdc.jcc.w100.mb1.br8.bgGray100.mh("4rem").$} key={bill.approval.id}>
        <div css={Css.dg.cg1.rg1.tiny.bgGray100.p2.aic.br8.if(sm).gtc("1fr").else.gtc("repeat(3, auto)").$}>
          <span {...tids.name} css={Css.blue700.cursor("pointer").$} onClick={() => openApproval(bill.approval?.id)}>
            {bill.name}
          </span>

          <div {...tids.approver} css={Css.df.fdr.$}>
            Approver &nbsp; <span css={Css.wbbw.$}>{bill.approval.approvers.first?.user.name}</span>
          </div>

          <div {...tids.status}>
            <Tag
              type={approvalStatusToTagTypeMapper(bill.approval)[0]}
              text={approvalStatusToTagTypeMapper(bill.approval)[1]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
