import { Css, StaticField, Tag, useBreakpoint, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import { billStatusTagMapper, getBillLabel } from "src/components/detailPane/bill/utils";
import { BillPage_BillFragment } from "src/generated/graphql-types";
import { isDefined } from "src/utils";

export function BillSectionHeader({ bill }: { bill: BillPage_BillFragment }) {
  const { sm } = useBreakpoint();
  const tid = useTestIds({});

  if (!bill?.id) return <LoadError />;
  const [type, text] = billStatusTagMapper(bill);
  const label = getBillLabel(bill, false);

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.my1.$}>
        <Tag type={type} text={text} />
      </div>
      <div css={Css.lgSb.mt2.df.if(sm).fdc.mb2.else.fdr.jcsb.$}>
        <div {...tid.tradePartnerNumber}>{label}</div>
        <Price valueInCents={bill.billedInCents} />
      </div>
      <div css={Css.xs.$} {...tid.tradeName}>
        {bill.tradePartner.name}
      </div>
      <BillParentList parents={bill.parents} />
      {isDefined(bill.intacctSyncError) && (
        <div css={Css.mb2.$}>
          <StaticField label="sync error" value={bill.intacctSyncError} />
        </div>
      )}
    </div>
  );
}
export function LoadError() {
  return (
    <>
      <span data-testid="loadError">Error loading bill or commitment</span>
    </>
  );
}

function BillParentList({ parents }: { parents: BillPage_BillFragment["parents"] }) {
  const tid = useTestIds({});
  return (
    <div css={Css.df.fdc.mb3.xs.$}>
      {parents.map((p) =>
        p.__typename === "CommitmentChangeOrder" ? (
          <Link to={p.blueprintUrl.path} key={p.id} {...tid.parent}>
            CO #{p.accountingNumber} - {p.name}
          </Link>
        ) : (
          <Link to={p.blueprintUrl.path} key={p.id} {...tid.parent}>
            PO #{p.accountingNumber} - {p.name}
          </Link>
        ),
      )}
    </div>
  );
}
