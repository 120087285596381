import { Css, Icon, Palette, useModal } from "@homebound/beam";
import { useKey } from "react-use";
import { GlobalSearchModal } from "src/routes/layout/GlobalSearchModal";

export function GlobalSearchBox() {
  const { openModal } = useModal();

  function open() {
    openModal({
      content: <GlobalSearchModal />,
      // Prevent the modal height by going up/down based on search results
      size: { height: 600, width: "lg" },
    });
  }

  // Look for command-o/control-o
  useKey(
    (event) => (event.metaKey || event.ctrlKey) && event.key === "o",
    (event) => {
      open();
      // Don't open the native file-open dialog
      event.preventDefault();
    },
    {},
    [],
  );

  const isMac = window.navigator.userAgent.indexOf("Mac") !== -1;
  return (
    // Use a one-off button to be slimmer and match the navbar ux
    <button
      type="button"
      onClick={open}
      // Use f1.fb.maxwPx to use "160px if available" with
      css={Css.df.fdr.fwnw.f1.fb("160px").maxwPx(160).aic.ba.bw1.mx2.pyPx(4).pxPx(8).bcGray500.br4.$}
    >
      <Icon inc={2} color={Palette.Gray500} icon="search" />
      <span /* center the placeholder within the remaining space */ css={Css.dg.pcc.w100.$}>
        <span css={Css.df.wsnw.gray500.mx1.xsMd.$}>{isMac ? "⌘ + O" : "Ctrl + O"}</span>
      </span>
    </button>
  );
}
