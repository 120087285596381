import {
  Css,
  Only,
  Padding,
  Properties,
  ScrollShadows,
  useDnDGridItem,
  useResponsiveGridItem,
  useTestIds,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import React, { useRef } from "react";
import { DashboardTileConfigInput } from "src/generated/graphql-types";
import { EditTileActions } from "src/routes/projects/dashboard/components/EditTileActions";
import { TileTitle } from "src/routes/projects/dashboard/components/TileTitle";
import { TileComponentConfig } from "src/routes/projects/dashboard/config";
import { useTileSize } from "src/routes/projects/dashboard/hooks/useTileSize";

export type TileProps<X> = TileBaseProps & {
  href?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  titleAdornment?: React.ReactNode;
  xss?: X;
};

// Defines the common styling and layout of a tile for consistency purposes. Handles displaying the title, actions, and its position within the Dashboard grid.
export function Tile<X extends Only<TileContentXss, X>>(props: TileProps<X>) {
  const { componentConfig, children, edit, href, actions, xss, titleAdornment, userDashboardConfig } = props;
  const { gridSpans } = useTileSize(userDashboardConfig.size);
  const { gridItemProps } = useResponsiveGridItem({ colSpan: gridSpans.columns });
  const cardStyles = {
    ...baseTileStyles,
    ...Css.gr(`span ${gridSpans.rows}`).$,
  };
  const tid = useTestIds(props, "tile");
  const itemRef = useRef(null);
  const { dragItemProps, dragHandleProps } = useDnDGridItem({ id: userDashboardConfig.tile.value, itemRef });

  return (
    <div css={cardStyles} ref={itemRef} {...dragItemProps} {...gridItemProps}>
      <TileTitle
        title={componentConfig.title}
        href={href}
        edit={edit}
        adornment={titleAdornment}
        actions={edit ? <EditTileActions {...props} dragHandleProps={dragHandleProps} /> : actions}
      />
      <ScrollShadows {...tid.content} xss={{ ...tileContentStyles, ...xss }}>
        {children}
      </ScrollShadows>
    </div>
  );
}

export type TileBaseProps = {
  userDashboardConfig: ObjectState<DashboardTileConfigInput>;
  edit: boolean;
  componentConfig: TileComponentConfig;
  removeTile: (config: ObjectState<DashboardTileConfigInput>) => void;
  // TODO: Support changing size and moving tiles.
  // updateSize: (config: ObjectState<DashboardTileConfig>, size: DashboardTileSize) => void;
};

export const baseTileStyles = Css.df.fdc.bgWhite.ba.bcGray200.bshBasic.br8.oh.onHover.bshHover.$;
export const tileContentStyles = Css.pxPx(20).pbPx(20).pt2.fg1.oa.h100.$;
type TileContentXss = Pick<Properties, Padding>;
