import { useEffect, useState } from "react";

/** Listens for the "visibilitychange" event and returns if the document is currently visible */
export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(isDocumentVisible());

  useEffect(() => {
    const onVisibilityChange = () => setIsVisible(isDocumentVisible());

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  return isVisible;
}

function isDocumentVisible() {
  return document.visibilityState === "visible";
}
