import {
  Button,
  Css,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  PageSettings,
  Pagination,
  ScrollableParent,
  Switch,
  column,
  multiFilter,
  selectColumn,
  simpleHeader,
  useComputed,
  useFilter,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { useEffect, useState } from "react";
import { SearchBox } from "src/components";
import {
  BidItemFilter,
  NamedFragment,
  Order,
  ReviewUnitBidScopeStep_BidPackageFragment,
  UnitBidMetaQuery,
  UnitBidPackageLineItemModalQuery,
  useSaveBidPackagesMutation,
  useUnitBidMetaQuery,
  useUnitBidPackageLineItemModalQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { TableActions } from "src/routes/layout/TableActions";
import { pageSchema, queryResult } from "src/utils";

export type UnitBidPackageLineItemParams = Omit<BidItemFilter, "search"> & PageSettings;

type UnitBidPackageLineItemModalProps = {
  costTypes: UnitBidMetaQuery["costTypes"];
  items: NamedFragment[];
  bidPackage: ReviewUnitBidScopeStep_BidPackageFragment;
};

export function UnitBidPackageLineItemModal({ bidPackage }: { bidPackage: ReviewUnitBidScopeStep_BidPackageFragment }) {
  // Fetch cost types and items (material category) for the bidItem filter options
  const metaQuery = useUnitBidMetaQuery({
    variables: {
      itemFilter: {
        // Ensure the material category filter only shows items with matching bp costcodes
        costCode: bidPackage.costCodes
          .map((cc) => cc.id)
          .unique()
          .compact(),
        version: [1, 2],
      },
    },
  });

  return queryResult(metaQuery, ({ costTypes, items }) => (
    <UnitBidPackageLineItemModalView costTypes={costTypes} items={items} bidPackage={bidPackage} />
  ));
}

export function UnitBidPackageLineItemModalView(props: UnitBidPackageLineItemModalProps) {
  const { costTypes, items, bidPackage } = props;
  const [pageSettings, setPageSettings] = useZodQueryString(pageSchema);
  const { closeModal } = useModal();
  const tableApi = useGridTableApi<Row>();
  const [saveBidPackages] = useSaveBidPackagesMutation({
    refetchQueries: ["ReviewUnitBidScopeStep_BidPackageGroup"],
  });
  const { setFilter, filter: bidItemsFilter } = useFilter<BidItemFilter>({
    filterDefs: filterDefs(costTypes, items),
  });
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [isUsedUpstream, setIsUsedUpstream] = useState<boolean>(true);

  const query = useUnitBidPackageLineItemModalQuery({
    variables: {
      filter: {
        bidPackageId: bidPackage.id,
        isUsedUpstream,
        bidItemsFilter: { ...bidItemsFilter, search: searchFilter },
      },
      order: { name: Order.Desc },
      offset: pageSettings.offset,
      first: pageSettings.limit,
    },
  });

  useEffect(() => {
    if (query.data?.potentialUnitBidItemsPage.bidItems.nonEmpty) {
      // initiate selection of line items that are already in the bid package
      const bplis = new Set(bidPackage.lineItems.map((li) => li.bidItem.id));
      query.data.potentialUnitBidItemsPage.bidItems.forEach((bi) => {
        if (bplis.has(bi.id)) {
          tableApi.selectRow(bi.id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data?.potentialUnitBidItemsPage.bidItems.nonEmpty]);

  const selectedBidItemIds = useComputed(() => tableApi.getSelectedRows("data").map((r) => r.id), [tableApi]);

  async function saveAndExit() {
    await saveBidPackages({
      variables: {
        input: [
          {
            id: bidPackage.id,
            lineItems: [
              // Persist line item ids that weren't deselected
              ...bidPackage.lineItems
                .filter((li) => selectedBidItemIds.includes(li.bidItem.id))
                .map((li) => ({
                  id: li.id,
                })),
              // Add line items that were newly selected
              ...selectedBidItemIds
                .filter((id) => !bidPackage.lineItems.some((li) => li.bidItem.id === id))
                .map((id) => ({
                  bidItemId: id,
                })),
            ],
          },
        ],
      },
    });
    closeModal();
  }

  return (
    <ScrollableParent xss={Css.h("calc(100% - 144px)").$}>
      <header css={Css.py4.bb.bw1.bcGray200.bgWhite.$}>
        <div css={Css.df.fdc.aic.jcc.$}>
          <h1 css={Css.xl3Bd.$}>{bidPackage.name}</h1>
          <div css={Css.py1.$}>You can toggle to view items used upstream by plans or design packages</div>
        </div>
      </header>
      <div css={Css.my1.mxPx(178).$}>
        <div css={Css.df.fdc.$}>
          <div css={Css.df.bb.bw1.bcGray200.pb2.pt1.mb3.$}>
            <Switch
              label="Show items used by upstream plan and design packages"
              selected={isUsedUpstream}
              onChange={setIsUsedUpstream}
            />
          </div>
          <TableActions>
            <div css={Css.df.fdr.cg1.$}>
              <Filters<BidItemFilter>
                filter={bidItemsFilter}
                filterDefs={filterDefs(costTypes, items)}
                onChange={setFilter}
                numberOfInlineFilters={8}
              />
            </div>
            <div>
              <SearchBox onSearch={setSearchFilter} clearable debounceDelayInMs={500} />
            </div>
          </TableActions>
        </div>
        <GridTable
          columns={columns}
          rows={createRows(query.data?.potentialUnitBidItemsPage.bidItems)}
          api={tableApi}
          style={{ bordered: true, allWhite: true }}
          stickyHeader
        />
        <Pagination
          page={[pageSettings, setPageSettings]}
          totalCount={query.data?.potentialUnitBidItemsPage.pageInfo.totalCount || 0}
        />
      </div>
      <div css={Css.fixed.bottom0.w100.px6.py3.bshHover.df.bgWhite.$}>
        <div css={Css.mla.$}>
          <Button label="Save & Return to Packages" onClick={saveAndExit} size="lg" />
        </div>
      </div>
    </ScrollableParent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = {
  kind: "data";
  data: { costCode: string | undefined; materialCode: string | undefined; materialVariantName: string; uom: string };
};
type Row = HeaderRow | DataRow;

function createRows(
  bidItems: UnitBidPackageLineItemModalQuery["potentialUnitBidItemsPage"]["bidItems"] | undefined,
): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...(bidItems || []).map((bi) => ({
      id: bi.id,
      kind: "data" as const,
      data: {
        costCode: bi.items[0].costCode.displayName,
        materialCode: bi.parentMaterialVariant?.code,
        materialVariantName: bi.parentMaterialVariant?.displayName ?? bi.displayName,
        uom: bi.unitOfMeasure.abbreviation,
        id: bi.id,
      },
    })),
  ];
}

const columns: GridColumn<Row>[] = [
  selectColumn<Row>(),
  column<Row>({ header: "Cost Code", data: (bi) => bi.costCode, w: 1 }),
  column<Row>({ header: "Name", data: (bi) => bi.materialVariantName, w: 1.5 }),
  column<Row>({ header: "Uom", data: (bi) => bi.uom, w: 0.5 }),
  column<Row>({ header: "Material code", data: (bi) => bi.materialCode, w: 1 }),
];

function filterDefs(costTypes: UnitBidMetaQuery["costTypes"], items: NamedFragment[]): FilterDefs<BidItemFilter> {
  return {
    costType: multiFilter({
      label: "Cost Type",
      options: costTypes,
      getOptionValue: (o) => o.code,
      getOptionLabel: (o) => o.name,
    }),
    items: multiFilter({
      label: "Material Category",
      options: items,
      getOptionValue: (o) => o.id,
      getOptionLabel: (o) => o.name,
    }),
  };
}
