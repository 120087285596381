import { Css, ToggleChipGroup } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { DayOfWeek, TimeOfDay, WarrantyTicketPageDetailsFragment } from "src/generated/graphql-types";
import { TicketFormInput } from "../WarrantyTicketPage";

export const daysOfWeek = [
  {
    label: "Monday",
    value: DayOfWeek.Monday,
  },
  {
    label: "Tuesday",
    value: DayOfWeek.Tuesday,
  },
  {
    label: "Wednesday",
    value: DayOfWeek.Wednesday,
  },
  {
    label: "Thursday",
    value: DayOfWeek.Thursday,
  },
  {
    label: "Friday",
    value: DayOfWeek.Friday,
  },
];

// Omitting "Evening" because clients assume it is after work hours
const timeOfDay = [
  {
    label: "Morning",
    value: TimeOfDay.Morning,
  },
  {
    label: "Afternoon",
    value: TimeOfDay.Afternoon,
  },
];

type WarrantyAvailabilitySectionProps = {
  warrantyTicket: WarrantyTicketPageDetailsFragment;
  formState: ObjectState<Partial<TicketFormInput>>;
};

export function WarrantyAvailabilitySection({ warrantyTicket, formState }: WarrantyAvailabilitySectionProps) {
  const onChipClick = (timeOfDay: string[], dayOfWeek: DayOfWeek) => {
    const mappedAvailability = timeOfDay.map((v) => ({ timeOfDay: v as TimeOfDay, dayOfWeek: dayOfWeek }));
    formState.availabilities.set([
      ...formState.availabilities.value.filter((v) => v.dayOfWeek !== dayOfWeek),
      ...mappedAvailability,
    ]);
    formState.availabilities.maybeAutoSave();
  };
  return (
    <Observer>
      {() => (
        <div css={Css.df.fdc.gap3.$}>
          <div css={Css.smBd.$}>Availability</div>
          {daysOfWeek.map((day) => (
            <div key={day.value} css={Css.df.jcsb.aic.$}>
              <div>{day.label}</div>
              <div css={Css.df.aic.pr1.$}>
                <ToggleChipGroup
                  options={timeOfDay}
                  onChange={(v) => onChipClick(v, day.value)}
                  label={day.label}
                  labelStyle="hidden"
                  values={
                    formState.availabilities.value
                      .filter((v) => v.dayOfWeek === day.value)
                      .map((v) => String(v.timeOfDay)) ?? []
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </Observer>
  );
}
