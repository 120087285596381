import {
  ButtonModal,
  Checkbox,
  Css,
  IconButton,
  Palette,
  RightPaneLayout,
  ScrollableContent,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { BryntumGantt, BryntumProjectModel, SubGrid } from "@homebound/schedules-v2-gantt";
import "@homebound/schedules-v2-gantt/dist/index.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSaveGanttScheduleTasksMutation, useScheduleGanttQuery } from "src/generated/graphql-types";
import { useDelayFlagModal } from "src/hooks/useDelayFlagModal";
import ErrorBoundary from "src/routes/projects/schedule-v2/ErrorBoundary";
import { ScheduleViewTabs } from "src/routes/projects/schedule-v2/ScheduleViewTabs";
import { renderToString } from "src/utils/renderToString";
import { TaskColorLegend } from "../components/TaskColorLegend";
import { useScheduleStore } from "../contexts/ScheduleStore";
import { ScheduleType } from "../table/ScheduleType";
import { dependencyTooltipMarkup, taskTooltipMarkup } from "./GanttTooltips";
import { SearchScheduleGantt } from "./SearchScheduleGantt";
import { ganttEventHandlers } from "./ganttEventHandlers";
import { ganttStyleOverrides } from "./ganttStyles";
import { calendarConfig, getScrollGanttTaskIntoViewById, taskMenuFeatureConfig } from "./ganttUtils";
import { prepareDataForGantt } from "./prepareDataForGantt";
import { useGanttMoreOptionsActions } from "./useGanttMoreOptionsActions";

type ScheduleGanttProps = {
  scheduleId: string;
  scheduleIsLocked: boolean;
  fullScreen?: boolean;
  toggleFullScreen?: () => void;
};

type GanttViews = {
  showCriticalPath: boolean;
  showBaseline: boolean;
  showTaskLabel: boolean;
  showPhaseGroupings: boolean;
};

export function ScheduleGantt({ scheduleId, scheduleIsLocked, fullScreen, toggleFullScreen }: ScheduleGanttProps) {
  const ganttRef = useRef<BryntumGantt>(null);
  const projectRef = useRef<BryntumProjectModel>(null);
  const {
    dispatch: scheduleStoreDispatch,
    scheduleState: {
      scheduleType,
      taskPaneState: { taskPaneId },
    },
  } = useScheduleStore();
  const { triggerNotice } = useSnackbar();
  const tids = useTestIds({}, "scheduleGantt");
  const { onCompleted } = useDelayFlagModal();

  const [saveScheduleTasks, { loading: saveTaskLoading }] = useSaveGanttScheduleTasksMutation({ onCompleted });

  const [scheduleViewState, setScheduleViewState] = useState<GanttViews>({
    showBaseline: false,
    showCriticalPath: false,
    showTaskLabel: false,
    showPhaseGroupings: true,
  });

  const { data, loading } = useScheduleGanttQuery({
    variables: { scheduleId },
    nextFetchPolicy: "cache-only",
  });

  const scheduleSetting = data?.schedule.scheduleSetting ?? undefined;

  const { taskData, dependencyData, originalTaskDataById } = useMemo(
    () => prepareDataForGantt(data, scheduleViewState.showPhaseGroupings),
    [data, scheduleViewState.showPhaseGroupings],
  );

  const scrollGanttTaskIntoViewById = getScrollGanttTaskIntoViewById({ ganttRef, projectRef });

  const isTemplate = scheduleType === ScheduleType.Template;
  const nameColumnWidth = 350;
  const buttonColumnWidth = 60;

  const { moreOptionsButtonColumnConfig } = useGanttMoreOptionsActions({
    scheduleIsLocked,
    buttonColumnWidth,
    originalTaskDataById,
    scheduleGanttQuery: data,
    scrollGanttTaskIntoViewById,
  });

  const eventHandlers = ganttEventHandlers({
    saveScheduleTasks,
    scheduleStoreDispatch,
    originalTaskDataById,
    triggerNotice,
    scheduleSetting,
  });

  const permanentGanttColumns = [
    { type: "name", width: nameColumnWidth },
    moreOptionsButtonColumnConfig,
    { type: "startdate", text: "Start Date", width: 100 },
    { type: "enddate", text: "End Date", width: 100 },
    { type: "duration", width: 70 },
  ];
  const {
    scheduleState: { delayFeatureFlagIsOn },
  } = useScheduleStore();

  useEffect(() => {
    // This is a workaround to an active issue brought up in the forum
    // https://forum.bryntum.com/viewtopic.php?p=109920#p109920
    if (ganttRef.current) {
      const subGrids = ganttRef.current.instance.subGrids as { locked: SubGrid; normal: SubGrid };
      subGrids.locked.width = nameColumnWidth + buttonColumnWidth;
    }
  });

  useEffect(() => {
    if (taskPaneId) void scrollGanttTaskIntoViewById(taskPaneId);
  }, [scrollGanttTaskIntoViewById, taskPaneId]);

  function viewButtonContent() {
    return (
      <div css={Css.dg.gap2.$}>
        {!isTemplate && (
          <Checkbox
            label="Show Plan"
            onChange={(val) => {
              setScheduleViewState((prevState) => ({
                ...prevState,
                showBaseline: val,
              }));
            }}
            selected={scheduleViewState.showBaseline}
          />
        )}
        <Checkbox
          label="Show Critical Path"
          selected={scheduleViewState.showCriticalPath}
          onChange={(val) => {
            setScheduleViewState((prevState) => ({
              ...prevState,
              showCriticalPath: val,
            }));
          }}
        />
        <Checkbox
          label="Task Labels"
          onChange={(val) => {
            setScheduleViewState((prevState) => ({
              ...prevState,
              showTaskLabel: val,
            }));
          }}
          selected={scheduleViewState.showTaskLabel}
        />
        <Checkbox
          label="Phases & Subphases"
          onChange={(val) => {
            setScheduleViewState((prevState) => ({
              ...prevState,
              showPhaseGroupings: val,
            }));
          }}
          selected={scheduleViewState.showPhaseGroupings}
        />
      </div>
    );
  }

  // helper loading function so that scheduleViewTabs can render before data has loaded
  function loadableContent() {
    if (loading || !data) return <div>Loading...</div>;
    return (
      <>
        <BryntumProjectModel
          ref={projectRef}
          calendars={[calendarConfig]}
          tasks={taskData}
          dependencies={dependencyData}
          calendar={calendarConfig.id as string}
        />
        <div css={{ ...Css.h100.$, ...ganttStyleOverrides.$ }} {...tids}>
          <BryntumGantt
            ref={ganttRef}
            project={projectRef}
            height={"calc(100% - 25px)"}
            viewPreset="weekAndDayLetter"
            cellEditFeature={{ disabled: true }}
            readOnly={saveTaskLoading || scheduleIsLocked}
            columns={permanentGanttColumns}
            taskMenuFeature={taskMenuFeatureConfig}
            taskResizeFeature={{ showExactResizePosition: true }}
            taskDragFeature={{ showExactDropPosition: true }}
            projectLinesFeature={{ disabled: true }}
            timeRangesFeature={{
              showCurrentTimeLine: {
                name: "Today",
              },
            }}
            rowReorderFeature={{ disabled: true }}
            dependenciesFeature={{
              tooltip: {
                getHtml: (data: unknown) => dependencyTooltipMarkup(data, originalTaskDataById),
              },
            }}
            features={{
              taskTooltip: {
                template: (data: unknown) =>
                  renderToString(taskTooltipMarkup(data, originalTaskDataById, delayFeatureFlagIsOn)),
              },
            }}
            criticalPathsFeature={{
              disabled: !scheduleViewState.showCriticalPath,
            }}
            baselinesFeature={{ disabled: !scheduleViewState.showBaseline }}
            labelsFeature={
              scheduleViewState.showTaskLabel
                ? {
                    right: {
                      field: "name",
                    },
                    disabled: false,
                  }
                : { right: { field: null }, disabled: true }
            }
            {...eventHandlers}
          />
          {!isTemplate && <TaskColorLegend title="Color legend:" />}
        </div>
      </>
    );
  }

  return (
    <ErrorBoundary>
      <>
        <div css={Css.df.jcsb.pt3.pb1.$}>
          <ScheduleViewTabs />
          <div css={Css.df.jcfe.aic.gap1.$}>
            <ButtonModal content={viewButtonContent()} trigger={{ label: "View" }} />
            <SearchScheduleGantt
              scheduleTasks={data?.scheduleTasks}
              scrollGanttTaskIntoViewById={scrollGanttTaskIntoViewById}
            />
            {toggleFullScreen && (
              <IconButton
                color={Palette.Gray900}
                contrast={true}
                icon={fullScreen ? "collapse" : "expand"}
                onClick={toggleFullScreen}
              />
            )}
          </div>
        </div>
        <ScrollableContent virtualized>
          <div css={Css.mr3.h100.$}>
            <RightPaneLayout>{loadableContent()}</RightPaneLayout>
          </div>
        </ScrollableContent>
      </>
    </ErrorBoundary>
  );
}
