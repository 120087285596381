import { BoundDateField, BoundSelectField, BoundTextAreaField, Button, Css } from "@homebound/beam";
import { AccountingNumber, FormActions, FormMode } from "src/components";
import { DetailItem, Details } from "src/components/contracts";
import { FormFileUploaderSection } from "src/components/contracts/FormFileUploaderSection";
import {
  CommitmentChangeOrderEditV2Fragment,
  CommitmentIdNameStageFragment,
  CommitmentProjectItemFragment,
  CommitmentStatus,
  DocumentEditorDetailFragment,
  DocumentType,
  Maybe,
  SaveCommitmentChangeOrderInput,
  useSaveCommitmentChangeOrderMutation,
} from "src/generated/graphql-types";
import { PageHeaderActions } from "src/routes/layout/PageHeader";
import {
  CommitmentEditorLineItem,
  CommitmentEditorLineItems,
  commitmentLineItemsFormConfig,
  mapCommitmentLineItemToForm,
  mapToSaveLineItemInput,
  projectItemToLineItem,
} from "src/routes/projects/commitments/CommitmentEditorLineItems";
import { accountingNumberRule } from "src/routes/projects/commitments/utils";
import { createChangeEventUrl } from "src/RouteUrls";
import { empty } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { ObjectConfig, ObjectState, required, useFormState } from "src/utils/formState";

type CommitmentChangeOrderEditorProps = {
  commitment: CommitmentIdNameStageFragment;
  changeOrder: Maybe<CommitmentChangeOrderEditV2Fragment>;
  initialProjectItems: CommitmentProjectItemFragment[];
  mode: FormMode;
  projectId: string;
  onEdit: () => void;
  onSave: (input: SaveCommitmentChangeOrderInput) => Promise<void>;
  onCancel: () => void;
  onDelete: () => Promise<void>;
};

export function CommitmentChangeOrderEditor(props: CommitmentChangeOrderEditorProps) {
  const { commitment, changeOrder, initialProjectItems, projectId, mode, onEdit, onSave, onCancel, onDelete } = props;
  // initial mode is read (as form actions still in play when creating a co)
  // overriding so we can have editable fields (auto save) for an existing co
  const readOnly = mode === "read" && !changeOrder;
  const [saveCoo] = useSaveCommitmentChangeOrderMutation();
  const canEditLineItems = changeOrder?.canEditLineItems.allowed;
  const formState = useFormState({
    config: formConfig,
    init: {
      input: changeOrder,
      map: (changeOrder) => mapToForm(changeOrder, commitment),
      ifUndefined: buildEmptyInput(commitment, initialProjectItems),
    },
    autoSave: async (co) => {
      // only auto save if the change order already exists
      if (changeOrder) {
        await saveCoo({ variables: { input: mapToInput(co.changedValue) } });
      }
    },
    readOnly,
  });

  return (
    <>
      {/* handling edit of existing change order via auto save*/}
      {!changeOrder && (
        <PageHeaderActions>
          <FormActions
            {...{ mode, onDelete, onCancel, onEdit, formState }}
            onSave={() => onSave(mapToInput(formState.value))}
          />
        </PageHeaderActions>
      )}
      <Details detailItems={createDetailItems(commitment, changeOrder, formState)} />
      <div css={Css.my3.$}>
        <CommitmentEditorLineItems
          projectId={projectId}
          formState={formState}
          projectStageId={commitment.projectStage.id}
          canEditLineItems={canEditLineItems}
          allowNegative
          stage={commitment.projectStage.stage.code}
          enableProductConfigPlan={commitment.projectStage.project.enableProductConfigPlan}
        />
      </div>

      {
        // Only show the file uploader option on Change Order creation
        // In edit mode, the price agreement upload option is enabled
        !changeOrder && (
          <FormFileUploaderSection
            documentFormState={formState.document}
            documentType={DocumentType.SubcontractChangeOrder}
            message="Upload Trade Change Order"
            {...{ projectId }}
          />
        )
      }
    </>
  );
}

function createDetailItems(
  commitment: CommitmentIdNameStageFragment,
  changeOrder: Maybe<CommitmentChangeOrderEditV2Fragment>,
  formState: FormState,
): DetailItem[] {
  const parentField = (
    <BoundSelectField
      field={formState.commitmentId}
      options={[commitment]}
      label="Associated Trade Subcontract"
      // Form field is always read only since users can never changed the associated Trade Subcontract.
      readOnly
    />
  );

  const changeEventField = changeOrder?.changeEvent && (
    <ChangeEventLink
      displayName={changeOrder.changeEvent.displayName}
      projectId={commitment.projectStage.project.id}
      changeEventId={changeOrder.changeEvent.id}
    />
  );

  const executionDateField = (
    <div css={Css.df.fdr.aic.$}>
      <BoundDateField field={formState.executionDate} format="long" helperText="The date the contract was executed" />
      <div css={Css.mtPx(4).ml1.$}>
        <Button
          label="clear"
          disabled={changeOrder && changeOrder.status !== CommitmentStatus.Draft}
          variant="tertiary"
          onClick={() => formState.executionDate.set(undefined)}
        />
      </div>
    </div>
  );
  const internalNoteField = <BoundTextAreaField field={formState.internalNote} />;
  return [
    { component: <AccountingNumber field={formState.accountingNumber} />, spanColumns: !changeOrder?.changeEvent },
    ...(changeOrder?.changeEvent ? [{ component: changeEventField }] : []),
    { component: parentField },
    { component: executionDateField },
    { component: internalNoteField, spanColumns: true },
  ];
}

type FormInput = Omit<SaveCommitmentChangeOrderInput, "documentId" | "lineItems" | "executionDate"> & {
  lineItems: Maybe<CommitmentEditorLineItem[]>;
  document?: DocumentEditorDetailFragment | null;
  executionDate?: Date | null;
};

type FormState = ObjectState<FormInput>;

const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  commitmentId: { type: "value", rules: [required] },
  executionDate: { type: "value" },
  internalNote: { type: "value" },
  document: { type: "value" },
  accountingNumber: { type: "value", rules: [accountingNumberRule] },
  lineItems: {
    type: "list",
    config: commitmentLineItemsFormConfig,
  },
};

function mapToForm(co: CommitmentChangeOrderEditV2Fragment, commitment: CommitmentIdNameStageFragment): FormInput {
  return {
    ...co,
    commitmentId: commitment.id,
    lineItems: mapCommitmentLineItemToForm(co.lineItems),
  };
}

function mapToInput(form: FormInput): SaveCommitmentChangeOrderInput {
  const { document, executionDate, lineItems, ...others } = form;
  return {
    documentId: document?.id,
    executionDate: executionDate && new DateOnly(executionDate),
    lineItems: mapToSaveLineItemInput(lineItems),
    ...others,
  };
}

function buildEmptyInput(
  commitment: CommitmentIdNameStageFragment,
  initialProjectItems: CommitmentProjectItemFragment[],
): FormInput {
  return {
    ...empty<FormInput>(),
    commitmentId: commitment.id,
    lineItems: initialProjectItems.map((pi) => projectItemToLineItem(pi)),
  };
}

type ChangeEventLinkProps = {
  displayName: string;
  projectId: string;
  changeEventId: string;
};

export function ChangeEventLink(props: ChangeEventLinkProps) {
  const { displayName, projectId, changeEventId } = props;

  return (
    <div>
      <div css={Css.mb1.$}>Change Event</div>
      <Button
        data-testid="changeEventLink"
        label={displayName}
        variant="text"
        onClick={createChangeEventUrl(projectId, changeEventId)}
      />
    </div>
  );
}

// Exported for CommitmentEditorLineItems
export type CommitmentChangeOrderEditorFormState = FormState;
