import { Button, ModalBody, ModalFooter, ModalHeader, TextField, useModal, useSnackbar } from "@homebound/beam";
import { useCallback, useState } from "react";
import { ProjectAdminFragment, useSetQbCustomerIdMutation } from "src/generated/graphql-types";

type QbIdModalProps = {
  project: ProjectAdminFragment;
};

export function QbIdModal({ project }: QbIdModalProps) {
  const { closeModal } = useModal();
  const { quickbooksCustomerId: existingQbId, id: projectId } = project;
  const [qbId, setQbId] = useState<string | undefined>(existingQbId ?? undefined);
  const { triggerNotice } = useSnackbar();
  const [qbCustomerIdMutation] = useSetQbCustomerIdMutation();

  const onSave = useCallback(
    async (qbId: string) => {
      await qbCustomerIdMutation({ variables: { projectId, quickbooksCustomerId: qbId } });

      triggerNotice({
        icon: "success",
        message: `${project.buildAddress.street1} (${project.id}) Quickbooks customer ID set to ${qbId || "null"}`,
      });
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerNotice, qbCustomerIdMutation, projectId],
  );

  return (
    <>
      <ModalHeader>Quickbooks Customer ID</ModalHeader>
      <ModalBody>
        <TextField label="Enter Quickbooks Customer ID" value={qbId} onChange={setQbId} />
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button
          disabled={qbId === existingQbId && qbId !== undefined}
          label="Save"
          onClick={async () => {
            await onSave(qbId!);
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
