import { BeamButtonProps, Button, Chips, ChipValue, Css, GridCellContent, Tag, TagType } from "@homebound/beam";
import get from "lodash/get";
import isString from "lodash/isString";
import { useState } from "react";
import { Link } from "react-router-dom";
import { TextHeader } from "src/components/tableHeaders";
import { Maybe } from "src/generated/graphql-types";
import { centsToDollars } from "src/utils";
import { calculateMarkup } from "src/utils/budget";
import { DateOnly } from "src/utils/dates";
import { formatDate, FormattedDate, FormattedDateProps, MarkupSummary, Percentage, Price, PriceProps } from "./";

export const emptyCellDash = "-";

// Header Cells
export function textHeader(text: string) {
  return <TextHeader {...{ text }} />;
}

export function linkHeader(text: string, pathname: string): GridCellContent {
  return {
    content: () => <Link to={{ pathname }}>{textHeader(text)}</Link>,
    sortValue: Number(text) || text, // String-sort will put 113 ahead of 12 so if this is number-able, do it
    value: text,
  };
}

// Body cells - All should support an `emptyCell`/`&mdash;` fallback for null/undefined/empty string values. "0" should be shown
export function priceCell(props: PriceProps & { alignment?: "left" | "right"; onClick?: () => void }): GridCellContent {
  const { alignment = "right", onClick, ...priceProps } = props;
  return {
    alignment,
    content: () =>
      // Only include the Link if there is a value for price. The link on the cell has been mostly used for the Budget table, where it doesn't make sense to have a link.
      onClick && typeof props.valueInCents === "number" ? (
        <button {...{ onClick }} css={Css.blue700.$}>
          <Price {...priceProps} />
        </button>
      ) : (
        <Price {...priceProps} />
      ),
    value: () => {
      const prefix = !props.valueInCents ? "" : props.valueInCents < 0 ? "-$" : props.displayDirection ? "+$" : "$";
      return prefix + centsToDollars(Math.abs(props.valueInCents ?? 0));
    },
    sortValue: props.valueInCents,
  };
}

export function dateCell(
  date: Date | DateOnly | undefined | null,
  props: Pick<FormattedDateProps<any>, "dateFormatStyle" | "timeFormatStyle" | "xss"> = {},
) {
  return {
    content: () => <FormattedDate date={date || undefined} {...props} mdash />,
    value: date ? formatDate(date) : "",
    sortValue: date?.valueOf() || 0,
  };
}

export function chipCell(
  values: string[] | ChipValue[],
  displayLimit?: number,
  onMoreClick?: BeamButtonProps["onClick"],
  hideTooltips?: boolean,
): GridCellContent {
  // Normalize to ChipValues
  const chipValues: ChipValue[] = (
    values.length > 0 && isString(values[0])
      ? values.map((v) => ({ text: v as string, title: hideTooltips ? "" : (v as string) }))
      : (values as ChipValue[])
  ).uniqueByKey("text");

  function ChipCellContent() {
    const [showAll, setShowAll] = useState(false);
    const totalChips = chipValues.uniqueByKey("text");
    const limitedChips = displayLimit && !showAll ? totalChips.slice(0, displayLimit) : totalChips;
    const hiddenChips = totalChips.length - limitedChips.length;

    return chipValues.length > 0 ? (
      <div css={Css.df.fdc.gapPx(5).$}>
        <Chips values={limitedChips} xss={Css.mt0.$} />
        {hiddenChips > 0 && !showAll && (
          <Button
            variant="text"
            label={`+${hiddenChips} more`}
            onClick={onMoreClick ? onMoreClick : () => setShowAll(true)}
          />
        )}
      </div>
    ) : (
      <>{emptyCellDash}</>
    );
  }

  return {
    alignment: "left" as const,
    content: () => <ChipCellContent />,
    value: chipValues.map((v) => `${get(v, ["text"])},${get(v, ["title"])}`).join(),
  };
}

export function percentageCell(percent: Maybe<number>, id?: string): GridCellContent {
  return { alignment: "right" as const, content: () => <Percentage {...{ percent, id }} />, value: percent ?? 0 };
}

export function markupCell(budget: number, price: number): GridCellContent {
  const markup = calculateMarkup(budget, price);
  return {
    alignment: "right" as const,
    content: () => <MarkupSummary {...{ budget, price }} />,
    value: `${markup.markupAmount < 0 ? "-$" : "+$"}${centsToDollars(Math.abs(markup.markupAmount))} (${
      markup.markupPercentage
    }%)`,
    sortValue: markup.markupAmount,
  };
}

export function yesNoCell(value: boolean): GridCellContent {
  return { content: () => (value ? "Yes" : "No") };
}

export function tagCell(type: TagType, text: string): GridCellContent {
  return {
    content: () => (
      <div>
        <Tag type={type} text={text} />
      </div>
    ),
    value: text,
  };
}

export function preserveLinesCell(value: string): GridCellContent {
  const formattedText = value.split("\n\n").map((p, i) => (
    <p key={i} css={Css.mb2.$}>
      {p.split("\n").map((sentence, j) => (
        <span key={j}>
          {sentence}
          <br />
        </span>
      ))}
    </p>
  ));
  return { alignment: "left" as const, content: value.length > 0 ? <div>{formattedText}</div> : emptyCellDash, value };
}

// Footer/Total cells -
export function priceTotal(props: PriceProps): GridCellContent {
  return {
    alignment: "right",
    content: () => <Price dropZero {...props} />,
    value:
      `${!props.valueInCents ? "" : props.valueInCents < 0 ? "-$" : "+$"}` +
      centsToDollars(Math.abs(props.valueInCents ?? 0)),
    sortValue: props.valueInCents,
  };
}
