import { Button, Css, useTestIds } from "@homebound/beam";
import { useParams } from "react-router";
import { ProgressPill } from "src/components";
import { LotSummaryPrpcFragment, Maybe, useLotSummaryQuery } from "src/generated/graphql-types";
import { ProjectParams } from "src/routes/routesDef";
import { queryResult } from "src/utils";

export type LotSummaryProps = {
  border?: boolean;
  reapplyTemplate?: VoidFunction;
};

export function LotSummary({ border = false, reapplyTemplate }: LotSummaryProps) {
  const { projectId } = useParams<ProjectParams>();
  const query = useLotSummaryQuery({ variables: { projectId }, skip: !projectId });

  return (
    <article data-testid="lotSummary" css={Css.bgWhite.p3.br8.wPx(330).mxa.if(border).ba.bcGray300.$}>
      {queryResult(query, ({ project }) => (
        <LotSummaryPanel
          title={`${project.name} Lot Summary`}
          prpc={project.readyPlanConfig}
          percentReleased={project.stage.percentReleased}
          reapplyTemplate={reapplyTemplate}
        />
      ))}
    </article>
  );
}

export type LotSummaryPanelProps = {
  prpc: Maybe<LotSummaryPrpcFragment>;
  percentReleased: number;
  title: string;
  reapplyTemplate?: VoidFunction;
};

export function LotSummaryPanel(props: LotSummaryPanelProps) {
  const testIds = useTestIds({});
  const { prpc, percentReleased, title, reapplyTemplate } = props;
  const otherOptions = prpc?.options.map((prpco) => prpco.readyPlanOption).filter((rpo) => !rpo.type.isElevation) ?? [];

  return (
    <>
      <section css={Css.df.jcsb.aic.bb.bw("0px").bcGray200.pb2.$}>
        <div {...testIds.title} css={Css.w50.base.$}>
          {title}
        </div>
        <ProgressPill progress={percentReleased} text="Released" stacked />
      </section>
      <div css={Css.gray200.bt.hPx(1).$} />
      <section>
        <div css={Css.my2.df.$} {...testIds.selectedPlan}>
          <div css={Css.$}>
            <span css={Css.fw7.$}> Plan: </span>
            {prpc?.readyPlan
              ? `${prpc.readyPlan.name}${
                  prpc.elevationOption?.readyPlanOption
                    ? ` - ${prpc.elevationOption.readyPlanOption.maybeShortName}`
                    : ""
                }, ${prpc.specOption?.readyPlanOption.name ?? "NA"}`
              : "not selected."}
          </div>
        </div>
        <div {...testIds.selectedOptions}>
          <div>
            <span css={Css.fw7.$}>Options:</span> {otherOptions?.isEmpty && "not selected."}
          </div>
          <div css={Css.mb2.$}>
            {otherOptions?.map((option) => (
              <div key={option.id} css={Css.pt1.df.$}>
                <div css={Css.tal.$}>{option.displayName}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {reapplyTemplate && (
        <Button label="Re-Apply Templates" variant="primary" onClick={async () => await reapplyTemplate()} />
      )}
    </>
  );
}
