import { Button, Css, ModalBody, ModalFooter, ModalHeader, NumberField, useModal, useSnackbar } from "@homebound/beam";
import { useMemo, useState } from "react";
import { Price, emptyCellDash } from "src/components";
import { BidContractLineItem, useSaveBidContractLineItemsMutation } from "src/generated/graphql-types";
import { fail, formatCentsToPrice, pluralize, sum } from "src/utils";

type PriceAdjustModalProps = {
  bclis: Pick<BidContractLineItem, "id" | "totalCostInCents">[];
  bidContractRevisionId: string;
};

export function PriceAdjustModal({ bclis, bidContractRevisionId }: PriceAdjustModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice, closeNotice } = useSnackbar();
  const [saveBidContractLineItems, { loading: mutationInProgress }] = useSaveBidContractLineItemsMutation();
  const [dollarAmountInCents, setDollarAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const costChangeInCents = useMemo(() => {
    if (dollarAmountInCents !== 0) return bclis.length * dollarAmountInCents;
    if (percentage !== 0) return bclis.map((d) => d.totalCostInCents * (percentage / 100)).reduce(sum, 0);
    return 0;
  }, [dollarAmountInCents, percentage, bclis]);

  return (
    <>
      <ModalHeader>Cost Change Calculator</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap4.$}>
          <div css={Css.baseMd.$}>
            Change the cost of {bclis.length} selected {pluralize(bclis, "item")} by:
          </div>
          <div css={Css.df.jcsb.aife.gap2.$}>
            <NumberField
              label="Dollars"
              type="cents"
              value={dollarAmountInCents}
              onChange={(e) => setDollarAmount(e ?? 0)}
              disabled={percentage !== 0}
              clearable
            />
            <div css={Css.pb1.baseMd.$}>or</div>
            <NumberField
              label="Percentage"
              type="percent"
              value={percentage}
              numFractionDigits={2}
              onChange={(e) => setPercentage(e ?? 0)}
              disabled={dollarAmountInCents !== 0}
              clearable
            />
          </div>
          <div>
            <label css={Css.db.sm.gray700.mbPx(4).$}>Cost Change:</label>
            <div data-testid="costChange">
              {costChangeInCents ? <Price valueInCents={costChangeInCents} displayDirection /> : emptyCellDash}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" onClick={closeModal} variant="tertiary" disabled={mutationInProgress} />
        <Button
          label="Apply Change"
          disabled={!(dollarAmountInCents || percentage) || !bclis.length || mutationInProgress}
          onClick={async () => {
            await saveBidContractLineItems({
              variables: {
                bidContractRevisionId,
                lineItems: bclis.map((d) => ({
                  id: d.id,
                  totalCostInCents: Math.floor(
                    dollarAmountInCents
                      ? d.totalCostInCents + dollarAmountInCents
                      : percentage
                        ? d.totalCostInCents * (1 + percentage / 100)
                        : fail("saveBidContractLineItems called without any markups"),
                  ),
                })),
              },
            });

            triggerNotice({
              icon: "success",
              id: "revertable-cost-change-notice",
              message: `Item cost successfully ${
                (dollarAmountInCents || percentage) > 0 ? "increased" : "decreased"
              } by ${
                dollarAmountInCents
                  ? `${formatCentsToPrice(dollarAmountInCents, true, undefined, true)}`
                  : `${Math.abs(percentage)}%`
              } for ${bclis.length} ${pluralize(bclis, "item")}`,
              persistent: true,
              action: {
                label: "Undo",
                variant: "tertiary",
                onClick: async () => {
                  await saveBidContractLineItems({
                    variables: {
                      bidContractRevisionId,
                      lineItems: bclis.map(({ id, totalCostInCents }) => ({ id, totalCostInCents })),
                    },
                  });
                  closeNotice("revertable-cost-change-notice");
                },
              },
            });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
