import { ButtonMenu, MenuItem, useModal } from "@homebound/beam";
import { useHistory } from "react-router";
import { createDevelopmentReviewTemplatesUrl } from "src/RouteUrls";
import {
  DevelopmentScopeTemplateFragment,
  ItemTemplateStatus,
  useCreateNewItemTemplateVersionsMutation,
  useDeleteItemTemplatesMutation,
} from "src/generated/graphql-types";
import { ArchiveItemTemplatesModal } from "src/routes/developments/templates/components/ArchiveItemTemplatesModal";
import { RenderMode } from "../review-and-publish/components/utils";
import { AddItemTemplatesReasonCodesModal } from "./AddItemTemplatesReasonCodesModal";

type DevelopmentScopeTemplateButtonMenuProps = {
  developmentId: string;
  itemTemplates: Pick<DevelopmentScopeTemplateFragment, "id" | "statusDetail" | "canCreateNewVersion">[];
  tableRefetch: () => void;
};

export function DevelopmentScopeTemplateButtonMenu(props: DevelopmentScopeTemplateButtonMenuProps) {
  const history = useHistory();
  const { developmentId, itemTemplates, tableRefetch } = props;
  const [createNewItemTemplateVersions] = useCreateNewItemTemplateVersionsMutation();
  const [deleteItemTemplates] = useDeleteItemTemplatesMutation();
  const { openModal } = useModal();
  const activeTemplates = itemTemplates.filter((it) => it.statusDetail?.code === ItemTemplateStatus.Active).nonEmpty;
  const draftTemplates = itemTemplates.filter((it) => it.statusDetail?.code === ItemTemplateStatus.Draft).nonEmpty;

  function cannotCreateNewVersions() {
    return !itemTemplates.every((it) => it.canCreateNewVersion.allowed) && "Active templates only";
  }

  function cannotEdit() {
    return (
      (activeTemplates && "Only draft templates can be edited") ||
      (itemTemplates.length < 2 && "Multiple templates must be selected")
    );
  }

  function cannotReviewAndPublish() {
    return !(draftTemplates && !activeTemplates) && showReviewAndPublishTooltip();
  }

  function cannotApplyToProjects() {
    return !(activeTemplates && !draftTemplates) && "Active templates only";
  }

  function canOpenMenuAction() {
    return itemTemplates.isEmpty;
  }

  function showReviewAndPublishTooltip() {
    return draftTemplates
      ? "Draft whole house and options templates must be selected together. Select the draft whole house temxwplate to Review & Publish"
      : "Selected templates have already been published";
  }

  function cannotDeleteDraftTemplates() {
    return !(draftTemplates && !activeTemplates) && "Draft templates only";
  }

  function maybeArchive() {
    return draftTemplates && "Active templates only";
  }

  const itemTemplatesIds = itemTemplates.map((it) => it.id);

  const menuItems: MenuItem[] = [
    {
      label: "Create New Versions",
      onClick: async () => {
        // Creates new versions of the selected templates
        await createNewItemTemplateVersions({
          variables: { templateIds: itemTemplates.map(({ id }) => id) },
        });
        tableRefetch();
      },
      disabled: cannotCreateNewVersions(),
    },
    {
      label: "Edit",
      onClick: async () => {
        history.push({
          pathname: createDevelopmentReviewTemplatesUrl(developmentId),
          state: { itemTemplatesIds, mode: RenderMode.edit, fromEditAction: true },
        });
      },
      disabled: cannotEdit(),
    },
    {
      label: "Review & Publish",
      onClick: () =>
        openModal({
          content: (
            <AddItemTemplatesReasonCodesModal
              itemTemplatesIds={itemTemplatesIds}
              reviewUrl={createDevelopmentReviewTemplatesUrl(developmentId)}
            />
          ),
        }),
      disabled: cannotReviewAndPublish(),
    },
    {
      label: "Apply to Lots",
      onClick: () => {
        history.push({
          pathname: createDevelopmentReviewTemplatesUrl(developmentId),
          state: { itemTemplatesIds: itemTemplates.map(({ id }) => id), mode: RenderMode.read, from: history.location },
        });
      },
      disabled: cannotApplyToProjects(),
    },
    {
      label: "Delete Drafts",
      onClick: async () => {
        // Deletes the selected templates
        await deleteItemTemplates({
          variables: { id: itemTemplates.map(({ id }) => id) },
        });
        // We refetch as another version of the template may have become 'latest'
        tableRefetch();
      },
      disabled: cannotDeleteDraftTemplates(),
    },
  ];

  return (
    <ButtonMenu
      trigger={{ label: "Actions" }}
      items={menuItems}
      persistentItems={[
        {
          label: "Archive",
          onClick: () =>
            openModal({
              content: <ArchiveItemTemplatesModal itemTemplates={itemTemplates} onSuccess={() => tableRefetch()} />,
            }),
          disabled: maybeArchive(),
        },
      ]}
      disabled={canOpenMenuAction()}
    />
  );
}
