import { BoundSelectField, BoundTextField, Css } from "@homebound/beam";
import { ProgressPill, emptyCellDash } from "src/components";
import {
  DocumentDetailDocumentTypeFragment,
  ProjectScheduleDocumentDetailFragment,
  SaveDocumentInput,
} from "src/generated/graphql-types";
import { ObjectConfig, useFormState } from "src/utils/formState";

type DocumentEditProps = {
  document: ProjectScheduleDocumentDetailFragment;
  documentTypes: DocumentDetailDocumentTypeFragment[];
  onSave: (input: SaveDocumentInput) => void;
};

export function DocumentEdit(props: DocumentEditProps) {
  const { document, documentTypes, onSave } = props;
  const formState = useFormState({
    config: formConfig,
    init: { input: document, map: mapToForm },
    autoSave: async (state) => await onSave(mapToInput(state.value)),
  });

  const docTypeOptions = documentTypes.filter((dt) => !dt.systemControlled);

  return (
    <div css={Css.df.pb1.aic.gap2.$}>
      <div css={Css.sm.$}>
        <BoundTextField borderless labelStyle="hidden" field={formState.name} />
      </div>
      <div>
        <BoundSelectField
          labelStyle="hidden"
          label="documentType"
          field={formState.documentType}
          unsetLabel={emptyCellDash}
          options={docTypeOptions}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ code }) => code}
        />
      </div>
      <ProgressPill hideProgress changeColorOnCompleted progress={100} />
      <div css={Css.sm.gray700.fb0.$}>Success</div>
    </div>
  );
}

function mapToForm(document: ProjectScheduleDocumentDetailFragment): FormValue {
  return {
    id: document.id,
    name: document.name,
    documentType: document.documentType.code,
  };
}

function mapToInput(formInput: FormValue): SaveDocumentInput {
  return {
    id: formInput.id,
    name: formInput.name,
    documentType: formInput.documentType,
  };
}

type FormValue = Pick<SaveDocumentInput, "id" | "name" | "documentType">;

const formConfig: ObjectConfig<FormValue> = {
  id: { type: "value" },
  name: { type: "value" },
  documentType: { type: "value" },
};
