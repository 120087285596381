import { Css, Margin, Only, Padding, Xss } from "@homebound/beam";
import { ReactNode } from "react";

type TableActionsXss = Xss<Margin | Padding>;

type TableActionsProps<X> = {
  xss?: X;
  children: ReactNode;
  onlyLeft?: boolean;
  onlyRight?: boolean;
};

/** Provides default spacing for Actions sitting above a table (Filters, Search, etc...) */
export function TableActions<X extends Only<TableActionsXss, X>>(props: TableActionsProps<X>) {
  const { xss, children, onlyLeft, onlyRight } = props;
  const alignmentStyles = onlyLeft ? Css.jcfs.$ : onlyRight ? Css.jcfe.$ : Css.jcsb.$;
  return <div css={{ ...Css.df.aic.pb2.gap1.if("sm").pb1.$, ...xss, ...alignmentStyles }}>{children}</div>;
}
