import {
  Button,
  ButtonMenu,
  Css,
  FilterDefs,
  Filters,
  GridDataRow,
  GridRowLookup,
  MenuItem,
  multiFilter,
  SuperDrawerContent,
  toggleFilter,
  useComputed,
  useGroupBy,
  useModal,
  usePersistedFilter,
  useSuperDrawer,
} from "@homebound/beam";
import { pascalCase } from "change-case";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRouteMatch } from "react-router";
import { matchPath, useHistory } from "react-router-dom";
import { SearchBox } from "src/components";
import { ArchivedTag } from "src/components/ArchivedTag";
import { baseDownloadUrl } from "src/context";
import { useFeatureFlags } from "src/contexts/FeatureFlags/FeatureFlagContext";
import {
  FeatureFlagType,
  ItemTemplateDetailFragment,
  ItemTemplateItemCostCodeFragment,
  ItemTemplateItemVersionFilter,
  ItemTemplateItemVersionOrder,
  ItemTemplateLineItemsTab_ReadyPlanOptionFragment,
  ItemTemplateStatus,
  ItemTemplateStatusDetail,
  ItivOrderField,
  Named,
  Order,
  ReadyPlanOption,
  useItemTemplateLineItemsTabQuery,
} from "src/generated/graphql-types";
import { AddScopeTemplateItemsModal } from "src/routes/developments/templates/components/AddScopeTemplateItemsModal";
import {
  DevelopmentOrPlanPackageParams,
  developmentPaths,
  ItemTemplateItemParams,
  itemTemplateItemPath,
  planPackageTakeoffsItemPath,
} from "src/routes/routesDef";
import { ImportItemTemplateItemsModal } from "src/routes/settings/itemTemplates/ImportItemTemplateItemsModal";
import {
  Column,
  ItemRow,
  ItemTemplateItemAllTable,
  ItemTemplateItemRows,
} from "src/routes/settings/itemTemplates/ItemTemplateItemAllTable";
import { ItemTemplateItemDetail } from "src/routes/settings/itemTemplates/ItemTemplateItemDetail";
import {
  createDevelopmentScopeTemplateItemUrl,
  createDevelopmentScopeTemplateUrl,
  createItemTemplateItemUrl as createGlobalItiUrl,
  createItemTemplateUrl as createGlobalItUrl,
  createPlanPackageTakeoffItemUrl,
  createPlanPackageTakeoffUrl,
} from "src/RouteUrls";
import { costTypeToNameMapper, safeEntries } from "src/utils";
import { ItivFilters, useItemTemplateItemVersionsQuery } from "src/utils/itemTemplateItem";
import { queryResult } from "src/utils/queryResult";
import { AddItemsFromExistingItemTemplateModal } from "./AddItemsFromExistingItemTemplateModal";
import { ItemTemplateApi, useItemTemplateApi } from "./api/ItemTemplateApi";
import { ItemTemplateChangesFilter } from "./changes/ItemTemplateChangesFilter";
import { ItemTemplateLineItemsBulkActionMenu } from "./ItemTemplateLineItemsBulkActionsMenu";

export type ItemTemplateItemTableMode = "global" | "development";

export type ItemTemplateLineItemsTabProps = {
  itemTemplateId: string;
  mode?: ItemTemplateItemTableMode;
  templateStatus: ItemTemplateStatusDetail;
  isVersioned?: boolean;
  filter?: ItemTemplateItemVersionFilter;
};

const PAGE_SIZE = 25;

/**
 * The "Line Items" tab of either v1 global templates or v2 development scope templates.
 */
export function ItemTemplateLineItemsTab(props: ItemTemplateLineItemsTabProps) {
  const { itemTemplateId } = props;
  const query = useItemTemplateLineItemsTabQuery({ variables: { id: itemTemplateId } });
  return queryResult(query, (data) => (
    <DataView
      {...props}
      template={data.itemTemplate}
      unitsOfMeasure={data.unitsOfMeasure}
      locations={data.locations}
      costCodes={data.costCodes}
    />
  ));
}

type DataViewProps = ItemTemplateLineItemsTabProps & {
  template: ItemTemplateDetailFragment;
  locations: Named[];
  unitsOfMeasure?: Named[];
  costCodes: ItemTemplateItemCostCodeFragment[];
};

function DataView(props: DataViewProps) {
  const {
    itemTemplateId,
    mode = "global",
    template,
    templateStatus,
    isVersioned,
    filter: externalFilter,
    ...filterData
  } = props;
  const { costCodes, locations, unitsOfMeasure } = filterData;
  const { options: allOptions = [] } = template.readyPlan ?? {};
  const [textFilter, setTextFilter] = useState<string>("");

  const [supportedElevations, supportedSpecOptions, supportedOptions] = useMemo(() => {
    const elevations = allOptions.filter((o) => o.type.isElevation).sortBy((o) => o.name);
    const specOptions = allOptions.filter((o) => o.type.isSpecLevel).sortBy((o) => o.name);
    const options = allOptions.filter((o) => !o.type.isElevation && !o.type.isSpecLevel).sortBy((o) => o.name);
    return [elevations, specOptions, options];
  }, [allOptions]);

  const { featureIsEnabled } = useFeatureFlags();
  const digitalBuildingExperimentsEnabled = featureIsEnabled(FeatureFlagType.DigitalBuildingExperiments);

  const filterDefs: FilterDefs<ItivFilters> = useMemo(
    () =>
      createFilterDefs(
        costCodes,
        locations,
        supportedElevations,
        supportedSpecOptions,
        supportedOptions,
        digitalBuildingExperimentsEnabled,
      ),
    [
      costCodes,
      locations,
      supportedElevations,
      supportedSpecOptions,
      supportedOptions,
      digitalBuildingExperimentsEnabled,
    ],
  );

  const groupBy = useGroupBy<GroupByKeys>({
    code: "Cost Code",
    baseOption: "Base / Option",
  });

  const { filter, setFilter } = usePersistedFilter<ItivFilters>({
    storageKey: "itemTemplateItemFilter",
    filterDefs,
  });

  const serverFilter = { ...(externalFilter ?? filter), search: textFilter };

  const [userRequestedOrder, setUserSortOrder] = useState<UserRequestedOrder>({
    column: ItivOrderField.Item,
    direction: Order.Asc,
  });
  const sortOrder = useComputed<ItemTemplateItemVersionOrder[]>(
    () => serverOrder({ userRequestedOrder, groupBy: groupBy.value }),
    [userRequestedOrder, groupBy.value],
  );

  const [itApi] = useItemTemplateApi({
    template: template,
    filter: serverFilter,
    order: sortOrder,
  });

  const {
    items: currentPageItems,
    groupTotals,
    loading,
    fetchMore,
  } = useItemTemplateItemVersionsQuery(serverFilter, sortOrder, PAGE_SIZE, {
    itemTemplateId,
    useFetchConfig: true,
    groupBy: groupBy.value,
  });

  const hiddenColumns = useMemo(() => {
    // Development mode currently hides the SelectionName column
    const hide =
      mode === "development"
        ? [Column.SelectionName]
        : [Column.BaseOption, Column.Elevation, Column.Actions, Column.SpecOption, Column.BidItem, Column.CostSource];
    if (isVersioned && templateStatus.code === ItemTemplateStatus.Active) {
      hide.push(Column.Select);
    }
    return hide;
  }, [mode, isVersioned, templateStatus.code]);

  const { rowLookup, addNewInDrawer, createItemTemplateItemUrl } = useItemTemplateItemSuperDrawer(
    itApi,
    supportedElevations,
    supportedSpecOptions,
    supportedOptions,
  );
  const addMenuItems = useAddMenuItems(itApi, template, mode, addNewInDrawer);

  const downloadButton = (
    <Button
      download
      label="Download"
      onClick={`${baseDownloadUrl()}/csv?type=itemTemplate&id=${itemTemplateId}`}
      variant="tertiary"
    />
  );

  return (
    <>
      <div css={Css.df.aic.jcsb.mb2.$}>
        <div css={Css.asfe.$}>
          {mode === "development" && (
            <Filters
              groupBy={groupBy}
              filterDefs={externalFilter ? {} : filterDefs}
              filter={externalFilter ?? filter}
              onChange={setFilter}
            />
          )}
        </div>
        <div css={Css.df.gap1.$}>
          {mode === "development" && isVersioned && downloadButton}
          {mode === "global" && downloadButton}
          <SearchBox onSearch={setTextFilter} debounceDelayInMs={500} />
          {!(mode === "development" && isVersioned && templateStatus?.code === ItemTemplateStatus.Active) && (
            <ItemTemplateLineItemsBulkActionMenu itApi={itApi} />
          )}
          <ButtonMenu items={addMenuItems} trigger={{ label: "Add Items" }} />
        </div>
      </div>
      <ItemTemplateItemAllTable
        itApi={itApi}
        items={currentPageItems ?? []}
        groupTotals={groupTotals ?? []}
        fetchGroupItems={fetchMore}
        selectedFilters={Object.keys(externalFilter ?? filter).nonEmpty || !!textFilter}
        unitsOfMeasure={unitsOfMeasure}
        locations={locations}
        createItemTemplateItemUrl={createItemTemplateItemUrl}
        rowLookup={rowLookup}
        hideColumns={hiddenColumns}
        elevations={supportedElevations}
        options={supportedOptions}
        specOptions={supportedSpecOptions}
        mode={mode}
        groupBy={groupBy.value}
        loading={loading}
        onSortOrderChange={setUserSortOrder}
        sortOrder={userRequestedOrder}
      />
    </>
  );
}

/**
 * Helper which encapsulates all the pieces needed to get the SuperDrawer working correctly
 * for both new and existing ItemTemplateItems.
 *
 * It is `path` aware of the two possible spots where this SuperDrawer is use:
 *   * Glabal Item Templates
 *   * Development Scope Templates
 */
function useItemTemplateItemSuperDrawer(
  itApi: ItemTemplateApi,
  elevations?: Pick<ReadyPlanOption, "id" | "name" | "shortName">[],
  specLevels?: Pick<ReadyPlanOption, "id" | "name">[],
  options?: Pick<ReadyPlanOption, "id" | "displayName">[],
) {
  const { openInDrawer, closeDrawer, isDrawerOpen } = useSuperDrawer();
  const rowLookup = useRef<GridRowLookup<ItemTemplateItemRows>>();
  const activeSuperDrawerRow = useRef<ItemRow>();
  const { push } = useHistory();

  const devOrPlanPackage = useRouteMatch<DevelopmentOrPlanPackageParams>();
  const potentialPaths = [developmentPaths.scopeTemplateItem, planPackageTakeoffsItemPath, itemTemplateItemPath];
  const itiMatch = useRouteMatch<ItemTemplateItemParams>(potentialPaths);

  const { developmentId, planPackageId } = devOrPlanPackage?.params;
  const itemTemplateItemId = itiMatch?.params.itemTemplateItemId;

  const createItemTemplateItemUrl = useCallback(
    (itiId: string) =>
      developmentId
        ? createDevelopmentScopeTemplateItemUrl(developmentId, itApi.templateId, itiId)
        : planPackageId
          ? createPlanPackageTakeoffItemUrl(planPackageId, itApi.templateId, itiId)
          : createGlobalItiUrl(itApi.templateId, itiId),
    [developmentId, planPackageId, itApi.templateId],
  );

  const createItemTemplateUrl = useCallback(
    (itId: string) =>
      developmentId
        ? createDevelopmentScopeTemplateUrl(developmentId, itId)
        : planPackageId
          ? createPlanPackageTakeoffUrl(planPackageId, itId)
          : createGlobalItUrl(itId),
    [developmentId, planPackageId],
  );

  const drawerShouldBeOpen = !!itemTemplateItemId;

  const openRow = useCallback(
    (row: GridDataRow<ItemTemplateItemRows>) => {
      if (activeSuperDrawerRow.current === row || row?.kind !== "item") {
        return;
      }
      activeSuperDrawerRow.current = row;
      const { prev, next } = rowLookup.current!.lookup(row)["item"];
      /**
       * Update URL with the prev/next itemTemplateItem id. We do not need to
       * explicity call openRow since the useEffect below watches for URL
       * changes and triggers a SuperDrawer update.
       */
      const handlePrevNextClick = (newRow: GridDataRow<ItemTemplateItemRows>) => {
        const url = `${createItemTemplateItemUrl(newRow.id)}${window.location.search}`;
        push(url);
      };
      openInDrawer({
        onPrevClick: prev && (() => handlePrevNextClick(prev)),
        onNextClick: next && (() => handlePrevNextClick(next)),
        content: (
          <SuperDrawerContent>
            <ItemTemplateItemDetail
              itApi={itApi}
              itemTemplateItem={row.data}
              elevations={elevations}
              specLevels={specLevels}
              options={options}
            />
          </SuperDrawerContent>
        ),
        onClose: () => {
          activeSuperDrawerRow.current = undefined;
          // Only redirect back to Item Templates page if we are still on the Item Template Item's Detail URL.
          // It is possible we could be redirecting outside of the page which caused the drawer to close.
          // If so, then do not redirect back to Item Templates page.
          if (
            matchPath(window.location.pathname, {
              path: potentialPaths,
            })
          ) {
            // When closing the SuperDrawer, remove /:itemTemplateItemId and retain query parameters
            push(`${createItemTemplateUrl(itApi.templateId)}${window.location.search}`);
          }
        },
      });
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowLookup, push, createItemTemplateItemUrl, createItemTemplateUrl, openInDrawer, closeDrawer],
  );

  const [rowLookupReady, setRowLookupReady] = useState(rowLookup.current !== undefined);

  function isRowLookupReady() {
    setRowLookupReady(rowLookup.current !== undefined);
    if (!rowLookupReady) {
      // Set a timeout to check again in a second
      setTimeout(isRowLookupReady, 1000);
    }
  }

  // Fix for making sure you actually open the super drawer when an ITIV is deep linked
  useEffect(isRowLookupReady, [isRowLookupReady]);

  useEffect(
    () => {
      if (itemTemplateItemId) {
        const row = rowLookup.current
          ?.currentList()
          .find((iti) => iti.kind === "item" && iti.id === itemTemplateItemId);
        if (row) {
          openRow(row);
        }
      } else if (isDrawerOpen) {
        closeDrawer();
      }

      return function unmountSuperDrawer() {
        !drawerShouldBeOpen && isDrawerOpen && closeDrawer();
      };
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemTemplateItemId, rowLookup, rowLookupReady, openRow],
  );

  const addNewInDrawer = useCallback(
    () =>
      openInDrawer({
        content: (
          <SuperDrawerContent>
            <ItemTemplateItemDetail itApi={itApi} itemTemplateItem={undefined} />
          </SuperDrawerContent>
        ),
      }),
    [openInDrawer, itApi],
  );

  return { rowLookup, addNewInDrawer, createItemTemplateItemUrl };
}

export type UserRequestedOrder = {
  column: ItivOrderField;
  direction: Order;
};

export function serverOrder(opts?: {
  userRequestedOrder?: UserRequestedOrder;
  groupBy?: GroupByKeys;
}): ItemTemplateItemVersionOrder[] {
  const {
    userRequestedOrder: { column, direction } = { column: ItivOrderField.Item, direction: Order.Asc },
    groupBy = GroupByKeys.code,
  } = opts ?? {};

  const defaultOrder: ItemTemplateItemVersionOrder[] = [
    { field: ItivOrderField.Item, direction: Order.Asc },
    { field: ItivOrderField.Elevation, direction: Order.Asc },
    { field: ItivOrderField.Option, direction: Order.Asc },
    // { field: ItivOrderField.SpecOption, direction: Order.Asc }, TODO: add once available on schema?
  ];
  const userOrder: ItemTemplateItemVersionOrder = {
    field: pascalCase(column) as ItivOrderField,
    direction,
  };
  const groupByOrder: ItemTemplateItemVersionOrder = {
    field: groupBy === GroupByKeys.code ? ItivOrderField.CostCode : ItivOrderField.Option,
    direction:
      // If the user has specified the order associated with our group by, use that direction
      (groupBy === GroupByKeys.code && userOrder.field === ItivOrderField.Item) ||
      (groupBy === GroupByKeys.baseOption && userOrder.field === ItivOrderField.Option)
        ? userOrder.direction
        : Order.Asc,
  };

  return [
    groupByOrder,
    // Put in the user order if it is different than the groupBy order
    ...(userOrder.field !== groupByOrder.field ? [userOrder] : []),
    ...defaultOrder.filter((o) => o.field !== userOrder.field && o.field !== groupByOrder.field),
  ];
}

/** Creates our Add menu items. */
function useAddMenuItems(
  itApi: ItemTemplateApi,
  template: ItemTemplateDetailFragment,
  mode: ItemTemplateItemTableMode,
  addNewInDrawer: () => void,
) {
  const { openModal } = useModal();
  // v1 and v2
  const fromImport: MenuItem = {
    label: "From Import",
    onClick: () => {
      openModal({
        content: <ImportItemTemplateItemsModal itApi={itApi} />,
      });
    },
  };

  // v1 only
  const newItemSuperDrawer: MenuItem = {
    label: "New",
    onClick: addNewInDrawer,
  };

  // v1 and v2
  const fromTemplate: MenuItem = {
    label: "From Template",
    onClick: () => {
      openModal({
        content: <AddItemsFromExistingItemTemplateModal itApi={itApi} template={template} />,
      });
    },
  };

  // v2 only
  const fromExistingWholeHouse: MenuItem = {
    label: "From Existing Ready Plan",
    onClick: () => {
      openModal({
        content: <AddItemsFromExistingItemTemplateModal itApi={itApi} template={template} isReadyHomeTemplate={true} />,
      });
    },
  };

  // v2
  const newItems: MenuItem = {
    label: "New Items",
    onClick: () => {
      openModal({
        content: <AddScopeTemplateItemsModal itApi={itApi} />,
        size: { width: "xxl", height: 800 },
      });
    },
  };

  const items =
    mode === "development"
      ? [fromTemplate, fromExistingWholeHouse, fromImport, newItems] // <- Whole house template menu items
      : [fromTemplate, fromImport, newItemSuperDrawer]; // <- Global template menu items

  return items.map((i) => ({ ...i, disabled: !template.canEditLineItems.allowed }));
}

function createFilterDefs(
  costCodes: ItemTemplateItemCostCodeFragment[],
  locations: Named[],
  supportedElevations: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
  supportedSpecOptions: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
  supportedOptions: ItemTemplateLineItemsTab_ReadyPlanOptionFragment[],
  digitalBuildingExperimentsEnabled: boolean,
): FilterDefs<ItivFilters> {
  const elevations = [
    { name: "All Elevations", shortName: undefined, id: null!, active: true },
    ...supportedElevations,
  ];
  const specOptions = [{ name: "All Levels", id: null!, active: true }, ...supportedSpecOptions];
  const options = [{ parentOption: null, id: null!, displayName: "Base", active: true }, ...supportedOptions];

  const location = multiFilter({
    options: locations,
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
  });

  const costType = multiFilter({
    options: safeEntries(costTypeToNameMapper),
    getOptionValue: ([code]) => code,
    getOptionLabel: ([_, name]) => name,
  });

  const costCode = multiFilter({
    options: costCodes,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ displayName }) => displayName,
  });

  const specOption = multiFilter({
    label: "Spec Level",
    options: specOptions,
    getOptionLabel: ({ name, active }) => `${name}${!active ? " (Archived)" : ""}`,
    getOptionValue: ({ id }) => id,
    getOptionMenuLabel: ({ name, active }) => <ArchivedTag active={active}>{name}</ArchivedTag>,
  });

  const elevation = multiFilter({
    label: "Elevation",
    options: elevations,
    getOptionLabel: ({ shortName, name, active }) =>
      shortName ? `${shortName} - ${name}${!active ? " (Archived)" : ""}` : `${name}${!active ? " (Archived)" : ""}`,
    getOptionValue: ({ id }) => id,
    getOptionMenuLabel: ({ shortName, name, active }) => (
      <ArchivedTag active={active}>{shortName ? `${shortName} - ${name}` : name}</ArchivedTag>
    ),
  });

  const option = multiFilter({
    label: "Option",
    options,
    getOptionLabel: ({ parentOption, displayName, active }) =>
      parentOption
        ? parentOption.shortName
          ? `${displayName} - ${parentOption.shortName} - ${parentOption.name}${!active ? " (Archived)" : ""}`
          : `${displayName} - ${parentOption.name}${!active ? " (Archived)" : ""}`
        : displayName,
    getOptionValue: ({ id }) => id,
    getOptionMenuLabel: ({ parentOption, displayName, active }) =>
      parentOption ? (
        parentOption.shortName ? (
          <ArchivedTag active={active}>
            {displayName} - {parentOption.shortName} - {parentOption.name}
          </ArchivedTag>
        ) : (
          <ArchivedTag active={active}>
            {displayName} - {parentOption.name}
          </ArchivedTag>
        )
      ) : (
        <ArchivedTag active={active}>{displayName}</ArchivedTag>
      ),
  });

  const hasAnomalies = toggleFilter({
    label: "Has Anomalies 🧪",
    defaultValue: undefined,
    onValue: true,
    offValue: undefined,
  });

  return {
    costCode,
    elevation,
    option,
    specOption,
    location,
    costType,
    ...(digitalBuildingExperimentsEnabled && {
      hasAnomalies,
      showChanges: (key) => new ItemTemplateChangesFilter(key, { label: "Show Changes" }),
    }),
  };
}

export enum GroupByKeys {
  code = "code",
  baseOption = "baseOption",
}
