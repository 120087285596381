import { BoundTextAreaField, BoundTextField, Button, Css, FormHeading, FormLines } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import { createInvoiceUrl } from "src/RouteUrls";
import { InvoiceNumber } from "src/components/formFields/InvoiceNumber";
import { StepActions, useStepperContext } from "src/components/stepper";
import {
  InvoiceProjectStageFragment,
  InvoiceV2Fragment,
  SaveInvoiceInput,
  useSaveInvoiceV2Mutation,
} from "src/generated/graphql-types";
import { useStripStage } from "src/hooks/useStripStage";
import { ExitIconButton } from "src/routes/developments/cost-mapping/components/ExitIconButton";
import { PageHeader } from "src/routes/layout/PageHeader";
import { useProjectContext } from "src/routes/projects/context/ProjectContext";
import { InvoiceStepEnum } from "../enums";

export type InvoiceSettingsProps = {
  draftInvoice?: InvoiceV2Fragment;
  onExit: () => void;
  projectStage: Pick<InvoiceProjectStageFragment, "id" | "stage"> | undefined;
  setDraftInvoice: Dispatch<SetStateAction<InvoiceV2Fragment | undefined>>;
};

export function InvoiceSettings({ draftInvoice, onExit, projectStage, setDraftInvoice }: InvoiceSettingsProps) {
  const history = useHistory();
  useStripStage();

  const [saveInvoiceV2] = useSaveInvoiceV2Mutation();
  const { clientNoun } = useProjectContext();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: draftInvoice,
      map: (draftInvoice) => {
        const { status, ...otherFields } = draftInvoice;
        return { ...otherFields };
      },
    },
  });

  const { nextStepEvenIfDisabled, setSteps } = useStepperContext();

  const onSave = async (andExit = false): Promise<void> => {
    const invoiceResult = await saveInvoiceV2({
      variables: {
        input: {
          ...formState.value,
          // when is editting a draft invoice use the currenct PS otherwise the pre selected PS
          projectStageId: draftInvoice?.projectStage.id ?? projectStage?.id,
        },
      },
    });
    if (andExit) {
      onExit();
    } else {
      const invoice = invoiceResult.data?.saveInvoice?.invoice;
      if (invoice) {
        setDraftInvoice(invoice);
        history.replace(createInvoiceUrl(invoice.project.id, invoice.id));
        setSteps((prevState) => {
          const currentIndex = prevState.findIndex((s) => s.value === InvoiceStepEnum.SETTINGS);
          return [
            ...prevState.slice(0, currentIndex),
            { ...prevState[currentIndex], state: "complete" },
            { ...prevState[currentIndex + 1], disabled: false },
            ...(currentIndex + 2 === prevState.length ? [] : prevState.slice(currentIndex + 2)),
          ];
        });

        nextStepEvenIfDisabled();
      }
    }
  };

  return (
    <>
      <PageHeader
        title="Set up your new invoice"
        breadcrumb={{
          label: "New Invoice",
          href: "#",
        }}
        right={
          <div css={Css.df.jcsb.gap4.$}>
            <ExitIconButton showModal={false} onCloseConfirmed={onExit} />
          </div>
        }
      />
      <FormLines>
        <FormHeading title="Details" />
        <BoundTextField field={formState.title} />
        <InvoiceNumber
          formState={formState}
          stage={projectStage?.stage.code ?? draftInvoice!.projectStage.stage.code}
          isHistoricalInvoiceNumber={draftInvoice?.isHistoricalInvoiceNumber}
          mode={draftInvoice?.id ? "update" : "create"}
        />

        <BoundTextAreaField
          field={formState.description}
          label={`Memo (optional)`}
          helperText={`This will be visible to the ${clientNoun.toLowerCase()}`}
        />
      </FormLines>

      <StepActions>
        <Observer>
          {() => (
            <div css={Css.df.gap2.$}>
              <Button
                disabled={!formState.valid}
                label="Save & Exit"
                variant="secondary"
                onClick={() => onSave(true)}
              />
              <Button disabled={!formState.valid} label="Continue" onClick={() => onSave(false)} />
            </div>
          )}
        </Observer>
      </StepActions>
    </>
  );
}

type InvoiceSettingsFormInput = Omit<SaveInvoiceInput, "lineItems" | "drawLineItems">;

const formConfig: ObjectConfig<InvoiceSettingsFormInput> = {
  id: { type: "value" },
  invoiceNumber: { type: "value" },
  creditForInvoiceId: { type: "value" },
  description: { type: "value" },
  title: { type: "value", rules: [required] },
};
