import { BaselineIntervalDetailsFragment, Maybe, ScheduleIntervalDetailsFragment } from "src/generated/graphql-types";

type DurationCellReadOnlyProps = {
  interval: Maybe<ScheduleIntervalDetailsFragment> | Maybe<BaselineIntervalDetailsFragment>;
  testId?: string;
};

export function DurationCellReadOnly({ interval, testId }: DurationCellReadOnlyProps) {
  const duration = interval?.durationInDays;
  return duration === undefined || duration === null ? null : (
    <div data-testid={testId}>{`${duration} day${duration === 1 ? "" : "s"}`}</div>
  );
}
