import { Css, useComputed, useSnackbar, useTestIds } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { Observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { createPlanPackageEditUrl, createPlanPackageUrl } from "src/RouteUrls";
import { useNavigationCheck } from "src/components";
import { useStepperContext } from "src/components/stepper";
import { StepLayout } from "src/components/stepper/StepLayout";
import { NextStepButton } from "src/components/stepper/useStepperWizard/NextStepButton";
import { UploaderProvider } from "src/contexts/UploaderContext";
import {
  ReadyPlanAssetType,
  ReadyPlanStatus,
  usePlanPackageDetailsStepQuery,
  useSavePlanPackageDetailsMutation,
} from "src/generated/graphql-types";
import { PlanPackageEditorHeader } from "src/routes/libraries/plan-package/stepper/components/PlanPackageEditorHeader";
import { PlanOverviewCard } from "./components/PlanOverviewCard";
import { planPackageDetailsConfig } from "./utils";

type PlanDetailsStepProps = {
  id: string | undefined;
  setStepDirty: (dirty: boolean) => void;
};

export function PlanDetailsStep({ id, setStepDirty }: PlanDetailsStepProps) {
  const { triggerNotice } = useSnackbar();
  const query = usePlanPackageDetailsStepQuery({ variables: { id: id! }, skip: !id });
  const [save] = useSavePlanPackageDetailsMutation();
  const history = useHistory();
  const { nextStepEvenIfDisabled } = useStepperContext();

  const formState = useFormState({
    config: planPackageDetailsConfig,
    init: {
      query,
      map: ({ planPackage }) => ({
        id: planPackage?.id,
        name: planPackage?.name,
        description: planPackage?.description,
        code: planPackage?.code ? Number(planPackage.code) : undefined,
        type: planPackage?.type.code,
        status: planPackage?.status.code,
        lotTypes: planPackage?.lotTypes,
        assets:
          planPackage?.assets?.map((readyPlanAsset) => ({
            ...readyPlanAsset,
            parentId: readyPlanAsset.parent?.id,
            type: readyPlanAsset.type.code as ReadyPlanAssetType,
          })) ?? [],
        markets: planPackage?.markets?.map((market) => market.id) ?? [],
      }),
    },
  });
  const isDirty = useComputed(() => formState.dirty, [formState.dirty]);
  useEffect(() => {
    setStepDirty(isDirty);
  }, [setStepDirty, isDirty]);
  const { useRegisterNavigationCheck } = useNavigationCheck();
  useRegisterNavigationCheck(() => !formState.dirty, [formState]);

  const tid = useTestIds({}, "planDetailsStep");

  const onSave = useCallback(async () => {
    if (formState.dirty) {
      const { code, ...otherValues } = formState.changedValue;
      const response = await save({
        variables: {
          input: {
            id,
            ...otherValues,
            ...(code && { code: code.toString().padStart(3, "0") }),
            ...(!id && { status: ReadyPlanStatus.Draft }),
            assets: [
              ...(formState.changedValue.assets?.map((readyPlanAsset) => ({
                ...readyPlanAsset,
                asset: { ...readyPlanAsset.asset, previewUrl: undefined },
              })) ?? []),
            ],
          },
        },
      });
      formState.commitChanges();
      triggerNotice({ message: `Your Plan has been updated!` });
      return response;
    }
  }, [formState, id, save, triggerNotice]);

  const onContinue = useCallback(async () => {
    const response = await onSave();
    if (!id && response?.data?.savePlanPackage.planPackage.id) {
      history.replace(createPlanPackageEditUrl(response.data.savePlanPackage.planPackage.id));
    }
    nextStepEvenIfDisabled();
  }, [nextStepEvenIfDisabled, onSave, id, history]);

  const onSaveAndExit = useCallback(async () => {
    const response = await onSave();
    const ppId = id ?? response?.data?.savePlanPackage.planPackage.id;
    history.push(createPlanPackageUrl(ppId));
  }, [history, id, onSave]);

  const getCoverPhoto = () =>
    formState.assets.value?.find((readyPlanAsset) => readyPlanAsset.type === ReadyPlanAssetType.CoverPhoto);

  const saveCoverPhoto = async (file: UppyFile) => {
    formState.assets.set([
      ...(formState.assets.value ?? []),
      {
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.data.size,
          previewUrl: file.meta.previewUrl as string,
        },
        parentId: id,
        type: ReadyPlanAssetType.CoverPhoto,
      },
    ]);
  };

  return (
    <Observer>
      {() => (
        <UploaderProvider>
          <StepLayout
            header={
              <PlanPackageEditorHeader
                title={id ? "Edit Plan" : "Create New Plan"}
                subtitle={
                  "A Plan is the the foundation of a product offering, and is where things like elevations, options and takeoffs are defined. Plans are later used to build Plan Offerings."
                }
              />
            }
            body={
              <div css={Css.py3.$}>
                <div css={Css.df.aic.gap1.jcc.$}>
                  <span css={Css.$} {...tid.titleSection}>
                    Name your new Plan and select the type and code.
                  </span>
                </div>
                <div css={Css.mt2.df.fww.ais.gap2.jcc.$} {...tid.sections}>
                  <PlanOverviewCard
                    formState={formState}
                    lotTypes={formState.lotTypes.value}
                    coverPhotoUrl={getCoverPhoto()?.asset?.previewUrl}
                    saveCoverPhoto={saveCoverPhoto}
                  />
                </div>
                <NextStepButton
                  label="Continue"
                  disabled={!formState.valid}
                  onClick={onContinue}
                  exitButton={{
                    variant: "secondary",
                    label: "Save & Exit",
                    onClick: onSaveAndExit,
                  }}
                />
              </div>
            }
          />
        </UploaderProvider>
      )}
    </Observer>
  );
}
