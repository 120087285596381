import { Css, TabContent, Tabs, TabWithContent, Tag, TagType } from "@homebound/beam";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PageHeadingDetails } from "src/components/Typography";
import {
  ItemTemplateStatus,
  useItemTemplatesDevelopmentsQuery,
  useItemTemplatesQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { IdOrAddParams } from "src/routes/routesDef";
import { ItemTemplateLineItemsTab } from "src/routes/settings/itemTemplates/ItemTemplateLineItemsTab";
import { addEntityParam, createItemTemplatesUrl } from "src/RouteUrls";
import { stageCodeToNameMapper } from "src/utils/mappers";
import { hasData, renderLoadingOrError } from "src/utils/queryResult";
import { ItemTemplateDetailsTab } from "./ItemTemplateDetailsTab";

/** This is called page, but we load it in a drawer. */
export function ItemTemplatePage() {
  const { idOrAdd } = useParams<IdOrAddParams>();
  const isNew = idOrAdd === addEntityParam;

  const [tab, setTab] = useState(isNew ? "details" : "lineItems");
  const query = useItemTemplatesQuery({ variables: { filter: { id: [idOrAdd!] } }, skip: isNew });

  const developmentsQuery = useItemTemplatesDevelopmentsQuery({ variables: { filter: {} } });

  if (!hasData(query) && !isNew) {
    return renderLoadingOrError(query);
  }

  const itemTemplate = query.data?.itemTemplates[0];
  // do not show developments in prod
  const developments = developmentsQuery.data?.developments;

  const tabs: TabWithContent[] = [
    {
      name: "Details",
      value: "details",
      render: () => <ItemTemplateDetailsTab developments={developments} itemTemplate={itemTemplate} />,
    },
    {
      value: "lineItems",
      name: "Line Items",
      disabled: isNew,
      render: () => (
        <ItemTemplateLineItemsTab itemTemplateId={itemTemplate!.id} templateStatus={itemTemplate!.statusDetail} />
      ),
    },
  ];
  return (
    <>
      <PageHeader
        title={isNew ? "Add Template" : itemTemplate!.name}
        breadcrumb={[{ label: "Specs & Selections", href: createItemTemplatesUrl() }]}
        left={
          itemTemplate && (
            <>
              <PageHeadingDetails>{stageCodeToNameMapper[itemTemplate.stage]}</PageHeadingDetails>
              <Tag type={statusTag[itemTemplate.status]} text={itemTemplate.status} />
            </>
          )
        }
      />
      <div css={Css.my2.$}>
        <Tabs tabs={tabs} selected={tab} onChange={setTab} />
      </div>
      <TabContent tabs={tabs} selected={tab} contentXss={Css.h100.$} />
    </>
  );
}

const statusTag: Record<ItemTemplateStatus, TagType> = {
  [ItemTemplateStatus.Draft]: "info",
  [ItemTemplateStatus.Archived]: "neutral",
  [ItemTemplateStatus.Active]: "success",
  [ItemTemplateStatus.SystemDraft]: "success",
};
