import { QueryResult } from "@apollo/client";
import { Global } from "@emotion/react";
import { Css, emptyCell, GridColumn, GridDataRow, GridTable, RowStyles, simpleHeader } from "@homebound/beam";
import { Fragment, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "src/components";
import {
  Maybe,
  ScheduleExportPageQuery,
  SchedulePhaseDetailsFragment,
  TaskScheduleDetailsFragment,
  useScheduleExportPageQuery,
} from "src/generated/graphql-types";
import { maybeFilterEmptySchedulePhasesAndSubPhases } from "src/routes/projects/schedule-v2/ProjectScheduleTableV2";
import { PhaseRow, SubPhaseRow, TaskRow } from "src/routes/projects/schedule-v2/table/utils";
import { isDefined, pluralize, renderLoadingOrError } from "src/utils";
import { DateOnly } from "src/utils/dates";
import { postProcessChildren, preProcessQueryResults, taskNumberMap } from "src/utils/schedules";
import { ArrayParam, BooleanParam, DateTimeParam, NumberParam, useQueryParam } from "use-query-params";
import { ScheduleWithGanttExportPdf } from "./ScheduleWithGanttExportPdf";

export function ScheduleExportPdf() {
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const [startDate] = useQueryParam("startDate", DateTimeParam);
  const [endDate] = useQueryParam("endDate", DateTimeParam);
  const [tradePartnerIds] = useQueryParam("tradePartnerIds", ArrayParam);
  const [scheduleSubPhaseIds] = useQueryParam("scheduleSubPhaseIds", ArrayParam);
  const [limit] = useQueryParam("limit", NumberParam);
  const [withGantt] = useQueryParam("withGantt", BooleanParam);

  const { data, loading, error } = useScheduleExportPageQuery({
    variables: {
      scheduleId,
      // TODO: @KoltonG look into why having no start and end date returns
      // significantly more results than placing the start and end as the edges
      // of the current active stage.
      startDate: new DateOnly(startDate!),
      endDate: new DateOnly(endDate!),
      tradePartnerIds: tradePartnerIds as Maybe<string[]>,
      scheduleSubPhaseIds: scheduleSubPhaseIds as Maybe<string[]>,
      limit: limit!,
    },
  });

  // Handle loading state
  if (loading || error) {
    return renderLoadingOrError({ loading, error } as QueryResult);
  }

  return withGantt ? (
    <ScheduleWithGanttExportPdf data={data} startDate={startDate!} endDate={endDate!} />
  ) : (
    <ScheduleExportPdfDataComponent data={data} />
  );
}

export function ScheduleExportPdfDataComponent({ data }: { data?: ScheduleExportPageQuery }) {
  const rows = createRows(data);
  const taskNumbers = taskNumberMap(rows);
  const columns = createColumns(taskNumbers);

  return (
    <Fragment>
      <Global styles={{ "@page": { size: "letter landscape" } }} />
      <GridTable as="table" columns={columns} rows={rows} rowStyles={createRowStyles()} />
    </Fragment>
  );
}

type HeaderRow = { kind: "header" };
type Row = HeaderRow | PhaseRow | SubPhaseRow | TaskRow;
export type ScheduleExportPdfRow = Row;

function maybeDisplayDurationInDays(duration?: number) {
  if (!isDefined(duration)) return null;
  return `${duration} ${pluralize(duration, "day")}`;
}

function createColumns(taskNumberMap: Record<string, number>): GridColumn<Row>[] {
  const idColumn: GridColumn<Row> = {
    header: () => <HeaderText>ID</HeaderText>,
    phase: emptyCell,
    subPhase: emptyCell,
    task: (row) => taskNumberMap[row.id],
  };
  const assigneeColumn: GridColumn<Row> = {
    header: () => <HeaderText>Assignee</HeaderText>,
    phase: emptyCell,
    subPhase: emptyCell,
    task: (row) => row.assignee?.name,
  };
  const nameColumn: GridColumn<Row> = {
    header: () => <HeaderText>Name</HeaderText>,
    phase: (row) => `Phase: ${row.name}`,
    subPhase: (row) => `SubPhase: ${row.name}`,
    task: (row) => row.name,
  };
  const durationColumn: GridColumn<Row> = {
    header: () => <HeaderText>Duration</HeaderText>,
    phase: (row) => maybeDisplayDurationInDays(row.interval?.durationInDays),
    subPhase: (row) => maybeDisplayDurationInDays(row.interval?.durationInDays),
    task: (row) => maybeDisplayDurationInDays(row.interval?.durationInDays),
  };
  const startDateColumn: GridColumn<Row> = {
    header: () => <HeaderText>Start Date</HeaderText>,
    phase: (row) => formatDate(row.interval?.startDate, "medium"),
    subPhase: (row) => formatDate(row.interval?.startDate, "medium"),
    task: (row) => formatDate(row.interval.startDate, "medium"),
    w: "115px",
  };
  const endDateColumn: GridColumn<Row> = {
    header: () => <HeaderText>End Date</HeaderText>,
    phase: (row) => formatDate(row.interval?.endDate, "medium"),
    subPhase: (row) => formatDate(row.interval?.endDate, "medium"),
    task: (row) => formatDate(row.interval.endDate, "medium"),
    w: "115px",
  };
  const tradePartnerColumn: GridColumn<Row> = {
    header: () => <HeaderText>Trade Partner</HeaderText>,
    phase: emptyCell,
    subPhase: emptyCell,
    task: (row) => row.tradePartner?.name,
  };

  return [idColumn, assigneeColumn, nameColumn, durationColumn, startDateColumn, endDateColumn, tradePartnerColumn];
}

function createRows(data?: ScheduleExportPageQuery): GridDataRow<Row>[] {
  // Match the "Phase sorted by Global Phase displayOrder" sorting of the list view
  const schedulePhases = data?.schedules[0].phases ?? [];
  const [phasesWithDisplayOrder, phasesWithoutDisplayOrder] = schedulePhases.partition((sp) =>
    isDefined(sp.globalPhase.displayOrder),
  );
  const sortedPhases = [
    ...phasesWithDisplayOrder.sortBy((phase) => phase.globalPhase.displayOrder!),
    ...phasesWithoutDisplayOrder,
  ];

  // Get all schedule phases and schedule sub phases in the correct order
  const schedulePhasesById = preProcessQueryResults(
    sortedPhases as SchedulePhaseDetailsFragment[],
    data?.scheduleTasks as TaskScheduleDetailsFragment[],
  );
  const schedulePhase = maybeFilterEmptySchedulePhasesAndSubPhases(schedulePhasesById, true).map((schedulePhaseRow) =>
    // sets the children of the schedulePhaseRow & scheduleSubPhasePhases
    postProcessChildren(schedulePhaseRow),
  );

  return [simpleHeader, ...schedulePhase];
}

function createRowStyles(): RowStyles<Row> {
  return {
    header: { cellCss: Css.p1.$ },
    phase: { cellCss: Css.bgGray200.p1.sm.$ },
    subPhase: { cellCss: Css.bgColor("rgba(245, 228, 205)").p1.sm.$ },
    task: { cellCss: Css.p1.sm.$ },
  };
}

function HeaderText({ children }: { children: ReactNode }) {
  return <div css={Css.sm.$}>{children}</div>;
}
