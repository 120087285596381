import {
  BoundSelectField,
  Button,
  Css,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  CloneItemTemplateInput,
  ItemTemplateStatus,
  Maybe,
  PotentialOperationDetailsFragment,
  useCopyItemTemplateMutation,
  useCopyLineItemsModalQuery,
} from "src/generated/graphql-types";

type CopyLineItemsModalButtonProps = {
  templateId: string;
  displayName: string;
  hasCloneToTargets: PotentialOperationDetailsFragment;
  developmentId: string;
  onSave: () => void;
};

export function CopyLineItemsModalButton(props: CopyLineItemsModalButtonProps) {
  const { openModal } = useModal();
  const { hasCloneToTargets } = props;
  return (
    <IconButton
      tooltip="Copy line items"
      icon="templates"
      onClick={() => openModal({ content: <CopyLineItemsModal {...props} /> })}
      disabled={hasCloneToTargets.allowed ? false : hasCloneToTargets.disabledReasons.map((dr) => dr.message).join(" ")}
    />
  );
}

export function CopyLineItemsModal(props: Omit<CopyLineItemsModalButtonProps, "hasCloneToTargets">) {
  const { templateId, displayName, onSave, developmentId } = props;
  const { closeModal } = useModal();
  const formState = useFormState({
    config,
    init: {
      onlyOnce: true,
      input: { original: templateId },
    },
  });
  const query = useCopyLineItemsModalQuery({
    variables: {
      filter: {
        status: [ItemTemplateStatus.Draft],
        development: [developmentId],
      },
    },
  });
  const [save] = useCopyItemTemplateMutation();

  const copyLineItems = useCallback(
    async (input: FormInput) => {
      await save({ variables: { input: mapToInput(input) } });
      onSave();
      closeModal();
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [save],
  );

  return (
    <>
      <ModalHeader>Copy line items</ModalHeader>
      <ModalBody>
        <p css={Css.sm.mb3.$}>
          You’re copying line items from <span css={Css.smMd.$}>{displayName}</span> to another destination Ready Plan.
          Any existing line items in the destination template will be replaced.
        </p>
        <BoundSelectField
          field={formState.cloneTo}
          options={query.data?.itemTemplates.filter((it) => it.id !== templateId) ?? []}
          label={`Select the destination draft Ready Plan`}
          getOptionValue={(o) => o.id}
          getOptionLabel={(o) => o.displayName}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" onClick={closeModal} label="Cancel" />
        <Observer>
          {() => (
            <Button
              label="Copy line items"
              onClick={() => copyLineItems(formState.value)}
              disabled={!(formState.valid && formState.dirty)}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Omit<CloneItemTemplateInput, "includeChildren"> & {
  includeChildren?: Maybe<boolean>;
};

const config: ObjectConfig<FormInput> = {
  original: { type: "value" },
  cloneTo: { type: "value", rules: [required] },
  includeChildren: { type: "value" },
};

function mapToInput(formValue: FormInput): CloneItemTemplateInput {
  const { includeChildren, ...others } = formValue;
  return {
    ...others,
  };
}
