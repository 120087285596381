import {
  Button,
  Css,
  DateField,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelectField,
  ToggleChips,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { format, isFuture, isSameDay } from "date-fns";
import { useCallback, useState } from "react";
import { formatDate } from "src/components";
import {
  DateOperation,
  JobLogDetailFragment,
  Market,
  useDuplicateJobLogsMutation,
  useJobLogsForGivenDateAndProjectsForCopyQuery,
} from "src/generated/graphql-types";
import { isEmpty, sortBy } from "src/utils";
import { DateOnly } from "src/utils/dates";

export type PasteJobLogType = "DifferentProject" | "SameProject";
export type ProjectsProps = { id: string; name: string; market?: Market }[];
export type PasteJobLogModalProps = {
  jobLog: JobLogDetailFragment;
  type: PasteJobLogType;
  refetchJobLogs: () => void;
};
export type ConfirmJobLogProps = {
  projectIds: string[];
  jobLog: JobLogDetailFragment;
  projects: ProjectsProps;
  projectsWithJobLogs: string[];
};

export function PasteJobLogModal(props: PasteJobLogModalProps) {
  const { jobLog, type, refetchJobLogs } = props;
  const isSameProject = type === "SameProject";
  const [duplicateJobLogs] = useDuplicateJobLogsMutation();
  const { data, loading } = useJobLogsForGivenDateAndProjectsForCopyQuery({
    variables: {
      logDate: {
        op: DateOperation.On,
        value: jobLog.logDate,
      },
      filter: { market: [jobLog.project.market.id] },
    },
    skip: isSameProject,
  });
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const { openModal, closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  // These projects have job log for the logDate
  // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const projectsWithJobLogsIds = data?.jobLogs.map((joblog) => joblog.project.id) || [];
  const projects = data?.projects.map((project) => ({ id: project.id, name: project.name })) || [];
  const projectDropDown = sortBy(
    projects.filter((project) => project.id !== jobLog.project.id),
    (project) => project.name,
  );
  // show N/A if the list if empty
  const showPlaceholder = !loading && (isEmpty(projects) || isEmpty(projectDropDown));

  const handlePasteJobLog = useCallback(async () => {
    if (isSameProject) {
      await duplicateJobLogs({
        variables: {
          duplicateJobLogs: {
            jobLogId: jobLog.id,
            projectIds: [jobLog.project.id],
            targetDates: selectedDays.map((d) => new DateOnly(d)),
          },
        },
      });
      triggerNotice({
        message: `Job log has been pasted to ${selectedDays.length} other days`,
      });
      closeModal();
      refetchJobLogs();
    } else {
      openModal({
        content: (
          <ConfirmModal
            data-testid="confirmModal"
            projectIds={selectedProjectIds}
            jobLog={jobLog}
            projects={projectDropDown}
            projectsWithJobLogs={projectsWithJobLogsIds}
          />
        ),
        onClose: refetchJobLogs,
      });
    }
  }, [
    closeModal,
    duplicateJobLogs,
    isSameProject,
    jobLog,
    openModal,
    projectDropDown,
    projectsWithJobLogsIds,
    refetchJobLogs,
    selectedDays,
    selectedProjectIds,
    triggerNotice,
  ]);

  return (
    <>
      <ModalHeader>Paste Jog Log {isSameProject ? "to Other Days" : "to Other Projects"}</ModalHeader>
      <ModalBody>
        <FormLines width="full">
          <div css={Css.gray700.$}>
            <b>Paste a job log</b>{" "}
            {isSameProject
              ? "from one day to another day within the same project."
              : "from one project to another project."}
          </div>
          <div css={Css.mt3.base.fwb.$}>{isSameProject ? "Days" : "Projects"}</div>
          <div css={Css.measureNarrow.$}>
            {isSameProject ? (
              <DateField
                label="Days"
                labelStyle="hidden"
                value={selectedDays?.last}
                disabledDays={(day) =>
                  [...selectedDays, jobLog.logDate].some((d) => isSameDay(d, day)) || isFuture(day)
                }
                onChange={(e) => setSelectedDays([...selectedDays, e!])}
              />
            ) : (
              <MultiSelectField
                label="Projects"
                labelStyle="hidden"
                values={selectedProjectIds}
                options={projectDropDown}
                onSelect={setSelectedProjectIds}
                placeholder={showPlaceholder ? "N/A" : ""}
                disabled={showPlaceholder}
              />
            )}
          </div>
          <ToggleChips
            values={selectedDays}
            getLabel={(day) => format(day, "d MMM")}
            onRemove={(day) => setSelectedDays(selectedDays.filter((d) => !isSameDay(d, day)))}
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button
          variant="primary"
          disabled={(isSameProject ? selectedDays : selectedProjectIds).isEmpty}
          label="Paste Job Log"
          onClick={handlePasteJobLog}
        />
      </ModalFooter>
    </>
  );
}
export function ConfirmModal(props: ConfirmJobLogProps) {
  const { projectIds, jobLog, projects, projectsWithJobLogs } = props;
  const { logDate } = jobLog;
  const [duplicateJobLogs] = useDuplicateJobLogsMutation();
  const { triggerNotice } = useSnackbar();
  function triggerCopyJobLogNotice(projectName: string, count: number) {
    triggerNotice({
      message: `Job log was successfully copied to ${count} project${count > 1 ? "s" : ""} from ${projectName}`,
    });
  }
  const { closeModal } = useModal();
  return (
    <>
      <ModalHeader>Confirm Pasting Job Log</ModalHeader>
      <ModalBody>
        <FormLines width="full">
          <div>
            {`Are you sure you want to paste the job log from ${formatDate(logDate, "medium")} in ${
              projectIds.length
            } other
            project${projectIds.length === 1 ? "" : "s"}?`}
          </div>
          <ul>
            {projectIds.map((projectId) => (
              <li key={projectId} css={Css.mb1.$}>
                <div css={Css.baseSb.$}>{projects.find((project) => project.id === projectId)?.name}</div>
                <div css={Css.sm.gray700.$}>
                  {projectsWithJobLogs.find((id) => id === projectId)
                    ? "Copy notes and images"
                    : "A new job log will be created"}
                </div>
              </li>
            ))}
          </ul>
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button label="Cancel" variant="tertiary" onClick={closeModal} />
        <Button
          variant="primary"
          label="Paste Job Log"
          onClick={async () => {
            await duplicateJobLogs({
              variables: { duplicateJobLogs: { jobLogId: jobLog.id, projectIds } },
            });
            triggerCopyJobLogNotice(jobLog.project.name, projectIds.length);
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
