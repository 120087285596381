export enum ReassignPurchaseOrdersStep {
  SELECT = "select",
  RELEASE = "release",
}

export enum ReassignPurchaseOrdersType {
  /** Used on the PurchaseOrdersPage to change trade partners for selected POs. */
  TRADE_PARTNER = "trade partner",
  /** Used on the PurchaseOrdersPage to change versions on very selected POs. */
  VERSION = "version",
  /** Used on the DevContractPage to change BCLIs for all POs on a contract. */
  MANAGE = "version management",
}
