import {
  Button,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useModal,
  UseModalHook,
} from "@homebound/beam";
import { useMemo, useState } from "react";
import { PageHeading, SearchBox } from "src/components";
import { TradeCategoryInfoFragment, useGetTradeCategoriesQuery } from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { TradeCategoriesEditor } from "./TradeCategoriesEditor";

export function TradeCategories() {
  const { data } = useGetTradeCategoriesQuery();
  const modal = useModal();
  const columns = useMemo(createColumns, []);
  const [filter, setFilter] = useState<string | undefined>();
  const rows: GridDataRow<Row>[] = useMemo(() => simpleDataRows(data?.tradeCategories || []), [data]);
  return (
    <div>
      <PageHeading>Trade Categories</PageHeading>
      <TableActions>
        <div />
        <div css={Css.df.jcfe.gap1.$}>
          <SearchBox onSearch={setFilter} />
          <Button
            label="Add new Trade Category"
            onClick={() => {
              modal.openModal({
                size: "sm",
                content: <TradeCategoriesEditor />,
              });
            }}
          />
        </div>
      </TableActions>
      <GridTable
        columns={columns}
        rows={rows}
        filter={filter}
        rowStyles={createRowStyles(modal)}
        sorting={{ on: "client" }}
      />
    </div>
  );
}

type Row = SimpleHeaderAndData<TradeCategoryInfoFragment>;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      header: "Code",
      data: (d) => d.number,
      w: "100px",
    }),
    column<Row>({
      header: "Name",
      data: (d) => d.name,
    }),
  ];
}

function createRowStyles(modal: UseModalHook): RowStyles<Row> {
  return {
    header: {},
    data: {
      onClick: (row) => {
        modal.openModal({
          size: "sm",
          content: <TradeCategoriesEditor tradeCategory={row.data} />,
        });
      },
    },
  };
}
