import {
  Css,
  GridColumn,
  GridTable,
  Pagination,
  SimpleHeaderAndData,
  column,
  dateColumn,
  simpleDataRows,
} from "@homebound/beam";
import { createProjectUrl } from "src/RouteUrls";

import { ProgressPill, dateCell } from "src/components";
import {
  FilteredProjectsTableFragment,
  ProjectFilter,
  useFilteredProjectsTableQuery,
} from "src/generated/graphql-types";
import useZodQueryString from "src/hooks/useZodQueryString";
import { z } from "zod";

type FilteredProjectsTableProps = {
  projectFilter: ProjectFilter;
};
export function FilteredProjectsTable({ projectFilter }: FilteredProjectsTableProps) {
  const [pageSettings, setPageSettings] = useZodQueryString(
    z.object({
      offset: z.coerce.number().int().default(0),
      limit: z.coerce.number().int().positive().default(10),
    }),
  );
  const query = useFilteredProjectsTableQuery({
    variables: {
      filter: projectFilter,
      page: pageSettings,
    },
  });
  const projectCount = query.data?.projectsPage.pageInfo.totalCount || 0;
  return (
    <div css={Css.pb2.$}>
      <GridTable
        columns={columns}
        rows={simpleDataRows(query.data?.projectsPage.entities || [])}
        fallbackMessage="No projects associated with this product offering."
        rowStyles={{
          data: {
            rowLink: ({ data }) => createProjectUrl(data.id),
          },
        }}
      />
      {projectCount > 9 && (
        <Pagination page={[pageSettings, setPageSettings]} totalCount={projectCount} pageSizes={[10, 25, 50]} />
      )}
    </div>
  );
}

type Row = SimpleHeaderAndData<FilteredProjectsTableFragment>;

const columns: GridColumn<Row>[] = [
  column<Row>({
    header: "Project",
    data: (data) => data.name,
  }),
  column<Row>({
    header: "Latest Stage",
    data: (data) => data.latestActiveStage?.stage.name,
  }),
  column<Row>({
    header: "Market",
    data: (data) => data.market.name,
  }),
  dateColumn<Row>({
    header: "Target End Date",
    data: (data) => {
      return dateCell(data.targetEndDate);
    },
  }),
  column<Row>({
    header: "Percent Bid Out",
    data: (data) => <ProgressPill progress={data.bidOutPercentage} changeColorOnCompleted />,
  }),
];
