import { useState } from "react";
import { DayOfWeek, DraftPlanTaskInput, ScheduleDraftMode_PlanTaskFragment } from "src/generated/graphql-types";

import {
  Button,
  CheckboxGroup,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";

type SetPlanTaskWorkingDaysModalProps = {
  planTask: ScheduleDraftMode_PlanTaskFragment;
  setDraftTaskChanges: (input: DraftPlanTaskInput[]) => void;
};

export function ScheduleDraftModePlanTaskSetDaysModal(props: SetPlanTaskWorkingDaysModalProps) {
  const {
    planTask: { id, name, customWorkableDays },
    setDraftTaskChanges,
  } = props;
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  const [customWorkableDaysOfWeek, setCustomWorkableDaysOfWeek] = useState(customWorkableDays);

  function onSave() {
    setDraftTaskChanges([{ id, customWorkableDaysOfWeek }]);
    triggerNotice({
      message: `${name} working days updated to ${customWorkableDaysOfWeek.join(", ")}`,
    });
    closeModal();
  }

  return (
    <>
      <ModalHeader>Scheduling Settings</ModalHeader>
      <ModalBody>
        <div>
          Choose which days you would like this task to be able to be scheduled on. By default, tasks can only be
          scheduled on weekdays.
        </div>
        <div css={Css.pt3.$}>
          <CheckboxGroup
            labelStyle="hidden"
            options={dayOfWeekOptions}
            label="Working Days"
            values={customWorkableDaysOfWeek}
            // NOTE: Beams CheckboxGroup is hardCoded to string[] so we need to cast the field to string[]
            onChange={(v) => setCustomWorkableDaysOfWeek(v as DayOfWeek[])}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="text" onClick={closeModal} label="Cancel" />
        <Button
          onClick={onSave}
          label="Save"
          disabled={customWorkableDaysOfWeek.length === 0 && "At least one working day required."}
        />
      </ModalFooter>
    </>
  );
}

const dayOfWeekOptions = [
  { value: DayOfWeek.Monday, label: "Monday" },
  { value: DayOfWeek.Tuesday, label: "Tuesday" },
  { value: DayOfWeek.Wednesday, label: "Wednesday" },
  { value: DayOfWeek.Thursday, label: "Thursday" },
  { value: DayOfWeek.Friday, label: "Friday" },
  { value: DayOfWeek.Saturday, label: "Saturday" },
  { value: DayOfWeek.Sunday, label: "Sunday" },
];
