import { IconButton, SelectField, SelectFieldProps, TextField, Value } from "@homebound/beam";
import { ReactNode, useState } from "react";
import { HasIdAndName } from "src/utils";

type SelectMaybeNewFieldProps<O extends HasIdAndName, V extends Value> = SelectFieldProps<O, V> & {
  /** Override options prop to remove the async OrLoad behavior */
  options: O[];
  onAdd: (value: string) => void;
  helperText?: string | ReactNode;
};

export function SelectMaybeNewField<O extends HasIdAndName, V extends Value>(props: SelectMaybeNewFieldProps<O, V>) {
  const [adding, setAdding] = useState(false);
  const [newOption, setNewOption] = useState<string | undefined>(undefined);
  const { onAdd, helperText, compact, options, onSelect, ...selectProps } = props;

  if (adding) {
    return (
      <div
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            setNewOption(undefined);
            setAdding(false);
          }
        }}
      >
        <TextField
          label={props.label}
          helperText={helperText}
          value={newOption}
          compact={compact}
          selectOnFocus={false}
          autoFocus
          onEnter={() => {
            if (newOption) {
              setNewOption(undefined);
              // Check if there is an existing option
              const existingOption = options.find((o) => o.name.toLowerCase() === newOption.toLowerCase());
              if (existingOption) {
                onSelect(existingOption.id as V, existingOption);
              } else {
                onAdd(newOption);
              }
            }
            setAdding(false);
          }}
          endAdornment={
            <IconButton
              icon="x"
              onClick={() => {
                onSelect(undefined, undefined);
                setNewOption(undefined);
                setAdding(false);
              }}
            />
          }
          onChange={(v) => {
            // Auto swap into select mode, if the user clears th text field
            if (!v) {
              onSelect(undefined, undefined);
              setAdding(false);
            }
            setNewOption(v);
          }}
        />
      </div>
    );
  }

  return (
    <SelectField
      {...selectProps}
      options={options}
      onSelect={onSelect}
      onAddNew={(v) => {
        setAdding(true);
        setNewOption(v);
      }}
      onSearch={(search) => {
        if (!search) return;
        // Auto swap into add mode if there are no matching options
        const filteredOptions = options.filter((o) => o.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
        if (filteredOptions.isEmpty) {
          setAdding(true);
          setNewOption(search);
        }
      }}
      compact={compact}
      autoFocus
    />
  );
}
