import { MultiSelectField } from "@homebound/beam";
import { useMemo } from "react";
import { InputMaybe, TaskAssigneeFragment } from "src/generated/graphql-types";

export type TaskAssigneeMultiSelectFieldProps = {
  options: TaskAssigneeFragment[];
  values: InputMaybe<string[]>;
  onSelect: (values: InputMaybe<string[]>) => void;
};

export function TaskAssigneeMultiSelectField({ options, values, onSelect }: TaskAssigneeMultiSelectFieldProps) {
  const assigneesWithUnassigned = useMemo(
    () => [{ id: unassignedFilterId, name: "Unassigned" }, ...options],
    [options],
  );
  const disabledAssignees = useMemo(
    () =>
      values?.includes(unassignedFilterId)
        ? options.map(({ id }) => ({ value: id, reason: "Filtering by 'Unassigned'" }))
        : [],
    [options, values],
  );

  return (
    <MultiSelectField
      onSelect={(val) => onSelect(val)}
      label="Assignee"
      nothingSelectedText="All"
      options={assigneesWithUnassigned}
      values={values ?? []}
      disabledOptions={disabledAssignees}
    />
  );
}

export const unassignedFilterId = "unassigned";
