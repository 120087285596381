import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { BidPackageDetailPage_BidContractRevisionFragment, NamedFragment } from "src/generated/graphql-types";
import { BillTermsTable } from "./BillTermsTable";

type BillTermsTabProps = {
  tradePartnerRevisions: {
    tradePartner: NamedFragment;
    revision: BidPackageDetailPage_BidContractRevisionFragment | undefined;
  }[];
  basePlanGlobalTasks: NamedFragment[];
};

export function BillTermsTab({ tradePartnerRevisions, basePlanGlobalTasks }: BillTermsTabProps) {
  const [selectedTab, setSelectedTab] = useState<string>(tradePartnerRevisions.first?.tradePartner.id ?? "");
  const tabs = tradePartnerRevisions.map(({ tradePartner, revision }) => ({
    name: tradePartner.name,
    value: tradePartner.id,
    render: () =>
      revision ? (
        <BillTermsTable basePlanGlobalTasks={basePlanGlobalTasks} revision={revision} />
      ) : (
        <div>No bid has been recieved for this trade</div>
      ),
  }));
  return <TabsWithContent tabs={tabs} selected={selectedTab} onChange={setSelectedTab} />;
}
