import {
  BoundSelectField,
  BoundSwitchField,
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridRowLookup,
  GridTable,
  GridTableApi,
  numericColumn,
  ScrollableContent,
  selectColumn,
  Tooltip,
  useComputed,
  useTestIds,
} from "@homebound/beam";
import { ObjectState, useFormStates } from "@homebound/form-state";
import { MutableRefObject, useMemo } from "react";
import { emptyCellDash, priceCell } from "src/components";
import { HomeownerSelectionStatus, SpecsAndSelections_UnitOfMeasureFragment } from "src/generated/graphql-types";
import { SpecsAndSelectionsMode } from "src/routes/projects/components/SpecsAndSelectionsTable";
import { createSpecsAndSelectionRows, getFinish } from "src/routes/projects/components/utils";
import {
  ObservableProjectItem,
  projectItemFormConfig,
  ProjectItemFormInput,
} from "src/routes/projects/models/ObservableProjectItem";
import { ProjectItemStore, SpecsAndSelectionsRow } from "src/routes/projects/models/ProjectItemStore";
import { setupPriceFields } from "src/routes/projects/selections/recalc";
import {
  commentColumn,
  getProjectItemColumn,
  idColumn,
  maybeBidItemColumn,
  quantityColumn,
  unitColumn,
} from "src/routes/projects/selections/sharedColumns";
import { HasIdAndName, selectionStatusToNameMapper } from "src/utils";
import { maybeAddIdColumn } from "src/utils/idColumn";

export type SelectionsTableProps = {
  createProjectItemUrl?(projectItemId: string): string;
  piStore: ProjectItemStore;
  disableSelectable: boolean;
  showPlanSource: boolean;
  textFilter?: string;
  /** Optional if readOnly: true. */
  unitsOfMeasure?: SpecsAndSelections_UnitOfMeasureFragment[];
  locations: HasIdAndName[];
  persistCollapse: string;
  mode?: SpecsAndSelectionsMode;
  rowLookup: MutableRefObject<GridRowLookup<GridDataRow<SpecsAndSelectionsRow>> | undefined>;
  autoSave: (state: ObjectState<ProjectItemFormInput>) => Promise<void>;
  api?: GridTableApi<SpecsAndSelectionsRow>;
};

export function SelectionsTable(props: SelectionsTableProps) {
  const {
    piStore,
    disableSelectable,
    showPlanSource,
    textFilter = "",
    createProjectItemUrl,
    unitsOfMeasure,
    locations,
    persistCollapse,
    mode = "S&S",
    rowLookup,
    autoSave,
    api,
  } = props;
  const selectionsTableId = useTestIds({}, "selectionsTable");

  const { getFormState } = useFormStates<ProjectItemFormInput, ObservableProjectItem>({
    config: projectItemFormConfig,
    getId: (v) => v.id,
    autoSave,
    addRules(formState) {
      // Recalc cost/markup/price
      setupPriceFields(
        {
          unitCostInCents: formState.unitCostInCents,
          totalCostInCents: formState.totalCostInCents,
          totalPriceInCents: formState.totalPriceInCents,
          markupPercentage: formState.markupPercent,
          totalMarkupInCents: formState.markupAmount,
          quantity: formState.quantity,
        },
        { canEditPrice: formState.canEditPrice.value },
      );
    },
  });

  const columns = useMemo(
    () => createColumns(disableSelectable, unitsOfMeasure, createProjectItemUrl, locations, mode, getFormState),
    [disableSelectable, unitsOfMeasure, createProjectItemUrl, locations, mode, getFormState],
  );
  const rows = useComputed(() => createSpecsAndSelectionRows(piStore, showPlanSource), [piStore, showPlanSource]);

  return (
    <div {...selectionsTableId}>
      <ScrollableContent virtualized>
        <GridTable
          as="virtual"
          style={{ grouped: true, inlineEditing: true }}
          columns={columns}
          rows={rows}
          filter={textFilter}
          stickyHeader
          persistCollapse={persistCollapse}
          fallbackMessage="No selections found that matched the given filters."
          rowLookup={rowLookup}
          sorting={{ on: "client", initial: ["projectItem", "ASC"] }}
          api={api}
        />
      </ScrollableContent>
    </div>
  );
}

function createColumns(
  disableSelectable: boolean,
  unitsOfMeasure: SpecsAndSelections_UnitOfMeasureFragment[] | undefined,
  createProjectItemUrl: ((projectItemId: string) => string) | undefined,
  locations: HasIdAndName[],
  mode: SpecsAndSelectionsMode,
  getFormState: (input: ObservableProjectItem) => ObjectState<ProjectItemFormInput>,
): GridColumn<SpecsAndSelectionsRow>[] {
  const readOnly = mode === "estimate" || mode === "S&S-PartialReadOnly";

  const locationColumn = column<SpecsAndSelectionsRow>({
    header: () => "Location",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) => {
      const os = getFormState(row);
      return {
        content: () => (
          <BoundSelectField
            field={os.locationId}
            readOnly={readOnly}
            placeholder="Location"
            options={locations || []}
          />
        ),
        value: locations.find((l) => l.id === row.locationId)?.name,
      };
    },
    lineItem: () => emptyCell,
    w: "180px",
  });

  const publishedColumn = column<SpecsAndSelectionsRow>({
    header: () => "Published",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) => {
      const os = getFormState(row);
      const hs = row.fragment.homeownerSelection;
      const disabledReason = hs?.canPublish?.disabledReasons?.map((reason) => reason.message).join('"\n"');
      return {
        content: () => (
          <BoundSwitchField
            disabled={!hs?.canPublish?.allowed ? disabledReason || true : readOnly}
            field={os.isSelectionPublished}
            labelStyle="hidden"
            compact
          />
        ),
        value: os.isSelectionPublished.value ? 1 : 0,
      };
    },
    lineItem: () => emptyCell,
    w: "102px",
  });

  const statusColumn = column<SpecsAndSelectionsRow>({
    header: () => "Status",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) => {
      const status = row.fragment.homeownerSelection?.status.code;
      return status ? selectionStatusToNameMapper[status] : emptyCellDash;
    },
    lineItem: () => emptyCell,
    w: "100px",
  });

  const selectionColumn = column<SpecsAndSelectionsRow>({
    header: () => "Selection",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) => {
      const hs = row.fragment.homeownerSelection;
      const images = hs?.selectedOption?.product.images;
      const imgUrl = images && images.length ? images[0].previewUrl : undefined;

      const hasCurrentSelection =
        hs?.status.code === HomeownerSelectionStatus.Finalized || hs?.status.code === HomeownerSelectionStatus.Selected;

      return {
        content: () =>
          hasCurrentSelection ? (
            <>
              {imgUrl && <img alt="item" loading="lazy" css={Css.objectContain.pr1.wPx(36).hPx(36).$} src={imgUrl} />}
              <span css={Css.truncate.$}>
                <Tooltip title={hs?.selectedOption?.name} placement="top">
                  {hs?.selectedOption?.name}
                </Tooltip>
              </span>
            </>
          ) : (
            emptyCellDash
          ),
        value: hs?.selectedOption?.name ?? "",
      };
    },
    lineItem: () => emptyCell,
    w: "240px",
  });

  const modelColumn = column<SpecsAndSelectionsRow>({
    header: () => "Model",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) => ({
      content: () => {
        const hs = row.fragment.homeownerSelection;
        return hs?.status.code === HomeownerSelectionStatus.Finalized ||
          hs?.status.code === HomeownerSelectionStatus.Selected
          ? hs?.selectedOption?.product.sku
          : "";
      },
    }),
    lineItem: () => emptyCell,
    w: "160px",
  });

  const finishColumn = column<SpecsAndSelectionsRow>({
    header: () => "Finish",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) => ({ content: getFinish(row.fragment.homeownerSelection?.selectedOption?.product) }),
    lineItem: () => emptyCell,
    w: "120px",
  });

  const msrpColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "MSRP",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) =>
      priceCell({ valueInCents: row.fragment.homeownerSelection?.selectedOption?.product.msrpInCents }),
    lineItem: () => emptyCell,
    w: "120px",
  });

  const unitPriceColumn = numericColumn<SpecsAndSelectionsRow>({
    header: () => "Unit Price",
    totals: () => emptyCell,
    group: emptyCell,
    projectItem: (row) =>
      priceCell({ valueInCents: row.fragment.homeownerSelection?.selectedOption?.unitPriceInCents }),
    lineItem: () => emptyCell,
    w: "120px",
  });

  const priceColumn = numericColumn<SpecsAndSelectionsRow>({
    header: "Total Price",
    totals: (row) => priceCell({ valueInCents: row.totalPriceInCents }),
    group: (row) => priceCell({ valueInCents: row.totalPriceInCents }),
    projectItem: (row) => priceCell({ valueInCents: row.totalPriceInCents }),
    lineItem: () => emptyCell,
    w: "120px",
  });

  const columns = [
    collapseColumn<SpecsAndSelectionsRow>({ totals: emptyCell }),
    ...maybeAddIdColumn(idColumn()),
    // set showselections argument to true to keep track of which tab the user was on
    getProjectItemColumn({ createProjectItemUrl, showSelections: true }),
    commentColumn(),
    locationColumn,
    publishedColumn,
    statusColumn,
    selectionColumn,
    modelColumn,
    finishColumn,
    ...maybeBidItemColumn(mode, readOnly),
    quantityColumn(readOnly, getFormState),
    unitColumn(true, unitsOfMeasure, getFormState),
    msrpColumn,
    unitPriceColumn,
    priceColumn,
  ];

  if (!disableSelectable) {
    columns.splice(1, 0, selectColumn<SpecsAndSelectionsRow>({ totals: emptyCell, lineItem: emptyCell }));
  }

  return columns;
}
