// This should take the current project and render it's address the amount allocated and the ability to switch to other projects

import { Css, Icon } from "@homebound/beam";
import { useMemo } from "react";
import { Price } from "src/components";
import { ExpenseAdmin_ProjectFragment } from "src/generated/graphql-types";
import { ExpenseAllocationSuccess } from "src/routes/projects/expenses/components/ExpenseAllocationSuccess";
import { ProjectsSlider } from "src/routes/projects/expenses/components/ProjectsSlider";
import { createBudgetUrl } from "src/RouteUrls";

export type ProjectHeaderViewProps = {
  project: ExpenseAdmin_ProjectFragment;
  setProject?: (project: ExpenseAdmin_ProjectFragment) => void;
  allProjects: ExpenseAdmin_ProjectFragment[];
};

export function ProjectHeader(props: ProjectHeaderViewProps) {
  const { project, setProject, allProjects } = props;

  const { allocated, toAllocate, fullyAllocated, totalProjectsDone } = useMemo(() => {
    const expensesAmount = allProjects.sum((p) => p.expenses.sum((e) => e.amountInCents));

    const unallocated = allProjects.sum((p) => p.unallocatedAmountInCents);
    const allocated = expensesAmount - unallocated;

    const totalProjectsDone = allProjects.count((p) => p.unallocatedAmountInCents === 0);

    return { toAllocate: expensesAmount, allocated, fullyAllocated: allocated === expensesAmount, totalProjectsDone };
  }, [allProjects]);

  const { projectIndex, numberOfProjects } = useMemo(
    () => ({
      projectIndex: allProjects.findIndex((p) => p.id === project.id),
      numberOfProjects: allProjects.length,
    }),
    [allProjects, project],
  );

  const navigateToProject = (position: "prev" | "next") => {
    let nextProject = allProjects[0];
    let prevProject = allProjects[0];

    if (projectIndex !== undefined) {
      const nextIndex = (projectIndex + 1) % allProjects.length;
      const prevIndex = (projectIndex - 1) % allProjects.length;
      nextProject = allProjects[nextIndex];
      prevProject = allProjects[prevIndex >= 0 ? prevIndex : allProjects.length - 1];
    }

    const newCurrentProject = position === "prev" ? prevProject : nextProject;
    setProject?.(newCurrentProject);
  };

  const budgetLink = createBudgetUrl(project.id);

  return (
    <>
      <div css={Css.df.jcsb.mb1.$} data-testid="projectHeader">
        <div css={Css.df.aic.$}>
          <Icon icon="house" />
          <h2 css={Css.xl2Sb.ml1.mr1.$}>{project.name}</h2>
          {budgetLink && (
            <a css={Css.sm.df.aic.blue700.$} href={budgetLink} target="_blank" rel="noopener noreferrer">
              <Icon icon="linkExternal" xss={Css.mr1.$} /> Open Budget
            </a>
          )}
        </div>
        <div css={Css.df.mr2.$}>
          <div css={Css.df.aic.fdc.$}>
            <p>
              <span css={Css.xl2Sb.if(fullyAllocated).green700.$}>
                <Price valueInCents={allocated} id="totalAmountAllocated" />
              </span>{" "}
              of{" "}
              <span css={Css.xl2Sb.if(fullyAllocated).green700.$}>
                <Price valueInCents={toAllocate} />
              </span>
            </p>
            <p data-testid="projectAllocatedLabel">Allocated</p>
          </div>
          {/* We need more than 1 project to display the slider */}
          {numberOfProjects > 1 && (
            <ProjectsSlider
              projectPosition={(projectIndex ?? 0) + 1}
              totalProjects={numberOfProjects}
              getSliderPosition={navigateToProject}
            />
          )}
        </div>
      </div>
      {project.unallocatedAmountInCents === 0 && (
        <ExpenseAllocationSuccess
          getProjectSlider={() => navigateToProject("next")}
          totalProjects={numberOfProjects}
          totalProjectsDone={totalProjectsDone}
        />
      )}
      {/* Divider */}
      <div css={Css.gc("1/3").hPx(1).bgGray200.mt0.mb3.$} />
    </>
  );
}
