import {
  Button,
  cardStyle,
  column,
  Css,
  FullBleed,
  GridDataRow,
  GridStyle,
  GridTable,
  useTestIds,
} from "@homebound/beam";
import { useMemo } from "react";
import {
  GlobalOptionTypeDrawerFragment,
  ReleaseToTradesTabLotConfigFragment,
  ScopeMode,
} from "src/generated/graphql-types";
import { disableBasedOnPotentialOperation } from "src/routes/components/PotentialOperationsUtils";
import { CohortProjectCard } from "src/routes/developments/lot-summary/lot-release/components/CohortProjectCard";
import { createDevelopmentLotConfigConfirmUrl } from "src/RouteUrls";
import { isDefined } from "src/utils";
import { DateOnly } from "src/utils/dates";

type CohortProps = {
  cohortName?: string;
  configs: ReleaseToTradesTabLotConfigFragment[];
  developmentId: string;
  searchFilter?: string;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
};

export function Cohort(props: CohortProps) {
  const { cohortName, configs, developmentId, searchFilter, globalOptionTypes } = props;
  const columns = useMemo(
    () => [
      column<Row>({
        data: (prpc) => ({
          content: <CellContent prpc={prpc} developmentId={developmentId} globalOptionTypes={globalOptionTypes} />,
        }),
      }),
    ],
    [developmentId, globalOptionTypes],
  );

  const rows = useMemo(() => createRows(configs, searchFilter), [configs, searchFilter]);

  const rowStyles = useMemo(
    () => ({
      data: {
        cellCss: Css.br8.p0.mhPx(160).$,
      },
    }),
    [],
  );

  const tableStyles: GridStyle = useMemo(
    () => ({
      ...cardStyle,
      nonHeaderRowCss: { ...cardStyle.nonHeaderRowCss, ...Css.bn.$ },
      cellCss: Css.px0.$,
      rowHoverColor: "none",
      nonHeaderRowHoverCss: Css.bshHover.$,
    }),
    [],
  );

  const tid = useTestIds(props, "cohort");

  return (
    <FullBleed>
      <div {...tid} css={Css.bgGray100.px2.pb3.pt2.mb2.df.fdc.aiStretch.$}>
        {cohortName && (
          <div {...tid.name} css={Css.xlSb.pxPx(12).py1.df.jcsb.$}>
            {cohortName}
          </div>
        )}

        <GridTable
          columns={columns}
          fallbackMessage="No lots to release"
          rows={rows}
          rowStyles={rowStyles}
          style={tableStyles}
        />
      </div>
    </FullBleed>
  );
}

type CellContentProps = {
  prpc: ReleaseToTradesTabLotConfigFragment;
  developmentId: string;
  globalOptionTypes: GlobalOptionTypeDrawerFragment[];
};

function CellContent({ prpc, developmentId, globalOptionTypes }: CellContentProps) {
  return isDefined(prpc.id) ? (
    <CohortProjectCard
      lot={prpc}
      globalOptionTypes={globalOptionTypes}
      right={
        <div css={Css.df.aifs.jcfe.$}>
          <Button
            label="Release"
            variant="secondary"
            onClick={createDevelopmentLotConfigConfirmUrl(developmentId, prpc.project.id)}
            disabled={disableBasedOnPotentialOperation(prpc.canRelease)}
          />
        </div>
      }
    />
  ) : (
    <></>
  );
}

function searchTerm(term: string | undefined, lot: ReleaseToTradesTabLotConfigFragment) {
  // Client side filtering
  const text = term ? term.toLocaleLowerCase() : "";
  // Get the labels from each LotConfigurationSection that are displayed
  const rpos = lot?.options.map(
    (rpo) => `${rpo.readyPlanOption.globalOption.code} ${rpo.readyPlanOption.globalOption.name}`,
  );
  // Get the labels project, ready plan name, ready plan display code that are displayed
  const fields = [lot?.project.name, lot?.readyPlan?.name, lot?.readyPlan?.displayCode, ...rpos]
    .join()
    .toLocaleLowerCase();
  return fields.includes(text);
}

const createRows = (lotsData: ReleaseToTradesTabLotConfigFragment[], searchFilter?: string): GridDataRow<Row>[] => {
  return lotsData
    .sortBy(({ constructionStartDate }) => constructionStartDate ?? new DateOnly(new Date()))
    .filter((lot) => lot.project.scopeMode.code !== ScopeMode.None)
    .filter((lot) => searchTerm(searchFilter, lot))
    .map((lot) => ({ id: lot.id, kind: "data", data: lot }));
};

type Row = {
  kind: "data";
  id: string;
  data: ReleaseToTradesTabLotConfigFragment;
};
