import { Breakpoints, useBreakpoint, useComputed } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { DashboardTileSize } from "src/generated/graphql-types";

/** Calculates the size the tile should be depending on the configuration and viewport size */
export function useTileSize(size: FieldState<DashboardTileSize>): TileSpansAndSize {
  const { md, sm } = useBreakpoint();
  const bp = md ? Breakpoints.md : sm ? Breakpoints.sm : Breakpoints.lg;

  const tileSizes = useComputed(() => getTileSizeDef(size?.value ?? DashboardTileSize.Small, bp), [bp, size.value]);

  return tileSizes;
}

export type TileSpansAndSize = {
  gridSpans: GridSpans;
  size: DashboardTileSize;
};

type GridSpans = {
  columns: number;
  rows: number;
};

const sizeToSpans: Record<DashboardTileSize, GridSpans> = {
  SMALL: { columns: 1, rows: 1 },
  MEDIUM: { columns: 2, rows: 1 },
  LARGE: { columns: 2, rows: 2 },
};

function getTileSizeDef(size: DashboardTileSize, bp: Breakpoints): TileSpansAndSize {
  if (bp === Breakpoints.sm) {
    return { gridSpans: sizeToSpans["SMALL"], size: DashboardTileSize.Small };
  }

  return { gridSpans: sizeToSpans[size], size };
}
