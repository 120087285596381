import { Button, ButtonVariant, Css, RichTextField, useSnackbar } from "@homebound/beam";
import { ErrorMessage } from "@homebound/beam/dist/inputs/ErrorMessage";
import { useCallback, useEffect, useState } from "react";
import { ApproverStatus, useApprovalSuperDrawer_SaveApprovalMutation } from "src/generated/graphql-types";
import { foldEnum, foldGqlUnion, noop, pluralize } from "src/utils";
import { ApprovalDrawerViews, useApprovalContext } from "./ApprovalSuperDrawer";
import { useUpdateApprovers } from "./useUpdateApprovers";

export function ApprovalReviewForm() {
  const { view, setView } = useApprovalContext();
  const updateApprovers = useUpdateApprovers();
  const retract = useRetractApproval();

  return (
    <div css={Css.df.fdc.aic.gap2.$}>
      {foldEnum(view, {
        [ApprovalDrawerViews.RequestingChanges]: () => (
          <ApprovalActionSubframe
            headline="What changes should be made to this request?"
            subtext="Outline all requested changes below:"
            requireComment
            buttonLabel="Request changes"
            onClick={([html, text]) => updateApprovers?.(ApproverStatus.ChangesRequested, html, text)}
          />
        ),
        [ApprovalDrawerViews.Rejecting]: () => (
          <ApprovalActionSubframe
            headline="Are you sure you want to reject this approval request?"
            subtext="If so, add a comment explaining your reasoning below:"
            requireComment
            stingerText="Note: A rejected request will NOT allow for any approval changes"
            buttonLabel="Reject request"
            buttonVariant="danger"
            onClick={([html, text]) => updateApprovers?.(ApproverStatus.Rejected, html, text)}
          />
        ),
        [ApprovalDrawerViews.Retracting]: () => (
          <ApprovalActionSubframe
            headline="Are you sure you want to retract this approval?"
            subtext="This action will remove this request from the dashboard and undo any actions triggered by the request."
            requireComment={false}
            buttonLabel="Retract Approval"
            onClick={retract}
          />
        ),
        else: () => "",
      })}
      <Button label="Cancel" onClick={() => setView(ApprovalDrawerViews.Review)} variant="tertiary" />
    </div>
  );
}

function useRetractApproval() {
  const { approvals, currentUserId, setView } = useApprovalContext();
  const { triggerNotice } = useSnackbar();
  const [updateApproval] = useApprovalSuperDrawer_SaveApprovalMutation();

  return useCallback(async () => {
    const filtered = approvals?.filter((a) => a.requestedBy?.id === currentUserId) ?? [];
    await filtered.asyncForEach((approval) =>
      updateApproval({
        variables: {
          input: {
            id: approval.id,
            retract: true,
          },
        },
      }),
    );

    const namedNotification: string | false | undefined =
      filtered.length === 1 &&
      foldGqlUnion(filtered.first!.subject, {
        ChangeEvent: (ce) => ce.displayName,
        Bill: (bill) => bill.name,
        Invoice: (inv) => inv.name,
        ChangeRequest: (cr) => cr.name,
      });
    triggerNotice({
      message: namedNotification
        ? `${namedNotification} successfully retracted`
        : `Successfully retracted ${filtered?.length ?? 0} ${pluralize(filtered, "approval")}`,
    });

    setView(ApprovalDrawerViews.Review);
  }, [approvals, currentUserId, setView, triggerNotice, updateApproval]);
}

type ApprovalActionSubframeProps = {
  headline: React.ReactNode;
  subtext: React.ReactNode;
  requireComment: boolean;
  stingerText?: React.ReactNode;
  buttonLabel: React.ReactNode;
  buttonVariant?: ButtonVariant;
  onClick: (maybeComment: [html: string | undefined, text: string | undefined]) => void;
};

function ApprovalActionSubframe({
  headline,
  subtext,
  requireComment,
  stingerText,
  buttonLabel,
  buttonVariant = "primary",
  onClick,
}: ApprovalActionSubframeProps) {
  const [[html, text], setComment] = useState<[string | undefined, string | undefined]>([undefined, undefined]);
  const [isCommentMissing, setIsCommentMissing] = useState(false); // state (vs calced) so mimick onBlur/onSubmit check
  useEffect(() => void (text && setIsCommentMissing(false)), [text]); // reset error when field updates
  return (
    <>
      <div css={Css.lgSb.mb2.$}>{headline}</div>
      <div css={Css.base.maxwPx(455).tac.mb1.$}>{subtext}</div>
      {requireComment && (
        <>
          <RichTextField
            placeholder="Add a comment..."
            value={html || ""}
            onChange={(html, text) => setComment([html, text])}
            onBlur={noop}
            onFocus={noop}
          />
          {isCommentMissing && <ErrorMessage errorMsg="A comment is required" />}
        </>
      )}
      {stingerText && <div css={Css.xsSb.$}>{stingerText}</div>}
      <div /* gap */ />
      <Button
        label={buttonLabel}
        variant={buttonVariant}
        onClick={() => {
          if (requireComment && !text) return setIsCommentMissing(true);
          return onClick([html, text]);
        }}
      />
    </>
  );
}
