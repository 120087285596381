import { pascalCase } from "change-case";
import { ReactNode } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { createProjectUrl } from "src/RouteUrls";
import { weightedStyle } from "src/routes/my-blueprint/activity-feed/utils";
import { developmentPaths } from "src/routes/routesDef";
import { UserEventRowData } from "../../UserEventsTable";
import { Accordion, AccordionProps, ConfigChanges, SingleChangeText } from "./components";

export function ProjectReadyPlanConfigEventText(props: {
  event: UserEventRowData;
  isProjectPage?: boolean;
  dateTag?: ReactNode;
}) {
  const { event, isProjectPage, dateTag } = props;
  const { url, type, createdBy, project, payload } = event;
  const { options = {}, __typename, __originalValues = {}, ...rest } = payload as any;
  const match = useRouteMatch(developmentPaths.lotDetails);

  const addedOptionTypes: Record<string, any[]> = options.added
    ? (options.added as any[])
        .map((option) => ({
          code: option.__payload?.__meta?.code,
          name: option.name,
          optionType: option.__payload?.__meta?.type?.name ?? "options",
        }))
        .groupBy(({ optionType }) => optionType)
    : {};
  const deletedOptionsType: Record<string, any[]> = options.deleted
    ? (options.deleted as any[])
        .map((option) => ({
          code: option.__payload?.__meta?.code,
          name: option.name,
          optionType: option.__payload?.__meta?.type?.name ?? "options",
        }))
        .groupBy(({ optionType }) => optionType)
    : {};

  const { programData = {}, ...prpcFields } = rest;
  const {
    __typename: pdTn,
    __originalValues: PdOriginalValues = {},
    ...programDataFields
  } = programData.__payload ?? {};
  const affectedAttributesValues = { ...prpcFields, ...programDataFields };
  const affectedAttributes = Object.keys(affectedAttributesValues);
  const affectedAttributesCount =
    affectedAttributes.length + (options.added?.length ?? 0) + (options.deleted?.length ?? 0);

  const ConfigurationTag = ({ children }: { children: ReactNode }) =>
    url && !match ? (
      <a css={weightedStyle} href={url}>
        {children}
      </a>
    ) : (
      <>{children}</>
    );
  const projectTag = project && !isProjectPage && (
    <>
      for
      <span css={weightedStyle}>
        {" "}
        <Link to={createProjectUrl(project.id)} target="_blank">
          {project.name}
        </Link>{" "}
      </span>
    </>
  );

  const allOriginalValues = { ...__originalValues, ...PdOriginalValues };

  const result: AccordionProps = {
    title:
      affectedAttributesCount === 1 ? (
        <SingleChangeText
          ConfigurationTag={ConfigurationTag}
          attribute={
            affectedAttributes.first
              ? {
                  name: pascalCase(affectedAttributes.first),
                  oldValue:
                    typeof allOriginalValues[affectedAttributes.first] === "object" &&
                    allOriginalValues[affectedAttributes.first]?.name
                      ? allOriginalValues[affectedAttributes.first].name
                      : typeof allOriginalValues[affectedAttributes.first] === "boolean"
                        ? allOriginalValues[affectedAttributes.first] + ""
                        : allOriginalValues[affectedAttributes.first],
                  newValue:
                    typeof affectedAttributesValues[affectedAttributes.first] === "object" &&
                    affectedAttributesValues[affectedAttributes.first]?.name
                      ? affectedAttributesValues[affectedAttributes.first].name
                      : affectedAttributesValues[affectedAttributes.first] + "",
                }
              : undefined
          }
          createdBy={createdBy}
          dateTag={dateTag}
          projectTag={projectTag}
          optionAdded={
            options.added
              ? {
                  name: options.added.first.name,
                  type: options.added.first.__payload?.__meta?.type?.name,
                }
              : undefined
          }
          optionDeleted={
            options.deleted
              ? {
                  name: options.deleted.first.name,
                  type: options.deleted.first.__payload?.__meta?.type?.name,
                }
              : undefined
          }
        />
      ) : (
        <>
          <ConfigurationTag>
            <span css={weightedStyle}>{affectedAttributesCount} lot configuration updates</span>
          </ConfigurationTag>{" "}
          were made {projectTag} by <span css={weightedStyle}>{createdBy}</span>
          {dateTag}
        </>
      ),
    children: "",
  };

  switch (type) {
    case "UPDATED":
      result.children = affectedAttributesCount > 1 && (
        <ConfigChanges
          fieldsChanged={affectedAttributes.map((attribute) => ({
            attributeName: pascalCase(attribute),
            newValue:
              typeof affectedAttributesValues[attribute] === "object" && affectedAttributesValues[attribute]?.name
                ? affectedAttributesValues[attribute].name
                : affectedAttributesValues[attribute] + "",
            oldValue:
              typeof allOriginalValues[attribute] === "object" && allOriginalValues[attribute]?.name
                ? allOriginalValues[attribute].name
                : typeof allOriginalValues[attribute] === "boolean"
                  ? allOriginalValues[attribute] + ""
                  : allOriginalValues[attribute],
          }))}
          optionsAdded={addedOptionTypes}
          optionsRemoved={deletedOptionsType}
        />
      );
      break;
    case "CREATED":
      result.title = (
        <>
          <span>
            <ConfigurationTag>New lot configuration</ConfigurationTag> added {projectTag} by{" "}
            <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
      break;
    case "PRPC_APPLIED":
    case "PRPC_RESET":
      const typeAction = type === "PRPC_APPLIED" ? "applied" : "removed";
      result.title = (
        <>
          <span>
            <ConfigurationTag>ReadyPlan scope</ConfigurationTag> has been {typeAction} {projectTag} by{" "}
            <span css={weightedStyle}>{createdBy}</span>
          </span>
          {dateTag}
        </>
      );
      result.children = (
        <span>
          <span css={weightedStyle}>Reason:</span> {payload.reason}
        </span>
      );
      break;
    default:
      return <></>;
  }

  return <Accordion {...result} />;
}
