import { Css, Tag, useTestIds } from "@homebound/beam";
import { Link } from "react-router-dom";
import { Price } from "src/components";
import { lienWaiverConditionStatusTagMapper } from "src/components/detailPane/bill/utils";
import { FormattedDate } from "src/components/FormattedDate";
import { FormGap } from "src/components/FormGap";
import { Percentage } from "src/components/Percentage";
import { BillPage_BillFragment } from "src/generated/graphql-types";
import { isDefined } from "src/utils";

export function BillRelationshipsTab({ bill }: { bill: BillPage_BillFragment }) {
  const tasksData = bill.lineItems
    .map((bli) => ({ task: bli.scheduleTask, createdBy: bli.bill.createdBy }))
    .filter((data) => isDefined(data.task))
    .uniqueByKey("task");

  const ownerCommitments = bill.lineItems.map((bli) => bli.commitmentLineItem?.ownerCommitment).compact();
  const lienWaivers = bill.lienWaivers;
  const tid = useTestIds({});

  return (
    <div css={Css.df.fdc.w100.my2.$}>
      <span css={Css.sm.gray700.mb1.$}>Schedule Tasks</span>
      {tasksData.nonEmpty ? (
        tasksData.map(
          (data) =>
            data.task && (
              <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={data.task.id}>
                <div css={Css.dg.gtc("130px 100px 100px").cg1.rg1.tiny.bgGray100.p2.asfs.aic.br8.$}>
                  <div css={Css.df.fdr.tal.aic.wbbw.$}>
                    <Link target="_blank" to={data.task.blueprintUrl.path} data-testid="taskItem">
                      {data.task.name}
                    </Link>
                  </div>

                  <div css={Css.df.fdc.wbbw.$} {...tid.taskCompletedOn}>
                    {data.task.completedAt ? (
                      <div>
                        Completed on
                        <FormattedDate date={data.task.completedAt || undefined} dateFormatStyle="short" />
                      </div>
                    ) : (
                      <div>{data.task.status.name}</div>
                    )}
                  </div>
                  <div {...tid.taskCompletedBy} css={Css.wbbw.df.fdc.$}>
                    <div>Completed by</div>
                    <div>{data.createdBy?.name}</div>
                  </div>
                </div>
              </div>
            ),
        )
      ) : (
        <span css={Css.tiny.mb1.$}>No tasks associated to bill</span>
      )}

      <div css={Css.mt3.$}>
        <div css={Css.sm.gray700.mb1.$}>Purchase Orders</div>
        {ownerCommitments.uniqueByKey("id").map((c) => (
          <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={c.id}>
            <div css={Css.dg.gtc("100px 70px 1fr 1fr").cg1.rg1.tiny.bgGray100.p2.aic.br8.$}>
              <span {...tid.purchaseNumber}>
                <Link target="_blank" to={c.blueprintUrl.path}>
                  #{c.accountingNumber}
                </Link>
              </span>
              <FormGap />
              <span css={Css.df.fdr.gap1.$} {...tid.poPercentPaid}>
                <Percentage percent={c.percentagePaid} /> paid
              </span>
              <span css={Css.tinyBd.$} {...tid.poCost}>
                <Price valueInCents={c.costChangeInCents} />
              </span>
            </div>
          </div>
        ))}
      </div>
      <div css={Css.mt3.$}>
        <div css={Css.sm.gray700.mb1.$}>Lien Waivers</div>
        {lienWaivers.uniqueByKey("id").map((c) => {
          const [lienWaiverStatus, text] = lienWaiverConditionStatusTagMapper({
            status: c?.status?.code,
            isLienWaiverReceiver: bill.tradePartner.isLienWaiverReceiver,
            condition: c?.condition?.name,
          });
          return (
            <div css={Css.df.fdc.w100.mb1.br8.bgGray100.$} key={c.id}>
              <div css={Css.dg.gtc("70% 30%").cg1.rg1.tiny.bgGray100.p2.aic.br8.$}>
                {c.pandaDoc && (
                  <span {...tid.pandaDocName}>
                    <Link target="_blank" rel="" to={{ pathname: c.pandaDoc.externalPandaDocUrl! }}>
                      #{c.pandaDoc.name}
                    </Link>
                  </span>
                )}
                <span {...tid.lwStatus}>
                  <Tag type={lienWaiverStatus} text={text} />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
