import { multiFilter } from "@homebound/beam";
import { useUseRoomTypeFilterQuery } from "src/generated/graphql-types";

export function useRoomTypeFilter() {
  const { data } = useUseRoomTypeFilterQuery({ fetchPolicy: "cache-first" });
  return multiFilter({
    label: "Room Type",
    options: data?.roomTypes ?? [],
    getOptionLabel: ({ name }) => name,
    getOptionValue: ({ id }) => id,
  });
}
