import {
  AutoSaveIndicator,
  ButtonMenu,
  Css,
  Filter as FilterType,
  Filters,
  checkboxFilter,
  multiFilter,
  usePersistedFilter,
} from "@homebound/beam";
import { useEffect, useMemo, useState } from "react";
import {
  createBusinessFunctionsUrl,
  createChangeTypesUrl,
  createDevelopmentChangeLogDetailsUrl,
  createDevelopmentChangeLogUrl,
  createGlobalChangeLogDetailsUrl,
  createGlobalChangeLogUrl,
  createProjectChangeLogDetailsUrl,
  createProjectChangeLogUrl,
} from "src/RouteUrls";
import {
  ChangeRequestsFilter,
  ChangeType,
  InputMaybe,
  Maybe,
  useChangeLogMetadataQuery,
  useChangeLogProjectFilterMetadataQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { TableActions } from "src/routes/layout/TableActions";
import { SearchBox } from "../SearchBox";
import { ChangeLogTable } from "./ChangeLogTable";
import { CreateChangeRequestButton } from "./CreateChangeRequestDrawer";
import { filterChangeRequestSources } from "./components/DetailsTab";

export enum ChangeLogView {
  Global,
  Development,
  Project,
}

export enum ChangeLogFilter {
  MyRequests,
  Status,
  ChangeType,
  Source,
  Department,
  Market,
  Development,
  Project,
  Group,
}

export type ChangeLogTableFilter = ChangeRequestsFilter & {
  // Custom and yet to be created filters
  myRequests?: InputMaybe<boolean>;
  changeTypes?: InputMaybe<ChangeType[]>;
};

type ChangeLogProps = {
  view: ChangeLogView;
  projectId?: string;
  developmentId?: string;
};

export function ChangeLog({ view, projectId, developmentId }: ChangeLogProps) {
  const { data } = useChangeLogMetadataQuery();
  const { markets = [], developments = [], changeRequestGroups = [], enumDetails } = data ?? {};

  const [projectSearch, setProjectSearch] = useState<string | undefined>();
  const [selectedProjects, setSelectedProjects] = useState<Maybe<string[]>>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");

  const statusDetails = useMemo(() => enumDetails?.changeRequestStatus ?? [], [enumDetails]);
  const changeTypes = useMemo(() => enumDetails?.changeType.sortByKey("sortOrder") ?? [], [enumDetails]);
  const businessFunctionTypes = useMemo(() => enumDetails?.businessFunctionType ?? [], [enumDetails]);
  const changeRequestSources = useMemo(
    () => filterChangeRequestSources(enumDetails?.changeRequestSource ?? []),
    [enumDetails],
  );

  const filters = useMemo(() => ChangeLogViewFilterConfig[view].filters, [view]);

  // This query will find the projects for which the project is searching for
  const { data: projectsFilterData } = useChangeLogProjectFilterMetadataQuery({
    variables: {
      filter: { search: projectSearch },
      page: { offset: 0, limit: 100 },
    },
    skip: !filters.includes(ChangeLogFilter.Project),
  });

  // This query will bring the already selected projects
  const { data: currentSelectedProjects } = useChangeLogProjectFilterMetadataQuery({
    variables: {
      filter: { id: selectedProjects },
      page: { offset: 0, limit: 100 },
    },
    skip: !filters.includes(ChangeLogFilter.Project),
  });

  // We need to concatenate the projects that are already selected with the projects that are being searched
  // And then we need to remove the duplicates
  const projectsForFilter = useMemo(() => {
    const selectedProjects = currentSelectedProjects?.projectsPage.entities ?? [];
    const searchedProjects = projectsFilterData?.projectsPage.entities ?? [];
    return [...selectedProjects, ...searchedProjects].uniqueBy((p) => p.id);
  }, [currentSelectedProjects, projectsFilterData]);

  const filterDefs = useMemo(
    () =>
      cleanFilters({
        myRequests: maybeFilter(
          filters,
          ChangeLogFilter.MyRequests,
          checkboxFilter({
            label: "My Requests",
            defaultValue: true,
          }),
        ),
        status: maybeFilter(
          filters,
          ChangeLogFilter.Status,
          multiFilter({
            label: "Status",
            options: statusDetails,
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ code }) => code,
          }),
        ),
        source: maybeFilter(
          filters,
          ChangeLogFilter.Source,
          multiFilter({
            label: "Source",
            options: changeRequestSources,
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ code }) => code,
          }),
        ),
        changeTypes: maybeFilter(
          filters,
          ChangeLogFilter.ChangeType,
          multiFilter({
            label: "Type of Change",
            options: changeTypes,
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ code }) => code,
          }),
        ),
        businessFunction: maybeFilter(
          filters,
          ChangeLogFilter.Department,
          multiFilter({
            label: "Department",
            options: businessFunctionTypes,
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ code }) => code,
          }),
        ),
        project: maybeFilter(
          filters,
          ChangeLogFilter.Project,
          multiFilter({
            label: "Project",
            options: projectsForFilter,
            onSearch: (input) => setProjectSearch(input),
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ id }) => id,
            sizeToContent: true,
          }),
        ),
        group: maybeFilter(
          filters,
          ChangeLogFilter.Group,
          multiFilter({
            label: "Group",
            options: changeRequestGroups,
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ id }) => id,
            sizeToContent: true,
          }),
        ),
        market: maybeFilter(
          filters,
          ChangeLogFilter.Market,
          multiFilter({
            label: "Market",
            options: markets,
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ id }) => id,
          }),
        ),
        development: maybeFilter(
          filters,
          ChangeLogFilter.Development,
          multiFilter({
            label: "Development",
            options: developments.sortByKey("name"),
            getOptionLabel: ({ name }) => name,
            getOptionValue: ({ id }) => id,
          }),
        ),
      }),
    [
      filters,
      statusDetails,
      changeRequestSources,
      changeTypes,
      businessFunctionTypes,
      projectsForFilter,
      changeRequestGroups,
      markets,
      developments,
    ],
  );

  const { filter, setFilter } = usePersistedFilter({
    filterDefs,
    storageKey: `change-log-filter-${view}`,
  });

  // Set the selected projects from the filter
  useEffect(() => {
    if (filters.includes(ChangeLogFilter.Project)) {
      setSelectedProjects(filter.project);
    }
  }, [filter.project, filters]);

  return (
    // Be 100% height of the parent container, minus the height of the header and the padding of the header
    <div css={Css.df.fdc.fg1.maxh("calc(100% - 48px)").$}>
      <PageHeader
        title="Change Log"
        left={<AutoSaveIndicator />}
        right={
          <>
            {view === ChangeLogView.Global && (
              <ButtonMenu
                placement="right"
                items={[
                  {
                    label: "Change Types",
                    onClick: createChangeTypesUrl(),
                  },
                  {
                    label: "Functions",
                    onClick: createBusinessFunctionsUrl(),
                  },
                ]}
                trigger={{ icon: "cog" }}
              />
            )}
            <CreateChangeRequestButton projectId={projectId} developmentId={developmentId} />
          </>
        }
        xss={Css.px3.$}
      />
      <div css={Css.df.fdc.fg1.if(view === ChangeLogView.Global).px3.$}>
        <TableActions onlyRight>
          <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} numberOfInlineFilters={8} />
          <SearchBox onSearch={setSearchFilter} />
        </TableActions>
      </div>
      <ChangeLogTable
        view={view}
        filter={{ ...filter, search: searchFilter }}
        changeTypes={changeTypes}
        projectId={projectId}
        developmentId={developmentId}
      />
    </div>
  );
}

type DetailsUrlParams = {
  changeRequestId: string;
  projectId?: string;
  developmentId?: string;
};

type ChangeLogViewConfig = {
  filters: ChangeLogFilter[];
  baseUrl: (params: Omit<DetailsUrlParams, "changeRequestId">) => string;
  detailsUrl: (params: DetailsUrlParams) => string;
};

export const ChangeLogViewFilterConfig: Record<ChangeLogView, ChangeLogViewConfig> = {
  [ChangeLogView.Global]: {
    filters: [
      ChangeLogFilter.MyRequests,
      ChangeLogFilter.Status,
      ChangeLogFilter.ChangeType,
      ChangeLogFilter.Source,
      ChangeLogFilter.Department,
      ChangeLogFilter.Market,
      ChangeLogFilter.Development,
      ChangeLogFilter.Project,
      ChangeLogFilter.Group,
    ],
    baseUrl: () => createGlobalChangeLogUrl(),
    detailsUrl: ({ changeRequestId }) => createGlobalChangeLogDetailsUrl(changeRequestId),
  },
  [ChangeLogView.Development]: {
    filters: [
      ChangeLogFilter.MyRequests,
      ChangeLogFilter.Status,
      ChangeLogFilter.ChangeType,
      ChangeLogFilter.Source,
      ChangeLogFilter.Department,
      ChangeLogFilter.Project,
      ChangeLogFilter.Group,
    ],
    baseUrl: ({ developmentId }) => createDevelopmentChangeLogUrl(developmentId!),
    detailsUrl: ({ changeRequestId, developmentId }) =>
      createDevelopmentChangeLogDetailsUrl(developmentId!, changeRequestId),
  },
  [ChangeLogView.Project]: {
    filters: [
      ChangeLogFilter.MyRequests,
      ChangeLogFilter.Status,
      ChangeLogFilter.ChangeType,
      ChangeLogFilter.Source,
      ChangeLogFilter.Department,
      ChangeLogFilter.Group,
    ],
    baseUrl: ({ projectId }) => createProjectChangeLogUrl(projectId!),
    detailsUrl: ({ changeRequestId, projectId }) => createProjectChangeLogDetailsUrl(projectId!, changeRequestId),
  },
};

const maybeFilter = <T,>(
  filters: ChangeLogFilter[],
  filterType: ChangeLogFilter,
  filter: (key: string) => FilterType<T>,
) => (filters.includes(filterType) ? filter : undefined);

const cleanFilters = (filters: Partial<Record<keyof ChangeLogTableFilter, Maybe<(key: string) => FilterType<any>>>>) =>
  Object.entries(filters)
    .filter(([_, value]) => value !== undefined)
    .reduce(
      (obj, [key, value]) => {
        obj[key as keyof ChangeLogTableFilter] = value!;
        return obj;
      },
      {} as Record<keyof ChangeLogTableFilter, (key: string) => FilterType<any>>,
    );
