import { SelectField } from "@homebound/beam";
import { PresentationFieldProps } from "@homebound/beam/dist/components/PresentationContext";
import { ReactNode } from "react";
import { emptyCellDash } from "src/components";
import {
  BidItemSelect_BidItemFragment,
  Maybe,
  PotentialBidItemFilter,
  useBidItemSelectLazyQuery,
} from "src/generated/graphql-types";

export type BidItemSelectFieldProps = PresentationFieldProps & {
  label?: string;
  value: Maybe<{ id: string; displayName: string }>;
  filter: PotentialBidItemFilter;
  /** An optional, additional client-side filter. */
  filterFn?: (bi: BidItemSelect_BidItemFragment) => boolean;
  readOnly?: boolean | ReactNode;
  unsetLabel?: string;
  onSelect: (id: string | undefined, selectedBidItem: BidItemSelect_BidItemFragment | undefined) => void;
};

export function BidItemSelectField(props: BidItemSelectFieldProps) {
  const { filter, filterFn, value, onSelect, readOnly, label, unsetLabel = emptyCellDash, ...others } = props;
  const [load, { data }] = useBidItemSelectLazyQuery({ variables: { filter }, fetchPolicy: "cache-first" });
  const options = data?.potentialBidItems.filter((bi) => !filterFn || filterFn(bi));
  return (
    <SelectField
      {...others}
      label={label ?? "Bid Item"}
      readOnly={readOnly}
      getOptionLabel={(o) => o.displayName}
      getOptionValue={(o) => o.id}
      unsetLabel={unsetLabel}
      onSelect={onSelect}
      options={{ current: value ? { ...value, products: [] } : undefined, load, options }}
      value={value?.id}
    />
  );
}
