import { ApolloClient } from "@apollo/client";
import "@homebound/activesupport";
import { AmplifyConfig, createApolloClientWithAuth } from "@homebound/auth-components";
import merge from "lodash/merge";
import { possibleTypes } from "src/generated/graphql-types";
import { typePolicies } from "src/typePolicies";
import { qaServerEnvironment } from "./utils";

export type Stage = "local" | "dev" | "qa" | "prod";

export function getStage(windowLocation = window.location): Stage {
  // Guess stage based on the current window.location.hostname
  const hostname = windowLocation.hostname;
  if (hostname.includes("dev-homebound")) {
    return "dev";
  } else if (hostname.includes("qa-homebound")) {
    return "qa";
  } else if (hostname.includes("homebound")) {
    return "prod";
  } else {
    return "local";
  }
}

export function isContentPreview(windowLocation = window.location): boolean {
  return /.*\.blueprint\.(dev|qa)-homebound\.com/.test(windowLocation.hostname);
}

export function getGraphQLBaseUrl(stage: Stage = getStage()): string {
  const stageToGraphQL: Record<Stage, string> = {
    local: VITE_GRAPHQL_SERVICE ? VITE_GRAPHQL_SERVICE : `http://localhost:${GQL_PORT}`,
    dev: "https://graphql.dev-homebound.com",
    qa: `https://${qaServerEnvironment().subDomain}.qa-homebound.com`,
    prod: "https://graphql.homebound.com",
  };
  return stageToGraphQL[stage];
}

export function baseDownloadUrl(): string {
  const url = getGraphQLBaseUrl();
  const stage = getStage();
  if (stage !== "prod") return url;
  /**
   * Auth cookie on Prod is scoped to blueprint.homebound.com where Dev/QA is scoped to *.dev-homebound.com,
   * so specifically in Prod for this Download URL we need to direct users to graphql.blueprint.homebound.com.
   * Alternatively we can just update getGraphQLBaseUrl but ApolloClient leans on that and passes a proper Auth
   * header (as opposed to a Cookie) and already works, and we're cautious about updating all GraphQL requests
   * to this URL.
   */
  return "https://graphql.blueprint.homebound.com";
}

export function createApolloClient(stage: Stage): ApolloClient<unknown> {
  const baseUrl = getGraphQLBaseUrl(stage);
  return createApolloClientWithAuth(`${baseUrl}/graphql`, {
    retryLinkOptions: {
      attempts: {
        retryIf(error: any) {
          if (error instanceof TypeError) {
            console.error("Retrying because fetched rejected: " + error.message);
          }
          // For a failed mutation, error will be a ServerError (error.name === 'ServerName'), don't retry
          // For a rejected fetch (likely transient connectivity issue), error will be a TypeError, so retry
          return error instanceof TypeError;
        },
      },
    },
    apolloClientOptions: {
      connectToDevTools: stage !== "prod",
      assumeImmutableResults: true,
      defaultOptions: {
        // Prefer consistency on CRUD tables/forms. Use watchQuery b/c that's what React's hooks use.
        // For nextFetchPolicy, see https://github.com/apollographql/apollo-client/pull/6712
        watchQuery: { fetchPolicy: "network-only", nextFetchPolicy: "cache-first" },
        query: { fetchPolicy: "network-only" },
      },
    },
    inMemoryCacheOptions: {
      // `as any` because our possibleTypes is generated with `as const` which makes readonly arrays,
      // but Apollo accepts mutable arrays.
      possibleTypes: possibleTypes as any,
      typePolicies,
    },
  });
}

// Include `aws.cognito.signin.user.admin` so that we can access user attributes like their profile picture:
// https://stackoverflow.com/a/53151260/355031
const scope = ["phone", "email", "openid", "aws.cognito.signin.user.admin"];

export function amplifyConfig(stage: Stage): AmplifyConfig {
  function stageConfig() {
    if (stage === "prod") {
      return {
        Auth: {
          userPoolId: "us-west-2_Sud41U2uX",
          userPoolWebClientId: "6jbhcfnuji5mor48dg2hg37hvg",
          oauth: { domain: "homebound-internal-prod.auth.us-west-2.amazoncognito.com", scope },
          // Use cookie storage so that non-GraphQL calls (i.e. image downloads) still get the auth token
          cookieStorage: { domain: "blueprint.homebound.com" },
        },
      };
    }

    return {
      Auth: {
        userPoolId: "us-west-2_u4KsZS7DF",
        userPoolWebClientId: "47jne7hh7uao4il33eq1kftr5c",
        oauth: { domain: "homebound-internal-dev.auth.us-west-2.amazoncognito.com", scope },
        cookieStorage: {
          // Use .<stage>-homebound.com so that our cookies get sent to graphql.<stage>-homebound.com, which
          // knows to look for the cookie as well as the usual Authentication header, so that the
          // GraphQL playground gets auth "for free".
          //
          // This domain pattern is too broad for production, i.e. it would leak our auth cookies onto
          // domains like `somechatvendor.homebound.com`, but it's fine for dev creds.
          //
          // We should also not conflict/overlap with other apps like `app.<stage>-homebound.com` because
          // Cognito puts the application-specific user pool id into the cookie names.
          domain: stage === "local" ? "localhost" : `.${stage}-homebound.com`,
          // Don't use secure so that the cookie works on the http://localhost:4000/graphql GraphQL playground.
          secure: false,
        },
      },
    };
  }

  const { origin } = window.location;

  return merge({}, stageConfig(), {
    Auth: {
      region: "us-west-2",
      oauth: {
        scope: ["phone", "email", "openid"],
        redirectSignIn: origin + "/auth/gcallback",
        redirectSignOut: origin + "/",
        responseType: "code" as const,
      },
    },
  });
}

export const lightGalleryKey = "3AA3D7F6-B4F64FA5-886A68F8-B0DF2E97";
