import { Css, SelectField, useSnackbar } from "@homebound/beam";
import { useCallback } from "react";
import { StatusIndicator } from "src/components/StatusIndicator";
import { ProjectAdminFragment, ProjectStatus, useSetProjectStatusMutation } from "src/generated/graphql-types";
import { HasIdAndName, safeEntries } from "src/utils";
import { projectStatusDetails } from "src/utils/mappers";

type ProjectStatusCellProps = {
  project: ProjectAdminFragment;
};

export function ProjectStatusCell({ project }: ProjectStatusCellProps) {
  const {
    status: { code: statusCode },
  } = project;
  const [projectStatusMutation] = useSetProjectStatusMutation();
  const { triggerNotice } = useSnackbar();

  const onSave = useCallback(
    async (projectStatus: ProjectStatus) => {
      await projectStatusMutation({ variables: { projectId: project.id, projectStatus } });
      triggerNotice({
        icon: "success",
        message: `${project.buildAddress.street1} (${project.id}) status set to ${projectStatusDetails[projectStatus]}`,
      });
    },
    // TODO: validate this eslint-disable. It was automatically ignored as part of https://app.shortcut.com/homebound-team/story/40033/enable-react-hooks-exhaustive-deps-for-internal-frontend
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id],
  );

  // Next create Id + Name pairs:
  const statusOptions: HasIdAndName[] = safeEntries(projectStatusDetails).map(([id, name]) => ({ id, name }));

  return (
    <SelectField
      value={statusCode as string}
      label="Project Status"
      options={statusOptions}
      fieldDecoration={({ id }) => <StatusIndicator status={id as ProjectStatus} />}
      getOptionMenuLabel={({ id, name }) => {
        return (
          <div css={Css.df.aic.gap1.$}>
            <StatusIndicator status={id as ProjectStatus} /> {name}
          </div>
        );
      }}
      onSelect={(v) => v && onSave(v as ProjectStatus)}
    />
  );
}
