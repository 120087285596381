import {
  addDays,
  eachWeekOfInterval,
  endOfWeek,
  getDay,
  getUnixTime,
  startOfDay,
  startOfWeek,
  subDays,
} from "date-fns";
import { useCallback, useState } from "react";
import { DateOnly } from "src/utils/dates";

// Hardcoding that weeks start on a Monday as a standard, can be made a prop if this needs to change
const WEEK_STARTS_ON = 1;
export const DAYS_IN_WEEK = 7;

type RangeFilterOps = {
  weekCount: number;
  initialStartDate?: DateOnly;
};

export type WeekInterval = {
  start: DateOnly;
  end: DateOnly;
  index: number;
  weekID: number;
};

export type RangeFilterReturnTypes = {
  dateRange: { start: DateOnly; end: DateOnly };
  nextRangeGroup: () => void;
  previousRangeGroup: () => void;
  setStartDate: (newStartDate: DateOnly | Date) => void;
  includedWeekIntervals: WeekInterval[];
  weekCount: number;
};

/** Custom filter for reports that allows for chunking dates into week groupings */
export function useWeekRangeFilter({ initialStartDate, weekCount }: RangeFilterOps): RangeFilterReturnTypes {
  if (initialStartDate) validateStartDate(initialStartDate);

  const startOfCurrentWeek = new DateOnly(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }));

  const [dateRange, setDateRange] = useState(
    createDateRange({ start: initialStartDate ?? startOfCurrentWeek, weekCount }),
  );

  const nextRangeGroup = useCallback(() => {
    const newStart = addDays(dateRange.end, 1);
    setDateRange(createDateRange({ start: newStart, weekCount }));
  }, [dateRange.end, weekCount]);

  const previousRangeGroup = useCallback(() => {
    const newStart = subDays(dateRange.start, weekCount * DAYS_IN_WEEK);

    setDateRange(createDateRange({ start: newStart, weekCount }));
  }, [dateRange.start, weekCount]);

  const setStartDate = useCallback(
    (newStartDate: DateOnly | Date) => {
      const newStartAsDateOnly = new DateOnly(newStartDate);
      validateStartDate(newStartAsDateOnly);
      setDateRange(createDateRange({ start: newStartAsDateOnly, weekCount }));
    },
    [weekCount],
  );

  return {
    dateRange,
    nextRangeGroup,
    previousRangeGroup,
    setStartDate,
    includedWeekIntervals: getIncludedWeekIntervals(dateRange),
    weekCount,
  };
}

/** End date is not inclusive of the following Monday (ends Sunday) */
function createDateRange({ start, weekCount }: { start: DateOnly | Date; weekCount: number }) {
  const startDateOnly = new DateOnly(start);
  const end = addDays(startDateOnly, weekCount * DAYS_IN_WEEK - 1);
  return { start: startDateOnly, end: new DateOnly(end) };
}

function validateStartDate(startDate: DateOnly) {
  if (getDay(startDate) !== WEEK_STARTS_ON) {
    throw new Error("Valid ranges must begin on a Monday");
  }
}

/** Used downstream to further group data into the individual weeks contained in the range.
 * * Use the `weekID` unix timestamp as a stable reference to a given week */
function getIncludedWeekIntervals({ start, end }: { start: DateOnly; end: DateOnly }) {
  const weekIntervalStartDates = eachWeekOfInterval({ start, end }, { weekStartsOn: WEEK_STARTS_ON });
  return weekIntervalStartDates.map((weekStart, index) => ({
    start: new DateOnly(weekStart),
    end: new DateOnly(startOfDay(endOfWeek(weekStart, { weekStartsOn: WEEK_STARTS_ON }))),
    index,
    weekID: getUnixTime(weekStart),
  }));
}
