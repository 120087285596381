import { Css, useTestIds } from "@homebound/beam";
import { Fragment } from "react";
import { sanitizeHtml } from "src/utils";

export function Section(props: { title: string; html?: string | null | undefined; children?: any }) {
  const { title, html, children } = props;
  const tid = useTestIds({}, title);
  return (
    <Fragment>
      <h1 {...tid} css={Css.ttu.mt2.mb1.base.$}>
        {title}
      </h1>
      {html ? <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} /> : children}
    </Fragment>
  );
}
