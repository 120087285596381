// Keep a list of all sub nav items and their relation to the Tiles.
// If we are missing a tile for a sub-nav item, then we'll add it to a "Menu" tile.
import { ObjectConfig } from "@homebound/form-state/dist/config";
import React from "react";
import { DashboardConfigInput, DashboardTile, DashboardTileSize } from "src/generated/graphql-types";
import { DocumentsTile } from "src/routes/projects/dashboard/components/DocumentsTile/DocumentsTile";
import { FinancesTile } from "src/routes/projects/dashboard/components/Financials/FinancesTile";
import { HomeownerNotesTile } from "src/routes/projects/dashboard/components/HomeownerNotesTile/HomeownerNotesTile";
import { JobLogsTile } from "src/routes/projects/dashboard/components/JobLogsTile/JobLogsTile";
import { PhotosTile } from "src/routes/projects/dashboard/components/PhotosTile/PhotosTile";
import { ProjectInfoTile } from "src/routes/projects/dashboard/components/ProjectInfoTile/ProjectInfoTile";
import { ScheduleTile } from "src/routes/projects/dashboard/components/ScheduleTile/ScheduleTile";
import { TeamMembersTile } from "src/routes/projects/dashboard/components/TeamMembersTile/TeamMembersTile";
import { TileBaseProps } from "src/routes/projects/dashboard/components/Tile";
import { ProjectToDosTile } from "src/routes/projects/dashboard/components/ToDosTile/ProjectToDosTile";
import {
  createBudgetUrl,
  createProjectDocumentsUrl,
  createProjectJobLogsGalleryUrl,
  createProjectJobLogsUrl,
  createProjectScheduleUrl,
  createProjectSettingsUrl,
  createProjectToDosUrl,
} from "src/RouteUrls";

export type TileComponentConfig = {
  component: React.FunctionComponent<TileBaseProps>;
  title: string;
  /** Add URL method to make Tile linkable.
   * If this tile is hidden and the `createUrl` is set, then it will appear in the "Other tiles" tile. */
  createUrl?: (projectId: string) => string;
  defaultSize: DashboardTileSize;
};

export const tileToComponentConfig: Record<DashboardTile, TileComponentConfig> = {
  ProjectSchedule: {
    component: ScheduleTile,
    title: "Schedule",
    createUrl: createProjectScheduleUrl,
    defaultSize: DashboardTileSize.Large,
  },
  ProjectInfo: {
    component: ProjectInfoTile,
    title: "Project Info",
    defaultSize: DashboardTileSize.Medium,
  },
  ProjectToDos: {
    component: ProjectToDosTile,
    title: "To-Dos",
    createUrl: createProjectToDosUrl,
    defaultSize: DashboardTileSize.Small,
  },
  ProjectJobLogs: {
    component: JobLogsTile,
    title: "Job Logs",
    createUrl: createProjectJobLogsUrl,
    defaultSize: DashboardTileSize.Small,
  },
  ProjectFinances: {
    component: FinancesTile,
    title: "Finances",
    createUrl: createBudgetUrl,
    defaultSize: DashboardTileSize.Medium,
  },
  ProjectDocuments: {
    component: DocumentsTile,
    title: "Documents",
    createUrl: createProjectDocumentsUrl,
    defaultSize: DashboardTileSize.Small,
  },
  ProjectTeamMembers: {
    component: TeamMembersTile,
    title: "Team Members",
    createUrl: createProjectSettingsUrl,
    defaultSize: DashboardTileSize.Medium,
  },
  // TODO: Make the Job Logs gallery view linkable and add it here.
  ProjectPhotos: {
    component: PhotosTile,
    title: "Photos",
    createUrl: createProjectJobLogsGalleryUrl,
    defaultSize: DashboardTileSize.Small,
  },
  ProjectHomeownerNotes: {
    component: HomeownerNotesTile,
    title: "Homeowner Notes",
    defaultSize: DashboardTileSize.Small,
  },
};

export const dashboardFormConfig: ObjectConfig<DashboardConfigInput> = {
  tileConfigs: {
    type: "list",
    config: {
      tile: { type: "value" },
      size: { type: "value" },
    },
  },
};
