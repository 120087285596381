import { SelectField } from "@homebound/beam";
import { PresentationFieldProps } from "@homebound/beam/dist/components/PresentationContext";
import { ReactNode } from "react";
import {
  BidContractLineItemFilter,
  CostSourceSelect_BidContractLineItemFragment,
  Maybe,
  useCostSourceSelectLazyQuery,
} from "src/generated/graphql-types";
import { CostSourceType } from "src/routes/projects/change-events/ChangeEventLineItemsTable";

export type CostSourceSelectFieldProps = PresentationFieldProps & {
  label?: string;
  value: Maybe<CostSourceSelect_BidContractLineItemFragment>;
  /** The server-side filter for valid BCLIs to show, required b/c we don't want to show all BCLIs. */
  filter: BidContractLineItemFilter;
  /** An optional, additional client-side filter. */
  filterFn?: (bcli: CostSourceSelect_BidContractLineItemFragment) => boolean;
  readOnly?: boolean | ReactNode;
  onSelect: (
    id: string | undefined,
    selectedCostSource: CostSourceSelect_BidContractLineItemFragment | undefined,
  ) => void;
};

export function CostSourceSelectField(props: CostSourceSelectFieldProps) {
  const { filter, filterFn, value, onSelect, readOnly, label, ...others } = props;
  // We can lazy load this and it will re-fetch when variables change
  const [load, { data }] = useCostSourceSelectLazyQuery({ variables: { filter }, fetchPolicy: "cache-first" });
  return (
    <SelectField
      {...others}
      label={label ?? "Cost Source"}
      readOnly={readOnly}
      unsetLabel={CostSourceType.MANUAL}
      value={value?.id}
      onSelect={onSelect}
      options={{
        // Add in `name` to match the `HasName` type
        current: value ? value : undefined,
        load,
        options: data?.bidContractLineItems.filter((bcli) => !filterFn || filterFn(bcli)),
      }}
    />
  );
}
