import { Button, Chip, Css, HbLoadingSpinner, IconButton, Palette, Tooltip, useTestIds } from "@homebound/beam";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  createDesignPackageNewSlotUrl,
  createDesignPackageProductSearchUrl,
  createDesignPackageSlotDetailUrl,
} from "src/RouteUrls";
import { Icon } from "src/components";
import {
  DesignPackageConfiguratorSlotFragment,
  DesignPackageItemSlotEditorDocument,
  DesignPackageSlotsDocument,
  useDeleteItivMutation,
} from "src/generated/graphql-types";
import { PRODUCT_FALLBACK_IMG_URL } from "src/routes/libraries/product-catalog/components/product-images-viewer/ProductImageViewer";
import { fail } from "src/utils";
import { OptionChips } from "../../designCatalogAtoms";
import { useDesignPackageConfiguratorContext } from "../DesignPackageConfiguratorContext";
import { simplifyLocationPaths, useTakeoffLocationsModal } from "./ProductGroupingsManager";

/** The Grid of avialable ITIVs/Cards for the selected Slot that can be configured */
export function DesignPackageItemSlotEditor() {
  const { designPackage, selectedSlots, loading } = useDesignPackageConfiguratorContext();
  const [deleteItiv] = useDeleteItivMutation();
  const history = useHistory();
  const tid = useTestIds({});
  const openTakeoffLocationModal = useTakeoffLocationsModal();

  const singleSlot = selectedSlots?.length === 1 ? selectedSlots.first! : undefined;

  if (!selectedSlots?.nonEmpty)
    return (
      <div css={Css.h100.df.fdc.jcc.aic.p4.gap3.$}>
        {loading ? (
          <HbLoadingSpinner />
        ) : (
          <>
            <div css={Css.xl2Bd.$}>👈 Please select a slot or some filters ☝️ to configure</div>
            <Button
              label="Add a new slot?"
              onClick={createDesignPackageNewSlotUrl(designPackage.id)}
              variant="secondary"
            />
          </>
        )}
      </div>
    );

  return (
    <div css={Css.df.fdc.gap2.$}>
      {selectedSlots.map((dpSlot) => (
        <Fragment key={dpSlot.slot.id}>
          {dpSlot.items
            .groupByObject((itiv) => itiv.scope.placeholder?.id ?? itiv.scope.id)
            .map(([placeholderItiId, itivs]) => {
              const placeholderItiv =
                itivs.find((itiv) => itiv.scope.id === placeholderItiId) ??
                fail("no placeholder found for product family");
              return (
                <div key={placeholderItiId} css={Css.df.fdc.gap2.bb.bcGray300.pb3.mb1.$} {...tid.familyHeader}>
                  <div css={Css.df.jcsb.$}>
                    <div css={Css.df.fdr.aic.gap2.$}>
                      <div css={Css.xsMd.$}>{placeholderItiv.displayName}</div>
                      {placeholderItiv.bidItem?.parentMaterialVariant?.materialAttributeValues
                        .groupByObject((mav) => mav.dimension.name)
                        .map(([dimensionName, mavs]) => (
                          <Chip
                            key={dimensionName}
                            text={`${dimensionName}: ${mavs.map((mav) => mav.valueWithUom).join(" • ")}`}
                          />
                        ))}
                    </div>
                    <div css={Css.df.gap2.$}>
                      <IconButton
                        icon="customize"
                        onClick={() => openTakeoffLocationModal(placeholderItiv.id)}
                        tooltip={
                          <>
                            <div>Split or merge locations. Current locations for this group:</div>
                            <ul>
                              {simplifyLocationPaths(placeholderItiv.scope.planLocations).map((loc) => (
                                <li key={loc.id}>{loc.simplifiedPath}</li>
                              ))}
                            </ul>
                          </>
                        }
                      />
                      <IconButton
                        icon="trash"
                        disabled={!placeholderItiv.scope.canDeleteFromDesignPackage?.allowed}
                        tooltip={placeholderItiv.scope.canDeleteFromDesignPackage?.disabledReasons.first?.message}
                        onClick={() =>
                          deleteItiv({
                            variables: {
                              input: {
                                itemTemplateItemVersions: [{ id: placeholderItiv.id, remove: true }],
                              },
                            },
                            refetchQueries: [DesignPackageSlotsDocument, DesignPackageItemSlotEditorDocument],
                          })
                        }
                      />
                    </div>
                  </div>

                  <InlineGrid>
                    {itivFilterAndSortToFinal(itivs).map((itiv) => (
                      <div
                        key={itiv.id}
                        {...tid.productCard}
                        css={{
                          ...Css.bgWhite.br8.p2.df.fdc.gap2.relative.h100.cursorPointer.bshBasic.onHover.bshHover.$,
                          [`&:hover #${hoverIcons}`]: Css.o100.vv.top3.$,
                          [`&:hover #${hoverImgOverlay}`]: Css.o50.br8.$,
                          // the button hovers white but the Icon is light so it makes it hard to see. Darken the icon when hovered.
                          "& button:hover path": Css.fill("black").$,
                        }}
                        onClick={() => {
                          history.push(
                            itiv.bidItem || itiv.isDisabledBidItem
                              ? createDesignPackageSlotDetailUrl(
                                  designPackage.id,
                                  placeholderItiv.id,
                                  itiv.id,
                                  itiv.isDisabledBidItem ? "noBidItem" : itiv.bidItem?.id,
                                )
                              : createDesignPackageProductSearchUrl(designPackage.id, placeholderItiv.id, itiv.id),
                          );
                        }}
                      >
                        <div
                          id={hoverIcons}
                          css={Css.absolute.o0.vh.top0.right3.df.fdc.gap1.z3.add({ transition: "all 250ms" }).$}
                          onClick={(e) => e.stopPropagation()} // prevent click from bubbling up to the overall card's onClick
                        >
                          <IconButton
                            icon="refresh"
                            color={!itiv.bidItem || itiv.isDisabledBidItem ? Palette.Gray700 : Palette.White}
                            onClick={createDesignPackageProductSearchUrl(designPackage.id, placeholderItiv.id, itiv.id)}
                          />
                          {(itiv.bidItem?.id || itiv.isDisabledBidItem) && (
                            <IconButton
                              icon="pencil"
                              color={!itiv.bidItem || itiv.isDisabledBidItem ? Palette.Gray700 : Palette.White}
                              onClick={createDesignPackageSlotDetailUrl(
                                designPackage.id,
                                placeholderItiv.id,
                                itiv.id,
                                itiv.isDisabledBidItem ? "noBidItem" : itiv.bidItem?.id,
                              )}
                            />
                          )}
                        </div>
                        {itiv.isDisabledBidItem ? (
                          <div css={Css.h100.df.fdc.aic.jcc.gap1.tac.ba.bcGray500.bgGray50.br4.$}>
                            <Tooltip title="Sometimes a product type isn't offered with certain Package Options (e.g., no refrigerator with the Essential package). In these cases, tell Design Packages to leave it empty!">
                              <Icon icon="remove" color={Palette.Gray700} inc={4} />
                            </Tooltip>
                            <div css={Css.gray700.baseSb.maxw75.$}>No Product</div>
                          </div>
                        ) : itiv.bidItem ? (
                          <div css={Css.relative.h100.w100.f1.br4.z1.$}>
                            <div
                              /* pseudo-element until :hover shows this. Overlays the image to darken it so Icons will pop */
                              id={hoverImgOverlay}
                              css={
                                Css.absolute.top0.left0.h100.w100
                                  .bgColor("black")
                                  .o0.z2.add({ transition: "all 400ms" }).$
                              }
                            />
                            <img
                              alt="product-headline"
                              src={
                                itiv.bidItem.parentMaterialVariant?.featuredImage?.asset?.previewUrl ??
                                PRODUCT_FALLBACK_IMG_URL
                              }
                              css={Css.absolute.h100.w100.p1.br4.objectCover.$}
                            />
                          </div>
                        ) : (
                          <div css={Css.fg1.add(blueishFillableUx()).mb1.$}>
                            <Icon icon="plus" pxSize={64} />
                          </div>
                        )}
                        <div css={Css.df.fdc.gap1.oh.$}>
                          <div css={Css.xs.truncate.$}>{itiv.displayName}</div>
                          {itiv.bidItem?.parentMaterialVariant?.displayName && (
                            <div css={Css.smMd.lineClamp(2).$}>{itiv.bidItem.parentMaterialVariant.displayName}</div>
                          )}
                        </div>
                        <div css={Css.fs0.df.fdr.aic.gap1.w100.oxa.$}>
                          {itiv.scope.options
                            .groupByObject((rpo) => rpo.optionGroup)
                            // render chip groups in a consistent order
                            .sortBy(([rpog, rpos]) => rpog.order)
                            .map(([rpog, rpos]) => (
                              <OptionChips key={rpog.id} rpos={rpos} />
                            ))}
                        </div>
                      </div>
                    ))}
                    <div
                      css={Css.h100.w100.add(blueishFillableUx()).df.fdc.jcc.gap1.tac.$}
                      {...tid.newSubVariant}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        history.push(createDesignPackageProductSearchUrl(designPackage.id, placeholderItiv.id));
                      }}
                    >
                      <div css={Css.lgMd.$}>+ New Product Slot</div>
                      <div css={Css.xsSb.gray700.$}>e.g. Deluxe</div>
                    </div>
                  </InlineGrid>
                </div>
              );
            })}
        </Fragment>
      ))}
      <InlineGrid>
        <div
          css={Css.h100.w100.add(blueishFillableUx()).df.fdc.jcc.gap1.tac.$}
          {...tid.newFamilyRow}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(createDesignPackageNewSlotUrl(designPackage.id));
          }}
        >
          <div css={Css.lgMd.$}>+ New {singleSlot?.slot.item.costCode?.name ?? "Product"} Slot</div>
          <div css={Css.xsSb.gray700.$}>e.g. 48" Gas Range</div>
        </div>
      </InlineGrid>
    </div>
  );
}

const blueishFillableUx = () => Css.ba.bsDashed.bgBlue50.bcBlue500.df.aic.jcsa.cursorPointer.blue400.$;
const InlineGrid = ({ children }: React.PropsWithChildren<unknown>) => (
  <div css={Css.dg.gtc("repeat(auto-fill, 276px)").gar("390px").gap2.$}>{children}</div>
);

/**
 * This handles all of the filtering and sorting ahead of Rendering a list of ITIs. We only show ProductITIs.
 *
 * Ordering ITIVs has several considerations:
 *   1. Cluster related items together. For example, the [Kitchen Upgrade] cards should all appear side-by-side
 *   2. More complex cards should come after simpler cards: [No Upgrades] should come before [Kitchen Upgrade]
 *      which should come before [Kitchen Upgrade, Decorative Hood].
 *   3. Within a cluster, sort by the minimum order of the option within a group. This is a heuristic to try to
 *      put [Base, Premium] ahead of [Deluxe].
 *   4. PlaceholderProduct (no non-upgrade options) cards should come last within a cluster.
 */
function itivFilterAndSortToFinal<I extends DesignPackageConfiguratorSlotFragment["items"][number]>(itivs: I[]): I[] {
  return (
    itivs
      // Only show Product ITIs (which all point to their placeholder)
      .filter((itiv) => itiv.scope.placeholder)
      // cluster itivs by Upgrade group via composite key: [rpog:1, rpog:2, rpog:3] -> "rpog:1-rpog:2-rpog:3"
      .groupByObject((itiv) =>
        itiv.scope.options
          .filter((rpo) => rpo.optionGroup.forDesignUpgrade || rpo.optionGroup.isPlanPackage) // cluster around [Kitchen Upgrade], not [SpecLevel] or [InteriorScheme]
          .map((rpo) => rpo.optionGroup.id)
          .unique()
          .sort() // fixes `rpog:1-rpog:2` vs `rpog:2-rpog:1`
          .join("-"),
      )
      // `""` then "rpog:1" then "rpog:1-rpog:2" then "rpog:1-rpog:2-rpog:3"
      .sortBy(([compositeRpogKey]) => compositeRpogKey.length)
      // map back down to clusters of [itiv[], itiv[], itiv[]] that we can subsort before flattening
      .map(([compositeRpogKey, itivs]) => itivs)
      .map((itivUpgradeGroup) =>
        // SUBSORT each cluster by the minimum RPO, so [Base(1), Premium(3)] comes before [Deluxe(2)]
        // issue: 1 card may have [Base,Prm][Dark][Transitional] so we probably want to min-sort by RPOG then RPO
        itivUpgradeGroup.sortBy((itiv) =>
          // PlaceholderProduct cards should come last within a cluster so sort them to the end
          itiv.scope.options.isEmpty
            ? Number.POSITIVE_INFINITY
            : itiv.scope.options
                // Since we've already clustered by Upgrades, and each card has the same set of
                // Upgrades, and we eventually `.first`, we only want to consider the non-Upgrade Groups
                .filter((rpo) => !rpo.optionGroup.forDesignUpgrade && !rpo.optionGroup.isPlanPackage)
                .groupByObject((rpo) => rpo.optionGroup)
                .sortBy(([rpog, rpos]) => rpog.order)
                .map(([rpog, rpos]) => rpos)
                .first?.min((rpo) => rpo.order) || 0,
        ),
      )
      // with everything now sorted and subsorted, flatten back down into 1 array. This is the final order.
      .flat()
  );
}

const hoverIcons = "cardIcons";
const hoverImgOverlay = "cardImg";
