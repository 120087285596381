import {
  Button,
  collapseColumn,
  column,
  Css,
  emptyCell,
  FilterDefs,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  HasIdAndName,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContent,
  SelectField,
  singleFilter,
  useModal,
  useTestIds,
} from "@homebound/beam";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { SearchBox } from "src/components";
import { UnitsOfMeasureSelectField } from "src/components/autoPopulateSelects/UnitsOfMeasureSelectField";
import {
  BulkAddItemCostCodes_ItemFragment,
  CostType,
  SaveChangeEventLineItemInput,
  Stage,
  useBulkAddItemCostCodesQuery,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { costTypeToNameMapper, groupBy, pluralize, queryResult, safeEntries } from "src/utils";

export type BulkAddItemCostCodesModalProps = {
  stage: Stage;
  onAdd: (celis: SaveChangeEventLineItemInput[]) => void;
  locations: HasIdAndName[];
  changeEventId: string;
};

export function BulkAddItemCostCodesModal(props: BulkAddItemCostCodesModalProps) {
  const { onAdd, locations, stage, changeEventId } = props;
  const query = useBulkAddItemCostCodesQuery();
  return queryResult(query, (data) => (
    <AddBulkItemCostCodesModalContent
      stage={stage}
      items={data.items ?? []}
      locations={locations}
      changeEventId={changeEventId}
      onAdd={onAdd}
    />
  ));
}

type Filter = {
  view?: "selections";
};

function AddBulkItemCostCodesModalContent(props: AddBulkItemCostCodesModalContentProps) {
  const { items, stage, locations, onAdd, changeEventId } = props;
  const testIds = useTestIds({}, "bulkAddCostCodeItemsModal");
  const { closeModal } = useModal();

  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [filter, setFilter] = useState<Filter>({});
  const [newCelis, setNewCelis] = useState<ChangeEventLineItemInstance[]>([]);

  const filterDefs = useMemo(() => createFilterDefs(stage), [stage]);
  const rows = useMemo(() => createRows(items, newCelis, filter), [items, newCelis, filter]);
  const columns = useMemo(() => createColumns(locations, newCelis, setNewCelis), [locations, newCelis]);

  function disableCreate() {
    if (newCelis.isEmpty) return true;
    const invalidCelis = newCelis.filter((celi) => !celi.costType || !celi.locationId);
    return invalidCelis.nonEmpty;
  }

  return (
    <>
      <ModalHeader>Add items by cost code</ModalHeader>
      <ModalBody>
        <div {...testIds}>
          <div css={Css.df.mb2.$}>
            <h2 css={Css.baseMd.$}>Line Items</h2>
            {newCelis.nonEmpty && (
              <div css={Css.mla.xs.$}>
                {newCelis.length} {pluralize(newCelis, "item")} selected
              </div>
            )}
          </div>
          <TableActions>
            <Filters filter={filter} filterDefs={filterDefs} onChange={setFilter} />
            <SearchBox onSearch={setSearchFilter} />
          </TableActions>
          <ScrollableContent virtualized>
            <div css={Css.hPx(800).w100.$}>
              <GridTable
                as="virtual"
                columns={columns}
                rows={rows}
                style={{ grouped: true }}
                filter={searchFilter}
                stickyHeader
                sorting={{ on: "client", initial: ["group", "DESC"] }}
              />
            </div>
          </ScrollableContent>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          variant="primary"
          label={"Add to Change Event"}
          disabled={disableCreate()}
          onClick={() => {
            closeModal();
            const input = newCelis.map(({ item, locationId, unitOfMeasureId, costType }) => ({
              itemId: item.id,
              locationId,
              unitOfMeasureId,
              costType,
              changeEventId,
            }));
            onAdd(input);
          }}
        />
      </ModalFooter>
    </>
  );
}

type HeaderRow = Record<string, any>;
type ItemRow =
  | { kind: "header"; data: HeaderRow; id: string }
  | { kind: "costCodeGroup"; data: BulkAddItemCostCodes_ItemFragment[]; id: string }
  | { kind: "itemGroup"; data: BulkAddItemCostCodes_ItemFragment; id: string }
  | { kind: "lineItem"; data: ChangeEventLineItemInstance; id: string };

type AddBulkItemCostCodesModalContentProps = {
  stage: Stage;
  locations: HasIdAndName[];
  items: BulkAddItemCostCodes_ItemFragment[];
  changeEventId: string;
  onAdd: (celis: SaveChangeEventLineItemInput[]) => void;
};

type ChangeEventLineItemInstance = {
  id: string;
  item: BulkAddItemCostCodes_ItemFragment;
  unitOfMeasureId?: string;
  costType?: CostType;
  locationId?: string;
};

function createRows(
  items: BulkAddItemCostCodes_ItemFragment[],
  newCelis: ChangeEventLineItemInstance[],
  filter: Filter,
): GridDataRow<ItemRow>[] {
  // Group CELIs by item
  const groupedCelis = groupBy(newCelis, (celi) => celi.item.id);
  // Group items by cost code
  const filteredItems = items.filter((i) => (filter.view === "selections" ? i.isSelection : true));
  const groupedItems = Object.entries(groupBy(filteredItems, (item) => item.costCode.id));
  return [
    { kind: "header", id: "header", data: groupedItems },
    ...groupedItems.map(([group, items]) => ({
      kind: "costCodeGroup" as const,
      id: group,
      data: items,
      children: items.map((i: BulkAddItemCostCodes_ItemFragment) => ({
        kind: "itemGroup" as const,
        id: i.id,
        data: i,
        children: (groupedCelis[i.id] ?? []).map((celi) => ({
          kind: "lineItem" as const,
          id: celi.id,
          data: celi,
        })),
      })),
    })),
  ];
}

function createColumns(
  locations: HasIdAndName[],
  newCelis: ChangeEventLineItemInstance[],
  setNewCelis: Dispatch<SetStateAction<ChangeEventLineItemInstance[]>>,
): GridColumn<ItemRow>[] {
  return [
    collapseColumn<ItemRow>(),
    column<ItemRow>({
      id: "group",
      header: "Cost Code",
      costCodeGroup: (data) => data[0]?.costCode?.displayName,
      itemGroup: (data) => data?.displayName,
      lineItem: (data) => data?.item?.displayName,
      w: 2,
    }),
    column<ItemRow>({
      header: "Location",
      costCodeGroup: emptyCell,
      itemGroup: emptyCell,
      lineItem: (data) => (
        <SelectField
          value={data.locationId}
          label="location"
          onSelect={(val) => {
            const updatedRow = {
              ...data,
              locationId: val,
            };
            const index = newCelis.findIndex((celi) => celi.id === data.id);
            const items = [...newCelis];
            items[index] = updatedRow;
            setNewCelis(items);
          }}
          options={locations}
        />
      ),
      clientSideSort: false,
      w: 1,
    }),
    column<ItemRow>({
      header: "Cost Type",
      costCodeGroup: emptyCell,
      itemGroup: emptyCell,
      lineItem: (data) => (
        <SelectField
          value={data.costType}
          label="costType"
          onSelect={(val) => {
            const updatedRow = {
              ...data,
              costType: val,
            };
            const index = newCelis.findIndex((celi) => celi.id === data.id);
            const items = [...newCelis];
            items[index] = updatedRow;
            setNewCelis(items);
          }}
          options={safeEntries(costTypeToNameMapper)}
          getOptionLabel={([ct, ctDisplay]) => ctDisplay}
          getOptionValue={([ct, ctDisplay]) => ct}
        />
      ),
      clientSideSort: false,
      w: 1,
    }),
    column<ItemRow>({
      header: "Unit Of Measure",
      costCodeGroup: emptyCell,
      itemGroup: emptyCell,
      lineItem: (data) => (
        <UnitsOfMeasureSelectField
          key={data.id}
          value={data.unitOfMeasureId}
          label="unitOfMeasure"
          onSelect={(val, opt) => {
            const updatedRow = {
              ...data,
              unitOfMeasureId: val,
            };
            const index = newCelis.findIndex((celi) => celi.id === data.id);
            const items = [...newCelis];
            items[index] = updatedRow;
            setNewCelis(items);
          }}
        />
      ),
      clientSideSort: false,
      w: "142px",
    }),
    column<ItemRow>({
      header: "",
      costCodeGroup: emptyCell,
      itemGroup: (data) => (
        <IconButton
          compact
          label="addItem"
          icon="plus"
          tooltip="Add item"
          onClick={() => setNewCelis((prev) => [...prev, { id: `${data.id}-${Math.random()}`, item: data }])}
        />
      ),
      w: "56px",
      lineItem: (data) => (
        <IconButton
          compact
          label="removeItem"
          icon="trash"
          tooltip="Remove item"
          onClick={() => setNewCelis((prev) => [...prev.filter((celi) => celi.id !== data.id)])}
        />
      ),
      clientSideSort: false,
    }),
  ];
}

function createFilterDefs(stage: Stage): FilterDefs<Filter> {
  return {
    // The precon stage doesn't have selections, so hide `View: Selections`
    ...(stage === Stage.Construction
      ? {
          view: singleFilter({
            options: [{ id: "selections" as const, name: "Selections" }],
            getOptionValue: (o) => o.id,
            getOptionLabel: (o) => o.name,
          }),
        }
      : {}),
  };
}
