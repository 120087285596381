import { Css, IconButton, Palette, RouteTabWithContent, TabsWithContent } from "@homebound/beam";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { addEntityParam, createTradePartnerUrl, createTradePartnersUrl } from "src/RouteUrls";
import {
  TradePartnerPageTradePartnerFragment,
  useSaveTradePartnerMutation,
  useTradePartnerPageQuery,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { IdOrAddParams, tradePartnerPaths } from "src/routes/routesDef";
import { TradePartnerCommitmentsTab } from "src/routes/trade-partners/TradePartnerCommitmentsTab";
import { TradePartnerContactsTab } from "src/routes/trade-partners/TradePartnerContactsTab";
import { TradePartnerDetailsTab } from "src/routes/trade-partners/TradePartnerDetailsTab";
import { TradePartnerReviews } from "src/routes/trade-partners/components/TradePartnerReviews";
import { queryResult } from "src/utils/queryResult";
import { TradePartnerDevelopmentContractsTab } from "./TradePartnerDevelopmentContractsTab";
import { TradePartnerRequirements } from "./TradePartnerRequirementsTab";

export function TradePartnerPage() {
  const { idOrAdd } = useParams<IdOrAddParams>();
  const isNew = idOrAdd === addEntityParam;
  const query = useTradePartnerPageQuery({
    variables: { id: idOrAdd },
    skip: isNew,
  });

  if (isNew) {
    return <DataView tradePartner={undefined} />;
  }

  return queryResult(query, ({ tradePartner }) => {
    return <DataView tradePartner={tradePartner} />;
  });
}

function DataView({ tradePartner }: { tradePartner: TradePartnerPageTradePartnerFragment | undefined }) {
  // Only used for updating the sweetheart indicator, which we put in the header.
  const [saveTradePartner] = useSaveTradePartnerMutation();

  const tabs = useMemo(() => createTabs(tradePartner?.id), [tradePartner?.id]);

  return (
    <>
      <PageHeader
        title={tradePartner?.name || "New Trade Partner"}
        breadcrumb={{ label: "All Trade Partners", href: createTradePartnersUrl() }}
        left={
          tradePartner && (
            <IconButton
              icon="star"
              color={tradePartner.isSweetheart ? Palette.Gray700 : Palette.Gray300}
              onClick={async () => {
                await saveTradePartner({
                  variables: { input: { id: tradePartner.id, isSweetheart: !tradePartner.isSweetheart } },
                });
              }}
            />
          )
        }
      />
      <div css={Css.mt2.$}>
        <TabsWithContent tabs={tabs} />
      </div>
    </>
  );
}

function createTabs(tradePartnerId: string | undefined): RouteTabWithContent[] {
  const isNew = tradePartnerId === undefined;
  const idOrAdd = tradePartnerId || addEntityParam;
  return [
    {
      name: "Details",
      href: createTradePartnerUrl(idOrAdd),
      path: tradePartnerPaths.tradePartner,
      render() {
        return <TradePartnerDetailsTab idOrAdd={idOrAdd} />;
      },
    },
    {
      name: "Contacts",
      href: createTradePartnerUrl(idOrAdd, "contacts"),
      path: tradePartnerPaths.contacts,
      disabled: isNew,
      render() {
        return <TradePartnerContactsTab tradePartnerId={idOrAdd} />;
      },
    },
    {
      name: "Reviews",
      href: createTradePartnerUrl(idOrAdd, "reviews"),
      path: tradePartnerPaths.reviews,
      disabled: isNew,
      render() {
        return <TradePartnerReviews tradePartnerId={idOrAdd} />;
      },
    },
    {
      name: "Commitments",
      href: createTradePartnerUrl(idOrAdd, "commitments"),
      path: tradePartnerPaths.commitments,
      disabled: isNew,
      render() {
        return <TradePartnerCommitmentsTab tradePartnerId={idOrAdd} />;
      },
    },
    {
      name: "Development Contracts",
      href: createTradePartnerUrl(idOrAdd, "development-contracts"),
      path: tradePartnerPaths.developmentContracts,
      disabled: isNew,
      render() {
        return <TradePartnerDevelopmentContractsTab tradePartnerId={idOrAdd} />;
      },
    },
    {
      name: "Requirements",
      href: createTradePartnerUrl(idOrAdd, "requirements"),
      path: tradePartnerPaths.requirements,
      disabled: isNew,
      render() {
        return <TradePartnerRequirements tradePartnerId={idOrAdd} />;
      },
    },
  ];
}
