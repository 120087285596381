import {
  Button,
  Checkbox,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelectField,
  Switch,
  TreeSelectField,
  useModal,
} from "@homebound/beam";
import { useState } from "react";
import { baseDownloadUrl } from "src/context";
import {
  FinishSchedule_DesignPackageFragment,
  FinishSchedule_ReadyPlanOptionFragment,
  ItemTemplateStatus,
  useFinishScheduleDesignPackageMetadataQuery,
} from "src/generated/graphql-types";
import { useLocationsTree } from "src/hooks/useLocationsTree";
import useSetState from "src/hooks/useSetState";
import { openInSelf } from "src/utils/window";
import { FinishScheduleFilters } from "../FinishSchedulePage";

type ExportFinishScheduleModalProps = {
  designPackage: FinishSchedule_DesignPackageFragment;
  designPackageTemplateId: string;
  exportFilter: FinishScheduleFilters | undefined;
};

export function ExportFinishScheduleModal({
  designPackage,
  exportFilter,
  designPackageTemplateId,
}: ExportFinishScheduleModalProps) {
  const metadataQuery = useFinishScheduleDesignPackageMetadataQuery({ variables: { dpItId: designPackageTemplateId } });
  const locationOptions = useLocationsTree({ itId: designPackageTemplateId });
  const { closeModal } = useModal();
  const [visualMode, setVisualMode] = useState(false);
  const { costCodes } = metadataQuery.data?.itemTemplate ?? {};
  const { planPackages, options } = designPackage;

  // The filter from the parent component is passed in as a prop.
  // Keep a local copy of filters so we don't upsert to parent component
  const [filter, setFilter] = useSetState<FinishScheduleFilters>(exportFilter);

  // Format the planPackages for the MultiSelectField component.
  const planPackageOptions = planPackages?.map((planPackage) => {
    const template =
      planPackage.itemTemplates?.find((it) => it.status === ItemTemplateStatus.Draft) ??
      planPackage.itemTemplates?.find((it) => it.isLatest);
    return { id: template!.id, name: planPackage.name };
  });

  // Group the options by group for display in the modal
  const designPackageOptions = options
    .filter((rpo) => rpo.optionGroup.forDesignPackages)
    .groupByObject((rpo) => rpo.optionGroup.name);

  const upgradeOptions = options.filter((rpo) => rpo.optionGroup.forDesignUpgrade);

  return (
    <>
      <ModalHeader>Export {designPackage.name} Finish Schedule</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.my1.gap2.$}>
          <Switch compact label="Visual Mode" selected={visualMode} onChange={(checked) => setVisualMode(checked)} />
          <p>
            Select a Plan (if applicable) and a set of Package Options and Upgrades for which you would like to export
            product selections
          </p>
          <MultiSelectField
            label="Plan"
            onSelect={(val) => setFilter({ plans: val })}
            options={planPackageOptions}
            values={filter.plans}
          />
          <MultiSelectField
            label="Cost Codes"
            onSelect={(val) => setFilter({ costCodes: val })}
            options={costCodes ?? []}
            values={filter.costCodes}
          />
          <TreeSelectField
            label="Locations"
            onSelect={(val) => setFilter({ locationInPath: val.root.values })}
            options={locationOptions}
            values={filter.locationInPath}
          />
          {designPackageOptions.map(([optionGroupName, options]) => (
            <div key={optionGroupName} css={Css.df.fdc.gap1.$}>
              <label>{optionGroupName}</label>
              {options.map((option) => (
                <Checkbox
                  key={option.id}
                  label={option.name}
                  onChange={(selected) => setOptionFilter(option, selected, setFilter)}
                  selected={(filter[optionGroupName] ?? []).includes(option.id)}
                />
              ))}
            </div>
          ))}
          {upgradeOptions.nonEmpty && (
            <div css={Css.df.fdc.gap1.$}>
              <label>Upgrades</label>
              {upgradeOptions.map((option) => (
                <Checkbox
                  key={option.id}
                  label={option.name}
                  onChange={(selected) => setOptionFilter(option, selected, setFilter)}
                  selected={(filter.upgradeOptions ?? []).includes(option.id)}
                />
              ))}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={Css.df.gap2.$}>
          <Button variant="text" label="Cancel" onClick={closeModal} />
          <Button
            variant="primary"
            label="Export"
            onClick={() => {
              openInSelf(
                `${baseDownloadUrl()}/pdf?${buildQueryParams(
                  filter,
                  visualMode,
                  designPackage.id,
                  designPackageTemplateId,
                )}`,
              );
              closeModal();
            }}
          />
        </div>
      </ModalFooter>
    </>
  );
}

function setOptionFilter(
  option: FinishSchedule_ReadyPlanOptionFragment,
  selected: boolean,
  setFilter: (prev: any) => void,
) {
  const filterName = (option.optionGroup.forDesignUpgrade && "upgradeOptions") || option.optionGroup.name;
  setFilter((prev: FinishScheduleFilters) => {
    const options = prev[filterName] ?? [];
    return {
      [filterName]: selected ? [...options, option.id] : options.filter((id: string) => id !== option.id),
    };
  });
}

function buildQueryParams(
  filter: FinishScheduleFilters,
  visualMode: boolean,
  designPackageId: string,
  designTemplateId: string,
) {
  const { plans, costCodes, locationInPath, ...options } = filter;
  // Reduce the options (specLevelOptions, styleOptions, etc.) to a single array of comma separated ids
  const optionIds = Object.values(options).flat();
  // build the query params for the PDF download url
  const params = new URLSearchParams({
    designTemplateId,
    type: visualMode ? "designPackageFinishScheduleVisualMode" : "designPackageFinishSchedule",
    // The standard Finish Schedule PDF requires the designPackageId, but the Visual Mode PDF does not
    ...(!visualMode && { designPackageId }),
    ...(plans?.nonEmpty && { planTemplateIds: plans.join() }),
    ...(costCodes?.nonEmpty && { costCodeIds: costCodes.join() }),
    ...(locationInPath?.nonEmpty && { locationInPath: locationInPath.join() }),
    ...(optionIds.nonEmpty && {
      optionIds: optionIds.join(),
    }),
  });
  return params.toString();
}
